import Tooltip from "../../../Tooltip";
import { DisabledButtons, OfflineBox } from "./style";
import { LoadingFlex } from "../../../../../user/unknown/login/pages/Login/styles";
import ReactLoading from "react-loading";
import { useMainHook } from "../../../../../../hooks/main";
import IconOffline from "./IconOffline";

const ButtonSave = ({ loading, saveIcon }) => {
  const { isOffline, lastSaveDate } = useMainHook();
  let horarioFormatado: string = "00:00";

  if (lastSaveDate) {
    const horarioUTC: Date = new Date(lastSaveDate);
    const opcoesFormato: Intl.DateTimeFormatOptions = {
      timeZone: "America/Sao_Paulo",
      hour: "2-digit",
      minute: "2-digit"
    };
    const formatoBrasileiro: Intl.DateTimeFormat = new Intl.DateTimeFormat(
      "pt-BR",
      opcoesFormato
    );
    horarioFormatado = formatoBrasileiro.format(horarioUTC);
  }

  if (loading && !isOffline) {
    return (
      <DisabledButtons>
        <LoadingFlex>
          <ReactLoading type="spin" color="#fff" height={16} width={16} />
        </LoadingFlex>
      </DisabledButtons>
    );
  }

  return (
    <DisabledButtons>
      {!isOffline ? (
        <Tooltip
          title="Projeto salvo"
          description={`Todas as alterações foram salvas às ${horarioFormatado}`}
          placement="bottom-start"
        >
          <img className="save" src={saveIcon} alt="Ícone de salvar" />
        </Tooltip>
      ) : (
        <Tooltip
          title="Offline"
          description="As alterações não foram salvas!"
          placement="bottom-start"
        >
          <OfflineBox>
            <p>Offline</p>
            <IconOffline />
          </OfflineBox>
        </Tooltip>
      )}
    </DisabledButtons>
  );
};

export default ButtonSave;
