import React from "react";

interface BaseIconProps {
  isActive?: boolean;
  className?: string;
}

export default function AllToBack({ isActive, className }: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M19.0083 21.9997H13.0249C11.3795 21.9997 10.0332 20.6574 10.0332 19.0168V13.0508C10.0332 11.4102 11.3795 10.0679 13.0249 10.0679H19.0083C20.6537 10.0679 22 11.4102 22 13.0508V19.0168C22 20.6574 20.6537 21.9997 19.0083 21.9997ZM13.0249 11.0622C11.9279 11.0622 11.0304 11.9571 11.0304 13.0508V19.0168C11.0304 20.1105 11.9279 21.0054 13.0249 21.0054H19.0083C20.1052 21.0054 21.0027 20.1105 21.0027 19.0168V13.0508C21.0027 11.9571 20.1052 11.0622 19.0083 11.0622H13.0249Z"
        fill="#343A40"
        stroke="#343A40"
        stroke-width="0.75"
      />
      <path
        d="M9.00829 17.9665C7.36286 17.9665 6.0166 16.6242 6.0166 14.9835V9.01763C6.0166 7.377 7.36286 6.03467 9.00829 6.03467H14.9917C16.6371 6.03467 17.9834 7.377 17.9834 9.01763H16.9861C16.9861 7.92388 16.0886 7.02899 14.9917 7.02899H9.00829C7.91134 7.02899 7.01383 7.92388 7.01383 9.01763V14.9835C7.01383 16.0773 7.91134 16.9722 9.00829 16.9722V17.9665Z"
        fill="#343A40"
        stroke="#343A40"
        stroke-width="0.75"
      />
      <path
        d="M13.4183 4.1875C13.169 2.9446 12.0222 2 10.7258 2H4.74238C3.24654 2 2 3.2429 2 4.73438V10.7003C2 11.9929 2.89751 13.1364 4.1939 13.385C4.39335 13.4347 4.54293 13.4347 4.74238 13.4347H4.99169V8.41336C4.99169 7.07103 5.88919 5.03268 8.43213 5.03268C11.2244 5.03268 13.2188 5.03268 13.2188 5.03268L13.4681 4.98296V4.73438C13.4681 4.58523 13.4681 4.38637 13.4183 4.1875Z"
        fill="#343A40"
      />
    </svg>
  );
}
