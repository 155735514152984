import Konva from "konva";

import { Fragment, memo, useEffect, useRef, useState } from "react";
import { Rect, Transformer } from "react-konva";
import { PagesProps } from "../../../../../dtos/PagesProps";
import { IRenderedObject } from "../../../../../dtos/RenderedObject";
import { Html } from "react-konva-utils";

interface RectangleShapeProps {
  shapeProps: IRenderedObject | Konva.Rect | Konva.Shape | any;
  selectedObject: Konva.Shape;
  setSelectedObject: React.Dispatch<
    React.SetStateAction<Konva.Shape | Konva.Group>
  >;
  addToHistory: (isLastHistory?: boolean) => void;
  currentMultipleSelection: React.MutableRefObject<Konva.Transformer>;
  setObjectScreen: React.Dispatch<React.SetStateAction<PagesProps[]>>;
  transformerSelectedObject: React.MutableRefObject<Konva.Transformer>;
  onChange?: (newAttrs: IRenderedObject) => void;
  handleRemoveElementGroup: (id: string) => void;
  isDraggingObject?: boolean;
  setIsDraggingObject?: React.Dispatch<React.SetStateAction<boolean>>;
  setPageObject?: React.Dispatch<React.SetStateAction<number>>;
}

const Rectangle: React.FC<RectangleShapeProps> = ({
  selectedObject,
  shapeProps,
  setSelectedObject,
  addToHistory,
  currentMultipleSelection,
  setObjectScreen,
  transformerSelectedObject,
  onChange,
  handleRemoveElementGroup,
  isDraggingObject,
  setIsDraggingObject,
  setPageObject,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [showRotation, setShowRotation] = useState(false);
  const [rotation, setRotation] = useState(0);
  const shapeRef = useRef<Konva.Shape>();
  const trRef = useRef<Konva.Transformer>();
  const [shiftPressed, setShiftPressed] = useState(false);

  // ======>
  let isDraggable = shapeRef?.current?.draggable();
  //this code below verify if elements stay block and remove anchors for editions in scale
  // when element is block
  useEffect(() => {
    if (shapeRef.current) {
      isDraggable = shapeRef.current.draggable();
    }
  }, [shapeRef?.current]);
  // <=======

  useEffect(() => {
    if (shapeRef.current === selectedObject) {
      setIsSelected(true);
      transformerSelectedObject.current = trRef.current;
    } else {
      setIsSelected(false);
    }
  }, [selectedObject, shapeRef.current]);

  useEffect(() => {
    trRef.current?.setNodes([shapeRef.current]);
    trRef.current?.setZIndex(trRef.current.getParent().children.length - 1);
    trRef.current?.getLayer().batchDraw();
  }, [isSelected]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <Fragment key={shapeProps.id}>
      <Rect
        key={shapeProps.id}
        ref={shapeRef}
        shadowBlur={0.7}
        shadowColor={shapeProps.fill}
        name="rectangle"
        {...shapeProps}
        onClick={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
          const isTargetInsideMultipleSelection =
            currentMultipleSelection.current?.nodes().includes(event.target);
          if (isTargetInsideMultipleSelection) {
            setSelectedObject(null);

            if (shiftPressed) {
              handleRemoveElementGroup(event.target.attrs.id);
              return;
            }
          } else {
            setSelectedObject(event.target);
            //when nodes is empty array because doesn`t exists any multiple items
            currentMultipleSelection.current?.setNodes([]);
          }
        }}
        onDragStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
          if (
            currentMultipleSelection.current?.nodes().length === 0 ||
            !currentMultipleSelection.current
          ) {
            addToHistory();
          }

          setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
          setIsDraggingObject(true);

          //if mutipleSelection includes element being dragged
          if (
            currentMultipleSelection.current?.nodes().includes(event.target)
          ) {
            setSelectedObject(null);
          } else {
            setSelectedObject(event.target);
            currentMultipleSelection.current?.setNodes([]);
          }
        }}
        onTransform={(event: Konva.KonvaEventObject<MouseEvent>) => {
          shapeRef.current.setAttrs({
            width: Math.max(
              shapeRef.current.width() * shapeRef.current.scaleX(),
              5
            ),
            height: Math.max(
              shapeRef.current.height() * shapeRef.current.scaleY(),
              5
            ),
            scaleX: 1,
            scaleY: 1,
          });

          onChange({
            ...shapeProps,
            x: event.target.x(),
            y: event.target.y(),
            // set minimal value
            width: shapeRef.current.width(),
            height: shapeRef.current.height(),
          });
        }}
        onTransformEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
          addToHistory();
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          //change sizes
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
        onDragEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setIsDraggingObject(false);
          onChange({
            ...shapeProps,
            x: event.target.x(),
            y: event.target.y(),
          });
        }}
      />

      {isSelected && showRotation && (
        <Html>
          <div
            style={{
              width: "40px",
              height: "20px",
              background: "rgba(0, 0, 0, 0.8)",
              position: "absolute",
              top: shapeProps?.y - 50,
              left: Math.ceil(shapeProps?.x - 20),
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#fff" }}>{`${rotation?.toFixed(0)}°`}</span>
          </div>
        </Html>
      )}

      {isSelected && (
        <Transformer
          ref={trRef}
          ignoreStroke
          padding={0}
          enabledAnchors={
            isDraggable
              ? [
                  "top-left",
                  "top-center",
                  "top-right",
                  "middle-right",
                  "middle-left",
                  "bottom-left",
                  "bottom-center",
                  "bottom-right",
                ]
              : []
          }
          rotateEnabled={isDraggable ? true : false}
          onTransformStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
            if (
              currentMultipleSelection.current?.nodes().length === 0 ||
              !currentMultipleSelection.current
            ) {
              addToHistory();
            }
          }}
          onTransform={(event: Konva.KonvaEventObject<MouseEvent>) => {
            const node = event.target;
            const rotationNode = node.rotation();
            setShowRotation(true);
            setRotation(rotationNode);
          }}
          onTransformEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
            setShowRotation(false);
            onChange({
              ...shapeProps,
              scaleX: event.target.scaleX(),
              scaleY: event.target.scaleY(),
              x: event.target.x(),
              y: event.target.y(),
              rotation: rotation,
            });
          }}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Fragment>
  );
};

export default memo(Rectangle);
