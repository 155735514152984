import styled from "styled-components";
import { Theme } from "../../../../../../dtos/theme";

interface TextProps extends React.HTMLProps<HTMLTextAreaElement> {
  margin?: string;
}

export const DropDownContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
  z-index: 99;
`;

export const Flex = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
`;

export const Container = styled.div`
  width: 100%;
  height: 30px;
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
`;

export const DropDownHeader = styled.div<{ Open: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 0.32rem;
  justify-content: space-between;
  font-weight: bold;
  height: 100%;
  background: red;
  font-size: 1.6rem;
  color: ${({ theme }: Theme) => theme.colors.black};
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  &:hover {
    cursor: pointer;
  }

  .effect-arrow {
    transition: all 0.1s ease-in;
    transform: ${props => (props.Open ? "rotateZ(-180deg)" : "")};
  }
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  bottom: -86px;

  border-radius: 2px;
  width: 208px;

  background-color: ${({ theme }) => theme.colors.white100};
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));

  cursor: pointer;
`;

export const DropDownList = styled.ul`
  padding-bottom: 5px;
  background-color: ${({ theme }) => theme.colors.white100};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ListItem = styled.li`
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  width: 188px;

  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 0rem 0;
`;



export const Text = styled.p<TextProps>`
  color: ${({ theme }) => theme.colors.white100};
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
`;

export const MiniBox = styled.div`
  max-width: 80px;
  width: 100%;
  height: 30px;
  background: #d9d9d9;
  border-radius: 5px;
`;


