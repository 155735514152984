import React, { useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import ButtonIcons from "../../../../../shared/components/ButtonIcons";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import EditClausesIcon from "../../../assets/icons/EditClausesIcon";
import RemoveClausesIcon from "../../../assets/icons/RemoveClausesIcon";
import StarClausesIcon from "../../../assets/icons/StarClausesIcon";
import ButtonPrimary from "../../ButtonPrimary";
import CategoriesBoxUser from "../../CategoriesBoxUser";
import IconSvg from "../../IconSvg";
import { v4 as uuidv4 } from "uuid";
import List from "../../List";
import ListItem from "../../ListItem";
import { ContainerLoading } from "../../LoadingEditor/styles";
import QuillRenderImage from "../../QuillRenderImage";
import { SearchInput } from "../../SearchInput";
import {
  ButtonCategories,
  ClausesItemFeatures,
  Container,
  Text,
  ContentFilter,
  DescriptionClause,
  FixedStack,
  FlexBar,
  FlexSearch,
  HStack,
  SpanClause,
  VStack,
  Header,
  ButtonFooter,
  NotResult,
} from "../styles";

import { animate, useAnimation, useMotionValue, motion } from "framer-motion";
import { ClausesDataProps, MoreOptionsButtonProps, QueryProps } from "../dtos";
import { useClausesEditor } from "../../../hooks/clausesEditor";
import Tooltip from "../../Tooltip";
import ButtonIconsWithTooltip from "../../ButtonsAsideBar/components/ButtonIconsAsidebar";
import { useMainHook } from "../../../../../hooks/main";
import ButtonMaxWidth from "../../../../../shared/components/ButtonMaxWidth";
import FavotiteIcon from "../../SearchInput/Icons/FavotiteIcon";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import { HeaderClause, TextClause } from "../../ListItem/styles";
import { useTheme } from "../../../../../hooks/theme";
import { MyClausesDataProps } from "../../../../../dtos/Clauses";

// import { Container } from './styles';

const list = {
  visible: {
    opacity: 1,
    y: 5,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    y: -20,
    transition: {
      when: "afterChildren",
    },
  },
};

interface UserClausesProps {
  isActiveBoxUserCategories: boolean;
  refComponentBoxUser: React.MutableRefObject<any>;
  query: QueryProps;
  setQuery: React.Dispatch<React.SetStateAction<QueryProps>>;
  focusInput: boolean;
  setFocusInput: React.Dispatch<React.SetStateAction<boolean>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;

  filteredFavorites: boolean;
  setFilteredFavorites: React.Dispatch<React.SetStateAction<boolean>>;
  forRefresh: boolean;
  setForRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseBoxUserCategories: () => void;
  dataCategoriesUser: string[];
  paramsCategoriesUser: {
    filter: string;
  };
  setParamsCategoriesUser: React.Dispatch<
    React.SetStateAction<{
      filter: string;
    }>
  >;
  handleOpenBoxUserCategories: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
  loading: boolean;
  showFilter: string;
  isFavoriteFilter: boolean;
  handleGenerateClause: (
    value: string,
    event?: any,
    index?: number,
    keyWords?: any
  ) => Promise<void>;
  isActiveOptions: MoreOptionsButtonProps;
  handleFavoriteClause: (id: string, index?: number) => Promise<void>;
  handleOpenEditClauseModal: (id?: string, index?: number) => void;
  handleRemoveClause: (id: string) => Promise<void>;
  handleActiveMoreOptions: (active: any, index: any) => void;
  CloseOptionsIcon: string;
  MoreOptionsIcon: string;
  visibilityIconsOptionsClauses: {
    visible: boolean;
    index: number;
  };
  existsNewPage: boolean;
  handleOpenNewClausesModal: () => void;
  handleShowClausule: (item: MyClausesDataProps, index?: number) => void;
  setIsOpenModalDeleteClause: React.Dispatch<React.SetStateAction<boolean>>;
  setIdClause: React.Dispatch<React.SetStateAction<string>>;
}

const UserClauses: React.FC<UserClausesProps> = ({
  isActiveBoxUserCategories,
  refComponentBoxUser,
  query,
  setQuery,
  focusInput,
  setFocusInput,
  currentPage,
  setCurrentPage,
  filteredFavorites,
  setFilteredFavorites,
  forRefresh,
  setForRefresh,
  handleCloseBoxUserCategories,
  dataCategoriesUser,
  paramsCategoriesUser,
  setParamsCategoriesUser,
  handleOpenBoxUserCategories,
  loading,
  showFilter,
  isFavoriteFilter,
  handleGenerateClause,
  isActiveOptions,
  handleFavoriteClause,
  handleOpenEditClauseModal,
  handleRemoveClause,
  handleActiveMoreOptions,
  CloseOptionsIcon,
  MoreOptionsIcon,
  visibilityIconsOptionsClauses,
  existsNewPage,
  handleOpenNewClausesModal,
  handleShowClausule,
  setIsOpenModalDeleteClause,
  setIdClause,
}) => {
  const { clausesData, setClausesData } = useClausesEditor();
  const { theme } = useTheme();

  return (
    <>
      <Container>
        <VStack isModalOpen={isActiveBoxUserCategories} height="100%">
          <Header ref={refComponentBoxUser}>
            <FlexSearch>
              <SearchInput
                query={query}
                setQuery={setQuery}
                label="Pesquisar por título"
                focusInput={focusInput}
                setFocusInput={setFocusInput}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setClausesData={setClausesData}
                clausesData={clausesData}
                filtered={filteredFavorites}
                setFiltered={setFilteredFavorites}
                refresh={forRefresh}
                setRefresh={setForRefresh}
              />
            </FlexSearch>

            {isActiveBoxUserCategories && (
              <CategoriesBoxUser
                handleBoxCategories={handleCloseBoxUserCategories}
                dataCategories={dataCategoriesUser}
                paramsCategories={paramsCategoriesUser}
                setParamsCategories={setParamsCategoriesUser}
                query={query}
                setQuery={setQuery}
                filtered={filteredFavorites}
                setFiltered={setFilteredFavorites}
                refCategories={refComponentBoxUser}
                // closeToggleUser={closeToggleUser}
              />
            )}

            <Tooltip
              title="Categoria"
              description="Filtre os textos por categoria"
              placement="bottom-start"
            >
              <ButtonCategories onClick={handleOpenBoxUserCategories}>
                Categoria
              </ButtonCategories>
            </Tooltip>
          </Header>

          {/* render only for transform clause in image for text state */}
          {<QuillRenderImage />}

          {loading ? (
            <ContainerLoading>
              {/* <LoadingEditor /> */}
              <ContractAnimation />
            </ContainerLoading>
          ) : (
            <>
              {showFilter !== "" && !isFavoriteFilter && (
                <ContentFilter>
                  <div className="chip">
                    <div className="chip-content"> {showFilter} </div>
                    <div
                      className="chip-close"
                      onClick={() => {
                        setQuery((state) => {
                          return {
                            ...state,
                            search_key_word: "",
                          };
                        });
                        setFilteredFavorites(false);
                        setCurrentPage(0);
                      }}
                    >
                      <svg
                        className="chip-svg"
                        focusable="false"
                        viewBox="0 0 25 25"
                        aria-hidden="true"
                      >
                        <path d="M18.854 6.21134C18.7557 6.1129 18.639 6.0348 18.5106 5.98151C18.3821 5.92822 18.2444 5.90079 18.1053 5.90079C17.9663 5.90079 17.8286 5.92822 17.7001 5.98151C17.5716 6.0348 17.4549 6.1129 17.3567 6.21134L12.1641 11.3934L6.97142 6.20072C6.87311 6.10241 6.7564 6.02442 6.62795 5.97122C6.4995 5.91801 6.36182 5.89063 6.22279 5.89062C6.08375 5.89062 5.94608 5.91801 5.81763 5.97122C5.68918 6.02442 5.57247 6.10241 5.47416 6.20072C5.37584 6.29903 5.29786 6.41574 5.24465 6.54419C5.19145 6.67264 5.16406 6.81032 5.16406 6.94935C5.16406 7.08839 5.19145 7.22606 5.24465 7.35451C5.29786 7.48296 5.37584 7.59967 5.47416 7.69798L10.6668 12.8906L5.47416 18.0833C5.37584 18.1816 5.29786 18.2983 5.24465 18.4267C5.19145 18.5552 5.16406 18.6929 5.16406 18.8319C5.16406 18.9709 5.19145 19.1086 5.24465 19.2371C5.29786 19.3655 5.37584 19.4822 5.47416 19.5805C5.57247 19.6788 5.68918 19.7568 5.81763 19.81C5.94608 19.8632 6.08375 19.8906 6.22279 19.8906C6.36182 19.8906 6.4995 19.8632 6.62795 19.81C6.7564 19.7568 6.87311 19.6788 6.97142 19.5805L12.1641 14.3879L17.3567 19.5805C17.455 19.6788 17.5717 19.7568 17.7002 19.81C17.8286 19.8632 17.9663 19.8906 18.1053 19.8906C18.2444 19.8906 18.382 19.8632 18.5105 19.81C18.6389 19.7568 18.7557 19.6788 18.854 19.5805C18.9523 19.4822 19.0303 19.3655 19.0835 19.2371C19.1367 19.1086 19.1641 18.9709 19.1641 18.8319C19.1641 18.6929 19.1367 18.5552 19.0835 18.4267C19.0303 18.2983 18.9523 18.1816 18.854 18.0833L13.6613 12.8906L18.854 7.69798C19.2575 7.29447 19.2575 6.61486 18.854 6.21134Z"></path>
                      </svg>
                    </div>
                  </div>
                </ContentFilter>
              )}

              <List isFavoriteFilter={isFavoriteFilter} showFilter={showFilter}>
                {clausesData.length === 0 && (
                  <NotResult>
                    <Text>Nenhum texto foi encontrado.</Text>
                  </NotResult>
                )}

                {clausesData?.map((item, index) => (
                  <ListItem
                    key={item.id}
                    variants={list}
                    initial="hidden"
                    animate="visible"
                    as={motion.div}
                    className="mouse-grab"
                    onDragStart={(e) => {
                      let generateId = uuidv4();
                      handleGenerateClause(item.clause, e, index);
                      // dragUrl.current = `richTextClause#@@#${generateId}`;
                    }}
                  >
                    <HeaderClause>
                      <div>
                        <ButtonIconsWithTooltip
                          width="30px"
                          onClick={() => handleFavoriteClause(item.id, index)}
                          title="Favoritar"
                          description="Favorite esse texto"
                          placement="bottom-start"
                        >
                          <FavotiteIcon isActive={item.is_favorite} />
                        </ButtonIconsWithTooltip>
                        <p
                          onClick={() => {
                            handleShowClausule(item);
                          }}
                          id="rich-text-clause"
                          className="mouse-grab"
                        >
                          {item.title}
                        </p>
                      </div>
                      <div>
                        <ButtonIconsWithTooltip
                          onClick={() =>
                            handleOpenEditClauseModal(item.id, index)
                          }
                          width="30px"
                          title="Editar"
                          description="Edite esse texto"
                          placement="bottom-start"
                        >
                          <PersonalizedIcon
                            dPath={MountIcons.IconEdit.dPath}
                            viewBox={MountIcons.IconEdit.viewBox}
                            inactivatedColor="#343A40"
                          />
                        </ButtonIconsWithTooltip>

                        <ButtonIconsWithTooltip
                          width="30px"
                          onClick={() => {
                            setIsOpenModalDeleteClause(true);
                            setIdClause(item.id);
                          }}
                          draggable={false}
                          title="Excluir"
                          description="Exclua esse texto"
                          placement="bottom-start"
                        >
                          <PersonalizedIcon
                            dPath={MountIcons.IconTrash.dPath}
                            viewBox={MountIcons.IconTrash.viewBox}
                            inactivatedColor="#343A40"
                          />
                        </ButtonIconsWithTooltip>
                      </div>
                    </HeaderClause>

                    <Tooltip
                      title="Arraste para usar"
                      placement="bottom-start"
                      maxWidth={180}
                    >
                      <TextClause
                        onClick={() => {
                          handleShowClausule(item);
                        }}
                        draggable
                      >
                        {item.clause}
                      </TextClause>
                    </Tooltip>
                  </ListItem>
                ))}

                {existsNewPage && !filteredFavorites && (
                  <li
                    style={{
                      width: "100%",
                      minHeight: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    id="sentinela"
                  >
                    <MoonLoader size={18} color="#000" />
                  </li>
                )}
                <li
                  style={{
                    width: "100%",
                    minHeight: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></li>
              </List>
            </>
          )}
        </VStack>
      </Container>

      <FixedStack>
        <ButtonFooter
          className="button-clause-addtext"
          onClick={handleOpenNewClausesModal}
        >
          <PersonalizedIcon
            dPath={MountIcons.IconCrossAdd.dPath}
            viewBox={MountIcons.IconCrossAdd.viewBox}
            inactivatedColor={theme.colors.primary90}
          />
          Cadastrar novo texto
        </ButtonFooter>
      </FixedStack>
    </>
  );
};

export default UserClauses;

