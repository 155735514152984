import React, { HTMLProps, useEffect, useRef } from "react";

import { Container, FontType, MenuBoxFontFamily } from "./styles";
import iconArrow from "../../assets/arrow-down.svg";
import Tooltip from "../../../../../Tooltip";

interface FontFamilyMenuProps extends HTMLProps<HTMLDivElement> {
  isActive: boolean;
  onClose: () => void;
  fontFamily: string;
  changeFontFamily: (font: string) => void;
}

const FontFamilyMenu: React.FC<FontFamilyMenuProps> = ({
  isActive,
  onClose,
  fontFamily,
  changeFontFamily,
  ...rest
}) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isActive &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isActive, onClose]);

  let fontFamilyArr = [
    "Roboto",
    "Lato",
    "Montserrat",
    "Open Sans",
    "Verdana",
    "Calibri",
    "Arial",
    "Tahoma",
    "Segoe UI",
    "IBM Plex Serif",
    "Merriweather",
    "Source Serif Pro",
    "Roboto Serif",
    "Lora",
    "Bitter",
    "Faustina",
    "Source Sans Pro",
    "Raleway",
    "Nunito Sans",
    "Poppins",
    "Fira Sans",
    "Archivo",
    "Asap",
    "Times New Roman"
  ];

  return (
    <div ref={menuRef}>
      <Tooltip
        placement="bottom-start"
        description="Selecione uma fonte para o seu texto."
        title="Fonte"
      >
        <div style={{ cursor: "pointer" }} {...rest}>
          <Container isActive={isActive}>
            <span> {fontFamily} </span>
            <img src={iconArrow} alt="" />
          </Container>
        </div>
      </Tooltip>

      {isActive && (
        <MenuBoxFontFamily>
          {fontFamilyArr.map(font => (
            <div
              onClick={() => {
                changeFontFamily(font);
              }}
              key={font}
            >
              <FontType font={font}>{font}</FontType>
            </div>
          ))}
        </MenuBoxFontFamily>
      )}
    </div>
  );
};

export default FontFamilyMenu;
