import styled from "styled-components";

export const Subtitle = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 700;
`;

export const Label = styled.label`
  color: #676b70;
  font-weight: 400;
  font-size: 13px;
`;
export const RequirementLabel = styled.label`
  color: #343a40;
  font-weight: 400;
  font-size: 13px;
`;
export const SaveButton = styled.button`
  padding: 12px 16px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  cursor: pointer;
  width: 100%;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
  width: 100%;
  &:disabled {
    background-color: #ebebec;
    color: #676b70;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

export const CancelButton = styled.button`
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  min-width: 100px;
`;
export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
  background-color: white;
  padding: 10px;
`;

export const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid #ebebec;
`;

export const RequirementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const Requirement = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border-radius: 0 5px;
`;
export const RequirementHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
export const RequirementContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StatusLabel = styled.span<{ color: string }>`
  font-weight: 500;
  font-size: 14px;
  color: ${({ color }) => color};
`;

export const ReviewInput = styled.input`
  width: 100%;
  padding: 11px;
  border-radius: 5px;
  border: 1px solid #ebebec;
  height: 30px;

  &:focus {
    border: 2px solid #5d5fef;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  background-color: white;
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.8);
`;
