import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Comment from "../../../Comment";
import { useComments } from "../../../../../../hooks/comments";
import { useParams } from "react-router-dom";
import { LoadingBox } from "../ListAllCommentsPage/styled";
import { ContractAnimation } from "../../../../../../../../shared/components/ContractAnimation";
import { EmptyContent } from "../../../../../ModalSign/styles";
import { AxiosResponse } from "axios";
import api from "../../../../../../../../services/api";

// import { Container } from './styles';

interface CommentHistoryListProps {
  id: string;
  user_id: string;
  user_template_id: string;
  message: string;
  position: string;
  is_excluded: boolean;
  is_edited: boolean;
  is_done: boolean;
  page: string;
  created_at: string;
  updated_at: string;
  avatar: string;
  avatar_url: string;
  first_name: string;
  last_name: string;
  count_answers: number;
}

interface ResponseListAllCommentsProps {
  pagination: number;
  dataArray: CommentHistoryListProps[];
}

interface ListHistoryCommentsView {
  setCategoryComment: any;
}

const ListHistoryCommentsView: React.FC<ListHistoryCommentsView> = ({
  setCategoryComment
}) => {
  const [commentsHistoryData, setCommentsHistoryData] = useState<
    CommentHistoryListProps[]
  >([]);
  const { templateid } = useParams();
  const [isShowEmpty, setIsShowEmpty] = useState(
    "Não há comentários nesse documento para exibir."
  );
  const { setSelectedIdComment, setListCommentsCanvas } = useComments();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const responseListAllComments: AxiosResponse<ResponseListAllCommentsProps> =
          await api.get(`user-template-comments/list-all/${templateid}`, {
            params: {
              pagination: currentPage,
              is_excluded_or_done: true
            }
          });

        if (responseListAllComments.data.dataArray.length === 0) {
          setIsShowEmpty("Não há comentários nesse documento para exibir.");
        }

        setCommentsHistoryData(oldState => [
          ...oldState,
          ...responseListAllComments.data.dataArray
        ]);
        setTotalPages(responseListAllComments.data.pagination);
        setLoading(false);
      } catch (err) {
        console.error(err, "Error when get all comments");
      }
    })();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage < totalPages) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (!loading) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries?.some(entry => entry?.isIntersecting)) {
          if (hasMore) {
            setCurrentPage(oldState => oldState + 1);
          }
        }
      });
      const myDiv = document.querySelector("#sentinela");
      if (myDiv) {
        intersectionObserver?.observe(myDiv);
      }
      return () => intersectionObserver?.disconnect();
    }
  }, [loading, hasMore]);

  if (commentsHistoryData.length === 0 && !loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "30vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <EmptyContent>
          <span style={{ margin: "auto auto", fontSize: "15px" }}>
            {isShowEmpty}
          </span>
        </EmptyContent>
      </div>
    );
  }

  if (loading) {
    return (
      <LoadingBox>
        <ContractAnimation />
      </LoadingBox>
    );
  }

  return (
    <>
      {commentsHistoryData.map((comment, index) => {
        return (
          <Comment
            key={comment.id}
            categoryComment="Historic"
            message={comment.message}
            type="Comment To View"
            indexComment={index}
            idComment={comment.id}
            avatar={comment.avatar_url}
            amountReplies={comment.count_answers}
            commentDate={comment.created_at}
            numberPage={comment.page}
            name={`${comment.first_name} ${comment.last_name}`}
            isDone={comment.is_done}
            isShowReplies={true}
            onClick={() => {
              setSelectedIdComment(comment.id);
              setCategoryComment(prev => {
                return {
                  page: prev.page,
                  isOpenComment: true
                };
              });
            }}
          />
        );
      })}
      {hasMore && !loading && (
        <div
          id="sentinela"
          style={{
            display: "flex",
            width: "100%",
            height: "50px",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <ReactLoading type="spin" color="#000" height={30} width={30} />
        </div>
      )}
    </>
  );
};

export default ListHistoryCommentsView;
