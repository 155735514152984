import { useNavigate, useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

import {
  GridIcon,
  Container,
  IconItem,
  ButtonFile,
  Header,
  ContainerLogin
} from "./styles";

import { useTeams } from "../../../../../hooks/teams";
import { useCallback, useEffect, useRef, useState } from "react";
import api from "../../../../../services/api";

import Pagination from "../../../components/Pagination";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import Tooltip from "../../../../editor/components/Tooltip";
import ImportIcon from "../../../../../shared/assets/arrow_down_circle-icon.svg";
import SearchBar from "../../../components/SearchBar";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import { NoElements } from "../../components/TableTeams/styles";
import { useMainHook } from "../../../../../hooks/main";

interface IDataResponse {
  pagination: number;
  dataArray: Array<imageTeamPropsApi>;
}

interface imageTeamPropsApi {
  id: string;
  user_id: string;
  name: string;
  created_at: string;
  updated_at: string;
  image_url: string;
}

const UploadsTeamPage: React.FC = () => {
  const { isOwnerStaff } = useTeams();
  const { debouncedSearchTerm, loadingDebounce, setSearch } = useMainHook();

  //route properties
  const { team_id, number_page } = useParams();
  const navigator = useNavigate();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;

  const [images, setImages] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  //Debounce responsible for keeping the user completing the search word

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const fetchImages = await api.get(`team-images/${team_id}`, {
          params: {
            pagination: pageNumber,
            search: debouncedSearchTerm ? debouncedSearchTerm : null
          }
        });
        const data = fetchImages.data as IDataResponse;
        setImages(data.dataArray);
        setTotalPages(data.pagination);
        setLoading(false);

        if (pageNumber > 0 && data.dataArray.length === 0) {
          navigator(`/view-team/${team_id}/uploads/${pageNumber}`);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, [forceUpdate, pageNumber, team_id, debouncedSearchTerm]);

  //Fetch the image properties and then sending the image file
  const handleUploadImage = useCallback(async () => {
    const dataTypesImage = ["svg", "png", "jpeg", "jpg"];
    const file = inputRef.current?.files[0];
    const isTypeInclude = dataTypesImage.some(item => file.type.match(item));

    if (isTypeInclude) {
      try {
        const fetchCreateImage = await api.post("team-images", {
          team_id: team_id
        });

        const imageId = fetchCreateImage.data.id;
        let formData = new FormData();
        formData.append("image", file);
        await api.patch(`team-images/image-upload/${imageId}`, formData);
        setForceUpdate(!forceUpdate);
        toast.success("Imagem importada com sucesso!");
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Formato de imagem inválido!");
    }
  }, [team_id, forceUpdate]);

  const handleDeleteImage = useCallback(
    async (id: string) => {
      try {
        await api.delete(`team-images/${id}`);
        toast.success("Imagem excluida com sucesso!");
        setImages(oldState => {
          let cloneState = cloneDeep(oldState);
          const newState = cloneState.filter(clause => clause.id !== id);
          return newState;
        });

        //pagination check
        if (images.length === 1) {
          if (pageNumber > 0) {
            setSearch("");
          }
          setForceUpdate(!forceUpdate);
        }
      } catch (err) {
        console.error(err, "ERROR when remove image upload user");
      }
    },
    [forceUpdate, images, pageNumber]
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  return (
    <Container>
      <Header>
        {isOwnerStaff && (
          <ButtonFile>
            <label htmlFor="uploadImg">
              <img src={ImportIcon} alt="" />
              Fazer upload
            </label>
            <input
              ref={inputRef}
              type="file"
              id="uploadImg"
              name="uploadImg"
              onChange={handleUploadImage}
            />
          </ButtonFile>
        )}
        <SearchBar
          pageNumber={pageNumber}
          screen={`view-team/${team_id}/uploads`}
          description="Digite aqui para encontrar a imagem que procura."
        />
      </Header>

      {loading || loadingDebounce ? (
        <ContainerLogin>
          <ContractAnimation />
        </ContainerLogin>
      ) : (
        <GridIcon>
          {images.length > 0 ? (
            images.map(image => {
              return (
                <IconItem key={image.id}>
                  {isOwnerStaff && (
                    <Tooltip placement="bottom-start" title="Excluir">
                      <div onClick={() => handleDeleteImage(image.id)}>
                        <PersonalizedIcon
                          dPath={MountIcons.IconTrash.dPath}
                          viewBox={MountIcons.IconTrash.viewBox}
                          inactivatedColor="#343A40"
                        />
                      </div>
                    </Tooltip>
                  )}
                  <figure>
                    <img src={image.image_url} alt="Icon Upload" />
                  </figure>
                </IconItem>
              );
            })
          ) : (
            <NoElements>Nenhuma imagem encontrada.</NoElements>
          )}
        </GridIcon>
      )}

      <Pagination
        total={totalPages}
        pageNow={pageNumber}
        screen={`view-team/${team_id}/uploads`}
      />
    </Container>
  );
};

export default UploadsTeamPage;
