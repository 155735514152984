import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  height: 30px;
  border-radius: 5px;
  background: transparent;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;
