import { Container } from "./styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "../../../../hooks/theme";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import Tooltip from "../../../editor/components/Tooltip";
import MountIcons from "../../../../shared/utils/MountIcons";
import ButtonExit from "../../../../shared/components/ButtonExit";
import { useMainHook } from "../../../../hooks/main";
import { useNavigate } from "react-router-dom";

interface SearchProps {
  description: string;
}

const SearchBarEditor = ({ description }: SearchProps) => {
  const { theme } = useTheme();
  const { search, setSearch, setDebouncedSearchTerm, setLoadingDebounce } =
    useMainHook();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    const myFunc = (evt: KeyboardEvent) => {
      if (evt.key === "Enter" && isFocus) {
        buttonRef.current.click();
      }
    };

    window.addEventListener("keydown", myFunc);
    return () => window.removeEventListener("keydown", myFunc);
  }, [isFocus]);

  //Debounce responsible for keeping the user completing the search word
  useEffect(() => {
    setLoadingDebounce(true);
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setLoadingDebounce(false);
    }, 900);
    return () => {
      clearTimeout(delayDebounce);
    };
  }, [search]);

  //event listener for every time our user
  //do a new search on documents templates
  const handleSearchText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  //Reset states
  useEffect(() => {
    return () => {
      setSearch("");
      setDebouncedSearchTerm("");
    };
  }, []);

  return (
    <Container isFocus={isFocus}>
      <Tooltip
        title="Pesquisar"
        description={description}
        placement="bottom-start"
      >
        <div>
          <PersonalizedIcon
            isActive={isFocus}
            className="icon-search"
            width={24}
            height={24}
            dPath={MountIcons.IconSearch.dPath}
            viewBox={MountIcons.IconSearch.viewBox}
            activeColor={theme.colors.black100}
            inactivatedColor={theme.colors.black50}
          />
        </div>
      </Tooltip>
      <input
        type="text"
        onFocus={() => setIsFocus(true)}
        value={search}
        onChange={handleSearchText}
      />

      {isFocus && (
        <button>
          <ButtonExit
            inactivatedColor="#999C9F"
            onClick={() => {
              setSearch("");
              setIsFocus(false);
            }}
          />
        </button>
      )}
    </Container>
  );
};

export default SearchBarEditor;
