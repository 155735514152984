import { Container } from "./style";
import IconComment from "../../../../assets/comment-icon.svg";

const CommentsCreating = () => {
  return (
    <Container>
      <img src={IconComment} alt="" />
      <p>
        Dê feedback, faça uma pergunta ou apenas deixe uma nota de
        agradecimento. Clique em qualquer lugar no arquivo para deixar um
        comentário.
      </p>
    </Container>
  );
};

export default CommentsCreating;
