import styled from "styled-components";
export const LogoBox = styled.div`
  width: 71.889px;
  height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  img {
    width: auto;
    height: 100%;
  }
`;
