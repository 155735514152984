import { useCallback, useEffect, useState } from "react";
import { BoxInput, Container } from "./styles";
import api from "../../../../../../../services/api";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../../../../../../shared/components/SpinnerLoading";
import { useAuth } from "../../../../../../../hooks/auth";
import { useTwoFactorAuth } from "../../../../../../../hooks/twoFactorAuth";

export const NotificationsPage = () => {
  const { data } = useAuth();
  const { isUserValidateOTP } = useTwoFactorAuth();

  const [isEmailEnabled, setIsEmailEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    document: "",
    is_individual: true,
    email_sending_authorization: false,
  });

  //Fetching necessary user information for editing
  useEffect(() => {
    (async () => {
      try {
        const responseProfile = await api.get("profile");
        setUserData({
          first_name: responseProfile.data?.people.first_name,
          last_name: responseProfile.data?.people.last_name,
          email: responseProfile.data?.user.email,
          phone: responseProfile.data?.contacts.phone,
          document: responseProfile.data?.people.document,
          is_individual: true,
          email_sending_authorization:
            responseProfile.data?.email_sending_authorization,
        });
        setIsEmailEnabled(responseProfile.data?.email_sending_authorization);
        setIsLoading(false);
      } catch (err) {
        console.error("Error when fetching user data", err);
        setIsLoading(false);
      }
    })();
  }, []);

  const handleChangeNotificationEmail = useCallback(async () => {
    if (!isUserValidateOTP && data?.user?.otp_enabled) {
      toast.error("Autenticação não realizada!");
    } else {
      setIsLoading(true);
      try {
        await api.put("profile", {
          ...userData,
          email_sending_authorization: !isEmailEnabled,
        });
        setIsEmailEnabled(!isEmailEnabled);
        setIsLoading(false);

        toast.success("Preferências de e-mail editada com sucesso!");
      } catch (error) {
        setIsLoading(false);
      }
    }
  }, [userData, isEmailEnabled, data?.user?.otp_enabled, isUserValidateOTP]);

  return (
    <Container>
      <h4>Notificações</h4>
      <div>
        <h5>Preferências de e-mail:</h5>
        <BoxInput>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <input
              type="checkbox"
              checked={isEmailEnabled}
              onChange={handleChangeNotificationEmail}
            />
          )}
          Receber e-mails com novidades e marketing do UX DOC.
        </BoxInput>
      </div>
    </Container>
  );
};
