import React from "react";
import { Button } from "./styles";
import Tooltip from "../../../modules/editor/components/Tooltip";
import PersonalizedIcon from "../../assets/customIcons/PersonalizedIcon";
import MountIcons from "../../utils/MountIcons";

// import { Container } from './styles';
interface ButtonRulerProps {
  showRuler: boolean;
  handleShowRuler: () => void;
}

const ButtonRuler: React.FC<ButtonRulerProps> = ({
  showRuler,
  handleShowRuler,
}) => {
  return (
    <Tooltip
      title="Régua"
      description={
        showRuler
          ? "Desative a régua."
          : "Ative a régua para ajudar a alinhar os elementos do seu projeto."
      }
      placement="bottom-start"
      maxWidth={200}
    >
      <Button isActive={showRuler} type="button" onClick={handleShowRuler}>
        <PersonalizedIcon
          dPath={MountIcons.IconRuler.dPath}
          viewBox={MountIcons.IconRuler.viewBox}
          activeColor="#663399"
          inactivatedColor="#999C9F"
          isActive={showRuler}
          isFillRule
        />
      </Button>
    </Tooltip>
  );
};

export default ButtonRuler;
