import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 25px;
  height: 25px;
  fill: currentColor;
`;

type Props = {
  color: string;
};

const MyProjectsIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 25 25" color={color}>
    <path
      d="M22.25 5.92651V16.4265L16.25 22.4265H5.75C3.85 22.4265 2.25 20.8265 2.25 18.9265V5.92651C2.25 4.02651 3.85 2.42651 5.75 2.42651H18.75C20.65 2.42651 22.25 4.02651 22.25 5.92651ZM20.25 6.22651C20.25 5.22651 19.45 4.42651 18.45 4.42651H6.05C5.05 4.42651 4.25 5.22651 4.25 6.22651V18.7265C4.25 19.7265 5.05 20.5265 6.05 20.5265H15.25V19.0265C15.25 17.1265 16.85 15.5265 18.75 15.5265H20.25V6.22651Z"
      fill="currentColor"
    />
    <path
      d="M13.2583 13.4265C13.5255 13.4265 13.7818 13.5319 13.9708 13.7194C14.1598 13.9069 14.2659 14.1613 14.2659 14.4265C14.2659 14.6917 14.1598 14.9461 13.9708 15.1336C13.7818 15.3212 13.5255 15.4265 13.2583 15.4265H9.22748C8.96022 15.4265 8.70391 15.3212 8.51493 15.1336C8.32596 14.9461 8.21979 14.6917 8.21979 14.4265C8.21979 14.1613 8.32596 13.9069 8.51493 13.7194C8.70391 13.5319 8.96022 13.4265 9.22748 13.4265H13.2583ZM15.2736 9.42651C15.5305 9.4268 15.7775 9.52439 15.9643 9.69936C16.151 9.87433 16.2634 10.1135 16.2785 10.3679C16.2935 10.6224 16.2101 10.8729 16.0453 11.0683C15.8804 11.2638 15.6466 11.3894 15.3915 11.4195L15.2736 11.4265H9.22748C8.97064 11.4262 8.7236 11.3286 8.53684 11.1537C8.35008 10.9787 8.23769 10.7396 8.22264 10.4851C8.20759 10.2307 8.29101 9.98014 8.45585 9.78468C8.6207 9.58923 8.85454 9.46362 9.10958 9.43351L9.22748 9.42651H15.2736Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default MyProjectsIcon;
