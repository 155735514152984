import { LoadingFlex } from "../../../modules/user/unknown/login/pages/Login/styles";
import { Container } from "./styles";
import PuffLoader from "react-spinners/PuffLoader";

interface IInputProps {
  typeStyle: "purple" | "white";
  text: string;
  onClick?: () => any;
  type?: "button" | "submit";
  disabled?: boolean;
  loading?: boolean;
}

const ButtonMaxWidth = ({
  typeStyle,
  text,
  onClick = () => {},
  type = "button",
  disabled = false,
  loading = false
}: IInputProps) => {
  return (
    <Container
      onClick={() => onClick()}
      typeStyle={typeStyle}
      type={type}
      disabled={disabled}
    >
      {loading ? (
        <LoadingFlex>
          <PuffLoader
            color={typeStyle === "purple" ? "#FFF" : "#000"}
            size="28px"
          />
        </LoadingFlex>
      ) : (
        text
      )}
    </Container>
  );
};

export default ButtonMaxWidth;
