import styled from "styled-components";

export const Container = styled.div<{ isActive: boolean }>`
  height: 30px;
  width: 200px;

  cursor: pointer;

  border: solid 1px white;
  border-radius: 5px;
  padding-inline: 5px;

  display: flex;
  align-items: center;

  :hover {
    border: solid 1px
      ${({ theme, isActive }) =>
        isActive ? theme.colors.focus : theme.colors.blact100};
  }

  h5 {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    cursor: pointer;
  }

  input {
    height: 100%;
    width: 100%;
    border: none;

    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
  }
`;
