import { Modal } from "@material-ui/core";
import { SetStateAction } from "react";
import { QueryProps } from "../../../../editor/components/ClausesView/dtos";
import { ObjectNewClause } from "../../pages";
import ModalDeleteText from "../ModalDeleteText";
import ModalShowText from "../ModalShow";
import ModalText from "../ModalText";
import { UseMutateFunction } from "react-query";
import { MyClausesDataProps } from "../../../../../dtos/Clauses";

interface IModalControllerProps {
  modalIsOpen: boolean;
  activeModal:
    | "showClausules"
    | "createClausules"
    | "editClausules"
    | "deleteClausules";
  objectClause: ObjectNewClause;
  onChangeDynamicState: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: string,
    setState: React.Dispatch<SetStateAction<ObjectNewClause | string>>
  ) => void;
  setObjectClause: React.Dispatch<React.SetStateAction<ObjectNewClause>>;
  handleNewClause: UseMutateFunction<
    MyClausesDataProps,
    unknown,
    void,
    unknown
  >;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveModal: React.Dispatch<
    React.SetStateAction<
      "showClausules" | "createClausules" | "editClausules" | "deleteClausules"
    >
  >;
  handleEditingClauseSpecific: UseMutateFunction<
    MyClausesDataProps,
    unknown,
    void,
    unknown
  >;
  handleRemoveClause: UseMutateFunction<void, unknown, string, unknown>;
}

const ModalController = ({
  modalIsOpen,
  activeModal,
  objectClause,
  onChangeDynamicState,
  setObjectClause,
  handleNewClause,
  setIsModalOpen,
  setActiveModal,
  handleEditingClauseSpecific,
  handleRemoveClause
}: IModalControllerProps) => {
  return (
    <Modal
      open={modalIsOpen}
      children={
        <div>
          {activeModal === "createClausules" && (
            <ModalText
              objectClause={objectClause}
              onChangeDynamicState={onChangeDynamicState}
              setObjectClause={setObjectClause}
              handleClause={handleNewClause}
              setIsModalOpen={setIsModalOpen}
              type="create"
            />
          )}

          {activeModal === "showClausules" && (
            <ModalShowText
              activeClausule={objectClause}
              setActiveModal={setActiveModal}
              setIsModalOpen={setIsModalOpen}
              setObjectClause={setObjectClause}
            />
          )}

          {activeModal === "editClausules" && (
            <ModalText
              objectClause={objectClause}
              onChangeDynamicState={onChangeDynamicState}
              setObjectClause={setObjectClause}
              handleClause={handleEditingClauseSpecific}
              setIsModalOpen={setIsModalOpen}
              type="edit"
            />
          )}

          {activeModal === "deleteClausules" && (
            <ModalDeleteText
              handleRemoveClause={handleRemoveClause}
              setObjectClause={setObjectClause}
              setIsModalOpen={setIsModalOpen}
              id={objectClause.user_clause_id}
            />
          )}
        </div>
      }
    />
  );
};

export default ModalController;
