import styled from "styled-components";

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: red;
  position: absolute;
  top: -1000px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: -1;
`;
