import React, { InputHTMLAttributes, useEffect, useRef } from "react";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import LoadingEditor from "../../../LoadingEditor";
import { ButtonIcon, Container, ContainerIcons, Label } from "./styles";
import Tooltip from "../../../Tooltip";
import IconDowload from "../../../../assets/icon-download_black.svg";
import MountIcons from "../../../../../../shared/utils/MountIcons";

interface AutomatizeItemProps extends InputHTMLAttributes<HTMLDivElement> {
  downloading: boolean;
  document_name: string;
  document_id: string;
  currentSelectedDocument: string;
  handlePreviewDocument: (document_id: string) => void;
  handleEditDocument: (document_id: string) => void;
  handleDownloadAutomatizedDocument: (
    document_id: string,
    document_name: string
  ) => void;
  handleDeleteAutomatizedDocument: (document_id: string) => void;
  loading:
    | false
    | "download"
    | "variables"
    | "automatize"
    | "automatizing"
    | "saving"
    | "deleting"
    | "preview"
    | "pages";
  setLoading: React.Dispatch<
    React.SetStateAction<
      | false
      | "download"
      | "variables"
      | "automatize"
      | "automatizing"
      | "saving"
      | "deleting"
      | "preview"
      | "pages"
    >
  >;
}

const AutomatizedItem: React.FC<AutomatizeItemProps> = ({
  document_name,
  document_id,
  handleDeleteAutomatizedDocument,
  handleEditDocument,
  handleDownloadAutomatizedDocument,
  handlePreviewDocument,
  loading,
  setLoading,
  currentSelectedDocument,
  downloading
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      {/*PREVIEW PROJECT BUTTON */}
      <Tooltip title="Clique para visualizar" placement="bottom-start">
        <ButtonIcon
          onClick={() => {
            setLoading("preview");
            handlePreviewDocument(document_id);
          }}
          style={{
            display: "flex",
            width: "auto",
            justifyContent: "left",
            height: "80%"
          }}
        >
          <Label style={{ cursor: "pointer" }}>
            {loading === "preview" &&
            currentSelectedDocument === document_id ? (
              <LoadingEditor color="#000" width={20} height={20} />
            ) : (
              document_name
            )}
          </Label>
        </ButtonIcon>
      </Tooltip>
      <ContainerIcons>
        {/*DOWNLOAD BUTTON */}
        <Tooltip title="Baixar em pdf" placement="bottom-start">
          <ButtonIcon
            onClick={() => {
              handleDownloadAutomatizedDocument(document_id, document_name);
            }}
          >
            {downloading && currentSelectedDocument === document_id ? (
              <LoadingEditor color="#000" width={30} height={30} />
            ) : (
              <img src={IconDowload} alt="" />
            )}
          </ButtonIcon>
        </Tooltip>

        {/*EDIT BUTTON */}
        <Tooltip title="Editar" placement="bottom-start">
          <ButtonIcon
            type="button"
            onClick={event => {
              event.preventDefault();
              handleEditDocument(document_id);
            }}
          >
            <PersonalizedIcon
              className="icon"
              dPath={MountIcons.IconEdit.dPath}
              viewBox={MountIcons.IconEdit.viewBox}
              inactivatedColor="#343A40"
            />
          </ButtonIcon>
        </Tooltip>

        {/*DELETE BUTTON */}
        <Tooltip title="Excluir" placement="bottom-end">
          <ButtonIcon
            onClick={() => {
              handleDeleteAutomatizedDocument(document_id);
            }}
          >
            <PersonalizedIcon
              className="icon"
              dPath={MountIcons.IconTrash.dPath}
              viewBox={MountIcons.IconTrash.viewBox}
              inactivatedColor="#343A40"
            />
          </ButtonIcon>
        </Tooltip>
      </ContainerIcons>
    </Container>
  );
};

export default AutomatizedItem;
