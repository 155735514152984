import { animate, useMotionValue, motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import useComponentVisible from "../../../hooks/useComponentVisible";
import ButtonIcons from "../../../../../shared/components/ButtonIcons";
import { SketchPicker } from "react-color";
import IconSvg from "../../IconSvg";
import {
  ActiveColor,
  BoxColor,
  BoxColorInput,
  Container,
  ContainerInputColor,
  DropDownContainer,
  DropDownList,
  DropDownListContainer,
  Flex,
  Grid,
  GridHistoric,
  GridItem,
  HistoricColors,
  HorizontalStack,
  Span,
} from "./styles";

import iconButtonInputColors from "../../../assets/icon-button-colors.svg";

import { useToolbarEditor } from "../../../hooks/toolbarEditor";
import { useSelection } from "../../../hooks/selection";
import iconTransparentColor from "../../../assets/icon-transparent-color.svg";
import { cloneDeep } from "lodash";
import DropDownHeader from "./components/DropDownHeader/DropDownHeader";
import { useHeaderEditor } from "../../../hooks/headerEditor";

interface ButtonColorProps {
  newColor: string;
  colorsDefault?: string[];
  colors?: [];
  colorsSelectVector?: string[];
  addNewColor?: any;
  handleColorPickerClick?: () => void;
  handleCloseColorPickerClick?: () => void;
  isDisplayColorPicker?: boolean;
  setIsDisplayColorPicker?: any;
  handleChange?: any;
  handleInputChange?: any;
  randomColor?: boolean;
  setSelectedOption?: any;
  selectedOption?: any;
  isFavoriteColor?: any;
  blockKeyboard?: boolean;
  setBlockKeyboard?: any;
  style?: React.CSSProperties;
  leftPosition?: number;
  rightPosition?: number;
  elementForShow?: string;
  topPosition?: number;
  positionLeftPopover?: number;
  positionTopPopover?: number;
  page?: "oneclick" | "editor";
}

export const ButtonColor: React.FC<ButtonColorProps> = ({
  colorsDefault,
  newColor,
  colors,
  addNewColor,
  handleColorPickerClick,
  handleCloseColorPickerClick,
  isDisplayColorPicker,
  setIsDisplayColorPicker,
  handleChange,
  handleInputChange,
  randomColor,
  isFavoriteColor,
  colorsSelectVector,
  setSelectedOption,
  selectedOption,
  blockKeyboard,
  setBlockKeyboard,
  leftPosition,
  rightPosition,
  topPosition,
  elementForShow,
  positionLeftPopover = 222,
  positionTopPopover = 144,
  page = "editor",
  ...rest
}: any) => {
  const list = {
    visible: {
      opacity: 1,
      y: 5,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
    hidden: {
      opacity: 0,
      y: -20,
      transition: {
        when: "afterChildren",
      },
    },
  };

  const {
    selectedObject,
    setForceBlockKey,
  } = useSelection();
  const { organizeHistoryColors, colorsHistory, delayOrganizeHistoryColors } = useHeaderEditor();
  const { setColorToElement } = useToolbarEditor();

  const variants = {
    visible: (i) => ({
      opacity: 1,
      transition: {
        delay: i * 0.3,
      },
    }),
    hidden: { opacity: 0 },
  };

  const x = useMotionValue(0);

  useEffect(() => {
    const controls = animate(x, 100, {
      type: "spring",
      stiffness: 2000,
    });

    return controls.stop;
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [colorSelect, setColorSelect] = useState(newColor);
  const [indexActiveColor, setIndexActiveColor] = useState<null | number>(null);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const [isTransparent, setIsTransparent] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleColorPickClick = useCallback(() => {
    setIsDisplayColorPicker(!isDisplayColorPicker);
  }, [isDisplayColorPicker]);

  const handleSetColor = useCallback(
    (color: string) => {
      if (color == "transparent") {
        setIndexActiveColor(null);
        setIsTransparent(true);
      }
      organizeHistoryColors(color);
      setColorToElement(color);
      setColorSelect(color);
      addNewColor(color);
      setIsDisplayColorPicker(false);
      setIsTransparent(false);
    },
    [setColorToElement, addNewColor, setIsDisplayColorPicker]
  );

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpen(false);
      setIsComponentVisible(true);
      setIsDisplayColorPicker(false);
    } else {
      return;
    }
  }, [isComponentVisible]);

  useEffect(() => {
    if (isOpen) {
      setForceBlockKey(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setForceBlockKey(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedObject) {
      let whichColor = selectedObject?.attrs?.fill;
      let newIndex = colorsDefault.findIndex((color) => color === whichColor);
      setIndexActiveColor(newIndex);
    }
  }, [isOpen, selectedObject]);

  useEffect(() => {
    if (selectedObject && isOpen) {
      setIsOpen(false);
      setIndexActiveColor(null);
    }
  }, [selectedObject]);

  return (
    <Container>
      <DropDownContainer ref={ref}>
        <DropDownHeader
          isOpen={isOpen}
          onClick={handleClick}
          refVisible={ref}
          {...rest}
          newColor={colorSelect}
          elementForShow={elementForShow}
          page={page}
        />
        {isOpen && isComponentVisible && (
          <DropDownListContainer>
            <DropDownList
              variants={list}
              initial="hidden"
              animate="visible"
              as={motion.ul}
              style={{
                right: rightPosition ?? "unset",
                left: leftPosition ?? "unset",
                top: topPosition ?? "unset",
              }}
            >
              <Flex>
                <Grid>
                  {colorsDefault.map((color: string, index: number) => (
                    <GridItem key={Math.random()}>
                      <div className="wrapper-border">
                        <BoxColor
                          onClick={(e) => {
                            handleSetColor(color);
                            setIndexActiveColor((state) => {
                              let cloneState = cloneDeep(state);
                              cloneState = index;
                              return cloneState;
                            });
                          }}
                          value={color}
                          color={color}
                        />
                        {indexActiveColor === index && <ActiveColor />}
                      </div>
                    </GridItem>
                  ))}
                  <button>
                    <div className="wrapper-border">
                      <img
                        src={iconTransparentColor}
                        onClick={(e) => {
                          handleSetColor("transparent");
                          setIsTransparent((prevState) => !prevState);
                        }}
                        color={"transparent"}
                        alt=""
                        className="button-transparent"
                      />
                      {isTransparent && <ActiveColor />}
                    </div>
                  </button>
                </Grid>
                <HorizontalStack>
                  <BoxColor value={colorSelect} color={colorSelect} />
                  <ContainerInputColor onSubmit={handleInputChange}>
                    <input
                      type="text"
                      name="color"
                      id="my-colors"
                      value={colorSelect}
                      onChange={(e) => handleSetColor(e.target.value)}
                    />
                  </ContainerInputColor>

                  <BoxColorInput
                    randomColor={randomColor}
                    color={colorSelect}
                    positionLeftPopover={positionLeftPopover}
                    positionTopPopover={positionTopPopover}
                    page={page}
                  >
                    <button
                      onClick={handleColorPickerClick}
                      className="btn-get-colors"
                    >
                      {isDisplayColorPicker && isComponentVisible && (
                        <div
                          className="popover-color"
                          onClick={handleColorPickClick}
                        >
                          <div className="cover-color">
                            <SketchPicker
                              color={colorSelect}
                              onChange={(color) => {
                                // delayOrganizeHistoryColors(color.hex);
                                setColorSelect(color.hex);
                                if (page === "editor") {
                                  selectedObject.setAttr("fill", color.hex);
                                }
                              }}
                              onChangeComplete={(color) => delayOrganizeHistoryColors(color.hex)}
                            />
                          </div>
                        </div>
                      )}
                    </button>
                  </BoxColorInput>
                </HorizontalStack>
                <HistoricColors>
                  <Span>{colorsHistory.length > 0 ? 'Cores recentes' : 'Não há cores recentes'}</Span>
                  <GridHistoric>
                    {colorsHistory.map((color) => (
                      <GridItem>
                        <div className="wrapper-border">
                          <BoxColor
                            onClick={(e) => {
                              handleSetColor(color.hexadecimal);
                            }}
                            value={color.hexadecimal}
                            color={color.hexadecimal}
                          />
                        </div>
                      </GridItem>
                    ))}
                  </GridHistoric>
                </HistoricColors>
              </Flex>
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Container>
  );
};