import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import api from "../../../services/api";
import { AxiosResponse } from "axios";
import { cloneDeep } from "lodash";
import { useHeaderEditor } from "./headerEditor";
import { toast } from "react-toastify";
import { useMainHook } from "../../../hooks/main";

interface CommentsProviderProps {
  children: ReactNode;
}

interface CommentsScreenProps {
  active: boolean;
  screen: "creating" | "listCommentSpecific" | "listAllComments" | "none";
}

interface CommentsHookData {
  isCommentsVisible: CommentsScreenProps;
  setIsCommentsVisible: React.Dispatch<
    React.SetStateAction<CommentsScreenProps>
  >;
  listCommentsCanvas: CommentsCanvasProps[];
  setListCommentsCanvas: React.Dispatch<
    React.SetStateAction<CommentsCanvasProps[]>
  >;
  commentValue: string;
  setCommentValue: React.Dispatch<React.SetStateAction<string>>;
  handleMakeComment: () => void;
  getCommentsInDoc: (idDoc: string) => Promise<void>;
  selectedSpecificComment: React.MutableRefObject<string>;
  handleClickCommentCanvas: (id: string) => void;
  selectedIdComment: string;
  setSelectedIdComment: React.Dispatch<React.SetStateAction<string>>;
}

interface ResponseListSimplifiedComemnt {
  id: string;
  position: string;
  page: string;
  avatar: string;
  first_name: string;
  last_name: string;
  avatar_url: string;
}

interface CommentsCanvasProps {
  id: string;
  page: string;
  avatar: string;
  isEditing: boolean;
  firstName: string;
  lastName: string;
  position: {
    x: number;
    y: number;
  };
  document?: string;
}

const CommentsContext = createContext<CommentsHookData>({} as CommentsHookData);

const CommentsHookProvider: React.FC<CommentsProviderProps> = ({
  children
}) => {
  const [listCommentsCanvas, setListCommentsCanvas] = useState<
    CommentsCanvasProps[]
  >([]);
  const selectedSpecificComment = useRef("");
  const { setLastSaveDate } = useMainHook();
  const { isSavingAutomatic, loading, setLoading } = useHeaderEditor();
  const [selectedIdComment, setSelectedIdComment] = useState("");
  const [commentValue, setCommentValue] = useState("");

  const handleDoneCommentarySpecific = useCallback(
    (id: string, action: "done" | "edit" | "remove") => {},
    []
  );

  const getCommentsInDoc = useCallback(
    async (idDoc: string) => {
      try {
        const responseDocs: AxiosResponse<ResponseListSimplifiedComemnt[]> =
          await api.get(`user-template-comments/list-all-simplified/${idDoc}`);
        const commentsOfDoc = responseDocs.data;
        setListCommentsCanvas(oldState => {
          let listOfComments = [];
          // let cloneState = cloneDeep(oldState);
          commentsOfDoc.forEach(comment => {
            let [posX, posY] = comment.position.split("@##@");
            listOfComments.push({
              id: comment.id,
              page: comment.page,
              avatar: comment.avatar_url,
              isEditing: false,
              firstName: comment.first_name,
              lastName: comment.last_name,
              position: {
                x: Number(posX),
                y: Number(posY)
              },
              document: ""
            });
          });

          return listOfComments;
        });
      } catch (err) {
        console.error(err, "error when response comments in doc");
      }
    },
    [setListCommentsCanvas]
  );

  const [isCommentsVisible, setIsCommentsVisible] =
    useState<CommentsScreenProps>({
      active: false,
      screen: "none"
    });

  const handleMakeComment = useCallback(async () => {
    try {
      setLoading(true);
      const isEditingComment = listCommentsCanvas.filter(
        comment => comment.isEditing === true
      );
      let idNewComment = isEditingComment[0].id;
      let object = {
        user_template_id: isEditingComment[0].document,
        message: commentValue,
        position: `${isEditingComment[0].position.x}@##@${isEditingComment[0].position.y}`,
        page: isEditingComment[0].page
      };

      const responseMakeComment = await api.post(
        "user-template-comments",
        object
      );

      setListCommentsCanvas(oldState => {
        let cloneState = cloneDeep(oldState);
        cloneState.forEach(comment => {
          if (comment.id === idNewComment) {
            comment.id = responseMakeComment.data.id;
            comment.isEditing = false;
          }
        });

        return cloneState;
      });
      selectedSpecificComment.current = responseMakeComment.data.id;
      setSelectedIdComment(responseMakeComment.data.id);
      setIsCommentsVisible({
        active: true,
        screen: "listCommentSpecific"
      });
      setCommentValue("");
      toast.success("Novo comentario adicionado ao documento.");
      setLoading(false);
      const myNewDate = new Date();
      setLastSaveDate(String(myNewDate));
    } catch (err) {
      setLoading(false);
      console.error(err, "error when send comment to api");
    }
  }, [
    commentValue,
    listCommentsCanvas,
    setIsCommentsVisible,
    selectedSpecificComment,
    setSelectedIdComment,
    setLastSaveDate
  ]);

  const handleClickCommentCanvas = useCallback(
    (id: string) => {
      selectedSpecificComment.current = id;
      setSelectedIdComment(id);
      setIsCommentsVisible({
        active: true,
        screen: "listCommentSpecific"
      });
    },
    [selectedSpecificComment]
  );

  useEffect(() => {
    if (isSavingAutomatic) {
      setIsCommentsVisible({ active: false, screen: "none" });
    }
  }, [isSavingAutomatic]);

  useEffect(() => {
    if (isCommentsVisible.screen === "none") {
      setListCommentsCanvas([]);
    }

    if (isCommentsVisible.screen === "creating") {
      setSelectedIdComment(null);
    }
  }, [isCommentsVisible]);

  return (
    <CommentsContext.Provider
      value={{
        isCommentsVisible,
        setIsCommentsVisible,
        listCommentsCanvas,
        setListCommentsCanvas,
        commentValue,
        setCommentValue,
        handleMakeComment,
        getCommentsInDoc,
        selectedSpecificComment,
        handleClickCommentCanvas,
        selectedIdComment,
        setSelectedIdComment
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
};

function useComments(): CommentsHookData {
  const context = useContext(CommentsContext);

  if (!context) {
    throw new Error("useComments must be used with an CommentsHookProvider");
  }

  return context;
}

export { CommentsHookProvider, useComments };
