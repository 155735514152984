import styled from "styled-components";

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 103px;
    height: calc(100% - 30px);
    width: 4px;
    background: #ebebec;
  }
`;

export const EventContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  margin: 10px 0;
  position: relative;
`;

export const EventStatus = styled.div<{ showShadow: boolean }>`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d6d8d9;
  left: 90px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  top: -5px;
  box-shadow: ${({ showShadow }) =>
    showShadow ? "0px 0px 10px 0px #00000040" : "none"};
`;

export const DateContainer = styled.div`
  min-width: 80px;
`;

export const EventTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
`;
export const EventSubtitle = styled.div`
  font-size: 12px;
`;
export const EventText = styled.div`
  font-size: 14px;
`;
export const EventDate = styled.div`
  font-size: 13px;
  line-height: 1.3;
`;

export const EventActionBottom = styled.div`
  font-size: 13px;
  color: #0099ff;
  cursor: pointer;
  font-style: italic;
`;

export const EventAction = styled.div`
  margin-left: auto;
  color: #999c9f;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
`;

export const Line = styled.div`
  height: 2px;
  background-color: black;
  margin: 0 10px;
`;

export const DownloadButton = styled.div`
  padding: 8px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  cursor: pointer;
  width: 150px;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
  position: absolute;
  right: 0;
`;
