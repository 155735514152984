import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  margin-bottom: 4.5rem;
`;

export const Box = styled.div`
  width: 100%;
  min-height: 40px;
  padding-inline: 10px 0px;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #d6d8d9;
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  color: #676b70;
  user-select: none;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Text = styled.span`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.gray300};
  text-align: center;
  line-height: 2.7rem;
  margin-top: 6rem;

  @media (max-width: 1600px) {
    font-size: 1.7rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.6rem;
    line-height: 2.2rem;
    padding: 0 1rem;
  }
`;

export const BoxClauseTeam = styled.div`
  width: 100%;
  border: 1px solid #d6d8d9;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  gap: 10px;

  user-select: none;
  cursor: grab;
`;

export const TeamName = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;

  color: #676b70;
`;

export const ScrollDisplay = styled.div`
  width: 100%;
  height: calc(100ch - 170px);
  padding-inline: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Span = styled.span`
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  margin: 2rem auto 0 auto;
  display: flex;

  @media (max-width: 1366px) {
    font-size: 1.3rem;
    margin: 1rem auto 0 auto;
  }
`;

export const BackToTeamsList = styled.div<{ whichFilter: string }>`
  min-height: 40px;
  width: 100%;
  display: flex;
  padding-inline: 10px;

  /* margin-top: ${({ whichFilter }) =>
    whichFilter === "" ? "2rem" : "2rem"}; */
  align-items: center;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    color: #343a40;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;

  max-width: 42ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ClauseSpecificTeam = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  white-space: normal;

  min-height: 41px;
  max-height: 62px;

  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: justify;
`;
