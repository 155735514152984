import React from "react";

interface BaseIconProps {
  isActive?: boolean;
  className?: string;
}

export default function TakeBackIcon({ isActive, className }: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M18.2282 22.0004H10.6848C8.61036 22.0004 6.91309 20.3032 6.91309 18.2287V10.6853C6.91309 8.61085 8.61036 6.91357 10.6848 6.91357H18.2282C20.3027 6.91357 21.9999 8.61085 21.9999 10.6853V18.2287C21.9999 20.3032 20.3027 22.0004 18.2282 22.0004ZM10.6848 8.17081C9.30184 8.17081 8.17032 9.30233 8.17032 10.6853V18.2287C8.17032 19.6117 9.30184 20.7432 10.6848 20.7432H18.2282C19.6112 20.7432 20.7427 19.6117 20.7427 18.2287V10.6853C20.7427 9.30233 19.6112 8.17081 18.2282 8.17081H10.6848Z"
        fill="#343A40"
        stroke="#343A40"
      />
      <path
        d="M16.3954 4.76592C16.0811 3.19438 14.6352 2 13.0008 2H5.4574C3.57155 2 2 3.57155 2 5.4574V13.0008C2 14.6352 3.13151 16.0811 4.76592 16.3954C5.01737 16.4582 5.20596 16.4582 5.4574 16.4582H5.77171V10.1092C5.77171 8.41191 6.90323 5.83458 10.1092 5.83458C13.6295 5.83458 16.1439 5.83458 16.1439 5.83458H16.4582V5.4574C16.4582 5.26882 16.4582 5.01737 16.3954 4.76592Z"
        fill="#343A40"
      />
    </svg>
  );
}
