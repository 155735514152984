import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

interface BoxColorProps {
  color: string;
}

interface ProfileUserProps {
  isAnnonymous?: boolean;
}

const getColorByIndex = (index: number) => {
  const colors = ["#24958E", "#F90", "#06C", "#663399"];
  const colorIndex = (index - 1) % colors.length;
  return colors[colorIndex];
};

export const ContainerModal = styled.form`
  width: 700px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  padding-block: 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  position: relative;

  .btn-exit {
    position: absolute;
    top: 13px;
    right: 13px;
  }
`;

export const Trash = styled.div`
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-inline: 20px;

  border-bottom: 1px solid #ebebec;
  padding-bottom: 10px;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  min-height: 73px;
  border-radius: 10px;
  align-items: center;
  border: solid 1px red;
  padding: 0 1.6rem;
  line-height: 190%;
  justify-content: center;
`;

export const Text = styled.span`
  color: black;
  font-size: 1.4rem;
  text-align: center;
  max-width: 783px;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  background: red;
  width: 100%;
  justify-content: space-between;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 227px;
  padding: 1rem 2rem;
  border-radius: 20px;

  &::placeholder {
    font-size: 1.6rem;
    font-weight: 400;
    color: ${({ theme }: Theme) => theme.colors.gray900};
  }
`;

export const HStack = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;

export const HStackBtn = styled.div`
  display: flex;
  width: 160px;
  justify-content: center;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const Button = styled.button`
  width: 120px;
  height: 40px;
  background: ${({ theme }) => theme.colors.background};
  color: white;
  border-radius: 5px;

  transition: background 200ms linear;

  &:hover {
    background: black;
    color: white;
  }
`;

export const FooterWraper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  margin-inline: 20px;
`;

export const Left = styled.div`
  width: 24px;
  height: 24px;
`;

export const Right = styled.div`
  height: 100%;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const InputHex = styled.input`
  width: 100px;
  height: 30px;
  padding: 0 0.5rem;

  &::placeholder {
    color: #c4c4c4;
    opacity: 0.6;
  }
`;

export const BoxColor = styled.div<BoxColorProps>`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${({ theme, color }) =>
    color == ""
      ? "conic-gradient(red, yellow, lime, aqua, blue, magenta, red)"
      : "red"};
  cursor: not-allowed;
  border: 1px solid black;
`;

export const ButtonBack = styled.button`
  width: 120px;
  height: 40px;
  background: white;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
`;

export const Title = styled.span`
  font-weight: 400;
  font-size: 1.6rem;
  color: #343a40;

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const Line = styled.div`
  background-color: #ebebec;
  width: 100%;
  height: 1px;
`;

export const ControlEditionsButton = styled.div`
  min-height: 30px;
  width: 400px;
  display: flex;
  margin-bottom: 1.3rem;
  align-items: center;

  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-right: 1.5rem;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* gray-dark */

    color: #343a40;
  }
`;

export const HorizontalStack = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  gap: 10px;
  padding-inline: 20px;
`;

export const InputBase = styled.input`
  width: 526px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #999c9f;
  margin-top: 5px;
  padding-left: 10px;

  :hover {
    border: 1px solid #000;
  }
`;

export const FlexInput = styled.div`
  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
`;

export const Table = styled.div`
  border-radius: 5px 5px 0px 0px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  margin-inline: 20px;

  & > div {
    height: 30px;
    border-radius: 5px 5px 0px 0px;
    background-color: ${({ theme }) => theme.colors.black10};

    display: flex;
    align-items: center;

    display: grid;
    grid-template-columns: 4fr 4fr 2.7fr 0.5fr;

    font-family: "Ubuntu";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};

    p:first-child {
      padding-left: 54px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    min-height: 44px;
    max-height: 132px;

    overflow-y: auto;
  }

  .containerLoading {
    width: 100%;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Footer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 30px;
  padding-inline: 20px;
`;

export const ItemUserInfo = styled.li`
  display: grid;
  grid-template-columns: 4fr 4fr 2.7fr 0.5fr;
  align-items: center;
  min-height: 44px;

  span {
    color: #a5a5a5;
  }

  .email {
    color: #999c9f;

    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const FirstAreaItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;

  p {
    max-width: 23ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const AvatarCustom = styled.div<{ indexOfUser: number }>`
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  user-select: none;
  background-color: ${({ indexOfUser }) => getColorByIndex(indexOfUser)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;

  &:nth-child(1) {
    transform: translateX(0);
  }

  &:nth-child(2) {
    transform: translateX(-5px);
  }

  &:nth-child(3) {
    transform: translateX(-10px);
  }

  span {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const ButtonShared = styled.button`
  height: 40px;
  width: 124px;
  border-radius: 5px;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary90};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  transition: background 120ms linear;

  :hover {
    background: ${({ theme }) => theme.colors.primary};
  }

  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const VerticalStack = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 1rem;
  flex-direction: column;
  /* padding-bottom: 2rem; */

  .containerLoading {
    width: 100%;
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem auto;
  }
`;

export const WrapperProfileShared = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 49px;
  margin: 0.5rem 0rem;
  justify-content: space-between;

  .removeItem {
    width: 16px;
    height: 16px;
    transform: translateY(3px);
  }

  @media (max-width: 1600px) {
    .removeItem {
      width: 14px;
      height: 14px;
      transform: translateY(1px);
    }
  }

  @media (max-width: 1366px) {
    margin: 0.1rem 0rem;

    .removeItem {
      width: 12px;
      height: 12px;
      transform: translateY(1px);
    }
  }
`;

export const ButtonDeleteUserShared = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const AvatarContainer = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const NameContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
`;

export const ProfileUser = styled.span<ProfileUserProps>`
  font-size: 1.4rem;
  color: #343a40;
  align-items: center;
  transform: translateX(-10px) translateY(1px);
  opacity: ${({ isAnnonymous }) => isAnnonymous && "0.2"};

  @media (max-width: 1366px) {
    font-size: 1.2rem;
    margin-left: 0rem;
    transform: translateX(0px) translateY(0px);
  }
`;
