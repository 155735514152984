import styled from "styled-components";

export const Subtitle = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 400;
`;
export const Input = styled.input`
  padding: 11px 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray600};
`;
export const Label = styled.label`
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 400;
  font-size: 13px;
`;

export const SaveButton = styled.button`
  padding: 12px 16px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  cursor: pointer;
  width: 100%;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
`;

export const CancelButton = styled.button`
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  padding: 0 24px 24px 24px;
  min-width: 700px;
`;
export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
