import { cloneDeep } from "lodash";
import {
  Container,
  ImageTemplate,
  Label1,
  SelectContainer,
  Template
} from "./styles";
import { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import PlainText from "../../../../../shared/components/PlainText";
import api from "../../../../../services/api";
import { CreateProjectContext } from "../CreateNewProject";
import { Box } from "@material-ui/core";
import SelectItem from "../SelectItem";
import { ApprovalFlowRequest } from "../../types/ApprovalFlowRequest";

interface MyTemplatesProps {
  id: string;
  user_id: string;
  title: string;
  description: string;
  thumbnail: string;
  is_automation: boolean;
  automation_user_template_id: any;
  created_at: string;
  updated_at: string;
  thumbnail_url: string;
  is_checked?: boolean;
}

type Props = {
  flows: ApprovalFlowRequest[];
  handleSelectFlow: (selected: any) => void;
  resend: boolean;
};

const TemplatesSelect = ({ flows, handleSelectFlow, resend }: Props) => {
  const [selectableTemplateId, setSelectableTemplateId] = useState<string>();
  const [myTemplates, setMyTemplates] = useState<MyTemplatesProps[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [selectModalOpen, setSelectModalOpen] = useState(false);

  const { setDocumentName, setSelectedTemplateId } =
    useContext(CreateProjectContext);
  useEffect(() => {
    if (!loading) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries?.some(entry => entry?.isIntersecting)) {
          if (hasMore) {
            setCurrentPage(oldState => oldState + 1);
          }
        }
      });

      const myDiv = document.querySelector("#sentinela2");

      if (myDiv) {
        intersectionObserver?.observe(myDiv);
      }
      return () => intersectionObserver?.disconnect();
    }
  }, [loading, hasMore]);

  useEffect(() => {
    (async () => {
      try {
        const responseMyTemplates = await api.get("user-templates", {
          params: {
            pagination: currentPage
          }
        });
        let myObjectNew: MyTemplatesProps[] = cloneDeep(
          responseMyTemplates.data.dataArray
        );
        myObjectNew.forEach(template => {
          if (!template.is_checked) {
            template.is_checked = false;
          }
        });
        setMyTemplates(prevState => [
          ...prevState,
          ...responseMyTemplates.data.dataArray
        ]);
        setLoading(false);
      } catch (err) {
        console.error(err, "ERROR when get my templates");
        setLoading(false);
      }
    })();
  }, [currentPage]);

  useEffect(() => {
    (async () => {
      try {
        const responseMyTemplates = await api.get("user-templates", {
          params: {
            pagination: currentPage + 1
          }
        });

        if (responseMyTemplates.data.dataArray.length > 0) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } catch (err) {
        console.error(err, "erro");
      }
    })();
  }, [currentPage]);

  return (
    <>
      <Container>
        {myTemplates.map((template, index) => (
          <Template key={template.id}>
            <input
              type="checkbox"
              checked={!!template.is_checked}
              onChange={event => {
                setSelectableTemplateId(template.id);
                setSelectedTemplateId(template.id);
                setDocumentName(template.title);
                setMyTemplates(oldState => {
                  let cloneState = cloneDeep(oldState);
                  cloneState.forEach((item, i) => {
                    cloneState[i].is_checked = i === index;
                  });
                  return cloneState;
                });
              }}
            />
            <ImageTemplate>
              {template.thumbnail !== "default" && (
                <img src={template.thumbnail_url} alt="Template" />
              )}
            </ImageTemplate>
            <PlainText>{template.title}</PlainText>
          </Template>
        ))}
      </Container>

      <div id="sentinela1" style={{ width: "100%", height: "50px" }} />
      {hasMore && (
        <div
          id="sentinela2"
          style={{
            width: "100%",
            height: "50px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex"
          }}
        >
          <div
            style={{
              margin: "auto auto",
              width: "30px",
              height: "30px",
              display: "flex"
            }}
          >
            <ReactLoading type="spin" color="#000" height={30} width={30} />
          </div>
        </div>
      )}
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <p>
          <Skeleton height={200} count={1} />
        </p>
      </SkeletonTheme>
      {!resend && myTemplates.length > 0 && !loading && (
        <SelectContainer open={selectModalOpen}>
          <Box style={{ width: "170px" }}>
            {" "}
            <Label1>Selecione o fluxo*</Label1>
          </Box>

          <SelectItem
            items={flows}
            value="name"
            onChange={selected => handleSelectFlow(selected)}
            selectedItem={null}
            onModalStateChange={value => setSelectModalOpen(value)}
          />
        </SelectContainer>
      )}
    </>
  );
};

export default TemplatesSelect;
