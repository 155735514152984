import { useEffect } from "react";
import { useMainHook } from "../../../../../hooks/main";
import { useSelection } from "../../../hooks/selection";
import { useToolbarEditor } from "../../../hooks/toolbarEditor";
import {
  BoxDecrement,
  BoxIncrement,
  Flex,
  NumberArea,
  Container
} from "./styles";
import { changeSelectedObject } from "../../../utils/changeSelectedObject";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import { useTheme } from "../../../../../hooks/theme";

const SelectBorderRadius = () => {
  const {
    changeBorderRadius,
    setChangeBorderRadius,
    elementBorderRadiusIncrement,
    elementBorderRadiusDecrement
  } = useToolbarEditor();

  const { selectedObject } = useSelection();
  const { setObjectScreen } = useMainHook();
  const { theme } = useTheme();

  useEffect(() => {
    if (selectedObject?.attrs?.cornerRadius) {
      setChangeBorderRadius(selectedObject?.attrs?.cornerRadius);
    } else {
      setChangeBorderRadius(0);
    }
  }, [selectedObject]);

  return (
    <Container>
      <BoxDecrement
        onClick={() => elementBorderRadiusDecrement(changeBorderRadius)}
      >
        <div />
      </BoxDecrement>
      <Flex>
        <NumberArea
          value={changeBorderRadius}
          onChange={e => {
            setChangeBorderRadius(Number(e.target.value));
            changeSelectedObject({
              selectedObject,
              setObjectScreen,
              propertie: "cornerRadius",
              value: Number(e.target.value)
            });
          }}
          //value={sizeOfFont}
          //  onChange={e => setSizeOfFont(Number(e.target.value))}
        ></NumberArea>
      </Flex>
      <BoxIncrement
        onClick={() => elementBorderRadiusIncrement(changeBorderRadius)}
      >
        <PersonalizedIcon
          dPath={MountIcons.IconCrossAdd.dPath}
          viewBox={MountIcons.IconCrossAdd.viewBox}
          inactivatedColor={theme.colors.primary}
        />
      </BoxIncrement>
    </Container>
  );
};

export default SelectBorderRadius;
