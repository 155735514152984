/*
this file render to open modal when user is
resolving pending texts because they are
in documents shared with other users
*/

import React from "react";
import { Footer, HStack, ModalContent, ModalOverlay, Text } from "./styles";
import { useTheme } from "../../../../hooks/theme";
import { useDiff } from "../../hooks/diffDocuments";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";

// import { Container } from './styles';

interface ModalDefaultDeclineProps {
  divRefModalDecline: React.MutableRefObject<HTMLDivElement>;
  option: "Accept" | "Decline";
  handleDefineModifications: (whichAction: string) => void;
  handleOpenModalDifferencesDecline: (type: "Close" | "Open") => void;
}

const ModalDefaultDecline: React.FC<ModalDefaultDeclineProps> = ({
  divRefModalDecline,
  option,
  handleDefineModifications,
  handleOpenModalDifferencesDecline
}) => {
  const { theme } = useTheme();
  const { setLoadingGeneratingText, loadingGeneratingText } = useDiff();

  return (
    <ModalOverlay ref={divRefModalDecline}>
      <ModalContent>
        {loadingGeneratingText ? (
          <div
            style={{
              width: "100%",
              minHeight: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <ContractAnimation />
          </div>
        ) : (
          <>
            <HStack>
              <TitleH5
                title={
                  option === "Accept"
                    ? "Aceitar alterações"
                    : "Rejeitar alterações"
                }
              />
              <Text>
                Tem certeza que você quer{" "}
                <strong>
                  {" "}
                  {option === "Accept" ? "aceitar" : "rejeitar"}{" "}
                </strong>{" "}
                estas alterações?
              </Text>
            </HStack>
            <Footer>
              <ButtonMaxWidth
                text="Sim"
                typeStyle="purple"
                onClick={() => {
                  if (option === "Accept") {
                    handleDefineModifications("Accept");
                  }

                  if (option === "Decline") {
                    handleDefineModifications("Decline");
                  }
                }}
              />

              <ButtonMaxWidth
                text="Não"
                typeStyle="white"
                onClick={() => handleOpenModalDifferencesDecline("Close")}
              />
            </Footer>
          </>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalDefaultDecline;
