import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 25px;
  height: 25px;
  fill: currentColor;
`;

type Props = {
  color?: string;
};

const DocApprovedIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 25 25" color={color || "#007B36"}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6901 16.6202C19.8322 16.7623 19.8322 16.9926 19.6901 17.1347L16.9184 19.9071C16.8502 19.9753 16.7577 20.0137 16.6612 20.0137C16.5647 20.0137 16.4721 19.9753 16.4039 19.9071L15.0177 18.5209C14.8756 18.3788 14.8756 18.1485 15.0177 18.0064C15.1598 17.8643 15.3901 17.8643 15.5322 18.0064L16.6611 19.1353L19.1756 16.6203C19.3176 16.4782 19.548 16.4781 19.6901 16.6202Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.6589 16.2081C19.0104 15.8565 19.5805 15.8564 19.9322 16.208C20.2838 16.5595 20.2839 17.1296 19.9323 17.4812L16.8448 20.5694C16.676 20.7383 16.447 20.8332 16.2082 20.8332C15.9694 20.8332 15.7403 20.7384 15.5715 20.5695L14.0274 19.0254C13.6758 18.6738 13.6758 18.1037 14.0274 17.7521C14.379 17.4005 14.949 17.4005 15.3006 17.7521L16.208 18.6595L18.6589 16.2081Z"
      fill="currentColor"
    />
    <path
      d="M12.9074 20.833H5.97407C4.9874 20.833 4.19629 20.0419 4.19629 19.0552V6.61079C4.19629 5.62412 4.9874 4.83301 5.97407 4.83301H18.4185C19.4052 4.83301 20.1963 5.62412 20.1963 6.61079V13.5441C19.6541 13.233 19.0585 13.0108 18.4185 12.9041V6.61079H5.97407V19.0552H12.2674C12.3741 19.6952 12.5963 20.2908 12.9074 20.833ZM12.1963 17.2775H8.64073C8.14981 17.2775 7.75184 16.8795 7.75184 16.3886C7.75184 15.8976 8.14981 15.4997 8.64073 15.4997H12.1963M14.5785 13.7219H8.64073C8.14981 13.7219 7.75184 13.3239 7.75184 12.833C7.75184 12.3421 8.14981 11.9441 8.64073 11.9441H15.6807C16.2109 11.9441 16.6407 12.3739 16.6407 12.9041C15.8852 13.0286 15.1918 13.313 14.5785 13.7219ZM12.1963 8.38856H15.7518C16.2428 8.38856 16.6407 8.78653 16.6407 9.27745C16.6407 9.76837 16.2428 10.1663 15.7518 10.1663H8.64073C8.14981 10.1663 7.75184 9.76837 7.75184 9.27745C7.75184 8.78653 8.14981 8.38856 8.64073 8.38856H12.1963Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default DocApprovedIcon;
