import React, { useCallback } from "react";
import {
  BoxButton,
  ModalContainer,
  Container,
  Title,
  Text,
  Category
} from "./styles";

// import { Container } from './styles';
import iconCopy from "../../../../../shared/assets/copy_line-icone.svg";
import { toast } from "react-toastify";
import ButtonExit from "../../../../../shared/components/ButtonExit";
import ButtonMaxWidth from "../../../../../shared/components/ButtonMaxWidth";
import TitleH5 from "../../../../../shared/components/TitleH5";

interface ObjectNewClause {
  user_clause_id?: string;
  title: string;
  clause: string;
  description: string;
  is_favorite: boolean;
  key_words?: string;
}

interface ModalShowTextProps {
  activeClausule: ObjectNewClause;
  setActiveModal?: React.Dispatch<
    React.SetStateAction<"editClausules" | "showClausules" | "createClausules">
  >;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setObjectClause: React.Dispatch<React.SetStateAction<ObjectNewClause>>;
}

const ModalShowText: React.FC<ModalShowTextProps> = ({
  activeClausule,
  setActiveModal,
  setIsModalOpen,
  setObjectClause
}) => {
  const handleCopyClause = useCallback((text: string) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Texto copiado com sucesso!");
    } catch (err) {
      // console.error(err);
      toast.error("Erro ao copiar texto");
    }
  }, []);

  return (
    <Container>
      <ModalContainer>
        <Title>
          <TitleH5 title={activeClausule?.title} />
          <div>
            <img
              onClick={() => {
                handleCopyClause(activeClausule?.clause);
              }}
              src={iconCopy}
              alt=""
            />
            <ButtonExit
              onClick={() => {
                setIsModalOpen(false);

                setObjectClause({
                  title: "",
                  clause: "",
                  description: "Clause by user",
                  is_favorite: false,
                  key_words: ""
                });
              }}
            />
          </div>
        </Title>
        <Category>
          <p>Categoria:</p>
          <ul>
            <p>{activeClausule?.key_words}</p>
          </ul>
        </Category>
        <Text>{activeClausule?.clause}</Text>

        <BoxButton>
          <ButtonMaxWidth
            text="Editar"
            typeStyle="purple"
            onClick={() => {
              setActiveModal("editClausules");
            }}
          />
        </BoxButton>
      </ModalContainer>
    </Container>
  );
};

export default ModalShowText;
