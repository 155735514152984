import styled from "styled-components";

export const FullContent = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 20px 20px 20px;

  display: flex;
  overflow-y: auto;
  flex-direction: column;
  background-color: transparent;
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;

  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .show-neither-data {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    z-index: -1;

    span {
      font-size: 1.4rem;
      color: ${({ theme }) => theme.colors.gray300};
      transform: translateY(-140px);
    }
  }

  @media (max-width: 1600px) {
    gap: 15px;
  }
`;

export const Header = styled.div`
  height: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
`;

export const BoxSearchMyProjects = styled.div`
  width: 90%;
  height: 51px;
  min-height: 51px;
  background-color: white;
  display: flex;
  margin-bottom: 6.1rem;
  align-items: flex-end;
`;

export const BoxSearch = styled.div`
  width: 96%;
  height: 51px;
  min-height: 51px;
  background-color: white;
  display: flex;
  margin-bottom: 6.1rem;
  align-items: flex-end;

  @media (max-width: 1366px) {
    width: 92%;
  }
`;

export const WrapperInput = styled.div`
  width: 85%;
  height: 100%;
  border-bottom: 2px solid #a5a5a5;
  display: flex;
`;

export const ContainerIcon = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon-search-input {
    width: 22px;
    height: auto;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  input {
    width: inherit;
    height: inherit;
    border: none;
    background-color: transparent;
    padding-left: 1rem;
  }
`;

export const ButtonSearch = styled.button`
  width: 15%;
  min-width: 201px;
  height: 100%;
  display: flex;
  margin-left: 2rem;
  background-color: black;
  border-radius: 10px;
  color: white;
  font-size: 1.8rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: background 120ms linear;

  :hover {
    background-color: ${({ theme }) => theme.colors.background};
  }

  @media (max-width: 1366px) {
    min-width: 172px;
    height: 80%;
    margin-left: 2rem;
    font-size: 1.3rem;
  }
`;

export const UpdatesLabel = styled.h2`
  margin-top: 2rem;
  margin-bottom: 3.2rem;
  font-size: 2.4rem;
  font-weight: 600;

  @media (max-width: 1600px) {
    font-size: 1.6rem;
  }

  @media (max-width: 1366px) {
    transform: translateY(15px);
    font-size: 1.4rem;
  }

  @media (max-width: 1280px) {
    font-size: 1.4rem;
  }
`;

export const GridContainerTemplates = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 212.09px);
  justify-content: space-between;
  grid-row-gap: 20px;
  min-height: 450px;
  margin-top: 10px;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(4, 250px);
  }
`;

export const WrapperPagination = styled.div`
  width: 100%;
  margin-bottom: auto;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  z-index: 999;
  height: 100%;
  margin-bottom: 5rem;

  /* transform: translateY(500px); */
`;

export const NoElements = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;

  color: #999c9f;

  min-height: 300px;
  min-width: 100%;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1600px) {
    min-height: 500px;
  }
`;
