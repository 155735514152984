import React from "react";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";

import { usePagesEditor } from "../../../../hooks/pagesEditor";
import { useWorkspaceEditor } from "../../../../hooks/workspaceEditor";

import ButtonIconsWithTooltip from "../../../ButtonsAsideBar/components/ButtonIconsAsidebar";
import { useTheme } from "../../../../../../hooks/theme";
import MountIcons from "../../../../../../shared/utils/MountIcons";

// import { Container } from './styles';

interface MoveUpProps {
  index: number;
  item: any;
}

const MoveUpPage: React.FC<MoveUpProps> = ({ index, item }) => {
  const { isActiveUpPage, isActiveHoverMoveUpPage, handleHoverMoveUpPage } =
    useWorkspaceEditor();

  const { handleOrderPageMoveUp, disabledButton } = usePagesEditor();
  const { theme } = useTheme();

  return (
    <>
      {disabledButton.visible && index === 0 ? (
        <ButtonIconsWithTooltip
          style={{
            position: "relative",
            cursor: "no-drop",
          }}
          disabled
          className="btn-page"
          title="Desabilitado"
          placement="bottom-start"
        >
          <PersonalizedIcon
            isActive={isActiveUpPage}
            activeColor="#D6D8D9"
            inactivatedColor="#D6D8D9"
            className="blocked-icon"
            dPath={MountIcons.IconsMoveUp.dPath}
            viewBox={MountIcons.IconsMoveUp.viewBox}
          />
        </ButtonIconsWithTooltip>
      ) : (
        <ButtonIconsWithTooltip
          onMouseEnter={() => handleHoverMoveUpPage(index, true)}
          onMouseLeave={() => handleHoverMoveUpPage(index, false)}
          onClick={() => handleOrderPageMoveUp(item.pageNumber, index)}
          className="btn-page"
          style={{ position: "relative" }}
          title="Para cima"
          placement="bottom-start"
          width="30px"
        >
          <PersonalizedIcon
            isActive={
              isActiveHoverMoveUpPage.visible &&
              isActiveHoverMoveUpPage.index === index
            }
            activeColor={theme.colors.primary}
            inactivatedColor="#999C9F"
            className="blocked-icon"
            dPath={MountIcons.IconsMoveUp.dPath}
            viewBox={MountIcons.IconsMoveUp.viewBox}
          />
        </ButtonIconsWithTooltip>
      )}
    </>
  );
};

export default MoveUpPage;
