import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: red; */
`;

export const Text = styled.div`
  font-size: 14px;
  font-family: Ubuntu, sans-serif;
  margin: 0 auto;
  margin-top: 1.2rem;
  line-height: 150%;
  color: #343a40;
  min-width: 678px;
  max-width: 678px;
`;

export const TokenBox = styled.div`
  width: 420px;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  /* background-color: red; */
  margin: 1.8rem auto 1.6rem auto;
  position: relative;

  input {
    min-width: 60px;
    max-width: 60px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border: 1px solid #d6d8d9;
    font-size: 48px;
    font-family: Ubuntu, sans-serif;
  }

  .otp-input.otp-input-space {
    margin-right: 60px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }

  .otp-input.item-0 {
    /* border-radius: 5px; */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0.5px solid #fff;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }

  .otp-input.item-1 {
    border-right: 0.5px solid #fff;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }

  .otp-input.item-3 {
    /* border-radius: 5px; */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0.5px solid #fff;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }

  .otp-input.item-4 {
    border-right: 0.5px solid #fff;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }

  .otp-input.item-5 {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }
`;

export const Divider = styled.div`
  min-width: 15px;
  border: 3px solid #ebebec;
  height: 3px;
  position: absolute;
  top: calc(50% - 1.5px);
  left: calc(50% - 7.5px);
`;

export const TextCode = styled.span`
  font-size: 30px;
  font-family: Ubuntu, sans-serif;
  color: #343a40;
  margin: auto auto;
`;

export const BoxInputCode = styled.div`
  width: 592px;
  height: 60px;
  display: flex;
  margin: 1.6rem auto;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #d6d8d9;
  display: flex;
`;

export const DownloadCode = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  font-size: 14px;
  font-family: Ubuntu;
  width: 106px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;
  gap: 6px;
  margin: 0rem auto 2rem auto;
`;

