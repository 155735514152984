import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 24px;
  height: 25px;
  fill: currentColor;
`;

type Props = {
  color?: string;
};

const DocInfoIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 24 25" color={color || "#676B70"}>
    <mask
      id="mask0_11287_1086"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.348877" width="24" height="24" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_11287_1086)">
      <path
        d="M16.5 20.3489H17.5V16.3489H16.5V20.3489ZM17 15.3489C17.1333 15.3489 17.25 15.2989 17.35 15.1989C17.45 15.0989 17.5 14.9822 17.5 14.8489C17.5 14.7155 17.45 14.5989 17.35 14.4989C17.25 14.3989 17.1333 14.3489 17 14.3489C16.8667 14.3489 16.75 14.3989 16.65 14.4989C16.55 14.5989 16.5 14.7155 16.5 14.8489C16.5 14.9822 16.55 15.0989 16.65 15.1989C16.75 15.2989 16.8667 15.3489 17 15.3489ZM7 14.3489H10.675C10.8583 13.9655 11.0708 13.6072 11.3125 13.2739C11.5542 12.9405 11.825 12.6322 12.125 12.3489H7V14.3489ZM7 18.3489H10.075C10.025 18.0155 10 17.6822 10 17.3489C10 17.0155 10.025 16.6822 10.075 16.3489H7V18.3489ZM5 22.3489C4.45 22.3489 3.97917 22.153 3.5875 21.7614C3.19583 21.3697 3 20.8989 3 20.3489V4.34888C3 3.79888 3.19583 3.32804 3.5875 2.93638C3.97917 2.54471 4.45 2.34888 5 2.34888H13L19 8.34888V10.6489C18.6833 10.5489 18.3583 10.4739 18.025 10.4239C17.6917 10.3739 17.35 10.3489 17 10.3489V9.34888H12V4.34888H5V20.3489H10.675C10.8583 20.7322 11.0708 21.0905 11.3125 21.4239C11.5542 21.7572 11.825 22.0655 12.125 22.3489H5ZM17 12.3489C18.3833 12.3489 19.5625 12.8364 20.5375 13.8114C21.5125 14.7864 22 15.9655 22 17.3489C22 18.7322 21.5125 19.9114 20.5375 20.8864C19.5625 21.8614 18.3833 22.3489 17 22.3489C15.6167 22.3489 14.4375 21.8614 13.4625 20.8864C12.4875 19.9114 12 18.7322 12 17.3489C12 15.9655 12.4875 14.7864 13.4625 13.8114C14.4375 12.8364 15.6167 12.3489 17 12.3489Z"
        fill="currentColor"
      />
    </g>
  </StyledSvg>
);

export default DocInfoIcon;
