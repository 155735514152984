import React from "react";

import DynamicIcons from "../DynamicIcons";

import { TooltipProps } from "@mui/material/Tooltip";
import Tooltip from "../../Tooltip";

interface ButtonToolbarProps {
  className?: string;
  type: "button" | "submit" | "reset";
  onClick?: () => void;
  isActive?: boolean;
  tooltipTitle?: string;
  tooltipDescription?: string;
  tooltipPlacement?: TooltipProps["placement"];
  component?: string;
  elementForShow?: string;
}

const ButtonToolbar: React.FC<ButtonToolbarProps> = ({
  className,
  type,
  onClick,
  isActive,
  tooltipTitle,
  component,
  tooltipDescription,
  tooltipPlacement,
  elementForShow
}: ButtonToolbarProps) => {
  if (elementForShow === "Text") {
    return (
      <button className={className} type={type} onClick={onClick}>
        <DynamicIcons isActive={isActive} component={component} />
      </button>
    );
  }

  return (
    <Tooltip
      title={tooltipTitle}
      description={tooltipDescription}
      placement={tooltipPlacement}
      disabled={isActive}
    >
      <button className={className} type={type} onClick={onClick}>
        <DynamicIcons isActive={isActive} component={component} />
      </button>
    </Tooltip>
  );
};

export default ButtonToolbar;
