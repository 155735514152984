import React from "react";

import { Box, Container, HStack } from "./styles";
import { CustomSpin } from "../../../shared/components/CustomSpin";

const ModalLoading: React.FC = () => {
  return (
    <Container>
      <Box>
        <div>
          {" "}
          <span className="title">Por favor, aguarde.</span>
        </div>
        <HStack>
          <CustomSpin />
          <span>Estamos processando...</span>
        </HStack>
      </Box>
    </Container>
  );
};

export default ModalLoading;
