const IconOffline = () => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="save"
    >
      <path
        d="M23.7969 25.6582L20.4844 22.4082H7.17188C5.25521 22.4082 3.63021 21.7415 2.29687 20.4082C0.963542 19.0749 0.296875 17.4499 0.296875 15.5332C0.296875 13.929 0.791875 12.502 1.78187 11.252C2.77187 10.002 4.04771 9.19987 5.60938 8.8457C5.67188 8.67904 5.73438 8.51737 5.79688 8.3607C5.85938 8.20404 5.92188 8.03237 5.98438 7.8457L0.796875 2.6582L2.54688 0.908203L25.5469 23.9082L23.7969 25.6582ZM7.17188 19.9082H17.9844L7.92188 9.8457C7.88021 10.0749 7.84896 10.2936 7.82812 10.502C7.80729 10.7103 7.79688 10.929 7.79688 11.1582H7.17188C5.96354 11.1582 4.93229 11.5853 4.07812 12.4395C3.22396 13.2936 2.79688 14.3249 2.79688 15.5332C2.79688 16.7415 3.22396 17.7728 4.07812 18.627C4.93229 19.4811 5.96354 19.9082 7.17188 19.9082ZM26.0469 20.8457L24.2344 19.0957C24.5885 18.804 24.8544 18.4653 25.0319 18.0795C25.2094 17.6936 25.2977 17.2615 25.2969 16.7832C25.2969 15.9082 24.9948 15.1686 24.3906 14.5645C23.7865 13.9603 23.0469 13.6582 22.1719 13.6582H20.2969V11.1582C20.2969 9.42904 19.6873 7.95487 18.4681 6.7357C17.249 5.51654 15.7752 4.90737 14.0469 4.9082C13.4844 4.9082 12.9427 4.97612 12.4219 5.11195C11.901 5.24779 11.401 5.46112 10.9219 5.75195L9.10938 3.93945C9.83854 3.43945 10.6148 3.05945 11.4381 2.79945C12.2615 2.53945 13.131 2.40904 14.0469 2.4082C16.4844 2.4082 18.5523 3.25737 20.2506 4.9557C21.949 6.65404 22.7977 8.72154 22.7969 11.1582C24.2344 11.3249 25.4273 11.9449 26.3756 13.0182C27.324 14.0915 27.7977 15.3465 27.7969 16.7832C27.7969 17.5957 27.6406 18.3511 27.3281 19.0495C27.0156 19.7478 26.5885 20.3465 26.0469 20.8457Z"
        fill="white"
      />
    </svg>
  );
};

export default IconOffline;
