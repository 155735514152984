import styled, { keyframes } from "styled-components";
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Container = styled.li<{ type: string }>`
  position: relative;
  display: flex;
  gap: 10px;
  /* animation: ${({ type }) => (type !== "Comment To View" ? fadeIn : "none")}
    0.5s ease; */
  cursor: pointer;
  background-color: #fff;
  padding: 10px;
  min-height: 120px;
  border: 1px solid #c4c4c4;
  border-bottom: ${({ type }) => type !== "Comment To View" && "none"};
  border-radius: ${({ type }) =>
    type === "Response"
      ? "0px"
      : type === "Main Comment"
      ? "5px 5px 0px 0px"
      : "5px 5px 5px 5px"};
  font-size: 16px;

  .status-comment {
    position: absolute;
    top: 46px;
    right: 15px;

    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
  }

  input {
    outline: transparent;
    min-height: 40px;
    border: 2px solid #000;
    border-radius: 5px;
    padding: 0 2rem;
  }
`;

export const CommentBox = styled.div<{ type: string }>`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: ${({ type }) => (type === "Response" ? "94%" : "100%")};

  & > span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    text-decoration-line: underline;
    color: #999c9f;
  }

  & > div {
    display: flex;
    justify-content: space-between;
  }

  & > p {
    padding-left: 40px;

    max-width: 100%;
    word-wrap: break-word;

    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const HeaderComment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  font-size: 12px;
  font-weight: 400;
  line-height: 150%;

  b {
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
  }

  span {
    font-size: 13px;
    font-weight: 400;
    line-height: 100%;
    color: #999c9f;
  }
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;

  img {
    width: 30px;
    height: 30px;
    cursor: pointer;

    :hover {
      background-color: #ebebec;
      border-radius: 5px;
    }
  }
`;

export const IconRes = styled.img`
  width: 20px;
  height: 17.418px;
`;

export const InputModeElement = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  border: 1px solid black;
  border-radius: 4px;
  padding: 5px;
  padding-right: 10px;

  textarea {
    width: 100%;
    height: 18px;
    border-radius: 4px;
    padding: 5px;
    resize: none;
    border: none;
    overflow: hidden;
    ::placeholder {
      color: #a5a5a5;
    }
  }

  img {
    width: 20px;
  }
`;
