/*
this component receive responsability for render
asidebar menu into dashboar
*/
import { useCallback, useEffect, useRef, useState } from "react";

import html2canvas from "html2canvas";
import { Container, LoadingContainerFirst, VStack } from "./styles";
import { useAuth } from "../../../../../hooks/auth";
import { getColors } from "../../../../editor/utils/getColorAvatar";
import { useMainHook } from "../../../../../hooks/main";
import MountIcons from "../../../../../shared/utils/MountIcons";
import HeaderDash from "../../components/HeaderDash";
import AsidebarDash from "../../components/AsidebarDash";
import api from "../../../../../services/api";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import Main from "../../components/Main";
import ModalController from "./components";
import ModalLoading from "../../../../../shared/components/ModalLoading";
// import { useQueryClient } from "react-query";
import { useTeams } from "../../../../../hooks/teams";
import { queryClient } from "../../../../../App";
import Notification2FA from "../../components/Notification2FA";

const Master: React.FC = () => {
  //set hooks
  const {
    signOut,
    data,
    permissionType,
    notificationTwoFactor,
    setNotificationsTwoFactor,
  } = useAuth();
  const { setListTeamsOfOwner, forceUpdate } = useTeams();

  const {
    srcAvatar,
    refreshAvatar,
    setRefreshAvatar,
    profile,
    isModalCreateClause,
    setFirstLoading,
    firstLoading,
    isIntentBuyModal,
    setIsIntentBuyModal,
    setAllMyProjects,
    setNameDocumentDelete,
    setIsLoadingModal,
    isLoadingModal,
  } = useMainHook();

  //set up states

  const [activeButton, setActiveButton] = useState("");
  const [letters, setLetters] = useState("");
  const [color, setColor] = useState("");
  const [isOpenAvatarConfig, setIsOpenAvatarConfig] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  // const [isLoadingModal, setIsLoadingModal] = useState(false);

  //fixing issue with z-index modal and photo
  //and both of the components conflicts z-indez
  const [zIndexActive, setZIndexActive] = useState(false);
  const [avatarChange, setAvatarChange] = useState("");
  const refAvatar = useRef(null);

  useEffect(() => {
    const interval = setTimeout(() => {
      setFirstLoading(false);
    }, 1500);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  //this function could generate color for avatar
  //when first time user dont have avatar
  //then we generate it
  const generateColor = useCallback(() => {
    let randomColor = getColors();
    setColor(randomColor);
  }, []);

  //here capture which is name of this user
  useEffect(() => {
    setLetters(`${data.people.first_name} ${data.people.last_name}`);
    generateColor();
  }, []);

  //useEffect for save avatar image
  //into we and generate one image to div of avatar
  useEffect(() => {
    let myImage: string;
    const autoLoadingAvatar = async () => {
      if (srcAvatar.includes("avatar_default")) {
        html2canvas(document.querySelector("#capture")).then((canvas) => {
          myImage = canvas.toDataURL();
          if (myImage) {
            setAvatarChange(myImage);
          }
        });
      }
    };

    //just one time for generate avatar
    //receive one loading
    if (profile) {
      setTimeout(() => {
        autoLoadingAvatar();
      }, 1000);
    }
  }, [profile]);

  //here we input new title for this page
  useEffect(() => {
    document.title = `UX DOC | Início`;
  }, []);

  //then save it unique image for this user
  //in our api
  useEffect(() => {
    const autoSaveAvatar = async () => {
      if (avatarChange) {
        setLoadingAvatar(true);
        const blob = await (await fetch(avatarChange)).blob();
        const file = new File([blob], "fileName.png", {
          type: "image/png",
        });

        let formData = new FormData();
        formData.append("avatar", file);

        try {
          const requestAvatarChange = await api.patch(
            "users/avatar",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setLoadingAvatar(false);
          setRefreshAvatar(!refreshAvatar);
        } catch (err) {
          setLoadingAvatar(false);
        }
      } else {
        setLoadingAvatar(false);
      }
    };

    autoSaveAvatar();
  }, [avatarChange]);

  //here just fix bug of zIndex
  //when modal of clauses was open
  useEffect(() => {
    if (isModalCreateClause) {
      setZIndexActive(true);
    } else {
      setZIndexActive(false);
    }
  }, [isModalCreateClause]);

  //responsible for clearing the master component.
  // const queryClient = useQueryClient();
  useEffect(() => {
    setIsLoadingModal(false);

    return () => {
      setAllMyProjects([]);
      setNameDocumentDelete("");
      queryClient.removeQueries("myProjects");
      queryClient.removeQueries("templatesBits");
      queryClient.removeQueries("myClausules");
    };
  }, []);

  useEffect(() => {
    const notificationLocalStorage = localStorage.getItem("UXDOC:2FA");
    const formattedNotificationObject = JSON.parse(notificationLocalStorage);

    if (formattedNotificationObject) {
      setNotificationsTwoFactor(
        formattedNotificationObject.notification as boolean
      );
    }
  }, []);

  //List of teams where the user is the owner
  useEffect(() => {
    (async () => {
      if (data) {
        try {
          const response = await api.get("team-users/list-teams-user-owner");
          setListTeamsOfOwner(response.data);
        } catch (err) {
          console.error(
            err,
            "error when was seach of the list of teams which user is owner"
          );
        }
      }
    })();
  }, [forceUpdate]);

  if (firstLoading) {
    return (
      <Container>
        <LoadingContainerFirst>
          <ContractAnimation />
        </LoadingContainerFirst>
      </Container>
    );
  }

  return (
    <VStack>
      {!data?.user?.otp_enabled && notificationTwoFactor && (
        <Notification2FA
          isNotifications={notificationTwoFactor}
          setIsNotifications={setNotificationsTwoFactor}
        />
      )}
      <Container ref={refAvatar}>
        <ModalController />
        {isLoadingModal && <ModalLoading />}
        <HeaderDash
          isOpenAvatarConfig={isOpenAvatarConfig}
          setIsOpenAvatarConfig={setIsOpenAvatarConfig}
          zIndexActive={zIndexActive}
          setZIndexActive={setZIndexActive}
          loadingAvatar={loadingAvatar}
          srcAvatar={srcAvatar}
          letters={letters}
          setLetters={setLetters}
          color={color}
        />

        <AsidebarDash
          permissionType={permissionType}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          MountIcons={MountIcons}
          signOut={signOut}
          isIntentBuyModal={isIntentBuyModal}
          setIsIntentBuyModal={setIsIntentBuyModal}
        />

        <Main
          isNotifications={!data?.user?.otp_enabled && notificationTwoFactor}
        />
      </Container>
    </VStack>
  );
};

export default Master;
