import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const MessageHeader = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 0px 0px 5px 5px;
  background-color: #f0ebf5;
  padding-left: 20px;

  display: flex;
  align-items: center;
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  position: relative;

  img,
  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const BoxButtonNav = styled.div`
  display: flex;
  align-items: center;

  button:nth-child(2) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ButtonHeader = styled.div<{ isActive: boolean }>`
  width: 196px;
  height: 37px;

  border-bottom: 3px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.primary90 : theme.colors.white100};
  background-color: white;
  text-decoration: none;

  font-size: 14px;
  font-weight: 400;
  line-height: 120%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-decoration: none;

    width: 100%;
    height: 100%;

    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.black100 : theme.colors.black75};
  }
`;


