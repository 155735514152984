import styled from "styled-components";

export const TextBox = styled.div<{ fontSize: string; isBold: boolean }>`
  width: 100%;
  height: 55px;
  background: #fff;
  padding-left: 18px;
  border-radius: 5px;

  display: flex;
  align-items: center;

  font-family: "Open Sans", open-sans;
  font-size: ${({ fontSize }) => fontSize && fontSize};
  font-weight: ${({ isBold }) => (isBold ? "bold" : "normal")};

  cursor: grab !important;

  :hover {
    background-color: #ebebec;
  }
`;
