import React from "react";
import AvatarLengthShared from "../../../../../dashboard/master/components/AvatarLengthShared";
import MultiplesAvatarLetter from "../../../../../dashboard/master/components/MultiplesAvatarLetter";
import { getColors } from "../../../../utils/getColorAvatar";
import { HeaderMiddleSharedGroup } from "../../styles";
import { useAuth } from "../../../../../../hooks/auth";

interface UsersSharedProps {
  id?: string;
  name?: string;
  email?: string;
  avatar_url?: string;
  permissions?: string;
  is_anonymous?: boolean;
  color?: string;
}

interface SharedGroupProps {
  sharedUsers: UsersSharedProps[];
  handleOpenModalSharedInfo: () => void;
}

const SharedGroup: React.FC<SharedGroupProps> = ({
  sharedUsers,
  handleOpenModalSharedInfo
}) => {
  const { data } = useAuth();

  const newArrayWithoutThisUser = sharedUsers.filter(
    user => user.id !== data.user.id
  );

  return (
    <>
      {newArrayWithoutThisUser?.length === 0 ? (
        <div />
      ) : newArrayWithoutThisUser?.length === 1 ? (
        <HeaderMiddleSharedGroup onClick={handleOpenModalSharedInfo}>
          <MultiplesAvatarLetter
            src={newArrayWithoutThisUser[0]?.avatar_url}
            text={newArrayWithoutThisUser[0]?.name}
            color={getColors(true)}
            isAnnonymous={newArrayWithoutThisUser[0]?.is_anonymous}
            isHeader
          />
        </HeaderMiddleSharedGroup>
      ) : newArrayWithoutThisUser?.length === 2 ? (
        <HeaderMiddleSharedGroup onClick={handleOpenModalSharedInfo}>
          <MultiplesAvatarLetter
            src={newArrayWithoutThisUser[0]?.avatar_url}
            text={newArrayWithoutThisUser[0]?.name}
            color={getColors(true)}
            isAnnonymous={newArrayWithoutThisUser[0]?.is_anonymous}
            isHeader
          />
          <MultiplesAvatarLetter
            className="adjust-avatar-multiples2rd"
            src={newArrayWithoutThisUser[1]?.avatar_url}
            text={newArrayWithoutThisUser[1]?.name}
            color={newArrayWithoutThisUser[1]?.color}
            isAnnonymous={newArrayWithoutThisUser[1]?.is_anonymous}
            isHeader
          />
        </HeaderMiddleSharedGroup>
      ) : newArrayWithoutThisUser?.length === 3 ? (
        <HeaderMiddleSharedGroup onClick={handleOpenModalSharedInfo}>
          <MultiplesAvatarLetter
            src={newArrayWithoutThisUser[0]?.avatar_url}
            text={newArrayWithoutThisUser[0]?.name}
            color={getColors(true)}
            isAnnonymous={newArrayWithoutThisUser[0]?.is_anonymous}
            isHeader
          />
          <MultiplesAvatarLetter
            className="adjust-avatar-multiples2rd"
            src={newArrayWithoutThisUser[1]?.avatar_url}
            text={newArrayWithoutThisUser[1]?.name}
            color={newArrayWithoutThisUser[1]?.color}
            isAnnonymous={newArrayWithoutThisUser[1]?.is_anonymous}
            isHeader
          />
          <MultiplesAvatarLetter
            className="adjust-avatar-multiples3rd"
            src={newArrayWithoutThisUser[2]?.avatar_url}
            text={newArrayWithoutThisUser[2]?.name}
            color={newArrayWithoutThisUser[2]?.color}
            isAnnonymous={newArrayWithoutThisUser[2]?.is_anonymous}
            isHeader
          />
        </HeaderMiddleSharedGroup>
      ) : (
        newArrayWithoutThisUser?.length >= 4 && (
          <HeaderMiddleSharedGroup onClick={handleOpenModalSharedInfo}>
            <MultiplesAvatarLetter
              src={newArrayWithoutThisUser[0]?.avatar_url}
              text={newArrayWithoutThisUser[0]?.name}
              color={getColors(true)}
              isAnnonymous={newArrayWithoutThisUser[0]?.is_anonymous}
              isHeader
            />
            <MultiplesAvatarLetter
              className="adjust-avatar-multiples2rd"
              src={newArrayWithoutThisUser[1]?.avatar_url}
              text={newArrayWithoutThisUser[1]?.name}
              color={newArrayWithoutThisUser[1]?.color}
              isAnnonymous={newArrayWithoutThisUser[1]?.is_anonymous}
              isHeader
            />
            <MultiplesAvatarLetter
              className="adjust-avatar-multiples3rd"
              src={newArrayWithoutThisUser[2]?.avatar_url}
              text={newArrayWithoutThisUser[2]?.name}
              color={newArrayWithoutThisUser[2]?.color}
              isAnnonymous={newArrayWithoutThisUser[2]?.is_anonymous}
              isHeader
            />
            <AvatarLengthShared
              length={newArrayWithoutThisUser?.length}
              className="avatar-length"
              src=""
            />
          </HeaderMiddleSharedGroup>
        )
      )}
    </>
  );
};

export default SharedGroup;
