import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";
import { darken } from "polished";

export const GridItemHeader = styled.header`
  grid-area: header;
  z-index: 99;
`;

export const HeaderBox = styled.nav`
  width: 100%;
  height: 100%;
  position: sticky;
`;

export const FlexContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  background: linear-gradient(
    180deg,
    ${({ theme }: Theme) => theme.colors.primary} 0%,
    #000 197.5%
  );
`;
