// let myText =
//   "O @[naruto] correcto da @[lei_do_ladrao] inglesa hobby é hóbi, \numa vez que a palavra tem o acento tónico na penúltima sílaba. @[rainha]\nEssa forma ainda não se @[contratante] atestada em dicionários portugueses, que @[contratado] sugerem como alternativa ao @[nativo] hobby a palavra @[amigos].\n";

let getVariablesFromText = (text: string): string[] => {
  let splitedText: string[] = text.split("");
  let insideVariable: boolean = false;
  let currentVariable = "";
  let myVariables: string[] = [];

  splitedText.forEach((letter: string, index: number) => {
    // first validation, fiding the @ with [ and opening variable
    if (letter === "{" && splitedText[index + 1] === "{") insideVariable = true;

    if (insideVariable) {
      currentVariable += letter;
    }

    //Closing variable
    if (insideVariable && letter === "}" && splitedText[index + 1] === "}") {
      currentVariable += "}";
      insideVariable = false;
    }

    // veryfing if variable is closed and cleaning it
    if (currentVariable !== "" && !insideVariable) {
      myVariables.push(currentVariable);
      currentVariable = "";
    }
  });

  return myVariables;
};

export default getVariablesFromText;

