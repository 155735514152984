import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Container, Form } from "./styles";

// import imageBackgroundDecoration from "../../assets/background-templates.svg";

import { toast, ToastContainer } from "react-toastify";

import api from "../../../../../../services/api";
import { useSelection } from "../../../../../editor/hooks/selection";
import UxDocLogoLarge from "../../../../../../shared/components/UxDocLogoLarge";
import TitleH3 from "../../../../../../shared/components/TitleH3";
import InputForm from "../../../../../../shared/components/InputForm";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import AlertError from "../../../../../../shared/components/AlertError";
import { useNavigate } from "react-router-dom";

interface SubmitProps {
  email?: string;
}

const ForgotPasswordScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { setForceBlockKey } = useSelection();

  useEffect(() => {
    setForceBlockKey(true);
  }, []);

  const schema = yup.object({
    email: yup.string().email("Email invalido").required()
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SubmitProps>({
    resolver: yupResolver(schema)
  });
  const navigator = useNavigate();

  const onSubmit: SubmitHandler<SubmitProps> = async data => {
    try {
      setIsLoading(true);
      const responseForgotPassword = await api.post("password/forgot", data);
      toast.success("Email enviado com sucesso!");
      setIsLoading(false);
      setTimeout(() => {
        navigator("/");
      }, 1000);
    } catch (err) {
      console.error(err, "error when post forgot password");
      toast.error("Algo deu errado. Tente novamente mais tarde.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = `UX DOC | Esqueci minha senha`;
  }, []);

  return (
    <Container>
      <ToastContainer position="top-left" />
      <UxDocLogoLarge />

      <header>
        <TitleH3 title="Esqueci minha senha" />

        <p>
          Esqueceu sua senha? Não se preocupe, coloque o seu e-mail no campo
          abaixo. Enviaremos um link para recuperar a sua senha.
        </p>
      </header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputForm
          error={false}
          name="email"
          register={register}
          type="email"
          label="Email*"
        />

        <footer>
          <ButtonMaxWidth
            text="Enviar"
            typeStyle="purple"
            type="submit"
            loading={isLoading}
          />
          <AlertError
            dataError={
              errors?.email?.message === "email is a required field"
                ? "Campo obrigatório"
                : errors?.email?.message
            }
          />
        </footer>
      </Form>
    </Container>
  );
};

export default ForgotPasswordScreen;
