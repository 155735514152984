const getLineGuideStops = (skipShape, guidelines) => {
  let shapes = [
    ".circle",
    ".simpleLine",
    ".rectangle",
    ".star",
    ".svgShape",
    ".triangle",
    ".triangleNew",
    ".URLimage",
    ".URLImageDrag",
    ".richTextImage",
  ];
  // we can snap to stage borders and the center of the stage
  let vertical = [
    0,
    skipShape?.getStage()?.width() / 2,
    skipShape?.getStage()?.width(),
    guidelines.arrayV,
  ]
    .flat()
    .sort();

  let horizontal = [
    0,
    skipShape.getStage().height() / 2,
    skipShape.getStage().height(),
    guidelines.arrayH,
  ]
    .flat()
    .sort();

  // and we snap over edges and center of each object on the canvas
  shapes.forEach((currentShape, index) => {
    skipShape
      .getStage()
      .find(currentShape)
      .forEach((guideItem) => {
        if (guideItem === skipShape) {
          return;
        }
        let box = guideItem.getClientRect({
          relativeTo: skipShape.getStage(),
        });
        // and we can snap to all edges of shapes
        vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
        horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
      });
  });

  return {
    vertical: vertical.flat(),
    horizontal: horizontal.flat(),
  };
};

// what points of the object will trigger to snapping?
// it can be just center of the object
// but we will enable all edges and center
const getObjectSnappingEdges = (node) => {
  let box = node.getClientRect({ relativeTo: node.getStage() });
  let absPos = node.absolutePosition();

  return {
    vertical: [
      {
        guide: Math.round(box.x),
        offset: Math.round(absPos.x - box.x),
        snap: "start",
      },
      {
        guide: Math.round(box.x + box.width / 2),
        offset: Math.round(absPos.x - box.x - box.width / 2),
        snap: "center",
      },
      {
        guide: Math.round(box.x + box.width),
        offset: Math.round(absPos.x - box.x - box.width),
        snap: "end",
      },
    ],
    horizontal: [
      {
        guide: Math.round(box.y),
        offset: Math.round(absPos.y - box.y),
        snap: "start",
      },
      {
        guide: Math.round(box.y + box.height / 2),
        offset: Math.round(absPos.y - box.y - box.height / 2),
        snap: "center",
      },
      {
        guide: Math.round(box.y + box.height),
        offset: Math.round(absPos.y - box.y - box.height),
        snap: "end",
      },
    ],
  };
};

// find all snapping possibilities
const getGuides = (lineGuideStops, itemBounds) => {
  const GUIDELINE_OFFSET = 5;
  let resultV = [];
  let resultH = [];

  lineGuideStops.vertical.forEach((lineGuide) => {
    itemBounds.vertical.forEach((itemBound) => {
      let diff = Math.abs(lineGuide - itemBound.guide);
      // if the distance between guild line and object snap point is close we can consider this for snapping
      if (diff < GUIDELINE_OFFSET) {
        resultV.push({
          lineGuide: lineGuide,
          diff: diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
        });
      }
    });
  });

  lineGuideStops.horizontal.forEach((lineGuide) => {
    itemBounds.horizontal.forEach((itemBound) => {
      let diff = Math.abs(lineGuide - itemBound.guide);
      if (diff < GUIDELINE_OFFSET) {
        resultH.push({
          lineGuide: lineGuide,
          diff: diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
        });
      }
    });
  });

  let guides = [];

  // find closest snap
  let minV = resultV.sort((a, b) => a.diff - b.diff)[0];
  let minH = resultH.sort((a, b) => a.diff - b.diff)[0];
  if (minV) {
    guides.push({
      lineGuide: minV.lineGuide,
      offset: minV.offset,
      orientation: "V",
      snap: minV.snap,
    });
  }
  if (minH) {
    guides.push({
      lineGuide: minH.lineGuide,
      offset: minH.offset,
      orientation: "H",
      snap: minH.snap,
    });
  }
  return guides;
};

const drawGuides = (guides, setHlines, setVlines) => {
  if (guides) {
    guides.forEach((lg) => {
      if (lg.orientation === "H") {
        let guide = {
          points: [-6000, 0, 6000, 0],
          stroke: "#663399",
          strokeWidth: 1,
          name: "guid-line",
          dash: [0.15, 0],
          x: 0,
          y: lg.lineGuide,
        };
        setHlines([...guides, guide]);
      } else if (lg.orientation === "V") {
        let guide = {
          points: [0, -6000, 0, 6000],
          stroke: "#663399",
          strokeWidth: 1,
          name: "guid-line",
          dash: [0.15, 0],
          x: lg.lineGuide,
          y: 0,
        };
        setVlines([...guides, guide]);
      }
    });
  }
};

const onDragMove = (e, setHlines, setVlines, guidelines) => {
  // clear all previous lines on the screen
  // layer.find('.guid-line').destroy();

  // find possible snapping lines
  let lineGuideStops = getLineGuideStops(e.target, guidelines);
  // find snapping points of current object
  let itemBounds = getObjectSnappingEdges(e.target);

  // now find where can we snap current object
  let guides = getGuides(lineGuideStops, itemBounds);

  // do nothing of no snapping
  if (!guides.length) {
    return;
  }

  drawGuides(guides, setHlines, setVlines);

  let absPos = e.target.absolutePosition();
  // now force object position
  guides.forEach((lg) => {
    switch (lg.snap) {
      case "start": {
        switch (lg.orientation) {
          case "V": {
            absPos.x = lg.lineGuide + lg.offset;
            break;
          }
          case "H": {
            absPos.y = lg.lineGuide + lg.offset;
            break;
          }
          default:
            break;
        }
        break;
      }
      case "center": {
        switch (lg.orientation) {
          case "V": {
            absPos.x = lg.lineGuide + lg.offset;
            break;
          }
          case "H": {
            absPos.y = lg.lineGuide + lg.offset;
            break;
          }
          default:
            break;
        }
        break;
      }
      case "end": {
        switch (lg.orientation) {
          case "V": {
            absPos.x = lg.lineGuide + lg.offset;
            break;
          }
          case "H": {
            absPos.y = lg.lineGuide + lg.offset;
            break;
          }
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
  });
  e.target.absolutePosition(absPos);
};

const onDragEnd = (e?: any, setHlines?: any, setVlines?: any) => {
  setHlines([]);
  setVlines([]);
};

export { onDragMove, onDragEnd };

