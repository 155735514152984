import styled from "styled-components";

export const GridItem = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 5px;

  display: flex;
  position: relative;

  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 12px;
    height: 12px;
    cursor: pointer !important;
    z-index: 300;
  }

  :hover {
    background-color: #ebebec;
  }
`;

export const GridItemFix = styled.div`
  width: 80px;
  height: 80px;
  max-height: 128px;
  max-width: 133px;
  min-height: 128px;
  min-width: 133px;
  display: flex;
  position: relative;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;

  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 12px;
    height: 12px;
    cursor: pointer !important;
    z-index: 300;
  }
`;
