import styled from "styled-components";

export const TitleBox = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  background-color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-bottom: 1px solid #ebebec;
  justify-content: space-between;
`;

export const Button = styled.button`
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
`;

