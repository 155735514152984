import React from "react";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";

import { Container } from "./styles";

const EditorLoadPage: React.FC = () => {
  return (
    <Container>
      <ContractAnimation />
    </Container>
  );
};

export default EditorLoadPage;
