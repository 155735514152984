import styled from "styled-components";

export const Container = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-bottom: solid 1px #ebebec;
  position: relative;

  p {
    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  :hover {
    background-color: #f5f5f5;
  }
`;

export const TitleItem = styled.div`
  padding-inline: 16px;

  display: flex;
  align-items: center;
  gap: 14px;

  p {
    height: 100%;
    width: 100%;

    cursor: pointer;
  }

  img,
  svg {
    width: 24px;
    height: 24px;

    cursor: pointer;
  }
`;

export const OptionsItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  width: 40px;
  height: 40px;

  position: absolute;
  bottom: 1px;
  right: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  span {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary90 : theme.colors.black75};
    width: 3px;
    height: 3px;
    border-radius: 50%;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black10};
    border-radius: 5px;
  }
`;

export const Modal = styled.div`
  position: absolute;
  z-index: 2;
  top: 40px;
  right: 0;

  background-color: ${({ theme }) => theme.colors.white100};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 117px;

  div {
    height: 44px;
    padding: 15px;

    display: flex;
    align-items: center;
    gap: 8px;

    font-family: "Ubuntu";
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black75};

    :first-child {
      svg {
        transform: scale(1.3);
      }
    }

    :hover {
      background-color: ${({ theme }) => theme.colors.black5};
    }
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;
