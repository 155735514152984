import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  height: 100%;
  width: 100%;
`;

export const EmptyArea = styled.div`
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 1.5rem;
  }
`;
