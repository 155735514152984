import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";
import icon from "./assets/background-button.svg";

interface BoxColorProps {
  color: string;
}

export const ContainerModal = styled.form`
  max-width: 300px;
  height: 170px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  -webkit-box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.11);
  position: relative;
  border-radius: 10px;
  padding: 0 1.6rem;
  justify-content: center;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  min-height: 73px;
  border-radius: 10px;
  align-items: center;
  border: solid 1px red;
  padding: 0 1.6rem;
  line-height: 190%;
  justify-content: center;
`;

export const Text = styled.span`
  color: black;
  font-size: 1.4rem;
  text-align: center;
  max-width: 783px;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  background: red;
  width: 100%;
  justify-content: space-between;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 227px;
  padding: 1rem 2rem;
  border-radius: 20px;

  &::placeholder {
    font-size: 1.6rem;
    font-weight: 400;
    color: ${({ theme }: Theme) => theme.colors.gray900};
  }
`;

export const HStack = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;

export const HStackBtn = styled.div<{ srcImg: string; isActive?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .button-transparency {
    width: 30px;
    height: 30px;
    background-image: url(${({ srcImg }) => srcImg && srcImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 2rem;
    border: ${({ isActive }) => (isActive ? "2px solid #663399" : "")};
    border-radius: ${({ isActive }) => (isActive ? "8px" : "")};
  }
`;

export const Button = styled.button`
  width: 120px;
  height: 40px;
  background: ${({ theme }) => theme.colors.background};
  color: white;
  border-radius: 5px;

  transition: background 200ms linear;

  &:hover {
    background: black;
    color: white;
  }
`;

export const InputHex = styled.input`
  width: 100px;
  height: 30px;
  padding: 0 0.5rem;

  &::placeholder {
    color: #c4c4c4;
    opacity: 0.6;
  }
`;

export const BoxColor = styled.div<BoxColorProps>`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${({ theme, color }) =>
    color == ""
      ? "conic-gradient(red, yellow, lime, aqua, blue, magenta, red)"
      : "red"};
  cursor: not-allowed;
  border: 1px solid black;
`;

export const ButtonBack = styled.button`
  width: 120px;
  height: 40px;
  background: white;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
`;
