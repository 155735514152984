import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const TextBox = styled.div`
  display: flex;
  justify-content: space-between;

  label {
    font-family: "Ubuntu";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }

  p {
    font-family: "Ubuntu";
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};

    cursor: pointer;
  }
`;
export const InputBox = styled.div<{ error: boolean }>`
  position: relative;

  input {
    width: 100%;
    max-height: 40px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.colors.error100 : theme.colors.black50};

    :focus {
      border: 1px solid
        ${({ error, theme }) =>
          error ? theme.colors.error100 : theme.colors.focus};
    }
  }

  img {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 24px;
    height: 24px;

    cursor: pointer;
  }
`;
