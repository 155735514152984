import { Box, Dialog, Grid, Stack } from "@mui/material";
import {
  ContainerButton,
  Header,
  Requirement,
  RequirementContent,
  RequirementHeader,
  RequirementsContainer,
  SaveButton,
  Subtitle,
  Label,
  RequirementLabel,
  StatusLabel,
  ReviewInput,
  LoadingContainer
} from "./styles";
import { GrClose } from "react-icons/gr";
import { BiInfoCircle } from "react-icons/bi";
import { IconCloseWrapper } from "../../styles";
import DocApprovedIcon from "../ProjectDetails/DocApprovedIcon";
import DocRejectedIcon from "../ProjectDetails/DocRejectedIcon";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import Tooltip from "../../../../editor/components/Tooltip";

interface RequirementData {
  id: string;
  description: string;
  status: string;
  notes: string;
  name: string;
}

interface ResponseData {
  flow_id: string;
  project_id: string;
  step_id: string;
  step_name: string;
  approver_id: string;
  approver_status: string;
  document_file_url: string;
  requirements: RequirementData[];
  project_notes: string;
  project_status: string;
}

type Props = {
  open: boolean;
  onClose: (next?: boolean) => void;
  approver_id: string;
};

const ReviewForm: React.FC<Props> = ({ open, onClose, approver_id }: Props) => {
  const [requirements, setRequirements] = useState<RequirementData[]>([]);
  const [stepName, setStepName] = useState("Revisão de Documentos");
  const [fileUrl, setFileUrl] = useState("");
  const [approval_project_id, setApprovalProjectId] = useState("");
  const [approval_flow_id, setApprovalFlowId] = useState("");
  const [approver_status, setApproverStatus] = useState("");
  const [project_status, setProjectStatus] = useState("");
  const [project_notes, setProjectNotes] = useState("");
  const [loading, setLoading] = useState(true);
  const onRefuse = (id: string) => {
    setRequirements(
      requirements.map(requirement =>
        requirement.id === id
          ? {
              ...requirement,
              status: requirement.status === "REFUSED" ? "PENDING" : "REFUSED"
            }
          : requirement
      )
    );
  };
  const onApprove = (id: string) => {
    setRequirements(
      requirements.map(requirement =>
        requirement.id === id
          ? {
              ...requirement,
              status: requirement.status === "APPROVED" ? "PENDING" : "APPROVED"
            }
          : requirement
      )
    );
  };

  const onNotesChange = (id: string, notes: string) => {
    setRequirements(
      requirements.map(requirement =>
        requirement.id === id
          ? {
              ...requirement,
              notes
            }
          : requirement
      )
    );
  };

  const onSend = async () => {
    try {
      setLoading(true);
      setApproverStatus("APPROVED");
      const request = {
        flow_approver_id: approver_id,
        requirement_reviews: requirements.map(requirement => {
          return {
            id: uuidv4(),
            requirement_id: requirement.id,
            status: requirement.status,
            notes: requirement.notes || ""
          };
        })
      };
      const response = await api.post(
        `/approval-projects/${approval_project_id}/flow/${approval_flow_id}/review`,
        request
      );

      if (response.status !== 200) throw new Error("Erro ao enviar revisão");
      setLoading(false);
      onClose(true);
      toast.success("Revisão enviada com sucesso");
    } catch (error) {
      toast.error("Erro ao enviar revisão");
    }
  };

  useEffect(() => {
    const fetchFile = async (project_id: string, flow_id: string) => {
      const response = await api.get(
        `/approval-projects/${project_id}/flows/${flow_id}/document-pdf`
      );

      // Convert base64 to binary
      const binary = atob(response.data);
      const array = [];

      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }

      // Create a blob from the binary data
      const blob = new Blob([new Uint8Array(array)], {
        type: "application/pdf"
      });

      setFileUrl(URL.createObjectURL(blob));
      setLoading(false);
    };
    const fetchRequeriments = async () => {
      setLoading(true);
      try {
        const data = await api.get(
          `/approval-projects/approver/${approver_id}/get-requirements`
        );
        const response = data.data as ResponseData;
        setStepName(response.step_name);
        // setFileUrl(response.document_file_url);
        setRequirements(response.requirements);
        setApprovalProjectId(response.project_id);
        setApprovalFlowId(response.flow_id);
        setApproverStatus(response.approver_status);
        setProjectNotes(response.project_notes);
        setProjectStatus(response.project_status);
        fetchFile(response.project_id, response.flow_id);
      } catch (error) {
        toast.error("Ocorreu um erro buscar os requisitos.");
      }
    };
    fetchRequeriments();
  }, [approver_id]);

  const isSubmitDisabled =
    requirements.some(requirement => requirement.status === "PENDING") ||
    approver_status !== "PENDING" ||
    requirements.some(
      requirement => requirement.status === "REFUSED" && !requirement.notes
    );

  return (
    <>
      {loading && (
        <LoadingContainer>
          <ContractAnimation />
        </LoadingContainer>
      )}

      {!loading && (
        <Dialog onClose={onClose} open={open} maxWidth={"xl"}>
          <Grid container style={{ minWidth: "80vw" }}>
            <Grid item xs={8}>
              <iframe
                src={fileUrl}
                title="File Preview"
                width="100%"
                height="500px"
                style={{ border: "none" }}
              ></iframe>
            </Grid>
            <Grid item xs={4} position={"relative"}>
              <Header>
                <Subtitle> {stepName}</Subtitle>
                <IconCloseWrapper onClick={() => onClose()}>
                  <GrClose />
                </IconCloseWrapper>
              </Header>

              <RequirementsContainer>
                {requirements.map(requirement => (
                  <Requirement key={requirement.id}>
                    <RequirementHeader>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"4px"}
                      >
                        <RequirementLabel>{requirement.name}</RequirementLabel>
                        <Tooltip
                          description={requirement.description}
                          placement="bottom-start"
                        >
                          <figure>
                            <BiInfoCircle fontSize={"22px"} color="#999C9F" />
                          </figure>
                        </Tooltip>
                      </Stack>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          padding={"4px"}
                          sx={{
                            cursor:
                              approver_status !== "PENDING"
                                ? " not-allowed"
                                : "pointer",
                            "&:hover": {
                              backgroundColor:
                                approver_status !== "PENDING"
                                  ? "transparent"
                                  : "#F5F5F5",
                              borderRadius: "5px"
                            }
                          }}
                          onClick={() => onApprove(requirement.id)}
                        >
                          <DocApprovedIcon
                            color={
                              requirement.status === "APPROVED"
                                ? "#007B36"
                                : "#676B70"
                            }
                          />
                          <StatusLabel
                            color={
                              requirement.status === "APPROVED"
                                ? "#007B36"
                                : "#676B70"
                            }
                          >
                            Aceitar
                          </StatusLabel>
                        </Stack>

                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          padding={"4px"}
                          sx={{
                            cursor:
                              approver_status !== "PENDING"
                                ? " not-allowed"
                                : "pointer",
                            "&:hover": {
                              backgroundColor:
                                approver_status !== "PENDING"
                                  ? "transparent"
                                  : "#F5F5F5",
                              borderRadius: "5px"
                            }
                          }}
                          onClick={() => onRefuse(requirement.id)}
                        >
                          <DocRejectedIcon
                            color={
                              requirement.status === "REFUSED"
                                ? "#DE2D2D"
                                : "#676B70"
                            }
                          />
                          <StatusLabel
                            color={
                              requirement.status === "REFUSED"
                                ? "#DE2D2D"
                                : "#676B70"
                            }
                          >
                            Rejeitar
                          </StatusLabel>
                        </Stack>
                      </Stack>
                    </RequirementHeader>
                    <RequirementContent>
                      <ReviewInput
                        disabled={approver_status !== "PENDING"}
                        onChange={e =>
                          onNotesChange(requirement.id, e.target.value)
                        }
                        value={requirement.notes}
                        style={{
                          cursor:
                            approver_status !== "PENDING"
                              ? "not-allowed"
                              : "text"
                        }}
                      />
                    </RequirementContent>
                  </Requirement>
                ))}
              </RequirementsContainer>
              <ContainerButton>
                <Stack width={"100%"} spacing={1}>
                  <Box
                    border={"1px solid #EBEBEC"}
                    borderRadius={"5px"}
                    width={"100%"}
                    padding={"12px"}
                    fontSize={"14px"}
                    color={"#676B70"}
                  >
                    {project_notes || "Sem instruções específicas"}
                  </Box>
                  <SaveButton
                    onClick={() => onSend()}
                    disabled={isSubmitDisabled}
                  >
                    {project_status === "CANCELED"
                      ? "Cancelado"
                      : approver_status !== "PENDING"
                      ? "Avaliado"
                      : "Enviar"}
                  </SaveButton>
                </Stack>
              </ContainerButton>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default ReviewForm;
