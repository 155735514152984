import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  color: #a5a5a5;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;

  pointer-events: none;
  user-select: none;

  svg {
    width: 328px;
    height: 328px;
  }

  span {
    font-size: 14px;
  }

  @media (max-width: 1600px) {
    svg {
      width: 228px;
      height: 228px;
    }
  }

  @media (max-width: 1366px) {
    font-size: 16px;
    span {
      font-size: 12px;
    }
  }
`;
