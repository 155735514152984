import styled from "styled-components";
import { lighten } from "polished";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  svg {
    width: 24px;
    height: 24px;
  }

  :hover {
    background-color: #ebebec;
  }
`;

export const SettingsBox = styled.div`
  width: 214px;
  background: #ffffff;
  border-radius: 5px;
  padding-block: 10px;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);

  position: absolute;
  top: 40px;
  left: -102px;
`;

export const HeaderOfBox = styled.div`
  width: 100%;
  padding: 0 10px 10px 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 100%;
  }
`;

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SwitchContain = styled.div`
  width: 123px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 15px;

  & > span {
    display: flex;
    align-items: center;

    font-size: 13px;
    font-weight: 400;
    color: #343a40;

    cursor: default;
    user-select: none;
  }

  .switch-btn {
    .react-switch-handle {
      width: 27px !important;
      height: 27px !important;
    }
  }
`;

export const Title = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  color: #343a40;

  padding-left: 10px;
  margin-top: 10px;
`;

export const CheckContainNum = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  border-bottom: solid 1px #ebebec;
  padding: 10px;

  input {
    accent-color: ${({ theme }) => theme.colors.primary};
    width: 24px;
    height: 24px;
    border-radius: 50% !important;
    border: none;
    margin-right: 1rem;

    cursor: pointer;
  }

  div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;

    /* gray-dark */

    color: #343a40;

    cursor: default;
    user-select: none;
  }
`;

export const CheckContain = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  padding: 10px;

  input {
    accent-color: ${({ theme }) => theme.colors.primary};
    width: 24px;
    height: 24px;
    border-radius: 50% !important;
    border: none;
    margin-right: 1rem;

    cursor: pointer;

    :hover {
      border: 1px solid #000;
    }
  }

  div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    /* gray-dark */

    color: #343a40;

    cursor: default;
    user-select: none;
  }
`;

export const CheckFirstPage = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  margin-top: 1.5rem;
  padding-inline: 10px;

  > input {
    width: 20px;
    height: 20px;
    accent-color: ${({ theme }) => theme.colors.primary};
    margin-right: 1rem;

    cursor: pointer;
  }

  span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    /* gray-dark */

    color: #343a40;

    cursor: default;
    user-select: none;
  }
`;

export const ButtonsBox = styled.div`
  width: 100%;
  height: 100%;
  min-height: 40px;
  margin-top: 20px;
  padding-inline: 10px;
  display: flex;
  gap: 10px;

  & > button {
    :hover {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    }
  }
`;

export const ButtonApply = styled.button<{ color: string }>`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, color }) =>
    color === "black" ? "#000" : theme.colors.primary};
  color: #fff;
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  :hover {
    background-color: ${({ theme, color }) =>
      color === "black"
        ? lighten(0.15, "#000")
        : lighten(0.15, theme.colors.primary)};
  }
`;
