import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-inline: 10px;
`;

export const Header = styled.header`
  width: 100%;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 1;

  font-size: 18px;
  font-weight: 500;
  line-height: 150%;

  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  line-height: 100%;

  display: flex;
  align-items: center;
  justify-content: start;

  div {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

export const BoxComments = styled.ul<{ page: boolean }>`
  border-bottom: ${({ page }) => page && " solid 1px #c4c4c4"};
`;
