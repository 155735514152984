import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
`;

export const Box = styled.main`
  width: 280px;
  height: 132px;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  flex-direction: column;
  padding: 2rem 2rem;

  .title {
    color: #639;
    text-align: center;
    font-family: Ubuntu;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }

  span {
    color: var(--preto-preto-100, #343a40);
    text-align: center;
    /* Texto corrido */
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
`;

export const HStack = styled.div`
  width: 100%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 1rem;
`;
