import styled from "styled-components";

export const Container = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-spinner {
    width: 20px;
    height: 20px;
    border: 1.5px solid #000;
    border-top: 2.5px solid ${({ theme }) => theme.colors.honey};
    border-radius: 50%;
    animation: spinner 260ms linear infinite;
  }
`;
