import styled from "styled-components";
import { Theme } from "../../../../../../dtos/theme";

interface BoxProps {
  grab?: boolean;
}

export const Box = styled.div<BoxProps>`
  width: 80px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${({ grab }) => (grab ? "grabbing" : "grab")};

  & .img-img {
    object-fit: cover;
    display: flex;
    height: 128px;
    width: 100%;
    border-radius: 10px;
  }

  .icons-elements-sidebar {
    width: 60px;
    height: 60px;
  }
`;
