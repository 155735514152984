import styled from "styled-components";
import { Theme } from "../../../../../../dtos/theme";

export const FlexRow = styled.div`
  display: flex;
  max-width: 190px;
  width: 100%;
  justify-content: space-between;
`;

export const TextPage = styled.div`
  color: ${({ theme }: Theme) => theme.colors.gray300};

  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`;
