import styled from "styled-components";

export const Container = styled.button<{
  typeStyle: "purple" | "white";
}>`
  width: 100%;
  max-height: 40px;
  background-color: ${({ typeStyle, theme }) =>
    typeStyle === "purple" ? theme.colors.primary90 : theme.colors.white100};
  color: ${({ typeStyle, theme }) =>
    typeStyle === "purple" ? theme.colors.white100 : theme.colors.primary90};
  padding: 13px 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary90};

  font-family: "Ubuntu";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${({ typeStyle, theme }) =>
      typeStyle === "purple" ? theme.colors.primary : theme.colors.white100};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }
`;
