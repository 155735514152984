import styled from "styled-components";
import { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  colorScheme?: "purple" | "default";
}
export const Button = styled.button<ButtonProps>`
  width: 100%;
  background: ${props =>
    props.colorScheme === "purple" ? " #663399" : "#343A40"};
  border-radius: 5px;
  color: white;
  font-family: "Roboto";
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 153px;
  padding: 2px 10px 10px 10px;
`;

export const Title = styled.div`
  font-family: "Roboto", sans-serif;
  color: #343a40;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
  @media (max-width: 1366px) {
    font-size: 1.2rem;
  }
`;

export const Advice = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 8px;
`;

export const HeaderSection = styled.div`
  padding: 20px;
  width: 800px;
`;

export const Link = styled.div`
  display: inline-flex;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: #676b70;

  :hover {
    background-color: #f5f5f5;
  }

  cursor: pointer;
`;

export const Item = styled.option<{ active: boolean }>`
  display: flex;
  justify-content: space-between;

  padding: 11px 16px !important;
  height: 44.5px !important;

  font-family: "Ubuntu";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  color: ${({ theme }) => theme.colors.black100};
  cursor: pointer;

  background-color: ${({ theme, active }) =>
    active ? theme.colors.black5 : theme.colors.white100};

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }
`;

export const SelectBox = styled.div`
  width: 100%;
  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }
  select {
    width: 100%;
    max-height: 40px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.black50};
    margin-top: 5px;

    @media (min-width: 1920px) {
      select {
        height: 43px;
        max-height: 43px;
      }
    }
    
    select:focus {
      border: 1px solid ${({ theme }) => theme.colors.focus};
    }
    
    select::placeholder {
      color: #d6d8d9;
    }
  }
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;

  @media (max-width: 425px) {
    align-items: center;
    justify-content: center;
  }
`;

