/*
this one is carrosel control of templates
then for each category we have that show to user 
our templates. 
*/

import {
  Heading,
  Wrapper,
  Category,
  HeaderWrapper,
  Main,
  SearchGrid,
} from "./styles";
import { useState, useCallback, useEffect } from "react";
// import api from "../../../../services/api";
import { useMainHook } from "../../../../hooks/main";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { Card } from "./components/card";
import { LeftArrow, RightArrow } from "./components/Arrows/arrows";
import { ContainerLoading } from "../LoadingEditor/styles";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import { useTemplates } from "../../hooks/templates";
import MoonLoader from "react-spinners/MoonLoader";
import SearchBarEditor from "../SearchBarEditor";
import { IScreenCreateNewProject } from "../../../dashboard/createproject/pages";
import PreviewTemplateModal from "../../../dashboard/components/PreviewTemplateModal";
import api from "../../../../services/api";
//set up interfaces
interface ScrollTemplatesProps {
  image?: string;
  text?: string;
}
//scroll visibility type
type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const ScrollTemplates: React.FC<ScrollTemplatesProps> = () => {
  //hooks that are necessary
  const { dragUrl, debouncedSearchTerm, loadingDebounce, canvasIsLoading } = useMainHook();
  const { loading, templatesByCategories, endPages, setLoading } =
    useTemplates();
  //set up states
  const [selected, setSelected] = useState<string[]>([]);
  const [bitsTemplateSearch, setBitsTemplateSearch] = useState<
    IScreenCreateNewProject[]
  >([]);
  // can save and restore position if needed
  const [position, setPosition] = useState(100);
  const isItemSelected = (id: string): boolean =>
    !!selected.find((el) => el === id);
  // can change position on click from your id
  const handleItemClick =
    (itemId: string) =>
    ({ getItemById }: scrollVisibilityApiType) => {
      const itemSelected = isItemSelected(itemId);
      //which element will move
      setSelected((currentSelected: string[]) =>
        itemSelected
          ? currentSelected.filter((el) => el !== itemId)
          : currentSelected.concat(itemId)
      );
    };
  //if we need disable scroll or enable
  // const { disableScroll, enableScroll } = usePreventBodyScroll();
  const savePosition = useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) =>
      !!scrollContainer.current &&
      setPosition(scrollContainer.current.scrollLeft),
    []
  );
  const getTemplateSearch = useCallback(async () => {
    setLoading(true);
    const response = await api.get("bits-templates", {
      params: {
        search: debouncedSearchTerm ? debouncedSearchTerm : null,
        pagination: null,
      },
    });

    setBitsTemplateSearch(response.data.dataArray);
    setLoading(false);
  }, [debouncedSearchTerm, setLoading]);

  useEffect(() => {
    if (debouncedSearchTerm.length > 0) {
      getTemplateSearch();
    }
  }, [debouncedSearchTerm]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [preview, setPreview] = useState({ pages: [], title: "" });
  const [loadingPreview, setLoadingPreview] = useState(true);

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const handleChooseTemplate = useCallback(async (templateId) => {
    try {
      setLoadingPreview(true);
      setModalIsOpen(true);

      const response = await api.get(`bits-templates/${templateId}`);
      const { template, title } = response.data.bits_template;

      setPreview({ pages: template.arrayOfPages, title: title });
      setLoadingPreview(false);
    } catch (error) {
      console.error("error getting document to preview");
      setLoadingPreview(false);
    }
  }, []);

  return (
    <Wrapper>
      <HeaderWrapper>
        <SearchBarEditor description="Digite aqui para encontrar o template que procura." />
      </HeaderWrapper>
      <Main>
        {(loading || canvasIsLoading) ? (
          <ContainerLoading>
            <ContractAnimation />
          </ContainerLoading>
        ) : debouncedSearchTerm.length === 0 ? (
          templatesByCategories.map((objectCategory) => (
            <Category key={objectCategory.id}>
              {objectCategory.templates.length > 0 && (
                <>
                  <Heading> {objectCategory.category} </Heading>
                  <ScrollMenu
                    LeftArrow={
                      <LeftArrow
                        numberOftemplate={objectCategory.templates.length}
                      />
                    }
                    RightArrow={
                      <RightArrow
                        numberOftemplate={objectCategory.templates.length}
                      />
                    }
                    onScroll={savePosition}
                    transitionDuration={2000}
                    wrapperClassName="exclude-scroll"
                  >
                    {objectCategory.templates.length > 0 &&
                      objectCategory?.templates?.map((template) => (
                        <Card
                          key={template.id}
                          title={template.id}
                          itemId={template.id} // NOTE: itemId is required for track items
                          handleChooseTemplate={handleChooseTemplate}
                          selected={isItemSelected(template.id)}
                          src={template.thumbnail_url}
                          dragUrl={dragUrl}
                        />
                      ))}
                  </ScrollMenu>
                </>
              )}
            </Category>
          ))
        ) : (
          <SearchGrid>
            {bitsTemplateSearch?.map((template) => (
              <Card
                key={template.id}
                title={template.id}
                itemId={template.id} // NOTE: itemId is required for track items
                handleChooseTemplate={handleChooseTemplate}
                selected={isItemSelected(template.id)}
                src={template.thumbnail_url}
                dragUrl={dragUrl}
              />
            ))}
          </SearchGrid>
        )}

        {templatesByCategories.length > 0 &&
          !endPages &&
          debouncedSearchTerm.length === 0 && (
            <ContainerLoading id="sentinela-templates">
              <MoonLoader size={18} color="#000" />
            </ContainerLoading>
          )}
      </Main>

      <PreviewTemplateModal
        open={modalIsOpen}
        handleCloseModal={handleCloseModal}
        pagesToBeRendered={preview.pages}
        title={preview.title}
        loadingPreview={loadingPreview}
        templateId={templateId}
        texButton="Usar Template"
        local="Editor"
      />
    </Wrapper>
  );
};

export { ScrollTemplates };
