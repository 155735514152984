import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  min-height: 100vh;
  margin-bottom: 30px;

  & > h3 {
    text-align: center;
    margin-inline: 8px;
  }
`;

export const Form = styled.form`
  width: 405px;

  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 431px) {
    width: 87%;
  }
`;

export const PasswordBox = styled.div``;

export const InputId = styled.div`
  & > div {
    display: flex;
    gap: 9px;
  }
`;

export const Terms = styled.div`
  display: flex;
  gap: 10px;
  margin-block: 15px;

  input {
    width: 30px;
    height: 24px;
    accent-color: ${({ theme }) => theme.colors.primary};
    :checked {
      background: ${({ theme }) => theme.colors.primary};
      border: 1px solid #c4c4c4;
      border-radius: 3px;
    }
  }

  p {
    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.black100};
  }
`;

export const FooterForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const InputEmail = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  label {
    font-family: "Ubuntu";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }

  div {
    position: relative;
  }

  input {
    width: 100%;
    max-height: 40px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.colors.error100 : theme.colors.black50};
    background-color: ${({ error, theme }) => error && theme.colors.error10};

    :focus {
      border: 1px solid
        ${({ error, theme }) =>
          error ? theme.colors.error100 : theme.colors.focus};
    }
  }

  img {
    width: 20px;
    height: 20px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
`;
