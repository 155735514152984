import React, { useEffect } from "react";

import logoIcon from "../../assets/logo-ux-white.svg";
import computerUxIcon from "../../assets/computerUx.svg";
import uxDecoration from "../../assets/decoration-uxdoc.svg";

import {
  Animation,
  Container,
  ContentMobile,
  DecorationWrapper,
  Description
} from "./styles";
import { ContractAnimation } from "../../../ContractAnimation";
import UxDocLogo from "../../../../assets/customIcons/UxDocLogo";
import UxDocLogoLarge from "../../../UxDocLogoLarge";
import Lottie from "lottie-react";
import AnimationDesk from "../../assets/Animation_desktop.json";

const MobileScreen: React.FC = () => {
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <ContentMobile>
        <ContractAnimation />
      </ContentMobile>
    );
  }

  return (
    <Container>
      <UxDocLogoLarge />
      <Animation>
        {" "}
        <Lottie animationData={AnimationDesk} />{" "}
      </Animation>
      <Description>
        Acesse o UX DOC <br /> a partir de um dispositivo desktop e comece a
        criar agora!
      </Description>
    </Container>
  );
};

export default MobileScreen;
