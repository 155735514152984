import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 25px;
  height: 25px;
  fill: currentColor;
`;

type Props = {
  color?: string;
};

const DocRejectedIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 25 25" color={color || "#DE2D2D"}>
    <path
      d="M5.97407 4.43457H18.4185C19.4052 4.43457 20.1963 5.22568 20.1963 6.21235V13.1457C19.6541 12.8346 19.0585 12.6123 18.4185 12.5057V6.21235H5.97407V18.6568H12.2674C12.3741 19.2968 12.5963 19.8923 12.9074 20.4346H5.97407C4.9874 20.4346 4.19629 19.6435 4.19629 18.6568V6.21235C4.19629 5.22568 4.9874 4.43457 5.97407 4.43457ZM7.75184 8.87901C7.75184 8.38809 8.14981 7.99012 8.64073 7.99012H15.7518C16.2428 7.99012 16.6407 8.38809 16.6407 8.87901C16.6407 9.36993 16.2428 9.7679 15.7518 9.7679H8.64073C8.14981 9.7679 7.75184 9.36993 7.75184 8.87901ZM7.75184 12.4346C7.75184 11.9436 8.14981 11.5457 8.64073 11.5457H15.6807C16.2109 11.5457 16.6407 11.9755 16.6407 12.5057C15.8852 12.6301 15.1918 12.9146 14.5785 13.3235H8.64073C8.14981 13.3235 7.75184 12.9255 7.75184 12.4346ZM7.75184 15.9901C7.75184 15.4992 8.14981 15.1012 8.64073 15.1012H12.1963V16.879H8.64073C8.14981 16.879 7.75184 16.481 7.75184 15.9901Z"
      fill="currentColor"
    />
    <path
      d="M19.6846 15.6406C19.6532 15.6092 19.6159 15.5842 19.5748 15.5671C19.5337 15.5501 19.4897 15.5413 19.4452 15.5413C19.4008 15.5413 19.3568 15.5501 19.3157 15.5671C19.2746 15.5842 19.2373 15.6092 19.2059 15.6406L17.5457 17.2974L15.8855 15.6372C15.854 15.6058 15.8167 15.5809 15.7757 15.5639C15.7346 15.5468 15.6906 15.5381 15.6461 15.5381C15.6017 15.5381 15.5576 15.5468 15.5166 15.5639C15.4755 15.5809 15.4382 15.6058 15.4068 15.6372C15.3753 15.6687 15.3504 15.706 15.3334 15.747C15.3164 15.7881 15.3076 15.8321 15.3076 15.8766C15.3076 15.921 15.3164 15.9651 15.3334 16.0061C15.3504 16.0472 15.3753 16.0845 15.4068 16.1159L17.067 17.7761L15.4068 19.4364C15.3753 19.4678 15.3504 19.5051 15.3334 19.5462C15.3164 19.5872 15.3076 19.6313 15.3076 19.6757C15.3076 19.7202 15.3164 19.7642 15.3334 19.8053C15.3504 19.8463 15.3753 19.8836 15.4068 19.9151C15.4382 19.9465 15.4755 19.9714 15.5166 19.9884C15.5576 20.0055 15.6017 20.0142 15.6461 20.0142C15.6906 20.0142 15.7346 20.0055 15.7757 19.9884C15.8167 19.9714 15.854 19.9465 15.8855 19.9151L17.5457 18.2549L19.2059 19.9151C19.2373 19.9465 19.2746 19.9714 19.3157 19.9884C19.3568 20.0055 19.4008 20.0142 19.4452 20.0142C19.4897 20.0142 19.5337 20.0055 19.5748 19.9884C19.6159 19.9714 19.6532 19.9465 19.6846 19.9151C19.716 19.8836 19.741 19.8463 19.758 19.8053C19.775 19.7642 19.7837 19.7202 19.7837 19.6757C19.7837 19.6313 19.775 19.5872 19.758 19.5462C19.741 19.5051 19.716 19.4678 19.6846 19.4364L18.0244 17.7761L19.6846 16.1159C19.8136 15.9869 19.8136 15.7696 19.6846 15.6406Z"
      fill="currentColor"
    />
    <path
      d="M15.3144 15.1289C15.4094 15.0896 15.5112 15.0693 15.614 15.0693C15.7168 15.0693 15.8186 15.0896 15.9136 15.1289C16.0086 15.1683 16.0949 15.226 16.1676 15.2987L17.5139 16.6449L18.8592 15.3024C18.9318 15.2297 19.0184 15.1717 19.1133 15.1323C19.2083 15.0929 19.3102 15.0726 19.4131 15.0726C19.516 15.0726 19.6179 15.0929 19.713 15.1323C19.808 15.1717 19.8944 15.2295 19.9671 15.3024C20.2693 15.605 20.2692 16.1034 19.9668 16.4059L18.6208 17.7518L19.9668 19.0978C20.0395 19.1705 20.0971 19.2568 20.1365 19.3518C20.1758 19.4468 20.1961 19.5486 20.1961 19.6514C20.1961 19.7542 20.1758 19.856 20.1365 19.951C20.0971 20.046 20.0395 20.1323 19.9668 20.205C19.8941 20.2777 19.8077 20.3354 19.7127 20.3748C19.6178 20.4141 19.5159 20.4344 19.4131 20.4344C19.3103 20.4344 19.2085 20.4141 19.1135 20.3748C19.0185 20.3354 18.9322 20.2777 18.8595 20.205L17.5136 18.8591L16.1676 20.205C16.0949 20.2777 16.0086 20.3354 15.9136 20.3748C15.8186 20.4141 15.7168 20.4344 15.614 20.4344C15.5112 20.4344 15.4094 20.4141 15.3144 20.3748C15.2194 20.3354 15.1331 20.2777 15.0604 20.205C14.9877 20.1323 14.93 20.046 14.8907 19.951C14.8513 19.856 14.8311 19.7542 14.8311 19.6514C14.8311 19.5486 14.8513 19.4468 14.8907 19.3518C14.93 19.2568 14.9877 19.1705 15.0604 19.0978L16.4063 17.7518L15.0604 16.4059C14.9877 16.3332 14.93 16.2469 14.8907 16.1519C14.8513 16.0569 14.8311 15.9551 14.8311 15.8523C14.8311 15.7495 14.8513 15.6477 14.8907 15.5527C14.93 15.4577 14.9877 15.3714 15.0604 15.2987C15.1331 15.226 15.2194 15.1683 15.3144 15.1289Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default DocRejectedIcon;
