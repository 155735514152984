import React from "react";

// import { Container } from './styles';
interface BoldProps {
  isActive: boolean;
}

const Strikeness: React.FC<BoldProps> = ({ isActive }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 16 19"
      fill="none"
      style={{width: "14px"}}
    >
      <path
        d="M0.999942 12.0938C0.716609 12.0938 0.478942 11.9857 0.286942 11.7697C0.0949424 11.5538 -0.000724242 11.2867 -5.75749e-05 10.9688C-5.75749e-05 10.65 0.0959425 10.3826 0.287943 10.1666C0.479943 9.95062 0.717276 9.843 0.999942 9.84375H14.9999C15.2833 9.84375 15.5209 9.95175 15.7129 10.1678C15.9049 10.3837 16.0006 10.6508 15.9999 10.9688C15.9999 11.2875 15.9039 11.5549 15.7119 11.7709C15.5199 11.9869 15.2826 12.0945 14.9999 12.0938H0.999942ZM6.49994 7.59375V4.21875H2.49994C2.08328 4.21875 1.72894 4.0545 1.43694 3.726C1.14494 3.3975 0.999276 2.99925 0.999943 2.53125C0.999943 2.0625 1.14594 1.66388 1.43794 1.33538C1.72994 1.00688 2.08394 0.843003 2.49994 0.843753H13.4999C13.9166 0.843753 14.2709 1.008 14.5629 1.3365C14.8549 1.665 15.0006 2.06325 14.9999 2.53125C14.9999 3 14.8539 3.39863 14.5619 3.72713C14.2699 4.05563 13.9159 4.2195 13.4999 4.21875H9.49994V7.59375H6.49994ZM6.49994 14.3437H9.49994V17.1562C9.49994 17.625 9.35394 18.0236 9.06194 18.3521C8.76994 18.6806 8.41594 18.8445 7.99994 18.8437C7.58328 18.8437 7.22894 18.6795 6.93694 18.351C6.64494 18.0225 6.49928 17.6242 6.49994 17.1562V14.3437Z"
        fill={isActive ? "#663399" : "#343A40"}
      />
    </svg>
  );
};

export default Strikeness;
