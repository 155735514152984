import styled from "styled-components";
interface IContainerProps {
  direction: "Right" | "Left";
  disabled: boolean;
  isActive: boolean;
}

export const Container = styled.button<IContainerProps>`
  width: 24px;
  height: 24px;
  background-color: transparent;
  cursor: pointer;
  user-select: none;

  position: absolute;
  top: -17px;
  right: ${({ direction }) => (direction === "Left" ? "30px" : "0px")};
  display: ${({ isActive }) => (isActive ? "block" : "none")};

  transition: 0.3s;

  svg {
    width: 100%;
    height: 100%;
  }
`;
