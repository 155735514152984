import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import Editor from "../modules/editor/pages";
import { ContractAnimation } from "../shared/components/ContractAnimation";
import ChangePasswordPage from "../modules/user/premium/pages/ChangePasswordPage";
import ProfilePage from "../modules/user/premium/pages/ProfilePage";

import ErrorPage from "../modules/user/unknown/components/ErrorPage";
import SuspendedPage from "../modules/user/suspended/pages/SuspendedPage";
import Master from "../modules/dashboard/master/pages/Master";
import NewPassword from "../modules/user/unknown/login/pages/NewPassword";
import LoginLazy from "../modules/user/unknown/login/pages/LoginLazy";
import Login from "../modules/user/unknown/login/pages/Login";
import VerificationPaid from "../modules/user/unknown/Paiding/components/VerificationPaid";
import RedirectRegistration from "../modules/user/unknown/RedirectRegistration";
import ForgotPasswordScreen from "../modules/user/unknown/register/components/ForgotPasswordScreen";
import RegisterScreen from "../modules/user/unknown/register/pages";
import EditorLoadPage from "../modules/editor/components/EditorLoadPage";
import PageNotFound from "../shared/pages/PageNotFound";

import LoadingPaymentRenew from "../modules/user/suspended/components/LoadingPaymentRenew";
import TrialEnd from "../modules/user/unknown/TrialEnd";
import LoginOrganization from "../modules/user/unknown/login/pages/LoginOrganization";
import ForgotPasswordScreenOrganization from "../modules/user/unknown/register/components/ForgotPasswordScreenOrganization";
import NewPasswordOrganization from "../modules/user/unknown/login/pages/NewPasswordOrganization";
import ScreenHome from "../modules/dashboard/home/ScreenHome";
import ScreenCreateNewProject from "../modules/dashboard/createproject/pages";
import MyClausules from "../modules/dashboard/myclausules/pages";
import SharedWithMe from "../modules/dashboard/sharedwithme";
import OneClick from "../modules/dashboard/oneclick/pages";
import MyProjects from "../modules/dashboard/myprojects/pages";
import MyAutomations from "../modules/dashboard/myAutomations/pages";
import TeamListPage from "../modules/dashboard/Teams/pages/TeamListPage";
import TeamSpecificPage from "../modules/dashboard/Teams/pages/TeamSpecificPage";
import TemplatesTeamPage from "../modules/dashboard/Teams/pages/TemplatesTeamPage";
import LibraryTeamPage from "../modules/dashboard/Teams/pages/LibraryTeamPage";
import UploadsTeamPage from "../modules/dashboard/Teams/pages/UploadsTeamPage";
import SignaturePage from "../modules/dashboard/signature/pages/SignaturePage";
import SignatureDocs from "../modules/dashboard/signature/components/SignatureDocs";
import SignatureSpam from "../modules/dashboard/signature/components/SignatureSpam";
import SignatureSpecificPage from "../modules/dashboard/signature/pages/SignatureSpecificPage";
import ApprovalFlow from "../modules/dashboard/approvalflow";
import FlowSteps from "../modules/dashboard/approvalflow/components/FlowSteps";
import FlowEdit from "../modules/dashboard/approvalflow/components/FlowEdit";
import PublicReview from "../modules/dashboard/approvalflow/components/PublicReview";
import RequestToken from "../modules/dashboard/approvalflow/components/RequestToken";
import MyFlows from "../modules/dashboard/approvalflow/components/MyFlows";
import ProjectDetails from "../modules/dashboard/approvalflow/components/ProjectDetails";
import MyApprovals from "../modules/dashboard/approvalflow/components/MyApprovals";
import MyApprovalProjects from "../modules/dashboard/approvalflow/components/MyProjects";
import { PersonalDataPage } from "../modules/user/premium/pages/ProfilePage/components/PersonalDataPage";
import { NotificationsPage } from "../modules/user/premium/pages/ProfilePage/components/NotificationsPage";
import { PasswordPage } from "../modules/user/premium/pages/ProfilePage/components/PasswordPage";
import SecurityPage from "../modules/user/premium/pages/ProfilePage/components/SecurityPage";
import { useTwoFactorAuth } from "../hooks/twoFactorAuth";
import TwoFactorAuthentication from "../modules/user/premium/components/TwoFactorAuthentication";
// import { SecurityPage } from "../modules/user/premium/pages/ProfilePage/components/SecurityPage";

interface RoutesAppProps {
  unlockScreenMobile?: boolean;
  setUnlockScreenMobile?: React.Dispatch<React.SetStateAction<boolean>>;
  isPayment?: boolean;
  setIsPayment?: React.Dispatch<React.SetStateAction<boolean>>;
}

//routes app -
export default function RoutesApp({
  unlockScreenMobile,
  setUnlockScreenMobile,
  isPayment,
  setIsPayment,
}: RoutesAppProps) {
  const { loading } = useAuth();
  const { data, typeUser, loadingTypeUser } = useAuth();

  const { isUserHasOTP, setIsUserHasOTP } = useTwoFactorAuth();

  //unlock for mobile public routes
  useEffect(() => {
    if (isPayment) {
      setUnlockScreenMobile(true);
    }

    if (data) {
      setUnlockScreenMobile(false);
    } else if (!data) {
      setUnlockScreenMobile(true);
    }
  }, [data, isPayment]);

  //set loading if type user was loading and return loading.
  if (data && typeUser === "Loading" && loadingTypeUser) {
    return (
      <div
        className="loading-application"
        id="pesss"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <ContractAnimation />
      </div>
    );
  }

  return data ? (
    !data?.user?.otp_validate && data?.user?.otp_enabled ? (
      <Routes>
        <Route path="*" element={<TwoFactorAuthentication />} />
      </Routes>
    ) : (
      <Routes>
        {typeUser === "Paid" && (
          <>
            {/** DASBOARD **/}
            <Route path="/" element={<Master />}>
              {/** Create Projects **/}
              <Route path="" element={<ScreenCreateNewProject />}>
                <Route path="create-projects" />
                <Route path="create-projects/:number_page" />
              </Route>

              {/** My Projects **/}
              <Route path="/projects" element={<MyProjects />}>
                <Route path=":number_page" element={<></>} />
              </Route>

              {/** Clauses **/}
              <Route path="/library" element={<MyClausules />}>
                <Route path=":number_page" element={<></>} />
                <Route path=":number_page/:filter" element={<></>} />
              </Route>

              {/** Shared **/}
              <Route path="/shareds" element={<SharedWithMe />}>
                <Route path=":number_page" element={<></>} />
              </Route>

              {/** Automations **/}
              <Route path="/automations" element={<MyAutomations />}>
                <Route path=":number_page" element={<></>} />
                <Route path=":number_page/:filter" element={<></>} />
              </Route>

              {/** Teams **/}
              <Route path="/teams" element={<TeamListPage />}>
                <Route path=":number_page" element={<></>} />
              </Route>

              <Route path="/view-team/:team_id" element={<TeamSpecificPage />}>
                <Route path="templates" element={<TemplatesTeamPage />} />
                <Route
                  path="templates/:number_page"
                  element={<TemplatesTeamPage />}
                />

                <Route path="library" element={<LibraryTeamPage />} />
                <Route
                  path="library/:number_page"
                  element={<LibraryTeamPage />}
                />
                <Route
                  path="library/:number_page/:filter"
                  element={<LibraryTeamPage />}
                />

                <Route path="uploads" element={<UploadsTeamPage />} />
                <Route
                  path="uploads/:number_page"
                  element={<UploadsTeamPage />}
                />
              </Route>

              {/** Signature **/}
              <Route path="/signatures" element={<SignaturePage />}>
                <Route path="" element={<SignatureDocs />} />
                <Route path="documents" element={<SignatureDocs />} />
                <Route
                  path="documents/:number_page"
                  element={<SignatureDocs />}
                />
                <Route path="trash" element={<SignatureSpam />} />
                <Route path="trash/:number_page" element={<SignatureSpam />} />
              </Route>

              <Route
                path="/view-signature/:document_id"
                element={<SignatureSpecificPage />}
              />

              {/** One-Click **/}
              <Route path="/IA" element={<OneClick />}>
                <Route path=":number_page" element={<OneClick />} />
              </Route>

              {/** Approval Flow **/}
              <Route path="/approvalflow" element={<MyFlows />} />
              <Route path="/approvalflow/my-flows" element={<MyFlows />}>
                <Route path=":pagination" element={<MyFlows />} />
              </Route>
              <Route
                path="/approvalflow/:id/flow-steps"
                element={<FlowSteps />}
              />
              <Route path="/approvalflow/flow-edit" element={<FlowEdit />}>
                <Route path=":id" element={<FlowEdit />} />
              </Route>
              <Route
                path="/approvalflow/my-projects"
                element={<MyApprovalProjects />}
              >
                <Route path=":pagination" element={<MyApprovalProjects />} />
              </Route>
              <Route
                path="/approvalflow/project-details/:id"
                element={<ProjectDetails />}
              />
              <Route
                path="/approvalflow/my-approvals"
                element={<MyApprovals />}
              />
            </Route>

            {/** Editor **/}
            <Route path="/editor/:typetemplate" element={<EditorLoadPage />} />
            <Route
              path="/editor/:typetemplate/:templateid"
              element={<Editor />}
            />

            {/** OTHER PAGES **/}
            <Route path="/perfil" element={<ProfilePage />}>
              <Route path="" element={<PersonalDataPage />} />
              <Route path="notificao" element={<NotificationsPage />} />
              <Route path="alterar-senha" element={<PasswordPage />} />
              <Route
                path="seguranca"
                element={
                  <SecurityPage
                    isUserHasOTP={isUserHasOTP}
                    setIsUserHasOTP={setIsUserHasOTP}
                  />
                }
              />
            </Route>



            {/** Editor **/}
            <Route path="/editor/:typetemplate" element={<EditorLoadPage />} />
            <Route
              path="/editor/:typetemplate/:templateid"
              element={<Editor />}
            />

            {/** OTHER PAGES **/}
            <Route path="/perfil" element={<ProfilePage />} />
            <Route path="/perfil/nova-senha" element={<ChangePasswordPage />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/success" element={<VerificationPaid />} />
            <Route path="/suspenso" element={<SuspendedPage />} />
            <Route path="/cadastro" element={<RedirectRegistration />} />

            <Route
              path="/fluxo-de-aprovacao/:approver_id/:token"
              element={<PublicReview />}
            />
            <Route
              path="/solicitar-acesso/:approver_id"
              element={<RequestToken />}
            />
          </>
        )}

        {typeUser === "Unpaid" && (
          <>
            <Route path="/" element={<TrialEnd />} />
            <Route path="/checkout" element={<LoadingPaymentRenew />} />
            <Route path="/editor/:any" element={<TrialEnd />} />
            <Route
              path="/editor/:typetemplate/:templateid"
              element={<TrialEnd />}
            />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/success" element={<VerificationPaid />} />
            <Route path="/cadastro" element={<RedirectRegistration />} />
            <Route path="/perfil" element={<LoginLazy />} />
            <Route path="*" element={<PageNotFound />} />
            <Route
              path="/fluxo-de-aprovacao/:approver_id/:token"
              element={<PublicReview />}
            />
            <Route
              path="/solicitar-acesso/:approver_id"
              element={<RequestToken />}
            />
          </>
        )}

        {typeUser === "Suspended" && (
          <>
            <Route path="/" element={<SuspendedPage />} />
            <Route path="/editor/:any" element={<SuspendedPage />} />
            <Route
              path="/editor/:typetemplate/:templateid"
              element={<SuspendedPage />}
            />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/success" element={<VerificationPaid />} />
            <Route path="/perfil" element={<LoginLazy />} />
            <Route path="/payment-renew" element={<LoadingPaymentRenew />} />
            <Route path="/cadastro" element={<RedirectRegistration />} />
          </>
        )}
      </Routes>
    )
  ) : (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/lbca" element={<LoginOrganization />} />
      <Route
        path="/cadastro"
        element={
          <RegisterScreen isPayment={isPayment} setIsPayment={setIsPayment} />
        }
      />
      <Route path="/esqueci-minha-senha" element={<ForgotPasswordScreen />} />
      <Route
        path="/:organizationType/esqueci-minha-senha"
        element={<ForgotPasswordScreenOrganization />}
      />
      <Route path="/nova-senha/:token" element={<NewPassword />} />
      <Route
        path="/:organizationType/nova-senha/:token"
        element={<NewPasswordOrganization />}
      />
      <Route path="/editor/:typetemplate/:templateid" element={<LoginLazy />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/not-found" element={<PageNotFound />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/perfil" element={<LoginLazy />} />
      <Route path="/perfil/nova-senha" element={<LoginLazy />} />
      <Route path="/success" element={<VerificationPaid />} />
      <Route
        path="/fluxo-de-aprovacao/:approver_id/:token"
        element={<PublicReview />}
      />
      <Route path="/solicitar-acesso/:approver_id" element={<RequestToken />} />
    </Routes>
  );
}