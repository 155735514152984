import React, { useCallback, useEffect, useRef, useState } from "react";
//sign
import ButtonToolbar from "../ButtonToolbar";
import {
  // BoxDashAmount,
  // BoxInput,
  // BoxText,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  Flex,
  HorizontalStack,
  // InputDashed,
  // InputDashedSpace,
  LineBarNormal,
  LineWithDash,
  WrapperDash,
  WrapperDash2
} from "./styles";

import { motion } from "framer-motion";
import { useToolbarEditor } from "../../../hooks/toolbarEditor";
import { useSelection } from "../../../hooks/selection";
import { useMainHook } from "../../../../../hooks/main";
import { TooltipProps } from "@mui/material";

// import { Container } from './styles';

interface BorderProperties {
  dash: number;
  spacing: number;
}

interface IBorders {
  [key: number]: BorderProperties;
}
interface DropdownDashProps {
  isActive?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  tooltipTitle: string;
  tooltipDescription?: string;
  tooltipPlacement?: TooltipProps["placement"];
  onClick?: any;
  component?: string;
}

const borders: IBorders = {
  1: { dash: 3, spacing: 2 },
  2: { dash: 4, spacing: 2 },
  3: { dash: 6, spacing: 3 },
  4: { dash: 8, spacing: 4 },
  5: { dash: 10, spacing: 5 },
  6: { dash: 12, spacing: 6 },
  7: { dash: 14, spacing: 7 },
  8: { dash: 15, spacing: 5 }
};

const DropdownDash: React.FC<DropdownDashProps> = ({
  className,
  type,
  onClick,
  tooltipTitle,
  tooltipDescription,
  tooltipPlacement,
  component
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setForceBlockKey, selectedObject } = useSelection();
  const { objectScreen } = useMainHook();
  const [isDashed, setIsDashed] = useState(false);
  const refNew = useRef(null);

  const { toggleElementDash, setDashedOption, setTypeLine, typeLine } =
    useToolbarEditor();

  useEffect(() => {
    if (isOpen) {
      setForceBlockKey(true);
    } else {
      setForceBlockKey(false);
    }
  }, [isOpen]);

  const handleClick = useCallback(
    event => {
      event.preventDefault();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  document.addEventListener("mousedown", event => {
    if (refNew.current?.contains(event?.target)) {
      return;
    } else {
      if (isOpen) {
        setIsOpen(false);
      }
    }
  });

  //animations
  const list = {
    visible: {
      opacity: 1,
      y: 5,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3
      }
    },
    hidden: {
      opacity: 0,
      y: -20,
      transition: {
        when: "afterChildren"
      }
    }
  };

  useEffect(() => {
    if (selectedObject) {
      if (selectedObject?.attrs?.dash) {
        setDashedOption(oldState => {
          return {
            ...oldState,
            dash: selectedObject.attrs.dash[0],
            spacing: selectedObject.attrs.dash[1]
              ? selectedObject.attrs.dash[1]
              : selectedObject.attrs.dash[0]
          };
        });
      }
    }
  }, [selectedObject]);

  useEffect(() => {
    if (selectedObject?.attrs?.dash) {
      const [dash, spacing] = selectedObject?.attrs?.dash
      const posOne = selectedObject?.attrs?.dash[1];
      const isLinear = dash === 0 && spacing === 0

      if (isLinear) {
        setTypeLine('linear')
      } else {
        setTypeLine('dashed')
      }

      if (posOne !== 0) {
        setIsDashed(true);
      } else {
        setIsDashed(false);
      }
    }
  }, [objectScreen, selectedObject, typeLine]);

  return (
    <Flex ref={refNew}>
      <DropDownContainer>
        <DropDownHeader Open={isOpen} onClick={handleClick}>
          <ButtonToolbar
            isActive={isOpen}
            className={className}
            type={type}
            tooltipTitle={tooltipTitle}
            tooltipDescription={tooltipDescription}
            tooltipPlacement={tooltipPlacement}
            onClick={onClick}
            component={component}
          />
        </DropDownHeader>
      </DropDownContainer>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList
            as={motion.ul}
            variants={list}
            initial="hidden"
            animate="visible"
          >
            <HorizontalStack>
              <WrapperDash
                onClick={() => {
                  setTypeLine('linear')
                  toggleElementDash(0, 0);
                  setDashedOption(oldState => {
                    return {
                      ...oldState,
                      dash: 0,
                      spacing: 0
                    };
                  });
                }}
                isActive={isDashed}
              >
                <LineBarNormal isActive={isDashed} />
              </WrapperDash>

              <WrapperDash2
                isActive={isDashed}
                onClick={() => {
                  setTypeLine('dashed')
                  if (selectedObject.attrs.strokeWidth > 0) {
                    const newDash = borders[selectedObject.attrs.strokeWidth]
                    setDashedOption({ dash: newDash.dash, spacing: newDash.spacing })
                    toggleElementDash(newDash.dash, newDash.spacing);
                  } else {
                    setDashedOption({ dash: 3, spacing: 2 })
                    toggleElementDash(3, 2)
                  }
                }}
              >
                <LineWithDash isActive={isDashed} />
              </WrapperDash2>
            </HorizontalStack>

            {/* <BoxDashAmount>
              <BoxInput>
                <InputDashed
                  type="number"
                  value={dashedOption.dash}
                  onChange={event => {
                    setDashedOption(oldState => {
                      return {
                        ...oldState,
                        dash: Number(event.target.value)
                      };
                    });
                  }}
                />
                <InputDashedSpace
                  type="number"
                  value={dashedOption.spacing}
                  onChange={event => {
                    setDashedOption(oldState => {
                      return {
                        ...oldState,
                        spacing: Number(event.target.value)
                      };
                    });
                  }}
                />
              </BoxInput>
              <BoxText>
                <span>traço</span>
                <span className="right-text">espaço</span>
              </BoxText>
            </BoxDashAmount> */}
          </DropDownList>
        </DropDownListContainer>
      )}
    </Flex>
  );
};

export default DropdownDash;
