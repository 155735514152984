import styled from "styled-components";

export const Container = styled.div<{ error: boolean; urlIcon: string }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  position: relative;

  label {
    font-family: "Ubuntu";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }

  select {
    width: 100%;
    max-height: 40px;
    height: 44.5px !important;
    padding: 11px 10px;
    border-radius: 5px;
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.colors.error100 : theme.colors.black50};
    color: ${({ theme }) => theme.colors.black100};

    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: ${({ urlIcon }) => `url(${urlIcon})`} 95.5% 50% no-repeat;

    :focus {
      caret-color: transparent;
      border: 1px solid
        ${({ error, theme }) =>
          error ? theme.colors.error100 : theme.colors.focus};
    }

    ::placeholder {
      font-family: "Ubuntu";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      color: ${({ theme }) => theme.colors.black100};
    }

    cursor: pointer;
  }

  ul {
    position: absolute;
    top: 76px;
    z-index: 3;

    transition: 0.5s;
    transform: translate(0px, -10px);

    border-radius: 0px 0px 5px 5px;
    max-height: 267px;
    width: 100%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  }

  img {
    width: 14px;
    height: 10.03px;
  }
`;

export const Item = styled.option<{ active: boolean }>`
  display: flex;
  justify-content: space-between;

  padding: 11px 16px !important;
  height: 44.5px !important;

  font-family: "Ubuntu";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  color: ${({ theme }) => theme.colors.black100};
  cursor: pointer;

  background-color: ${({ theme, active }) =>
    active ? theme.colors.black5 : theme.colors.white100};

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }
`;

export const OverLay = styled.div`
  position: fixed;
  background-color: transparent;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;
