import React, { useEffect } from "react";
import api from "../../../../../services/api";
import { getStripeJs } from "../../../../../services/stipe-js";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { LoadingFlex } from "./styles";
import PagePlans from "../../../../../shared/pages/PagePlans";

// import { Container } from './styles';

const LoadingPaymentRenew: React.FC = () => {
  return (
    <>
      <PagePlans />
    </>
  );
};

export default LoadingPaymentRenew;

