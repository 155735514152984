import styled from "styled-components";

export const Container = styled.ul<{ isScreenLimit: boolean; }>`
  background-color: ${({ theme }) => theme.colors.white100};
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 3;
  border-radius: 5px;

  position: absolute;
  ${({ isScreenLimit }) => (isScreenLimit ? "right: 30px;" : "left: 30px;")};
  top: 0;
  padding-top: 8px;
`;

export const Item = styled.li`
  width: 283px;
  height: 44px;
  padding: 10px 12px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.black75};

  & > div {
    display: flex;
    align-items: center;
    column-gap: 8px;

    svg,
    img {
      height: 24px;
      width: 24px;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }

  & > svg {
    width: 8px;
    height: 15px;
  }
`;

export const SubModal = styled.div<{ isScreenLimit: boolean; }>`
  background-color: ${({ theme }) => theme.colors.white100};
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  max-height: 313px;
  overflow-y: auto;

  position: absolute;
  z-index: 4;
  top: 0;
  ${({ isScreenLimit }) => (isScreenLimit ? "right: 283px;" : "left: 283px;")};

  display: flex;
  flex-direction: column;
`;

export const ItemSubModal = styled.div<{ isDisabled?: boolean; }>`
  width: 246px;
  padding: 10px 12px;

  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    color: ${({ theme }) => theme.colors.black75};

    max-width: 24ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }

  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  & > div {
    display: flex;
    align-items: center;
    column-gap: 8px;

    svg,
    img {
      height: 24px;
      width: 24px;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }

  & > svg {
    width: 8px;
    height: 15px;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `};
`;
