import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  overflow: hidden;
`;

export const LabelVariable = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
`;

export const InputVariable = styled.input`
  height: 4rem;
  border: none;
  padding-left: 15px;
`;

