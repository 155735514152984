import React from "react";
import CheckButtonClauses from "../../Sidebar/components/CheckButtonClauses";
import CheckClausesContainer from "../../Sidebar/components/CheckClausesContainer";
import {
  CompanyLibraryButton,
  MyLibraryButton,
  TeamLibraryButton,
  WrapMainClauses
} from "./styles";
import Tooltip from "../../Tooltip";

// import { Container } from './styles';

interface MenuClausesProps {
  isActive: "MyLibrary" | "CompanyLibrary" | "TeamsLibrary";
  setIsActiveBoxCategories: (value: React.SetStateAction<boolean>) => void;
  handleActiveButton: (
    type: "MyLibrary" | "CompanyLibrary" | "TeamsLibrary"
  ) => void;
  setIsActiveBoxUserCategories: (value: React.SetStateAction<boolean>) => void;
}

const MenuClauses: React.FC<MenuClausesProps> = ({
  isActive,
  setIsActiveBoxCategories,
  handleActiveButton,
  setIsActiveBoxUserCategories
}) => {
  return (
    <WrapMainClauses>
      <Tooltip
        title="Minha biblioteca"
        description="Modelos de texto cadastrados por você."
        placement="bottom-start"
      >
        <MyLibraryButton
          onClick={() => {
            setIsActiveBoxCategories(false);
            handleActiveButton("MyLibrary");
          }}
          isActive={isActive}
        >
          Minha <br /> Biblioteca
        </MyLibraryButton>
      </Tooltip>

      <Tooltip
        title="Biblioteca UX DOC"
        description="Modelos de texto do UX DOC acessíveis a todos."
        placement="bottom-start"
      >
        <CompanyLibraryButton
          onClick={() => {
            setIsActiveBoxUserCategories(false);
            handleActiveButton("CompanyLibrary");
          }}
          isActive={isActive}
        >
          Biblioteca <br /> UX DOC
        </CompanyLibraryButton>
      </Tooltip>

      <Tooltip
        title="Biblioteca da equipe"
        description="Modelos de texto cadastrados em cada equipe."
        placement="bottom-start"
      >
        <TeamLibraryButton
          onClick={() => {
            setIsActiveBoxCategories(false);
            setIsActiveBoxUserCategories(false);
            handleActiveButton("TeamsLibrary");
          }}
          isActive={isActive}
        >
          Biblioteca <br /> de equipe
        </TeamLibraryButton>
      </Tooltip>
    </WrapMainClauses>
  );
};

export default MenuClauses;
