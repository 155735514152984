import React, { useCallback, useState } from "react";

import {
  Container,
  Text,
  TextCode,
  BoxInputCode,
  DownloadCode,
} from "./styles";
import { DownloadSimple } from "phosphor-react";

type Props = {
  code: string;
};

const BackupRestoreCode: React.FC<Props> = ({ code }) => {
  //make download with txt of code backup
  const handleDownloadNoteCode = useCallback((text: string) => {
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "backup-codigo-uxdoc.txt";
    document.body.appendChild(element);
    element.click();
  }, []);

  return (
    <Container>
      <Text>
        Salve este código de backup de emergência e guarde-o em um lugar seguro.
        Se você perder seu dispositivo, esse código poderá ser usado para
        desativar a autenticação de dois fatores e acessar a conta.
      </Text>

      <BoxInputCode>
        <TextCode>{code}</TextCode>
      </BoxInputCode>

      <DownloadCode type="button" onClick={() => handleDownloadNoteCode(code)}>
        <DownloadSimple size={22} weight="bold" color={"#fff"} />
        Baixar
      </DownloadCode>
    </Container>
  );
};

export default BackupRestoreCode;

