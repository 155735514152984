import { Box } from "@material-ui/core";
import PlainText from "../../../../../shared/components/PlainText";
import { Container } from "./styles";

const AlertMessage = () => {
  return (
    <Container>
      <PlainText>
        A IA está em fase experimental. Ao testar, saiba
        que ela nem sempre acerta. Por isso, revise o texto e os elementos.
      </PlainText>
      <PlainText>
        <Box component={'span'} color={'#D32F2F'} fontFamily={'Ubuntu'} fontSize={'14px'} fontWeight={'700'}>
          IMPORTANTE:
        </Box>
        {' '}
        <Box component={'span'} color={'343A40'} fontFamily={'Ubuntu'} fontSize={'14px'} fontWeight={'700'}>
          NÃO conter tabelas, cabeçalhos, rodapés, imagens, partes de assinatura, sumário, capa, fórmulas matemáticas e quadro resumo.
        </Box>
      </PlainText>
    </Container>
  );
};

export default AlertMessage;
