import React, { InputHTMLAttributes, useState, useEffect } from "react";

import { Input, Container } from "./styles";

import searchIcon from "./assets/searchIcon.svg";
import Tooltip from "../../../../editor/components/Tooltip";
import ButtonExit from "../../../../../shared/components/ButtonExit";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  setSearch: (value: React.SetStateAction<string>) => void;
}

const SearchInputBase: React.FC<InputProps> = ({ setSearch, ...rest }) => {
  const [isFocus, setIsFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearch(inputValue);
    }, 600);

    return () => {
      clearTimeout(timer);
    };
  }, [inputValue, setSearch]);

  return (
    <Tooltip
      title="Pesquisar"
      description="Digite aqui para encontrar o que procura"
      placement="bottom-start"
    >
      <Container>
        <img src={searchIcon} className="search-input" alt="" />
        <Input
          {...rest}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onFocus={() => setIsFocus(true)}
        />
        {isFocus && (
          <button>
            <ButtonExit
              inactivatedColor="#999C9F"
              onClick={() => {
                setInputValue("");
                setIsFocus(false);
              }}
            />
          </button>
        )}
      </Container>
    </Tooltip>
  );
};

export default SearchInputBase;
