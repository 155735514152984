import styled from "styled-components";
import { firstTheme } from "../../styles/theme";

export const Container = styled.main`
  margin: 60px auto 20px auto;
  max-width: 560px;
  padding-inline: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  a {
    width: 69px;
    height: 40px;
    background-color: ${firstTheme.colors.background};
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${firstTheme.colors.whiteColor};
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.41px;
  }

  @media (min-width: 1920px) {
    a {
      width: 120px;
    }
  }

  @media screen and (max-height: 667px) {
    margin-top: 30px;
  }
`;

export const Animation = styled.div`
  max-width: 200px;
  height: auto;

  @media (min-width: 1600px) {
    max-width: 300px;
  }

  @media (min-width: 1920px) {
    max-width: 400px;
  }
`;

export const BoxText = styled.div`
  line-height: 30px;
  font-weight: 700;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 20px;

  h1 {
    font-family: "Ubuntu";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.primary};
    margin: 0;
  }

  p {
    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    color: ${({ theme }) => theme.colors.black100};
  }

  @media (min-width: 1920px) {
    h1 {
      font-size: 34px;
    }

    p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 368px) {
    h1 {
      font-size: 25px;
    }

    p {
      font-size: 14px;
    }
  }
`;
