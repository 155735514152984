import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .close-button-box {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    /* background-color: red; */
  }
`;

export const Modal = styled.div`
  width: 800px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);

  padding: 10px 20px 20px 20px;

  @media (min-width: 1920px) {
    width: 974px;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }
`;

export const Button = styled.button<{ isActive: boolean }>`
  height: 40px;
  background-color: #fff;
  padding-inline: 16px;

  border-bottom: solid 3px #fff;
  border-bottom: solid 3px
    ${({ isActive, theme }) =>
      isActive ? theme.colors.primary : theme.colors.white100};

  font-size: 14px;
  font-weight: 400;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.black100 : theme.colors.black75};

  display: flex;
  align-items: center;
  gap: 8px;

  .icon-scale {
    width: 20px;
    height: 20px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const DragBox = styled.div`
  width: 100%;
  height: 440px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #d6d8d9;
  padding-bottom: 30px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: end;
  align-items: center;
  position: relative;

  img {
    width: 240px;
    height: 206.43px;
    margin-bottom: 10px;
  }

  button {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 5px;

    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    width: 177px;
    height: 40px;
  }

  @media (max-height: 560px) {
    height: 300px;

    img {
      width: 140px;
      height: 106.43px;
      margin-bottom: 10px;
    }
  }
`;

export const DraggBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(102, 51, 153, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 999999999 !important;
  span {
    color: #fff;
    font-size: 1.4rem;
  }
`;

export const ContentPDF = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectDocBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 440px;

  @media (max-height: 560px) {
    height: 300px;
  }
`;

export const BoxButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  img {
    width: 120px;
    height: 120px;
  }

  button {
    width: 205px;
    height: 40px;

    gap: 8px;
    border-radius: 5px;

    background-color: ${({ theme }) => theme.colors.prymary};
  }
`;
