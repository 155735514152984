import Konva from "konva";
import { cloneDeep } from "lodash";
import { Fragment, memo, useEffect, useRef, useState } from "react";
import { Image as ImageKonva, Transformer } from "react-konva";
import { Html } from "react-konva-utils";
import useImage from "use-image";
import { IRenderedObject } from "../../../../../dtos/RenderedObject";
import { PagesProps } from "../../../../../dtos/PagesProps";
import { Skeleton } from "@mui/material";
import { Image } from "phosphor-react";

type Props = {
  onChange?: (newAttrs: IRenderedObject) => void;
  selectedObject?: Konva.Path | IRenderedObject | Konva.Image | any;
  shapeProps?: Konva.Path | IRenderedObject | Konva.Image | any;
  setSelectedObject?:
    | React.Dispatch<React.SetStateAction<Konva.Image | Konva.Group>>
    | any;
  addToHistory?: (isLastHistory?: boolean) => void;
  currentMultipleSelection?: React.MutableRefObject<Konva.Transformer>;
  setObjectScreen?: React.Dispatch<React.SetStateAction<PagesProps[]>>;
  transformerSelectedObject?: React.MutableRefObject<Konva.Transformer>;
  handleRemoveElementGroup?: (id: string) => void;
  pendingSave?: any;
  setPageObject?: React.Dispatch<React.SetStateAction<number>>;
  isDraggingObject?: boolean;
  setIsDraggingObject?: React.Dispatch<React.SetStateAction<boolean>>;
};

const URLImageDrag = ({
  shapeProps,
  selectedObject,
  setSelectedObject,
  addToHistory,
  currentMultipleSelection,
  // setObjectScreen,
  transformerSelectedObject,
  handleRemoveElementGroup,
  // pendingSave,
  onChange,
  setPageObject,
  isDraggingObject,
  setIsDraggingObject,
}: Props) => {
  const [isSelected, setIsSelected] = useState(false);
  const [image] = useImage(shapeProps.src, "anonymous");
  //const [image] = useImage(`${shapeProps.src}`, "anonymous");
  const shapeRef = useRef<Konva.Shape>();
  const trRef = useRef<Konva.Transformer>();
  const [showRotation, setShowRotation] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [shiftPressed, setShiftPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (shapeRef.current === selectedObject) {
      setIsSelected(true);
      transformerSelectedObject.current = trRef.current;
    } else {
      setIsSelected(false);
    }
  }, [selectedObject, shapeRef.current]);

  useEffect(() => {
    trRef.current?.setNodes([shapeRef.current]);
    trRef.current?.setZIndex(trRef.current.getParent().children.length - 1);
    trRef.current?.getLayer().batchDraw();
  }, [isSelected]);

  return (
    <Fragment key={shapeProps.id}>
      {shapeProps.isLoadingImage ? (
        <Html>
          <div
            style={{
              position: "absolute",
              left: shapeProps.x,
              top: shapeProps.y,
            }}
          >
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "999999999999",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: `${shapeProps.width}px`,
                    height: `${shapeProps.height}px`,
                    // margin: "auto auto",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image size={60} color="#fff" />
                </div>
              </div>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={shapeProps.width}
                height={shapeProps.height}
                sx={{ backgroundColor: "#a2a0a0" }}
              />
            </div>
          </div>
        </Html>
      ) : (
        <ImageKonva
          image={image}
          ref={shapeRef}
          name="URLImageDrag"
          shadowBlur={1}
          shadowColor={"#fff"}
          {...shapeProps}
          onClick={(event: Konva.KonvaEventObject<MouseEvent>) => {
            setPageObject(event.currentTarget?.getStage()?.attrs?.id);
            const isTargetInsideMultipleSelection =
              currentMultipleSelection.current?.nodes().includes(event.target);

            if (isTargetInsideMultipleSelection) {
              setSelectedObject(null);
            } else {
              setSelectedObject(event.target);
              currentMultipleSelection.current?.setNodes([]);
            }
          }}
          onDragStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
            if (
              currentMultipleSelection.current?.nodes().length === 0 ||
              !currentMultipleSelection.current
            ) {
              addToHistory();
            }
            setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
            setIsDraggingObject(true);
            //if mutipleSelection includes element being dragged
            if (
              currentMultipleSelection.current?.nodes().includes(event.target)
            ) {
              if (shiftPressed) {
                handleRemoveElementGroup(event.target.attrs.id);
                return;
              }
              setSelectedObject(null);
            } else {
              setSelectedObject(event.target);
              currentMultipleSelection.current?.setNodes([]);
            }
          }}
          onDragEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
            setIsDraggingObject(false);
            onChange({
              ...shapeProps,
              x: event.target.absolutePosition().x,
              y: event.target.absolutePosition().y,
            });
          }}
        />
      )}

      {isSelected && showRotation && (
        <Html>
          <div
            style={{
              width: "40px",
              height: "20px",
              background: "rgba(0, 0, 0, 0.8)",
              position: "absolute",
              top: shapeProps?.y - 50,
              left: Math.ceil(shapeProps?.x - 20),
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#fff" }}>{`${rotation?.toFixed(0)}°`}</span>
          </div>
        </Html>
      )}

      {isSelected && (
        <Transformer
          onTransformStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
            if (
              currentMultipleSelection.current?.nodes().length === 0 ||
              !currentMultipleSelection.current
            ) {
              addToHistory();
            }
          }}
          onTransform={(event: Konva.KonvaEventObject<MouseEvent>) => {
            const node = event.target;
            const rotationNode = node.rotation();
            setShowRotation(true);
            setRotation(rotationNode);
          }}
          onTransformEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
            let myWidth = trRef?.current?.width();
            let myHeight = trRef?.current?.height();
            let rotation = trRef?.current.rotation();

            if (myWidth && myHeight) {
              shapeRef.current.width(myWidth);
              shapeRef.current.height(myHeight);

              shapeRef.current.scaleX(1);
              shapeRef.current.scaleY(1);

              onChange({
                ...shapeProps,
                width: myWidth,
                height: myHeight,
                scaleX: 1,
                scaleY: 1,
                x: event.target.absolutePosition().x,
                y: event.target.absolutePosition().y,
                rotation: rotation,
              });
            }

            setShowRotation(true);
          }}
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Fragment>
  );
};

export default memo(URLImageDrag);
