import React, { useContext, useEffect, useState } from "react";

import {
  CheckboxContainer,
  Container,
  GhostInput,
  HeaderContainer,
  InputBox,
  InputDate,
  OutlinedButton,
  StepsContainer,
  TitleLabel
} from "./styles";
import { Box, DialogContent, Divider, Grid } from "@material-ui/core";
import { Stack } from "@mui/material";
import EditIcon from "../../EditIcon";
import { Input } from "../styles";
import { CheckCircle } from "phosphor-react";
import PdfViewer from "../../PdfViewer";
import { CreateProjectContext } from "..";
import { StepRequest } from "../../../types/ApprovalFlowRequest";

const StepTwo: React.FC = () => {
  const [activeStep, setActiveStep] = useState(-1);
  const [selectedStep, setSelectedStep] = useState<StepRequest>();
  const [isEditingName, setIsEditingName] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const { project, setProject, uploadedFile } =
    useContext(CreateProjectContext);

  const getBackgroundColor = (step: number): string => {
    return activeStep === step ? "#F5F5F5" : "#FFFFFF";
  };

  useEffect(() => {
    if (activeStep > -1) {
      setSelectedStep(project.approval_flow.steps[activeStep]);
    } else {
      if (project.approval_flow.steps.length > 0) {
        setActiveStep(0);
        setSelectedStep(project.approval_flow.steps[0]);
      }
    }
  }, [activeStep, project.approval_flow.steps]);

  const onAllRequirementsCheckHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    const updatedRequirements = selectedStep?.requirements.map(item => {
      return {
        ...item,
        status: isChecked ? "PENDING" : "NOT_CHECKED"
      };
    });

    if (selectedStep) {
      const updatedStep = {
        ...selectedStep,
        requirements: updatedRequirements || []
      };

      const updatedSteps = project.approval_flow.steps.map((step, i) => {
        if (i === activeStep) {
          return updatedStep;
        }
        return step;
      });

      setProject({
        ...project,
        approval_flow: {
          ...project.approval_flow,
          steps: updatedSteps
        }
      });
    }
  };

  const onAllApproversCheckHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    const updatedApprovers = selectedStep?.approvers.map(item => {
      return {
        ...item,
        status: isChecked ? "PENDING" : "NOT_CHECKED"
      };
    });

    if (selectedStep) {
      const updatedStep = {
        ...selectedStep,
        approvers: updatedApprovers || []
      };

      const updatedSteps = project.approval_flow.steps.map((step, i) => {
        if (i === activeStep) {
          return updatedStep;
        }
        return step;
      });

      setProject({
        ...project,
        approval_flow: {
          ...project.approval_flow,
          steps: updatedSteps
        }
      });
    }
  };

  const handleDeadlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDeadline = event.target.value;
    const updatedSteps = project.approval_flow.steps.map((step, index) => {
      if (index === activeStep) {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + Number(newDeadline));
        return {
          ...step,
          deadline: Number(newDeadline),
          planned_deadline_date: newDate.toISOString()
        };
      }
      return step;
    });
    setProject({
      ...project,
      approval_flow: {
        ...project.approval_flow,
        steps: updatedSteps
      }
    });
  };

  const onCheckRequirementItem = (id: string) => {
    const updatedRequirements = selectedStep?.requirements.map(item => {
      if (item.id === id) {
        return {
          ...item,
          status: item.status === "PENDING" ? "NOT_CHECKED" : "PENDING"
        };
      }
      return item;
    });

    if (selectedStep) {
      const updatedStep = {
        ...selectedStep,
        requirements: updatedRequirements || []
      };

      const updatedSteps = project.approval_flow.steps.map((step, i) => {
        if (i === activeStep) {
          return updatedStep;
        }
        return step;
      });

      setProject({
        ...project,
        approval_flow: {
          ...project.approval_flow,
          steps: updatedSteps
        }
      });
    }
  };

  const onCheckApproverItem = (id: string) => {
    const updatedApprovers = selectedStep?.approvers.map(item => {
      if (item.id === id) {
        return {
          ...item,
          status: item.status === "PENDING" ? "NOT_CHECKED" : "PENDING"
        };
      }
      return item;
    });

    if (selectedStep) {
      const updatedStep = {
        ...selectedStep,
        approvers: updatedApprovers || []
      };

      const updatedSteps = project.approval_flow.steps.map((step, i) => {
        if (i === activeStep) {
          return updatedStep;
        }
        return step;
      });

      setProject({
        ...project,
        approval_flow: {
          ...project.approval_flow,
          steps: updatedSteps
        }
      });
    }
  };

  const isAllRequirementsChecked = () => {
    if (selectedStep) {
      return selectedStep.requirements.every(item => item.status === "PENDING");
    }
    return false;
  };

  const isAllApproversChecked = () => {
    if (selectedStep) {
      return selectedStep.approvers.every(item => item.status === "PENDING");
    }
    return false;
  };

  const openPdfPreview = () => {
    setShowPdf(true);
  };

  return (
    <>
      <HeaderContainer>
        {!isEditingName && (
          <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
            <TitleLabel>{project.name}</TitleLabel>

            <Box
              style={{ cursor: "pointer" }}
              onClick={() => setIsEditingName(true)}
            >
              <EditIcon width="16px" height="16px" />
            </Box>
          </Stack>
        )}
        {isEditingName && (
          <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
            <GhostInput
              value={project.name}
              onChange={event =>
                setProject({ ...project, name: event.target.value })
              }
            />

            <Box
              style={{ cursor: "pointer", color: "#343a40" }}
              onClick={() => setIsEditingName(false)}
            >
              <CheckCircle style={{ fontSize: "24px" }} />
            </Box>
          </Stack>
        )}

        <OutlinedButton onClick={() => openPdfPreview()}>
          Visualizar projeto
        </OutlinedButton>
      </HeaderContainer>
      <DialogContent style={{ overflowX: "hidden" }}>
        <Container>
          <TitleLabel>{project.approval_flow.name}</TitleLabel>
          <StepsContainer>
            <Grid container>
              <Grid item xs={6}>
                <Box padding={"16px"}>Etapas</Box>

                <Box
                  overflow={"scroll"}
                  height={"160px"}
                  style={{ overflowX: "hidden" }}
                >
                  {project.approval_flow.steps.map((step, index) => (
                    <Box
                      key={index}
                      padding="8px 16px"
                      bgcolor={getBackgroundColor(index)}
                      onClick={() => {
                        setActiveStep(index);
                        setSelectedStep(step);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {step.name}
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  <Stack
                    padding={"8px 16px"}
                    direction={"row"}
                    alignItems={"center"}
                    gap={"8px"}
                  >
                    Prazo para aprovação
                    <Input
                      style={{ width: "50px" }}
                      value={selectedStep?.deadline}
                      onChange={handleDeadlineChange}
                    />
                    {selectedStep?.deadline === 1 ? "DIA" : "DIAS"}
                  </Stack>
                  <Divider />
                  <Stack
                    overflow={"scroll"}
                    height={"159px"}
                    style={{ overflowX: "hidden" }}
                    mb={"8px"}
                    borderRadius={"8px"}
                  >
                    <Box padding={"16px"}>
                      <span> Requisitos de aprovação*</span>
                      <CheckboxContainer>
                        <input
                          type="checkbox"
                          onChange={onAllRequirementsCheckHandler}
                          checked={isAllRequirementsChecked()}
                        />
                        Todos
                      </CheckboxContainer>
                      {selectedStep?.requirements.map(item => (
                        <CheckboxContainer>
                          <input
                            type="checkbox"
                            checked={item.status === "PENDING"}
                            onChange={() => onCheckRequirementItem(item.id)}
                          />
                          {item.name}
                        </CheckboxContainer>
                      ))}
                    </Box>

                    <Box padding={"8px 16px"}>
                      <span> Aprovadores*</span>
                      <CheckboxContainer>
                        <input
                          type="checkbox"
                          onChange={onAllApproversCheckHandler}
                          checked={isAllApproversChecked()}
                        />
                        Todos
                      </CheckboxContainer>
                      {selectedStep?.approvers.map(item => (
                        <CheckboxContainer>
                          <input
                            type="checkbox"
                            checked={item.status === "PENDING"}
                            onChange={() => onCheckApproverItem(item.id)}
                          />
                          {item.name}
                        </CheckboxContainer>
                      ))}
                    </Box>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </StepsContainer>
          <Stack gap={"16px"} mt={"16px"}>
            <InputDate>
              <Stack direction={"row"} alignItems={"center"} gap="8px">
                <label style={{ fontSize: "14px" }}>
                  Data de aprovação planejada
                </label>
                <InputBox>
                  <input
                    onChange={event => {
                      setProject({
                        ...project,
                        planned_approval_date: event.target.value
                      });
                    }}
                    type="date"
                    value={project.planned_approval_date}
                  />
                </InputBox>
              </Stack>
            </InputDate>

            <Input
              placeholder="Mensagem específica (opcional)"
              value={project.notes}
              onChange={event =>
                setProject({ ...project, notes: event.target.value })
              }
            ></Input>
          </Stack>
        </Container>
      </DialogContent>

      <PdfViewer
        open={showPdf}
        onClose={() => setShowPdf(false)}
        //  file="https://pdfobject.com/pdf/sample.pdf"
        file={uploadedFile}
      />
    </>
  );
};

export default StepTwo;
