import styled from "styled-components";

interface IButtonProps {
  backgroundColor: "#343A40" | "#663399" | "#FFFFFF";
  isOwnerTeam: boolean;
}

export const Modal = styled.div`
  width: 700px;
  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    color: #000;
    border: solid 1px #a5a5a5;
    height: 40px;
    width: 100%;
    border-radius: 3px;
    padding-left: 15px;

    ::placeholder {
      color: #a5a5a5;
    }
  }
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebebec;
  padding-bottom: 10px;

  div {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  img {
    width: 22.5px;
    height: 25px;

    cursor: pointer;
  }

  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
`;

export const Text = styled.p`
  min-height: 126px;
  max-height: 240px;
  overflow-y: auto;
  width: 100%;

  text-align: justify;
  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: #343a40;
`;

export const Button = styled.button<IButtonProps>`
  width: ${props => (props.isOwnerTeam ? "49%" : "100%")};
  height: 40px;
  border-radius: 5px;
  background-color: ${props => props.backgroundColor};

  font-weight: 500;
  font-size: 14px;
  line-height: 16.41px;
  color: ${props =>
    props.backgroundColor === "#FFFFFF" ? "#663399" : "#FFFFFF"};

  border: ${props =>
    props.backgroundColor === "#FFFFFF" ? "solid 1px #663399" : "none"};

  @media (max-width: 1600px) {
    height: 36px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 400px;
  transform: translateY(30px);
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  border-bottom: 1px solid #ebebec;
  padding-bottom: 10px;

  ul {
    display: flex;

    p {
      color: #343a40;
    }
  }

  p {
    font-family: "Ubuntu";
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }

  & > p {
    color: #999c9f;
  }
`;

export const BoxButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
