import styled from "styled-components";

export const Container = styled.main`
  grid-area: 2 / 2 / 6 / 6;

  width: 100%;
  height: 100%;
  padding: 30px 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  h4 {
    font-size: 18px;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
  }
`;

export const Header = styled.div`
  font-size: 14px;
  line-height: 130%;

  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Form = styled.form`
  width: 535px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  p,
  li {
    font-size: 14px;
    line-height: 130%;
  }

  li {
    list-style: inside;
  }

  button {
    width: 143px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;

    :hover {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    }
  }

  a {
    width: 210px;
    height: 40px;
    border-radius: 5px;
    margin-top: 15px;
    margin-left: -15px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: #999c9f;

    :hover {
      background-color: #f5f5f5;
    }
  }
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 3px;

  label {
    font-size: 14px;
  }

  input {
    height: 40px;
    width: 405px;
    border-radius: 5px;
    border: 1px solid #999c9f;
    padding-left: 15px;

    :hover {
      border: 1px solid black;
    }

    :focus {
      border: 2px solid ${({ theme }) => theme.colors.focus};
    }
  }
`;

export const ChangeAvatar = styled.div`
  max-width: 187px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  position: relative;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const BoxFooterForm = styled.div`
  max-width: 405px;
`;

export const HoverAvatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);

  position: absolute;
  top: 0;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextError = styled.p<{ isError: boolean }>`
  padding-left: 13px;
  height: 30px;
  border: 1px solid ${({ isError }) => (isError ? "#de2d2d" : "transparent")};
  border-radius: 5px;

  font-size: 13px;

  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
