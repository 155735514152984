import React, { FormEvent, useEffect, useState } from "react";
import * as yup from "yup";
import {
  AsideContainer,
  Box,
  Button,
  Container,
  FormBox,
  Input,
  LoadingFlex,
  MarketingSide,
  Wrapper,
  Heading,
  HStack,
  LoginContent,
  InputBase,
  Label,
  ButtonStyled,
  Title,
  TextInfo,
  PasswordVerification
} from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import PuffLoader from "react-spinners/PuffLoader";
import DecorationBackground from "../../components/DecorationBackground";
import logoUX3 from "../../assets/uxlogotipo.svg";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../../../../../hooks/auth";
import api from "../../../../../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";

interface Inputs {
  newPassword: string;
  confirmPassword: string;
}

interface ObjectNewPasswordProps {
  token: string;
  password: string;
  password_confirmation: string;
}

const NewPassword: React.FC = () => {
  const { token } = useParams();
  const { signIn } = useAuth();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      scale: 1.05,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 200,
        staggerDirection: -1
      }
    }
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.9,
        type: "spring",
        stiffness: 100
      }
    }
  };

  const schema = yup.object({
    // oldPassword: yup.string().min(6).required("Senha antiga obrigatoria"),
    newPassword: yup
      .string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(
        /(?=.*[A-Z])/g,
        "A senha deve conter pelo menos uma letra maiúscula"
      )
      .matches(/(?=.*\d)/g, "A senha deve conter pelo menos um número")
      .matches(/[a-z]/, "Senha deve conter pelo menos uma letra minúscula")
      .matches(/[*@!#%&()^~{}]+/, "A senha deve conter pelo menos um símbolo")
      .required(`Senha necessária`),
    confirmPassword: yup
      .string()
      .required("Confirmação de senha obrigatória")
      .oneOf([yup.ref("newPassword"), null], "As senhas não são iguais")
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Inputs>({
    resolver: yupResolver(schema)
  });

  const [passwordMessages, setPasswordMessages] = useState({
    haveMinLength: "",
    haveUpperOrLower: "",
    haveOneSymbol: ""
  });

  const second = useWatch({
    control,
    name: "newPassword"
  });

  function hasSymbol(str: string) {
    const hasSymbol = /(?=.*\d)(?=.*[\W])/.test(str);

    if (hasSymbol) {
      setPasswordMessages(oldState => {
        return {
          ...oldState,
          haveOneSymbol: "right"
        };
      });
    } else {
      setPasswordMessages(oldState => {
        return {
          ...oldState,
          haveOneSymbol: "wrong"
        };
      });
    }
  }

  function hasUpperCaseAndLowerCase(str: string) {
    if (str) {
      const verifyExists = /[a-z]/.test(str) && /[A-Z]/.test(str);

      if (verifyExists) {
        setPasswordMessages(oldState => {
          return {
            ...oldState,
            haveUpperOrLower: "right"
          };
        });
      } else {
        setPasswordMessages(oldState => {
          return {
            ...oldState,
            haveUpperOrLower: "wrong"
          };
        });
      }
    }
  }

  useEffect(() => {
    if (!second) {
      setPasswordMessages(oldState => {
        return {
          ...oldState,
          haveMinLength: "",
          haveUpperOrLower: "",
          haveOneSymbol: ""
        };
      });
    }

    if (second?.length > 1) {
      hasUpperCaseAndLowerCase(second);
    }

    if (second?.length > 1) {
      hasSymbol(second);
    }

    if (second !== undefined) {
      if (second?.length > 1 && second?.length >= 8) {
        setPasswordMessages(oldState => {
          return {
            ...oldState,
            haveMinLength: "right"
          };
        });
      } else {
        setPasswordMessages(oldState => {
          return {
            ...oldState,
            haveMinLength: "wrong"
          };
        });
      }
    }
  }, [second]);

  useEffect(() => {
    if (errors?.newPassword?.message) {
      toast.error(`${errors?.newPassword?.message}`);
    } else if (errors?.confirmPassword?.message) {
      toast.error(`${errors?.confirmPassword?.message}`);
    }
  }, [errors]);

  const onSubmit: SubmitHandler<Inputs> = async data => {
    const ObjectForApi: ObjectNewPasswordProps = {
      token: token,
      password: data.newPassword,
      password_confirmation: data.confirmPassword
    };

    try {
      if (data.newPassword !== data.confirmPassword) {
        return toast.error("Tente novamente. As senhas não são iguais.");
      }
      setLoading(true);
      const responseNewPassword = await api.post(
        "password/reset",
        ObjectForApi
      );

      toast.success("Senha atualizada com sucesso!");
      setLoading(false);
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error: any) {
      console.error(error, "error when post new password");
      console.error(error.response.data.validation.body.message, "error");
      if (
        error.response.data.message === "Incorrect email/password combination."
      ) {
        alert("E-mail ou senha incorreto.");
        setLoading(false);
      } else if (
        error.response.data.validation.body.message ===
        "Password must be at least 8 characters long"
      ) {
        toast.error("A senha deve ter no minimo 8 caracteres.");
        setLoading(false);
      } else if (
        error.response.data.validation.body.message ===
        "The password must contain at least one uppercase letter, one number, one lowercase letter and one symbol"
      ) {
        toast.error(
          "A senha deve ter uma letra minuscula e maiuscula e um simbolo"
        );
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <ToastContainer position="top-left" />
      <AsideContainer>
        <LoginContent
          initial="hidden"
          animate="show"
          variants={container}
          as={motion.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* <img src={logoUX} className="logo-login" /> */}

          <div className="hero-login-div">
            <img className="hero-login-img" src={logoUX3} />
          </div>

          <Title>Criar nova senha</Title>
          <TextInfo>
            Crie uma nova senha para voltar a ter acesso à sua conta
          </TextInfo>
          <InputBase
            style={{ marginBottom: "-1rem" }}
            type="password"
            placeholder="Nova senha"
            {...register("newPassword")}
          />

          <PasswordVerification isActive={passwordMessages}>
            <span
              style={{
                fontSize: "1.4rem",
                fontFamily: "Roboto",
                marginBottom: "0.8rem"
              }}
            >
              Sua senha deve conter:
            </span>
            <ul>
              <li className="have-min-length">
                Pelo menos conter 8 caracteres
              </li>
              <li className="have-min-upper-lower">
                Letras maiúsculas e minúsculas
              </li>
              <li className="have-symbol">
                Pelo menos 1 símbolo especial e um número (!@#$%&0-9)
              </li>
            </ul>
          </PasswordVerification>

          <InputBase
            style={{ marginTop: "-0.1rem" }}
            type="password"
            placeholder="Confirmar nova senha"
            {...register("confirmPassword")}
          />

          <ButtonStyled type="submit">
            {loading ? (
              <LoadingFlex>
                <PuffLoader color="#ffff" size="28" />
              </LoadingFlex>
            ) : (
              `Salvar`
            )}
          </ButtonStyled>
        </LoginContent>
      </AsideContainer>
      <DecorationBackground />
      <MarketingSide>
        <HStack>
          <Heading>Crie documentos com design em poucos cliques</Heading>
        </HStack>
      </MarketingSide>
    </Container>
  );
};

export default NewPassword;
