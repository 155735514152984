import { useNavigate, useParams } from "react-router-dom";
import { useTeams } from "../../../../../hooks/teams";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import HeaderSection from "../../../components/HeaderSection";
import Pagination from "../../../components/Pagination";
import SearchBar from "../../../components/SearchBar";
import TableTeams from "../../components/TableTeams";
import { ButtonHeader, Container, Header } from "./styles";
import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { TeamsListProps } from "../../../../../dtos/teamsUtils";
import api from "../../../../../services/api";
import { Box, Modal } from "@material-ui/core";
import ModalTeams from "../../components/ModalTeams";
import { ToastContainer } from "react-toastify";
import { useMainHook } from "../../../../../hooks/main";

const TeamListPage = () => {
  const {
    setSelectModal,
    setListTeams,
    loadingListTeam,
    setLoadingListTeam,
    listTeams,
    forceUpdate,
    selectModal
  } = useTeams();

  const { debouncedSearchTerm, loadingDebounce } = useMainHook();

  const navigate = useNavigate();
  const { number_page } = useParams();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;

  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setSelectModal({ modal: "", isOpen: false });

    return () => {
      setListTeams([]);
    };
  }, []);

  //Debounce responsible for keeping the user completing the search word

  useEffect(() => {
    (async () => {
      try {
        setLoadingListTeam(true);
        const response: AxiosResponse<TeamsListProps> = await api.get(
          "team-users/list-user-teams",
          {
            params: {
              pagination: pageNumber,
              search: debouncedSearchTerm !== "" ? debouncedSearchTerm : null
            }
          }
        );
        setListTeams(response.data.dataArray);
        setTotalPages(response.data.pagination);
        setLoadingListTeam(false);

        //Checks if there are automations on that page
        // If not, it sends me back to the previous page
        if (pageNumber > 0 && response.data.dataArray.length === 0) {
          navigate(`/teams/${pageNumber}`);
        }
      } catch (err) {
        setLoadingListTeam(false);
        console.error(err, "ERROR when get teams list");
      }
    })();
  }, [pageNumber, forceUpdate, debouncedSearchTerm]);

  return (
    <>
      <ToastContainer />
      <Container>
        <Header>
          <HeaderSection
            title="Equipe"
            description="Aqui você pode criar uma área de equipe que funciona como uma caixa de ferramentas 
                  para o seu time. Cadastre usuários, templates, banco de textos e recursos visuais específicos 
                  para uso apenas pela sua equipe.
                  Como criador da equipe você poderá inserir ou excluir tudo. Como membro usuário você poderá apenas utilizar os recursos."
          />

          <Box>
            <ButtonHeader
              onClick={() => {
                setSelectModal({ modal: "Create Team", isOpen: true });
              }}
            >
              <PersonalizedIcon
                dPath={MountIcons.IconCrossAdd.dPath}
                viewBox={MountIcons.IconCrossAdd.viewBox}
                inactivatedColor="#FFF"
              />
              Criar nova equipe
            </ButtonHeader>
            <SearchBar
              screen="teams"
              pageNumber={pageNumber}
              description="Digite aqui para o time que procura."
            />
          </Box>
        </Header>

        <TableTeams
          teams={listTeams}
          loading={loadingListTeam || loadingDebounce}
        />

        <Pagination total={totalPages} screen="teams" pageNow={pageNumber} />
      </Container>
      <Modal
        children={
          <div>
            <ModalTeams />
          </div>
        }
        open={selectModal.isOpen}
      />
    </>
  );
};

export default TeamListPage;
