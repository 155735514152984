import styled from "styled-components";

export const Container = styled.ul<{ isScreenLimit: boolean }>`
  background-color: ${({ theme }) => theme.colors.white100};
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 3;
  border-radius: 5px;

  position: absolute;
  ${({ isScreenLimit }) => (isScreenLimit ? "right: 30px;" : "left: 30px;")};
  top: 0;
  padding-top: 8px;
`;

export const Item = styled.li`
  width: 256px;
  height: 44px;
  padding: 10px 12px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.black75};

  & > div {
    display: flex;
    align-items: center;
    column-gap: 8px;

    svg,
    img {
      height: 24px;
      width: 24px;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }

  & > svg {
    width: 8px;
    height: 15px;
  }
`;

export const EditName = styled.div`
  width: 100%;

  div {
    display: flex;
    align-items: center;
    gap: 3px;
    width: 100%;

    height: 40px;
    border: solid 1px ${({ theme }) => theme.colors.white100};
    border-radius: 5px;

    &:hover {
      border: solid 1px ${({ theme }) => theme.colors.black100};
      background-color: ${({ theme }) => theme.colors.white100};
    }

    p {
      font-family: "Ubuntu";
      font-size: 16px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.black100};
      margin-left: 5px;
      margin-top: 2px;

      max-width: 22ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  svg {
    height: 24px;
    width: 24px;
    scale: 1.3;
  }

  input {
    height: 40px;
    width: 100%;
    padding: 5px;
    border: solid 1px ${({ theme }) => theme.colors.focus};
    border-radius: 5px;

    font-family: "Ubuntu";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const SubModal = styled.div<{ isScreenLimit: boolean }>`
  background-color: ${({ theme }) => theme.colors.white100};
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  max-height: 313px;
  overflow-y: auto;

  position: absolute;
  z-index: 4;
  top: 0;
  ${({ isScreenLimit }) => (isScreenLimit ? "right: 256px;" : "left: 256px;")};

  display: flex;
  flex-direction: column;
`;

export const ItemSubModal = styled.div`
  width: 246px;
  padding: 10px 12px;

  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    color: ${({ theme }) => theme.colors.black75};

    max-width: 24ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }

  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  & > div {
    display: flex;
    align-items: center;
    column-gap: 8px;

    svg,
    img {
      height: 24px;
      width: 24px;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }

  & > svg {
    width: 8px;
    height: 15px;
  }
`;
