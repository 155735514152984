import styled from "styled-components";

interface colorButton {
  backgroundColor: "#343A40" | "#663399" | "#FFFFFF";
}

interface isErrorEmail {
  isError: boolean;
}

export const ContainerModal = styled.div`
  width: 700px;
  padding-block: 20px;

  background: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  .container-loading {
    width: 100%;
    height: 100%;
    min-height: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-inline: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebec;
`;

export const InputArea = styled.div`
  padding-inline: 20px;
  display: flex;
  align-items: end;
  justify-content: space-between;

  button {
    width: 124px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.primary90};
    border-radius: 5px;
    color: #fff;
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 526px;

  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }
`;

export const Table = styled.div`
  border-radius: 5px 5px 0px 0px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  margin-inline: 20px;

  & > div {
    height: 30px;
    border-radius: 5px 5px 0px 0px;
    background-color: ${({ theme }) => theme.colors.black10};

    display: flex;
    align-items: center;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.17fr;

    font-family: "Ubuntu";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};

    p:first-child {
      padding-left: 54px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    min-height: 44px;
    max-height: 132px;

    overflow-y: auto;
  }
`;

export const ButtonModal = styled.button<colorButton>`
  height: 40px;
  width: 49%;
  border-radius: 5px;
  margin-top: 15px;
  background-color: ${props =>
    props.backgroundColor === "#663399"
      ? props.theme.colors.primary
      : props.backgroundColor};

  font-weight: 500;
  font-size: 14px;
  line-height: 16.41px;
  color: ${props =>
    props.backgroundColor === "#FFFFFF"
      ? `${props.theme.colors.primary}`
      : "#FFFFFF"};

  border: ${props =>
    props.backgroundColor === "#FFFFFF"
      ? `solid 1px ${props.theme.colors.primary}`
      : "none"};
`;

export const HeaderSettings = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebec;

  div:first-child {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: solid 1px
      ${({ theme, isActive }) => (isActive ? theme.colors.focus : "white")};
    width: 93%;
    height: 30px;
    padding-left: 5px;

    cursor: pointer;

    :hover {
      border: solid 1px
        ${({ theme, isActive }) => (isActive ? theme.colors.focus : "black")};
    }
  }

  input {
    border: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
  }
`;

export const ButtonAddUser = styled.button`
  height: 40px;
  width: 184px;
  margin-inline: 20px;
  background-color: white;

  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: #999c9f;

  :hover {
    background-color: #f5f5f5;
  }
`;

export const InputEmail = styled.input<isErrorEmail>`
  border: 1px solid
    ${({ isError, theme }) =>
      isError ? theme.colors.error : theme.colors.black50};
  border-radius: 5px;

  padding-left: 15px;

  width: 100%;
  max-height: 40px;
  padding: 11.5px 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.black50};

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.focus};
  }
`;
