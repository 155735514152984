import React from "react";
import ReactLoading, { LoadingProps } from "react-loading";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import { ContainerLoading } from "./styles";

// import { Container } from './styles';

const LoadingEditor: React.FC<LoadingProps> = ({ ...rest }) => {
  return (
    <ContainerLoading>
      {/* {isButton ? (
        <ReactLoading
          type="spin"
          color="#000"
          height={100}
          width={100}
          {...rest}
        />
      ) : (
        <div>
          <ContractAnimation />
        </div>
      )} */}
      <ReactLoading
        type="spin"
        color="#000"
        height={100}
        width={100}
        {...rest}
      />
    </ContainerLoading>
  );
};

export default LoadingEditor;
