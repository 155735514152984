import { HTMLProps } from "react";
import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

interface VStackProps extends HTMLProps<HTMLDivElement> {
  margin?: string;
  height?: string;
  isModalOpen?: boolean;
}

interface FlexSearchProps {
  width?: string;
}

interface SpanClauseProps {
  text?: string;
}

export const SpanClause = styled.span<SpanClauseProps>`
  color: black;

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const VStack = styled.div<VStackProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ height }) => (height ? height : "")};
  justify-content: space-between;
  margin: ${({ margin }) => (margin ? margin : "")};
  position: relative;
  justify-content: flex-start;
  overflow-y: ${({ isModalOpen }) => (isModalOpen ? "hidden" : "auto")};
  min-height: 60px;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a5a5a5;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  .container-search-and-category {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    position: relative;
  }

  .mouse-grab {
    cursor: grab !important;
  }
`;

export const ButtonLetter = styled.button`
  border: none;
  display: flex;
`;

export const LettersContainer = styled.div`
  width: 100%;
  min-height: 76px;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  flex-wrap: wrap;
`;

export const BoxCategories = styled.div`
  width: 400px;
  height: calc(100vh - 320px);
  background-color: white;
  position: absolute;
  right: 0;
  top: 60px;
  -webkit-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
`;

export const HStack = styled.div`
  display: flex;
  width: 100%;
  height: 45%;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  /* background: red; */
`;

export const ContentFilter = styled.div`
  /* position: absolute; */
  width: 100%;
  top: 55px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .chip {
    display: inline-flex;
    flex-direction: row;
    background-color: transparent;
    border: 1px solid #676b70;
    cursor: default;
    height: 32px;
    outline: none;
    padding: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #676b70;
    white-space: nowrap;
    align-items: center;
    border-radius: 15px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
  }
  .chip-head {
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: #32c5d2;
    font-size: 1.25rem;
    flex-shrink: 0;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    width: 36px;
    color: #fff;
    height: 36px;
    font-size: 20px;
    margin-right: -4px;
  }
  .chip-content {
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
  }
  .chip-svg {
    color: #676b70;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
  }
  .chip-svg:hover {
    color: #676b70;
  }

  .chip2 {
    display: inline-flex;
    flex-direction: row;
    background-color: transparent;
    border: 1px solid #676b70;
    cursor: default;
    height: 32px;
    outline: none;
    padding: 0;
    font-size: 14px;

    font-family: "Open Sans", sans-serif;
    white-space: nowrap;
    align-items: center;
    border-radius: 15px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
    margin-top: 2rem;
  }
  .chip-head2 {
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: #32c5d2;
    font-size: 1.25rem;
    flex-shrink: 0;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    width: 36px;
    color: #fff;
    height: 36px;
    font-size: 20px;
    margin-right: -4px;
  }
  .chip-content2 {
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
  }
  .chip-svg2 {
    color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
  }
  .chip-svg2:hover {
    color: #666666;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  padding-inline: 10px 0px;
  padding-bottom: 120px;
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 10px;
  padding-right: 10px;

  & > button {
    height: 30px;
  }
`;

export const DescriptionClause = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  color: #343a40;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 42px;
  height: 42px;
  overflow: hidden;
  line-height: 20px; /* fallback */

  @media (max-width: 1366px) {
    font-size: 1.2rem;
    line-height: 15px;
    height: 32px;
  }
`;

export const FlexSearch = styled.div<FlexSearchProps>``;

export const FlexBar = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
`;

export const TitleClause = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #343a40;
  cursor: pointer;
`;

export const TextContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;
`;

export const Text = styled.span`
  color: ${({ theme }: Theme) => theme.colors.gray300};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  user-select: none;
`;

export const ClausesItemFeatures = styled.div`
  display: flex;
  width: 200px;
  height: 80%;
  justify-content: flex-end;
  align-items: center;
`;

export const ButtonCategories = styled.button`
  height: 30px;
  width: 122px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  color: white;

  font-size: 14px;
  font-weight: 500;
  line-height: 100%;

  transition: all 120ms linear;

  :hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }
`;

export const WrapperClauseBank = styled.div`
  display: flex;
  width: 95%;
  height: auto;
  align-items: center;
  cursor: grab;
`;

export const TitleBankClause = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  color: #343a40;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 42px;
  height: 42px;
  overflow: hidden;
  line-height: 20px; /* fallback */
`;

export const ButtonFavoriteBankClause = styled.button`
  display: flex;
  flex: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const FixedStack = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  position: absolute;
  bottom: 50px;
`;

export const ButtonFooter = styled.button`
  width: 362px;
  height: 30px;
  background-color: #fff;
  padding: 3px 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary90};

  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.primary90};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  :hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }
`;

export const NotResult = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
