import styled from "styled-components";

export const Modal = styled.div`
  width: 700px;

  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BoxButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BoxInput = styled.div<{ check: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 10px;

  input {
    height: 40px;
    width: 100%;

    border: 1px solid #a5a5a5;
    border-radius: 5px;

    padding-left: 15px;

    :focus {
      border: ${({ check, theme }) =>
        check ? "1.5px solid red" : `1.5px solid ${theme.colors.focus}`};
    }
  }
`;
