import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { useAuth } from "../../../../../../hooks/auth";
import { useMainHook } from "../../../../../../hooks/main";
import { LoadingFlex } from "../../../../../user/unknown/login/pages/Login/styles";
import { useHeaderEditor } from "../../../../hooks/headerEditor";
import { useSelection } from "../../../../hooks/selection";
import { useSharedDocument } from "../../../../hooks/sharedDocument";
import { ButtonShare } from "../../styles";
import { ButtonFile, Buttons, DropdownKindFile } from "./styles";
import api from "../../../../../../services/api";
import { useNavigate } from "react-router-dom";
import { useMetric } from "../../../../../../hooks/metric";
import ReactLoading from "react-loading";
import Tooltip from "../../../Tooltip";
import signIcon from "../../assets/sign-icon.svg";
import { useSign } from "../../../../../../hooks/sign";
import ButtonSave from "../ButtonSave";
import { useWorkspaceEditor } from "../../../../hooks/workspaceEditor";
import SharedIcon from "../../assets/toShared-icon.svg";
import WordICon from "../../assets/word-icon.svg";
import PdfICon from "../../assets/pdf-icon.svg";

// import { Container } from './styles';

interface RightButtonsProps {
  handleMouseEnterSaveTemplate: () => void;
  saveIcon: string;
  downloadIcon: string;
  isOwnerDocument: boolean;
  templateid: string;
}

const RightButtons: React.FC<RightButtonsProps> = ({
  handleMouseEnterSaveTemplate,
  saveIcon,
  downloadIcon,
  isOwnerDocument,
  templateid,
}) => {
  const { data, permissionType } = useAuth();
  const { containerRef } = useWorkspaceEditor();
  const [typeUserAccount, setTypeUserAccount] = useState<
    "trial" | "premium" | "loading"
  >("loading");
  const MenuFormatsRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const [isFormatsFileVisible, setIsFormatsFileVisible] = useState(false);
  const buttonRef = useRef(null);
  const { generatePDF, generateNewPDF } = useHeaderEditor();
  const { setSelectedObject, setSelectedObjects, currentMultipleSelection } =
    useSelection();
  const { setIsIntentBuyModal, isTeamTemplate, isLoadingSavingTemplate } =
    useMainHook();

  const { setIsSignEditor } = useSign();

  const { handleOpenModalShared } = useSharedDocument();

  const onCloseColorsMenu = useCallback(() => {
    setIsFormatsFileVisible(false);
  }, [setIsFormatsFileVisible]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (isFormatsFileVisible) {
        if (
          MenuFormatsRef.current &&
          !MenuFormatsRef.current.contains(event.target as Node) &&
          event.target !== buttonRef.current
        ) {
          onCloseColorsMenu();
        }
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isFormatsFileVisible, onCloseColorsMenu, buttonRef]);

  const handleWordDownload = useCallback(() => {
    setIsIntentBuyModal(true);
  }, [setIsIntentBuyModal]);

  const handleDownloadNewPDF = useCallback(() => {
    generateNewPDF("pdf");
    setIsFormatsFileVisible(false);
  }, [generateNewPDF, setIsFormatsFileVisible]);

  const handleDownloadNewWord = useCallback(() => {
    generateNewPDF("word");
    setIsFormatsFileVisible(false);
  }, [generateNewPDF, setIsFormatsFileVisible, typeUserAccount]);

  const handleDownloadPDF = useCallback(() => {
    generatePDF("pdf");
    setIsFormatsFileVisible(false);
  }, [generatePDF, setIsFormatsFileVisible]);

  const handleDownloadWord = useCallback(() => {
    generatePDF("word");
  }, [generatePDF, handleWordDownload, typeUserAccount]);

  const isBitsAcademyEmail = useMemo(() => {
    return !!data?.user?.email.match("@bitsacademy.com.br");
  }, [data]);

  useEffect(() => {
    if (
      data.payment_method?.user_type === "user_free" &&
      !data.payment_method.subscription_id &&
      data.payment_method.cancellation_date &&
      data.payment_method.is_active
    ) {
      setTypeUserAccount("trial");
    } else {
      setTypeUserAccount("premium");
    }
  }, [data]);



  return (
    <>
      <Tooltip
        title="Assinatura"
        description="Assinatura digital de documentos"
        placement="bottom-end"
      >
        <Buttons
          style={
            permissionType === "editor"
              ? { opacity: 0.2, cursor: "not-allowed" }
              : {}
          }
          onClick={() => {
            setIsSignEditor((prev) => !prev);
            containerRef.current.scrollTop = 0;
          }}
        >
          <img
            draggable={false}
            className="download"
            src={signIcon}
            alt="Ícone de download"
          />
        </Buttons>
      </Tooltip>

      <Tooltip
        title="Baixar"
        description="Exporte o seu projeto em diferentes formatos."
        placement="bottom-end"
      >
        <Buttons
          draggable={false}
          ref={MenuFormatsRef}
          style={
            permissionType === "editor"
              ? {
                opacity: 0.2,
                cursor: "not-allowed",
              }
              : {}
          }
          onClick={() => {
            if (permissionType === "editor") return;
            setSelectedObject(null);
            setSelectedObjects([]);
            currentMultipleSelection.current?.setNodes([]);
            setIsFormatsFileVisible((prev) => !prev);
          }}
          isHover={isFormatsFileVisible}
        >
          <img
            draggable={false}
            className="download"
            src={downloadIcon}
            alt="Ícone de download"
          />
        </Buttons>
      </Tooltip>

      {isFormatsFileVisible && (
        <DropdownKindFile>
          {isBitsAcademyEmail && (
            <>
              <Tooltip
                title=""
                description="Faça o download em PDF."
                placement="bottom-start"
              >
                <ButtonFile draggable={false} onClick={handleDownloadNewPDF}>
                  <div>
                    <img src={PdfICon} />
                  </div>
                  <div className="text-format">
                    <span>PDF Novo</span>
                  </div>
                </ButtonFile>
              </Tooltip>

              <Tooltip
                title=""
                description="Faça o download em Word."
                placement="bottom-start"
              >
                <ButtonFile draggable={false} onClick={handleDownloadNewWord}>
                  <div draggable={false}>
                    <img src={WordICon} />
                  </div>
                  <div className="text-format">
                    <span>Word Novo</span>
                  </div>
                </ButtonFile>
              </Tooltip>
            </>
          )}
          <Tooltip
            title=""
            description="Faça o download em PDF."
            placement="bottom-start"
          >
            <ButtonFile draggable={false} onClick={handleDownloadPDF}>
              <div>
                <img src={PdfICon} />
              </div>
              <div className="text-format">
                <span>PDF</span>
              </div>
            </ButtonFile>
          </Tooltip>

          <Tooltip
            title=""
            description="Faça o download em Word."
            placement="bottom-start"
          >
            <ButtonFile draggable={false} onClick={handleDownloadWord}>
              <div draggable={false}>
                <img src={WordICon} />
              </div>
              <div className="text-format">
                <span>Word</span>
              </div>
            </ButtonFile>
          </Tooltip>
        </DropdownKindFile>
      )}

      {isOwnerDocument && permissionType === "normal" ? (
        <Tooltip
          title="Compartilhar"
          description="Compartilhe seu projeto com outras pessoas."
          placement="bottom-end"
          maxWidth={200}
        >
          <ButtonShare
            onClick={() => {
              setSelectedObject(null);
              handleOpenModalShared(templateid);
            }}
          >
            <img draggable={false} src={SharedIcon} alt="Compartilhar" />{" "}
            Compartilhar
          </ButtonShare>
        </Tooltip>
      ) : (
        <ButtonShare
          target="_blank"
          className="disable-hover"
          disabled={true}
          style={
            permissionType === "editor" || isTeamTemplate
              ? { opacity: 0.2, cursor: "not-allowed" }
              : {}
          }
        >
          <img draggable={false} src={SharedIcon} alt="Compartilhar" />{" "}
          Compartilhar
        </ButtonShare>
      )}
    </>
  );
};

export default RightButtons;

