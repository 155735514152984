import React, { InputHTMLAttributes, useState } from "react";

import { Input, Container } from "./styles";

import searchIcon from "./assets/searchIcon.svg";
import Tooltip from "../Tooltip";
import ButtonExit from "../../../../shared/components/ButtonExit";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  setSearchIcon: (value: React.SetStateAction<string>) => void;
}

const SearchInputBase: React.FC<InputProps> = ({ setSearchIcon, ...rest }) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <Tooltip
      title="Pesquisar"
      description="Digite aqui para encontrar o que procura"
      placement="bottom-start"
    >
      <Container>
        <img src={searchIcon} className="search-input" alt="" />
        <Input {...rest} onFocus={() => setIsFocus(true)} />
        {isFocus && (
          <button>
            <ButtonExit
              inactivatedColor="#999C9F"
              onClick={() => {
                setSearchIcon("");
                setIsFocus(false);
              }}
            />
          </button>
        )}
      </Container>
    </Tooltip>
  );
};

export default SearchInputBase;
