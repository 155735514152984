import { Dialog, DialogTitle } from "@mui/material";
import {
  CancelButton,
  Container,
  ContainerButton,
  SaveButton,
  Subtitle
} from "./styles";
import { IconCloseWrapper } from "../../styles";
import { GrClose } from "react-icons/gr";

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const CancelProject: React.FC<Props> = ({
  open,
  onClose,
  onConfirm
}: Props) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth={"md"}>
      <DialogTitle style={{ fontWeight: 700, fontSize: "16px" }}>
        Cancelar projeto
      </DialogTitle>

      <IconCloseWrapper onClick={() => onClose()}>
        <GrClose />
      </IconCloseWrapper>

      <Container>
        <Subtitle>Tem certeza que você quer cancelar o projeto?</Subtitle>
        <ContainerButton>
          <CancelButton onClick={() => onClose()}>Não</CancelButton>
          <SaveButton onClick={onConfirm}>Sim</SaveButton>
        </ContainerButton>
      </Container>
    </Dialog>
  );
};

export default CancelProject;
