import styled from "styled-components";

export const ContainerRightBlankPage = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const Divider = styled.div`
  border-left: 1px solid #d6d8d9;
  padding-left: 4px;
  height: 30px;

  display: flex;
  align-items: center;
`;
