import styled from "styled-components";

export const Button = styled.button<{
  pos: { x?: string | number; y?: string | number };
  isActive: boolean;
}>`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--primria-primria-90, #7547a3);
  background: ${({ isActive }) => (isActive ? "#7547A3" : "#fff")};
  position: absolute;
  top: ${({ pos }) => `${pos.y}px`};
  left: ${({ pos }) => `${pos.x}px`};
`;

export const Dropdown = styled.div<{
  pos: { x?: string | number; y?: string | number };
}>`
  width: 200px;
  height: 62px;
  position: absolute;
  top: ${({ pos }) => `${Number(pos.y) + 50}px`};
  left: ${({ pos }) => `${pos.x}px`};
  border-radius: 5px;
  background: var(--white, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    min-height: 31px;
    /* background-color: red; */
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  span {
    color: var(--preto-preto-100, #343a40);

    /* legenda */
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    text-align: left;
    margin-left: 10px;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  min-height: 31px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

