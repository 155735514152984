import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
  background-color: #f5f5f5;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  user-select: none;
`;

export const Animation = styled.div`
  width: 200px;
  height: 200px;
`;

export const Description = styled.span`
  width: 293px;

  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

export const DecorationWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  z-index: 1;
  flex-direction: column;
`;

export const ContentMobile = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
