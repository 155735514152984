import React, { useRef } from "react";
import Modal from "react-modal";
import { Layer, Stage, Rect, Transformer } from "react-konva";
import {
  ExitIcon,
  HorizontalStack,
  StageContainer,
  TextDescription,
  TextVersion,
  VerticalStack
} from "./styles";
import { motion } from "framer-motion";
import iconExit from "../../assets/exit-icon.svg";
import Rendering from "../Canvas/Rendering";
import { useMainHook } from "../../../../hooks/main";
import { usePagesEditor } from "../../hooks/pagesEditor";
import { useToolbarEditor } from "../../hooks/toolbarEditor";
import { useWorkspaceEditor } from "../../hooks/workspaceEditor";
import { useSelection } from "../../hooks/selection";
import Konva from "konva";
import { useVersioning } from "../../hooks/versioning";
import { useDiff } from "../../hooks/diffDocuments";
import { useTextsEdition } from "../../hooks/textsEdition";

interface ModalVersioningProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const widthCanvas: number = 596;
const heigthCanvas: number = 842;

const ModalVersioning: React.FC<ModalVersioningProps> = ({
  isOpen,
  onRequestClose
}) => {
  const container = {
    hidden: { opacity: 0, scale: 0.9, stiffness: 60, duration: 10000 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 300,
        stiffness: 100,
        staggerDirection: -2
      }
    },
    exit: {}
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.9,
        type: "spring",
        stiffness: 100
      }
    }
  };

  // logic stage below

  const {
    addToRefs,
    addToHistory,
    setObjectScreen,
    nameOfCurrentUser,
    hasGroupShared
  } = useMainHook();
  const { setStagePointerPosition } = usePagesEditor();
  const { blockCommandKeyboard, setBlockCommandKeyboard } = useSelection();
  const { checkDeselect } = useWorkspaceEditor();

  const {
    addToMultipleSelectionRef,
    multipleSelectionRefs,
    currentMultipleSelection,
    setSelectedObject,
    selectedObject,
    selectedObjects,
    transformerSelectedObject,
    setSelectedObjects
  } = useSelection();

  const {
    quillRef,
    requestTextUpdate,
    textRich,
    isEditing,
    setIsEditing,
    blockingKeyboard,
    setBlockingKeyboard,
    quillRefComponent
  } = useTextsEdition();

  const {
    templateStartedShared,
    setTemplateStartedShared,
    oldContentsState,
    setOldContentsState,
    newContentsState,
    setNewContentsState,
    selectionCursor,
    setSelectionCursor,
    findDiff,
    buttonRef,
    newDelta,
    setNewDelta,
    isEditingQuill,
    setIsEditingQuill,
    newChanges,
    setNewChanges,
    newContentsRef,
    oldContentsRef,
    getQuillCursorRef,
    isNewTextRef,
    isTypingQuillRef,
    whichUserEdited,
    interceptModifications,
    textIsChanged,
    blockRenderImage,
    blockBackspace,
    whichKeyPress,
    selectionFormatText
  } = useDiff();

  const {
    comparisonVersionOne,
    setComparisonVersionOne,
    comparisonVersionTwo,
    setComparisonVersionTwo,
    descriptionOne,
    descriptionTwo,
    numberVersionOne,
    numberVersionTwo
  } = useVersioning();

  const { idPage, setIdPage } = usePagesEditor();

  const windows = window as any;
  const Konvas = windows.Konva;
  const oneRef = useRef(null);
  const oldPos = useRef(null);
  const selectionRectRef = useRef(null);
  const layerRef = useRef(null);
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  });

  const updateSelectionRect = () => {
    const node = selectionRectRef.current;
    node?.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: "rgba(140, 161, 255, 0.3)"
    });
    node.getLayer().batchDraw();
  };

  const onMouseDown = e => {
    if (e.target instanceof Konva.Stage) {
      setSelectedObject(null);

      // cleaning currentMutipleSelection
      if (currentMultipleSelection.current) {
        currentMultipleSelection.current.setNodes([]);
        setSelectedObjects([]);
      }

      const pos = e.target.getStage().getPointerPosition();
      selection.current.visible = true;
      selection.current.x1 = pos.x;
      selection.current.y1 = pos.y;
      selection.current.x2 = pos.x;
      selection.current.y2 = pos.y;
      updateSelectionRect();
      return;
    }
  };

  const onMouseMove = e => {
    if (!selection.current.visible) {
      return;
    }

    const pos = e.target.getStage().getPointerPosition();
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };

  const onMouseUp = event => {
    //assigning currentMutipleSelection to the multpleSelection in the stage with it's index

    const currentIndex = event.currentTarget.attrs.id - 1;
    currentMultipleSelection.current =
      multipleSelectionRefs.current[currentIndex];

    oldPos.current = null;
    if (!selection.current.visible) {
      return;
    }
    // getting properties of selectionRect
    const selBox = selectionRectRef.current.getClientRect();

    const elements = [];

    layerRef.current.children.forEach((elementNode, index) => {
      if (
        elementNode !== currentMultipleSelection.current &&
        elementNode !== selectionRectRef.current
      ) {
        const elBox = elementNode.getClientRect();

        if (Konva.Util.haveIntersection(selBox, elBox)) {
          elements.push(elementNode);
        }
      }
    });

    currentMultipleSelection.current.setNodes(elements);
    setSelectedObjects(elements);

    selection.current.visible = false;
    updateSelectionRect();
  };

  return (
    <Modal
      className="react-modal-content-versioning"
      overlayClassName="react-modal-overlay-versioning"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <ExitIcon src={iconExit} onClick={onRequestClose} />
      <HorizontalStack>
        <VerticalStack>
          <TextVersion>Versão {numberVersionOne} </TextVersion>
          <TextDescription>{descriptionOne}</TextDescription>
          {comparisonVersionOne?.map((item, index) => (
            <StageContainer
              as={motion.div}
              initial="hidden"
              animate="show"
              variants={container}
              exit={{
                opacity: 0,
                scale: 0.8,
                x: -300
              }}
              transition={{ duration: 1 }}
            >
              <Stage
                ref={oneRef}
                listening={false}
                pixelRatio={5}
                scaleX={1}
                scaleY={1}
                width={widthCanvas}
                height={heigthCanvas}
              >
                <Layer>
                  {item.renderObjects.map((objectProps, index) => (
                    <Rendering
                      key={String(Math.random() * index)}
                      selectedObject={selectedObject}
                      setSelectedObject={setSelectedObject}
                      objectProps={objectProps}
                      addToHistory={addToHistory}
                      commandKeyboard={blockCommandKeyboard}
                      setCommandBlockKeyboard={setBlockCommandKeyboard}
                      currentMultipleSelection={currentMultipleSelection}
                      setObjectScreen={setObjectScreen}
                      transformerSelectedObject={transformerSelectedObject}
                      quillRef={quillRef}
                      requestUpdateText={requestTextUpdate}
                      textRich={textRich}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      blockingKeyboard={blockingKeyboard}
                      setBlockingKeyboard={setBlockingKeyboard}
                      templateStartedShared={templateStartedShared}
                      setTemplateStartedShared={setTemplateStartedShared}
                      oldContentsState={oldContentsState}
                      setOldContentsState={setOldContentsState}
                      newContentsState={newContentsState}
                      setNewContentsState={setNewContentsState}
                      selectionCursor={selectionCursor}
                      setSelectionCursor={setSelectionCursor}
                      findDiff={findDiff}
                      buttonRef={buttonRef}
                      newDelta={newDelta}
                      setNewDelta={setNewDelta}
                      isEditingQuill={isEditingQuill}
                      setIsEditingQuill={setIsEditingQuill}
                      newChanges={newChanges}
                      setNewChanges={setNewChanges}
                      oldContentsRef={oldContentsRef}
                      newContentsRef={newContentsRef}
                      getQuillCursorRef={getQuillCursorRef}
                      isNewTextRef={isNewTextRef}
                      isTypingQuillRef={isTypingQuillRef}
                      quillRefComponent={quillRefComponent}
                      whichUserEdited={whichUserEdited}
                      nameOfCurrentUser={nameOfCurrentUser}
                      interceptModifications={interceptModifications}
                      textIsChanged={textIsChanged}
                      blockRenderImage={blockRenderImage}
                      blockBackspace={blockBackspace}
                      hasGroupShared={hasGroupShared}
                      whichKeyPress={whichKeyPress}
                      selectionFormatText={selectionFormatText}
                    />
                  ))}
                </Layer>
              </Stage>
            </StageContainer>
          ))}
        </VerticalStack>
        <VerticalStack>
          <TextVersion> Versão {numberVersionTwo} </TextVersion>
          <TextDescription>{descriptionTwo}</TextDescription>
          {comparisonVersionTwo.map((item, index) => (
            <StageContainer
              as={motion.div}
              initial="hidden"
              animate="show"
              variants={container}
              exit={{
                opacity: 0,
                scale: 0.8,
                x: -300
              }}
              transition={{ duration: 1 }}
            >
              <Stage
                ref={oneRef}
                listening={false}
                pixelRatio={5}
                scaleX={1}
                scaleY={1}
                width={widthCanvas}
                height={heigthCanvas}
              >
                <Layer>
                  {item.renderObjects.map((objectProps, index) => (
                    <Rendering
                      key={String(Math.random() * index)}
                      selectedObject={selectedObject}
                      setSelectedObject={setSelectedObject}
                      objectProps={objectProps}
                      addToHistory={addToHistory}
                      commandKeyboard={blockCommandKeyboard}
                      setCommandBlockKeyboard={setBlockCommandKeyboard}
                      currentMultipleSelection={currentMultipleSelection}
                      setObjectScreen={setObjectScreen}
                      transformerSelectedObject={transformerSelectedObject}
                      quillRef={quillRef}
                      requestUpdateText={requestTextUpdate}
                      textRich={textRich}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      blockingKeyboard={blockingKeyboard}
                      setBlockingKeyboard={setBlockingKeyboard}
                      templateStartedShared={templateStartedShared}
                      setTemplateStartedShared={setTemplateStartedShared}
                      oldContentsState={oldContentsState}
                      setOldContentsState={setOldContentsState}
                      newContentsState={newContentsState}
                      setNewContentsState={setNewContentsState}
                      selectionCursor={selectionCursor}
                      setSelectionCursor={setSelectionCursor}
                      findDiff={findDiff}
                      buttonRef={buttonRef}
                      newDelta={newDelta}
                      setNewDelta={setNewDelta}
                      isEditingQuill={isEditingQuill}
                      setIsEditingQuill={setIsEditingQuill}
                      newChanges={newChanges}
                      setNewChanges={setNewChanges}
                      oldContentsRef={oldContentsRef}
                      newContentsRef={newContentsRef}
                      getQuillCursorRef={getQuillCursorRef}
                      isNewTextRef={isNewTextRef}
                      isTypingQuillRef={isTypingQuillRef}
                      quillRefComponent={quillRefComponent}
                      whichUserEdited={whichUserEdited}
                      nameOfCurrentUser={nameOfCurrentUser}
                      interceptModifications={interceptModifications}
                      textIsChanged={textIsChanged}
                      blockRenderImage={blockRenderImage}
                      blockBackspace={blockBackspace}
                      hasGroupShared={hasGroupShared}
                      whichKeyPress={whichKeyPress}
                      selectionFormatText={selectionFormatText}
                    />
                  ))}
                </Layer>
              </Stage>
            </StageContainer>
          ))}
        </VerticalStack>
      </HorizontalStack>
    </Modal>
  );
};

export default ModalVersioning;
