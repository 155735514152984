import styled from "styled-components";

const getColorByIndex = (index: number) => {
  const colors = ["#24958E", "#F90", "#06C", "#663399"];
  const colorIndex = (index - 1) % colors.length;
  return colors[colorIndex];
};

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 10px;
`;

export const Item = styled.option<{ active: boolean }>`
  display: flex;
  justify-content: space-between;

  padding: 11px 16px !important;
  height: 44.5px !important;

  font-family: "Ubuntu";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  color: ${({ theme }) => theme.colors.black100};
  cursor: pointer;

  background-color: ${({ theme, active }) =>
    active ? theme.colors.black5 : theme.colors.white100};

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }
`;

export const BoxOthers = styled.div`
  width: 100%;
`;

export const BoxInput1 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const BoxInput2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.3fr;
  align-items: flex-end;
  gap: 10px;
`;

export const AvatarCustom = styled.div<{ indexOfUser: number; }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ indexOfUser }) => getColorByIndex(indexOfUser)};
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #fff !important;
    font-weight: 600;
  }
`;

export const SelectBox = styled.div`
  width: 100%;
  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }
  select {
    width: 100%;
    max-height: 40px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.black50};
    margin-top: 5px;

    @media (min-width: 1920px) {
      select {
        height: 43px;
        max-height: 43px;
      }
    }
    
    select:focus {
      border: 1px solid ${({ theme }) => theme.colors.focus};
    }
    
    select::placeholder {
      color: #d6d8d9;
    }
  }
`

export const InputBox = styled.div`
  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }

  input, select {
    width: 100%;
    max-height: 40px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.black50};
    margin-top: 5px;

    @media (min-width: 1920px) {
      input, select {
        height: 43px;
        max-height: 43px;
      }
    }
    
    input:focus, select:focus {
      border: 1px solid ${({ theme }) => theme.colors.focus};
    }
    
    input::placeholder, select::placeholder {
      color: #d6d8d9;
    }
  }
`;

export const InputDate = styled.div`
  width: 100%;

  input {
    width: 209.887px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    height: 22px;
    width: 24px;
    opacity: 0.5;
  }
`;

export const ItemUserInfo = styled.li`
  display: grid;
  grid-template-columns: 2.7fr 4fr 0.3fr;
  align-items: center;
  min-height: 44px;

  span {
    color: #a5a5a5;
  }

  .email {
    color: #999c9f;
  }
`;

export const FirstAreaItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;

  p {
    max-width: 23ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Trash = styled.div`
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`;

export const Table = styled.div`
  border-radius: 5px 5px 0px 0px;
  border: 1px solid ${({ theme }) => theme.colors.black20};

  & > div {
    height: 30px;
    border-radius: 5px 5px 0px 0px;
    background-color: ${({ theme }) => theme.colors.black10};

    display: flex;
    align-items: center;

    display: grid;
    grid-template-columns: 2.7fr 4fr 0.3fr;

    font-family: "Ubuntu";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};

    p:first-child {
      padding-left: 54px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    min-height: 44px;
    max-height: 132px;

    overflow-y: auto;
  }
`;
