import { Dialog, DialogTitle } from "@mui/material";
import {
  CancelButton,
  Container,
  ContainerButton,
  LoadingContainer,
  SaveButton,
  Subtitle
} from "./styles";
import { IconCloseWrapper } from "../../styles";
import { GrClose } from "react-icons/gr";
import api from "../../../../../services/api";
import { useState } from "react";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { toast } from "react-toastify";

type Props = {
  open: boolean;
  onClose: (refresh?: boolean) => void;
  project_id: string;
};

const DeleteProject: React.FC<Props> = ({
  open,
  onClose,
  project_id
}: Props) => {
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    try {
      setLoading(true);
      await api.delete(`/approval-projects/${project_id}`);
      onClose(true);
      toast.success("Projeto excluído com sucesso!");
    } catch (error) {
      toast.error("Erro ao excluir projeto, Contate o suporte!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <LoadingContainer>
          <ContractAnimation />
        </LoadingContainer>
      )}
      {!loading && (
        <Dialog onClose={onClose} open={open} maxWidth={"md"}>
          <DialogTitle style={{ fontWeight: 700, fontSize: "16px" }}>
            Excluir projeto
          </DialogTitle>

          <IconCloseWrapper onClick={() => onClose()}>
            <GrClose />
          </IconCloseWrapper>

          <Container>
            <>
              <Subtitle>Tem certeza que você quer excluir o projeto?</Subtitle>
              <ContainerButton>
                <CancelButton onClick={() => onClose()}>Não</CancelButton>
                <SaveButton onClick={onSubmit}>Sim</SaveButton>
              </ContainerButton>
            </>
          </Container>
        </Dialog>
      )}
    </>
  );
};

export default DeleteProject;
