import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { MyClausesDataProps } from "../dtos/Clauses";
import { ObjectNewClause } from "../modules/dashboard/myclausules/pages";

interface ModalContextProps {
  isNewClausesModalOpen?: boolean;
  setIsNewClausesModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseNewClausesModal?: () => void;
  handleOpenNewClausesModal?: () => void;
  refreshClause?: boolean;
  setRefreshClause?: React.Dispatch<React.SetStateAction<boolean>>;
  isEditingClause: boolean;
  setIsEditingClause: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenEditClauseModal: (id?: string, index?: number) => void;
  handleCloseEditClauseModal: () => void;
  idClause: string;
  indexClause?: number;
  endTrialModal: boolean;
  setEndTrialModal: React.Dispatch<React.SetStateAction<boolean>>;
  endTrialWrapperRef: React.MutableRefObject<HTMLDivElement>;
  handleShowClausule: (item: MyClausesDataProps, index?: number) => void;
  objectClause: ObjectNewClause;
  setObjectClause: React.Dispatch<React.SetStateAction<ObjectNewClause>>;
  setIsOpenModalViewClause: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenModalViewClause: boolean;
  isOpenModalDeleteClause: boolean;
  setIsOpenModalDeleteClause: React.Dispatch<React.SetStateAction<boolean>>;
  setIdClause: React.Dispatch<React.SetStateAction<string>>;
}

interface ModalContextProviderProps {
  children: ReactNode;
}

const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

const ModalContextProvider: React.FC = ({
  children
}: ModalContextProviderProps) => {
  const [isNewClausesModalOpen, setIsNewClausesModalOpen] = useState(false);
  const [refreshClause, setRefreshClause] = useState(false);
  const [isEditingClause, setIsEditingClause] = useState(false);
  const [idClause, setIdClause] = useState("");
  const [indexClause, setIndexClause] = useState<number | null>(null);
  const [endTrialModal, setEndTrialModal] = useState(false);
  const [isOpenModalViewClause, setIsOpenModalViewClause] = useState(false);
  const [isOpenModalDeleteClause, setIsOpenModalDeleteClause] = useState(false);
  const [objectClause, setObjectClause] = useState<ObjectNewClause>({
    title: "",
    clause: "",
    description: "Clause by user",
    is_favorite: false,
    key_words: ""
  });
  const endTrialWrapperRef = useRef<HTMLDivElement>(null);

  const handleOpenNewClausesModal = useCallback(() => {
    setIsNewClausesModalOpen(!isNewClausesModalOpen);
  }, [isNewClausesModalOpen]);

  const handleCloseNewClausesModal = useCallback(() => {
    setIsNewClausesModalOpen(!isNewClausesModalOpen);
  }, [isNewClausesModalOpen]);

  const handleOpenEditClauseModal = useCallback(
    (id: string, index?: number) => {
      setIdClause(id);
      setIndexClause(index);
      setIsEditingClause(!isEditingClause);
    },
    [isEditingClause, idClause, indexClause]
  );

  const handleShowClausule = useCallback(
    (item: MyClausesDataProps, index?: number) => {
      setIsOpenModalViewClause(!isEditingClause);
      setObjectClause({
        user_clause_id: item.id,
        title: item.title,
        clause: item.clause,
        description: "Created by user",
        is_favorite: item.is_favorite,
        key_words: item.key_words
      });
    },
    [isEditingClause]
  );
  const handleCloseEditClauseModal = useCallback(() => {
    setIsEditingClause(!isEditingClause);
  }, [isEditingClause]);

  return (
    <ModalContext.Provider
      value={{
        isNewClausesModalOpen,
        handleCloseNewClausesModal,
        handleOpenNewClausesModal,
        refreshClause,
        setRefreshClause,
        isEditingClause,
        setIsEditingClause,
        handleOpenEditClauseModal,
        handleCloseEditClauseModal,
        idClause,
        endTrialModal,
        setEndTrialModal,
        endTrialWrapperRef,
        handleShowClausule,
        objectClause,
        setObjectClause,
        setIsOpenModalViewClause,
        isOpenModalViewClause,
        isOpenModalDeleteClause,
        setIsOpenModalDeleteClause,
        setIdClause
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

// creating hook

function useModalContext(): ModalContextProps {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useAuth must be used with an ModalContextProvider");
  }

  return context;
}

export { ModalContextProvider, useModalContext };
