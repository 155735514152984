import { ReactNode } from "react";
import { ListStyle, Wrapper } from "./styles";

interface ListProps {
  children: ReactNode;
  showFilter?: string;
  isFavoriteFilter?: boolean;
}

const ListBitsClause: React.FC<ListProps> = ({ children, ...rest }) => {
  return (
    <Wrapper>
      <ListStyle {...rest}>{children}</ListStyle>
    </Wrapper>
  );
};

export default ListBitsClause;
