import React, { FormEvent, useEffect, useState } from "react";
import {
  AsideContainer,
  Box,
  Button,
  Container,
  FormBox,
  Input,
  LoadingFlex,
  MarketingSide,
  Wrapper,
  Heading,
  HStack,
  LoginContent,
  InputBase,
  Label,
  ButtonStyled,
  ImageLogo
} from "./styles";

import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useForm, SubmitHandler } from "react-hook-form";

import DecorationBackground from "../../components/DecorationBackground";

import logoUX3 from "../../assets/uxlogotipo.svg";
import computerImage2 from "../../assets/imagem-login2.png";

import { toast, ToastContainer } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Ripple } from "react-spinners-css";
import { ContainerLoading } from "../../../../../editor/pages/styles";
import { ContractAnimation } from "../../../../../../shared/components/ContractAnimation";
import IconSvg from "../../../../../editor/components/IconSvg";
import { useAuth } from "../../../../../../hooks/auth";
import { BoxError } from "../../../components/BoxError/styles";
import { IoMdAlert } from "react-icons/io";

interface Inputs {
  email: string;
  password: string;
}

interface SubmitProps {
  email: string;
  password: string;
}

const LoginLazy: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { createdAccount, setCreatedAccount, typeUser } = useAuth();
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [dataError, setDataError] = useState("");

  const { signIn } = useAuth();
  let navigate = useNavigate();

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      scale: 1.05,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 200,
        staggerDirection: -1
      }
    }
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.9,
        type: "spring",
        stiffness: 100
      }
    }
  };

  const schema = yup.object({
    email: yup.string().email("Email invalido").required(),
    password: yup.string().min(6).required(`Senha necessaria`)
  });

  function toastMessageCreatedAccount() {
    toast.success("Sua conta foi criada com sucesso!");
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SubmitProps>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<Inputs> = async data => {
    try {
      setLoading(true);
      await signIn(data.email, data.password);
      setLoading(false);
    } catch (error: any) {
      if (
        error.response.data.message === "Incorrect email/password combination."
      ) {
        toast.error("Email ou senha inválida!");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (createdAccount) {
      toastMessageCreatedAccount();
      setCreatedAccount(false);
    }
  }, [createdAccount]);

  const [checkLogout, setCheckLogout] = useState(false);

  useEffect(() => {
    if (checkLogout) {
      navigate("/");
    }
  }, [checkLogout]);

  useEffect(() => {
    if (typeUser === "Suspended" || typeUser === "Unpaid") {
      navigate("/");
    }
    function myFunction() {
      setTimeout(function () {
        if (typeUser === "Loading") {
          setCheckLogout(true);
        }
      }, 30000); //30 seconds
    }
    myFunction();
  }, [typeUser]);

  useEffect(() => {
    return () => {
      setDataError("");
      setLoadingScreen(true);
    };
  }, []);

  return (
    <>
      {loadingScreen ? (
        <LoadingFlex style={{ height: "100vh" }}>
          <ContractAnimation />
        </LoadingFlex>
      ) : (
        <Container>
          <ToastContainer position="top-left" />
          <AsideContainer>
            <LoginContent
              initial="hidden"
              animate="show"
              variants={container}
              as={motion.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="hero-login-div">
                <img className="hero-login-img" src={logoUX3} />
              </div>

              {/* <ImageLogo src={logoUX2} /> */}

              <span>Faça o login na sua conta e aproveite!</span>

              <InputBase
                className="force-margin"
                type="text"
                placeholder="E-mail"
                {...register("email")}
              />
              {/* {errors?.email?.message && (
                <p className="error-validation">
                  {errors?.email?.message === "email is a required field"
                    ? "Campo obrigatório"
                    : errors?.email?.message}
                </p>
              )} */}
              <InputBase
                type="password"
                placeholder="Senha"
                {...register("password")}
                className="force-margin"
              />
              {/* {errors.password?.message && (
                <p className="error-validation">
                  {errors.password?.message ===
                  "password must be at least 6 characters"
                    ? "Senha deve ter no mínimo 6 caracteres"
                    : "email invalido."}
                </p>
              )} */}
              <Label onClick={() => navigate("esqueci-minha-senha")}>
                Esqueci minha senha
              </Label>

              <ButtonStyled type="submit">
                {!loading ? (
                  `Entrar`
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "0.5rem"
                    }}
                  >
                    <Ripple color="#ffffff" size={32} />
                  </div>
                )}
              </ButtonStyled>

              <ButtonStyled
                className="testing-free"
                onClick={() => navigate("/")}
              >
                Teste grátis por 14 dias!
              </ButtonStyled>

              <BoxError>
                {dataError && dataError !== "" && (
                  <div>
                    <p>
                      <IoMdAlert /> {dataError}
                    </p>
                  </div>
                )}
              </BoxError>
            </LoginContent>
          </AsideContainer>
          <DecorationBackground />
          <MarketingSide>
            <HStack>
              <Heading>Legal Design e Visual Law em poucos cliques</Heading>
            </HStack>
            <IconSvg
              className="computerImg"
              src={computerImage2}
              description=""
            />
          </MarketingSide>
        </Container>
      )}
    </>
  );
};

export default LoginLazy;
