import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 24px;
  height: 24px;
  fill: currentColor;
`;

type Props = {
  color: string;
};

const FilterIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 24 24" color={color}>
    <mask
      id="mask0_10960_4869"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.427246" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_10960_4869)">
      <path
        d="M11 20.4272C10.7167 20.4272 10.4792 20.3314 10.2875 20.1397C10.0958 19.9481 9.99999 19.7106 9.99999 19.4272V13.4272L4.19999 6.02725C3.94999 5.69391 3.91249 5.34391 4.08749 4.97725C4.26249 4.61058 4.56666 4.42725 4.99999 4.42725H19C19.4333 4.42725 19.7375 4.61058 19.9125 4.97725C20.0875 5.34391 20.05 5.69391 19.8 6.02725L14 13.4272V19.4272C14 19.7106 13.9042 19.9481 13.7125 20.1397C13.5208 20.3314 13.2833 20.4272 13 20.4272H11ZM12 12.7272L16.95 6.42725H7.04999L12 12.7272Z"
        fill="#7547A3"
      />
    </g>
  </StyledSvg>
);

export default FilterIcon;
