import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

export const ButtonHeader = styled.button`
  background-color: ${({ theme }) => theme.colors.primary90};
  width: 201px;
  height: 40px;
  padding: 8px 16px;
  border-radius: 5px;

  display: flex;
  align-items: center;
  gap: 8px;

  font-family: "Ubuntu";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  color: ${({ theme }) => theme.colors.white100};

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Main = styled.main`
  width: 100%;
  height: 100%;

  @media (max-height: 560px) {
    height: 600px;
  }
`;

export const TableText = styled.div`
  border-radius: 5px 5px 0px 0px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  min-height: 560px;
  height: 90%;

  & > div {
    height: 30px;
    border-radius: 5px 5px 0px 0px;
    background-color: ${({ theme }) => theme.colors.black10};

    display: flex;
    align-items: center;

    display: grid;
    grid-template-columns: 1fr 1fr;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};

    p:first-child {
      padding-left: 54px;
    }
  }
`;

export const GridText = styled.ul`
  height: 100%;
  display: grid;
  grid-template-rows: repeat(12, 44px);
  position: relative;
`;

export const ContainerLogin = styled.div`
  width: 100%;
  height: 528px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexPagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;

export const TextContent = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20rem;
`;

export const Text = styled.span`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.gray300};
  text-align: center;
  line-height: 2.7rem;
`;

export const NoElements = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;

  color: #999c9f;

  height: 100%;
  min-width: 100%;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;
