import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 212.09px);
  grid-template-rows: 1fr 1fr;
  justify-content: space-between;
  grid-row-gap: 20px;
  position: relative;

  @media (min-width: 1920px) {
    grid-template-columns: repeat(4, 250px);
  }
`;

export const NoElements = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;

  color: #999c9f;

  min-height: 300px;
  min-width: 100%;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1600px) {
    min-height: 500px;
  }
`;
