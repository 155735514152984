import React, { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { BiChevronDown } from "react-icons/bi";

import {
  CheckboxContainer,
  ClearButton,
  InputBox,
  InputDate,
  StyledButton
} from "./styles";

export type DropdownOption = {
  label: string;
  value: string;
};

type Props = {
  options: DropdownOption[];
  onChange: (selectedOptions: string[]) => void;
  label?: string;
  showSearch?: boolean;
  isDateInput?: boolean;
};
const DropdownFilter = ({
  options,
  onChange,
  label,
  showSearch,
  isDateInput
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [dateValue, setDateValue] = useState(new Date());

  const ref = useRef(null);

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    const newSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter(option => option !== value)
      : [...selectedOptions, value];
    setSelectedOptions(newSelectedOptions);
  };

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );

  const handleClear = () => {
    if (isDateInput) {
      setDateValue(new Date());
      setSelectedOptions([]);
      onChange([]);
      setIsOpen(false);
    }
    if (selectedOptions.length === 0) {
      setIsOpen(false);
      return;
    }
    setSelectedOptions([]);
    onChange([]);
    setIsOpen(false);
  };

  const handleApply = () => {
    if (isDateInput) {
      onChange([dateValue.toISOString()]);
    } else {
      onChange(selectedOptions);
    }

    setIsOpen(false);
  };

  return (
    <Box position={"relative"} ref={ref} minWidth={"fit-content"}>
      <Stack
        direction="row"
        alignItems={"center"}
        border={"1px solid #999C9F"}
        borderRadius={"5px"}
        p={"4px 8px"}
        fontSize={"13px"}
        color={"#343A40"}
        onClick={handleOpen}
        style={{ cursor: "pointer" }}
      >
        <span> {label || "Filtrar"}</span>

        <BiChevronDown fontSize={"24px"} />
      </Stack>
      {isOpen && (
        <Box
          bgcolor={"white"}
          boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.1)"}
          position={"absolute"}
          zIndex={200}
          width={"min-content"}
          mt={"5px"}
          mb={"10px"}
        >
          {showSearch && (
            <Stack direction={"row"} p={1} alignItems={"center"}>
              <input
                type="text"
                width={"100%"}
                height={"25px"}
                style={{
                  border: "1px solid #999C9F",
                  borderRadius: "5px",
                  padding: "4px",
                  fontSize: "13px",
                  color: "#343A40"
                }}
                onChange={e => setSearch(e.target.value)}
              />
            </Stack>
          )}

          {isDateInput && (
            <InputDate>
              <Stack gap="8px" padding={1}>
                <label style={{ fontSize: "14px" }}>Iniciado em</label>
                <InputBox>
                  <input
                    onChange={event => {
                      setDateValue(new Date(event.target.value));
                    }}
                    type="date"
                    value={dateValue.toISOString().split("T")[0]}
                  />
                </InputBox>
              </Stack>
            </InputDate>
          )}
          {!isDateInput && (
            <Stack maxHeight={"200px"} overflow={"auto"}>
              {filteredOptions.map(option => (
                <Stack
                  key={option.value}
                  direction={"row"}
                  fontSize={"13px"}
                  p={"0 8px"}
                >
                  <CheckboxContainer>
                    <input
                      type="checkbox"
                      checked={selectedOptions.includes(option.value)}
                      value={option.value}
                      onChange={handleChange}
                    />
                    <span>{option.label}</span>
                  </CheckboxContainer>
                </Stack>
              ))}
            </Stack>
          )}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={1}
            p={1}
          >
            <ClearButton onClick={handleClear}>Limpar</ClearButton>
            <StyledButton onClick={handleApply}>Aplicar</StyledButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DropdownFilter;
