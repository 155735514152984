import styled from "styled-components";

export const ButtonAccept = styled.div<{
  positionX: number;
  positionY: number;
}>`
  width: 362px;
  height: 30px;
  position: absolute;
  top: ${({ positionY }) => positionY && `${positionY - 40}px`};
  left: ${({ positionX }) => positionX && `${positionX}px`};
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 0 0rem;

  svg {
    margin-left: 0rem;
  }

  span {
    color: var(--preto-preto-50, #999c9f);
    /* Botões */
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
  }
`;

export const ButtonCustom = styled.div`
  width: 180px;
  height: 100%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: rgba(244, 244, 244, 0.6);
  }
`;
