import React, { useEffect, useState } from "react";
import ButtonPrimary from "../../../../ButtonPrimary";
import DropdownVersioning from "../../../../DropdownVersioning";
import { CardVersion } from "../CardVersion";
import { BoxNotListApi, Flex, VStack, Text, LineDivider } from "./styles";
import { useVersioning } from "../../../../../hooks/versioning";
import LoadingEditor from "../../../../LoadingEditor";
import DropdownVersioningTwo from "../../../../DropdownVersioningTwo";
import { useMainHook } from "../../../../../../../hooks/main";
import api from "../../../../../../../services/api";
import { CardVersionMain } from "../../CardVersionMain";
import ButtonPrimaryFixed from "../../../../ButtonPrimaryFixed";
import { ContainerLoading } from "../../../../LoadingEditor/styles";
import { ContractAnimation } from "../../../../../../../shared/components/ContractAnimation";
import { AllMyProjects } from "../../../../../../../dtos/AllMyProjects";

export interface IRenderedObject {
  x: number;
  y: number;
  width: number;
  align?: "justify" | "center" | "left" | "right";
  text?: string;
  fontSize?: number;
  fontFamily?: string;
  fill?: string;
  id: string;
  object: string;
  setPoints?: number;
  stroke?: string;
  strokeWidth?: string;
  src?: string;
  dash?: number[];
  cornerRadius?: number;
  lineHeight?: number;
  textDecoration?: "underline" | "none";
  fontStyle?: string;
  ZIndex?: number;
}

interface PageProps {
  pageNumber: number;
  renderObjects: IRenderedObject[];
}

interface ResponseDataApi {
  created_at: string;
  creator_id: string;
  description?: string;
  id: string;
  is_main: boolean;
  template: {
    arrayOfPages?: PageProps[];
  };
  updated_at: string;
  user_template_id: string;
  version?: string;
  thumbnail_url?: string;
  first_name_creator: string;
  last_name_creator: string;
}

interface ResponseDocumentInfo {
  created_at: string;
  description: string;
  download_counter: number;
  id: string;
  template: {
    arrayOfPages?: PageProps[];
  };
  thumbnail: string;
  thumbnail_url: string;
  title: string;
  updated_at: string;
  user_id: string;
}

interface dataVersionOfTemplate {
  dataVersionOfTemplate: ResponseDataApi[];
  dataUser?: any;
  responseApi?: {
    created_at: string;
    creator_id: string;
    description?: string;
    id: string;
    is_main: boolean;
    template: IRenderedObject;
    first_name_creator: string;
    last_name_creator: string;
    updated_at: string;
    user_template_id: string;
  };
  loadingApi: boolean;
  enabledButtonCompareVersion: boolean;
  currentTemplateInfo?: React.MutableRefObject<AllMyProjects>;
}

//

export const CompareVersionBox: React.FC<dataVersionOfTemplate> = ({
  dataVersionOfTemplate,
  dataUser,
  responseApi,
  loadingApi,
  enabledButtonCompareVersion,
  currentTemplateInfo
}) => {
  const { templateIsLoaded } = useMainHook();

  const {
    handleOpenCompareVersionModalOpen,
    selectedOptionOne,
    selectedOptionTwo
  } = useVersioning();

  const [documentInfo, setDocumentInfo] = useState({} as ResponseDocumentInfo);
  const [thumbMainVersion, setThumbMainVersion] = useState("");

  useEffect(() => {
    const autoLoad = async () => {
      const response = await api.get(
        `user-templates/${currentTemplateInfo.current.id}`
      );
      setDocumentInfo(response.data);
      setThumbMainVersion(response.data.thumbnail_url);
    };

    autoLoad();
  }, [documentInfo, thumbMainVersion]);

  return (
    <>
      <Flex>
        <DropdownVersioning
          dataVersionOfTemplate={dataVersionOfTemplate}
          borderRadius={"10px 0 0 10px"}
        />
        <DropdownVersioningTwo
          dataVersionOfTemplate={dataVersionOfTemplate}
          borderRadius={"0"}
        />
        {selectedOptionOne !== null && selectedOptionTwo !== null ? (
          <ButtonPrimaryFixed
            onClick={handleOpenCompareVersionModalOpen}
            borderRadius={"0 10px 10px 0"}
          >
            Visualizar versões
          </ButtonPrimaryFixed>
        ) : (
          <ButtonPrimaryFixed
            borderRadius={"0 10px 10px 0"}
            style={{ cursor: "not-allowed" }}
            disabledButtonOpacity="0.2"
          >
            Visualizar versões
          </ButtonPrimaryFixed>
        )}
      </Flex>
      <VStack style={{ marginBottom: "5rem" }}>
        <CardVersionMain
          user={
            dataVersionOfTemplate
              ? `${
                  dataVersionOfTemplate[0]?.first_name_creator
                    ? dataVersionOfTemplate[0]?.first_name_creator
                    : dataUser
                } ${
                  dataVersionOfTemplate[0]?.last_name_creator
                    ? dataVersionOfTemplate[0]?.last_name_creator
                    : ""
                }`
              : ""
          }
          version={"principal"}
          date={documentInfo.updated_at}
          description="atual"
          template={currentTemplateInfo?.current?.template?.arrayOfPages}
          idTemplateMain={currentTemplateInfo.current.id}
          currentTemplateInfo={currentTemplateInfo}
          thumbnailUrl={thumbMainVersion}
        />

        <LineDivider />

        {loadingApi || !dataVersionOfTemplate ? (
          <ContainerLoading>
            {/* <LoadingEditor /> */}
            <ContractAnimation />
          </ContainerLoading>
        ) : dataVersionOfTemplate && dataVersionOfTemplate?.length >= 1 ? (
          dataVersionOfTemplate?.map((template, index) => (
            <CardVersion
              key={template.id}
              user={`${template.first_name_creator} ${template.last_name_creator}`}
              version={template.version}
              date={template.created_at}
              description={template.description}
              template={template.template.arrayOfPages}
              idTemplateMain={template.id}
              currentTemplateInfo={currentTemplateInfo}
              thumbnailUrl={template.thumbnail_url}
            />
          ))
        ) : (
          <div></div>
          // <BoxNotListApi>
          //   <Text>Você ainda não criou versões do projeto.</Text>
          // </BoxNotListApi>
        )}
      </VStack>
    </>
  );
};
