import { ISelectModalTeam } from "../../../../../../dtos/teamsUtils";
import ButtonExit from "../../../../../../shared/components/ButtonExit";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import PlainText from "../../../../../../shared/components/PlainText";
import TitleH5 from "../../../../../../shared/components/TitleH5";
import { Container, Modal, ButtonBox, Title } from "./styled";

interface IModalLeaveTeamProps {
  handleTeamLeave: (id: string) => void;
  nameOfTeam: string;
  id: string;
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
}

const ModalLeaveTeam = ({
  handleTeamLeave,
  id,
  nameOfTeam,
  setSelectModal
}: IModalLeaveTeamProps) => {
  return (
    <Container>
      <Modal>
        <div>
          <Title>
            <TitleH5 title="Sair da equipe" />
            <ButtonExit
              onClick={() => setSelectModal({ modal: "", isOpen: false })}
            />
          </Title>
          <PlainText>
            Tem certeza que você quer sair da equipe {nameOfTeam} ?
          </PlainText>
        </div>
        <ButtonBox>
          <ButtonMaxWidth
            typeStyle="purple"
            text="Não"
            onClick={() => setSelectModal({ modal: "", isOpen: false })}
          />
          <ButtonMaxWidth
            onClick={() => {
              handleTeamLeave(id);
              setSelectModal({ modal: "", isOpen: false });
            }}
            typeStyle="white"
            text="Sim"
          />
        </ButtonBox>
      </Modal>
    </Container>
  );
};

export default ModalLeaveTeam;
