import {
  AllMyProjects,
  IHandleChangeDocumentNameProps
} from "../../../../dtos/AllMyProjects";
import TemplateItem from "../TemplateItem";
import { IScreenCreateNewProject } from "../../createproject/pages";
import { Container, NoElements } from "./styles";
import { TemplatesOfTeamProps } from "../../../../hooks/teams";
import { PagesProps } from "../../../../dtos/PagesProps";
import { UseMutateFunction } from "react-query";
import { IResponseCreationProjectFromBitsTemplates } from "../../../../dtos/CreateNewProjects";
import { IHandlePreviewTemplateProps } from "../../../../dtos/SharedInterfaces";

interface IGridTemplateProps {
  templates:
    | IScreenCreateNewProject[]
    | AllMyProjects[]
    | TemplatesOfTeamProps[];
  children?: any;
  handleChooseTemplate?: any /* UseMutateFunction<
    {
      pages: [];
      title: string;
    },
    unknown,
    IHandlePreviewTemplateProps,
    unknown
  >; */;
  setTemplateId?: React.Dispatch<React.SetStateAction<string>>;
  templateOptions?: boolean;
  handleMakeCopy?: UseMutateFunction<any, unknown, string, unknown>;
  handleDeleteDocument?: (document_id: string, name: string) => Promise<void>;
  handleChangeDocumentName?: UseMutateFunction<
    AllMyProjects,
    unknown,
    IHandleChangeDocumentNameProps,
    unknown
  >;
  page: "Shared with me" | "My projects" | "Create new project" | "Team";
  handleEditTamplate?: (title: any, id: any) => Promise<void>;
  downloading?: boolean;
  setDownloading?: React.Dispatch<React.SetStateAction<boolean>>;
  idToastifyDownloadRef?: any;
  whichFileToBeDownload?: React.MutableRefObject<"pdf" | "word">;
  documentPreviewData?: React.MutableRefObject<PagesProps[]>;
  handleUseTemplate: UseMutateFunction<
    IResponseCreationProjectFromBitsTemplates,
    unknown,
    string,
    unknown
  >;
  isIntentBuyModal?: boolean;
  setIsIntentBuyModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const GridTemplate = ({
  children,
  templates,
  handleChooseTemplate,
  setTemplateId,
  templateOptions = false,
  handleMakeCopy,
  handleDeleteDocument,
  handleChangeDocumentName,
  handleEditTamplate,
  page,
  downloading,
  setDownloading,
  idToastifyDownloadRef,
  documentPreviewData,
  whichFileToBeDownload,
  handleUseTemplate,
  isIntentBuyModal,
  setIsIntentBuyModal
}: IGridTemplateProps) => {
  return (
    <Container>
      {children}

      {templates.length === 0 ? (
        <NoElements>Nenhum projeto encontrado.</NoElements>
      ) : (
        templates.map((template, index) => (
          <TemplateItem
            id={template.id}
            key={template.id}
            src={template.thumbnail_url}
            text={template.title}
            handleChooseTemplate={handleChooseTemplate}
            setTemplateId={setTemplateId}
            options={templateOptions}
            handleMakeCopy={handleMakeCopy}
            handleDeleteDocument={handleDeleteDocument}
            index={index}
            handleChangeDocumentName={handleChangeDocumentName}
            template={template.template}
            page={page}
            handleEditTamplate={handleEditTamplate}
            downloading={downloading}
            setDownloading={setDownloading}
            idToastifyDownloadRef={idToastifyDownloadRef}
            documentPreviewData={documentPreviewData}
            whichFileToBeDownload={whichFileToBeDownload}
            handleUseTemplate={handleUseTemplate}
            isIntentBuyModal={isIntentBuyModal}
            setIsIntentBuyModal={setIsIntentBuyModal}
          />
        ))
      )}
    </Container>
  );
};

export default GridTemplate;
