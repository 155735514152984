import React, { useEffect, useRef, useState } from "react";

import {
  BodyCategories,
  BoxCategories,
  CategoryWrap,
  Container,
  ContentFilter,
  IconLeft,
  IconRight,
  Input,
  InputBox,
  InputWrap,
  ListItem
} from "./styles";

import searchIcon from "../../../../../assets/icon-search.svg";
import filterIcon from "../../assets/filter-team-clause.svg";
import CategoriesBox from "../../../../CategoriesBox";
import { LettersContainer } from "../../../../CategoriesBox/styles";
import { dataAlpha } from "../../../../../utils/dataAlphabetic";
import api from "../../../../../../../services/api";
import { ClauseTeamListProps } from "../..";
import Tooltip from "../../../../Tooltip";
import ButtonExit from "../../../../../../../shared/components/ButtonExit";

interface SearchBarProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  handleChangeSearch: (event: any) => void;
  visibleCategories: boolean;
  setVisibileCategories: React.Dispatch<React.SetStateAction<boolean>>;
  idTeam: string;
  whichFilter: string;
  setWhichFilter: React.Dispatch<React.SetStateAction<string>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  listTeamClauses: ClauseTeamListProps[];
  setListTeamClauses: React.Dispatch<
    React.SetStateAction<ClauseTeamListProps[]>
  >;
  setLoadingClauses: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  search,
  setSearch,
  handleChangeSearch,
  visibleCategories,
  setVisibileCategories,
  idTeam,
  whichFilter,
  setWhichFilter,
  currentPage,
  setCurrentPage,
  listTeamClauses,
  setListTeamClauses,
  setLoadingClauses
}) => {
  const [keyWords, setKeyWords] = useState<string[]>([]);
  const [isFocus, setIsFocus] = useState(false);
  const refComponent2 = useRef(null);

  document?.addEventListener("mousedown", event => {
    if (refComponent2.current?.contains(event?.target)) {
      return;
    } else {
      if (visibleCategories) {
        setVisibileCategories(false);
      }
    }
  });

  useEffect(() => {
    (async () => {
      if (idTeam) {
        try {
          const responseCategoriesKeyWords = await api.get(
            `team-clauses/list-array-key-words/${idTeam}`
          );

          setKeyWords(responseCategoriesKeyWords.data.key_words);
        } catch (err) {
          console.error(err, "ERROR when response categories keywords team");
        }
      }
    })();
  }, [idTeam]);

  return (
    <Container>
      <InputWrap>
        <Tooltip
          title="Pesquisar"
          description="Digite aqui para encontrar o que procura"
          placement="bottom-start"
        >
          <InputBox>
            <IconLeft>
              <img src={searchIcon} alt="" className="search-icon" />
            </IconLeft>
            <Input
              value={search}
              onChange={handleChangeSearch}
              placeholder="Pesquisar por título"
              onFocus={() => setIsFocus(true)}
            />
            {isFocus && (
              <button>
                <ButtonExit
                  inactivatedColor="#999C9F"
                  onClick={() => {
                    setSearch("");
                    setIsFocus(false);
                  }}
                />
              </button>
            )}
          </InputBox>
        </Tooltip>
      </InputWrap>

      <CategoryWrap>
        <Tooltip
          title="Categoria"
          description="Filtre os textos por categoria"
          placement="bottom-start"
        >
          <button
            onClick={() => {
              setVisibileCategories(prevState => !prevState);
            }}
          >
            Categoria
          </button>
        </Tooltip>
      </CategoryWrap>

      {visibleCategories && (
        <BoxCategories ref={refComponent2}>
          {/* <LettersContainer>
            {dataAlpha.map(option => (
              <button className="alphabetical" onClick={() => {}}>
                {option.letter}
              </button>
            ))}
          </LettersContainer> */}
          {/* <ContainerLetters>

        </ContainerLetters> */}

          <BodyCategories>
            {keyWords.length > 0 ? (
              keyWords.map(keyword => (
                <ListItem
                  key={keyword}
                  onClick={() => {
                    setWhichFilter(keyword);
                    setVisibileCategories(false);
                  }}
                >
                  {keyword}
                </ListItem>
              ))
            ) : (
              <div className="empty-categories">
                <span>Sem categorias para listar.</span>
              </div>
            )}
          </BodyCategories>
        </BoxCategories>
      )}

      {whichFilter && (
        <ContentFilter>
          <div className="chip">
            <div className="chip-content"> {whichFilter} </div>
            <div
              className="chip-close"
              onClick={() => {
                setListTeamClauses([]);
                setLoadingClauses(true);
                setCurrentPage(0);
                setWhichFilter("");
              }}
            >
              <svg
                className="chip-svg"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
              </svg>
            </div>
          </div>
        </ContentFilter>
      )}
    </Container>
  );
};

export default SearchBar;
