import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

export const ContainerModal = styled.div`
  max-width: 506px;
  height: 780px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 2rem auto;
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  -webkit-box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.11);
  position: relative;
  border-radius: 0px;
  padding: 0 1.6rem;
  justify-content: space-around;
  padding-bottom: 1.6rem;
`;

export const WrapperMain = styled.div`
  width: 100%;
  background: red;
  height: 100%;
  display: flex;
  margin-top: 20rem;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  min-height: 73px;
  border-radius: 20px;
  align-items: center;
  border: solid 1px red;
  padding: 0 1.6rem;
  line-height: 190%;
  justify-content: center;
`;

export const Text = styled.span`
  color: red;
  font-size: 1.6rem;
  text-align: center;
  max-width: 700px;
`;

export const TextVersion = styled.span`
  color: white;
  font-size: 1.6rem;
  text-align: left;
  padding-left: 2rem;
  font-weight: bold;
`;

export const TextDescription = styled.span`
  color: white;
  width: 600px;
  font-size: 1.4rem;
  text-align: left;
  padding-left: 2rem;
  font-weight: normal;
  word-break: break-all;
  margin: 1rem 0;
  height: 40px;
`;

export const Wrapper = styled.div`
  display: flex;
  background: red;
  width: 100%;
  justify-content: space-between;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 227px;
  padding: 1, 6rem 1.6rem;
  border-radius: 20px;

  &::placeholder {
    font-size: 1.8rem;
    padding: 1rem 0 0 1rem;
    margin-left: 2rem;
    color: ${({ theme }: Theme) => theme.colors.gray900};
    line-height: 2.7rem;
  }
`;

export const VerticalStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2rem 1rem;
  overflow-y: hidden;
`;

export const HorizontalStack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const StageContainer = styled.div`
  width: 596px;
  height: 894px;
  background: white;
  margin: 2.5rem 2rem;
`;

export const ExitIcon = styled.img`
  position: absolute;
  right: 1%;
  bottom: 0;
  top: 0;
  cursor: pointer;
`;

