/*
This file render all icons can be draggable in our editor (canvas)
we worked here with list icons from api,   
*/

import React, { useEffect, useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { useMainHook } from "../../../../hooks/main";
import api from "../../../../services/api";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import {
  stylesInject,
  stylesInjectLarge,
  stylesInjectMedium
} from "../../helpers/KonvaDropObject";
import { useAsidebarEditor } from "../../hooks/asidebarEditor";
import { useSelection } from "../../hooks/selection";
import { getWidthOfScreenUser } from "../../utils/getWidthOfScreenUser";
import { ContainerLoading } from "../LoadingEditor/styles";
import SearchInputBase from "../SearchInputBase";
import CardElements from "../Sidebar/components/CardElements";
import { ContainerSidebarNav } from "../Sidebar/components/ContainerSidebar/styles";
import GridElements from "../Sidebar/components/GridElements";
import GridItemElements from "../Sidebar/components/GridItemElements";
import TooltipIconsApi from "../Sidebar/components/TooltipIconsApi";
import { Container, FlexSearch, Grid, Heading, NotResult } from "./styles";
import Tooltip from "../Tooltip";

//set up interfaces
interface ImageIcon {
  created_at: string;
  creator_id: string;
  icon_url: string;
  id: string;
  key_words: string;
  last_editor_id: string;
  name: string;
  updated_at: string;
  dpath: string;
  is_icon_filled: boolean;
  stroke_width: string;
  nickname: string;
}

interface MutableEvent extends EventTarget {
  dpath?: string;
}

const Icons: React.FC = () => {
  //hooks
  const { setSelectedObject } = useSelection();
  const { dragUrl, strokeWidth } = useMainHook();
  const { grab, setIsGrab, searchIcon, setSearchIcon, setNameIconSelect } =
    useAsidebarEditor();

  //set up all states
  const [data, setData] = useState<ImageIcon[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isSmallView, setIsSmallView] = useState(false);
  const [isMediumView, setIsMediumView] = useState(false);
  const [isLargeView, setIsLargeView] = useState(false);
  //get window user dimension
  let width = window.innerWidth;

  //list all icons of we repository and resolve pagination
  //and user can be search any icon from our storage of icons
  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("icon-repository", {
          params: {
            pagination: currentPage,
            search: searchIcon === "" ? null : searchIcon
          }
        });
        //verifing if they user are be searched something
        //if search value is differents of empty then he is searching
        if (searchIcon === "") {
          setData(prevState => [...prevState, ...response.data.dataArray]);
        } else {
          setData(response.data.dataArray);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [currentPage, searchIcon]);

  //reset current page and has more pages
  useEffect(() => {
    if (searchIcon !== "") {
      setCurrentPage(0);
      setHasMore(false);
    }
  }, [searchIcon]);

  //verifing with the api if next page exists
  useEffect(() => {
    const autoLoad = async () => {
      const response = await api.get("icon-repository", {
        params: {
          pagination: currentPage + 1
        }
      });
      //if return length then has more pages
      if (response.data.dataArray.length > 0) {
        setHasMore(true);
      } else {
        //if return length 0 because doesn`t exists any pages more
        setHasMore(false);
      }
    };

    autoLoad();
  }, [currentPage, searchIcon]);

  //intercept div for generate infinity scroll
  //for pagination observer when final div appear
  useEffect(() => {
    if (!loading) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          if (hasMore && searchIcon === "") {
            setCurrentPage(state => state + 1);
          }
        }
      });
      //when intersection id sentinella appear
      intersectionObserver?.observe(document?.querySelector("#newIcons"));
      //cleanup function for observer
      return () => intersectionObserver?.disconnect();
    }
  }, [loading, hasMore, searchIcon]);

  //dynamically apply width of user through your window dimension
  //and then each time width is change this trigger goes fire
  useEffect(() => {
    getWidthOfScreenUser(
      width,
      setIsSmallView,
      setIsMediumView,
      setIsLargeView
    );
  }, [width]);

  //just reset grab move (your active)
  useEffect(() => {
    setIsGrab({ active: false });
  }, []);

  return (
    <ContainerSidebarNav>
      {loading ? (
        <ContainerLoading>
          <ContractAnimation />
        </ContainerLoading>
      ) : (
        <Container>
          <FlexSearch
            onClick={() => {
              setSelectedObject(null);
            }}
          >
            <SearchInputBase
              placeholder="Pesquisar"
              value={searchIcon}
              onChange={e => setSearchIcon(e.target.value)}
              setSearchIcon={setSearchIcon}
            />
          </FlexSearch>
          {/* <Heading style={{ margin: "3rem auto 0 auto", color: "#343A40" }}>
            Arraste o elemento para o editor para inserir no documento
          </Heading> */}
          {data.length === 0 ? (
            <NotResult>Nenhum Ícone encontrado</NotResult>
          ) : (
            <Grid>
              {data.map((image: ImageIcon, index) => {
                return (
                  <GridItemElements key={index}>
                    <CardElements>
                      <Tooltip
                        title="Arraste para usar"
                        placement="bottom-start"
                      >
                        <img
                          style={{
                            opacity: grab && index === grab.index ? "0.1" : "1",
                            cursor:
                              grab && index === grab.index
                                ? "-webkit-grabbing !important"
                                : "-webkit-grab !important",
                            transition: "all 0.1s linear"

                            /* W3C standards syntax, should come least */
                          }}
                          onDragEnd={(
                            event: React.DragEvent<HTMLImageElement>
                          ) => {
                            setIsGrab({ active: false });
                          }}
                          className="icons-elements-sidebar"
                          alt={
                            image.is_icon_filled
                              ? `iconFilled#@@#${Math.random().toString()}`
                              : `icon#@@#${Math.random().toString()}`
                          }
                          src={image.icon_url}
                          draggable={true}
                          onDragStart={(
                            event: React.DragEvent<HTMLImageElement>
                          ) => {
                            let eventClone: MutableEvent = event.target;
                            eventClone.dpath = image.dpath;
                            dragUrl.current = event.target;
                            strokeWidth.current = String(image.stroke_width);
                            setNameIconSelect(image.nickname);
                          }}
                          id={image.id}
                        />
                      </Tooltip>
                    </CardElements>
                  </GridItemElements>
                );
              })}
            </Grid>
          )}
          <li
            id="newIcons"
            className="testing"
            style={{
              height: "80px",
              display: "flex",
              marginBottom: "4rem",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {hasMore && !searchIcon && <MoonLoader size={14} color={"#000"} />}
          </li>
        </Container>
      )}
    </ContainerSidebarNav>
  );
};

export default Icons;
