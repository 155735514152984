import { HTMLProps } from "react";
import styled from "styled-components";

export const ListItemText = styled.div`
  width: 100%;
  border: 1px solid #d6d8d9;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  gap: 10px;

  user-select: none;
  cursor: grab;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    width: 30px;
    height: 30px;
    border-radius: 5px;

    :hover {
      background-color: #ebebec;
    }
  }
`;

export const HeaderClause = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;

    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    width: 24px;
    height: 24px;

    cursor: pointer;
  }
`;

export const TextClause = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  white-space: normal;

  min-height: 41px;
  max-height: 62px;

  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: justify;
`;
