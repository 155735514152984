import styled from "styled-components";

export const BoxError = styled.div`
  width: 100%;
  display: inline-block;
  height: 45px;

  div {
    background-color: ${({ theme }) => theme.colors.error10};
    border: 1px solid ${({ theme }) => theme.colors.error100};
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.error100};
    padding: 10px 5px 10px 0px;
  }

  p {
    font-weight: 400;
    font-size: 14px;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  svg {
    min-height: 20px;
    margin-left: 15px;
    width: 20px;
  }

  @media (max-width: 1600px) {
    height: 50px;
    div {
      padding: 7px 5px 7px 0px;
      border-radius: 5px;
    }
  }

  @media (max-width: 1366px) {
    p {
      font-size: 13px;
      border-radius: 8px;
      height: 30px;
    }

    div {
      padding: 3px 5px 3px 0px;
    }

    svg {
      margin-left: 10px;
      height: 17px;
      width: 17px;
    }
  }

  @media (max-width: 1163px) {
    p {
      font-size: 12px;
    }
  }
`;
