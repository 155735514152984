import styled from "styled-components";

interface ContainerProps extends React.HTMLProps<HTMLDivElement> {
  maxWidth?: string;
}

export const ContainerImageLeft = styled.div<ContainerProps>`
  width: 100%;
  max-width: 240px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 60;

  & .btn-toolbar {
    align-items: center;
    display: flex;
    background: transparent;
    width: 40px;
    height: 40px;
    justify-content: center;
    border-radius: 10px;
    position: relative;
    z-index: 90;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 1600px) {
    max-width: 240px;
  }

  @media (max-width: 1366px) {
    max-width: 210px;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 60;

  & .btn-toolbar {
    align-items: center;
    display: flex;
    background: transparent;
    width: 40px;
    height: 40px;
    justify-content: center;
    border-radius: 10px;
    position: relative;
    z-index: 90;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 1600px) {
    /* max-width: 20px; */
  }

  @media (max-width: 1368px) {
    max-width: 150px;
  }
`;

export const ContainerRight = styled.div<ContainerProps>`
  width: 100%;
  max-width: 190px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 60;

  & .btn-toolbar {
    align-items: center;
    display: flex;
    background: transparent;
    width: 40px;
    height: 40px;
    justify-content: center;
    border-radius: 10px;
    position: relative;
    z-index: 90;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 1600px) {
    max-width: 170px;
  }

  @media (max-width: 1368px) {
    max-width: 165px;
  }
`;

