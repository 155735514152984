import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FilterAlphaProps } from "../../../../dtos/FilterAlphaProps";
import { animate, useAnimation, useMotionValue, motion } from "framer-motion";
import { dataAlpha } from "../../utils/dataAlphabetic";
import {
  BodyCategories,
  BoxCategories,
  Container,
  LettersContainer,
  ListItem,
} from "./styles";
import ReactLoading from "react-loading";

interface CategoriesProps {
  id: string;
  creator_id: string;
  last_editor_id: string;
  title: string;
  description?: null | string;
  created_at: string;
  updated_at: string;
}

interface ParamsCategoriesProps {
  pagination: number;
  filter: string;
}

interface QueryClausesProps {
  pagination?: number;
  search?: string;
  filter?: string;
}

interface CategoriesBoxProps {
  handleBoxCategories?: () => void;
  toggleBoxCategories?: () => void;
  dataCategories?: CategoriesProps[];
  setParamsCategories?: React.Dispatch<
    React.SetStateAction<ParamsCategoriesProps>
  >;
  paramsCategories?: ParamsCategoriesProps;
  query?: QueryClausesProps;
  setQuery?: React.Dispatch<React.SetStateAction<QueryClausesProps>>;
  focusInput?: boolean;
  setFocusInput?: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterBits?: boolean;
  setIsFilterBits?: React.Dispatch<React.SetStateAction<boolean>>;
  closeToggle?: () => void;
  pageCurrent?: number;
  setPageCurrent?: React.Dispatch<React.SetStateAction<number>>;
  limitPage?: number;
  setLimitPage?: React.Dispatch<React.SetStateAction<number>>;
}

const list = {
  visible: {
    opacity: 1,
    y: 5,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    y: -20,
    transition: {
      when: "afterChildren",
    },
  },
};

const variants = {
  visible: (i: number) => ({
    opacity: 1,
    transition: {
      delay: i * 0.3,
    },
  }),
  hidden: { opacity: 0 },
};

const CategoriesBox: React.FC<CategoriesBoxProps> = ({
  handleBoxCategories,
  dataCategories,
  setParamsCategories,
  paramsCategories,
  query,
  setQuery,
  focusInput,
  setFocusInput,
  isFilterBits,
  setIsFilterBits,
  closeToggle,
  pageCurrent,
  setPageCurrent,
  limitPage,
  setLimitPage,
}) => {
  const [filterAlphaCategory, setFilterAlphaCategory] =
    useState<FilterAlphaProps>({
      value: "all",
    });

  const handleFilterOption = useCallback(
    (value) => {
      setFilterAlphaCategory({ value: value });
      setParamsCategories((state) => {
        return {
          ...state,
          filter: value,
        };
      });
    },
    [filterAlphaCategory, paramsCategories]
  );

  const handleCategoryFilter = useCallback(
    (value: string) => {
      setIsFilterBits((prevState) => true);
      setQuery((state) => {
        return {
          ...state,
          category: value,
          filter: "all",
        };
      });
      handleBoxCategories();
    },
    [query, isFilterBits]
  );

  const controls = useAnimation();
  const x = useMotionValue(0);

  useEffect(() => {
    const controls = animate(x, 100, {
      type: "spring",
      stiffness: 2000,
    });

    return controls.stop;
  }, []);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        if (pageCurrent <= limitPage) {
          setPageCurrent((prevState) => {
            return prevState + 1;
          });
        }
      }
    });

    const myDiv = document.querySelector("#sentinela3");

    if (myDiv) {
      intersectionObserver?.observe(myDiv);
    }

    return () => intersectionObserver?.disconnect();
  }, [limitPage]);

  return (
    <BoxCategories
      variants={list}
      initial="hidden"
      animate="visible"
      as={motion.div}
      // ref={ref2}
    >
      <Container>
        <LettersContainer>
          {dataAlpha.map((option) => (
            <button
              className={`alphabetical ${
                filterAlphaCategory.value === option.object && "active"
              }`}
              onClick={() => {
                setPageCurrent(0);
                handleFilterOption(option.object);
              }}
            >
              {option.letter}
            </button>
          ))}
        </LettersContainer>

        <BodyCategories>
          {dataCategories.length > 0 ? (
            dataCategories.map((category) => (
              <>
                <ListItem
                  variants={list}
                  initial="hidden"
                  animate="visible"
                  as={motion.div}
                  onClick={() => handleCategoryFilter(category.title)}
                >
                  {category.title}
                </ListItem>
              </>
            ))
          ) : (
            <span
              style={{
                fontStyle: "italic",
                fontSize: "1.2rem",
              }}
            >
              Sem resultado para pesquisa.
            </span>
          )}
        </BodyCategories>
        {
          <div id="sentinela3" style={{ width: "100%", height: "24px" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactLoading type="spin" color="#fff" height={22} width={22} />
            </div>
          </div>
        }
      </Container>
    </BoxCategories>
  );
};

export default CategoriesBox;
