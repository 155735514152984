import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useMainHook } from "../../../hooks/main";
import { useHeaderEditor } from "./headerEditor";
import { usePagesEditor } from "./pagesEditor";
import { useSelection } from "./selection";

interface WorkspaceEditorContextProviderProps {
  children: ReactNode;
}

interface WorkspaceEditorData {
  isZoom: boolean;
  setIsZoom: React.Dispatch<React.SetStateAction<boolean>>;
  isPan: boolean;
  setIsPan: React.Dispatch<React.SetStateAction<boolean>>;
  loadingTemplate: boolean;
  setLoadingTemplate: React.Dispatch<React.SetStateAction<boolean>>;

  isActiveUpPage: boolean;
  setIsActiveUpPage: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveHoverMoveUpPage: {
    visible: boolean;
    index: number;
  };
  setIsActiveHoverMoveUpPage: React.Dispatch<React.SetStateAction<any>>;
  isHandleHoverIconMoveDownPage: {
    visible: boolean;
    index: number;
  };
  setIsHandleHoverIconMoveDownPage: React.Dispatch<React.SetStateAction<any>>;
  handleHoverMoveUpPage: (index: number, visible: boolean) => void;
  handleHovePageMoveDown: (index: number, visible: boolean) => void;
  handleEnterMouse: () => void;
  handleOutside: () => void;
  tupeOfText: React.MutableRefObject<string>;
  checkDeselect: (e: any) => void;
  draggableVector: React.MutableRefObject<string>;
  showRuler: boolean;
  handleShowRuler: () => void;
  setShowRuler: (value: React.SetStateAction<boolean>) => void;
  flexEditorRef: React.MutableRefObject<HTMLDivElement>;
  headerEditorRef: React.MutableRefObject<HTMLDivElement>;
  toolbarEditorRef: React.MutableRefObject<HTMLDivElement>;
  guideLineHDiff: () => number;
  guidelineVDiff: () => number;
  scrollEditorRef: React.MutableRefObject<number>;
  handleMouseMoveLineGuide: (
    event: React.MouseEvent<HTMLDivElement>,
    page: any
  ) => void;
  isDragTemplate: boolean;
  setIsDragTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  containerRef: React.MutableRefObject<HTMLDivElement>;
}

const WorkspaceEditorContext = createContext({} as WorkspaceEditorData);

const WorkspaceEditorProvider: React.FC<
  WorkspaceEditorContextProviderProps
> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { handleSaveTemplate } = useHeaderEditor();
  const { linespage, setMousePosition } = usePagesEditor();

  const { shiftPressed, setShiftPressed, nodesShift, setNodesShift } =
    useSelection();

  const [isZoom, setIsZoom] = useState(true);
  const [isPan, setIsPan] = useState(true);
  const [isActiveUpPage, setIsActiveUpPage] = useState(true);
  const [loadingTemplate, setLoadingTemplate] = useState(true);
  const [showRuler, setShowRuler] = useState(false);

  const scrollEditorRef = useRef(0);
  const [isDragTemplate, setIsDragTemplate] = useState(false);
  const flexEditorRef = useRef<HTMLDivElement>(null);
  const headerEditorRef = useRef<HTMLDivElement>(null);
  const toolbarEditorRef = useRef<HTMLDivElement>(null);

  const handleShowRuler = useCallback(() => {
    setShowRuler((prev) => !prev);
  }, []);

  const tupeOfText = useRef("");
  const draggableVector = useRef("");

  const [isActiveHoverMoveUpPage, setIsActiveHoverMoveUpPage] = useState({
    visible: false,
    index: 0,
  });

  const [isHandleHoverIconMoveDownPage, setIsHandleHoverIconMoveDownPage] =
    useState({
      visible: false,
      index: 0,
    });

  const handleHoverMoveUpPage = (index: number, visible: boolean) => {
    setIsActiveHoverMoveUpPage({
      visible,
      index,
    });
  };

  const handleHovePageMoveDown = (index: number, visible: boolean) => {
    setIsHandleHoverIconMoveDownPage({
      visible,
      index,
    });
  };

  const handleEnterMouse = () => {
    setIsPan(true);
    setIsZoom(false);
  };

  const handleOutside = () => {
    setIsPan(false);
    setIsZoom(true);
  };

  const checkDeselect = useCallback(
    (event) => {
      const clickedOnEmpty = event.target === event.target.getStage();
      if (clickedOnEmpty) {
        setNodesShift([]);
      }
    },
    [setNodesShift]
  );

  //calculation made to position the H line with the mouse
  const guideLineHDiff = useCallback(() => {
    const guideZoneHight = document
      .getElementsByClassName("get-height_guide_zone")[0]
      ?.getBoundingClientRect().width;

    return (
      (flexEditorRef.current?.getBoundingClientRect().width -
        guideZoneHight +
        4) /
        2 +
      6
    );
  }, []);

  //calculation made to position the V line with the mouse
  const guidelineVDiff = useCallback(() => {
    //Screen elements needed to calculate the difference in the y-axis of the ruler

    const rulerTopHeight = document
      .getElementsByClassName("get-height_ruler-top")[0]
      ?.getBoundingClientRect().height;

    const horizontalStackHeight = document
      .getElementsByClassName("get-height-horizontal_stackRef")[0]
      ?.getBoundingClientRect().height;

    const headerEditorHeigth = document
      .getElementById("get-height-horizontal_header-Editor")
      ?.getBoundingClientRect().height;

    const toolbarEditorHeight =
      toolbarEditorRef.current?.getBoundingClientRect().height;

    const heightCorrections = 4;

    return (
      headerEditorHeigth +
      toolbarEditorHeight +
      rulerTopHeight +
      horizontalStackHeight -
      scrollEditorRef.current +
      heightCorrections
    );
  }, []);

  //calculation made to position the V line with the mouse scroll
  const guideLineDiffPages = useCallback((page) => {
    if (page > 1) {
      const dif =
        document
          .getElementsByClassName("get_heigth-container_page")[0]
          ?.getBoundingClientRect().height *
        (page - 1);

      return page > 2 ? dif + 45 : dif;
    } else {
      return 0;
    }
  }, []);

  //capture where the mouse position to position the guidelines

  const handleMouseMoveLineGuide = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, page) => {
      setMousePosition({
        x: event.clientX - guideLineHDiff(),
        y: event.clientY - guidelineVDiff() - guideLineDiffPages(page),
      });
    },
    [setMousePosition, guideLineHDiff, guidelineVDiff, guideLineDiffPages]
  );

  return (
    <WorkspaceEditorContext.Provider
      value={{
        isZoom,
        setIsZoom,
        isPan,
        setIsPan,
        loadingTemplate,
        setLoadingTemplate,
        isActiveUpPage,
        setIsActiveUpPage,
        isActiveHoverMoveUpPage,
        setIsActiveHoverMoveUpPage,
        isHandleHoverIconMoveDownPage,
        setIsHandleHoverIconMoveDownPage,
        handleHoverMoveUpPage,
        handleHovePageMoveDown,
        handleEnterMouse,
        handleOutside,
        checkDeselect,
        tupeOfText,
        draggableVector,
        showRuler,
        handleShowRuler,
        setShowRuler,
        flexEditorRef,
        headerEditorRef,
        toolbarEditorRef,
        guideLineHDiff,
        guidelineVDiff,
        scrollEditorRef,
        handleMouseMoveLineGuide,
        isDragTemplate,
        setIsDragTemplate,
        containerRef,
      }}
    >
      {children}
    </WorkspaceEditorContext.Provider>
  );
};

// creating hook

function useWorkspaceEditor(): WorkspaceEditorData {
  const context = useContext(WorkspaceEditorContext);

  if (!context) {
    throw new Error(
      "useWorkspaceEditor must be used with an WorkspaceEditorContext"
    );
  }

  return context;
}

export { WorkspaceEditorProvider, useWorkspaceEditor };

