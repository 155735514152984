import React, { useCallback, useState } from "react";
import UploadImages from "../UploadImages";
import UploadImagesTeam from "./components/UploadImagesTeam";

import { Container, MyUploads, SelectTab, TeamUploads } from "./styles";
import Tooltip from "../Tooltip";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";

const MasterUploads: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<"MyUploads" | "TeamsUploads">(
    "MyUploads"
  );

  const handleChangeTab = useCallback(
    (type: "MyUploads" | "TeamsUploads") => {
      setSelectedTab(type);
    },
    [selectedTab]
  );

  return (
    <Container>
      <SelectTab>
        <Tooltip
          title="Meus uploads"
          description="Imagens inseridas por você"
          placement="bottom-start"
        >
          <MyUploads
            onClick={() => handleChangeTab("MyUploads")}
            selectedTab={selectedTab}
          >
            <span>Meus uploads</span>
          </MyUploads>
        </Tooltip>
        <Tooltip
          title="Meus uploads"
          description="Imagens inseridas por você"
          placement="bottom-start"
        >
          <TeamUploads
            onClick={() => handleChangeTab("TeamsUploads")}
            selectedTab={selectedTab}
          >
            <span>Uploads da equipe</span>
          </TeamUploads>
        </Tooltip>
      </SelectTab>

      {selectedTab === "MyUploads" ? <UploadImages /> : <UploadImagesTeam />}
    </Container>
  );
};

export default MasterUploads;
