import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import {
  Button,
  ButtonBack,
  ContainerModal,
  HStack,
  HStackBtn,
  InputHex,
  Text
} from "./styles";
import { AnimatePresence, motion } from "framer-motion";

import { useSelection } from "../../hooks/selection";
import { useTextsEdition } from "../../hooks/textsEdition";
import iconTransprency from "./assets/bg-trans.png";

interface ModalHexProps {
  isOpen: boolean;
  onRequestClose: () => void;
  idClause?: string;
  refreshClause?: boolean;
  setRefreshClause?: React.Dispatch<React.SetStateAction<boolean>>;
  type?: "none" | "color" | "background";
}

export const ModalHex: React.FC<ModalHexProps> = ({
  isOpen,
  onRequestClose,
  type
}) => {
  const {
    colorText,
    setColorText,
    selectionColor,
    setSelectionColor,
    setDisableFocusQuill
  } = useSelection();
  const { quillRef } = useTextsEdition();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isActiveTransparency, setIsActiveTransparency] = useState(false);

  const handleHex = useCallback(() => {
    if (type === "color") {
      quillRef.current.getEditor().focus();

      quillRef.current
        .getEditor()
        .formatText(
          selectionColor.index,
          selectionColor.length,
          "color",
          colorText
        );
      onRequestClose();
    } else if (type === "background") {
      quillRef.current.getEditor().focus();

      quillRef.current
        .getEditor()
        .formatText(
          selectionColor.index,
          selectionColor.length,
          "background",
          colorText
        );
      onRequestClose();
    }
  }, [quillRef, selectionColor, colorText, type]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      scale: 1.2,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 100,
        staggerDirection: -1
      }
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setColorText("");
      setSelectionColor(oldState => {
        return {
          ...oldState,
          length: 0,
          index: 0
        };
      });

      setIsActiveTransparency(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (colorText !== "transparent") {
      setIsActiveTransparency(false);
    }
  }, [colorText]);

  useEffect(() => {
    if (isOpen) {
      setDisableFocusQuill(true);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 500);
    } else {
      setDisableFocusQuill(false);
    }
  }, [isOpen]);

  return (
    <Modal
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
    >
      <AnimatePresence>
        <ContainerModal
          as={motion.form}
          initial="hidden"
          animate="show"
          variants={container}
          exit={{
            opacity: 0,
            scale: 0.8,
            x: -300
          }}
          transition={{ duration: 1 }}
        >
          <Text>
            Inserir hexadecimal para editar a{" "}
            {type === "color" ? "cor do texto" : "cor do fundo do texto"}{" "}
          </Text>
          <HStackBtn srcImg={iconTransprency} isActive={isActiveTransparency}>
            <InputHex
              type="text"
              value={colorText}
              ref={inputRef}
              onChange={(event: any) => {
                setColorText(oldState => {
                  if (!event.target.value.startsWith("#")) {
                    // Check if the string doesn't start with a "#"
                    event.target.value = "#" + event.target.value; // If not, add a "#" to the beginning of the string
                  }

                  return event.target.value; // Return the modified string
                });
              }}
              placeholder="Hexadecimal"
            />
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsActiveTransparency(true);
                setColorText("transparent");
              }}
              className="button-transparency"
            ></div>
            {/* <BoxColor color={textRef.current?.value} /> */}
          </HStackBtn>

          <HStack>
            <Button
              onClick={handleHex}
              type="button"
              style={{ marginRight: "1rem" }}
            >
              Confirmar
            </Button>
            <ButtonBack onClick={onRequestClose}>Voltar</ButtonBack>
          </HStack>
        </ContainerModal>
      </AnimatePresence>
    </Modal>
  );
};

