import styled from "styled-components";

interface FlexCardProps {
  src?: string;
}

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  width: 100%;
`;

export const FlexCard = styled.div<FlexCardProps>`
  width: 100%;
  height: 299.383px;
  position: relative;

  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  user-select: none;

  cursor: pointer;

  @media (min-width: 1920px) {
    height: 352.896px;
  }
`;

export const ImageThumbnail = styled.div<FlexCardProps>`
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src && src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Text = styled.label`
  width: 100%;
  text-align: start;
  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.black50};

  max-width: 27ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
