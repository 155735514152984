import React from "react";
import { Circle, Container, LineIntersection } from "./styles";
import { Steps } from "../../constants/stepsSecure";

// import { Container } from './styles';
type Props = {
  step: "1" | "2" | "3" | "4";
};

const ControllerSteps: React.FC<Props> = ({ step }) => {
  return (
    <Container>
      <LineIntersection />
      {Steps.map((stepInterator) => (
        <Circle
          key={stepInterator.step}
          currentStep={Number(step) === Number(stepInterator.step)}
        >
          <span> {stepInterator.step}</span>
        </Circle>
      ))}
    </Container>
  );
};

export default ControllerSteps;

