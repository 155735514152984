/* 
this file renders the some components that can be rendered by Stage Konva,
so according to the object type, we render a Konva component to Rendering
*/

import Konva from "konva";
import React, { MutableRefObject } from "react";
import ReactQuill from "react-quill";
import CircleShape from "../Circle";

import Rectangle from "../Rectangle";
import SimpleLine from "../SimpleLine";
import SimpleText from "../SimpleText";
import StarIcon from "../Star";
import SvgElement from "../SvgShape";
import TriangleNew from "../TriangleNew";
import URLImage from "../URLimage";
import URLImageDrag from "../URLImageDrag";
import * as QuillText from "quill";
import { IRenderedObject } from "../../../../../dtos/RenderedObject";
import AutoNumberPage from "../AutoNumberPage";
import RichTextImage from "../RichTextImage";

//set up interfaces
interface SelectionProps {
  index: number;
  length: number;
}

interface RenderingProps {
  objectProps: IRenderedObject;
  setSelectedObject?: React.Dispatch<React.SetStateAction<Konva.Shape>>;
  selectedObject?: Konva.Shape;
  addToHistory?: (isLastHistory?: boolean) => void;

  currentMultipleSelection?: React.MutableRefObject<Konva.Transformer>;
  setObjectScreen?: React.Dispatch<React.SetStateAction<PagesProps[]>>;
  commandKeyboard?: boolean;
  setCommandBlockKeyboard?: React.Dispatch<React.SetStateAction<boolean>>;
  transformerSelectedObject?: React.MutableRefObject<Konva.Transformer | null>;
  quillRef?: MutableRefObject<ReactQuill>;
  requestUpdateText?: () => void;
  textRich?: MutableRefObject<string>;
  isEditing?: "closed" | "blocked" | "open";
  setIsEditing?: React.Dispatch<
    React.SetStateAction<"closed" | "blocked" | "open">
  >;
  blockingKeyboard?: boolean;
  setBlockingKeyboard?: React.Dispatch<React.SetStateAction<boolean>>;
  templateStartedShared?: boolean;
  setTemplateStartedShared?: React.Dispatch<React.SetStateAction<boolean>>;
  oldContentsState?: null | QuillText.DeltaStatic;
  setOldContentsState?: null | React.Dispatch<
    React.SetStateAction<QuillText.DeltaStatic>
  >;
  newContentsState?: null | QuillText.DeltaStatic;
  setNewContentsState?: null | React.Dispatch<
    React.SetStateAction<QuillText.DeltaStatic>
  >;
  selectionCursor?: SelectionProps;
  setSelectionCursor?: React.Dispatch<React.SetStateAction<SelectionProps>>;
  findDiff?: () => void;
  buttonRef?: React.MutableRefObject<HTMLButtonElement>;
  isEditingQuill?: boolean;
  setIsEditingQuill?: React.Dispatch<React.SetStateAction<boolean>>;
  newDelta?: null | QuillText.DeltaStatic;
  newChanges?: string;
  setNewChanges?: React.Dispatch<React.SetStateAction<string>>;
  setNewDelta?: React.Dispatch<React.SetStateAction<QuillText.DeltaStatic>>;
  oldContentsRef?: null | React.MutableRefObject<QuillText.DeltaStatic>;
  newContentsRef?: null | React.MutableRefObject<QuillText.DeltaStatic>;
  getQuillCursorRef?: null | React.MutableRefObject<SelectionProps>;
  isNewTextRef?: React.MutableRefObject<boolean>;
  isTypingQuillRef?: React.MutableRefObject<boolean>;
  quillRefComponent?: React.MutableRefObject<ReactQuill>;
  whichUserEdited?: React.MutableRefObject<string>;
  nameOfCurrentUser?: React.MutableRefObject<string>;
  interceptModifications?: React.MutableRefObject<boolean>;
  textIsChanged?: React.MutableRefObject<boolean>;
  blockRenderImage?: React.MutableRefObject<boolean>;
  blockBackspace?: React.MutableRefObject<boolean>;
  hasGroupShared?: React.MutableRefObject<boolean>;
  whichKeyPress?: React.MutableRefObject<string>;
  selectionFormatText?: React.MutableRefObject<QuillText.StringMap>;
  isClickLineMultiSelection?: boolean;
  setIsClickLineMultiSelection?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: (newAttrs: IRenderedObject) => void;
  disableFocusQuill?: boolean;
  setDisableFocusQuill?: React.Dispatch<React.SetStateAction<boolean>>;
  hasColorBlock?: React.MutableRefObject<boolean>;
  rectangule?: React.MutableRefObject<Konva.Rect>;
  blockDblClick?: boolean;
  setBlockDblClick?: React.Dispatch<React.SetStateAction<boolean>>;
  index?: number;
  idPage?: number;
  handleRemoveElementGroup?: (id: string) => void;
  currentQuillFormats?: QuillText.StringMap;
  setCurrentQuillFormats?: React.Dispatch<
    React.SetStateAction<QuillText.StringMap>
  >;
  selectionQuill?: QuillText.RangeStatic;
  setSelectionQuill?: React.Dispatch<
    React.SetStateAction<QuillText.RangeStatic>
  >;
  handleOpenAcceptDiff?: () => void;
  handleOpenDeclineDiff?: () => void;
  pendingSave?: React.MutableRefObject<boolean>;
  isDraggingObject?: boolean;
  setIsDraggingObject?: React.Dispatch<React.SetStateAction<boolean>>;
  setPageObject?: React.Dispatch<React.SetStateAction<number>>;
}

interface PagesProps {
  pageNumber: number;
  renderObjects: IRenderedObject[];
}

export default function Rendering({
  objectProps,
  setSelectedObject,
  selectedObject,
  addToHistory,
  currentMultipleSelection,
  setObjectScreen,
  commandKeyboard,
  setCommandBlockKeyboard,
  transformerSelectedObject,
  quillRef,
  requestUpdateText,
  textRich,
  isEditing,
  setIsEditing,
  blockingKeyboard,
  setBlockingKeyboard,
  templateStartedShared,
  setTemplateStartedShared,
  oldContentsState,
  setOldContentsState,
  newContentsState,
  setNewContentsState,
  selectionCursor,
  setSelectionCursor,
  findDiff,
  buttonRef,
  newDelta,
  setNewDelta,
  isEditingQuill,
  setIsEditingQuill,
  newChanges,
  setNewChanges,
  newContentsRef,
  oldContentsRef,
  getQuillCursorRef,
  isNewTextRef,
  isTypingQuillRef,
  quillRefComponent,
  whichUserEdited,
  nameOfCurrentUser,
  interceptModifications,
  textIsChanged,
  blockRenderImage,
  blockBackspace,
  hasGroupShared,
  whichKeyPress,
  selectionFormatText,
  isClickLineMultiSelection,
  setIsClickLineMultiSelection,
  onChange,
  disableFocusQuill,
  setDisableFocusQuill,
  hasColorBlock,
  rectangule,
  blockDblClick,
  setBlockDblClick,
  index,
  idPage,
  handleRemoveElementGroup,
  currentQuillFormats,
  setCurrentQuillFormats,
  selectionQuill,
  setSelectionQuill,
  handleOpenAcceptDiff,
  handleOpenDeclineDiff,
  pendingSave,
  isDraggingObject,
  setIsDraggingObject,
  setPageObject,
}: RenderingProps) {
  let RenderObject = null;

  switch (objectProps?.object) {
    case "image":
      RenderObject = (
        <URLImage
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          handleRemoveElementGroup={handleRemoveElementGroup}
          pendingSave={pendingSave}
          onChange={onChange}
          setPageObject={setPageObject}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
        />
      );
      break;
    case "circle":
      RenderObject = (
        <CircleShape
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          onChange={onChange}
          handleRemoveElementGroup={handleRemoveElementGroup}
          setPageObject={setPageObject}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
        />
      );

      break;

    case "rectangle":
      RenderObject = (
        <Rectangle
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          onChange={onChange}
          handleRemoveElementGroup={handleRemoveElementGroup}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
          setPageObject={setPageObject}
        />
      );

      break;

    case "square":
      RenderObject = (
        <Rectangle
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          onChange={onChange}
          handleRemoveElementGroup={handleRemoveElementGroup}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
          setPageObject={setPageObject}
        />
      );

      break;

    case "simpleLine":
      RenderObject = (
        <SimpleLine
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          onChange={onChange}
          handleRemoveElementGroup={handleRemoveElementGroup}
          setPageObject={setPageObject}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
        />
      );

      break;

    case "simpleText":
      RenderObject = (
        <SimpleText
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          commandKeyboard={commandKeyboard}
          setCommandBlockKeyboard={setCommandBlockKeyboard}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          onChange={(newAttrs) => { }}
        />
      );

      break;

    case "dropImage":
      RenderObject = (
        <URLImageDrag
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          handleRemoveElementGroup={handleRemoveElementGroup}
          pendingSave={pendingSave}
          onChange={onChange}
          setPageObject={setPageObject}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
        />
      );

      break;

    case "autoNumberPage":
      RenderObject = <AutoNumberPage index={index} shapeProps={objectProps} />;
      break;

    case "richTextImage":
      RenderObject = (
        <RichTextImage
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          quillRef={quillRef}
          requestUpdateText={requestUpdateText}
          textRich={textRich}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          blockingKeyboard={commandKeyboard}
          setBlockingKeyboard={setCommandBlockKeyboard}
          templateStartedShared={templateStartedShared}
          setTemplateStartedShared={setTemplateStartedShared}
          oldContentsState={oldContentsState}
          setOldContentsState={setOldContentsState}
          newContentsState={newContentsState}
          setNewContentsState={setNewContentsState}
          selectionCursor={selectionCursor}
          setSelectionCursor={setSelectionCursor}
          findDiff={findDiff}
          buttonRef={buttonRef}
          newDelta={newDelta}
          setNewDelta={setNewDelta}
          isEditingQuill={isEditingQuill}
          setIsEditingQuill={setIsEditingQuill}
          newChanges={newChanges}
          setNewChanges={setNewChanges}
          oldContentsRef={oldContentsRef}
          newContentsRef={newContentsRef}
          getQuillCursorRef={getQuillCursorRef}
          isNewTextRef={isNewTextRef}
          isTypingQuillRef={isTypingQuillRef}
          quillRefComponent={quillRefComponent}
          whichUserEdited={whichUserEdited}
          nameOfCurrentUser={nameOfCurrentUser}
          interceptModifications={interceptModifications}
          textIsChanged={textIsChanged}
          blockRenderImage={blockRenderImage}
          blockBackspace={blockBackspace}
          hasGroupShared={hasGroupShared}
          whichKeyPress={whichKeyPress}
          selectionFormatText={selectionFormatText}
          disableFocusQuill={disableFocusQuill}
          setDisableFocusQuill={setDisableFocusQuill}
          hasColorBlock={hasColorBlock}
          rectangule={rectangule}
          blockDblClick={blockDblClick}
          setBlockDblClick={setBlockDblClick}
          idPage={idPage}
          handleRemoveElementGroup={handleRemoveElementGroup}
          currentQuillFormats={currentQuillFormats}
          setCurrentQuillFormats={setCurrentQuillFormats}
          selectionQuill={selectionQuill}
          setSelectionQuill={setSelectionQuill}
          handleOpenAcceptDiff={handleOpenAcceptDiff}
          handleOpenDeclineDiff={handleOpenDeclineDiff}
          pendingSave={pendingSave}
          onChange={onChange}
          setPageObject={setPageObject}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
        />
      );

      break;

    case "star":
      RenderObject = (
        <StarIcon
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          handleRemoveElementGroup={handleRemoveElementGroup}
          onChange={onChange}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
          setPageObject={setPageObject}
        />
      );

      break;

    case "triangle2":
      RenderObject = (
        <TriangleNew
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          handleRemoveElementGroup={handleRemoveElementGroup}
          pendingSave={pendingSave}
          onChange={onChange}
          setPageObject={setPageObject}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
        />
      );

      break;

    case "triangleSVG":
      RenderObject = (
        <SvgElement
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          handleRemoveElementGroup={handleRemoveElementGroup}
          pendingSave={pendingSave}
          onChange={onChange}
          setPageObject={setPageObject}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
        />
      );

      break;
    case "starSVG":
      RenderObject = (
        <SvgElement
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          handleRemoveElementGroup={handleRemoveElementGroup}
          pendingSave={pendingSave}
          onChange={onChange}
          setPageObject={setPageObject}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
        />
      );

      break;
    case "text":
      RenderObject = (
        <SimpleText
          shapeProps={objectProps}
          setObjectScreen={setObjectScreen}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          commandKeyboard={commandKeyboard}
          setCommandBlockKeyboard={setCommandBlockKeyboard}
          currentMultipleSelection={currentMultipleSelection}
          transformerSelectedObject={transformerSelectedObject}
        />
      );

      break;

    case "icon":
      RenderObject = (
        <SvgElement
          shapeProps={objectProps}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          addToHistory={addToHistory}
          currentMultipleSelection={currentMultipleSelection}
          setObjectScreen={setObjectScreen}
          transformerSelectedObject={transformerSelectedObject}
          handleRemoveElementGroup={handleRemoveElementGroup}
          pendingSave={pendingSave}
          onChange={onChange}
          setPageObject={setPageObject}
          isDraggingObject={isDraggingObject}
          setIsDraggingObject={setIsDraggingObject}
        />
      );
      break;

    default:
      RenderObject = null;
      break;
  }

  return <>{RenderObject && RenderObject}</>;
}
