import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import ButtonPrimary from "../ButtonPrimary";
import { InputModal } from "../InputModal";
import {
  Box,
  BoxColor,
  Button,
  ButtonBack,
  ButtonGoBack,
  ContainerModal,
  HStack,
  HStackBtn,
  InputHex,
  Text,
  TextArea,
  TextContainer,
  VStack
} from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import api from "../../../../services/api";
import { useModalContext } from "../../../../hooks/modalContext";
import { useSelection } from "../../hooks/selection";
import { useMainHook } from "../../../../hooks/main";
import { useSharedDocument } from "../../hooks/sharedDocument";
import TitleH5 from "../../../../shared/components/TitleH5";

interface ModalHexProps {
  isOpen: boolean;
  onRequestClose: () => void;
  idClause?: string;
  refreshClause?: boolean;
  setRefreshClause?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalBlockFuncionality: React.FC<ModalHexProps> = ({
  isOpen,
  onRequestClose
}) => {
  const { colorText, setColorText, textRef } = useSelection();

  const handleHex = event => {
    event.preventDefault();
    const values = textRef.current.value;
    setColorText(values);
    onRequestClose();
  };

  const { sourceModalBlock } = useMainHook();
  const { isModalSharedOpen, handleOpenModalShared } = useSharedDocument();
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 100,
        staggerDirection: -1
      }
    }
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.9,
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <Modal
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
    >
      <AnimatePresence>
        <ContainerModal
          as={motion.form}
          initial="hidden"
          animate="show"
          variants={container}
          exit={{
            opacity: 0,
            scale: 0.8,
            x: -300
          }}
          transition={{ duration: 1 }}
        >
          <VStack>
            <TextContainer>
              {sourceModalBlock === "download" && (
                <>
                  <TitleH5 title="Baixar documento" />
                  <Text>
                    É preciso aceitar ou recusar todas as alterações para baixar
                    o documento.
                  </Text>
                </>
              )}

              {sourceModalBlock === "automatizing" && (
                <>
                  <TitleH5 title="Automatizar documento" />
                  <Text>
                    É preciso aceitar ou recusar todas as alterações para
                    automatizar o documento.
                  </Text>
                </>
              )}

              {sourceModalBlock === "shared" && (
                <>
                  <TitleH5 title="Descompartilhar documento" />
                  <Text>
                    É preciso aceitar ou recusar todas as alterações para
                    descompartilhar o documento.
                  </Text>
                </>
              )}

              {sourceModalBlock === "disableEditions" && (
                <>
                  <TitleH5 title="Desativar Alterações" />
                  <Text>
                    É preciso aceitar ou recusar todas as alterações para
                    desativar o controle de alterações neste documento.
                  </Text>
                </>
              )}
            </TextContainer>
            {sourceModalBlock === "shared" ? (
              <ButtonGoBack
                type="button"
                onClick={() => {
                  onRequestClose();
                  handleOpenModalShared();
                }}
              >
                Voltar
              </ButtonGoBack>
            ) : (
              <ButtonGoBack type="button" onClick={onRequestClose}>
                Voltar
              </ButtonGoBack>
            )}
          </VStack>
        </ContainerModal>
      </AnimatePresence>
    </Modal>
  );
};
