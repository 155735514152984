import DefauldImage from "../../assets/defaultImg.svg";
import { GrClose } from "react-icons/gr";
import { ItemStyle } from "./styled";
import { useTeams } from "../../../../../hooks/teams";
import Tooltip from "../../../../editor/components/Tooltip";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import { ISelectModalTeam } from "../../../../../dtos/teamsUtils";

interface UsersTeamGroupProps {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  avatar_url: string;
  email: string;
  is_owner: true;
}

interface ParticipantItemProps {
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
  user: UsersTeamGroupProps;
  handleRemoveMember:  (id_member: string, team_id: any) => Promise<void>
  isOwner: boolean;
}

const ParticipantItem: React.FC<ParticipantItemProps> = ({
  setSelectModal,
  user,
  handleRemoveMember,
  isOwner
}) => {
  const { userForDelete, setUserForDelete } = useTeams();

  return (
    <ItemStyle>
      <div>
        <figure>
          <img src={user?.avatar_url} alt="d" />
        </figure>
        <p>
          {user?.first_name} {user?.last_name}
        </p>
      </div>
      <span>{user?.email}</span>
      <p>{user?.is_owner ? "Proprietário" : "Membro"} </p>

      {!user?.is_owner && isOwner && (
        <Tooltip title="Excluir participante" placement="bottom-end">
          <div
            onClick={() => {
              setSelectModal({ modal: "Delete Participant", isOpen: true });
              setUserForDelete({
                ...userForDelete,
                name: `${user.first_name} ${user.last_name}`,
                id: user.id
              });
            }}
          >
            <PersonalizedIcon
              dPath={MountIcons.IconTrash.dPath}
              viewBox={MountIcons.IconTrash.viewBox}
            />
          </div>
        </Tooltip>
      )}
    </ItemStyle>
  );
};

export default ParticipantItem;
