import styled from "styled-components";

export const Container = styled.div`
  min-width: 60px;
  min-height: 24px;
  border-radius: 5px;
  border: 1px solid var(--black-black-50, #999c9f);
  background: var(--other-white, #fff);
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  align-items: center;
  cursor: pointer;

  span {
    color: #343a40;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
  }
`;

export const MenuBoxFontFamily = styled.div`
  position: absolute;
  top: 27px;
  left: -30px;
  background-color: #fff;
  width: 64px;
  max-height: 188px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 5px 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  div {
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 24px;
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 5px;
    justify-content: space-between;
    background-color: transparent;
    :hover {
      background-color: #f5f5f5;
    }

    img {
      width: 18px;
    }
  }

  span {
    color: #343a40;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin: 5px 0;
  }
`;
