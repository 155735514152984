import { useTheme } from "../../../../../../../hooks/theme";
import PersonalizedIcon from "../../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../../shared/utils/MountIcons";
import Tooltip from "../../../../Tooltip";
import {
  ButtonToolbarColor,
  ButtonToolbarColorOneclick,
  ColorSelect,
  Container,
} from "./styles";

interface IDropDownHeaderProps {
  isOpen: boolean;
  handleClick: () => void;
  ref: React.MutableRefObject<HTMLDivElement>;
  rest: any;
  newColor: string;
  elementForShow?: string;
  page: "oneclick" | "editor";
}

const DropDownHeader = ({
  isOpen,
  handleClick,
  newColor,
  elementForShow,
  page,
  ...rest
}: IDropDownHeaderProps) => {
  const { theme } = useTheme();

  if (page === "oneclick") {
    return (
      <Container Open={isOpen} onClick={handleClick}>
        <ButtonToolbarColorOneclick newColor={newColor} {...rest} />
      </Container>
    );
  }

  if (elementForShow === "Text") {
    return (
      <Container Open={isOpen} onClick={handleClick}>
        <ButtonToolbarColor {...rest}>
          <PersonalizedIcon
            dPath={MountIcons.IconsPaintBucket.dPath}
            viewBox={MountIcons.IconsPaintBucket.viewBox}
            inactivatedColor="#000"
            activeColor={theme.colors.primary}
            isActive={isOpen}
          />
          <ColorSelect newColor={newColor} />
        </ButtonToolbarColor>
      </Container>
    );
  }
  return (
    <Tooltip
      title="Cor do preenchimento"
      description="Escolha a cor do preenchimento do seu elemento."
      placement="bottom-start"
      disabled={isOpen}
    >
      <Container Open={isOpen} onClick={handleClick}>
        <ButtonToolbarColor {...rest}>
          <PersonalizedIcon
            dPath={MountIcons.IconsPaintBucket.dPath}
            viewBox={MountIcons.IconsPaintBucket.viewBox}
            inactivatedColor="#000"
            activeColor={theme.colors.primary}
            isActive={isOpen}
          />
          <ColorSelect newColor={newColor} />
        </ButtonToolbarColor>
      </Container>
    </Tooltip>
  );
};

export default DropDownHeader;
