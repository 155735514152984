/*
this page render component shared with me
on dashboard section, and user can view
projects that were shared with him, and transform that project
on your own document in your documents or open this document.
*/

import React, { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import { Container, Header } from "./styles";

import { useMainHook } from "../../../hooks/main";
import { AllMyProjects } from "../../../dtos/AllMyProjects";
import api from "../../../services/api";

import { ContractAnimation } from "../../../shared/components/ContractAnimation";
import { LoadingFlex } from "../../user/premium/components/ChangePassword/styles";
import PreviewTemplateModal from "../components/PreviewTemplateModal";
import HeaderSection from "../components/HeaderSection";
import SearchBar from "../components/SearchBar";
import GridTemplate from "../components/GridTemplate";
import Pagination from "../components/Pagination";
import { IHandlePreviewTemplateProps } from "../../../dtos/SharedInterfaces";
import { PagesProps } from "../../../dtos/PagesProps";
import Downloadfiles from "../../../shared/components/DownloadFiles";

const SharedWithMe: React.FC = () => {
  //route properties
  const navigator = useNavigate();
  const { number_page } = useParams();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;

  //hooks necessary
  const {
    setIsLoadingModal,
    currentTemplateInfo,
    documentName,
    debouncedSearchTerm,
    loadingDebounce,
    isIntentBuyModal,
    setIsIntentBuyModal,
  } = useMainHook();

  //set up all states
  const [allMyProjects, setAllMyProjects] = useState<AllMyProjects[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  //state Search Bar
  //state Modal Preview
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [preview, setPreview] = useState({ pages: [], title: "" });
  const [templateId, setTemplateId] = useState("");
  const [downloading, setDownloading] = useState(false);
  const whichFileToBeDownload = useRef<"word" | "pdf">("pdf");
  const documentPreviewData = useRef<PagesProps[] | null>(null);
  const idToastifyDownloadRef = useRef<"">(null);

  //get documents that were shared between api
  //not forget this page have it pagination
  //then we pass it for request which page params for user navigation
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const fetchAllMyTemplates = await api.get(
          "user-templates/shared-with-me",
          {
            params: {
              pagination: pageNumber,
              search: debouncedSearchTerm === "" ? null : debouncedSearchTerm,
            },
          }
        );

        setAllMyProjects(fetchAllMyTemplates.data.dataArray as AllMyProjects[]);
        setTotalPages(fetchAllMyTemplates.data.pagination);
        setLoading(false);
      } catch (error) {
        console.error("ERROR GETTING TEMPLATES " + error);
        setLoading(false);
      }
      api.get("user-templates");
    })();
  }, [pageNumber, debouncedSearchTerm]);

  //when user choose which template he wish used, this callback
  //update our main state and navigate it to document
  const handleChooseTemplate = useCallback(
    async (id: string) => {
      setIsLoadingModal(true);
      //currentTemplateInfo.current = myProject;
      try {
        const response = await api.get(`user-templates/${id}`);
        currentTemplateInfo.current = response.data;
        documentName.current = response.data.title;
        // setObjectScreen(response.data.template.arrayOfPages);
        setIsLoadingModal(false);
        navigator(`/editor/my-template/${id}`);
      } catch (err) {
        setIsLoadingModal(false);
        console.error(err, "error when get specific template");
      }
    },
    [currentTemplateInfo, documentName, navigator, setIsLoadingModal]
  );

  //Responsible for fetching the information of the selected template.
  const handleSetInfoTemplate = useCallback(
    async ({ templateId, title }: IHandlePreviewTemplateProps) => {
      try {
        setLoadingPreview(true);
        setModalIsOpen(true);
        const fetchDocumentPreview = await api.get(
          `user-templates/template/${templateId}`
        );
        setPreview({
          pages: fetchDocumentPreview.data.response.arrayOfPages,
          title: title,
        });
        setLoadingPreview(false);
      } catch (error) {
        console.error(error, "error getting document to preview");
        setLoadingPreview(false);
      }
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  return (
    <>
      <Downloadfiles
        toastId={idToastifyDownloadRef}
        fileExport={whichFileToBeDownload.current}
        downloading={downloading}
        setDownloading={setDownloading}
        documentName={idToastifyDownloadRef.current}
        pagesToBeRendered={documentPreviewData.current}
      />
      <ToastContainer />
      <Container>
        <Header>
          <HeaderSection
            title="Compartilhados comigo"
            description="Este é o local onde ficam salvos os projetos 
            que foram compartilhados com você por outros usuários."
          />

          <SearchBar
            screen="shareds"
            pageNumber={pageNumber}
            description="Digite aqui para encontrar o template que procura."
          />
        </Header>

        {loading || loadingDebounce ? (
          <LoadingFlex>
            <ContractAnimation />
          </LoadingFlex>
        ) : (
          <GridTemplate
            templates={allMyProjects}
            handleChooseTemplate={handleSetInfoTemplate}
            setTemplateId={setTemplateId}
            templateOptions={true}
            handleUseTemplate={handleChooseTemplate}
            page="Shared with me"
            whichFileToBeDownload={whichFileToBeDownload}
            idToastifyDownloadRef={idToastifyDownloadRef}
            documentPreviewData={documentPreviewData}
            downloading={downloading}
            setDownloading={setDownloading}
            isIntentBuyModal={isIntentBuyModal}
            setIsIntentBuyModal={setIsIntentBuyModal}
          />
        )}

        <Pagination total={totalPages} screen="shareds" pageNow={pageNumber} />

        <PreviewTemplateModal
          open={modalIsOpen}
          handleCloseModal={handleCloseModal}
          pagesToBeRendered={preview.pages}
          loadingPreview={loadingPreview}
          chooseTemplate={handleChooseTemplate}
          templateId={templateId}
          texButton="Abrir Projeto"
          title={preview.title}
        />
      </Container>
    </>
  );
};

export default SharedWithMe;

