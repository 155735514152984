import React from "react";

// import { Container } from './styles';
interface BoldProps {
  isActive: boolean;
}

const Underline: React.FC<BoldProps> = ({ isActive }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ic:round-format-underlined">
        <g id="Group 1064">
          <path
            id="Vector"
            d="M5.77777 19.8088C5.77777 20.2977 6.17777 20.6977 6.66667 20.6977H17.3334C17.8223 20.6977 18.2223 20.2977 18.2223 19.8088C18.2223 19.3199 17.8223 18.9199 17.3334 18.9199H6.66667C6.17777 18.9199 5.77777 19.3199 5.77777 19.8088Z"
            fill={isActive ? "#663399" : "#343A40"}
          />
          <path
            id="Vector_2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.1293 6.48144C10.6561 6.48144 10.2023 6.66941 9.86772 7.00401C9.53312 7.33861 9.34515 7.79242 9.34515 8.26561C9.34515 8.7388 9.53312 9.19261 9.86772 9.52721C10.2023 9.86181 10.6561 10.0498 11.1293 10.0498H12.9135C13.8599 10.0498 14.7675 10.4257 15.4367 11.0949C16.1059 11.7641 16.4818 12.6717 16.4818 13.6181C16.4818 14.5645 16.1059 15.4721 15.4367 16.1413C14.7675 16.8105 13.8599 17.1865 12.9135 17.1865H11.1293C10.3913 17.1864 9.67144 16.9576 9.06884 16.5315C8.46623 16.1054 8.0105 15.503 7.76437 14.8073C7.69055 14.5855 7.70675 14.3435 7.80948 14.1335C7.91221 13.9236 8.09327 13.7623 8.3137 13.6844C8.53412 13.6066 8.77631 13.6183 8.98814 13.7172C9.19997 13.8161 9.36453 13.9942 9.4464 14.2131C9.56959 14.561 9.79756 14.8621 10.0989 15.0751C10.4003 15.288 10.7603 15.4024 11.1293 15.4023H12.9135C13.3867 15.4023 13.8405 15.2143 14.1751 14.8797C14.5097 14.5451 14.6977 14.0913 14.6977 13.6181C14.6977 13.1449 14.5097 12.6911 14.1751 12.3565C13.8405 12.0219 13.3867 11.834 12.9135 11.834H11.1293C10.1829 11.834 9.27531 11.458 8.60612 10.7888C7.93692 10.1196 7.56097 9.21199 7.56097 8.26561C7.56097 7.31923 7.93692 6.4116 8.60612 5.74241C9.27531 5.07322 10.1829 4.69727 11.1293 4.69727H12.9135C13.6515 4.69734 14.3714 4.92617 14.974 5.35224C15.5766 5.77831 16.0323 6.38069 16.2784 7.07646C16.3523 7.29827 16.3361 7.5402 16.2333 7.75019C16.1306 7.96018 15.9495 8.12146 15.7291 8.19932C15.5087 8.27718 15.2665 8.2654 15.0547 8.16652C14.8428 8.06764 14.6783 7.88956 14.5964 7.67059C14.4732 7.32273 14.2452 7.02161 13.9439 6.80866C13.6425 6.5957 13.2825 6.48138 12.9135 6.48144H11.1293Z"
            fill={isActive ? "#663399" : "#343A40"}
          />
        </g>
      </g>
    </svg>
  );
};

export default Underline;
