import styled from "styled-components";
import { darken, lighten } from "polished";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  height?: string;
  borderRadius?: string;
  disabledButtonOpacity?: string;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.black};
  min-height: ${({ height }) => (height ? height : "55px")};
  margin-bottom: 1rem;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "10px"};
  color: ${({ theme }) => theme.colors.whiteColor};
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Roboto";
  width: 100%;
  height: 1px;
  opacity: ${({ disabledButtonOpacity }) =>
    disabledButtonOpacity ? disabledButtonOpacity : ""};
  transition: all 120ms linear;

  :hover {
    background: ${({ theme }) => theme.colors.background};
    color: white;
  }

  @media (max-width: 1602px) {
    font-size: 1.5rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;
