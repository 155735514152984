import React from "react";

import { ContainerRightBlankPage, Divider } from "./styles";
import { useWorkspaceEditor } from "../../../../hooks/workspaceEditor";
import ButtonRuler from "../../../../../../shared/components/ButtonRuler";
import AutomaticNumbering from "../../../AutomaticNumbering";
import Comments from "../../../Comments";

const BlankPage: React.FC = () => {
  const { handleShowRuler, showRuler } = useWorkspaceEditor();

  return (
    <ContainerRightBlankPage>
      <AutomaticNumbering />
      <ButtonRuler showRuler={showRuler} handleShowRuler={handleShowRuler} />
      <Divider>
        <Comments />
      </Divider>
    </ContainerRightBlankPage>
  );
};

export default BlankPage;
