import React from "react";

// import { Container } from './styles';
interface CenterAlignProps {
  isActive: boolean;
}

const CenterAlign: React.FC<CenterAlignProps> = ({ isActive }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="uil:align-center">
        <path
          id="Vector"
          d="M3 5.48921H21C21.2652 5.48921 21.5196 5.37311 21.7071 5.16644C21.8946 4.95977 22 4.67946 22 4.38718C22 4.09491 21.8946 3.8146 21.7071 3.60793C21.5196 3.40126 21.2652 3.28516 21 3.28516H3C2.73478 3.28516 2.48043 3.40126 2.29289 3.60793C2.10536 3.8146 2 4.09491 2 4.38718C2 4.67946 2.10536 4.95977 2.29289 5.16644C2.48043 5.37311 2.73478 5.48921 3 5.48921ZM6.54725 8.84253C6.28203 8.84253 6.02768 8.95863 5.84014 9.1653C5.6526 9.37197 5.54725 9.65228 5.54725 9.94455C5.54725 10.2368 5.6526 10.5171 5.84014 10.7238C6.02768 10.9305 6.28203 11.0466 6.54725 11.0466H16.5472C16.8125 11.0466 17.0668 10.9305 17.2544 10.7238C17.4419 10.5171 17.5472 10.2368 17.5472 9.94455C17.5472 9.65228 17.4419 9.37197 17.2544 9.1653C17.0668 8.95863 16.8125 8.84253 16.5472 8.84253H6.54725ZM21 14.3743H2.99999C2.73477 14.3743 2.48042 14.4904 2.29288 14.6971C2.10535 14.9038 1.99999 15.1841 1.99999 15.4763C1.99999 15.7686 2.10535 16.0489 2.29288 16.2556C2.48042 16.4623 2.73477 16.5784 2.99999 16.5784H21C21.2652 16.5784 21.5196 16.4623 21.7071 16.2556C21.8946 16.0489 22 15.7686 22 15.4763C22 15.1841 21.8946 14.9038 21.7071 14.6971C21.5196 14.4904 21.2652 14.3743 21 14.3743ZM16.8125 19.9061H6.81246C6.54725 19.9061 6.29289 20.0222 6.10536 20.2289C5.91782 20.4355 5.81246 20.7159 5.81246 21.0081C5.81246 21.3004 5.91782 21.5807 6.10536 21.7874C6.29289 21.9941 6.54725 22.1102 6.81246 22.1102H16.8125C17.0777 22.1102 17.332 21.9941 17.5196 21.7874C17.7071 21.5807 17.8125 21.3004 17.8125 21.0081C17.8125 20.7159 17.7071 20.4355 17.5196 20.2289C17.332 20.0222 17.0777 19.9061 16.8125 19.9061Z"
          fill={isActive ? "#663399" : "#343A40"}
        />
      </g>
    </svg>
  );
};

export default CenterAlign;
