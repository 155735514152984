import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import {
  BoxFooterForm,
  BoxInput,
  Container,
  Form,
  Header,
  TextError,
} from "./style";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../../../../../../../shared/components/InputForm";
import PasswordAlert from "../../../../../unknown/register/components/PasswordAlert";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../../../../services/api";
import { useAuth } from "../../../../../../../hooks/auth";
import { useTwoFactorAuth } from "../../../../../../../hooks/twoFactorAuth";

interface SubmitProps {
  oldPassword?: string;
  password?: string;
  confirmPassword?: string;
}

const schema = yup.object({
  oldPassword: yup.string().min(6).required("Senha antiga obrigatoria"),
  password: yup
    .string()
    .min(8, "A senha deve ter no mínimo 8 caracteres")
    .matches(
      /(?=.*[A-Z])/g,
      "A senha deve conter pelo menos uma letra maiúscula"
    )
    .matches(/(?=.*\d)/g, "A senha deve conter pelo menos um número")
    .matches(/[a-z]/, "Senha deve conter pelo menos uma letra minúscula")
    .matches(/[*@!#%&()^~{}]+/, "A senha deve conter pelo menos um símbolo")
    .required(`Senha necessária`),
  confirmPassword: yup
    .string()
    .required("Confirmação de senha obrigatória")
    .oneOf([yup.ref("password"), null], "As senhas não são iguais"),
});

export const PasswordPage = () => {
  const { data } = useAuth();
  const { isUserValidateOTP } = useTwoFactorAuth();

  const [passwordMessages, setPasswordMessages] = useState({
    haveMinLength: "",
    haveUpperOrLower: "",
    haveOneSymbol: "",
  });
  const [dataError, setDataError] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<SubmitProps>({
    resolver: yupResolver(schema),
  });

  const second = useWatch({
    control,
    name: "password",
  });

  const hasSymbol = useCallback(
    (str: string) => {
      const hasSymbol = /(?=.*\d)(?=.*[\W])/.test(str);

      if (hasSymbol) {
        setPasswordMessages((oldState) => {
          return {
            ...oldState,
            haveOneSymbol: "right",
          };
        });
      } else {
        setPasswordMessages((oldState) => {
          return {
            ...oldState,
            haveOneSymbol: "wrong",
          };
        });
        setDataError(
          "A senha deve conter pelo menos 1 símbolo especial e um número "
        );
      }
    },
    [setPasswordMessages]
  );

  const hasUpperCaseAndLowerCase = useCallback(
    (str: string) => {
      if (str) {
        const verifyExists = /[a-z]/.test(str) && /[A-Z]/.test(str);

        if (verifyExists) {
          setPasswordMessages((oldState) => {
            return {
              ...oldState,
              haveUpperOrLower: "right",
            };
          });
        } else {
          setPasswordMessages((oldState) => {
            return {
              ...oldState,
              haveUpperOrLower: "wrong",
            };
          });
          setDataError(
            "Senha deve conter pelo menos uma letra minúscula e maiúscula"
          );
        }
      }
    },
    [setPasswordMessages]
  );

  useEffect(() => {
    if (!second) {
      setPasswordMessages((oldState) => {
        return {
          ...oldState,
          haveMinLength: "",
          haveUpperOrLower: "",
          haveOneSymbol: "",
        };
      });
    }

    if (second?.length > 1) {
      hasUpperCaseAndLowerCase(second);
    }

    if (second?.length > 1) {
      hasSymbol(second);
    }

    if (second !== undefined) {
      if (second?.length > 1 && second?.length >= 8) {
        setPasswordMessages((oldState) => {
          return {
            ...oldState,
            haveMinLength: "right",
          };
        });
      } else {
        setPasswordMessages((oldState) => {
          return {
            ...oldState,
            haveMinLength: "wrong",
          };
        });
        setDataError("A senha deve ter no mínimo 8 caracteres");
      }
    } else {
      setDataError("");
    }

    if (
      second?.length > 8 &&
      /[a-z]/.test(second) &&
      /[A-Z]/.test(second) &&
      /(?=.*\d)(?=.*[\W])/.test(second)
    ) {
      setDataError("");
    }
  }, [second]);

  useEffect(() => {
    if (errors?.password?.message) {
      setDataError(`${errors?.password?.message}`);
    } else if (errors?.confirmPassword?.message) {
      setDataError(`${errors?.confirmPassword?.message}`);
    } else if (errors?.oldPassword?.message) {
      if (
        errors?.oldPassword?.message ===
        "oldPassword must be at least 6 characters"
      ) {
        setDataError("A senha antiga deve conter pelo menos 6 caracteres");
      }
      setDataError(`${errors?.oldPassword?.message}`);
    }
  }, [errors]);

  const onSubmit: SubmitHandler<SubmitProps> = async (dataForm) => {
    if (!isUserValidateOTP && data?.user?.otp_enabled) {
      toast.error("Autenticação não realizada!");
    } else {
      if (dataForm.oldPassword === "") {
        setDataError("Insira a senha.");
        return;
      }

      if (dataForm.password !== dataForm.confirmPassword) {
        setDataError("As senhas não sao iguais.");
        return;
      }

      try {
        setLoading(true);

        await api.put("profile/reset-password", {
          old_password: dataForm.oldPassword,
          new_password: dataForm.password,
        });

        toast.success("Nova senha atualizada, redirecionando para perfil...");
        setLoading(false);
        reset();
        setDataError("");
      } catch (error) {
        setLoading(false);

        setDataError("Senha antiga inválida");
      }
    }
  };

  return (
    <Container>
      <Header>
        <h4>Alterar senha</h4>
        <p>
          Altere a sua senha para deixar a sua conta mais protegida!
          <br />
          Preencha as informações abaixo:
        </p>
      </Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <BoxInput>
          <InputForm
            label="Senha Antiga"
            type="text"
            register={register}
            name="oldPassword"
            error={!!errors?.oldPassword?.message}
          />
        </BoxInput>

        <BoxInput>
          <InputForm
            label="Nova senha"
            type="text"
            register={register}
            name="password"
            error={!!errors?.password?.message}
          />
        </BoxInput>

        <PasswordAlert passwordMessages={passwordMessages} />

        <BoxInput>
          <InputForm
            label="Confirmar nova senha"
            type="text"
            register={register}
            name="confirmPassword"
            error={!!errors?.confirmPassword?.message}
          />
        </BoxInput>

        <BoxFooterForm>
          <TextError isError={!!dataError}>{dataError}</TextError>
          <button disabled={loading}>Salvar alterações</button>
        </BoxFooterForm>
      </Form>
    </Container>
  );
};
