import { Container } from "./styles";
import TitleH5 from "../../../../../../../shared/components/TitleH5";

interface InputEditnameProsp {
  isOwnerStaff: boolean;
  setNameOfTeam: React.Dispatch<React.SetStateAction<string>>;
  nameOfTeam: string;
  handleChangeNameTeam: () => Promise<void>;
  isEditName: boolean;
  setIsEditName: React.Dispatch<React.SetStateAction<boolean>>;
}

const InputEditName = ({
  isOwnerStaff,
  setNameOfTeam,
  nameOfTeam,
  handleChangeNameTeam,
  isEditName,
  setIsEditName
}: InputEditnameProsp) => {
  return (
    <Container isActive={isEditName} onClick={() => setIsEditName(true)}>
      {isEditName && isOwnerStaff ? (
        <input
          value={nameOfTeam}
          onChange={event => setNameOfTeam(event.target.value)}
          autoFocus={true}
          onBlur={handleChangeNameTeam}
          type="text"
        />
      ) : (
        <TitleH5 title={nameOfTeam} />
      )}
    </Container>
  );
};

export default InputEditName;
