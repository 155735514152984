import React from "react";

import { Box, Container, HStack } from "./styles";
import { CustomSpin } from "../../../../../../shared/components/CustomSpin";

const ModalDownloadDoc: React.FC = () => {
  return (
    <Container
      onClick={() => {
        return;
      }}
    >
      <Box>
        <div>
          {" "}
          <span className="title">Por favor, aguarde.</span>
        </div>
        <HStack>
          <CustomSpin />
          <span>Preparando para baixar...</span>
        </HStack>
      </Box>
    </Container>
  );
};

export default ModalDownloadDoc;
