import { Box, Dialog, Grid, Stack } from "@mui/material";
import {
  ContainerButton,
  Header,
  Requirement,
  RequirementContent,
  RequirementHeader,
  RequirementsContainer,
  SaveButton,
  Subtitle,
  Label,
  RequirementLabel,
  StatusLabel
} from "./styles";
import { GrClose } from "react-icons/gr";
import { IconCloseWrapper } from "../../../styles";
import { BiInfoCircle } from "react-icons/bi";
import DocApprovedIcon from "../DocApprovedIcon";
import DocRejectedIcon from "../DocRejectedIcon";
import { useEffect, useState } from "react";
import api from "../../../../../../services/api";
import { toast } from "react-toastify";
import { ContractAnimation } from "../../../../../../shared/components/ContractAnimation";
import Tooltip from "../../../../../editor/components/Tooltip";

interface RequirementData {
  id: string;
  description: string;
  status: string;
  notes: string;
  name: string;
  requirement_id: string;
}

interface ResponseData {
  flow_id: string;
  project_id: string;
  project_notes: string;
  step_id: string;
  step_name: string;
  approver_id: string;
  approver_status: string;
  document_file_url: string;
  requirements: RequirementData[];
}

type Props = {
  open: boolean;
  onClose: (next?: boolean) => void;
  approver_id: string;
};

const ApproverReview: React.FC<Props> = ({
  open,
  onClose,
  approver_id
}: Props) => {
  const [requirements, setRequirements] = useState<RequirementData[]>([]);
  const [stepName, setStepName] = useState("Revisão de Documentos");
  const [fileUrl, setFileUrl] = useState("");
  const [project_notes, setProjectNotes] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFile = async (project_id: string, flow_id: string) => {
      const response = await api.get(
        `/approval-projects/${project_id}/flows/${flow_id}/document-pdf`
      );

      // Convert base64 to binary
      const binary = atob(response.data);
      const array = [];

      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }

      // Create a blob from the binary data
      const blob = new Blob([new Uint8Array(array)], {
        type: "application/pdf"
      });

      setFileUrl(URL.createObjectURL(blob));
      setLoading(false);
    };
    const fetchReview = async () => {
      try {
        setLoading(true);
        const data = await api.get(
          `/approval-projects/approver/${approver_id}/details`
        );
        const response = data.data as ResponseData;
        setStepName(response.step_name);
        //  setFileUrl(response.document_file_url);
        setRequirements(response.requirements);
        setProjectNotes(response.project_notes);
        fetchFile(response.project_id, response.flow_id);
      } catch (error) {
        toast.error("Erro ao buscar revisão do aprovador.");
      }
    };
    fetchReview();
  }, [approver_id]);

  return (
    <Dialog onClose={onClose} open={open} maxWidth={"xl"}>
      {loading && (
        <Box
          minWidth={"80vw"}
          minHeight={"80vh"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ContractAnimation />
        </Box>
      )}
      {!loading && (
        <Grid
          container
          style={{ minWidth: "80vw", minHeight: "80vh" }}
          overflow={"hidden"}
        >
          <Grid item xs={8} height={"80vh"}>
            <iframe
              src={fileUrl}
              title="File Preview"
              width="100%"
              height="100%"
              style={{
                border: "none"
              }}
            ></iframe>
          </Grid>
          <Grid item xs={4} position={"relative"}>
            <Header>
              <Subtitle> {stepName}</Subtitle>
              <IconCloseWrapper onClick={() => onClose()}>
                <GrClose />
              </IconCloseWrapper>
            </Header>

            <RequirementsContainer>
              {requirements.map(requirement => (
                <Requirement key={requirement.id}>
                  <RequirementHeader>
                    <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
                      <RequirementLabel>{requirement.name}</RequirementLabel>
                      <Tooltip
                        description={requirement.description}
                        placement="bottom-start"
                      >
                        <figure>
                          <BiInfoCircle fontSize={"22px"} color="#999C9F" />
                        </figure>
                      </Tooltip>
                    </Stack>

                    {requirement.status === "APPROVED" ? (
                      <Stack direction={"row"} alignItems={"center"}>
                        <DocApprovedIcon />
                        <StatusLabel status="APPROVED">Aceito</StatusLabel>
                      </Stack>
                    ) : (
                      <Stack direction={"row"} alignItems={"center"}>
                        <DocRejectedIcon />
                        <StatusLabel status="REFUSED">Recusado</StatusLabel>
                      </Stack>
                    )}
                  </RequirementHeader>
                  <RequirementContent>
                    {requirement.notes ? (
                      <Label>{requirement.notes}</Label>
                    ) : (
                      <Label>Sem observações</Label>
                    )}
                  </RequirementContent>
                </Requirement>
              ))}
            </RequirementsContainer>
            <ContainerButton>
              <Box display={"flex"} justifyContent={"flex-start"} p={"16px 0"}>
                <Label>{project_notes || "Sem instruções específicas"}</Label>
              </Box>
              <SaveButton onClick={() => onClose(true)}>Fechar</SaveButton>
            </ContainerButton>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default ApproverReview;
