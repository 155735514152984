import PersonalizedIcon from "../../assets/customIcons/PersonalizedIcon";
import MountIcons from "../../utils/MountIcons";
import { Container } from "./styles";

interface IButtonExitProsp {
  onClick: any;
  inactivatedColor?: string;
  className?: string;
}

const ButtonExit = ({
  onClick,
  inactivatedColor,
  className
}: IButtonExitProsp) => {
  return (
    <Container onClick={onClick} className={className}>
      <PersonalizedIcon
        dPath={MountIcons.IconX.dPath}
        viewBox={MountIcons.IconX.viewBox}
        inactivatedColor={inactivatedColor}
      />
    </Container>
  );
};

export default ButtonExit;
