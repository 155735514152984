import { Container, Item } from "./styles";
import Arrow from "./../../assets/arrow-dow.svg";
import { useState } from "react";
import { UseFormRegister } from "react-hook-form";

interface SubmitPropsRegister {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  where_do_you_work?: string;
  how_did_you_meet_us?: string;
  phone?: string;
  register?: string;
  emailConfirm?: string;
}

interface IInputProps {
  label?: string;
  options: string[];
  error: boolean;
  register: UseFormRegister<SubmitPropsRegister>;
  name: "how_did_you_meet_us" | "where_do_you_work";
}

const InputSelect = ({
  label,
  options,
  error,
  register,
  name
}: IInputProps) => {
  const [isOpen, setIsOpne] = useState(false);
  const [value, setValue] = useState("");

  return (
    <Container error={error} urlIcon={Arrow}>
      <label>{label}</label>
      <select {...register(name)}>
        <option value="">Selecione</option>
        {options.map((option, index) => {
          return (
            <Item
              key={index}
              onClick={() => {
                setIsOpne(false);
                setValue(option);
              }}
              active={option === value}
            >
              {option}
            </Item>
          );
        })}
      </select>
    </Container>
  );
};

export default InputSelect;
