import styled from "styled-components";

export const Title = styled.h3`
  font-family: "Ubuntu";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.black100};

  user-select: none;
  margin-inline: 8px;
`;
