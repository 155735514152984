import styled from "styled-components";

export const Container = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.primary90};
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;

  :hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  span {
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white100};
  }
`;
