/*
this component render button for redo action of user
edit state history for next action old be, this button is avaiable
since have one action of to back

NOTE: when editor text is open, then we blocked this feature
*/

import React from "react";
import { PagesProps } from "../../../../../../dtos/PagesProps";
import { Button, IconRedo } from "./styles";
import Tooltip from "../../../Tooltip";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../shared/utils/MountIcons";

interface ButtonRedoProps {
  isEditing: "open" | "closed" | "blocked";
  history: {
    currentStep: number;
    step: PagesProps[][];
  };
  handleRedo: () => void;
  redoIconTransparent: string;
  redoIcon: string;
  pendingSave?: React.MutableRefObject<boolean>;
}

const ButtonRedo: React.FC<ButtonRedoProps> = ({
  isEditing,
  history,
  handleRedo,
  redoIconTransparent,
  redoIcon,
  pendingSave
}) => {
  return (
    <Tooltip
      title="Refazer"
      description="Recupere alguma ação que você fez no seu projeto."
      placement="bottom-start"
    >
      <Button
        onClick={() => {
          if (history.currentStep >= history.step.length - 1) return;
          if (isEditing === "open") return;
          // pendingSave.current = true;
          handleRedo();
        }}
      >
        <IconRedo>
          <PersonalizedIcon
            dPath={MountIcons.IconRedo.dPath}
            viewBox={MountIcons.IconRedo.viewBox}
            activeColor="#FFF"
            inactivatedColor="rgba(255, 255, 255, 0.253)"
            isActive={
              history.currentStep >= history.step.length - 1
                ? false
                : isEditing === "open"
                ? false
                : true
            }
          />
        </IconRedo>
      </Button>
    </Tooltip>
  );
};

export default ButtonRedo;
