import { Link } from "react-router-dom";
import { Container } from "./styles";
import { useAuth } from "../../../../../../../hooks/auth";
import { useEffect, useState } from "react";
import api from "../../../../../../../services/api";
import { useMainHook } from "../../../../../../../hooks/main";
import { LoadingSpinner } from "../../../../../../../shared/components/SpinnerLoading";

export const HeaderProfile = () => {
  const { refreshAvatar } = useMainHook();

  const [avatar, setAvatar] = useState("");
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const responseProfile = await api.get("profile");
        setAvatar(responseProfile.data.user.avatar_url);
        setIsLogin(false);
      } catch (err) {
        console.error("Error when fetching user data", err);
      }
    })();
  }, [refreshAvatar]);

  return (
    <Container>
      <Link to="/">Início</Link>
      {isLogin ? <LoadingSpinner /> : <img src={avatar} alt="" />}
    </Container>
  );
};
