import React from "react";

interface BaseIconProps {
  className?: string;
  isActive?: boolean;
}

export default function UnlockIcon({ className, isActive }: BaseIconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill={isActive ? "#663399" : "#343A40"}
        d="M9.61905 13.4286C10.1242 13.4286 10.6087 13.6293 10.9659 13.9865C11.3231 14.3437 11.5238 14.8282 11.5238 15.3333C11.5238 16.3905 10.6762 17.2381 9.61905 17.2381C8.5619 17.2381 7.71429 16.3905 7.71429 15.3333C7.71429 14.2762 8.57143 13.4286 9.61905 13.4286ZM17.2381 2C14.6095 2 12.4762 4.13333 12.4762 6.7619V8.66667H3.90476C2.85714 8.66667 2 9.52381 2 10.5714V20.0952C2 21.1429 2.85714 22 3.90476 22H15.3333C16.381 22 17.2381 21.1429 17.2381 20.0952V10.5714C17.2381 9.52381 16.381 8.66667 15.3333 8.66667H14.381V6.7619C14.381 5.18095 15.6571 3.90476 17.2381 3.90476C18.819 3.90476 20.0952 5.18095 20.0952 6.7619V8.66667H22V6.7619C22 4.13333 19.8667 2 17.2381 2ZM15.3333 10.5714V20.0952H3.90476V10.5714H15.3333Z"
      />
    </svg>
  );
}
