import styled from "styled-components";

export const WrapMainClauses = styled.div`
  width: 100%;
  min-height: 44px;
  background-color: #fff;
  border-bottom: solid 1px #d6d8d9;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const MyLibraryButton = styled.button<{
  isActive: "MyLibrary" | "CompanyLibrary" | "TeamsLibrary";
}>`
  height: 100%;
  background-color: #fff;
  color: ${({ isActive, theme }) =>
    isActive === "MyLibrary" ? theme.colors.primary : "#676B70"};

  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;

  :hover {
    background-color: #ebebec;
  }
`;
export const CompanyLibraryButton = styled.button<{
  isActive: "MyLibrary" | "CompanyLibrary" | "TeamsLibrary";
}>`
  height: 100%;
  background-color: #fff;
  color: ${({ isActive, theme }) =>
    isActive === "CompanyLibrary" ? theme.colors.primary : "#676B70"};

  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;

  :hover {
    background-color: #ebebec;
  }
`;

export const TeamLibraryButton = styled.button<{
  isActive: "MyLibrary" | "CompanyLibrary" | "TeamsLibrary";
}>`
  height: 100%;
  background-color: #fff;
  color: ${({ isActive, theme }) =>
    isActive === "TeamsLibrary" ? theme.colors.primary : "#676B70"};

  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;

  :hover {
    background-color: #ebebec;
  }
`;
