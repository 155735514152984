/*
in this file we will have the entire area in workspace of our editor,
so we have divided it between the toolbar and the workspace
*/

import React from "react";
import EditorMain from "../EditorMain";
import ToolbarMain from "../ToolbarMain";
import { GridItemWorkspace } from "./styles";

const Workspace: React.FC = () => {
  return (
    <GridItemWorkspace>
      <ToolbarMain />
      <EditorMain />
    </GridItemWorkspace>
  );
};

export default Workspace;
