import styled from "styled-components";

export const Aside = styled.aside`
  grid-area: aside;
  display: grid;
  grid-template-rows: 0.3fr 7fr 1fr;
  flex-direction: column;
  gap: 30px;

  padding: 12.5px 0px 20px 0px;
  background: ${({ theme }) => theme.colors.black5};
`;

export const AsideTop = styled.div`
  padding-left: 20px;
`;

export const AsideMain = styled.div``;

export const AsideFooter = styled.div`
  border-top: 2px ${({ theme }) => theme.colors.black20} solid;
  /* background-color: red; */
  padding-bottom: 1rem;
  /* overflow-y: auto; */
  div {
    height: 44px;
  }
`;

