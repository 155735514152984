// This hook is used to handle selection actions, that is, when someone clicks.
// See, the selection can be unique, example: I select ONE object. Or multiple
// selections, if the user drags more than one NODE element (konva/canvas).
import Konva from "konva";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useMainHook } from "../../../hooks/main";
import { NodeConfig } from "konva/lib/Node";

interface SelectionContextData {
  selectedObject: Konva.Shape;
  setSelectedObject: React.Dispatch<
    React.SetStateAction<Konva.Shape | Konva.Group>
  >;

  selectedObjects: Konva.Shape[];
  setSelectedObjects: React.Dispatch<React.SetStateAction<Konva.Shape[]>>;
  multipleSelectionRefs: React.MutableRefObject<Konva.Transformer[]>;
  currentMultipleSelection: React.MutableRefObject<Konva.Transformer | null>;
  addToMultipleSelectionRef: (transformer: Konva.Transformer) => void;
  transformerSelectedObject: React.MutableRefObject<Konva.Transformer | null>;
  text: any;
  setText: any;
  textRef: React.MutableRefObject<HTMLInputElement>;
  colorText: string;
  setColorText: React.Dispatch<React.SetStateAction<string>>;
  isOpenEdit: boolean;
  setIsOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModal: () => void;
  isClickLineMultiSelection?: boolean;
  setIsClickLineMultiSelection?: React.Dispatch<React.SetStateAction<boolean>>;
  IsModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectionColor: {
    index: number;
    length: number;
  };
  setSelectionColor: React.Dispatch<
    React.SetStateAction<{
      index: number;
      length: number;
    }>
  >;
  blockCommandKeyboard: boolean;
  setBlockCommandKeyboard: React.Dispatch<React.SetStateAction<boolean>>;
  typeChangeColor: "color" | "background" | "none";
  setTypeChangeColor: React.Dispatch<
    React.SetStateAction<"color" | "background" | "none">
  >;
  layerRef: React.MutableRefObject<Konva.Layer>;
  selectionRectRef: React.MutableRefObject<any>;
  forceBlockKey: boolean;
  setForceBlockKey: React.Dispatch<React.SetStateAction<boolean>>;
  disableFocusQuill: boolean;
  setDisableFocusQuill: React.Dispatch<React.SetStateAction<boolean>>;
  shiftPressed: boolean;
  setShiftPressed: React.Dispatch<React.SetStateAction<boolean>>;
  nodesShift: any[];
  setNodesShift: React.Dispatch<React.SetStateAction<any[]>>;
}

const SelectionContext = createContext<SelectionContextData>(
  {} as SelectionContextData
);

const SelectionHookProvider: React.FC = ({ children }) => {
  const [selectedObject, setSelectedObject] = useState<Konva.Shape | null>(
    null
  );

  const [blockCommandKeyboard, setBlockCommandKeyboard] = useState(false);
  const [forceBlockKey, setForceBlockKey] = useState(false);
  const [shiftPressed, setShiftPressed] = useState(false);
  const [nodesShift, setNodesShift] = useState<Konva.ShapeConfig[]>([]);
  const selectionRectRef = useRef(null);
  const layerRef = useRef(null);
  const [text, setText] = useState(selectedObject?.attrs?.text);
  const [selectedObjects, setSelectedObjects] = useState<Konva.Shape[]>([]);
  const [isClickLineMultiSelection, setIsClickLineMultiSelection] =
    useState(false);
  const currentMultipleSelection = useRef<Konva.Transformer | null>(null);
  const multipleSelectionRefs = useRef<Konva.Transformer[]>([]);
  const [disableFocusQuill, setDisableFocusQuill] = useState(false);
  const transformerSelectedObject = useRef<Konva.Transformer | null>();
  const [IsModalVisible, setIsModalVisible] = useState(false);
  const [colorText, setColorText] = useState("");

  const textRef = useRef<HTMLInputElement>(null);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [typeChangeColor, setTypeChangeColor] = useState<
    "color" | "background" | "none"
  >("none");
  const [selectionColor, setSelectionColor] = useState<{
    index: number;
    length: number;
  }>({
    index: 0,
    length: 0
  });

  //to close modal
  const handleCloseModal = useCallback(() => {
    setIsOpenEdit(!isOpenEdit);
  }, [isOpenEdit]);

  //to add transformers to our var global
  const addToMultipleSelectionRef = useCallback(
    (transformer?: Konva.Transformer) => {
      if (
        transformer &&
        !multipleSelectionRefs?.current?.includes(transformer)
      ) {
        multipleSelectionRefs.current[0]?.setNodes([]);
        setSelectedObjects([]);
        multipleSelectionRefs.current = [];
        multipleSelectionRefs?.current?.push(transformer);
      }
    },
    [multipleSelectionRefs]
  );

  return (
    <SelectionContext.Provider
      value={{
        setSelectedObject,
        selectedObject,
        multipleSelectionRefs,
        addToMultipleSelectionRef,
        currentMultipleSelection,
        transformerSelectedObject,
        text,
        setText,
        selectedObjects,
        setSelectedObjects,
        colorText,
        setColorText,
        textRef,
        isOpenEdit,
        setIsOpenEdit,
        handleCloseModal,
        IsModalVisible,
        setIsModalVisible,
        selectionColor,
        setSelectionColor,
        typeChangeColor,
        setTypeChangeColor,
        isClickLineMultiSelection,
        setIsClickLineMultiSelection,
        layerRef,
        selectionRectRef,
        blockCommandKeyboard,
        setBlockCommandKeyboard,
        forceBlockKey,
        setForceBlockKey,
        disableFocusQuill,
        setDisableFocusQuill,
        shiftPressed,
        setShiftPressed,
        nodesShift,
        setNodesShift
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

// creating hook

function useSelection(): SelectionContextData {
  const context = useContext(SelectionContext);

  if (!context) {
    throw new Error("useSelection must be used with an selectionProvider");
  }

  return context;
}

export { SelectionHookProvider, useSelection };
