import React, { useEffect, useState } from "react";
import { Button, ButtonNo, ButtonsBox, Container, Modal } from "./styles";
import { useMainHook } from "../../../../../hooks/main";
import ReactLoading from "react-loading";
import TitleH5 from "../../../../../shared/components/TitleH5";
import PlainText from "../../../../../shared/components/PlainText";
import ButtonMaxWidth from "../../../../../shared/components/ButtonMaxWidth";

// import { Container } from './styles';

const ModalDeleteAutomation: React.FC = () => {
  const {
    setIsModalDeleteAutomation,
    handleDeleteAutomationById,
    idAutomationToDelete
  } = useMainHook();

  const [loadingDeleteDoc, setLoadingDeleteDoc] = useState(false);

  return (
    <Modal>
      <Container>
        <div>
          <TitleH5 title="Excluir automação" />
          <PlainText>Tem certeza que você quer excluir a automação?</PlainText>
        </div>

        <ButtonsBox>
          <ButtonMaxWidth
            onClick={() => setIsModalDeleteAutomation(false)}
            typeStyle="purple"
            text="Não"
          />
          <ButtonMaxWidth
            onClick={async () => {
              setLoadingDeleteDoc(true);
              await handleDeleteAutomationById(idAutomationToDelete);
            }}
            text="Sim"
            typeStyle="white"
            loading={loadingDeleteDoc}
          />
        </ButtonsBox>
      </Container>
    </Modal>
  );
};

export default ModalDeleteAutomation;
