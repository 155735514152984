import styled from "styled-components";

export const Container = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  height: 30px;

  & > div {
    height: 100%;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ButtonFile = styled.div`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    gap: 8px;

    height: 100%;
    width: 143px;
    color: ${({ theme }) => theme.colors.white100};
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.primary90};
    padding-left: 12px;

    font-size: 14px;
    font-weight: 500;
    line-height: 100%;

    cursor: pointer;
  }
`;

export const GridIcon = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 29.8px;

  min-height: 330px;
  position: relative;
`;

export const IconItem = styled.li`
  width: 150px;
  height: 150px;
  background-color: #f2f2f2;
  border: 1px solid #ebebec;
  border-radius: 5px;
  position: relative;
  padding: 10px;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  figure {
    height: 90%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  div {
    position: absolute;
    top: 5px;
    right: 5px;

    svg {
      height: 20px;
      width: 20px;
    }

    cursor: pointer;
  }

  transition: 0.7s;
  animation: render 0.5s;
`;


export const ContainerLogin = styled.span`
  width: 100%;
  height: 400px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
