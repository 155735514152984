import React, { useCallback, useEffect, useState } from "react";
import MessageTeamsClauseInfo from "./components/MessageTeamsClauseInfo";
import arrowIcon from "./assets/icon-arrow.svg";
import { v4 as uuidv4 } from "uuid";

import {
  BackToTeamsList,
  Box,
  BoxClauseTeam,
  ClauseSpecificTeam,
  Container,
  ScrollDisplay,
  Span,
  TeamName,
  Title,
  Text
} from "./styles";

import SearchBar from "./components/SearchBar";
import { useTeams } from "../../../../../hooks/teams";
import api from "../../../../../services/api";
import QuillRenderImage from "../../QuillRenderImage";
import { useMainHook } from "../../../../../hooks/main";
import { useClausesEditor } from "../../../hooks/clausesEditor";
import { useDiff } from "../../../hooks/diffDocuments";
import html2canvas from "html2canvas";
import cloneDeep from "lodash/cloneDeep";
import { LoadingContainer } from "../../../../dashboard/Teams/pages/TemplatesTeamPage/styles";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import ReactLoading from "react-loading";
import Tooltip from "../../Tooltip";
import { useTheme } from "../../../../../hooks/theme";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";

interface TeamsListProps {
  team_users_id: string;
  team_id: string;
  name: string;
  created_at: string;
}

export interface ClauseTeamListProps {
  clause: string;
  created_at: string;
  id: string;
  key_words: string;
  team_id: string;
  updated_at: string;
  title: string;
}

const TeamsClauseView: React.FC = () => {
  const [clausesOfTeams, setClausesOfTeams] = useState<TeamsListProps[]>([]);
  const [teamClausesSpecifics, setTeamClausesSpecifics] = useState(false);
  const [teamName, setTeamName] = useState("");
  const { createdTeamId, setCreatedTeamId } = useTeams();
  const [loadingClauses, setLoadingClauses] = useState(true);
  const [listTeamClauses, setListTeamClauses] = useState<ClauseTeamListProps[]>(
    []
  );

  const [loading, setLoading] = useState(true);

  const {
    dragUrl,
    nameOfCurrentUser,
    setIsTeamClause,
    setTextClauseView,
    setIsVisibleClauseText,
    hasGroupShared
  } = useMainHook();

  const {
    quillRenderImage,
    setClausesObject,
    draggClauseRef,
    clausesObject,
    sourceClause,
    sizeQuillClause,
    dragging,
    setDragging,
    clausesData,
    setClausesData,
    addedClause
  } = useClausesEditor();
  const [runClause, setRunClause] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const { whichUserEdited } = useDiff();
  const [search, setSearch] = useState("");
  const [paramsSearch, setParamsSearch] = useState("");
  const [visibleCategories, setVisibleCategories] = useState(false);
  const [whichFilter, setWhichFilter] = useState("");
  const [currentPageTeams, setCurrentPageTeams] = useState(0);
  const [hasMoreTeams, setHasMoreTeams] = useState(false);

  const { lightPrimaryColor } = useTheme();

  useEffect(() => {
    (async () => {
      try {
        const responseTeamsList = await api.get("team-users/list-user-teams", {
          params: {
            pagination: currentPageTeams
          }
        });
        setClausesOfTeams(oldState => [
          ...oldState,
          ...responseTeamsList.data.dataArray
        ]);
        setLoading(false);
      } catch (err) {
        console.error(err, "ERROR when get list of teams");
      }
    })();
  }, [currentPageTeams]);

  useEffect(() => {
    (async () => {
      try {
        const responseTeamsList = await api.get("team-users/list-user-teams", {
          params: {
            pagination: currentPageTeams + 1
          }
        });
        if (responseTeamsList.data.dataArray.length > 0) {
          setHasMoreTeams(true);
        } else {
          setHasMoreTeams(false);
        }
      } catch (err) {
        console.error(err, "ERROR when get list of teams");
      }
    })();
  }, [currentPageTeams]);

  useEffect(() => {
    if (!loading) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries?.some(entry => entry?.isIntersecting)) {
          if (hasMoreTeams) {
            setCurrentPageTeams(oldState => oldState + 1);
          }
        }
      });

      const myDiv = document.querySelector("#sentinel");

      if (myDiv) {
        intersectionObserver?.observe(myDiv);
      }
      return () => intersectionObserver?.disconnect();
    }
  }, [loading, hasMoreTeams]);

  useEffect(() => {
    if (teamClausesSpecifics) {
      (async () => {
        try {
          // setLoadingClauses(true);
          const responseClauseSpecificTeam = await api.get(
            `/team-clauses/${createdTeamId}`,
            {
              params: {
                pagination: currentPage,
                search: paramsSearch === "" ? null : paramsSearch,
                search_key_word: whichFilter === "" ? null : whichFilter
              }
            }
          );

          if (paramsSearch === "" && whichFilter === "" && currentPage !== 0) {
            setListTeamClauses(oldState => [
              ...oldState,
              ...responseClauseSpecificTeam.data.dataArray
            ]);
          } else {
            setListTeamClauses(responseClauseSpecificTeam.data.dataArray);
          }

          setTotalPages(responseClauseSpecificTeam.data.pagination);
          setLoadingClauses(false);
        } catch (err) {
          console.error(err);
          setLoadingClauses(false);
        }
      })();
    }
  }, [
    teamClausesSpecifics,
    currentPage,
    paramsSearch,
    whichFilter,
    createdTeamId
  ]);

  useEffect(() => {
    return () => {
      setLoadingClauses(true);
      setCurrentPage(0);
      setListTeamClauses([]);
    };
  }, [teamClausesSpecifics]);

  const handleChangeSearch = useCallback(
    event => {
      setSearch(event.target.value);
    },
    [search]
  );

  useEffect(() => {
    setLoadingClauses(true);
    const timer = setTimeout(async () => {
      setParamsSearch(search);
      setCurrentPage(0);
    }, 2000);

    return () => {
      if (search === "") {
        setListTeamClauses([]);
        setCurrentPage(0);
      }
      clearTimeout(timer);
    };
  }, [search]);

  //when clause drag and drop
  //send to global variable with the command "richTextClause"
  //and second params of our splited text variable are id
  //and then apply these changes in our main state
  const handleGenerateClause = useCallback(
    async (
      value: string,
      event: React.DragEvent<HTMLDivElement>,
      index: number
    ) => {
      let generateId = uuidv4();
      dragUrl.current = `richTextClause#@@#${generateId}`;
      quillRenderImage.current.getEditor().root.style.padding = "0px";

      //verification for don`t get other clause in UI inside of move of user
      if (runClause) return;
      setRunClause(true);
      event.preventDefault();
      setDragging({ active: true, index: index });
      quillRenderImage?.current?.getEditor().setText(value);
      //if has group shared users then apply review of text
      if (hasGroupShared.current) {
        const myContents = quillRenderImage.current.getEditor().getContents();
        //and new attributes to text, all changes have color #9945EE
        myContents.forEach(op => {
          op.attributes = {
            color: lightPrimaryColor
          };
        });
        //invoke to main ref quill js
        //important to generate the image for api
        quillRenderImage.current.getEditor().setContents(myContents);
      }
      //render that content with html2canvas to generate image
      const canvas = await html2canvas(
        quillRenderImage.current.getEditor().root,
        {
          backgroundColor: "rgba(0,0,0,0)"
        }
      );
      //so if the image as already then change state with src
      if (canvas) {
        setClausesObject(prevState => {
          let cloneState = cloneDeep(prevState);
          return {
            ...cloneState,
            text: value,
            src: canvas.toDataURL(),
            format: hasGroupShared.current
              ? {
                  ops: [
                    {
                      insert: `${value}\n`,
                      attributes: {
                        color: lightPrimaryColor
                      }
                    }
                  ]
                }
              : {
                  ops: [
                    {
                      attributes: {
                        size: "12px"
                      },
                      insert: `${value}\n`
                    }
                  ]
                },
            initialDelta: hasGroupShared.current
              ? {
                  ops: [
                    {
                      insert: `${value}\n`,
                      attributes: {
                        color: lightPrimaryColor
                      }
                    }
                  ]
                }
              : "",

            isModify: hasGroupShared.current ? true : null,
            nameOfUserEdited: hasGroupShared.current
              ? nameOfCurrentUser.current
              : "",
            idOfUserEdited: hasGroupShared.current
              ? whichUserEdited.current
              : "",
            isSharedNewText: true
          };
        });

        draggClauseRef.current = canvas.toDataURL();
        setRunClause(false);
      }
    },
    [
      clausesObject,
      sourceClause,
      draggClauseRef,
      quillRenderImage,
      sizeQuillClause,
      dragging,
      hasGroupShared
    ]
  );

  //verifing with the api if next page exists
  useEffect(() => {
    const autoLoad = async () => {
      const response = await api.get(`team-clauses/${createdTeamId}`, {
        params: {
          pagination: currentPage + 1
        }
      });

      //if return length then has more pages
      if (response.data.dataArray.length > 0) {
        setHasMore(true);
      } else {
        //if return length 0 because doesn`t exists any pages more
        setHasMore(false);
      }
    };

    if (teamClausesSpecifics) {
      autoLoad();
    }
  }, [teamClausesSpecifics, currentPage]);

  useEffect(() => {
    if (teamClausesSpecifics) {
      const myElement = document.querySelector("#sentinela-clauseteam");

      if (myElement && !loadingClauses) {
        const intersectionObserver = new IntersectionObserver(entries => {
          if (entries?.some(entry => entry?.isIntersecting)) {
            if (hasMore && search === "" && whichFilter === "") {
              setCurrentPage(oldState => oldState + 1);
            }
          }
        });
        intersectionObserver?.observe(
          document?.querySelector("#sentinela-clauseteam")
        );
        return () => intersectionObserver?.disconnect();
      }
    }
  }, [loadingClauses, hasMore, search, whichFilter]);

  useEffect(() => {
    return () => {
      setClausesOfTeams([]);
    };
  }, []);

  return (
    <>
      {loading ? (
        <LoadingContainer>
          <ContractAnimation />
        </LoadingContainer>
      ) : (
        <Container>
          {clausesOfTeams.length > 0 ? (
            <>
              <MessageTeamsClauseInfo
                className={teamClausesSpecifics ? "specific-clause-true" : ""}
                text="Apenas o proprietário da equipe pode incluir os textos."
                isSpecific={teamClausesSpecifics}
              />

              {teamClausesSpecifics && (
                <BackToTeamsList
                  whichFilter={whichFilter}
                  onClick={() => setTeamClausesSpecifics(false)}
                >
                  <PersonalizedIcon
                    dPath={MountIcons.IconArrowLeft.dPath}
                    viewBox={MountIcons.IconArrowLeft.viewBox}
                    inactivatedColor="#999C9F"
                  />
                  <span> {teamName} </span>
                </BackToTeamsList>
              )}

              {teamClausesSpecifics && (
                <SearchBar
                  search={search}
                  setSearch={setSearch}
                  handleChangeSearch={handleChangeSearch}
                  visibleCategories={visibleCategories}
                  setVisibileCategories={setVisibleCategories}
                  idTeam={createdTeamId}
                  whichFilter={whichFilter}
                  setWhichFilter={setWhichFilter}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  listTeamClauses={listTeamClauses}
                  setListTeamClauses={setListTeamClauses}
                  setLoadingClauses={setLoadingClauses}
                />
              )}

              {<QuillRenderImage />}

              <ScrollDisplay>
                {!teamClausesSpecifics ? (
                  <>
                    {clausesOfTeams.map(team => (
                      <Box
                        key={team.team_id}
                        onClick={() => {
                          setCreatedTeamId(team.team_id);
                          setTeamName(team.name);
                          setTeamClausesSpecifics(!teamClausesSpecifics);
                        }}
                      >
                        <TeamName> {team.name} </TeamName>
                        <PersonalizedIcon
                          dPath={MountIcons.IconArrowRight.dPath}
                          viewBox={MountIcons.IconArrowRight.viewBox}
                          inactivatedColor="#676B70"
                        />
                      </Box>
                    ))}
                    {hasMoreTeams && (
                      <div
                        style={{
                          width: "100%",
                          minHeight: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                        id="sentinel"
                      >
                        <ReactLoading
                          type="spin"
                          color="#000"
                          height={25}
                          width={25}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {loadingClauses ? (
                      <div
                        id="sentinela-clauseteam"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "5rem"
                        }}
                      >
                        <ContractAnimation />
                      </div>
                    ) : listTeamClauses.length > 0 ? (
                      listTeamClauses?.map((clause, index) => (
                        <Tooltip
                          key={clause.id}
                          title="Arraste para usar ou clique para visualizar"
                          placement="bottom-start"
                        >
                          <BoxClauseTeam
                            draggable
                            key={index}
                            onDragStart={e => {
                              setTimeout(() => {
                                handleGenerateClause(clause.clause, e, index);
                              }, 50);
                            }}
                            onClick={() => {
                              setIsTeamClause(true);
                              setTextClauseView(clause.id);
                              setIsVisibleClauseText(true);
                            }}
                          >
                            <Title> {clause.title} </Title>
                            <ClauseSpecificTeam>
                              {clause.clause}
                            </ClauseSpecificTeam>
                          </BoxClauseTeam>
                        </Tooltip>
                      ))
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <Text>Não há textos para exibir.</Text>
                      </div>
                    )}

                    {!loadingClauses &&
                      hasMore &&
                      paramsSearch === "" &&
                      whichFilter === "" && (
                        <div
                          id="sentinela-clauseteam"
                          style={{
                            width: "100%",
                            minHeight: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <ReactLoading
                            type="spin"
                            color="#000"
                            height={22}
                            width={22}
                          />
                        </div>
                      )}
                  </>
                )}
              </ScrollDisplay>
            </>
          ) : (
            <MessageTeamsClauseInfo
              isSpecific={false}
              text="Você não possui textos porque não está em uma equipe."
            />
          )}
        </Container>
      )}
    </>
  );
};

export default TeamsClauseView;
