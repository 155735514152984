import React from "react";
import { MainView, Spacer } from "./styles";
import ItemTextFormat from "./components/ItemTextFormat";
import { styledFonts } from "../../constants/fontsFortmats";

// import { Container } from './styles';

const TextsFormatsTab: React.FC = () => {
  return (
    <MainView>
      {styledFonts.map(style => (
        <ItemTextFormat
          key={style.type}
          isBold={style.isBold}
          title={style.type}
          fontSize={style.fontSize}
        />
      ))}
    </MainView>
  );
};

export default TextsFormatsTab;
