import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;

  p {
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
    color: ${({ theme }) => theme.colors.white100};
  }
`;

export const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Options = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const Button = styled.button`
  position: absolute;
  bottom: 15px;
  right: 22%;

  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary90};
  background: ${({ theme }) => theme.colors.white100};
  color: ${({ theme }) => theme.colors.primary};
  height: 30px;
  width: 120px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

