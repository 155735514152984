import React from "react";

// import { Container } from './styles';
interface CaseSensitiveProps {
  onClick: () => void;
}

const CaseSensitive: React.FC<CaseSensitiveProps> = ({ onClick }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g id="ph:text-aa">
        <path
          id="Vector"
          d="M8.83082 5.95452C8.77512 5.82826 8.68686 5.7215 8.57634 5.64672C8.46582 5.57194 8.33762 5.53223 8.2067 5.53223C8.07579 5.53223 7.94758 5.57194 7.83707 5.64672C7.72655 5.7215 7.63828 5.82826 7.58259 5.95452L2.06558 18.4487C2.02703 18.5361 2.00501 18.6307 2.00076 18.7271C1.99651 18.8235 2.01013 18.9199 2.04082 19.0107C2.10282 19.1941 2.23064 19.3437 2.39617 19.4267C2.56169 19.5097 2.75136 19.5191 2.92345 19.4531C3.09554 19.387 3.23595 19.2508 3.3138 19.0744L4.74995 15.8222H11.6635L13.0996 19.0744C13.1382 19.1617 13.1925 19.2401 13.2594 19.3051C13.3264 19.3701 13.4047 19.4204 13.49 19.4531C13.5752 19.4858 13.6656 19.5003 13.7561 19.4958C13.8465 19.4912 13.9353 19.4678 14.0172 19.4267C14.0992 19.3856 14.1728 19.3277 14.2337 19.2563C14.2947 19.185 14.3419 19.1015 14.3726 19.0107C14.4033 18.9199 14.4169 18.8235 14.4127 18.7271C14.4084 18.6307 14.3864 18.5361 14.3478 18.4487L8.83082 5.95452ZM5.39906 14.3523L8.2067 7.99402L11.0143 14.3523H5.39906ZM18.5511 9.94257C17.4512 9.94257 16.5917 10.2614 15.9969 10.8907C15.8724 11.029 15.8035 11.2137 15.805 11.4054C15.8064 11.5972 15.8782 11.7807 16.0048 11.9168C16.1314 12.053 16.303 12.131 16.4828 12.1343C16.6627 12.1375 16.8366 12.0658 16.9676 11.9343C17.2951 11.5879 17.8296 11.4125 18.5511 11.4125C19.6916 11.4125 20.62 12.2393 20.62 13.2499V13.5457C20.008 13.1093 19.2874 12.8783 18.5511 12.8824C16.6495 12.8824 15.103 14.3661 15.103 16.1897C15.103 18.0133 16.6495 19.497 18.5511 19.497C19.2877 19.5004 20.0084 19.2684 20.62 18.8309C20.6286 19.0258 20.7094 19.2091 20.8448 19.3405C20.9802 19.4719 21.159 19.5406 21.3419 19.5314C21.5248 19.5223 21.6968 19.4361 21.8201 19.2918C21.9434 19.1475 22.0078 18.9569 21.9992 18.762V13.2499C21.9992 11.4263 20.4528 9.94257 18.5511 9.94257ZM18.5511 18.0271C17.4106 18.0271 16.4822 17.2002 16.4822 16.1897C16.4822 15.1791 17.4106 14.3523 18.5511 14.3523C19.6916 14.3523 20.62 15.1791 20.62 16.1897C20.62 17.2002 19.6916 18.0271 18.5511 18.0271Z"
          fill="#343A40"
          stroke="#343A40"
          stroke-width="0.65"
        />
      </g>
    </svg>
  );
};

export default CaseSensitive;
