import { Container } from "./styles";
/* import ForestOflline from "../../assets/Forest-offline.svg"; */
import ImageOffline from "./ImageOffline";

const OfflineElement = () => {
  return (
    <Container>
      <ImageOffline />
      <p>Você está offline</p>
      <span>É preciso estar online para acessar esse conteúdo.</span>
      <span>O seu trabalho não está sendo salvo.</span>
    </Container>
  );
};

export default OfflineElement;
