import styled from "styled-components";

interface GridProps {
  marginTop?: string;
  columns: number;
}

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 7px;
  margin: ${({ marginTop }) => marginTop && marginTop};
`;
