import { ISelectModalTeam } from "../../../../../../dtos/teamsUtils";
import ButtonExit from "../../../../../../shared/components/ButtonExit";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import PlainText from "../../../../../../shared/components/PlainText";
import TitleH5 from "../../../../../../shared/components/TitleH5";
import { Container, Modal, ButtonBox, Title } from "./styled";

interface IModalDeleteTextProps {
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
  handleDeleteClauseTeam: (id: any) => Promise<void>;
  clauseId: string;
}

const ModalDeleteText = ({
  setSelectModal,
  handleDeleteClauseTeam,
  clauseId
}: IModalDeleteTextProps) => {
  return (
    <Container>
      <Modal>
        <div>
          <Title>
            <TitleH5 title="Excluir texto" />
            <ButtonExit
              onClick={() => setSelectModal({ modal: "", isOpen: false })}
            />
          </Title>
          <PlainText>Tem certeza que você quer excluir o texto?</PlainText>
        </div>
        <ButtonBox>
          <ButtonMaxWidth
            typeStyle="purple"
            text="Não"
            onClick={() => setSelectModal({ modal: "", isOpen: false })}
          />
          <ButtonMaxWidth
            onClick={() => {
              handleDeleteClauseTeam(clauseId);
              setSelectModal({ modal: "", isOpen: false });
            }}
            typeStyle="white"
            text="Sim"
          />
        </ButtonBox>
      </Modal>
    </Container>
  );
};

export default ModalDeleteText;
