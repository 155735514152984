import { Modal } from "@material-ui/core";
import { ObjectNewClause } from "../../../dashboard/myclausules/pages";

import {
  BoxButton,
  Category,
  Container,
  ModalContainer,
  Text,
  Title
} from "./styles";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonExit from "../../../../shared/components/ButtonExit";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import iconCopy from "../../../../shared/assets/copy_line-icone.svg";
import { useCallback } from "react";
import { toast } from "react-toastify";

interface ModalShowTextProps {
  activeClausule: ObjectNewClause;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setObjectClause: React.Dispatch<React.SetStateAction<ObjectNewClause>>;
  isOpen: boolean;
  handleOpenEditClauseModal: (id?: string, index?: number) => void;
}

const ModalViewText: React.FC<ModalShowTextProps> = ({
  activeClausule,
  setIsModalOpen,
  setObjectClause,
  isOpen,
  handleOpenEditClauseModal
}) => {
  const handleCopyClause = useCallback((text: string) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Texto copiado com sucesso!");
    } catch (err) {
      // console.error(err);
      toast.error("Erro ao copiar texto");
    }
  }, []);

  return (
    <Modal
      open={isOpen}
      children={
        <Container>
          <ModalContainer>
            <Title>
              <TitleH5 title={activeClausule?.title} />
              <div>
                <img
                  onClick={() => {
                    handleCopyClause(activeClausule?.clause);
                  }}
                  src={iconCopy}
                  alt=""
                />
                <ButtonExit
                  onClick={() => {
                    setIsModalOpen(false);

                    setObjectClause({
                      title: "",
                      clause: "",
                      description: "Clause by user",
                      is_favorite: false,
                      key_words: ""
                    });
                  }}
                />
              </div>
            </Title>
            <Category>
              <p>Categoria:</p>
              <ul>
                <p>{activeClausule?.key_words}</p>
              </ul>
            </Category>
            <Text>{activeClausule?.clause}</Text>

            <BoxButton>
              <ButtonMaxWidth
                text="Editar"
                typeStyle="purple"
                onClick={() => {
                  setIsModalOpen(false);
                  handleOpenEditClauseModal(activeClausule.user_clause_id);
                }}
              />
            </BoxButton>
          </ModalContainer>
        </Container>
      }
    />
  );
};

export default ModalViewText;
