import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

export const FlexSearch = styled.div`
  width: 100%;
  height: 30px;
`;

export const Heading = styled.div`
  font-size: 1.8rem;
  margin: 1.8rem 0 1.8rem 1.6rem;
  color: ${({ theme }: Theme) => theme.colors.black};
`;

export const NotResult = styled.div`
  width: 100%;
  height: 120px;
  background-color: #fff;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
`;

export const Container = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow-y: auto;

  margin-bottom: 4rem;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;
