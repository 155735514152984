import { useNavigate, useParams } from "react-router-dom";
import { cloneDeep } from "lodash";

import {
  Container,
  Header,
  GridText,
  ContainerLogin,
  TableText,
  Main,
  HeaderTable,
  ButtonCreatedText
} from "./styles";

import { useTeams } from "../../../../../hooks/teams";
import { useCallback, useEffect, useState } from "react";
import api from "../../../../../services/api";

import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import ImportIcon from "../../../../../shared/assets/arrow_down_circle-icon.svg";
import SearchBar from "../../../components/SearchBar";
import ItemClauseTeam from "../../components/ItemClauseTeam";
import SelectCategory from "../../../components/SelectCategory";
import Pagination from "../../../components/Pagination";
import { NoElements } from "../../components/TableTeams/styles";
import { useMainHook } from "../../../../../hooks/main";

const LibraryTeamPage: React.FC = () => {
  const {
    clausesListTeam,
    setClausesListTeam,
    loadingClause,
    setLoadingClause,
    setClauseId,
    isOwnerStaff,
    setSelectModal,
    setIsSettings,
    forceUpdate
  } = useTeams();

  const { debouncedSearchTerm, loadingDebounce, setSearch } = useMainHook();

  //route properties
  const { team_id, number_page, filter } = useParams();
  const navigator = useNavigate();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;
  const filterCategory = filter === undefined ? "all" : filter;

  const [totalPage, setTotalPages] = useState(0);

  useEffect(() => {
    return () => {
      setClausesListTeam([]);
      setLoadingClause(true);
      setClauseId("");
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoadingClause(true);
        const responseListOfClausesTeam = await api.get(
          `team-clauses/${team_id}`,
          {
            params: {
              search: debouncedSearchTerm ? debouncedSearchTerm : null,
              filter: filterCategory === "all" ? null : filterCategory,
              pagination: pageNumber
            }
          }
        );
        setClausesListTeam(responseListOfClausesTeam.data.dataArray);
        setTotalPages(responseListOfClausesTeam.data.pagination);
        setLoadingClause(false);

        if (
          pageNumber > 0 &&
          responseListOfClausesTeam.data.dataArray.length === 0
        ) {
          navigator(`/view-team/${team_id}/library/${pageNumber}`);
        }
      } catch (err) {
        console.error(err, "error when get clauses of team specific");
        setLoadingClause(false);
      }
    })();
  }, [filterCategory, pageNumber, debouncedSearchTerm, forceUpdate]);

  useEffect(() => {
    setLoadingClause(true);
    (async () => {
      try {
        const responseMyTemplatesOfTeam = await api.get(
          `team-clauses/${team_id}`,
          {
            params: {
              search: debouncedSearchTerm ? debouncedSearchTerm : null,
              pagination: pageNumber,
              filter: filterCategory === "all" ? null : filterCategory
            }
          }
        );

        if (clausesListTeam.length === 0 && pageNumber > 0) {
          setSearch(debouncedSearchTerm);
        }
        setTotalPages(responseMyTemplatesOfTeam.data.pagination);
        setLoadingClause(false);
      } catch (err) {
        console.error(err, "error response");
      }
    })();
  }, [filterCategory, debouncedSearchTerm]);

  const handleFavoriteText = useCallback(
    async textId => {
      try {
        await api.put("/team-clauses/favorite", {
          team_clause_id: textId
        });

        setClausesListTeam(prev => {
          const clone = cloneDeep(prev);

          const newArray = clone.map(element => {
            if (element.id === textId) {
              return { ...element, is_favorite: !element.is_favorite };
            }

            return element;
          });

          return newArray;
        });
      } catch (error) {
        console.error(error);
      }
    },
    [setClausesListTeam]
  );

  return (
    <Container>
      <Header>
        {isOwnerStaff && (
          <ButtonCreatedText
            onClick={() => {
              setSelectModal({ modal: "Create New Text", isOpen: true });
            }}
          >
            <img src={ImportIcon} alt="" />
            Cadastrar novo texto
          </ButtonCreatedText>
        )}
        <SearchBar
          screen={`view-team/${team_id}/library`}
          pageNumber={pageNumber}
          description="Digite aqui para encontrar o texto que procura."
        />
      </Header>

      <Main>
        <SelectCategory
          category={filterCategory}
          page={number_page}
          screen={`view-team/${team_id}/library`}
        />
        <TableText>
          <HeaderTable>
            <p>Título</p> <p>Categoria</p>
          </HeaderTable>

          <GridText>
            {loadingClause || loadingDebounce ? (
              <ContainerLogin>
                <ContractAnimation />
              </ContainerLogin>
            ) : clausesListTeam.length === 0 ? (
              <NoElements>Nenhum texto encontrado.</NoElements>
            ) : (
              clausesListTeam.map(item => (
                <ItemClauseTeam
                  item={item}
                  key={item.id}
                  setClauseId={setClauseId}
                  setIsSettings={setIsSettings}
                  setSelectModal={setSelectModal}
                  handleFavoriteText={handleFavoriteText}
                  isOwner={isOwnerStaff}
                />
              ))
            )}
          </GridText>
        </TableText>
      </Main>
      <Pagination
        total={totalPage}
        pageNow={pageNumber}
        screen={`view-team/${team_id}/library`}
        afterUrl={filter && filter}
      />
    </Container>
  );
};

export default LibraryTeamPage;
