import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 20px;
  height: 21px;
  fill: currentColor;
`;

type Props = {
  color?: string;
};

const DownloadIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 20 21" color={color || "#676B70"}>
    <path
      d="M17.0204 20.1627H3.01346C2.623 20.1468 2.2395 20.054 1.88493 19.8897C1.53035 19.7254 1.21165 19.4928 0.947061 19.2052C0.682473 18.9176 0.477191 18.5807 0.342961 18.2137C0.20873 17.8467 0.148187 17.4568 0.164795 17.0663V13.6885C0.164795 13.4645 0.253765 13.2497 0.412134 13.0913C0.570502 12.933 0.785296 12.844 1.00926 12.844C1.23323 12.844 1.44802 12.933 1.60639 13.0913C1.76476 13.2497 1.85373 13.4645 1.85373 13.6885V17.0663C1.82466 17.4007 1.92539 17.7333 2.13503 17.9954C2.34467 18.2574 2.64713 18.4287 2.97969 18.4738H17.0204C17.3529 18.4287 17.6554 18.2574 17.865 17.9954C18.0747 17.7333 18.1754 17.4007 18.1463 17.0663V13.6885C18.1463 13.4645 18.2353 13.2497 18.3937 13.0913C18.552 12.933 18.7668 12.844 18.9908 12.844C19.2148 12.844 19.4295 12.933 19.5879 13.0913C19.7463 13.2497 19.8353 13.4645 19.8353 13.6885V17.0663C19.869 17.8493 19.5921 18.6139 19.065 19.1938C18.5378 19.7736 17.803 20.1219 17.0204 20.1627Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.00934 12.9921C0.824567 12.9921 0.647362 13.0655 0.516708 13.1962C0.386054 13.3268 0.312654 13.504 0.312654 13.6888V17.0729C0.296871 17.444 0.354271 17.8145 0.481828 18.1632C0.609385 18.512 0.804461 18.8322 1.0559 19.1055C1.30733 19.3788 1.61019 19.5998 1.94713 19.756C2.28318 19.9117 2.64657 19.9998 3.0166 20.0152H17.0165C17.7588 19.9755 18.4556 19.6448 18.9557 19.0947C19.4567 18.5436 19.7197 17.817 19.6877 17.073L19.6874 17.0667L19.6875 13.6888C19.6875 13.504 19.6141 13.3268 19.4835 13.1962C19.3528 13.0655 19.1756 12.9921 18.9909 12.9921C18.8061 12.9921 18.6289 13.0655 18.4982 13.1962C18.3676 13.3268 18.2942 13.504 18.2942 13.6888V17.0604C18.3247 17.4303 18.2125 17.798 17.9805 18.088C17.7471 18.3797 17.4104 18.5704 17.0403 18.6205L17.0304 18.6219H2.9698L2.95993 18.6205C2.58975 18.5704 2.25307 18.3797 2.01971 18.088C1.78772 17.798 1.67554 17.4304 1.70602 17.0604V13.6888C1.70602 13.504 1.63262 13.3268 1.50197 13.1962C1.37132 13.0655 1.19411 12.9921 1.00934 12.9921ZM0.307713 12.9872C0.493796 12.8011 0.746178 12.6965 1.00934 12.6965C1.2725 12.6965 1.52488 12.8011 1.71097 12.9872C1.89705 13.1732 2.00159 13.4256 2.00159 13.6888V17.0795C1.97581 17.376 2.06458 17.671 2.2505 17.9034C2.43441 18.1333 2.69886 18.2844 2.99002 18.3263H17.0102C17.3013 18.2844 17.5658 18.1333 17.7497 17.9034C17.9356 17.671 18.025 17.376 17.9992 17.0795L17.9981 17.0667L17.9986 13.6888C17.9986 13.4256 18.1032 13.1732 18.2892 12.9872C18.4753 12.8011 18.7277 12.6965 18.9909 12.6965C19.254 12.6965 19.5064 12.8011 19.6925 12.9872C19.8786 13.1732 19.9831 13.4256 19.9831 13.6888V17.0635C20.0176 17.8842 19.7271 18.6856 19.1744 19.2935C18.621 19.9022 17.8497 20.2677 17.0281 20.3106L17.0204 20.311L3.00751 20.3108C2.59763 20.2941 2.19508 20.1966 1.82287 20.0241C1.45067 19.8517 1.11612 19.6075 0.838381 19.3056C0.560638 19.0037 0.34515 18.65 0.204247 18.2647C0.0637072 17.8805 0.000118885 17.4723 0.0170899 17.0635V13.6888C0.0170899 13.4256 0.12163 13.1732 0.307713 12.9872Z"
      fill="currentColor"
    />
    <path
      d="M10.0169 14.5333C9.90597 14.5338 9.79603 14.5122 9.69356 14.4696C9.59108 14.4271 9.49814 14.3645 9.42017 14.2856L4.91634 9.78173C4.76718 9.62165 4.68597 9.40992 4.68983 9.19114C4.69369 8.97237 4.78231 8.76363 4.93704 8.60891C5.09176 8.45419 5.30049 8.36556 5.51927 8.3617C5.73804 8.35784 5.94977 8.43905 6.10986 8.58822L10.0169 12.4953L13.924 8.58822C14.0841 8.43905 14.2958 8.35784 14.5146 8.3617C14.7334 8.36556 14.9421 8.45419 15.0968 8.60891C15.2515 8.76363 15.3402 8.97237 15.344 9.19114C15.3479 9.40992 15.2667 9.62165 15.1175 9.78173L10.6137 14.2856C10.5357 14.3645 10.4428 14.4271 10.3403 14.4696C10.2378 14.5122 10.1279 14.5338 10.0169 14.5333Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.00737 8.69424C5.87552 8.57225 5.70161 8.50588 5.52195 8.50905C5.34146 8.51224 5.16926 8.58535 5.04161 8.713C4.91397 8.84064 4.84085 9.01285 4.83766 9.19334C4.83449 9.373 4.90086 9.5469 5.02285 9.67876L9.52474 14.1806C9.58888 14.2456 9.66599 14.2977 9.75029 14.3327C9.83459 14.3677 9.92503 14.3855 10.0163 14.3851H10.0177C10.109 14.3855 10.1994 14.3677 10.2837 14.3327C10.368 14.2977 10.4445 14.2462 10.5086 14.1813L15.0112 9.67875C15.1331 9.5469 15.1995 9.373 15.1963 9.19334C15.1932 9.01285 15.12 8.84064 14.9924 8.713C14.8647 8.58535 14.6925 8.51224 14.5121 8.50905C14.3324 8.50588 14.1585 8.57225 14.0266 8.69424L10.017 12.7039L6.00737 8.69424ZM5.51674 8.21353C5.7738 8.209 6.02258 8.30442 6.21068 8.47969L6.2145 8.48324L10.017 12.2859L13.8233 8.47962C14.0114 8.30435 14.2602 8.209 14.5173 8.21353C14.7743 8.21807 15.0196 8.32221 15.2014 8.504C15.3832 8.6858 15.4873 8.93106 15.4919 9.18812C15.4964 9.44518 15.401 9.69397 15.2257 9.88207L15.2221 9.88588L10.7189 14.389C10.7188 14.3891 10.719 14.3889 10.7189 14.389C10.6272 14.4818 10.5175 14.5557 10.397 14.6057C10.2766 14.6557 10.1474 14.6812 10.017 14.6806C9.88661 14.6812 9.75742 14.6557 9.63698 14.6057C9.51648 14.5557 9.40717 14.4821 9.31545 14.3893C9.31533 14.3892 9.31557 14.3895 9.31545 14.3893L4.80823 9.88213C4.63296 9.69403 4.53761 9.44518 4.54215 9.18812C4.54668 8.93106 4.65082 8.6858 4.83262 8.504C5.01441 8.32221 5.25968 8.21807 5.51674 8.21353Z"
      fill="currentColor"
    />
    <path
      d="M10.0168 14.5332C9.79377 14.5303 9.58067 14.4404 9.42293 14.2826C9.26519 14.1249 9.17528 13.9118 9.17236 13.6887V1.30321C9.17236 1.07924 9.26133 0.864447 9.4197 0.706079C9.57807 0.547711 9.79286 0.45874 10.0168 0.45874C10.2408 0.45874 10.4556 0.547711 10.614 0.706079C10.7723 0.864447 10.8613 1.07924 10.8613 1.30321V13.6887C10.8584 13.9118 10.7685 14.1249 10.6107 14.2826C10.453 14.4404 10.2399 14.5303 10.0168 14.5332Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0172 0.606599C9.83238 0.606599 9.65517 0.679999 9.52452 0.810653C9.39387 0.941307 9.32047 1.11851 9.32047 1.30328V13.6878C9.32311 13.872 9.39746 14.0479 9.52775 14.1782C9.65779 14.3083 9.83332 14.3826 10.0172 14.3855C10.201 14.3826 10.3765 14.3083 10.5066 14.1782C10.6368 14.0479 10.7112 13.872 10.7138 13.6878V1.30328C10.7138 1.11851 10.6404 0.941307 10.5098 0.810653C10.3791 0.679999 10.2019 0.606599 10.0172 0.606599ZM9.31553 0.601658C9.50161 0.415575 9.75399 0.311035 10.0172 0.311035C10.2803 0.311035 10.5327 0.415575 10.7188 0.601658C10.9049 0.787741 11.0094 1.04012 11.0094 1.30328V13.6907C11.006 13.9523 10.9005 14.2022 10.7156 14.3872C10.5306 14.5722 10.2807 14.6776 10.0191 14.681L10.0152 14.6811C9.75364 14.6777 9.50373 14.5722 9.31875 14.3872C9.13377 14.2022 9.02833 13.9523 9.02491 13.6907L9.0249 13.6888V1.30328C9.0249 1.04012 9.12944 0.787741 9.31553 0.601658Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default DownloadIcon;
