import { Container } from "./styles";

interface IInputRadiosProps {
  checked: boolean;
  text: string;
  onChange: () => any;
}

const InputRadio = ({ text, checked, onChange }: IInputRadiosProps) => {
  return (
    <Container>
      <input type="radio" checked={checked} onChange={() => onChange()} />
      <p>{text}</p>
    </Container>
  );
};

export default InputRadio;
