const styledFonts = [
  {
    type: "TÍTULO 1",
    fontSize: "24px",
    isBold: true,
    isUpperCase: false
  },
  {
    type: "Título 2",
    fontSize: "18px",
    isBold: true,
    isUpperCase: false
  },
  {
    type: "Título 3",
    fontSize: "16px",
    isBold: true,
    isUpperCase: true
  },
  {
    type: "Subtítulo 1",
    fontSize: "14px",
    isBold: false,
    isUpperCase: false
  },
  {
    type: "Subtítulo 2",
    fontSize: "12px",
    isBold: true,
    isUpperCase: false
  },
  {
    type: "Conteúdo 1",
    fontSize: "12px",
    isBold: false,
    isUpperCase: false
  },
  {
    type: "Conteúdo 2",
    fontSize: "10px",
    isBold: false,
    isUpperCase: false
  },

  {
    type: "Legenda 1",
    fontSize: "10px",
    isBold: false,
    isUpperCase: false
  },
  {
    type: "Legenda 2",
    fontSize: "8px",
    isBold: false,
    isUpperCase: false
  }
];

export { styledFonts };
