import { Link } from "react-router-dom";
import styled from "styled-components";

export const Conatainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const PaginationList = styled.ul`
  display: flex;
  align-items: center;
`;

export const PaginationListItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ButtonPag = styled(Link)`
  width: 40.6px;
  height: 30px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black50};
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  :first-child {
    border-left: none;
  }

  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 500;
`;

export const ButtonPagActive = styled.button`
  width: 40.6px;
  height: 30px;
  font-weight: bold;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.black};

  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white100};

  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: 500;

  :first-child {
    border-left: none;
  }
`;
