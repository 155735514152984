import React from "react";
import { useTheme } from "../../../../../../hooks/theme";

// import { Container } from './styles';
interface DashIconProps {
  isActive?: boolean;
  className?: string;
}

const DashIcon: React.FC<DashIconProps> = ({ isActive, className }) => {
  const { theme } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={className}
    >
      <mask
        id="mask0_10626_21605"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect
          x="0.580078"
          y="0.889709"
          width="24.1078"
          height="24.1078"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_10626_21605)">
        <path
          d="M2.52148 21.8051V19.5728H4.75369V21.8051H2.52148ZM2.52148 17.3406V15.1084H8.102V17.3406H2.52148ZM2.52148 12.8762V10.644H11.4503V12.8762H2.52148ZM2.52148 8.4118V3.94739H22.6114V8.4118H2.52148ZM6.9859 21.8051V19.5728H9.21811V21.8051H6.9859ZM9.77616 17.3406V15.1084H15.3567V17.3406H9.77616ZM11.4503 21.8051V19.5728H13.6825V21.8051H11.4503ZM13.6825 12.8762V10.644H22.6114V12.8762H13.6825ZM15.9147 21.8051V19.5728H18.1469V21.8051H15.9147ZM17.0308 17.3406V15.1084H22.6114V17.3406H17.0308ZM20.3791 21.8051V19.5728H22.6114V21.8051H20.3791Z"
          fill={isActive ? theme.colors.primary : "#000"}
        />
      </g>
    </svg>
  );
};

export default DashIcon;
