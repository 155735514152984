import React, { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import {
  StepsWrapper,
  StepExpand,
  StepExpandHeader,
  StepExpandContent,
  Label1,
  Label2,
  NoDescription,
  Title
} from "./styles";
import { Grid, Stack } from "@mui/material";
import { ApprovalProject } from "../../../types/ApprovalProject";

type Props = {
  project: ApprovalProject;
};

const StepsDetails: React.FC<Props> = ({ project }: Props) => {
  const [activeStep, setActiveStep] = useState<number>(-1);

  const handleStepExpand = (index: number) => {
    setActiveStep(activeStep === index ? -1 : index);
  };
  return (
    <StepsWrapper>
      {project.approval_flows[0].steps.map((step, index) => (
        <StepExpand
          key={step.id}
          isFirst={index === 0}
          isLast={index + 1 === project.approval_flows[0].steps.length}
        >
          <StepExpandHeader
            active={activeStep === index}
            onClick={() => handleStepExpand(index)}
            isFirst={index === 0}
          >
            <Grid container>
              <Grid item xs={4} p={1} height={"32px"}>
                <Title>Etapa {index + 1}</Title>
              </Grid>
              <Grid item xs={7} p={1} height={"32px"}>
                <span>{step.name}</span>
              </Grid>
              <Grid
                item
                xs={1}
                p={"4px"}
                height={"32px"}
                display={"flex"}
                justifyContent={"end"}
              >
                {activeStep === index ? (
                  <BiChevronUp fontSize={"24px"} />
                ) : (
                  <BiChevronDown fontSize={"24px"} />
                )}
              </Grid>
            </Grid>
          </StepExpandHeader>
          {activeStep === index && (
            <StepExpandContent>
              <Stack p={1} borderBottom={"1px solid #d6d8d9"}>
                <Title>
                  Prazo para aprovação: {step.deadline}{" "}
                  {Number(step.deadline) === 1 ? "dia" : "dias"}
                </Title>
              </Stack>
              <Grid
                container
                style={{
                  borderBottom: "1px solid #d6d8d9",
                  padding: "8px"
                }}
              >
                <Grid item xs={4}>
                  <Title>Requisitos</Title>
                </Grid>
                <Grid item xs={8}>
                  {step.requirements.map(requirement => (
                    <Stack key={requirement.id} marginBottom={"8px"}>
                      <Label1>{requirement.name}</Label1>
                      {requirement.description ? (
                        <Label2>{requirement.description}</Label2>
                      ) : (
                        <NoDescription>Sem descrição</NoDescription>
                      )}
                    </Stack>
                  ))}
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  padding: "8px"
                }}
              >
                <Grid item xs={4}>
                  <Title>Aprovadores</Title>
                </Grid>
                <Grid item xs={8}>
                  {step.approvers.map(approver => (
                    <Stack key={approver.id} marginBottom={"8px"}>
                      <Label1>{approver.name}</Label1>
                      <Label2>{approver.email}</Label2>
                    </Stack>
                  ))}
                </Grid>
              </Grid>
            </StepExpandContent>
          )}
        </StepExpand>
      ))}
    </StepsWrapper>
  );
};

export default StepsDetails;
