import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  width: 445px;
  background-color: ${({ theme }) => theme.colors.white100};
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  textarea {
    width: 100%;
    height: 150px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.black50};
    resize: none;
  }

  @media (max-width: 459px) {
    width: 93%;
  }

  @media (min-width: 1600px) {
    width: 450px;
  }

  @media (min-width: 1920px) {
    width: 560px;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: "Ubuntu";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (min-width: 1920px) {
    font-size: 16px;
  }

  input {
    width: 24px;
    height: 24px;
  }
`;
