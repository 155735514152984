//this component render for example Konva Shape to show in our canvas
import Konva from "konva";
import { Fragment, memo, useEffect, useRef, useState } from "react";
import { Line, Transformer } from "react-konva";
import { Html } from "react-konva-utils";
import { PagesProps } from "../../../../../dtos/PagesProps";
import { IRenderedObject } from "../../../../../dtos/RenderedObject";

interface LineShapeProps {
  shapeProps: IRenderedObject | Konva.Line | Konva.Shape | any;
  selectedObject: Konva.Shape;
  setSelectedObject: React.Dispatch<
    React.SetStateAction<Konva.Shape | Konva.Group>
  >;
  addToHistory: (isLastHistory?: boolean) => void;
  currentMultipleSelection: React.MutableRefObject<Konva.Transformer>;
  setObjectScreen: React.Dispatch<React.SetStateAction<PagesProps[]>>;
  transformerSelectedObject: React.MutableRefObject<Konva.Transformer>;
  onChange?: (newAttrs: IRenderedObject) => void;
  handleRemoveElementGroup: (id: string) => void;
  setPageObject?: React.Dispatch<React.SetStateAction<number>>;
  isDraggingObject?: boolean;
  setIsDraggingObject?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SimpleLine: React.FC<LineShapeProps> = ({
  shapeProps,
  selectedObject,
  setSelectedObject,
  addToHistory,
  currentMultipleSelection,
  setObjectScreen,
  transformerSelectedObject,
  onChange,
  handleRemoveElementGroup,
  setPageObject,
  isDraggingObject,
  setIsDraggingObject,
}) => {
  const shapeRef = useRef<Konva.Shape>();
  const trRef = useRef<Konva.Transformer>();
  const trPreSelected = useRef<Konva.Transformer>();
  const [isSelected, setIsSelected] = useState(false);
  const [isPreSelected, setIsPreSelected] = useState(false);
  const [showRotation, setShowRotation] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [shiftPressed, setShiftPressed] = useState(false);

  if (shapeProps?.strokeWidth === 1) shapeProps.strokeWidth = 2;

  // ======>
  let isDraggable = shapeRef?.current?.draggable();
  //this code below verify if elements stay block and remove anchors for editions in scale
  // when element is block
  useEffect(() => {
    if (shapeRef.current) {
      isDraggable = shapeRef.current.draggable();
    }
  }, [shapeRef]);
  // <=======

  //to selected object and attributes transformer too a global variable
  useEffect(() => {
    if (shapeRef.current === selectedObject) {
      setIsSelected(true);
      transformerSelectedObject.current = trRef.current;
    } else {
      setIsSelected(false);
    }
  }, [selectedObject, shapeRef.current]);

  //when selected shape then go set nodes inside of transformer
  //bring zIndex to behind and set anchors
  useEffect(() => {
    trRef?.current?.setNodes([shapeRef?.current]);
    trRef?.current?.setZIndex(
      trRef?.current?.getParent()?.children?.length - 1
    );
    trRef?.current?.getLayer()?.batchDraw();
  }, [isSelected]);

  //when user passed it mouse in line shape this transformer
  //will be visible, it might to help the user to get line
  //just user experience
  useEffect(() => {
    trPreSelected?.current?.setNodes!([shapeRef?.current]);
    trPreSelected?.current?.getLayer()?.batchDraw();
  }, [isPreSelected]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <Fragment key={shapeProps.id}>
      <Line
        key={shapeProps.id}
        ref={shapeRef}
        name="simpleLine"
        hitStrokeWidth={10}
        points={[0, 0, shapeProps?.setPoints, 0]}
        {...shapeProps}
        onClick={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
          const isTargetInsideMultipleSelection =
            currentMultipleSelection.current?.nodes()?.includes(event.target);
          if (isTargetInsideMultipleSelection) {
            setSelectedObject(null);

            if (shiftPressed) {
              handleRemoveElementGroup(event.target.attrs.id);
              return;
            }
          } else {
            setSelectedObject(event.target);
            currentMultipleSelection.current?.setNodes([]);
          }
        }}
        onMouseEnter={(event: Konva.KonvaEventObject<MouseEvent>) => {
          if (event.target !== selectedObject) {
            setIsPreSelected(true);
          }
        }}
        onMouseOut={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setIsPreSelected(false);
        }}
        onDragStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
          if (
            currentMultipleSelection.current?.nodes().length === 0 ||
            !currentMultipleSelection.current
          ) {
            addToHistory();
          }
          setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
          setIsDraggingObject(true);
          //if mutipleSelection includes element being dragged
          if (
            currentMultipleSelection.current?.nodes().includes(event.target)
          ) {
            setSelectedObject(null);
          } else {
            setSelectedObject(event.target);
            currentMultipleSelection.current?.setNodes([]);
          }
        }}
        onDragEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setIsDraggingObject(false);
          onChange({
            ...shapeProps,
            x: event.target.x(),
            y: event.target.y(),
          });
        }}
      />

      {isSelected && showRotation && (
        <Html>
          <div
            style={{
              width: "40px",
              height: "20px",
              background: "rgba(0, 0, 0, 0.8)",
              position: "absolute",
              top: shapeProps?.y - 50,
              left: Math.ceil(shapeProps?.x - 20),
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#fff" }}>{`${rotation?.toFixed(0)}°`}</span>
          </div>
        </Html>
      )}

      {isSelected && (
        <>
          <Transformer
            ref={trRef}
            enabledAnchors={["middle-left", "middle-right"]}
            onTransformStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
              if (
                currentMultipleSelection.current?.nodes().length === 0 ||
                !currentMultipleSelection.current
              ) {
                addToHistory();
              }
            }}
            boundBoxFunc={(oldBox, newBox) => {
              if (newBox.width < 1 || newBox.height < 1) {
                return oldBox;
              }
              return newBox;
            }}
            onTransform={(event: Konva.KonvaEventObject<MouseEvent>) => {
              const node = event.target;
              const rotationNode = node.rotation();
              setShowRotation(true);
              setRotation(rotationNode);
            }}
            onTransformEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
              setShowRotation(false);
              onChange({
                ...shapeProps,
                scaleX: event.target.scaleX(),
                scaleY: event.target.scaleY(),
                x: event.target.x(),
                y: event.target.y(),
                rotation: rotation,
              });
            }}
          />
        </>
      )}

      {isPreSelected && (
        <Transformer
          ref={trPreSelected}
          padding={3}
          enabledAnchors={isDraggable ? [""] : []}
          onTransformStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
            addToHistory();
          }}
          onClick={(event: Konva.KonvaEventObject<MouseEvent>) => {
            setSelectedObject(shapeRef.current);
          }}
          // rotateEnabled={isDraggable ? true : false}
          // resizeEnabled={isDraggable ? true : false}
          borderStroke="purple"
          rotateEnabled={false}
        />
      )}
    </Fragment>
  );
};

export default memo(SimpleLine);
