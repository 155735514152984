/*
this file render all features about clauses of bits area, inside clauses view
screen features such as search, favorite, delete clauses, all
requests of api, and through only to clauses of bits library`s ok?
 */
import React, { useEffect, useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import CategoriesBox from "../../CategoriesBox";
import ListBitsClause from "../../ListBitsClause";
import ListItemBank from "../../ListItemBank";
import { ContainerLoading } from "../../LoadingEditor/styles";
import QuillRenderImage from "../../QuillRenderImage";
import { SearchInputBank } from "../../SearchInputBank";
import {
  ButtonCategories,
  ButtonFavoriteBankClause,
  Container,
  ContentFilter,
  FlexSearch,
  Header,
  NotResult,
  Text,
  VStack
} from "../styles";
import { motion } from "framer-motion";
import { useClausesEditor } from "../../../hooks/clausesEditor";
import {
  BankOfClausesProps,
  CategoriesProps,
  ClausesDataProps,
  ParamsCategoriesProps
} from "../dtos";
import api from "../../../../../services/api";
import { useMainHook } from "../../../../../hooks/main";
import Tooltip from "../../Tooltip";
import { HeaderClause, TextClause } from "../../ListItem/styles";
import FavotiteIcon from "../../SearchInput/Icons/FavotiteIcon";
import { ContainerItem } from "./styles";

// import { Container } from './styles';

//set up interfaces
interface BitsClausesProps {
  isActiveBoxCategories: boolean;
  refComponentBitsClauses: React.MutableRefObject<any>;
  focusInput: boolean;
  setFocusInput: React.Dispatch<React.SetStateAction<boolean>>;
  currentPageBits: number;
  setCurrentPageBits: React.Dispatch<React.SetStateAction<number>>;
  setParamsCategories: React.Dispatch<
    React.SetStateAction<ParamsCategoriesProps>
  >;
  paramsCategories: ParamsCategoriesProps;
  dataCategories: CategoriesProps[];
  handleBoxCategories: () => void;
  setCurrentPageCategories: React.Dispatch<React.SetStateAction<number>>;
  currentPageCategories: number;
  setIsFavoriteFilterBits: React.Dispatch<React.SetStateAction<boolean>>;
  isFavoriteFilterBits: boolean;
  showFilterBits: string;
  handleActiveBoxCategories: () => void;
  setLimitPageCategories: React.Dispatch<React.SetStateAction<number>>;
  limitPageCategories: number;
  loadingBank: boolean;
  bankOfClausesData: BankOfClausesProps[];
  existsNewPageBits: boolean;
  handleFavoriteClauseBits: (
    id: string,
    object: BankOfClausesProps,
    index: number
  ) => Promise<void>;
  handleGenerateClause: (
    value: string,
    event: React.DragEvent<HTMLDivElement>,
    index: number,
    keyWords?: string
  ) => Promise<void>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isActive: "MyLibrary" | "CompanyLibrary" | "TeamsLibrary";
  setBankOfClausesData: React.Dispatch<
    React.SetStateAction<BankOfClausesProps[]>
  >;
  setShowFilterBits: React.Dispatch<React.SetStateAction<string>>;
  setLoadingBank: React.Dispatch<React.SetStateAction<boolean>>;
  newPageClauseBits: ClausesDataProps[];
  setExistsNewPageBits: React.Dispatch<React.SetStateAction<boolean>>;
  setNewPageClauseBits: React.Dispatch<
    React.SetStateAction<ClausesDataProps[]>
  >;
}

//only animated dropdown component
//we are used framer-motion
const list = {
  visible: {
    opacity: 1,
    y: 5,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3
    }
  },
  hidden: {
    opacity: 0,
    y: -20,
    transition: {
      when: "afterChildren"
    }
  }
};

const BitsClauses: React.FC<BitsClausesProps> = ({
  isActiveBoxCategories,
  refComponentBitsClauses,
  focusInput,
  setFocusInput,
  currentPageBits,
  setCurrentPageBits,
  setParamsCategories,
  paramsCategories,
  dataCategories,
  handleBoxCategories,
  setCurrentPageCategories,
  currentPageCategories,
  setIsFavoriteFilterBits,
  isFavoriteFilterBits,
  showFilterBits,
  handleActiveBoxCategories,
  setLimitPageCategories,
  limitPageCategories,
  loadingBank,
  bankOfClausesData,
  existsNewPageBits,
  handleFavoriteClauseBits,
  handleGenerateClause,
  setCurrentPage,
  isActive,
  setBankOfClausesData,
  setShowFilterBits,
  setLoadingBank,
  newPageClauseBits,
  setExistsNewPageBits,
  setNewPageClauseBits
}) => {
  //hooks
  const { dragging, setDragging, setIdClause, queryBank, setQueryBank } =
    useClausesEditor();

  const [isButtonMoreOptions, setIsButtonMoreOptions] = useState(false);

  const { setTextClauseView, setIsVisibleClauseText } = useMainHook();

  //trigger when filter was favorite type
  //then back value to 0 to current page
  useEffect(() => {
    if (queryBank.filter === "favorite") {
      setCurrentPageBits(0);
      setIsFavoriteFilterBits(true);
    }

    //if the value is empty to category then
    //reset current page and set false favorite filter state
    if (queryBank.category !== "") {
      setCurrentPageBits(0);
      setIsFavoriteFilterBits(false);
    }
  }, [queryBank]);

  //query params of bits clauses library
  useEffect(() => {
    setQueryBank(state => {
      return {
        ...state,
        pagination: currentPageBits
      };
    });
  }, [currentPageBits, isActive]);

  //when favorite clauses of bits state is true
  //we have that set current page to zero
  useEffect(() => {
    if (isFavoriteFilterBits) {
      setCurrentPage(0);
    }
  }, [isFavoriteFilterBits]);

  useEffect(() => {
    if (!isActiveBoxCategories) {
      setParamsCategories(oldState => {
        return {
          ...oldState,
          filter: "all"
        };
      });
    }
  }, [isActiveBoxCategories]);

  //to load we clauses of bits library`s
  //we requesto to the endpoint and we use params to
  //controls pagination (infinity scroll), let's create requests
  //and collect them in our main state of clauses
  useEffect(() => {
    const autoLoadBankOfClauses = async () => {
      try {
        const responseBankOfClauses = await api.get("bits-clause", {
          params: {
            pagination:
              queryBank?.search === "" &&
              queryBank?.filter !== "favorite" &&
              queryBank.category === ""
                ? queryBank.pagination
                : 0,
            search: queryBank?.search === "" ? null : queryBank.search,
            filter: queryBank?.filter === "" ? null : queryBank.filter,
            category: queryBank?.category === "" ? null : queryBank.category
          }
        });
        let newClauses = responseBankOfClauses.data.dataArray;
        // verify because this useEffect execute two times and occurring issue in this pagination
        setBankOfClausesData(prevState => {
          let cloneState = [...prevState];
          if (
            queryBank.pagination !== 0 &&
            queryBank.filter !== "favorite" &&
            queryBank.category === "" &&
            queryBank.search === ""
          ) {
            return [...cloneState, ...newClauses];
          } else {
            cloneState = [...newClauses];
            return cloneState;
          }
        });

        setLoadingBank(false);
      } catch (err) {
        console.error(err, "error generate list of bank of clauses api");
      }
    };

    autoLoadBankOfClauses();
  }, [queryBank, isActive]);

  //when user change params and that props favorite
  //is true then reset current page and set favorites states
  useEffect(() => {
    setShowFilterBits(queryBank.category);
    if (queryBank.filter === "favorite") {
      setCurrentPageBits(0);
      setShowFilterBits("favorite");
      setIsFavoriteFilterBits(true);
    } else {
      setIsFavoriteFilterBits(false);
    }
  }, [queryBank]);

  //useEffect for verify if already exists next page for show sentinela
  useEffect(() => {
    const autoNextPage = async () => {
      setTimeout(async () => {
        try {
          const responseNextPage = await api.get("bits-clause", {
            params: {
              pagination: currentPageBits + 1,
              search: queryBank?.search === "" ? null : queryBank.search,
              filter: queryBank?.filter === "" ? null : queryBank.filter,
              category: queryBank?.category === "" ? null : queryBank.category
            }
          });

          if (responseNextPage.status === 200) {
            setNewPageClauseBits(responseNextPage.data.dataArray);
          }
        } catch (err) {
          console.error(err);
        }
      }, 2000);
    };

    autoNextPage();
  }, [currentPageBits, queryBank]);

  //verify if exists really next page
  useEffect(() => {
    if (newPageClauseBits.length > 0) {
      setExistsNewPageBits(true);
    } else if (newPageClauseBits.length === 0) {
      setExistsNewPageBits(false);
    }
  }, [newPageClauseBits, queryBank]);

  //to observe when user to arrive on end scroll view and
  //trigger the we infinity scroll to load new page
  //we get by id and apply observe every time trigger when
  //interface show it that id
  useEffect(() => {
    //isactive = tab for clauses of bits
    if (
      !loadingBank &&
      existsNewPageBits &&
      isActive === "CompanyLibrary" &&
      !isFavoriteFilterBits
    ) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          setCurrentPageBits(prevState => prevState + 1);
        }
      });
      const myDiv = document.querySelector("#sentinela2");

      if (myDiv) {
        intersectionObserver?.observe(myDiv);
      }
      return () => intersectionObserver?.disconnect();
    }
  }, [loadingBank, existsNewPageBits, isActive]);

  return (
    <Container>
      <VStack isModalOpen={isActiveBoxCategories} height="100%">
        <Header ref={refComponentBitsClauses}>
          <FlexSearch width="100%">
            <SearchInputBank
              query={queryBank}
              setQuery={setQueryBank}
              label="Pesquisar por título"
              focusInput={focusInput}
              setFocusInput={setFocusInput}
              currentPage={currentPageBits}
              setCurrentPage={setCurrentPageBits}
            />

            {/* render only for transform clause in image for text state */}
            {<QuillRenderImage />}

            {isActiveBoxCategories && (
              <CategoriesBox
                handleBoxCategories={handleBoxCategories}
                dataCategories={dataCategories}
                paramsCategories={paramsCategories}
                setParamsCategories={setParamsCategories}
                query={queryBank}
                setQuery={setQueryBank}
                focusInput={focusInput}
                setFocusInput={setFocusInput}
                isFilterBits={isFavoriteFilterBits}
                setIsFilterBits={setIsFavoriteFilterBits}
                // closeToggle={closeToggle}
                pageCurrent={currentPageCategories}
                setPageCurrent={setCurrentPageCategories}
                limitPage={limitPageCategories}
                setLimitPage={setLimitPageCategories}
              ></CategoriesBox>
            )}
          </FlexSearch>

          <Tooltip
            title="Categoria"
            description="Filtre os textos por categoria"
            placement="bottom-start"
          >
            <ButtonCategories onClick={handleActiveBoxCategories}>
              Categoria
            </ButtonCategories>
          </Tooltip>
        </Header>

        {loadingBank ? (
          <ContainerLoading>
            <ContractAnimation />
          </ContainerLoading>
        ) : (
          <>
            {showFilterBits && showFilterBits !== "favorite" && (
              <ContentFilter>
                <div className="chip">
                  <div className="chip-content">
                    {showFilterBits !== "favorite" && showFilterBits}
                  </div>
                  <div
                    className="chip-close2"
                    onClick={() => {
                      if (showFilterBits === "favorite") {
                        setCurrentPageBits(0);
                        setIsFavoriteFilterBits(false);
                        setQueryBank(state => {
                          return {
                            ...state,
                            category: "",
                            filter: "all"
                          };
                        });

                        return;
                      } else {
                        setIsFavoriteFilterBits(true);
                        setQueryBank(state => {
                          return {
                            ...state,
                            category: ""
                          };
                        });
                        setCurrentPageBits(0);
                      }
                    }}
                  >
                    <svg
                      className="chip-svg2"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                    </svg>
                  </div>
                </div>
              </ContentFilter>
            )}

            <ListBitsClause
              isFavoriteFilter={isFavoriteFilterBits}
              showFilter={showFilterBits}
            >
              {bankOfClausesData.length === 0 && (
                <NotResult>
                  <Text>Nenhum texto foi encontrado.</Text>
                </NotResult>
              )}

              {bankOfClausesData.map((item, index) => (
                <ListItemBank
                  key={index}
                  variants={list}
                  initial="hidden"
                  animate="visible"
                  as={motion.li}
                  onDragStart={e => {
                    setIdClause(item.id);
                    handleGenerateClause(item.clause, e, index);
                  }}
                >
                  <ContainerItem>
                    <HeaderClause>
                      <div>
                        <Tooltip
                          title="Favoritar"
                          description="Favorite esse texto"
                          placement="bottom-end"
                        >
                          <ButtonFavoriteBankClause
                            onClick={() =>
                              handleFavoriteClauseBits(item.id, item, index)
                            }
                          >
                            <FavotiteIcon isActive={item.is_favorite} />
                          </ButtonFavoriteBankClause>
                        </Tooltip>
                      </div>
                    </HeaderClause>

                    <Tooltip
                      title="Arraste para usar ou clique para visualizar"
                      placement="bottom-start"
                    >
                      <TextClause
                        onClick={() => {
                          if (!isButtonMoreOptions) {
                            setTextClauseView(item.id);
                            setIsVisibleClauseText(true);
                          }
                        }}
                        onDragEnterCapture={e => {
                          if (!dragging.active) {
                            handleGenerateClause(
                              item.clause,
                              e,
                              index,
                              item.key_words
                            );
                          }
                        }}
                        onDragOverCapture={() => {
                          setDragging({ active: false, index: 0 });
                        }}
                      >
                        {item.clause}
                      </TextClause>
                    </Tooltip>
                  </ContainerItem>
                </ListItemBank>
              ))}

              {existsNewPageBits && !isFavoriteFilterBits && (
                <li
                  style={{
                    width: "100%",
                    minHeight: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  id="sentinela2"
                >
                  <MoonLoader size={18} color="#000" />
                </li>
              )}
            </ListBitsClause>
          </>
        )}
      </VStack>
    </Container>
  );
};

export default BitsClauses;
