import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Paper } from "@material-ui/core";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page } from "react-pdf";
import HeaderContainer from "./styles";
import DownloadIcon from "../DownloadIcon";
import { MdClose } from "react-icons/md";
import { Box, Stack } from "@mui/material";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  paper: {
    width: "80%",
    height: "90%",
    outline: "none",
    overflow: "auto",
    backgroundColor: "transparent",
    padding: theme.spacing(2, 4, 3),
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-star",
    // Make scrollbar transparent for Chrome, Safari and Opera
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent"
    },
    // Make scrollbar transparent for IE, Edge and Firefox
    msOverflowStyle: "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */
  }
}));

interface PdfViewerProps {
  open: boolean;
  onClose: () => void;
  file: File;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ open, onClose, file }) => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setTimeout(() => {
      setLoading(false);
      setVisible(true);
    }, 1000);
  }

  const handleDownload = async () => {
    try {
      const url = URL.createObjectURL(file);
      const responseFile = await fetch(url);
      if (responseFile.status === 200) {
        const blob = await responseFile.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${file.name}.pdf`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl); // Clean up the URL object
      } else {
        toast.error(
          "Ocorreu um erro ao baixar o documento. Contate o suporte técnico."
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Ocorreu um erro ao baixar o documento. Contate o suporte técnico."
      );
    }
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <>
        {loading && (
          <Box
            top={"20px"}
            position={"absolute"}
            width={"100vw"}
            height={"100vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            zIndex={5000}
          >
            <ContractAnimation />
          </Box>
        )}
        <HeaderContainer>
          <span>{file.name}</span>
          <Stack direction={"row"} gap="8px">
            <div onClick={handleDownload}>
              <DownloadIcon color="white" />
            </div>
            <MdClose style={{ fontSize: "24px" }} onClick={onClose} />
          </Stack>
        </HeaderContainer>

        <Paper
          className={classes.paper}
          style={{ visibility: visible ? "visible" : "hidden" }}
        >
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {!loading &&
              Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
          </Document>
        </Paper>
      </>
    </Modal>
  );
};

export default PdfViewer;
