import styled from "styled-components";

export const Header = styled.header`
  height: 40px;
  width: 100%;
  padding-inline: 15px;
  background-color: #343a40;
  position: sticky;
  top: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  p {
    color: #fff;
  }

  button {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    color: #999c9f;
    border-radius: 5px;
    background-color: transparent;

    :hover {
      background: rgba(245, 245, 245, 0.1);
    }
  }

  .button-edit {
    color: white;
    background-color: ${({ theme }) => theme.colors.primary90};

    :hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: rgb(0, 0, 0, 0.5);
`;
