import { Modal, ModalProps } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useMainHook } from "../../../../hooks/main";
import Rendering from "../../../../modules/editor/components/Canvas/Rendering";
import { useSelection } from "../../../../modules/editor/hooks/selection";
import {
  Container,
  ContainerLoading,
  FlexLoading,
  StageContainer,
  Pages,
} from "./styles";
import { Layer, Stage } from "react-konva";
import { motion } from "framer-motion";
import { PagesProps } from "../../../../dtos/PagesProps";
import { useTextsEdition } from "../../../../modules/editor/hooks/textsEdition";
import { useDiff } from "../../../../modules/editor/hooks/diffDocuments";

import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import Header from "./components/Header";
import { UseMutateFunction, UseMutationResult } from "react-query";
import { IResponseCreationProjectFromBitsTemplates } from "../../../../dtos/CreateNewProjects";

interface PersonalizedModalProps extends ModalProps {
  open: boolean;
  pagesToBeRendered: PagesProps[] | null;
  handleCloseModal: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  loadingPreview: boolean;
  chooseTemplate?: UseMutateFunction<
    IResponseCreationProjectFromBitsTemplates,
    unknown,
    string,
    unknown
  >;
  templateId?: string;
  texButton?: "Usar Template" | "Abrir Projeto";
  title: string;
  local?: "Dashboard" | "Editor";
}

type OmitedPreviewDocument = Omit<PersonalizedModalProps, "children">;

const PreviewTeamDocument: React.FC<OmitedPreviewDocument> = ({
  open,
  handleCloseModal,
  title,
  pagesToBeRendered,
  loadingPreview,
  chooseTemplate,
  templateId,
  onPlayingCapture,
  texButton,
  local = "Dashboard",
  ...rest
}) => {
  const oneRef = useRef(null);

  const {
    setSelectedObject,
    currentMultipleSelection,
    selectedObject,
    transformerSelectedObject,
  } = useSelection();

  const { addToHistory, setObjectScreen, nameOfCurrentUser, hasGroupShared } =
    useMainHook();
  const { blockCommandKeyboard, setBlockCommandKeyboard } = useSelection();

  const widthCanvas: number = 596;
  const heigthCanvas: number = 842;

  const {
    quillRef,
    requestTextUpdate,
    textRich,
    isEditing,
    setIsEditing,
    blockingKeyboard,
    setBlockingKeyboard,
    quillRefComponent,
  } = useTextsEdition();

  const {
    templateStartedShared,
    setTemplateStartedShared,
    oldContentsState,
    setOldContentsState,
    newContentsState,
    setNewContentsState,
    selectionCursor,
    setSelectionCursor,
    findDiff,
    buttonRef,
    newDelta,
    setNewDelta,
    isEditingQuill,
    setIsEditingQuill,
    newChanges,
    setNewChanges,
    newContentsRef,
    oldContentsRef,
    getQuillCursorRef,
    isNewTextRef,
    isTypingQuillRef,
    whichUserEdited,
    interceptModifications,
    textIsChanged,
    blockRenderImage,
    blockBackspace,
    whichKeyPress,
    selectionFormatText,
  } = useDiff();

  return (
    <Modal open={open} {...rest}>
      {loadingPreview ? (
        <ContainerLoading>
          <div>
            <FlexLoading>
              <ContractAnimation />
            </FlexLoading>
          </div>
        </ContainerLoading>
      ) : (
        <Container>
          <Header
            handleCloseModal={handleCloseModal}
            chooseTemplateBits={chooseTemplate}
            templateId={templateId}
            texButton={texButton}
            title={title}
            local={local}
          />
          <Pages>
            {pagesToBeRendered?.map((item, index) => (
              <StageContainer as={motion.div} key={item.pageNumber}>
                <Stage
                  ref={oneRef}
                  listening={false}
                  pixelRatio={5}
                  scaleX={1}
                  scaleY={1}
                  width={widthCanvas}
                  height={heigthCanvas}
                >
                  <Layer>
                    {item?.renderObjects?.map((objectProps, index) => (
                      <Rendering
                        key={index}
                        selectedObject={selectedObject}
                        setSelectedObject={setSelectedObject}
                        objectProps={objectProps}
                        addToHistory={addToHistory}
                        commandKeyboard={blockCommandKeyboard}
                        setCommandBlockKeyboard={setBlockCommandKeyboard}
                        currentMultipleSelection={currentMultipleSelection}
                        setObjectScreen={setObjectScreen}
                        transformerSelectedObject={transformerSelectedObject}
                        quillRef={quillRef}
                        requestUpdateText={requestTextUpdate}
                        textRich={textRich}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        blockingKeyboard={blockingKeyboard}
                        setBlockingKeyboard={setBlockingKeyboard}
                        templateStartedShared={templateStartedShared}
                        setTemplateStartedShared={setTemplateStartedShared}
                        oldContentsState={oldContentsState}
                        setOldContentsState={setOldContentsState}
                        newContentsState={newContentsState}
                        setNewContentsState={setNewContentsState}
                        selectionCursor={selectionCursor}
                        setSelectionCursor={setSelectionCursor}
                        findDiff={findDiff}
                        buttonRef={buttonRef}
                        newDelta={newDelta}
                        setNewDelta={setNewDelta}
                        isEditingQuill={isEditingQuill}
                        setIsEditingQuill={setIsEditingQuill}
                        newChanges={newChanges}
                        setNewChanges={setNewChanges}
                        oldContentsRef={oldContentsRef}
                        newContentsRef={newContentsRef}
                        getQuillCursorRef={getQuillCursorRef}
                        isNewTextRef={isNewTextRef}
                        isTypingQuillRef={isTypingQuillRef}
                        quillRefComponent={quillRefComponent}
                        whichUserEdited={whichUserEdited}
                        nameOfCurrentUser={nameOfCurrentUser}
                        interceptModifications={interceptModifications}
                        textIsChanged={textIsChanged}
                        blockRenderImage={blockRenderImage}
                        blockBackspace={blockBackspace}
                        hasGroupShared={hasGroupShared}
                        whichKeyPress={whichKeyPress}
                        selectionFormatText={selectionFormatText}
                      />
                    ))}
                  </Layer>
                </Stage>
              </StageContainer>
            ))}
          </Pages>
        </Container>
      )}
    </Modal>
  );
};

export default PreviewTeamDocument;

