import React from "react";
import Lottie from "lottie-react";
import uxDocAnimation from "../../assets/lottieJsons/UXDOC-loading-speed.json";
import ReactLoading from "react-loading";

import { useLottie } from "lottie-react";
import { useAuth } from "../../../hooks/auth";

const ContractAnimation: React.FC = () => {
  const options = {
    animationData: uxDocAnimation,
    loop: true
  };

  const { View } = useLottie(options);
  const { data } = useAuth();

  return data?.organization ? (
    <ReactLoading type="spin" color="#000" height={34} width={34} />
  ) : (
    <div className="responsive-loading">{View}</div>
  );
};

export { ContractAnimation };
