/*
this component controls pages on editor, then here we have 
the pages which should be renders, and manage pages such as
duplicate page, remove page and more...
the most important canvas component is SceneKonva, that component
controls stages of konva lib and pass it which permission that user has
and CanvasDropArea component controls wich objects will be drop on editor.

NOTE: second user will be other user try access the same document
we dont have real time then that means we need block this second user.
*/

import React from "react";
import { PagesProps } from "../../../../dtos/PagesProps";
import ButtonsPagesControl from "../EditorMain/components/ButtonsPagesControl";

import TitleOfPage from "../EditorMain/components/TitleOfPage";
import { Container, FlexRow, HorizontalStack } from "./styles";

import SceneKonva from "../Canvas/SceneKonva";
import CanvasDropArea from "./CanvasDropArea";
import { Skeleton } from "@mui/material";
import MouseFollower from "../MouseFollower";

//interfaces
interface toggleProps {
  visible?: boolean;
  index: number;
}

interface CanvasPageProps {
  objectScreen: PagesProps[];
  isOpenOptions: toggleProps;
  toggleActiveOptionsPage: (visible?: boolean, index?: number) => void;
  sizePage: number;
  permissionDocument: boolean;
  containerRef?: React.MutableRefObject<HTMLDivElement>;
  pageRefs?: React.MutableRefObject<any[]>;
  hasLoadPages?: boolean;
}

const CanvasPage: React.FC<CanvasPageProps> = ({
  objectScreen,
  // isOpenOptions,
  toggleActiveOptionsPage,
  sizePage,
  permissionDocument,
  // containerRef,
  pageRefs,
  hasLoadPages,
}) => {
  const skeletonStyles = {
    width: "100%",
    height: "45px",
    maxWidth: "600px",
    display: "flex",
    justifyContent: "space-between",
    padding: "0",
    marginLeft: "3rem",
  };

  return (
    <>
      {objectScreen.map((item, index) => (
        <Container
          key={item.pageNumber}
          ref={(ref) => (pageRefs!.current[index] = ref)}
          className="get_heigth-container_page"
        >
          {item.visible ? (
            <>
              <HorizontalStack
                id={`pageNumber-${index}`}
                onDragStart={(event) => {
                  event.preventDefault();
                  return;
                }}
                className="get-height-horizontal_stackRef"
              >
                <TitleOfPage index={index} />
                <FlexRow>
                  <ButtonsPagesControl
                    item={item}
                    index={index}
                    onClick={toggleActiveOptionsPage}
                    pageSize={sizePage}
                  />
                </FlexRow>
              </HorizontalStack>
              <CanvasDropArea
                index={index}
                item={item}
                isSecondUser={!permissionDocument}
              >
                <SceneKonva
                  isSecondUser={!permissionDocument}
                  item={item}
                  index={index}
                />
              </CanvasDropArea>
            </>
          ) : (
            <>
              <div
                style={skeletonStyles}
              >
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2.5rem",
                    bgcolor: "#d4d4d4",
                  }}
                  width={200}
                  height={40}
                />

                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2.5rem",
                    bgcolor: "#d4d4d4",
                    marginLeft: "2.5rem",
                  }}
                  width={30}
                  height={40}
                />
              </div>
              <Skeleton
                sx={{ bgcolor: "#d4d4d4" }}
                variant="rectangular"
                width={596}
                animation="pulse"
                height={842}
                style={{
                  borderRadius: "5px",
                  marginBottom: "2rem",
                  marginLeft: "3rem",
                }}
              />
            </>
          )}

          {index === objectScreen.length - 1 && hasLoadPages && (
            <>
              <div style={skeletonStyles}>
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2.5rem",
                    bgcolor: "#d4d4d4",
                  }}
                  width={200}
                  height={40}
                />

                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2.5rem",
                    bgcolor: "#d4d4d4",
                    marginLeft: "2.5rem",
                  }}
                  width={30}
                  height={40}
                />
              </div>
              <Skeleton
                sx={{ bgcolor: "#d4d4d4" }}
                variant="rectangular"
                width={596}
                animation="pulse"
                height={842}
                style={{
                  borderRadius: "5px",
                  marginBottom: "2rem",
                  marginLeft: "3rem",
                }}
              />
            </>
          )}
        </Container>
      ))}
    </>
  );
};

export default CanvasPage;

