/*
this file render all elements that can possible draggable to editor
such as star, line, text (for now only), rect, and more elements...
that elements has this one props most important: your "alt".

NOTE:
the props "alt" send command to we callback of on drop into editor (canvas),
src only to show in User Interface into of sidebar and type only are class of css
*/

import React, { useCallback, useEffect, useState } from "react";
import {
  KonvaDropObject,
  KonvaObjectProps
} from "../../helpers/KonvaDropObject";
import { useAsidebarEditor } from "../../hooks/asidebarEditor";
import CardElements from "../Sidebar/components/CardElements";
import GridElements from "../Sidebar/components/GridElements";
import GridItemElements from "../Sidebar/components/GridItemElements";
import ImageDrag from "./components/ImageDrag";
import {
  BackToAllElementsBox,
  BoxSearch,
  Container,
  ContainerEmpty,
  ContainerLoading,
  ElementCategory,
  ScrollInfinityBox,
  SplitContainerBox,
  Title
} from "./styles";
import imageSearching from "../../assets/icon-search.svg";
import api from "../../../../services/api";
import { useMainHook } from "../../../../hooks/main";
import iconArrowRight from "../../../../assets/icon-right-arrow.svg";
import Tooltip from "../Tooltip";

import { LoadingContainer } from "../../../dashboard/Teams/pages/TemplatesTeamPage/styles";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import ReactLoading from "react-loading";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../shared/utils/MountIcons";
import ButtonExit from "../../../../shared/components/ButtonExit";

interface CategoriesShapesProps {
  id: string;
  creator_id: string;
  last_editor_id: string;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
}

interface MutableEvent extends EventTarget {
  dpath?: string;
}

const ElementsKonva: React.FC = () => {
  const { grab, setIsGrab } = useAsidebarEditor();
  const { dragUrl, strokeWidth } = useMainHook();
  const [categoriesShapes, setCategoriesShapes] = useState<
    CategoriesShapesProps[]
  >([]);
  const [isSeeAll, setIsSeeAll] = useState(false);
  const [iconsByCategory, setIconsByCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingObjects, setLoadingObjects] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState("");
  const [objectSearchApi, setObjectSearchApi] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [loadingSearching, setLoadingSearching] = useState(false);
  const [vectorDataSearch, setVectorDataSearch] = useState([]);
  const [dataSpecificElements, setDataSpecificElements] = useState([]);
  const [idSpecificCategory, setIdSpecificCategory] = useState("");
  const [searchByCategory, setSearchByCategory] = useState("");
  const [nameChooseCategoryElement, setNameChooseCategoryElement] =
    useState("");

  const [isFocus, setIsFocus] = useState(false);

  const handleBackCategories = useCallback(() => {
    setIsSeeAll(false);
    setDataSpecificElements([]);
    setIdSpecificCategory("");
    setNameChooseCategoryElement("");
    setCurrentPage(0);
    setHasMore(true);
    setLoadingObjects(true);
  }, [
    setIsSeeAll,
    setDataSpecificElements,
    setIdSpecificCategory,
    setNameChooseCategoryElement,
    setCurrentPage,
    setHasMore
  ]);

  useEffect(() => {
    return () => {
      setIsSeeAll(false);
      setDataSpecificElements([]);
      setIdSpecificCategory("");
      setNameChooseCategoryElement("");
      setCurrentPage(0);
      setHasMore(true);
      setLoadingObjects(true);
    };
  }, []);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries?.some(entry => entry?.isIntersecting)) {
        if (totalPages > currentPage) {
          setHasMore(false);
          setCurrentPage(oldState => oldState + 1);
        } else {
          setHasMore(false);
        }
      }
    });

    const myDiv = document.querySelector("#sentinel");

    if (myDiv && !loadingObjects) {
      intersectionObserver?.observe(myDiv);
    }
    return () => intersectionObserver?.disconnect();
  }, [currentPage, totalPages, isSeeAll, loadingObjects]);

  useEffect(() => {
    (async () => {
      try {
        const responseCategoriesShapes = await api.get("bits-shape-category");
        const { dataArray } = responseCategoriesShapes.data;
        setCategoriesShapes(dataArray);
      } catch (err) {
        console.error(err, "error when get categories shaopes");
      }
    })();
  }, []);

  useEffect(() => {
    if (isSeeAll && idSpecificCategory) {
      (async () => {
        try {
          const responseDataCategory = await api.get(
            `bits-shape/list-all-category/${idSpecificCategory}`,
            {
              params: {
                pagination: currentPage,
                search: searchByCategory.length > 0 ? searchByCategory : null
              }
            }
          );

          setTotalPages(responseDataCategory.data.pagination);

          if (searchByCategory) {
            setDataSpecificElements(responseDataCategory.data.dataArray);
          } else {
            setDataSpecificElements(oldState => [
              ...oldState,
              ...responseDataCategory.data.dataArray
            ]);
          }

          setLoadingObjects(false);
        } catch (err) {
          console.error(err.response, "ERRO!");
        }
      })();
    }
  }, [isSeeAll, idSpecificCategory, currentPage, searchByCategory]);

  useEffect(() => {
    (async () => {
      if (categoriesShapes.length > 0) {
        let iconsArr = [];

        for (const value of categoriesShapes) {
          try {
            const dataOfShapes = await api.get(
              `bits-shape/list-all-category/${value.id}`
            );
            iconsArr.push({
              category: value.title,
              id: value.id,
              shapes: dataOfShapes.data
            });
          } catch (err) {
            console.error(err, "error when fetch id categories");
          }
        }

        setIconsByCategory(state => {
          let cloneState = [...state];
          cloneState = iconsArr;
          return cloneState;
        });

        setLoading(false);
      }
    })();
  }, [categoriesShapes]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setObjectSearchApi(search);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  useEffect(() => {
    if (search.length > 0) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
      setVectorDataSearch([]);
    }
  }, [search]);

  const handleSeeAllElements = useCallback(
    (id: string, nameCategory: string) => {
      setIsSeeAll(true);
      setIdSpecificCategory(id);
      setNameChooseCategoryElement(nameCategory);
    },
    [setIsSeeAll, setIdSpecificCategory, setNameChooseCategoryElement]
  );

  useEffect(() => {
    if (isSearching) {
      (async () => {
        try {
          setLoadingSearching(true);
          setTimeout(async () => {
            if (objectSearchApi.length > 0) {
              const responseVectors = await api.get("bits-shape", {
                params: {
                  search: objectSearchApi
                }
              });

              setVectorDataSearch(responseVectors.data.dataArray);
              setLoadingSearching(false);
            } else {
            }
          }, 500);
        } catch (err) {
          console.error(err);
        }
      })();
    } else {
      return;
    }
  }, [isSearching, objectSearchApi]);

  if (loading) {
    return (
      <LoadingContainer>
        <ContractAnimation />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      {isSeeAll && (
        <>
          {loadingObjects ? (
            <>
              <ContainerLoading>
                <ContractAnimation />
              </ContainerLoading>
            </>
          ) : (
            <>
              <Tooltip
                title="Pesquisar"
                description="Digite aqui para encontrar o que procura"
                placement="bottom-start"
              >
                <BoxSearch>
                  <img src={imageSearching} alt="" />

                  <div className="input-search-box">
                    <input
                      value={searchByCategory}
                      onFocus={() => setIsFocus(true)}
                      onChange={event =>
                        setSearchByCategory(event.target.value)
                      }
                      type="text"
                    />
                  </div>
                  {isFocus && (
                    <button>
                      <ButtonExit
                        inactivatedColor="#999C9F"
                        onClick={() => {
                          setSearchByCategory("");
                          setIsFocus(false);
                        }}
                      />
                    </button>
                  )}
                </BoxSearch>
              </Tooltip>

              <BackToAllElementsBox onClick={handleBackCategories}>
                <PersonalizedIcon
                  dPath={MountIcons.IconArrowLeft.dPath}
                  viewBox={MountIcons.IconArrowLeft.viewBox}
                  inactivatedColor="#999C9F"
                />
                <span> {nameChooseCategoryElement} </span>
              </BackToAllElementsBox>

              <ElementCategory>
                <GridElements columns={4}>
                  {dataSpecificElements?.map((item: any, index: number) => (
                    <GridItemElements
                      className="grid-items-shapes"
                      key={item.id}
                    >
                      <Tooltip
                        title="Arraste para usar"
                        placement="bottom-start"
                      >
                        <img
                          className="grabbing-vectors"
                          onDragEnd={(
                            event: React.DragEvent<HTMLImageElement>
                          ) => setIsGrab({ active: false })}
                          // className="icons-elements-sidebar"
                          alt={
                            item.is_filled
                              ? `iconFilled#@@#${Math.random().toString()}`
                              : `icon#@@#${Math.random().toString()}`
                          }
                          src={item.shape_url}
                          draggable
                          onDragStart={(
                            event: React.DragEvent<HTMLImageElement>
                          ) => {
                            let eventClone: MutableEvent = event.target;
                            eventClone.dpath = item.dpath;
                            dragUrl.current = event.target;
                            strokeWidth.current = String(item.stroke_width);
                          }}
                          id={item.id}
                        />
                      </Tooltip>
                    </GridItemElements>
                  ))}
                </GridElements>

                <ScrollInfinityBox id="sentinel">
                  <div
                    style={{
                      margin: "auto auto",
                      width: "30px",
                      height: "30px",
                      display: "flex"
                    }}
                  >
                    {hasMore && (
                      <ReactLoading
                        type="spin"
                        color="#000"
                        height={20}
                        width={20}
                      />
                    )}
                  </div>
                </ScrollInfinityBox>
              </ElementCategory>
            </>
          )}
        </>
      )}

      {!isSeeAll && (
        <Tooltip
          title="Pesquisar"
          description="Digite aqui para encontrar o que procura"
          placement="bottom-start"
        >
          <BoxSearch>
            <img src={imageSearching} alt="" />

            <div className="input-search-box">
              <input
                value={search}
                onChange={event => setSearch(event.target.value)}
                type="text"
                onFocus={() => setIsFocus(true)}
              />
            </div>

            {isFocus && (
              <button>
                <ButtonExit
                  inactivatedColor="#999C9F"
                  onClick={() => {
                    setSearch("");
                    setIsFocus(false);
                  }}
                />
              </button>
            )}
          </BoxSearch>
        </Tooltip>
      )}

      {!isSearching && !isSeeAll && (
        <ElementCategory>
          <Title>Formas básicas</Title>
          <GridElements columns={4}>
            {KonvaDropObject?.map((item: KonvaObjectProps, index: number) => (
              <GridItemElements key={item.alt}>
                <CardElements>
                  <ImageDrag
                    draggable
                    className={item.type}
                    grab={grab.active}
                    setIsGrab={setIsGrab}
                    imageSrc={item.src}
                    alt={item.alt}
                    index={index}
                    indexGrab={grab.index}
                  />
                </CardElements>
              </GridItemElements>
            ))}
          </GridElements>
        </ElementCategory>
      )}

      {!isSearching &&
        !isSeeAll &&
        iconsByCategory.map(shape => {
          return (
            shape?.shapes?.dataArray?.length > 0 && (
              <ElementCategory key={shape.id}>
                <SplitContainerBox>
                  <Title className="firstChild"> {shape?.category} </Title>

                  <div
                    onClick={() =>
                      handleSeeAllElements(shape?.id, shape?.category)
                    }
                  >
                    {shape?.shapes?.pagination > 0 && <h2>Ver tudo</h2>}
                  </div>
                </SplitContainerBox>
                <GridElements className="container-shapers" columns={4}>
                  {shape?.shapes?.dataArray &&
                    shape?.shapes?.dataArray?.map(shapeObject => (
                      <GridItemElements
                        className="grid-items-shapes"
                        key={shapeObject.id}
                      >
                        <Tooltip
                          title="Arraste para usar"
                          placement="bottom-start"
                        >
                          <img
                            className="grabbing-vectors"
                            onDragEnd={(
                              event: React.DragEvent<HTMLImageElement>
                            ) => setIsGrab({ active: false })}
                            // className="icons-elements-sidebar"
                            alt={
                              shapeObject.is_filled
                                ? `iconFilled#@@#${Math.random().toString()}`
                                : `icon#@@#${Math.random().toString()}`
                            }
                            src={shapeObject.shape_url}
                            draggable
                            onDragStart={(
                              event: React.DragEvent<HTMLImageElement>
                            ) => {
                              let eventClone: MutableEvent = event.target;
                              eventClone.dpath = shapeObject.dpath;
                              dragUrl.current = event.target;
                              strokeWidth.current = String(
                                shapeObject.stroke_width
                              );
                            }}
                            id={shapeObject.id}
                          />
                        </Tooltip>
                      </GridItemElements>
                    ))}
                </GridElements>
              </ElementCategory>
            )
          );
        })}

      {loadingSearching ? (
        <LoadingContainer>
          <ContractAnimation />
        </LoadingContainer>
      ) : (
        <GridElements className="container-shapers" columns={4}>
          {isSearching && vectorDataSearch.length > 0
            ? vectorDataSearch.map(shapeObject => (
                <GridItemElements className="grid-items-shapes">
                  <img
                    onDragEnd={(event: React.DragEvent<HTMLImageElement>) =>
                      setIsGrab({ active: false })
                    }
                    // className="icons-elements-sidebar"
                    alt={
                      shapeObject.is_filled
                        ? `iconFilled#@@#${Math.random().toString()}`
                        : `icon#@@#${Math.random().toString()}`
                    }
                    src={shapeObject.shape_url}
                    draggable
                    onDragStart={(event: React.DragEvent<HTMLImageElement>) => {
                      let eventClone: MutableEvent = event.target;
                      eventClone.dpath = shapeObject.dpath;
                      dragUrl.current = event.target;
                      strokeWidth.current = String(shapeObject.stroke_width);
                    }}
                    id={shapeObject.id}
                  />
                </GridItemElements>
              ))
            : isSearching && (
                <ContainerEmpty>
                  <div>
                    <span>Nenhum vetor foi encontrado.</span>
                  </div>
                </ContainerEmpty>
              )}
        </GridElements>
      )}
    </Container>
  );
};

export default ElementsKonva;
