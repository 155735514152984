import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;

  flex-direction: column;
  gap: 30px;
  padding: 0px 20px 20px 20px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

export const ButtonHeader = styled.button`
  background-color: ${({ theme }) => theme.colors.primary90};
  width: 201px;
  height: 40px;
  padding: 8px 16px;
  border-radius: 5px;

  display: flex;
  align-items: center;
  gap: 8px;

  font-family: "Ubuntu";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  color: ${({ theme }) => theme.colors.white100};

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ContainerLogin = styled.div`
  width: 100%;
  height: 528px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexPagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;

export const TextContent = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20rem;
`;

export const Text = styled.span`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.gray300};
  text-align: center;
  line-height: 2.7rem;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
