/* 
when user selected tab of clauses screen, then we bring all 
clauses and library texts that we have to offers to our customers
*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useModalContext } from "../../../../hooks/modalContext";
import api from "../../../../services/api";
import MoreOptionsIcon from "../../assets/someoptions-icon.svg";
import CloseOptionsIcon from "../../assets/close-icon.svg";
import { useAsidebarEditor } from "../../hooks/asidebarEditor";
import { useClausesEditor } from "../../hooks/clausesEditor";
import { ContainerSidebarNav } from "../Sidebar/components/ContainerSidebar/styles";
import { animate, useMotionValue } from "framer-motion";
import html2canvas from "html2canvas";
import { useMainHook } from "../../../../hooks/main";
import cloneDeep from "lodash/cloneDeep";
import { useDiff } from "../../hooks/diffDocuments";
import { useSelection } from "../../hooks/selection";

//set up interfaces
import {
  BankOfClausesProps,
  CategoriesProps,
  ClauseObjectProps,
  ClausesDataProps,
  IClausesViewProps,
  MoreOptionsButtonProps,
  ParamsCategoriesProps,
  QueryBankProps,
  QueryProps
} from "./dtos";

//import main components
import MenuClauses from "./MenuClauses";
import UserClauses from "./UserClauses";
import BitsClauses from "./BitsClauses";
import TeamsClauseView from "./TeamsClauseView";

const ClausesView: React.FC<IClausesViewProps> = ({
  isActive,
  handleActiveButton
}) => {
  //setting hooks are necessary
  const { visibilityIconsOptionsClauses } = useAsidebarEditor();
  const {
    quillRenderImage,
    setClausesObject,
    draggClauseRef,
    clausesObject,
    sourceClause,
    sizeQuillClause,
    dragging,
    setDragging,
    clausesData,
    setClausesData,
    addedClause
  } = useClausesEditor();

  const { setSelectedObject } = useSelection();
  const { whichUserEdited } = useDiff();
  const { handleOpenEditClauseModal, handleShowClausule } = useModalContext();
  const {
    handleOpenNewClausesModal,
    refreshClause,
    setIsOpenModalDeleteClause,
    setIdClause
  } = useModalContext();
  //set up all states
  const [loading, setLoading] = useState(true);
  const [isActiveBoxCategories, setIsActiveBoxCategories] = useState(false);
  const [currentPageCategories, setCurrentPageCategories] = useState(0);
  const [limitPageCategories, setLimitPageCategories] = useState(0);
  const [isActiveBoxUserCategories, setIsActiveBoxUserCategories] =
    useState(false);
  const [dataCategories, setDataCategories] = useState<CategoriesProps[]>([]);
  const [dataCategoriesUser, setDataCategoriesUser] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredFavorites, setFilteredFavorites] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [focusInput, setFocusInput] = useState(false);
  const [showFilter, setShowFilter] = useState("");
  const [showFilterBits, setShowFilterBits] = useState("");
  const [isFavoriteFilter, setIsFavoriteFilter] = useState(false);
  const [currentPageBits, setCurrentPageBits] = useState(0);
  const [isFavoriteFilterBits, setIsFavoriteFilterBits] = useState(false);
  const [loadingBank, setLoadingBank] = useState(true);
  const [paramsCategories, setParamsCategories] = useState({
    pagination: currentPageCategories,
    filter: "all"
  } as ParamsCategoriesProps);
  const [loadingUser, setLoadingUser] = useState(false);
  const [forRefresh, setForRefresh] = useState(false);
  const [paramsCategoriesUser, setParamsCategoriesUser] = useState({
    filter: "all"
  });
  const [bankOfClausesData, setBankOfClausesData] = useState<
    BankOfClausesProps[]
  >([]);

  const { dragUrl, nameOfCurrentUser, hasGroupShared } = useMainHook();

  //reset current page when user change tab
  useEffect(() => {
    if (isActive === "MyLibrary") {
      setCurrentPage(0);
    }
  }, [isActive]);

  //when user clicked on categories button on bits clause
  const handleBoxCategories = useCallback(() => {
    setFocusInput(!focusInput);
    setIsActiveBoxCategories(!isActiveBoxCategories);
  }, [isActiveBoxCategories, focusInput]);

  //when user clicked on categories button on bits clause
  const handleActiveBoxCategories = useCallback(() => {
    setFocusInput(!focusInput);
    setIsActiveBoxCategories(!isActiveBoxCategories);
  }, [isActiveBoxCategories, focusInput]);

  //reset selected object when input was focused
  useEffect(() => {
    if (focusInput) {
      setSelectedObject(null);
    }
  }, [focusInput]);

  //set up refs to user and bits clause
  //to detect when outside click these components
  const refComponentBoxUser = useRef(null);
  const refComponentBitsClauses = useRef(null);

  //when user clicked outside categories box in user tab
  document.addEventListener("mousedown", event => {
    if (refComponentBoxUser.current?.contains(event?.target)) {
      //if user was inside only return, not will be happened.
      return;
    } else {
      //when click outside, just close modal
      if (isActiveBoxUserCategories) {
        setIsActiveBoxUserCategories(false);
      }
    }
  });
  //when user clicked outside categories box in bits tab
  document.addEventListener("mousedown", event => {
    if (refComponentBitsClauses.current?.contains(event?.target)) {
      //if user was inside only return, not will be happened.
      return;
    } else {
      if (isActiveBoxCategories) {
        //when click outside, just close modal
        setIsActiveBoxCategories(false);
      }
    }
  });

  //when page is load or params changes,
  //trigger that to fetch categories list of key words
  //about users tab categories
  useEffect(() => {
    const fetchCategoriesUser = async () => {
      try {
        const responseCategoriesUser = await api.get(
          "user-clause/list-array-key-words",
          {
            params: paramsCategoriesUser
          }
        );
        setDataCategoriesUser(oldState => {
          return [...responseCategoriesUser.data.key_words];
        });
      } catch (err) {
        console.error(err, "error when fetch categories user");
      }
    };

    fetchCategoriesUser();
  }, [isActiveBoxUserCategories, paramsCategoriesUser]);

  //when user wanna open box user categories (handle click)
  const handleOpenBoxUserCategories = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setFocusInput(!focusInput);
      setIsActiveBoxUserCategories(!isActiveBoxUserCategories);
    },
    [isActiveBoxUserCategories, focusInput, dataCategoriesUser]
  );
  //when user wanna close box user categories (handle click)
  const handleCloseBoxUserCategories = () => {
    setIsActiveBoxUserCategories(false);
    setFocusInput(false);
  };

  //when click on to favorite clause bits
  const handleFavoriteClauseBits = useCallback(
    async (id: string, object: BankOfClausesProps, index: number) => {
      let optionBoolean = object.is_favorite;

      if (object.is_favorite) {
        try {
          await api.delete(`user-favorite-bits-clause/${id}`);
          setForceUpdate(!forceUpdate);
        } catch (err) {
          console.error(err, "error when unfavorite one clause ");
        }
      } else if (!object.is_favorite) {
        try {
          await api.post("user-favorite-bits-clause", {
            bits_clause_id: id
          });
          setForceUpdate(!forceUpdate);
        } catch (err) {
          console.error(err, "error when favorite one clause as favorite");
        }
      }

      setBankOfClausesData(state => {
        let cloneState = cloneDeep(state);
        cloneState[index].is_favorite = !optionBoolean;
        return cloneState;
      });
    },
    [refreshClause, forceUpdate, bankOfClausesData]
  );
  const [newPageClause, setNewPageClause] = useState<ClausesDataProps[]>([]);
  const [existsNewPage, setExistsNewPage] = useState(false);
  const [runClause, setRunClause] = useState(false);
  const [newPageClauseBits, setNewPageClauseBits] = useState<
    ClausesDataProps[]
  >([]);
  const [existsNewPageBits, setExistsNewPageBits] = useState(false);
  const [objectSpecifClause, setObjectSpecifClause] =
    useState<ClauseObjectProps>({
      user_clause_id: "",
      title: "",
      clause: "",
      description: "",
      is_favorite: false
    });

  const [query, setQuery] = useState<QueryProps>({
    pagination: 0,
    search: "",
    filter: "",
    search_key_word: ""
  });

  const [isActiveOptions, setActiveOptions] = useState<MoreOptionsButtonProps>({
    index: 0,
    active: false
  });

  const handleActiveMoreOptions = (active, index) => {
    setActiveOptions({
      active,
      index
    });
  };

  //when click callback to remove clause
  //send this one id to delete method endpoint
  //when substitle the state without these id
  const handleRemoveClause = useCallback(
    async (id: string) => {
      try {
        const responseRemoveClause = await api.delete(`user-clause/${id}`);
        // setRefreshClause(!refreshClause);
        setClausesData(state => {
          let cloneState = [...state];
          let newArray = cloneState.filter(item => item.id !== id);
          return newArray;
        });
      } catch (err) {
        console.error(err, "error when remove clause");
      }
    },
    [refreshClause, clausesData]
  );

  //just handle when user click in user clause
  //handle favorite clause by id and edit him and then update our main state
  const handleFavoriteClause = useCallback(
    async (id: string, index?: number) => {
      let title: string = null;
      let clause: string = null;
      let description: string = null;
      let is_favorite: boolean = false;

      try {
        const responseObjectSpecifClause = await api.get(`user-clause/${id}`);
        setObjectSpecifClause(state => {
          let cloneState = { ...state };
          cloneState = responseObjectSpecifClause.data;
          return cloneState;
        });

        title = responseObjectSpecifClause.data.title;
        clause = responseObjectSpecifClause.data.clause;
        description = responseObjectSpecifClause.data.description;
        is_favorite = responseObjectSpecifClause.data.is_favorite;
      } catch (err) {
        console.error(err, "error when transform clause in favorite");
      }
      try {
        await api.put("user-clause", {
          user_clause_id: id,
          title: title,
          clause: clause,
          description: description,
          is_favorite: !is_favorite
        });

        setClausesData(state => {
          let cloneState = [...state];
          cloneState[index].is_favorite = !is_favorite;
          return cloneState;
        });
      } catch (err) {
        console.error(err, "error update favorite clause");
      }
    },
    [objectSpecifClause, refreshClause, query, clausesData]
  );

  //verifing if next page exists.
  //only add one in our currentpage and save the result
  useEffect(() => {
    const autoNextPage = async () => {
      setTimeout(async () => {
        try {
          const responseNextPage = await api.get("user-clause", {
            params: {
              pagination: currentPage + 1,
              search: query?.search === "" ? null : query?.search,
              filter: query?.filter === "" ? null : query?.filter,
              search_key_word:
                query?.search_key_word === "" ? null : query?.search_key_word
            }
          });

          if (responseNextPage.status === 200) {
            setNewPageClause(responseNextPage.data.dataArray);
          }
        } catch (err) {
          console.error(err);
        }
      }, 2000);
    };

    autoNextPage();
  }, [currentPage, query]);
  //apply in state if exist newPageClause for true
  useEffect(() => {
    if (newPageClause.length > 0) {
      setExistsNewPage(true);
    } else if (newPageClause.length === 0) {
      setExistsNewPage(false);
    }
  }, [newPageClause]);
  //set pagination query with currentPage with base infinity scroll logic
  useEffect(() => {
    setQuery(state => {
      return {
        ...state,
        pagination: currentPage
      };
    });
  }, [currentPage, isActive]);
  //if favorites, set 0 currentpage
  useEffect(() => {
    if (filteredFavorites) {
      setCurrentPage(0);
    }
  }, [filteredFavorites]);
  //setting main state of myclauses logic
  useEffect(() => {
    const autoLoadClauses = async () => {
      try {
        setLoadingUser(true);
        const responseClauses = await api.get("user-clause", {
          params: {
            pagination:
              query?.search === "" && query?.filter === ""
                ? query?.pagination
                : 0,
            search: query?.search === "" ? null : query?.search,
            filter: query?.filter === "" ? null : query?.filter,
            search_key_word:
              query?.search_key_word === "" ? null : query?.search_key_word
          }
        });
        const newClauses = responseClauses.data.dataArray;
        setClausesData(prevState => {
          if (
            query.pagination !== 0 &&
            query.filter === "" &&
            query.search === "" &&
            query.search_key_word === ""
          ) {
            return [...prevState, ...newClauses];
          }
          let cloneState = [...newClauses];
          return cloneState;
        });

        setLoading(false);
      } catch (err) {
        console.error(err, "error when fetch api clauses route");
      }
    };

    autoLoadClauses();
  }, [query, isActive, addedClause]);

  //useEffect for get categories
  //using pagination and filters posibilities to fetch
  //bits clause categories
  useEffect(() => {
    const autoLoadCategories = async () => {
      try {
        const responseCategories = await api.get("bits-clause-categories", {
          params: {
            pagination: currentPageCategories,
            filter: paramsCategories.filter
          }
        });

        //if exists add total of pages categories pagination

        setLimitPageCategories(responseCategories.data.pagination);

        if (paramsCategories.filter === "all" && currentPageCategories > 0) {
          setDataCategories(oldState => {
            let cloneState = cloneDeep(oldState);
            return [...cloneState, ...responseCategories.data.dataArray];
          });
        } else if (
          paramsCategories.filter !== "all" &&
          currentPageCategories === 0
        ) {
          setCurrentPageCategories(0);
          setDataCategories(responseCategories.data.dataArray);
        } else if (
          paramsCategories.filter !== "all" &&
          currentPageCategories > 0
        ) {
          setDataCategories(oldState => {
            let cloneState = cloneDeep(oldState);
            return [...cloneState, ...responseCategories.data.dataArray];
          });
        } else if (
          paramsCategories.filter === "all" &&
          currentPageCategories === 0
        ) {
          setCurrentPageCategories(0);
          setDataCategories(responseCategories.data.dataArray);
        }
      } catch (err) {
        console.error(err, "ERROR WHEN GET CATEGORIES");
      }
    };

    autoLoadCategories();
  }, [paramsCategories, currentPageCategories]);

  //when clause drag and drop
  //send to global variable with the command "richTextClause"
  //and second params of our splited text variable are id
  //and then apply these changes in our main state
  const handleGenerateClause = useCallback(
    async (
      value: string,
      event: React.DragEvent<HTMLDivElement>,
      index: number,
      keyWords: string
    ) => {
      let generateId = uuidv4();
      dragUrl.current = `richTextClause#@@#${generateId}`;
      //verification for don`t get other clause in UI inside of move of user
      if (runClause) return;
      setRunClause(true);
      setDragging({ active: true, index: index });
      quillRenderImage.current.getEditor().root.style.padding = "0px";
      quillRenderImage?.current?.getEditor().setText(value);
      //if has group shared users then apply review of text
      if (hasGroupShared.current) {
        const myContents = quillRenderImage.current.getEditor().getContents();
        //and new attributes to text, all changes have color #9945EE
        myContents.forEach(op => {
          op.attributes = {
            color: "#9945EE",
            size: "12px",
            font: "Roboto",
            align: "justify"
          };
        });
        //invoke to main ref quill js
        //important to generate the image for api
        quillRenderImage.current.getEditor().setContents(myContents);
      } else {
        const myContents = quillRenderImage.current.getEditor().getContents();
        //and new attributes to text, all changes have color #9945EE
        myContents.forEach(op => {
          op.attributes = {
            size: "12px",
            font: "Roboto",
            align: "justify"
          };
        });
        //invoke to main ref quill js
        //important to generate the image for api
        quillRenderImage.current.getEditor().setContents(myContents);
      }
      //render that content with html2canvas to generate image
      const canvas = await html2canvas(
        quillRenderImage.current.getEditor().root,
        {
          backgroundColor: "rgba(0,0,0,0)"
        }
      );

      //so if the image as already t  hen change state with src
      if (canvas) {
        setClausesObject(prevState => {
          let cloneState = cloneDeep(prevState);
          return {
            ...cloneState,
            text: value,
            src: canvas.toDataURL(),
            keyWords: keyWords,
            format: hasGroupShared.current
              ? {
                  ops: [
                    {
                      insert: `${value}\n`,
                      attributes: {
                        color: "#9945EE",
                        size: "12px",
                        font: "Roboto",
                        align: "justify"
                      }
                    }
                  ]
                }
              : {
                  ops: [
                    {
                      attributes: {
                        size: "12px",
                        font: "Roboto",
                        align: "justify"
                      },
                      insert: `${value}\n`
                    }
                  ]
                },
            initialDelta: hasGroupShared.current
              ? {
                  ops: [
                    {
                      insert: `${value}\n`,
                      attributes: {
                        color: "#9945EE"
                      }
                    }
                  ]
                }
              : "",

            isModify: hasGroupShared.current ? true : null,
            nameOfUserEdited: hasGroupShared.current
              ? nameOfCurrentUser.current
              : "",
            idOfUserEdited: hasGroupShared.current
              ? whichUserEdited.current
              : "",
            isSharedNewText: true
          };
        });

        draggClauseRef.current = canvas.toDataURL();
        setRunClause(false);
      }
    },
    [
      clausesObject,
      sourceClause,
      draggClauseRef,
      quillRenderImage,
      sizeQuillClause,
      dragging,
      refreshClause,
      hasGroupShared
    ]
  );
  //define motion value with name as x to
  //control motion framer animation
  const x = useMotionValue(0);

  //add type spring and stiffness in animation,
  //define moment to animete and stop
  useEffect(() => {
    const controls = animate(x, 100, {
      type: "spring",
      stiffness: 2000
    });

    return controls.stop;
  },[]);

  //to observe when user to arrive on end scrollview and then
  //we trigger next page to help us to create infinity scroll
  useEffect(() => {
    if (
      !loading &&
      existsNewPage &&
      isActive === "MyLibrary" &&
      !filteredFavorites
    ) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          setCurrentPage(prevState => prevState + 1);
        }
      });
      intersectionObserver?.observe(document?.querySelector("#sentinela"));
      //clean up use effect
      return () => intersectionObserver?.disconnect();
    }
  }, [loading, existsNewPage, isActive]);

  //when button of favorite is true then
  //change state with params to favorite
  //to send new request to endpoint
  useEffect(() => {
    setShowFilter(query.search_key_word);
    if (query.filter === "favorite") {
      setIsFavoriteFilter(true);
    } else {
      setIsFavoriteFilter(false);
    }
  }, [query]);

  return (
    <ContainerSidebarNav>
      <MenuClauses
        isActive={isActive}
        setIsActiveBoxCategories={setIsActiveBoxCategories}
        handleActiveButton={handleActiveButton}
        setIsActiveBoxUserCategories={setIsActiveBoxUserCategories}
      />
      {isActive === "MyLibrary" ? (
        // My Clauses
        <UserClauses
          setIsOpenModalDeleteClause={setIsOpenModalDeleteClause}
          setIdClause={setIdClause}
          isActiveBoxUserCategories={isActiveBoxUserCategories}
          refComponentBoxUser={refComponentBoxUser}
          query={query}
          setQuery={setQuery}
          focusInput={focusInput}
          setFocusInput={setFocusInput}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          filteredFavorites={filteredFavorites}
          setFilteredFavorites={setFilteredFavorites}
          forRefresh={forRefresh}
          setForRefresh={setForRefresh}
          handleCloseBoxUserCategories={handleCloseBoxUserCategories}
          dataCategoriesUser={dataCategoriesUser}
          paramsCategoriesUser={paramsCategoriesUser}
          setParamsCategoriesUser={setParamsCategoriesUser}
          handleOpenBoxUserCategories={handleOpenBoxUserCategories}
          loading={loading}
          showFilter={showFilter}
          isFavoriteFilter={isFavoriteFilter}
          handleGenerateClause={handleGenerateClause}
          isActiveOptions={isActiveOptions}
          handleFavoriteClause={handleFavoriteClause}
          handleOpenEditClauseModal={handleOpenEditClauseModal}
          handleRemoveClause={handleRemoveClause}
          handleActiveMoreOptions={handleActiveMoreOptions}
          CloseOptionsIcon={CloseOptionsIcon}
          MoreOptionsIcon={MoreOptionsIcon}
          visibilityIconsOptionsClauses={visibilityIconsOptionsClauses}
          existsNewPage={existsNewPage}
          handleOpenNewClausesModal={handleOpenNewClausesModal}
          handleShowClausule={handleShowClausule}
        />
      ) : isActive === "CompanyLibrary" ? (
        // Bank of Clauses
        <BitsClauses
          isActiveBoxCategories={isActiveBoxCategories}
          refComponentBitsClauses={refComponentBitsClauses}
          focusInput={focusInput}
          setFocusInput={setFocusInput}
          currentPageBits={currentPageBits}
          setCurrentPageBits={setCurrentPageBits}
          setParamsCategories={setParamsCategories}
          paramsCategories={paramsCategories}
          dataCategories={dataCategories}
          handleBoxCategories={handleBoxCategories}
          setCurrentPageCategories={setCurrentPageCategories}
          currentPageCategories={currentPageCategories}
          setIsFavoriteFilterBits={setIsFavoriteFilterBits}
          isFavoriteFilterBits={isFavoriteFilterBits}
          showFilterBits={showFilterBits}
          handleActiveBoxCategories={handleActiveBoxCategories}
          setLimitPageCategories={setLimitPageCategories}
          limitPageCategories={limitPageCategories}
          loadingBank={loadingBank}
          bankOfClausesData={bankOfClausesData}
          existsNewPageBits={existsNewPageBits}
          handleFavoriteClauseBits={handleFavoriteClauseBits}
          handleGenerateClause={handleGenerateClause}
          setCurrentPage={setCurrentPage}
          isActive={isActive}
          setBankOfClausesData={setBankOfClausesData}
          setShowFilterBits={setShowFilterBits}
          setLoadingBank={setLoadingBank}
          newPageClauseBits={newPageClauseBits}
          setExistsNewPageBits={setExistsNewPageBits}
          setNewPageClauseBits={setNewPageClauseBits}
        />
      ) : (
        <TeamsClauseView />
      )}
    </ContainerSidebarNav>
  );
};

export default ClausesView;
