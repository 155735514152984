import React from "react";
import appleImg from "../../assets/download_apple.png";
import androidImg from "../../assets/download_play.png";
import QRCode from "react-qr-code";

import { ViewPlatformQRCode, QRCodeBox } from "./styles";
type Props = {
  url: string;
  platform?: "iOS" | "Android";
};

const GeneratorQRCode: React.FC<Props> = ({ url, platform }) => {
  return (
    <ViewPlatformQRCode>
      {platform && (
        <img src={platform === "iOS" ? appleImg : androidImg} alt="" />
      )}
      <QRCodeBox>
        <QRCode size={162} value={url} viewBox={`0 0 162 162`} />
      </QRCodeBox>
    </ViewPlatformQRCode>
  );
};

export default GeneratorQRCode;

