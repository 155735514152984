import styled from "styled-components";

export const Container = styled.div<{ isActive: boolean }>`
  width: 145px;
  min-height: 24px;
  border-radius: 5px;
  border: ${({ isActive }) =>
    !isActive
      ? "1px solid var(--black-black-50, #999c9f)"
      : "1.7px solid var(--outros-focus, #5D5FEF)"};
  background: var(--other-white, #fff);
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  align-items: center;
  position: relative;
  transition: all linear 50ms;
  outline: ${({ isActive }) => (!isActive ? "" : "#5D5FEF")};

  :hover {
    border: 1.7px solid #5d5fef;
  }

  span {
    color: #343a40;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
  }
`;

export const MenuBoxFontFamily = styled.div`
  position: absolute;
  top: 46px;
  left: 16px;
  background-color: #fff;
  width: 145px;
  max-height: 188px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 5px 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  div {
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 24px;
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: transparent;

    :hover {
      background-color: #f5f5f5;
    }
  }

  span {
    color: #343a40;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin: 5px 0;
  }
`;

export const FontType = styled.span<{ font: string }>`
  color: #343a40;
  font-family: ${({ font }) => font && `${font}`};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  margin: 5px 0;
`;
