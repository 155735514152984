import React, { useEffect, useRef, useState } from "react";
import { Modal } from "./styles";
import { Document, pdfjs, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import { useSign } from "../../../../../hooks/sign";
import api from "../../../../../services/api";
import { toast } from "react-toastify";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// import { Container } from './styles';
interface GenerateThumbProps {
  isActive: boolean;
  url?: string;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const GenerateThumb: React.FC<GenerateThumbProps> = ({
  isActive,
  setIsActive,
 
}) => {
  const {
    urlPdf,
    idPdfFile,
    setUploadedFile,
    setSignerData,
    setUrlPdf,
    setOpenModal
  } = useSign();

  const documentRef = useRef<HTMLDivElement>(null);

  function onDocumentLoadSuccess() {
    setTimeout(() => {
      generateImageOrPDF();
    }, 6000);
  }

  function base64toBlob(base64String: string): Blob {
    const byteString = atob(base64String);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }
    const mimeString = "image/jpeg";
    return new Blob([arrayBuffer], { type: mimeString });
  }

  async function generateImageOrPDF() {
    const targetElement = documentRef.current;

    if (!targetElement) {
      return;
    }

    try {
      const canvas = await html2canvas(targetElement);
      const image = canvas.toDataURL("image/png");
      const base64WithoutPrefix = image.replace(
        /^data:image\/(png|jpeg|jpg);base64,/,
        ""
      );
      const blob = base64toBlob(base64WithoutPrefix);
      const file = new File([blob], "filename2.jpg", { type: "image/jpeg" });
      const formData = new FormData();
      formData.append("thumbnail", file);

      const makeThumbnail = await api.patch(
        `signatures/thumbnail-upload/${idPdfFile.current}`,
        formData
      );

      toast.success("Documento para assinatura criado com sucesso.");
      setUploadedFile(null);
      setSignerData([]);
      setUrlPdf("");
      setIsActive(false);
      setOpenModal({
        isOpen: false,
        modalName: ""
      });
    } catch (err) {
      toast.success("Documento para assinatura criado com sucesso.");
      setUploadedFile(null);
      setSignerData([]);
      setUrlPdf("");
      setIsActive(false);
      setOpenModal({
        isOpen: false,
        modalName: ""
      });
      console.error(err, "ERRO thumbnail patch");
    }
  }

  if (!isActive) {
    return <></>;
  } else {
    return (
      <Modal>
        <div ref={documentRef}>
          <Document onLoadError={(error) => {
            toast.warn("A imagem de thumbnail falhou.");
            setUploadedFile(null);
            setSignerData([]);
            setUrlPdf("");
            setIsActive(false);
            setOpenModal({
              isOpen: false,
              modalName: ""
            });
          }} file={`data:image/jpeg;base64,${urlPdf}`} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={1} scale={1} />
          </Document>
        </div>
      </Modal>
    );
  }
};

export default GenerateThumb;
