/* 
when user selected tab of templates, then we bring all 
templates that we have to offers to our customers
*/

import React from "react";
import { ScrollTemplates } from "../ScrollTemplates";
import { ContainerSidebarNav } from "../Sidebar/components/ContainerSidebar/styles";
import { VerticalStack, WrapperButtonFeedback } from "./styles";
//set up interfaces
interface ITemplatesViewProps {
  search?: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
}

const TemplatesView: React.FC<ITemplatesViewProps> = () => {
  return (
    <ContainerSidebarNav scroll={"hidden"}>
      <VerticalStack>
        <ScrollTemplates text="Tipo de contrato" />
        <WrapperButtonFeedback>
          <button
            onClick={() =>
              window.open("https://bitsacademy.typeform.com/templateuxdoc")
            }
          >
            Não encontrou o que queria? Deixe a sua sugestão.
          </button>
        </WrapperButtonFeedback>
      </VerticalStack>
    </ContainerSidebarNav>
  );
};

export default TemplatesView;
