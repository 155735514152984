import React from "react";

import { ViewMainContent, Text, ContainerMain } from "./styles";
import GeneratorQRCode from "../GeneratorQRCode";
import {
  PATH_GOOGLE_AUTH_IOS,
  PATH_GOOGLE_AUTH_PLAY_STORE,
} from "../../constants/stepsSecure";

const LearnTwoFactor: React.FC = () => {
  return (
    <ContainerMain>
      <Text>
        Escaneie o QR code e baixe gratuitamente o aplicativo{`\n`}
        <span> Google Authenticator.</span>
      </Text>
      <ViewMainContent>
        <GeneratorQRCode platform="iOS" url={PATH_GOOGLE_AUTH_IOS} />
        <GeneratorQRCode platform="Android" url={PATH_GOOGLE_AUTH_PLAY_STORE} />
      </ViewMainContent>
    </ContainerMain>
  );
};

export default LearnTwoFactor;

