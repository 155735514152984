import React, { useEffect, useState } from "react";
import { format } from "date-fns-tz";
import ptBR from "date-fns/locale/pt-BR";
import {
  CardImage,
  CardInfos,
  Container,
  Heading,
  Text,
  SimpleItalicText,
  TextBlack,
  VStack,
  HStack,
  HStackStar
} from "./styles";
import DropdownCheck from "../../../../DropdownCheck";
import { useVersioning } from "../../../../../hooks/versioning";
import { IoMdRefresh } from "react-icons/io";
import starIcon from "../../../../../assets/star-icon.svg";

interface CardVersionProps {
  card?: string;
  date?: string;
  hours?: number | Date | null | undefined;
  version?: string;
  user?: string;
  description?: string;
  template?: any;
  idTemplateMain?: string;
  currentTemplateInfo?: any;
  thumbnailUrl?: string;
  first_name_creator?: string;
  last_name_creator?: string;
}

export const CardVersion: React.FC<CardVersionProps> = ({
  card,
  date,
  hours,
  version,
  user,
  description,
  template,
  idTemplateMain,
  currentTemplateInfo,
  thumbnailUrl
}) => {
  const { setIsRevert, isRevert } = useVersioning();

  return (
    <Container style={{ paddingBottom: "2rem" }}>
      <CardImage srcImg={thumbnailUrl}></CardImage>
      <CardInfos>
        <VStack>
          <HStackStar>
            <Heading>
              {date
                ? format(Date.parse(date), " dd/MM/yyyy' - ' HH':'mm", {
                    locale: ptBR,
                    timeZone: "America/Sao_Paulo"
                  })
                : ""}
            </Heading>
            {isRevert.id === idTemplateMain && isRevert.revert && (
              <img src={starIcon} />
            )}
          </HStackStar>
          <Text>{`Versão ${version}`}</Text>

          <SimpleItalicText> {`Criado por: ${user}`} </SimpleItalicText>
          <TextBlack>{`Descrição: ${description}`}</TextBlack>
        </VStack>
        <HStack>
          <DropdownCheck
            idTemplateMain={idTemplateMain}
            dataTemplate={template}
            info={currentTemplateInfo}
          />
        </HStack>
      </CardInfos>
    </Container>
  );
};
