import { cloneDeep } from "lodash";
import { ILinesPage } from "../../../../hooks/pagesEditor";
import { Container, FirsLine, SecondLine } from "./styled";

interface IGuidelineHProps {
  setLinesPage?: React.Dispatch<React.SetStateAction<ILinesPage[]>>;
  position: { top: number; left: number };
  id?: string;
  isActive: boolean;
  thisIndexObject: number;
  handleMouseMoveLineGuide: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    page: any
  ) => void;
  pageNumber: number;
}

const GuidelineH = ({
  position,
  setLinesPage,
  id,
  isActive,
  thisIndexObject,
  handleMouseMoveLineGuide,
  pageNumber,
}: IGuidelineHProps) => {
  return (
    <Container style={position}>
      <FirsLine
        isActive={isActive}
        onMouseDown={(e) => {
          if (!isActive) {
            handleMouseMoveLineGuide(e, pageNumber);
            setLinesPage((prevState) => {
              let newState = cloneDeep(prevState);
              let newLineGuidH = newState[thisIndexObject].lineGuidH?.filter(
                (line) => line.id !== id
              );

              newState[thisIndexObject] = {
                ...newState[thisIndexObject],
                lineGuidH: newLineGuidH,
                islineH: true,
              };

              return newState;
            });
          }
        }}
      >
        <div>
          <span className="vector-up" />
          <span className="vector-dow" />
        </div>
      </FirsLine>
      <SecondLine />
    </Container>
  );
};

export default GuidelineH;
