import starFilledIcon from "../../../../../shared/assets/star-filled.svg";
import { Container, TitleItem, OptionsItem } from "./styles";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import React, { useState } from "react";
import { useTheme } from "../../../../../hooks/theme";
import OptionsModal from "../../components/OptionsModal";
import { PagesProps } from "../../../../../dtos/PagesProps";

interface MyAutomationsProps {
  id: string;
  user_id: string;
  title: string;
  description?: string;
  thumbnail: string;
  is_automation: boolean;
  automation_user_template_id: string;
  created_at: string;
  updated_at: string;
  thumbnail_url: string;
  is_favorite?: boolean;
}

interface IAutomationItemProps {
  item: MyAutomationsProps;
  handlePreviewDocumentAutomation: (templateId: string) => Promise<void>;
  handleOpenAutomation: (id: string) => Promise<void>;
  handleDeleteAutomation: (id: string) => Promise<void>;
  handleDateConvert: (data: string) => string[];
  setTemplateId: React.Dispatch<React.SetStateAction<string>>;
  isFavorite: boolean;
  handleFavoriteAutomation: (automationId: any) => Promise<void>;
  downloading: boolean;
  setDownloading: React.Dispatch<React.SetStateAction<boolean>>;
  idToastifyDownloadRef: any;
  whichFileToBeDownload: React.MutableRefObject<"pdf" | "word">;
  documentPreviewData: React.MutableRefObject<PagesProps[]>;
  setIsIntentBuyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AutomationItem = ({
  item,
  handlePreviewDocumentAutomation,
  handleOpenAutomation,
  handleDeleteAutomation,
  handleDateConvert,
  setTemplateId,
  isFavorite,
  handleFavoriteAutomation,
  downloading,
  setDownloading,
  idToastifyDownloadRef,
  documentPreviewData,
  whichFileToBeDownload,
  setIsIntentBuyModal
}: IAutomationItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();
  const [isVisibleOption, setIsVisibleOption] = useState(false);

  return (
    <Container
      onMouseEnter={() => setIsVisibleOption(true)}
      onMouseLeave={() => setIsVisibleOption(false)}
    >
      <TitleItem>
        {isFavorite ? (
          <div
            onClick={() => {
              handleFavoriteAutomation(item.id);
            }}
          >
            <PersonalizedIcon
              dPath={MountIcons.IconStars.dPath}
              viewBox={MountIcons.IconStars.viewBox}
              inactivatedColor={theme.colors.primary}
            />
          </div>
        ) : (
          <img
            src={starFilledIcon}
            alt=""
            onClick={() => {
              handleFavoriteAutomation(item.id);
            }}
          />
        )}
        <p
          onClick={() => {
            setTemplateId(item.id);
            handlePreviewDocumentAutomation(item.id);
          }}
        >
          {item.title}
        </p>
      </TitleItem>
      <p>{handleDateConvert(item.created_at)}</p>
      {isVisibleOption && (
        <OptionsItem
          onClick={() => setIsOpen(!isOpen)}
          onMouseLeave={() => setIsOpen(false)}
          isActive={isOpen}
        >
          <span />
          <span />
          <span />
          {isOpen && (
            <OptionsModal
              downloading={downloading}
              setDownloading={setDownloading}
              idToastifyDownloadRef={idToastifyDownloadRef}
              documentPreviewData={documentPreviewData}
              whichFileToBeDownload={whichFileToBeDownload}
              handleOpenAutomation={handleOpenAutomation}
              handleDeleteAutomation={handleDeleteAutomation}
              id={item.id}
              setIsIntentBuyModal={setIsIntentBuyModal}
            />
          )}
        </OptionsItem>
      )}
    </Container>
  );
};

export default AutomationItem;
