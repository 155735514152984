import { IoMdAlert } from "react-icons/io";
import { Container } from "./styles";

const AlertError = ({ dataError, ...rest }) => {
  return (
    <Container {...rest}>
      {!!dataError && (
        <div>
          <p>
            <IoMdAlert /> {dataError}
          </p>
        </div>
      )}
    </Container>
  );
};

export default AlertError;
