import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HorizontalStack = styled.div`
  display: flex;
  width: 626px;
  height: 50px;
  /* background-color: red; */
  justify-content: space-between;
  align-items: center;

  padding-left: 3rem;

  user-select: none;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;

  transition: all 2s ease-in-out;

  .btn-page {
    width: 30px;
    height: 30px;

    svg {
      width: 24px;
      height: 24px;
    }

    :hover {
      background-color: #ebebec;
      border-radius: 5px;
    }
  }
`;

export const RulerVertical = styled.div<{ isActive?: boolean }>`
  width: 30px;
  height: 100%;
  background-color: transparent;
  position: absolute;
  left: 66.5px;
  top: 42px;
  height: 95.8%;
  display: flex;
  justify-content: flex-end;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;

  img {
    width: 100%;
  }
`;

