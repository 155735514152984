import React, { useEffect, useState } from "react";
import { Container } from "./style";
import { Sidebar } from "./components/Sidebar";
import { Outlet } from "react-router-dom";

import { HeaderProfile } from "./components/HeaderProfile";
import { ToastContainer } from "react-toastify";

import ModalSetup2FA from "../../components/ModalSetup2FA";
import { useTwoFactorAuth } from "../../../../../hooks/twoFactorAuth";

import ModalDisable2FA from "./components/ModalDisable2FA";
import TwoFactorAuthentication from "../../components/TwoFactorAuthentication";
import { useAuth } from "../../../../../hooks/auth";

const ProfilePage: React.FC = () => {
  const {
    modalTwoFactor,
    isDisable2FA,
    setIsDisable2FA,
    isAuthenticatedOTP,
    isUserValidateOTP,
    setIsValidateOTP,
  } = useTwoFactorAuth();

  const { data } = useAuth();

  useEffect(() => {
    document.title = `UX DOC | Perfil`;
  }, []);

  useEffect(() => {
    if (isAuthenticatedOTP) {
      setIsValidateOTP(true);
    }
  }, [isAuthenticatedOTP]);

  useEffect(() => {
    setIsValidateOTP(false);
  }, []);

  return (
    <>
      <ToastContainer />

      <Container>
        {!isUserValidateOTP && data?.user?.otp_enabled && (
          <TwoFactorAuthentication isProfilePage />
        )}
        {isDisable2FA && <ModalDisable2FA setIsDisable2FA={setIsDisable2FA} />}
        {modalTwoFactor === "setup" && !isDisable2FA && (
          <ModalSetup2FA setIsValidateOTP={setIsValidateOTP} />
        )}
        <Sidebar />
        <HeaderProfile />
        <Outlet />
      </Container>
    </>
  );
};

export default ProfilePage;

