import Logo from "../../assets/uxlogotipo-large.svg";
import { Container } from "./styled";

const UxDocLogoLarge = () => {
  return (
    <Container>
      <img src={Logo} alt="Logo UXDOC" />
    </Container>
  );
};

export default UxDocLogoLarge;
