/*
this file render to open modal when user is
resolving pending texts because they are
in documents shared with other users
*/

import React, { useEffect } from "react";
import {
  BoxButton,
  Footer,
  HStack,
  ModalContent,
  ModalOverlay,
  Text
} from "./styles";
import { useTheme } from "../../../../hooks/theme";
import { useMainHook } from "../../../../hooks/main";
import { useDiff } from "../../hooks/diffDocuments";
import { LoadingContainer } from "../EditClauseModal/styles";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import ButtonExit from "../../../../shared/components/ButtonExit";

//modal for diff functionality

interface ModalDefaultProps {
  divRefModal: React.MutableRefObject<HTMLDivElement>;
  option: "Accept" | "Decline";
  handleDefineModifications: (whichAction: string) => void;
  handleOpenModalDifferences: (type: "Close" | "Open") => void;
}

const ModalDefault: React.FC<ModalDefaultProps> = ({
  divRefModal,
  option,
  handleDefineModifications,
  handleOpenModalDifferences
}) => {
  const { theme } = useTheme();

  const { setLoadingGeneratingText, loadingGeneratingText } = useDiff();

  // useEffect(() => {
  //   setLoadingGeneratingText(false);
  // }, []);

  return (
    <ModalOverlay ref={divRefModal}>
      <ModalContent>
        {loadingGeneratingText ? (
          <div
            style={{
              width: "100%",
              minHeight: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <ContractAnimation />
          </div>
        ) : (
          <>
            <HStack>
              <TitleH5
                title={
                  option === "Accept"
                    ? "Aceitar alterações"
                    : "Rejeitar alterações"
                }
              />
              <Text>
                Tem certeza que você quer{" "}
                <strong>
                  {" "}
                  {option === "Accept" ? "aceitar" : "rejeitar"}{" "}
                </strong>{" "}
                estas alterações?
              </Text>
            </HStack>
            <Footer>
              <ButtonMaxWidth
                text="Não"
                typeStyle="white"
                onClick={() => handleOpenModalDifferences("Close")}
              />

              <ButtonMaxWidth
                text="Sim"
                typeStyle="purple"
                onClick={() => {
                  if (option === "Accept") {
                    handleDefineModifications("Accept");
                  }

                  if (option === "Decline") {
                    handleDefineModifications("Decline");
                  }
                }}
              />
            </Footer>

            <BoxButton>
              <ButtonExit onClick={() => handleOpenModalDifferences("Close")} />
            </BoxButton>
          </>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalDefault;
