import React, { useState } from "react";

import {
  ItemCarrousel,
  CardHover,
  CardContainer,
  MainCardHover,
  Button,
  DraggableArea
} from "../styles";
import Tooltip from "../../Tooltip";

interface CardProps {
  disabled?: boolean;
  handleChooseTemplate: (templateId: any) => Promise<void>;
  selected: boolean;
  title: string;
  itemId: string;
  src: string;
  dragUrl: React.MutableRefObject<any>;
}

export function Card({
  itemId,
  src,
  dragUrl,
  handleChooseTemplate
}: CardProps) {
  const [isHover, setIsHover] = useState(false);

  return (
    <CardContainer
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <DraggableArea
        draggable
        onDragStart={() => {
          setIsHover(false);
          dragUrl.current = { alt: `template#@@#${itemId}` };
        }}
      >
        <Tooltip title="Arraste para usar" placement="bottom">
          <ItemCarrousel
            draggable
            style={{ cursor: "grab" }}
            src={src}
            alt={`template#@@#${itemId}`}
          />
        </Tooltip>
        {isHover && (
          <CardHover draggable="false">
            <MainCardHover>
              <p>Arraste para substituir o template atual</p>
            </MainCardHover>

            <Button onClick={() => handleChooseTemplate(itemId)}>
              Pré-visualizar
            </Button>
          </CardHover>
        )}
      </DraggableArea>
    </CardContainer>
  );
}
