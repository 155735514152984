import React, { useEffect } from "react";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";

import { usePagesEditor } from "../../../../hooks/pagesEditor";

import ButtonIconsWithTooltip from "../../../ButtonsAsideBar/components/ButtonIconsAsidebar";
import { useTheme } from "../../../../../../hooks/theme";
import MountIcons from "../../../../../../shared/utils/MountIcons";

// import { Container } from './styles';

interface RemovePageEditorProps {
  index: number;
  item: any;
}

const RemovePageEditor: React.FC<RemovePageEditorProps> = ({ index, item }) => {
  const {
    handleRemovePage,
    isActiveHoverIconRemovePage,
    handleHoverIconRemovePage
  } = usePagesEditor();
  const { theme } = useTheme();

  return (
    <ButtonIconsWithTooltip
      onClick={() => handleRemovePage(item.pageNumber)}
      onMouseEnter={() => handleHoverIconRemovePage(index, true)}
      onMouseLeave={() => handleHoverIconRemovePage(index, false)}
      style={{ position: "relative" }}
      title="Remover página"
      placement="bottom-start"
    >
      <PersonalizedIcon
        isActive={
          isActiveHoverIconRemovePage.visible &&
          isActiveHoverIconRemovePage.index === index
        }
        activeColor={theme.colors.primary}
        inactivatedColor="#999C9F"
        width={30}
        height={30}
        className="btn-page"
        dPath={MountIcons.IconTrash.dPath}
        viewBox={MountIcons.IconTrash.viewBox}
      />
    </ButtonIconsWithTooltip>
  );
};

export default RemovePageEditor;
