import React, { useCallback, useEffect, useRef, useState } from "react";
import * as FileSaver from "file-saver";
import moment from 'moment';
import Excel from "exceljs";
import { AxiosResponse } from "axios";
import { differenceInDays } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { ToastContainer, toast } from "react-toastify";
import { Link, Outlet, useLocation } from "react-router-dom";

import {
  Button,
  ButtonDocument,
  ButtonFilter,
  ButtonsArray,
  Container,
  DivMap,
  Dropdown,
  FooterButtons,
  HStack,
  HeaderBox,
  Main,
  NavFilters,
  NavMain,
  OptionsFilters,
  RangeDateInput,
  SelectBox
} from "./styles";

import api from "../../../../../services/api";
import { useSign } from "../../../../../hooks/sign";
import { useAuth } from "../../../../../hooks/auth";

import GeneratePdfDocument from "../../../../../shared/components/GeneratePdfDocument";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import DocumentIcon from "../../../../../shared/assets/icons/DocumentIcon";
import PlainText from "../../../../../shared/components/PlainText";
import MountIcons from "../../../../../shared/utils/MountIcons";
import ControllerModalSign from "../../components/ControllerModalSign";
import HeaderSection from "../../../components/HeaderSection";
import GenerateThumb from "../../components/GenerateThumb";
import IconSvg from "../../../../editor/components/IconSvg";
import iconExit from "../../assets/exit-icon.svg";
import FilterIcon from "../../../../../assets/filter_alt.svg";
import Divider from "../../../../editor/components/Divider";

interface ResponseExcelDataProps {
  signature: {
    id: string;
    user_id: string;
    title: string;
    status: string;
    final_date: string;
    document_name: string;
    thumbnail: string;
    is_excluded: boolean;
    created_at: string;
    updated_at: string;
    thumbnail_url: string;
    document_base64?: string;
  };
  signature_users: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    is_signed: boolean;
    created_at: string;
    updated_at: string;
  }[];
}

interface DataExcelProps {
  nameOfDoc: string;
  status: string;
  responsable: string;
  signers: string;
  createdAt: string;
  doneDate: string;
  differenceDays: string;
  expiredDate: string;
  cancelledDate: string;
  isExcluded: boolean;
}

interface SubmitProps {
  status: string;
  created_at_start: string;
  created_at_end: string;
  final_date_start: string;
  final_date_end: string;
  created_at?: string;
  final_date?: string;
  data_envio?: string;
  data_expedicao?: string;
}

const SignaturePage = () => {
  const {
    amountSignDocs,
    documentName,
    generating,
    setGenerating,
    pagesRender,
    generateThumb,
    setGenerateThumb,
    setOpenModal,
    openModal,
    setDataSign,
    setDataSpamDocs,
    setAmountSignDocs,
    setDocumentName,
    arrayFilters,
    setArrayFilters,
    filters,
    setFilters,
  } = useSign();

  const { data } = useAuth();


  //Open/Close Filters
  const dropdownRef = useRef(null);
  const [openFilters, setOpenFilters] = useState<boolean>(false);

  //route properties
  const location = useLocation();
  const screenNowSignature = location.pathname.split("/")[2];

  useEffect(() => {
    return () => {
      setFilters({} as SubmitProps);
      setDataSign([]);
      setDataSpamDocs([]);
      setAmountSignDocs({ spam: 0, docs: 0 });
      setDocumentName("");
      setOpenModal({
        isOpen: false,
        modalName: ""
      });
    };
  }, []);

  const formatDate = (date: string | undefined) => {
    return date ? moment(date).format('DD/MM/YYYY') : '';
  };

  const formatDateFinal = useCallback((date: string, finalDate?: string) => {
    let initialDate = date;
    // let now = new Date();
    const nyTimeZone = "UTC";
    const initialDateUTC = utcToZonedTime(initialDate, nyTimeZone);

    if (finalDate) {
      const final_Date = utcToZonedTime(finalDate, nyTimeZone);
      const daysDifference = differenceInDays(final_Date, initialDateUTC);

      let differenceText = `${daysDifference} dias`;

      return differenceText;
    }

    const niceDate = format(initialDateUTC, "dd/MM/yyyy", {
      timeZone: "UTC",
    });

    return niceDate;

  }, []);

  let idToastify: any;

  const handleGenerateExcelData = useCallback(async () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    idToastify = toast.loading("");

    //do something else
    toast.update(idToastify, {
      render: "Exportando seu documento em Excel.",
      type: "default",
      isLoading: true
    });
    try {
      let arrayFormatted: DataExcelProps[] = [];

      const responseExcelData: AxiosResponse<ResponseExcelDataProps[]> =
        await api.get("signatures/excel", {
          params: {
            is_excluded: false
          }
        });

      if (responseExcelData.status !== 200) {
        toast.update(idToastify, {
          render: "Algo deu errado, tente novamente mais tarde.",
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
        return toast.error("Ocorreu um erro ao exportar seu documento.");
      }

      if (responseExcelData.status === 200) {
        responseExcelData.data.forEach(signature => {
          const userNames = signature?.signature_users?.map(
            user => `${user.first_name} ${user.last_name} (${user.email})`
          );
          const concatenatedNames = userNames?.join(", ");
          arrayFormatted.push({
            nameOfDoc: signature.signature.title,
            status:
              signature.signature.status === "Concluído"
                ? "Assinado"
                : signature.signature.status === "Assinatura cancelada"
                  ? "Cancelado"
                  : signature.signature.status === "Assinatura expirada"
                    ? "Expirado"
                    : signature.signature.status === "Aguardando assinatura" &&
                    "Aguardando assinatura",
            responsable: `${data.people.first_name} ${data.people.last_name} (${data.user.email})`,
            signers: concatenatedNames,
            createdAt: format(
              Date.parse(signature.signature.created_at),
              " dd/MM/yyyy'"
            ),
            doneDate:
              signature.signature.status === "Concluído"
                ? format(
                  Date.parse(signature.signature.updated_at),
                  " dd/MM/yyyy'"
                )
                : "Não Concluído",
            differenceDays:
              signature.signature.status === "Concluído"
                ? formatDateFinal(
                  signature.signature.created_at,
                  signature.signature.updated_at
                )
                : signature.signature.status === "Concluído"
                  ? "Assinado"
                  : '-',
            expiredDate:
              signature.signature.status === "Concluído"
                ? 'Concluído'
                : formatDateFinal(signature.signature.final_date),
            cancelledDate:
              signature.signature.status === "Assinatura cancelada"
                ? format(
                  Date.parse(signature.signature.final_date),
                  " dd/MM/yyyy'"
                )
                : "-",
            isExcluded: false
          });
        });

        try {
          const responseExcelData: AxiosResponse<ResponseExcelDataProps[]> =
            await api.get("signatures/excel", {
              params: {
                is_excluded: true
              }
            });

          if (responseExcelData.status !== 200) {
            toast.update(idToastify, {
              render: "Algo deu errado, tente novamente mais tarde.",
              type: "error",
              isLoading: false,
              autoClose: 5000
            });
            return toast.error("Ocorreu um erro ao exportar seu documento.");
          }

          if (responseExcelData.status === 200) {
            responseExcelData.data.forEach(signature => {
              const userNames = signature?.signature_users?.map(
                user => `${user.first_name} ${user.last_name} (${user.email})`
              );
              const concatenatedNames = userNames?.join(", ");
              arrayFormatted.push({
                nameOfDoc: signature.signature.title,
                status: "Excluido",
                responsable: `${data.people.first_name} ${data.people.last_name} (${data.user.email})`,
                signers: concatenatedNames,
                createdAt: format(
                  Date.parse(signature.signature.created_at),
                  " dd/MM/yyyy'"
                ),
                doneDate:
                  signature.signature.status === "Concluído"
                    ? format(
                      Date.parse(signature.signature.updated_at),
                      " dd/MM/yyyy'"
                    )
                    : "Não Concluído",
                differenceDays: "Excluido",
                expiredDate:
                  signature.signature.status === "Concluído"
                    ? 'Concluído'
                    : formatDateFinal(signature.signature.final_date),
                cancelledDate:
                  signature.signature.status === "Assinatura cancelada"
                    ? format(
                      Date.parse(signature.signature.final_date),
                      " dd/MM/yyyy'"
                    )
                    : "-",
                isExcluded: true
              });
            });
          }
          handleExportForExcel(arrayFormatted);
        } catch (err) {
          console.error(
            err,
            "error when change params of api of get spam docs sign"
          );
        }
      }
    } catch (err) {
      console.error(err, "error when get excel api");
    }
  }, []);

  const handleExportForExcel = useCallback(
    async (array: DataExcelProps[]) => {
      let nameOfFile = `ANO.MES.DIA - Realtório de Assinaturas - UX Doc Sign (NOME DO USUARIO)`;
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}.${month}.${day}`;
      nameOfFile = `${formattedDate} - Relatório de Assinaturas - UX Doc Sign (${data.people.first_name} ${data.people.last_name})`;

      try {
        const workbook = new Excel.Workbook();
        const sheet = workbook.addWorksheet("My Sheet");
        sheet.properties.defaultRowHeight = 80;

        // Header formatting
        const headerRow = sheet.getRow(1);
        // Definir a altura da linha para todas as linhas
        headerRow.height = 20; // Altura desejada em unidades

        headerRow.font = {
          name: "Calibri",
          family: 4,
          size: 11,
          bold: true
        };

        // Column definitions
        const columns = [
          {
            header: "NOME DO ARQUIVO",
            key: "nameOfDoc",
            width: 45,
            height: 20
          },
          { header: "STATUS", key: "status", width: 58, height: 20 },
          { header: "RESPONSÁVEL", key: "responsable", width: 40, height: 20 },
          { header: "ASSINANTES", key: "signers", width: 90, height: 20 },
          { header: "DATA DO ENVIO", key: "createdAt", width: 40, height: 20 },
          {
            header: "DATA DA CONCLUSÃO",
            key: "doneDate",
            width: 40,
            height: 20
          },
          {
            header: "CICLO DE ASSINATURA",
            key: "differenceDays",
            width: 40,
            height: 20
          },
          {
            header: "DATA DA EXPIRAÇÃO",
            key: "expiredDate",
            width: 40,
            height: 20
          },
          {
            header: "DATA DO CANCELAMENTO",
            key: "cancelledDate",
            width: 40,
            height: 20
          },
        ];
        sheet.columns = columns;

        // Data rows
        array.forEach((data, index) => {
          const row = sheet.addRow(data);
          row.height = 20; // Customize row height if needed
        });

        // Center align headers
        headerRow.eachCell(cell => {
          cell.alignment = { horizontal: "center" };
        });

        const buf = await workbook.xlsx.writeBuffer();

        const fileName = `${nameOfFile}`; // Customize the file name
        FileSaver.saveAs(new Blob([buf]), `${fileName}.xlsx`);

        //do something else
        toast.update(idToastify, {
          render: "Documento exportado com sucesso.",
          type: "success",
          isLoading: false,
          autoClose: 5000
        });
      } catch (error) {
        //do something else
        toast.update(idToastify, {
          render: "Algo deu errado, tente novamente mais tarde.",
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
        console.error("<<<ERROR>>>", error);
        console.error("Something Went Wrong", error.message);
      }
    },
    [data]
  );

  const resetFilters = () => {
    setFilters({
      status: '',
      created_at_start: '',
      created_at_end: '',
      created_at: '',
      final_date: '',
      final_date_start: '',
      final_date_end: '',
    });
  };

  const onSubmit = async () => {
    let unifiedFilters: SubmitProps = { ...filters };

    if (unifiedFilters.created_at_start && unifiedFilters.created_at_end) {
      if (unifiedFilters.created_at_start > unifiedFilters.created_at_end) {
        return toast.error('Data inicial não pode ser maior que a final');
      }
      unifiedFilters.created_at = `${formatDate(unifiedFilters.created_at_start)} - ${formatDate(unifiedFilters.created_at_end)}`;
    } else if (unifiedFilters.created_at_start && !unifiedFilters.created_at_end) {
      unifiedFilters.created_at = formatDate(unifiedFilters.created_at_start);
    } else if (!unifiedFilters.created_at_start && unifiedFilters.created_at_end) {
      unifiedFilters.created_at = formatDate(unifiedFilters.created_at_end);
    }

    if (unifiedFilters.final_date_start && unifiedFilters.final_date_end) {
      if (unifiedFilters.final_date_start > unifiedFilters.final_date_end) {
        return toast.error('Data inicial não pode ser maior que a final');
      }
      unifiedFilters.final_date = `${formatDate(unifiedFilters.final_date_start)} - ${formatDate(unifiedFilters.final_date_end)}`;
    } else if (unifiedFilters.final_date_start && !unifiedFilters.final_date_end) {
      unifiedFilters.final_date = formatDate(unifiedFilters.final_date_start);
    } else if (!unifiedFilters.final_date_start && unifiedFilters.final_date_end) {
      unifiedFilters.final_date = formatDate(unifiedFilters.final_date_end);
    }

    const newArray: { id: string; value: string; }[] = Object.entries(unifiedFilters)
      .filter(([key, value]) => value !== undefined && value !== '')
      .map(([key, value]) => ({
        id: key,
        value: value || '',
      }));
    setArrayFilters(newArray);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenFilters(false);
      }
    }

    // Adiciona um ouvinte de evento ao documento
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove o ouvinte de evento ao desmontar o componente
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <ToastContainer />

      <GeneratePdfDocument
        documentNameTemplate={documentName}
        downloading={generating}
        pagesToBeRendered={pagesRender}
        setDownloading={setGenerating}
      />
      <GenerateThumb
        setIsActive={setGenerateThumb}
        isActive={generateThumb}
        url=""
      />

      <Container>
        <HeaderBox>
          <HeaderSection
            description="Gerencie os documentos enviados para assinatura digital."
            title="Assinatura"
          />

          <HStack>
            <ButtonDocument color="white" onClick={handleGenerateExcelData}>
              Exportar para excel
            </ButtonDocument>
            <ButtonDocument
              color="purple"
              onClick={() => {
                setOpenModal({
                  isOpen: true,
                  modalName: "Import document"
                });
              }}
            >
              <PersonalizedIcon
                dPath={MountIcons.IconCrossAdd.dPath}
                viewBox={MountIcons.IconCrossAdd.viewBox}
                inactivatedColor="#FFF"
              />
              <p>Enviar novo documento</p>
            </ButtonDocument>
          </HStack>
        </HeaderBox>
        <Main>
          <NavMain>
            <Button
              isActive={
                screenNowSignature === "documents" ||
                screenNowSignature === undefined
              }
            >
              <Link to="/signatures/documents">
                <DocumentIcon
                  inactivatedColor="#676B70"
                  activeColor="#343A40"
                  isActive={
                    screenNowSignature === "documents" ||
                    screenNowSignature === undefined
                  }
                />
                <PlainText>Documentos ({amountSignDocs.docs})</PlainText>
              </Link>
            </Button>

            <Button isActive={screenNowSignature === "trash"}>
              <Link to="/signatures/trash">
                <PersonalizedIcon
                  dPath={MountIcons.IconTrash.dPath}
                  viewBox={MountIcons.IconTrash.viewBox}
                  inactivatedColor="#676B70"
                  activeColor="#343A40"
                  isActive={screenNowSignature === "trash"}
                />
                <PlainText>Lixeira ({amountSignDocs.spam})</PlainText>
              </Link>
            </Button>
          </NavMain>
          {(screenNowSignature === 'documents') || !screenNowSignature && (
            <NavFilters>
              <ButtonFilter onClick={() => setOpenFilters(!openFilters)}>
                <IconSvg
                  className=""
                  description=""
                  src={FilterIcon}
                />
                Filtro
              </ButtonFilter>
              {openFilters && (
                <Dropdown ref={dropdownRef}>
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                  }}>
                    <OptionsFilters>
                      <label>Status</label>
                      <SelectBox>
                        <select
                          onChange={(e) => {
                            setFilters((prevState) => ({
                              ...prevState,
                              status: e.target.value,
                            }));
                          }}
                          defaultValue={""}
                          value={filters.status}
                        >
                          <option value={""} selected >Selecionar</option>
                          <option value={"Aguardando assinatura"}>Aguardando assinatura</option>
                          <option value={"Concluído"}>Concluído</option>
                          <option value={"Assinatura expirada"}>Expirado</option>
                          <option value={"Assinatura cancelada"}>Assinatura cancelada</option>
                        </select>
                      </SelectBox>
                    </OptionsFilters>
                    <OptionsFilters>
                      <label>Data do envio</label>
                      <SelectBox>
                        <RangeDateInput>
                          <input
                            type="date"
                            onChange={(e) => {
                              setFilters((prevState) => ({
                                ...prevState,
                                created_at_start: e.target.value,
                              }));
                            }}
                            value={filters.created_at_start}
                          />
                          <Divider />
                          <input
                            onChange={(e) => {
                              setFilters((prevState) => ({
                                ...prevState,
                                created_at_end: e.target.value,
                              }));
                            }}
                            type="date"
                            value={filters.created_at_end}
                          />
                        </RangeDateInput>
                      </SelectBox>
                    </OptionsFilters>
                    <OptionsFilters>
                      <label>Data da expiração</label>
                      <SelectBox>
                        <RangeDateInput>
                          <input
                            onChange={(e) => {
                              setFilters((prevState) => ({
                                ...prevState,
                                final_date_start: e.target.value,
                              }));
                            }}
                            type="date"
                            value={filters.final_date_start}
                          />
                          <Divider />
                          <input
                            onChange={(e) => {
                              setFilters((prevState) => ({
                                ...prevState,
                                final_date_end: e.target.value,
                              }));
                            }}
                            value={filters.final_date_end}
                            type="date"
                          />
                        </RangeDateInput>
                      </SelectBox>
                    </OptionsFilters>
                    <FooterButtons>
                      <button type="button" onClick={resetFilters}>
                        Limpar
                      </button>
                      <button type="submit">
                        Filtar
                      </button>
                    </FooterButtons>
                  </form>
                </Dropdown>
              )}
              {arrayFilters.length > 0 && (
                <DivMap>
                  {arrayFilters.map((item, index) => {
                    const notShow = item.id === 'created_at_start' || item.id === 'created_at_end' || item.id === 'final_date_start' || item.id === 'final_date_end';
                    if (notShow) return;
                    return (
                      <ButtonsArray key={index}>
                        <span>{item.value}</span>
                        <IconSvg
                          className=""
                          description=""
                          src={iconExit}
                          onClick={() => {
                            if (item.id === 'created_at') {
                              const filteredCreatedAt = arrayFilters.filter((filter) =>
                                filter.id !== 'created_at_start' &&
                                filter.id !== 'created_at_end' &&
                                filter.id !== 'created_at'
                              );
                              setFilters((prevState) => ({
                                ...prevState,
                                created_at_start: '',
                                created_at_end: '',
                              }));
                              setArrayFilters(filteredCreatedAt);
                              return;
                            }

                            if (item.id === 'final_date') {
                              const filteredFinalDate = arrayFilters.filter((filter) =>
                                filter.id !== 'final_date_start' &&
                                filter.id !== 'final_date_end' &&
                                filter.id !== 'final_date'
                              );
                              setFilters((prevState) => ({
                                ...prevState,
                                final_date_start: '',
                                final_date_end: '',
                              }));
                              setArrayFilters(filteredFinalDate);
                              return;
                            }

                            setFilters((prevState) => ({
                              ...prevState,
                              status: '',
                            }));
                            setArrayFilters(arrayFilters.filter((filter) => filter.id !== item.id));
                          }}
                        />
                      </ButtonsArray>
                    );
                  })}
                </DivMap>
              )}
            </NavFilters>
          )}
          <Outlet />
        </Main>
      </Container>

      <ControllerModalSign
        setOpenModal={setOpenModal}
        openModal={openModal}
        screenNowSignature={screenNowSignature}
      />
    </>
  );
};

export default SignaturePage;
