/*
this file controls which element our toolbar should show to the user.
example: if object selected was a rectangle then 
show toolbar with features relative to the konva shape objects
*/

import { useCallback, useEffect, useState } from "react";
//icons
import iconTrash from "../../assets/icon-trash.svg";
import TextTool from "./Components/TextTool";
import { useIconsColors } from "../../hooks/iconsColors";
import { useToolbarEditor } from "../../hooks/toolbarEditor";
import { useSelection } from "../../hooks/selection";
import Konva from "konva";
import { usePagesEditor } from "../../hooks/pagesEditor";
import { VectorOfColors } from "../../../../shared/utils/VectorOfColors";
import DefaultTool from "./Components/DefaultTool";
import BlankPage from "./Components/BlankPage";
import ImageTool from "./Components/ImageTool";
import MultiplesTool from "./Components/MultiplesTool";
import RichTextTool from "./Components/RichTextTool";

//set up interfaces
interface ElementForShowProps {
  elementForShow:
    | "Default"
    | "Image"
    | "Text"
    | "BlankPage"
    | "MultipleItems"
    | "RichTextEditor";
}
// colors bar utils
const colorsSelectVector = ["#000"];
const colorsBorderSelectVector = ["#000"];

const ToolbarByElement: React.FC<ElementForShowProps> = ({
  elementForShow,
}) => {
  //setting hooks to this page
  const {
    handleBlockElements,
    handleCloseColorPickerClick,
    handleColorPickerClick,
    isDisplayColorPicker,
    isActiveBringToForward,
    isActiveTakeBack,
    setIsDisplayColorPicker,
    isDisplayColorPickerBorder,
  } = useIconsColors();

  const {
    setColorToElement,
    deleteElement,
    bringElementToFront,
    pushElementToBehind,
    toggleBlockElement,
    isDraggable,
    setColorToElementBorder,

    handleChangeOpacity,
    valueOpacity,
    setValueOpacity,
    handleAllToFront,
    handleAllToBack,
  } = useToolbarEditor();

  const { idPage } = usePagesEditor();
  const {
    selectedObject,
    selectedObjects,
    setSelectedObject,
    blockCommandKeyboard,
  } = useSelection();
  //set up states
  const [isFavoriteColor, setIsFavoriteColor] = useState([colorsSelectVector]);
  const [isFavoriteColorBorder, setIsFavoriteColorBorder] = useState([
    colorsBorderSelectVector,
  ]);
  const [color, setColor] = useState("");
  const [borderColor, setColorBorder] = useState("");
  const [randomColor, setRandomColor] = useState(false);
  const [randomColorBorder, setRandomColorBorder] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionBorder, setSelectedOptionBorder] = useState(null);
  const [isActiveDash, setIsActiveDash] = useState(false);
  const [isActiveOpacity, setIsActiveOpacity] = useState(false);
  const [isActiveBorderRadius, setIsActiveBorderRadius] = useState(false);

  //handle when user click on button to bring that object selected
  //all zIndex to front then trigger of this callback to him
  //example: zIndex + 1
  const handleClickBringToForwardIcon = useCallback(() => {
    bringElementToFront();
  }, [selectedObject]);

  //handle when user click to open config of border radius
  //when this button are visible and object was previously selected.
  //only to show dropdown.
  const handleClickRadius = useCallback(() => {
    setIsActiveBorderRadius(!isActiveBorderRadius);
  }, [isActiveBorderRadius]);

  //handle when user click on button to change opacity of selected object
  //then trigger of this callback to him
  //only to show dropdown.
  const handleClickOpacity = useCallback(() => {
    setIsActiveOpacity(!isActiveOpacity);
  }, [isActiveOpacity]);

  //handle when user click on button to change dash option on stroke
  // of selected object then trigger of this callback to him
  //only to show dropdown.
  const handleClickDash = useCallback(() => {
    setIsActiveDash(!isActiveDash);
  }, [isActiveDash, selectedObject]);

  //handle when user click on button to take that object selected
  //all zIndex to back then trigger of this callback to him
  //example: zIndex - 1
  const handleClickTakeBackIcon = useCallback(() => {
    pushElementToBehind();
  }, [selectedObject]);

  //when modal of colors element is visible and the event of
  // input color is trigger then set this value to the
  //state relative color of elements
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setColor(e.target.value);
    },
    [color, selectedObject]
  );

  //when the event of input color is change
  //then set this value to the state relative color of elements
  const handleInputChangeBorder = (
    e: React.ChangeEvent<HTMLInputElement>,
    color: string
  ) => {
    e.preventDefault();
    setColorBorder(e.target.value);
    setColorToElementBorder(color);
  };

  //lock icon - when user click in this button, he wants lock/block
  //the selected object then trigger our callback for that
  //only check we do is if we variable to we know if keyboard events can`t be
  //trigger so ignore this function to destroy this object selected.
  const handleDeleteElement = useCallback(() => {
    if (!blockCommandKeyboard) {
      deleteElement();
    }
  }, [selectedObject, selectedObjects, blockCommandKeyboard]);

  //we set in this project one lib to create component
  //to generate UI and change custom hexadecimal or rgba
  //and this library control that task, then if the user
  //is changing the color through the library, we need
  //to make aware of our color state that the hexadecimal was edited through
  //that library of colors
  const handleChange = useCallback(
    (color) => {
      setColorToElement(color.hex);
    },
    [setColorToElement]
  );

  //same to the handleChange above fynction
  //to make aware of our color of border state that the hexadecimal
  //was edited through that library of colors
  const handleChangeBorder = useCallback(
    (color) => {
      setColorToElementBorder(color.hex);
    },
    [selectedObject]
  );

  //only to trigger callback to lock selected object on editor
  const handleToggleBlockElement = useCallback(() => {
    toggleBlockElement();
  }, [selectedObject, selectedObjects]);

  //generate random boolean color for color picker buttonLabel
  useEffect(() => {
    setRandomColorBorder(!isDisplayColorPickerBorder);
  }, [isDisplayColorPickerBorder, selectedObject]);

  // Getting stroke of object to show for user
  useEffect(() => {
    if (selectedObject) {
      if (selectedObject instanceof Konva.Shape) {
        setColorBorder(selectedObject?.stroke());
      }
    }
  }, [selectedObject]);

  // Getting fill of object to show for user
  useEffect(() => {
    if (selectedObject) {
      if (selectedObject instanceof Konva.Shape) {
        setColor(selectedObject?.fill());
      }
    }
  }, [selectedObject]);

  return (
    <>
      {elementForShow === "Text" && (
        <TextTool
          VectorOfColors={VectorOfColors}
          color={color}
          isFavoriteColor={isFavoriteColor}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          colorsSelectVector={colorsSelectVector}
          handleColorPickerClick={handleColorPickerClick}
          handleCloseColorPickerClick={handleCloseColorPickerClick}
          isDisplayColorPicker={isDisplayColorPicker}
          setIsDisplayColorPicker={setIsDisplayColorPicker}
          handleChange={handleChange}
          randomColor={randomColor}
          handleInputChange={handleInputChange}
          handleClickBringToForwardIcon={handleClickBringToForwardIcon}
          handleClickTakeBackIcon={handleClickTakeBackIcon}
          handleToggleBlockElement={handleToggleBlockElement}
          isDraggable={isDraggable}
          iconTrash={iconTrash}
          setColor={setColor}
          handleDeleteElement={handleDeleteElement}
          elementForShow={elementForShow}
        />
      )}
      {elementForShow === "Default" && (
        <DefaultTool
          color={color}
          setColor={setColor}
          isFavoriteColor={isFavoriteColor}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          colorsSelectVector={colorsSelectVector}
          handleChange={handleChange}
          randomColor={randomColor}
          handleInputChange={handleInputChange}
          borderColor={borderColor}
          isFavoriteColorBorder={isFavoriteColorBorder}
          selectedOptionBorder={selectedOptionBorder}
          setSelectedOptionBorder={setSelectedOptionBorder}
          colorsBorderSelectVector={colorsBorderSelectVector}
          setColorBorder={setColorBorder}
          handleChangeBorder={handleChangeBorder}
          randomColorBorder={randomColorBorder}
          handleInputChangeBorder={handleInputChangeBorder}
          isActiveDash={isActiveDash}
          handleClickDash={handleClickDash}
          handleClickRadius={handleClickRadius}
          isActiveBorderRadius={isActiveBorderRadius}
          handleClickBringToForwardIcon={handleClickBringToForwardIcon}
          handleClickTakeBackIcon={handleClickTakeBackIcon}
          handleClickOpacity={handleClickOpacity}
          handleToggleBlockElement={handleToggleBlockElement}
          handleDeleteElement={handleDeleteElement}
          iconTrash={iconTrash}
        />
      )}
      {elementForShow === "BlankPage" && <BlankPage />}
      {elementForShow === "Image" && (
        <ImageTool
          isActiveBringToForward={isActiveBringToForward}
          handleClickBringToForwardIcon={handleClickBringToForwardIcon}
          isActiveTakeBack={isActiveTakeBack}
          handleClickTakeBackIcon={handleClickTakeBackIcon}
          handleAllToFront={handleAllToFront}
          handleAllToBack={handleAllToBack}
          idPage={idPage}
          valueOpacity={valueOpacity}
          setValueOpacity={setValueOpacity}
          handleChangeOpacity={handleChangeOpacity}
          handleClickOpacity={handleClickOpacity}
          handleBlockElements={handleBlockElements}
          handleToggleBlockElement={handleToggleBlockElement}
          handleDeleteElement={handleDeleteElement}
          isDraggable={isDraggable}
          iconTrash={iconTrash}
        />
      )}
      {elementForShow === "MultipleItems" && (
        <MultiplesTool
          handleToggleBlockElement={handleToggleBlockElement}
          isDraggable={isDraggable}
          handleDeleteElement={handleDeleteElement}
          iconTrash={iconTrash}
        />
      )}
      {elementForShow === "RichTextEditor" && (
        <RichTextTool
          isActiveBringToForward={isActiveBringToForward}
          handleClickBringToForwardIcon={handleClickBringToForwardIcon}
          isActiveTakeBack={isActiveTakeBack}
          handleClickTakeBackIcon={handleClickTakeBackIcon}
          handleAllToFront={handleAllToFront}
          idPage={idPage}
          handleAllToBack={handleAllToBack}
          valueOpacity={valueOpacity}
          setValueOpacity={setValueOpacity}
          handleChangeOpacity={handleChangeOpacity}
          handleClickOpacity={handleClickOpacity}
          handleBlockElements={handleBlockElements}
          handleToggleBlockElement={handleToggleBlockElement}
          isDraggable={isDraggable}
          handleDeleteElement={handleDeleteElement}
          iconTrash={iconTrash}
        />
      )}
    </>
  );
};

export default ToolbarByElement;
