import { Dialog, DialogTitle } from "@mui/material";
import {
  CancelButton,
  Container,
  ContainerButton,
  SaveButton,
  Subtitle
} from "./styles";
import { IconCloseWrapper } from "../../styles";
import { GrClose } from "react-icons/gr";
import api from "../../../../../services/api";
import { useContext } from "react";
import { CreateProjectContext } from "../CreateNewProject";

type Props = {
  open: boolean;
  onClose: (next?: boolean) => void;
};

const ConfirmDialog: React.FC<Props> = ({ open, onClose }: Props) => {
  const { project } = useContext(CreateProjectContext);

  return (
    <Dialog onClose={onClose} open={open} maxWidth={"md"}>
      <DialogTitle style={{ fontWeight: 700, fontSize: "16px" }}>
        Você está usando o fluxo {project.approval_flow?.name}
      </DialogTitle>

      <IconCloseWrapper onClick={() => onClose()}>
        <GrClose />
      </IconCloseWrapper>

      <Container>
        <Subtitle>Tem certeza que você quer usar este fluxo?</Subtitle>
        <ContainerButton>
          <CancelButton onClick={() => onClose()}>Não</CancelButton>
          <SaveButton onClick={() => onClose(true)}>Sim</SaveButton>
        </ContainerButton>
      </Container>
    </Dialog>
  );
};

export default ConfirmDialog;
