import React from "react";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";

import { usePagesEditor } from "../../../../hooks/pagesEditor";
import { useWorkspaceEditor } from "../../../../hooks/workspaceEditor";

import ButtonIconsWithTooltip from "../../../ButtonsAsideBar/components/ButtonIconsAsidebar";
import { useTheme } from "../../../../../../hooks/theme";
import MountIcons from "../../../../../../shared/utils/MountIcons";

// import { Container } from './styles';

interface IMoveDownPageProps {
  index: number;
  item: any;
}

const MoveDownPage: React.FC<IMoveDownPageProps> = ({ index, item }) => {
  const { handleOrderPageMoveDown } = usePagesEditor();

  const { isHandleHoverIconMoveDownPage, handleHovePageMoveDown } =
    useWorkspaceEditor();
  const { theme } = useTheme();

  return (
    <ButtonIconsWithTooltip
      style={{ position: "relative" }}
      onClick={() => handleOrderPageMoveDown(item.pageNumber)}
      onMouseEnter={() => handleHovePageMoveDown(index, true)}
      onMouseLeave={() => handleHovePageMoveDown(index, false)}
      title="Para baixo"
      placement="bottom"
      width="30px"
      className="btn-page"
    >
      <PersonalizedIcon
        isActive={
          isHandleHoverIconMoveDownPage.visible &&
          isHandleHoverIconMoveDownPage.index === index
        }
        activeColor={theme.colors.primary}
        inactivatedColor="#999C9F"
        width={20}
        height={12.37}
        className="arrow-moveup-icon"
        dPath={MountIcons.IconsMovedDow.dPath}
        viewBox={MountIcons.IconsMovedDow.viewBox}
      />
    </ButtonIconsWithTooltip>
  );
};

export default MoveDownPage;
