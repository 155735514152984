import {
  InputDate,
  InputBox,
  ItemUserInfo,
  ButtonsBox,
  AvatarCustom,
  Form,
  BoxInput1,
  BoxInput2,
  Table,
  Trash,
  FirstAreaItem,
  SelectBox,
  BoxOthers,
  Item,
} from "./styles";
import ButtonMaxWidth from "../ButtonMaxWidth";
import PlainText from "../PlainText";
import PersonalizedIcon from "../../assets/customIcons/PersonalizedIcon";
import MountIcons from "../../utils/MountIcons";

const ItemsOptions = [
  {
    value: 'assinante',
    label: 'Assinante',
  },
  {
    value: 'testemunha',
    label: 'Testemunha',
  },
  {
    value: 'interveniente-anuente',
    label: 'Interveniente-anuente',
  },
  {
    value: 'aprovador',
    label: 'Aprovador',
  },
  {
    value: 'fiador',
    label: 'Fiador',
  },
  {
    value: 'garantidor',
    label: 'Garantidor',
  },
  {
    value: 'outros',
    label: 'Outro',
  },
];

interface IPropsForms {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  mail: string;
  setMail: React.Dispatch<React.SetStateAction<string>>;
  assignWho: string;
  setAssignWho: React.Dispatch<React.SetStateAction<string>>;
  others: string;
  setOthers: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  dateFormatted: string;
  setDateFormatted: React.Dispatch<React.SetStateAction<string>>;
  signerData: any;
  handleAddMemberToSign: () => void;
  handleDeleteMemberToSign: (idUser: any) => void;
  handleBackImport: () => void;
  handleSendDocToSign: () => void;
}

const FormSign = ({
  name,
  setName,
  lastName,
  setLastName,
  mail,
  setMail,
  assignWho,
  setAssignWho,
  others,
  setOthers,
  loading,
  dateFormatted,
  setDateFormatted,
  signerData,
  handleAddMemberToSign,
  handleDeleteMemberToSign,
  handleBackImport,
  handleSendDocToSign
}: IPropsForms) => {

  return (
    <>
      <Form>
        <BoxInput1>
          <InputBox>
            <label> Nome</label>
            <input
              value={name}
              onChange={(event) => setName(event.target.value)}
              type="text"
            />
          </InputBox>
          <InputBox>
            <label>Sobrenome</label>
            <input
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              type="text"
            />
          </InputBox>
        </BoxInput1>
        <SelectBox>
          <label>Assinar como</label>
          <select
            value={assignWho}
            onChange={(e) =>
              setAssignWho(e.target.value)
            }
          >
            {ItemsOptions.map((option, index) => {
              return (
                <Item
                  key={index}
                  value={option.value}
                  active={option.value === assignWho}
                >
                  {option.label}
                </Item>
              );
            })}
          </select>
        </SelectBox>
        {assignWho === 'outros' && (
          <BoxOthers>
            <InputBox>
              <input
                onChange={(event) => setOthers(event.target.value)}
                type="text"
                value={others}
              />
            </InputBox>
          </BoxOthers>
        )}
        <BoxInput2>
          <InputDate>
            <InputBox>
              <label>Data da expiração</label>
              <input
                onChange={(event) => setDateFormatted(event.target.value)}
                type="date"
                value={dateFormatted}
              />
            </InputBox>
          </InputDate>
          <InputBox>
            <label>E-mail</label>
            <input
              value={mail}
              onChange={(event) => setMail(event.target.value)}
              type="text"
            />
          </InputBox>

          <ButtonMaxWidth
            text="Adicionar"
            typeStyle="purple"
            onClick={handleAddMemberToSign}
          />
        </BoxInput2>
      </Form>
      {signerData.length === 0 ? (
        <div className="empty-container">
          <span>
            Para começar, inclua novos integrantes para assinarem o documento.
            Caso você também seja assinante,
            <strong> adicione as suas informações</strong> para assinar o
            documento.
          </span>
        </div>
      ) : (
        <Table>
          <div>
            <p>Nome</p> <p>E-mail</p>
          </div>
          <ul>
            {signerData.map((user, index) => (
              <ItemUserInfo key={user.id}>
                <FirstAreaItem>
                  <figure>
                    <AvatarCustom indexOfUser={index + 1}>
                      <PlainText>
                        {`${user.firstName
                          .charAt(0)
                          .toUpperCase()}${user.lastName
                            .charAt(0)
                            .toUpperCase()}`}
                      </PlainText>
                    </AvatarCustom>
                  </figure>

                  <PlainText>
                    {`${user.firstName} ${user.lastName}`}
                  </PlainText>
                </FirstAreaItem>
                <PlainText className="email"> {user.email} </PlainText>
                <Trash onClick={() => handleDeleteMemberToSign(user.id)}>
                  <PersonalizedIcon
                    dPath={MountIcons.IconTrash.dPath}
                    viewBox={MountIcons.IconTrash.viewBox}
                    inactivatedColor="#999C9F"
                  />
                </Trash>
              </ItemUserInfo>
            ))}
          </ul>
        </Table>
      )}
      <ButtonsBox>
        <ButtonMaxWidth
          onClick={handleBackImport}
          text="Cancelar"
          typeStyle="white"
        />

        <ButtonMaxWidth
          onClick={handleSendDocToSign}
          text="Enviar"
          typeStyle="purple"
          disabled={loading}
          loading={loading}
        />
      </ButtonsBox>
    </>
  );
};

export default FormSign;