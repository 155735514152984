import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  height: 100vh;

  header {
    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    width: 405px;
    text-align: center;

    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 431px) {
      width: 87%;
      font-size: 12px;
    }

    @media (min-width: 1600px) {
      width: 450px;
    }

    @media (min-width: 1920px) {
      font-size: 16px;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const Form = styled.form`
  width: 405px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 431px) {
    width: 87%;
  }

  @media (min-width: 1600px) {
    width: 450px;
  }

  @media (min-width: 1920px) {
    gap: 20px;
  }
`;

export const LeftWrapper = styled.aside`
  display: flex;
  width: 38.95%;
  height: 100%;
  background: #ffffff;
  position: relative;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const RightWrapper = styled.main`
  display: flex;
  height: 100%;
  width: 61.05%;
  z-index: 20;
  align-items: center;
  background-image: url("./images/background-templates.svg");
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 800px) {
    width: 0%;
  }
`;

export const MainContent = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: auto;
  min-width: 447px;
  height: auto;

  .text-forgotpassword {
    font-size: 1.6rem;
    margin: 0 auto;
    width: 440px;
    text-align: center;
    margin-top: 1rem;
  }

  & .container-logo {
    display: flex;
    width: 222px;
    height: 95px;
    background-image: url("./images/logo-uxdoc.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
  }

  .force-margin {
    margin-top: 1rem;
    color: red;
    font-size: 1.4rem;
  }

  @media (max-width: 1600px) {
    .text-forgotpassword {
      font-size: 1.6rem;
      margin: 0 auto;
      width: 320px;
      text-align: center;
      margin-top: 1rem;
    }
    .container-logo {
      width: 180px;
      height: 90px;
    }

    .error-validation {
      height: 40px;
      margin-top: -4rem;
    }

    min-width: 200px;
  }

  @media (max-width: 1366px) {
    .text-forgotpassword {
      font-size: 1.4rem;
      margin-top: 0.5rem;
    }

    .error-validation {
      height: 40px;
      margin-top: -4rem;
    }

    min-width: 180px !important;
  }
`;

export const Text = styled.span`
  font-family: Raleway, sans-serif;
  text-align: center;
  margin: 0 auto;
  font-size: 2.4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.background};

  @media (max-width: 1600px) {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 1600px) {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
`;

export const InputBase = styled.input`
  border: none;
  width: 100%;
  border-bottom: 1.5px solid black;
  font-size: 2rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
    inset 0 0 0 100px rgba(255, 255, 255, 1);

  :focus {
    border-color: ${({ theme }) => theme.colors.background};
  }

  @media (max-width: 1600px) {
    width: 100%;
    margin: 5rem auto;
    font-size: 1.6rem;
  }

  @media (max-width: 1600px) {
    width: 100%;
    margin: 4rem auto;
    font-size: 1.4rem;
  }

  @media (max-width: 1366px) {
    width: 100%;
    margin: 4rem auto;
    font-size: 1.4rem;
    padding-bottom: 1rem;
  }
`;

export const ButtonSend = styled.button`
  width: 100%;
  height: 58px;
  background-color: black;
  color: white;
  border-radius: 10px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 4rem;

  transition: all 120ms linear;

  :hover {
    background-color: ${({ theme }) => theme.colors.background};
  }

  @media (max-width: 1600px) {
    margin-top: 0rem;
    height: 50px;
    font-size: 1.6rem;
  }

  @media (max-width: 1600px) {
    margin-top: -1rem;
    height: 42px;
    font-size: 1.4rem;
  }
`;

export const LogoOrganization = styled.img`
  width: 115px;
  height: 61px;
`;
