import { useCallback, useEffect, useState } from "react";
import {
  Container,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
} from "./styles";
import { animate, motion, useAnimation, useMotionValue } from "framer-motion";
import useComponentVisible from "../../hooks/useComponentVisible";
import { useToolbarEditor } from "../../hooks/toolbarEditor";
import { useMainHook } from "../../../../hooks/main";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import dotsIcon from "../../assets/icon-three-dots.svg";
import Konva from "konva";
import { useVersioning } from "../../hooks/versioning";
//options for fonts data below

const list = {
  visible: {
    opacity: 1,
    y: 5,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    y: -20,
    transition: {
      when: "afterChildren",
    },
  },
};

const variants = {
  visible: (i) => ({
    opacity: 1,
    transition: {
      delay: i * 0.3,
    },
  }),
  hidden: { opacity: 0 },
};

const DropdownToolbar = ({ dataTemplate, idTemplateMain, info }: any) => {
  const controls = useAnimation();
  const x = useMotionValue(0);
  const [idUser, setIdUser] = useState(null);

  const { setIsRevert, isRevert, forceUpdateRevert, setForceUpdateRevert } =
    useVersioning();

  useEffect(() => {
    const controls = animate(x, 100, {
      type: "spring",
      stiffness: 2000,
    });

    return controls.stop;
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const {
    setObjectScreen,
    currentTemplateInfo,
    stageRef,
    documentName,
    objectScreen,
    autoNumber,
    setAutoNumber,
  } = useMainHook();

  const { selectedOptionFont, setSelectedOptionFont, selectedNewFont } =
    useToolbarEditor();

  const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const onOptionClicked = useCallback(async (value: any) => {
    let idOfUser = currentTemplateInfo?.current.id;
    setIsRevert((prevState) => {
      let cloneState = cloneDeep(prevState);
      cloneState.id = idTemplateMain;
      cloneState.revert = true;
      return cloneState;
    });
    try {
      const requestApi = await api.put(
        "user-template-versions/update-is-main",
        {
          template_version_id: idTemplateMain,
          user_template_id: idOfUser,
        }
      );
      setObjectScreen((prevState) => {
        let cloneState = cloneDeep(prevState);
        cloneState = value;
        return cloneState;
      });

      let stages = stageRef.current as Konva.Stage[];
      let formatedObjectToApi: any = [];
      const dataURL = stages[0].toDataURL();
      const blob = await (await fetch(dataURL)).blob();
      const file = new File([blob], "fileName.png", { type: "image/png" });
      let formData = new FormData();
      formData.append("thumbnail", file);

      try {
        const fetchUploadPicture = await api.patch(
          `user-templates/thumbnail-upload/${currentTemplateInfo.current.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (error) {
        console.error("error uploading thumbnail", error.response.data);
      }

      stages.forEach((stage, index) => {
        let pageNumber = stage.attrs.id;

        let renderObjects = [];

        //layer     //objects
        stage.children[0].children.forEach((object, indexObject) => {
          let objectWithExtractedImagePropertie = null;

          if (object?.attrs?.image) {
            let { image, ...rest } = object.attrs;

            objectWithExtractedImagePropertie = rest;
          }

          renderObjects.push(
            objectWithExtractedImagePropertie
              ? objectWithExtractedImagePropertie
              : object.attrs
          );
        });

        formatedObjectToApi.push({
          pageNumber,
          renderObjects,
        });
      });

      try {
        api.put("/user-templates", {
          user_template_id: currentTemplateInfo.current.id,
          template: {
            arrayOfPages: formatedObjectToApi,
          },
          title: documentName.current
            ? documentName.current
            : "Documento sem nome",
          is_automatic_numbering_active: autoNumber.isActive,
          automatic_numbering_position: autoNumber.position,
          first_page_automatic_numbering: autoNumber.firstPage,
        });
      } catch (error) {
        console.error("Error trying to save user template " + error);
      }
      setIsOpen(false);
      toast.success("Versão principal com sucesso!");
    } catch (err) {
      setIsOpen(false);
      console.error(err, "deu erro");
    }
  }, [currentTemplateInfo]);

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpen(false);
      setIsComponentVisible(true);
    } else {
      return;
    }
  }, [isComponentVisible]);

  return (
    <>
      <Container>
        <DropDownContainer ref={ref}>
          <DropDownHeader Open={isOpen} onClick={handleClick}>
            <img
              style={{ marginLeft: "-1.5rem" }}
              className="dots-content"
              src={dotsIcon}
              alt=""
            />
          </DropDownHeader>
          {isOpen && isComponentVisible && (
            <DropDownListContainer onClick={handleClick}>
              <DropDownList
                variants={list}
                initial="hidden"
                animate="visible"
                onClick={() => onOptionClicked(dataTemplate)}
                as={motion.ul}
              >
                <ListItem
                  as={motion.li}
                  // onClick={() => onOptionClicked(dataTemplate)}
                  value={dataTemplate}
                  animate={controls}
                  variants={variants}
                >
                  Tornar versão principal
                </ListItem>
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
      </Container>
    </>
  );
};

export default DropdownToolbar;

