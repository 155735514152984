import React from "react";

interface BaseIconProps {
  className?: string;
  isActive?: boolean;
}

export default function LockedIcon({ className, isActive }: BaseIconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill={isActive ? "#663399" : "#343A40"}
        d="M11.9999 17.2381C11.4947 17.2381 11.0102 17.0374 10.653 16.6802C10.2958 16.323 10.0951 15.8385 10.0951 15.3333C10.0951 14.2762 10.9428 13.4286 11.9999 13.4286C12.5051 13.4286 12.9896 13.6293 13.3468 13.9865C13.704 14.3437 13.9047 14.8282 13.9047 15.3333C13.9047 15.8385 13.704 16.323 13.3468 16.6802C12.9896 17.0374 12.5051 17.2381 11.9999 17.2381ZM17.7142 20.0952V10.5714H6.28562V20.0952H17.7142ZM17.7142 8.66667C18.2194 8.66667 18.7038 8.86735 19.0611 9.22456C19.4183 9.58177 19.619 10.0663 19.619 10.5714V20.0952C19.619 20.6004 19.4183 21.0849 19.0611 21.4421C18.7038 21.7993 18.2194 22 17.7142 22H6.28562C5.78045 22 5.29596 21.7993 4.93875 21.4421C4.58154 21.0849 4.38086 20.6004 4.38086 20.0952V10.5714C4.38086 9.51429 5.22848 8.66667 6.28562 8.66667H7.238V6.76191C7.238 5.49897 7.7397 4.28776 8.63273 3.39473C9.52576 2.5017 10.737 2 11.9999 2C12.6252 2 13.2445 2.12317 13.8222 2.36248C14.3999 2.60179 14.9249 2.95255 15.3671 3.39473C15.8093 3.83691 16.16 4.36186 16.3993 4.9396C16.6386 5.51734 16.7618 6.13656 16.7618 6.76191V8.66667H17.7142ZM11.9999 3.90476C11.2421 3.90476 10.5154 4.20578 9.9796 4.7416C9.44378 5.27742 9.14276 6.00414 9.14276 6.76191V8.66667H14.857V6.76191C14.857 6.00414 14.556 5.27742 14.0202 4.7416C13.4844 4.20578 12.7577 3.90476 11.9999 3.90476Z"
      />
    </svg>
  );
}
