import styled from "styled-components";

interface AvataProps {
  color?: string;
}

export const Avatar = styled.div`
  align-items: center;
  color: #fff;
  background-color: #bdbdbd;
  border: 4px solid #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  font-family: Nunito-Bold;
  font-size: 1rem;
  height: 30px;
  overflow: hidden;
  text-align: center;
  width: 30px;
  margin-left: auto;
  cursor: pointer;
  img {
    margin-right: 0.5rem;
    object-fit: contain;
    cursor: pointer;
  }
`;

export const AvatarLetter = styled.div<AvataProps>`
  height: 30px;
  width: 30px;
  background-color: ${({ theme, color }) =>
    color ? color : theme.colors.background};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid white;
  cursor: pointer;
`;

export const TextAvatar = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;
  color: black;
`;
