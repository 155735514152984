interface TemplateIconProps {
  isActive: boolean;
  className?: string;
  colorActive: string;
  inactiveColor?: string;
}

const TemplateIcon = ({
  isActive,
  className,
  colorActive,
  inactiveColor = "#999C9F",
  ...rest
}: TemplateIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.10784 5.30871C4.02662 5.30871 3.96078 5.36945 3.96078 5.44437V7.52438C3.96078 7.5993 4.02662 7.66004 4.10784 7.66004H19.8922C19.9734 7.66004 20.0392 7.5993 20.0392 7.52438V5.44437C20.0392 5.36945 19.9734 5.30871 19.8922 5.30871H4.10784ZM2 5.44437C2 4.37052 2.94371 3.5 4.10784 3.5H19.8922C21.0563 3.5 22 4.37052 22 5.44437V7.52438C22 8.59823 21.0563 9.46875 19.8922 9.46875H4.10784C2.94371 9.46875 2 8.59823 2 7.52438V5.44437Z"
        fill={isActive ? colorActive : "#999C9F"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.32968 12.849C3.96078 12.849 3.96078 13.3401 3.96078 13.3401V18.2022C3.96078 18.2022 3.96078 18.5788 4.32968 18.5788H8.38437C8.76471 18.5788 8.76471 18.2022 8.76471 18.2022V13.3401C8.76471 13.3401 8.76471 12.849 8.38437 12.849H4.32968ZM2 12.8477C2 11.8437 2.94371 11.0298 4.10784 11.0298H8.61765C9.78178 11.0298 10.7255 11.8437 10.7255 12.8477V18.6821C10.7255 19.6861 9.78178 20.5 8.61765 20.5H4.10784C2.94371 20.5 2 19.6861 2 18.6821V12.8477Z"
        fill={isActive ? colorActive : "#999C9F"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7129 12.0102C12.7129 11.4687 13.1801 11.0298 13.7564 11.0298H20.9569C21.5332 11.0298 22.0004 11.4687 22.0004 12.0102C22.0004 12.5516 21.5332 12.9906 20.9569 12.9906H13.7564C13.1801 12.9906 12.7129 12.5516 12.7129 12.0102Z"
        fill={isActive ? colorActive : "#999C9F"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7129 15.7646C12.7129 15.2231 13.1801 14.7842 13.7564 14.7842H20.9569C21.5332 14.7842 22.0004 15.2231 22.0004 15.7646C22.0004 16.306 21.5332 16.745 20.9569 16.745H13.7564C13.1801 16.745 12.7129 16.306 12.7129 15.7646Z"
        fill={isActive ? colorActive : "#999C9F"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7129 19.5195C12.7129 18.978 13.1801 18.5391 13.7564 18.5391H20.9569C21.5332 18.5391 22.0004 18.978 22.0004 19.5195C22.0004 20.0609 21.5332 20.4998 20.9569 20.4998H13.7564C13.1801 20.4998 12.7129 20.0609 12.7129 19.5195Z"
        fill={isActive ? colorActive : "#999C9F"}
      />
    </svg>
  );
};

export default TemplateIcon;
