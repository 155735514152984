import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MountIcons from "../../utils/MountIcons";
import { Container, ItemSubModal, SubModal } from "./styles";

import PdfICon from "../../../modules/dashboard/assets/pdf-icon.svg";
import WordICon from "../../../modules/dashboard/assets/word-icon.svg";
import DowloadICon from "../../../modules/dashboard/assets/dowload-icon.svg";
import PersonalizedIcon from "../../assets/customIcons/PersonalizedIcon";
import Tooltip from "../../../modules/editor/components/Tooltip";
import { useAuth } from "../../../hooks/auth";
import { useHeaderEditor } from "../../../modules/editor/hooks/headerEditor";
import { useMainHook } from "../../../hooks/main";

const ButtonDowloadDocument = () => {
  const { permissionType, data } = useAuth();
  const { generatePDF, generateNewPDF } = useHeaderEditor();
  const { setIsIntentBuyModal } = useMainHook();

  const [isOpenDowloadModal, setIsOpenDowloadModal] = useState(false);
  const [typeUserAccount, setTypeUserAccount] = useState<
    "trial" | "premium" | "loading"
  >("loading");

  const MenuFormatsRef = useRef<HTMLButtonElement>(null);
  const buttonRef = useRef(null);

  const handleWordDownload = useCallback(() => {
    setIsIntentBuyModal(true);
  }, [setIsIntentBuyModal]);

  const handleDownloadWord = useCallback(() => {
    if (typeUserAccount === "premium") {
      generatePDF("word");
    } else {
      handleWordDownload();
    }
  }, [generatePDF, handleWordDownload, typeUserAccount]);

  const handleDownloadPDF = useCallback(() => {
    generatePDF("pdf");
    setIsOpenDowloadModal(false);
  }, [generatePDF, setIsOpenDowloadModal]);

  const handleDownloadNewPDF = useCallback(() => {
    generateNewPDF("pdf");
    setIsOpenDowloadModal(false);
  }, [generateNewPDF, setIsOpenDowloadModal]);

  const handleDownloadNewWord = useCallback(() => {
    generateNewPDF("word");
    setIsOpenDowloadModal(false);
  }, [generateNewPDF, setIsOpenDowloadModal]);

  const onCloseColorsMenu = useCallback(() => {
    setIsOpenDowloadModal(false);
  }, [setIsOpenDowloadModal]);

  const isBitsAcademyEmail = useMemo(() => {
    return !!data?.user?.email.match("@bitsacademy.com.br");
  }, [data]);

  useEffect(() => {
    if (
      data.payment_method?.user_type === "user_free" &&
      !data.payment_method.subscription_id &&
      data.payment_method.cancellation_date &&
      data.payment_method.is_active
    ) {
      setTypeUserAccount("trial");
    } else {
      setTypeUserAccount("premium");
    }
  }, [data]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (isOpenDowloadModal) {
        if (
          MenuFormatsRef.current &&
          !MenuFormatsRef.current.contains(event.target as Node) &&
          event.target !== buttonRef.current
        ) {
          onCloseColorsMenu();
        }
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpenDowloadModal, onCloseColorsMenu, buttonRef]);

  return (
    <Container
      ref={MenuFormatsRef}
      onMouseEnter={() => setIsOpenDowloadModal(true)}
      onMouseLeave={() => setIsOpenDowloadModal(false)}
      style={
        permissionType === "editor"
          ? {
            opacity: 0.2,
            cursor: "not-allowed"
          }
          : {}
      }
    >
      <div>
        <img src={DowloadICon} alt="" /> Baixar
      </div>

      <PersonalizedIcon
        dPath={MountIcons.IconArrowRight.dPath}
        viewBox={MountIcons.IconArrowRight.viewBox}
        inactivatedColor="#676B70"
      />

      {isOpenDowloadModal && (
        <SubModal>
          {isBitsAcademyEmail && (
            <>
              <ItemSubModal onClick={handleDownloadNewPDF}>
                <img src={PdfICon} alt="" />
                <p>PDF Novo</p>
              </ItemSubModal>
              <ItemSubModal onClick={handleDownloadNewWord}>
                <img src={WordICon} alt="" />
                <p>Word Novo</p>
              </ItemSubModal>
            </>
          )}
          <ItemSubModal onClick={handleDownloadPDF}>
            <img src={PdfICon} alt="" />
            <p>PDF</p>
          </ItemSubModal>
          <Tooltip
            description={
              typeUserAccount === "premium"
                ? "Faça o download em Word."
                : "Assine o UX DOC para fazer o download em Word."
            }
            placement="bottom-start"
            maxWidth={180}
          >
            <ItemSubModal onClick={handleDownloadWord}>
              <img src={WordICon} alt="" />
              <p>Word</p>
            </ItemSubModal>
          </Tooltip>
        </SubModal>
      )}
    </Container>
  );
};

export default ButtonDowloadDocument;
