import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 24px;
  height: 24px;
  fill: currentColor;
`;

type Props = {
  color?: string;
};

const ShareIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 24 24" color={color || "#676B70"}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6294 8.17822H17.8489V10.5489H15.4783V12.3293H17.8489V14.7H19.6294V12.3293H22V10.5489H19.6294V8.17822Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.23453 15.1159C4.02498 14.3254 5.09707 13.8813 6.21495 13.8813H12.8267C13.9445 13.8813 15.0166 14.3254 15.8071 15.1159C16.5975 15.9063 17.0416 16.9784 17.0416 18.0963V19.7492C17.0416 20.2513 16.6346 20.6583 16.1325 20.6583C15.6304 20.6583 15.2234 20.2513 15.2234 19.7492V18.0963C15.2234 17.4606 14.9709 16.851 14.5214 16.4015C14.0719 15.952 13.4623 15.6995 12.8267 15.6995H6.21495C5.57929 15.6995 4.96966 15.952 4.52018 16.4015C4.0707 16.851 3.81818 17.4606 3.81818 18.0963V19.7492C3.81818 20.2513 3.41117 20.6583 2.90909 20.6583C2.40701 20.6583 2 20.2513 2 19.7492V18.0963C2 16.9784 2.44407 15.9063 3.23453 15.1159Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.52037 5.78156C8.19667 5.78156 7.1236 6.85463 7.1236 8.17833C7.1236 9.50202 8.19667 10.5751 9.52037 10.5751C10.8441 10.5751 11.9171 9.50202 11.9171 8.17833C11.9171 6.85463 10.8441 5.78156 9.52037 5.78156ZM5.30542 8.17833C5.30542 5.85047 7.19252 3.96338 9.52037 3.96338C11.8482 3.96338 13.7353 5.85047 13.7353 8.17833C13.7353 10.5062 11.8482 12.3933 9.52037 12.3933C7.19252 12.3933 5.30542 10.5062 5.30542 8.17833Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default ShareIcon;
