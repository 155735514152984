import styled from "styled-components";
import { Theme } from "../../../../../dtos/theme";

interface StrokeBarProps {
  size: number;
  isActive?: boolean;
}

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropDownContainer = styled.div`
  margin: 0 auto;
  display: flex;
`;

export const DropDownHeader = styled.div<{ Open: boolean }>`
  display: block;

  &:hover {
    cursor: pointer;
  }
`;

export const DropDownListContainer = styled.div``;

export const DropDownList = styled.ul`
  width: 162px;
  background-color: #fff;
  padding-block: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  position: absolute;
  top: 35px;
  left: 104px;

  font-size: 12px;
  font-weight: 400;
  line-height: 150%;

  &:hover {
    cursor: pointer;
  }
`;

export const HorizontalStack = styled.div<{ isActive?: boolean }>`
  height: 24px;
  padding-inline: 10px 5px;

  display: flex;
  gap: 10px;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? "#f5f5f5" : "#FFF")};

  :hover {
    background-color: #f5f5f5;
  }

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

export const TextStroke = styled.span<{ isActive?: boolean }>`
  color: #000;
`;

export const StrokeBar = styled.div<StrokeBarProps>`
  height: ${({ size }) => `${size}px `};
  width: 80px;

  background-color: #000;
`;
