import React, { useCallback, useEffect, useState } from "react";

import ButtonPrimary from "../ButtonPrimary";
import { InputModal } from "../InputModal";
import {
  BoxButton,
  ModalContainer,
  ModalShowClausule,
  Title,
  Text,
  Button,
  Category,
  LoadingContainer
} from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import api from "../../../../services/api";

import PuffLoader from "react-spinners/PuffLoader";

import { LoadingFlex } from "../../../user/unknown/login/pages/Login/styles";
import { useSelection } from "../../hooks/selection";
import { useMainHook } from "../../../../hooks/main";

import { toast } from "react-toastify";
import iconCopy from "../../../dashboard/assets/copy-icon.svg";

import { GrClose } from "react-icons/gr";
import { Modal } from "@material-ui/core";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonExit from "../../../../shared/components/ButtonExit";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";

interface NewClausesModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  idClause?: string;
  refreshClause?: boolean;
  setRefreshClause?: React.Dispatch<React.SetStateAction<boolean>>;
  indexClause?: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SpecificClauseProps {
  clause?: string;
  created_at?: string;
  description?: string;
  id?: string;
  is_favorite?: boolean;
  key_words?: string;
  title?: string;
  updated_at?: string;
  user_id?: string;
}

interface ObjectClauseUpdate {
  user_clause_id: string;
  title: string;
  clause: string;
  description: string;
  is_favorite: boolean;
  key_words?: string;
}

export const ModalViewClauses: React.FC<NewClausesModalProps> = ({
  isOpen,
  onRequestClose,
  idClause,
  setIsModalOpen
}) => {
  const {
    blockCommandKeyboard,
    setBlockCommandKeyboard,
    forceBlockKey,
    setForceBlockKey
  } = useSelection();

  const { textClauseView, setTextClauseView, isTeamClause, setIsTeamClause } =
    useMainHook();

  const [loading, setLoading] = useState(false);
  const [categoryClauseBits, setCategoryClauseBits] = useState([]);
  const [categoryClauseTeam, setCategoryClauseTeam] = useState("");
  const [valueClause, setValueClause] = useState("");
  const [titleClause, setTitleClause] = useState("");

  useEffect(() => {
    (async () => {
      if (!isTeamClause) {
        try {
          setLoading(true);
          const responseClause = await api.get(`bits-clause/${textClauseView}`);
          setValueClause(responseClause?.data?.bitsClause?.clause);
          // setNameClause(responseClause?.data?.bitsClause.)
          setCategoryClauseBits(responseClause?.data.bitsClauseCategories);
          setLoading(false);
        } catch (err) {
          console.error(err, "error when get specific clause");
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const responseClause = await api.get(
            `team-clauses/list-specific/${textClauseView}`
          );

          setValueClause(responseClause?.data?.clause);
          setTitleClause(responseClause?.data?.title);
          setCategoryClauseTeam(responseClause?.data?.key_words);
          setLoading(false);
        } catch (err) {
          console.error(err, "error when get specific clause");
          setLoading(false);
        }
      }
    })();

    return () => {
      if (isOpen) {
        setValueClause("");
        setIsTeamClause(false);
        setTitleClause("");
        setCategoryClauseBits([]);
        setCategoryClauseTeam("");
      }
    };
  }, [textClauseView, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setForceBlockKey(true);
    } else {
      setForceBlockKey(false);
    }
  }, [isOpen]);

  const handleCopyClause = useCallback((text: string) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Texto copiado com sucesso!");
    } catch (err) {
      console.error(err);
      toast.error("Erro ao copiar texto");
    }
  }, []);

  return (
    <Modal
      open={isOpen}
      children={
        <ModalShowClausule>
          <ModalContainer>
            {loading ? (
              <LoadingContainer>
                <ContractAnimation />
              </LoadingContainer>
            ) : (
              <>
                <Title>
                  <TitleH5
                    title={isTeamClause ? titleClause : "Texto UX DOC"}
                  />

                  <div>
                    <img
                      onClick={() => {
                        handleCopyClause(valueClause);
                      }}
                      src={iconCopy}
                      alt=""
                    />
                    <ButtonExit onClick={() => setIsModalOpen(false)} />
                  </div>
                </Title>
                <Category>
                  <p>Categoria:</p>
                  <ul>
                    {isTeamClause
                      ? categoryClauseTeam
                          .split(";")
                          .filter(item => item !== "")
                          .map((item, index) => {
                            if (index === 0) {
                              return <p key={item}>{item}</p>;
                            }

                            return <p key={item}>,{item} </p>;
                          })
                      : categoryClauseBits?.map((item, index) => {
                          if (index === 0) {
                            return <p key={item.title}>{item.title}</p>;
                          }

                          return <p key={item.title}>, {item.title} </p>;
                        })}
                  </ul>
                </Category>
                <Text>{valueClause}</Text>

                <BoxButton>
                  <ButtonMaxWidth
                    text="Voltar"
                    typeStyle="purple"
                    onClick={() => setIsModalOpen(false)}
                    loading={loading}
                  />
                </BoxButton>
              </>
            )}
          </ModalContainer>
        </ModalShowClausule>
      }
    />
  );
};
