/*
this is the component where all features, buttons, 
name document, and more will be.
we have features such as undo and redo, shared document,
amount of users that are joined in this document,
also create new project, save and download PDF.
*/

//set up styles
import {
  Container,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
  InputNameDocument,
} from "./styles";

import backIcon from "../../assets/icon-back.svg";
import undoIcon from "../../assets/icon-undo.svg";
import redoIcon from "../../assets/icon-redo.svg";
import savingAutomaticIcon from "../../assets/save-icon.svg";
import downloadIcon from "../../assets/icon-download.svg";

import { useCallback, useEffect, useRef, useState } from "react";
import { useHeaderEditor } from "../../hooks/headerEditor";
import { useMainHook } from "../../../../hooks/main";
import undoIconTransparent from "../../assets/icon-undo-transparent.svg";
import redoIconTransparent from "../../assets/icon-redo-transparent.svg";
import { useSelection } from "../../hooks/selection";
import { useTextsEdition } from "../../hooks/textsEdition";
import { useSharedDocument } from "../../hooks/sharedDocument";
import { useAuth } from "../../../../hooks/auth";
import DropdownAvatarDash from "../DropdownAvatarDash";
import api from "../../../../services/api";
import { getColors } from "../../utils/getColorAvatar";
import LoadingEditor from "../LoadingEditor";
import MultiplesAvatarLetter from "../../../dashboard/master/components/MultiplesAvatarLetter";
import { useParams } from "react-router-dom";

import ButtonsFeature from "./components/ButtonsFeature";
import SharedGroup from "./components/SharedGroup";
import RightButtons from "./components/RightButtons";
import { useAutomaticSaving } from "../../hooks/automaticSaving";
import { toast } from "react-toastify";

//set up interfaces
interface UsersSharedProps {
  id?: string;
  name?: string;
  email?: string;
  avatar_url?: string;
  permissions?: string;
  is_anonymous?: boolean;
  color?: string;
}

export default function Header() {
  //hooks

  const { documentName, history, srcAvatar, myProfileId } = useMainHook();
  const {
    handleSaveTemplate,
    handleGoHomePage,
    handleUndo,
    handleRedo,
    isSafetySave,
  } = useHeaderEditor();
  const {
    selectedObject,
    setSelectedObject,
    selectedObjects,
    setSelectedObjects,
  } = useSelection();
  const { isEditing, setIsEditing } = useTextsEdition();
  const { handleOpenModalSharedInfo } = useSharedDocument();
  const { isTemplateIsReady } = useAutomaticSaving();
  const { templateid, typetemplate } = useParams();
  const { data, permissionType } = useAuth();

  const [nameDoc, setNameDoc] = useState("");

  //set up states
  const [letters, setLetters] = useState("LUCAS JUSTE");
  // const [color, setColor] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showPen, setShowPen] = useState(false);
  const [isOwnerDocument, setIsOwnerDocument] = useState(false);
  const [isOpenAvatarConfig, setIsOpenAvatarConfig] = useState(false);

  //refs
  const refInputNameProject = useRef<HTMLInputElement>(null);

  //temporary
  const { refreshUsersShared } = useSharedDocument();
  // const { isEditing } = useTextsEdition();
  const [sharedUsers, setSharedUsers] = useState<UsersSharedProps[]>([]);

  //callback for when user move mouse inside some target
  //whe call this callback for unselected any object
  const handleMouseEnterSaveTemplate = useCallback(() => {
    setSelectedObject(null);
    setSelectedObjects([]);
  }, [selectedObject, selectedObjects]);

  const handleClosedText = useCallback(() => {
    // if (isEditing === "open") {
    //   setSelectedObject(null);
    //   setIsEditing("closed");
    // }
  }, [isEditing, setIsEditing, setSelectedObject]);

  //if in this project has a shared user group
  //we have put one timeout for prevent bug
  //when page load first time, sometime could
  //button shared is block, then timeout 1s prevent this issue.
  useEffect(() => {
    const autoLoadSharedUsers = async () => {
      if (typetemplate === 'team-template') return;
      try {
        const responseTemplatesUsers = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );

        const generateColorsInData = responseTemplatesUsers.data.users.map(
          (user: UsersSharedProps) => {
            return {
              ...user,
              color: getColors(),
            };
          }
        );

        setSharedUsers(generateColorsInData);
      } catch (err) {
        console.error(err, "ERROR when fetch users shared template");
      }
    };

    setTimeout(() => {
      autoLoadSharedUsers();
    }, 1000);
  }, [refreshUsersShared]);

  //generating color for each user
  //but if user have avatar, this is unecessary
  useEffect(() => {
    setSharedUsers((state) => {
      state.map((user) => {
        return {
          ...user,
          color: getColors(),
        };
      });

      return state;
    });
  }, [refreshUsersShared]);

  //this callback will be verify if current user is owner
  //of this project because only owner can delete members of group
  //for example, and more features
  useEffect(() => {
    if (myProfileId) {
      const getOwnerId = sharedUsers.find(
        (user) => user.permissions === "Owner"
      );
      if (getOwnerId?.id === myProfileId) {
        setIsOwnerDocument(true);
      } else {
        setIsOwnerDocument(false);
      }
    }
  }, [sharedUsers]);

  // this useEffect colects the name of the document to apply on the top
  useEffect(() => {
    (async () => {
      const request = typetemplate === "team-template"
        ? `team-templates/list-specific/${templateid}`
        : `user-templates/${templateid}`;
      try {
        const userTemplate = await api.get(request);
        const { title } = userTemplate.data;
        documentName.current = title;
        setNameDoc(title);
        document.title = `UX DOC - ${title}`;
      } catch (error) {
        console.error("error on getting title of template");
      }
    })();

    return () => {
      documentName.current = "";
    };
  }, []);

  return (
    <Container
      onMouseEnter={handleClosedText}
      id="get-height-horizontal_header-Editor"
    >
      <HeaderLeft>
        <ButtonsFeature
          handleGoHomePage={handleGoHomePage}
          backIcon={backIcon}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isEditing={isEditing}
          handleUndo={handleUndo}
          history={history}
          undoIconTransparent={undoIconTransparent}
          undoIcon={undoIcon}
          handleRedo={handleRedo}
          redoIconTransparent={redoIconTransparent}
          redoIcon={redoIcon}
        />
      </HeaderLeft>

      <HeaderCenter>
        {isTemplateIsReady ? (
          <InputNameDocument
            showPen={showPen}
            ref={refInputNameProject}
            type="text"
            disabled={!isSafetySave.current}
            placeholder="Novo documento"
            readOnly={permissionType === "editor" ? true : false}
            onMouseEnter={(e) => {
              setShowPen(!showPen);
            }}
            onClick={() => {
              if (!isSafetySave.current) {
                toast.warn("Aguarde o documento carregar...");
              }
            }}
            onMouseLeave={(e) => {
              setShowPen(!showPen);
            }}
            value={nameDoc}
            // defaultValue={nameDoc}
            onChange={(onChangeEvent: React.ChangeEvent<HTMLInputElement>) => {
              setNameDoc(onChangeEvent.target.value);
              documentName.current = onChangeEvent.target.value;
              document.title = `UX DOC - ${nameDoc}`;
            }}
            onBlur={() => handleSaveTemplate(true)}
          />
        ) : (
          <div className="wrapper-loading">
            <LoadingEditor height={14} width={14} color={"#FFF"} />
          </div>
        )}
      </HeaderCenter>

      <HeaderRight>
        <SharedGroup
          sharedUsers={sharedUsers}
          handleOpenModalSharedInfo={handleOpenModalSharedInfo}
        />

        <RightButtons
          handleMouseEnterSaveTemplate={handleMouseEnterSaveTemplate}
          saveIcon={savingAutomaticIcon}
          downloadIcon={downloadIcon}
          isOwnerDocument={isOwnerDocument}
          templateid={templateid}
        />

        {/*disable dropdown by request of tech
         team at organization bits*/}

        <DropdownAvatarDash
          isOpen={isOpenAvatarConfig}
          setIsOpen={setIsOpenAvatarConfig}
          isEditor={true}
        >
          <MultiplesAvatarLetter
            className="avatar-dashboard"
            src={String(srcAvatar)}
            isEditor={true}
            text={letters}
            setText={setLetters}
            color={getColors(true)}
          />
        </DropdownAvatarDash>
      </HeaderRight>
    </Container>
  );
}
