import { useCallback, useEffect, useState } from "react";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import DowloadICon from "../../../assets/dowload-icon.svg";
import PdfICon from "../../../assets/pdf-icon.svg";
import WordICon from "../../../assets/word-icon.svg";
import { Container, Item, ItemSubModal, SubModal } from "./styles";
import { useAuth } from "../../../../../hooks/auth";
import { toast } from "react-toastify";

import api from "../../../../../services/api";
import { useMetric } from "../../../../../hooks/metric";

const OptionsModal = ({
  handleOpenAutomation,
  id,
  handleDeleteAutomation,
  downloading,
  setDownloading,
  idToastifyDownloadRef,
  documentPreviewData,
  whichFileToBeDownload,
  setIsIntentBuyModal
}) => {
  const [isOpenDowloadModal, setIsOpenDowloadModal] = useState(false);
  const [typeUserAccount, setTypeUserAccount] = useState<
    "trial" | "premium" | "loading"
  >("loading");

  const { data } = useAuth();
  const { handleDowloadProjectAction } = useMetric();

  const handlePreviewDocument = useCallback(
    async (document_id: string, type: "pdf" | "word") => {
      let nameDoc: string;
      try {
        const fetchName = await api.get(`user-templates/${document_id}`);
        nameDoc = fetchName.data.title;
      } catch (err) {
        console.error(err)
      }
      const loadingtoast = toast.loading("Tudo certo. Aguarde um momento.");
      if (nameDoc) {
        try {
          const fetchDocumentPreview = await api.get(
            `user-templates/template/${document_id}`
          );

          documentPreviewData.current =
            fetchDocumentPreview.data.response.arrayOfPages;
          whichFileToBeDownload.current = type;
          idToastifyDownloadRef.current = nameDoc.replaceAll(".", "-");
          setTimeout(() => {
            toast.dismiss(loadingtoast);
          }, 1000);
          setDownloading(true);

          // Registrar ação de download do documento
          handleDowloadProjectAction({
            project_id: document_id,
            automated: false,
            type: type
          });
        } catch (error) {
          setTimeout(() => {
            toast.dismiss(loadingtoast);
          }, 1000);
          toast.error("Ops, algo deu errado!");

          console.error("error getting document to preview", error);
        }
      } else {
        toast.error("Algo deu errado, tente novamente mais tarde.");
      }
    },
    [
      documentPreviewData,
      idToastifyDownloadRef,
      setDownloading,
      whichFileToBeDownload
    ]
  );

  useEffect(() => {
    if (
      data.payment_method?.user_type === "user_free" &&
      !data.payment_method.subscription_id &&
      data.payment_method.cancellation_date &&
      data.payment_method.is_active
    ) {
      setTypeUserAccount("trial");
    } else {
      setTypeUserAccount("premium");
    }
  }, [data]);

  return (
    <Container>
      <Item
        onClick={() => {
          handleOpenAutomation(id);
        }}
      >
        <div>
          <PersonalizedIcon
            dPath={MountIcons.ExitIcon.dPath}
            viewBox={MountIcons.ExitIcon.viewBox}
            inactivatedColor="#676B70"
          />
          Abrir
        </div>
      </Item>
      <Item
        onMouseEnter={() => setIsOpenDowloadModal(true)}
        onMouseLeave={() => setIsOpenDowloadModal(false)}
      >
        <div>
          <img src={DowloadICon} alt="" /> Baixar
        </div>
        <PersonalizedIcon
          dPath={MountIcons.IconArrowRight.dPath}
          viewBox={MountIcons.IconArrowRight.viewBox}
          inactivatedColor="#676B70"
        />
        {isOpenDowloadModal && (
          <SubModal>
            <ItemSubModal onClick={() => handlePreviewDocument(id, "pdf")}>
              <img src={PdfICon} alt="" />
              <p>PDF</p>
            </ItemSubModal>
            <ItemSubModal
              onClick={() => handlePreviewDocument(id, "word")}
            >
              <img src={WordICon} alt="" />
              <p>Word</p>
            </ItemSubModal>
          </SubModal>
        )}
      </Item>
      <Item
        onClick={() => {
          handleDeleteAutomation(id);
        }}
      >
        <div>
          <PersonalizedIcon
            dPath={MountIcons.IconTrash.dPath}
            viewBox={MountIcons.IconTrash.viewBox}
            inactivatedColor="#676B70"
          />
          Excluir
        </div>
      </Item>
    </Container>
  );
};

export default OptionsModal;
