import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

