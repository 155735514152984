import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useMainHook } from "../../../hooks/main";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import Konva from "konva";
import api from "../../../services/api";
import { useSelection } from "./selection";
import { cloneDeep } from "lodash";
import { useMetric } from "../../../hooks/metric";
import { useWorkspaceEditor } from "./workspaceEditor";

import { usePagesEditor } from "./pagesEditor";
import { useTextsEdition } from "./textsEdition";
import { PagesProps } from "../../../dtos/PagesProps";
import { DeltaOperation } from "quill";
// import { useAutomaticSaving } from "./automaticSaving";

interface CustomDeltaOperation extends DeltaOperation {
  lineheight?: string | number;
}

type IObjectNew = {
  isNewDownload: boolean,
  isPDF: boolean,
  id: string
}

interface HeaderEditorContextData {
  handleSaveNewVersion: () => void;
  handleSaveTemplate: (isAutoSave?: Boolean) => Promise<void>;
  handleGoHomePage: () => void;
  organizeHistoryColors: (event: string, active?: boolean) => void;
  handleUndo: () => void;
  handleRedo: () => void;
  generatePDF: (format: "pdf" | "word") => void;
  generateNewPDF: (format: "pdf" | "word") => void;
  handleCreateNewProject: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  colorAvatar: string;
  setColorAvatar: React.Dispatch<React.SetStateAction<string>>;
  loadingButtonGoHome: boolean;
  setLoadingButtonGoHome: React.Dispatch<React.SetStateAction<boolean>>;
  isSavingAutomatic: boolean;
  setIsSavingAutomatic: React.Dispatch<React.SetStateAction<boolean>>;
  mountStages: boolean;
  setMountStages: React.Dispatch<React.SetStateAction<boolean>>;
  hasLoadPages: boolean;
  setHasLoadPages: React.Dispatch<React.SetStateAction<boolean>>;
  isSafetySave: React.MutableRefObject<boolean>;
  colorsHistory: { hexadecimal: string; }[];
  setColorsHistory: React.Dispatch<
    React.SetStateAction<{ hexadecimal: string; }[]>
  >;
  delayOrganizeHistoryColors: (color: string) => void;
  isNewDownloadObject: IObjectNew
  setIsNewDownloadObject: React.Dispatch<React.SetStateAction<IObjectNew>>
}

const HeaderEditorContext = createContext<HeaderEditorContextData>(
  {} as HeaderEditorContextData
);

const HeaderEditorProvider: React.FC = ({ children }) => {
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const {
    objectScreen,
    currentTemplateInfo,
    isTeamTemplate,
    setIsLoadingSavingTemplate,
    documentName,
    pendingSave,
  } = useMainHook();

  const { isEditing } = useTextsEdition();

  const {
    stageRef,
    history,
    setHistory,
    setObjectScreen,
    addToHistory,
    handleOpenModalBlockFuncionalities,
    autoNumber,
    setLastSaveDate,
    setCanvasIsLoading,
  } = useMainHook();

  const { handleDowloadProjectAction, handleCreatedProjectAction } =
    useMetric();

  const { linespage } = usePagesEditor();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSaveNewVersion = useCallback(() => { }, []);
  const { blockCommandKeyboard } = useSelection();
  const [colorAvatar, setColorAvatar] = useState("");
  const [isSavingAutomatic, setIsSavingAutomatic] = useState(false);
  const { setLoadingTemplate, containerRef } = useWorkspaceEditor();
  const [mountStages, setMountStages] = useState(false);
  const [loadingButtonGoHome, setLoadingButtonGoHome] = useState(false);
  const [hasLoadPages, setHasLoadPages] = useState(true);
  const isSafetySave = useRef(false);
  const { currentMultipleSelection, setSelectedObject, setSelectedObjects } =
    useSelection();
  const [colorsHistory, setColorsHistory] = useState<{ hexadecimal: string; }[]>(
    []
  );
  const [isNewDownloadObject, setIsNewDownloadObject] = useState<IObjectNew>({} as IObjectNew)

  // const { initialLoad } = useAutomaticSaving();

  const organizeHistoryColors = useCallback(
    (event: string, active: boolean = false) => {
      // Rule about colors with 3 words or 6 words, based in input colors
      const validateHex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
      const improvedList = new RegExp(validateHex);
      const searchColor = !!colorsHistory.find(
        (item) => item.hexadecimal === event
      );
      if (improvedList.test(event) && !searchColor) {
        setColorsHistory((prevState) => {
          const newState = [
            {
              active,
              hexadecimal: event,
            },
            ...prevState,
          ];
          return newState.slice(0, 7);
        });
      }
    },
    [colorsHistory]
  );

  const delayOrganizeHistoryColors = (color: string) => {
    setTimeout(() => {
      organizeHistoryColors(color);
    }, 3000);
  };

  const handleCreateNewProject = useCallback(async () => {
    setLoadingTemplate(true);
    const newProject = {
      template: {
        arrayOfPages: [{ pageNumber: 1, renderObjects: [] }],
      },
      title: `Novo documento`,
      description: `Novo documento`,
      is_automation: false,
    };
    setObjectScreen(newProject.template.arrayOfPages);
    try {
      const request = await api.post("user-templates", newProject);
      const idNewProject = request.data.id;
      //created project action register function
      handleCreatedProjectAction({
        project_id: idNewProject,
        creation_origin: "New Template",
        template_id: false,
        template_bits_name: false,
        researched: false,
      });

      navigate(`/editor/my-template/${idNewProject}`);
      // window.location.reload();
    } catch (err) {
      console.error(err?.response?.data, "error when create new project");
    }
  }, [
    handleCreatedProjectAction,
    navigate,
    setLoadingTemplate,
    setObjectScreen,
  ]);

  //fixing issues when upper or lower case
  const myFunc = useCallback(
    (evt: KeyboardEvent) => {
      if (
        (evt.key === "z" && evt.ctrlKey) ||
        (evt.key === "Z" && evt.ctrlKey)
      ) {
        evt.preventDefault();

        if (!blockCommandKeyboard) {
          handleUndo();
        }
      } else if (
        (evt.key === "y" && evt.ctrlKey) ||
        (evt.key === "Y" && evt.ctrlKey)
      ) {
        if (!blockCommandKeyboard) {
          handleRedo();
        }
      }
    },
    [history, blockCommandKeyboard]
  );

  useEffect(() => {
    window.addEventListener("keydown", myFunc);
    return () => window.removeEventListener("keydown", myFunc);
  }, [history, blockCommandKeyboard]);

  const handleGenerateThumbDoc = useCallback(
    async (dataURL: string, isTeam: boolean = false, firstTime: boolean, isDownload: boolean = false) => {
      const blob = await (await fetch(dataURL)).blob();
      const file = new File([blob], "fileName.png", { type: "image/png" });
      let formData = new FormData();
      formData.append("thumbnail", file);

      try {
        setLoading(true);
        const fetchUploadPicture = await api.patch(
          isTeam
            ? `team-templates/thumbnail-upload/${currentTemplateInfo.current.id}`
            : `user-templates/thumbnail-upload/${currentTemplateInfo.current.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setCanvasIsLoading(false);
        setLoading(false);
        setLastSaveDate(fetchUploadPicture.data.updated_at);
        if (firstTime && !isDownload) {
          toast.success('O seu documento está pronto para uso.');
          setFirstTime(prevState => !prevState);
        }
      } catch (error) {
        console.error("error uploading thumbnail2", error.response.data);
        setLoading(false);
        setCanvasIsLoading(false);
        setIsLoadingSavingTemplate(false);
      }
    },
    [currentTemplateInfo, setIsLoadingSavingTemplate, setLastSaveDate]
  );

  const handleSaveTemplate = useCallback(
    async (isAutoSave: boolean = false, isDownload: boolean = false) => {
      if (mountStages && !isDownload) return;
      if (!isSafetySave.current) return;
      if (!isDownload) setIsLoadingSavingTemplate(true);
      const firstPage = 0;
      const stages = stageRef.current as Konva.Stage[];
      const formatedObjectToApi: any[] = [];

      if (stages[firstPage]?.attrs?.id === "1") {
        const dataURL = stages[firstPage]?.toDataURL();
        await handleGenerateThumbDoc(dataURL, isTeamTemplate, firstTime, isDownload);
      }

      const hasObjects = objectScreen.some(
        (page) => page.renderObjects.length > 0
      );

      if (hasObjects) {
        formatedObjectToApi.push(
          ...objectScreen.map((page, index) => {
            const pageNumber = index + 1;
            const renderObjects = page.renderObjects.map((renderObject) => {
              const { image, lineHeight, format, ...rest } = renderObject || {};
              // Verifica se a propriedade lineheight não está vazia
              if (lineHeight !== "") {
                // Verifica se o objeto format e ops[0] existem e faz a atribuição
                if (format && format.ops[0].attributes) {
                  const firstOp = format.ops[0].attributes as CustomDeltaOperation;

                  if (!firstOp?.lineheight) {
                    format.ops[0].attributes.lineheight = ""
                  }
                  if (firstOp?.lineheight || firstOp?.lineheight === "") {
                    format.ops[0].attributes.lineheight = lineHeight
                  }
                }
              }
              return image ? rest : renderObject;
            });

            return {
              pageNumber,
              renderObjects,
              lineGuide: {
                h: linespage[pageNumber - 1]?.lineGuidH,
                v: linespage[pageNumber - 1]?.lineGuidV,
              },
            };
          })
        );

        const hasObjectsToSave = formatedObjectToApi.some(
          (page: PagesProps) => page?.renderObjects?.length > 0
        );

        if (objectScreen.length === formatedObjectToApi.length) {
          if (hasObjectsToSave) {
            const objectType = isTeamTemplate
              ? "team-templates"
              : "user-templates";
            const objectData = isTeamTemplate
              ? {
                team_template_id: `${currentTemplateInfo.current.id}`,
                template: { arrayOfPages: formatedObjectToApi },
                title: documentName.current || "Documento sem nome",
                is_automatic_numbering_active: autoNumber.isActive,
                automatic_numbering_position: autoNumber.position,
                first_page_automatic_numbering: autoNumber.firstPage,
                recent_colors:
                  colorsHistory.length > 0
                    ? `${colorsHistory.map((colors) => colors.hexadecimal)}`
                    : undefined,
              }
              : {
                user_template_id: `${currentTemplateInfo.current.id}`,
                template: { arrayOfPages: formatedObjectToApi },
                title: documentName.current || "Documento sem nome",
                is_automatic_numbering_active: autoNumber.isActive,
                automatic_numbering_position: autoNumber.position,
                first_page_automatic_numbering: autoNumber.firstPage,
                recent_colors:
                  colorsHistory.length > 0
                    ? `${colorsHistory.map((colors) => colors.hexadecimal)}`
                    : undefined,
              };
            if (objectScreen.length === formatedObjectToApi.length) {
              try {
                const responseSaving = await api.put(objectType, objectData);

                if (!isAutoSave) {
                  toast.success("Template salvo com sucesso!");
                }

                if (responseSaving.status === 200) {
                  setIsLoadingSavingTemplate(false);
                } else {
                  toast.error("Erro ao salvar o documento.");
                }
              } catch (error) {
                toast.error(
                  "Deu algo errado com o salvamento. Tente novamente mais tarde."
                );
                setIsLoadingSavingTemplate(false);
                console.error("Error trying to save user template " + error);
              }
            } else {
              // toast.error("ERRO: Não foi possível salvar o seu documento. Tente novamente mais tarde.")
              setIsLoadingSavingTemplate(false);
            }
          } else {
            setIsLoadingSavingTemplate(false);
          }
        } else {
          setIsLoadingSavingTemplate(false);
        }
      } else {
        setIsLoadingSavingTemplate(false);
      }
    },
    [
      mountStages,
      setIsLoadingSavingTemplate,
      stageRef,
      objectScreen,
      handleGenerateThumbDoc,
      linespage,
      isTeamTemplate,
      currentTemplateInfo,
      documentName,
      autoNumber,
      isSafetySave,
      colorsHistory,
    ]
  );

  const handleDownloadPDF = useCallback(
    async (url: string, filename: string) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(fileUrl);
        toast.success("Seu documento foi baixado com sucesso.");

        try {
          await api.post("download/delete", {
            filename,
          });
        } catch (err) {
          console.error(err, "error when delete url of pdf");
        }
      } catch (err) {
        toast.error("Ops! Deu algo de errado, tente novamente mais tarde.");
        console.error(err, "error fetch url");
      }
    },
    []
  );
  //check stages
  const waitForStages = useCallback(async (): Promise<void> => {
    return new Promise<void>((resolve) => {
      const checkStages = () => {
        if (stageRef.current.length === objectScreen.length) {
          resolve();
        } else {
          setTimeout(checkStages, 100);
        }
      };

      checkStages();
    });
  }, [stageRef, objectScreen]);

  const generateNewPDF = useCallback(
    async (format: "pdf" | "word") => {
      if (hasLoadPages) {
        toast.warn("Aguarde o documento pode estar carregando.");
        return;
      }
      
      setMountStages(true);
      await handleSaveTemplate(true, true)
      let changes = false;

      objectScreen.forEach((page, index) => {
        page.renderObjects.forEach((renderedObject, index) => {
          if (renderedObject.text && !changes) {
            changes = renderedObject.isModify;
          }
        });
      });

      if (changes) {
        handleOpenModalBlockFuncionalities("download");
        setMountStages(false);
      } else {
        containerRef.current.scrollTop = 1;
        setMountStages(true);
        let allStagesAlreadyToUse = false;
        const amountPages = objectScreen.length;

        setObjectScreen((oldState) => {
          const clone = cloneDeep(oldState);

          const newState = clone.map((page, index) => {
            const clonePage = cloneDeep(page);

            if (index + 1 === amountPages) {
              allStagesAlreadyToUse = true;
            }

            return {
              ...clonePage,
              visible: true,
            };
          });

          return newState;
        });

        if (allStagesAlreadyToUse) {
          setTimeout(async () => {
            await waitForStages();

            try {
              const sendBase = await api.post(`download/${format === 'pdf' ? 'new-pdf' : 'docx'}`, {
                user_template_id: currentTemplateInfo.current.id
              });
              const nameDoc = `${documentName.current.replaceAll(".", "-")}`;

              if (sendBase.data.url) {
                await handleDownloadPDF(sendBase.data.url, nameDoc);
              }

              setObjectScreen((oldState: PagesProps[]) => {
                let clone = cloneDeep(oldState);
                const newState: PagesProps[] = clone.map((page, index) => {
                  const newPage = cloneDeep(page);
                  newPage.visible = index <= 1;
                  return newPage;
                });

                return newState;
              });
              setMountStages(false);
            } catch (err) {
              toast.error(
                "Ocorreu um erro ao gerar seu documento, tente novamente depois."
              );
              setMountStages(false);
              console.error(err?.response?.data, "error generate PDF");
            }

            // Registrar ação de download do documento...
            handleDowloadProjectAction({
              project_id: currentTemplateInfo.current.id,
              automated: false,
              type: format,
            });
          }, 2500);
        }
      }
    },
    [
      objectScreen,
      handleOpenModalBlockFuncionalities,
      containerRef,
      setObjectScreen,
      waitForStages,
      stageRef,
      handleDowloadProjectAction,
      currentTemplateInfo,
      documentName,
      handleDownloadPDF,
      hasLoadPages,
      handleSaveTemplate,
    ]
  );

  const generatePDF = useCallback(
    async (format: "pdf" | "word") => {
      if (hasLoadPages) {
        toast.warn("Aguarde o documento pode estar carregando.");
        return;
      }

      setMountStages(true);
      let changes = false;

      objectScreen.forEach((page, index) => {
        page.renderObjects.forEach((renderedObject, index) => {
          if (renderedObject.text && !changes) {
            changes = renderedObject.isModify;
          }
        });
      });

      if (changes) {
        handleOpenModalBlockFuncionalities("download");
        setMountStages(false);
      } else {
        containerRef.current.scrollTop = 1;
        setMountStages(true);
        let allStagesAlreadyToUse = false;
        const amountPages = objectScreen.length;

        setObjectScreen((oldState) => {
          const clone = cloneDeep(oldState);

          const newState = clone.map((page, index) => {
            const clonePage = cloneDeep(page);

            if (index + 1 === amountPages) {
              allStagesAlreadyToUse = true;
            }

            return {
              ...clonePage,
              visible: true,
            };
          });

          return newState;
        });

        if (allStagesAlreadyToUse) {
          setTimeout(async () => {
            await waitForStages();

            let documentPages = [];
            stageRef.current.forEach((page) => {
              const data_url = page.toDataURL({
                pixelRatio: 2,
              });
              documentPages.push({ base64: data_url });
            });

            try {
              const pages = {
                page: documentPages,
                file_type: format, // ou "word"
              };
              const sendBase = await api.post(`download`, pages);
              const nameDoc = `${documentName.current.replaceAll(".", "-")}`;

              if (sendBase.data.url) {
                await handleDownloadPDF(sendBase.data.url, nameDoc);
              }

              setObjectScreen((oldState: PagesProps[]) => {
                let clone = cloneDeep(oldState);
                const newState: PagesProps[] = clone.map((page, index) => {
                  const newPage = cloneDeep(page);
                  newPage.visible = index <= 1;
                  return newPage;
                });

                return newState;
              });
              setMountStages(false);
            } catch (err) {
              toast.error(
                "Ocorreu um erro ao gerar seu documento, tente novamente depois."
              );
              setMountStages(false);
              console.error(err?.response?.data, "error generate PDF");
            }

            // Registrar ação de download do documento...
            handleDowloadProjectAction({
              project_id: currentTemplateInfo.current.id,
              automated: false,
              type: format,
            });
          }, 2500);
        }
      }
    },
    [
      objectScreen,
      handleOpenModalBlockFuncionalities,
      containerRef,
      setObjectScreen,
      waitForStages,
      stageRef,
      handleDowloadProjectAction,
      currentTemplateInfo,
      documentName,
      handleDownloadPDF,
      hasLoadPages,
    ]
  );

  const handleGoHomePage = useCallback(async () => {
    if (isEditing === "open") {
      alert(
        "Estamos salvando seu documento. Aguarde alguns segundos antes de sair do editor."
      );

      return;
    }

    if (pendingSave.current) {
      await handleSaveTemplate(true);
      setIsLoadingSavingTemplate(true);
      setIsSavingAutomatic(true);
      setLoadingButtonGoHome(true);
      setSelectedObject(null);
      setSelectedObjects([]);
      setTimeout(() => {
        pendingSave.current = false;
        navigate(-1);
        setIsLoadingSavingTemplate(false);
        setIsSavingAutomatic(false);
        setLoadingButtonGoHome(false);
      }, 500);
      return;
    }

    setIsLoadingSavingTemplate(true);
    setIsSavingAutomatic(true);
    setLoadingButtonGoHome(true);
    setSelectedObject(null);
    setSelectedObjects([]);
    setTimeout(() => {
      pendingSave.current = false;
      navigate(-1);
      setIsLoadingSavingTemplate(false);
      setIsSavingAutomatic(false);
      setLoadingButtonGoHome(false);
    }, 500);
  }, [
    setLoadingButtonGoHome,
    setSelectedObject,
    setIsLoadingSavingTemplate,
    setSelectedObjects,
    navigate,
    isEditing,
    pendingSave,
    handleSaveTemplate,
  ]);

  useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      if (pendingSave.current) {
        toast.warn(
          "Não se preocupe, aguarde que iremos salvar para você. Aguarde e tente novamente."
        );
        const isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (!isSafari) {
          const confirmationMessage = "Deseja sair sem salvar?";
          event.returnValue = confirmationMessage;
          return confirmationMessage;
        }

        await handleSaveTemplate();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [pendingSave]);

  const handleUndo = useCallback(() => {
    // cleaning mutipleSelection
    setSelectedObject(null);
    setSelectedObjects([]);
    currentMultipleSelection.current?.setNodes([]);

    if (history.currentStep === 0 || !history.step[0].length) return;

    setTimeout(() => {
      const positionScroll = containerRef.current.scrollTop;
      containerRef.current.scrollTop = positionScroll + 0.00001;
    }, 300);

    if (history.currentStep === history.step.length) {
      addToHistory(true);
    }

    if (history.currentStep === 1) {
      setHistory((prevState) => {
        setObjectScreen(prevState.step[1]);
        setObjectScreen(prevState.step[0]);
        return prevState;
      });

      pendingSave.current = true;
    }

    setHistory((prevState) => {
      let cloneState = cloneDeep(prevState);
      cloneState.currentStep = cloneState.currentStep - 1;
      setObjectScreen(cloneState.step[cloneState.currentStep]);
      return cloneState;
    });
    pendingSave.current = true;
  }, [
    setSelectedObject,
    setSelectedObjects,
    currentMultipleSelection,
    history,
    setHistory,
    containerRef,
    addToHistory,
    setObjectScreen,
    pendingSave,
  ]);

  const handleRedo = useCallback(() => {
    setSelectedObject(null);
    setSelectedObjects([]);
    currentMultipleSelection.current?.setNodes([]);
    if (history.currentStep + 1 === history.step.length) return;

    setHistory((prevState) => {
      let cloneState = cloneDeep(prevState);

      cloneState.currentStep = cloneState.currentStep + 1;
      setObjectScreen(cloneState.step[cloneState.currentStep]);

      return cloneState;
    });
    pendingSave.current = true;
    setTimeout(() => {
      const positionScroll = containerRef.current.scrollTop;
      containerRef.current.scrollTop = positionScroll + 0.00001;
    }, 300);
  }, [
    setSelectedObject,
    setSelectedObjects,
    currentMultipleSelection,
    history,
    setHistory,
    containerRef,
    setObjectScreen,
    pendingSave,
  ]);

  return (
    <HeaderEditorContext.Provider
      value={{
        handleSaveNewVersion,
        handleSaveTemplate,
        handleGoHomePage,
        generateNewPDF,
        generatePDF,
        handleUndo,
        handleRedo,
        handleCreateNewProject,
        loading,
        setLoading,
        colorAvatar,
        setColorAvatar,
        loadingButtonGoHome,
        setLoadingButtonGoHome,
        isSavingAutomatic,
        setIsSavingAutomatic,
        mountStages,
        setMountStages,
        hasLoadPages,
        setHasLoadPages,
        isSafetySave,
        colorsHistory,
        setColorsHistory,
        organizeHistoryColors,
        delayOrganizeHistoryColors,
        isNewDownloadObject,
        setIsNewDownloadObject
      }}
    >
      {children}
    </HeaderEditorContext.Provider>
  );
};

// creating hook

function useHeaderEditor(): HeaderEditorContextData {
  const context = useContext(HeaderEditorContext);

  if (!context) {
    throw new Error("useAuth must be used with an HeaderEditorProvider");
  }

  return context;
}

export { HeaderEditorProvider, useHeaderEditor };
