import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HStack = styled.div`
  display: flex;
  gap: 20px;
`;

export const ButtonDocument = styled.button<{ color: string; }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-inline: 16px;

  height: 40px;
  border-radius: 5px;

  background-color: ${({ theme, color }) =>
    color === "white" ? theme.colors.white100 : theme.colors.primary90};

  border: solid 1px ${({ theme }) => theme.colors.primary90};
  color: ${({ theme, color }) =>
    color === "white"
      ? theme.colors.primary90
      : theme.colors.white100} !important;

  font-size: 14px;
  font-weight: 500;
  line-height: 100%;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const WrapperPagination = styled.div``;

export const BoxTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  @media (max-height: 560px) {
    min-height: 700px;
  }
`;

export const NavMain = styled.div`
  display: flex;
  border-bottom: solid 1px #ebebec;
`;

export const Button = styled.div<{ isActive: boolean; }>`
  background-color: #fff;
  padding: 13px 16px;
  text-decoration: none;

  border-bottom: solid 3px
    ${({ theme, isActive }) =>
    isActive ? theme.colors.primary90 : theme.colors.white100};

  a {
    color: ${({ theme, isActive }) =>
    isActive ? theme.colors.black100 : theme.colors.black75};

    display: flex;
    align-items: center;
    gap: 8px;

    text-decoration: none;
  }

  svg,
  img {
    width: 24px;
    height: 24px;
  }
`;

export const NavFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const OptionsFilters = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  padding: 0px 0px 10px 0px;
`;

export const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
  margin-top: 10px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    min-height: 30px;
    min-width: 77px;
    font-size: 14px;
    font-weight: 500;

    :first-child {
      color: ${({ theme }) => theme.colors.primary90};
      border: 1px solid ${({ theme }) => theme.colors.primary90};
      background: ${({ theme }) => theme.colors.whiteColor};
    }
    :last-child {
      color: ${({ theme }) => theme.colors.whiteColor};
      border: 1px solid ${({ theme }) => theme.colors.whiteColor};
      background: ${({ theme }) => theme.colors.primary90};
    }
  }
`;

export const ButtonFilter = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #7547A3;
  background: none;
  border-radius: 4px;
  gap: 10px;
  color: #7547A3;
  font-weight: 500;


  svg,
  img {
    width: 16px;
    height: 16px;
  }
`;

export const SelectBox = styled.div`

  display: flex;

  select, input {
    width: 100%;
    max-height: 30px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.black50};

    select:focus, input:focus {
      border: 1px solid ${({ theme }) => theme.colors.focus};
    }
    
    select::placeholder, input::placeholder {
      color: #d6d8d9;
    }
  }
`;

export const Dropdown = styled.div`
  max-width: 460px;
  gap: 20px;
  top: 225px;
  position: absolute;
  border-radius: 5px;
  background: var(--white, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 999;
  padding: 16px;

  label {
    color: var(--preto-preto-100, #343a40);

    /* legenda */
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    text-align: left;
  }
`;

export const ButtonsArray = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: 1px solid ${({theme}) => theme.colors.black100};
  background: ${({theme}) => theme.colors.whiteColor};
  padding: 0px 10px;

  span {
    color: var(--preto-preto-100, #343a40);
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  svg,
  img {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`

export const RangeDateInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const DivMap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`


