import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 24px;
  height: 24px;
  fill: currentColor;
`;

const FilterOffIcon = () => (
  <StyledSvg viewBox="0 0 24 24">
    <mask
      id="mask0_10709_12598"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_10709_12598)">
      <path
        d="M14.7999 11.9748L13.3749 10.5498L16.9499 5.9998H8.8249L6.8249 3.9998H18.9999C19.4166 3.9998 19.7166 4.18314 19.8999 4.5498C20.0832 4.91647 20.0499 5.26647 19.7999 5.5998L14.7999 11.9748ZM19.7749 22.5998L13.9999 16.8248V18.9998C13.9999 19.2831 13.9041 19.5206 13.7124 19.7123C13.5207 19.904 13.2832 19.9998 12.9999 19.9998H10.9999C10.7166 19.9998 10.4791 19.904 10.2874 19.7123C10.0957 19.5206 9.9999 19.2831 9.9999 18.9998V12.8248L1.3999 4.2248L2.7999 2.7998L21.1999 21.1998L19.7749 22.5998Z"
        fill="#7547A3"
      />
    </g>
  </StyledSvg>
);

export default FilterOffIcon;
