import styled from "styled-components";

export const InputDate = styled.div`
  width: 100%;

  input {
    width: 209.887px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    height: 22px;
    width: 24px;
    opacity: 0.5;
  }
`;
export const InputBox = styled.div`
  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }

  input {
    width: 100%;
    max-height: 40px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.black50};
    margin-top: 5px;

    @media (min-width: 1920px) {
      height: 43px;
      max-height: 43px;
    }

    :focus {
      border: 1px solid ${({ theme }) => theme.colors.focus};
    }

    ::placeholder {
      color: #d6d8d9;
    }
  }
`;

export const StyledButton = styled.button`
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background-color: #663399;
  color: white;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 4px 0px #663399;
  }
`;

export const ClearButton = styled.button`
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  color: gray;
  cursor: pointer;
  background-color: #ebebec;
  &:hover {
    background-color: #ebebec;
  }
`;

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #343a40;
  margin-top: 12px;
  input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999c9f;
    border-radius: 3px;
    cursor: pointer;
    &:checked {
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      &::after {
        content: "";
        display: block;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: relative;
        top: 2px;
        left: 5px;
      }
    }
  }
`;
