import React from "react";

interface BaseIconProps {
  isActive?: boolean;
  className?: string;
}

export default function AllToFront({ isActive, className }: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M11 1.99951H5C3.35 1.99951 2 3.34951 2 4.99951V10.9995C2 12.6495 3.35 13.9995 5 13.9995H11C12.65 13.9995 14 12.6495 14 10.9995V4.99951C14 3.34951 12.65 1.99951 11 1.99951Z"
        fill="#343A40"
      />
      <path
        d="M15 18H9C7.35 18 6 16.65 6 15H7C7 16.1 7.9 17 9 17H15C16.1 17 17 16.1 17 15V9C17 7.9 16.1 7 15 7V6C16.65 6 18 7.35 18 9V15C18 16.65 16.65 18 15 18Z"
        fill="#343A40"
        stroke="#343A40"
      />
      <path
        d="M19 22.0005H13C11.6 22.0005 10.35 21.0005 10.05 19.6005C10 19.4005 10 19.2005 10 19.0005H11C11 19.1505 11 19.2505 11.05 19.4005C11.25 20.3005 12.05 21.0005 13 21.0005H19C20.1 21.0005 21 20.1005 21 19.0005V13.0005C21 12.0505 20.35 11.2505 19.4 11.0505C19.25 11.0005 19.15 11.0005 19 11.0005V10.0005C19.2 10.0005 19.4 10.0005 19.6 10.0505C21 10.3505 22 11.5505 22 13.0005V19.0005C22 20.6505 20.65 22.0005 19 22.0005Z"
        fill="#343A40"
        stroke="#343A40"
      />
    </svg>
  );
}
