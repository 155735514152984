import React, { useCallback, useEffect, useState } from "react";
import { Container, Form, FooterForm, LoadingFlex } from "./styles";

import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useForm, SubmitHandler } from "react-hook-form";

import { toast, ToastContainer } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAuth } from "../../../../../../hooks/auth";

import { ContractAnimation } from "../../../../../../shared/components/ContractAnimation";

import { useTheme } from "../../../../../../hooks/theme";

import UxDocLogoLarge from "../../../../../../shared/components/UxDocLogoLarge";
import TitleH3 from "../../../../../../shared/components/TitleH3";
import InputForm from "../../../../../../shared/components/InputForm";
import InputPassword from "../../../../../../shared/components/InputPassword";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import AlertError from "../../../../../../shared/components/AlertError";

interface Inputs {
  email: string;
  password: string;
}

interface SubmitPropsLogin {
  email?: string;
  password?: string;
}

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { createdAccount, setCreatedAccount } = useAuth();
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [dataError, setDataError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { theme, setTheme } = useTheme();
  useEffect(() => {
    document.title = `UX DOC | Login`;
  }, []);

  const { signIn } = useAuth();
  let navigate = useNavigate();

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      scale: 1.05,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 200,
        staggerDirection: -1,
      },
    },
  };

  const schema = yup.object({
    email: yup.string().email("Email invalido").required(),
    password: yup.string().min(6).required(`Senha necessaria`),
  });

  function toastMessageCreatedAccount() {
    toast.success("Sua conta foi criada com sucesso!");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitPropsLogin>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await signIn(data.email, data.password);
      setLoading(false);
    } catch (error: any) {
      if (
        error.response.data?.message ===
          "Incorrect email/password combination." ||
        error.response.data?.message === "celebrate request validation failed"
      ) {
        setDataError("Email ou senha inválida!");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (createdAccount) {
      toastMessageCreatedAccount();
      setCreatedAccount(false);
    }
  }, [createdAccount]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoadingScreen(false);
    }, 500);

    return () => {
      setDataError("");
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    setTheme((oldState) => {
      return {
        colors: {
          gradientBody: "#3c2058",
          gradientBody2: "#663399",
          black: "#000",
          background: "#663399",
          border: "#C4C4C4",
          gray900: "#343A40",
          gray300: "#ADB5BD",
          gray500: "#EEEEEE",
          backgroundEditor: "#F8F9FA",
          red: "#520202",
          whiteColor: "#fff",
          backgroundAsideDash: "#fafafa",
          colorTextAsideDash: "#a5a5a5",

          black100: "#343A40",
          black50: "#999C9F",
          black5: "#F5F5F5",
          primary: "#663399",
          primary90: "#7547A3",
          white100: "#FFF",
          error100: "#DE2D2D",
          error10: "#FCEAEA",
          focus: "#5D5FEF",
        },
      };
    });
  }, []);

  return (
    <>
      {loadingScreen ? (
        <LoadingFlex style={{ height: "100vh" }}>
          <ContractAnimation />
        </LoadingFlex>
      ) : (
        <Container>
          <ToastContainer position="top-left" />

          <UxDocLogoLarge />

          <TitleH3 title="Fazer login" />

          <Form
            initial="hidden"
            animate="show"
            variants={container}
            as={motion.form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <InputForm
              label="E-mail"
              type="text"
              register={register}
              name="email"
              error={!!errors?.email?.message}
            />

            <InputPassword
              isPasswordVisible={isPasswordVisible}
              setIsPasswordVisible={setIsPasswordVisible}
              register={register}
              error={!!errors?.password?.message}
              recoverPassword={true}
            />

            <FooterForm>
              <ButtonMaxWidth
                text="Entrar"
                typeStyle="purple"
                type="submit"
                loading={loading}
              />
              <ButtonMaxWidth
                onClick={() => navigate("cadastro")}
                text="Teste grátis por 14 dias!"
                typeStyle="white"
              />
              <AlertError dataError={dataError} />
            </FooterForm>
          </Form>
        </Container>
      )}
    </>
  );
};

export default Login;

