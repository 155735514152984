import styled from "styled-components";

export const ContainerModal = styled.div`
  width: 700px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 13px;

  .modal-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(60px);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin-bottom: 15px;

  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }

  input {
    width: 100%;
    max-height: 40px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.black50};

    :focus {
      border: 1px solid ${({ theme }) => theme.colors.focus};
    }
  }
`;
