import styled from "styled-components";

export const Container = styled.div`
  width: 100dvw;
  height: 100dvh;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextDone = styled.span`
  font-size: 14px;
  font-family: Ubuntu, sans-serif;
  margin: 0 auto;
  margin-top: 1.2rem;
  line-height: 150%;
  color: #343a40;
  min-width: 500px;
  max-width: 500px;
  text-align: center;
  margin-bottom: 1.8rem;
`;

export const HStack = styled.div`
  display: flex;
  margin: auto auto;
  align-items: center;
  margin-top: 1.6rem;
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoxModal = styled.div`
  width: 800px;
  min-height: 190px;
  display: flex;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 5px;
  flex-direction: column;
`;

export const Button = styled.button`
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 14px;
  font-family: Ubuntu, sans-serif;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 150%;
  color: #343a40;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  background: #fff;
  justify-content: flex-start;
  flex-shrink: 1;
  margin-top: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  gap: 20px;
  padding: 0 20px;
  align-items: center;
  border-top: 1px solid #ebebec;
`;

export const Heading = styled.span`
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  color: #343a40;
  font-weight: 700;
  margin-left: 7px;
`;

