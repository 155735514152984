import { BoxButton, ButtonModal } from "../styled";
import { BoxInput, BoxWarning, InputText, Modal, Title } from "./styled";
import { GrClose } from "react-icons/gr";
import { useTeams } from "../../../../../../hooks/teams";
import { useCallback, useEffect, useState } from "react";
import api from "../../../../../../services/api";
import { LoadingContainer } from "../ModalViewText/styled";
import { ContractAnimation } from "../../../../../../shared/components/ContractAnimation";
import { toast } from "react-toastify";
import TitleH5 from "../../../../../../shared/components/TitleH5";
import ButtonExit from "../../../../../../shared/components/ButtonExit";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../shared/utils/MountIcons";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import { ISelectModalTeam } from "../../../../../../dtos/teamsUtils";
import { useParams } from "react-router-dom";

interface IModalCreateTextProps {
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
  setIsSettings: React.Dispatch<React.SetStateAction<boolean>>;
  isSettings: boolean;
}

const ModalCreateText = ({
  setSelectModal,
  isSettings,
  setIsSettings
}: IModalCreateTextProps) => {
  const {
    createdTeamId,
    setClausesListTeam,
    setClauseId,
    clauseId,
    forceUpdate,
    setForceUpdate
  } = useTeams();
  const { team_id } = useParams();

  const [form, setForm] = useState({
    team_id: team_id,
    title: "",
    key_words: "",
    clause: ""
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isSettings) {
      setLoading(false);
    }
  }, [isSettings]);

  const handleUpdateClauses = useCallback(async () => {
    try {
      const responseListOfClausesTeam = await api.get(
        `team-clauses/${team_id}`
      );
      setClausesListTeam(responseListOfClausesTeam.data.dataArray);
      // setLoading(false);
    } catch (err) {
      console.error(err, "error when get clauses of team specific");

      setLoading(false);
    }
  }, [setClausesListTeam, team_id]);

  const handleAddNewClauseTeam = useCallback(async () => {
    try {
      setLoading(true);
      const responseAddNewClauseTeam = await api.post("team-clauses", form);
      setSelectModal({ modal: "", isOpen: false });
      setIsSettings(false);
      await handleUpdateClauses();
      setLoading(false);
      toast.success("Texto criado com sucesso!");
      setForceUpdate(!forceUpdate);
    } catch (err) {
      toast.error("Todos os campos sao obrigatorios.");
      setLoading(false);
      console.error(err, "ERROR when add new clause for specific team");
    }
  }, [form]);

  const handleUpdateClauseTeam = useCallback(async () => {
    try {
      setLoading(true);
      const responseAddNewClauseTeam = await api.put("team-clauses", {
        team_clause_id: clauseId,
        title: form.title,
        clause: form.clause,
        key_words: form.key_words
      });
      setSelectModal({ modal: "", isOpen: false });
      setIsSettings(false);
      await handleUpdateClauses();
      setLoading(false);
      toast.success("Texto atualizado com sucesso!");
    } catch (err) {
      console.error(err, "ERROR when add new clause for specific team");
      toast.error("Algo deu errado, tente novamente mais tarde.");
    }
  }, [form, clauseId]);

  useEffect(() => {
    (async () => {
      if (isSettings && clauseId) {
        setLoading(true);
        try {
          const responseSpecificClause = await api.get(
            `team-clauses/list-specific/${clauseId}`
          );

          setForm(oldState => {
            return {
              ...oldState,
              title: responseSpecificClause.data.title,
              key_words: responseSpecificClause.data.key_words,
              clause: responseSpecificClause.data.clause
            };
          });
          setLoading(false);
        } catch (err) {
          console.error(err, "ERROR ");
          setLoading(false);
        }
      }
    })();

    return () => {
      setClauseId("");
    };
  }, [isSettings]);

  useEffect(() => {
    return () => {
      setForm({
        team_id: "",
        title: "",
        key_words: "",
        clause: ""
      });
    };
  }, []);

  return (
    <Modal>
      {loading ? (
        <LoadingContainer>
          <ContractAnimation />
        </LoadingContainer>
      ) : (
        <>
          <Title>
            <TitleH5 title={isSettings ? "Editar" : "Cadastrar novo texto"} />

            <ButtonExit
              onClick={() => {
                setSelectModal({ modal: "", isOpen: false });
                setIsSettings(false);
              }}
            />
          </Title>
          <BoxInput>
            <div>
              <label>Título</label>
              <input
                value={form.title}
                onChange={event => {
                  setForm({ ...form, title: event.target.value });
                }}
                type="text"
              />
            </div>

            <div>
              <label>Categoria</label>
              <input
                type="text"
                value={form.key_words}
                onChange={event => {
                  setForm({ ...form, key_words: event.target.value });
                }}
              />
            </div>

            <div>
              <label>Texto</label>
              <InputText
                value={form.clause}
                onChange={event => {
                  setForm({ ...form, clause: event.target.value });
                }}
              />
            </div>
          </BoxInput>
          <BoxWarning>
            <PersonalizedIcon
              dPath={MountIcons.IconAlert.dPath}
              viewBox={MountIcons.IconAlert.viewBox}
              inactivatedColor="#de2d2d"
            />
            <p>
              Tudo que você escrever aqui ficará gravado no banco de cláusulas.
              Assim, evite usar informações confidenciais de clientes neste
              texto, dê preferência a incluir estas informações confidenciais
              apenas no documento que está criando.
            </p>
          </BoxWarning>

          <ButtonMaxWidth
            text="Salvar"
            typeStyle="purple"
            onClick={
              isSettings ? handleUpdateClauseTeam : handleAddNewClauseTeam
            }
          />
        </>
      )}
    </Modal>
  );
};

export default ModalCreateText;
