import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  header {
    width: 125.23px;
    height: 55px;
    overflow: hidden;
    display: flex;

    img {
      transform: translateY(-1px) translateX(2px);
    }
  }

  @media (max-height: 920px) {
    section {
      gap: 30px;
    }

    header {
      width: 107.231px;
      height: 48px;
    }
  }

  @media (max-width: 1366px) {
    section {
      gap: 25px;
    }

    header {
      width: 102.231px;
      height: 41px;
    }

    @media (max-height: 615px) {
      margin-block: 40px;
    }
  } ;
`;

export const Main = styled.div`
  color: #fff;
  text-align: center;
  width: 857px;

  h3 {
    height: 40px;

    font-variant-numeric: lining-nums proportional-nums;
    font-family: "Ubuntu";
    font-size: 24px;
    font-weight: 700;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      width: 125.231px;
      height: 1px;
      background: #fff;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;

    font-family: "Ubuntu";
    font-size: 16px;
    line-height: 34px;

    border-radius: 10px 10px 0px 0px;
    background: ${({ theme }) => theme.colors.primary};
  }

  li {
    list-style: inside;
  }

  @media (max-height: 920px) {
    width: 657px;

    h3 {
      gap: 8px;

      div {
        width: 110px;
      }
    }

    ul {
      gap: 10px;

      font-size: 14px;
      line-height: 23px;
    }
  }

  @media (max-width: 1366px) {
    width: 557px;

    h3 {
      font-size: 20px;

      div {
        width: 100px;
      }
    }

    ul {
      gap: 10px;

      font-size: 14px;
      line-height: 20px;
    }
  } ;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-around;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 0px 0px 10px 10px;
  padding: 30px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 18px;
    position: relative;
  }

  p {
    color: ${({ theme }) => theme.colors.primary};
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "Ubuntu";
    font-size: 20px;
    font-weight: 700;
  }

  span {
    color: #343a40;
    font-family: "Ubuntu";
    font-size: 20px;

    font-weight: 800;
  }

  button {
    width: 343.187px;
    height: 58px;
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;

    font-family: "Ubuntu";
    font-size: 16px;
    font-weight: 700;
  }

  .big-num {
    font-size: 56px;
  }

  .line {
    width: 2px;
    height: 202px;
    background: #cccccc;
  }

  @media (max-height: 920px) {
    padding: 20px;

    & > div {
      gap: 7px;
    }

    p {
      font-size: 18px;
    }

    span {
      font-size: 16px;
      line-height: 130%;
    }

    button {
      width: 243px;
      height: 43px;
      border-radius: 8px;
      font-size: 14px;
    }

    .big-num {
      font-size: 46px;
    }

    .line {
      height: 138px;
    }
  }

  @media (max-width: 1366px) {
    padding: 12px;

    & > div {
      gap: 7px;
    }

    p {
      font-size: 16px;
    }

    span {
      font-size: 14px;
      line-height: 120%;
    }

    button {
      width: 223px;
      height: 38px;
      border-radius: 8px;
      font-size: 13px;
    }

    .big-num {
      font-size: 44px;
    }

    .line {
      height: 120px;
    }
  }
`;

export const BoxYearly = styled.div`
  div {
    display: flex;
    flex-direction: column;

    p {
      color: black;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .promotion {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -25px;
    left: -35px;
    object-fit: cover;
  }
  .search {
    width: 88.938px;
    height: 51px;
    position: absolute;
    top: -15px;
    right: 0;
    object-fit: cover;
  }

  @media (max-height: 920px) {
    div > p {
      font-size: 14px;
      font-weight: 500;
    }

    .promotion {
      width: 78px;
      height: 79px;
      top: -12px;
      left: -46px;
    }

    .search {
      width: 69px;
      height: 44px;
      top: -14px;
      right: -10px;
    }
  }

  @media (max-width: 1366px) {
    .promotion {
      width: 65px;
      height: 70px;
      left: -29px;
    }

    .search {
      width: 60px;
      height: 33px;
      top: -5px;
      right: -3px;
    }

    div > p {
      font-size: 12px;
      font-weight: 400;
    }
  }
`;
export const BoxMonthly = styled.div`
  @media (max-height: 920px) {
    span {
      height: 75.8px;
    }
  }
  @media (max-width: 1366px) {
    span {
      height: 66.8px;
    }
  }
`;
