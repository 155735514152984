import {
  Container,
  ContainerLogin,
  Grid,
  HeaderTable,
  NoElements,
  Table
} from "./styles";

import { useTeams } from "../../../../../hooks/teams";
import { ArrayOfTeamListProps } from "../../../../../dtos/teamsUtils";
import ItemTeam from "../ItemTeam";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";

interface TeamsListProps {
  teams: ArrayOfTeamListProps[];
  loading: boolean;
}

const TableTeams = ({ teams, loading }: TeamsListProps) => {
  const {
    setSelectModal,
    setIsSettings,
    setCreatedTeamId,
    setIdLeaveTeam,
    setNameOfTeam
  } = useTeams();

  return (
    <Container>
      <Table>
        <HeaderTable>
          <p>Nome da equipe</p> <p>Equipe</p> <p>Função na equipe</p>
        </HeaderTable>

        <Grid>
          {loading ? (
            <ContainerLogin>
              <ContractAnimation />
            </ContainerLogin>
          ) : teams.length === 0 ? (
            <NoElements>Nenhuma equipe encontrada.</NoElements>
          ) : (
            teams?.map(team => (
              <ItemTeam
                setNameOfTeam={setNameOfTeam}
                key={team.team_id}
                item={team}
                setCreatedTeamId={setCreatedTeamId}
                setIsSettings={setIsSettings}
                setSelectModal={setSelectModal}
                setIdLeaveTeam={setIdLeaveTeam}
              />
            ))
          )}
        </Grid>
      </Table>
    </Container>
  );
};

export default TableTeams;
