import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

export const BoxCategories = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  position: absolute;
  z-index: 200;
  right: 0;
  top: 40px;
`;

export const Container = styled.div`
  width: 348px;
  background-color: white;
  border-radius: 5px;

  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
`;

export const LettersContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;

  .alphabetical {
    height: 24px;
    width: 24px;

    background: transparent;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;

    :hover {
      background-color: #ebebec;
      border-radius: 5px;
    }
  }

  button:first-child {
    width: 48px;
    height: 24px;
  }

  .active {
    color: ${({ theme }: Theme) => theme.colors.primary} !important;
    background-color: #f5f5f5;
  }
`;

export const BodyCategories = styled.div`
  width: 100%;
  height: 156px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a5a5a5;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  & > span {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ListItem = styled.div`
  min-height: 24px;
  height: 24px;
  padding-inline: 10px;

  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: black;

  cursor: pointer;
  transition: all 120ms linear;

  &:hover {
    background-color: #ebebec;
  }
`;
