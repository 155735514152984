import styled from "styled-components";

interface FlexCardProps {
  src?: string;
}

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  width: 100%;
`;

export const FlexCard = styled.div<FlexCardProps>`
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-image: url(${({ src }) => src && src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);

  user-select: none;

  .img {
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 1920px) {
    height: 352.896px;
  }
`;

export const Text = styled.label`
  width: 100%;
  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.black50};
`;
