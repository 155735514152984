import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.black100};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px 15px;

  p {
    font-family: "Ubuntu";
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.white100};
  }

  button {
    background-color: ${({ theme }) => theme.colors.primary90};
    width: 125px;
    height: 30px;
    border-radius: 5px;

    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.white100};

    :hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
