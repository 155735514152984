import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

interface ContainerProps {
  isLoading?: boolean;
}

export const CanvasContainer = styled.div`
  width: 611px;
  height: 920px;
  background: transparent;
  margin: 1.6rem 0;
`;

export const Container = styled.div<ContainerProps>`
  width: calc(100vw - 397px);
  height: 100%;

  position: relative;
  transform: none;
  perspective: none;

  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: 1920px) {
    width: calc(100vw - 500px);
  }

  ${({ isLoading }) =>
    isLoading &&
    `
  pointer-events: none; /* Disables interactions including scrolling */
  `}
`;

export const HorizontalStack = styled.div`
  display: flex;
  width: 596px;
  height: 48px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export const FlexRow = styled.div`
  display: flex;
  max-width: 230px;

  width: 100%;
  justify-content: space-between;

  transition: all 2s ease-in-out;

  @media (max-width: 1600px) {
    max-width: 210px;
  }

  @media (max-width: 1366px) {
    max-width: 190px;
  }
`;
