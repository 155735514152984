import { useNavigate } from "react-router-dom";
import { ISelectModalTeam } from "../../../../../../../dtos/teamsUtils";
import PersonalizedIcon from "../../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../../shared/utils/MountIcons";
import OutoutIcon from "../../../../../assets/output.svg";

import { Container, Item } from "./styles";

interface ITeamItemProps {
  teamId: string;
  isWoner: boolean;
  setCreatedTeamId: React.Dispatch<React.SetStateAction<string>>;
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
  setIsSettings: React.Dispatch<React.SetStateAction<boolean>>;
  teamName: string;
  setNameOfTeam: React.Dispatch<React.SetStateAction<string>>;
  setIdLeaveTeam: React.Dispatch<React.SetStateAction<string>>;
}

const OptionsModal = ({
  setCreatedTeamId,
  setSelectModal,
  setIsSettings,
  teamId,
  isWoner,
  teamName,
  setNameOfTeam,
  setIdLeaveTeam
}: ITeamItemProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Item
        onClick={() => {
          setCreatedTeamId(teamId);
          navigate(`/view-team/${teamId}/templates`);
        }}
      >
        <div>
          <PersonalizedIcon
            dPath={MountIcons.IconOpen.dPath}
            viewBox={MountIcons.IconOpen.viewBox}
            inactivatedColor="#676B70"
          />
          Abrir
        </div>
      </Item>

      {isWoner ? (
        <>
          <Item
            onClick={() => {
              setCreatedTeamId(teamId);
              setSelectModal({ modal: "Add Participant", isOpen: true });
              setIsSettings(true);
            }}
          >
            <div>
              <PersonalizedIcon
                dPath={MountIcons.IconGear.dPath}
                viewBox={MountIcons.IconGear.viewBox}
                inactivatedColor="#676B70"
              />
              Configurações da equipe
            </div>
          </Item>

          <Item
            onClick={() => {
              setCreatedTeamId(teamId);
              setIsSettings(true);
              setSelectModal({ modal: "Delete Team", isOpen: true });
              setNameOfTeam(teamName);
            }}
          >
            <div>
              <PersonalizedIcon
                dPath={MountIcons.IconTrash.dPath}
                viewBox={MountIcons.IconTrash.viewBox}
                inactivatedColor="#676B70"
              />
              Excluir equipe
            </div>
          </Item>
        </>
      ) : (
        <Item
          onClick={() => {
            setIdLeaveTeam(teamId);
            setSelectModal({ modal: "Leave Team", isOpen: true });
            setIsSettings(true);
            setNameOfTeam(teamName);
          }}
        >
          <div>
            <img src={OutoutIcon} alt="" />
            Sair da equipe
          </div>
        </Item>
      )}
    </Container>
  );
};

export default OptionsModal;
