import { cloneDeep } from "lodash";
import { ILinesPage } from "../../../../hooks/pagesEditor";
import { Container, FirsLine, LastLine, SecondLine } from "./styled";

interface IGuidelineVProps {
  setLinesPage?: React.Dispatch<React.SetStateAction<ILinesPage[]>>;
  position: { top: number; left: number };
  id?: string;
  isActive: boolean;
  thisIndexObject: number;
  handleMouseMoveLineGuide: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    page: any
  ) => void;
  pageNumber: number;
}

const GuidelineV = ({
  position,
  setLinesPage,
  id,
  isActive,
  thisIndexObject,
  handleMouseMoveLineGuide,
  pageNumber,
}: IGuidelineVProps) => {
  return (
    <Container style={position}>
      <FirsLine
        isActive={isActive}
        onMouseDown={(e) => {
          if (!isActive) {
            handleMouseMoveLineGuide(e, pageNumber);
            setLinesPage((prevState) => {
              let newState = cloneDeep(prevState);
              let newLineGuidV = newState[thisIndexObject].lineGuidV?.filter(
                (line) => line.id !== id
              );

              newState[thisIndexObject] = {
                ...newState[thisIndexObject],
                lineGuidV: newLineGuidV,
                islineV: true,
              };

              return newState;
            });
          }
        }}
      >
        <div>
          <span className="vector-right" />
          <span className="vector-left" />
        </div>
      </FirsLine>
      <SecondLine />

      <LastLine
        isActive={isActive}
        onMouseDown={(e) => {
          if (!isActive) {
            handleMouseMoveLineGuide(e, pageNumber);
            setLinesPage((prevState) => {
              let newState = cloneDeep(prevState);
              let newLineGuidV = newState[thisIndexObject].lineGuidV?.filter(
                (line) => line.id !== id
              );

              newState[thisIndexObject] = {
                ...newState[thisIndexObject],
                lineGuidV: newLineGuidV,
                islineV: true,
              };

              return newState;
            });
          }
        }}
      >
        <div>
          <span className="vector-right" />
          <span className="vector-left" />
        </div>
      </LastLine>
    </Container>
  );
};

export default GuidelineV;
