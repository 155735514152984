import styled from "styled-components";

const getColorByIndex = (index: number) => {
  const colors = ["#24958E", "#F90", "#06C", "#663399"];
  const colorIndex = (index - 1) % colors.length;
  return colors[colorIndex];
};

export const Container = styled.li`
  display: grid;
  grid-template-columns: 2fr 1.2fr 0.7fr 0.7fr 1.3fr 0.9fr 0.2fr;
  height: 44px;
  align-items: center;
  position: relative;
  padding-inline: 16px;

  border-bottom: solid 1px #ebebec;

  p:first-child {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }
`;

export const SubscriberBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
    user-select: none;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    transform: translateX(-5px);
  }

  .avatar span {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
    user-select: none;

    position: relative;
  }

  img:nth-child(2n) {
    left: -6px;
  }
  img:nth-child(3n) {
    left: -12px;
  }
  img:nth-child(4n) {
    left: -18px;
  }
`;

export const AvatarCustom = styled.div<{ indexOfUser: number }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  user-select: none;
  background-color: ${({ indexOfUser }) => getColorByIndex(indexOfUser)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;

  &:nth-child(1) {
    transform: translateX(0);
  }

  &:nth-child(2) {
    transform: translateX(-10px);
  }

  &:nth-child(3) {
    transform: translateX(-15px);
  }

  span {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const AvailableDate = styled.p<{ isExpired: boolean }>`
  color: ${({ isExpired }) => (isExpired ? "#1172BA" : "#000")};
  text-decoration-line: ${({ isExpired }) =>
    isExpired ? "underline" : "none"};
  cursor: ${({ isExpired }) => (isExpired ? "pointer" : "auto")};

  font-size: 14px;
`;
