import { Container } from "./styles";

interface IPlainTextProps {
  children: any;
  className?: string;
}

const PlainText = ({ children, className }: IPlainTextProps) => {
  return <Container className={className}>{children}</Container>;
};

export default PlainText;
