import React from "react";

// import { Container } from './styles';
interface SupportIconProps {}

const SupportIcon: React.FC<SupportIconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.14314 8.57118C9.14314 7.31047 10.1681 6.28547 11.4289 6.28547H12.5717C13.8324 6.28547 14.8574 7.31047 14.8574 8.57118V8.69976C14.8574 9.47833 14.461 10.2033 13.8074 10.6212L12.3003 11.589C11.8578 11.8736 11.4938 12.2646 11.2417 12.7264C10.9896 13.1881 10.8575 13.7058 10.8574 14.2319V14.2855C10.8574 14.9176 11.3681 15.4283 12.0003 15.4283C12.6324 15.4283 13.1431 14.9176 13.1431 14.2855V14.2355C13.1431 13.9426 13.2931 13.6712 13.536 13.514L15.0431 12.5462C16.3503 11.7033 17.1431 10.2569 17.1431 8.69976V8.57118C17.1431 6.04618 15.0967 3.99976 12.5717 3.99976H11.4289C8.90385 3.99976 6.85742 6.04618 6.85742 8.57118C6.85742 9.20333 7.36814 9.71404 8.00028 9.71404C8.63242 9.71404 9.14314 9.20333 9.14314 8.57118ZM12.0003 19.9998C12.3792 19.9998 12.7425 19.8492 13.0104 19.5813C13.2783 19.3134 13.4288 18.9501 13.4288 18.5712C13.4288 18.1923 13.2783 17.8289 13.0104 17.561C12.7425 17.2931 12.3792 17.1426 12.0003 17.1426C11.6214 17.1426 11.258 17.2931 10.9901 17.561C10.7222 17.8289 10.5717 18.1923 10.5717 18.5712C10.5717 18.9501 10.7222 19.3134 10.9901 19.5813C11.258 19.8492 11.6214 19.9998 12.0003 19.9998Z"
        fill="white"
      />
    </svg>
  );
};

export default SupportIcon;
