import searchIcon from "../../assets/icon-search.svg";
import IconSvg from "../IconSvg";
import {
  Container,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  FlexContainer,
  HorizontalStack,
  Input,
  ListItem,
  VerticalStack,
} from "./styles";

import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { animate, useAnimation, useMotionValue, motion } from "framer-motion";
import useComponentVisible from "../../hooks/useComponentVisible";
import favoriteIconNormal from "../../assets/icon-favorites.svg";
import favoriteIconActive from "../../assets/icon-favorites-active.svg";
import Tooltip from "../Tooltip";
import { useTheme } from "../../../../hooks/theme";
import { RiStarFill } from "react-icons/ri";
import FavotiteIcon from "../SearchInput/Icons/FavotiteIcon";
import ButtonExit from "../../../../shared/components/ButtonExit";

interface QueryProps {
  pagination?: number;
  search?: string;
  filter?: string;
}

interface SearchInputProps {
  search?: string;
  setSearch?: any;
  name?: string;
  type?: string;
  label?: string;
  query?: QueryProps;
  setQuery?: React.Dispatch<React.SetStateAction<QueryProps>>;
  focusInput?: boolean;
  setFocusInput?: React.Dispatch<React.SetStateAction<boolean>>;
  currentPage?: number;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
}

const category = [
  {
    name: "Favoritos",
    icon: "/images/icon-star-filter.svg",
    description: "ícone de favoritos",
    function: "favorite",
  },

  {
    name: "Todos",
    icon: "/images/icon-all-filter.svg",
    description: "ícone de favoritos",
    function: "all",
  },
];

const list = {
  visible: {
    opacity: 1,
    y: 5,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    y: -20,
    transition: {
      when: "afterChildren",
    },
  },
};

const variants = {
  visible: (i: number) => ({
    opacity: 1,
    transition: {
      delay: i * 0.3,
    },
  }),
  hidden: { opacity: 0 },
};

export const SearchInputBank = ({
  label,
  name,
  type,
  query,
  setQuery,
  setFocusInput,
  setCurrentPage,
  focusInput,
}: SearchInputProps) => {
  const controls = useAnimation();
  const x = useMotionValue(0);
  const { lightPrimaryColor } = useTheme();

  useEffect(() => {
    const controls = animate(x, 100, {
      type: "spring",
      stiffness: 2000,
    });

    return controls.stop;
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const [filtered, setFiltered] = useState<"all" | "favorite">("all");

  // const onOptionClicked = (value: string) => () => {
  //   setSelectedOption(value);
  //   setIsOpen(false);
  // };

  useEffect(() => {
    if (query.filter !== "favorite") {
      setFiltered("all");
    }
  }, [query]);

  const handleOptionFilter = useCallback(
    (type: string) => {
      if (type === "favorite") {
        setFiltered((state) => (state = "favorite"));
        setQuery((state) => {
          return {
            ...state,
            filter: type,
            category: "",
          };
        });

        setIsOpen(!isOpen);
      } else {
        setFiltered((state) => (state = "all"));
        setQuery((state) => {
          return {
            ...state,
            filter: "",
            category: "",
          };
        });
      }
      setIsOpen(!isOpen);
    },
    [query, isOpen, filtered]
  );

  return (
    <Container>
      <Tooltip
        title="Pesquisar"
        description="Digite aqui para encontrar o que procura"
        placement="bottom-start"
      >
        <FlexContainer>
          <IconSvg
            src={searchIcon}
            className="search-icon"
            description={"Pesquisar"}
          />

          <Input
            onChange={(e) => {
              setQuery({ ...query, search: e.target.value });
              setCurrentPage(0);
            }}
            value={query.search}
            name={name}
            placeholder={label}
            type={type}
            onFocus={() => setFocusInput((state) => !state)}
          />

          {focusInput && (
            <button>
              <ButtonExit
                inactivatedColor="#999C9F"
                onClick={() => {
                  setQuery({ ...query, search: "", pagination: 0 });
                  setFocusInput(false);
                }}
              />
            </button>
          )}
        </FlexContainer>
      </Tooltip>

      <DropDownContainer ref={ref}>
        <DropDownHeader Open={isOpen} onClick={handleClick}>
          {filtered === "favorite" ? (
            <Tooltip
              title="Favoritos"
              description="Ocultar lista de favoritos. Ver todos os textos."
              placement="bottom-start"
              maxWidth={170}
            >
              <button onClick={() => handleOptionFilter("all")}>
                <FavotiteIcon isActive={true} />
              </button>
            </Tooltip>
          ) : (
            <Tooltip
              title="Favoritos"
              description="Ver lista de favoritos"
              placement="bottom-start"
            >
              <button onClick={() => handleOptionFilter("favorite")}>
                <FavotiteIcon isActive={false} />
              </button>
            </Tooltip>
          )}
        </DropDownHeader>
      </DropDownContainer>
    </Container>
  );
};
