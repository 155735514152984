import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 25px;
  height: 25px;
  fill: currentColor;
`;

type Props = {
  color: string;
};

const MyApprovalsIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 25 25" color={color}>
    <path
      d="M22.25 5.92651V16.4265L16.25 22.4265H5.75002C3.85002 22.4265 2.25002 20.8265 2.25002 18.9265V5.92651C2.25002 4.02651 3.85002 2.42651 5.75002 2.42651H18.75C20.65 2.42651 22.25 4.02651 22.25 5.92651ZM20.25 6.22651C20.25 5.22651 19.45 4.42651 18.45 4.42651H6.05002C5.05002 4.42651 4.25002 5.22651 4.25002 6.22651V18.7265C4.25002 19.7265 5.05002 20.5265 6.05002 20.5265H15.25V19.0265C15.25 17.1265 16.85 15.5265 18.75 15.5265H20.25V6.22651Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.3922 9.50439C15.2756 9.50439 15.1601 9.5274 15.0524 9.5721C14.9447 9.61681 14.8468 9.68232 14.7644 9.7649L11.3242 13.2069L9.73507 11.6164C9.65265 11.5339 9.5548 11.4686 9.4471 11.424C9.33941 11.3793 9.22399 11.3564 9.10742 11.3564C8.99086 11.3564 8.87543 11.3793 8.76774 11.424C8.66005 11.4686 8.56219 11.5339 8.47977 11.6164C8.39735 11.6988 8.33196 11.7966 8.28735 11.9043C8.24275 12.012 8.21979 12.1275 8.21979 12.244C8.21979 12.3606 8.24275 12.476 8.28735 12.5837C8.33196 12.6914 8.39735 12.7892 8.47977 12.8717L10.6965 15.0884C10.7788 15.1709 10.8766 15.2364 10.9843 15.2811C11.0921 15.3258 11.2075 15.3488 11.3242 15.3488C11.4408 15.3488 11.5563 15.3258 11.664 15.2811C11.7717 15.2364 11.8696 15.1708 11.952 15.0883L16.0198 11.0201C16.1024 10.9378 16.1678 10.8399 16.2125 10.7323C16.2572 10.6245 16.2802 10.509 16.2802 10.3924C16.2802 10.2758 16.2572 10.1603 16.2125 10.0526C16.1679 9.94494 16.1024 9.84716 16.0199 9.76484C15.9376 9.68229 15.8398 9.6168 15.7321 9.5721C15.6243 9.5274 15.5089 9.50439 15.3922 9.50439Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default MyApprovalsIcon;
