import { darken, lighten, saturate } from "polished";

import styled from "styled-components";
import { useTheme } from "../../../../hooks/theme";
// const { theme } = useTheme();

// const colorActive = darken(
//   0.05,
//   saturate(0.6, lighten(0.24, ))
// );

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  /* visibility: hidden; */
  -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.55);
`;

export const LoadingFlex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 3.5rem;
`;

export const Box = styled.div`
  width: 700px;
  height: 455px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--preto-preto-5, #f5f5f5);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  display: flex;
`;

export const DetailsBox = styled.div`
  width: 340px;
  height: 455px;
  flex-shrink: 0;
  border-radius: 5px 0px 0px 5px;
  background: var(--preto-preto-5, #f5f5f5);
  display: flex;
  padding: 32.5px 30px;
  flex-direction: column;

  img {
    width: 68.308px;
    height: 30px;
  }

  ul {
    margin-left: 2rem;
    margin-top: 10px;
  }

  h2 {
    color: var(--preto-preto-100, #343a40);
    /* H4 */
    font-family: Ubuntu;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
    margin-top: 30px;
  }

  li {
    color: var(--preto-preto-100, #343a40);
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 25.2px */
    list-style-type: disc !important;
  }
`;

export const PlansBox = styled.div`
  display: flex;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 48px 30px 36.5px 30px;
  gap: 15px;
  border-radius: 5px;
  position: relative;

  .close-btn {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  h4 {
    color: var(--preto-preto-100, #343a40);
    margin: 5px 0;
    /* H4 */
    font-family: Ubuntu;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
  }

  .box-information-values {
    width: 191px;
    height: 18px;
    background-color: #ebebec;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    border-radius: 5px;
    margin-top: 5px;
  }

  .information-values {
    color: var(--preto-preto-100, #343a40);
    /* legenda */
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  .just-one-payment-text {
    color: #343a40;
    margin-top: 5px;
    /* legenda */
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
`;

export const RecommendedPlan = styled.div`
  width: 300px;
  height: 189px;
  border-radius: 5px;
  border: 1px solid #999c9f;
  display: flex;
  position: relative;
  padding: 20px;
  flex-direction: column;

  .dashed-text {
    color: var(--preto-preto-50, #999c9f);

    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: strikethrough;
  }

  .dark-text {
    color: #343a40;
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  .green-text {
    color: var(--outros-correto, #007b36);

    /* Texto corrido */
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const MonthlyPlan = styled.div`
  width: 300px;
  height: 166px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #999c9f;
  display: flex;
  padding: 15px 20px;
  flex-direction: column;

  .dark-text {
    color: #343a40;
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
`;

export const RecommendedTag = styled.div`
  position: absolute;
  top: -12.5px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: var(--outros-correto, #007b36);
  width: 99px;
  height: 24px;
  flex-shrink: 0;

  span {
    color: var(--outros-branco, #fff);
    /* legenda */
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
`;

export const ButtonPayment = styled.button`
  display: flex;
  width: 260px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  /* background: var(--primria-primria-90, #7547a3); */
  background: ${({ theme }) => darken(0, theme.colors.primary)};
  color: var(--outros-branco, #fff);
  text-align: center;
  /* Botões */
  font-family: Ubuntu;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  margin-top: 10px;
  transition: all 250ms linear;

  :hover {
    background: ${({ theme }) => darken(0.1, theme.colors.primary)};
    box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  }
`;
