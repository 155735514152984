import { useMainHook } from "../../../../../../hooks/main";
import ModalBuyProduct from "../../../../../editor/components/ModalBuyProduct";
import DeleteTemplateModal from "../../../../components/DeleteTemplateModal";

const ModalController = () => {
  const { isIntentBuyModal, modalProviderIsOpen } = useMainHook();

  return (
    <>
      {isIntentBuyModal && <ModalBuyProduct />}
      {modalProviderIsOpen && <DeleteTemplateModal />}
    </>
  );
};

export default ModalController;
