import { ReactNode } from "react";
import { ListStyle, Wrapper } from "./styles";

interface ListProps {
  children: ReactNode;
  showFilter?: string;
  isFavoriteFilter?: boolean;
}

const List: React.FC<ListProps> = ({
  children,
  showFilter,
  isFavoriteFilter,
  ...rest
}) => {
  return (
    <Wrapper>
      <ListStyle
        isFavoriteFilter={isFavoriteFilter}
        showFilter={showFilter}
        {...rest}
      >
        {children}
      </ListStyle>
    </Wrapper>
  );
};

export default List;
