import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "./../../../../user/unknown/TrialEnd/styles";
import WorkflowIcon from "../WorkflowIcon";
import MyProjectsIcon from "../MyProjectsIcon";
import MyApprovalsIcon from "../MyApprovalsIcon";
import { useNavigate } from "react-router-dom";

interface TabProps {
  isActive: boolean;
}

const NavigationBarContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
`;

const Tab = styled.div<TabProps>`
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? "400" : "400")};
  border-bottom: 3px solid
    ${({ isActive }) => (isActive ? "#7547A3" : "transparent")};
`;

interface NavTabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  icon: React.ReactNode;
}

const NavTab: React.FC<NavTabProps> = ({ label, icon, isActive, onClick }) => {
  return (
    <Tab isActive={isActive} onClick={onClick}>
      {icon}
      {label}
    </Tab>
  );
};
interface Props {
  activeTab: string;
}
const NavigationBar: React.FC<Props> = ({ activeTab }: Props) => {
  const navigator = useNavigate();

  const handleTabClick = (page: string) => {
    navigator(`/approvalflow/${page}`);
  };

  return (
    <NavigationBarContainer>
      <NavTab
        label="Meus Fluxos"
        isActive={activeTab === "Meus Fluxos"}
        onClick={() => handleTabClick("my-flows")}
        icon={
          <WorkflowIcon
            color={activeTab === "Meus Fluxos" ? "#000" : "#676B70"}
          />
        }
      />
      <NavTab
        label="Meus Projetos"
        isActive={activeTab === "Meus Projetos"}
        onClick={() => handleTabClick("my-projects")}
        icon={
          <MyProjectsIcon
            color={activeTab === "Meus Projetos" ? "#000" : "#676B70"}
          />
        }
      />
      <NavTab
        label="Minhas Aprovações"
        isActive={activeTab === "Minhas Aprovações"}
        onClick={() => handleTabClick("my-approvals")}
        icon={
          <MyApprovalsIcon
            color={activeTab === "Minhas Aprovações" ? "#000" : "#676B70"}
          />
        }
      />
    </NavigationBarContainer>
  );
};

export default NavigationBar;
