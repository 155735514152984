import React, { useEffect, useRef, useState } from "react";
import { Backdrop, Box } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import useDocumentUpload from "../../../../one-click/hooks/useDocumentUpload";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import ReactQuill from "react-quill";
import { useImageRenderer } from "./../../../../one-click/hooks/useImageRenderer";
import TemplateEmpty from "../../../../one-click/components/Preview/TemplateEmpty";
import WordIcon from "../WordIcon";
import { UploadButton } from "./styles";

const UploadDocSection = () => {
  const {
    isLoading,
    data,
    open,
    setOpen,
    setLoading,
    fileInputRef,
    uploadDocument
  } = useDocumentUpload();

  const quillRef = useRef<ReactQuill>(null);
  const [startRender, setStartRender] = useState(false);
  const { isRenderingImages, paragraphObjects } = useImageRenderer(
    quillRef,
    data,
    "filled",
    "black",
    "black",
    startRender
  );
  const [isRenderingTemplate, setIsRenderingTemplate] = useState(false);

  const handleClickUpload = () => {
    const quill = quillRef.current?.getEditor();
    if (quill) quill.setText("");
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (!data) return;
    const delta: any = data.data.paragraphs
      .map(paragraph =>
        paragraph.finalText.format.ops.map(op => {
          const newAttributes = {
            ...op.attributes,
            customBlock: {
              id: paragraph.id,
              isTitle: paragraph.type_text === "title"
            },
            background:
              op.attributes?.background === "rgba(102, 51, 153, 0.25)"
                ? null
                : op.attributes?.background,
            indent: 0
          };
          return {
            ...op,
            attributes: newAttributes,
            id: paragraph.id
          };
        })
      )
      .reduce((acc, val) => acc.concat(val), []);

    const quill = quillRef.current?.getEditor();

    quill?.setContents(delta);
    setStartRender(true);
    setIsRenderingTemplate(true);
  }, [data]);

  return (
    <>
      <Backdrop
        style={{
          zIndex: 999999,
          color: "#fff"
        }}
        open={isLoading || isRenderingImages || isRenderingTemplate}
        onClick={() => setLoading(false)}
      >
        <ContractAnimation />
        <ToastContainer />
      </Backdrop>

      <input
        type="file"
        accept=".docx"
        onChange={uploadDocument}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      {/* <UploadButton onClick={handleClickUpload}>
        <WordIcon />
        Importar texto do word
      </UploadButton> */}
      <Box
        position={"absolute"}
        zIndex={-9999}
        top={-1000}
        overflow={"hidden"}
        height={200}
      >
        <ReactQuill
          ref={quillRef}
          modules={{
            toolbar: false
          }}
          readOnly={true}
        />
        <div id="my-editor" />
      </Box>

      {open && !isRenderingImages && paragraphObjects.length > 0 && (
        <Backdrop
          style={{
            zIndex: 999999,
            overflow: "auto"
          }}
          open={open}
        >
          <Box
            position={"absolute"}
            top={0}
            minHeight={"100vh"}
            minWidth={"99vw"}
            onClick={e => e.stopPropagation()}
          >
            <div>
              {" "}
              <TemplateEmpty
                prevStep={() => {
                  setOpen(false);
                  setStartRender(false);
                  handleClickUpload();
                }}
                handleClose={() => {
                  setOpen(false);
                  setStartRender(false);
                }}
                setIsLoading={setIsRenderingTemplate}
                isLoading={isRenderingTemplate}
                paragraphObjects={paragraphObjects}
              />
            </div>
          </Box>
        </Backdrop>
      )}
    </>
  );
};

export default UploadDocSection;
