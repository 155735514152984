import styled from "styled-components";

interface ContainerProps extends React.HTMLProps<HTMLDivElement> {
  maxWidth?: string;
}

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & .btn-toolbar {
    align-items: center;
    display: flex;
    background: transparent;
    width: 30px;
    height: 30px;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    z-index: 90;

    &:hover {
      background: #ebebec;
    }
  }
`;

export const ContainerRichText = styled.div<ContainerProps>`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
  z-index: 60;
`;

export const MenuTextSentiveCase = styled.div`
  position: absolute;
  top: 33px;
  left: 0px;
  background-color: #fff;
  width: 218px;
  height: 132px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px 3px;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  div {
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 24px;
    background-color: transparent;
    border-radius: 5px;
    padding: 10px 5px;
    cursor: pointer;
    justify-content: flex-start;

    span {
      color: var(--preto-preto-100, #343a40);
      /* legenda */
      font-family: Ubuntu;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
      text-align: left;
    }

    :hover {
      background-color: #f5f5f5;
    }
  }
`;

export const ContainerTextToolbar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 24px;
  background: #d6d8d9;
`;

export const Button = styled.button``;

export const ContainerRight = styled.div<ContainerProps>`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 60;
`;

export const Divider = styled.div`
  border-left: 1px solid #d6d8d9;
  padding-left: 4px;
  height: 30px;

  display: flex;
  align-items: center;
`;
