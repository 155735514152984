import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FlowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  animation: ${fadeIn} 0.3s ease-in-out;

  @media (max-width: 1600px) {
    gap: 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px 0px;
  border-bottom: 1px solid #ebebec;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 700;
  font-size: 16px;
`;

export const StepWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 192px;
  cursor: pointer;
`;

export const StepTextWrapper = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const LinkButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  font-size: 14px;
`;

export const StepNormalText = styled.span`
  color: white;
  font-weight: 500;
  font-size: 14px;
  max-width: 131px;
`;

export const StepsFlexWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(172px, 172px));
  grid-gap: 10px;
  row-gap: 22px;
  align-items: start;
`;

export const ConfirmButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  cursor: pointer;
  width: 95px;
  margin-top: 20px;
`;

export const StepBottomText = styled.span`
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 500;
  font-size: 13px;
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
`;
