import styled from "styled-components";

export const StyledInput = styled.input`
  height: 40px;
  width: 405px;
  border-radius: 5px;
  border: 1px solid #999c9f;
  padding-left: 15px;

  :hover {
    border: 1px solid black;
  }

  :focus {
    border: 2px solid ${({ theme }) => theme.colors.focus};
  }
`;
