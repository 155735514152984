import styled from "styled-components";

const getColorByIndex = (index: number) => {
  const colors = ["#24958E", "#F90", "#06C", "#663399"];
  const colorIndex = (index - 1) % colors.length;
  return colors[colorIndex];
};

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999999;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;

  .empty-container {
    display: flex;
    min-height: 90px;
    justify-content: center;
    align-items: center;

    color: #343a40;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 400;
  }
`;

export const SignContainerBox = styled.div`
  width: 700px;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;

  .close-button-box {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -14px;
    right: 0;
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
    }

    /* background-color: red; */
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 10px;
`;

export const TitleBox = styled.div`
  width: 100%;
  display: flex;

  span {
    color: #343a40;
    font-size: 18px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const AvatarCustom = styled.div<{ indexOfUser: number }>`
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  user-select: none;
  background-color: ${({ indexOfUser }) => getColorByIndex(indexOfUser)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  margin-right: 1rem;

  &:nth-child(1) {
    transform: translateX(0);
  }

  &:nth-child(2) {
    transform: translateX(-5px);
  }

  &:nth-child(3) {
    transform: translateX(-10px);
  }

  span {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const LineDivider = styled.div`
  width: 100%;
  height: 0.13rem;
  background-color: #a5a5a5;
  margin-top: 1rem;
`;

export const InfoBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;

  span {
    color: #343a40;
    font-size: 1.6rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const DateBox = styled.div`
  margin-top: 1rem;
  width: 209.887px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #a5a5a5;
  background: var(--white, #fff);
  display: flex;
`;

export const InputBox = styled.div`
  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }

  input {
    width: 100%;
    max-height: 40px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.black50};
    margin-top: 5px;

    @media (min-width: 1920px) {
      height: 43px;
      max-height: 43px;
    }

    :focus {
      border: 1px solid ${({ theme }) => theme.colors.focus};
    }

    ::placeholder {
      color: #d6d8d9;
    }
  }
`;
export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  color: var(--gray, #a5a5a5);
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;

  cursor: text;

  &::placeholder {
    color: var(--gray, #a5a5a5);
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const IconBox = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 25px;
    height: 25px;
  }
`;

export const InfoUserBox = styled.div`
  width: 100%;
  max-width: 800px;
  min-height: 40px;
  display: flex;
  gap: 0.5rem;
`;

export const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 1.4rem;
  }
`;
