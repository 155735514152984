import styled from "styled-components";

export const Container = styled.main`
  padding: 30px 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 15px;
  }
`;

export const BoxInput = styled.div`
  font-size: 14px;
  margin-top: 10px;

  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  gap: 10px;

  min-height: 24px;

  input {
    width: 24px;
    height: 24px;
    border-radius: 5px;
  }
`;
