import { Container, TextBox, InputBox } from "./styles";
import EyeIconVisible from "../../assets/eye-visible-password.svg";
import EyeIconHidden from "../../assets/visible-password-icon.svg";
import { useNavigate } from "react-router-dom";
import { UseFormRegister } from "react-hook-form";
import Tooltip from "../../../modules/editor/components/Tooltip";

interface SubmitProps {
  password?: string;
}

interface IInputProps {
  isPasswordVisible: boolean;
  setIsPasswordVisible: React.Dispatch<React.SetStateAction<boolean>>;
  register: UseFormRegister<SubmitProps>;
  error: boolean;
  recoverPassword?: boolean;
}

const InputPassword = ({
  isPasswordVisible,
  setIsPasswordVisible,
  register,
  recoverPassword = false,
  error
}: IInputProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <TextBox>
        <label htmlFor="">Senha</label>
        {recoverPassword && (
          <p onClick={() => navigate("esqueci-minha-senha")}>
            Esqueci minha senha
          </p>
        )}
      </TextBox>
      <InputBox error={error}>
        <input
          type={isPasswordVisible ? "text" : "password"}
          {...register("password")}
          autoComplete="on"
        />
        <Tooltip
          title={isPasswordVisible ? "Ocultar senha" : "Mostrar senha"}
          placement="bottom"
        >
          <img
            src={isPasswordVisible ? EyeIconVisible : EyeIconHidden}
            alt=""
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        </Tooltip>
      </InputBox>
    </Container>
  );
};

export default InputPassword;
