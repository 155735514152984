import { useCallback, useEffect, useState } from "react";
import { useMainHook } from "../../../../hooks/main";
import api from "../../../../services/api";
import { useHeaderEditor } from "../../hooks/headerEditor";
import useComponentVisible from "../../hooks/useComponentVisible";
import { useVersioning } from "../../hooks/versioning";
import {
  Container,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem
} from "./styles";

export interface IRenderedObject {
  x: number;
  y: number;
  width: number;
  align?: "justify" | "center" | "left" | "right";
  text?: string;
  fontSize?: number;
  fontFamily?: string;
  fill?: string;
  id: string;
  object: string;
  setPoints?: number;
  stroke?: string;
  strokeWidth?: string;
  src?: string;
  dash?: number[];
  cornerRadius?: number;
  lineHeight?: number;
  textDecoration?: "underline" | "none";
  fontStyle?: string;
  ZIndex?: number;
}

interface PageProps {
  pageNumber: number;
  renderObjects: IRenderedObject[];
}

interface ResponseDataApi {
  created_at: string;
  creator_id: string;
  description?: string;
  id: string;
  is_main: boolean;
  template: {
    arrayOfPages?: PageProps[];
  };
  updated_at: string;
  user_template_id: string;
  version?: string;
}

interface DropdownVersioningProps {
  borderRadius?: string;
  dataVersionOfTemplate?: ResponseDataApi[];
}

const DropdownVersioningTwo: React.FC<DropdownVersioningProps> = ({
  borderRadius,
  dataVersionOfTemplate
}) => {
  const {
    setComparisonVersionTwo,
    selectedOptionTwo,
    setSelectedOptionTwo,
    descriptionTwo,
    setDescriptionTwo,
    numberVersionTwo,
    setNumberVersionTwo,
    comparisonVersionTwo
  } = useVersioning();
  // const options = ["01", "02", "03"];
  const [isOpen, setIsOpen] = useState(false);
  const [modifyMainTemplate, setModifyMainTemplate] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
    setModifyMainTemplate(!modifyMainTemplate);
  }, [isOpen, modifyMainTemplate]);

  const { currentTemplateInfo } = useMainHook();

  let getTemplateId = currentTemplateInfo.current.id;

  const { handleSaveTemplate } = useHeaderEditor();

  useEffect(() => {
    const autoLoad = async () => {
      try {
        const fetchMyTemplate = await api.get(
          `user-templates/${getTemplateId}`
        );
        const { template } = fetchMyTemplate.data as any;
        currentTemplateInfo.current = fetchMyTemplate.data;
      } catch (err) {
        console.error("error", err);
      }
    };

    autoLoad();
  }, [handleSaveTemplate, modifyMainTemplate]);

  const onOptionClicked = useCallback(
    (
        value: string,
        index: number,
        template: PageProps[],
        description: string,
        version: string
      ) =>
      () => {
        setSelectedOptionTwo(Number(version));
        setIsOpen(false);
        setComparisonVersionTwo(template);
        setDescriptionTwo(description);
        setNumberVersionTwo(version);
      },
    [selectedOptionTwo, isOpen, descriptionTwo, numberVersionTwo]
  );

  const handleSelectMainVersion = useCallback(
    mainVersionObject => {
      setModifyMainTemplate(!modifyMainTemplate);
      setSelectedOptionTwo("Versão principal");
      setIsOpen(false);
      setComparisonVersionTwo(mainVersionObject);
      setDescriptionTwo("principal");
      setNumberVersionTwo("Principal");
    },
    [
      isOpen,
      modifyMainTemplate,
      selectedOptionTwo,
      comparisonVersionTwo,
      descriptionTwo,
      numberVersionTwo
    ]
  );

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpen(false);
      setIsComponentVisible(true);
    } else {
      return;
    }
  }, [isComponentVisible, setIsComponentVisible]);

  return (
    <>
      <Container borderRadius={borderRadius}>
        <DropDownContainer ref={ref}>
          <DropDownHeader
            isNumber={selectedOptionTwo}
            Open={isOpen}
            onClick={handleClick}
          >
            <span>{selectedOptionTwo || "Escolher versão"}</span>
          </DropDownHeader>
          {isOpen && isComponentVisible && (
            <DropDownListContainer onClick={handleClick}>
              <DropDownList>
                <ListItem
                  value="Versão principal"
                  onClick={() =>
                    handleSelectMainVersion(
                      currentTemplateInfo.current.template.arrayOfPages
                    )
                  }
                >
                  Versão principal
                </ListItem>
                {dataVersionOfTemplate
                  ? dataVersionOfTemplate.map((option, index) => (
                      <ListItem
                        onClick={onOptionClicked(
                          option.id,
                          index,
                          option.template.arrayOfPages,
                          option.description,
                          option.version
                        )}
                        value={option.id}
                        key={option.id}
                      >
                        {option.version}
                      </ListItem>
                    ))
                  : ""}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
      </Container>
    </>
  );
};

export default DropdownVersioningTwo;
