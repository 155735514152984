import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 21px;
  height: 21px;
  fill: currentColor;
`;

type Props = {
  color: string;
};

const WorkflowIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 21 21" color={color}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.75 2.17578C5.75 1.20928 6.5335 0.425781 7.5 0.425781H13C13.9665 0.425781 14.75 1.20928 14.75 2.17578V4.67578C14.75 5.64228 13.9665 6.42578 13 6.42578H7.5C6.5335 6.42578 5.75 5.64228 5.75 4.67578V2.17578ZM7.5 1.92578C7.36193 1.92578 7.25 2.03771 7.25 2.17578V4.67578C7.25 4.81385 7.36193 4.92578 7.5 4.92578H13C13.1381 4.92578 13.25 4.81385 13.25 4.67578V2.17578C13.25 2.03771 13.1381 1.92578 13 1.92578H7.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.75 9.17627C5.75 8.20977 6.5335 7.42627 7.5 7.42627H13C13.9665 7.42627 14.75 8.20977 14.75 9.17627V11.6763C14.75 12.6428 13.9665 13.4263 13 13.4263H7.5C6.5335 13.4263 5.75 12.6428 5.75 11.6763V9.17627ZM7.5 8.92627C7.36193 8.92627 7.25 9.0382 7.25 9.17627V11.6763C7.25 11.8143 7.36193 11.9263 7.5 11.9263H13C13.1381 11.9263 13.25 11.8143 13.25 11.6763V9.17627C13.25 9.0382 13.1381 8.92627 13 8.92627H7.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.75 16.1772C5.75 15.2107 6.5335 14.4272 7.5 14.4272H13C13.9665 14.4272 14.75 15.2107 14.75 16.1772V18.6772C14.75 19.6437 13.9665 20.4272 13 20.4272H7.5C6.5335 20.4272 5.75 19.6437 5.75 18.6772V16.1772ZM7.5 15.9272C7.36193 15.9272 7.25 16.0392 7.25 16.1772V18.6772C7.25 18.8153 7.36193 18.9272 7.5 18.9272H13C13.1381 18.9272 13.25 18.8153 13.25 18.6772V16.1772C13.25 16.0392 13.1381 15.9272 13 15.9272H7.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.250004 18.1772L0.250005 9.68116L4.24219 9.68116L4.24219 11.1812L1.75 11.1812L1.75 16.6772L4.24609 16.6772L4.24609 18.1772L0.250004 18.1772Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.25 11.1719L20.25 2.67579L16.2578 2.67579L16.2578 4.17579L18.75 4.17579L18.75 9.67187L16.2539 9.67187L16.2539 11.1719L20.25 11.1719Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.36058 17.4214L2.17459 15.8763L3.36447 14.963L5.2488 17.4179L3.36618 19.8897L2.17288 18.9808L3.36058 17.4214Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.1394 10.4161L18.3254 8.87094L17.1355 7.95761L15.2512 10.4125L17.1338 12.8843L18.3271 11.9755L17.1394 10.4161Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default WorkflowIcon;
