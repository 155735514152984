import styled from "styled-components";

interface colorButton {
  backgroundColor: "#343A40" | "#663399" | "#FFFFFF";
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  }

  @media (max-width: 1366px) {
    h4 {
      font-weight: 600;
      font-size: 17px;
      line-height: 19px;
    }
  }
`;

export const BoxButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-inline: 20px;
`;

export const ParagraphError = styled.p`
  color: #de2d2d;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  margin-top: 7px;
`;

export const ButtonModal = styled.button<colorButton>`
  height: 40px;
  width: 49%;
  border-radius: 5px;
  background-color: ${props =>
    props.backgroundColor === "#663399"
      ? props.theme.colors.primary
      : props.backgroundColor};

  font-weight: 500;
  font-size: 14px;
  line-height: 16.41px;
  color: ${props =>
    props.backgroundColor === "#FFFFFF" ? "#663399" : "#FFFFFF"};

  border: ${props =>
    props.backgroundColor === "#FFFFFF" ? "solid 1px #663399" : "none"};

  @media (max-width: 1600px) {
    height: 36px;
  }
`;
