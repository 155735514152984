import { lighten } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  min-height: 30px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  margin: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  > span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 13px;
    font-weight: 400;
    line-height: 100%;
  }
`;

export const ContainerTwo = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  padding-inline: 10px;

  > span {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
    max-width: 500px;
    text-align: center;
  }
`;
