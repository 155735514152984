import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.aside`
  grid-area: 1 / 1 / 6 / 2;

  width: 246px;
  height: 100%;
  background-color: #f5f5f5;
  padding-block: 15px;

  display: flex;
  flex-direction: column;
  gap: 44px;

  .active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Header = styled.figure`
  padding-left: 20px;
`;

export const Button = styled(Link)`
  width: 100%;
  height: 44px;
  padding-inline: 10px;
  background-color: transparent;
  font-size: 14px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black100};

  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  :hover {
    background-color: #ebebec;
  }
`;
