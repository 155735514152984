import { Modal } from "@material-ui/core";
import { Container, ModalContainer, ButtonBox, Title } from "./styled";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonExit from "../../../../shared/components/ButtonExit";
import PlainText from "../../../../shared/components/PlainText";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import { useCallback, useEffect } from "react";
import api from "../../../../services/api";
import { useClausesEditor } from "../../hooks/clausesEditor";

interface IModalDeleteTextProps {
  isActive: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  idClause: string;
  setIdClause: React.Dispatch<React.SetStateAction<string>>;
}

const ModalDeleteText = ({
  isActive,
  setIsModalOpen,
  idClause,
  setIdClause
}: IModalDeleteTextProps) => {
  const { setClausesData } = useClausesEditor();

  const handleRemoveClause = useCallback(
    async (id: string) => {
      try {
        const responseRemoveClause = await api.delete(`user-clause/${id}`);
        // setRefreshClause(!refreshClause);
        setClausesData(state => {
          let cloneState = [...state];
          let newArray = cloneState.filter(item => item.id !== id);
          return newArray;
        });
      } catch (err) {
        console.error(err, "error when remove clause");
      }
    },
    [setClausesData]
  );

  useEffect(() => {
    return () => {
      setIdClause("");
    };
  }, []);

  return (
    <Modal
      open={isActive}
      children={
        <Container>
          <ModalContainer>
            <div>
              <Title>
                <TitleH5 title="Excluir texto" />{" "}
                <ButtonExit onClick={() => setIsModalOpen(false)} />
              </Title>
              <PlainText>Tem certeza que você quer excluir o texto?</PlainText>
            </div>
            <ButtonBox>
              <ButtonMaxWidth
                typeStyle="purple"
                text="Não"
                onClick={() => setIsModalOpen(false)}
              />
              <ButtonMaxWidth
                onClick={() => {
                  handleRemoveClause(idClause);
                  setIsModalOpen(false);
                }}
                typeStyle="white"
                text="Sim"
              />
            </ButtonBox>
          </ModalContainer>
        </Container>
      }
    />
  );
};

export default ModalDeleteText;
