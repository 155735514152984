import styled from "styled-components";
import { Theme } from "../../../../../../dtos/theme";

export const PasswordVerification = styled.div<{ isActive: any }>`
  width: 100%;
  padding-top: 15.15px;
  margin-bottom: 15.15px;
  display: flex;
  flex-direction: column;
  transform: translateY(10px);
  font-weight: 400;

  ul {
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    color: #343a40;
    margin-bottom: 1rem;
  }

  li {
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    color: #343a40;
    list-style: circle;
  }

  .have-min-length {
    color: ${({ isActive }) =>
      isActive.haveMinLength === ""
        ? "#343a40"
        : isActive.haveMinLength === "wrong"
        ? "#DE2D2D"
        : isActive.haveMinLength === "right" && "#007B36"};
  }

  .have-min-upper-lower {
    color: ${({ isActive }) =>
      isActive.haveUpperOrLower === ""
        ? "#343a40"
        : isActive.haveUpperOrLower === "wrong"
        ? "#DE2D2D"
        : isActive.haveUpperOrLower === "right" && "#007B36"};
  }

  .have-symbol {
    color: ${({ isActive }) =>
      isActive.haveOneSymbol === ""
        ? "#343a40"
        : isActive.haveOneSymbol === "wrong"
        ? "#DE2D2D"
        : isActive.haveOneSymbol === "right" && "#007B36"};
  }

  @media (max-width: 1366px) {
    margin-bottom: 8px;
    padding-top: 8px;
  }
`;

export const Container = styled.main`
  /* display: flex;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to top,
    ${({ theme }: Theme) => theme.colors.gradientBody},
    ${({ theme }: Theme) => theme.colors.gradientBody2} 80%
  );
  margin: 0 auto;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  overflow: hidden; */

  display: flex;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(0deg, #000000 0%, #663399 100%);
  position: relative;
  overflow: hidden;

  .hero-login-div {
    width: 197px;
    height: 85.5px;
    overflow: hidden;
    display: flex;
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;
  }

  .hero-login-img {
    transform: translateY(-1px) translateX(2px);
  }

  @media (max-width: 1600px) {
    .hero-login-div {
      width: 178px;
      height: 77px;
      overflow: hidden;
      display: flex;
      margin-bottom: 4rem;
    }

    .hero-login-img {
      transform: translateY(-1px) translateX(2px);
    }
  }

  @media (max-width: 1366px) {
    .hero-login-div {
      width: 139px;
      height: 60px;
      overflow: hidden;
      display: flex;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    .hero-login-img {
      transform: translateY(-1px) translateX(2px);
    }
  }
`;

export const AsideContainer = styled.aside`
  width: 100%;
  max-width: 40%;
  background: white;
  display: flex;
  height: 100vh;
  z-index: 100;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
  }
`;

export const MarketingSide = styled.div`
  width: 60%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5rem;

  .mockup-image {
    width: 1804.3px;
    height: 470.31px;
    z-index: 1;
    transform: translateX(-550px) translateY(0px);
  }

  @media (max-width: 1600px) {
    .mockup-image {
      width: 1204.3px;
      height: 370.31px;
      transform: translateX(-320px) translateY(0px);
    }
  }

  @media (max-width: 1366px) {
    .mockup-image {
      width: 1070.3px;
      height: 300.31px;
      margin-top: -1rem;
      transform: translateX(-290px) translateY(0px);
    }
  }

  @media (max-width: 800px) {
    width: 0%;
    display: none;
  }
`;

export const InputBase = styled.input`
  width: 100%;
  height: 45px;
  border: none;
  border-bottom: 1px solid black;
  font-size: 2rem;
  background-color: transparent;
  padding-bottom: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 3.5rem;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
    inset 0 0 0 100px rgba(255, 255, 255, 1);

  :focus {
    border-color: ${({ theme }) => theme.colors.background};
  }

  @media (max-width: 1600px) {
    height: 40px;
    margin-top: 0rem;

    ::placeholder {
      font-size: 1.6rem;
    }
  }

  @media (max-width: 1366px) {
    height: 40px;
    margin-top: 0rem;

    ::placeholder {
      font-size: 1.4rem;
    }
  }
`;

export const Label = styled.label`
  font-size: 1.6rem;
  font-style: italic;
  text-decoration: underline;
  color: #555454;
  margin-top: 1.5rem;
  margin-left: auto;
  cursor: pointer;
`;

export const ButtonStyled = styled.button`
  background-color: black;
  width: 100%;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  height: 58px;
  border-radius: 10px;
  margin-top: 4.3rem;
  transition: all 120ms linear;

  :hover {
    background: ${({ theme }) => theme.colors.background};
  }

  @media (max-width: 1600px) {
    margin-top: 0;
    min-height: 50px;
    font-size: 1.6rem;
  }

  @media (max-width: 1366px) {
    margin-top: 0;
    min-height: 42px;
    font-size: 1.4rem;
  }

  @media (max-width: 800px) {
    height: 42px;
  }
`;

export const LoginContent = styled.form`
  display: flex;
  width: 100%;
  max-width: 448px;
  height: auto;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .logo-login {
    width: 222px;
    height: 97.5px;
    margin-bottom: 3rem;
  }

  @media (max-width: 1600px) {
    max-width: 360px;

    .logo-login {
      width: 40%;
      height: 40%;
      margin-bottom: 5rem;
    }
  }

  @media (max-width: 1366px) {
    max-width: 320px;

    .logo-login {
      width: 40%;
      height: 40%;
      margin-bottom: 3.5rem;
    }
  }
`;

export const TextInfo = styled.p`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  color: #555454;
  margin: -2.5rem auto 3rem auto;
  align-items: center;
  text-align: center;
  width: 400px;

  @media (max-width: 1600px) {
    font-size: 1.6rem;
    width: 360px;
    margin-top: -3rem;
    margin-bottom: 4rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.4rem;
    width: 260px;
    margin-top: -3rem;
    margin-bottom: 3.4rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1220px;
  height: 100%;
  overflow-x: hidden;
  overflow: hidden;
`;

export const FormBox = styled.form`
  width: 844px;
  margin: auto 2rem;
  display: flex;
  padding: 10px 0 2px 0;
  flex-direction: column;
  height: 676px;
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  border-radius: 30px;
  box-shadow: -1px 1px 15px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 15px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 15px -3px rgba(0, 0, 0, 0.75);

  .img-logo {
    margin: 7.5rem auto 1rem auto;
    width: 252.78px;
    height: 53px;
  }

  @media (max-width: 1610px) {
    width: 720px;
    height: 560px;

    .img-logo {
      margin: 7.5rem auto 1rem auto;
      width: 192.78px;
      height: 43px;
    }
  }

  @media (max-width: 1399px) {
    width: 620px;
    height: 488px;

    .img-logo {
      margin: 4.5rem auto 0rem auto;
      width: 162.78px;
      height: 43px;
    }
  }
`;

export const HStack = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  padding-bottom: 0rem;
`;

export const Title = styled.span`
  font-family: Raleway, sans-serif;
  font-weight: bold;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.background};
  margin-bottom: 6rem;

  @media (max-width: 1600px) {
    font-size: 2rem;
    margin-top: 0rem;
    margin-bottom: 4.5rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.8rem;
    margin-top: 0rem;
    margin-bottom: 4rem;
  }
`;

export const Heading = styled.h1`
  font-size: 4.8rem;
  max-width: 673px;
  font-family: Raleway, sans-serif;
  line-height: 65px;
  color: white;
  text-align: center;

  @media (max-width: 1600px) {
    font-size: 4rem;
    line-height: 55px;
  }

  @media (max-width: 1366px) {
    font-size: 3.2rem;
    line-height: 55px;
    max-width: 500px;
    line-height: 45px;
  }
`;

export const LoadingFlex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  max-width: 548px;
  height: 360px;
  padding: 0 2rem;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  justify-content: center;
`;

export const Input = styled.input`
  margin: 0.9rem auto;
  width: 100%;
  max-width: 418px;
  height: 45px;
  border: none;
  border-bottom: 4px solid ${({ theme }: Theme) => theme.colors.background};
  border-radius: 2px;
  padding: 1rem 1rem 1rem 0rem;

  &::placeholder {
    font-size: 1.25rem;
    font-family: "Roboto";
    font-weight: 400;
  }
`;

export const Button = styled.button`
  width: 100%;
  max-width: 423px;
  height: 58px;
  margin: 0 auto;
  font-size: 1.25rem;
  font-family: "Roboto";
  font-weight: 600;
  margin-top: 3rem;
  color: ${({ theme }: Theme) => theme.colors.whiteColor};
  background: linear-gradient(
    to top,
    ${({ theme }) => theme.colors.gradientBody},
    ${({ theme }) => theme.colors.gradientBody2} 148%
  );

  transition: all 0.9s linear;

  &:hover {
    background: linear-gradient(
      to top,
      ${({ theme }) => theme.colors.black},
      ${({ theme }) => theme.colors.black} 148%
    );
  }
`;
