import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  overflow-y: auto;
  position: relative;
  padding-inline: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const GridContainerTemplates = styled.div`
  display: grid;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  height: auto;
  padding-bottom: 10rem;
  grid-column-gap: 12rem;
  grid-row-gap: 5rem;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalDeleteDocuments = styled.div`
  width: 10000px;
  height: 10000px;
  background: black;
  z-index: 999;
  position: absolute;
  left: 0;
  top: 0;
`;
