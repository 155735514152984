import { Outlet } from "react-router-dom";
import { CentralBoxMain, Container } from "./styles";

//Main component of the Dashboard
//Responsible for displaying the selected page.

//The Outlet component is responsible for indicating to React Router
//where the pages of the Dashboard should be rendered.

type Props = {
  isNotifications: boolean;
};

const Main: React.FC<Props> = ({ isNotifications }) => {
  return (
    <Container isNotifications={isNotifications}>
      <CentralBoxMain>
        <Outlet />
      </CentralBoxMain>
    </Container>
  );
};

export default Main;

