import { ISelectModalTeam } from "../../../../../../../dtos/teamsUtils";
import PersonalizedIcon from "../../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../../shared/utils/MountIcons";

import { Option, Item, Container } from "./styles";

interface ITeamItemProps {
  teamId: string;
  isWoner: boolean;
  setCreatedTeamId: React.Dispatch<React.SetStateAction<string>>;
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
  setIsSettings: React.Dispatch<React.SetStateAction<boolean>>;
  teamName: string;
  setNameOfTeam: React.Dispatch<React.SetStateAction<string>>;
  setIdLeaveTeam: React.Dispatch<React.SetStateAction<string>>;
  setIsOpneModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenModal: boolean;
}

const OptionsModal = ({
  setCreatedTeamId,
  setSelectModal,
  setIsSettings,

  teamId,
  isWoner,
  setIdLeaveTeam,
  teamName,
  setNameOfTeam,
  setIsOpneModal,
  isOpenModal
}: ITeamItemProps) => {
  return (
    <Container
      onClick={() => setIsOpneModal(!isOpenModal)}
      onMouseLeave={() => setIsOpneModal(false)}
    >
      <span />
      <span />
      <span />
      {isOpenModal && (
        <Option>
          {isWoner ? (
            <>
              <Item
                onClick={() => {
                  setCreatedTeamId(teamId);
                  setSelectModal({ modal: "Add Participant", isOpen: true });
                  setIsSettings(true);
                }}
              >
                <div>
                  <PersonalizedIcon
                    dPath={MountIcons.IconGear.dPath}
                    viewBox={MountIcons.IconGear.viewBox}
                    inactivatedColor="#676B70"
                  />
                  Configurações da equipe
                </div>
              </Item>

              <Item
                onClick={() => {
                  setCreatedTeamId(teamId);
                  setIsSettings(true);
                  setSelectModal({ modal: "Delete Team", isOpen: true });
                  setNameOfTeam(teamName);
                }}
              >
                <div>
                  <PersonalizedIcon
                    dPath={MountIcons.IconTrash.dPath}
                    viewBox={MountIcons.IconTrash.viewBox}
                    inactivatedColor="#676B70"
                  />
                  Excluir equipe
                </div>
              </Item>
            </>
          ) : (
            <Item
              onClick={() => {
                setIdLeaveTeam(teamId);
                setSelectModal({ modal: "Leave Team", isOpen: true });
                setIsSettings(true);
                setNameOfTeam(teamName);
              }}
            >
              <div>
                <PersonalizedIcon
                  dPath={MountIcons.ExitIcon.dPath}
                  viewBox={MountIcons.ExitIcon.viewBox}
                  inactivatedColor="#676B70"
                />
                Sair da equipe
              </div>
            </Item>
          )}
        </Option>
      )}
    </Container>
  );
};

export default OptionsModal;
