import React from "react";

// import { Container } from './styles';

const LineAlign: React.FC = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ic:round-format-line-spacing">
        <path
          id="Vector"
          d="M7.13611 7.01021C7.59082 7.01021 7.81312 6.39539 7.48977 6.04243L5.17582 3.42372C4.97372 3.196 4.66048 3.196 4.45839 3.42372L2.14443 6.04243C2.07461 6.12269 2.02743 6.22445 2.00885 6.33488C1.99026 6.44531 2.0011 6.55946 2.04001 6.66296C2.07891 6.76645 2.14413 6.85466 2.22744 6.91645C2.31076 6.97825 2.40845 7.01087 2.5082 7.01021H3.81169V18.3959H2.5082C2.05349 18.3959 1.83119 19.0108 2.15454 19.3637L4.46849 21.971C4.67059 22.1987 4.98383 22.1987 5.18592 21.971L7.49988 19.3637C7.5697 19.2834 7.61688 19.1817 7.63547 19.0713C7.65405 18.9608 7.64321 18.8467 7.60431 18.7432C7.5654 18.6397 7.50018 18.5515 7.41687 18.4897C7.33355 18.4279 7.23586 18.3953 7.13611 18.3959H5.83262V7.01021H7.13611ZM10.8849 7.01021H20.9895C21.5453 7.01021 22 6.49786 22 5.87164C22 5.24543 21.5453 4.73307 20.9895 4.73307H10.8849C10.3292 4.73307 9.87446 5.24543 9.87446 5.87164C9.87446 6.49786 10.3292 7.01021 10.8849 7.01021ZM20.9895 18.3959H10.8849C10.3292 18.3959 9.87446 18.9083 9.87446 19.5345C9.87446 20.1607 10.3292 20.6731 10.8849 20.6731H20.9895C21.5453 20.6731 22 20.1607 22 19.5345C22 18.9083 21.5453 18.3959 20.9895 18.3959ZM20.9895 11.5645H10.8849C10.3292 11.5645 9.87446 12.0769 9.87446 12.7031C9.87446 13.3293 10.3292 13.8416 10.8849 13.8416H20.9895C21.5453 13.8416 22 13.3293 22 12.7031C22 12.0769 21.5453 11.5645 20.9895 11.5645Z"
          fill="#343A40"
        />
      </g>
    </svg>
  );
};

export default LineAlign;
