import React from "react";
import { useTheme } from "../../../../../../hooks/theme";

// import { Container } from './styles';
interface BorderRadiusIconProps {
  isActive?: boolean;
  className?: string;
}

const RadiusBorderIcon: React.FC<BorderRadiusIconProps> = ({
  isActive,
  className
}) => {
  const { theme } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_10485_1025"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
        className={className}
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_10485_1025)">
        <path
          d="M2 22V19.7778H4.22222V22H2ZM2 17.5556V15.3333H4.22222V17.5556H2ZM2 13.1111V10.8889H4.22222V13.1111H2ZM2 8.66667V6.44444H4.22222V8.66667H2ZM2 4.22222V2H4.22222V4.22222H2ZM6.44444 22V19.7778H8.66667V22H6.44444ZM6.44444 4.22222V2H8.66667V4.22222H6.44444ZM10.8889 22V19.7778H13.1111V22H10.8889ZM15.3333 22V19.7778H17.5556V22H15.3333ZM19.7778 22V19.7778H22V22H19.7778ZM19.7778 17.5556V15.3333H22V17.5556H19.7778ZM22 13.1111H19.7778V7.55556C19.7778 6.62963 19.4537 5.84259 18.8056 5.19444C18.1574 4.5463 17.3704 4.22222 16.4444 4.22222H10.8889V2H16.4444C17.9815 2 19.2917 2.54167 20.375 3.625C21.4583 4.70833 22 6.01852 22 7.55556V13.1111Z"
          fill={isActive ? theme.colors.primary : "#000"}
        />
      </g>
    </svg>
  );
};

export default RadiusBorderIcon;
