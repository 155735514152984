import styled from "styled-components";

export const Container = styled.div``;

export const Table = styled.div`
  border-radius: 5px 5px 0px 0px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
`;

export const HeaderTable = styled.div`
  height: 30px;
  border-radius: 5px 5px 0px 0px;
  background-color: ${({ theme }) => theme.colors.black10};

  display: flex;
  align-items: center;

  display: grid;
  grid-template-columns: 1.5fr 0.4fr 0.4fr 0.3fr;

  font-family: "Ubuntu";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black100};

  p:first-child {
    padding-left: 16px;
  }
`;

export const Grid = styled.ul`
  height: 100%;

  display: grid;
  grid-template-rows: repeat(12, 44px);
  position: relative;
`;

export const ContainerLogin = styled.div`
  width: 100%;
  height: 528px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextNotTeam = styled.p`
  width: 100%;
  height: 500px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px !important;
`;

export const NoElements = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;

  color: #999c9f;

  height: 100%;
  min-width: 100%;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;
