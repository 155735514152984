import { cloneDeep } from "lodash";
import { Container, ImageTemplate, NoElements, Template } from "./styles";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import PlainText from "../../../../shared/components/PlainText";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";
import { IListTemplatesChecked } from "../../Teams/components/ModalTeams/ModalImportTemplate";

interface MyTemplatesProps {
  id: string;
  user_id: string;
  title: string;
  description: string;
  thumbnail: string;
  is_automation: boolean;
  automation_user_template_id: any;
  created_at: string;
  updated_at: string;
  thumbnail_url: string;
  is_checked?: boolean;
}

interface IProspTemplatesSelect {
  myTemplates: any;
  setMyTemplates: React.Dispatch<React.SetStateAction<MyTemplatesProps[]>>;
  loading: boolean;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  hasMore: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>;
  listTemplatesChecked: IListTemplatesChecked[];
  setListTemplatesChecked: React.Dispatch<React.SetStateAction<IListTemplatesChecked[]>>;
}

const TemplatesSelect = ({
  myTemplates,
  setMyTemplates,
  loading,
  setCurrentPage,
  hasMore,
  fetchNextPage,
  listTemplatesChecked,
  setListTemplatesChecked,
}: IProspTemplatesSelect) => {
  useEffect(() => {
    if (!loading) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries?.some(entry => entry?.isIntersecting)) {
          if (hasMore) {
            setCurrentPage(oldState => oldState + 1);
            fetchNextPage();
          }
        }
      });

      const myDiv = document.querySelector("#sentinela2");

      if (myDiv) {
        intersectionObserver?.observe(myDiv);
      }
      return () => intersectionObserver?.disconnect();
    }
  }, [loading, hasMore]);

  return (
    <Container isEmpty={myTemplates.length === 0}>
      {myTemplates.length === 0 ? (
        <NoElements>Nenhum projeto encontrado.</NoElements>
      ) : (
        myTemplates.map((template, index) => (
          <Template key={template.id}>
            <input
              type="checkbox"
              checked={!!template.is_checked}
              onChange={event => {
                const findTemplate = listTemplatesChecked.find(listIds => listIds.id === template.id);
                if (findTemplate) {
                  setListTemplatesChecked(listTemplatesChecked.filter(listIds => listIds.id !== template.id));
                } else {
                  setListTemplatesChecked(prevState => [...prevState, { id: template.id }]);
                }
                setMyTemplates(oldState => {
                  let cloneState = cloneDeep(oldState);
                  cloneState[index].is_checked = event.target.checked;
                  return cloneState;
                });
              }}
            />
            <ImageTemplate>
              {template.thumbnail !== "default" && (
                <img src={template.thumbnail_url} alt="Template" />
              )}
            </ImageTemplate>
            <PlainText>{template.title}</PlainText>
          </Template>
        ))
      )}
      {hasMore && (
        <>
          <div id="sentinela1" style={{ width: "100%", height: "50px" }} />
          <div
            id="sentinela2"
            style={{
              width: "100%",
              height: "50px",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                margin: "auto auto",
                width: "30px",
                height: "30px",
                display: "flex"
              }}
            >
              <ReactLoading type="spin" color="#000" height={30} width={30} />
            </div>
          </div>
        </>
      )}
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <p>
          <Skeleton height={200} count={1} />
        </p>
      </SkeletonTheme>
    </Container>
  );
};

export default TemplatesSelect;
