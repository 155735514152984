interface CloudIconProps {
  isActive?: boolean;
  className?: string;
  colorActive: string;
}

const AutomatizingIcon = ({
  isActive,
  className,
  colorActive,
  ...rest
}: CloudIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M2.76764 20.5C2.25588 20.5 2 20.2435 2 19.7305L2 4.26947C2 3.75649 2.25588 3.5 2.76763 3.5H4.82034C5.20416 3.5 5.39607 3.75649 5.39607 4.26947C5.39607 4.78244 5.20416 5.03918 4.82034 5.03918H3.84161L3.84161 18.961H4.82035C5.20416 18.961 5.39607 19.2175 5.39607 19.7304C5.39607 20.2434 5.20416 20.5 4.82035 20.5H2.76764Z"
        fill={isActive ? colorActive : "#999C9F"}
      />
      <path
        d="M7.29961 18.2978C6.51918 18.2978 6.80222 17.7207 6.80222 17.7207C6.80222 17.7207 6.847 17.5924 6.8598 17.5283L11.0818 6.14375C11.1841 5.87444 11.369 5.73978 11.9959 5.73978C12.7464 5.73978 12.9228 5.87444 13.0124 6.14375L17.2344 17.5283C17.2344 17.5283 17.6558 18.2978 16.8561 18.2978H16.1999C15.9185 18.2978 15.6818 18.2721 15.4899 18.2208C15.298 18.1567 15.1764 18.0477 15.1252 17.8938L14.3576 15.5469H9.71766L8.95003 17.8938C8.89885 18.0349 8.7965 18.1375 8.64297 18.2016C8.48945 18.2657 8.29754 18.2978 8.06725 18.2978H7.29961ZM10.5429 13.556H13.5324L11.9995 8.90073L10.5429 13.556Z"
        fill={isActive ? colorActive : "#999C9F"}
      />
      <path
        d="M19.2259 20.5C18.8421 20.5 18.6502 20.2435 18.6502 19.7305C18.6502 19.2176 18.8421 18.961 19.2259 18.961H20.2046L20.2046 5.03918H19.2259C18.8421 5.03918 18.6502 4.78269 18.6502 4.26971C18.6502 3.75673 18.8421 3.5 19.2259 3.5H21.2324C21.7441 3.5 22 3.75649 22 4.26947L22 19.7305C22 20.2435 21.7441 20.5 21.2324 20.5H19.2259Z"
        fill={isActive ? colorActive : "#999C9F"}
      />
    </svg>
  );
};

export default AutomatizingIcon;
