interface ClausesIconProps {
  isActive?: boolean;
  className?: string;
  colorActive: string;
}

const ClausesIcon = ({
  isActive,
  className,
  colorActive,
  ...rest
}: ClausesIconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fill={isActive ? colorActive : "#C4C4C4"}
        d="M2.57227 4.48438C2.57227 3.94072 3.01299 3.5 3.55664 3.5H21.5879C22.1315 3.5 22.5723 3.94072 22.5723 4.48438C22.5723 5.02803 22.1315 5.46875 21.5879 5.46875H3.55664C3.01299 5.46875 2.57227 5.02803 2.57227 4.48438ZM2.57227 9.5C2.57227 8.96497 3.00599 8.53125 3.54102 8.53125H21.6035C22.1385 8.53125 22.5723 8.96497 22.5723 9.5C22.5723 10.035 22.1385 10.4688 21.6035 10.4688H3.54102C3.00599 10.4688 2.57227 10.035 2.57227 9.5ZM2.57227 14.4844C2.57227 13.9407 3.01299 13.5 3.55664 13.5H21.5879C22.1315 13.5 22.5723 13.9407 22.5723 14.4844C22.5723 15.028 22.1315 15.4688 21.5879 15.4688H3.55664C3.01299 15.4688 2.57227 15.028 2.57227 14.4844ZM2.57227 19.5C2.57227 18.9477 3.01998 18.5 3.57227 18.5H14.0723C14.6246 18.5 15.0723 18.9477 15.0723 19.5C15.0723 20.0523 14.6246 20.5 14.0723 20.5H3.57227C3.01998 20.5 2.57227 20.0523 2.57227 19.5Z"
      />
    </svg>
  );
};

export default ClausesIcon;
