import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Container = styled.div`
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 0px 0px 5px 5px;
  padding: 8px 10px;
  animation: fadeIn 0.5s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 90%;
  }

  textarea {
    width: 100%;
    padding-top: 4px;
    border: none;
    resize: none;
    overflow: hidden;
    ::placeholder {
      color: #a5a5a5;
    }
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`;
