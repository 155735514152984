import styled from "styled-components";

interface CardImageProps {
  srcImg?: string;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 1.6rem auto;
`;

export const CardImage = styled.div<CardImageProps>`
  max-width: 216px;
  width: 100%;
  height: 100%;
  height: 300px;
  border-radius: 10px;
  background: white;
  position: relative;
  background-image: url(${({ srcImg }) => srcImg && srcImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-box-shadow: 1px 1px 15px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px 15px 5px rgba(0, 0, 0, 0.05);
  margin-right: 2.5rem;

  @media (max-width: 1700px) {
    width: 372px;
  }

  @media (max-width: 1602px) {
    max-width: 186px;
    width: 100%;
    height: 100%;
    height: 230px;
    border-radius: 10px;
    margin-right: 2rem;
  }

  @media (max-width: 1366px) {
    height: 210px;
    min-width: 140px;
  }
`;

export const CardInfos = styled.div`
  max-width: 100%;
  width: 100%;
  height: 110px;
  display: flex;
  border-radius: 8px;
  padding: 1rem 1rem;
  background: #f5f6fa;
  justify-content: space-between;
  margin-left: auto;
  margin: auto 0 auto auto;
  flex-direction: column;
`;

export const Heading = styled.h2`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  color: #0e51d4;
`;

export const Text = styled.span`
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.56rem;
  max-width: 232px;
  color: #0e51d4;
  align-items: center;
`;

export const HStackStar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const TextBlack = styled.span`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.37rem;
  max-width: 252px;
  width: 250px;
  margin-top: 1rem;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  min-height: 60px;
`;

export const SimpleItalicText = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  font-style: italic;
  max-width: 232px;
  color: ${({ theme }) => theme.colors.black};
`;

export const VStack = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const SpaceWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
