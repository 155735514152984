import React, { ReactNode } from "react";
import { Button } from "./styles";
import Tooltip from "../../../../../Tooltip";

// import { Container } from './styles';

interface ButtonMenuProps {
  src?: string;
  childrean?: ReactNode;
  onClick?: () => void;
  refMenu?: any;
  description?: string;
  title?: string;
  isDisable?: boolean;
}

const ButtonMenu: React.FC<ButtonMenuProps> = ({
  src,
  children,
  onClick,
  refMenu,
  description,
  title,
  isDisable
}) => {
  if (isDisable) {
    return (
      <Button ref={refMenu} onClick={onClick}>
        {children}
      </Button>
    );
  }

  return (
    <Tooltip
      placement="bottom-start"
      isDisable={isDisable}
      description={description}
      title={title}
    >
      <Button ref={refMenu} onClick={onClick}>
        {children}
      </Button>
    </Tooltip>
  );
};

export default ButtonMenu;
