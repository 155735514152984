import Konva from "konva";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "react-toastify";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useMainHook } from "../../../hooks/main";
import api from "../../../services/api";
import { useSelection } from "./selection";
import { useMetric } from "../../../hooks/metric";

interface VersioningContextData {
  isCompareVersionModalOpen: boolean;
  setIsCompareVersionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenCompareVersionModalOpen: () => void;
  handleCloseCompareVersionModalOpen: () => void;
  handleCreateNewVersionTemplate: () => void;
  loadingCreateVersion: boolean;
  setComparisonVersionOne: React.Dispatch<React.SetStateAction<PageProps[]>>;
  setComparisonVersionTwo: React.Dispatch<React.SetStateAction<PageProps[]>>;
  comparisonVersionOne: PageProps[] | [];
  comparisonVersionTwo: PageProps[] | [];
  versioningDescriptionModalOpen: boolean;
  setVersioningDescriptionModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  handleCloseDescriptionVersioningModalOpen: () => void;
  handleOpenDescriptionVersioningModalOpen: () => void;
  handleCreateNewVersionClick: () => void;
  descriptionHistory?: string | null;
  setDescriptionHistory?: React.Dispatch<string | null>;
  selectedOptionOne: number;
  setSelectedOptionOne: React.Dispatch<number | string | null>;
  selectedOptionTwo: number;
  setSelectedOptionTwo: React.Dispatch<number | string | null>;
  descriptionOne: string;
  descriptionTwo: string;
  setDescriptionOne: React.Dispatch<string | null>;
  setDescriptionTwo: React.Dispatch<string | null>;
  numberVersionOne: string;
  numberVersionTwo: string;
  setNumberVersionOne: React.Dispatch<string | null>;
  setNumberVersionTwo: React.Dispatch<string | null>;
  isRevert: RevertProps;
  setIsRevert: React.Dispatch<React.SetStateAction<RevertProps>>;
  forceUpdateRevert: boolean;
  setForceUpdateRevert: React.Dispatch<React.SetStateAction<boolean>>;
}

interface VersioningContextProps {
  children: ReactNode;
}

export interface IRenderedObject {
  x: number;
  y: number;
  width: number;
  align?: "justify" | "center" | "left" | "right";
  text?: string;
  fontSize?: number;
  fontFamily?: string;
  fill?: string;
  id: string;
  object: string;
  setPoints?: number;
  stroke?: string;
  strokeWidth?: string;
  src?: string;
  dash?: number[];
  cornerRadius?: number;
  lineHeight?: number;
  textDecoration?: "underline" | "none";
  fontStyle?: string;
  ZIndex?: number;
}

interface PageProps {
  pageNumber: number;
  renderObjects: IRenderedObject[];
}

interface RevertProps {
  id: string;
  revert: boolean;
}

interface DataObjectForApiProps {
  user_template_id?: string;
  template?: {
    arrayOfPages?: PageProps[];
  };
  description?: string;
}

const VersioningContext = createContext<VersioningContextData>(
  {} as VersioningContextData
);

const VersioningHookProvider: React.FC<VersioningContextProps> = ({
  children
}) => {
  const {
    objectScreen,
    setObjectScreen,
    stageRef,
    currentTemplateInfo,
    documentName
  } = useMainHook();

  const { setSelectedObject } = useSelection();
  const { handleCreatedVersionAction } = useMetric();
  const [image, setImage] = useState(null);
  const [comparisonVersionOne, setComparisonVersionOne] = useState<PageProps[]>(
    []
  );
  const [comparisonVersionTwo, setComparisonVersionTwo] = useState<PageProps[]>(
    []
  );
  const [isCompareVersionModalOpen, setIsCompareVersionModalOpen] =
    useState(false);

  const [isRevert, setIsRevert] = useState<RevertProps>({
    id: "",
    revert: false
  });

  const [descriptionOne, setDescriptionOne] = useState("");
  const [descriptionTwo, setDescriptionTwo] = useState("");

  const [descriptionHistory, setDescriptionHistory] = useState(null);

  const [versioningDescriptionModalOpen, setVersioningDescriptionModalOpen] =
    useState(false);
  const [loadingCreateVersion, setLoadingCreateVersion] = useState(false);

  const [selectedOptionOne, setSelectedOptionOne] = useState(null);
  const [selectedOptionTwo, setSelectedOptionTwo] = useState(null);
  const [numberVersionOne, setNumberVersionOne] = useState("");
  const [numberVersionTwo, setNumberVersionTwo] = useState("");

  const handleOpenCompareVersionModalOpen = useCallback(() => {
    setIsCompareVersionModalOpen(true);
  }, [isCompareVersionModalOpen]);

  const handleCloseCompareVersionModalOpen = useCallback(() => {
    setIsCompareVersionModalOpen(false);
  }, [isCompareVersionModalOpen]);

  const handleOpenDescriptionVersioningModalOpen = useCallback(() => {
    setVersioningDescriptionModalOpen(true);
  }, [versioningDescriptionModalOpen]);

  const handleCloseDescriptionVersioningModalOpen = useCallback(() => {
    setVersioningDescriptionModalOpen(false);
  }, [versioningDescriptionModalOpen]);

  const handleCreateNewVersionClick = useCallback(() => {
    setSelectedObject(null);
    handleOpenDescriptionVersioningModalOpen();
  }, []);

  const [forceUpdateRevert, setForceUpdateRevert] = useState(false);

  const handleCreateNewVersionTemplate = useCallback(async () => {
    if (descriptionHistory !== null || descriptionHistory !== "") {
      setSelectedObject(null);
      let stages = stageRef.current as Konva.Stage[];
      let formatedObjectToApi: any = [];
      const dataURL = stages[0].toDataURL();
      const blob = await (await fetch(dataURL)).blob();
      const file = new File([blob], "fileName.png", { type: "image/png" });
      let formData = new FormData();
      formData.append("thumbnail", file);
      stages.forEach((stage, index) => {
        let pageNumber = stage.attrs.id;
        let renderObjects = [];
        stage.children[0].children.forEach((object, indexObject) => {
          let objectWithExtractedImagePropertie = null;
          if (object?.attrs?.image) {
            let { image, ...rest } = object.attrs;
            objectWithExtractedImagePropertie = rest;
          }
          renderObjects.push(
            objectWithExtractedImagePropertie
              ? objectWithExtractedImagePropertie
              : object.attrs
          );
        });

        formatedObjectToApi.push({
          pageNumber,
          renderObjects
        });
      });

      const idOfTemplate = currentTemplateInfo.current.id;

      let objectTemplate = {
        user_template_id: idOfTemplate,
        template: {
          arrayOfPages: formatedObjectToApi
        },
        description: descriptionHistory
      };

      let idVersioning = null;

      try {
        setLoadingCreateVersion(true);
        const responseApi = await api.post(
          "user-template-versions",
          objectTemplate,
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "true"
            }
          }
        );

        //Created new version action register function
        handleCreatedVersionAction({
          version_id: responseApi.data.id,
          project_id: idOfTemplate
        });

        idVersioning = responseApi.data.id;

        if (idVersioning !== null) {
          try {
            const uploadThumbnailResponse = await api.patch(
              `user-template-versions/thumbnail-upload/${idVersioning}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            );
          } catch (err) {
            // console.error("erro do patch upload image");
          }
        }

        setLoadingCreateVersion(false);
        toast.success("Versão salva com sucesso!");
        setDescriptionHistory(null);
        setSelectedOptionOne(null);
        setSelectedOptionTwo(null);
        setComparisonVersionOne([]);
        setComparisonVersionTwo([]);
        handleCloseDescriptionVersioningModalOpen();
      } catch (err) {
        setLoadingCreateVersion(false);
        console.error(err);
      }
    } else {
      alert("Insira a descrição");
    }
  }, [stageRef, currentTemplateInfo, descriptionHistory, selectedOptionOne]);

  return (
    <VersioningContext.Provider
      value={{
        isCompareVersionModalOpen,
        setIsCompareVersionModalOpen,
        handleOpenCompareVersionModalOpen,
        handleCloseCompareVersionModalOpen,
        handleCreateNewVersionTemplate,
        loadingCreateVersion,
        setComparisonVersionOne,
        setComparisonVersionTwo,
        comparisonVersionOne,
        comparisonVersionTwo,
        versioningDescriptionModalOpen,
        setVersioningDescriptionModalOpen,
        handleOpenDescriptionVersioningModalOpen,
        handleCloseDescriptionVersioningModalOpen,
        handleCreateNewVersionClick,
        descriptionHistory,
        setDescriptionHistory,
        selectedOptionOne,
        setSelectedOptionOne,
        selectedOptionTwo,
        setSelectedOptionTwo,
        descriptionOne,
        setDescriptionOne,
        descriptionTwo,
        setDescriptionTwo,
        numberVersionOne,
        numberVersionTwo,
        setNumberVersionOne,
        setNumberVersionTwo,
        isRevert,
        setIsRevert,
        forceUpdateRevert,
        setForceUpdateRevert
      }}
    >
      {children}
    </VersioningContext.Provider>
  );
};

// creating hook

function useVersioning(): VersioningContextData {
  const context = useContext(VersioningContext);

  if (!context) {
    throw new Error("useVersioning must be used with an versioningProvider");
  }

  return context;
}

export { VersioningHookProvider, useVersioning };
