import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../hooks/auth";

// import { Container } from './styles';

//page for signOut unpaid user

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  useEffect(() => {
    navigate("/");
    signOut();
  }, []);

  return <div />;
};

export default ErrorPage;
