import styled from "styled-components";

interface ContainerSidebarNavProps {
  paddingTop?: string;
  padding?: string;
  scroll?: string;
}

export const ContainerSidebarNav = styled.div<ContainerSidebarNavProps>`
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .adjustmargin {
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
  }
`;
