import styled from "styled-components";

export const Main = styled.div`
  .active-link {
    color: ${({ theme }) => theme.colors.primary};
    font-family: "Ubuntu", sans-serif;
  }

  .inactive-link {
    color: ${({ theme }) => theme.colors.black75};
    font-family: "Ubuntu", sans-serif;
  }

  a {
    text-decoration: none;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  padding: 12px 12px 12px 20px;
  position: relative;

  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.black10};
  }

  /*for changing icon's height*/
  .icon {
    width: 20px;
    height: 20px;
  }

  .icon-shared {
    width: 20px;
    height: 20px;
  }
`;

export const ButtonText = styled.label`
  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 400;

  cursor: pointer;
`;

export const ContainerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Beta = styled.div`
  position: absolute;
  right: 20px;

  width: 63px;
  height: 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.emphasis};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.colors.white100};

  font-family: "Ubuntu";
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
`;
