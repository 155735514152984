import styled from "styled-components";

export const ItemStyle = styled.li`
  width: 100%;
  display: grid;
  justify-content: space-between;
  align-items: center;

  grid-template-columns: 1fr 1fr 1fr 0.17fr;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
  }

  figure {
    width: 30px;
    height: 30px;
    margin-left: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    color: #999c9f;
  }

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  @media (min-width: 1920px) {
    & > div {
      height: 50px;
    }

    figure {
      width: 35px;
      height: 35px;
    }

    svg {
      width: 28px;
      height: 28px;
    }

    p,
    span {
      font-size: 14px;
    }
  }
`;
