import { HTMLProps } from "react";
import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

export const Container = styled.div`
  width: 100%;
  margin-block: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  display: flex;
  padding: 3px 16px 3px 10px;
  align-items: center;
  gap: 8px;

  background-color: transparent;

  :hover {
    background-color: #ebebec;
  }
`;

export const Heading = styled.p`
  color: #999c9f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;
