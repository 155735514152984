import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  z-index: 101;
  width: 15px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  pointer-events: none;
  user-select: none;

  cursor: e-resize;
`;

export const FirsLine = styled.div<{ isActive: boolean }>`
  height: 30px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  pointer-events: visible;
  user-select: auto;

  div {
    width: 1px;
    height: 100%;
    background-color: ${props => props.theme.colors.primary};
    position: relative;

    .vector-right {
      position: absolute;
      left: 4px;
      top: 14px;

      border-top: 5.3px solid transparent;
      border-bottom: 5.3px solid transparent;
      border-left: 5.3px solid ${props => props.theme.colors.primary};

      display: ${({ isActive }) => (isActive ? "none" : "inline-block")};
      animation: fade-in 0.3s ease-in-out;
    }

    .vector-left {
      position: absolute;
      right: 4px;
      top: 14px;

      border-top: 5.3px solid transparent;
      border-bottom: 5.3px solid transparent;
      border-right: 5.3px solid ${props => props.theme.colors.primary};

      display: ${({ isActive }) => (isActive ? "none" : "inline-block")};
      animation: fade-in 0.3s ease-in-out;
    }
  }
`;

export const LastLine = styled.div<{ isActive: boolean }>`
  height: 30px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  pointer-events: visible;
  user-select: auto;

  div {
    width: 1px;
    height: 100%;
    background-color: ${props => props.theme.colors.primary};
    position: relative;

    .vector-right {
      position: absolute;
      left: 4px;
      top: 4px;

      border-top: 5.3px solid transparent;
      border-bottom: 5.3px solid transparent;
      border-left: 5.3px solid ${props => props.theme.colors.primary};

      display: ${({ isActive }) => (isActive ? "none" : "inline-block")};
      animation: fade-in 0.3s ease-in-out;
    }

    .vector-left {
      position: absolute;
      right: 4px;
      top: 4px;

      border-top: 5.3px solid transparent;
      border-bottom: 5.3px solid transparent;
      border-right: 5.3px solid ${props => props.theme.colors.primary};

      display: ${({ isActive }) => (isActive ? "none" : "inline-block")};
      animation: fade-in 0.3s ease-in-out;
    }
  }
`;

export const SecondLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${props => props.theme.colors.primary};
  user-select: none;
`;
