import { Circle, Line, RegularPolygon, Transformer } from "react-konva";
import { Html } from "react-konva-utils";
import {
  BordContainer,
  ButtonSend,
  CommentBox,
  Container,
  ImageComment,
  Input,
  InputBox
} from "./styles";

import imageExample from "../../../assets/dragon.jpeg";
import { useCallback, useEffect, useRef, useState } from "react";

interface CommentsScreenProps {
  active: boolean;
  screen: "creating" | "listCommentSpecific" | "listAllComments" | "none";
}

interface CommentShapeProps {
  position: {
    x: number;
    y: number;
  };
  avatar: string;
  isEditing: boolean;
  idComment: string;
  commentValue: string;
  setCommentValue: React.Dispatch<React.SetStateAction<string>>;
  handleMakeComment: () => void;
  callbackClick: (id: string) => void;
  isVisible: boolean;
  selectedIdComment: string;
  isCommentsVisible: CommentsScreenProps;
}

const CommentShape: React.FC<CommentShapeProps> = ({
  position,
  avatar,
  isEditing,
  commentValue,
  setCommentValue,
  handleMakeComment,
  idComment,
  callbackClick,

  selectedIdComment
}) => {
  const [textareaFocused, setTextareaFocused] = useState(false);
  const refInput = useRef<HTMLTextAreaElement>(null);

  let isSelected = selectedIdComment === idComment;
  const [inputHeight, setInputHeight] = useState<number>(20); // Initial height

  const handleInputHeight = useCallback(() => {
    if (refInput.current) {
      setInputHeight(refInput.current.scrollHeight);
    }
  }, [setInputHeight]);

  useEffect(() => {
    setTimeout(() => {
      refInput.current?.focus();
    }, 200);
  }, [isEditing, position]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && textareaFocused) {
      // Prevent default "Enter" behavior (e.g., line break)
      event.preventDefault();
      handleMakeComment();
    }
  };

  return (
    <>
      <Html>
        <Container
          position={position}
          isSelected={isSelected}
          onClick={() => {
            if (isSelected) return;
            callbackClick(idComment);
          }}
        >
          <BordContainer isSelected={isSelected}>
            <ImageComment>
              <img src={avatar ? avatar : imageExample} alt="" />
            </ImageComment>
          </BordContainer>
        </Container>

        {isEditing && (
          <InputBox position={position}>
            <Input
              ref={refInput}
              height={inputHeight}
              placeholder="Adicione um comentário"
              className={textareaFocused ? "animate-grow" : ""}
              onFocus={() => setTextareaFocused(true)}
              onKeyDown={handleKeyDown} // Attach the event handler here
              onBlur={() => setTextareaFocused(false)}
              value={commentValue}
              onChange={event => {
                setCommentValue(event.target.value);
                handleInputHeight();
              }}
            />
            <ButtonSend onClick={handleMakeComment}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 31 31"
                fill="none"
              >
                <path
                  d="M15.5 30.4111C19.4782 30.4111 23.2936 28.8308 26.1066 26.0177C28.9196 23.2047 30.5 19.3894 30.5 15.4111C30.5 11.4329 28.9196 7.61758 26.1066 4.80453C23.2936 1.99149 19.4782 0.411132 15.5 0.411132C11.5218 0.411132 7.70644 1.99148 4.8934 4.80453C2.08035 7.61758 0.5 11.4329 0.499999 15.4111C0.499999 19.3894 2.08035 23.2047 4.8934 26.0177C7.70644 28.8308 11.5218 30.4111 15.5 30.4111ZM8.9375 16.3486C8.68886 16.3486 8.4504 16.2499 8.27459 16.074C8.09877 15.8982 8 15.6598 8 15.4111C8 15.1625 8.09877 14.924 8.27459 14.7482C8.4504 14.5724 8.68886 14.4736 8.9375 14.4736L19.7994 14.4736L15.7737 10.4499C15.5977 10.2738 15.4988 10.0351 15.4988 9.78613C15.4988 9.53718 15.5977 9.29842 15.7737 9.12238C15.9498 8.94635 16.1885 8.84745 16.4375 8.84745C16.6865 8.84745 16.9252 8.94635 17.1012 9.12238L22.7262 14.7474C22.8136 14.8345 22.8828 14.9379 22.9301 15.0518C22.9773 15.1657 23.0017 15.2878 23.0017 15.4111C23.0017 15.5344 22.9773 15.6565 22.9301 15.7704C22.8828 15.8843 22.8136 15.9878 22.7262 16.0749L17.1012 21.6999C16.9252 21.8759 16.6865 21.9748 16.4375 21.9748C16.1885 21.9748 15.9498 21.8759 15.7737 21.6999C15.5977 21.5238 15.4988 21.2851 15.4988 21.0361C15.4988 20.7872 15.5977 20.5484 15.7737 20.3724L19.7994 16.3486L8.9375 16.3486Z"
                  fill="#A5A5A5"
                />
              </svg>
            </ButtonSend>
          </InputBox>
        )}
      </Html>
    </>
  );
};

export default CommentShape;
