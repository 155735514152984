/*
this file is responsible for containing the editor
with its main components, loading the template in the main
state, in addition to controlling sharing access, such as
whether the user belongs to a shared group and whether he
has access to this document or if he already has someone
already using the document at that moment...
*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMainHook } from "../../../../hooks/main";
import api from "../../../../services/api";
import { usePagesEditor } from "../../hooks/pagesEditor";
import { useWorkspaceEditor } from "../../hooks/workspaceEditor";
import { CanvasContainer, FlexEditor } from "../../pages/styles";
import { useSharedDocument } from "../../hooks/sharedDocument";
import { useAuth } from "../../../../hooks/auth";
import { useHeaderEditor } from "../../hooks/headerEditor";
import { toast } from "react-toastify";
import ProviderModals from "../ProviderModals";
// import GlobalAnimationLoading from "../GlobalAnimationLoading";
import CanvasPage from "../CanvasPage";
import IncreasePages from "../IncreasePages";
import {
  getProfile,
  getUsersByDocumentId,
  defineActiveUserInDocument,
  // timerForUpdateUserActive,
} from "../../utils/getProfile";
import { useAutomaticSaving } from "../../hooks/automaticSaving";
import { Container } from "./styles";
import Quill from "quill";
import { useTextsEdition } from "../../hooks/textsEdition";
import { useSelection } from "../../hooks/selection";
import { PagesProps } from "../../../../dtos/PagesProps";
import { IRenderedObject } from "../DropdownVersioning";
import { cloneDeep, template } from "lodash";
import { AxiosResponse } from "axios";
import { Skeleton } from "@mui/material";
import MouseFollower from "../MouseFollower";

interface AllPagesProps {
  response: {
    arrayOfPages: PagesProps[];
  };
}

//set up interfaces
interface toggleProps {
  visible?: boolean;
  index: number;
}

interface UsersSharedGroupProps {
  user_template_id?: string;
  users?: [
    {
      id: string;
      name: string;
      email: string;
      avatar_url: string;
      permissions: string;
    }
  ];
}

interface UserProps {
  avatar_url?: string;
  email?: string;
  id?: string;
  name?: string;
  permissions?: string;
}

interface StorageDocProps {
  template: {
    pageNumber: number;
    renderObjects: IRenderedObject;
    lineGuide: {
      v: any[];
      h: any[];
    };
  };
}

interface DocumentProps {
  id: string;
  user_id: string;
  title: string;
  description: string;
  download_counter: number;
  save_counter: number;
  thumbnail: string;
  thumbnail_url: string;
  is_automation: boolean;
  automation_user_template_id: null | any;
  created_at: string;
  updated_at: string;
  is_automatic_numbering_active: boolean;
  automatic_numbering_position: string;
  first_page_automatic_numbering: boolean;
  recent_colors: string;
  is_favorite: boolean;
}

interface TeamDocProps {
  created_at: string;
  id: string;
  team_id: string;
  template: { arrayOfPages: PagesProps[]; };
  thumbnail: string;
  thumbnail_url: string;
  title: string;
  updated_at: string;
}

const EditorMain: React.FC = () => {
  //hooks
  const {
    loadingTemplate,
    setLoadingTemplate,
    flexEditorRef,
    containerRef,
    scrollEditorRef,
  } = useWorkspaceEditor();

  const {
    objectScreen,
    setObjectScreen,
    documentName,
    currentTemplateInfo,
    setTemplateIsLoaded,
    setCreatedSharedDocument,
    createdSharedDocument,
    setIsTeamTemplate,
    canvasIsLoading,
    setCanvasIsLoading,
    isDraggingObject,
    setIsDraggingObject,
  } = useMainHook();

  const {
    setSelectedObject,
    currentMultipleSelection,
    setNodesShift,
    setSelectedObjects,
  } = useSelection();
  const { setIsEditing } = useTextsEdition();

  const [dragObject, setDragObject] = useState({
    x: 0,
    y: 0,
  });

  const { data } = useAuth();
  // const navigate = useNavigate();
  const { setDocumentUsersAccess, setIsModalActiveUser, documentUsersAccess } =
    useSharedDocument();
  const { handleAddNewPage, scaleForZoom, sizePage, setIdTemplate } =
    usePagesEditor();
  const {
    setLoadingButtonGoHome,
    mountStages,
    hasLoadPages,
    setHasLoadPages,
    isSafetySave,
    colorsHistory,
    setColorsHistory,
  } = useHeaderEditor();
  const { setIsTemplateIsReady, isScrollChangeRef, initialLoad } =
    useAutomaticSaving();

  //set up states
  const [alreadyMessageCreated, setAlreadyMessageCreated] = useState(false);
  const [permissionDocument, setPermissionDocument] = useState(false);
  const { templateid, typetemplate } = useParams();
  const [loadingDefault, setLoadingDefault] = useState(false);
  const [verifyApi, setVerifyApi] = useState(false);

  const navigate = useNavigate();

  // const containerRef = useRef(null);
  // const [activePage, setActivePage] = useState(0);
  const pageRefs = useRef([]);
  const [usersSharedGroup, setUsersSharedGroup] =
    useState<UsersSharedGroupProps>({});

  const [usersAuthorized, setUsersAuthorized] = useState<UserProps>({
    avatar_url: null,
    email: null,
    id: null,
    name: null,
    permissions: null,
  });

  //this state controls page is visible or not and which index
  //should be visible or not
  const [isOpenOptions, setIsOpenOptions] = useState<toggleProps>({
    visible: true,
    index: 0,
  });

  let Block = Quill.import("blots/block");
  Block.tagName = "p";
  Quill.register(Block, true);

  //this state controls page is visible or not and which index
  //should be visible or not
  const toggleActiveOptionsPage = (visible?: boolean, index?: any) => {
    setIsOpenOptions({
      visible,
      index,
    });
  };

  useEffect(() => {
    if (templateid) {
      setIdTemplate(templateid);
    }
  }, [templateid]);

  //some callbacks is trigger when fist load page
  useEffect(() => {
    //define user and save in memory
    const autoLoad = async () => {
      // fetch group of users what can access this template
      await getUsersByDocumentId({ setUsersSharedGroup, templateid });
      //when user enter to document
      await defineActiveUserInDocument({
        setLoadingDefault,
        templateid,
        setDocumentUsersAccess,
        setVerifyApi,
      });
    };

    if (typetemplate !== "team-template") {
      autoLoad();
    }
  }, [typetemplate]);

  useEffect(() => {
    setHasLoadPages(true);
  }, []);

  // we need to match if have a sharing group in this document,
  // if group exists then we wanna to know if that current user
  // it is inside that group shared of this document
  // we are save this user in this state to save your permission
  useEffect(() => {
    const matchUserInsideGroup = usersSharedGroup?.users?.find(
      (user) => user.id === data?.user?.id
    );
    //set user as var for security
    setUsersAuthorized((state) => {
      let cloneState = { ...state };
      cloneState = matchUserInsideGroup ? matchUserInsideGroup : {};
      return cloneState;
    });
  }, [usersSharedGroup]);

  const handleGetTemplateInfo = useCallback(async () => {
    try {
      const loadInfoTemplate = await api.get(`user-templates/${templateid}`);
      const templateInfo = loadInfoTemplate.data as DocumentProps;
      const { title, recent_colors } = templateInfo;
      documentName.current = title;
      currentTemplateInfo.current = templateInfo;
      if (colorsHistory?.length > 0 && recent_colors) {
        setColorsHistory(
          recent_colors.split(",").map((color) => ({
            hexadecimal: color,
          }))
        );
      } else {
        setColorsHistory([])
      }
    } catch (err) {
      console.log(err + "Error when get info template");
    }
  }, [currentTemplateInfo, documentName, templateid]);

  useEffect(() => {
    (async () => {
      if (typetemplate === "team-template") {
        setIsTeamTemplate(true);

        initialLoad.current = true;
        try {
          const loadInfoTemplate: AxiosResponse<TeamDocProps> = await api.get(
            `team-templates/list-specific/${templateid}`
          );

          const { template, title } = loadInfoTemplate.data as TeamDocProps;
          currentTemplateInfo.current = loadInfoTemplate.data;
          documentName.current = title;

          setObjectScreen(() => {
            const newState = template.arrayOfPages.map((page, index) => {
              return {
                pageNumber: page.pageNumber,
                renderObjects: page.renderObjects,
                lineGuide: page.lineGuides,
                visible: index <= 2 ? true : false,
              };
            });

            return newState;
          });
          if (!isSafetySave.current) {
            isSafetySave.current = true;
          }
          setLoadingTemplate(false);
          setCanvasIsLoading(false);
          setIsTemplateIsReady(true);
          setHasLoadPages(false);
          isSafetySave.current = true;

          // currentTemplateInfo.current = templateInfo;
        } catch (err) {
          console.log(err + "Error when get info template");
        }
      }
    })();
  }, [templateid, typetemplate]);

  useEffect(() => {
    (async () => {
      if ( usersSharedGroup?.users?.length > 0 && typetemplate === "my-template" ) {
        if (!usersAuthorized.id) {
          navigate("/");
          return;
        }
        setCanvasIsLoading(true);
        toast.warning('Aguarde, estamos carregando o seu documento')
        setIsTeamTemplate(false);
        await handleGetTemplateInfo();
        isScrollChangeRef.current = true;

        //i.e. the backup and main state will be the same
        //medium or large document and so we should complete all pages
        try {
          const responseAllPages: AxiosResponse<AllPagesProps> = await api.get(
            `user-templates/template/${templateid}`
          );

          if (responseAllPages?.data.response) {
            // currentTemplateInfo.current = responseAllPages.data;
            const AllPages = responseAllPages.data.response.arrayOfPages;
            // when was loaded rest of pages just increment to the main state
            if (AllPages) {
              setObjectScreen(() => {
                const pagesAfterThreePage = AllPages.filter(
                  (_, index: number) => index >= 0
                ).map((pageDoc: PagesProps, indexPage) => ({
                  pageNumber: pageDoc.pageNumber,
                  renderObjects: pageDoc.renderObjects,
                  lineGuides: pageDoc.lineGuides ?? null,
                  visible: indexPage <= 2 ? true : false,
                }));
                return pagesAfterThreePage;
              });
              initialLoad.current = true;
              if (!isSafetySave.current) {
                isSafetySave.current = true;
              }
              setHasLoadPages(false);
              setIsTemplateIsReady(true);
              setLoadingTemplate(false);
              isSafetySave.current = true;
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [usersAuthorized, templateid, typetemplate]);

  const debounceTimeoutRef = useRef(null);

  const handleScrollPage = useCallback(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      const containerElement = containerRef.current;

      if (!containerElement) {
        return;
      }

      const containerRect = containerElement.getBoundingClientRect();
      const containerTop = containerRect.top;
      let mostVisiblePage = 0;
      let maxVisiblePercentage = 0;

      setNodesShift([]);
      if (currentMultipleSelection.current) {
        currentMultipleSelection?.current?.setNodes([]);
        setSelectedObjects([]);
        setNodesShift([]);
      }

      for (let i = 0; i < pageRefs.current.length; i++) {
        const pageElement = pageRefs.current[i];
        if (!pageElement) {
          continue;
        }
        const pageRect = pageElement.getBoundingClientRect();
        const pageTop = pageRect.top;
        const pageBottom = pageRect.bottom;
        // calculate porcentage relative display of user
        const visiblePercentage =
          Math.max(
            0,
            Math.min(pageBottom, containerRect.bottom) -
            Math.max(pageTop, containerTop)
          ) / pageRect.height;
        // update for page most visible
        if (visiblePercentage > maxVisiblePercentage) {
          mostVisiblePage = i;
          maxVisiblePercentage = visiblePercentage;
        }
      }

      if (!mountStages && !isDraggingObject) {
        setObjectScreen((oldState) => {
          const cloneState = cloneDeep(oldState);
          isScrollChangeRef.current = true;
          const updatedState = cloneState.map((page, index) => ({
            ...page,
            visible: Math.abs(index - mostVisiblePage) <= 1,
          }));
          return updatedState;
        });
      }
    }, 200); // Adjust the debounce time (in milliseconds) as needed
  }, [
    containerRef,
    setNodesShift,
    currentMultipleSelection,
    mountStages,
    isDraggingObject,
    setSelectedObjects,
    setObjectScreen,
    isScrollChangeRef,
  ]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current?.addEventListener("scroll", handleScrollPage);
      return () => {
        containerRef.current?.removeEventListener("scroll", handleScrollPage);
      };
    }
  }, [containerRef, handleScrollPage]);

  //to observe which user of group is active
  //in this document, we need to effect for each
  //1minutes and 30 seconds to save in database
  //which user is active, because if any user access
  //this document when busy, the system should be lock
  //the second user.
  useEffect(() => {
    //create a interval of timeout node
    let interval: NodeJS.Timeout;
    const autoLoadAccess = async () => {
      try {
        setLoadingDefault(true);
        //send active current user
        // timerForUpdateUserActive({ templateid });
      } catch (err) {
        console.error(err, "ERROR when update user access control2");
        setLoadingDefault(false);
      }
    };
    //update interval with 1m30s timer trigger
    interval = setInterval(async () => {
      autoLoadAccess();
    }, 90000); // 1min 30seg
    //clean up interval
    return () => {
      clearInterval(interval);
    };
  }, []);

  //this effect request to endpoint if that user
  //have permission to edit, and does have anyone
  //editing current document
  useEffect(() => {
    if (documentUsersAccess) {
      setPermissionDocument(documentUsersAccess.access_control);
    }

    if (verifyApi) {
      if (!permissionDocument) {
        setIsModalActiveUser(true);
      }
    }
  }, [documentUsersAccess, verifyApi]);

  //when open template, we only verify if this object was
  // created from a previously shared document to which
  // he has access
  useEffect(() => {
    if (createdSharedDocument) {
      toast.success(`A cópia foi criada e está salvo em "Meus projetos"`);
      setAlreadyMessageCreated(true);
    }
    //just clean up effect to reset the state
    return () => {
      setCreatedSharedDocument(false);
    };
  }, [createdSharedDocument]);

  useEffect(() => {
    setSelectedObject(null);
    setIsEditing("closed");

    return () => {
      setSelectedObject(null);
      setIsEditing("closed");
      setHasLoadPages(true);
      setIsTemplateIsReady(false);
      setLoadingButtonGoHome(false);
      setLoadingTemplate(true);
      initialLoad.current = true;
      isScrollChangeRef.current = false;
      isSafetySave.current = false;
      // setIsLoadingSavingTemplate(false);
    };
  }, []);

  const skeletonStyles = {
    width: "100%",
    height: "45px",
    maxWidth: "600px",
    display: "flex",
    justifyContent: "space-between",
    padding: "0",
    marginLeft: "3rem",
  };

  return (
    <Container
      id="container-auto-fullWidth"
      ref={containerRef}
      onScroll={(e) => (scrollEditorRef.current = e.currentTarget.scrollTop!)}
      isLoading={canvasIsLoading}
    >
      <FlexEditor ref={flexEditorRef}>
        <ProviderModals />

        <CanvasContainer>
          {loadingTemplate ? (
            <Container>
              <div style={skeletonStyles}>
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2.5rem",
                    bgcolor: "#d4d4d4",
                  }}
                  width={200}
                  height={40}
                />

                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2.5rem",
                    bgcolor: "#d4d4d4",
                    marginLeft: "2.5rem",
                  }}
                  width={30}
                  height={40}
                />
              </div>
              <Skeleton
                sx={{ bgcolor: "#d4d4d4" }}
                variant="rectangular"
                width={596}
                animation="pulse"
                height={842}
                style={{
                  borderRadius: "5px",
                  marginBottom: "2rem",
                  marginLeft: "3rem",
                }}
              />
            </Container>
          ) : (
            <>
              <CanvasPage
                objectScreen={objectScreen}
                isOpenOptions={isOpenOptions}
                toggleActiveOptionsPage={toggleActiveOptionsPage}
                sizePage={sizePage}
                permissionDocument={permissionDocument}
                pageRefs={pageRefs}
                hasLoadPages={hasLoadPages}
              />

              <IncreasePages
                loading={loadingTemplate}
                state={objectScreen}
                increaseCallback={handleAddNewPage}
              />
            </>
          )}
        </CanvasContainer>
      </FlexEditor>
    </Container>
  );
};

export default EditorMain;

