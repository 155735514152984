import styled from "styled-components";

export const Buttons = styled.button<{ isHover?: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ isHover }) =>
    isHover ? "rgba(255, 255, 255, 0.1)" : "transparent"};

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  & > img {
    width: 24px;
    height: 24px;
  }
`;

export const DropdownKindFile = styled.div`
  min-width: 132px;
  height: auto;
  position: absolute;
  top: 37px;
  left: 60px;

  border-radius: 5px;
  background: var(--white, #fff);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
`;

export const ButtonFile = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 8px;
  padding: 10px 16px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
    border-radius: 5px;
  }

  span {
    color: var(--preto-preto-75, #676b70);
    /* Dropdown, input e sidebar */
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
`;
