import UxDocLogoComponent from "../../../../../../../shared/components/UxDocLogo";
import { Button, Container, Header } from "./styled";
import PersonalizedIcon from "../../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../../shared/utils/MountIcons";

import { useTheme } from "../../../../../../../hooks/theme";
import { IconUser } from "../../assets/iconUser";
import { IconKey } from "../../assets/keyIcon";
import { IconShieldLock } from "../../assets/IconShieldLock";
import { Link, useLocation } from "react-router-dom";

export const Sidebar = () => {
  const { theme } = useTheme();
  const location = useLocation();
  const screenNow = location.pathname.split("/")[2];

  return (
    <Container>
      <Header>
        <UxDocLogoComponent />
      </Header>

      <nav>
        <Button className={screenNow === undefined && "active"} to="">
          <IconUser
            isActive={screenNow === undefined}
            colorActive={theme.colors.primary}
          />
          Dados pessoais
        </Button>

        <Button
          className={screenNow === "notificao" && "active"}
          to="notificao"
        >
          <PersonalizedIcon
            dPath={MountIcons.IconsEmail.dPath}
            viewBox={MountIcons.IconsEmail.viewBox}
            isActive={screenNow === "notificao"}
            activeColor={theme.colors.primary}
          />
          Notificações
        </Button>

        <Button
          className={screenNow === "alterar-senha" && "active"}
          to="alterar-senha"
        >
          <IconKey
            isActive={screenNow === "alterar-senha"}
            colorActive={theme.colors.primary}
          />
          Alterar senha
        </Button>

        <Button
          className={screenNow === "seguranca" && "active"}
          to="seguranca"
        >
          <IconShieldLock
            isActive={screenNow === "seguranca"}
            colorActive={theme.colors.primary}
          />
          Segurança
        </Button>
      </nav>
    </Container>
  );
};
