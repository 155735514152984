import { useComments } from "../../hooks/comments";
import CommentsCreating from "./components/CommentsCreating";
import ListCommentSpecific from "./components/ListCommentSpecific";
import ListAllComments from "./components/ListAllComments";
import { Container } from "./style";

const CommentsScreen = () => {
  const { isCommentsVisible } = useComments();

  return (
    <Container>
      {isCommentsVisible.screen === "creating" && <CommentsCreating />}
      {isCommentsVisible.screen === "listCommentSpecific" && (
        <ListCommentSpecific />
      )}
      {isCommentsVisible.screen === "listAllComments" && <ListAllComments />}
    </Container>
  );
};

export default CommentsScreen;
