import styled from "styled-components";

export const Container = styled.div<{
  position: {
    x: number;
    y: number;
  };
  isSelected: boolean;
}>`
  position: absolute;
  top: ${({ position }) => `${position.y}px`};
  left: ${({ position }) => `${position.x}px`};
  border-radius: 50%;
  width: 43px;
  height: 43px;
  cursor: pointer;
  -webkit-box-shadow: 3px 4px 14px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 14px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 7px 14px -6px rgba(0, 0, 0, 0.75);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all ease-in 50ms;
  transform: ${({ isSelected }) => (isSelected ? "scale(1.2)" : "")};
  user-select: none;
  transform: scale(0.8);

  :hover {
    transform: scale(1.2);
  }
`;

export const BordContainer = styled.div<{ isSelected: boolean }>`
  clip-path: url(#border-comment);
  background-color: ${({ isSelected }) => (isSelected ? "#663399" : "#fff")};
  position: relative;
  width: 47px;
  height: 48px;
`;

export const ImageComment = styled.div`
  clip-path: url(#image-comment);
  width: 50px;
  height: 50px;
  position: absolute;
  top: 2.7px;
  right: -5.7px;

  img {
    width: 50px;
    height: 50px;

    transform: translateY(-1px) translateX(-5px);

    pointer-events: none;
  }
`;

export const CommentBox = styled.div<{
  position: {
    x: number;
    y: number;
  };
  image: string;
  isSelected: boolean;
}>`
  position: absolute;
  border-radius: 50%;
  top: ${({ position }) => `${position.y}px`};
  left: ${({ position }) => `${position.x}px`};
  position: relative;
  width: 36px;
  height: 36px;
  background-color: "#fff";
  background-image: ${({ image }) => `url(${image})`};
  box-shadow: 0px 0px 92px -31px rgba(0, 0, 0, 0.67);
  border: ${({ isSelected }) =>
    !isSelected ? "2px solid #fff" : "3px solid #663399"};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-shadow: 3px 4px 14px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 14px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 4px 14px -6px rgba(0, 0, 0, 0.75);
  transition: all ease-in 50ms;
  transform: ${({ isSelected }) => (isSelected ? "scale(1.3)" : "")};
  overflow-y: hidden;
  :hover {
    transform: scale(1.2);
  }
`;

export const InputBox = styled.div<{
  position: {
    x: number;
    y: number;
  };
}>`
  width: 314px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  position: absolute;
  top: ${({ position }) => `${position.y + 3}px`};
  left: ${({ position }) =>
    position.x <= 400 ? `${position.x + 55}px` : `${position.x - 314}px`};
  padding: 4px 4px;
  display: flex;
  align-items: center;
  overflow-y: auto;
  z-index: 999999999999999999999999999;
  -webkit-box-shadow: 3px 4px 14px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 14px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 4px 14px -6px rgba(0, 0, 0, 0.75);
  overflow-y: hidden;

  justify-content: space-between;
`;

export const Input = styled.textarea<{ height: number }>`
  width: 85%;
  display: flex;
  border: none;
  padding-left: 0.4rem;
  height: 20px;
  min-height: 20px;
  overflow-y: hidden;
  transition: height 0.3s;
  resize: none;
  padding-top: 0.2rem;
  height: ${({ height }) => `${height}px`};

  /* &:focus {
    height: 70px;
  } */
`;

export const ButtonSend = styled.div`
  width: 10%;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
