import styled from "styled-components";

export const Subtitle = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 400;
`;
export const Input = styled.input`
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray600};
`;
export const Label = styled.label`
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 400;
  font-size: 13px;
`;

interface SaveButtonProps {
  disabled?: boolean;
}

export const SaveButton = styled.button<SaveButtonProps>`
  padding: 12px 16px;
  border-radius: 5px;
  border: ${({ theme, disabled }) =>
    disabled ? "1px solid #ccc" : `1px solid ${theme.colors.primary}`};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme, disabled }) =>
    disabled ? "#ccc" : theme.colors.primary};
  color: ${({ disabled }) => (disabled ? "#999" : "white")};
  cursor: pointer;
  width: 100%;
  &:hover {
    box-shadow: ${({ disabled }) =>
      disabled ? "none" : `0 0 4px 0px ${({ theme }) => theme.colors.primary}`};
  }
`;

export const CancelButton = styled.button`
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  min-width: 700px;
`;
export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding: 12px 12px;
  box-sizing: border-box;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TrashWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
`;

export const LinkButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  color: #999c9f;
  font-weight: 500;
  font-size: 14px;
`;

export const TableContainer = styled.div`
  width: 100%;
  border: 1px solid #ebebec;
  border-radius: 5px;
  min-height: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const THead = styled.thead`
  background-color: #ebebec;
`;
export const TableHead = styled.th`
  padding: 10px 20px;
  text-align: left;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray600};
  font-size: 13px;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ebebec;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const TableData = styled.td`
  padding: 10px 20px;
  font-size: 14px;
`;

export const SendButton = styled.button`
  padding: 13px 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  cursor: pointer;
  width: 100%;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
`;

export const EmailLabel = styled.span`
  color: #999c9f;
  font-weight: 400;
  font-size: 12px;
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #343a40;
  border: 1px solid #007b36;
  width: 700px;
`;

export const MessageErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #343a40;
  border: 1px solid #dc3545;
`;

export const MessageErrorText = styled.span`
  color: #dc3545;
  font-weight: 500;
  font-size: 14px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const MenuContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  border: 1px solid #ebebec;
  border-radius: 6px;
  box-shadow: 0 0 4px 0px #ebebec;
  background-color: white;
`;

export const MenuItem = styled.div`
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.gray600};
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: #f5f5f5;
  }
`;
