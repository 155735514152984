import React, { HTMLProps, useCallback, useEffect, useRef } from "react";

import { Container, MenuBoxFontFamily } from "./styles";
import iconArrow from "../../assets/arrow-down.svg";
import Tooltip from "../../../../../Tooltip";

interface FontFamilyMenuProps extends HTMLProps<HTMLDivElement> {
  isActive: boolean;
  onClose: () => void;
  changeFontSize: (size: number) => void;
  handleOpen: () => void;
  sizeFont: string;
  setSizeFont: React.Dispatch<React.SetStateAction<string>>;
  refInput: React.MutableRefObject<HTMLInputElement>;
}

const FontSizeMenu: React.FC<FontFamilyMenuProps> = ({
  isActive,
  onClose,
  onClick,
  changeFontSize,
  handleOpen,
  sizeFont,
  setSizeFont,
  refInput,
  ...rest
}) => {
  let fontFamilyArr = [
    "8px",
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
    "36px",
    "48px"
  ];

  const menuRef = useRef(null);

  const debounce = (func: (value: string) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return function (value: string) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(value);
      }, delay);
    };
  };

  const updateSizeFont = useCallback(
    (value: string) => {
      let numericValue = "";
      for (let i = 0; i < value.length; i++) {
        if (!isNaN(Number(value[i]))) {
          numericValue += value[i];
        }
      }

      changeFontSize(Number(numericValue));
    },
    [changeFontSize]
  );

  const debouncedUpdateSizeFont = debounce(updateSizeFont, 1500);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isActive &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isActive, onClose]);

  return (
    <div ref={menuRef} style={{ position: "relative" }} {...rest}>
      <Tooltip
        placement="bottom-start"
        description="Mude o tamanho da fonte do seu texto."
        title="Tamanho da fonte"
      >
        <Container isActive={isActive} onClick={handleOpen}>
          <input
            value={sizeFont}
            onChange={event => {
              const inputValue = event.target.value;
              setSizeFont(inputValue);
              debouncedUpdateSizeFont(inputValue);
            }}
            ref={refInput}
            onBlur={() => {
              updateSizeFont(sizeFont);
            }}
          />
          {/* <span style={{ cursor: "text" }}> {sizeFont} </span> */}
          <img src={iconArrow} alt="" />
        </Container>
      </Tooltip>

      {isActive && (
        <MenuBoxFontFamily>
          {fontFamilyArr.map(size => (
            <div
              onClick={() => {
                let numberSize = Number(size.replace("px", ""));
                changeFontSize(numberSize);
              }}
              key={size}
            >
              <span>{size}</span>
            </div>
          ))}
        </MenuBoxFontFamily>
      )}
    </div>
  );
};

export default FontSizeMenu;
