import styled from "styled-components";

export const ViewPlatformQRCode = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  /* background-color: brown; */
  flex-direction: column;
  align-items: center;

  padding: 0rem;
`;

export const QRCodeBox = styled.div`
  width: 188px;
  height: 188px;
  display: flex;
  background-color: #fff;
  margin-top: 1.2rem;
  border-radius: 5px;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
`;

