import React from "react";
import { Container } from "./styled";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "./styleTippy.css";
import { Placement } from "tippy.js";

interface ITooltipProps {
  children: any;
  title?: string;
  description?: string;
  placement: Placement;
  maxWidth?: number;
  disabled?: boolean;
  obs?: string;
  isDisable?: boolean;
}

const Tooltip = ({
  children,
  title,
  description,
  placement,
  disabled = false,
  maxWidth = 280,
  obs,
  isDisable
}: ITooltipProps) => {
  if (isDisable) return <>{children}</>;

  return (
    <Tippy
      content={
        <Container>
          <p>{title}</p>
          <span>{description}</span>
          <span className="obs-tooltip">{obs}</span>
        </Container>
      }
      allowHTML={true}
      placement={placement}
      arrow={false}
      maxWidth={maxWidth}
      delay={[400, 0]}
      className="tooltipCustom"
      disabled={disabled}
      theme="light"
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
