import { Container } from "./styles";

export const LoadingSpinner = () => {
  return (
    <Container>
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    </Container>
  );
};
