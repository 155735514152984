interface IconuserProps {
  isActive: boolean;
  colorActive: string;
}

export const IconUser = ({ isActive, colorActive }: IconuserProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46917 15.8066C5.41611 14.8596 6.70045 14.3276 8.03963 14.3276H15.9603C17.2995 14.3276 18.5838 14.8596 19.5308 15.8066C20.4777 16.7535 21.0097 18.0379 21.0097 19.377V21.3572C21.0097 21.9587 20.5221 22.4463 19.9206 22.4463C19.3191 22.4463 18.8316 21.9587 18.8316 21.3572V19.377C18.8316 18.6155 18.529 17.8852 17.9906 17.3467C17.4521 16.8083 16.7218 16.5058 15.9603 16.5058H8.03963C7.27813 16.5058 6.54781 16.8083 6.00934 17.3467C5.47088 17.8852 5.16837 18.6155 5.16837 19.377V21.3572C5.16837 21.9587 4.68078 22.4463 4.0793 22.4463C3.47783 22.4463 2.99023 21.9587 2.99023 21.3572V19.377C2.99023 18.0379 3.52222 16.7535 4.46917 15.8066Z"
        fill={isActive ? colorActive : "#676B70"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9995 4.62442C10.4137 4.62442 9.12821 5.90993 9.12821 7.49569C9.12821 9.08144 10.4137 10.367 11.9995 10.367C13.5852 10.367 14.8707 9.08144 14.8707 7.49569C14.8707 5.90993 13.5852 4.62442 11.9995 4.62442ZM6.95007 7.49569C6.95007 4.70698 9.21077 2.44629 11.9995 2.44629C14.7882 2.44629 17.0489 4.70698 17.0489 7.49569C17.0489 10.2844 14.7882 12.5451 11.9995 12.5451C9.21077 12.5451 6.95007 10.2844 6.95007 7.49569Z"
        fill={isActive ? colorActive : "#676B70"}
      />
    </svg>
  );
};
