import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 25px;
  height: 25px;
  fill: currentColor;
`;

type Props = {
  color: string;
};

const AddIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 25 25" color={color}>
    <path
      d="M13.25 11.4269V6.42688C13.25 6.16166 13.1446 5.90731 12.9571 5.71977C12.7696 5.53224 12.5152 5.42688 12.25 5.42688C11.9848 5.42688 11.7304 5.53224 11.5429 5.71977C11.3554 5.90731 11.25 6.16166 11.25 6.42688V11.4269H6.25C5.98478 11.4269 5.73043 11.5322 5.54289 11.7198C5.35536 11.9073 5.25 12.1617 5.25 12.4269C5.25 12.6921 5.35536 12.9465 5.54289 13.134C5.73043 13.3215 5.98478 13.4269 6.25 13.4269H11.25V18.4269C11.25 18.6921 11.3554 18.9465 11.5429 19.134C11.7304 19.3215 11.9848 19.4269 12.25 19.4269C12.5152 19.4269 12.7696 19.3215 12.9571 19.134C13.1446 18.9465 13.25 18.6921 13.25 18.4269V13.4269H18.25C18.5152 13.4269 18.7696 13.3215 18.9571 13.134C19.1446 12.9465 19.25 12.6921 19.25 12.4269C19.25 12.1617 19.1446 11.9073 18.9571 11.7198C18.7696 11.5322 18.5152 11.4269 18.25 11.4269H13.25Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default AddIcon;
