import styled from "styled-components";

export const Container = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  bottom: 21px;
  right: 13px;
  background-color: #639;
  z-index: 99999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #639;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.75));
  cursor: pointer;

  @media (max-width: 1366px) {
    width: 40px;
    height: 40px;

    svg {
      width: 22px;
    }
  }
`;
