import React, { ReactNode } from "react";

import { FlexContainer } from "./styles";

import { Placement } from "tippy.js";
import Tooltip from "../../../Tooltip";

interface ButtonIconsProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  width?: string;
  margin?: string;
  className?: string;
  justifyContent?: string;

  title?: string;
  description?: string;
  obs?: string;
  placement: Placement;
}

const ButtonIconsWithTooltip = ({
  children,
  width,
  margin,
  className,
  justifyContent,
  title,
  description,
  placement,
  obs,
  ...rest
}: ButtonIconsProps) => {
  return (
    <Tooltip
      title={title}
      description={description}
      placement={placement}
      obs={obs}
    >
      <FlexContainer
        justifyContent={justifyContent}
        margin={margin}
        width={width}
        className={className}
        {...rest}
      >
        {children}
      </FlexContainer>
    </Tooltip>
  );
};

export default ButtonIconsWithTooltip;
