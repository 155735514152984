import { cloneDeep } from "lodash";
import { ILinesPage } from "../../../../hooks/pagesEditor";
import { Box, Container, ImgTrash } from "./styled";

import Trash from "../../../../assets/trash.svg";

interface IRulerTopProps {
  setLinesPage: React.Dispatch<React.SetStateAction<ILinesPage[]>>;
  thisIndexObject: number;
  isActive: boolean;
  linespage: ILinesPage[];
}

const RulerTop = ({
  setLinesPage,
  thisIndexObject,
  isActive,
  linespage,
}: IRulerTopProps) => {
  if (!isActive) {
    return <Container />;
  }

  return (
    <Container
      onMouseDown={() => {
        if (isActive) {
          setLinesPage((prevState) => {
            let cloneState = cloneDeep(prevState);
            let thisObject = cloneState[thisIndexObject];
            thisObject.islineH = true;
            thisObject.islineV = false;

            return cloneState;
          });
        }
      }}
      className="get-height_ruler-top"
      isActive={isActive}
    >
      <ImgTrash
        src={Trash}
        alt="Lixeira"
        isActive={
          linespage[thisIndexObject]?.islineV ||
          linespage[thisIndexObject]?.islineH
        }
      />

      <div>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>0</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>1</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>2</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>3</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>4</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>5</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>6</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>7</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>8</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>9</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>10</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>11</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>12</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>13</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>14</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>15</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>16</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>17</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>18</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>19</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
          <p>20</p>
        </Box>
        <Box>
          <div>
            <span className="first-vector" />
          </div>
          <p>21</p>
        </Box>
      </div>
    </Container>
  );
};

export default RulerTop;
