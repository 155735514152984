import React, { InputHTMLAttributes, useEffect, useRef } from "react";
import { Container, InputBox, InputVariable, LabelVariable } from "./styles";
import { useField } from "@unform/core";

interface IVariables {
  variable: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  variable: string;
}

const Variables: React.FC<InputProps> = ({ variable, name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value"
    });
  }, [fieldName, registerField]);

  const result = variable.replace(/[._-]/g, " ");
  const resultNew = result.replaceAll("{", "");
  const resultWithoutKeys = resultNew.replaceAll("}", "");
  const FinalStringWithCapitalize =
    resultWithoutKeys.charAt(0).toUpperCase() + resultWithoutKeys.slice(1);

  return (
    <Container>
      <LabelVariable>{FinalStringWithCapitalize}</LabelVariable>
      <InputBox>
        <InputVariable defaultValue={defaultValue} ref={inputRef} {...rest} />
      </InputBox>
    </Container>
  );
};

export default Variables;
