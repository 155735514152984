import styled from "styled-components";

export const Container = styled.div`
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }

  cursor: pointer;
`;
