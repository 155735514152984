/*
this component receive callbacks relative modal of context right menu
when user clicked on right button of mouse and open some option of features
*/

import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";

//set up interfaces
interface RightClickMenuProps {
  isShowRightClickMenu: boolean;
  setDataPoints: (x: number, y: number) => void;
  points: CoordinatesProps;
  handleShowRightClick: () => void;
}

interface RightMenuProviderProps {
  children: ReactNode;
}

interface CoordinatesProps {
  x: number;
  y: number;
}

const RightClickMenuContext = createContext<RightClickMenuProps>(
  {} as RightClickMenuProps
);

const RightMenuContextProvider: React.FC = ({
  children
}: RightMenuProviderProps) => {
  //set up states
  const [isShowRightClickMenu, setIsShowRightClickMenu] = useState(false);
  const [points, setPoints] = useState({ y: 0, x: 0 });

  //if click of right menu trigger then open and
  //set coordinates on screen to display him
  useEffect(() => {
    const handleClick = () => setIsShowRightClickMenu(false);
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  function handleShowRightClick() {
    setIsShowRightClickMenu(true);
  }

  function setDataPoints(y: number, x: number) {
    setPoints({ y: y, x: x });
  }

  return (
    <RightClickMenuContext.Provider
      value={{
        isShowRightClickMenu,
        setDataPoints,
        points,
        handleShowRightClick
      }}
    >
      {children}
    </RightClickMenuContext.Provider>
  );
};

// creating hook

function useRightMenuContext(): RightClickMenuProps {
  const context = useContext(RightClickMenuContext);

  if (!context) {
    throw new Error("useAuth must be used with an RightMenuProvider");
  }

  return context;
}

export { RightMenuContextProvider, useRightMenuContext };
