/*
this component render just button for add new pages on editor
then when user click in add pages button we iterator our main state
with that action and add more pages. if loading is true then return
space container empty.
*/

import React from "react";
import { PagesProps } from "../../../../dtos/PagesProps";
import ButtonAddPageCanva from "../ButtonAddPageCanva";
import { SpaceOfContainer } from "./styles";

interface IncreasePages {
  loading: boolean;
  state: PagesProps[];
  increaseCallback: (page: number) => void;
}

const IncreasePages: React.FC<IncreasePages> = ({
  loading,
  state,
  increaseCallback
}) => {
  return (
    <>
      {!loading ? (
        <ButtonAddPageCanva
          onClick={() => {
            let pages: number[] = [];
            state.forEach(page => {
              pages.push(page.pageNumber);
            });
            let lengthOfPages = pages.length;

            if (lengthOfPages === 0) {
              increaseCallback(1);
            } else {
              increaseCallback(lengthOfPages);
            }
          }}
          text="Adicionar Página"
        ></ButtonAddPageCanva>
      ) : (
        <SpaceOfContainer />
      )}
    </>
  );
};

export default IncreasePages;
