import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";
import img from "../../assets/ellipse.svg";

interface TemplateCardStyleProps {
  image?: string;
}

interface ItemCarrouselProps {
  src?: string;
  alt?: string;
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 7.3rem;

  display: flex;
  flex-direction: column;

  position: relative;
  overflow-y: auto;

  .full-width {
    background-size: contain;
  }

  .responsive-loading {
    width: 40px;
    height: 40px;
  }

  #sentinela-templates {
    height: 150px;
    align-items: start;
    margin-top: 50px;
  }
`;

export const HeaderWrapper = styled.div`
  background-color: #f5f6fa;
  padding: 10px;

  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Heading = styled.div`
  color: ${({ theme }: Theme) => theme.colors.black};
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  padding-inline: 10px;
`;

export const Carrousel = styled.div`
  overflow: hidden;
  position: relative;
`;

export const ContainerLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* -webkit-box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.44); */
`;

export const InnerCarrousel = styled.div`
  display: flex;
`;

export const TemplateCard = styled.div<TemplateCardStyleProps>`
  min-width: 146.64px;
  height: 207px;
  display: flex;
  width: 100%;
  margin: 10px;
  background: ${({ theme }: Theme) => theme.colors.border};
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.94);
  box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.94);
  margin-right: 2.4rem;
  position: relative;
`;

/// new element

export const CarrouselMain = styled.div`
  display: flex;
  overflow-x: auto;
  overflow: hidden;
  scroll-behavior: smooth !important;
  height: auto;
`;

export const ItemCarrousel = styled.img<ItemCarrouselProps>`
  width: 100%;
  height: 100%;

  cursor: default;
  background-image: url(${({ src }) => src && src});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  flex: none;

  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);
`;

export const Category = styled.div`
  .exclude-scroll {
    position: relative;
  }
`;

export const Main = styled.div`
  height: 100%;
`;

export const CardContainer = styled.div`
  width: 141.39px;
  height: 200px;
  margin: 8px 8px 20px 10px;
`;

export const CardHover = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;

  cursor: grab;

  p {
    width: 96px;
    height: 51px;

    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.white100};
  }
`;

export const MainCardHover = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  height: 30px;
  width: 120px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary90};
  background: ${({ theme }) => theme.colors.white100};
  color: ${({ theme }) => theme.colors.primary};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  position: absolute;
  bottom: 15px;
  right: 7%;
`;

export const SearchGrid = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const DraggableArea = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;
