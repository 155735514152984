import styled from "styled-components";

export const Container = styled.div<{ isActive: any }>`
  margin-top: 5px;

  ul {
    padding-left: 20px;
  }

  p,
  li {
    color: ${({ theme }) => theme.colors.black100};
    font-family: "Ubuntu";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  li {
    list-style: disc;
  }

  .have-min-length {
    color: ${({ isActive }) =>
      isActive.haveMinLength === ""
        ? "#343a40"
        : isActive.haveMinLength === "wrong"
        ? "#DE2D2D"
        : isActive.haveMinLength === "right" && "#007B36"};
  }

  .have-min-upper-lower {
    color: ${({ isActive }) =>
      isActive.haveUpperOrLower === ""
        ? "#343a40"
        : isActive.haveUpperOrLower === "wrong"
        ? "#DE2D2D"
        : isActive.haveUpperOrLower === "right" && "#007B36"};
  }

  .have-symbol {
    color: ${({ isActive }) =>
      isActive.haveOneSymbol === ""
        ? "#343a40"
        : isActive.haveOneSymbol === "wrong"
        ? "#DE2D2D"
        : isActive.haveOneSymbol === "right" && "#007B36"};
  }
`;
