import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: black;
`;

export const LeftWrapper = styled.aside`
  display: flex;
  width: 38.95%;
  height: 100%;
  background: linear-gradient(0deg, #000000 0%, #663399 100%);
  position: relative;
`;

export const RightWrapper = styled.main`
  display: flex;
  flex: 1;
  height: 100%;
  background: white;
  z-index: 20;
`;

export const GroupDecorationLogo = styled.div`
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 160px;
  display: flex;
  right: -26%;
  bottom: -4%;
  justify-content: space-around;
  z-index: 0;

  .decoration-logo {
    margin-right: 4rem;
  }

  @media (max-width: 1600px) {
    bottom: -9%;

    .decoration-logo {
      width: 70%;
      height: 70%;
    }
  }

  @media (max-width: 1366px) {
    bottom: -9%;

    .decoration-logo {
      width: 65%;
      height: 65%;
    }
  }
`;

export const GroupDecorationLogoII = styled.div`
  opacity: 0.6;
  position: absolute;
  width: 100%;
  height: 160px;
  display: flex;
  left: 10%;
  bottom: 15%;
  justify-content: space-around;
  z-index: 0;
  .decoration-logo {
    margin-right: 4rem;
  }

  @media (max-width: 1600px) {
    .decoration-logo {
      width: 70%;
      height: 70%;
    }
    bottom: 11%;
  }

  @media (max-width: 1366px) {
    bottom: 12%;

    .decoration-logo {
      width: 65%;
      height: 65%;
      margin-right: 0;
    }
  }
`;

export const GroupDecorationLogoIII = styled.div`
  opacity: 0.4;
  position: absolute;
  width: 100%;
  height: 160px;
  display: flex;
  right: -4%;
  bottom: 34%;
  justify-content: space-around;
  z-index: 0;
  .decoration-logo {
    margin-right: 4rem;
  }

  @media (max-width: 1600px) {
    bottom: 32%;
    .decoration-logo {
      width: 70%;
      height: 70%;
    }
  }

  @media (max-width: 1366px) {
    bottom: 33%;
    left: 19%;
    .decoration-logo {
      width: 65%;
      height: 65%;
    }
  }
`;

export const GroupDecorationLogoIV = styled.div`
  opacity: 0.2;
  position: absolute;
  width: 100%;
  height: 160px;
  display: flex;
  left: 8%;
  bottom: 53%;
  justify-content: space-around;
  z-index: 0;
  .decoration-logo {
    margin-right: 4rem;
  }

  @media (max-width: 1600px) {
    bottom: 52%;
    left: 15%;
    .decoration-logo {
      width: 70%;
      height: 70%;
    }
  }

  @media (max-width: 1366px) {
    bottom: 54%;
    left: 12%;
    .decoration-logo {
      width: 65%;
      height: 65%;
    }
  }
`;
