import { Container, Modal, ButtonBox, Title } from "./styled";
import TitleH5 from "../../../../../shared/components/TitleH5";
import PlainText from "../../../../../shared/components/PlainText";
import ButtonExit from "../../../../../shared/components/ButtonExit";
import ButtonMaxWidth from "../../../../../shared/components/ButtonMaxWidth";
import { SetStateAction, useEffect } from "react";
import { ObjectNewClause } from "../../pages";
import { UseMutateFunction } from "react-query";

interface IModalDeleteTextProps {
  handleRemoveClause: UseMutateFunction<void, unknown, string, unknown>;
  setObjectClause: React.Dispatch<SetStateAction<ObjectNewClause>>;
  setIsModalOpen: React.Dispatch<SetStateAction<boolean>>;
  id: string;
}

const ModalDeleteText = ({
  handleRemoveClause,
  setObjectClause,
  setIsModalOpen,
  id
}: IModalDeleteTextProps) => {
  useEffect(() => {
    return () => {
      setObjectClause({
        title: "",
        clause: "",
        description: "Clause by user",
        is_favorite: false,
        key_words: ""
      });
    };
  }, []);

  return (
    <Container>
      <Modal>
        <div>
          <Title>
            <TitleH5 title="Excluir texto" />{" "}
            <ButtonExit onClick={() => setIsModalOpen(false)} />
          </Title>
          <PlainText>Tem certeza que você quer excluir o texto?</PlainText>
        </div>
        <ButtonBox>
          <ButtonMaxWidth
            typeStyle="purple"
            text="Não"
            onClick={() => setIsModalOpen(false)}
          />
          <ButtonMaxWidth
            onClick={() => {
              handleRemoveClause(id);
              setIsModalOpen(false);
            }}
            typeStyle="white"
            text="Sim"
          />
        </ButtonBox>
      </Modal>
    </Container>
  );
};

export default ModalDeleteText;
