import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import api from "../services/api";
import { useAuth } from "./auth";
// import { cloneDeep } from "lodash";

interface TwoFactorAuthDataProps {
  stepSecure: "1" | "2" | "3" | "4";
  setStepSecure: React.Dispatch<React.SetStateAction<"1" | "2" | "3" | "4">>;
  modalTwoFactor: "setup" | "none";
  setModalTwoFactor: React.Dispatch<React.SetStateAction<"setup" | "none">>;
  handleCloseModal: () => void;
  handleNextStep: () => void;
  isOTPComplete: boolean;
  setIsOTPComplete: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthenticatedOTP: boolean;
  setisAuthenticatedOTP: React.Dispatch<React.SetStateAction<boolean>>;
  backupRestoreCode: string;
  setBackupRestoreCode: React.Dispatch<React.SetStateAction<string>>;
  otp: string[];
  setOTP: React.Dispatch<React.SetStateAction<string[]>>;
  loadingSetup2FA: boolean;
  setLoadingSetup2FA: React.Dispatch<React.SetStateAction<boolean>>;
  isUserHasOTP: boolean;
  setIsUserHasOTP: React.Dispatch<React.SetStateAction<boolean>>;
  isDisable2FA: boolean;
  setIsDisable2FA: React.Dispatch<React.SetStateAction<boolean>>;
  isUserValidateOTP: boolean;
  setIsValidateOTP: React.Dispatch<React.SetStateAction<boolean>>;
}

interface TwoFactorAuthContextProps {
  children: ReactNode;
}

const TwoFactorAuth = createContext<TwoFactorAuthDataProps>(
  {} as TwoFactorAuthDataProps
);

const TwoFactorAuthContext: React.FC = ({
  children,
}: TwoFactorAuthContextProps) => {
  const { data, setData } = useAuth();

  const [stepSecure, setStepSecure] = useState<"1" | "2" | "3" | "4">("1");
  const [isOTPComplete, setIsOTPComplete] = useState(false);
  const [isAuthenticatedOTP, setisAuthenticatedOTP] = useState(false);
  const [modalTwoFactor, setModalTwoFactor] = useState<"setup" | "none">(
    "none"
  );
  const [isDisable2FA, setIsDisable2FA] = useState(false);
  const [backupRestoreCode, setBackupRestoreCode] = useState("");
  const [otp, setOTP] = useState<string[]>(new Array(6).fill(""));
  const [loadingSetup2FA, setLoadingSetup2FA] = useState(false);
  const [isUserValidateOTP, setIsValidateOTP] = useState(false);

  const [isUserHasOTP, setIsUserHasOTP] = useState<boolean>(
    data?.user?.otp_enabled
  );

  const handleCloseModal = useCallback(() => {
    setStepSecure("1");
    setModalTwoFactor("none");
    setisAuthenticatedOTP(false);
  }, [setStepSecure, setModalTwoFactor]);

  const handleVerify = useCallback(async () => {
    if (!isAuthenticatedOTP) {
      setLoadingSetup2FA(true);
      const token = {
        token: otp.join(""),
      };
      try {
        const responseVerify = await api.post(
          "two-factor-authentication/verify",
          token
        );
        if (responseVerify.data) {
          toast.success("Autenticação de 2 fatores configurada.");
          setisAuthenticatedOTP(true);
          setLoadingSetup2FA(false);
          setIsUserHasOTP(true);

          setData((oldState) => {
            return {
              ...oldState,
              user: {
                ...oldState.user,
                otp_enabled: true,
                otp_verified: true,
                otp_validate: true,
              },
            };
          });
        }
      } catch (err) {
        if (
          err.response.data.message ===
          "Failed to verify two factor authentication"
        ) {
          setLoadingSetup2FA(false);
          return toast.error("Código inválido ou expirado.");
        }
        setLoadingSetup2FA(false);
        toast.error(err?.response?.data?.message);
      }
    } else {
      setStepSecure("4");
    }
  }, [isAuthenticatedOTP, otp, setData]);

  const handleNextStep = useCallback(() => {
    switch (stepSecure) {
      case "1":
        setStepSecure("2");
        break;
      case "2":
        setStepSecure("3");
        break;
      case "3":
        handleVerify();
        break;

      case "4":
        handleCloseModal();
        break;
      default:
        setStepSecure("1");
        break;
    }
  }, [stepSecure, handleVerify, handleCloseModal]);

  useEffect(() => {
    setIsUserHasOTP(data?.user?.otp_enabled);
  }, [data]);

  return (
    <TwoFactorAuth.Provider
      value={{
        stepSecure,
        setStepSecure,
        modalTwoFactor,
        setModalTwoFactor,
        handleCloseModal,
        handleNextStep,
        isOTPComplete,
        setIsOTPComplete,
        isAuthenticatedOTP,
        setisAuthenticatedOTP,
        backupRestoreCode,
        setBackupRestoreCode,
        otp,
        setOTP,
        loadingSetup2FA,
        setLoadingSetup2FA,
        isUserHasOTP,
        setIsUserHasOTP,
        isDisable2FA,
        setIsDisable2FA,
        isUserValidateOTP,
        setIsValidateOTP,
      }}
    >
      {children}
    </TwoFactorAuth.Provider>
  );
};

// creating hook
function useTwoFactorAuth(): TwoFactorAuthDataProps {
  const context = useContext(TwoFactorAuth);

  if (!context) {
    throw new Error(
      "useTwoFactorAuth must be used with an TwoFactorAuthContext"
    );
  }

  return context;
}

export { TwoFactorAuthContext, useTwoFactorAuth };
