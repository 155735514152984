/*
this page is to render tab of create new project into dash 
or this user can choose one templates already created by
bits academy and use it, just click inside model of template
*/

import React, { useCallback, useEffect, useState } from "react";
import { Container, Header, RightSide } from "./styles";
import BlankProjectDefault from "../components/BlankProjectDefault";
import { useNavigate, useParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { useMainHook } from "../../../../hooks/main";
import api from "../../../../services/api";
import { LoadingFlex } from "../../../user/unknown/login/pages/Login/styles";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import { useHeaderEditor } from "../../../editor/hooks/headerEditor";

import { PagesProps } from "../../../../dtos/PagesProps";
import { useMetric } from "../../../../hooks/metric";
import HeaderSection from "../../components/HeaderSection";
import Banner from "../components/Banner";
import SearchBar from "../../components/SearchBar";
import GridTemplate from "../../components/GridTemplate";
import PreviewTemplateModal from "../../components/PreviewTemplateModal";
import Pagination from "../../components/Pagination";
import ModalLoading from "../../../../shared/components/ModalLoading";
import { ToastContainer, toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { IResponseCreationProjectFromBitsTemplates } from "../../../../dtos/CreateNewProjects";
import { IHandlePreviewTemplateProps } from "../../../../dtos/SharedInterfaces";
import UploadDocSection from "../components/uploadSection";
import WordIcon from "../components/WordIcon";

//set up interfaces

export interface IScreenCreateNewProject {
  bits_template_category_id: string;
  created_at: string;
  creator_id: string;
  description: string;
  id: string;
  key_words: string;
  last_editor_id: string;
  template: {
    arrayOfPages: PagesProps[];
  };
  thumbnail: string;
  thumbnail_url: string;
  title: string;
  updated_at: string;
  usage_counter: number;
}

interface IDataCreateProject {
  templatesBits: IScreenCreateNewProject[];
  totalPages: number;
}

const ScreenCreateNewProject: React.FC = () => {
  const { handleCreateNewProject } = useHeaderEditor();
  const {
    documentName,
    debouncedSearchTerm,
    loadingDebounce,
    search,
    setIsLoadingModal,
  } = useMainHook();
  const { handleCreatedProjectAction } = useMetric();

  //route properties
  const navigator = useNavigate();
  const { number_page } = useParams();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;

  //set up states
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [templateId, setTemplateId] = useState("");

  // get all templates of bits academy
  // we have pagination and search in this page
  // this useEffect will change everytime our
  //params change
  const { data: dataCreateProject, isLoading } = useQuery<IDataCreateProject>(
    ["templatesBits", debouncedSearchTerm, pageNumber],
    async () => {
      const response = await api.get("bits-templates", {
        params: {
          pagination: pageNumber,
          search: debouncedSearchTerm ? debouncedSearchTerm : null,
        },
      });

      return {
        templatesBits: response.data.dataArray,
        totalPages: response.data.pagination,
      };
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 60000,
    }
  );

  //this callback receive one id
  //then we created new project into canvas
  //and navigate to the document
  const chooseTemplateBits = useMutation({
    mutationFn: async (id: string) => {
      //creating request with new project and then
      //send the template was choosed by user and goes show
      //that template on editor to the user
      setIsLoadingModal(true);
      const newProject = {
        title: "Novo documento",
        description: "Criando novo projeto",
        is_automation: false,
        bits_template_id: id,
      };

      return (await api.post("user-templates", newProject)).data;
    },
    onSuccess: (data: IResponseCreationProjectFromBitsTemplates) => {
      documentName.current = "Novo documento";

      handleCreatedProjectAction({
        project_id: data.id,
        creation_origin: "UXDoc Template",
        template_id: data.bits_template_id,
        template_bits_name: "Novo documento",
        researched: search.length > 0,
      });

      setIsLoadingModal(false);
      //just navigate to template
      navigator(`/editor/my-template/${data.id}`);
    },
    onError: () => {
      setIsLoadingModal(false);
      toast.error("Erro ao criar projeto a partir de um template Bits.");
    },
  });

  const handlePreviewTemplateBits = useMutation({
    mutationFn: async ({ templateId, title }: IHandlePreviewTemplateProps) => {
      setModalIsOpen(true);
      const response = await api.get(`bits-templates/${templateId}`);

      return {
        pages: response.data.bits_template.template.arrayOfPages as [],
        title: response.data.bits_template.title as string,
      };
    },
    onError: () => {
      setModalIsOpen(false);
      toast.error("Erro ao pré-visualizar templates.");
    },
  });

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  return (
    <>
      <Container>
        <Banner
          title="Personalize o seu projeto"
          text="Está com dificuldades para criar o seu documento em Legal Design? Solicite a personalização pela nossa equipe de designers."
          link="https://bitsacademy.typeform.com/modelo-uxdoc"
          textButton="Solicitar personalização"
        />

        <Header>
          <HeaderSection
            description="Aqui você pode escolher um template para iniciar o
            seu projeto, ou começar com uma tela em branco."
            title="Criar novo projeto"
          />

          <RightSide>
            <UploadDocSection />
            <SearchBar
              screen="create-projects"
              pageNumber={pageNumber}
              description="Digite aqui para encontrar o template que procura."
            />
          </RightSide>
        </Header>

        {isLoading || loadingDebounce ? (
          <LoadingFlex>
            <ContractAnimation />
          </LoadingFlex>
        ) : (
          <GridTemplate
            templates={dataCreateProject.templatesBits}
            handleChooseTemplate={handlePreviewTemplateBits.mutate}
            setTemplateId={setTemplateId}
            handleUseTemplate={chooseTemplateBits.mutate}
            page="Create new project"
          >
            {pageNumber === 0 && search.length === 0 && (
              <>
                <BlankProjectDefault
                  text="Em branco"
                  onClick={handleCreateNewProject}
                />
              </>
            )}
          </GridTemplate>
        )}

        <Pagination
          total={dataCreateProject?.totalPages}
          pageNow={pageNumber}
          screen="create-projects"
        />

        <PreviewTemplateModal
          open={modalIsOpen}
          handleCloseModal={handleCloseModal}
          pagesToBeRendered={handlePreviewTemplateBits.data?.pages}
          title={handlePreviewTemplateBits.data?.title}
          loadingPreview={handlePreviewTemplateBits.isLoading}
          chooseTemplate={chooseTemplateBits.mutate}
          templateId={templateId}
          texButton="Usar Template"
        />
      </Container>
      <ToastContainer />
    </>
  );
};

export default ScreenCreateNewProject;

