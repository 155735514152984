const SvgComments = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47"
        height="47"
        viewBox="0 0 47 47"
        fill="none"
      >
        <defs>
          <clipPath id="border-comment">
            <path
              d="M23.4065 46.4106C28.3988 46.4099 33.2491 44.7489 37.1937 41.689C41.1384 38.6292 43.9534 34.3444 45.1956 29.5091C46.4377 24.6738 46.0365 19.5627 44.0551 14.9805C42.0737 10.3983 38.6246 6.60515 34.2508 4.19828C29.8771 1.79141 24.827 0.907513 19.8958 1.68573C14.9645 2.46396 10.4322 4.8601 7.01217 8.49693C3.59218 12.1338 1.47884 16.8047 1.00486 21.7745C0.530884 26.7442 1.72318 31.7304 4.39405 35.9481L0.906555 46.4106L11.369 42.9231C14.9679 45.2076 19.1439 46.4174 23.4065 46.4106Z"
              fill="#24958E"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
      >
        <defs>
          <clipPath id="image-comment">
            <path
              d="M20.9062 41.4106C25.3437 41.41 29.6551 39.9335 33.1614 37.2136C36.6678 34.4938 39.17 30.685 40.2742 26.387C41.3783 22.089 41.0217 17.5458 39.2604 13.4727C37.4992 9.39965 34.4333 6.02798 30.5455 3.88854C26.6577 1.74911 22.1688 0.963417 17.7855 1.65517C13.4022 2.34692 9.37338 4.47682 6.33339 7.70956C3.29341 10.9423 1.41488 15.0943 0.993568 19.5118C0.572252 23.9293 1.63207 28.3615 4.00618 32.1106L0.906182 41.4106L10.2062 38.3106C13.4051 40.3412 17.1172 41.4167 20.9062 41.4106Z"
              fill="#24958E"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default SvgComments;
