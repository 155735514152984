import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  min-width: 700px;
  position: relative;
`;

export const UploadContainer = styled.div`
  border: 1px solid #999c9f;
  display: flex;
  flex: 1;
  margin-top: 20px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FileNameLabel = styled.span`
  color: #343a40;
  font-size: 14px;
  font-weight: 400;
`;

export const UploadButton = styled.button`
  padding: 12px 16px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  cursor: pointer;
  width: 100%;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
`;

export const NotUploadedState = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 200px;
  align-items: center;
  padding-bottom: 20px;
`;
export const UploadedState = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 300px;
  align-items: center;
`;

export const Label1 = styled.span`
  font-size: 14px;
  color: #343a40;
`;
export const Label2 = styled.span`
  font-size: 12px;
  color: #343a40;
`;
interface SelectContainerProps {
  open: boolean;
}

export const SelectContainer = styled.div<SelectContainerProps>`
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding-bottom: ${props => (props.open ? "100px" : "0px")};
`;

export const DraggBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(102, 51, 153, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 999999999 !important;
  span {
    color: #fff;
    font-size: 1.4rem;
  }
`;
