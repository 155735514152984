import React, { useCallback, useEffect, useRef, useState } from "react";
import useComponentVisible from "../../../hooks/useComponentVisible";
import { animate, useAnimation, useMotionValue, motion } from "framer-motion";
import ButtonToolbar from "../ButtonToolbar";
import { useDetectClickOutside } from "react-detect-click-outside";
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  Flex,
  HorizontalStack,
  StrokeBar,
  TextStroke
} from "./styles";
import { useSelection } from "../../../hooks/selection";
import { clone, cloneDeep } from "lodash";
import { useMainHook } from "../../../../../hooks/main";
import { TooltipProps } from "@mui/material/Tooltip";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import { useTheme } from "../../../../../hooks/theme";
import { useToolbarEditor } from "../../../hooks/toolbarEditor";

// import { Container } from './styles';

interface ButtonToolbarProps {
  isActive?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  tooltipTitle: string;
  tooltipDescription?: string;
  tooltipPlacement?: TooltipProps["placement"];
  onClick?: any;
  component: string;
  handleChangeStroke?: any;
}

const initialStrokes = [
  { name: "Sem borda", borderSize: 0, isActive: true },
  { name: "1px", borderSize: 1, isActive: false },
  { name: "2px", borderSize: 2, isActive: false },
  { name: "3px", borderSize: 3, isActive: false },
  { name: "4px", borderSize: 4, isActive: false },
  { name: "5px", borderSize: 5, isActive: false },
  { name: "6px", borderSize: 6, isActive: false },
  { name: "7px", borderSize: 7, isActive: false },
  { name: "8px", borderSize: 8, isActive: false }
];

const borders = {
  1: { dash: 3, spacing: 2 },
  2: { dash: 4, spacing: 2 },
  3: { dash: 6, spacing: 3 },
  4: { dash: 8, spacing: 4 },
  5: { dash: 10, spacing: 5 },
  6: { dash: 12, spacing: 6 },
  7: { dash: 14, spacing: 7 },
  8: { dash: 15, spacing: 5 }
};


const DropdownStroke: React.FC<ButtonToolbarProps> = ({
  isActive,
  className,
  type,
  tooltipTitle,
  tooltipDescription,
  tooltipPlacement,
  handleChangeStroke,
  component
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [strokeVariants, setStrokeVariants] = useState(initialStrokes);
  const { setDashedOption, setTypeLine, typeLine } =
    useToolbarEditor();
  const refComponent = useRef(null);
  const { objectScreen } = useMainHook();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const { selectedObject } = useSelection();
  const { theme } = useTheme();

  const handleClick = useCallback(
    event => {
      event.preventDefault();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  document.addEventListener("mousedown", event => {
    if (refComponent.current?.contains(event?.target)) {
      return;
    } else {
      if (isOpen) {
        setIsOpen(false);
      }
    }
  });

  //animations
  const list = {
    visible: {
      opacity: 1,
      y: 5,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3
      }
    },
    hidden: {
      opacity: 0,
      y: -20,
      transition: {
        when: "afterChildren"
      }
    }
  };

  useEffect(() => {
    if (
      selectedObject?.attrs?.stroke === "transparent" ||
      !selectedObject?.attrs?.stroke
    ) {
      if (selectedObject?.attrs?.strokeWidth == undefined) {
        setStrokeVariants(initialStrokes);
      }
      setTypeLine('linear');
    } else {
      if (selectedObject?.attrs?.strokeWidth == undefined) {
        setTypeLine('linear');
        setStrokeVariants(oldState => {
          let cloneState = cloneDeep(oldState);
          cloneState.forEach(stroke => {
            if (stroke.name === "1px") {
              stroke.isActive = true;
            } else {
              stroke.isActive = false;
            }
          });

          return cloneState;
        });
      } else {
        if (selectedObject?.attrs?.strokeWidth === 0) {
          if (selectedObject?.attrs?.dash) {
            const [dash, spacing] = selectedObject?.attrs?.dash;
            setTypeLine((dash === 0 && spacing === 0) ? 'linear' : 'dashed');
          } else {
            setTypeLine('linear');
          }
          return setStrokeVariants(initialStrokes);
        } else {
          if (selectedObject?.attrs?.strokeWidth === 1) {
            if (selectedObject?.attrs?.dash) {
              const [dash, spacing] = selectedObject?.attrs?.dash;
              setTypeLine((dash === 0 && spacing === 0) ? 'linear' : 'dashed');
            } else {
              setTypeLine('linear');
            }

            setStrokeVariants(oldState => {
              let cloneState = cloneDeep(oldState);
              cloneState.forEach(stroke => {
                if (stroke.name === "1px") {
                  stroke.isActive = true;
                } else {
                  stroke.isActive = false;
                }
              });

              return cloneState;
            });
          } else {
            if (selectedObject?.attrs?.strokeWidth) {
              if (selectedObject?.attrs?.dash) {
                const [dash, spacing] = selectedObject?.attrs?.dash;
                setTypeLine((dash === 0 && spacing === 0) ? 'linear' : 'dashed');
              } else {
                setTypeLine('linear');
              }
              setStrokeVariants(oldState => {
                let cloneState = cloneDeep(oldState);
                cloneState.forEach(stroke => {
                  if (stroke.borderSize === selectedObject.attrs.strokeWidth) {
                    stroke.isActive = true;
                  } else {
                    stroke.isActive = false;
                  }
                });

                return cloneState;
              });
            }
          }
        }
      }
    }

    return () => { };
  }, [selectedObject, objectScreen, typeLine]);

  useEffect(() => {
    return () => {
      setStrokeVariants([]);
    };
  }, []);

  return (
    <Flex ref={refComponent}>
      <DropDownContainer>
        <DropDownHeader Open={isOpen} onClick={handleClick}>
          <ButtonToolbar
            isActive={isOpen}
            className={className}
            type={type}
            tooltipTitle={tooltipTitle}
            tooltipDescription={tooltipDescription}
            tooltipPlacement={tooltipPlacement}
            component={component}
          />
        </DropDownHeader>
      </DropDownContainer>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList
            as={motion.ul}
            variants={list}
            initial="hidden"
            animate="visible"
          >
            {strokeVariants.map((stroke, index) => (
              <HorizontalStack
                isActive={stroke.isActive}
                key={String(Math.random() * index)}
                onClick={() => {
                  setStrokeVariants(oldState => {
                    let cloneState = [...oldState];

                    cloneState.forEach(stroke => {
                      if (stroke.isActive) {
                        stroke.isActive = false;
                      }
                    });

                    cloneState[index].isActive = true;

                    return cloneState;
                  });

                  setDashedOption(() => ({
                    dash: typeLine === 'linear' ? 0 : borders[stroke.borderSize].dash,
                    spacing: typeLine === 'linear' ? 0 : borders[stroke.borderSize].spacing
                  }));
                  handleChangeStroke(stroke.borderSize);
                }}
              >
                <TextStroke isActive={stroke.isActive} key={stroke.name}>
                  {" "}
                  {stroke.name}{" "}
                </TextStroke>
                {index === 0 ? null : (
                  <StrokeBar
                    isActive={stroke.isActive}
                    size={stroke.borderSize}
                  />
                )}

                {stroke.isActive && (
                  <PersonalizedIcon
                    dPath={MountIcons.IconsCheck.dPath}
                    viewBox={MountIcons.IconsCheck.viewBox}
                    inactivatedColor={theme.colors.primary}
                  />
                )}
              </HorizontalStack>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </Flex>
  );
};

export default DropdownStroke;
