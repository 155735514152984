/*
hook to verify clicks outiside of modals dynamic
*/

import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(initialIsVisible: boolean) {
  //set up states
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);

  //ref
  const ref = useRef<HTMLDivElement>(null);

  //if event keyboard as espace then hidden
  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  //if the clicked object is not current node then the modal must close
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  };

  //add one listening when user click then execute some callbacks
  //after trigger callback clean up listeners events on document
  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}
