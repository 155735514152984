import { firstTheme } from "./../../../../shared/styles/theme/index";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .btn-comment {
    width: 35px;
  }
`;

export const ButtonComments = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  .ball-notification {
    width: 12px;
    height: 12px;
    background-color: #9945ee;
    position: absolute;
    top: 6px;
    right: 3px;
    border-radius: 50%;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  :hover {
    background-color: #ebebec;
  }
`;

export const SettingsBox = styled.div`
  width: 147px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-block: 5px;

  position: absolute;
  top: 37px;
  left: -118px;

  li {
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.black75};

    cursor: pointer;

    :hover {
      background-color: ${({ theme }) => theme.colors.black5};
    }
  }
`;
