import React, { useMemo, useState } from "react";
import { PagesProps } from "../../../../dtos/PagesProps";
import CardHover from "./components/CardHover";
import { Flex, FlexCard, ImageThumbnail, Text } from "./styles";
import { UseMutateFunction } from "react-query";
import { IResponseCreationProjectFromBitsTemplates } from "../../../../dtos/CreateNewProjects";
import { CustomSpin } from "../../../../shared/components/CustomSpin";
import { HStack } from "../OneClickSection/styles";
import { Box } from "@material-ui/core";
import Tooltip from "../../../editor/components/Tooltip";
// import {
//   AllMyProjects,
//   IHandleChangeDocumentNameProps,
// } from "../../../../dtos/AllMyProjects";
// import { IHandlePreviewTemplateProps } from "../../../../dtos/SharedInterfaces";

interface ITemplateItem {
  src?: string;
  text?: string;
  onClick?: any;
  id: string;
  handleChooseTemplate?: any /* UseMutateFunction<
    {
      pages: [];
      title: string;
    },
    unknown,
    IHandlePreviewTemplateProps,
    unknown
  >; */;
  setTemplateId?: React.Dispatch<React.SetStateAction<string>>;
  options?: boolean;
  handleMakeCopy?: UseMutateFunction<any, unknown, string, unknown>;
  handleDeleteDocument?: (document_id: string, name: string) => Promise<void>;
  index?: number;
  template?: {
    arrayOfPages: PagesProps[];
  };
  handleChangeDocumentName?: any /* UseMutateFunction<
    AllMyProjects,
    unknown,
    IHandleChangeDocumentNameProps,
    unknown
  >; */;
  page:
  | "Shared with me"
  | "My projects"
  | "Create new project"
  | "Team"
  | "One Click";
  handleEditTamplate?: (title: any, id: any) => Promise<void>;
  downloading?: boolean;
  setDownloading?: React.Dispatch<React.SetStateAction<boolean>>;
  idToastifyDownloadRef?: any;
  whichFileToBeDownload?: React.MutableRefObject<"pdf" | "word">;
  documentPreviewData?: React.MutableRefObject<PagesProps[]>;
  handleUseTemplate: UseMutateFunction<
    IResponseCreationProjectFromBitsTemplates,
    unknown,
    string,
    unknown
  >;
  isIntentBuyModal?: boolean;
  setIsIntentBuyModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
}

const TemplateItem: React.FC<ITemplateItem> = ({
  src,
  text,
  onClick,
  id,
  handleChooseTemplate,
  setTemplateId,
  options = false,
  handleMakeCopy,
  handleDeleteDocument,
  index,
  handleChangeDocumentName,
  page,
  handleEditTamplate,
  downloading,
  setDownloading,
  idToastifyDownloadRef,
  documentPreviewData,
  whichFileToBeDownload,
  handleUseTemplate,
  isIntentBuyModal,
  setIsIntentBuyModal,
  isLoading
}) => {
  const [isHover, setIsHover] = useState(false);

  const textTooltip = useMemo(() => {
    return text.length > 30
  }, [text])

  return (
    <Flex>
      <FlexCard
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={onClick}
      >
        {isLoading ? (
          <Box component={'div'} display={'flex'} justifyContent={'center'} height={'100%'} alignItems={'center'}>
            <CustomSpin />
          </Box>
        ) : (
          <ImageThumbnail
            src={src}
            onClick={() => {
              handleUseTemplate(id);
              setTemplateId(id);
            }}
          />
        )}


        {isHover && !isLoading && (
          <CardHover
            handleChooseTemplate={handleChooseTemplate}
            id={id}
            setTemplateId={setTemplateId}
            options={options}
            handleMakeCopy={handleMakeCopy}
            handleDeleteDocument={handleDeleteDocument}
            title={text}
            index={index}
            handleChangeDocumentName={handleChangeDocumentName}
            page={page}
            handleEditTamplate={handleEditTamplate}
            downloading={downloading}
            setDownloading={setDownloading}
            idToastifyDownloadRef={idToastifyDownloadRef}
            documentPreviewData={documentPreviewData}
            whichFileToBeDownload={whichFileToBeDownload}
            handleUseTemplate={handleUseTemplate}
            isIntentBuyModal={isIntentBuyModal}
            setIsIntentBuyModal={setIsIntentBuyModal}
          />
        )}
      </FlexCard>
      <Tooltip isDisable={!textTooltip} title={textTooltip && text} placement="bottom-start">
        <Text> {text} </Text>
      </Tooltip>
    </Flex>
  );
};

export default TemplateItem;
