import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import ButtonPrimary from "../ButtonPrimary";
import { InputModal } from "../InputModal";
import {
  AvatarContainer,
  AvatarCustom,
  Box,
  BoxColor,
  Button,
  ButtonBack,
  ButtonShared,
  ContainerModal,
  FirstAreaItem,
  FlexInput,
  Footer,
  Header,
  HorizontalStack,
  HStack,
  HStackBtn,
  InputBase,
  InputHex,
  ItemUserInfo,
  Line,
  NameContainer,
  ProfileUser,
  Table,
  Text,
  TextArea,
  Title,
  Trash,
  VerticalStack,
  WrapperProfileShared,
} from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import api from "../../../../services/api";
import { useModalContext } from "../../../../hooks/modalContext";
import { useSelection } from "../../hooks/selection";

import { useMainHook } from "../../../../hooks/main";
import { useSharedDocument } from "../../hooks/sharedDocument";
import { useParams } from "react-router-dom";
import { ButtonDeleteUserShared } from "../ModalSharedDocument/styles";
import IconSvg from "../IconSvg";
import deleteIcon from "../../assets/deleteIcon.svg";
import { getColors } from "../../utils/getColorAvatar";
import { Ring } from "react-spinners-css";
import closeIcon from "../../assets/close.svg";
import { useDiff } from "../../hooks/diffDocuments";
import MultiplesAvatarLetter from "../../../dashboard/master/components/MultiplesAvatarLetter";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonExit from "../../../../shared/components/ButtonExit";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../shared/utils/MountIcons";
import PlainText from "../../../../shared/components/PlainText";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import { useAuth } from "../../../../hooks/auth";

interface UsersSharedProps {
  id?: string;
  name?: string;
  email?: string;
  avatar_url?: string;
  permissions?: string;
  is_anonymous?: boolean;
}

interface ModalSharedInfoProps {
  isOpen: boolean;
  onRequestClose?: () => void;
}

export const ModalSharedInfo: React.FC<ModalSharedInfoProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 100,
        staggerDirection: -1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.9,
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const {
    currentTemplateInfo,
    myProfileId,
    objectScreen,
    handleOpenModalBlockFuncionalities,
    hasGroupShared,
  } = useMainHook();

  const { updateIfGroup, setUpdateIfGroup } = useDiff();
  const { data } = useAuth();

  const { templateid, typetemplate } = useParams();
  const [sharedUsers, setSharedUsers] = useState<UsersSharedProps[]>([]);
  const { refreshUsersShared, setRefreshUsersShared } = useSharedDocument();
  const [isOwnerDocument, setIsOwnerDocument] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [indexDelete, setIndexDelete] = useState(0);
  const [toggleIsUsersShared, setToggleIsUsersShared] = useState(false);

  useEffect(() => {
    const autoLoadSharedUsers = async () => {
      try {
        const responseTemplatesUsers = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );
        setSharedUsers(responseTemplatesUsers.data.users);
      } catch (err) {
        console.error(err, "ERROR when fetch users shared template");
      }
    };

    if (typetemplate === 'team-template') return;
    autoLoadSharedUsers();
  }, [refreshUsersShared, toggleIsUsersShared]);

  const handleDeleteUserShare = useCallback(
    async (idUser: string, isAnnonymous: boolean, event, index) => {
      event.preventDefault();
      setIndexDelete(index);
      let userId = idUser;
      let documentId = templateid;
      let isUserAnnonymous = isAnnonymous ? 1 : 0;

      try {
        setLoadingDelete(true);
        const responseDeleteUserShare = await api.delete(
          `user-share-template/delete-user-share/${userId}/${documentId}/${isUserAnnonymous}`
        );

        setRefreshUsersShared(!refreshUsersShared);
        setLoadingDelete(false);
      } catch (err) {
        console.error(err, "ERROR when delete user shared");
        setLoadingDelete(false);
      }
    },
    [refreshUsersShared, indexDelete, loadingDelete]
  );

  const handleTrashClick = (user, event, index) => {
    event.preventDefault();
    setToggleIsUsersShared((prevState) => !prevState);

    // Verifica se há mudanças no objeto para bloquear download com marcas de edições
    let changes = false;
    objectScreen.forEach((page) => {
      page.renderObjects.forEach((renderedObject) => {
        if (renderedObject.text && !changes) {
          changes = renderedObject.isModify ? true : false;
        }
      });
    });

    if (!changes) {
      handleDeleteUserShare(user.id, user.is_anonymous, event, index);
      setUpdateIfGroup((prevState) => !prevState);
    } else {
      onRequestClose();
      handleOpenModalBlockFuncionalities("shared");
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setForceUpdate(!forceUpdate);
    }, 2000);

    return clearTimeout(timeOut);
  }, []);

  useEffect(() => {
    if (myProfileId) {
      const getOwnerId = sharedUsers.find(
        (user) => user.permissions === "Owner"
      );

      if (getOwnerId?.id === myProfileId) {
        setIsOwnerDocument(true);
      } else {
        setIsOwnerDocument(false);
      }
    }
  }, [forceUpdate]);

  const isOwner = useMemo(() => {
    // Filtra apenas os Owners na lista de usuários compartilhados
    const owners = sharedUsers.filter(user => user.permissions === 'Owner');

    // Verifica se o usuário atual é um Owner
    const isCurrentUserOwner = owners.some(user => user.email === data.user.email);

    return isCurrentUserOwner;
  }, [sharedUsers, data]);

  return (
    <Modal
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
    >
      <AnimatePresence>
        <ContainerModal
          as={motion.form}
          initial="hidden"
          animate="show"
          variants={container}
          exit={{
            opacity: 0,
            scale: 0.8,
            x: -300,
          }}
          transition={{ duration: 1 }}
        >
          <Header>
            <TitleH5
              title={`Compartilhado - ${currentTemplateInfo?.current?.title}`}
            />

            <ButtonExit onClick={onRequestClose} className="icon-exit" />
          </Header>

          <Table>
            <div>
              <p>Nome</p> <p>E-mail</p> <p>Função na equipe</p>
            </div>

            <ul>
              {sharedUsers.map((user, index) => (
                <ItemUserInfo key={user.name}>
                  <FirstAreaItem>
                    <figure>
                      <AvatarCustom indexOfUser={index + 1}>
                        <PlainText>
                          {`${user.name.charAt(0).toUpperCase()}`}
                        </PlainText>
                      </AvatarCustom>
                    </figure>

                    <PlainText>{user.name}</PlainText>
                  </FirstAreaItem>
                  <PlainText className="email"> {user.email} </PlainText>
                  <PlainText>
                    {user.permissions === "Owner" ? "Proprietário" : "Membro"}{" "}
                  </PlainText>
                  {/* Owner pode excluir outros, mas não ele mesmo */}
                  {(isOwner && user.permissions !== "Owner") && (
                    <Trash onClick={(event) => handleTrashClick(user, event, index)}>
                      <PersonalizedIcon
                        dPath={MountIcons.IconTrash.dPath}
                        viewBox={MountIcons.IconTrash.viewBox}
                        inactivatedColor="#999C9F"
                      />
                    </Trash>
                  )}

                  {/* Membro pode excluir apenas ele mesmo */}
                  {user.permissions !== "Owner" && user.email === data.user.email && (
                    <Trash onClick={(event) => handleTrashClick(user, event, index)}>
                      <PersonalizedIcon
                        dPath={MountIcons.IconTrash.dPath}
                        viewBox={MountIcons.IconTrash.viewBox}
                        inactivatedColor="#999C9F"
                      />
                    </Trash>
                  )}
                </ItemUserInfo>
              ))}
            </ul>
          </Table>
          <Footer>
            <ButtonMaxWidth
              onClick={onRequestClose}
              text="Fechar"
              typeStyle="purple"
            />
          </Footer>
        </ContainerModal>
      </AnimatePresence>
    </Modal>
  );
};
