/* 
to change the buttons of main header asidebar
and below that header we have the body dynamic rendered
because we are watch which tabscreen user will be and 
render body accordingly

NOTE: IsEditing will be watch too, because if the user
brings need to type some text, so we change interface to 
texts properties, however if don't have texts editions
remove anything related with texts 
*/

import React, { useEffect } from "react";
import { useAsidebarEditor } from "../../hooks/asidebarEditor";
import { useTextsEdition } from "../../hooks/textsEdition";
import AsidebarComponents from "../AsidebarComponents";
import AsideText from "../AsideText";
import ButtonsAsideBar from "../ButtonsAsideBar";
import TextAsideScreen from "../TextAsideScreen";
import { GridItemSidebarMain, SidebarBody, SidebarNavigate } from "./styles";
import { useComments } from "../../hooks/comments";
import CommentsScreen from "../CommentsScreen";

const Asidebar: React.FC = () => {
  const { handleNavigate } = useAsidebarEditor();
  const { isEditing, toggleCloseEditing } = useTextsEdition();
  const { isCommentsVisible, setIsCommentsVisible } = useComments();

  //when clean up effect, return to initial state
  //every time that the user get out of editor
  //reset our selected tab of asidebar value

  return (
    <GridItemSidebarMain>
      <SidebarBody>
        {/* {isEditing === "open" && (
          <AsideText toggleCloseEditing={toggleCloseEditing} />
        )} */}

        {isCommentsVisible.active && isEditing !== "open" && <CommentsScreen />}

        <SidebarNavigate>
          <ButtonsAsideBar />
        </SidebarNavigate>
        <AsidebarComponents />
      </SidebarBody>
    </GridItemSidebarMain>
  );
};

export default Asidebar;
