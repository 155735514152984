import React, { useState } from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../shared/utils/MountIcons";
import { Container } from "./style";

function Arrow({
  children,
  disabled,
  onClick,
  direction,
  isActive
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  direction: "Right" | "Left";
  isActive: boolean;
}) {
  return (
    <Container
      disabled={disabled}
      onClick={onClick}
      direction={direction}
      isActive={isActive}
    >
      {children}
    </Container>
  );
}

export const LeftArrow = ({
  numberOftemplate
}: {
  numberOftemplate: number;
}) => {
  const { isFirstItemVisible, scrollPrev, visibleItemsWithoutSeparators } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isFirstItemVisible
  );

  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow
      disabled={disabled}
      onClick={() => scrollPrev()}
      direction="Left"
      isActive={numberOftemplate > 2}
    >
      <PersonalizedIcon
        dPath={MountIcons.IconArrowLeft.dPath}
        viewBox={MountIcons.IconArrowLeft.viewBox}
        inactivatedColor="#D6D8D9"
        activeColor="#676B70"
        isActive={!disabled}
      />
    </Arrow>
  );
};

export function RightArrow({ numberOftemplate }: { numberOftemplate: number }) {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow
      disabled={disabled}
      onClick={() => scrollNext()}
      direction="Right"
      isActive={numberOftemplate > 2}
    >
      <PersonalizedIcon
        dPath={MountIcons.IconArrowRight.dPath}
        viewBox={MountIcons.IconArrowRight.viewBox}
        inactivatedColor="#D6D8D9"
        activeColor="#676B70"
        isActive={!disabled}
      />
    </Arrow>
  );
}
