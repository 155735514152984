import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  z-index: 2;
  top: 40px;
  right: 0;

  width: 222px;
  background-color: ${({ theme }) => theme.colors.white100};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 44px;
  padding: 10px 12px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.black75};

  & > div {
    display: flex;
    align-items: center;
    column-gap: 8px;

    svg,
    img {
      height: 24px;
      width: 24px;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }

  & > svg {
    width: 8px;
    height: 15px;
  }
`;
