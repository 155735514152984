import React from "react";
import { TextBox } from "./styles";
import { useMainHook } from "../../../../../../hooks/main";
import { useWorkspaceEditor } from "../../../../hooks/workspaceEditor";
import Tooltip from "../../../Tooltip";

// import { Container } from './styles';
interface ItemTextProps {
  fontSize?: string;
  isBold?: boolean;
  isUpperCase?: boolean;
  title?: string;
}

const ItemTextFormat: React.FC<ItemTextProps> = ({
  fontSize,
  isBold,
  isUpperCase,
  title
}) => {
  const { dragUrl } = useMainHook();
  const { tupeOfText } = useWorkspaceEditor();

  return (
    <Tooltip title="Arraste para usar" placement="bottom-start">
      <TextBox
        onDragStart={(e: any) => {
          dragUrl.current = e.target;

          switch (title) {
            case "TÍTULO 1":
              tupeOfText.current = "header1";
              break;

            case "Título 2":
              tupeOfText.current = "header2";
              break;

            case "Título 3":
              tupeOfText.current = "header3";
              break;

            case "Subtítulo 1":
              tupeOfText.current = "subtitle1";
              break;

            case "Subtítulo 2":
              tupeOfText.current = "subtitle2";
              break;

            case "Conteúdo 1":
              tupeOfText.current = "content1";
              break;
            case "Conteúdo 2":
              tupeOfText.current = "content2";
              break;

            case "Legenda 1":
              tupeOfText.current = "label1";
              break;
            case "Legenda 2":
              tupeOfText.current = "label2";
              break;
            default:
              tupeOfText.current = "";
              break;
          }
        }}
        draggable
        isBold={isBold}
        fontSize={fontSize}
      >
        <span> {title} </span>
      </TextBox>
    </Tooltip>
  );
};

export default ItemTextFormat;
