import styled from "styled-components";

export const ModalOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.55);
`;

export const ModalContent = styled.div`
  width: 703.146px;
  padding: 20px;
  border-radius: 5px;
  background: white;

  display: flex;
  flex-direction: column;
  gap: 30px;

  position: relative;
`;

export const HStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Text = styled.span`
  font-size: 14.063px;
  font-weight: 400;
  line-height: 150%;
`;

export const Footer = styled.div`
  display: flex;
  gap: 10px;
`;

export const BoxButton = styled.div`
  position: absolute;
  top: 13px;
  right: 13px;
`;
