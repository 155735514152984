import React, { useState, useEffect } from "react";
import RoutesApp from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyle from "./shared/styles/global";
import AppProvider from "./hooks";
import { ConfigProvider } from "react-avatar";
import { isMobile } from "react-device-detect";
import MobileScreen from "./shared/components/mobile/screens/MobileScreen";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { datadogRum } from '@datadog/browser-rum';

import "react-toastify/dist/ReactToastify.css";
import "rc-slider/assets/index.css";

const datadogEnv = process.env.DATADOG_ENV || 'prod';

datadogRum.init({
  applicationId: '8fac2cd3-6c27-48c5-8780-7a0c920a72e5',
  clientToken: 'pub9854794f01509d8c71997eb8a75bfe8a',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'uxdoc-prod',
  env: datadogEnv,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: '1.0.0', 
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

function App() {
  const [screenMode, setScreenMode] = useState<"Desktop" | "Mobile" | "Unlock">(
    "Desktop"
  );

  const [screenUnlock, setScreenUnlock] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [isPayment, setIsPayment] = useState(false);

  //get device of user
  useEffect(() => {
    if (isMobile) {
      setScreenMode("Mobile");
    } else {
      setScreenMode("Desktop");
    }
  }, [isMobile]);

  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider colors={["#24958E", "#D38E26"]}>
          <Router>
            <AppProvider>
              <GlobalStyle />
              {screenMode === "Desktop" || screenUnlock ? (
                <RoutesApp
                  unlockScreenMobile={screenUnlock}
                  setUnlockScreenMobile={setScreenUnlock}
                  isPayment={isPayment}
                  setIsPayment={setIsPayment}
                />
              ) : (
                <MobileScreen />
              )}
            </AppProvider>
          </Router>
        </ConfigProvider>
      </QueryClientProvider>
    </CookiesProvider>
  );
}

export default App;
