import React, { useCallback, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FilterAlphaProps } from "../../../../dtos/FilterAlphaProps";
import { animate, useAnimation, useMotionValue, motion } from "framer-motion";
import { dataAlpha } from "../../utils/dataAlphabetic";
import {
  BodyCategories,
  BoxCategories,
  Container,
  LettersContainer,
  ListItem
} from "./styles";

interface CategoriesProps {
  id: string;
  creator_id: string;
  last_editor_id: string;
  title: string;
  description?: null | string;
  created_at: string;
  updated_at: string;
}

interface ParamsCategoriesProps {
  filter: string;
}

interface QueryClausesProps {
  pagination?: number;
  search?: string;
  filter?: string;
  search_key_words?: string;
}

interface CategoriesBoxProps {
  handleBoxCategories: () => void;
  toggleBoxCategories?: () => void;
  dataCategories?: CategoriesProps[] | string[];
  setParamsCategories?: React.Dispatch<
    React.SetStateAction<ParamsCategoriesProps>
  >;
  paramsCategories?: ParamsCategoriesProps;
  query?: QueryClausesProps;
  setQuery?: React.Dispatch<React.SetStateAction<QueryClausesProps>>;
  filtered?: boolean;
  setFiltered?: React.Dispatch<React.SetStateAction<boolean>>;
  closeToggleUser?: () => void;
  refCategories?: any;
}

const list = {
  visible: {
    opacity: 1,
    y: 5,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3
    }
  },
  hidden: {
    opacity: 0,
    y: -20,
    transition: {
      when: "afterChildren"
    }
  }
};

const CategoriesBox: React.FC<CategoriesBoxProps> = ({
  handleBoxCategories,
  dataCategories,
  setParamsCategories,
  paramsCategories,
  query,
  setQuery,
  filtered,
  setFiltered,
  closeToggleUser,
  refCategories
}) => {
  // const ref = useDetectClickOutside({ onTriggered: closeToggleUser });

  // const ref = useRef(null);

  const [filterAlphaCategory, setFilterAlphaCategory] =
    useState<FilterAlphaProps>({
      value: "all"
    });

  const handleFilterOption = useCallback(
    value => {
      setFilterAlphaCategory({ value: value });
      setParamsCategories(state => {
        return {
          ...state,
          filter: value
        };
      });
    },
    [filterAlphaCategory, paramsCategories]
  );

  const handleCategoryFilter = useCallback(
    (value: string) => {
      setFiltered(true);
      setQuery(state => {
        return {
          ...state,
          search_key_word: value,
          filter: ""
        };
      });

      handleBoxCategories();
    },

    [query, filtered]
  );

  return (
    <BoxCategories
      variants={list}
      initial="hidden"
      animate="visible"
      as={motion.div}
    >
      <Container>
        <LettersContainer>
          {dataAlpha.map(option => (
            <button
              key={option.object}
              className={`alphabetical ${
                filterAlphaCategory.value === option.object && "active"
              }`}
              onClick={() => handleFilterOption(option.object)}
            >
              {option.letter}
            </button>
          ))}
        </LettersContainer>

        <BodyCategories>
          {dataCategories.length > 0 ? (
            dataCategories.map(category => (
              <ListItem
                key={category}
                variants={list}
                initial="hidden"
                animate="visible"
                as={motion.div}
                onClick={() => handleCategoryFilter(category)}
              >
                {category}
              </ListItem>
            ))
          ) : (
            <span
              style={{
                fontStyle: "italic",
                fontSize: "1.2rem"
              }}
            >
              Sem resultado para pesquisa.
            </span>
          )}
        </BodyCategories>
        {filtered && (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "auto"
            }}
          ></div>
        )}
      </Container>
    </BoxCategories>
  );
};

export default CategoriesBox;
