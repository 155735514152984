import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "@material-ui/core";

import {
  BoxInput,
  BoxWarning,
  ButtonSave,
  InputText,
  ModalContainer,
  ModalShowClausule,
  Title
} from "./styles";

import api from "../../../../services/api";
import { useClausesEditor } from "../../hooks/clausesEditor";
import { toast } from "react-toastify";
import { useSelection } from "../../hooks/selection";

import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonExit from "../../../../shared/components/ButtonExit";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../shared/utils/MountIcons";

interface NewClausesModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  idClause?: string;
  refreshClause?: boolean;
  setRefreshClause?: React.Dispatch<React.SetStateAction<boolean>>;
  indexClause?: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SpecificClauseProps {
  clause?: string;
  created_at?: string;
  description?: string;
  id?: string;
  is_favorite?: boolean;
  key_words?: string;
  title?: string;
  updated_at?: string;
  user_id?: string;
}

interface ObjectClauseUpdate {
  user_clause_id: string;
  title: string;
  clause: string;
  description: string;
  is_favorite: boolean;
  key_words?: string;
}

export const EditClauseModal: React.FC<NewClausesModalProps> = ({
  isOpen,
  onRequestClose,
  idClause,
  refreshClause,
  setRefreshClause,
  indexClause,
  setIsModalOpen
}) => {
  const {
    // blockCommandKeyboard,
    // setBlockCommandKeyboard,
    // forceBlockKey,
    setForceBlockKey
  } = useSelection();

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      scale: 1.2,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 100,
        staggerDirection: -1
      }
    }
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.9,
        type: "spring",
        stiffness: 100
      }
    }
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const autoLoadClause = async () => {
      if (idClause !== "") {
        try {
          setLoading(true);
          const responseClauseSpecific = await api.get(
            `user-clause/${idClause}`
          );

          setMyClause(responseClauseSpecific.data);
          setLoading(false);
        } catch (err) {
          console.error(err, "error when trying fetch data specific clause");
        }
      }
    };

    autoLoadClause();
  }, [idClause]);

  const [myClause, setMyClause] = useState<SpecificClauseProps>({});
  const { clausesData, setClausesData } = useClausesEditor();

  const [objectFormatted, setObjectFormatted] = useState<ObjectClauseUpdate>({
    user_clause_id: "",
    title: "",
    clause: "",
    description: "",
    is_favorite: false
  });

  const onChangeTitleMyClause = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMyClause(state => {
        return {
          ...state,
          title: event.target.value
        };
      });
    },
    []
  );
  const onChangeKeyWordsMyClause = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMyClause(state => {
        return {
          ...state,
          key_words: event.target.value
        };
      });
    },
    []
  );

  const onChangeTextClause = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMyClause(state => {
        return {
          ...state,
          clause: event.target.value
        };
      });
    },
    []
  );

  const handleCopyClause = useCallback((text: string) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Texto copiado com sucesso!");
    } catch (err) {
      console.error(err);
      toast.error("Erro ao copiar texto");
    }
  }, []);

  const handleSubmitEditingMyClause = useCallback(
    async event => {
      event.preventDefault();

      if (
        objectFormatted.user_clause_id === idClause &&
        objectFormatted.user_clause_id !== ""
      ) {
        try {
          setLoading(true);
          const responseUpdateClause = await api.put(
            "user-clause",
            objectFormatted
          );

          setClausesData(state => {
            let cloneState = [...state];

            let editingClause = cloneState.map(clause => {
              if (clause.id === idClause) {
                return {
                  ...clause,
                  title: objectFormatted.title,
                  description: objectFormatted.description,
                  clause: objectFormatted.clause
                };
              } else {
                return {
                  ...clause
                };
              }
            });

            return editingClause;
          });
          toast.success("Clausula editada com sucesso!");
          setLoading(false);
        } catch (err) {
          console.error(err, "error");
          toast.error("Algo inesperado aconteceu.");
          setLoading(false);
        }
      }
      setObjectFormatted(state => {
        return {
          ...state,
          user_clause_id: "",
          title: "",
          clause: "",
          description: "",
          is_favorite: false
        };
      });

      setRefreshClause(!refreshClause);
      onRequestClose();
    },
    [myClause, objectFormatted, refreshClause, clausesData]
  );

  useEffect(() => {
    setObjectFormatted(state => {
      let cloneState = { ...state };
      cloneState.user_clause_id = myClause.id;
      cloneState.title = myClause.title;
      cloneState.clause = myClause.clause;
      cloneState.description = myClause.description;
      cloneState.is_favorite = myClause.is_favorite;

      return cloneState;
    });
  }, [myClause]);

  useEffect(() => {
    if (isOpen) {
      setForceBlockKey(true);
    } else {
      setForceBlockKey(false);
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        open={isOpen}
        children={
          <ModalShowClausule>
            <ModalContainer>
              <Title>
                <TitleH5 title="Editar Texto" />

                <ButtonExit onClick={() => setIsModalOpen(false)} />
              </Title>

              <BoxInput>
                <div>
                  <label>Título</label>
                  <input
                    placeholder="Título"
                    height="50px"
                    value={myClause?.title}
                    name="title"
                    onChange={onChangeTitleMyClause}
                  />
                </div>
                <div>
                  <label>Palavra-chave</label>
                  <input
                    placeholder="Palavra-chave"
                    height="50px"
                    value={myClause?.key_words}
                    name="category"
                    onChange={onChangeKeyWordsMyClause}
                  />
                </div>
                <div>
                  <label>Texto</label>
                  <InputText
                    value={myClause?.clause}
                    onChange={onChangeTextClause}
                  />
                </div>
              </BoxInput>

              <BoxWarning>
                <PersonalizedIcon
                  dPath={MountIcons.IconAlert.dPath}
                  viewBox={MountIcons.IconAlert.viewBox}
                  inactivatedColor="#de2d2d"
                />
                <p>
                  Tudo que você escrever aqui ficará gravado no banco de
                  cláusulas. Assim, evite usar informações confidenciais de
                  clientes neste texto, dê preferência a incluir estas
                  informações confidenciais apenas no documento que está
                  criando.
                </p>
              </BoxWarning>

              <ButtonSave onClick={handleSubmitEditingMyClause}>
                Salvar
              </ButtonSave>
            </ModalContainer>
          </ModalShowClausule>
        }
      ></Modal>
    </>
  );
};
