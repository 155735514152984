import styled from "styled-components";

interface ContainerSidebarNavProps {
  paddingTop?: string;
}

export const ContainerSidebarNav = styled.div<ContainerSidebarNavProps>`
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "")};
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 8rem;
`;

export const VStackVersioning = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin: 2rem 0 0.8rem 0;
  position: relative;
  justify-content: flex-start;
  overflow-y: auto;
  min-height: 60px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a5a5a5;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  .container-search-and-category {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    position: relative;
  }

  .mouse-grab {
    cursor: grab !important;
  }

  @media (max-width: 1366px) {
  }
`;

export const VStackVersioning2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
  justify-content: space-between;
  margin: 0.8rem 0 0.8rem 0;
  position: relative;
  justify-content: flex-start;
  overflow-y: auto;
  min-height: 60px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a5a5a5;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  .container-search-and-category {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    position: relative;
  }

  .mouse-grab {
    cursor: grab !important;
  }

  transform: translateY(-10px);

  @media (max-width: 1366px) {
    transform: translateY(-30px);
  }
`;

