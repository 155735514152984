import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import DropdownAvatarDash from "../../../../editor/components/DropdownAvatarDash";
import MultiplesAvatarLetter from "../MultiplesAvatarLetter";
import { CentralBoxHeader, Header, Loading } from "./styles";

interface HeaderDashProps {
  isOpenAvatarConfig: boolean;
  setIsOpenAvatarConfig: React.Dispatch<React.SetStateAction<boolean>>;
  zIndexActive: boolean;
  setZIndexActive: React.Dispatch<React.SetStateAction<boolean>>;
  loadingAvatar: boolean;
  srcAvatar: string;
  letters: string;
  setLetters: React.Dispatch<React.SetStateAction<string>>;
  color: string;
}

const HeaderDash: React.FC<HeaderDashProps> = ({
  isOpenAvatarConfig,
  setIsOpenAvatarConfig,
  zIndexActive,
  loadingAvatar,
  srcAvatar,
  letters,
  setLetters,
  color
}) => {
  return (
    <Header>
      <CentralBoxHeader>
        <DropdownAvatarDash
          isOpen={isOpenAvatarConfig}
          setIsOpen={setIsOpenAvatarConfig}
          zIndexActive={zIndexActive}
        >
          {loadingAvatar && (
            <Loading className="loading-container">
              <PuffLoader color="#000" size="28" />
            </Loading>
          )}

          <MultiplesAvatarLetter
            className="avatar-dashboard"
            src={srcAvatar}
            text={letters}
            setText={setLetters}
            color={color}
            idElement="capture"
          />
        </DropdownAvatarDash>
      </CentralBoxHeader>
    </Header>
  );
};

export default HeaderDash;
