/*
this component is responsible for saving a history of verses if the user wants
to save the current moment of his project, he can choose and compare other
project verses created from this or document
*/

import React, { useCallback, useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { useMainHook } from "../../../../hooks/main";
import api from "../../../../services/api";
import { LoadingFlex } from "../../../user/unknown/login/pages/Login/styles";
import { useSelection } from "../../hooks/selection";
import { useVersioning } from "../../hooks/versioning";
import ButtonPrimary from "../ButtonPrimary";

import { CompareVersionBox } from "../Sidebar/components/VersioningArea/CompareVersionBox";
import {
  ContainerSidebarNav,
  VStackVersioning,
  VStackVersioning2,
} from "./styles";

interface ResponseDataApi {
  created_at: string;
  creator_id: string;
  description?: string;
  id: string;
  is_main: boolean;
  template: any;
  updated_at: string;
  user_template_id: string;
  first_name_creator: string;
  last_name_creator: string;
}

const VersioningProject: React.FC = () => {
  const { currentTemplateInfo, templateIsLoaded } = useMainHook();
  const { setSelectedObject, selectedObject } = useSelection();
  const [dataVersionOfTemplate, setDataVersionOfTemplate] =
    useState<ResponseDataApi[]>(null);
  const [dataUser, setDataUser] = useState(null);
  const [responseApi, setResponseApi] = useState<ResponseDataApi>(null);
  const [loadingListVersion, setLoadingListVersion] = useState(false);

  const [enabledButtonCompareVersion, setEnabledButtonCompareVersion] =
    useState(false);
  const {
    handleCreateNewVersionClick,
    loadingCreateVersion,
    comparisonVersionOne,
    comparisonVersionTwo,
    setComparisonVersionOne,
    setComparisonVersionTwo,
  } = useVersioning();

  const handleMouseButtonNewVersion = useCallback(() => {
    setSelectedObject(null);
  }, [selectedObject]);

  useEffect(() => {
    if (
      comparisonVersionOne.length !== 0 &&
      comparisonVersionTwo.length !== 0
    ) {
      setEnabledButtonCompareVersion(true);
    }
  }, [comparisonVersionOne, comparisonVersionTwo]);

  //get user

  useEffect(() => {
    const autoLoadUser = async () => {
      const response = await api.get("profile");
      setDataUser(response?.data?.people?.first_name);
    };

    autoLoadUser();
  }, []);

  // get list of templates

  useEffect(() => {
    const autoLoadVersioningList = async () => {
      try {
        setLoadingListVersion(true);
        const response = await api.get(
          `user-template-versions/${currentTemplateInfo.current.id}`
        );
        setDataVersionOfTemplate(response.data);
        setLoadingListVersion(false);
      } catch (err) {
        console.error(err, "deu erro");
      }
    };
    autoLoadVersioningList();
  }, [dataVersionOfTemplate, templateIsLoaded]);

  return (
    <ContainerSidebarNav style={{ padding: "0 1.5rem 1.5rem 1.5rem" }}>
      {templateIsLoaded ? (
        <div></div>
      ) : (
        <>
          <VStackVersioning>
            <ButtonPrimary
              onMouseEnter={handleMouseButtonNewVersion}
              onClick={handleCreateNewVersionClick}
            >
              {loadingCreateVersion ? (
                <LoadingFlex>
                  <PuffLoader color="#ffff" size="28" />
                </LoadingFlex>
              ) : (
                "Criar nova versão"
              )}
            </ButtonPrimary>
          </VStackVersioning>
          <VStackVersioning2>
            <CompareVersionBox
              dataUser={dataUser}
              dataVersionOfTemplate={dataVersionOfTemplate}
              loadingApi={loadingCreateVersion}
              enabledButtonCompareVersion={enabledButtonCompareVersion}
              currentTemplateInfo={currentTemplateInfo}
            />
          </VStackVersioning2>
        </>
      )}

      {/* <TextContent>
        <Text>
          A opção de <strong>Versionamento ou Controle de Versão</strong> serve
          para visualizar o histórico de edições do documento e recuperar
          versões anteriores se necessário. No entanto, essa funcionalidade
          ainda não está ativa nesta versão beta. Ela será lançada em breve.
        </Text>
      </TextContent> */}
    </ContainerSidebarNav>
  );
};

export default VersioningProject;
