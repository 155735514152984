import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: inline-block;
  height: 45px;

  div {
    background-color: ${({ theme }) => theme.colors.error10};
    border: 1px solid ${({ theme }) => theme.colors.error100};
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.error100};
    padding: 3px 5px 3px 0px;
  }

  p {
    font-weight: 400;
    font-size: 13px;

    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
  }

  svg {
    margin-left: 10px;
    height: 17px;
    width: 17px;
  }
`;
