import React, { useCallback, useState } from "react";

import { ButtonGoTwoFactor, Container } from "./styles";

import ShieldIcon from "../../assets/shield_lock.svg";
import { X } from "phosphor-react";
import { useNavigate } from "react-router-dom";

type Props = {
  isNotifications: boolean;
  setIsNotifications: React.Dispatch<React.SetStateAction<boolean>>;
};

const Notification2FA: React.FC<Props> = ({
  isNotifications,
  setIsNotifications,
}) => {
  const navigate = useNavigate();

  const handleCloseNotifications = useCallback(() => {
    localStorage.setItem("UXDOC:2FA", JSON.stringify({ notification: false }));
    setIsNotifications(false);
  }, [setIsNotifications]);

  return (
    <Container>
      <img src={ShieldIcon} alt="" />
      <span>Adicione segurança extra com a autenticação de dois fatores.</span>

      <div className="vstack">
        <ButtonGoTwoFactor onClick={() => navigate("/perfil/seguranca")}>
          Habilitar
        </ButtonGoTwoFactor>

        <button
          type="button"
          onClick={handleCloseNotifications}
          className="close-btn"
        >
          <X weight="bold" size={16} color="#fff" />
        </button>
      </div>
    </Container>
  );
};

export default Notification2FA;

