import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from "react";

interface SharedDocumentProps {
  children: ReactNode;
}

interface UsersSharedProps {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  permissions: "Owner" | "Editor" | string;
  is_anonymous: boolean;
  current_user: boolean;
}

interface UserAccessControlProps {
  access_control: boolean;
  user_list: UsersSharedProps[];
}

interface SharedDocumentPropsData {
  isModalSharedOpen: boolean;
  setIsModalSharedOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModalShared: () => void;
  handleOpenModalShared: (idTemplate?: string) => void;
  isModalSharedInfoOpen: boolean;
  setIsModalSharedInfoOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModalSharedInfo: (e?: any) => void;
  handleOpenModalSharedInfo: () => void;
  refreshUsersShared: boolean;
  setRefreshUsersShared: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseActiveUser: () => void;
  handleOpenActiveUser: () => void;
  isModalActiveUser: boolean;
  setIsModalActiveUser: React.Dispatch<React.SetStateAction<boolean>>;
  documentUsersAccess: UserAccessControlProps;
  setDocumentUsersAccess: React.Dispatch<
    React.SetStateAction<UserAccessControlProps>
  >;
  isControlEditionsActive: boolean;
  setIsControlEditionsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const SharedDocumentContext = createContext<SharedDocumentPropsData>(
  {} as SharedDocumentPropsData
);

const SharedDocumentHookProvider: React.FC<SharedDocumentProps> = ({
  children
}) => {
  const [isModalSharedOpen, setIsModalSharedOpen] = useState(false);
  const [isModalSharedInfoOpen, setIsModalSharedInfoOpen] = useState(false);
  const [refreshUsersShared, setRefreshUsersShared] = useState(false);
  const [isModalActiveUser, setIsModalActiveUser] = useState(false);
  const [isControlEditionsActive, setIsControlEditionsActive] = useState(false);
  const [documentUsersAccess, setDocumentUsersAccess] = useState(
    {} as UserAccessControlProps
  );

  const handleCloseActiveUser = useCallback(() => {
    setIsModalActiveUser(!isModalActiveUser);
  }, [isModalActiveUser]);

  const handleOpenActiveUser = useCallback(() => {
    setIsModalActiveUser(true);
  }, [isModalActiveUser]);

  const handleCloseModalShared = useCallback(() => {
    setIsModalSharedOpen(!isModalSharedOpen);
  }, [isModalSharedOpen]);

  const handleOpenModalShared = useCallback(
    idTemplate => {
      setIsModalSharedOpen(!isModalSharedOpen);
    },
    [isModalSharedOpen]
  );

  const handleCloseModalSharedInfo = useCallback(
    e => {
      setIsModalSharedInfoOpen(false);
    },
    [isModalSharedInfoOpen]
  );

  const handleOpenModalSharedInfo = useCallback(() => {
    setIsModalSharedInfoOpen(!isModalSharedInfoOpen);
  }, [isModalSharedInfoOpen]);

  return (
    <SharedDocumentContext.Provider
      value={{
        isModalSharedOpen,
        setIsModalSharedOpen,
        handleCloseModalShared,
        handleOpenModalShared,
        isModalSharedInfoOpen,
        setIsModalSharedInfoOpen,
        handleCloseModalSharedInfo,
        handleOpenModalSharedInfo,
        refreshUsersShared,
        setRefreshUsersShared,
        handleCloseActiveUser,
        handleOpenActiveUser,
        documentUsersAccess,
        setDocumentUsersAccess,
        isModalActiveUser,
        setIsModalActiveUser,
        isControlEditionsActive,
        setIsControlEditionsActive
      }}
    >
      {children}
    </SharedDocumentContext.Provider>
  );
};

// creating hook

function useSharedDocument(): SharedDocumentPropsData {
  const context = useContext(SharedDocumentContext);

  if (!context) {
    throw new Error(
      "useSharedDocument must be used with an SharedDocumentHookProvider"
    );
  }

  return context;
}

export { SharedDocumentHookProvider, useSharedDocument };
