export const getWidthOfScreenUser = (
  width: number,
  setIsSmallView: React.Dispatch<React.SetStateAction<boolean>>,
  setIsMediumView: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLargeView: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (width <= 1366) {
    setIsSmallView(true);
  }

  if (width > 1366 && width <= 1600) {
    setIsMediumView(true);
  }

  if (width > 1600) {
    setIsLargeView(true);
  }
};
