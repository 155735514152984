import { cloneDeep } from "lodash";
import { Container, ImageTemplate, Template } from "./styles";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSign } from "../../../../hooks/sign";
import PlainText from "../../../../shared/components/PlainText";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";
import { IListTemplatesChecked } from "../../signature/components/ModalImportDocument";

interface MyTemplatesProps {
  id: string;
  user_id: string;
  title: string;
  description: string;
  thumbnail: string;
  is_automation: boolean;
  automation_user_template_id: any;
  created_at: string;
  updated_at: string;
  thumbnail_url: string;
  is_checked?: boolean;
}

interface IProspTemplatesSelect {
  myTemplates: any;
  setMyTemplates: React.Dispatch<React.SetStateAction<MyTemplatesProps[]>>;
  loading: boolean;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  hasMore: boolean;
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>;
  listTemplatesChecked: IListTemplatesChecked[];
  setListTemplatesChecked: React.Dispatch<React.SetStateAction<IListTemplatesChecked[]>>;
}

const TemplatesSelectSign = ({
  myTemplates,
  setMyTemplates,
  loading,
  setCurrentPage,
  hasMore,
  setHasMore,
  fetchNextPage,
  listTemplatesChecked,
  setListTemplatesChecked
}: IProspTemplatesSelect) => {
  const { selectableTemplateId, setSelectableTemplateId } = useSign();

  useEffect(() => {
    if (!loading) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries?.some((entry) => entry?.isIntersecting)) {
          if (hasMore) {
            setCurrentPage(oldState => oldState + 1);
            fetchNextPage();
          }
        }
      });

      const myDiv = document.querySelector("#sentinela2");

      if (myDiv) {
        intersectionObserver?.observe(myDiv);
      }
      return () => intersectionObserver?.disconnect();
    }
  }, [loading, hasMore, fetchNextPage]);

  return (
    <Container>
      {myTemplates.map((template, index) => (
        <Template key={template.id}>
          <input
            type="checkbox"
            checked={!!template.is_checked}
            onChange={(event) => {
              const findTemplate = listTemplatesChecked.find(listIds => listIds.id === template.id);
              if (findTemplate) {
                setListTemplatesChecked(listTemplatesChecked.filter(listIds => listIds.id !== template.id));
              } else {
                setListTemplatesChecked(prevState => [...prevState, { id: template.id }]);
              }
              setSelectableTemplateId(template.id);
              setMyTemplates((oldState) => {
                let cloneState = cloneDeep(oldState);
                cloneState.forEach((item, i) => {
                  cloneState[i].is_checked = i === index;
                });
                return cloneState;
              });
            }}
          />
          <ImageTemplate>
            {template.thumbnail !== "default" && (
              <img src={template.thumbnail_url} alt="Template" />
            )}
          </ImageTemplate>
          <PlainText>{template.title}</PlainText>
        </Template>
      ))}
      <div id="sentinela1" style={{ width: "100%", height: "50px" }} />
      {hasMore && (
        <div
          id="sentinela2"
          style={{
            width: "100%",
            height: "50px",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              margin: "auto auto",
              width: "30px",
              height: "30px",
              display: "flex",
            }}
          >
            <ReactLoading type="spin" color="#000" height={30} width={30} />
          </div>
        </div>
      )}
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <p>
          <Skeleton height={200} count={1} />
        </p>
      </SkeletonTheme>
    </Container>
  );
};

export default TemplatesSelectSign;
