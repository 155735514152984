import styled from "styled-components";

export const MainView = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #f5f5f5;

  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow-y: auto;
`;

export const Spacer = styled.div`
  width: 100%;
  height: 20px;
`;
