import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";

import {
  ButtonSend,
  Container,
  Form,
  InputBase,
  LeftWrapper,
  LogoOrganization,
  MainContent,
  RightWrapper,
  Text
} from "./styles";

import imageBackgroundDecoration from "../../assets/background-templates.svg";

import { toast, ToastContainer } from "react-toastify";

import PuffLoader from "react-spinners/PuffLoader";
import logoUX3 from "../../../../unknown/login/assets/uxlogotipo.svg";
import { LoadingFlex } from "../../../login/pages/Login/styles";
import api from "../../../../../../services/api";
import { useSelection } from "../../../../../editor/hooks/selection";
import { useParams } from "react-router-dom";
import { useTheme } from "../../../../../../hooks/theme";
import TitleH3 from "../../../../../../shared/components/TitleH3";
import InputForm from "../../../../../../shared/components/InputForm";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import AlertError from "../../../../../../shared/components/AlertError";
import { darken, lighten, saturate } from "polished";

interface SubmitProps {
  email?: string;
}

const ForgotPasswordScreenOrganization: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { organizationType } = useParams();
  const { setForceBlockKey } = useSelection();
  const [logoUrl, setLogoUrl] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(true);
  const { setTheme } = useTheme();

  useEffect(() => {
    setForceBlockKey(true);
  }, []);

  const schema = yup.object({
    email: yup.string().email("Email inválido").required()
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SubmitProps>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<SubmitProps> = async data => {
    try {
      setIsLoading(true);
      const responseForgotPassword = await api.post("password/forgot", data);
      toast.success("Email enviado com sucesso!");
      setIsLoading(false);
    } catch (err) {
      console.error(err, "error when post forgot password");
      toast.error("Algo deu errado. Tente novamente mais tarde.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = `UX DOC | Esqueci minha senha`;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const responseOrganization = await api.get(
          "organization/find-organization",
          {
            params: {
              name: organizationType
            }
          }
        );

        const lightPrimaryColor = darken(
          0.05,
          saturate(0.6, lighten(0.24, responseOrganization.data.primary_color))
        );

        setLogoUrl(responseOrganization.data.logo_url);
        setTheme(oldState => {
          return {
            ...oldState,
            name: organizationType,
            colors: {
              primary: responseOrganization.data.primary_color,
              backgroundAsideDash:
                responseOrganization.data.menu_background_color,
              colorTextAsideDash: responseOrganization.data.menu_text_color,
              gradientBody: "#3c2058",
              gradientBody2: "#663399",
              secondary: "#000",
              black: "#000",
              background: "#663399",
              border: "#C4C4C4",
              gray900: "#343A40",
              gray300: "#ADB5BD",
              gray500: "#EEEEEE",
              backgroundEditor: "#F8F9FA",
              red: "#520202",
              whiteColor: "#fff",

              black100: "#343A40",
              black75: "#676B70",
              black50: "#999C9F",
              black20: "#D6D8D9",
              black10: "#EBEBEC",
              black5: "#F5F5F5",
              primary90: responseOrganization.data.primary_color,
              white100: "#FFF",
              error100: "#DE2D2D",
              error10: "#FCEAEA",
              focus: "#5D5FEF",
              emphasis: lightPrimaryColor
            }
          };
        });
        setLoadingScreen(false);
      } catch (err) {
        console.error(err);
        toast.error("Algo deu errado!");
      }
    })();
  }, []);

  return (
    <Container>
      <ToastContainer position="top-left" />
      <LogoOrganization src={logoUrl} />

      <header>
        <TitleH3 title="Esqueci minha senha" />

        <p>
          Esqueceu sua senha? Não se preocupe, coloque o seu e-mail no campo
          abaixo. Enviaremos um link para recuperar a sua senha.
        </p>
      </header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputForm
          error={false}
          name="email"
          register={register}
          type="email"
          label="Email*"
        />

        <footer>
          <ButtonMaxWidth
            text="Enviar"
            typeStyle="purple"
            type="submit"
            loading={isLoading}
          />
          <AlertError
            dataError={
              errors?.email?.message === "email is a required field"
                ? "Campo obrigatório"
                : errors?.email?.message
            }
          />
        </footer>
      </Form>
    </Container>
  );
};

export default ForgotPasswordScreenOrganization;
