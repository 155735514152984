import styled from "styled-components";

export const DisabledButtons = styled.button`
  display: flex;
  align-items: center;

  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background: transparent;
  min-width: 28px;

  .save {
    width: 24px;
    height: 24px;
  }
`;

export const OfflineBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  color: #fff;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 1600px) {
    font-size: 14px;
  }

  @media (max-width: 1366px) {
    font-size: 13px;
  }
`;
