import starFilledIcon from "../../../../../shared/assets/star-filled.svg";
import { Container, TitleItem, OptionsItem, Modal } from "./styles";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import { useState } from "react";
import { useTheme } from "../../../../../hooks/theme";
import { UseMutateFunction } from "react-query";

interface MyClausesDataProps {
  clause: string;
  created_at: string;
  description?: string;
  id: string;
  is_favorite: boolean;
  key_words?: string;
  title: string;
  updated_at: string;
  user_id: string;
}

interface IClauseIteProps {
  item: MyClausesDataProps;
  index: number;
  handleShowClausule: (item: MyClausesDataProps, index?: number) => void;
  handleFavoriteClause: UseMutateFunction<
    any,
    unknown,
    MyClausesDataProps,
    unknown
  >;
  handleEditClausule: (item: MyClausesDataProps, index?: number) => void;
  handleRemoveClause: UseMutateFunction<void, unknown, string, unknown>;
  handleOpenModalDeleteClausule: (item: MyClausesDataProps) => void;
}

const ClauseItem = ({
  item,
  handleShowClausule,
  handleFavoriteClause,
  handleEditClausule,
  handleOpenModalDeleteClausule
}: IClauseIteProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();
  const [isVisibleOption, setIsVisibleOption] = useState(false);

  return (
    <Container
      onMouseEnter={() => setIsVisibleOption(true)}
      onMouseLeave={() => setIsVisibleOption(false)}
    >
      <TitleItem>
        {item.is_favorite ? (
          <div onClick={() => handleFavoriteClause(item)}>
            <PersonalizedIcon
              dPath={MountIcons.IconStars.dPath}
              viewBox={MountIcons.IconStars.viewBox}
              inactivatedColor={theme.colors.primary}
            />
          </div>
        ) : (
          <img
            src={starFilledIcon}
            alt=""
            onClick={() => handleFavoriteClause(item)}
          />
        )}
        <p onClick={() => handleShowClausule(item)}>{item.title}</p>
      </TitleItem>
      <p>{item.key_words}</p>
      {isVisibleOption && (
        <OptionsItem
          onClick={() => setIsOpen(!isOpen)}
          onMouseLeave={() => setIsOpen(false)}
          isActive={isOpen}
        >
          <span />
          <span />
          <span />
          {isOpen && (
            <Modal>
              <div onClick={() => handleEditClausule(item)}>
                <PersonalizedIcon
                  dPath={MountIcons.IconEdit.dPath}
                  viewBox={MountIcons.IconEdit.viewBox}
                  inactivatedColor="#676B70"
                />
                Editar
              </div>
              <div onClick={() => handleOpenModalDeleteClausule(item)}>
                <PersonalizedIcon
                  dPath={MountIcons.IconTrash.dPath}
                  viewBox={MountIcons.IconTrash.viewBox}
                  inactivatedColor="#676B70"
                />
                Excluir
              </div>
            </Modal>
          )}
        </OptionsItem>
      )}
    </Container>
  );
};

export default ClauseItem;
