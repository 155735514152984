import React from "react";
import { useTheme } from "../../../../../../hooks/theme";

// import { Container } from './styles';
interface StrokeIconProps {
  isActive?: boolean;
  className?: string;
}

const StrokeIcon: React.FC<StrokeIconProps> = ({ isActive, className }) => {
  const { theme } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <mask
        id="mask0_10474_21975"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_10474_21975)">
        <path
          d="M2 20.8889V19.7778H22V20.8889H2ZM2 17.5555V15.3333H22V17.5555H2ZM2 13.1111V9.77775H22V13.1111H2ZM2 7.55553V3.11108H22V7.55553H2Z"
          fill={isActive ? theme.colors.primary : "#000"}
        />
      </g>
    </svg>
  );
};

export default StrokeIcon;
