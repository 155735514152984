import React, { useState, useRef, useEffect, useCallback } from "react";
import { ButtonComments, Container, SettingsBox } from "./styles";
import { useComments } from "../../hooks/comments";
import { useParams } from "react-router-dom";
import Tooltip from "../Tooltip";
import api from "../../../../services/api";
import { AxiosResponse } from "axios";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../shared/utils/MountIcons";
import { useTheme } from "../../../../hooks/theme";

// import { Container } from './styles';
interface CommentListProps {
  id: string;
  user_id: string;
  user_template_id: string;
  message: string;
  position: string;
  is_excluded: boolean;
  is_edited: boolean;
  is_done: boolean;
  page: string;
  created_at: string;
  updated_at: string;
  avatar: string;
  avatar_url: string;
  first_name: string;
  last_name: string;
  count_answers: number;
}

interface ResponseListAllCommentsProps {
  pagination: number;
  dataArray: CommentListProps[];
}

const Comments: React.FC = () => {
  const boxRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [commentsActive, setCommentsActive] = useState(false);

  const {
    isCommentsVisible,
    setIsCommentsVisible,
    getCommentsInDoc,
    setSelectedIdComment
  } = useComments();

  const { templateid } = useParams();
  const [isNewComments, setIsNewComments] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const responseListAllComments: AxiosResponse<ResponseListAllCommentsProps> =
          await api.get(`user-template-comments/list-all/${templateid}`, {
            params: {
              pagination: 0,
              is_excluded_or_done: false
            }
          });

        if (responseListAllComments.data.dataArray.length === 0) {
          setIsNewComments(false);
        } else {
          setIsNewComments(true);
        }
      } catch (err) {
        console.error(err, "Error when get all comments");
      }
    })();
  }, [templateid]);

  const handleChangeCommentsActive = useCallback(() => {
    if (isCommentsVisible.active) {
      setIsCommentsVisible({
        active: false,
        screen: "none"
      });
      return;
    }
    setCommentsActive(prev => !prev);
  }, [isCommentsVisible.active, setIsCommentsVisible]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        boxRef.current &&
        !boxRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setCommentsActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      setCommentsActive(false);
      setIsNewComments(false);
    };
  }, []);

  const handleCreateNewComment = useCallback(() => {
    setIsCommentsVisible({
      active: true,
      screen: "creating"
    });
    getCommentsInDoc(templateid);
    setCommentsActive(false);
  }, [getCommentsInDoc, setIsCommentsVisible, templateid]);

  const { theme } = useTheme();

  return (
    <Container ref={boxRef}>
      <Tooltip
        title="Comentários"
        description="Insira ou visualize todos os comentários para que outras pessoas possam entender algum texto ou alteração que você fez."
        placement="bottom-start"
      >
        <ButtonComments onClick={handleChangeCommentsActive} ref={buttonRef}>
          <PersonalizedIcon
            dPath={MountIcons.IconComment.dPath}
            viewBox={MountIcons.IconComment.viewBox}
            isActive={commentsActive || isCommentsVisible.active}
            activeColor={theme.colors.primary}
            inactivatedColor="#343A40"
          />
          {isNewComments && <div className="ball-notification"></div>}
        </ButtonComments>
      </Tooltip>
      {commentsActive && (
        <SettingsBox>
          <ul>
            <li onClick={handleCreateNewComment}>Criar comentário</li>
            <li
              onClick={() => {
                setSelectedIdComment(null);
                getCommentsInDoc(templateid);
                setIsCommentsVisible({
                  active: true,
                  screen: "listAllComments"
                });
                setCommentsActive(false);
              }}
            >
              Ver comentários
            </li>
          </ul>
        </SettingsBox>
      )}
    </Container>
  );
};

export default Comments;
