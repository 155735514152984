import { HTMLAttributes } from "react";

interface IconSvgProps extends HTMLAttributes<HTMLImageElement> {
  src: string;
  description: string;
  className?: string;
}

const IconSvg: React.FC<IconSvgProps> = ({
  src,
  description,
  className,
  ...rest
}) => {
  return (
    <img
      {...rest}
      className={className}
      src={src}
      alt={description}
      {...rest}
    />
  );
};

export default IconSvg;
