import { useTheme } from "../../../../../../hooks/theme";

interface StrongIconProps {
  isActive?: boolean;
  className?: string;
}

export default function StrongIcon({ isActive, className }: StrongIconProps) {
  const { theme } = useTheme();

  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.8333 27.1667H16V22.1667H21.8333C22.4964 22.1667 23.1323 22.4301 23.6011 22.8989C24.0699 23.3677 24.3333 24.0036 24.3333 24.6667C24.3333 25.3297 24.0699 25.9656 23.6011 26.4344C23.1323 26.9033 22.4964 27.1667 21.8333 27.1667ZM16 12.1667H21C21.663 12.1667 22.2989 12.4301 22.7678 12.8989C23.2366 13.3677 23.5 14.0036 23.5 14.6667C23.5 15.3297 23.2366 15.9656 22.7678 16.4344C22.2989 16.9033 21.663 17.1667 21 17.1667H16V12.1667ZM25.3333 19.3167C26.95 18.1833 28.0833 16.3333 28.0833 14.6667C28.0833 10.9 25.1667 8 21.4167 8H11V31.3333H22.7333C26.2333 31.3333 28.9167 28.5 28.9167 25.0167C28.9167 22.4833 27.4833 20.3167 25.3333 19.3167Z"
        fill={isActive ? theme.colors.primary : "#000"}
      />
    </svg>
  );
}
