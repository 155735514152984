import styled from "styled-components";

export const Container = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  width: 40px;
  height: 40px;

  position: absolute;
  bottom: 1px;
  right: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  span {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary90 : theme.colors.black75};
    width: 3px;
    height: 3px;
    border-radius: 50%;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black10};
    border-radius: 5px;
  }
`;

export const Modal = styled.div`
  position: absolute;
  z-index: 2;
  top: 40px;
  right: 0;

  min-width: 138px;
  background-color: ${({ theme }) => theme.colors.white100};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const Item = styled.button`
  width: 100%;
  height: 44px;
  background-color: ${({ theme }) => theme.colors.white100};

  position: relative;
  cursor: pointer;
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 400;
  line-height: 120%;

  & > a,
  div {
    width: 100%;
    height: 100%;
    padding: 10px 12px;

    display: flex;
    align-items: center;
    column-gap: 8px;

    text-decoration: none;
    color: ${({ theme }) => theme.colors.black75};

    svg,
    img {
      height: 24px;
      width: 24px;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }

  & > svg {
    width: 8px;
    height: 15px;
  }
`;
