import styled from "styled-components";

export const Container = styled.div`
  grid-area: 2 / 2 / 6 / 6;
  /* background-color: red; */
  display: flex;
  flex: 1;
  height: calc(100dvh - 40px);
  overflow-y: auto;
  margin-bottom: auto;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 25px;
`;

export const HStack = styled.div`
  display: flex;
  max-width: 800px;
`;

export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: green; */
  min-width: 500px;
`;

export const HeadText = styled.span`
  //styleName: H4;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

export const DescriptionText = styled.h2`
  //styleName: Texto grande bold;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
`;
export const BodyText = styled.h3`
  //styleName: Texto corrido;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
`;
export const Button = styled.button`
  margin-left: auto;
  width: 90px;
  height: 40px;
  background: #fff;
  border: 1px solid #7547a3;
  border-radius: 5px;
  //styleName: Botões;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  transform: translateY(47px);
`;

export const ButtonCancel = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #676b70;
  color: #343a40;
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
  background-color: transparent;
`;

export const ButtonDisable = styled.button<{ color: string }>`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  /* border: 1px solid #676b70; */
  color: #fff;
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
  background-color: ${({ color }) => color && color};
`;

