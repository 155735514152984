import React from "react";
import { VectorOfColors } from "../../../../../../shared/utils/VectorOfColors";
import { useIconsColors } from "../../../../hooks/iconsColors";
import { usePagesEditor } from "../../../../hooks/pagesEditor";
import { useSelection } from "../../../../hooks/selection";
import { useToolbarEditor } from "../../../../hooks/toolbarEditor";
import DropdownZoom from "../../../DropdownZoom";
import IconToolbar from "../../../IconToolbar";

import { ButtonBorderColor } from "../../ButtonBorderColor";
import { ButtonColor } from "../../ButtonColor";
import ButtonToolbar from "../../ButtonToolbar";
import DropdownDash from "../../DropdownDash";
import DropdownOpacity from "../../DropdownOpacity";
import DropdownRadius from "../../DropdownRadius";
import DropdownStroke from "../../DropdownStroke";
import LockedIcon from "../../icons/LockedIcon";
import UnlockIcon from "../../icons/UnlockIcon";
import { ContainerDefaultPage, ContainerRight, Divider } from "./styles";
import Tooltip from "../../../Tooltip";
import ButtonRuler from "../../../../../../shared/components/ButtonRuler";
import { useWorkspaceEditor } from "../../../../hooks/workspaceEditor";
import AutomaticNumbering from "../../../AutomaticNumbering";
import Comments from "../../../Comments";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../shared/utils/MountIcons";

interface DefaultToolProps {
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  isFavoriteColor: string[][];
  selectedOption: any;
  setSelectedOption: any;
  colorsSelectVector: string[];
  handleChange: (color: any) => void;
  randomColor: boolean;
  handleInputChange: (e: any) => void;
  borderColor: string;
  isFavoriteColorBorder: string[][];
  selectedOptionBorder: any;
  setSelectedOptionBorder: any;
  colorsBorderSelectVector: string[];
  setColorBorder: React.Dispatch<React.SetStateAction<string>>;
  handleChangeBorder: (color: any) => void;
  randomColorBorder: boolean;
  handleInputChangeBorder: (e: any, color: string) => void;
  isActiveDash: boolean;
  handleClickDash: () => void;
  handleClickRadius: () => void;
  isActiveBorderRadius: boolean;
  handleClickBringToForwardIcon: () => void;
  handleClickTakeBackIcon: () => void;
  handleClickOpacity: () => void;
  handleToggleBlockElement: () => void;
  handleDeleteElement: () => void;
  iconTrash: string;
}

const DefaultTool: React.FC<DefaultToolProps> = ({
  color,
  setColor,
  isFavoriteColor,
  selectedOption,
  setSelectedOption,
  colorsSelectVector,
  handleChange,
  randomColor,
  handleInputChange,
  borderColor,
  isFavoriteColorBorder,
  selectedOptionBorder,
  setSelectedOptionBorder,
  colorsBorderSelectVector,
  setColorBorder,
  handleChangeBorder,
  randomColorBorder,
  handleInputChangeBorder,
  isActiveDash,
  handleClickDash,
  handleClickRadius,
  isActiveBorderRadius,
  handleClickBringToForwardIcon,
  handleClickTakeBackIcon,
  handleClickOpacity,
  handleToggleBlockElement,
  handleDeleteElement,
  iconTrash,
}) => {
  const { selectedObject, blockCommandKeyboard, setBlockCommandKeyboard } =
    useSelection();

  const {
    handleBlockElements,
    handleCloseColorPickerClick,
    handleColorPickerClick,
    handleColorPickerClickBorder,
    isDisplayColorPicker,
    isActiveBringToForward,
    isActiveTakeBack,
    setIsDisplayColorPicker,
    handleCloseColorPickerClickBorder,
    isDisplayColorPickerBorder,
    setIsDisplayColorPickerBorder,
  } = useIconsColors();

  const {
    isDraggable,

    handleChangeOpacity,
    valueOpacity,
    setValueOpacity,
    handleAllToFront,
    handleAllToBack,
    handleChangeStroke,
  } = useToolbarEditor();

  const { idPage } = usePagesEditor();

  const { handleShowRuler, showRuler } = useWorkspaceEditor();
  return (
    <>
      <ContainerDefaultPage>
        <Divider>
          {selectedObject?.attrs?.object !== "simpleLine" && (
            <ButtonColor
              colorsDefault={VectorOfColors}
              newColor={color}
              isFavoriteColor={isFavoriteColor}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              colorsSelectVector={colorsSelectVector}
              addNewColor={setColor}
              handleColorPickerClick={handleColorPickerClick}
              handleCloseColorPickerClick={handleCloseColorPickerClickBorder}
              isDisplayColorPicker={isDisplayColorPicker}
              setIsDisplayColorPicker={setIsDisplayColorPicker}
              handleChange={handleChange}
              randomColor={randomColor}
              handleInputChange={handleInputChange}
              blockKeyboard={blockCommandKeyboard}
              setBlockKeyboard={setBlockCommandKeyboard}
            />
          )}

          <ButtonBorderColor
            colorsDefault={VectorOfColors}
            newColorBorder={borderColor}
            isFavoriteColor={isFavoriteColorBorder}
            selectedOption={selectedOptionBorder}
            setSelectedOption={setSelectedOptionBorder}
            colorsSelectVector={colorsBorderSelectVector}
            isStrokeColor={borderColor}
            addNewColor={setColorBorder}
            handleColorPickerClick={handleColorPickerClickBorder}
            handleCloseColorPickerClick={handleCloseColorPickerClickBorder}
            isDisplayColorPickerBorder={isDisplayColorPickerBorder}
            setIsDisplayColorPicker={setIsDisplayColorPickerBorder}
            handleChangeBorder={handleChangeBorder}
            randomColor={randomColorBorder}
            handleInputChange={handleInputChangeBorder}
            blockKeyboard={blockCommandKeyboard}
            setBlockKeyboard={setBlockCommandKeyboard}
          />
        </Divider>

        <Divider>
          <DropdownStroke
            component="isStroke"
            handleChangeStroke={handleChangeStroke}
            className="btn-toolbar"
            type="button"
            tooltipTitle="Espessura"
            tooltipDescription="Escolha a espessura da linha da sua forma."
            tooltipPlacement="bottom-start"
          />

          <DropdownDash
            isActive={isActiveDash}
            className="btn-toolbar"
            type="button"
            onClick={handleClickDash}
            component="isDash"
            tooltipTitle="Traço"
            tooltipDescription="Escolha o tipo de traço para a forma que você escolheu."
            tooltipPlacement="bottom-start"
          />

          <DropdownRadius
            tooltipTitle="Canto"
            tooltipDescription="Arredonde a borda da forma."
            tooltipPlacement="bottom-start"
            component="isBorderRadius"
            onClick={handleClickRadius}
            type="button"
            isActive={isActiveBorderRadius}
            className="btn-toolbar"
          />
        </Divider>

        <Divider>
          <ButtonToolbar
            isActive={isActiveBringToForward}
            className="btn-toolbar"
            type="button"
            onClick={handleClickBringToForwardIcon}
            component="toFront"
            tooltipTitle="Avançar"
            tooltipDescription="Trazer o objeto selecionado um nível para a frente, para que fique à frente de menos objetos."
            tooltipPlacement="bottom-start"
          />

          <ButtonToolbar
            isActive={isActiveTakeBack}
            className="btn-toolbar"
            type="button"
            component="toBack"
            onClick={handleClickTakeBackIcon}
            tooltipTitle="Recuar"
            tooltipDescription="Enviar o objeto selecionado um nível para trás, para que fique atrás de mais objetos."
            tooltipPlacement="bottom-start"
          />

          <ButtonToolbar
            isActive={isActiveTakeBack}
            className="btn-toolbar"
            type="button"
            component="isAllToFront"
            onClick={() => handleAllToFront(idPage)}
            tooltipTitle="Tudo para frente"
            tooltipDescription="Trazer o objeto selecionado para a frente de todos os outros objetos."
            tooltipPlacement="bottom-start"
          />

          <ButtonToolbar
            isActive={isActiveTakeBack}
            className="btn-toolbar"
            type="button"
            component="isAllToBack"
            onClick={() => handleAllToBack(idPage)}
            tooltipTitle="Tudo para trás"
            tooltipDescription="Enviar o objeto selecionado para trás de todos os outros objetos."
            tooltipPlacement="bottom-start"
          />
        </Divider>
        <DropdownOpacity
          valueOpacity={valueOpacity}
          setValueOpacity={setValueOpacity}
          callback={handleChangeOpacity}
          // isActive={isActiveDash}
          className="btn-toolbar"
          type="button"
          tooltipTitle="Transparência"
          tooltipDescription="Manipule a transparência do objeto."
          tooltipPlacement="bottom-start"
          onClick={handleClickOpacity}
          component="isOpacity"
        />
      </ContainerDefaultPage>

      <ContainerRight>
        <Divider>
          <AutomaticNumbering />
          <ButtonRuler
            showRuler={showRuler}
            handleShowRuler={handleShowRuler}
          />
        </Divider>
        <Divider>
          <Comments />
        </Divider>

        <Divider>
          <Tooltip
            title={isDraggable ? "Bloquear" : "Desbloquear"}
            description={
              isDraggable
                ? "Evite que um elemento possa ser alterado durante a edição do documento."
                : "Permita a edição do elemento"
            }
            placement="bottom-start"
          >
            <button
              onClick={() => {
                handleBlockElements();
                handleToggleBlockElement();
              }}
              draggable={false}
              className="btn-toolbar"
            >
              {isDraggable ? (
                <UnlockIcon className="width-unlock-icon" />
              ) : (
                <LockedIcon className="width-lock-icon" />
              )}
            </button>
          </Tooltip>
        </Divider>

        <Tooltip
          title="Excluir"
          description="Exclua um elemento que você criou."
          placement="bottom-start"
        >
          <button
            draggable={false}
            className="btn-toolbar"
            onClick={handleDeleteElement}
          >
            <IconToolbar
              className="width-icon-toolbar"
              src={iconTrash}
              description="Excluir"
            />
          </button>
        </Tooltip>

        {/* <DropdownZoom /> */}
      </ContainerRight>
    </>
  );
};

export default DefaultTool;

