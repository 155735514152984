import styled from "styled-components";

type ButtonSecureProps = {
  action: "advance" | "cancel";
  isDisabled: boolean;
};

export const Button = styled.button<ButtonSecureProps>`
  width: 100%;
  background-color: ${({ theme, action, isDisabled }) =>
    isDisabled
      ? "#F5F5F5"
      : action === "advance"
      ? theme.colors.primary
      : "#fff"};
  height: 40px;
  border-radius: 5px;
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
  color: ${({ theme, action, isDisabled }) =>
    isDisabled
      ? "#D6D8D9"
      : action === "cancel"
      ? theme.colors.primary
      : "#fff"};
  border: ${({ theme, action }) =>
    action === "cancel" && `1px solid ${theme.colors.primary}`};
`;

export const BoxInputCode = styled.div`
  width: 592px;
  height: 60px;
  display: flex;
  margin: 1.6rem auto;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #d6d8d9;
  display: flex;
`;

