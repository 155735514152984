import styled from "styled-components";

export const HStack = styled.div`
  width: 100%;
  height: auto;
  background-color: transparent !important;
  display: flex;
  margin-top: 0.5rem;
  /* background-color: red !important; */
  /* padding: 0 !important; */
  /* justify-content: flex-start !important; */
  justify-content: flex-start !important;
  gap: 0;

  input {
    /* width: 40%; */
    width: 50px;
    margin: 0 auto;
    border: none; /* <-- This thing here */
    border: solid 1px #ccc;
    border-radius: 10px;
    accent-color: #663399;
    left: -16px;
    top: 0px;
    cursor: pointer;
    width: 20px;
    height: 20px;

    border-radius: 50%;
    display: inline-block;
    position: relative;
  }

  :hover {
    background-color: transparent !important;
  }
`;

export const FlexRight = styled.div`
  width: 50px;
  height: 100%;
  background-color: red;
  display: flex;
`;

export const DiffControlView = styled.div`
  position: absolute;
  top: 27px;
  left: -30px;
  background-color: #fff;
  width: 211px;
  height: 65px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  p {
    color: var(--preto-preto-100, #343a40);
    /* Título pequeno */
    font-family: Ubuntu;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    margin-top: 2px;
  }

  .activate-span {
  }

  span {
    color: #343a40;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
  }

  .box-hstack {
    display: flex;
    gap: 0;
  }

  @media (max-width: 1366px) {
    left: -200px;
  }
`;

export const Stack = styled.form`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  /* background-color: red; */
  margin-top: 10px;
  padding: 0;
`;

export const MidBox = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    color: var(--preto-preto-100, #343a40);
    /* Título pequeno */
    font-family: Ubuntu;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    margin-left: 10px;
  }

  input {
    width: 18px;
    height: 18px;
    accent-color: #663399;
  }
`;
