import { useEffect, useState } from "react";
import { Container, ButtonInitial, Modal, MenuLink } from "./styles";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";

interface Props<T> {
  items: T[];
  value?: keyof T;
  onChange: (value: T) => void;
  selectedItem: T;
  onModalStateChange?: (state: boolean) => void;
}

const SelectItem = <T,>({
  items,
  value,
  onChange,
  selectedItem,
  onModalStateChange
}: Props<T>) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState<T | null>(selectedItem ?? null);

  const onSelect = (value: T) => {
    onChange(value);
    setSelected(value);
    setModal(false);
  };

  useEffect(() => {
    setSelected(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    onModalStateChange && onModalStateChange(modal);
  }, [modal, onModalStateChange]);

  return (
    <Container>
      <ButtonInitial isOpen={modal} onClick={() => setModal(!modal)}>
        {selected === null || selected === undefined ? (
          <div>Selecione o fluxo</div>
        ) : (
          <div>{value ? selected[value] : selected}</div>
        )}
        <PersonalizedIcon
          dPath={MountIcons.IconArrowBottom.dPath}
          viewBox={MountIcons.IconArrowBottom.viewBox}
          inactivatedColor="#676B70"
        />
      </ButtonInitial>
      {modal && (
        <Modal>
          {items.map(item => (
            <MenuLink onClick={() => onSelect(item)}>
              {value ? item[value] : item}
            </MenuLink>
          ))}
        </Modal>
      )}
    </Container>
  );
};

export default SelectItem;
