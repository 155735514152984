import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Heading = styled.span`
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  color: #343a40;
  font-weight: 700;
  margin-left: 7px;
`;

export const Text = styled.div`
  font-size: 14px;
  font-family: Ubuntu, sans-serif;
  margin: 0 auto;
  margin-top: 1.2rem;
  line-height: 150%;
  color: #343a40;
  min-width: 500px;
  max-width: 500px;
  text-align: center;
  margin-bottom: 1.8rem;
`;

export const HStack = styled.div`
  display: flex;
  margin: auto auto;
  align-items: center;
  margin-top: 1.6rem;
`;

