/*
this component render the main page of editor module
then here this file brings components such as modals 
reference feature of diff when user shared,
the editor module was splited by three most importants
components: header editor, workspace editor and sidebar
*/

import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import Asidebar from "../components/Asidebar";
import HeaderMain from "../components/HeaderMain";
import Workspace from "../components/Workspace";
import MainContainer from "../components/MainContainer";
import { useCallback, useEffect, useState } from "react";
import { useMainHook } from "../../../hooks/main";
import { useSelection } from "../hooks/selection";
import { ToastContainer } from "react-toastify";
import { useDiff } from "../hooks/diffDocuments";
import { useTemplates } from "../hooks/templates";
import { useAsidebarEditor } from "../hooks/asidebarEditor";

import {
  loadTemplatesCategories,
  templateStore,
} from "../utils/templatesStore";
import ModalsDiffProvider from "../components/ModalsDiffProvider";

import { useNavigate } from "react-router-dom";
import { Box, ButtonSaving, ModalFlex, ModalSave } from "./styles";
import { useHeaderEditor } from "../hooks/headerEditor";
import ReactLoading from "react-loading";

import { useWorkspaceEditor } from "../hooks/workspaceEditor";
import { usePagesEditor } from "../hooks/pagesEditor";
import { useMetric } from "../../../hooks/metric";
import ModalSign from "../components/ModalSign";
import { useSign } from "../../../hooks/sign";
import SvgComments from "../components/SvgComments";
import ButtonSuport from "../components/ButtonSuport";
import ModalBuyProduct from "../components/ModalBuyProduct";
import ModalDownloadDoc from "../components/EditorMain/components/ModalDownloadDoc";
import api from "../../../services/api";

//set up interfaces
interface ITemplatesData {
  bits_templates_category?: string;
  created_at?: string;
  creator_id?: string;
  description?: string;
  id?: string;
  key_words?: string;
  last_editor_id?: string;
  template?: ITemplatesData[];
  thumbnail?: string;
  thumbnail_url?: string;
  title?: string;
  updated_at?: string;
  usage_counter?: number;
}

interface TemplatesCategoriesProps {
  id: string;
  category: string;
  templates?: ITemplatesData[];
}

export default function Editor() {
  //hooks configs
  const { multipleSelectionRefs, selectedObject } = useSelection();
  const {
    clearHistory,
    handleOpenModalDifferences,
    divRefModal,
    divRefModalDecline,
    handleOpenModalDifferencesDecline,
    documentName,
    saveModalVisible,
    setSaveModalVisible,
    setLastSaveDate,
    isIntentBuyModal,
    setIsIntentBuyModal,
  } = useMainHook();

  const { isSignEditor } = useSign();

  const { handleSaveTemplate, mountStages } = useHeaderEditor();

  //hooks of templates to load on editor
  const {
    loading,
    setLoading,
    setTemplateByCategories,
    setEndPages,
    setLoadingTemplates,
    hasNewPage,
    setHasNewPage,
    currentPage,
    setCurrentPage,
    dataCategoriesTemplates,
    setDataCategoriesTemplates,
  } = useTemplates();

  const { handleDefineModifications } = useDiff();
  const [loadingSaving, setLoadingSaving] = useState(false);
  const { setShowRuler } = useWorkspaceEditor();
  const { setLinesPage } = usePagesEditor();
  //which tab was selected
  const { selectedTab } = useAsidebarEditor();
  const navigate = useNavigate();
  const { setSelectedObject, setSelectedObjects, currentMultipleSelection } =
    useSelection();
  const { handleUserEnterEditor } = useMetric();

  const handleCloseIntentBuyModal = useCallback(() => {
    setIsIntentBuyModal(false);
  }, [setIsIntentBuyModal]);

  // Unload Page using UseEffect
  //every time that user exit of editor
  //this callback will be trigger and
  //reset pages multipleSelection refs and clear history
  useEffect(() => {
    //function responsible for capturing and sending metrics
    //when the user enters the editor
    handleUserEnterEditor();

    return () => {
      //unloading mutitpleSelections
      //every time user leave the screen
      multipleSelectionRefs.current = [];
      clearHistory();
      setShowRuler(false);
      setLinesPage([]);
    };
  }, []);

  //init categories for know your documents into tag
  useEffect(() => {
    let templatesPerCategory: TemplatesCategoriesProps[] = [];
    //initially we will list the categories and their documents later
    templateStore({
      dataCategoriesTemplates,
      setLoadingTemplates,
      templatesPerCategory,
      setTemplateByCategories,
      hasNewPage,
      currentPage,
      setEndPages,
      setLoading,
    });
  }, [dataCategoriesTemplates]);

  //this useEffect get templates by categories
  useEffect(() => {
    loadTemplatesCategories({
      currentPage,
      setHasNewPage,
      setDataCategoriesTemplates,
      setLoading,
    });
  }, [currentPage]);

  //here we initilize one observer for div sentinela
  //when user scrolling page and sentinella was visible
  //then fetch new page to load more templates
  useEffect(() => {
    if (!loading && selectedTab === "templates") {
      //create observer
      const intersectionObserver = new IntersectionObserver((entries) => {
        //if div sentinela be visible then add new page
        if (entries.some((entry) => entry?.isIntersecting)) {
          setCurrentPage((prevState) => (prevState += 1));
        }
      });

      //clean up in observe
      let mySelector = document?.querySelector("#sentinela-templates");
      if (mySelector) {
        intersectionObserver?.observe(
          document?.querySelector("#sentinela-templates")
        );

        return () => intersectionObserver?.disconnect();
      }
    }
  }, [loading, selectedTab, selectedObject]);

  const handleGoHomeAndSave = useCallback(async () => {
    setLoadingSaving(true);
    // await handleSaveTemplate(true);
    navigate("/");
  }, [handleSaveTemplate, navigate]);

  //modify title of document page browser
  useEffect(() => {
    if (documentName.current) {
      document.title = `UX DOC | ${documentName.current}`;
    }
  }, [documentName]);

  useEffect(() => {
    return () => {
      setSaveModalVisible(false);
      setDataCategoriesTemplates([]);
      setCurrentPage(0);
      setEndPages(false);
      setLastSaveDate("");
    };
  }, []);

  useEffect(() => {
    if (saveModalVisible) {
      setSelectedObject(null);
      setSelectedObjects([]);
      currentMultipleSelection?.current?.setNodes([]);
    }
  }, [saveModalVisible]);

  //Remove scroll conflict on the Y axis between HTML and tooltip
  useEffect(() => {
    document.getElementsByTagName("html")[0].style.overflowY = "hidden";

    return () => {
      document.getElementsByTagName("html")[0].style.overflowY = "auto";
    };
  }, []);

  return (
    <>
      <MainContainer>
        <ModalsDiffProvider
          divRefModal={divRefModal}
          handleDefineModifications={handleDefineModifications}
          handleOpenModalDifferences={handleOpenModalDifferences}
          divRefModalDecline={divRefModalDecline}
          handleOpenModalDifferencesDecline={handleOpenModalDifferencesDecline}
        />

        {mountStages && <ModalDownloadDoc />}

        <ButtonSuport />
        <SvgComments />

        {isSignEditor && <ModalSign />}
        {/* <ModalSave /> */}
        {isIntentBuyModal && <ModalBuyProduct />}
        {saveModalVisible && (
          <ModalSave>
            <ModalFlex>
              <span>Salvar projeto</span>
              <p>
                {`Salvar alterações no ${documentName.current} antes de
                fechar?`}
              </p>
              <Box>
                <ButtonSaving
                  onClick={() => !loadingSaving && navigate("/")}
                  isSave={false}
                >
                  Não
                </ButtonSaving>
                <ButtonSaving onClick={handleGoHomeAndSave} isSave={true}>
                  {!loadingSaving ? (
                    `Sim`
                  ) : (
                    <ReactLoading
                      type="spin"
                      color="#fff"
                      height={14}
                      width={14}
                    />
                  )}
                </ButtonSaving>
              </Box>
            </ModalFlex>
          </ModalSave>
        )}

        <HeaderMain />
        <Workspace />
        <Asidebar />
      </MainContainer>

      <ToastContainer position="bottom-left" />
    </>
  );
}
