import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.colors.primary} 0%, #000000 100%)`};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px 20px 13px 20px;
`;

export const Title = styled.h3`
  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: 700;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.white100};

  @media (min-width: 1600px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 180%;
  }

  @media (min-width: 1920px) {
    font-size: 22px;
    font-weight: 500;
    line-height: 200%;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.white100};
  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;

  @media (min-width: 1600px) {
    font-size: 15px;
    line-height: 170%;
  }

  @media (min-width: 1920px) {
    font-size: 16px;
    line-height: 190%;
  }
`;

export const Button = styled.a`
  color: ${({ theme }) => theme.colors.white100};
  padding: 13px 16px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primary90};
  border-radius: 5px;
  text-decoration: none;

  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1920px) {
    height: 45px;
    font-size: 16px;
  }
`;
