import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LabelVariable = styled.label`
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
`;

export const InputBox = styled.div`
  margin-top: 5px;
  border: 1px solid #999c9f;
  height: 40px;
  border-radius: 1rem;
  overflow: hidden;
`;

export const InputVariable = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  padding-left: 1rem;
`;
