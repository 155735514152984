import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  height: 30px;

  button {
    display: flex;
    align-items: center;
    gap: 8px;

    height: 100%;
    width: 187px;
    color: ${({ theme }) => theme.colors.white100};
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.primary90};
    padding-left: 12px;

    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
  }

  & > div {
    height: 100%;
    padding: 5px 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 400px;
  transform: translateY(0px);
`;

export const FlexPaginationType = styled.div`
  display: flex;

  justify-content: flex-end;
  align-items: center;

  margin-bottom: 2rem;
`;

export const TextNotTemplate = styled.p`
  width: 100%;
  height: 300px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px !important;
`;
