import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 626px;
  height: 902px;

  display: flex;
  flex-wrap: wrap;
`;




