import React, { ReactNode, useRef } from "react";
import { IMaskMixin } from "react-imask";
import { StyledInput } from "./styles";

// import { Container } from './styles';
interface MaskedInputProps {
  maskOptions: IMask.AnyMaskedOptions;
  inputRef: React.MutableRefObject<any>;
}

const StyledMask = IMaskMixin(({ inputRef, ...props }: any) => (
  <StyledInput
    {...props}
    ref={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
  />
));

export default StyledMask;
