import styled from "styled-components";
import { Theme } from "../../../../../dtos/theme";

interface DashProps {
  dash?: number[];
  isActive?: boolean;
}

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropDownContainer = styled.div`
  margin: 0 auto;
  display: flex;
`;

export const DropDownHeader = styled.div<{ Open: boolean }>`
  display: block;

  &:hover {
    cursor: pointer;
  }
`;

export const DropDownListContainer = styled.div``;

export const DropDownList = styled.ul`
  position: absolute;
  left: 150px;
  left: 150px;

  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;
  width: 120px;
  padding-block: 5px;

  display: flex;
  flex-direction: column;
  gap: 5px;

  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));

  color: ${({ theme }: Theme) => theme.colors.black};

  &:hover {
    cursor: pointer;
  }
`;

export const HorizontalStack = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const LineBarNormal = styled.div<{ isActive?: boolean }>`
  width: 100%;
  border: 2px solid #000;
  display: flex;
`;

export const LineWithDash = styled.div<DashProps>`
  border: 2px dashed #000;
  width: 100%;
  display: flex;
`;

export const BoxDashAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BoxText = styled.div<{ isActive?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    color: ${({ isActive }) => (isActive ? "#663399" : "#000")};

    text-align: center;
  }
`;

export const BoxInput = styled.div`
  width: 100%;
  padding-inline: 10px;
  display: flex;
  justify-content: space-between;
`;

export const InputDashedSpace = styled.input`
  width: 45px;
  height: 40px;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  cursor: pointer;

  :focus {
    border: solid 1px ${({ theme }) => theme.colors.primary};
  }

  :hover {
    border: solid 1px #000;
  }
`;

export const InputDashed = styled.input`
  width: 45px;
  height: 40px;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  /* Chrome, Safari, Edge, Opera */

  -webkit-appearance: none;
  margin: 0;
  cursor: pointer;

  /* Firefox */

  -moz-appearance: textfield;

  :focus {
    border: solid 1px ${({ theme }) => theme.colors.primary};
  }

  :hover {
    border: solid 1px #000;
  }
`;

export const WrapperDash = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 30px;
  padding-inline: 10px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ isActive }) => (!isActive ? "#f5f5f5" : "#FFF")};

  :hover {
    background-color: #f5f5f5;
  }
`;

export const WrapperDash2 = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 30px;
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ isActive }) => (isActive ? "#f5f5f5" : "#FFF")};

  :hover {
    background-color: #f5f5f5;
  }
`;
