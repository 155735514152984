import React, { useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import { useClausesEditor } from "../../hooks/clausesEditor";
// import { Container } from './styles';

const QuillRenderImage: React.FC = () => {
  const {
    quillRenderImage,
    clausesObject,
    divQuillClauseRef,
    sizeQuillClause,
    setSizeQuillClause
  } = useClausesEditor();

  const modules = React.useMemo(
    () => ({
      toolbar: false
    }),
    []
  );
  // formats of rich text register
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "background",
    "align",
    "lineheight"
  ];
  return (
    <div>
      <ReactQuill
        ref={quillRenderImage}
        modules={modules}
        formats={formats}
        theme="snow"
        readOnly
        className=""
        style={{
          position: "absolute",
          top: "0",
          maxWidth: "500px",
          left: "0",
          zIndex: "-150",
          resize: "both"
        }}
      />
    </div>
  );
};

export default QuillRenderImage;
