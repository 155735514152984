import React, { HTMLProps, useEffect, useState } from "react";
import IconSvg from "../../../modules/editor/components/IconSvg";
import PersonalizedIcon from "../../assets/customIcons/PersonalizedIcon";
import { ButtonText, Container, ContainerIcon, Main, Beta } from "./styles";
import { useTheme } from "../../../hooks/theme";
import { NavLink } from "react-router-dom";

// import { Container } from './styles';

interface Props extends HTMLProps<HTMLDivElement> {
  isActive: boolean;
  isActiveClick?: boolean;
  buttonLabel: string;
  iconWidth?: number;
  iconHeight?: number;
  viewBox?: string;
  dPath: string;
  classNameIcon?: string;
  src?: string;
  style?: object;
  haveIconNew?: boolean;
  activeButton?: any;
  srcActive?: string;
  isBeta?: boolean;
  className?: string;
  url?: string;
  target?: React.HTMLAttributeAnchorTarget;
}

const PagesButton: React.FC<Props> = ({
  isActive,
  isActiveClick,
  iconWidth,
  buttonLabel,
  iconHeight,
  viewBox,
  dPath,
  children,
  classNameIcon,
  src,
  style,
  haveIconNew,
  activeButton,
  srcActive,
  isBeta,
  className,
  url,
  target = "_self",
  ...rest
}) => {
  const [active, setActive] = useState(false);
  const { theme } = useTheme();

  useEffect(() => {
    (() => {
      if (isActive && isActiveClick) {
        setActive(true);
      } else if (!isActive && isActiveClick) {
        setActive(true);
      } else if (isActive && !isActiveClick) {
        setActive(true);
      } else if (!isActive && !isActiveClick) {
        setActive(false);
      }
    })();
  }, [isActive, isActiveClick]);

  if (!url) {
    return (
      <Main className={className}>
        <Container
          onMouseEnter={rest.onMouseEnter}
          onMouseLeave={rest.onMouseOut}
          onClick={rest.onClick}
          className={isActiveClick ? "active-link" : "inactive-link"}
          style={style}
        >
          {dPath !== "" && !haveIconNew ? (
            <ContainerIcon>
              <PersonalizedIcon
                isActive={active}
                className={classNameIcon}
                width={iconWidth}
                height={iconHeight}
                dPath={dPath}
                viewBox={viewBox}
                activeColor={theme.colors.primary}
                inactivatedColor={theme.colors.black75}
              />
            </ContainerIcon>
          ) : (
            <ContainerIcon>
              {!isActiveClick && !isActive ? (
                <IconSvg
                  className={classNameIcon}
                  src={srcActive}
                  description=""
                />
              ) : (
                <IconSvg className={classNameIcon} src={src} description="" />
              )}
            </ContainerIcon>
          )}
          <ButtonText style={style}>{buttonLabel}</ButtonText>
          {isBeta && <Beta>BETA</Beta>}
        </Container>
      </Main>
    );
  }

  return (
    <Main className={className}>
      <NavLink to={url} target={target}>
        <Container
          onMouseEnter={rest.onMouseEnter}
          onMouseLeave={rest.onMouseOut}
          onClick={rest.onClick}
          className={isActiveClick ? "active-link" : "inactive-link"}
          style={style}
        >
          {dPath !== "" && !haveIconNew ? (
            <ContainerIcon>
              <PersonalizedIcon
                isActive={active}
                className={classNameIcon}
                width={iconWidth}
                height={iconHeight}
                dPath={dPath}
                viewBox={viewBox}
                activeColor={theme.colors.primary}
                inactivatedColor={theme.colors.black75}
              />
            </ContainerIcon>
          ) : (
            <ContainerIcon>
              {!isActiveClick && !isActive ? (
                <IconSvg
                  className={classNameIcon}
                  src={srcActive}
                  description=""
                />
              ) : (
                <IconSvg className={classNameIcon} src={src} description="" />
              )}
            </ContainerIcon>
          )}
          <ButtonText style={style}>{buttonLabel}</ButtonText>
          {isBeta && <Beta>BETA</Beta>}
        </Container>
      </NavLink>
    </Main>
  );
};

export default PagesButton;
