const DocumentIcon = ({ isActive, activeColor, inactivatedColor }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_9601_2082"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.498047" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9601_2082)">
        <path
          d="M8 18.498H16V16.498H8V18.498ZM8 14.498H16V12.498H8V14.498ZM6 22.498C5.45 22.498 4.97917 22.3022 4.5875 21.9105C4.19583 21.5189 4 21.048 4 20.498V4.49805C4 3.94805 4.19583 3.47721 4.5875 3.08555C4.97917 2.69388 5.45 2.49805 6 2.49805H14L20 8.49805V20.498C20 21.048 19.8042 21.5189 19.4125 21.9105C19.0208 22.3022 18.55 22.498 18 22.498H6ZM13 9.49805V4.49805H6V20.498H18V9.49805H13Z"
          fill={isActive ? activeColor : inactivatedColor}
        />
      </g>
    </svg>
  );
};

export default DocumentIcon;
