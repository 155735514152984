import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #343a40;
  width: 100%;
  color: white;
  font-size: 14px;
`;

export default HeaderContainer;
