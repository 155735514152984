import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

interface BoxColorProps {
  color: string;
}

export const ContainerModal = styled.form`
  width: 703.146px;
  border-radius: 5px;
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  box-shadow: 0px 0px 20.09px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;

  position: relative;

`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  min-height: 73px;
  border-radius: 10px;
  align-items: center;
  border: solid 1px red;
  padding: 0 1.6rem;
  line-height: 190%;
  justify-content: center;
`;

export const Text = styled.span`
  font-size: 14.063px;
  font-weight: 400;
  line-height: 150%;
`;

export const Wrapper = styled.div`
  display: flex;
  background: red;
  width: 100%;
  justify-content: space-between;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 227px;
  padding: 1rem 2rem;
  border-radius: 20px;

  &::placeholder {
    font-size: 1.6rem;
    font-weight: 400;
    color: ${({ theme }: Theme) => theme.colors.gray900};
  }
`;

export const HStack = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;

export const HStackBtn = styled.div`
  display: flex;
  width: 160px;
  justify-content: center;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const Button = styled.button`
  width: 120px;
  height: 40px;
  background: ${({ theme }) => theme.colors.background};
  color: white;
  border-radius: 5px;

  transition: background 200ms linear;

  &:hover {
    background: black;
    color: white;
  }
`;

export const InputHex = styled.input`
  width: 100px;
  height: 30px;
  padding: 0 0.5rem;

  &::placeholder {
    color: #c4c4c4;
    opacity: 0.6;
  }
`;

export const BoxColor = styled.div<BoxColorProps>`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${({ theme, color }) =>
    color == ""
      ? "conic-gradient(red, yellow, lime, aqua, blue, magenta, red)"
      : "red"};
  cursor: not-allowed;
  border: 1px solid black;
`;

export const ButtonBack = styled.button`
  width: 120px;
  height: 40px;
  background: white;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
`;

export const VStack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonGoBack = styled.button`
  width: 100%;
  height: 40px;
  color: white;
  border-radius: 5px;

  background-color: ${({ theme }) => theme.colors.primary};
`;
