import React from "react";
import PagesButton from "../../../../../shared/components/PageButton";
import UxDocLogoComponent from "../../../../../shared/components/UxDocLogo";
import { Aside, AsideFooter, AsideMain, AsideTop } from "./styles";
import OrganizationLogo from "../../../../../shared/components/OrganizationLogo";
import { useAuth } from "../../../../../hooks/auth";
import SignIcon from "../../assets/signature-icon.svg";
import SignIconInactive from "../../assets/signature-icon-inactive.svg";
import { useSign } from "../../../../../hooks/sign";
import iconTeamsInactive from "../../assets/team-icon-inactive.svg";
import { useLocation } from "react-router-dom";
import OutoutIcon from "../../../assets/output.svg";
import OutoutIconActive from "../../../assets/output-active.svg";
import IconChat from "../../assets/chat.svg";
import IconChatActive from "../../assets/chatActive.svg";
import { useMetric } from "../../../../../hooks/metric";

interface MountIconsProps {
  NewProjectIcon: {
    dPath: string;
    viewBox: string;
  };
  HomeIcon: {
    dPath: string;
    viewBox: string;
  };
  MyProjectsIcon: {
    dPath: string;
    viewBox: string;
  };
  IconTutorial: {
    dPath: string;
    viewBox: string;
  };
  IconLibrary: {
    dPath: string;
    viewBox: string;
  };
  IconShared: {
    dPath: string;
    viewBox: string;
  };
  IconAutomation: {
    dPath: string;
    viewBox: string;
  };

  IconCourses: {
    dPath: string;
    viewBox: string;
  };
  ExitIcon: {
    dPath: string;
    viewBox: string;
  };
  TeamsIcon: {
    dPath: string;
    viewBox: string;
  };
  SignatureIcon: {
    dPath: string;
    viewBox: string;
  };
  OneClickIcon: {
    dPath: string;
    viewBox: string;
  };
  HelpIcon: {
    dPath: string;
    viewBox: string;
  };
  ApprovalFlowIcon: {
    dPath: string;
    viewBox: string;
  };
}

interface AsidebarDashProps {
  permissionType: "editor" | "normal" | "loading";
  setActiveButton: React.Dispatch<React.SetStateAction<string>>;
  activeButton: string;
  MountIcons: MountIconsProps;
  signOut: () => void;
  isIntentBuyModal?: boolean;
  setIsIntentBuyModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AsidebarDash: React.FC<AsidebarDashProps> = ({
  permissionType,
  setActiveButton,
  activeButton,
  MountIcons,
  signOut,
}) => {
  const location = useLocation();
  const { data } = useAuth();
  const { handleIAMenu } = useMetric()

  const screenNow = location.pathname.split("/")[1];

  return (
    <Aside>
      <AsideTop>
        {data.organization ? <OrganizationLogo /> : <UxDocLogoComponent />}
      </AsideTop>

      <AsideMain>
        <PagesButton
          url="/"
          style={
            permissionType === "editor"
              ? {
                  cursor: "not-allowed",
                  opacity: "0.6",
                }
              : {}
          }
          onMouseEnter={() => {
            setActiveButton("CreateNewProject");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "CreateNewProject"}
          isActiveClick={screenNow === "" || screenNow === "create-projects"}
          buttonLabel="Criar novo projeto"
          dPath={MountIcons.NewProjectIcon.dPath}
          viewBox={MountIcons.NewProjectIcon.viewBox}
          iconWidth={20}
          iconHeight={20}
          classNameIcon="icon"
        />

        <PagesButton
          url="projects"
          onMouseEnter={() => {
            setActiveButton("MyProjects");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "MyProjects"}
          isActiveClick={screenNow === "projects"}
          buttonLabel="Meus projetos"
          dPath={MountIcons.MyProjectsIcon.dPath}
          viewBox={MountIcons.MyProjectsIcon.viewBox}
          iconWidth={22}
          iconHeight={22}
          classNameIcon="icon"
        />

        <PagesButton
          url="library"
          style={
            permissionType === "editor"
              ? {
                  cursor: "not-allowed",
                  opacity: "0.6",
                }
              : {}
          }
          onMouseEnter={() => {
            setActiveButton("MyClausules");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "MyClausules" ? true : false}
          isActiveClick={screenNow === "library"}
          buttonLabel="Biblioteca de texto"
          dPath={MountIcons.IconLibrary.dPath}
          viewBox={MountIcons.IconLibrary.viewBox}
          iconWidth={22}
          iconHeight={22}
          classNameIcon="icon"
        />

        <PagesButton
          url="shareds"
          style={
            permissionType === "editor"
              ? {
                  cursor: "not-allowed",
                  opacity: 0.6,
                }
              : {}
          }
          onMouseEnter={() => {
            setActiveButton("MySharedDocuments");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "MySharedDocuments" ? true : false}
          isActiveClick={screenNow === "shareds"}
          buttonLabel="Compartilhados comigo"
          dPath={MountIcons.IconShared.dPath}
          viewBox={MountIcons.IconShared.viewBox}
          iconWidth={22}
          iconHeight={22}
          classNameIcon="icon"
        />

        <PagesButton
          url="automations"
          style={
            permissionType === "editor"
              ? {
                  cursor: "not-allowed",
                  opacity: 0.6,
                }
              : {}
          }
          onMouseEnter={() => {
            setActiveButton("MyAutomations");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "MyAutomations"}
          isActiveClick={screenNow === "automations"}
          buttonLabel="Versões automatizadas"
          dPath={MountIcons.IconAutomation.dPath}
          viewBox={MountIcons.IconAutomation.viewBox}
          iconWidth={22}
          iconHeight={22}
          classNameIcon="icon"
        />

        <PagesButton
          url="teams"
          style={
            permissionType === "editor"
              ? {
                  cursor: "not-allowed",
                  opacity: 0.6,
                }
              : {}
          }
          onMouseEnter={() => {
            setActiveButton("Teams");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "Teams"}
          isActiveClick={screenNow === "teams" || screenNow === "view-team"}
          buttonLabel="Equipe"
          dPath={MountIcons.TeamsIcon.dPath}
          viewBox={MountIcons.TeamsIcon.viewBox}
          srcActive={iconTeamsInactive}
          iconWidth={25}
          iconHeight={25}
          classNameIcon="icon"
        />

        <PagesButton
          url="signatures"
          onMouseEnter={() => {
            setActiveButton("Signature");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "Signature"}
          dPath=""
          src={SignIcon}
          isActiveClick={screenNow === "signatures"}
          buttonLabel="Assinatura"
          iconWidth={25}
          iconHeight={25}
          classNameIcon="icon"
          srcActive={SignIconInactive}
        />

        <PagesButton
          url="IA"
          style={
            permissionType === "editor"
              ? {
                  cursor: "not-allowed",
                  opacity: 0.6,
                }
              : {}
          }
          onMouseEnter={() => {
            setActiveButton("OneClick");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "OneClick"}
          isActiveClick={screenNow === "IA"}
          buttonLabel="IA"
          dPath={MountIcons.OneClickIcon.dPath}
          viewBox={MountIcons.OneClickIcon.viewBox}
          iconWidth={22}
          iconHeight={22}
          classNameIcon="icon"
          haveIconNew={false}
          activeButton={screenNow}
          isBeta={true}
          onClick={() => handleIAMenu({
            title: 'Clicou no menu IA',
            user_id: data.user.id
          })}
        />

        <PagesButton
          url="approvalflow"
          style={
            permissionType === "editor"
              ? {
                  cursor: "not-allowed",
                  opacity: 0.6
                }
              : {}
          }
          onMouseEnter={() => {
            setActiveButton("ApprovalFlow");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "ApprovalFlow"}
          isActiveClick={screenNow === "approvalflow"}
          buttonLabel="Fluxo de aprovação"
          dPath={MountIcons.ApprovalFlowIcon.dPath}
          viewBox={MountIcons.ApprovalFlowIcon.viewBox}
          iconWidth={22}
          iconHeight={22}
          classNameIcon="icon"
          haveIconNew={false}
          activeButton={screenNow}
          isBeta={false}
        />
      </AsideMain>

      <AsideFooter>
        <PagesButton
          target="_blank"
          className="help-button"
          onClick={() => {
            window.open(
              "https://forms.monday.com/forms/f8c891ad8f4f16fc5e0f653d925e9ff8?r=use1",
              "_blank"
            );
          }}
          onMouseEnter={() => {
            setActiveButton("ContactUs");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "ContactUs"}
          buttonLabel="Fale conosco"
          src={IconChatActive}
          srcActive={IconChat}
          iconWidth={22}
          iconHeight={22}
          classNameIcon="icon"
          dPath=""
        />

        <PagesButton
          target="_blank"
          className="help-button"
          onClick={() => {
            window.open("https://uxdoc.com.br/ajuda/", "_blank");
          }}
          onMouseEnter={() => {
            setActiveButton("help");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "help" ? true : false}
          buttonLabel="Ajuda"
          dPath={MountIcons.HelpIcon.dPath}
          viewBox={MountIcons.HelpIcon.viewBox}
          iconWidth={22}
          iconHeight={22}
          classNameIcon="icon"
        />

        <PagesButton
          target="_blank"
          onClick={() => {
            if (permissionType === "editor") return;
            window.open(
              `https://www.youtube.com/channel/UCu6G9p_xJSkzjoZU0d9rysQ`,
              "_blank"
            );
          }}
          onMouseEnter={() => {
            if (permissionType === "normal") {
              setActiveButton("courses");
            }
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          style={
            permissionType === "editor"
              ? {
                  cursor: "not-allowed",
                  opacity: 0.6,
                }
              : {}
          }
          onMouseLeave={() => {}}
          isActive={activeButton === "courses" ? true : false}
          buttonLabel="Tutorial"
          dPath={MountIcons.IconTutorial.dPath}
          viewBox={MountIcons.IconTutorial.viewBox}
          iconWidth={22}
          iconHeight={22}
          classNameIcon="icon"
        />

        <PagesButton
          onClick={() => {
            signOut();
          }}
          onMouseEnter={() => {
            setActiveButton("Logout");
          }}
          onMouseOut={() => {
            setActiveButton("");
          }}
          onMouseLeave={() => {}}
          isActive={activeButton === "Logout"}
          buttonLabel="Sair"
          srcActive={OutoutIcon}
          src={OutoutIconActive}
          iconWidth={22}
          iconHeight={22}
          classNameIcon="icon"
          dPath=""
        />
      </AsideFooter>
    </Aside>
  );
};

export default AsidebarDash;
