import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 24px;
  height: 24px;
  fill: currentColor;
`;

type Props = {
  color?: string;
};

const TrashIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 24 24" color={color || "#999C9F"}>
    <path
      d="M5.91667 0.615234V1.72635H0.361115V3.94857H1.47223V18.393C1.47223 18.9824 1.70635 19.5476 2.1231 19.9644C2.53985 20.3811 3.10508 20.6152 3.69445 20.6152H14.8056C15.3949 20.6152 15.9602 20.3811 16.3769 19.9644C16.7937 19.5476 17.0278 18.9824 17.0278 18.393V3.94857H18.1389V1.72635H12.5833V0.615234H5.91667ZM3.69445 3.94857H14.8056V18.393H3.69445V3.94857ZM5.91667 6.17079V16.1708H8.13889V6.17079H5.91667ZM10.3611 6.17079V16.1708H12.5833V6.17079H10.3611Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default TrashIcon;
