import React from "react";
import { useTheme } from "../../../../../../hooks/theme";

interface BaseIconProps {
  isActive?: boolean;
  className?: string;
}

export default function ListIcon({ isActive, className }: BaseIconProps) {
  const { theme } = useTheme();

  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 40 40"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6667 8.33333H35V11.6667H11.6667V8.33333ZM11.6667 21.6667V18.3333H35V21.6667H11.6667ZM6.66669 7.5C7.32973 7.5 7.96561 7.76339 8.43445 8.23223C8.9033 8.70107 9.16669 9.33696 9.16669 10C9.16669 10.663 8.9033 11.2989 8.43445 11.7678C7.96561 12.2366 7.32973 12.5 6.66669 12.5C6.00365 12.5 5.36776 12.2366 4.89892 11.7678C4.43008 11.2989 4.16669 10.663 4.16669 10C4.16669 9.33696 4.43008 8.70107 4.89892 8.23223C5.36776 7.76339 6.00365 7.5 6.66669 7.5ZM6.66669 17.5C7.32973 17.5 7.96561 17.7634 8.43445 18.2322C8.9033 18.7011 9.16669 19.337 9.16669 20C9.16669 20.663 8.9033 21.2989 8.43445 21.7678C7.96561 22.2366 7.32973 22.5 6.66669 22.5C6.00365 22.5 5.36776 22.2366 4.89892 21.7678C4.43008 21.2989 4.16669 20.663 4.16669 20C4.16669 19.337 4.43008 18.7011 4.89892 18.2322C5.36776 17.7634 6.00365 17.5 6.66669 17.5ZM11.6667 31.6667V28.3333H35V31.6667H11.6667ZM6.66669 27.5C7.32973 27.5 7.96561 27.7634 8.43445 28.2322C8.9033 28.7011 9.16669 29.337 9.16669 30C9.16669 30.663 8.9033 31.2989 8.43445 31.7678C7.96561 32.2366 7.32973 32.5 6.66669 32.5C6.00365 32.5 5.36776 32.2366 4.89892 31.7678C4.43008 31.2989 4.16669 30.663 4.16669 30C4.16669 29.337 4.43008 28.7011 4.89892 28.2322C5.36776 27.7634 6.00365 27.5 6.66669 27.5Z"
        fill={isActive ? theme.colors.primary : "#000"}
      />
    </svg>
  );
}
