import styled from "styled-components";

export const FullContent = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 20px 20px 20px;

  display: flex;
  overflow-y: auto;
  flex-direction: column;
  background-color: transparent;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .show-neither-data {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    z-index: -1;

    span {
      font-size: 1.4rem;
      color: ${({ theme }) => theme.colors.gray300};
      transform: translateY(-140px);
    }
  }

  @media (max-width: 1600px) {
    gap: 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 20px 0px;
  figure {
    display: flex;
    align-items: center;

    svg {
      height: 20px;
      width: auto;
      color: ${({ theme }) => theme.colors.black50};
    }
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 700;
  font-size: 16px;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  justify-content: space-between;
  margin-top: 8px;
`;
export const SearchInput = styled.input`
  width: 100%;
  padding: 6px 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray600};
  width: 250px;
`;
export const SearchButton = styled.button`
  padding: 2px 4px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  background-color: transparent;
`;

export const SearchLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const CreateNewButton = styled.button`
  padding: 5px 16px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  cursor: pointer;
  width: 200px;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
`;

export const IconCloseWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 16px;
  color: #999c9f;
`;
