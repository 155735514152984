import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 350px;
  min-width: 700px;
`;

export const StepsContainer = styled.div`
  border: 1px solid #999c9f;
  flex: 1;
  margin-top: 20px;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  max-height: 220px;
`;

export const Label1 = styled.span`
  font-size: 14px;
  color: #343a40;
`;
export const Label2 = styled.span`
  font-size: 12px;
  color: #343a40;
`;

export const SelectContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

export const OutlinedButton = styled.button`
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
  }
`;

export const InputDate = styled.div`
  width: 100%;

  input {
    width: 209.887px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    height: 22px;
    width: 24px;
    opacity: 0.5;
  }
`;
export const InputBox = styled.div`
  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }

  input {
    width: 100%;
    max-height: 40px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.black50};
    margin-top: 5px;

    @media (min-width: 1920px) {
      height: 43px;
      max-height: 43px;
    }

    :focus {
      border: 1px solid ${({ theme }) => theme.colors.focus};
    }

    ::placeholder {
      color: #d6d8d9;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 55px 16px 16px;
`;

export const TitleLabel = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #343a40;
`;

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #343a40;
  margin-top: 12px;
  input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999c9f;
    border-radius: 3px;
    cursor: pointer;
    &:checked {
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      &::after {
        content: "";
        display: block;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: relative;
        top: 2px;
        left: 5px;
      }
    }
  }
`;

export const GhostInput = styled.input`
  width: 100%;
  max-height: 30px;
  padding-bottom: 4px;
  border: none;
  border-bottom: 1px solid #e2e2e2;
  background-color: transparent;
  color: #514f4f;
  font-weight: 700;
  font-size: 16px;

  @media (min-width: 1920px) {
    height: 43px;
    max-height: 43px;
  }

  :focus {
    border: none;
    border-bottom: 1px solid #e2e2e2;
  }

  ::placeholder {
    color: #d6d8d9;
  }
`;
