import { useRef, useState } from "react";
import axios from "axios";
import { ConversionResult } from "../types/conversionResult";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useMetric } from "../../../hooks/metric";
import { useMainHook } from "../../../hooks/main";

const useDocumentUpload = () => {
  const { handleDocumentImportedOneClickAction } = useMetric();
  const { documentName } = useMainHook();

  const [isLoading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [data, setData] = useState<ConversionResult>(null);
  const [open, setOpen] = useState(false);

  const ONE_CLICK_BASE_URL = "https://apioneclick.uxdoc.com.br";

  const uploadDocument = () => {
    setOpen(false);
    setLoading(true);
    const file = fileInputRef.current.files[0];
    let fileName = file.name;

    let lastDotIndex = fileName.lastIndexOf(".");
    let documentNameWithoutExtension =
      lastDotIndex > 0 ? fileName.substring(0, lastDotIndex) : fileName;
    documentName.current = documentNameWithoutExtension;

    const extension = file.name.split(".").pop();
    if (extension !== "docx") {
      toast.error("O arquivo precisa ser um .docx");
      setLoading(false);
      return;
    }

    fileInputRef.current.value = "";

    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const result = fileReader.result;
        const base64Content =
          typeof result === "string"
            ? result.split(",")[1]
            : arrayBufferToBase64(result);
        const requestBody = {
          file: {
            name: file.name,
            content: base64Content
          }
        };

        axios
          .post(`${ONE_CLICK_BASE_URL}/documents`, requestBody)
          .then(response => {
            startConversionStatusCheck(response.data.protocolId);

            //document import action register function OneClick
            handleDocumentImportedOneClickAction({
              protocol_id: response.data.protocolId
            });
          })
          .catch(error => {
            console.error("Conversion failed:", error);
          });
      };

      fileReader.readAsDataURL(file);
    }
  };

  const arrayBufferToBase64 = buffer => {
    const binary = String.fromCharCode.apply(null, new Uint8Array(buffer));
    return btoa(binary);
  };

  const startConversionStatusCheck = protocolId => {
    let retryCount = 0;
    const maxRetries = 60;

    const intervalId = setInterval(() => {
      axios
        .get(`${ONE_CLICK_BASE_URL}/documents/${protocolId}`)
        .then(response => {
          const result = response.data;
          const { status, message } = result;

          if (status === "SUCCESS") {
            clearInterval(intervalId);
            const responseData: ConversionResult = response.data;
            responseData.data.paragraphs.forEach(paragraph => {
              paragraph.id = uuidv4();
            });

            setData(responseData);
            setLoading(false);
            setOpen(true);
          } else if (status === "ERROR") {
            clearInterval(intervalId);
            console.error("Conversion failed:", message);
            toast.error("Erro ao tentar converter o documento.");
            setLoading(false);
          } else if (retryCount >= maxRetries) {
            clearInterval(intervalId);
            console.error("Maximum retries reached. Conversion not completed.");
            toast.error("Erro ao tentar converter o documento.");
          } else {
            retryCount++;
          }
        })
        .catch(error => {
          console.error("Error checking conversion status:", error.response);

          if (retryCount >= maxRetries) {
            clearInterval(intervalId);
            console.error("Maximum retries reached. Conversion not completed.");
            toast.error("Erro ao tentar converter o documento.");
            setLoading(false);
          } else {
            retryCount++;
          }
        });
    }, 5000); // 5 seconds interval
  };

  return {
    isLoading,
    data,
    open,
    setOpen,
    setLoading,
    fileInputRef,
    uploadDocument
  };
};

export default useDocumentUpload;
