import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

export const DropDownContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: transparent;
`;

export const Container = styled.div`
  display: flex;
  max-width: 140px;
  width: 100%;
  margin-right: 0.64rem;
  height: 35px;
  background: transparent;
  justify-content: center;
  align-items: center;

  @media (max-width: 1600px) {
    width: 170px;
  }

  @media (max-width: 1366px) {
    width: 110px;
    margin: 0 1rem;
  }
`;

export const DropDownHeader = styled.div<{ Open: boolean }>`
  display: flex;
  justify-content: flex-start;
  font-weight: normal;
  height: 100%;
  margin-top: -3.8rem;
  font-size: 1.6rem;
  color: ${({ theme }: Theme) => theme.colors.black};
  background: transparent;
  &:hover {
    cursor: pointer;
  }

  & img {
    display: flex;
    margin: auto 0 0 0;
  }

  & input {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid black;
  }

  & input:checked {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    accent-color: ${({ theme }) => theme.colors.background};
  }

  .effect-arrow {
    transition: all 0.1s ease-in;
    transform: ${props => (props.Open ? "rotateZ(-180deg)" : "")};
  }

  @media (max-width: 1600px) {
    font-size: 1.5rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const DropDownListContainer = styled.div`
  width: 10px;
  position: relative;
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin-left: -17rem;
  width: 200px;
  margin-top: 0rem;
  padding-left: 1.6em;
  background: black;
  position: relative;
  box-shadow: 1px 3px 41px -15px rgba(0, 0, 0, 0.31);
  -webkit-box-shadow: 1px 3px 41px -15px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 1px 3px 41px -15px rgba(0, 0, 0, 0.21);
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Roboto";
  color: white;
  font-size: 1.5rem;
  font-weight: 500;

  &::after {
    content: "";
    position: absolute;
    left: 82%;
    top: -25%;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    border-bottom: 12px solid black;
  }
  &:hover {
    cursor: pointer;
  }

  &:first-child {
    padding-top: 0.8em;
  }

  @media (max-width: 1600px) {
    font-size: 1.4rem;
    font-weight: 500;
  }

  @media (max-width: 1366px) {
    font-size: 1.3rem;
    font-weight: 500;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.8em;
  width: 100%;
`;
