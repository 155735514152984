import styled from "styled-components";

export const Container = styled.div<{ isFocus: boolean, w_100?: boolean; }>`
  border: 1px solid
    ${({ theme, isFocus }) =>
    isFocus ? theme.colors.focus : theme.colors.black50};
  width: ${({ w_100 }) => w_100 ? '100%' : '300px'};
  height: 40px;
  border-radius: 5px;
  padding-inline: 10px 5px;

  display: flex;
  align-items: center;
  gap: 8px;

  position: relative;

  :hover {
    border: 1px solid
      ${({ theme, isFocus }) =>
    isFocus ? theme.colors.focus : theme.colors.black100};
  }

  input {
    border: none;
    height: 100%;
    width: ${({ w_100 }) => w_100 ? '100%' : '73%'};
    font-family: "Ubuntu";
  }

  div {
    display: flex;
    align-items: center;
  }

  button {
    width: 40px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    :hover {
      background-color: #f5f5f5;
      border-radius: 5px;
    }
  }
`;
