/*
this button is relative undo action of user, 
when user clicked on this button then if have
some modification then buttons will be avaiable
to user could be to back action in your document

NOTE: when editor text is open, then we blocked this feature
*/

import React from "react";
import { PagesProps } from "../../../../../../dtos/PagesProps";
import { Button, IconUndo } from "./styles";
import Tooltip from "../../../Tooltip";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../shared/utils/MountIcons";

// import { Container } from './styles';
//set up interfaces
interface IHistory {
  currentStep: number;
  step: PagesProps[][];
}

interface ButtonUndoProps {
  isEditing: "open" | "closed" | "blocked";
  handleUndo: () => void;
  history: IHistory;
  undoIconTransparent: string;
  undoIcon: string;
  pendingSave?: React.MutableRefObject<boolean>;
}

const ButtonUndo: React.FC<ButtonUndoProps> = ({
  isEditing,
  handleUndo,
  history,
  undoIconTransparent,
  undoIcon,
  pendingSave
}) => {
  return (
    <Tooltip
      title="Desfazer"
      description="Desfaça alguma ação que você fez no seu projeto."
      placement="bottom-start"
    >
      <Button
        onClick={() => {
          if (isEditing === "open") return;

          // pendingSave.current = true;
          handleUndo();
        }}
      >
        <IconUndo>
          <PersonalizedIcon
            dPath={MountIcons.IconUndo.dPath}
            viewBox={MountIcons.IconUndo.viewBox}
            activeColor="#FFF"
            inactivatedColor="rgba(255, 255, 255, 0.253)"
            isActive={
              history.currentStep === 0
                ? false
                : isEditing === "open"
                ? false
                : true
            }
          />
        </IconUndo>
      </Button>
    </Tooltip>
  );
};

export default ButtonUndo;
