import { useAuth } from "../../../hooks/auth";
import { LogoBox } from "./styles";

const OrganizationLogo = () => {
  const { data } = useAuth();

  return (
    <LogoBox>
      <img src={data?.organization?.logo_url} alt="Logo" />
    </LogoBox>
  );
};

export default OrganizationLogo;
