const ColorsPallete = [
  { active: false, hexadecimal: "#343A40" },
  { active: false, hexadecimal: "#E60000" },
  { active: false, hexadecimal: "#FF9900" },
  { active: false, hexadecimal: "#FFFF00" },
  { active: false, hexadecimal: "#008A00" },
  { active: false, hexadecimal: "#0066CC" },
  { active: false, hexadecimal: "#9933FF" },
  { active: false, hexadecimal: "#FFFFFF" },
  { active: false, hexadecimal: "#FACCCC" },
  { active: false, hexadecimal: "#FFEBCC" },
  { active: false, hexadecimal: "#FFFFCC" },
  { active: false, hexadecimal: "#CCE8CC" },
  { active: false, hexadecimal: "#CCE0F5" },
  { active: false, hexadecimal: "#EBD6FF" },
  { active: false, hexadecimal: "#BBBBBB" },
  { active: false, hexadecimal: "#F06666" },
  { active: false, hexadecimal: "#FFC266" },
  { active: false, hexadecimal: "#FFFF66" },
  { active: false, hexadecimal: "#66B966" },
  { active: false, hexadecimal: "#66A3E0" },
  { active: false, hexadecimal: "#C285FF" },
  { active: false, hexadecimal: "#888888" },
  { active: false, hexadecimal: "#A10000" },
  { active: false, hexadecimal: "#B26B00" },
  { active: false, hexadecimal: "#B2B200" },
  { active: false, hexadecimal: "#006100" },
  { active: false, hexadecimal: "#0047B2" },
  { active: false, hexadecimal: "#6B24B2" },
  { active: false, hexadecimal: "#444444" },
  { active: false, hexadecimal: "#5C0000" },
  { active: false, hexadecimal: "#663D00" },
  { active: false, hexadecimal: "#666600" },
  { active: false, hexadecimal: "#003700" },
  { active: false, hexadecimal: "#002966" },
  { active: false, hexadecimal: "#3D1466" }
];

export { ColorsPallete };
