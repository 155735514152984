import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Header = styled.header`
  width: 100%;
  height: 40px;
  background-color: #fff;
  border-bottom: 1px solid #d6d8d9;
  border-left: 1px solid #d6d8d9;

  position: sticky;
  top: 0;
  z-index: 1;

  display: flex;
`;

export const Button = styled.button<{ isActive: boolean }>`
  width: 50%;
  background-color: #fff;

  font-size: 14px;
  font-weight: 500;
  line-height: 100%;

  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary : "#676B70"};

  :hover {
    background-color: #ebebec;
  }
`;

export const BoxComments = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding-inline: 10px;
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
`;
