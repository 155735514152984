import { useState } from "react";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../shared/utils/MountIcons";
import { Container, ButtonInitial, Modal } from "./styles";
import { Link } from "react-router-dom";

interface ISelectCategoryProps {
  category: string;
  page?: string;
  screen?: string;
}

const SelectCategory = ({ category, screen }: ISelectCategoryProps) => {
  const [modal, setModal] = useState(false);

  return (
    <Container onMouseLeave={() => setModal(false)}>
      <ButtonInitial isOpen={modal} onClick={() => setModal(!modal)}>
        {category === "all" ? (
          <div>
            <PersonalizedIcon
              dPath={MountIcons.MyClausesIcon.dPath}
              viewBox={MountIcons.MyClausesIcon.viewBox}
              inactivatedColor="#676B70"
              className="icon-clause"
            />
            Todos
          </div>
        ) : (
          <div>
            <PersonalizedIcon
              dPath={MountIcons.IconStars.dPath}
              viewBox={MountIcons.IconStars.viewBox}
              inactivatedColor="#676B70"
            />
            Favoritos
          </div>
        )}
        <PersonalizedIcon
          dPath={MountIcons.IconArrowBottom.dPath}
          viewBox={MountIcons.IconArrowBottom.viewBox}
          inactivatedColor="#676B70"
        />
      </ButtonInitial>
      {modal && (
        <Modal>
          <Link to={`/${screen}/1`} onClick={() => setModal(false)}>
            <PersonalizedIcon
              dPath={MountIcons.MyClausesIcon.dPath}
              viewBox={MountIcons.MyClausesIcon.viewBox}
              inactivatedColor="#676B70"
              className="icon-clause"
            />
            Todos
          </Link>
          <Link to={`/${screen}/1/favorite`} onClick={() => setModal(false)}>
            <PersonalizedIcon
              dPath={MountIcons.IconStars.dPath}
              viewBox={MountIcons.IconStars.viewBox}
              inactivatedColor="#676B70"
            />
            Favoritos
          </Link>
        </Modal>
      )}
    </Container>
  );
};

export default SelectCategory;
