import api from "../../../services/api";

interface CategoryProps {
  created_at: string;
  creator_id: string;
  id: string;
  last_editor_id: string;
  title: string;
  updated_at: string;
}

interface ITemplatesData {
  bits_templates_category?: string;
  created_at?: string;
  creator_id?: string;
  description?: string;
  id?: string;
  key_words?: string;
  last_editor_id?: string;
  template?: any;
  thumbnail?: string;
  thumbnail_url?: string;
  title?: string;
  updated_at?: string;
  usage_counter?: number;
}

interface TemplatesCategoriesProps {
  id: string;
  category: string;
  templates?: ITemplatesData[];
}

interface TemplateStoreProps {
  dataCategoriesTemplates: CategoryProps[];
  setLoadingTemplates: React.Dispatch<React.SetStateAction<boolean>>;
  templatesPerCategory: TemplatesCategoriesProps[];
  setTemplateByCategories: React.Dispatch<
    React.SetStateAction<TemplatesCategoriesProps[]>
  >;
  hasNewPage: number;
  currentPage: number;
  setEndPages: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface LoadTemplatesCategoriesProps {
  currentPage: number;
  setHasNewPage: (value: React.SetStateAction<number>) => void;
  setDataCategoriesTemplates: React.Dispatch<
    React.SetStateAction<CategoryProps[]>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ObserverInfinityScrollProps {
  loading: boolean;
  templatesByCategories: TemplatesCategoriesProps[];
  hasNewPage: number;
  currentPage: number;
  selectedTab:
    | "none"
    | "automatizing"
    | "clauses"
    | "groupElements"
    | "elements"
    | "icons"
    | "upload"
    | "comments"
    | "templates"
    | "controlOfVersions";
  setCurrentPage: (value: React.SetStateAction<number>) => void;
}

const templateStore = async ({
  dataCategoriesTemplates,
  setLoadingTemplates,
  templatesPerCategory,
  setTemplateByCategories,
  hasNewPage,
  currentPage,
  setEndPages,
  setLoading
}: TemplateStoreProps) => {
  if (dataCategoriesTemplates.length > 0) {
    for (const value of dataCategoriesTemplates) {
      try {
        setLoadingTemplates(true);
        const dataOfTemplates = await api.get(
          `bits-templates/list-all-category/${value.id}`
        );

        templatesPerCategory.push({
          category: value.title,
          id: value.id,
          templates: dataOfTemplates.data
        });

        setLoadingTemplates(false);
      } catch (err) {
        console.log(err, "error when fetch id categories");
      }
    }

    setTemplateByCategories(state => {
      let cloneState = [...state];
      cloneState = templatesPerCategory;
      return cloneState;
    });

    if (hasNewPage === currentPage) {
      setEndPages(true);
    }

    setLoading(false);
  }
};

const loadTemplatesCategories = async ({
  currentPage,
  setHasNewPage,
  setDataCategoriesTemplates,
  setLoading
}: LoadTemplatesCategoriesProps) => {
  try {
    const responseCategories = await api.get("bits-template-categories", {
      params: {
        pagination: currentPage
      }
    });

    setHasNewPage(responseCategories.data.pagination);

    setDataCategoriesTemplates(prevState => [
      ...prevState,
      ...responseCategories.data.dataArray
    ]);
  } catch (err) {
    console.log(err.response, "error when get categories");
    setLoading(false);
  }
};

const observerInfinityScroll = ({
  loading,
  templatesByCategories,
  hasNewPage,
  currentPage,
  selectedTab,
  setCurrentPage
}: ObserverInfinityScrollProps) => {
  if (
    !loading &&
    templatesByCategories.length > 0 &&
    hasNewPage !== currentPage &&
    selectedTab === "templates"
  ) {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry?.isIntersecting)) {
        setCurrentPage(prevState => (prevState += 1));
      }
    });

    let mySelector = document?.querySelector("#sentinela-templates");

    if (mySelector) {
      intersectionObserver?.observe(
        document?.querySelector("#sentinela-templates")
      );

      return () => intersectionObserver?.disconnect();
    }
  }
};

export { templateStore, loadTemplatesCategories, observerInfinityScroll };
