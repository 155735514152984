import React, { useState } from "react";
import { useHeaderEditor } from "../../../../editor/hooks/headerEditor";

import { Avatar, AvatarLetter, TextAvatar } from "./styles";

// const images = [
//   "https://picsum.photos/300/300",
//   "https://picsum.photos/500/500",
//   "https://picsum.photos/600/600",
//   "https://picsum.photos/700/700",
//   "https://picsum.photos/800/800",
//   "https://picsum.photos/900/900"
// ];

interface MultiplesAvatarLetterProps {
  src?: string;
  text?: string;
  setText?: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  color?: string;
  length?: number;
}

const AvatarLengthShared: React.FC<MultiplesAvatarLetterProps> = ({
  src,
  // text,
  // setText,
  className,
  // color,
  length,
}) => {
  return (
    <>
      {src === "" ? (
        <AvatarLetter className={className} color={"#BBBBBB"}>
          <TextAvatar> {length - 3}+ </TextAvatar>
        </AvatarLetter>
      ) : (
        <Avatar>
          <img className={className} src={src} alt="" />
        </Avatar>
      )}
    </>
  );
};

export default AvatarLengthShared;

