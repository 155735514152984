/*
This code file will contain all the main component of the canvas library (Konva),
so here we define the Stage of each page, using a function to store dynamic refs
in an array and store each page with a Konva Stage to render objects, in addition
to the Layer that is also configured here, but we still use only one layer

We have lines guides to design and to help align objects, and rect to
selectable many objects (multiple selection) 
*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Layer,
  Line,
  Rect,
  Stage,
  Transformer,
  Text,
  Image,
} from "react-konva";
import { PagesProps } from "../../../../../dtos/PagesProps";
import { useMainHook } from "../../../../../hooks/main";
import { useDiff } from "../../../hooks/diffDocuments";
import { usePagesEditor } from "../../../hooks/pagesEditor";
import { useSelection } from "../../../hooks/selection";
import { useStageTools } from "../../../hooks/stageTools";
import { onDragEnd, onDragMove } from "../../../utils/guidesLine";
import { useTextsEdition } from "../../../hooks/textsEdition";
import { useWorkspaceEditor } from "../../../hooks/workspaceEditor";
import LoadingCanvas from "../LoadingCanvas";
import Rendering from "../Rendering";
import { KonvaEventObject } from "konva/lib/Node";
import Konva from "konva";
import { IRenderedObject } from "../../../../../dtos/RenderedObject";
import { cloneDeep } from "lodash";
import api from "../../../../../services/api";
import CommentShape from "../CommentShape";
import { useComments } from "../../../hooks/comments";
import { useAuth } from "../../../../../hooks/auth";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { useHeaderEditor } from "../../../hooks/headerEditor";
import { Vector2d } from "konva/lib/types";
import { useAutomaticSaving } from "../../../hooks/automaticSaving";
import { toast } from "react-toastify";
import { imagePropsApi } from "../../UploadImages";
import { Html } from "react-konva-utils";

//set up interfaces
interface SceneKonvaProps {
  item: PagesProps;
  index: number;
  isSecondUser: boolean;
}

//define dimensions of each page to attribuites to Konva Stage size
const widthCanvas: number = 596;
const heigthCanvas: number = 842;

const SceneKonva: React.FC<SceneKonvaProps> = ({
  item,
  index,
  isSecondUser = false,
}) => {
  const { isSavingAutomatic } = useHeaderEditor();

  //const Konva = window.Konva;
  //setting hooks to Konva page
  const {
    addToRefs,
    addToHistory,
    setObjectScreen,
    nameOfCurrentUser,
    canvasIsLoading,
    hasGroupShared,
    autoNumber,
    setAutoNumber,
    stageRef,
    whichOptionForModal,
    handleOpenModalDifferencesDecline,
    handleOpenModalDifferences,
    pendingSave,
    isDraggingObject,
    setIsDraggingObject,
  } = useMainHook();

  const {
    setStagePointerPosition,
    linespage,
    idTemplate,
    handleImagePaste,
    // autoNumber,
    // setAutoNumber
  } = usePagesEditor();

  const {
    blockCommandKeyboard,
    setBlockCommandKeyboard,
    disableFocusQuill,
    setDisableFocusQuill,
    shiftPressed,
    nodesShift,
    setNodesShift,
    setSelectedObjects,
  } = useSelection();
  const { checkDeselect } = useWorkspaceEditor();
  const [h_lines, setHlines] = useState([]);
  const [v_lines, setVlines] = useState([]);
  const { templateid, typetemplate } = useParams();

  const [pageObject, setPageObject] = useState(0);

  const {
    addToMultipleSelectionRef,
    currentMultipleSelection,
    setSelectedObject,
    selectedObject,
    transformerSelectedObject,
    multipleSelectionRefs,
    selectedObjects,
  } = useSelection();

  const { setIdPage, idPage } = usePagesEditor();
  const {
    quillRef,
    requestTextUpdate,
    textRich,
    isEditing,
    setIsEditing,
    blockingKeyboard,
    setBlockingKeyboard,
    quillRefComponent,
    hasBlockText,
    handleDblClick,
    rectangule,
    blockDblClick,
    setBlockDblClick,
    currentQuillFormats,
    setCurrentQuillFormats,
    selectionQuill,
    setSelectionQuill,
  } = useTextsEdition();

  const {
    templateStartedShared,
    setTemplateStartedShared,
    oldContentsState,
    setOldContentsState,
    newContentsState,
    setNewContentsState,
    selectionCursor,
    setSelectionCursor,
    findDiff,
    buttonRef,
    newDelta,
    setNewDelta,
    isEditingQuill,
    setIsEditingQuill,
    newChanges,
    setNewChanges,
    newContentsRef,
    oldContentsRef,
    getQuillCursorRef,
    isNewTextRef,
    isTypingQuillRef,
    whichUserEdited,
    interceptModifications,
    textIsChanged,
    blockRenderImage,
    blockBackspace,
    whichKeyPress,
    selectionFormatText,
  } = useDiff();

  const { data } = useAuth();
  // const shapeRef = useRef<Konva.Rect>(null);
  const { onMouseDown, onMouseMove, onMouseUp, currentPosition, selection } =
    useStageTools();

  const {
    isCommentsVisible,
    listCommentsCanvas,
    setListCommentsCanvas,
    commentValue,
    setCommentValue,
    handleMakeComment,
    setIsCommentsVisible,
    handleClickCommentCanvas,
    selectedIdComment,
  } = useComments();

  const handleOpenAcceptDiff = useCallback(() => {
    whichOptionForModal.current = "Accept";
    handleOpenModalDifferences("Open");
  }, [whichOptionForModal, handleOpenModalDifferences]);

  const handleOpenDeclineDiff = useCallback(() => {
    handleOpenModalDifferencesDecline("Open");
  }, [handleOpenModalDifferencesDecline]);

  const hasEmptyShiftNodes: boolean = nodesShift.length === 0;

  useEffect(() => {
    (async () => {
      try {
        const hasAutoNumber = await api.get(
          typetemplate === "team-template"
            ? `team-templates/list-specific/${idTemplate}`
            : `user-templates/${idTemplate}`
        );

        setAutoNumber((oldState) => {
          return {
            ...oldState,
            firstPage: hasAutoNumber.data.first_page_automatic_numbering,
            position: hasAutoNumber.data.automatic_numbering_position,
            isActive: hasAutoNumber.data.is_automatic_numbering_active,
          };
        });
      } catch (err) {
        console.error(err, "getTemplates");
      }
    })();
  }, [typetemplate]);

  //ref for selection (multiple selections) and layer of konva
  const selectionRectRef = useRef<Konva.Rect>(null);
  const layerRef = useRef<Konva.Layer>(null);

  const handleGroupElements = useCallback(
    (event?: KonvaEventObject<MouseEvent>) => {
      setSelectedObject(null);

      if (nodesShift.length < 1) {
        return;
      }

      const nodes = [];
      const findStage: Konva.Stage = stageRef.current?.find(
        (el) => el?.attrs?.id == idPage
      );
      const currentIndexTransformerActive = 0;
      nodesShift?.forEach((object) => {
        const result = findStage?.find(`#${object.attrs.id}`);

        if (result) {
          const shapeResult = result[0];
          nodes?.push(shapeResult);
        }
      });
      currentMultipleSelection.current =
        multipleSelectionRefs.current[currentIndexTransformerActive];
      currentMultipleSelection?.current?.setNodes(nodes);

      if (nodes) {
        setSelectedObjects(nodes);
        let colectObjectsName = currentMultipleSelection.current
          ?.nodes()
          ?.map((object) => {
            return object?.attrs?.object;
          });
        if (
          colectObjectsName?.includes("richTextImage") ||
          colectObjectsName?.includes("simpleLine")
        ) {
          currentMultipleSelection?.current?.anchorSize(0);
          currentMultipleSelection?.current?.rotateAnchorOffset(0);
        } else {
          currentMultipleSelection?.current?.anchorSize(10);
          currentMultipleSelection?.current?.rotateAnchorOffset(50);
        }
      }
    },
    [
      currentMultipleSelection,
      idPage,
      multipleSelectionRefs,
      nodesShift,
      setSelectedObject,
      setSelectedObjects,
      stageRef,
    ]
  );

  //when user click inside object in canvas,
  //then if any click outside object target
  //then remove select to object
  const handleClickCanvas = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      const myObjectSelected = selectedObject;

      if (shiftPressed && selectedObject) {
        setSelectedObject(null);
        setNodesShift((oldState) => {
          let cloneState = cloneDeep(oldState);
          const isDuplicate = cloneState.some(
            (object) => object.attrs.id === myObjectSelected.attrs.id
          );

          if (!isDuplicate) {
            cloneState.push(myObjectSelected);
          }
          return cloneState;
        });

        return;
      } else {
        if (isCommentsVisible.active) {
          if (isCommentsVisible.screen === "creating") {
            const position: Vector2d = event.currentTarget
              .getStage()
              .getPointerPosition();

            const newComment = {
              id: uuidv4(),
              page: String(idPage),
              avatar: data.user.avatar_url,
              isEditing: true,
              firstName: "Lucas",
              lastName: "Juste",
              position: {
                x: position.x,
                y: position.y - 40,
              },
              document: templateid,
            };

            setListCommentsCanvas((oldState) => {
              let cloneState = cloneDeep(oldState);
              let withoutPendentComment = cloneState.filter(
                (comment) => comment.isEditing !== true
              );
              withoutPendentComment.push(newComment);
              return withoutPendentComment;
            });

            return;
          } else {
            setIsCommentsVisible({
              active: false,
              screen: "none",
            });
          }
        }

        setNodesShift([]);
      }
    },
    [
      selectedObject,
      shiftPressed,
      setSelectedObject,
      setNodesShift,
      isCommentsVisible.active,
      isCommentsVisible.screen,
      idPage,
      data.user.avatar_url,
      templateid,
      setListCommentsCanvas,
      setIsCommentsVisible,
    ]
  );

  //only for each page add to dynamic refs
  //send that stage to array of stages
  const handleAddToRefs = useCallback(
    (event: Konva.Stage) => {
      if (!event?.attrs?.id) return;
      addToRefs(event);
    },
    [addToRefs]
  );

  //to ensure position of mouse and show things
  //such as menu context with features like copy and paste...
  // and few things more.
  const handleContextMenu = useCallback(
    (event: KonvaEventObject<PointerEvent>) => {
      const absPosition = event.target.getStage().getPointerPosition();
      setStagePointerPosition(absPosition);
    },
    [setStagePointerPosition]
  );

  // this callback already receives the index and the changes made to the object
  // that is being rendered, so we only modify the state punctually
  // on the page and on the right index (change state)
  const handleChangeObjectScreen = useCallback(
    (newAttrs: IRenderedObject, indexObject: number) => {
      //necessary to become source page object
      let previousPage = Number(pageObject);
      //if any data is already break here
      if (!pageObject || !previousPage) return;
      //Does the user want to change the object page?
      //then previous page is different
      if (Number(idPage) !== previousPage) {
        if (!selectedObject) return;
        if (!isDraggingObject) return;

        setObjectScreen((prevState) => {
          let cloneState = cloneDeep(prevState);
          //remove duplicate object when transfer of pages
          const renderWithoutDuplicateObject = cloneState[
            previousPage - 1
          ].renderObjects.filter(
            (object) => object?.id !== selectedObject?.attrs?.id
          );
          //removing last object of previous page
          cloneState[previousPage - 1].renderObjects =
            renderWithoutDuplicateObject;
          //get new page to add the same object
          const page = cloneState.find(
            (page) => Number(page.pageNumber) === Number(idPage)
          );
          //validate each element to center the object with the mouse
          if (page) {
            let cloneObject: Konva.Shape = cloneDeep(selectedObject);
            let newObjectCloned: IRenderedObject = null;
            //select which object user selected
            switch (cloneObject.attrs.object) {
              case "rectangle":
                cloneObject.attrs.x =
                  currentPosition.current.x - cloneObject.attrs.width / 2;
                cloneObject.attrs.y =
                  currentPosition.current.y - cloneObject.attrs.height / 2;
                cloneObject.attrs.page = page.pageNumber;
                break;

              case "circle":
                cloneObject.attrs.x = currentPosition.current.x;
                cloneObject.attrs.y = currentPosition.current.y;
                cloneObject.attrs.page = page.pageNumber;
                break;

              case "richTextImage":
                let cloneAttrs = cloneDeep(newAttrs);
                let myUUID = uuidv4();
                newObjectCloned = {
                  id: myUUID,
                  cornerRadius: cloneAttrs?.cornerRadius,
                  draggable: cloneAttrs?.draggable,
                  format: cloneAttrs?.format,
                  height: cloneAttrs?.height,
                  name: cloneAttrs?.name,
                  object: cloneAttrs?.object,
                  page: page.pageNumber,
                  rotation: cloneAttrs?.rotation,
                  scaleX: cloneAttrs?.scaleX,
                  scaleY: cloneAttrs?.scaleY,
                  src: cloneAttrs?.src,
                  text: cloneAttrs?.text,
                  textHTML: cloneAttrs?.textHTML,
                  width: cloneAttrs?.width,
                  x: currentPosition.current.x - cloneAttrs.width / 2,
                  y: currentPosition.current.y - cloneAttrs.height / 2,
                  initialDelta: cloneAttrs?.initialDelta,
                };
                break;

              case "triangle2":
                cloneObject.attrs.x = currentPosition.current.x;
                cloneObject.attrs.y = currentPosition.current.y;
                cloneObject.attrs.page = page.pageNumber;
                break;

              case "triangleSVG":
                cloneObject.attrs.x =
                  currentPosition.current.x - selectedObject?.width() / 2;
                cloneObject.attrs.y =
                  currentPosition.current.y - selectedObject?.height() / 2;
                cloneObject.attrs.page = page.pageNumber;
                break;
              case "starSVG":
                cloneObject.attrs.x =
                  currentPosition.current.x - selectedObject?.width() / 2;
                cloneObject.attrs.y =
                  currentPosition.current.y - selectedObject?.height() / 2;
                cloneObject.attrs.page = page.pageNumber;
                break;

              case "star":
                cloneObject.attrs.x = currentPosition.current.x;
                cloneObject.attrs.y = currentPosition.current.y;
                cloneObject.attrs.page = page.pageNumber;
                break;

              case "simpleLine":
                cloneObject.attrs.x =
                  currentPosition.current.x - selectedObject.width() / 2;
                cloneObject.attrs.y = currentPosition.current.y;
                cloneObject.attrs.page = page.pageNumber;
                break;

              case "icon":
                cloneObject.attrs.x =
                  currentPosition.current.x - selectedObject?.width() / 2;
                cloneObject.attrs.y =
                  currentPosition.current.y - selectedObject?.height() / 2;
                cloneObject.attrs.page = page.pageNumber;
                break;
              case "dropImage":
                cloneObject.attrs.x =
                  currentPosition.current.x - selectedObject?.width() / 2;
                cloneObject.attrs.y =
                  currentPosition.current.y - selectedObject?.height() / 2;
                cloneObject.attrs.page = page.pageNumber;
                break;

              default:
                toast.error("Ops, tente novamente mais tarde.");
                break;
            }
            //deep clone to avoid issues when text has many layers
            if (newAttrs.object === "richTextImage") {
              page.renderObjects.push(newObjectCloned);
            } else {
              page.renderObjects.push(cloneObject.attrs);
            }
          }
          return cloneState;
        });
        //remove guides line when user leaves the page
        setHlines([]);
        setVlines([]);
        pendingSave.current = true;
        return;
      }
      //case user want move inside own page object
      //we just pass the new attributes
      setObjectScreen((prevState) => {
        let cloneState = cloneDeep(prevState);
        const page = cloneState.find(
          (page) => Number(page.pageNumber) === Number(idPage)
        );
        if (page) {
          page.renderObjects[indexObject] = newAttrs;
        }
        return cloneState;
      });

      pendingSave.current = true;
    },
    [
      idPage,
      setObjectScreen,
      pendingSave,
      currentPosition,
      selectedObject,
      pageObject,
      isDraggingObject,
    ]
  );

  const linesOfThisPage = useCallback(() => {
    let arrayH = [];
    let arrayV = [];

    const constantDiff = -22.5;

    linespage.forEach((element) => {
      if (element.pageNumber === parseInt(item?.pageNumber + "")) {
        element.lineGuidH?.forEach((lg) => {
          arrayH.push(lg.y + constantDiff);
        });
        element.lineGuidV.forEach((lg) => {
          arrayV.push(lg.x + constantDiff);
        });
      }
    });

    return { arrayH, arrayV };
  }, [item?.pageNumber, linespage]);

  useEffect(() => {
    if (shiftPressed && hasEmptyShiftNodes) {
      if (selectedObject) {
        setNodesShift((oldState) => {
          let cloneState = cloneDeep(oldState);
          const isDuplicate = cloneState.some(
            (object) => object.attrs.id === selectedObject.attrs.id
          );

          if (!isDuplicate) {
            cloneState.push(selectedObject);
          }
          return cloneState;
        });
      } else {
        if (selectedObjects.length > 1) {
          let nodes = [];

          selectedObjects.forEach((object) => {
            nodes.push(object);
          });

          if (nodes) {
            setNodesShift(nodes);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftPressed, selectedObject, hasEmptyShiftNodes, selectedObjects]);

  useEffect(() => {
    document.addEventListener("paste", handleImagePaste, true);
    return () => document.removeEventListener("paste", handleImagePaste, true);
  }, []);

  useEffect(() => {
    if (nodesShift?.length > 1) {
      handleGroupElements();
    }
  }, [nodesShift]);

  const handleRemoveElementGroup = useCallback(
    (id: string) => {
      const nodes = currentMultipleSelection.current.nodes();
      let nodesWithoutCurrentTarget = [];

      nodes.forEach((node) => {
        if (node.attrs.id !== id) {
          nodesWithoutCurrentTarget.push(node);
        }
      });

      currentMultipleSelection.current?.setNodes(nodesWithoutCurrentTarget);
      setSelectedObjects(nodesWithoutCurrentTarget);
      return;
    },
    [currentMultipleSelection, setSelectedObjects]
  );

  return (
    <Stage
      // key={uuidv4()}
      ref={handleAddToRefs}
      onClick={handleClickCanvas}
      onContextMenu={handleContextMenu}
      pixelRatio={1}
      scaleX={1}
      scaleY={1}
      listening={(!canvasIsLoading || idPage - 1 === index)}
      width={widthCanvas}
      height={heigthCanvas}
      id={`${index + 1}`}
      onMouseLeave={() => { }}
      onMouseDown={(event) => {
        onMouseDown(event, selectionRectRef);
      }}
      onMouseEnter={(e) => {
        setIdPage(e.currentTarget.attrs.id);
      }}
      onMouseMove={(event: KonvaEventObject<MouseEvent>) => {
        onMouseMove(event, selectionRectRef);
      }}
      onTouchStart={checkDeselect}
      onMouseUp={(event: KonvaEventObject<MouseEvent>) =>
        onMouseUp(event, selectionRectRef, layerRef)
      }
    >
      <Layer
        listening={idPage - 1 === index}
        onDragMove={(event: KonvaEventObject<DragEvent>) =>
          onDragMove(event, setHlines, setVlines, linesOfThisPage())
        }
        onDragEnd={(event: KonvaEventObject<DragEvent>) =>
          onDragEnd(event, setHlines, setVlines)
        }
        ref={layerRef}
      >
        {item.renderObjects.map(
          (objectProps: IRenderedObject, index: number) => (
            <Rendering
              onChange={(newAttrs: IRenderedObject) => {
                handleChangeObjectScreen(newAttrs, index);
              }}
              key={objectProps?.id ? objectProps?.id : index}
              selectedObject={selectedObject}
              setSelectedObject={setSelectedObject}
              objectProps={objectProps}
              addToHistory={addToHistory}
              commandKeyboard={blockCommandKeyboard}
              setCommandBlockKeyboard={setBlockCommandKeyboard}
              currentMultipleSelection={currentMultipleSelection}
              setObjectScreen={setObjectScreen}
              transformerSelectedObject={transformerSelectedObject}
              quillRef={quillRef}
              requestUpdateText={requestTextUpdate}
              textRich={textRich}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              blockingKeyboard={blockingKeyboard}
              setBlockingKeyboard={setBlockingKeyboard}
              templateStartedShared={templateStartedShared}
              setTemplateStartedShared={setTemplateStartedShared}
              oldContentsState={oldContentsState}
              setOldContentsState={setOldContentsState}
              newContentsState={newContentsState}
              setNewContentsState={setNewContentsState}
              selectionCursor={selectionCursor}
              setSelectionCursor={setSelectionCursor}
              findDiff={findDiff}
              buttonRef={buttonRef}
              newDelta={newDelta}
              setNewDelta={setNewDelta}
              isEditingQuill={isEditingQuill}
              setIsEditingQuill={setIsEditingQuill}
              newChanges={newChanges}
              setNewChanges={setNewChanges}
              oldContentsRef={oldContentsRef}
              newContentsRef={newContentsRef}
              getQuillCursorRef={getQuillCursorRef}
              isNewTextRef={isNewTextRef}
              isTypingQuillRef={isTypingQuillRef}
              quillRefComponent={quillRefComponent}
              whichUserEdited={whichUserEdited}
              nameOfCurrentUser={nameOfCurrentUser}
              interceptModifications={interceptModifications}
              textIsChanged={textIsChanged}
              blockRenderImage={blockRenderImage}
              blockBackspace={blockBackspace}
              hasGroupShared={hasGroupShared}
              whichKeyPress={whichKeyPress}
              selectionFormatText={selectionFormatText}
              disableFocusQuill={disableFocusQuill}
              setDisableFocusQuill={setDisableFocusQuill}
              hasColorBlock={hasBlockText}
              rectangule={rectangule}
              blockDblClick={blockDblClick}
              setBlockDblClick={setBlockDblClick}
              index={index}
              idPage={idPage}
              handleRemoveElementGroup={handleRemoveElementGroup}
              currentQuillFormats={currentQuillFormats}
              setCurrentQuillFormats={setCurrentQuillFormats}
              selectionQuill={selectionQuill}
              setSelectionQuill={setSelectionQuill}
              handleOpenAcceptDiff={handleOpenAcceptDiff}
              handleOpenDeclineDiff={handleOpenDeclineDiff}
              pendingSave={pendingSave}
              isDraggingObject={isDraggingObject}
              setIsDraggingObject={setIsDraggingObject}
              setPageObject={setPageObject}
            />
          )
        )}
        {/* {!canvasIsLoading ? (
          item.renderObjects.map(
            (objectProps: IRenderedObject, index: number) => (
              <Rendering
                onChange={(newAttrs: IRenderedObject) => {
                  handleChangeObjectScreen(newAttrs, index);
                }}
                key={objectProps?.id ? objectProps?.id : index}
                selectedObject={selectedObject}
                setSelectedObject={setSelectedObject}
                objectProps={objectProps}
                addToHistory={addToHistory}
                commandKeyboard={blockCommandKeyboard}
                setCommandBlockKeyboard={setBlockCommandKeyboard}
                currentMultipleSelection={currentMultipleSelection}
                setObjectScreen={setObjectScreen}
                transformerSelectedObject={transformerSelectedObject}
                quillRef={quillRef}
                requestUpdateText={requestTextUpdate}
                textRich={textRich}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                blockingKeyboard={blockingKeyboard}
                setBlockingKeyboard={setBlockingKeyboard}
                templateStartedShared={templateStartedShared}
                setTemplateStartedShared={setTemplateStartedShared}
                oldContentsState={oldContentsState}
                setOldContentsState={setOldContentsState}
                newContentsState={newContentsState}
                setNewContentsState={setNewContentsState}
                selectionCursor={selectionCursor}
                setSelectionCursor={setSelectionCursor}
                findDiff={findDiff}
                buttonRef={buttonRef}
                newDelta={newDelta}
                setNewDelta={setNewDelta}
                isEditingQuill={isEditingQuill}
                setIsEditingQuill={setIsEditingQuill}
                newChanges={newChanges}
                setNewChanges={setNewChanges}
                oldContentsRef={oldContentsRef}
                newContentsRef={newContentsRef}
                getQuillCursorRef={getQuillCursorRef}
                isNewTextRef={isNewTextRef}
                isTypingQuillRef={isTypingQuillRef}
                quillRefComponent={quillRefComponent}
                whichUserEdited={whichUserEdited}
                nameOfCurrentUser={nameOfCurrentUser}
                interceptModifications={interceptModifications}
                textIsChanged={textIsChanged}
                blockRenderImage={blockRenderImage}
                blockBackspace={blockBackspace}
                hasGroupShared={hasGroupShared}
                whichKeyPress={whichKeyPress}
                selectionFormatText={selectionFormatText}
                disableFocusQuill={disableFocusQuill}
                setDisableFocusQuill={setDisableFocusQuill}
                hasColorBlock={hasBlockText}
                rectangule={rectangule}
                blockDblClick={blockDblClick}
                setBlockDblClick={setBlockDblClick}
                index={index}
                idPage={idPage}
                handleRemoveElementGroup={handleRemoveElementGroup}
                currentQuillFormats={currentQuillFormats}
                setCurrentQuillFormats={setCurrentQuillFormats}
                selectionQuill={selectionQuill}
                setSelectionQuill={setSelectionQuill}
                handleOpenAcceptDiff={handleOpenAcceptDiff}
                handleOpenDeclineDiff={handleOpenDeclineDiff}
                pendingSave={pendingSave}
                isDraggingObject={isDraggingObject}
                setIsDraggingObject={setIsDraggingObject}
                setPageObject={setPageObject}
              />
            )
          )
        ) : (
          <LoadingCanvas />
        )} */}

        {isCommentsVisible.active &&
          listCommentsCanvas.map((comment) =>
            Number(comment.page) === index + 1 ? (
              <CommentShape
                key={comment.id}
                isEditing={comment.isEditing}
                position={comment.position}
                avatar={comment.avatar}
                commentValue={commentValue}
                setCommentValue={setCommentValue}
                handleMakeComment={handleMakeComment}
                idComment={comment.id}
                callbackClick={handleClickCommentCanvas}
                isVisible={isSavingAutomatic}
                isCommentsVisible={isCommentsVisible}
                selectedIdComment={selectedIdComment}
              />
            ) : null
          )}
        {autoNumber?.isActive && autoNumber?.firstPage ? (
          <Text
            key={index}
            x={autoNumber.position === "center" ? 580 / 2 : 570}
            draggable={false}
            y={810}
            id={String(Math.random() * index)}
            text={String(item.pageNumber)}
            listening={false}
          />
        ) : (
          autoNumber?.isActive &&
          !autoNumber?.firstPage &&
          index > 0 && (
            <Text
              x={autoNumber.position === "center" ? 580 / 2 : 570}
              draggable={false}
              y={810}
              id={String(Math.random() * index)}
              text={String(item.pageNumber - 1)}
              listening={false}
              visible={item.pageNumber === 0 ? false : true}
            />
          )
        )}
        {isCommentsVisible.active && (
          <Rect x={0} y={0} width={1000} height={1000} />
        )}

        {idPage - 1 === index &&
          h_lines.map((item, i) => {
            return <Line key={i} {...item} />;
          })}
        {idPage - 1 === index &&
          v_lines.map((item, i) => {
            return <Line key={i} {...item} />;
          })}

        <Rect ref={selectionRectRef} />
        {index === idPage - 1 && (
          <Transformer
            ref={addToMultipleSelectionRef}
            onTransformStart={() => {
              addToHistory();
            }}
            onDragStart={() => {
              addToHistory();
            }}
            borderStroke="#663399"
          />
        )}
      </Layer>
    </Stage>
  );
};

export default SceneKonva;

