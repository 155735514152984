import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  background-color: #855cad;
  min-height: 34px;
  align-items: center;
  padding: 0 15px;

  > img {
    width: 14px;
    height: 17.5px;
    margin-right: 15px;
  }

  > span {
    font-family: Ubuntu;
    font-size: 12px;
    color: #fff;
  }

  .close-btn {
    width: 20px;
    height: 20px;
    display: flex;
    margin-left: auto;
    background-color: transparent;
    /* background-color: red; */
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
  }

  .vstack {
    display: flex;
    align-items: center;
    margin-left: auto;
    transform: translateY(-2px);
  }
`;

export const ButtonGoTwoFactor = styled.button`
  width: 89px;
  height: 23px;
  display: flex;
  margin-left: auto;
  color: #fff;
  background-color: #7547a3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Ubuntu;
  font-size: 13px;
  /* transform: translateY(-2px); */
`;

