import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "./styles";

import { useSign } from "../../../../../hooks/sign";
import api from "../../../../../services/api";

import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { ContentLoading } from "../SignatureDocs/styles";
import Pagination from "../../../components/Pagination";
import SignatureTable from "../SignatureTable";

const SignatureSpam = () => {
  const {
    dataSpamDocs,
    setDataSpamDocs,
    loadingSpamDocs,
    setLoadingSpamDocs,
    setTotalPagesSpamDocs,
    setOpenModal,
    totalPagesSpamDocs,
    refreshPage,
    setAmountSignDocs
  } = useSign();

  //route properties
  const { number_page } = useParams();
  const navigator = useNavigate();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;

  useEffect(() => {
    (async () => {
      try {
        setLoadingSpamDocs(true);
        const responseDataSpam = await api.get("signature-trash", {
          params: {
            pagination: pageNumber
          }
        });
        setDataSpamDocs(responseDataSpam.data.dataArray);
        setTotalPagesSpamDocs(responseDataSpam.data.pagination);
        setLoadingSpamDocs(false);

        setAmountSignDocs(oldState => {
          return {
            ...oldState,
            spam: responseDataSpam.data.countSignatureTrash,
            docs: responseDataSpam.data.countSignature
          };
        });

        if (pageNumber > 0 && responseDataSpam.data.dataArray.length === 0) {
          navigator(`/signatures/trash/${pageNumber}`);
        }
      } catch (err) {
        setLoadingSpamDocs(false);
        console.error(err, "ERRO get spam list");
      }
    })();
  }, [pageNumber, refreshPage]);

  if (loadingSpamDocs) {
    return (
      <Container>
        <ContentLoading>
          <ContractAnimation />
        </ContentLoading>
      </Container>
    );
  }

  return (
    <Container>
      <SignatureTable data={dataSpamDocs} setOpenModal={setOpenModal} />

      <Pagination
        total={totalPagesSpamDocs}
        pageNow={pageNumber}
        screen="signatures/trash"
      />
    </Container>
  );
};

export default SignatureSpam;
