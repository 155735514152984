import React from "react";

// import { Container } from './styles';

const BlankIcon: React.FC = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      className="icon-blank"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 37.6666C11.8125 37.6666 4.33334 30.1875 4.33334 21C4.33334 11.8125 11.8125 4.33329 21 4.33329C30.1875 4.33329 37.6667 11.8125 37.6667 21C37.6667 30.1875 30.1875 37.6666 21 37.6666ZM21 0.166626C18.2641 0.166626 15.555 0.705496 13.0274 1.75247C10.4998 2.79944 8.20317 4.33401 6.26861 6.26857C2.36161 10.1756 0.166672 15.4746 0.166672 21C0.166672 26.5253 2.36161 31.8243 6.26861 35.7313C8.20317 37.6659 10.4998 39.2005 13.0274 40.2474C15.555 41.2944 18.2641 41.8333 21 41.8333C26.5253 41.8333 31.8244 39.6384 35.7314 35.7313C39.6384 31.8243 41.8333 26.5253 41.8333 21C41.8333 18.2641 41.2945 15.555 40.2475 13.0274C39.2005 10.4998 37.6659 8.20312 35.7314 6.26857C33.7968 4.33401 31.5002 2.79944 28.9726 1.75247C26.445 0.705496 23.7359 0.166626 21 0.166626V0.166626ZM23.0833 10.5833H18.9167V18.9166H10.5833V23.0833H18.9167V31.4166H23.0833V23.0833H31.4167V18.9166H23.0833V10.5833Z"
        fill="#D8D8D8"
      />
    </svg>
  );
};

export default BlankIcon;
