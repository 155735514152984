import { Title } from "./styles";

interface ITitleH3Prosp {
  title: string;
}

const TitleH3 = ({ title }: ITitleH3Prosp) => {
  return <Title>{title}</Title>;
};

export default TitleH3;
