import styled from "styled-components";

export const Container = styled.ul`
  border: solid 1px #d6d8d9;
  border-radius: 5px;
  min-height: 560px;
  height: 90%;
  position: relative;
`;
export const HeaderTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.2fr 0.7fr 0.7fr 1.3fr 0.9fr 0.2fr;
  background-color: ${({ theme }) => theme.colors.black10} !important;
  height: 30px;
  padding-inline: 16px;

  h4 {
    font-size: 13px;
    font-weight: 400;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};

    display: flex;
    align-items: center;
  }
`;

export const NoElements = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;

  color: #999c9f;

  height: 100%;
  min-width: 100%;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;
