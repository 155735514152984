import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 240px;
  height: 208px;
  fill: currentColor;
`;

const PdfIcon = () => (
  <StyledSvg viewBox="0 0 240 208">
    <path
      d="M105.406 148.127C159.492 156.891 225.524 151.098 238.149 89.3678C249.599 33.369 198.069 -13.4452 140.213 4.57744C115.259 12.3509 103.624 36.2284 76.8373 40.2698C50.051 44.3113 16.0235 34.5016 5.11834 68.9067C-11.7778 122.17 69.8128 142.371 105.406 148.127Z"
      fill="#F5F5F5"
    />
    <path
      d="M120 207.086C186.274 207.086 240 203.949 240 200.08C240 196.21 186.274 193.073 120 193.073C53.7258 193.073 0 196.21 0 200.08C0 203.949 53.7258 207.086 120 207.086Z"
      fill="#F5F5F5"
    />
    <path
      d="M221.278 34.2043H127.996C127.996 34.2043 111.193 49.677 111.193 87.2942C111.193 119.645 123.936 163.507 119.152 181.461C115.544 195.009 98.0473 197.336 89.6611 197.658H178.45C178.45 197.658 207.65 199.422 212.434 181.461C217.218 163.5 204.475 119.645 204.475 87.2881C204.475 49.6523 221.278 34.2043 221.278 34.2043Z"
      fill="#D6D8D9"
    />
    <g opacity="0.8">
      <path
        opacity="0.8"
        d="M195.903 73.8759H121.3C123.41 57.3263 129.098 47.6961 132.236 43.4875H204.172C200.644 50.8525 197.314 60.885 195.903 73.8759Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M195.278 82.6523H120.569C120.631 81.1483 120.718 79.6877 120.842 78.2766H195.507C195.404 79.7001 195.327 81.1587 195.278 82.6523Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M143.995 91.4234H120.545C120.503 90.0247 120.481 88.6487 120.477 87.2953V87.054H143.933V87.2891C143.933 88.6569 143.952 90.0371 143.995 91.4234Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M195.723 100.191H121.071C120.941 98.718 120.835 97.2574 120.755 95.8215H195.438C195.513 97.2698 195.608 98.7263 195.723 100.191Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M196.547 108.949H121.956C121.783 107.485 121.622 106.026 121.473 104.574H196.126C196.231 106.047 196.386 107.507 196.547 108.949Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M197.623 117.738H123.094C122.89 116.277 122.698 114.817 122.513 113.368H197.066C197.24 114.825 197.425 116.281 197.623 117.738Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M198.855 126.507H124.332C124.118 125.047 123.911 123.59 123.713 122.138H198.23C198.434 123.592 198.645 125.041 198.855 126.507Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M200.173 135.285H125.675C125.558 134.487 125.434 133.676 125.31 132.865L125.013 130.909H199.511C199.734 132.37 199.951 133.831 200.173 135.285Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M126.975 144.055C126.769 142.61 126.562 141.154 126.356 139.685H200.841C201.058 141.146 201.281 142.6 201.491 144.055H126.975Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M128.157 152.824C127.984 151.394 127.792 149.934 127.594 148.454H189.732C189.943 149.927 190.141 151.388 190.351 152.824H128.157Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M129.073 161.602C128.953 160.174 128.817 158.715 128.665 157.226H203.255C203.422 158.724 203.577 160.184 203.707 161.602H129.073Z"
        fill="#999C9F"
      />
      <path
        opacity="0.5"
        d="M129.5 170.371C129.5 168.96 129.445 167.499 129.364 166.001H168.721C168.82 167.53 168.875 168.991 168.894 170.371H129.5Z"
        fill="#999C9F"
      />
    </g>
    <path
      d="M185.604 192.922C180.87 193.782 176.073 193.126 171.314 192.922C169.031 192.774 166.74 192.834 164.469 193.101C162.24 193.398 160.025 193.98 157.778 193.782C158.521 193.383 159.22 192.908 159.864 192.365C159.892 192.336 159.913 192.301 159.924 192.263C159.935 192.224 159.935 192.183 159.925 192.144C159.915 192.105 159.895 192.07 159.867 192.041C159.839 192.012 159.803 191.992 159.765 191.981C152.777 189.505 146.415 196.202 139.452 194.642C141.427 193.182 144.205 193.151 145.889 191.232C145.92 191.199 145.941 191.157 145.949 191.113C145.957 191.068 145.952 191.022 145.934 190.98C145.916 190.938 145.887 190.902 145.849 190.876C145.812 190.851 145.767 190.837 145.722 190.836C139.496 190.712 133.616 193.12 127.427 193.671C127.13 193.671 127.13 194.159 127.427 194.135C133.399 193.615 139.124 191.331 145.146 191.282C143.345 192.891 140.597 192.946 138.765 194.568C138.737 194.597 138.716 194.632 138.706 194.671C138.695 194.71 138.695 194.752 138.705 194.791C138.715 194.83 138.735 194.866 138.763 194.895C138.791 194.924 138.826 194.946 138.864 194.958C145.883 196.895 152.27 190.198 159.202 192.266C158.479 192.833 157.691 193.313 156.856 193.695C156.817 193.719 156.786 193.753 156.766 193.795C156.746 193.836 156.738 193.882 156.744 193.927C156.75 193.972 156.769 194.015 156.799 194.05C156.829 194.084 156.868 194.11 156.912 194.122C159.195 194.506 161.43 194.017 163.683 193.658C166.205 193.296 168.758 193.197 171.301 193.361C176.11 193.59 180.938 194.24 185.716 193.361C186.031 193.318 185.907 192.866 185.604 192.922Z"
      fill="#343A40"
    />
    <path
      d="M59.2724 57.0425C59.6623 57.5438 60.1636 58.1318 60.6216 58.6764C61.0796 59.221 61.5809 59.7719 62.076 60.3041C63.0725 61.3686 64.0813 62.4146 65.1706 63.3987C67.1906 65.3166 69.4851 66.9227 71.9786 68.1643C72.2818 68.3004 72.5975 68.4242 72.876 68.5356C73.0137 68.5904 73.1541 68.6379 73.2968 68.6779C73.4289 68.7301 73.5657 68.7695 73.7053 68.7955C74.2993 68.9482 74.9065 69.0436 75.5187 69.0802C76.8889 69.1468 78.2623 69.068 79.6159 68.845C81.0534 68.6258 82.4772 68.3241 83.8801 67.9414C85.3036 67.5701 86.7581 67.1121 88.1135 66.6355L89.3513 69.1112C86.6 70.8311 83.6207 72.1561 80.5009 73.0474C78.8559 73.5184 77.1604 73.7906 75.4506 73.8582C74.5247 73.8954 73.5975 73.8352 72.6841 73.6787C72.4489 73.6416 72.2075 73.5859 71.9724 73.5302C71.7372 73.4745 71.4896 73.4126 71.2854 73.3445C70.8521 73.2145 70.4313 73.0722 70.0476 72.9113C66.8974 71.6159 63.9984 69.7789 61.4819 67.4834C60.2539 66.4192 59.0825 65.2912 57.9727 64.1042C57.4218 63.4853 56.8834 62.9097 56.3635 62.2908C55.8436 61.6719 55.3485 61.053 54.8101 60.3413L59.2724 57.0425Z"
      fill="#FFB573"
    />
    <path
      d="M52.9782 55.2906C52.8421 58.7565 54.2161 65.0075 54.2161 65.0075L62.7941 63.9677C62.7941 63.9677 63.1098 54.8202 61.4449 52.6107C58.6289 48.9159 53.1082 52.0104 52.9782 55.2906Z"
      fill="#663399"
    />
    <path
      d="M86.9624 67.5154L90.9791 63.8391L91.4928 67.8001C91.4928 67.8001 89.8279 70.0344 87.9403 69.0936L86.9624 67.5154Z"
      fill="#FFB573"
    />
    <path
      d="M92.9966 64.0557L94.6986 66.2838L91.4927 67.8001L90.979 63.8391L92.9966 64.0557Z"
      fill="#FFB573"
    />
    <path
      d="M91.9821 54.0353C92.4091 54.0353 92.6443 54.0353 92.9476 54.0353C93.0774 54.0263 93.2077 54.0263 93.3375 54.0353L93.6593 54.0786C93.9371 54.1113 94.2016 54.2158 94.4267 54.3819C94.5362 54.5108 94.6089 54.6669 94.6372 54.8337L94.7795 55.304L95.3551 57.1608L96.5063 60.9175L98.8024 68.4372C100.319 73.4504 101.897 78.4512 103.358 83.4767C106.359 93.5154 109.2 103.591 111.991 113.686C114.783 123.78 117.407 133.918 120.105 144.012C122.804 154.106 125.217 164.318 126.078 174.914C126.109 175.622 125.896 176.32 125.474 176.89C125.052 177.46 124.448 177.868 123.761 178.046C123.075 178.223 122.348 178.16 121.703 177.866C121.058 177.572 120.533 177.065 120.217 176.43C115.822 166.751 112.951 156.681 110.438 146.519C107.925 136.356 105.258 126.28 102.794 116.124C100.331 105.968 97.9236 95.7558 95.6708 85.5253C94.5134 80.4193 93.4613 75.2824 92.3534 70.164L90.7133 62.4772L89.8902 58.6338L89.4817 56.709L89.3765 56.2324C89.3203 56.0714 89.3075 55.8985 89.3393 55.7311C89.4598 55.4856 89.6382 55.2732 89.8592 55.1122L90.1253 54.9141C90.2274 54.8363 90.335 54.766 90.4472 54.7037C90.7133 54.549 90.9052 54.419 91.2889 54.2333C91.5048 54.1227 91.7403 54.0554 91.9821 54.0353Z"
      fill="#263238"
    />
    <path
      d="M125.483 176.51C125.437 176.343 125.327 176.2 125.176 176.113C125.026 176.026 124.847 176.002 124.679 176.046L123.317 176.399L121.956 176.751C121.787 176.795 121.643 176.904 121.554 177.053C121.464 177.202 121.438 177.381 121.479 177.55C122.327 181.449 121.479 184.24 122.383 187.669C123.317 191.277 127.383 193.128 127.383 193.128L127.253 191.587L127.872 192.998C127.872 192.998 130.527 189.408 129.593 185.8C128.714 182.371 126.634 180.329 125.483 176.51Z"
      fill="#999C9F"
    />
    <path
      d="M126.542 185.46C126.582 185.622 126.573 185.793 126.516 185.95C126.459 186.108 126.357 186.245 126.222 186.344C126.088 186.444 125.927 186.501 125.759 186.509C125.592 186.517 125.426 186.476 125.283 186.39C125.139 186.304 125.024 186.177 124.953 186.026C124.881 185.875 124.855 185.706 124.879 185.54C124.904 185.375 124.976 185.22 125.088 185.095C125.2 184.971 125.346 184.882 125.508 184.841C125.727 184.786 125.959 184.821 126.153 184.937C126.346 185.053 126.486 185.241 126.542 185.46Z"
      fill="#263238"
    />
    <path
      d="M127.303 191.774C127.279 191.781 127.253 191.783 127.228 191.781C127.202 191.778 127.178 191.77 127.156 191.757C127.134 191.744 127.115 191.727 127.099 191.706C127.084 191.686 127.074 191.662 127.068 191.637L125.533 185.727C125.526 185.703 125.525 185.677 125.528 185.652C125.531 185.627 125.54 185.603 125.552 185.581C125.565 185.559 125.582 185.54 125.602 185.525C125.622 185.509 125.645 185.498 125.669 185.492C125.693 185.484 125.719 185.482 125.744 185.485C125.77 185.488 125.794 185.496 125.816 185.509C125.838 185.521 125.858 185.539 125.873 185.559C125.888 185.58 125.899 185.603 125.904 185.628L127.439 191.538C127.446 191.563 127.447 191.588 127.444 191.613C127.441 191.638 127.433 191.662 127.42 191.684C127.407 191.706 127.39 191.725 127.37 191.741C127.35 191.756 127.327 191.767 127.303 191.774Z"
      fill="#263238"
    />
    <path
      d="M122.29 155.258L113.947 157.418C113.715 157.476 113.47 157.441 113.265 157.319C113.06 157.197 112.911 156.999 112.851 156.768C112.792 156.541 112.823 156.3 112.939 156.095C113.054 155.891 113.245 155.739 113.47 155.673L121.856 153.513C122.084 153.453 122.325 153.485 122.529 153.6C122.734 153.716 122.885 153.906 122.952 154.132C122.984 154.249 122.993 154.372 122.977 154.493C122.961 154.614 122.921 154.73 122.859 154.835C122.797 154.94 122.715 155.032 122.617 155.104C122.519 155.177 122.408 155.229 122.29 155.258Z"
      fill="#999C9F"
    />
    <path
      d="M79.9381 41.9832C79.7895 42.3545 79.87 42.7445 80.1113 42.8497C80.3527 42.9549 80.6745 42.7383 80.8231 42.3669C80.9716 41.9956 80.8974 41.6057 80.6498 41.5005C80.4022 41.3952 80.0928 41.6119 79.9381 41.9832Z"
      fill="#263238"
    />
    <path
      d="M80.5381 41.4895L81.5345 41.6195C81.5345 41.6195 80.7918 42.1084 80.5381 41.4895Z"
      fill="#263238"
    />
    <path
      d="M80.1112 42.4797C80.1015 43.476 80.2264 44.4691 80.4826 45.4319C80.2357 45.4846 79.9804 45.4825 79.7344 45.4259C79.4885 45.3692 79.258 45.2593 79.0591 45.1039L80.1112 42.4797Z"
      fill="#ED893E"
    />
    <path
      d="M81.4358 39.7195C81.3971 39.7121 81.361 39.6951 81.3306 39.67C81.1079 39.4665 80.8413 39.3171 80.5515 39.2333C80.2617 39.1496 79.9564 39.1339 79.6595 39.1873C79.5988 39.2026 79.5346 39.1937 79.4803 39.1625C79.426 39.1313 79.386 39.0803 79.3686 39.0202C79.3534 38.9595 79.3622 38.8952 79.3934 38.841C79.4246 38.7867 79.4756 38.7466 79.5357 38.7293C79.9076 38.6556 80.2915 38.6698 80.6568 38.7708C81.0222 38.8718 81.3589 39.0568 81.64 39.3111C81.6621 39.3329 81.6796 39.359 81.6916 39.3876C81.7036 39.4163 81.7097 39.4471 81.7097 39.4782C81.7097 39.5092 81.7036 39.54 81.6916 39.5687C81.6796 39.5974 81.6621 39.6234 81.64 39.6453C81.6148 39.6736 81.5829 39.6952 81.5473 39.7082C81.5116 39.7211 81.4733 39.725 81.4358 39.7195Z"
      fill="#263238"
    />
    <path
      d="M66.8173 41.7942C67.0834 44.8083 66.0808 49.741 63.8032 51.4182C63.8032 51.4182 65.5176 54.587 71.0073 55.3235C77.0417 56.1343 74.2071 52.7365 74.2071 52.7365C70.9949 51.4987 71.3415 49.1035 72.1523 46.9002L66.8173 41.7942Z"
      fill="#FFB573"
    />
    <path
      d="M83.2612 57.8784C83.7378 60.6883 84.3258 63.6033 84.9385 66.4441C85.2665 67.8614 85.5945 69.2849 85.9782 70.6774C86.362 72.07 86.7457 73.4625 87.1727 74.8303C87.4079 75.5049 87.6183 76.1919 87.8659 76.8479L88.2372 77.8382L88.3239 78.0796V78.1538C88.3362 78.1918 88.3507 78.2289 88.3672 78.2652C88.4948 78.4859 88.7036 78.648 88.949 78.717C89.4386 78.8914 89.9521 78.9895 90.4715 79.0079C91.7996 79.0445 93.1278 78.9366 94.4325 78.6861C95.8003 78.4385 97.2424 78.1043 98.5854 77.733L99.718 80.2395C99.0356 80.6458 98.3333 81.0176 97.6137 81.3536C96.9027 81.7021 96.1734 82.012 95.429 82.2819C93.8704 82.8906 92.2357 83.2821 90.5705 83.4455C89.5924 83.5344 88.6064 83.4676 87.6493 83.2474C86.4228 82.9678 85.3186 82.3017 84.4991 81.3474C84.2871 81.0912 84.0967 80.8179 83.9297 80.5304C83.843 80.3881 83.7749 80.2396 83.7007 80.0972L83.5831 79.8187L83.1003 78.7356C82.7847 78.0115 82.4814 77.2874 82.2153 76.5571C81.683 75.0964 81.1755 73.6296 80.7361 72.1566C80.2967 70.6836 79.9006 69.1982 79.4983 67.7129C78.7618 64.7297 78.1614 61.7775 77.7158 58.6892L83.2612 57.8784Z"
      fill="#FFB573"
    />
    <path
      d="M97.9915 77.9066L100.38 76.0127L101.457 79.689C101.457 79.689 98.1896 82.0532 97.3169 79.2743L97.9915 77.9066Z"
      fill="#FFB573"
    />
    <path
      d="M102.541 76.6626L103.395 79.3486L101.457 79.689L100.387 76.0127L102.541 76.6626Z"
      fill="#FFB573"
    />
    <path
      d="M57.9048 194.614L62.8994 194.985L64.3786 183.517L59.384 183.146L57.9048 194.614Z"
      fill="#FFB573"
    />
    <path
      d="M25.9194 180.044L30.2456 182.675L37.4435 173.688L33.1173 171.058L25.9194 180.044Z"
      fill="#FFB573"
    />
    <path
      d="M33.2659 184C33.2043 184.01 33.1417 184.01 33.0802 184C32.4613 184 31.7248 183.035 31.3534 182.465C31.3398 182.447 31.3325 182.424 31.3325 182.4C31.3325 182.377 31.3398 182.354 31.3534 182.335C31.3654 182.316 31.3829 182.3 31.4038 182.29C31.4247 182.28 31.4481 182.276 31.471 182.28C31.6753 182.323 33.4639 182.701 33.6991 183.295C33.7243 183.36 33.7319 183.432 33.721 183.501C33.7102 183.571 33.6813 183.636 33.6372 183.691C33.5541 183.835 33.4225 183.945 33.2659 184ZM31.7186 182.595C32.2447 183.338 32.746 183.778 33.0864 183.784C33.1605 183.784 33.2335 183.765 33.2977 183.728C33.3619 183.691 33.415 183.638 33.4515 183.573C33.4681 183.547 33.4769 183.517 33.4769 183.487C33.4769 183.456 33.4681 183.426 33.4515 183.4C33.3525 183.084 32.4303 182.769 31.7186 182.602V182.595Z"
      fill="#663399"
    />
    <path
      d="M32.9998 182.453C32.4856 182.593 31.9473 182.62 31.4216 182.534C31.402 182.527 31.3844 182.516 31.3703 182.501C31.3563 182.485 31.3462 182.467 31.3411 182.447C31.3313 182.43 31.3262 182.411 31.3262 182.391C31.3262 182.372 31.3313 182.353 31.3411 182.336C31.3782 182.292 32.3623 181.283 33.0864 181.314C33.1799 181.316 33.2719 181.338 33.3555 181.38C33.4391 181.421 33.5122 181.482 33.5692 181.556C33.6362 181.622 33.6788 181.708 33.6901 181.802C33.7013 181.895 33.6805 181.99 33.6311 182.069C33.4673 182.262 33.246 182.396 32.9998 182.453ZM31.7001 182.342C32.319 182.41 33.2721 182.23 33.464 181.946C33.464 181.896 33.5135 181.828 33.4144 181.704C33.3779 181.657 33.3311 181.618 33.2775 181.591C33.2239 181.565 33.1649 181.55 33.105 181.55C32.5659 181.662 32.0749 181.938 31.7001 182.342Z"
      fill="#663399"
    />
    <path
      d="M31.5455 181.709L27.4422 177.76C27.3743 177.69 27.2832 177.648 27.1862 177.641C27.0892 177.634 26.9931 177.663 26.9161 177.723L23.4131 180.421C23.3286 180.496 23.2611 180.588 23.2148 180.691C23.1685 180.794 23.1445 180.906 23.1445 181.019C23.1445 181.131 23.1685 181.243 23.2148 181.346C23.2611 181.449 23.3286 181.541 23.4131 181.616C24.8675 182.971 25.635 183.553 27.436 185.292C28.55 186.363 30.0602 188.034 31.595 189.513C33.1299 190.992 34.6277 189.593 34.1202 188.845C31.8302 185.49 32.1706 184.234 31.9664 182.519C31.9227 182.209 31.7742 181.923 31.5455 181.709Z"
      fill="#263238"
    />
    <path
      d="M65.0658 195.146C64.5373 195.136 64.0109 195.076 63.4938 194.967C63.47 194.962 63.4482 194.95 63.4316 194.932C63.415 194.914 63.4043 194.892 63.4009 194.868C63.3978 194.843 63.4019 194.818 63.413 194.796C63.424 194.774 63.4414 194.756 63.4628 194.744C63.6918 194.626 65.7218 193.605 66.316 193.952C66.3731 193.981 66.4211 194.025 66.4549 194.08C66.4887 194.134 66.507 194.197 66.5079 194.261C66.5206 194.368 66.5063 194.477 66.4664 194.578C66.4264 194.678 66.3619 194.767 66.2789 194.837C65.9211 195.074 65.4935 195.183 65.0658 195.146ZM63.8899 194.799C64.9915 194.979 65.8147 194.936 66.1241 194.663C66.1788 194.617 66.2209 194.557 66.2469 194.49C66.2728 194.423 66.2817 194.351 66.2727 194.28C66.2745 194.255 66.269 194.23 66.2569 194.208C66.2448 194.186 66.2267 194.168 66.2046 194.156C65.8704 193.97 64.7254 194.403 63.8899 194.799Z"
      fill="#663399"
    />
    <path
      d="M63.5184 194.978C63.4962 194.984 63.4726 194.984 63.4504 194.978C63.4322 194.966 63.4178 194.949 63.409 194.93C63.4002 194.91 63.3974 194.888 63.4009 194.867C63.4009 194.78 63.6422 192.738 64.7624 192.738H64.8553C65.1833 192.768 65.2761 192.929 65.2947 193.066C65.3875 193.629 64.1745 194.712 63.568 194.984L63.5184 194.978ZM64.7563 192.954C64.0383 192.954 63.7536 194.142 63.667 194.65C64.2859 194.279 65.109 193.412 65.0533 193.09C65.0533 193.059 65.0533 192.979 64.8243 192.96L64.7563 192.954Z"
      fill="#663399"
    />
    <path
      d="M63.2089 194.273H57.5954C57.4982 194.273 57.4041 194.307 57.3295 194.369C57.2548 194.431 57.2044 194.518 57.1869 194.613L56.4319 199.051C56.4169 199.162 56.4258 199.275 56.4581 199.382C56.4905 199.49 56.5454 199.589 56.6193 199.673C56.6932 199.758 56.7842 199.826 56.8864 199.872C56.9886 199.918 57.0995 199.942 57.2117 199.942C59.1675 199.942 60.1144 199.793 62.5838 199.793C64.1001 199.793 67.238 199.948 69.3361 199.948C71.4342 199.948 71.6013 197.875 70.7286 197.689C66.8357 196.847 65.3256 195.684 64.0383 194.595C63.8086 194.393 63.5147 194.279 63.2089 194.273Z"
      fill="#263238"
    />
    <path
      opacity="0.2"
      d="M64.3787 183.515L63.5556 189.89L58.6167 189.06L59.3841 183.15L64.3787 183.515Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M33.1113 171.058L37.4436 173.694L34.4605 177.408L30.0415 174.889L33.1113 171.058Z"
      fill="black"
    />
    <path
      d="M55.3857 81.5076L75.6054 84.2246C83.4408 55.8662 81.1323 55.105 81.1323 55.105C80.2413 54.6802 79.3209 54.3203 78.3781 54.0281C77.425 53.6815 76.4616 53.3658 75.4878 53.0811C73.5427 52.5038 71.5649 52.0429 69.5649 51.701C65.6831 51.0335 61.7378 50.8113 57.8056 51.0387C57.4116 51.0663 57.0288 51.1824 56.6859 51.3785C56.3429 51.5746 56.0487 51.8456 55.8251 52.1713C55.7411 52.2922 55.6685 52.4206 55.6085 52.5551C55.3885 53.026 55.3028 53.5486 55.361 54.0652C57.0691 68.9685 56.5183 73.8702 55.3857 81.5076Z"
      fill="#663399"
    />
    <path
      opacity="0.2"
      d="M77.5738 63.7695C77.1756 66.7039 77.5533 69.6913 78.6693 72.4342C80.0123 66.9012 80.7426 63.1506 81.1016 60.5512C79.5605 60.4831 77.9575 61.0773 77.5738 63.7695Z"
      fill="black"
    />
    <path
      d="M80.9527 55.0077C84.4186 56.9882 86.8261 61.7105 87.5502 64.4522L80.1729 71.1983C80.1729 71.1983 76.348 62.3046 76.6575 59.5443C76.9669 56.784 79.6468 54.2588 80.9527 55.0077Z"
      fill="#663399"
    />
    <path
      d="M67.9124 35.5718C66.3589 40.2631 65.6039 42.2127 66.9655 45.5548C69.0202 50.5865 75.1041 51.651 78.0253 47.56C80.6557 43.8775 82.7538 36.822 79.0094 33.0714C78.2225 32.2346 77.231 31.6175 76.1328 31.2811C75.0345 30.9446 73.8675 30.9005 72.7469 31.153C71.6264 31.4054 70.5911 31.9458 69.7432 32.7208C68.8954 33.4957 68.2643 34.4784 67.9124 35.5718Z"
      fill="#FFB573"
    />
    <path
      d="M75.6055 39.7321C75.2791 40.5926 75.2272 41.5331 75.4569 42.4243C75.7664 43.6621 76.6948 43.7673 77.2703 42.9689C77.8459 42.1706 78.2358 40.7285 77.6169 39.6887C76.998 38.649 76.0078 38.7851 75.6055 39.7321Z"
      fill="#FFB573"
    />
    <path
      d="M69.5961 29.7303C66.254 27.8736 60.2382 33.3076 58.7281 42.1765C57.645 48.5265 71.2053 51.5963 79.771 51.4973C79.2016 49.9995 75.6429 45.4939 77.9142 41.1553C79.5667 38.0051 81.4482 38.7354 81.572 35.0715C81.7267 30.4544 75.6119 27.3599 69.5961 29.7303Z"
      fill="#263238"
    />
    <path
      d="M77.2023 31.7224C81.0891 31.3449 84.9943 35.1635 82.0979 38.9698C81.3904 38.4025 80.5669 37.9975 79.6857 37.7836C78.8044 37.5697 77.8869 37.5521 76.9981 37.732C75.2218 38.1281 77.2023 31.7224 77.2023 31.7224Z"
      fill="#263238"
    />
    <path
      d="M70.2707 83.5058C70.2707 83.5058 62.4415 116.692 58.7281 135.76C54.8537 155.875 36.2803 176.831 36.2803 176.831L29.9922 173.315C29.9922 173.315 41.6772 154.191 45.4649 135.407C53.1641 97.2827 47.9405 89.6083 55.3674 81.5129L70.2707 83.5058Z"
      fill="#263238"
    />
    <path
      d="M36.9236 177.419L29.0449 173.031L30.1961 170.704L39.0341 174.771L36.9236 177.419Z"
      fill="#663399"
    />
    <path
      opacity="0.2"
      d="M66.093 90.7184C59.9906 96.0225 60.6528 114.856 61.2903 123.31C63.8526 111.254 66.9286 97.8359 68.7172 90.0686C68.0921 89.5858 67.2194 89.7653 66.093 90.7184Z"
      fill="black"
    />
    <path
      d="M75.3086 84.2379C75.3086 84.2379 78.8425 123.644 76.9116 138.671C74.9063 154.304 64.7067 188.313 64.7067 188.313L57.4902 187.385C57.4902 187.385 63.3451 153.679 64.3849 138.207C65.5175 121.329 60.4857 82.2512 60.4857 82.2512L75.3086 84.2379Z"
      fill="#263238"
    />
    <path
      d="M66.2351 188.729L55.9736 187.405L56.1284 184.372L67.2687 185.449L66.2351 188.729Z"
      fill="#663399"
    />
    <path
      d="M55.2061 80.2882L54.1045 81.9654C54.0178 82.0954 54.1726 82.2563 54.4015 82.2873L75.6115 85.1343C75.7972 85.1343 75.9581 85.0909 75.9767 84.9795L76.3542 83.2095C76.3542 83.0857 76.2304 82.9557 76.0324 82.9309L55.5527 80.183C55.4908 80.1701 55.4265 80.1729 55.366 80.1913C55.3054 80.2097 55.2505 80.243 55.2061 80.2882Z"
      fill="#663399"
    />
    <path
      opacity="0.5"
      d="M55.2061 80.2882L54.1045 81.9654C54.0178 82.0954 54.1726 82.2563 54.4015 82.2873L75.6115 85.1343C75.7972 85.1343 75.9581 85.0909 75.9767 84.9795L76.3542 83.2095C76.3542 83.0857 76.2304 82.9557 76.0324 82.9309L55.5527 80.183C55.4908 80.1701 55.4265 80.1729 55.366 80.1913C55.3054 80.2097 55.2505 80.243 55.2061 80.2882Z"
      fill="black"
    />
    <path
      d="M57.7191 82.9573L57.1683 82.883C57.0569 82.883 56.9826 82.8025 56.995 82.7344L57.5025 80.4321C57.5025 80.3702 57.6201 80.3269 57.7253 80.3393L58.2761 80.4135C58.3875 80.4135 58.4618 80.494 58.4494 80.5621L57.9419 82.8644C57.9295 82.9016 57.8243 82.9573 57.7191 82.9573Z"
      fill="#263238"
    />
    <path
      d="M71.5701 84.8132L71.0193 84.7389C70.9141 84.7389 70.8336 84.6585 70.846 84.5904L71.3535 82.2881C71.3535 82.22 71.4711 82.1767 71.5825 82.1952L72.1271 82.2633C72.2385 82.2633 72.3128 82.35 72.3004 82.418L71.7929 84.7142C71.7805 84.8132 71.6753 84.8132 71.5701 84.8132Z"
      fill="#263238"
    />
  </StyledSvg>
);

export default PdfIcon;
