import styled from "styled-components";

export const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1999;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 700px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  gap: 30px;

  h5 {
    margin-bottom: 8px;
  }

  @media (min-width: 1920px) {
    width: 900px;
  }
`;

export const TextBox = styled.div`
  width: 100%;
  height: 50%;
  background: red;
`;

export const ButtonsBox = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

export const Button = styled.button<{ color: string }>`
  width: 415px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonNo = styled.button<{ color: string }>`
  width: 415px;
  height: 40px;
  background-color: ${({ color }) => color && color};
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
`;
