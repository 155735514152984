import { useState } from "react";
import ListAllCommentsPage from "./components/ListAllCommentsPage";
import ListSpecificComentPage from "./components/ListSpecificComentPage";
import { Container } from "./styled";

export interface ICategoryComment {
  page: "All" | "Historic";
  isOpenComment: boolean;
}

const ListAllComments = () => {
  const [categoryComment, setCategoryComment] = useState<ICategoryComment>({
    page: "All",
    isOpenComment: false
  });

  return (
    <Container>
      {categoryComment.isOpenComment ? (
        <ListSpecificComentPage
          categoryComment={categoryComment}
          setCategoryComment={setCategoryComment}
        />
      ) : (
        <ListAllCommentsPage
          categoryComment={categoryComment.page}
          setCategoryComment={setCategoryComment}
        />
      )}
    </Container>
  );
};

export default ListAllComments;
