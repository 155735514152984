/*
this file render button to go back dashboard or home
just only navigate user to back
*/

import React, { useEffect } from "react";
import { Button, Text } from "./styles";
import { useHeaderEditor } from "../../../../hooks/headerEditor";
import ReactLoading from "react-loading";
import Tooltip from "../../../Tooltip";
import { useMainHook } from "../../../../../../hooks/main";
import { useComments } from "../../../../hooks/comments";
import { useTextsEdition } from "../../../../hooks/textsEdition";
import { useSelection } from "../../../../hooks/selection";

//set up interfaces
interface ButtonGoHomeProps {
  handleGoHomePage: () => void;
  backIcon: string;
}

const ButtonGoHome: React.FC<ButtonGoHomeProps> = ({
  handleGoHomePage,
  backIcon
}) => {
  const { isLoadingSavingTemplate } = useMainHook();
  const { setIsCommentsVisible } = useComments();
  const { setIsEditing } = useTextsEdition();
  const { setSelectedObject } = useSelection();

  return (
    <Tooltip
      // title="Início"
      // description="Volte para a tela inicial"
      placement="bottom-start"
      isDisable
    >
      <Button
        disabled={isLoadingSavingTemplate}
        onClick={() => {
          setSelectedObject(null);
          setIsCommentsVisible({ active: false, screen: "none" });
          setIsEditing("closed");
          handleGoHomePage();
        }}
      >
        {!isLoadingSavingTemplate ? (
          <>
            <img className="icon-back" src={backIcon} alt="Ícone de voltar" />
            <Text>Voltar</Text>
          </>
        ) : (
          <div
            style={{
              margin: "0 auto",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <ReactLoading type="spin" color="#fff" height={16} width={16} />
          </div>
        )}
      </Button>
    </Tooltip>
  );
};

export default ButtonGoHome;
