import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelection } from "./selection";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Shape } from "konva/lib/Shape";
import { useTextsEdition } from "./textsEdition";
// import { useMainHook } from "../../../hooks/main";

interface StageToolsProviderProps {
  children: ReactNode;
}

interface StageToolsDataProps {
  onMouseDown: (
    event: KonvaEventObject<MouseEvent>,
    selectionRectRef: React.MutableRefObject<Shape>
  ) => void;
  onMouseMove: (
    event: KonvaEventObject<MouseEvent>,
    selectionRectRef: React.MutableRefObject<Shape>
  ) => void;
  onMouseUp: (
    event: KonvaEventObject<MouseEvent>,
    selectionRectRef: React.MutableRefObject<Konva.Shape>,
    layerRef: React.MutableRefObject<Konva.Layer>
  ) => void;
  onDragMove: (e: KonvaEventObject<DragEvent>) => void;
  onDragEnd: (e: KonvaEventObject<DragEvent>) => void;
  h_lines: any[];
  v_lines: any[];
  currentPosition: React.MutableRefObject<{ x: number; y: number }>;
  selection: React.MutableRefObject<{
    visible: boolean;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  }>;
  isPendingMouseUp: boolean;
  setIsPendingMouseUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const StageToolsContext = createContext({} as StageToolsDataProps);

const StageToolsProvider: React.FC<StageToolsProviderProps> = ({
  children,
}) => {
  const {
    setSelectedObject,
    currentMultipleSelection,
    setSelectedObjects,
    multipleSelectionRefs,
    setShiftPressed,
  } = useSelection();

  const { isEditing } = useTextsEdition();

  const [isPendingMouseUp, setIsPendingMouseUp] = useState(false);

  const GUIDELINE_OFFSET = 5;
  const [h_lines, setHlines] = useState([]);
  const [v_lines, setVlines] = useState([]);
  const currentPosition = useRef<{
    x: number;
    y: number;
  }>(null);

  const windows = window as any;
  const Konvas = windows.Konva;
  const oldPos = useRef(null);
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });

  const updateSelectionRect = useCallback(
    ({
      selectionRectRef,
    }: {
      selectionRectRef: React.MutableRefObject<Konva.Shape>;
    }) => {
      const node = selectionRectRef?.current;

      node?.setAttrs({
        visible: selection?.current?.visible,
        x: Math?.min(selection?.current?.x1, selection?.current?.x2),
        y: Math?.min(selection?.current?.y1, selection?.current?.y2),
        width: Math?.abs(selection?.current?.x1 - selection?.current?.x2),
        height: Math?.abs(selection?.current?.y1 - selection?.current?.y2),
        fill: "rgba(65, 23, 180, 0.3)",
      });
      node?.getLayer()?.batchDraw();
    },
    []
  );

  const onMouseDown = useCallback(
    (
      e: KonvaEventObject<MouseEvent>,
      selectionRectRef: React.MutableRefObject<Konva.Shape>
    ) => {
      if (e?.target instanceof Konva.Stage) {
        setSelectedObject(null);
        // cleaning currentMutipleSelection
        if (currentMultipleSelection?.current) {
          currentMultipleSelection?.current?.setNodes([]);
          setSelectedObjects([]);
        }
        const pos = e?.target?.getStage()?.getPointerPosition();
        selection.current.visible = true;
        selection.current.x1 = pos?.x;
        selection.current.y1 = pos?.y;
        selection.current.x2 = pos?.x;
        selection.current.y2 = pos?.y;
        if (
          selection.current.x2 >= 595 ||
          selection.current.y2 >= 841 ||
          selection.current.x2 <= 1 ||
          selection.current.y2 <= 1
        ) {
          // selection.current!.visible = false;
        }
        updateSelectionRect({ selectionRectRef });
        return;
      }
    },
    [
      currentMultipleSelection,
      setSelectedObject,
      setSelectedObjects,
      updateSelectionRect,
    ]
  );

  const onMouseMove = useCallback(
    (e, selectionRectRef) => {
      // setIsPendingMouseUp(true);
      const position = e.target.getStage().getPointerPosition();
      currentPosition.current = position;

      if (!selection?.current?.visible) {
        return;
      }
      if (false) {
        selection.current!.visible = false;
      }
      const pos = e?.target?.getStage()?.getPointerPosition();
      selection.current.x2 = pos?.x;
      selection.current.y2 = pos?.y;
      updateSelectionRect({ selectionRectRef });
    },
    [updateSelectionRect, currentPosition]
  );

  const onMouseUp = useCallback(
    (
      event: KonvaEventObject<MouseEvent>,
      selectionRectRef: React.MutableRefObject<Konva.Shape>,
      layerRef: React.MutableRefObject<Konva.Layer>
    ) => {
      const position = event.target.getStage().getPointerPosition();
      currentPosition.current = position;
      if (false) {
        selection.current!.visible = false;
      }
      oldPos.current = null;
      if (!selection?.current?.visible) {
        return;
      }

      // assigning currentMutipleSelection to the multpleSelection in the stage with it's index
      currentMultipleSelection.current = multipleSelectionRefs.current[0];

      // getting properties of selectionRect
      const selBox = selectionRectRef?.current?.getClientRect();

      const elements = [];
      layerRef?.current?.children?.forEach((elementNode, index) => {
        if (
          elementNode !== currentMultipleSelection.current &&
          elementNode !== selectionRectRef.current
        ) {
          const elBox = elementNode?.getClientRect();
          if (Konvas.Util.haveIntersection(selBox, elBox)) {
            elements.push(elementNode);
          }
        }
      });

      currentMultipleSelection?.current?.setNodes(elements);
      //verying if we have any richText component
      let colectObjectsName = currentMultipleSelection.current
        ?.nodes()
        ?.map((object) => {
          return object?.attrs?.object;
        });

      if (colectObjectsName?.length === 1) {
        const myTextObject: Konva.NodeConfig = currentMultipleSelection.current
          ?.nodes()
          ?.map((object) => {
            return object;
          });

        if (myTextObject[0]?.attrs.object === "richTextImage") {
          if (isEditing === "open") {
            setSelectedObject(null);
          } else {
            setSelectedObject(myTextObject[0]);
          }
        }
      }

      if (colectObjectsName?.includes("richTextImage")) {
        currentMultipleSelection?.current?.anchorSize(0);
        currentMultipleSelection?.current?.rotateAnchorOffset(0);
      } else {
        currentMultipleSelection?.current?.anchorSize(10);
        currentMultipleSelection?.current?.rotateAnchorOffset(50);
      }

      setSelectedObjects(elements);
      selection.current!.visible = false;
      updateSelectionRect({ selectionRectRef });
    },
    [
      Konvas.Util,
      currentMultipleSelection,
      isEditing,
      multipleSelectionRefs,
      setSelectedObject,
      setSelectedObjects,
      updateSelectionRect,
    ]
  );

  //guide lines now

  const getLineGuideStops = useCallback((skipShape) => {
    let shapes = [
      ".circle",
      ".simpleLine",
      ".rectangle",
      ".star",
      ".svgShape",
      ".triangle",
      ".triangleNew",
      ".URLimage",
      ".URLImageDrag",
      ".richTextImage",
    ];
    // we can snap to stage borders and the center of the stage
    let vertical = [
      0,
      skipShape?.getStage()?.width() / 2,
      skipShape?.getStage()?.width(),
    ];
    let horizontal = [
      0,
      skipShape.getStage().height() / 2,
      skipShape.getStage().height(),
    ];

    // and we snap over edges and center of each object on the canvas
    shapes.forEach((currentShape, index) => {
      skipShape
        .getStage()
        .find(currentShape)
        .forEach((guideItem) => {
          if (guideItem === skipShape) {
            return;
          }
          let box = guideItem.getClientRect({
            relativeTo: skipShape.getStage(),
          });
          // and we can snap to all edges of shapes
          vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
          horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
        });
    });

    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    };
  }, []);

  // what points of the object will trigger to snapping?
  // it can be just center of the object
  // but we will enable all edges and center
  const getObjectSnappingEdges = useCallback((node) => {
    let box = node.getClientRect({ relativeTo: node.getStage() });
    let absPos = node.absolutePosition();

    return {
      vertical: [
        {
          guide: Math.round(box.x),
          offset: Math.round(absPos.x - box.x),
          snap: "start",
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: "center",
        },
        {
          guide: Math.round(box.x + box.width),
          offset: Math.round(absPos.x - box.x - box.width),
          snap: "end",
        },
      ],
      horizontal: [
        {
          guide: Math.round(box.y),
          offset: Math.round(absPos.y - box.y),
          snap: "start",
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: "center",
        },
        {
          guide: Math.round(box.y + box.height),
          offset: Math.round(absPos.y - box.y - box.height),
          snap: "end",
        },
      ],
    };
  }, []);

  // find all snapping possibilities
  const getGuides = useCallback((lineGuideStops, itemBounds) => {
    let resultV = [];
    let resultH = [];

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        let diff = Math.abs(lineGuide - itemBound.guide);
        // if the distance between guild line and object snap point is close we can consider this for snapping
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        let diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    let guides = [];

    // find closest snap
    let minV = resultV.sort((a, b) => a.diff - b.diff)[0];
    let minH = resultH.sort((a, b) => a.diff - b.diff)[0];
    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: "V",
        snap: minV.snap,
      });
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: "H",
        snap: minH.snap,
      });
    }
    return guides;
  }, []);

  const drawGuides = useCallback((guides) => {
    if (guides) {
      guides.forEach((lg) => {
        if (lg.orientation === "H") {
          let guide = {
            points: [-6000, 0, 6000, 0],
            stroke: "#663399",
            strokeWidth: 1,
            name: "guid-line",
            dash: [0.15, 0],
            x: 0,
            y: lg.lineGuide,
          };
          setHlines([...guides, guide]);
        } else if (lg.orientation === "V") {
          let guide = {
            points: [0, -6000, 0, 6000],
            stroke: "#663399",
            strokeWidth: 1,
            name: "guid-line",
            dash: [0.15, 0],
            x: lg.lineGuide,
            y: 0,
          };
          setVlines([...guides, guide]);
        }
      });
    }
  }, []);

  const onDragMove = useCallback((e) => {
    // clear all previous lines on the screen
    // layer.find('.guid-line').destroy();
    // find possible snapping lines
    let lineGuideStops = getLineGuideStops(e.target);
    // find snapping points of current object
    let itemBounds = getObjectSnappingEdges(e.target);

    // now find where can we snap current object
    let guides = getGuides(lineGuideStops, itemBounds);

    // do nothing of no snapping
    if (!guides.length) {
      return;
    }

    drawGuides(guides);

    let absPos = e.target.absolutePosition();
    // now force object position
    guides.forEach((lg) => {
      switch (lg.snap) {
        case "start": {
          switch (lg.orientation) {
            case "V": {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case "H": {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
            default:
              break;
          }
          break;
        }
        case "center": {
          switch (lg.orientation) {
            case "V": {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case "H": {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
            default:
              break;
          }
          break;
        }
        case "end": {
          switch (lg.orientation) {
            case "V": {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case "H": {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    });
    e.target.absolutePosition(absPos);
  }, []);

  const onDragEnd = () => {
    setHlines([]);
    setVlines([]);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <StageToolsContext.Provider
      value={{
        onMouseDown,
        onMouseMove,
        onMouseUp,
        h_lines,
        v_lines,
        onDragEnd,
        onDragMove,
        currentPosition,
        selection,
        isPendingMouseUp,
        setIsPendingMouseUp,
      }}
    >
      {children}
    </StageToolsContext.Provider>
  );
};

// creating hook
function useStageTools(): StageToolsDataProps {
  const context = useContext(StageToolsContext);

  if (!context) {
    throw new Error("useStageTools must be used with an StageToolsProvider");
  }

  return context;
}

export { StageToolsProvider, useStageTools };

