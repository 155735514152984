import styled from "styled-components";
import { Theme } from "../../../../../dtos/theme";

interface DashProps {
  dash?: number[];
}

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const DropDownContainer = styled.div`
  margin: 0 auto;
  display: flex;
`;

export const DropDownHeader = styled.div<{ Open: boolean }>`
  display: block;

  /* &:hover {
    cursor: pointer;
  } */
`;

export const DropDownListContainer = styled.div`
  cursor: default;
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: 0.9rem;
  position: absolute;
  padding: 4px;
  top: 24px;
  left: 0px;
  bottom: 0;
  min-height: 90px;
  width: 258px;
  background: white;
  -webkit-box-shadow: 3px 3px 15px -2px rgba(0, 0, 0, 0.71);
  box-shadow: 3px 3px 15px -2px rgba(0, 0, 0, 0.71);
  /* border: 2px solid #e5e5e5; */
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Roboto";
  color: ${({ theme }: Theme) => theme.colors.black};
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  cursor: default;

  /* &:hover {
    cursor: pointer;
  } */
`;

export const HorizontalStack = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const LineBarNormal = styled.div`
  margin-top: 1rem;
  border: 2px solid black;
  width: 100px;
`;

export const LineWithDash = styled.div<DashProps>`
  margin-top: 3rem;
  border: 2px solid black;
  border-style: dashed;
  width: 100px;
`;

export const HStack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerSlider = styled.div`
  display: flex;
  height: 50%;
  align-items: center;
  margin: 0 1rem;
`;

export const ContainerTextValue = styled.div`
  display: flex;
  height: 50%;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  transform: translateY(10px);
`;

export const Text = styled.span`
  font-family: "Roboto", sans-serif;
  color: black;
  font-size: 1.4rem;
`;
export const Input = styled.input`
  width: 45px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #999c9f;
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  text-align: center;

  :hover {
    border: 1px solid #000;
  }

  :focus {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;
