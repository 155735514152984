import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container, MainContainer } from "./styles";

import EndTrialModal from "../../../../../shared/components/EndTrialModal";
import { useModalContext } from "../../../../../hooks/modalContext";

import UxDocLogoLarge from "../../../../../shared/components/UxDocLogoLarge";
import TitleH3 from "../../../../../shared/components/TitleH3";
import ButtonMaxWidth from "../../../../../shared/components/ButtonMaxWidth";

const SuspendedView: React.FC = () => {
  const navigate = useNavigate();
  const { endTrialModal, endTrialWrapperRef, setEndTrialModal } =
    useModalContext();

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDeclinePayment = useCallback(async () => {
    setEndTrialModal(true);
  }, [isVisibleModal, setEndTrialModal]);

  const handleSubscription = useCallback(async () => {
    navigate("/payment-renew");
  }, []);

  useEffect(() => {
    document.title = `UX DOC | Problemas no pagamento`;
  }, []);

  return (
    <Container>
      <EndTrialModal
        ref={endTrialWrapperRef}
        isActive={endTrialModal}
        setIsActive={setEndTrialModal}
        source="unsubscription"
      />

      <UxDocLogoLarge />

      <MainContainer>
        <TitleH3 title="Ops... parece que houve algum problema com o seu pagamento." />

        <p>
          Mas não se preocupe! Clique no botão abaixo para renovar a sua
          assinatura ou alterar o meio de pagamento e volte a criar documentos.
          <br />
          Érapidinho!
        </p>

        <ButtonMaxWidth
          text="Ir para a área de pagamento"
          typeStyle="purple"
          type="submit"
          onClick={handleSubscription}
        />
        <ButtonMaxWidth
          text="Não tenho interesse agora"
          typeStyle="white"
          loading={loading}
          onClick={handleDeclinePayment}
        />
      </MainContainer>
    </Container>
  );
};

export default SuspendedView;
