import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: rgb(0, 0, 0, 0.5);
`;

export const Pages = styled.div`
  overflow-y: auto;
  width: 100%;
  padding-top: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

export const StageContainer = styled.div`
  width: 595.5px;
  height: 842px;
  background: white;
  transform: translateX(45px);
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  position: relative;
  background: rgb(0, 0, 0, 0.7);

  div {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
`;

export const FlexLoading = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
