import ButtonOptions from "../../../../components/ButtonOptions";
import { Container, Options, Main, Button } from "./styles";
import { useEffect, useState } from "react";
import OptionsTemplateMyproject from "../OptionsTemplateMyproject";
import OptionsTemplateSharedWithMe from "../OptionsTemplateSharedWithMe";
import OptionsTemplateTeam from "../OptionsTemplateTeam";
import OptionsTemplateOneClick from "../OptionsTemplateOneClick";
import { PagesProps } from "../../../../../../dtos/PagesProps";
import { UseMutateFunction } from "react-query";
import { IResponseCreationProjectFromBitsTemplates } from "../../../../../../dtos/CreateNewProjects";
import {
  AllMyProjects,
  IHandleChangeDocumentNameProps
} from "../../../../../../dtos/AllMyProjects";
// import { defineActiveUserInDocument } from "../../../../../editor/utils/getProfile";
// import { useSharedDocument } from "../../../../../editor/hooks/sharedDocument";
// import { IHandlePreviewTemplateProps } from "../../../../../../dtos/SharedInterfaces";

interface ICArdHoverProps {
  handleChooseTemplate: any /* UseMutateFunction<
    {
      pages: [];
      title: string;
    },
    unknown,
    IHandlePreviewTemplateProps,
    unknown
  >; */;
  setTemplateId: React.Dispatch<React.SetStateAction<string>>;
  id: string;
  options: boolean;
  handleMakeCopy: UseMutateFunction<any, unknown, string, unknown>;
  handleDeleteDocument: (id: string, name?: string) => Promise<void>;
  title: string;
  index: number;
  handleChangeDocumentName: UseMutateFunction<
    AllMyProjects,
    unknown,
    IHandleChangeDocumentNameProps,
    unknown
  >;
  page:
    | "Shared with me"
    | "My projects"
    | "Create new project"
    | "Team"
    | "One Click";
  handleEditTamplate?: (title: any, id: any) => Promise<void>;
  downloading: boolean;
  setDownloading: React.Dispatch<React.SetStateAction<boolean>>;
  idToastifyDownloadRef: any;
  whichFileToBeDownload: React.MutableRefObject<"pdf" | "word">;
  documentPreviewData: React.MutableRefObject<PagesProps[]>;
  handleUseTemplate: UseMutateFunction<
    IResponseCreationProjectFromBitsTemplates,
    unknown,
    string,
    unknown
  >;
  isIntentBuyModal?: boolean;
  setIsIntentBuyModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardHover = ({
  handleChooseTemplate,
  setTemplateId,
  id,
  options,
  handleMakeCopy,
  handleDeleteDocument,
  title,
  index,
  handleChangeDocumentName,
  page,
  handleEditTamplate,
  downloading,
  setDownloading,
  idToastifyDownloadRef,
  documentPreviewData,
  whichFileToBeDownload,
  handleUseTemplate,
  isIntentBuyModal,
  setIsIntentBuyModal
}: ICArdHoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  // const [loadingDefault, setLoadingDefault] = useState(false);
  // const [verifyApi, setVerifyApi] = useState(false);

  // const {
  //   setDocumentUsersAccess
  // } = useSharedDocument();


  // UseEffect para que possa ser atualizado o defineUser para liberar ou não o donwload
  // porém, está com um atraso, não sei como resolver isso.

  // useEffect(() => {
  //   (async () => {
  //     if (page === 'Shared with me') {
  //       await defineActiveUserInDocument({
  //         setLoadingDefault,
  //         templateid: id,
  //         setDocumentUsersAccess,
  //         setVerifyApi,
  //       });
  //     }
  //   })()
  // }, [page, id])

  return (
    <Container>
      {options && (
        <Options>
          <ButtonOptions setIsOpen={setIsOpen} />
          {isOpen && page === "My projects" && (
            <OptionsTemplateMyproject
              isEditName={isEditName}
              setIsEditName={setIsEditName}
              handleMakeCopy={handleMakeCopy}
              id={id}
              setIsOpen={setIsOpen}
              handleDeleteDocument={handleDeleteDocument}
              title={title}
              index={index}
              handleChangeDocumentName={handleChangeDocumentName}
              downloading={downloading}
              setDownloading={setDownloading}
              idToastifyDownloadRef={idToastifyDownloadRef}
              documentPreviewData={documentPreviewData}
              whichFileToBeDownload={whichFileToBeDownload}
              isIntentBuyModal={isIntentBuyModal}
              setIsIntentBuyModal={setIsIntentBuyModal}
            />
          )}
          {isOpen && page === "One Click" && (
            <OptionsTemplateOneClick
              isEditName={isEditName}
              setIsEditName={setIsEditName}
              handleMakeCopy={handleMakeCopy}
              id={id}
              setIsOpen={setIsOpen}
              handleDeleteDocument={handleDeleteDocument}
              title={title}
              index={index}
              handleChangeDocumentName={handleChangeDocumentName}
              downloading={downloading}
              setDownloading={setDownloading}
              idToastifyDownloadRef={idToastifyDownloadRef}
              documentPreviewData={documentPreviewData}
              whichFileToBeDownload={whichFileToBeDownload}
              isIntentBuyModal={isIntentBuyModal}
              setIsIntentBuyModal={setIsIntentBuyModal}
            />
          )}
          {isOpen && page === "Shared with me" && (
            <OptionsTemplateSharedWithMe
              id={id}
              index={index}
              title={title}
              // downloading={downloading}
              // setDownloading={setDownloading}
              // idToastifyDownloadRef={idToastifyDownloadRef}
              // documentPreviewData={documentPreviewData}
              // whichFileToBeDownload={whichFileToBeDownload}
              // isIntentBuyModal={isIntentBuyModal}
              // setIsIntentBuyModal={setIsIntentBuyModal}
            />
          )}
          {isOpen && page === "Team" && (
            <OptionsTemplateTeam
              index={index}
              handleUseTemplate={handleUseTemplate}
              id={id}
              title={title}
              handleEditTamplate={handleEditTamplate}
              handleDeleteDocument={handleDeleteDocument}
            />
          )}
        </Options>
      )}

      <Main onClick={() => handleUseTemplate(id)}>
        <p>
          {page === "Shared with me" || page === "One Click"
            ? "Abrir projeto"
            : "Usar template"}
        </p>
      </Main>
      <Button
        onClick={() => {
          handleChooseTemplate({ templateId: id, title: title });
          setTemplateId(id);
        }}
      >
        Pré-visualizar
      </Button>
    </Container>
  );
};

export default CardHover;
