export const firstTheme = {
  name: "first",
  colors: {
    gradientBody: "#3c2058",
    gradientBody2: "#663399",
    black: "#000",
    background: "#663399",
    border: "#C4C4C4",
    gray900: "#343A40",
    gray300: "#ADB5BD",
    gray500: "#EEEEEE",
    backgroundEditor: "#F8F9FA",
    red: "#520202",
    whiteColor: "#fff",
    backgroundAsideDash: "#fafafa",
    colorTextAsideDash: "#a5a5a5",

    black100: "#343A40",
    black75: "#676B70",
    black50: "#999C9F",
    black20: "#D6D8D9",
    black10: "#EBEBEC",
    black5: "#F5F5F5",
    primary: "#663399",
    primary90: "#7547A3",
    white100: "#FFF",
    error100: "#DE2D2D",
    error10: "#FCEAEA",
    focus: "#5D5FEF",
    emphasis: "#9945EE"
  }
};

export const secondTheme = {
  name: "second",
  colors: {
    primary: "#fff",
    black: "#fff",
    gradientBody: "#220221",
    gradientBody2: "#fff",
    background: "#fff",
    border: "#fff",
    gray900: "#343A40",
    gray300: "#ADB5BD",
    gray500: "#EEEEEE",
    backgroundEditor: "#FAFBFD",
    red: "#520202",
    colorTextAsideDash: "#a5a5a5"
  }
};
