import React from "react";

import { Container, ContainerTwo } from "./styles";

interface MessageTeamsClauseInfoProps {
  text: string;
  className?: string;
  isSpecific: boolean;
}

const MessageTeamsClauseInfo: React.FC<MessageTeamsClauseInfoProps> = ({
  text,
  className,
  isSpecific
}) => {
  return (
    <Container className={className}>
      <span> {text} </span>
    </Container>
  );
};

export default MessageTeamsClauseInfo;
