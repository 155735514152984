import {
  Container,
  TitleItem,
  OptionsItem,
  SubscriberBox,
  AvatarCustom,
  Users
} from "./styles";
import { useCallback, useEffect, useState } from "react";
import {
  ArrayOfTeamListProps,
  ISelectModalTeam
} from "../../../../../dtos/teamsUtils";
import api from "../../../../../services/api";
import OptionsModal from "./components/OptionsModal";

interface ITeamItemProps {
  item: ArrayOfTeamListProps;
  setCreatedTeamId: React.Dispatch<React.SetStateAction<string>>;
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
  setIsSettings: React.Dispatch<React.SetStateAction<boolean>>;
  setNameOfTeam: React.Dispatch<React.SetStateAction<string>>;
  setIdLeaveTeam: React.Dispatch<React.SetStateAction<string>>;
}

const TeamItem = ({
  item,
  setCreatedTeamId,
  setSelectModal,
  setIsSettings,
  setNameOfTeam,
  setIdLeaveTeam
}: ITeamItemProps) => {
  const [isOpenModalOptions, setIsOpenModalOptions] = useState(false);
  const [usersTeam, setUsersTeam] = useState([]);
  const [isVisibleOption, setIsVisibleOption] = useState(false);

  const getUsersTeam = useCallback(async (id: string) => {
    try {
      const response = await api.get(`team-users/${id}`);
      setUsersTeam(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getUsersTeam(item.team_id);
  }, []);

  return (
    <Container
      onMouseEnter={() => setIsVisibleOption(true)}
      onMouseLeave={() => setIsVisibleOption(false)}
    >
      <TitleItem to={`/view-team/${item.team_id}/templates`}>
        <p>{item.name}</p>
      </TitleItem>
      <Users>
        <SubscriberBox>
          {usersTeam?.slice(0, 4).map((user, indexOfUser) => (
            <AvatarCustom indexOfUser={indexOfUser + 1} key={user.id}>
              <span>
                {`${user.first_name.charAt(0).toUpperCase()}${user.last_name
                  .charAt(0)
                  .toUpperCase()}`}
              </span>
            </AvatarCustom>
          ))}
        </SubscriberBox>
      </Users>
      <p>{item.is_owner ? "Proprietário" : "Membro"}</p>
      {isVisibleOption && (
        <OptionsItem
          onClick={() => setIsOpenModalOptions(!isOpenModalOptions)}
          onMouseLeave={() => setIsOpenModalOptions(false)}
          isActive={isOpenModalOptions}
        >
          <span />
          <span />
          <span />
          {isOpenModalOptions && (
            <OptionsModal
              isWoner={item.is_owner}
              setCreatedTeamId={setCreatedTeamId}
              setSelectModal={setSelectModal}
              setIsSettings={setIsSettings}
              teamId={item.team_id}
              teamName={item.name}
              setNameOfTeam={setNameOfTeam}
              setIdLeaveTeam={setIdLeaveTeam}
            />
          )}
        </OptionsItem>
      )}
    </Container>
  );
};

export default TeamItem;
