//this component render for example Konva Shape to show in our canvas
import Konva from "konva";

import { Text, Transformer } from "react-konva";
import { PagesProps } from "../../../../../dtos/PagesProps";
import { IRenderedObject } from "../../../../../dtos/RenderedObject";
import { Html } from "react-konva-utils";
import { memo } from "react";
//set up interfaces
interface AutoNumberPageProps {
  shapeProps: IRenderedObject | Konva.Text | Konva.Shape | any;
  index: number;
}

const AutoNumberPage: React.FC<AutoNumberPageProps> = ({ shapeProps }) => {
  return (
    <>
      <Text name="autoNumberPage" zIndex={9999} {...shapeProps} />
    </>
  );
};

export default memo(AutoNumberPage);
