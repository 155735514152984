import styled from "styled-components";

export const Header = styled.div``;

export const UpdatesLabel = styled.h5`
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black100};

  figure {
    display: flex;

    svg {
      height: 20px;
      width: auto;
      color: ${({ theme }) => theme.colors.black50};
    }
  }
`;
