import React, { InputHTMLAttributes, useEffect, useRef } from "react";
import { Container, InputVariable, LabelVariable } from "./styles";
import { useField } from "@unform/core";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: {};
}

const Input: React.FC<InputProps> = ({ name, containerStyle, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value"
    });
  }, [fieldName, registerField]);

  return (
    <Container style={containerStyle}>
      <InputVariable defaultValue={defaultValue} ref={inputRef} {...rest} />
    </Container>
  );
};

export default Input;

