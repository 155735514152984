import { useAuth } from "../../../../../../../hooks/auth";
import ButtonMaxWidth from "../../../../../../../shared/components/ButtonMaxWidth";
import {
  Avatar,
  BoxInput,
  BoxInputSelect,
  ButtonStripe,
  ButtonSubmit,
  ChangeAvatar,
  Container,
  ContainerLogin,
  Form,
  HoverAvatar,
} from "./style";
import { useCallback, useEffect, useState } from "react";
import IconCam from "../../assets/camera_icon.svg";
import StyledMask from "../../../../components/StyledMask/Index";
import api from "../../../../../../../services/api";
import { toast } from "react-toastify";
import ImageUploading from "react-images-uploading";
import { useMainHook } from "../../../../../../../hooks/main";

import { LoadingFlex } from "../../../../components/ChangePassword/styles";
import { LoadingSpinner } from "../../../../../../../shared/components/SpinnerLoading";
import { ContractAnimation } from "../../../../../../../shared/components/ContractAnimation";
import { useTwoFactorAuth } from "../../../../../../../hooks/twoFactorAuth";

type TypeDocumentProps = "cpf" | "cnpj";

export const PersonalDataPage = () => {
  const { data } = useAuth();
  const { isUserValidateOTP } = useTwoFactorAuth();
  const { setRefreshAvatar, refreshAvatar } = useMainHook();

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    document: "",
    where_do_you_work: "",
    is_individual: true,
    email_sending_authorization: false,
    how_did_you_meet_us: "",
    avatar: "",
  });

  const [isHoverAvatar, setIsHoverAvatar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [avatarLoding, setAvatarLoding] = useState(false);
  const [isUserStripe, setIsUserStripe] = useState(true);
  const [typeDocument, setTypeDocument] = useState<TypeDocumentProps>(
    userData.document?.length > 11 ? "cnpj" : "cpf"
  );

  const [images, setImages] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const responseProfile = await api.get("profile");
        const responseQuestion = await api.get("create-account-questions");

        setUserData({
          first_name: responseProfile.data?.people.first_name,
          last_name: responseProfile.data?.people.last_name,
          email: responseProfile.data?.user.email,
          phone: responseProfile.data?.contacts.phone,
          document: responseProfile.data?.people.document,
          is_individual: true,
          email_sending_authorization:
            responseProfile.data?.email_sending_authorization,
          where_do_you_work: responseQuestion.data?.where_do_you_work,
          how_did_you_meet_us: responseQuestion.data?.how_did_you_meet_us,
          avatar: responseProfile.data?.user.avatar_url,
        });

        setIsLoading(false);
      } catch (err) {
        console.error("Error when fetching user data", err);
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const requestForStripe = await api.post("stripe/create-session-portal");

        if (requestForStripe.status === 200) {
          setIsUserStripe(true);
        } else {
          setIsUserStripe(false);
        }
      } catch (err) {
        setIsUserStripe(false);
      }
    })();
  }, []);

  const handleSubmitChanges = useCallback(async () => {
    if (!isUserValidateOTP && data?.user?.otp_enabled) {
      toast.error("Autenticação não realizada!");
    } else {
      try {
        const {
          email,
          first_name,
          last_name,
          phone,
          document,
          is_individual,
          email_sending_authorization,
        } = userData;

        await api.put("profile", {
          email,
          first_name,
          last_name,
          phone,
          document,
          is_individual,
          email_sending_authorization,
        });

        if (
          userData.where_do_you_work !==
          data?.account_questions.where_do_you_work
        ) {
          await api.put("create-account-questions", {
            where_do_you_work: userData.where_do_you_work,
            how_did_you_meet_us: userData.how_did_you_meet_us,
          });
        }

        toast.success("Perfil atualizado com sucesso!");
      } catch (error) {
        if (
          error.response.data.message === "Invalid CPF." ||
          error.response.data.message === "Invalid CNPJ."
        ) {
          toast.error("CPF ou CNPJ inválido!");
        } else {
          toast.error("Todos os campos são obrigatórios");
        }
      }
    }
  }, [
    data?.account_questions.where_do_you_work,
    userData,
    data?.user?.otp_enabled,
    isUserValidateOTP,
  ]);

  const handleChangeAvatar = async (imageList, addUpdateIndex) => {
    if (!isUserValidateOTP && data?.user?.otp_enabled) {
      toast.error("Autenticação não realizada!");
    } else {
      // data for submit
      setAvatarLoding(true);
      setImages(imageList);
      let formData = new FormData();
      formData.append("avatar", imageList[0].file);
      try {
        const response = await api.patch(`users/avatar`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setUserData((state) => {
          return {
            ...state,
            avatar: response.data.avatar_url,
          };
        });
        setRefreshAvatar(!refreshAvatar);
        setImages([]);
        toast.success("Foto de perfil atualizada com sucesso!");
      } catch (err) {
        console.error(err, "error when update avatar");
      }
      setAvatarLoding(false);
    }
  };

  const handlePortalCustomer = useCallback(async () => {
    try {
      const response = await api.post("stripe/create-session-portal");
      const { url } = response.data;
      window.open(`${url}`);
    } catch (err) {
      alert(err.message);
    }
  }, []);

  return (
    <Container>
      <h4>Dados pessoais</h4>

      {isLoading ? (
        <ContainerLogin>
          <ContractAnimation />
        </ContainerLogin>
      ) : (
        <section>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitChanges();
            }}
          >
            <BoxInput>
              <label htmlFor="">Nome</label>
              <input
                type="text"
                value={userData.first_name}
                onChange={(e) => {
                  setUserData({ ...userData, first_name: e.target.value });
                }}
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="">Sobrenome</label>
              <input
                type="text"
                value={userData.last_name}
                onChange={(e) => {
                  setUserData({ ...userData, last_name: e.target.value });
                }}
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="">E-mail</label>
              <input
                type="text"
                style={{ cursor: "not-allowed" }}
                readOnly
                value={userData.email}
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="">Celular</label>
              <input
                type="number"
                value={userData.phone}
                onChange={(e) => {
                  setUserData({ ...userData, phone: e.target.value });
                }}
              />
            </BoxInput>

            <BoxInput>
              <select
                onChange={(e) =>
                  setTypeDocument(e.target.value as TypeDocumentProps)
                }
              >
                <option value={"cpf"}>CPF</option>
                <option value={"cnpj"}>CNPJ</option>
              </select>

              <StyledMask
                mask={
                  typeDocument === "cpf"
                    ? "000.000.000-00"
                    : "00.000.000/0000-00"
                }
                value={userData.document}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    document: e.target.value,
                  });
                }}
              />
            </BoxInput>

            <BoxInputSelect>
              <label htmlFor="">Onde você atua?</label>
              <select
                value={userData.where_do_you_work}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    where_do_you_work: e.target.value,
                  });
                }}
              >
                <option value="Escritório de advocacia pequeno">
                  Escritório de advocacia pequeno
                </option>
                <option value="Escritório de advocacia grande">
                  Escritório de advocacia grande
                </option>
                <option value="Departamento jurídico de empresa">
                  Departamento jurídico de empresa
                </option>
                <option value="Sou advogado autônomo (a)">
                  Sou advogado autônomo (a)
                </option>
                <option value="Sou estudante de Direito">
                  Sou estudante de Direito
                </option>
                <option value="Não tenho formação na área jurídica">
                  Não tenho formação na área jurídica
                </option>
              </select>
            </BoxInputSelect>

            <ButtonSubmit type="submit">Salvar alterações</ButtonSubmit>

            {isUserStripe && (
              <ButtonStripe type="button" onClick={handlePortalCustomer}>
                Gerenciar assinatura
              </ButtonStripe>
            )}

            <a
              href="https://bitsacademy.typeform.com/to/IWl1i1ly"
              target="_blank"
              rel="noreferrer"
            >
              Remover os dados da conta
            </a>
          </Form>

          <ChangeAvatar>
            <ImageUploading
              multiple
              value={images}
              onChange={handleChangeAvatar}
              maxNumber={69}
              dataURLKey="data_url"
            >
              {({ onImageUpload, dragProps }) => (
                // write your building UI
                <Avatar
                  onMouseEnter={() => setIsHoverAvatar(true)}
                  onMouseLeave={() => setIsHoverAvatar(false)}
                >
                  {avatarLoding ? (
                    <LoadingFlex>
                      <LoadingSpinner />
                    </LoadingFlex>
                  ) : (
                    <img src={userData.avatar} alt="Imagem de perfil" />
                  )}

                  {isHoverAvatar && (
                    <HoverAvatar onClick={onImageUpload} {...dragProps}>
                      <img src={IconCam} alt="" />
                    </HoverAvatar>
                  )}
                </Avatar>
              )}
            </ImageUploading>

            <ImageUploading
              multiple
              value={images}
              onChange={handleChangeAvatar}
              maxNumber={69}
              dataURLKey="data_url"
            >
              {({ onImageUpload, dragProps }) => (
                // write your building UI
                <ButtonMaxWidth
                  onClick={onImageUpload}
                  text="Faça upload da sua foto"
                  typeStyle="white"
                />
              )}
            </ImageUploading>
          </ChangeAvatar>
        </section>
      )}
    </Container>
  );
};
