import React from "react";

// import { Container } from './styles';

interface BoldProps {
  isActive: boolean;
}
const JustifyAlign: React.FC<BoldProps> = ({ isActive }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mingcute:align-justify-line">
        <path
          id="Vector"
          d="M3.11111 3.25391C2.81643 3.25391 2.53381 3.37096 2.32544 3.57932C2.11706 3.78768 2 4.07027 2 4.36494C2 4.6596 2.11706 4.94219 2.32544 5.15055C2.53381 5.35891 2.81643 5.47597 3.11111 5.47597H20.8889C21.1836 5.47597 21.4662 5.35891 21.6746 5.15055C21.8829 4.94219 22 4.6596 22 4.36494C22 4.07027 21.8829 3.78768 21.6746 3.57932C21.4662 3.37096 21.1836 3.25391 20.8889 3.25391H3.11111ZM3.11111 8.80905C2.81643 8.80905 2.53381 8.92611 2.32544 9.13447C2.11706 9.34282 2 9.62542 2 9.92008C2 10.2147 2.11706 10.4973 2.32544 10.7057C2.53381 10.9141 2.81643 11.0311 3.11111 11.0311H20.8889C21.1836 11.0311 21.4662 10.9141 21.6746 10.7057C21.8829 10.4973 22 10.2147 22 9.92008C22 9.62542 21.8829 9.34282 21.6746 9.13447C21.4662 8.92611 21.1836 8.80905 20.8889 8.80905H3.11111ZM2 15.4752C2 15.1806 2.11706 14.898 2.32544 14.6896C2.53381 14.4813 2.81643 14.3642 3.11111 14.3642H20.8889C21.1836 14.3642 21.4662 14.4813 21.6746 14.6896C21.8829 14.898 22 15.1806 22 15.4752C22 15.7699 21.8829 16.0525 21.6746 16.2608C21.4662 16.4692 21.1836 16.5863 20.8889 16.5863H3.11111C2.81643 16.5863 2.53381 16.4692 2.32544 16.2608C2.11706 16.0525 2 15.7699 2 15.4752ZM3.11111 19.9193C2.81643 19.9193 2.53381 20.0364 2.32544 20.2448C2.11706 20.4531 2 20.7357 2 21.0304C2 21.325 2.11706 21.6076 2.32544 21.816C2.53381 22.0244 2.81643 22.1414 3.11111 22.1414H20.8889C21.1836 22.1414 21.4662 22.0244 21.6746 21.816C21.8829 21.6076 22 21.325 22 21.0304C22 20.7357 21.8829 20.4531 21.6746 20.2448C21.4662 20.0364 21.1836 19.9193 20.8889 19.9193H3.11111Z"
          fill={isActive ? "#663399" : "#343A40"}
        />
      </g>
    </svg>
  );
};

export default JustifyAlign;
