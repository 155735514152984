import React, { useCallback, useEffect, useState } from "react";
import { useTeams } from "../../../../../../hooks/teams";
import IconSvg from "../../../IconSvg";
import CardElements from "../../../Sidebar/components/CardElements";
import GridElements from "../../../Sidebar/components/GridElements";
import GridItemElements from "../../../Sidebar/components/GridItemElements";
import { HStack } from "../../../UploadImages/styles";
import iconTeste from "../../assets/testing.svg";
import iconArrow from "../../assets/icon-arrow.svg";

import {
  BackToTeamsList,
  Box,
  BoxMessage,
  Container,
  Text,
  ContainerList,
  LoadingContainerImages,
  TeamName,
  TextTip,
  ContainerListSpecific
} from "./styles";
import api from "../../../../../../services/api";
import { useMainHook } from "../../../../../../hooks/main";
import { LoadingContainer } from "../../../../../dashboard/Teams/pages/TemplatesTeamPage/styles";
import { ContractAnimation } from "../../../../../../shared/components/ContractAnimation";
import ReactLoading from "react-loading";
import Tooltip from "../../../Tooltip";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../shared/utils/MountIcons";

interface TeamProps {
  team_users_id: string;
  team_id: string;
  name: string;
  created_at: string;
}

interface ImagesTeamProps {
  created_at: string;
  id: string;
  image_url: string;
  name: string;
  team_id: string;
  updated_at: string;
}

const UploadImagesTeam: React.FC = () => {
  const { createdTeamId, setCreatedTeamId } = useTeams();
  const [teamSpecific, setTeamSpecific] = useState(false);
  const [imagesTeam, setImagesTeam] = useState<ImagesTeamProps[]>([]);
  const [teams, setTeams] = useState<TeamProps[]>([]);
  const [nameTeam, setNameTeam] = useState([]);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [loadingImages, setLoadingImages] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasNewPage, setHasNewPage] = useState(false);
  const [currentPageImages, setCurrentPageImages] = useState(0);
  const [hasMoreImages, setHasMoreImages] = useState(false);

  const { dragUrl } = useMainHook();

  const handleClickTeam = useCallback(
    (teamId, teamName) => {
      setCreatedTeamId(teamId);
      setTeamSpecific(true);
      setNameTeam(teamName);
    },
    [setCreatedTeamId]
  );

  const handleBackTeamList = useCallback(() => {
    setTeamSpecific(false);
    setCreatedTeamId("");
  }, [setCreatedTeamId]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingImages(true);
        const responseTeams = await api.get("team-users/list-user-teams", {
          params: {
            pagination: currentPageImages
          }
        });
        setTeams(oldState => [...oldState, ...responseTeams.data.dataArray]);
        setLoadingTeams(false);
      } catch (err) {
        setLoadingTeams(false);
        console.error(err, "error when get teams list");
      }
    })();
  }, [currentPageImages]);

  useEffect(() => {
    (async () => {
      try {
        const responseTeamsListImages = await api.get(
          "team-users/list-user-teams",
          {
            params: {
              pagination: currentPageImages + 1
            }
          }
        );
        if (responseTeamsListImages.data.dataArray.length > 0) {
          setHasMoreImages(true);
        } else {
          setHasMoreImages(false);
        }
      } catch (err) {
        console.error(err, "ERROR when get list of teams");
      }
    })();
  }, [currentPageImages]);

  useEffect(() => {
    if (!loadingTeams) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries?.some(entry => entry?.isIntersecting)) {
          if (hasMoreImages) {
            setCurrentPageImages(oldState => oldState + 1);
          }
        }
      });

      const myDiv = document.querySelector("#sentinel");

      if (myDiv) {
        intersectionObserver?.observe(myDiv);
      }
      return () => intersectionObserver?.disconnect();
    }
  }, [loadingTeams, hasMoreImages]);

  useEffect(() => {
    (async () => {
      if (teamSpecific) {
        try {
          // setLoadingImages(true);
          const responseImages = await api.get(`team-images/${createdTeamId}`, {
            params: {
              pagination: currentPage
            }
          });

          setImagesTeam(prevState => [
            ...prevState,
            ...responseImages.data.dataArray
          ]);

          setTotalPages(responseImages.data.pagination);
          setLoadingImages(false);
        } catch (err) {
          console.error(err, "ERROR when response images");
        }
      } else {
        setImagesTeam([]);
        setLoadingImages(true);
      }
    })();
  }, [currentPage, createdTeamId, teamSpecific]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (!loadingImages) {
        if (entries.some(entry => entry.isIntersecting)) {
          if (hasNewPage) {
            setCurrentPage(oldState => oldState + 1);
          }
        }
      }
    });
    const myDiv = document.querySelector("#sentinela-upload-images");

    if (myDiv) {
      intersectionObserver?.observe(myDiv);
    }

    return () => {
      intersectionObserver?.disconnect();
    };
  }, [hasNewPage, loadingImages]);

  useEffect(() => {
    return () => {
      setCurrentPage(0);
    };
  }, [createdTeamId]);

  useEffect(() => {
    if (teamSpecific) {
      (async () => {
        try {
          const responseImages = await api.get(`team-images/${createdTeamId}`, {
            params: {
              pagination: currentPage + 1
            }
          });

          if (responseImages.data.dataArray.length > 0) {
            setHasNewPage(true);
          } else {
            setHasNewPage(false);
          }
          // setLoadingImages(false);
        } catch (err) {
          console.error(err, "ERROR when response images");
        }
      })();
    }
  }, [teamSpecific, currentPage]);

  useEffect(() => {
    return () => {
      setTeams([]);
    };
  }, []);

  return (
    <Container>
      {!teamSpecific ? (
        <ContainerList>
          <BoxMessage>
            <span>
              Apenas o proprietário da equipe pode incluir os uploads.
            </span>
          </BoxMessage>

          {loadingTeams ? (
            <LoadingContainer>
              <ContractAnimation />
            </LoadingContainer>
          ) : (
            <>
              {teams.length > 0 ? (
                teams.map(team => (
                  <Box
                    key={team.team_id}
                    onClick={() => handleClickTeam(team.team_id, team.name)}
                  >
                    <TeamName> {team.name} </TeamName>
                    <PersonalizedIcon
                      dPath={MountIcons.IconArrowRight.dPath}
                      viewBox={MountIcons.IconArrowRight.viewBox}
                      inactivatedColor="#676B70"
                    />
                  </Box>
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Text>Não há equipes para listar.</Text>
                </div>
              )}
              <div className="spacing"></div>
              {hasMoreImages && (
                <div
                  style={{
                    width: "100%",
                    minHeight: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  id="sentinel"
                >
                  <ReactLoading
                    type="spin"
                    color="#000"
                    height={25}
                    width={25}
                  />
                </div>
              )}
            </>
          )}
        </ContainerList>
      ) : (
        <ContainerListSpecific>
          <BackToTeamsList onClick={handleBackTeamList}>
            <PersonalizedIcon
              dPath={MountIcons.IconArrowLeft.dPath}
              viewBox={MountIcons.IconArrowLeft.viewBox}
              inactivatedColor="#999C9F"
            />
            <span> {nameTeam} </span>
          </BackToTeamsList>
          {/* <TextTip>
              Arraste seu upload para o editor para inserir no documento
            </TextTip> */}
          {loadingImages ? (
            <LoadingContainerImages>
              <ContractAnimation />
            </LoadingContainerImages>
          ) : (
            <ContainerList>
              {imagesTeam.length === 0 ? (
                <div
                  style={{
                    width: "100%",
                    height: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Text>Não há imagens para listar.</Text>
                </div>
              ) : (
                <GridElements columns={4} className="grid-upload-images2">
                  <>
                    {imagesTeam.map(image => (
                      <GridItemElements
                        key={image.id}
                        isFixSize
                        className="wrapper-upload-images"
                      >
                        <CardElements>
                          <Tooltip
                            title="Arraste para usar"
                            placement="bottom-start"
                          >
                            <img
                              draggable
                              src={image.image_url}
                              alt={`img#@@#${image.name}`}
                              style={{
                                height: "60px",
                                width: "60px"
                              }}
                              onDragStart={(
                                e: React.DragEvent<HTMLImageElement>
                              ) => {
                                dragUrl.current = e.target;
                              }}
                            />
                          </Tooltip>
                        </CardElements>
                      </GridItemElements>
                    ))}
                  </>
                </GridElements>
              )}
              {imagesTeam.length > 0 && hasNewPage ? (
                <div
                  style={{
                    width: "100%",
                    height: "40px",
                    marginTop: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  id="sentinela-upload-images"
                >
                  <ReactLoading
                    type="spin"
                    color="#000"
                    height={22}
                    width={22}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "50px",
                    marginTop: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                ></div>
              )}
            </ContainerList>
          )}
        </ContainerListSpecific>
      )}
    </Container>
  );
};

export default UploadImagesTeam;
