import React, { HTMLProps, useEffect, useRef } from "react";

import { Container, MenuBoxFontFamily } from "./styles";
import checkIcon from "./assets/check.svg";

interface FontFamilyMenuProps {
  onClose?: () => void;
  changeFontSize?: (size: string) => void;
  handleOpen?: () => void;
  sizeFont?: string;
  setBlockClose: React.Dispatch<React.SetStateAction<boolean>>;
  callback: (size: string) => void;
  isCurrentValue?: string;
}

const LineHeightMenu: React.FC<FontFamilyMenuProps> = ({
  onClose,
  callback,
  changeFontSize,
  handleOpen,
  sizeFont,
  children,
  setBlockClose,
  isCurrentValue
}) => {
  let fontFamilyArr = ["1.0", "1.2", "1.5", "2.0", "2.5", "3.0"];

  return (
    <>
      <MenuBoxFontFamily
        onMouseEnter={() => setBlockClose(true)}
        onMouseLeave={() => setBlockClose(false)}
        // {...rest}
      >
        {fontFamilyArr.map(size => (
          <div onClick={() => callback(size)} key={size}>
            <span>{size}</span>
            {isCurrentValue && isCurrentValue === size && (
              <img src={checkIcon} alt="" />
            )}
          </div>
        ))}
      </MenuBoxFontFamily>
    </>
  );
};

export default LineHeightMenu;
