import { ReactNode } from "react";
import { Button } from "./styles";

interface ButtonClausesProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  height?: string;
  borderRadius?: string;
  disabledButtonOpacity?: string;
}

const ButtonPrimaryFixed: React.FC<ButtonClausesProps> = ({
  height,
  children,
  borderRadius,
  disabledButtonOpacity,

  ...rest
}) => {
  return (
    <Button
      disabledButtonOpacity={disabledButtonOpacity}
      borderRadius={borderRadius}
      height={height}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ButtonPrimaryFixed;
