import { BoxButton, Modal, Text, Category, Title } from "./styled";

import { useTeams } from "../../../../../../hooks/teams";
import { useCallback, useEffect, useState } from "react";
import api from "../../../../../../services/api";

import { ContractAnimation } from "../../../../../../shared/components/ContractAnimation";
import { toast } from "react-toastify";
import TitleH5 from "../../../../../../shared/components/TitleH5";
import iconCopy from "../../../../../../shared/assets/copy_line-icone.svg";
import ButtonExit from "../../../../../../shared/components/ButtonExit";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import { ISelectModalTeam } from "../../../../../../dtos/teamsUtils";

interface ClauseSpecificProps {
  id: string;
  team_id: string;
  title: string;
  clause: string;
  key_words: string;
  created_at: string;
  updated_at: string;
}

interface IModalCreateTextProps {
  setIsSettings: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
  isOwnerTeam: boolean;
}

const ModalViewText = ({
  setIsSettings,
  setSelectModal,
  isOwnerTeam
}: IModalCreateTextProps) => {
  const { clauseId, setClauseId } = useTeams();

  const [clauseItem, setClauseItem] = useState<ClauseSpecificProps>(
    {} as ClauseSpecificProps
  );

  const [loading, setLoading] = useState(true);

  const handleCopyClause = useCallback((text: string) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Texto copiado com sucesso!");
    } catch (err) {
      console.error(err);
      toast.error("Erro ao copiar texto");
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const responseSpecificClause = await api.get(
          `team-clauses/list-specific/${clauseId}`
        );
        setClauseItem(responseSpecificClause.data);
        setLoading(false);
      } catch (err) {
        console.error(err, "ERROR WHEN GET specific id clause");
      }
    })();
  }, []);

  return (
    <Modal>
      <Title>
        <TitleH5 title={clauseItem?.title} />
        <div>
          <img
            onClick={() => {
              handleCopyClause(clauseItem?.clause);
            }}
            src={iconCopy}
            alt=""
          />
          <ButtonExit
            onClick={() => setSelectModal({ modal: "", isOpen: false })}
          />
        </div>
      </Title>
      <Category>
        <p>Categoria:</p>
        <ul>
          <p>{clauseItem?.key_words}</p>
        </ul>
      </Category>
      <Text>{clauseItem?.clause}</Text>

      <BoxButton>
        {isOwnerTeam ? (
          <ButtonMaxWidth
            text="Editar"
            typeStyle="purple"
            onClick={() => {
              setClauseId(clauseItem.id);
              setIsSettings(true);
              setSelectModal({ modal: "Create New Text", isOpen: true });
            }}
          />
        ) : (
          <ButtonMaxWidth
            text="Fechar"
            typeStyle="purple"
            onClick={() => setSelectModal({ modal: "", isOpen: false })}
          />
        )}
      </BoxButton>
    </Modal>
  );
};

export default ModalViewText;
