import React from "react";
import DropdownZoom from "../../../DropdownZoom";
import IconToolbar from "../../../IconToolbar";

import ButtonToolbar from "../../ButtonToolbar";
import DropdownOpacity from "../../DropdownOpacity";
import LockedIcon from "../../icons/LockedIcon";
import UnlockIcon from "../../icons/UnlockIcon";
import { Container, ContainerImageLeft, ContainerRight } from "./styles";

import Tooltip from "../../../Tooltip";

import ButtonRuler from "../../../../../../shared/components/ButtonRuler";
import { useWorkspaceEditor } from "../../../../hooks/workspaceEditor";
import AutomaticNumbering from "../../../AutomaticNumbering";

// import { Container } from './styles';

interface ImageToolProps {
  isActiveBringToForward: boolean;
  handleClickBringToForwardIcon: () => void;
  isActiveTakeBack: boolean;
  handleClickTakeBackIcon: () => void;
  handleAllToFront: (idPage: number) => void;
  handleAllToBack: (idPage: number) => void;
  idPage: number;
  valueOpacity: number;
  setValueOpacity: React.Dispatch<React.SetStateAction<number>>;
  handleChangeOpacity: (value: number) => void;
  handleClickOpacity: () => void;
  handleBlockElements: () => void;
  handleToggleBlockElement: () => void;
  handleDeleteElement: () => void;
  isDraggable: boolean;
  iconTrash: string;
}

const ImageTool: React.FC<ImageToolProps> = ({
  isActiveBringToForward,
  handleClickBringToForwardIcon,
  isActiveTakeBack,
  handleClickTakeBackIcon,
  handleAllToFront,
  handleAllToBack,
  idPage,
  valueOpacity,
  setValueOpacity,
  handleChangeOpacity,
  handleClickOpacity,
  handleBlockElements,
  handleToggleBlockElement,
  handleDeleteElement,
  isDraggable,
  iconTrash
}) => {
  const { handleShowRuler, showRuler } = useWorkspaceEditor();
  return (
    <>
      <ContainerImageLeft>
        <ButtonToolbar
          isActive={isActiveBringToForward}
          className="btn-toolbar"
          type="button"
          tooltipTitle="Avançar"
          tooltipDescription="Trazer o objeto selecionado um nível para a frente, para que fique à frente de menos objetos."
          tooltipPlacement="bottom-start"
          onClick={handleClickBringToForwardIcon}
          component="toFront"
        />

        <ButtonToolbar
          isActive={isActiveTakeBack}
          className="btn-toolbar"
          type="button"
          component="toBack"
          tooltipTitle="Recuar"
          tooltipDescription="Enviar o objeto selecionado um nível para trás, para que fique atrás de mais objetos."
          tooltipPlacement="bottom-start"
          onClick={handleClickTakeBackIcon}
        />

        <ButtonToolbar
          isActive={isActiveTakeBack}
          className="btn-toolbar"
          type="button"
          component="isAllToFront"
          tooltipTitle="Tudo para frente"
          tooltipDescription="Trazer o objeto selecionado para a frente de todos os outros objetos."
          tooltipPlacement="bottom-start"
          onClick={() => handleAllToFront(idPage)}
        />

        <ButtonToolbar
          isActive={isActiveTakeBack}
          className="btn-toolbar"
          type="button"
          component="isAllToBack"
          tooltipTitle="Tudo para trás"
          tooltipDescription="Enviar o objeto selecionado para trás de todos os outros objetos."
          tooltipPlacement="bottom-start"
          onClick={() => handleAllToBack(idPage)}
        />

        <DropdownOpacity
          valueOpacity={valueOpacity}
          setValueOpacity={setValueOpacity}
          callback={handleChangeOpacity}
          // isActive={isActiveDash}
          className="btn-toolbar"
          type="button"
          tooltipTitle="Transparência"
          tooltipDescription="Manipule a transparência do objeto."
          tooltipPlacement="bottom-start"
          onClick={handleClickOpacity}
          component="isOpacity"
        />
      </ContainerImageLeft>
      <Container maxWidth="720px"></Container>
      <ContainerRight>
        <Tooltip
          title={isDraggable ? "Bloquear" : "Desbloquear"}
          description={
            isDraggable
              ? "Evite que um elemento possa ser alterado durante a edição do documento."
              : "Permita a edição do elemento"
          }
          placement="bottom-start"
        >
          <button
            onClick={() => {
              handleBlockElements();
              handleToggleBlockElement();
            }}
            className="btn-toolbar"
          >
            {isDraggable ? (
              <UnlockIcon className="width-unlock-icon" />
            ) : (
              <LockedIcon className="width-lock-icon" />
            )}
          </button>
        </Tooltip>
        <Tooltip
          title="Excluir"
          description="Exclua um elemento que você criou."
          placement="bottom-start"
        >
          <button className="btn-toolbar" onClick={handleDeleteElement}>
            <IconToolbar
              className="width-spam-icon"
              src={iconTrash}
              description="Excluir"
            />
          </button>
        </Tooltip>

        <AutomaticNumbering />
        <ButtonRuler
          showRuler={showRuler}
          handleShowRuler={handleShowRuler}
        />

        {/* <DropdownZoom /> */}
      </ContainerRight>
    </>
  );
};

export default ImageTool;

