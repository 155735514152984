import { Input, ContainerModal, Modal, Header } from "./styled";
import { useTeams } from "../../../../../../hooks/teams";
import { useCallback, useEffect } from "react";
import { ContractAnimation } from "../../../../../../shared/components/ContractAnimation";
import { useAuth } from "../../../../../../hooks/auth";
import TitleH5 from "../../../../../../shared/components/TitleH5";
import PlainText from "../../../../../../shared/components/PlainText";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import ButtonExit from "../../../../../../shared/components/ButtonExit";

const ModalCreateTeams = ({ setSelectModal }) => {
  const {
    insertNameTeam,
    setInsertNameTeam,
    loadingCreateTeam,
    handleCreateTeam
  } = useTeams();

  const onChangeNameTeam = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInsertNameTeam(event.target.value);
    },
    [setInsertNameTeam]
  );

  useEffect(() => {
    return () => {
      setInsertNameTeam("");
    };
  }, []);

  return (
    <ContainerModal>
      {loadingCreateTeam ? (
        <div className="modal-loading">
          <ContractAnimation />
        </div>
      ) : (
        <Modal>
          <Header>
            <TitleH5 title="UX DOC para equipes" />
            <ButtonExit
              onClick={() => setSelectModal({ modal: "", isOpen: false })}
            />
          </Header>
          <PlainText>
            Crie documentos e compartilhe recursos visuais e textos com a sua
            equipe de forma rápida e fácil.
          </PlainText>
          <Input>
            <label>Nome da equipe</label>
            <input
              onChange={onChangeNameTeam}
              type="text"
              placeholder="Nome da equipe"
              value={insertNameTeam}
            />
          </Input>

          <ButtonMaxWidth
            text="Próximo"
            typeStyle="purple"
            onClick={handleCreateTeam}
          />
        </Modal>
      )}
    </ContainerModal>
  );
};

export default ModalCreateTeams;
