import styled from "styled-components";

export const Container = styled.div`
  width: 100dvw;
  height: 100dvh;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BoxModal = styled.div`
  width: 800px;
  height: auto;
  display: flex;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 5px;
  flex-direction: column;
`;

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  font-size: 14px;
  font-family: Ubuntu, sans-serif;
  margin: 0 auto;
  margin-top: 1.8rem;
  margin-bottom: 1rem;
  line-height: 150%;
  color: #343a40;
  max-width: 452px;
  text-align: center;
`;

export const TokenBox = styled.div`
  width: 420px;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  /* background-color: red; */
  margin: 1.8rem auto 0.5rem auto;
  position: relative;

  input {
    min-width: 60px;
    max-width: 60px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border: 1px solid #d6d8d9;
    font-size: 48px;
    font-family: Ubuntu, sans-serif;
  }

  .otp-input.otp-input-space {
    margin-right: 60px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }

  .otp-input.item-0 {
    /* border-radius: 5px; */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0.5px solid #fff;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }

  .otp-input.item-1 {
    border-right: 0.5px solid #fff;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }

  .otp-input.item-3 {
    /* border-radius: 5px; */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0.5px solid #fff;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }

  .otp-input.item-4 {
    border-right: 0.5px solid #fff;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }

  .otp-input.item-5 {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &:focus {
      border: 2px solid #5d5fef;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  background: #fff;
  justify-content: flex-start;
  flex-shrink: 1;
  margin-top: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  gap: 20px;
  padding: 0 20px;
  align-items: center;
  border-top: 1px solid #ebebec;
`;

export const TextEmergency = styled.span`
  color: #5d5fef;
  font-size: 13px;
  font-family: Ubuntu, sans-serif;
  font-style: italic;
  margin: 1rem auto 2rem auto;
  cursor: pointer;
`;

export const ButtonContinue = styled.button`
  width: 100%;
  height: 40px;
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.primary};
  /* display: flex; */
  color: #fff;
  border-radius: 5px;
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
`;

export const Input2Fa = styled.input`
  width: 592px;
  height: 60px;
  margin: 1rem auto;
  border-radius: 5px;
  border: 1px solid #d6d8d9;
  padding: 0 2rem;
  font-family: Ubuntu, sans-serif;
  font-size: 36px;
  color: #343a40;

  &:focus {
    border: 2px solid #5d5fef;
  }

  &::placeholder {
    color: #d4d4d4;
  }
`;

