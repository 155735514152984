import styled from "styled-components";
import { lighten } from "polished";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 10px;
`;

export const BoxMessage = styled.div`
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary};

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
`;

export const TextTip = styled.p`
  color: #000;
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  margin: 0 auto;
  margin-top: 1.8rem;
`;

export const LoadingContainerImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  margin-top: 5rem;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.gray300};
  user-select: none;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const ContainerList = styled.div`
  width: 100%;
  height: calc(100vh - 120px);

  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow-y: auto;

  overflow-x: hidden;

  padding-inline: 10px;

  .grid-upload-images2 {
    background-color: #fff;
    padding: 10px;
  }

  .spacing {
    margin-top: 1.2rem;
  }
`;

export const ContainerListSpecific = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 2rem;
  overflow-x: hidden;

  .spacing {
    margin-top: 1.2rem;
  }
`;

export const ContainerImages = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const BackToTeamsList = styled.div`
  min-height: 30px;
  width: 100%;
  display: flex;
  gap: 8px;
  padding-inline: 10px;

  align-items: center;

  cursor: pointer;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Box = styled.div`
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
  border: 1px solid #d6d8d9;
  padding-inline: 10px;

  background: #fff;
  display: flex;

  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const TeamName = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;
