import React, { SetStateAction, useEffect } from "react";
import {
  BoxButton,
  BoxInput,
  BoxWarning,
  InputText,
  ModalContainer,
  ModalCreateClausule,
  Title
} from "./styles";
import { GrClose } from "react-icons/gr";
import ButtonMaxWidth from "../../../../../shared/components/ButtonMaxWidth";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import TitleH5 from "../../../../../shared/components/TitleH5";
import ButtonExit from "../../../../../shared/components/ButtonExit";
import { UseMutateFunction } from "react-query";
import { MyClausesDataProps } from "../../../../../dtos/Clauses";

// import { Container } from './styles';

interface ObjectNewClause {
  user_clause_id?: string;
  title: string;
  clause: string;
  description: string;
  is_favorite: boolean;
  key_words?: string;
}

interface QueryProps {
  search?: string;
  filter?: string;
  pagination?: number;
}

interface ModalText {
  objectClause: ObjectNewClause;
  onChangeDynamicState: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: string,
    setState: React.Dispatch<SetStateAction<ObjectNewClause | string>>
  ) => void;
  setObjectClause: React.Dispatch<React.SetStateAction<ObjectNewClause>>;
  handleClause: UseMutateFunction<MyClausesDataProps, unknown, void, unknown>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: "create" | "edit";
}

const ModalText: React.FC<ModalText> = ({
  objectClause,
  onChangeDynamicState,
  setObjectClause,
  handleClause,
  setIsModalOpen,
  type
}) => {
  useEffect(() => {
    return () => {
      setObjectClause({
        title: "",
        clause: "",
        description: "Clause by user",
        is_favorite: false,
        key_words: ""
      });
    };
  }, []);

  return (
    <ModalCreateClausule>
      <ModalContainer>
        <Title>
          <TitleH5
            title={type === "create" ? "Cadastrar novo texto" : "Editar Texto"}
          />

          <ButtonExit onClick={() => setIsModalOpen(false)} />
        </Title>

        <BoxInput>
          <div>
            <label>Título</label>
            <input
              value={objectClause?.title}
              onChange={event =>
                onChangeDynamicState(event, "title", setObjectClause)
              }
              type="text"
            />
          </div>
          <div>
            <label>Categoria</label>
            <input
              type="text"
              value={objectClause?.key_words}
              onChange={e =>
                setObjectClause(state => {
                  return {
                    ...state,
                    key_words: e.target.value
                  };
                })
              }
            />
          </div>
          <div>
            <label>Texto</label>
            <InputText
              value={objectClause.clause}
              onChange={event =>
                onChangeDynamicState(event, "clause", setObjectClause)
              }
            />
          </div>
        </BoxInput>

        <BoxWarning>
          <PersonalizedIcon
            dPath={MountIcons.IconAlert.dPath}
            viewBox={MountIcons.IconAlert.viewBox}
            inactivatedColor="#de2d2d"
          />
          <p>
            Tudo que você escrever aqui ficará gravado no banco de cláusulas.
            Assim, evite usar informações confidenciais de clientes neste texto,
            dê preferência a incluir estas informações confidenciais apenas no
            documento que está criando.
          </p>
        </BoxWarning>

        <ButtonMaxWidth
          text="Salvar"
          typeStyle="purple"
          onClick={handleClause}
        />
      </ModalContainer>
    </ModalCreateClausule>
  );
};

export default ModalText;
