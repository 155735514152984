import React from "react";

// import { Container } from './styles';
interface IdentNegativeProps {
  isActive: boolean;
}

const RecoilLeft: React.FC<IdentNegativeProps> = ({ isActive }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="streamline:interface-text-formatting-indent-decrease-text-alignment-indent-paragraph-align-formatting-decrease">
        <path
          id="Vector (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 4.37283C2 3.75918 2.49746 3.26172 3.11111 3.26172H20.8889C21.5025 3.26172 22 3.75918 22 4.37283C22 4.98648 21.5025 5.48394 20.8889 5.48394H3.11111C2.49746 5.48394 2 4.98648 2 4.37283ZM6.53558 9.22431C7.01682 9.60507 7.09827 10.3039 6.71751 10.7851L5.21171 12.6883L6.71751 14.5914C7.09827 15.0727 7.01682 15.7715 6.53558 16.1522C6.05434 16.533 5.35556 16.4515 4.9748 15.9703L2.92352 13.3777C2.60384 12.9737 2.60384 12.4029 2.92352 11.9988L4.9748 9.40624C5.35556 8.92501 6.05434 8.84355 6.53558 9.22431ZM9.52137 10.0957C9.52137 9.48202 10.0188 8.98456 10.6325 8.98456H20.8889C21.5025 8.98456 22 9.48202 22 10.0957C22 10.7093 21.5025 11.2068 20.8889 11.2068H10.6325C10.0188 11.2068 9.52137 10.7093 9.52137 10.0957ZM9.52137 15.2809C9.52137 14.6672 10.0188 14.1697 10.6325 14.1697H20.8889C21.5025 14.1697 22 14.6672 22 15.2809C22 15.8945 21.5025 16.392 20.8889 16.392H10.6325C10.0188 16.392 9.52137 15.8945 9.52137 15.2809ZM2 21.0221C2 20.4085 2.49746 19.911 3.11111 19.911H20.8889C21.5025 19.911 22 20.4085 22 21.0221C22 21.6358 21.5025 22.1332 20.8889 22.1332H3.11111C2.49746 22.1332 2 21.6358 2 21.0221Z"
          fill={isActive ? "#663399" : "#343A40"}
        />
      </g>
    </svg>
  );
};

export default RecoilLeft;
