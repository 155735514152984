import styled from "styled-components";

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepExpand = styled.div<{
  isFirst: boolean;
  isLast: boolean;
}>`
  display: flex;
  flex-direction: column;
  border: 1px solid #d6d8d9;
  border-radius: ${({ isFirst, isLast }) =>
    isFirst && isLast
      ? "5px 5px"
      : isFirst
      ? "5px 5px 0 0"
      : isLast
      ? "0 0 5px 5px"
      : "none"};
`;

export const StepExpandHeader = styled.div<{
  active: boolean;
  isFirst: boolean;
}>`
  font-size: 14px;
  border: ${({ active }) =>
    active ? "2px solid #5D5FEF" : "2px solid transparent"};
  border-radius: ${({ isFirst }) => (isFirst ? "5px 5px 0 0" : "0 0")};
  cursor: pointer;
`;

export const StepExpandContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  border-top: 1px solid #d6d8d9;
`;

export const Label1 = styled.span`
  font-size: 14px;
  color: #343a40;
`;

export const Label2 = styled.span`
  font-size: 12px;
  color: #676b70;
`;

export const NoDescription = styled.span`
  font-size: 12px;
  color: #999c9f;
`;

export const Title = styled.span`
  font-size: 13px;
  color: #343a40;
`;
