import React, { useEffect } from "react";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import { usePagesEditor } from "../../../../hooks/pagesEditor";
import ButtonIconsWithTooltip from "../../../ButtonsAsideBar/components/ButtonIconsAsidebar";
import MountIcons from "../../../../../../shared/utils/MountIcons";

// import { Container } from './styles';

interface RemovePageEditorProps {
  index: number;
  item: any;
}

const DisableRemovePage: React.FC<RemovePageEditorProps> = ({
  index,
  item
}) => {
  const {
    handleRemovePage,
    isActiveHoverIconRemovePage,
    handleHoverIconRemovePage
  } = usePagesEditor();

  return (
    <ButtonIconsWithTooltip
      style={{ position: "relative" }}
      title="Desabilitado"
      placement="bottom-start"
    >
      <PersonalizedIcon
        isActive={true}
        activeColor="#D6D8D9"
        inactivatedColor="#D6D8D9"
        className="btn-page"
        dPath={MountIcons.IconTrash.dPath}
        viewBox={MountIcons.IconTrash.viewBox}
      />
    </ButtonIconsWithTooltip>
  );
};

export default DisableRemovePage;
