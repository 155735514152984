import styled from "styled-components";
import { Theme } from "../../../../../dtos/theme";

export const Container = styled.div`
  display: grid;
  height: 40px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1px;
`;

export const BoxDecrement = styled.button`
  height: 100%;
  border: 1px solid ${({ theme }: Theme) => theme.colors.primary};
  border-radius: 5px 0px 0px 5px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }

  div {
    width: 13.44px;
    height: 2.44px;

    border-radius: 1.44px;
    background-color: ${({ theme }: Theme) => theme.colors.primary};
    margin-top: 2px;
  }
`;

export const BoxIncrement = styled.button`
  height: 100%;
  border: 1px solid ${({ theme }: Theme) => theme.colors.primary};
  border-radius: 0px 5px 5px 0px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Flex = styled.div`
  max-width: 50px;
  height: 100%;
`;

export const NumberArea = styled.input`
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0;
  border: none;
  border: 1px solid ${({ theme }: Theme) => theme.colors.gray300};
  height: 100%;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  :hover {
    border: 1px solid #000;
  }
`;
