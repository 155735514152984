import styled from "styled-components";

export const Container = styled.main<{ isNotifications: boolean }>`
  grid-area: main;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  padding-bottom: ${({ isNotifications }) => (isNotifications ? "2.5rem" : "")};
`;

export const CentralBoxMain = styled.main`
  width: 100%;
  height: 100%;
`;

