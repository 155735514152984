/*
here is component for dropdown archive button on header editor
and when open that dropdown with features such download, new document,
and save project
*/

import React from "react";
import DropdownArchive from "../DropdownArchive";
import { Button } from "./styles";

//set up interfaces
interface ButtonArchiveProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ButtonArchive: React.FC<ButtonArchiveProps> = ({ isOpen, setIsOpen }) => {
  return (
    <Button onClick={() => setIsOpen(!isOpen)}>
      <DropdownArchive isOpen={isOpen} setIsOpen={setIsOpen} />
    </Button>
  );
};

export default ButtonArchive;
