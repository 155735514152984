import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 25px;
  height: 25px;
  fill: currentColor;
`;

type Props = {
  color?: string;
};

const StickIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 25 25" color={color || "#676B70"}>
    <path
      d="M22.5 5.77319V16.2732L16.5 22.2732H6C4.1 22.2732 2.5 20.6732 2.5 18.7732V5.77319C2.5 3.87319 4.1 2.27319 6 2.27319H19C20.9 2.27319 22.5 3.87319 22.5 5.77319ZM20.5 6.07319C20.5 5.07319 19.7 4.27319 18.7 4.27319H6.3C5.3 4.27319 4.5 5.07319 4.5 6.07319V18.5732C4.5 19.5732 5.3 20.3732 6.3 20.3732H15.5V18.8732C15.5 16.9732 17.1 15.3732 19 15.3732H20.5V6.07319Z"
      fill="currentColor"
    />
    <path
      d="M13.5082 13.2732C13.7754 13.2732 14.0318 13.3786 14.2207 13.5661C14.4097 13.7536 14.5159 14.008 14.5159 14.2732C14.5159 14.5384 14.4097 14.7928 14.2207 14.9803C14.0318 15.1678 13.7754 15.2732 13.5082 15.2732H9.47742C9.21016 15.2732 8.95385 15.1678 8.76487 14.9803C8.57589 14.7928 8.46973 14.5384 8.46973 14.2732C8.46973 14.008 8.57589 13.7536 8.76487 13.5661C8.95385 13.3786 9.21016 13.2732 9.47742 13.2732H13.5082ZM15.5236 9.27319C15.7804 9.27348 16.0275 9.37107 16.2142 9.54604C16.401 9.72101 16.5134 9.96015 16.5284 10.2146C16.5435 10.469 16.46 10.7196 16.2952 10.915C16.1304 11.1105 15.8965 11.2361 15.6415 11.2662L15.5236 11.2732H9.47742C9.22058 11.2729 8.97354 11.1753 8.78678 11.0003C8.60002 10.8254 8.48763 10.5862 8.47258 10.3318C8.45752 10.0774 8.54094 9.82682 8.70579 9.63136C8.87064 9.43591 9.10448 9.3103 9.35952 9.28019L9.47742 9.27319H15.5236Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default StickIcon;
