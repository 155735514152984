/*
this file have responsabilities to define which toolbar the UX DOC
could show for that user, following action of object selected
or multiple objects could be selected too
*/

import React, { useEffect, useState } from "react";
import { useSelection } from "../../hooks/selection";
import { whichSelectedObject } from "../../utils/whichSelectedObject";
import ToolbarByElement from "../ToolbarByElement";
import { FlexToolbar, ToolsbarEditor } from "./styles";
import { useWorkspaceEditor } from "../../hooks/workspaceEditor";

const ToolbarMain: React.FC = () => {
  //hooks
  const {
    selectedObject,
    setSelectedObject,
    currentMultipleSelection,
    selectedObjects,
    setSelectedObjects
  } = useSelection();

  const { toolbarEditorRef } = useWorkspaceEditor();
  const initialState = "Default";
  //main state for show toolbar elements by selected object
  const [whichTypeElement, setWhichTypeElement] = useState<
    | "Default"
    | "Text"
    | "Image"
    | "BlankPage"
    | "MultipleItems"
    | "RichTextEditor"
  >(initialState);
  //when objects or object was selected
  //then trigger this callback
  useEffect(() => {
    whichSelectedObject({
      selectedObject,
      selectedObjects,
      setSelectedObject,
      setWhichTypeElement,
      currentMultipleSelection,
      setSelectedObjects
    });
  }, [selectedObject, currentMultipleSelection, selectedObjects]);

  return (
    <ToolsbarEditor ref={toolbarEditorRef}>
      <FlexToolbar>
        <ToolbarByElement elementForShow={whichTypeElement} />
      </FlexToolbar>
    </ToolsbarEditor>
  );
};

export default ToolbarMain;
