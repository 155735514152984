import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import {
  GridContainerTemplates,
  Container,
  FullContent,
  FlexWrapper,
  NoElements,
} from "./styles";

import api from "../../../../services/api";
import { useMainHook } from "../../../../hooks/main";
import { useMetric } from "../../../../hooks/metric";

import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import { LoadingFlex } from "../../../user/unknown/login/pages/Login/styles";
import PreviewTemplateModal from "../../components/PreviewTemplateModal";
import { AllMyProjects } from "../../../../dtos/AllMyProjects";
import OneClickSection from "../../components/OneClickSection";
import TemplateItem from "../../components/TemplateItem";
import Pagination from "../../components/Pagination";
import AlertMessage from "../components/AlertMessage";
import { PagesProps } from "../../../../dtos/PagesProps";
import Downloadfiles from "../../../../shared/components/DownloadFiles";

const OneClick: React.FC = () => {
  //route properties
  const navigator = useNavigate();
  const { number_page } = useParams();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;

  //import hooks necessary
  const {
    currentTemplateInfo,
    documentName,
    allMyProjects,
    setAllMyProjects,
    setDocumentRemoveId,
    copyDocument,
    setCopyDocument,
    setNameDocumentDelete,
    handleRequestModal,
    setIsLoadingModal,
    isIntentBuyModal,
    setIsIntentBuyModal,
    refresh,
  } = useMainHook();
  const { handleCreatedProjectAction } = useMetric();
  //set up states

  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [preview, setPreview] = useState({ pages: [], title: "" });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [downloading, setDownloading] = useState(false);
  const whichFileToBeDownload = useRef<"word" | "pdf">("pdf");
  const documentPreviewData = useRef<PagesProps[] | null>(null);
  const idToastifyDownloadRef = useRef<"">(null);

  //params for search to editing data of main state
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const fetchAllMyTemplates = await api.get("user-templates", {
          params: {
            search: null,
            pagination: pageNumber,
            is_oneclick: true,
          },
        });
        setAllMyProjects(fetchAllMyTemplates.data.dataArray as AllMyProjects[]);
        setTotalPages(fetchAllMyTemplates.data.pagination);
        setLoading(false);

        if (pageNumber > 0 && fetchAllMyTemplates.data.dataArray.length === 0) {
          navigator(`/IA/${pageNumber}`);
        }
      } catch (error) {
        console.error("ERROR GETTING TEMPLATES " + error);
        setLoading(false);
      }
    })();
  }, [copyDocument, pageNumber, refresh]);

  const refetchTemplates = useCallback(async () => {
    try {
      const fetchAllMyTemplates = await api.get("user-templates", {
        params: {
          search: null,
          pagination: pageNumber,
          is_oneclick: true,
        },
      });
      setAllMyProjects(fetchAllMyTemplates.data.dataArray as AllMyProjects[]);
      setTotalPages(fetchAllMyTemplates.data.pagination);
      setLoading(false);
    } catch (error) {
      console.error("ERROR GETTING TEMPLATES " + error);
      setLoading(false);
    }
  }, [pageNumber, setAllMyProjects]);

  //when user clicked on delete document
  //then ask to user if he is sure he wants deleted these document?
  //if yes, call endpoint to delete or not just return
  const handleDeleteDocument = useCallback(
    async (document_id: string, name: string) => {
      setDocumentRemoveId(document_id);
      setNameDocumentDelete(name);
      handleRequestModal();
    },
    [handleRequestModal, setDocumentRemoveId, setNameDocumentDelete]
  );

  //to user get one copy of these document
  //then with id in hands we could be duplicated
  //considering doing the duplicate template creation process
  const handleMakeCopyDocument = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const creteCopyTemplate = await api.post(`user-templates/clone`, {
          user_template_id: id,
        });

        //created project action register function
        handleCreatedProjectAction({
          project_id: creteCopyTemplate.data.id,
          creation_origin: "User Template",
          template_id: id,
          researched: false,
          template_bits_name: "",
        });

        setCopyDocument(!copyDocument);
        toast.success("O documento foi criado.");
        setLoading(false);
      } catch (err) {
        // alert("bad");
        console.error("error when make a copy api");
      }
    },
    [copyDocument, setCopyDocument, setLoading, handleCreatedProjectAction]
  );

  //if the user wants to rename the document name
  //do one request to put id document
  const handleChangeDocumentName = useCallback(
    async ({ documentId, newDocumentName }) => {
      try {
        const template = await api.get(`user-templates/${documentId}`);

        api.put("user-templates", {
          user_template_id: documentId,
          template: template.data.template,
          title: newDocumentName,
          is_automatic_numbering_active: false,
          automatic_numbering_position: "right",
          first_page_automatic_numbering: false,
        });

        setAllMyProjects(() =>
          allMyProjects.filter((element) => {
            if (element.id === documentId) {
              return (element.title = newDocumentName);
            }

            return element;
          })
        );

        toast.success("Título alterado com sucesso");
      } catch (error: any) {
        console.error(
          "Error trying to update document's name",
          error.response.data
        );
      }
    },
    [allMyProjects, setAllMyProjects]
  );

  //Responsible for fetching the information of the selected template.
  const handleSetInfoTemplate = useCallback(async ({ templateId, title } : {
    templateId: string,
    title: string
  }) => {
    try {
      setLoadingPreview(true);
      setModalIsOpen(true);
      const fetchDocumentPreview = await api.get(
        `user-templates/template/${templateId}`
      );
      setPreview({
        pages: fetchDocumentPreview.data.response.arrayOfPages,
        title,
      });
      setLoadingPreview(false);
    } catch (error) {
      console.error(error, "error getting document to preview");
      setLoadingPreview(false);
    }
  }, []);

  const handleChooseTemplate = useCallback(
    async (id: string) => {
      setIsLoadingModal(true);
      //get template and update the main state
      //when ready state then navigate these user
      try {
        const response = await api.get(`user-templates/template/${id}`);

        currentTemplateInfo.current = response.data;
        documentName.current = response.data.title;
        // setObjectScreen(response.data.template.arrayOfPages);
        setIsLoadingModal(false);
        navigator(`/editor/my-template/${id}`);
      } catch (err) {
        setIsLoadingModal(false);
        console.error(err, "error when get specific template");
      }
    },
    [currentTemplateInfo, documentName, navigator, setIsLoadingModal]
  );

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  return (
    <FullContent>
      <Downloadfiles
        toastId={idToastifyDownloadRef}
        fileExport={whichFileToBeDownload.current}
        downloading={downloading}
        setDownloading={setDownloading}
        documentName={idToastifyDownloadRef.current}
        pagesToBeRendered={documentPreviewData.current}
      />
      <ToastContainer />
      {loading ? (
        <LoadingFlex>
          <ContractAnimation />
        </LoadingFlex>
      ) : (
        <Container>
          <OneClickSection refetchTemplates={refetchTemplates} />
          <AlertMessage />

          <FlexWrapper>
            {allMyProjects.length > 0 ? (
              <GridContainerTemplates>
                {allMyProjects.map((item, index) => {
                  let defaultIMG = false;
                  let splitURL = item.thumbnail_url.split("/");

                  if (splitURL[splitURL.length - 1] === "default")
                    defaultIMG = true;

                  return (
                    <TemplateItem
                      id={item.id}
                      page="One Click"
                      key={String(Math.random())}
                      src={
                        defaultIMG
                          ? "https://picnic.com.br/wp-content/uploads/2017/12/pagina-em-branco-catalogo-1.jpg"
                          : item.thumbnail_url
                      }
                      text={item.title}
                      index={index}
                      options={true}
                      isLoading={item.is_loading}
                      handleDeleteDocument={handleDeleteDocument}
                      handleChangeDocumentName={handleChangeDocumentName}
                      handleChooseTemplate={handleSetInfoTemplate}
                      setTemplateId={setTemplateId}
                      handleMakeCopy={handleMakeCopyDocument}
                      handleUseTemplate={handleChooseTemplate}
                      whichFileToBeDownload={whichFileToBeDownload}
                      idToastifyDownloadRef={idToastifyDownloadRef}
                      documentPreviewData={documentPreviewData}
                      downloading={downloading}
                      setDownloading={setDownloading}
                      isIntentBuyModal={isIntentBuyModal}
                      setIsIntentBuyModal={setIsIntentBuyModal}
                    />
                  );
                })}
              </GridContainerTemplates>
            ) : (
              <NoElements>Nenhum projeto encontrado.</NoElements>
            )}
          </FlexWrapper>

          <Pagination
            total={totalPages}
            pageNow={pageNumber}
            screen="IA"
          />
        </Container>
      )}

      <PreviewTemplateModal
        title={preview.title}
        open={modalIsOpen}
        handleCloseModal={handleCloseModal}
        pagesToBeRendered={preview.pages}
        loadingPreview={loadingPreview}
        chooseTemplate={handleChooseTemplate}
        templateId={templateId}
        texButton="Abrir Projeto"
      />
    </FullContent>
  );
};

export default OneClick;

