import styled from "styled-components";

export const Button = styled.button`
  min-width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const IconRedo = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;
