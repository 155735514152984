import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

interface TextProps extends React.HTMLProps<HTMLTextAreaElement> {
  margin?: string;
}

export const DropDownContainer = styled.div<{ zIndexActive?: boolean }>`
  align-items: center;
  justify-content: flex-start;
  z-index: ${({ zIndexActive }) => (zIndexActive ? 0 : 99)};
  margin-left: 1rem;
  position: relative;
`;

export const Flex = styled.div`
  display: flex;
  max-width: 40px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  position: relative;
  padding: 0 1.6rem;
  background: transparent;
  align-items: center;
`;

export const DropDownHeader = styled.div<{ Open: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 0.32rem;
  justify-content: space-between;
  font-weight: bold;
  height: 100%;
  background: red;
  font-size: 1.6rem;
  color: ${({ theme }: Theme) => theme.colors.black};
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  &:hover {
    cursor: pointer;
  }

  .effect-arrow {
    transition: all 0.1s ease-in;
    transform: ${(props) => (props.Open ? "rotateZ(-180deg)" : "")};
  }
`;

export const DropDownListContainer = styled.div``;

export const DropDownList = styled.ul`
  width: 368px;
  height: auto;
  background: white;

  margin: 0;
  margin-top: 8px;
  border-radius: 5px;

  position: absolute;
  top: 50%;
  right: 5px;

  box-shadow: 1px 3px 41px -15px rgba(0, 0, 0, 0.31);
  -webkit-box-shadow: 1px 3px 41px -15px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 1px 3px 41px -15px rgba(0, 0, 0, 0.41);
  box-sizing: border-box;

  font-family: "Roboto";
  color: ${({ theme }: Theme) => theme.colors.black};
  font-size: 1.8rem;
  font-weight: 500;

  display: flex;
  flex-direction: column;
  z-index: 99;

  &:hover {
    cursor: pointer;
  }
`;

export const ListItem = styled.li`
  height: 44px;
  color: #676b70;
  list-style: none;
  padding-left: 20px;

  display: flex;
  align-items: center;

  z-index: 99;
  font-size: 14px;
  font-weight: 400;
  :hover {
    background-color: #f5f5f5;
  }
`;

export const Box = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;

  padding: 20px;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  max-width: 302px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 0rem 0;
`;

export const Button = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  background: transparent;
  margin: 0.8rem 0;
  font-size: 1.6rem;
  line-height: 27px;
  justify-content: space-between;

  @media (max-width: 1600px) {
    font-size: 1.4rem;
  }
`;

export const Text = styled.p<TextProps>`
  font-family: "Roboto";
  font-size: 43px;
  color: ${({ theme }) => theme.colors.whiteColor};
  font-size: 1.6rem;
  margin: ${(props) => (props.margin ? props.margin : "")};
  align-items: center;

  @media (max-width: 1366px) {
    font-size: 1.6rem !important;
  }

  @media (max-width: 1600px) {
    font-size: 1.7rem;
  }
`;

export const MiniBox = styled.div`
  max-width: 80px;
  width: 100%;
  height: 30px;
  background: #d9d9d9;
  border-radius: 5px;
`;

export const AvatarProfile = styled.div`
  display: flex;

  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  .avatar-profile {
    height: 70px;
    width: 70px;
    position: relative;
  }

  .camera-icon {
    position: absolute;
    top: 60%;
    right: 10%;
    bottom: 0;
    border-radius: 50%;
    -webkit-box-shadow: 2px -2px 15px 1px rgba(0, 0, 0, 0.11);
    box-shadow: 2px -2px 15px 1px rgba(0, 0, 0, 0.11);
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
`;

export const NameProfile = styled.span`
  font-size: 16px;
  font-weight: bold;

  color: #343a40;
  margin-bottom: 0.4rem;
`;

export const EmailProfile = styled.p`
  color: #a5a5a5;
  font-size: 14px;
  font-weight: 300;
`;

export const TrialPeriodEndSpam = styled.span`
  color: black;
  font-size: 14px;
  margin-top: 8px;
`;

export const DividerLine = styled.div`
  border: 0.8px solid #a5a5a5;
  width: 90%;
  margin: 1rem auto;

  @media (max-width: 1366px) {
    border: 0.4px solid #a5a5a5;
    margin: 0 auto 1rem auto;
  }
`;
