import React from "react";
import {
  ButtonPag,
  ButtonPagActive,
  PaginationList,
  PaginationListItem,
  Conatainer,
} from "./styles";

import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import { useTheme } from "../../../../hooks/theme";

interface PaginationProps {
  total: number;
  pageNow?: number;
  screen?: string;
  afterUrl?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  total,
  pageNow,
  screen,
  afterUrl,
}) => {
  const { theme } = useTheme();

  const pagesToShow = [];
  total = total + 1;
  pageNow = pageNow + 1;

  if (total === 1) {
    return <></>;
  }

  if (pageNow <= 2) {
    for (let i = 1; i <= Math.min(total, 3); i++) {
      pagesToShow.push(i);
    }
  } else if (pageNow === total) {
    for (let i = pageNow - 2; i <= Math.min(pageNow + 1, total); i++) {
      pagesToShow.push(i);
    }
  } else {
    for (let i = pageNow - 1; i <= Math.min(pageNow + 1, total); i++) {
      pagesToShow.push(i);
    }
  }

  return (
    <Conatainer>
      <PaginationList>
        <ButtonPag
          to={
            pageNow > 1 &&
            `/${screen}/${pageNow - 1}${!!afterUrl ? `/${afterUrl}` : ""}`
          }
        >
          <PersonalizedIcon
            activeColor={theme.colors.black50}
            isActive={pageNow === 1 ? false : true}
            inactivatedColor={theme.colors.black20}
            viewBox={"0 0 25 25"}
            dPath="M16.0069 19.4613C15.6066 19.861 14.9579 19.8605 14.5582 19.4602L8.59163 13.4845C8.19231 13.0846 8.19231 12.4368 8.59163 12.0369L14.5582 6.06133C14.958 5.66097 15.6066 5.66049 16.0069 6.06023C16.4073 6.45998 16.4078 7.10859 16.008 7.50894L10.7641 12.7607L16.008 18.0125C16.4078 18.4129 16.4073 19.0615 16.0069 19.4613Z"
          />
        </ButtonPag>

        {pagesToShow.map((page) => (
          <PaginationListItem key={String(Math.random())}>
            {page === pageNow ? (
              <ButtonPagActive className={"active-button"}>
                {page}
              </ButtonPagActive>
            ) : page > total ? (
              <></>
            ) : (
              <ButtonPag
                to={`/${screen}/${page}${!!afterUrl ? `/${afterUrl}` : ""}`}
                className={"active-button"}
              >
                {page}
              </ButtonPag>
            )}
          </PaginationListItem>
        ))}

        <ButtonPag
          to={
            pageNow === total
              ? `/${screen}/${pageNow}${!!afterUrl ? `/${afterUrl}` : ""}`
              : `/${screen}/${pageNow + 1}${!!afterUrl ? `/${afterUrl}` : ""}`
          }
        >
          <PersonalizedIcon
            activeColor={theme.colors.black50}
            isActive={pageNow === total ? false : true}
            inactivatedColor={theme.colors.black20}
            viewBox={"0 0 25 25"}
            dPath="M8.99297 6.06023C9.39333 5.66048 10.0419 5.66098 10.4417 6.06133L16.4083 12.037C16.8076 12.4369 16.8076 13.0847 16.4082 13.4846L10.4417 19.4602C10.0419 19.8605 9.39332 19.861 8.99297 19.4613C8.59262 19.0615 8.59213 18.4129 8.99188 18.0125L14.2357 12.7608L8.99187 7.50894C8.59213 7.10858 8.59262 6.45997 8.99297 6.06023Z"
          />
        </ButtonPag>
      </PaginationList>
    </Conatainer>
  );
};

export default Pagination;
