// hooks/useImageRenderer.js
import { useState, useEffect } from "react";
import { ParagraphObjects } from "../types/paragraphObjects";
import { v4 as uuidv4 } from "uuid";

export const useImageRenderer = (
  quillRef,
  convertionResult,
  iconChoice,
  titleColor,
  contentColor,
  startRenderingImages
) => {
  const [isRenderingImages, setIsRenderingImages] = useState(false);
  const [paragraphObjects, setParagraphObjects] = useState<ParagraphObjects[]>(
    []
  );

  const renderImage = async () => {
    setIsRenderingImages(true);
    const quill = quillRef.current?.getEditor();

    const editorContainer = document.querySelector(".ql-editor") as HTMLElement;
    const width = "530px";
    editorContainer.style.maxWidth = width;
    editorContainer.style.width = width;
    const objects = [] as ParagraphObjects[];
    if (quill) {
      const paragraphs: HTMLElement[] = quill.root.querySelectorAll("p");

      // Prepare the array of paragraphs for conversion
      const conversionData = Array.from(paragraphs)
        .filter(p => p.textContent && p.textContent !== "")
        .map((paragraph, index) => {
          // Get the computed styles of the paragraph
          const computedStyles = getComputedStyle(paragraph);
          const isTitle = paragraph.getAttribute("data-is-title") === "true";

          // Create a new paragraph element with the computed styles as inline styles
          const paragraphWithStyles = document.createElement("p");
          const styleProps = Object.keys(computedStyles);
          for (const prop of styleProps) {
            const value = computedStyles.getPropertyValue(prop);
            paragraphWithStyles.style.setProperty(prop, value);
          }
          if (paragraph.className === "ql-align-center") {
            paragraphWithStyles.style.setProperty("text-align", "center");
          }
          if (isTitle) {
            paragraphWithStyles.style.setProperty("color", titleColor);
            if (index > 0) {
              paragraphWithStyles.style.removeProperty("text-align");
            }
          } else {
            paragraphWithStyles.style.setProperty("color", contentColor);
          }
          paragraphWithStyles.style.setProperty("background", "transparent");
          //  paragraphWithStyles.style.setProperty("margin-left", "-8px");
          // remove all underline
          paragraphWithStyles.style.setProperty("text-decoration", "none");
          // scale element
          paragraphWithStyles.style.setProperty("transform", "scale(5)");
          paragraphWithStyles.style.setProperty("transform-origin", "top left");
          // paragraphWithStyles.style.setProperty("font-size", "14px");
          // paragraphWithStyles.style.setProperty("font-family", "Arial");
          const hasFontSize = paragraph.style.fontSize !== "";

          if (!hasFontSize) {
            paragraphWithStyles.style.setProperty("font-size", "13px");
          }

          // Get the inner text of the paragraph
          const innerText = paragraph.innerHTML;

          const parser = new DOMParser();
          const doc = parser.parseFromString(innerText, "text/html");

          //  get all doc elements and if isTitle replace all color with titleColor else contentColor
          const docElements = doc.querySelectorAll("*");
          docElements.forEach(element => {
            if (element instanceof HTMLElement) {
              if (isTitle) {
                element.style.setProperty("color", titleColor);
                // Remove text alignment
                element.style.removeProperty("text-align");
              } else {
                element.style.setProperty("color", contentColor);
                element.style.setProperty(
                  "font-size",
                  paragraph.style.fontSize
                );
              }
              // Remove background color
              element.style.removeProperty("background-color");
              // Remove text decoration
              element.style.setProperty("text-decoration", "none");
              // scale element
              element.style.setProperty("transform", "scale(5)");
              element.style.setProperty("transform-origin", "top left");
            }
          });

          const formattedInnerText = doc.body.innerHTML;

          paragraphWithStyles.innerHTML = formattedInnerText;

          const scale = 5;
          const isTooLong = paragraph.offsetHeight > 700;
          const newParagraphHeight = paragraph.offsetHeight + 20;
          const newHeight = newParagraphHeight * scale;

          // Return the updated conversion data
          return {
            html: paragraphWithStyles.outerHTML,
            width: paragraph.offsetWidth * scale,
            height: isTooLong ? newHeight : paragraph.offsetHeight * scale,
            index: paragraph.getAttribute("data-id")
          };
        });
      try {
        // Call the endpoint to convert the paragraphs to images
        const response = await fetch(
          "https://apioneclick.uxdoc.com.br/html2image",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              paragraphs: conversionData
            })
          }
        );

        if (response.ok) {
          const data = await response.json();

          // Iterate over the converted paragraphs data
          for (const convertedParagraph of data) {
            const { dataUrl, id: index } = convertedParagraph;
            const paragraphsArray = Array.from(paragraphs);
            const paragraph = paragraphsArray.find(
              p => p.getAttribute("data-id") === index
            );
            const paragraphPosition = paragraph.getBoundingClientRect();
            const text = paragraph.textContent;
            const resultParagraph = convertionResult.data.paragraphs.find(
              p => p.id === index
            );
            const html = conversionData.find(p => p.index === index).html;

            // get the contents of the editor in Delta format
            const format = htmlToQuillDelta(html);

            const uuid = uuidv4();

            const isTooLong = paragraph.offsetHeight > 700;
            const newHeight = paragraph.offsetHeight + 20;

            const object = {
              x: 30,
              y: paragraphPosition.y,
              id: uuid,
              object: "richTextImage",
              scaleX: 1,
              scaleY: 1,
              width: paragraph.offsetWidth,
              height: isTooLong ? newHeight : paragraph.offsetHeight,
              src: dataUrl,
              text: text,
              format: format,
              isModify: false,
              draggable: true
            };

            const newParagraphObject: ParagraphObjects = {
              index: index,
              object: object,
              isPart: resultParagraph?.isPart,
              isHeading:
                resultParagraph?.type_text === "title" &&
                resultParagraph?.isSignature === false,
              icons:
                iconChoice === "filled"
                  ? resultParagraph?.icons.filter(i => i.is_icon_filled)
                  : resultParagraph?.icons.filter(i => !i.is_icon_filled)
            };

            objects.push(newParagraphObject);
            setParagraphObjects(objects);
          }
        } else {
          throw new Error("Failed to convert paragraphs to images");
        }
      } catch (error) {
        console.error("Conversion Error:", error);
        // Handle conversion error if needed
        // You can return a placeholder object or handle the error in any other way
      }
    }
    setIsRenderingImages(false);
  };

  function htmlToQuillDelta(htmlString: string) {
    const parser = new DOMParser();
    const document = parser.parseFromString(htmlString, "text/html");

    let ops: Array<any> = [];

    document.body.childNodes.forEach((node: HTMLElement) => {
      if (node.nodeName === "P") {
        node.childNodes.forEach((childNode: HTMLElement) => {
          const insertText = childNode.textContent || "";
          const attributes = extractNodeAttributes(node, childNode);

          ops.push({
            insert: insertText,
            attributes: attributes
          });

          // Add space if not the last child
          if (childNode.nextSibling) {
            ops.push({ insert: " " });
          }
        });

        // Add newline character to indicate paragraph break
        const paragraphAttributes = extractNodeAttributes(node, node);
        ops.push({ insert: "\n", attributes: paragraphAttributes });
      }
    });

    return { ops: ops };
  }

  const extractNodeAttributes = (node: HTMLElement, childNode: HTMLElement) => {
    let attributes: { [key: string]: any } = {};

    // Handle 'color' style
    const color = childNode.style?.color || node.style?.color;
    if (color) {
      attributes.color = color;
    }

    // Handle 'background-color' style
    const backgroundColor =
      childNode.style?.backgroundColor || node.style?.background;
    if (backgroundColor) {
      attributes.background = backgroundColor;
    }

    // handle 'font-size'

    const fontSize = childNode.style?.fontSize || node.style?.fontSize;
    if (fontSize) attributes.size = fontSize;
    attributes.font = "Arial";

    // Handle 'underline' style
    if (childNode.nodeName === "U") {
      attributes.underline = true;
    }

    // Handle 'bold' style
    if (childNode.nodeName === "B" || childNode.nodeName === "STRONG") {
      attributes.bold = true;
    }

    // Handle 'italic' style
    if (childNode.nodeName === "I" || childNode.nodeName === "EM") {
      attributes.italic = true;
    }

    // Handle text alignment
    const textAlign = childNode.style?.textAlign || node.style.textAlign;
    if (textAlign) {
      attributes.align = textAlign;
    }

    return attributes;
  };

  useEffect(() => {
    if (startRenderingImages) {
      renderImage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quillRef, convertionResult, iconChoice, startRenderingImages]);

  return { isRenderingImages, paragraphObjects };
};
