import { Container, Text, Title, Button } from "./styles";

const Banner = ({ title, text, link, textButton }) => {
  return (
    <Container>
      <div>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </div>

      <Button target="_blank" href={link}>
        {textButton}
      </Button>
    </Container>
  );
};

export default Banner;
