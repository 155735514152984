/*
in this file contains some features of header editor
to  decrease the size of the header file, so here only
the functions that are into in the left corner of the header
are customizable
*/

import React from "react";
import { PagesProps } from "../../../../../../dtos/PagesProps";
import ButtonArchive from "../ButtonArchive";
import ButtonGoHome from "../ButtonGoHome";
import ButtonRedo from "../ButtonRedo";
import ButtonUndo from "../ButtonUndo";
import { Wrapper, WrapperChange } from "./styles";
import { useMainHook } from "../../../../../../hooks/main";
import saveIcon from "../../../../assets/save-icon.svg";
import ButtonSave from "../ButtonSave";

// set up interfaces
interface IHistory {
  currentStep: number;
  step: PagesProps[][];
}

interface ButtonsFeatureProps {
  handleGoHomePage: () => void;
  backIcon: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: "open" | "closed" | "blocked";
  handleUndo: () => void;
  history: IHistory;
  undoIconTransparent: string;
  undoIcon: string;
  handleRedo: () => void;
  redoIconTransparent: string;
  redoIcon: string;
}

const ButtonsFeature: React.FC<ButtonsFeatureProps> = ({
  handleGoHomePage,
  backIcon,
  isOpen,
  setIsOpen,
  isEditing,
  handleUndo,
  history,
  undoIconTransparent,
  undoIcon,
  handleRedo,
  redoIconTransparent,
  redoIcon
}) => {
  const { pendingSave, isLoadingSavingTemplate } = useMainHook();

  return (
    <>
      <Wrapper>
        <ButtonGoHome handleGoHomePage={handleGoHomePage} backIcon={backIcon} />
      </Wrapper>
      <Wrapper>
        <ButtonArchive isOpen={isOpen} setIsOpen={setIsOpen} />
      </Wrapper>
      <WrapperChange>
        <ButtonUndo
          isEditing={isEditing}
          handleUndo={handleUndo}
          history={history}
          undoIconTransparent={undoIconTransparent}
          undoIcon={undoIcon}
          pendingSave={pendingSave}
        />
        <ButtonRedo
          isEditing={isEditing}
          history={history}
          handleRedo={handleRedo}
          redoIconTransparent={redoIconTransparent}
          redoIcon={redoIcon}
          pendingSave={pendingSave}
        />
      </WrapperChange>
      <Wrapper>
        <ButtonSave loading={isLoadingSavingTemplate} saveIcon={saveIcon} />
      </Wrapper>
    </>
  );
};

export default ButtonsFeature;
