import React, { useEffect, useState } from "react";

import { Box, Dialog, DialogTitle, Stack } from "@mui/material";
import {
  CancelButton,
  Container,
  ContainerButton,
  EmailLabel,
  FormGroup,
  Input,
  Label,
  LoadingContainer,
  MenuContainer,
  MenuItem,
  MessageContainer,
  MessageErrorContainer,
  MessageErrorText,
  SaveButton,
  SendButton,
  THead,
  Table,
  TableContainer,
  TableData,
  TableHead,
  TableRow,
  TrashWrapper
} from "./styles";
import {
  Avatar,
  DialogActions,
  DialogContent,
  Divider,
  Grid
} from "@material-ui/core";
import TrashIcon from "../TrashIcon";
import { ApprovalFlow, Member } from "../../types/ApprovalFlow";
import { IconCloseWrapper } from "../../styles";
import { GrClose } from "react-icons/gr";
import api from "../../../../../services/api";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { ApprovalFlowRequest } from "../../types/ApprovalFlowRequest";
import { BiChevronDown } from "react-icons/bi";

type Props = {
  open: boolean;
  onClose: (refresh?: boolean) => void;
  flowId: string;
  currentMembers: Member[];
  flow: ApprovalFlow;
  isEditing?: boolean;
  loggedUserId: string;
};

const EditMembers: React.FC<Props> = ({
  open,
  onClose,
  flowId,
  currentMembers,
  flow,
  isEditing = false,
  loggedUserId
}: Props) => {
  const [members, setMembers] = useState<Member[]>([...currentMembers]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<any>();
  const [isError, setIsError] = useState(false);

  const [menuOpen, setMenuOpen] = useState(-1);

  const [hasUpdated, setHasUpdated] = useState(false);
  const [hasInvited, setHasInvited] = useState(false);
  const handleClick = (index: number) => {
    setMenuOpen(index);
  };
  const handleClose = () => {
    setMenuOpen(-1);
  };

  const removeMember = (email: string) => {
    setHasUpdated(true);
    const newMembers = members.filter(m => m.email !== email);
    setMembers(newMembers);
    setUser(undefined);
    setIsError(false);
  };

  const sendInvitation = async () => {
    if (!email) return;
    try {
      if (loading) return;
      setUser(undefined);
      setIsError(false);
      setLoading(true);

      const respose = await api.post(`/approval-flows/${flowId}/add_member`, {
        email: email
      });

      const updatedFlow = await api.get(`/approval-flows/${flowId}`);
      setUser(respose.data);
      setMembers(updatedFlow.data.members);
      setLoading(false);
      setEmail("");
      setHasInvited(true);
      flow.members = updatedFlow.data.members;
    } catch (error) {
      setIsError(true);
      setLoading(false);
      setUser(undefined);
    }
  };

  const updateFlow = async () => {
    if (!hasUpdated) return onClose(false);
    try {
      setLoading(true);
      const updatedFlow = {
        ...flow,
        members: members
      } as ApprovalFlow;

      const request = mapToRequest(updatedFlow);
      await api.put(`/approval-flows`, request);
      setTimeout(() => {
        onClose(true);
        setLoading(false);
      }, 300);
    } catch (error) {
      console.log(error);
    }
  };

  const mapToRequest = (flow: ApprovalFlow): ApprovalFlowRequest => {
    const mappedMembers = flow.members.map(member => {
      const {
        first_name,
        last_name,
        created_at,
        updated_at,
        email,
        avatar_url,
        ...rest
      } = member;
      return rest;
    });

    const mappedSteps = flow.steps.map(step => {
      const { created_at, updated_at, requirements, approvers, ...rest } = step;
      const mappedRequirements = requirements.map(requirement => {
        const { created_at, updated_at, ...rest } = requirement;
        return rest;
      });
      const mappedApprovers = approvers.map(approver => {
        const { created_at, updated_at, ...rest } = approver;
        return rest;
      });
      return {
        ...rest,
        requirements: mappedRequirements,
        approvers: mappedApprovers
      };
    });

    return {
      id: flow.id,
      name: flow.name,
      steps: mappedSteps,
      members: mappedMembers,
      is_template: flow.is_template,
      status: flow.status
    };
  };

  const roles = {
    owner: "Proprietário",
    user: "Utilizador",
    editor: "Editor"
  };

  const changeRole = (email: string, role: string) => {
    setHasUpdated(true);
    const newMembers = members.map(m => {
      if (m.email === email) {
        m.role = role;
      }
      return m;
    });
    setMembers(newMembers);
    setMenuOpen(-1);
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth={"md"}>
      <DialogTitle style={{ fontWeight: 700, fontSize: "16px" }}>
        {isEditing ? "Editar membros" : `Compartilhar ${flow.name}`}
      </DialogTitle>
      <DialogContent>
        <IconCloseWrapper onClick={() => onClose()}>
          <GrClose />
        </IconCloseWrapper>
        <Container>
          <Divider />

          <Grid
            container
            spacing={2}
            style={{ alignItems: "end", minWidth: "100%" }}
          >
            <Grid item xs={9}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={3}>
              <SendButton onClick={sendInvitation}>Enviar convite</SendButton>
            </Grid>
          </Grid>

          {loading && (
            <LoadingContainer>
              <ContractAnimation />
            </LoadingContainer>
          )}
          {isError && (
            <MessageErrorContainer>
              <MessageErrorText>
                Ocorreu um erro ao enviar o convite. Verifique se o e-mail está
                correto ou se o e-mail é cadastrado no UX DOC.
              </MessageErrorText>
            </MessageErrorContainer>
          )}

          {!loading && members.length > 1 && (
            <TableContainer>
              <Table>
                <THead>
                  <tr>
                    <TableHead style={{ width: "40%" }}>Nome</TableHead>
                    <TableHead>Email</TableHead>
                    {isEditing && <TableHead>Tipo de usuário</TableHead>}
                    <TableHead style={{ width: "40px" }}></TableHead>
                  </tr>
                </THead>
                <tbody>
                  {members
                    .filter(m => m.member_id !== loggedUserId)
                    .map((item, index) => (
                      <TableRow key={item.id}>
                        <TableData>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                          >
                            <Avatar
                              alt={item.first_name}
                              src="/static/images/avatar/1.jpg"
                              style={{
                                backgroundColor: "#0066CC",
                                width: "30px",
                                height: "30px"
                              }}
                            />
                            <span>
                              {" "}
                              {item.first_name} {item.last_name}
                            </span>
                          </Stack>
                        </TableData>
                        <TableData>
                          <EmailLabel>{item.email}</EmailLabel>
                        </TableData>
                        {isEditing && (
                          <TableData>
                            <EmailLabel>
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                position={"relative"}
                                style={{ cursor: "pointer" }}
                              >
                                <EmailLabel onClick={() => handleClick(index)}>
                                  {roles[item.role]}
                                </EmailLabel>

                                <BiChevronDown
                                  style={{ fontSize: "24px" }}
                                  onClick={() => handleClick(index)}
                                />
                                {menuOpen === index && (
                                  <MenuContainer onMouseLeave={handleClose}>
                                    <MenuItem
                                      onClick={() =>
                                        changeRole(item.email, "editor")
                                      }
                                    >
                                      Editor
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        changeRole(item.email, "user")
                                      }
                                    >
                                      Utilizador
                                    </MenuItem>
                                  </MenuContainer>
                                )}
                              </Stack>
                            </EmailLabel>
                          </TableData>
                        )}
                        <TableData>
                          {" "}
                          <TrashWrapper
                            onClick={() => removeMember(item.email)}
                          >
                            <TrashIcon />
                          </TrashWrapper>
                        </TableData>
                      </TableRow>
                    ))}
                </tbody>
              </Table>
            </TableContainer>
          )}

          {!loading && user && (
            <MessageContainer>
              <IoMdCheckmarkCircleOutline
                style={{ fontSize: "32px", color: "#007b36" }}
              />
              <span>
                O convite foi enviado com sucesso. Caso o convidado não receba o
                e-mail de convite, peça que verifique a caixa de entrada, lixo
                eletrônico e SPAM.
              </span>
            </MessageContainer>
          )}
        </Container>
      </DialogContent>
      <DialogActions>
        <ContainerButton>
          <CancelButton onClick={() => onClose(user !== undefined)}>
            Cancelar
          </CancelButton>
          <SaveButton
            onClick={updateFlow}
            disabled={!hasUpdated && !hasInvited}
          >
            Concluir
          </SaveButton>
        </ContainerButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditMembers;
