import React, { useCallback, useEffect, useState } from "react";
import { BoxModal, Container, Footer } from "./styles";

import HeaderModal2FA from "../HeaderModal2FA";
import ControllerSteps from "../ControllerSteps";

import ButtonSecure from "../ButtonSecure";
import { useTwoFactorAuth } from "../../../../../hooks/twoFactorAuth";
import LearnTwoFactor from "../LearnTwoFactor";
import Setup2FA from "../Setup2FA";
import Validate2FA from "../Validate2FA";
import Done2Fa from "../Done2Fa";
import { ToastContainer } from "react-toastify";
import HeaderSetup2fa from "../HeaderSetup2fa";

type Props = {
  setIsValidateOTP: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalSetup2FA: React.FC<Props> = ({ setIsValidateOTP }) => {
  const {
    stepSecure,
    setStepSecure,
    handleCloseModal,
    handleNextStep,
    isOTPComplete,
    setIsOTPComplete,
    isAuthenticatedOTP,
    setisAuthenticatedOTP,
    otp,
    setOTP,
  } = useTwoFactorAuth();

  const handleCancel2FA = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  useEffect(() => {
    setisAuthenticatedOTP(false);
    return () => setStepSecure("1");
  }, []);

  return (
    <Container>
      <ToastContainer />
      <BoxModal>
        <HeaderModal2FA isSetup title="Autenticação de dois fatores" />

        <ControllerSteps step={stepSecure} />
        {stepSecure === "1" && <LearnTwoFactor />}
        {stepSecure === "2" && <Setup2FA />}
        {stepSecure === "3" && (
          <Validate2FA
            isAuthenticated={isAuthenticatedOTP}
            setIsOTPComplete={setIsOTPComplete}
            otp={otp}
            setOTP={setOTP}
          />
        )}

        {stepSecure === "4" && <Done2Fa />}

        <Footer>
          {(stepSecure === "3" && isAuthenticatedOTP) || stepSecure === "4" ? (
            <></>
          ) : (
            <ButtonSecure onClick={handleCancel2FA} action={"cancel"} />
          )}
          <ButtonSecure
            isDisabled={stepSecure === "3" && !isOTPComplete}
            action={"advance"}
            onClick={handleNextStep}
            isChecked={stepSecure === "3" && isAuthenticatedOTP}
            isDone={stepSecure === "4"}
          />
        </Footer>
      </BoxModal>
    </Container>
  );
};

export default ModalSetup2FA;

