import React, { useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import { PagesProps } from "../../../../../dtos/PagesProps";
import SceneKonva from "../../../../editor/components/Canvas/SceneKonva";
import { Button, WrapperButtons } from "../../../styles";
import api from "../../../../../services/api";
import { SaveTemplateRequest } from "../../../types/saveTemplateRequest";
import { Loading } from "../../loading";
import { useNavigate } from "react-router-dom";
import { useMainHook } from "../../../../../hooks/main";
import { AllMyProjects } from "../../../../../dtos/AllMyProjects";
import { useTextsEdition } from "../../../../editor/hooks/textsEdition";
import { useSelection } from "../../../../editor/hooks/selection";
import Rendering from "../../../../editor/components/Canvas/Rendering";
import { Layer, Stage } from "react-konva";
import { useDiff } from "../../../../editor/hooks/diffDocuments";
import { useMetric } from "../../../../../hooks/metric";
import { Header, Container } from "./styled";
import PlainText from "../../../../../shared/components/PlainText";
import ButtonExit from "../../../../../shared/components/ButtonExit";

export interface Props {
  objectScreen: PagesProps[];
  prevStep: () => void;
  handleClose: () => void;
  isRenderingImage: boolean;
  saveAsNormalProject?: boolean;
}

const Screen: React.FC<Props> = ({
  objectScreen,
  prevStep,
  isRenderingImage,
  handleClose,
  saveAsNormalProject
}) => {
  const oneRef = useRef(null);

  const {
    setSelectedObject,
    currentMultipleSelection,
    selectedObject,
    transformerSelectedObject
  } = useSelection();

  const { addToHistory, nameOfCurrentUser, hasGroupShared } = useMainHook();
  const { blockCommandKeyboard, setBlockCommandKeyboard } = useSelection();

  const widthCanvas: number = 596;
  const heigthCanvas: number = 842;

  const {
    quillRef,
    requestTextUpdate,
    textRich,
    isEditing,
    setIsEditing,
    blockingKeyboard,
    setBlockingKeyboard,
    quillRefComponent
  } = useTextsEdition();

  const {
    templateStartedShared,
    setTemplateStartedShared,
    oldContentsState,
    setOldContentsState,
    newContentsState,
    setNewContentsState,
    selectionCursor,
    setSelectionCursor,
    findDiff,
    buttonRef,
    newDelta,
    setNewDelta,
    isEditingQuill,
    setIsEditingQuill,
    newChanges,
    setNewChanges,
    newContentsRef,
    oldContentsRef,
    getQuillCursorRef,
    isNewTextRef,
    isTypingQuillRef,
    whichUserEdited,
    interceptModifications,
    textIsChanged,
    blockRenderImage,
    blockBackspace,
    whichKeyPress,
    selectionFormatText
  } = useDiff();
  const {
    setObjectScreen,
    currentTemplateInfo,
    documentName,
    setIsLoadingModal
  } = useMainHook();
  const { handleProjectCreatedOneClickAction } = useMetric();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const saveTemplate = async () => {
    try {
      setLoading(true);
      const title = saveAsNormalProject
        ? documentName.current
        : "Template one click";
      const request: SaveTemplateRequest = {
        is_oneclick: saveAsNormalProject ? false : true,
        title: title,
        template: {
          arrayOfPages: objectScreen
        },
        description: title,
        is_automation: false
      };
      const response = await api.post("user-templates", request, {
        params: {
          is_oneclick: saveAsNormalProject ? false : true
        }
      });

      //Document create OneClick action register function
      handleProjectCreatedOneClickAction({ project_id: response.data.id });

      setTimeout(() => {
        handleChooseTemplate(response.data);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.error("ERROR SAVING TEMPLATES " + error);
    }
  };

  const handleChooseTemplate = async (myProject: AllMyProjects) => {
    documentName.current = myProject.title;
    try {
      const response = await api.get(`user-templates/template/${myProject.id}`);

      currentTemplateInfo.current = response.data;
      documentName.current = response.data.title;
      // setObjectScreen(response.data.template.arrayOfPages);
      setIsLoadingModal(false);
      navigate(`/editor/my-template/${myProject.id}`);
    } catch (err) {
      setIsLoadingModal(false);
      console.error(err, "error when get specific template");
    }
  };

  const handleLayerClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    //Prevent any click action within Konva stage
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
  };

  useEffect(() => {
    return () => {
      documentName.current = "";
    };
  }, []);

  return (
    <Container>
      {loading && (
        <Box
          position={"fixed"}
          left={0}
          width={"100vw"}
          height={"100vh"}
          display={"flex"}
          alignItems={"center"}
        >
          <Loading />
        </Box>
      )}
      {!loading && (
        <>
          <Header>
            <PlainText>{documentName.current}</PlainText>

            <div>
              <button
                onClick={e => {
                  e.stopPropagation();
                  prevStep();
                }}
              >
                Quero outro {saveAsNormalProject ? "documento" : "design"}
              </button>
              <button
                className="button-edit"
                onClick={e => {
                  e.stopPropagation();
                  saveTemplate();
                }}
              >
                Editar no UX DOC
              </button>
              <ButtonExit onClick={handleClose} inactivatedColor="#999C9F" />
            </div>
          </Header>
          {objectScreen.map((item, index) => (
            <Box
              key={index}
              bgcolor={"#fff"}
              mt={2}
              onClick={handleLayerClick}
              style={{ pointerEvents: "none" }}
            >
              <Stage
                ref={oneRef}
                listening={false}
                pixelRatio={5}
                scaleX={1}
                scaleY={1}
                width={widthCanvas}
                height={heigthCanvas}
              >
                <Layer>
                  {item.renderObjects.map((objectProps, index) => (
                    <Rendering
                      key={index}
                      selectedObject={selectedObject}
                      setSelectedObject={setSelectedObject}
                      objectProps={objectProps}
                      addToHistory={addToHistory}
                      commandKeyboard={blockCommandKeyboard}
                      setCommandBlockKeyboard={setBlockCommandKeyboard}
                      currentMultipleSelection={currentMultipleSelection}
                      setObjectScreen={setObjectScreen}
                      transformerSelectedObject={transformerSelectedObject}
                      quillRef={quillRef}
                      requestUpdateText={requestTextUpdate}
                      textRich={textRich}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      blockingKeyboard={blockingKeyboard}
                      setBlockingKeyboard={setBlockingKeyboard}
                      templateStartedShared={templateStartedShared}
                      setTemplateStartedShared={setTemplateStartedShared}
                      oldContentsState={oldContentsState}
                      setOldContentsState={setOldContentsState}
                      newContentsState={newContentsState}
                      setNewContentsState={setNewContentsState}
                      selectionCursor={selectionCursor}
                      setSelectionCursor={setSelectionCursor}
                      findDiff={findDiff}
                      buttonRef={buttonRef}
                      newDelta={newDelta}
                      setNewDelta={setNewDelta}
                      isEditingQuill={isEditingQuill}
                      setIsEditingQuill={setIsEditingQuill}
                      newChanges={newChanges}
                      setNewChanges={setNewChanges}
                      oldContentsRef={oldContentsRef}
                      newContentsRef={newContentsRef}
                      getQuillCursorRef={getQuillCursorRef}
                      isNewTextRef={isNewTextRef}
                      isTypingQuillRef={isTypingQuillRef}
                      quillRefComponent={quillRefComponent}
                      whichUserEdited={whichUserEdited}
                      nameOfCurrentUser={nameOfCurrentUser}
                      interceptModifications={interceptModifications}
                      textIsChanged={textIsChanged}
                      blockRenderImage={blockRenderImage}
                      blockBackspace={blockBackspace}
                      hasGroupShared={hasGroupShared}
                      whichKeyPress={whichKeyPress}
                      selectionFormatText={selectionFormatText}
                    />
                  ))}
                </Layer>
              </Stage>
            </Box>
          ))}
        </>
      )}
    </Container>
  );
};

export default Screen;
