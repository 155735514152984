import Konva from "konva";
import { PagesProps } from "../../../dtos/PagesProps";

interface SelectionDataProps {
  setSelectedObject: React.Dispatch<React.SetStateAction<Konva.Shape>>;
  myId: string;
  currentPageForModification: number;
  objectScreen: PagesProps[];
  stageRef: React.MutableRefObject<Konva.Stage[]>;
}

export const automaticSelectionWithoutPage = ({
  setSelectedObject,
  myId,
  stageRef,
  objectScreen,
  currentPageForModification
}: SelectionDataProps) => {
  objectScreen?.forEach(page => {
    page?.renderObjects?.forEach((renderedObject: any) => {
      if (renderedObject?.id === myId) {
        const findStage: Konva.Stage = stageRef.current?.find(
          el => el?.attrs?.id == currentPageForModification
        );

        const result = findStage?.find(`#${myId}`);
        const ShapeResult: any = result[0];
        setSelectedObject(ShapeResult);
      }
    });
  });
};
