import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 33px;
  left: 0px;
  background-color: #fff;
  width: 218px;
  /* min-height: 268px; */
  height: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: default;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  flex-direction: column;

  .flexbox-fix
    div[style*="position: relative; height: 10px; margin-top: 4px; overflow: hidden;"] {
    /* Estilize o elemento filho desejado aqui */
    display: none;
  }

  /* .flexbox-fix div[style*="position: absolute; inset: 0px;"] {
    margin-top: 0.5rem;
  } */
`;

export const Grid = styled.div`
  height: auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 5px;
`;

export const GridHistoric = styled.div`
  height: auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
`;

export const GridItem = styled.div<{
  isActive?: boolean;
  color: string;
  currentColor: string;
}>`
  width: 24px;
  height: 24px;
  display: flex;
  background-color: ${({ color }) => color && color};
  border-radius: 5px;
  cursor: pointer;
  border: ${({ color, currentColor }) =>
    color === currentColor ? "2px solid #5D5FEF" : "1px solid #ebebec"};
`;

export const Divider = styled.div`
  width: 100%;
  background-color: #d6d8d9;
  height: 1px;
  margin: 1rem 0;
`;

export const HStack = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  gap: 5px;
  justify-content: flex-end;
`;

export const CurrentColor = styled.div<{ color: string }>`
  width: 24px;
  height: 24px;
  background-color: ${({ color }) => (color ? color : "#000")};
  display: flex;
  border-radius: 5px;
  border: 1px solid #999c9f;
  cursor: not-allowed;
`;

export const InputColor = styled.div`
  flex: 1;
  display: flex;
  border: 1px solid #999c9f;
  border-radius: 5px;
  background-color: #fff;
`;

export const TransparencyColor = styled.div<{
  url: string;
  isTransparency?: boolean;
}>`
  width: 24px;
  height: 24px;
  background-color: #fff;
  display: flex;
  border-radius: 5px;
  border: 1px solid #999c9f;
  background-image: ${({ url }) => url && `url(${url})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 120% 120%;
  background-position: 50% 50%;
  cursor: ${({ isTransparency }) =>
    isTransparency ? "not-allowed" : "pointer"};
`;

export const ChooseColor = styled.div<{ url: string }>`
  width: 24px;
  height: 24px;
  background-color: yellow;
  display: flex;
  border-radius: 5px;
  border: 1px solid #999c9f;
  background-image: ${({ url }) => url && `url(${url})`};
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
  padding: 0 5px;

  &:focus {
    border: 1px solid #5d5fef;
  }
`;

export const HistoricColors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Span = styled.span`
  display: flex;
`