import styled from "styled-components";

export const Title = styled.h5`
  font-family: "Ubuntu";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.black100};
`;
