import styled from "styled-components";

interface ContainerProps extends React.HTMLProps<HTMLDivElement> {
  maxWidth?: string;
}

export const ContainerDefaultPage = styled.div<ContainerProps>`
  width: 100%;
  max-width: 530px;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 7.03px;
  z-index: 60;

  & .btn-toolbar {
    align-items: center;
    display: flex;
    background: transparent;
    width: 30px;
    height: 30px;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    z-index: 90;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const ContainerRight = styled.div<ContainerProps>`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 7.03px;
  z-index: 60;

  & .btn-toolbar {
    align-items: center;
    display: flex;
    background: transparent;
    width: 30px;
    height: 30px;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    z-index: 90;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  gap: 7.03px;

  height: 24px;
  border-right: 1px solid #d6d8d9;
  padding-right: 7.03px;
`;
