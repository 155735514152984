import styled from "styled-components";

interface ListProps {
  showFilter?: string;
  isFavoriteFilter?: boolean;
}

export const ListStyle = styled.ul<ListProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  padding-right: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;
