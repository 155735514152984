import React, { useEffect, useRef } from "react";

import jsPDF from "jspdf";
import Konva from "konva";
import Rendering from "../../../modules/editor/components/Canvas/Rendering";

import { motion } from "framer-motion";
import { Layer, Stage } from "react-konva";
import { useMainHook } from "../../../hooks/main";
import { Container, StageContainer } from "./styles";
import { PagesProps } from "../../../dtos/PagesProps";
import { Modal, ModalProps } from "@material-ui/core";
import { useSelection } from "../../../modules/editor/hooks/selection";
import { useToolbarEditor } from "../../../modules/editor/hooks/toolbarEditor";
import { useDiff } from "../../../modules/editor/hooks/diffDocuments";
import { useTextsEdition } from "../../../modules/editor/hooks/textsEdition";

interface PersonalizedModalProps extends ModalProps {
  documentName: string;
  downloading: boolean;
  setDownloading: React.Dispatch<React.SetStateAction<boolean>>;
  pagesToBeRendered: PagesProps[] | null;
}
type ModalOmitted = Omit<PersonalizedModalProps, "open" | "children">;

const DownloadDocument: React.FC<ModalOmitted> = ({
  downloading,
  pagesToBeRendered,
  setDownloading,
  documentName,
  ...rest
}) => {
  const allStages = useRef<Konva.Stage[]>([]);

  const {
    quillRef,
    requestTextUpdate,
    textRich,
    isEditing,
    setIsEditing,
    blockingKeyboard,
    setBlockingKeyboard,
    quillRefComponent
  } = useTextsEdition();

  const {
    templateStartedShared,
    setTemplateStartedShared,
    oldContentsState,
    setOldContentsState,
    newContentsState,
    setNewContentsState,
    selectionCursor,
    setSelectionCursor,
    findDiff,
    buttonRef,
    newDelta,
    setNewDelta,
    isEditingQuill,
    setIsEditingQuill,
    newChanges,
    setNewChanges,
    newContentsRef,
    oldContentsRef,
    getQuillCursorRef,
    isNewTextRef,
    isTypingQuillRef,
    whichUserEdited,
    interceptModifications,
    textIsChanged,
    blockRenderImage,
    blockBackspace,
    whichKeyPress,
    selectionFormatText
  } = useDiff();

  const { nameOfCurrentUser, hasGroupShared } = useMainHook();

  useEffect(() => {
    (async () => {
      await new Promise((res, rej) => {
        setTimeout(() => {
          res("");
        }, 1000);
      });

      if (downloading) {
        let myDoc = new jsPDF("p", "px", "a4", true);

        allStages.current.forEach((page: any, index) => {
          const data_url = page.toDataURL({
            pixelRatio: 2
          });
          let width = myDoc.internal.pageSize.getWidth();
          let height = myDoc.internal.pageSize.getHeight();
          let top_left_margin = 0;
          let PDF_Width = width + top_left_margin * 2;
          let PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
          if (index > 0) {
            myDoc.addPage();
            myDoc.addImage(data_url, "PNG", 0, 0, width, height);
          } else {
            myDoc.addImage(data_url, "PNG", 0, 0, width, height);
          }
        });
        allStages.current = [];
        myDoc.save(documentName);
        setDownloading(false);
      }
    })();
  }, [downloading]);

  const {
    setSelectedObject,
    currentMultipleSelection,
    selectedObject,
    transformerSelectedObject
  } = useSelection();

  const { blockCommandKeyboard, setBlockCommandKeyboard } = useSelection();
  const { addToHistory, setObjectScreen } = useMainHook();

  const widthCanvas: number = 596;
  const heigthCanvas: number = 842;

  return (
    <Modal
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0)" } }}
      open={downloading}
      {...rest}
      style={{ backgroundColor: "rgba(0,0,0,0)" }}
    >
      <Container>
        {pagesToBeRendered?.map((item, index) => (
          <StageContainer
            key={item.pageNumber}
            as={motion.div}
            style={{
              position: "absolute",
              right: window.innerWidth
            }}
          >
            <Stage
              ref={currentStage => {
                if (currentStage && !allStages.current.includes(currentStage)) {
                  allStages.current.push(currentStage);
                }
              }}
              listening={false}
              pixelRatio={5}
              scaleX={1}
              scaleY={1}
              width={widthCanvas}
              height={heigthCanvas}
            >
              <Layer>
                {item.renderObjects.map((objectProps, index) => (
                  <Rendering
                    key={index}
                    selectedObject={selectedObject}
                    setSelectedObject={setSelectedObject}
                    objectProps={objectProps}
                    addToHistory={addToHistory}
                    commandKeyboard={blockCommandKeyboard}
                    setCommandBlockKeyboard={setBlockCommandKeyboard}
                    currentMultipleSelection={currentMultipleSelection}
                    setObjectScreen={setObjectScreen}
                    transformerSelectedObject={transformerSelectedObject}
                    quillRef={quillRef}
                    requestUpdateText={requestTextUpdate}
                    textRich={textRich}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    blockingKeyboard={blockingKeyboard}
                    setBlockingKeyboard={setBlockingKeyboard}
                    templateStartedShared={templateStartedShared}
                    setTemplateStartedShared={setTemplateStartedShared}
                    oldContentsState={oldContentsState}
                    setOldContentsState={setOldContentsState}
                    newContentsState={newContentsState}
                    setNewContentsState={setNewContentsState}
                    selectionCursor={selectionCursor}
                    setSelectionCursor={setSelectionCursor}
                    findDiff={findDiff}
                    buttonRef={buttonRef}
                    newDelta={newDelta}
                    setNewDelta={setNewDelta}
                    isEditingQuill={isEditingQuill}
                    setIsEditingQuill={setIsEditingQuill}
                    newChanges={newChanges}
                    setNewChanges={setNewChanges}
                    oldContentsRef={oldContentsRef}
                    newContentsRef={newContentsRef}
                    getQuillCursorRef={getQuillCursorRef}
                    isNewTextRef={isNewTextRef}
                    isTypingQuillRef={isTypingQuillRef}
                    quillRefComponent={quillRefComponent}
                    whichUserEdited={whichUserEdited}
                    nameOfCurrentUser={nameOfCurrentUser}
                    interceptModifications={interceptModifications}
                    textIsChanged={textIsChanged}
                    blockRenderImage={blockRenderImage}
                    blockBackspace={blockBackspace}
                    hasGroupShared={hasGroupShared}
                    whichKeyPress={whichKeyPress}
                    selectionFormatText={selectionFormatText}
                  />
                ))}
              </Layer>
            </Stage>
          </StageContainer>
        ))}
      </Container>
    </Modal>
  );
};

export default DownloadDocument;
