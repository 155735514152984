import {
  Container,
  CommentBox,
  HeaderComment,
  IconBox,
  IconRes,
  InputModeElement
} from "./style";
import IconCheck from "../../assets/check.svg";
import IconEdit from "../../assets/edit.svg";
import IconTrash from "../../assets/trash.svg";
import IconTest from "../../assets/response-icon.svg";
import IconResponse from "../../assets/send-message.svg";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  formatDistanceToNow
} from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { format, utcToZonedTime } from "date-fns-tz";
import { useAuth } from "../../../../../../hooks/auth";
import ReactLoading from "react-loading";

interface ICommentProps {
  type: "Main Comment" | "Response" | "Comment To View";
  categoryComment?: "All" | "Historic";
  onClick?: () => void;
  message?: string;
  page?: string;
  commentDate?: string;
  avatar?: string;
  name?: string;
  numberPage?: string;
  amountReplies?: number;
  isShowReplies?: boolean;
  handleDoneCommentary?: () => void;
  handleDeleteCommentary?: () => void;
  inputValue?: string;
  setInputValue?: React.Dispatch<React.SetStateAction<string>>;
  idComment?: string;
  inputEditComment?: string;
  setInputEditComment?: React.Dispatch<React.SetStateAction<string>>;
  handleEditComment?: () => void;
  indexComment?: number;
  isDone?: boolean;
  idUser?: string;
}

const Comment = ({
  type,
  categoryComment,
  onClick,
  message,
  page,
  commentDate,
  avatar,
  name,
  numberPage,
  amountReplies,
  isShowReplies,
  handleDoneCommentary,
  handleDeleteCommentary,
  inputValue,
  setInputValue,
  idComment,
  inputEditComment,
  setInputEditComment,
  handleEditComment,
  indexComment,
  isDone,
  idUser
}: ICommentProps) => {
  const [inputMode, setInputMode] = useState(false);
  const [isOwnAuthor, setIsOwnAuthor] = useState(false);
  const [blockCallback, setBlockCallback] = useState(false);
  const refInput = useRef<HTMLTextAreaElement>(null);
  const { data } = useAuth();

  useEffect(() => {
    if (idUser === data?.user?.id) {
      setIsOwnAuthor(true);
    } else {
      setIsOwnAuthor(false);
    }
  }, [idUser, data]);

  const handleKeyPress = useCallback(
    event => {
      if (event.key === "Enter") {
        setInputMode(false);
        handleEditComment();
      }
    },
    [handleEditComment]
  );

  const formatDateFinal = useCallback((date: string) => {
    let myObject = date;

    let now = new Date();
    const nyTimeZone = "UTC";
    const myDate = utcToZonedTime(myObject, nyTimeZone);
    const nowDate = utcToZonedTime(now, nyTimeZone);
    const daysDifference = differenceInDays(nowDate, myDate);
    const hoursDifference = differenceInHours(nowDate, myDate);
    const minutesDifference = differenceInMinutes(nowDate, myDate);

    let differenceText = "";

    if (daysDifference > 0) {
      differenceText = `- ${daysDifference} dia${
        daysDifference === 1 ? "" : "s"
      } atrás`;
    } else if (hoursDifference > 0) {
      differenceText = `- ${hoursDifference} hora${
        hoursDifference === 1 ? "" : "s"
      } atrás`;
    } else if (minutesDifference > 0) {
      differenceText = `- ${minutesDifference} minuto${
        minutesDifference === 1 ? "" : "s"
      } atrás`;
    }

    return differenceText || "- Agora";
  }, []);

  useEffect(() => {
    if (inputMode) {
      setInputEditComment(message);
    }
  }, [inputMode]);

  useEffect(() => {
    if (refInput.current) {
      if (inputEditComment.length > 0) {
        refInput.current.style.height = refInput.current?.scrollHeight + "px";
      } else {
        refInput.current.style.height = "26px";
      }
    }
  }, [inputEditComment]);

  return (
    <Container
      onClick={() => {
        if (inputMode) return;

        if (blockCallback) {
          setBlockCallback(false);
          return;
        }

        if (!blockCallback) {
          onClick();
        }
      }}
      type={type}
    >
      {type === "Response" && <IconRes src={IconTest} alt="Response Icon" />}
      <CommentBox type={type}>
        <div>
          <HeaderComment>
            {type !== "Response" && (
              <span>{`Página ${Number(numberPage)}`} </span>
            )}
            <div style={{ cursor: "pointer" }} onClick={onClick}>
              <img src={avatar} alt="" />

              <p>
                <b> {name} </b> <span> {formatDateFinal(commentDate)} </span>
              </p>
            </div>
          </HeaderComment>

          {categoryComment !== "Historic" && (
            <IconBox>
              {type !== "Response" && isOwnAuthor && (
                <img
                  onMouseEnter={() => {
                    setBlockCallback(true);
                  }}
                  onMouseOut={() => {
                    setBlockCallback(false);
                  }}
                  onClick={handleDoneCommentary}
                  src={IconCheck}
                  alt="Concluir"
                />
              )}
              {isOwnAuthor && (
                <img
                  onMouseEnter={() => {
                    setBlockCallback(true);
                  }}
                  onMouseOut={() => {
                    setBlockCallback(false);
                  }}
                  src={IconEdit}
                  onClick={() => {
                    setBlockCallback(true);
                    setInputMode(true);
                    setTimeout(() => {
                      refInput.current.focus();
                    }, 100);
                  }}
                  alt="Editar"
                />
              )}
              {isOwnAuthor && (
                <img
                  onMouseEnter={() => {
                    setBlockCallback(true);
                  }}
                  onMouseOut={() => {
                    setBlockCallback(false);
                  }}
                  src={IconTrash}
                  onClick={handleDeleteCommentary}
                  alt="Lixeira"
                />
              )}
            </IconBox>
          )}
        </div>

        {inputMode ? (
          <InputModeElement>
            <textarea
              spellCheck="false"
              ref={refInput}
              onBlur={() => {
                setBlockCallback(true);
                setInputMode(false);
                handleEditComment();
              }}
              value={inputEditComment}
              onKeyDown={handleKeyPress}
              onChange={event => setInputEditComment(event.target.value)}
              onInput={() => {
                refInput.current.style.height =
                  refInput.current.scrollHeight + "px";
              }}
            />

            <img src={IconResponse} alt="" />
          </InputModeElement>
        ) : (
          <p>{message}</p>
        )}

        {isShowReplies && (
          <span
            onClick={onClick}
            style={{ cursor: "pointer" }}
          >{`${amountReplies} resposta`}</span>
        )}
      </CommentBox>
      {type === "Comment To View" && categoryComment === "Historic" && (
        <p className="status-comment">{isDone ? "Concluído" : "Excluído"} </p>
      )}
    </Container>
  );
};

export default Comment;
