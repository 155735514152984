import styled from "styled-components";
import { Theme } from "../../../../../../../dtos/theme";

export const Flex = styled.div`
  width: 100%;

  display: flex;
  padding-top: 0.5rem;
  margin-top: 0.2rem;
`;

export const VStack = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 1rem;
  /* margin-bottom: 15rem !important; */
`;

export const BoxNotListApi = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span`
  font-size: 2rem;
  color: ${({ theme }: Theme) => theme.colors.gray300};
  text-align: center;
  line-height: 2.7rem;

  @media (max-width: 1600px) {
    font-size: 1.7rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.6rem;
    line-height: 2.2rem;
    padding: 0 1rem;
  }
`;

export const LineDivider = styled.div`
  width: 100%;
  height: 0;
  margin-top: -2rem;
  margin-bottom: 2rem;
  border: 1px solid #c4c4c4;
  background: #c4c4c4;
`;
