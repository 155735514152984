import { Container, Main, Footer, BoxYearly, BoxMonthly } from "./styled";
import Logo from "../../../modules/user/unknown/login/assets/uxlogotipo.svg";
import IconPromotion from "../../assets/star-promotion.svg";
import IconSearch from "../../assets/bigger-search.svg";
import { useCallback, useState } from "react";
import api from "../../../services/api";
import { getStripeJs } from "../../../services/stipe-js";
import { LoadingBox } from "../../../modules/editor/components/CommentsScreen/components/ListAllComments/components/ListAllCommentsPage/styled";
import { ContractAnimation } from "../../components/ContractAnimation";

const PagePlans = () => {
  const [loading, setLoading] = useState(false);

  const handlePlan = useCallback(async (type: "monthly" | "yearly") => {
    try {
      setLoading(true);
      const response = await api.post("stripe/create-session-payment", {
        type_plan: type
      });
      const { session_id } = response.data;
      const stripe = await getStripeJs();
      await stripe?.redirectToCheckout({
        sessionId: session_id
      });
    } catch (err) {
      setLoading(true);
      console.error(err, "error when get payment checkout stripe");
      alert(err.message);
    }
  }, []);

  if (loading) {
    return (
      <LoadingBox>
        <ContractAnimation />
      </LoadingBox>
    );
  }

  return (
    <Container>
      <section>
        <header>
          <img src={Logo} alt="UXDOC" />
        </header>
        <Main>
          <ul>
            <h3>
              Starter <div />
            </h3>
            <li>1 usuários</li>
            <li>Documentos ilimitados com legal design</li>
            <li>Modelos prontos</li>
            <li>Insira elementos visuais da sua marca</li>
            <li>Automação</li>
            <li>Banco de texto simplificado e ícones jurídicos</li>
            <li>Controle de alterações</li>
            <li>Documento compartilhável</li>
            <li>Armazenamento em nuvem</li>
            <li>Assinatura eletrônica</li>
          </ul>
          <Footer>
            <BoxMonthly>
              <p>Plano mensal</p>
              <span>
                R$ <span className="big-num">59</span>,90/mês
              </span>
              <button type="button" onClick={() => handlePlan("monthly")}>
                Plano mensal
              </button>
            </BoxMonthly>
            <div className="line" />
            <BoxYearly>
              <p>Plano anual</p>
              <div>
                <span>
                  Total de R$ <span className="big-num">39</span>,91/mês
                </span>
                <p>Cobrados anualmente</p>
              </div>
              <button type="button" onClick={() => handlePlan("yearly")}>
                Plano anual
              </button>
              <img src={IconPromotion} alt="" className="promotion" />
              <img src={IconSearch} alt="" className="search" />
            </BoxYearly>
          </Footer>
        </Main>
      </section>
    </Container>
  );
};

export default PagePlans;
