import styled from "styled-components";

export const Canvas = styled.div`
  width: 596px;
  height: 842px;
  background: white;
  margin: 0 auto;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
`;

export const FlexDesignArea = styled.div<{
  isCommentCreate?: boolean;
  srcImageCursor?: string;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 3;
  position: relative;
  cursor: ${({ isCommentCreate, srcImageCursor }) =>
    !isCommentCreate ? "" : `url(${srcImageCursor}), auto`};
`;
