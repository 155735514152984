import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useMainHook } from "../../../hooks/main";
import { useHeaderEditor } from "./headerEditor";
import { usePagesEditor } from "./pagesEditor";

interface AutomaticSavingProviderProps {
  children: ReactNode;
}

interface AutomaticSavingData {
  isSave: boolean;
  setIsSave: React.Dispatch<React.SetStateAction<boolean>>;
  isTemplateIsReady: boolean;
  setIsTemplateIsReady: React.Dispatch<React.SetStateAction<boolean>>;
  handleDisableAutoSave: () => void;
  isScrollChangeRef: React.MutableRefObject<boolean>;
  initialLoad: React.MutableRefObject<boolean>;
}

const AutomaticSaving = createContext({} as AutomaticSavingData);

const AutomaticSavingProvider: React.FC<AutomaticSavingProviderProps> = ({
  children
}) => {
  const { objectScreen, pendingSave } = useMainHook();
  const { handleSaveTemplate, setIsSavingAutomatic, mountStages } =
    useHeaderEditor();
  const { linespage } = usePagesEditor();
  const [isSave, setIsSave] = useState(true);
  const [isTemplateIsReady, setIsTemplateIsReady] = useState(false);
  const debounceTimeoutRef = useRef(null);
  // const debounceTimeoutPendRef = useRef(null);
  const shouldSaveRef = useRef(true);
  const isScrollChangeRef = useRef(false);
  const initialLoad = useRef(true);

  // const [scrolling, setScrolling] = useState(false);

  //debounce function concept
  const saveAutomaticDoc = useCallback(() => {
    debounceTimeoutRef.current = setTimeout(async () => {
      if (shouldSaveRef.current) {
        setIsSavingAutomatic(true);
        await handleSaveTemplate(true);
        setIsSave(true);
        pendingSave.current = false;
      }
    }, 5000);
  }, [handleSaveTemplate, pendingSave, setIsSavingAutomatic, shouldSaveRef]);

  

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    if (mountStages) {
      return;
    }

    if (!isScrollChangeRef.current) {
      if (isTemplateIsReady) {
        setIsSave(false);

        if (debounceTimeoutRef.current) {
          clearTimeout(debounceTimeoutRef.current);
        }

        saveAutomaticDoc();
      }
    } else {
      if (pendingSave.current) {
        if (debounceTimeoutRef.current) {
          clearTimeout(debounceTimeoutRef.current);
        }

        saveAutomaticDoc();
      }

      isScrollChangeRef.current = false;
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
      // clearTimeout(debounceTimeoutPendRef.current);
      setIsSave(true);
    };
  }, [objectScreen, isTemplateIsReady, linespage]);

  const handleDisableAutoSave = useCallback(() => {
    shouldSaveRef.current = false;

    setTimeout(() => {
      shouldSaveRef.current = true;
    }, 5000);
  }, [shouldSaveRef]);

  return (
    <AutomaticSaving.Provider
      value={{
        isSave,
        setIsSave,
        isTemplateIsReady,
        setIsTemplateIsReady,
        handleDisableAutoSave,
        isScrollChangeRef,
        initialLoad
      }}
    >
      {children}
    </AutomaticSaving.Provider>
  );
};

// creating hook

function useAutomaticSaving(): AutomaticSavingData {
  const context = useContext(AutomaticSaving);

  if (!context) {
    throw new Error("useAutomaticSaving must be used with an AutomaticSaving");
  }

  return context;
}

export { AutomaticSavingProvider, useAutomaticSaving };
