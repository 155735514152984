/*
In this file, we have a hook for controlling actions relative to pages
then when user manipulate pages of editor such as remove pages,
move to up or down pages, duplicate or add new page callbacks here.
*/

import { arrayMoveImmutable } from "array-move";
import Konva from "konva";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMainHook } from "../../../hooks/main";
import { useSelection } from "./selection";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";
import { useTextsEdition } from "./textsEdition";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { imagePropsApi } from "../components/UploadImages";

//set up interfaces
interface PagesEditorProviderProps {
  children: ReactNode;
}

interface IPosition {
  x: number;
  y: number;
}

export interface ILinesPage {
  pageNumber: number;
  islineH: boolean;
  islineV: boolean;
  lineGuidH: { y: number; id: string }[];
  lineGuidV: { x: number; id: string }[];
}

const widthCanvas: number = 596;
const heigthCanvas: number = 842;

interface PagesEditorData {
  handleRemovePage: (page: number) => void;
  handleOrderPageMoveDown: (page: number) => void;
  handleOrderPageMoveUp: (page: number, index: number) => void;
  handleDuplicatePage: (page: number, index: number) => void;
  isActiveNewPage: {
    visible: boolean;
    index: number;
  };
  setIsActiveNewPage: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      index: number;
    }>
  >;
  handleAddNewPage: (page: number) => void;
  handleHoverIcon: (index: number, visible: boolean) => void;
  isActiveDuplicatePage: {
    visible: boolean;
    index: number;
  };
  setIsActiveDuplicatePage: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      index: number;
    }>
  >;
  handleHoverIconDuplicatePage: (index: number, visible: boolean) => void;
  isActiveHoverIconRemovePage: {
    visible: boolean;
    index: number;
  };
  setIsActiveHoverIconRemovePage: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      index: number;
    }>
  >;
  handleHoverIconRemovePage: (index: number, visible: boolean) => void;
  idPage: number;
  setIdPage: React.Dispatch<React.SetStateAction<number>>;
  isPageOneOnly: boolean;
  setIsPageOneOnly: React.Dispatch<React.SetStateAction<boolean>>;
  isVisibleAddPage: boolean;
  setIsVisibleAddPage: React.Dispatch<React.SetStateAction<boolean>>;
  disabledButton: {
    visible: boolean;
    index: number;
  };

  setDisabledButton: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      index: number;
    }>
  >;
  stagePointerPosition: { x: number; y: number };
  setStagePointerPosition: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >;

  copyObject: () => void;
  pasteObject: (isARightButton?: boolean) => void;

  scaleForZoom: { x: number; y: number; scale: number };
  setScaleForZoom: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
      scale: number;
    }>
  >;
  sizePage: number;
  setSizePage: React.Dispatch<React.SetStateAction<number>>;
  mousePosition: IPosition;
  setMousePosition: React.Dispatch<React.SetStateAction<IPosition>>;
  linesH: number[];
  setLinesH: React.Dispatch<React.SetStateAction<number[]>>;
  linesV: number[];
  setLinesV: React.Dispatch<React.SetStateAction<number[]>>;
  isguidLineH: boolean;
  setIsguidLineH: React.Dispatch<React.SetStateAction<boolean>>;
  isguidLineV: boolean;
  setIsguidLineV: React.Dispatch<React.SetStateAction<boolean>>;

  linespage: ILinesPage[];
  setLinesPage: React.Dispatch<React.SetStateAction<ILinesPage[]>>;
  handleApplyAutoNumbering: (
    isTeam: boolean,
    templateId: string,
    isActive?: boolean,
    position?: "center" | "right",
    rule?: boolean
  ) => void;
  isHasAutoPage?: boolean;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  position: "right" | "center";
  setPosition: React.Dispatch<React.SetStateAction<"center" | "right">>;
  showAfterFirstPage: boolean;
  setShowAfterFirstPage: React.Dispatch<React.SetStateAction<boolean>>;
  idTemplate: string;
  setIdTemplate: React.Dispatch<React.SetStateAction<string>>;
  loadingAutoNumber: boolean;
  setLoadingAutoNumber: React.Dispatch<React.SetStateAction<boolean>>;
  handleImagePaste: (event: ClipboardEvent) => Promise<void>;
}
//creting context
const PagesEditorContext = createContext({} as PagesEditorData);

const PagesEditorProvider: React.FC = ({
  children,
}: PagesEditorProviderProps) => {
  //call others hooks necessary
  const {
    objectScreen,
    setObjectScreen,
    stageRef,
    addToHistory,
    hasGroupShared,
    currentTemplateInfo,
    documentName,
    autoNumber,
    setAutoNumber,
    pendingSave,
  } = useMainHook();
  const {
    selectedObject,
    selectedObjects,
    setSelectedObject,
    setSelectedObjects,
    IsModalVisible,
    currentMultipleSelection,
    multipleSelectionRefs,
  } = useSelection();

  //set up states
  const { isEditing, blockingKeyboard, setIsEditing } = useTextsEdition();
  const [idPage, setIdPage] = useState(1);
  const { blockCommandKeyboard, forceBlockKey } = useSelection();
  const [loadingAutoNumber, setLoadingAutoNumber] = useState(false);
  const [isCopyFromApp, setIsCopyFromApp] = useState(false);
  // const { hasGroupShared, whichUserEdited } = useDiff();

  const [stagePointerPosition, setStagePointerPosition] = useState({
    x: 0,
    y: 0,
  });
  const [sizePage, setSizePage] = useState(null);
  const [scaleForZoom, setScaleForZoom] = useState({ x: 0, y: 0, scale: 1 });
  const [isVisibleAddPage, setIsVisibleAddPage] = useState(false);
  const [automaticSelect, setAutomaticSelect] = useState<string>(null);
  const [isPageOneOnly, setIsPageOneOnly] = useState(false);
  const [, updateState] = useState<any>();
  const [copyElement, setCopyElement] = useState<Konva.Shape>(null);
  const [pasteMultipleElements, setPasteMultipleElements] = useState([]);
  const [copyElements, setCopyElements] = useState<any>([]);
  const [disabledButton, setDisabledButton] = useState({
    visible: true,
    index: 0,
  });

  const [linespage, setLinesPage] = useState<ILinesPage[]>([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [linesH, setLinesH] = useState<number[]>([]);
  const [linesV, setLinesV] = useState<number[]>([]);
  const [isguidLineH, setIsguidLineH] = useState(false);
  const [isguidLineV, setIsguidLineV] = useState(false);
  const [isHasAutoPage, setIsHasAutoPage] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [position, setPosition] = useState<"center" | "right">("right");
  const [idTemplate, setIdTemplate] = useState("");
  const [showAfterFirstPage, setShowAfterFirstPage] = useState(true);
  const [isActiveDuplicatePage, setIsActiveDuplicatePage] = useState({
    visible: false,
    index: 0,
  });

  const [isActiveNewPage, setIsActiveNewPage] = useState({
    visible: false,
    index: 0,
  });

  const [isActiveHoverIconRemovePage, setIsActiveHoverIconRemovePage] =
    useState({
      visible: false,
      index: 0,
    });

  const requestAPIImage = async (blob: Blob) => {
    let newWidth: number;
    let newHeight: number;
    const fetchNewImage = await api.post("user-image-repository");
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      const imageUrl = base64String;
      const img = new Image();
      img.src = imageUrl;

      img.onload = async () => {
        if (img.width >= 596 || img.height >= 842) {
          let widthRatio = 596 / img.width;
          let heightRatio = 842 / img.height;
          let bestRatio = Math.min(widthRatio, heightRatio);
          newWidth = img.width * bestRatio;
          newHeight = img.height * bestRatio;
        } else {
          //if image is smaller or medium, apply the same size
          newWidth = img.width;
          newHeight = img.height;
        }

        const newX =
          widthCanvas - newWidth < 0 ? 0 : (widthCanvas - newWidth) / 2;
        const newY =
          heigthCanvas - newHeight < 0 ? 0 : (heigthCanvas - newHeight) / 2;
        const newId = uuidv4();

        setObjectScreen((prevState) => {
          let cloneState = cloneDeep(prevState);
          const page = cloneState.find(
            (page) => Number(page.pageNumber) === Number(idPage)
          );
          if (page) {
            cloneState[page.pageNumber - 1].renderObjects?.push({
              x: newX, // Verificar com o time de produto
              y: newY, // Verificar com o time de produto
              id: newId,
              object: "dropImage",
              src: imageUrl,
              page: idPage,
              width: newWidth,
              height: newHeight,
              draggable: true,
              isLoadingImage: true,
            });
          }
          return cloneState;
        });

        try {
          const { id } = fetchNewImage.data as imagePropsApi;
          let formData = new FormData();
          formData.append("image", blob);

          const responseUpload = await api.patch(
            `user-image-repository/image-upload/${id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          const { image_url: image_urlUpdate } = responseUpload.data;

          setObjectScreen((prevState) => {
            let cloneState = cloneDeep(prevState);
            const page = cloneState.find(
              (page) => Number(page.pageNumber) === Number(idPage)
            );
            if (page) {
              cloneState[page.pageNumber - 1].renderObjects?.forEach(
                (object) => {
                  if (object.id === newId) {
                    object.src = image_urlUpdate;
                    object.isLoadingImage = false;
                  }
                }
              );
            }
            return cloneState;
          });

          navigator.clipboard.writeText("");
        } catch (error) {
          console.error("error on uploading images", error.response.data);
        }
      };
    };
    reader.readAsDataURL(blob);
  };

  const handleImagePaste = useCallback(
    async (event: ClipboardEvent) => {
      // Verifica se o navegador suporta o acesso à área de transferência

      if (navigator.clipboard) {
        // Lê os itens da área de transferência
        navigator.clipboard
          .read()
          .then((clipboardItems) => {
            // Itera sobre os itens da área de transferência
            for (const item of clipboardItems) {
              // Verifica se o tipo de mídia é uma imagem
              if (
                item.types.includes("image/png") ||
                item.types.includes("image/jpeg")
              ) {
                // Obtém a imagem
                item.getType("image/png").then(async (blob: Blob) => {
                  const file = new File([blob], "fileName.png", {
                    type: "image/png",
                  });
                  requestAPIImage(file);
                });
              }
            }
          })
          .catch((err) => {
            console.error("Erro ao ler a área de transferência:", err);
          });
      } else {
        toast.error(
          "O seu navegador não permite colar da área de transferência."
        );
      }

      const clipboardData = event.clipboardData;

      // Verificar se há itens na área de transferência
      if (isCopyFromApp) {
        pasteObject(true);

        setIsCopyFromApp(false);
        return;
      }
      let isImagePasted = false;

      if (clipboardData?.items) {
        setIsCopyFromApp(false);
        const item = clipboardData.items[0];

        if (
          item?.kind === "file" &&
          (item?.type === "image/jpeg" || item?.type === "image/png")
        ) {
          isImagePasted = true;
          const blob = item.getAsFile();
          requestAPIImage(blob);
        } else {
          if (item?.kind === "file") {
            toast.error("Apenas arquivos JPEG ou PNG são suportados.");
          }
        }
      }
    },
    [
      setObjectScreen,
      blockCommandKeyboard,
      forceBlockKey,
      selectedObject,
      selectedObjects,
      isEditing,
      blockingKeyboard,
      copyElement,
      copyElements,
      idPage,
      isCopyFromApp,
    ]
  );

  //this event listener have responsabilities of execute some callbacks
  //observer keyboard events to trigger copy and paste feature
  //when user call CTRL + C && CTRL + V (trigger event)
  const myFunc = useCallback(
    (event: KeyboardEvent) => {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if ((event.ctrlKey || event.metaKey) && charCode === "c") {
        copyObject();
      }
    },
    [
      blockCommandKeyboard,
      forceBlockKey,
      selectedObject,
      selectedObjects,
      isEditing,
      blockingKeyboard,
      copyElement,
      copyElements,
    ]
  );
  //then useEffect will be observer when user used keyboard
  //to copy and paste actions
  useEffect(() => {
    document.addEventListener("keydown", myFunc, true);
    document.addEventListener("paste", handleImagePaste, true);
    return () => {
      document.removeEventListener("paste", handleImagePaste, true);
      document.removeEventListener("keydown", myFunc, true);
    };
  }, [selectedObject, selectedObjects, blockCommandKeyboard, forceBlockKey]);

  const idPageRef = useRef(1);

  const handleApplyAutoNumbering = useCallback(
    async (isTeam: boolean, templateId: string) => {
      if (idTemplate) {
        setLoadingAutoNumber(true);
        setAutoNumber((oldState) => {
          return {
            ...oldState,
            isActive: isChecked,
            position: position,
            firstPage: showAfterFirstPage,
          };
        });
        let formatedObjectToApi: any = [];
        objectScreen.forEach((page, index) => {
          let pageNumber = ++index;
          let renderObjects = [];

          page.renderObjects.forEach((renderObject) => {
            let objectWithExtractedImagePropertie = null;
            if (renderObject?.image) {
              //remove break change in json
              let { image, ...rest } = renderObject;
              objectWithExtractedImagePropertie = rest;
            }

            renderObjects.push(
              objectWithExtractedImagePropertie
                ? objectWithExtractedImagePropertie
                : renderObject
            );
          });

          formatedObjectToApi.push({
            pageNumber,
            renderObjects: renderObjects,
            lineGuide: {
              h: linespage[pageNumber - 1]?.lineGuidH,
              v: linespage[pageNumber - 1]?.lineGuidV,
            },
          });
        });

        const objectTeam = {
          team_template_id: templateId,
          template: {
            arrayOfPages: formatedObjectToApi,
          },
          title: currentTemplateInfo?.current?.title,
          description:
            currentTemplateInfo?.current?.description ??
            `${currentTemplateInfo?.current?.title}`,
          is_automatic_numbering_active: isChecked,
          automatic_numbering_position: position ? position : "right",
          first_page_automatic_numbering: showAfterFirstPage,
        };

        const objectUser = {
          user_template_id: templateId,
          template: {
            arrayOfPages: formatedObjectToApi,
          },
          title: currentTemplateInfo?.current?.title,
          description:
            currentTemplateInfo?.current?.description ??
            `${currentTemplateInfo?.current?.title}`,
          is_automatic_numbering_active: isChecked,
          automatic_numbering_position: position ? position : "right",
          first_page_automatic_numbering: showAfterFirstPage,
        };

        try {
          const updateAutoNumber = await api.put(
            isTeam ? `team-templates` : `user-templates`,
            isTeam ? objectTeam : objectUser
          );
          setLoadingAutoNumber(false);
          setIsActive(false);
          toast.success("Configurações de numeração aplicada.");
        } catch (err) {
          toast.error("Erro interno. Tente mais tarde.");
          setLoadingAutoNumber(false);
          console.error(err.response, "err save auto numbnering");
        }
      }
    },
    [
      idTemplate,
      setAutoNumber,
      objectScreen,
      isChecked,
      position,
      showAfterFirstPage,
      linespage,
      currentTemplateInfo,
    ]
  );

  //when user wish to add new page
  //then manipulate our main state
  //to concate with new page
  //and reorder thats pages
  const handleAddNewPage = useCallback(
    (page?: number) => {
      addToHistory();

      setObjectScreen((prevState) => {
        let numberOfpages = prevState.length;
        let cloneState = cloneDeep(prevState);
        let newCloneState = cloneState.map((el, index) => {
          return {
            pageNumber: index + 1,
            renderObjects: el?.renderObjects,
          };
        });
        let arrayFinally = [...newCloneState];
        arrayFinally.push({
          pageNumber: Number(numberOfpages + 1),
          renderObjects: [],
        });

        let lastPageNew = arrayFinally.length - 1;
        const fromIndex = page;
        const endArray = arrayMoveImmutable(
          arrayFinally,
          lastPageNew,
          fromIndex
        );

        const reorderPageNumber = endArray.map((el, index) => {
          const isPageBeforeOrAfter = index >= page - 1 && index <= page + 1;
          const isFirstOrSecondPage = page === 1 ? index <= 1 : false;

          return {
            pageNumber: index + 1,
            renderObjects: el.renderObjects,
            visible: isPageBeforeOrAfter || isFirstOrSecondPage,
          };
        });

        //reset trasnformers of pages (multiple selection)
        // multipleSelectionRefs.current = [];
        pendingSave.current = true;
        return reorderPageNumber;
      });

      setLinesPage((prevState) => {
        let numberOfpages = prevState.length;
        let cloneState = cloneDeep(prevState);
        let newCloneState = cloneState.map((el, index) => {
          return {
            pageNumber: index + 1,
            islineH: el.islineH,
            islineV: el.islineV,
            lineGuidH: el.lineGuidH,
            lineGuidV: el.lineGuidV,
          };
        });
        let arrayFinally = [...newCloneState];
        arrayFinally.push({
          pageNumber: Number(numberOfpages + 1),
          islineH: false,
          islineV: false,
          lineGuidH: [],
          lineGuidV: [],
        });

        let lastPageNew = arrayFinally.length - 1;
        const fromIndex = page;
        const endArray = arrayMoveImmutable(
          arrayFinally,
          lastPageNew,
          fromIndex
        );

        const reorderPageNumber = endArray.map((el, index) => {
          return {
            pageNumber: index + 1,
            islineH: el.islineH,
            islineV: el.islineV,
            lineGuidH: el.lineGuidH,
            lineGuidV: el.lineGuidV,
          };
        });

        return reorderPageNumber;
      });
    },
    [
      objectScreen,
      multipleSelectionRefs,
      setLinesPage,
      isHasAutoPage,
      addToHistory,
      setObjectScreen,
      pendingSave,
    ]
  );

  //when user wish deleted pages in your document
  //then remove it that page and fix issues relative
  //multiple selection, everytime this happens so
  //reset arrays of transformers
  const handleRemovePage = useCallback(
    (page: number) => {
      //make to history action
      addToHistory();
      //reset trasnformers of pages (multiple selection)
      // multipleSelectionRefs.current = [];
      setObjectScreen((state) => {
        let cloneState = cloneDeep(state);
        const myArray = [];
        cloneState.forEach((pageObject, index) => {
          if (Number(page) !== index + 1) {
            pageObject.pageNumber = myArray.length + 1;
            if (page === index + 1 || page + 1 === index + 1) {
              pageObject.visible = true;
            } else {
              pageObject.visible = false;
            }
            myArray.push(pageObject);
          }
        });

        return myArray;
      });
      pendingSave.current = true;

      setLinesPage((state) => {
        let cloneState = cloneDeep(state);
        const myArray = [];
        cloneState.forEach((pageObject, index) => {
          if (Number(page) !== index + 1) {
            pageObject.pageNumber = myArray.length + 1;
            myArray.push(pageObject);
          }
        });

        return myArray;
      });
    },
    [
      multipleSelectionRefs,
      pendingSave,
      setLinesPage,
      addToHistory,
      setObjectScreen,
    ]
  );

  //when user wish move to down that page
  //then reorder array of pages in our main state
  const handleOrderPageMoveDown = useCallback(
    (page: number) => {
      //make a history action
      addToHistory();
      //reset trasnformers of pages (multiple selection)
      // multipleSelectionRefs.current = [];

      setObjectScreen((state) => {
        let cloneState = cloneDeep(state);

        let fromIndex = page;
        let toIndex = page + 1;
        let newState = arrayMoveImmutable(
          cloneState,
          fromIndex - 1,
          toIndex - 1
        );

        newState.forEach((pageState, index) => {
          pageState.pageNumber = index + 1;

          if (page === index + 1 || page + 1 === index + 1) {
            pageState.visible = true;
          } else {
            pageState.visible = false;
          }

          pageState.renderObjects.forEach((object) => {
            object.page = String(index + 1);
            object.id = uuidv4();
          });
        });

        return newState;
      });
      pendingSave.current = true;

      // updating guidelines when page down
      setLinesPage((state) => {
        let cloneState = cloneDeep(state);
        const findPage = cloneState.findIndex(
          (object) => object.pageNumber === parseInt(page + "")
        );
        let formattedState = [];

        cloneState.forEach((objectPage, index) => {
          let pageNumberOfPages = index + 1;

          formattedState.push({
            pageNumber: pageNumberOfPages,
            islineH: objectPage.islineH,
            islineV: objectPage.islineV,
            lineGuidH: objectPage.lineGuidH,
            lineGuidV: objectPage.lineGuidV,
          });
        });

        cloneState = formattedState;
        let fromIndex = findPage;
        let toIndex = findPage + 1;
        let newState = arrayMoveImmutable(cloneState, fromIndex, toIndex);

        newState.forEach((pageState, index) => {
          pageState.pageNumber = index + 1;
        });

        return newState;
      });
    },

    [
      setObjectScreen,
      pendingSave,
      addToHistory,
      multipleSelectionRefs,
      setLinesPage,
    ]
  );

  //when user wish move to up that page
  //then reorder array of pages in our main state
  const handleOrderPageMoveUp = useCallback(
    (page: number) => {
      //make a history action
      addToHistory();
      //reset trasnformers of pages (multiple selection)
      // multipleSelectionRefs.current = [];

      setObjectScreen((state) => {
        let cloneState = cloneDeep(state);

        let fromIndex = page;
        let toIndex = page - 1;
        let newState = arrayMoveImmutable(
          cloneState,
          fromIndex - 1,
          toIndex - 1
        );

        newState.forEach((pageState, index) => {
          pageState.pageNumber = index + 1;

          if (page === 1) {
            if (index <= 1) {
              pageState.visible = true;
            } else {
              pageState.visible = false;
            }
          } else {
            if (index + 1 === page - 1 || index + 1 === page) {
              pageState.visible = true;
            } else {
              pageState.visible = false;
            }
          }

          pageState.renderObjects.forEach((object) => {
            object.page = String(index + 1);
            object.id = uuidv4();
          });
        });

        return newState;
      });
      pendingSave.current = true;

      // updating guidelines when page down
      setLinesPage((state) => {
        let cloneState = cloneDeep(state);
        const findPage = cloneState.findIndex(
          (object) => object.pageNumber === parseInt(page + "")
        );
        let formattedState = [];

        cloneState.forEach((objectPage, index) => {
          let pageNumberOfPages = index + 1;

          formattedState.push({
            pageNumber: pageNumberOfPages,
            islineH: objectPage.islineH,
            islineV: objectPage.islineV,
            lineGuidH: objectPage.lineGuidH,
            lineGuidV: objectPage.lineGuidV,
          });
        });

        cloneState = formattedState;
        let fromIndex = findPage;
        let toIndex = findPage + 1;
        let newState = arrayMoveImmutable(cloneState, fromIndex, toIndex);

        newState.forEach((pageState, index) => {
          pageState.pageNumber = index + 1;
        });

        return newState;
      });
    },
    [
      setObjectScreen,
      pendingSave,
      multipleSelectionRefs,
      addToHistory,
      setLinesPage,
    ]
  );

  //when user wish duplicate with all elements that page
  //make new page and reorder with exactly design of page duplicated
  const handleDuplicatePage = useCallback(
    (page: number, index: number) => {
      // setSelectedObject(null);
      setObjectScreen((prevState) => {
        let cloneState = cloneDeep(prevState);
        let arrayFinally = cloneDeep(cloneState);
        let pages = stageRef.current;
        let newPages = [];
        const findStage = pages.find((el) => el.attrs.id == page);
        const selectedObjs = findStage?.children[0].children;
        selectedObjs.forEach((values, index) => {
          // let pageNumber = values.attrs.id;
          // let newObjs = { ...item.attrs, id: String(Math.random() * 2) };
          let cloneValues = cloneDeep(values);

          if (values?.attrs?.object === "richTextImage") {
            let cloneValues = cloneDeep(values.attrs);
            let cloneAttrs = {
              cornerRadius: cloneValues.cornerRadius,
              draggable: cloneValues.draggable,
              format: cloneValues.format,
              height: cloneValues.height,
              id: uuidv4(),
              name: cloneValues.name,
              object: cloneValues.object,
              offsetX: cloneValues.offsetX,
              offsetY: cloneValues.offsetY,
              page: cloneValues.page,
              rotation: cloneValues.rotation,
              scaleX: cloneValues.scaleX,
              scaleY: cloneValues.scaleY,
              skewX: cloneValues.skewX,
              skewY: cloneValues.skewY,
              src: cloneValues.src,
              text: cloneValues.text,
              textHTML: cloneValues.textHTML,
              width: cloneValues.width,
              x: cloneValues.x,
              y: cloneValues.y,
              initialDelta: {},
            };

            newPages.push({ ...cloneAttrs });
          } else {
            let cloneAttrs = {
              ...cloneValues.attrs,
              id: uuidv4(),
            };
            newPages.push(cloneAttrs);
          }
        });

        arrayFinally.push({
          pageNumber: page + 1,
          renderObjects: newPages,

          // visible: page + 1 || page - 1 || page ? true : false
        });

        let numberOfpages = arrayFinally.length;
        const fromIndex = numberOfpages - 1;
        const toIndex = page;
        const endArray = [
          ...arrayMoveImmutable(arrayFinally, fromIndex, toIndex),
        ];

        const reorderPageNumber = endArray.map((el, index) => {
          const isPageBeforeOrAfter = index >= page - 1 && index <= page + 1;
          const isFirstOrSecondPage = page === 1 ? index <= 1 : false;

          return {
            pageNumber: index + 1,
            renderObjects: [...el.renderObjects],
            visible: isPageBeforeOrAfter || isFirstOrSecondPage,
          };
        });
        //every time action make history
        addToHistory();
        reorderPageNumber.forEach((page, index) => {
          page.renderObjects.forEach((renderObject) => {
            renderObject.id = uuidv4();
          });
        });
        //reset trasnformers of pages (multiple selection)
        // multipleSelectionRefs.current = [];
        return reorderPageNumber;
      });
      pendingSave.current = true;

      setLinesPage((prevState) => {
        let cloneState = cloneDeep(prevState);
        let arrayFinally = cloneDeep(cloneState);
        const findStage = cloneState.find((el) => el.pageNumber === page);

        arrayFinally.push({
          pageNumber: page + 1,
          islineH: findStage?.islineH,
          islineV: findStage?.islineV,
          lineGuidH: findStage?.lineGuidH,
          lineGuidV: findStage?.lineGuidV,
        });

        let numberOfpages = arrayFinally.length;
        const fromIndex = numberOfpages - 1;
        const toIndex = page;
        const endArray = [
          ...arrayMoveImmutable(arrayFinally, fromIndex, toIndex),
        ];

        const reorderPageNumber = endArray.map((el, index) => {
          return {
            pageNumber: index + 1,
            islineH: el?.islineH,
            islineV: el?.islineV,
            lineGuidH: el?.lineGuidH,
            lineGuidV: el?.lineGuidV,
          };
        });

        return reorderPageNumber;
      });
    },
    [setObjectScreen, stageRef, addToHistory, pendingSave]
  );

  //this callback relative copy object
  //then when user copy one object
  //we have 2 options, if element unique or multiples
  //when keyboard variable is true then only return and
  //just keep this normal behavior
  const copyObject = useCallback(() => {
    setIsCopyFromApp(true);
    if (blockCommandKeyboard || forceBlockKey) {
      return;
    }

    if (IsModalVisible) {
      return;
    }

    if (selectedObject) {
      setAutomaticSelect(null);
      setCopyElements((oldState) => {
        let newState = [];
        return newState;
      });

      setCopyElement((prevState) => {
        let cloneState = cloneDeep(prevState);
        cloneState = selectedObject;
        return cloneState;
      });
      setSelectedObject(null);
    }

    if (!selectedObject) {
      setAutomaticSelect(null);
      setCopyElement(null);
      setCopyElements((oldState) => {
        let cloneState = cloneDeep(oldState);
        cloneState = selectedObjects;
        return cloneState;
      });
      setSelectedObject(null);
      currentMultipleSelection.current?.setNodes([]);
      setSelectedObjects([]);
    }
  }, [
    selectedObject,
    selectedObjects,
    blockCommandKeyboard,
    forceBlockKey,
    IsModalVisible,
    currentMultipleSelection,
    setSelectedObjects,
    setSelectedObject,
    isEditing,
  ]);

  useEffect(() => {
    if (!!automaticSelect) {
      if (copyElement && copyElements?.length === 0) {
        objectScreen?.forEach((page) => {
          page?.renderObjects?.forEach((renderedObject: any) => {
            if (renderedObject?.id == automaticSelect) {
              const findStage: Konva.Stage = stageRef.current?.find(
                (el) => el?.attrs?.id == idPageRef.current
              );
              const result = findStage?.find(`#${automaticSelect}`);

              if (result) {
                const ShapeResult: any = result[0];
                setSelectedObject(ShapeResult);
              }
            }
          });
        });
      }
      // setCopyElement(null);
    }
  }, [automaticSelect]);

  //in this case when will be many objects with selection
  //then this event will make new selection to that group objects
  //when copy and paste objects within multiple objects
  useEffect(() => {
    if (pasteMultipleElements && !hasGroupShared.current) {
      const nodes = [];
      const findStage: Konva.Stage = stageRef.current?.find(
        (el) => el.attrs.id == idPageRef.current
      );
      const currentIndexTransformerActive = 0;
      pasteMultipleElements?.forEach((object) => {
        const result = findStage?.find(`#${object.id}`);

        if (result) {
          const shapeResult = result[0];
          nodes?.push(shapeResult);
        }
      });
      currentMultipleSelection.current =
        multipleSelectionRefs.current[currentIndexTransformerActive];
      currentMultipleSelection?.current?.setNodes(nodes);
      setSelectedObjects(nodes);
      let colectObjectsName = currentMultipleSelection.current
        ?.nodes()
        ?.map((object) => {
          return object?.attrs?.object;
        });
      if (
        colectObjectsName?.includes("richTextImage") ||
        colectObjectsName?.includes("simpleLine")
      ) {
        currentMultipleSelection?.current?.anchorSize(0);
        currentMultipleSelection?.current?.rotateAnchorOffset(0);
      } else {
        currentMultipleSelection?.current?.anchorSize(10);
        currentMultipleSelection?.current?.rotateAnchorOffset(50);
      }
    }
  }, [pasteMultipleElements]);

  //ensure id page to make algorithm of automatic selection
  useEffect(() => {
    idPageRef.current = idPage;
  }, [idPage]);

  //when user paste object will be trigger this callback
  //based on the object previously copied and saved in its state in memory,
  // then we just identify that object and add a new and equal one with
  // the same characteristics to the page
  const pasteObject = useCallback(
    (isARightButton: boolean = false) => {
      if (!objectScreen) {
        return;
      }

      if (objectScreen.length < 1) {
        return;
      }

      if (blockCommandKeyboard || forceBlockKey) {
        return;
      }

      if (IsModalVisible) {
        return;
      }

      if ((copyElement || copyElements) && isARightButton) {
        addToHistory();
        setObjectScreen((prevState) => {
          let cloneState = cloneDeep(prevState);
          let newObjects = [];

          if (copyElements?.length === 0) {
            let cloneAttrs = cloneDeep(copyElement?.attrs);

            if (copyElement?.attrs?.object === "richTextImage") {
              const myIdObject = uuidv4();

              // const widthCanvas: number = 596;
              // const heigthCanvas: number = 842;
              let newObjectCloned: any = {
                cornerRadius: cloneAttrs?.cornerRadius,
                draggable: cloneAttrs?.draggable,
                format: cloneAttrs?.format,
                height: cloneAttrs?.height,
                id: myIdObject,
                name: cloneAttrs?.name,
                object: cloneAttrs?.object,
                offsetX: cloneAttrs?.offsetX,
                offsetY: cloneAttrs?.offsetY,
                page: idPageRef.current,
                rotation: cloneAttrs?.rotation,
                scaleX: cloneAttrs?.scaleX,
                scaleY: cloneAttrs?.scaleY,
                skewX: cloneAttrs?.skewX,
                skewY: cloneAttrs?.skewY,
                src: cloneAttrs?.src,
                text: cloneAttrs?.text,
                textHTML: cloneAttrs?.textHTML,
                width: cloneAttrs?.width,
                x: cloneAttrs?.x + 20,
                y: cloneAttrs?.y + 20,
                initialDelta: {},
              };

              cloneState[idPageRef.current - 1]?.renderObjects?.push({
                ...newObjectCloned,
              });

              setAutomaticSelect(myIdObject);
            } else {
              // const widthCanvas: number = 596;
              // const heigthCanvas: number = 842;
              const myIdObject = uuidv4();

              let posX = 0;
              let posY = 0;

              if (copyElement?.attrs?.page === idPageRef?.current) {
                posX = posX = copyElement?.attrs?.x + 20;
                posY = copyElement?.attrs?.y + 20;
              } else {
                posX = copyElement?.attrs?.x + 20;
                posY = copyElement?.attrs?.y + 20;
              }

              let myObjectPasted = {
                ...copyElement?.attrs,
                id: myIdObject,
                x: posX,
                y: posY,
              };

              cloneState[idPageRef.current - 1]?.renderObjects.push({
                ...myObjectPasted,
              });

              setAutomaticSelect(myIdObject);
            }
          } else {
            setAutomaticSelect(null);
            copyElements?.forEach((object, index) => {
              if (object?.attrs?.object === "richTextImage") {
                let cloneAttrs = cloneDeep(object.attrs);
                let myUUID = uuidv4();
                let newObjectCloned = {
                  id: myUUID,
                  cornerRadius: cloneAttrs?.cornerRadius,
                  draggable: cloneAttrs?.draggable,
                  format: cloneAttrs?.format,
                  height: cloneAttrs?.height,
                  name: cloneAttrs?.name,
                  object: cloneAttrs?.object,
                  offsetX: cloneAttrs?.offsetX,
                  offsetY: cloneAttrs?.offsetY,
                  page: idPageRef.current,
                  rotation: cloneAttrs?.rotation,
                  scaleX: cloneAttrs?.scaleX,
                  scaleY: cloneAttrs?.scaleY,
                  skewX: cloneAttrs?.skewX,
                  skewY: cloneAttrs?.skewY,
                  src: cloneAttrs?.src,
                  text: cloneAttrs?.text,
                  textHTML: cloneAttrs?.textHTML,
                  width: cloneAttrs?.width,
                  x: cloneAttrs?.x + 20,
                  y: cloneAttrs?.y + 20,
                  initialDelta: "",
                };

                newObjects?.push(newObjectCloned);
                setPasteMultipleElements([...newObjects]);
              } else {
                newObjects?.push({
                  ...object?.attrs,
                  id: uuidv4(),
                  x: object?.attrs.x + 20,
                  y: object?.attrs.y + 20,
                });

                setPasteMultipleElements([...newObjects]);
              }
            });

            cloneState[idPageRef.current - 1]?.renderObjects?.push(
              ...newObjects
            );
          }

          cloneState.forEach((page, index) => {
            page.renderObjects.forEach((renderedObject) => {
              // renderedObject.id = uuidv4();
              renderedObject.page = String(index + 1);
            });
          });

          return cloneState;
        });
      }
    },
    [
      objectScreen,
      blockCommandKeyboard,
      forceBlockKey,
      IsModalVisible,
      copyElement,
      copyElements,
      addToHistory,
      setObjectScreen,
    ]
  );

  //just control by index which object will stay visible
  const handleHoverIcon = (index: number, visible: boolean) => {
    setIsActiveNewPage({
      visible,
      index,
    });
  };

  //just control by index which object will stay visible
  const handleHoverIconDuplicatePage = (index: number, visible: boolean) => {
    setIsActiveDuplicatePage({
      visible,
      index,
    });
  };

  //just control by index which object will stay visible
  const handleHoverIconRemovePage = (index: number, visible: boolean) => {
    addToHistory();
    setIsActiveHoverIconRemovePage({
      visible,
      index,
    });
  };

  //only to ensure in this case of just one page in the editor
  //block delete for example a
  useEffect(() => {
    const numberOfPages = objectScreen.length;
    if (numberOfPages > 1) {
      setIsVisibleAddPage(false);
      setIsPageOneOnly(false);
    } else {
      setIsVisibleAddPage(true);
      setIsPageOneOnly(true);
    }
  }, [objectScreen]);

  useEffect(() => {
    setSizePage(objectScreen.length);
  }, [objectScreen]);

  return (
    <PagesEditorContext.Provider
      value={{
        handleRemovePage,
        handleOrderPageMoveDown,
        handleOrderPageMoveUp,
        handleDuplicatePage,
        setIsActiveNewPage,
        isActiveNewPage,
        handleAddNewPage,
        handleHoverIcon,
        handleHoverIconDuplicatePage,
        isActiveDuplicatePage,
        setIsActiveDuplicatePage,
        isActiveHoverIconRemovePage,
        setIsActiveHoverIconRemovePage,
        handleHoverIconRemovePage,
        idPage,
        setIdPage,
        isVisibleAddPage,
        setIsVisibleAddPage,
        isPageOneOnly,
        setIsPageOneOnly,
        disabledButton,
        setDisabledButton,
        copyObject,
        pasteObject,
        stagePointerPosition,
        setStagePointerPosition,
        scaleForZoom,
        setScaleForZoom,
        sizePage,
        setSizePage,
        mousePosition,
        setMousePosition,
        linesH,
        setLinesH,
        linesV,
        setLinesV,
        isguidLineH,
        setIsguidLineH,
        isguidLineV,
        setIsguidLineV,
        linespage,
        setLinesPage,
        handleApplyAutoNumbering,
        isHasAutoPage,
        isActive,
        setIsActive,
        isChecked,
        setIsChecked,
        position,
        setPosition,
        showAfterFirstPage,
        setShowAfterFirstPage,
        idTemplate,
        setIdTemplate,
        loadingAutoNumber,
        setLoadingAutoNumber,
        handleImagePaste,
      }}
    >
      {children}
    </PagesEditorContext.Provider>
  );
};

// creating hook

function usePagesEditor(): PagesEditorData {
  const context = useContext(PagesEditorContext);

  if (!context) {
    throw new Error(
      "useToolbarEditor must be used with an ToolbarEditorProvider.. "
    );
  }

  return context;
}

export { PagesEditorProvider, usePagesEditor };

