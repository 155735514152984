import React from "react";
import { useMainHook } from "../../../../hooks/main";
import { useModalContext } from "../../../../hooks/modalContext";
import { useSelection } from "../../hooks/selection";
import { useSharedDocument } from "../../hooks/sharedDocument";
import { useVersioning } from "../../hooks/versioning";
import { EditClauseModal } from "../EditClauseModal";
import { ModalActiveUser } from "../ModalActiveUser";
import { ModalBlockFuncionality } from "../ModalBlockFuncionality";
import ModalDescriptionVersioning from "../ModalDescriptionVersioning";
import { ModalHex } from "../ModalHex";
import { ModalSharedDocument } from "../ModalSharedDocument";
import { ModalSharedInfo } from "../ModalSharedInfo";
import ModalVersioning from "../ModalVersioning";
import { NewClausesModal } from "../NewClausesModal";
import { ModalViewClauses } from "../ModalViewClauses";
import ModalShowText from "../../../dashboard/myclausules/components/ModalShow";
import ModalViewText from "../ModalViewText";
import ModalDeleteText from "../ModalDeleteText";

// import { Container } from './styles';

const ProviderModals: React.FC = () => {
  const {
    isModalSharedOpen,
    handleCloseModalShared,
    isModalSharedInfoOpen,
    handleCloseModalSharedInfo,
    isModalActiveUser,
    setDocumentUsersAccess,
    setIsModalActiveUser,
    documentUsersAccess
  } = useSharedDocument();

  const {
    isNewClausesModalOpen,
    handleCloseNewClausesModal,
    isEditingClause,
    setIsEditingClause,
    handleCloseEditClauseModal,
    idClause,
    setRefreshClause,
    refreshClause,
    indexClause,
    objectClause,
    setObjectClause,
    setIsOpenModalViewClause,
    isOpenModalViewClause,
    handleOpenEditClauseModal,
    isOpenModalDeleteClause,
    setIsOpenModalDeleteClause,
    setIdClause
  } = useModalContext();

  const { isOpenEdit, handleCloseModal, typeChangeColor } = useSelection();

  const {
    isModalBlockFunctionality,
    handleCloseModalBlockFuncionalities,
    isVisibleClauseText,
    setIsVisibleClauseText,
    handleCloseViewTextClause
  } = useMainHook();

  const {
    isCompareVersionModalOpen,
    handleCloseCompareVersionModalOpen,
    versioningDescriptionModalOpen,
    handleCloseDescriptionVersioningModalOpen
  } = useVersioning();

  return (
    <>
      <ModalViewClauses
        isOpen={isVisibleClauseText}
        onRequestClose={handleCloseViewTextClause}
        setIsModalOpen={setIsVisibleClauseText}
      />

      <ModalActiveUser
        isOpen={isModalActiveUser}
        // onRequestClose={handleCloseActiveUser}
        dataUsers={documentUsersAccess}
      />
      <NewClausesModal
        isOpen={isNewClausesModalOpen}
        onRequestClose={handleCloseNewClausesModal}
      />
      <ModalHex
        type={typeChangeColor}
        isOpen={isOpenEdit}
        onRequestClose={handleCloseModal}
      />
      <ModalBlockFuncionality
        isOpen={isModalBlockFunctionality}
        onRequestClose={handleCloseModalBlockFuncionalities}
      />
      <ModalSharedDocument
        isOpen={isModalSharedOpen}
        onRequestClose={handleCloseModalShared}
      />
      <ModalSharedInfo
        isOpen={isModalSharedInfoOpen}
        onRequestClose={handleCloseModalSharedInfo}
      />
      <EditClauseModal
        isOpen={isEditingClause}
        onRequestClose={handleCloseEditClauseModal}
        idClause={idClause}
        refreshClause={refreshClause}
        indexClause={indexClause}
        setRefreshClause={setRefreshClause}
        setIsModalOpen={setIsEditingClause}
      />

      <ModalDeleteText
        idClause={idClause}
        isActive={isOpenModalDeleteClause}
        setIsModalOpen={setIsOpenModalDeleteClause}
        setIdClause={setIdClause}
      />

      <ModalViewText
        setObjectClause={setObjectClause}
        activeClausule={objectClause}
        setIsModalOpen={setIsOpenModalViewClause}
        isOpen={isOpenModalViewClause}
        handleOpenEditClauseModal={handleOpenEditClauseModal}
      />

      <ModalVersioning
        isOpen={isCompareVersionModalOpen}
        onRequestClose={handleCloseCompareVersionModalOpen}
      />
      <ModalDescriptionVersioning
        isOpen={versioningDescriptionModalOpen}
        onRequestClose={handleCloseDescriptionVersioningModalOpen}
      />
    </>
  );
};

export default ProviderModals;
