import styled from "styled-components";

export const Label = styled.label`
  font-size: 16px;
  font-weight: 400;
  color: #343a40;
`;

export const Link = styled.a`
  font-size: 16px;
  color: #663399;
  cursor: pointer;
  font-weight: 700;
`;

export default Label;
