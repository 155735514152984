import React from "react";

import { Header, UpdatesLabel } from "./styles";
import { BiInfoCircle } from "react-icons/bi";
import Tooltip from "../../../editor/components/Tooltip";

interface IHeaderSectionProps {
  title: string;
  description: string;
}

const HeaderSection: React.FC<IHeaderSectionProps> = ({
  title,
  description
}) => {
  return (
    <Header>
      <UpdatesLabel>
        {title}
        <Tooltip description={description} placement="bottom-start">
          <figure>
            <BiInfoCircle />
          </figure>
        </Tooltip>
      </UpdatesLabel>
    </Header>
  );
};

export default HeaderSection;
