import starFilledIcon from "../../../../../shared/assets/star-filled.svg";
import { Container, TitleItem, OptionsItem, Modal } from "./styles";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import { useState } from "react";
import { useTheme } from "../../../../../hooks/theme";
import { ClauseListProps } from "../../../../../hooks/teams";
import { ISelectModalTeam } from "../../../../../dtos/teamsUtils";

interface IClauseItemTeamProps {
  item: ClauseListProps;
  setClauseId: React.Dispatch<React.SetStateAction<string>>;
  setIsSettings: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
  handleFavoriteText: (textId: any) => Promise<void>;
  isOwner: boolean;
}

const ItemClauseTeam = ({
  item,
  setClauseId,
  setIsSettings,
  setSelectModal,
  handleFavoriteText,
  isOwner,
}: IClauseItemTeamProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();
  const [isVisibleOption, setIsVisibleOption] = useState(false);

  return (
    <Container
      onMouseEnter={() => setIsVisibleOption(true)}
      onMouseLeave={() => setIsVisibleOption(false)}
    >
      <TitleItem>
        {item.is_favorite ? (
          <div onClick={() => handleFavoriteText(item.id)}>
            <PersonalizedIcon
              dPath={MountIcons.IconStars.dPath}
              viewBox={MountIcons.IconStars.viewBox}
              inactivatedColor={theme.colors.primary}
            />
          </div>
        ) : (
          <img
            src={starFilledIcon}
            alt=""
            onClick={() => handleFavoriteText(item.id)}
          />
        )}
        <p
          onClick={() => {
            setClauseId(item.id);
            setSelectModal({ modal: "View Text", isOpen: true });
          }}
        >
          {item.title}
        </p>
      </TitleItem>
      <p>{item.key_words}</p>
      {isOwner && isVisibleOption && (
        <OptionsItem
          onClick={() => setIsOpen(!isOpen)}
          onMouseLeave={() => setIsOpen(false)}
          isActive={isOpen}
        >
          <span />
          <span />
          <span />
          {isOpen && (
            <Modal>
              <div
                onClick={() => {
                  setClauseId(item.id);
                  setIsSettings(true);
                  setSelectModal({ modal: "Create New Text", isOpen: true });
                }}
              >
                <PersonalizedIcon
                  dPath={MountIcons.IconEdit.dPath}
                  viewBox={MountIcons.IconEdit.viewBox}
                  inactivatedColor="#676B70"
                />
                Editar
              </div>
              <div
                onClick={() => {
                  setClauseId(item.id);
                  setSelectModal({ modal: "Delete Text", isOpen: true });
                }}
              >
                <PersonalizedIcon
                  dPath={MountIcons.IconTrash.dPath}
                  viewBox={MountIcons.IconTrash.viewBox}
                  inactivatedColor="#676B70"
                />
                Excluir
              </div>
            </Modal>
          )}
        </OptionsItem>
      )}
    </Container>
  );
};

export default ItemClauseTeam;

