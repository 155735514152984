import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  height: 100vh;

  p {
    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  @media (min-width: 1920px) {
    p {
      font-size: 16px;
    }
  }
`;

export const MainContainer = styled.div`
  width: 405px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  text-align: center;

  @media (max-width: 431px) {
    width: 87%;
  }

  @media (min-width: 1600px) {
    width: 450px;
  }

  @media (min-width: 1920px) {
    width: 560px;
  }
`;

export const Icon = styled.img`
  width: 123.1px;
  height: 53.25px;
`;

export const RightWrapper = styled.main`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  div {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 58%;
    max-width: 672px;
    min-width: 480px;
    padding-inline: 1rem;
  }

  h1 {
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 35.22px;
    color: #663399;
    margin: 0;
  }

  p {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 58px;
  background-color: ${props => `${props.color}`};
  color: #fff;
  border-radius: 10px;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 1600px) {
    height: 50px;
    font-size: 1.6rem;
  }

  @media (max-width: 1000px) {
    height: 42px;
    font-size: 1.4rem;
    margin-top: 2.5rem;
  }
`;
