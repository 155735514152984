import styled from "styled-components";

type CircleProps = {
  currentStep: boolean;
};

export const Container = styled.div`
  width: 210px;
  height: 30px;
  /* background-color: red; */
  margin: 0 auto;
  margin-top: 1.8rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 0.6rem;
`;

export const Circle = styled.div<CircleProps>`
  width: 30px;
  height: 30px;
  background-color: ${({ currentStep, theme }) =>
    currentStep ? theme.colors.primary : "#fff"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #999c9f;
  z-index: 100;

  span {
    font-size: 14px;
    color: ${({ currentStep }) => (currentStep ? "#fff" : "#999c9f")};
    font-family: Ubuntu, sans-serif;
  }
`;

export const LineIntersection = styled.div`
  background-color: #999c9f;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10;
`;

