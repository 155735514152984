import { ReactNode } from "react";
import { GridItem } from "./styles";

interface GridItemElementsProps {
  src?: string;
  description?: string;
  className?: string;
  children?: ReactNode;
  css?: any;
  isFixSize?: boolean;
}

const GridItemElements: React.FC<GridItemElementsProps> = ({
  src,
  description,
  className,
  children,
  css,
  isFixSize,
  ...rest
}): JSX.Element => {
  return (
    <>
      {isFixSize ? (
        <GridItem className={className} style={css} {...rest}>
          {children}
        </GridItem>
      ) : (
        <GridItem className={className} style={css} {...rest}>
          {children}
        </GridItem>
      )}
    </>
  );
};

export default GridItemElements;
