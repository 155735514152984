import React, { useCallback, useEffect, useState } from "react";
import { Box, Modal } from "@material-ui/core";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

import {
  Container,
  Header,
  MessageHeader,
  Navigation,
  ButtonHeader,
  BoxButtonNav
} from "./styles";

import { useTeams } from "../../../../../hooks/teams";
import api from "../../../../../services/api";

import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import TemplateIcon from "../../../../editor/components/Sidebar/components/Icons/TemplateIcon";
import UploadIcon from "../../../../../shared/assets/icons/UploadIcon";
import PlainText from "../../../../../shared/components/PlainText";
import MountIcons from "../../../../../shared/utils/MountIcons";
import OptionsModal from "./components/OptionsModal";
import ModalTeams from "../../components/ModalTeams";
import InputEditName from "./components/InputEditName";

const TeamSpecificPage: React.FC = () => {
  const {
    setCreatedTeamId,
    setSelectModal,
    setIsSettings,
    nameOfTeam,
    setNameOfTeam,
    setListTeams,
    listTeams,
    setIdLeaveTeam,
    setIsOwnerStaff,
    isOwnerStaff,
    selectModal
  } = useTeams();

  //route properties
  const { team_id } = useParams();
  const location = useLocation();
  const screenNowTeam = location.pathname.split("/")[3];

  const [loading, setLoading] = useState(true);
  const [isEditName, setIsEditName] = useState(false);
  const [isOpenModal, setIsOpneModal] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const responseIsOwner = await api.get(`team-users/is-owner/${team_id}`);
        setIsOwnerStaff(responseIsOwner.data.is_owner);
      } catch (err) {
        console.error(err, "error if thats user is owner of this team");
        setLoading(false);
      }

      try {
        const responseSpecificTeam = await api.get(`team/${team_id}`);
        setNameOfTeam(responseSpecificTeam.data.name);
        setLoading(false);
      } catch (err) {
        console.error(err, "error when get specific team infos");
        setLoading(false);
      }
    })();
  }, [team_id]);

  useEffect(() => {
    return () => {
      setIsOwnerStaff(false);
      setNameOfTeam("");
      setCreatedTeamId("");
    };
  }, []);

  const handleChangeNameTeam = useCallback(async () => {
    try {
      const responseUpdateNameTeam = await api.put("team", {
        team_id: team_id,
        name: nameOfTeam
      });
      setIsEditName(false);
      setListTeams(
        listTeams.filter(team => {
          if (team.team_id === team_id) {
            return (team.name = nameOfTeam);
          }
          return team;
        })
      );
    } catch (err) {
      setIsEditName(false);
      console.error(err, "error response when change name of team");
    }
  }, [team_id, listTeams, nameOfTeam, setListTeams]);

  return (
    <>
      <Container>
        <Header>
          <MessageHeader>
            <PlainText>
              {isOwnerStaff
                ? "Você é o proprietário dessa equipe e pode administrar, editar, incluir ou excluir conteúdos e membros da equipe."
                : "Você é um membro dessa equipe. Somente o proprietário pode administrar, editar, incluir ou excluir conteúdos e membros da equipe."}
            </PlainText>
          </MessageHeader>

          <Navigation>
            <Link to="/teams">
              <PersonalizedIcon
                dPath={MountIcons.IconArrowLeft.dPath}
                viewBox={MountIcons.IconArrowLeft.viewBox}
              />
            </Link>

            <InputEditName
              handleChangeNameTeam={handleChangeNameTeam}
              isOwnerStaff={isOwnerStaff}
              nameOfTeam={nameOfTeam}
              setNameOfTeam={setNameOfTeam}
              isEditName={isEditName}
              setIsEditName={setIsEditName}
            />

            <BoxButtonNav>
              <ButtonHeader isActive={screenNowTeam === "templates"}>
                <Link to={`templates`}>
                  <TemplateIcon
                    colorActive="#343A40"
                    inactiveColor="#676B70"
                    isActive={true}
                  />
                  Templates da equipe
                </Link>
              </ButtonHeader>
              <ButtonHeader isActive={screenNowTeam === "library"}>
                <Link to={`library`}>
                  <PersonalizedIcon
                    dPath={MountIcons.MyClausesIcon.dPath}
                    viewBox={MountIcons.MyClausesIcon.viewBox}
                    activeColor="#343A40"
                    inactivatedColor="#676B70"
                  />
                  Biblioteca da equipe
                </Link>
              </ButtonHeader>
              <ButtonHeader isActive={screenNowTeam === "uploads"}>
                <Link to={`uploads`}>
                  <UploadIcon colorActive="#343A40" isActive={false} />
                  Uploads da equipe
                </Link>
              </ButtonHeader>
            </BoxButtonNav>

            <OptionsModal
              isWoner={isOwnerStaff}
              setIdLeaveTeam={setIdLeaveTeam}
              setCreatedTeamId={setCreatedTeamId}
              setIsSettings={setIsSettings}
              setNameOfTeam={setNameOfTeam}
              setSelectModal={setSelectModal}
              teamId={team_id}
              teamName={nameOfTeam}
              isOpenModal={isOpenModal}
              setIsOpneModal={setIsOpneModal}
            />
          </Navigation>
        </Header>

        <Outlet />
      </Container>
      <Modal
        children={
          <Box>
            <ModalTeams />
          </Box>
        }
        open={selectModal.isOpen}
      />
    </>
  );
};

export default TeamSpecificPage;
