import React, { useEffect, useState } from "react";

import { Dialog, DialogTitle, Stack } from "@mui/material";
import {
  ButtonText,
  CancelButton,
  Container,
  ContainerButton,
  FormGroup,
  Input,
  Label,
  LinkButton,
  SaveButton,
  Subtitle,
  TrashWrapper
} from "./styles";
import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  Grid
} from "@material-ui/core";
import TrashIcon from "../TrashIcon";
import { Step } from "../../types/ApprovalFlow";
import AddIcon from "../AddIcon";
import { v4 as uuidv4 } from "uuid";
import { GrClose } from "react-icons/gr";
import { IconCloseWrapper } from "../../styles";
import { StepRequest } from "../../types/ApprovalFlowRequest";
import { toast } from "react-toastify";
import Tooltip from "../../../../editor/components/Tooltip";
import { BiInfoCircle } from "react-icons/bi";

type Props = {
  open: boolean;
  onClose: (step?: StepRequest) => void;
  step: StepRequest;
  setStep: (step: StepRequest) => void;
  stepNumber: number;
  isEditing?: boolean;
  flow_id: string;
};

const AddStep: React.FC<Props> = ({
  open,
  onClose,
  step,
  setStep,
  stepNumber,
  isEditing,
  flow_id
}: Props) => {
  const [activeStep, setActiveStep] = useState(1);

  const addRequirement = () => {
    const newRequirement = {
      id: uuidv4(),
      name: "",
      description: "",
      step_id: step.id,
      status: "PENDING",
      approval_flow_id: flow_id
    };
    setStep({ ...step, requirements: [...step.requirements, newRequirement] });
  };

  const addApprover = () => {
    const newApprover = {
      id: uuidv4(),
      name: "",
      email: "",
      step_id: step.id,
      status: "PENDING",
      approval_flow_id: flow_id
    };
    setStep({ ...step, approvers: [...step.approvers, newApprover] });
  };

  const removeApprover = (index: number) => {
    const newApprovers = step.approvers.filter(
      (_, approverIndex) => approverIndex !== index
    );
    setStep({ ...step, approvers: newApprovers });
  };

  const removeRequirement = (index: number) => {
    const newRequirements = step.requirements.filter(
      (_, requirementIndex) => requirementIndex !== index
    );
    setStep({ ...step, requirements: newRequirements });
  };
  const nextStep = () => {
    if (activeStep === 1) {
      if (!step.name || step.name.trim() === "") {
        toast.warn("O nome da etapa é obrigatório");
        return;
      }

      if (step.requirements.length === 0) {
        toast.warn("A etapa deve ter pelo menos um requisito de aprovação");
        return;
      }

      const hasEmptyRequirement = step.requirements.some(
        requirement => !requirement.name || requirement.name.trim() === ""
      );

      if (hasEmptyRequirement) {
        toast.warn("Todos os requisitos de aprovação devem ser preenchidos");
        return;
      }
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 2) {
      if (step.approvers.length === 0) {
        toast.warn("A etapa deve ter pelo menos um aprovador");
        return;
      }

      const hasEmptyName = step.approvers.some(
        approver => !approver.name || approver.name.trim() === ""
      );

      if (hasEmptyName) {
        toast.warn("Todos os nomes dos aprovadores devem ser preenchidos");
        return;
      }

      const hasInvalidEmail = step.approvers.some(
        approver =>
          !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(approver.email)
      );

      if (hasInvalidEmail) {
        toast.warn("Todos os emails dos aprovadores devem ser válidos");
        return;
      }

      const hasDuplicatedEmail = step.approvers.some(
        (approver, index) =>
          step.approvers.findIndex(
            approver2 => approver2.email === approver.email
          ) !== index
      );

      if (hasDuplicatedEmail) {
        toast.warn("Os emails dos aprovadores devem ser únicos");
        return;
      }

      onClose(step);
      return;
    }
  };
  const previousStep = () => {
    if (activeStep === 1) {
      onClose();
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const updateRequirement = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
    prop: string
  ) => {
    const value = event.target.value;
    const newRequirements = step.requirements.map(
      (requirement, requirementIndex) => {
        if (requirementIndex === index) {
          return { ...requirement, [prop]: value };
        }
        return requirement;
      }
    );
    setStep({ ...step, requirements: newRequirements });
  };

  const updateApprover = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
    prop: string
  ) => {
    const value = event.target.value;
    const newApprovers = step.approvers.map((approver, approverIndex) => {
      if (approverIndex === index) {
        return { ...approver, [prop]: value };
      }
      return approver;
    });
    setStep({ ...step, approvers: newApprovers });
  };

  return (
    <Dialog open={open} maxWidth={"md"}>
      <DialogTitle style={{ fontWeight: 700, fontSize: "16px" }}>
        {isEditing && "Editar"} Etapa {stepNumber}
      </DialogTitle>
      <DialogContent>
        <IconCloseWrapper onClick={() => onClose()}>
          <GrClose />
        </IconCloseWrapper>
        <Container>
          {activeStep === 1 && (
            <>
              <Subtitle>Defina os requisitos desssa etapa</Subtitle>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <FormGroup>
                    <Label>Nome da etapa</Label>
                    <Input
                      value={step.name}
                      onChange={event =>
                        setStep({ ...step, name: event.target.value })
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <FormGroup>
                    <Label>Prazo para aprovação</Label>
                    <Box
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center"
                      }}
                    >
                      <Input
                        style={{ width: "100%" }}
                        type="number"
                        value={step.deadline}
                        onChange={event =>
                          setStep({ ...step, deadline: +event.target.value })
                        }
                      />
                      <span>DIAS</span>
                    </Box>
                  </FormGroup>
                </Grid>
              </Grid>
              <Divider />

              {step.requirements.map((requirement, index) => (
                <Grid
                  container
                  spacing={2}
                  key={requirement.id}
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={6}>
                    <FormGroup>
                      {index === 0 && (
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={"4px"}
                        >
                          <Tooltip
                            description={
                              "Requisitos de aprovação funcionam como itens que o aprovador terá que aprovar dentro desta etapa. Assim, se a sua etapa é de “Verificação do Contrato”, um possível requisito de aprovação seria “Multa”, com a observação “Verificar se o contrato possui multa acima de R$ 1.000,00”, por exemplo."
                            }
                            placement="top"
                          >
                            <figure>
                              <BiInfoCircle fontSize={"18px"} />
                            </figure>
                          </Tooltip>
                          <Label>Requisitos de aprovação</Label>
                        </Stack>
                      )}

                      <Input
                        value={requirement.name}
                        onChange={event =>
                          updateRequirement(index, event, "name")
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <FormGroup>
                        {index === 0 && <Label>Descrição (opcional)</Label>}
                        <Input
                          value={requirement.description}
                          onChange={event =>
                            updateRequirement(index, event, "description")
                          }
                        />
                      </FormGroup>
                      <TrashWrapper
                        style={{ paddingTop: index === 0 ? "24px" : "0" }}
                        onClick={() => removeRequirement(index)}
                      >
                        <TrashIcon />
                      </TrashWrapper>
                    </Stack>
                  </Grid>
                </Grid>
              ))}

              <LinkButton onClick={addRequirement}>
                <AddIcon color={"#999C9F"} />
                <ButtonText>Adicionar novo requisito</ButtonText>
              </LinkButton>
            </>
          )}

          {activeStep === 2 && (
            <>
              <Subtitle>Defina os aprovadores dessa etapa</Subtitle>

              <Divider />

              {step.approvers.map((approver, index) => (
                <Grid container spacing={2} key={approver.id}>
                  <Grid item xs={6}>
                    <FormGroup>
                      {index === 0 && <Label>Aprovadores</Label>}
                      <Input
                        value={approver.name}
                        onChange={event => updateApprover(index, event, "name")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <FormGroup>
                        {index === 0 && <Label>Email do aprovador</Label>}
                        <Input
                          value={approver.email}
                          onChange={event =>
                            updateApprover(index, event, "email")
                          }
                        />
                      </FormGroup>
                      <TrashWrapper
                        style={{ paddingTop: index === 0 ? "24px" : "0" }}
                        onClick={() => removeApprover(index)}
                      >
                        <TrashIcon />
                      </TrashWrapper>
                    </Stack>
                  </Grid>
                </Grid>
              ))}

              <LinkButton onClick={addApprover}>
                <AddIcon color={"#999C9F"} />
                <ButtonText>Adicionar novo aprovador</ButtonText>
              </LinkButton>
            </>
          )}
        </Container>
      </DialogContent>
      <DialogActions>
        <ContainerButton>
          <CancelButton onClick={previousStep}>
            {activeStep === 1 ? "Cancelar" : "Voltar"}
          </CancelButton>
          <SaveButton onClick={nextStep}>
            {activeStep === 1 ? "Próximo" : "Concluir"}
          </SaveButton>
        </ContainerButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddStep;
