import { useEffect } from "react";
import {
  Text,
  DropDownContainer,
  DropDownList,
  DropDownListContainer,
  ListItem,
  Flex,
  Container,
  WrapperButtons
} from "./styles";
import { animate, motion, useAnimation, useMotionValue } from "framer-motion";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import ButtonDowloadDocument from "../../../../../../shared/components/ButtonDowloadDocument";

// set up interfaces

interface DropProps {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
}

interface OptionCallbackProps {
  option: "download" | "createNewProject";
}

//setup animations to framer-motion
const list = {
  visible: {
    opacity: 1,
    y: 10,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3
    }
  },
  hidden: {
    opacity: 0,
    y: 0,
    transition: {
      when: "afterChildren"
    }
  }
};

const variants = {
  visible: i => ({
    opacity: 1,
    transition: {
      delay: i * 0.3
    }
  }),
  hidden: { opacity: 0 }
};

const DropdownArchive = ({ isOpen, setIsOpen }: DropProps) => {
  const controls = useAnimation();
  const x = useMotionValue(0);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  //controlling animation stiffness where define type spring
  useEffect(() => {
    const controls = animate(x, 100, {
      type: "spring",
      stiffness: 2000
    });

    return controls.stop;
  },[]);
  //open dropdown
  const handleClick = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpen(false);
      setIsComponentVisible(true);
    } else {
      return;
    }
  }, [isComponentVisible]);

  return (
    <>
      <Container>
        <DropDownContainer ref={ref}>
          <Flex>
            <Text onClick={handleClick}>Arquivo</Text>
          </Flex>
          {isOpen && isComponentVisible && (
            <DropDownListContainer onClick={handleClick}>
              <DropDownList
                variants={list}
                initial="hidden"
                animate="visible"
                as={motion.ul}
              >
                <ListItem as={motion.li} animate={controls} variants={variants}>
                  Tamanho: A4 (21cm x 29,7cm)
                </ListItem>

                <WrapperButtons>
                  <ButtonDowloadDocument />

                </WrapperButtons>
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
      </Container>
    </>
  );
};

export default DropdownArchive;
