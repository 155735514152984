import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTeams } from "../../../../../../hooks/teams";
import api from "../../../../../../services/api";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import PlainText from "../../../../../../shared/components/PlainText";
import TitleH5 from "../../../../../../shared/components/TitleH5";
import { ParagraphError } from "../styled";
import { BoxInput, Modal, BoxButton, Header } from "./styled";
import { useNavigate, useParams } from "react-router-dom";

const ModalDeleteTeams = ({ setSelectModal }) => {
  const [textConfirm, setTextConfirm] = useState("");
  const {
    nameOfTeam,
    createdTeamId,
    setListTeams,
    setIsSettings,
    setForceUpdate,
    forceUpdate,
    setClauseId,
    setCreatedTeamId
  } = useTeams();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const { team_id } = useParams();

  const handleDeleteTeam = useCallback(async () => {
    if (textConfirm.toLowerCase() === "confirmar") {
      try {
        const responseDelete = await api.delete(`team/${createdTeamId}`);

        if (responseDelete.data.response !== "Error deleting team") {
          setListTeams(prevState => {
            let cloneState = [...prevState];
            const newArr = cloneState.filter(
              team => team.team_id !== createdTeamId
            );

            cloneState = newArr;
            return cloneState;
          });

          setSelectModal({ modal: "", isOpen: false });
          setTextConfirm("");
        }
        setLoading(false);
        setIsSettings(false);
        setForceUpdate(!forceUpdate);
        toast.success("Equipe removida com sucesso!");

        //If the action of deleting the team occurs while the user is on the team page
        //they will be redirected to the list of teams.
        if (team_id) {
          navigate("/teams");
        }
      } catch (err) {
        console.error(err, "ERROR when delete team");
      }
    } else {
      setIsError(true);
      return;
    }
    setCreatedTeamId("");
  }, [
    textConfirm,
    setCreatedTeamId,
    createdTeamId,
    setIsSettings,
    setForceUpdate,
    forceUpdate,
    team_id,
    setListTeams,
    setSelectModal,
    navigate
  ]);

  useEffect(() => {
    return () => {
      setTextConfirm("");
    };
  }, []);

  return (
    <div>
      <Modal>
        <Header>
          <TitleH5 title="Excluir equipe" />
          <PlainText>
            Tem certeza que você quer excluir a equipe <b>{nameOfTeam}</b> ?
          </PlainText>
        </Header>
        <BoxInput check={isError}>
          <PlainText>
            Para você completar essa ação, escreva a palavra <b>“confirmar” </b>
            na caixa de texto abaixo
          </PlainText>

          <div>
            <input
              value={textConfirm}
              onChange={e => setTextConfirm(e.target.value)}
              type="text"
            />
            {isError && (
              <ParagraphError>
                Você precisa digitar corretamente a palavra: confirmar
              </ParagraphError>
            )}
          </div>
        </BoxInput>
        <BoxButton>
          <ButtonMaxWidth
            onClick={() => {
              setSelectModal({ modal: "", isOpen: false });
              setIsSettings(false);
            }}
            text="Não"
            typeStyle="purple"
          />
          <ButtonMaxWidth
            onClick={handleDeleteTeam}
            text="Sim"
            typeStyle="white"
            loading={loading}
          />
        </BoxButton>
      </Modal>
    </div>
  );
};

export default ModalDeleteTeams;
