import { ReactNode } from "react";
import { ListItemText } from "./styles";

interface ListItemProps {
  children: ReactNode;
  visibilityIconsOptionsClauses?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  as?: any;
  variants?: any;
  animate?: any;
  initial?: any;
  onDragStart?: (e: any) => void;
}

const ListItemBank: React.FC<ListItemProps> = ({
  children,
  onMouseEnter,
  onMouseLeave,
  visibilityIconsOptionsClauses,
  as,
  variants,
  animate,
  initial,
  onDragStart,
  ...rest
}) => {
  return (
    <ListItemText
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      variants={variants}
      initial={initial}
      animate={animate}
      as={as}
      draggable
      {...rest}
      onDragStart={onDragStart}
    >
      {children}
    </ListItemText>
  );
};

export default ListItemBank;
