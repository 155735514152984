import React from "react";
import { useTheme } from "../../../../../../hooks/theme";

interface BaseIconProps {
  isActive?: boolean;
  className?: string;
}

export default function UpLowCaseIcon({ isActive, className }: BaseIconProps) {
  const { theme } = useTheme();

  return (
    <svg
      width="32"
      height="32"
      className={className}
      viewBox="0 0 37 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.6862 23.0154C33.5007 22.4836 33.3768 21.9323 33.3169 21.3723C32.08 22.6646 30.5846 23.3107 28.8677 23.3107C27.3354 23.3107 26.0615 22.8677 25.0831 22C24.1046 21.1692 23.6062 20.043 23.6062 18.6954C23.6062 17.0707 24.2154 15.8154 25.4523 14.9107C26.6892 14.0061 28.4246 13.5631 30.6769 13.5631H33.2616V12.3815C33.2616 11.4769 32.9846 10.7569 32.4308 10.2215C31.8769 9.68613 31.0462 9.42767 29.9754 9.42767C29.0154 9.42767 28.2215 9.6492 27.5754 10.0923C26.9292 10.5538 26.6154 11.0892 26.6154 11.7354H23.92C23.92 10.9415 24.1969 10.1846 24.7508 9.44613C25.2677 8.70766 26.0616 8.13536 27.0031 7.71074C27.9446 7.32305 28.96 7.06459 30.1231 7.06459C31.9323 7.06459 33.3354 7.50767 34.3508 8.41228C35.3662 9.3169 35.9016 10.5538 35.9385 12.1415V19.323C35.9385 20.8 36.1231 21.9446 36.4923 22.7938V23.0154H33.6862ZM29.2554 20.9477C30.0862 20.9477 30.88 20.7446 31.6369 20.3569C32.3754 19.9692 32.9292 19.4523 33.2616 18.8246V15.9261H31.1754C27.9077 15.9261 26.2646 16.7938 26.2646 18.5292C26.2646 19.323 26.5415 19.8769 27.1139 20.3015C27.6677 20.7261 28.3692 20.9477 29.2554 20.9477ZM6.73232 15.0954H14.2462L10.4985 5.0892L6.73232 15.0954ZM8.91078 0.861511H12.0862L20.7815 23.0154H17.2185L15.4277 18.2707H5.55078L3.77847 23.0154H0.215393L8.91078 0.861511Z"
        fill={isActive ? theme.colors.primary : "#000"}
      />
    </svg>
  );
}
