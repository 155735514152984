import {
  Container,
  Modal,
  Header,
} from "./styles";
import { useSign } from "../../../../../hooks/sign";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import api from "../../../../../services/api";
import { ResponseSign } from "../../../../../dtos/signedProps";
import { AxiosResponse } from "axios";
import TitleH5 from "../../../../../shared/components/TitleH5";
import ButtonExit from "../../../../../shared/components/ButtonExit";
import FormSign from "../../../../../shared/components/FormSign";

interface IFormAddMember {
  first_name: string,
  last_name: string,
  email: string,
  type: string,
  others?: string,
}

const ModalRegisterSubscription = ({ setOpenModal }) => {
  const {
    signerData,
    setSignerData,
    dateFormatted,
    setDateFormatted,
    setUploadedFile,
    uploadedFile,
    setUrlPdf,
    setGenerateThumb,
    idPdfFile,
    setLoadingDocs,
    setRefreshPage,
    refreshPage,
  } = useSign();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mail, setMail] = useState("");
  const [assignWho, setAssignWho] = useState("assinante");
  const [others, setOthers] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSendDocToSign = useCallback(async () => {
    setLoading(true);
    if (!dateFormatted) {
      setLoading(false);
      return toast.error("Preencha uma data para o documento");
    }
    if (signerData.length === 0) {
      setLoading(false);
      return toast.error("Adicione um assinante para enviar");
    }

    // current date
    const currentDate = new Date();

    //format date
    const inputDateTimestamp = Date.parse(dateFormatted);

    // verify if paste or present date is invalid
    const isValidDate =
      !isNaN(inputDateTimestamp) && inputDateTimestamp > currentDate.getTime();

    // if not date for future then return error
    if (!isValidDate) {
      setLoading(false);
      toast.error("Coloque uma data para o futuro.");
      return;
    }

    let usersToSignDoc = [];
    signerData.forEach((user) => {
      const userToAdd: IFormAddMember = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        type: user.type,
      };
      
      if (user.others) {
        userToAdd.others = user.others;
      }

      usersToSignDoc.push(userToAdd);
    });

    let nameOfDocument: string = uploadedFile?.name?.replace(".pdf", "");

    try {
      let formattedApiData = {
        title: nameOfDocument,
        final_date: dateFormatted,
        users: usersToSignDoc,
      };

      try {
        if (uploadedFile) {
          const pdfBlob = new Blob([uploadedFile], {
            type: "application/pdf",
          });

          const formData = new FormData();
          formData.append("pdf", pdfBlob, "document.pdf");

          try {
            const makeDocSign = await api.post("signatures", formattedApiData);

            idPdfFile.current = makeDocSign.data.id;

            try {
              const sendPDF = await api.patch(
                `signatures/pdf-upload/${makeDocSign.data.id}`,
                formData
              );

              if (sendPDF.data.document_base64) {
                setUrlPdf(sendPDF.data.document_base64);
                setGenerateThumb(true);
              }
            } catch (err) {
              toast.error("Tente novamente mais tarde.");
              setLoading(false);
              console.error(err.response, "Error when send pdf");
            }

            try {
              const responseSignatures: AxiosResponse<ResponseSign> =
                await api.get("signatures", {
                  params: {
                    pagination: 0,
                  },
                });
              setRefreshPage(!refreshPage);
              setLoadingDocs(false);
            } catch (err) {
              console.error(err, "error when get list of all signs");
            }
          } catch (err) {
            setLoading(false);
            toast.error("Tente novamente mais tarde.");
            console.error(err, "ERROR get specific id2");
          }
        }
      } catch (err) {
        setLoading(false);
        toast.error("Tente novamente mais tarde.");
        console.error(err, "ERROR get specific id2");
      }
    } catch (err) {
      setLoading(false);
      console.error("ERROR when save sign doc to api", err.response);
    }
  }, [
    dateFormatted,
    signerData,
    uploadedFile,
    idPdfFile,
    setUrlPdf,
    setGenerateThumb,
    setRefreshPage,
    refreshPage,
    setLoadingDocs,
  ]);

  const handleAddMemberToSign = useCallback(() => {
    let isDuplicateMail = false;

    if (!mail.includes("@")) {
      toast.error("E-mail é inválido.");
      return;
    }

    signerData.forEach((object) => {
      if (!isDuplicateMail) {
        if (object.email === mail) {
          isDuplicateMail = true;
        }
      }
    });

    if (!isDuplicateMail) {
      setSignerData((oldState) => [
        ...oldState,
        {
          firstName: name,
          lastName: lastName,
          email: mail,
          type: assignWho,
          others: assignWho === 'outros' ? others : null,
          id: uuidv4(),
        },
      ]);

      setOthers("");
      setName("");
      setLastName("");
      setMail("");
    } else {
      toast.error("Email ja cadastrado.");
      console.error("ERRO de email ja cadastrado.");
      return;
    }
  }, [setSignerData, name, lastName, mail, signerData]);

  const handleClose = useCallback(() => {
    setOpenModal({
      isOpen: false,
      modalName: "",
    });
    setSignerData([]);
    setUploadedFile(null);
    setDateFormatted("");
  }, [setDateFormatted, setOpenModal, setSignerData, setUploadedFile]);

  const handleBackImport = useCallback(() => {
    setOpenModal({
      isOpen: true,
      modalName: "Import document",
    });
  }, [setOpenModal]);

  const handleDeleteMemberToSign = useCallback(
    (idUser) => {
      setSignerData((oldState) => {
        let cloneState = cloneDeep(oldState);
        const newState = cloneState.filter((user) => user.id !== idUser);
        return newState;
      });
    },
    [setSignerData]
  );

  useEffect(() => {
    return () => {
      setName("");
      setLastName("");
      setMail("");
      setAssignWho("");
      setOthers("");
    };
  }, []);

  return (
    <Container>
      <Modal>
        <Header>
          <TitleH5 title="Cadastro do assinante" />
          <ButtonExit onClick={handleClose} />
        </Header>
        <FormSign
          name={name}
          setName={setName}
          lastName={lastName}
          setLastName={setLastName}
          mail={mail}
          setMail={setMail}
          assignWho={assignWho}
          setAssignWho={setAssignWho}
          others={others}
          setOthers={setOthers}
          signerData={signerData}
          dateFormatted={dateFormatted}
          setDateFormatted={setDateFormatted}
          loading={loading}
          handleAddMemberToSign={handleAddMemberToSign}
          handleDeleteMemberToSign={handleDeleteMemberToSign}
          handleBackImport={handleBackImport}
          handleSendDocToSign={handleSendDocToSign}
        />
      </Modal>
    </Container>
  );
};

export default ModalRegisterSubscription;

