import { ReactNode } from "react";
import { ListItemText } from "./styles";

interface ListItemProps {
  children: ReactNode;
  visibilityIconsOptionsClauses?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  as?: any;
  variants?: any;
  animate?: any;
  initial?: any;
  onDragStart?: (event: MouseEvent) => void;
}

const ListItem: React.FC<ListItemProps> = ({
  children,
  onMouseEnter,
  onMouseLeave,
  visibilityIconsOptionsClauses,
  className,
  variants,
  initial,
  animate,
  as,
  onDragStart,
  ...rest
}) => {
  return (
    <ListItemText
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      draggable={true}
      {...rest}
      variants={variants}
      initial={initial}
      animate={animate}
      as={as}
      onDragStart={onDragStart}
    >
      {children}
    </ListItemText>
  );
};

export default ListItem;
