import textEditIcon from "../assets/editIconText.svg";
import EllipseIcon from "../assets/ellipse.svg";
import LineIcon from "../assets/line.svg";
import RectangleIcon from "../assets/rectangle.svg";
import SquareIcon from "../assets/square.svg";
import StarIcon from "../assets/star.svg";
import TriangleIcon from "../assets/triangle.svg";

export interface KonvaObjectProps {
  type: string;
  src: string;
  alt: string;
}

export const KonvaDropObject: KonvaObjectProps[] = [
  // {
  //   type: "konvashape-icon",
  //   src: textEditIcon,
  //   alt: "konvashape#@@#richTextImage"
  // },
  {
    type: "konvashape-icon",
    src: RectangleIcon,
    alt: "konvashape#@@#rectangle"
  },
  {
    type: "konvashape-icon",
    src: EllipseIcon,
    alt: "konvashape#@@#circle"
  },
  {
    type: "konvashape-icon",
    src: TriangleIcon,
    alt: "konvashape#@@#triangleSVG"
  },
  {
    type: "konvashape-icon",
    src: SquareIcon,
    alt: "konvashape#@@#square"
  },
  {
    type: "konvashape-icon",
    src: StarIcon,
    alt: "konvashape#@@#starSVG"
  },
  {
    type: "konvashape-icon",
    src: LineIcon,
    alt: "konvashape#@@#simpleLine"
  }
];

export const stylesInject = {
  paddingLeft: "1.25rem"
};

export const stylesInjectMedium = {
  paddingLeft: "0.9rem"
};

export const stylesInjectLarge = {
  paddingLeft: "1.55rem"
};
