import Konva from "konva";
import { cloneDeep } from "lodash";
import React, { useRef, useEffect, useState } from "react";
import { Transformer, Text } from "react-konva";
import { TextEditor } from "../TextEditor";

interface ICursorProps {
  x?: number;
  y?: number;
}

interface TextProps {
  selectedObject?: any;
  shapeProps?: any;
  setSelectedObject?: any;
  addToHistory?: any;
  onChange?: any;
  currentMultipleSelection?: any;
  onTextResize?: any;
  setObjectScreen: any;
  commandKeyboard?: boolean;
  setCommandBlockKeyboard?: React.Dispatch<React.SetStateAction<boolean>>;
  transformerSelectedObject: React.MutableRefObject<Konva.Transformer | null>;
}

const SimpleText = ({
  selectedObject,
  shapeProps,
  setSelectedObject,
  addToHistory,
  onChange,
  onTextResize,
  currentMultipleSelection,
  setObjectScreen,
  commandKeyboard,
  setCommandBlockKeyboard,
  transformerSelectedObject,
}: TextProps) => {
  let MAX_WIDTH = 596;
  const [isSelected, setIsSelected] = useState(false);
  const shapeRef = useRef<Konva.Shape>();
  const trRef = useRef<Konva.Transformer>();
  const [text, setText] = useState(shapeProps?.text);
  const [editorEnabledTextArea, setEditorEnabledTextArea] =
    useState<boolean>(false);
  const [isCursor, setIsCursor] = useState<ICursorProps>({});

  function getTotalBox(boxes) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    boxes.forEach((box) => {
      minX = Math.min(minX, box.x);
      minY = Math.min(minY, box.y);
      maxX = Math.max(maxX, box.x + box.width);
      maxY = Math.max(maxY, box.y + box.height);
    });
    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
  }

  useEffect(() => {
    if (
      shapeRef?.current?.attrs?.text !== selectedObject?.attrs?.text ||
      shapeRef?.current?.attrs?.id !== selectedObject?.attrs?.id
    ) {
      setEditorEnabledTextArea(false);
      setCommandBlockKeyboard(false);
    }
  }, [shapeRef, selectedObject]);

  useEffect(() => {
    if (shapeRef.current === selectedObject) {
      setIsSelected(true);
      transformerSelectedObject.current = trRef.current;
    } else {
      setIsSelected(false);
      setEditorEnabledTextArea(false);
      setCommandBlockKeyboard(false);
    }
  }, [selectedObject, isSelected, text]);

  useEffect(() => {
    trRef.current?.setNodes([shapeRef.current]);
    trRef.current?.setZIndex(trRef.current.getParent().children.length - 1);
    trRef.current?.getLayer().batchDraw();
  }, [isSelected]);

  return (
    <>
      <Text
        ref={shapeRef}
        {...shapeProps}
        keepRatio={true}
        width={shapeProps.width}
        visible={!editorEnabledTextArea}
        onClick={(event: Konva.KonvaEventObject<MouseEvent>) => {
          const isTargetInsideMultipleSelection =
            currentMultipleSelection.current?.nodes().includes(event.target);

          if (isTargetInsideMultipleSelection) {
            setSelectedObject(null);
          } else {
            setSelectedObject(event.target);
            currentMultipleSelection.current?.setNodes([]);
          }
        }}
        onDblClick={(e) => {
          setCommandBlockKeyboard(true);
          let currentWidth = trRef.current.width();
          let currentHeight = trRef.current.height();
          selectedObject?.setAttrs({
            width: currentWidth,
            scaleX: 1,
            scaleY: 1,
          });

          if (shapeRef?.current?.attrs?.text === selectedObject?.attrs?.text) {
            const absPosition = e.target.getAbsolutePosition();
            setText(shapeProps.text);
            setIsCursor(absPosition);
            setEditorEnabledTextArea(!editorEnabledTextArea);
          } else {
            setEditorEnabledTextArea(false);
            setCommandBlockKeyboard(false);
          }
        }}
        onDragStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
          if (
            currentMultipleSelection.current?.nodes().length === 0 ||
            !currentMultipleSelection.current
          ) {
            if (
              currentMultipleSelection.current?.nodes().length === 0 ||
              !currentMultipleSelection.current
            ) {
              addToHistory();
            }
          }

          //if mutipleSelection includes element being dragged
          if (
            currentMultipleSelection.current?.nodes().includes(event.target)
          ) {
            setSelectedObject(null);
          } else {
            setSelectedObject(event.target);
            currentMultipleSelection.current?.setNodes([]);
          }
        }}
        onDragEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setObjectScreen((prevState) => {
            let cloneState = cloneDeep(prevState);

            cloneState.forEach((page) => {
              page.renderObjects.forEach((object) => {
                if (object.id === event.target.id()) {
                  object.x = event.target.absolutePosition().x;
                  object.y = event.target.absolutePosition().y;
                }
              });
            });

            return cloneState;
          });
        }}
        onDragMove={(event: Konva.KonvaEventObject<MouseEvent>) => {
          const boxes = trRef?.current
            ?.nodes()
            .map((node) => node?.getClientRect());
          const box = getTotalBox(boxes);

          trRef?.current?.nodes()?.forEach((shape) => {
            const absPos = shape?.getAbsolutePosition();
            const offsetX = box.x - absPos.x;
            const offsetY = box.y - absPos.y;

            const newAbsPos = { ...absPos };
            if (box.x < 0) {
              newAbsPos.x = -offsetX;
            }
            if (box.y < 0) {
              newAbsPos.y = -offsetY;
            }
            if (box.x + box.width > event.target.getStage().width()) {
              newAbsPos.x =
                event?.target?.getStage().width() - box.width - offsetX;
            }
            if (box.y + box.height > event?.target?.getStage().height()) {
              newAbsPos.y =
                event?.target?.getStage().height() - box.height - offsetY;
            }
            shape?.setAbsolutePosition(newAbsPos);
          });
        }}
        onChange={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setSelectedObject(event.target);
          //addToHistory();
        }}
        onTransform={(event: Konva.KonvaEventObject<MouseEvent>) => {
          //this code bellow allow fetch transform change event for change width
          // difference between stage and position of text
          //above i get limits of stage size for text not extrapolate canvas
          // ======>
          if (selectedObject) {
            setSelectedObject(event.target);
            let currentWidth = trRef.current.width();
            let currentHeight = trRef.current.height();
            let stageWidth = event.target.getStage().width();
            let absPositionX = event.target.getAbsolutePosition().x;
            MAX_WIDTH = stageWidth - absPositionX;
            selectedObject?.setAttrs({
              width: currentWidth,
              scaleX: 1,
              scaleY: 1,
            });
          } else {
            //  is possible implements if multiple selection scale change of fontsize x scale
            //when nodes are be text, because now still remains missing this functionality
            // because this of code just return nothing for implements future
            //of new funcionality of text
            return;
          }
          // <=========
        }}
        perfectDrawEnabled={false}
      />
      {editorEnabledTextArea && (
        <TextEditor
          value={shapeProps}
          cursorPosition={isCursor}
          editorEnabled={editorEnabledTextArea}
          textNodeRef={shapeRef}
          setEditorEnabled={setEditorEnabledTextArea}
          onChange={(shapeProps.text = text)}
          text={text}
          addToHistory={addToHistory}
          setText={setText}
          onBlur={(e: any) => {
            shapeProps.text = text;
            setEditorEnabledTextArea(false);
            setCommandBlockKeyboard(false);
          }}
        />
      )}

      {isSelected && (
        <Transformer
          ref={trRef}
          keepRatio={true}
          enabledAnchors={
            !editorEnabledTextArea ? ["middle-left", "middle-right"] : []
          }
          rotateEnabled={false}
          resizeEnabled={true}
          onTransformStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
            addToHistory();
          }}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width > MAX_WIDTH) {
              return oldBox;
            }
            // newBox.width = Math.max(30, newBox.width);
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default SimpleText;
