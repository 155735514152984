import styled from "styled-components";

const StyledSvg = styled.svg`
  width: ${props => props.width || "40px"};
  height: ${props => props.height || "41px"};
  fill: currentColor;
`;

type Props = {
  color?: string;
  width?: string;
  height?: string;
  viewBox?: string;
};

const EditIcon = ({ color, width, height, viewBox }: Props) => (
  <StyledSvg
    viewBox={viewBox || "0 0 24 24"}
    color={color || "#676B70"}
    width={width}
    height={height}
  >
    <path
      d="M14.2874 8.9777L15.3319 10.0221L5.24298 20.0888H4.22076V19.0666L14.2874 8.9777ZM18.2874 2.31104C18.0096 2.31104 17.7208 2.42215 17.5096 2.63326L15.4763 4.66659L19.643 8.83326L21.6763 6.79992C22.1096 6.36659 22.1096 5.64437 21.6763 5.23326L19.0763 2.63326C18.8541 2.41104 18.5763 2.31104 18.2874 2.31104ZM14.2874 5.85548L1.99854 18.1444V22.311H6.1652L18.4541 10.0221L14.2874 5.85548Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default EditIcon;
