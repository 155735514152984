import styled from "styled-components";

export const HeadingText = styled.h2`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

export const Text = styled.p`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const Container = styled.div`
  background: linear-gradient(180deg, #663399 0%, #000000 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 15px 20px;
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.primary90};
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;

  width: 167px;
  height: 40px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }
`;

export const HStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
