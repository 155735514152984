import styled from "styled-components";

export const Header = styled.header`
  grid-area: header;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black10};
`;

export const CentralBoxHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  padding: 5px 20px;

  background-color: ${({ theme }) => theme.colors.white100};

  .avatar-dashboard {
    width: 30px;
    height: 30px;
  }
`;
export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 2rem;
`;
