import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  position: relative;
  z-index: 99;
`;

export const WrapperChange = styled.div`
  display: flex;
  align-items: center;

  height: 30px;

  position: relative;
  z-index: 99;

  border-left: 1px solid #855cad;
  border-right: 1px solid #855cad;
  padding-inline: 7.5px;
`;

export const Button = styled.button`
  display: flex;
  width: 60px;
  height: 40px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: relative;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 1366px) {
    height: 35px;
    width: 46px;
  }

  .save {
    width: 18px;
    height: 18px;
  }

  & .download {
    width: 25px;
    height: 25px;
  }
`;
