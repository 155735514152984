import styled from "styled-components";

interface CardImageProps {
  srcImg?: string;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0rem auto;

  &:first-child {
    margin: 2rem auto;
  }
`;

export const CardImage = styled.div<CardImageProps>`
  width: 356px;
  height: 100%;
  height: 300px;
  border-radius: 10px;
  background: white;
  position: relative;
  background-image: url(${({ srcImg }) => srcImg && srcImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-box-shadow: 1px 1px 15px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px 15px 5px rgba(0, 0, 0, 0.05);

  @media (max-width: 1602px) {
    max-width: 266px;
    height: 100%;
    height: 230px;
    border-radius: 10px;
    width: 187px;
    margin-right: 0rem;
  }

  @media (max-width: 1366px) {
    height: 210px;
    max-width: 216px;
    min-width: 140px;
  }
`;

export const HStack = styled.div`
  display: flex;
  width: 10%;
  height: 100%;
  align-items: center;
`;

export const CardInfos = styled.div`
  width: 100%;
  height: 129px;
  display: flex;
  border-radius: 8px;
  padding: 2rem 1rem;
  position: relative;
  background: #f5f6fa;
  justify-content: space-between;
  margin-left: auto;
  margin: auto auto auto 2rem;

  & .icon-reverse {
    position: absolute;
    right: 7%;
    top: 10%;
    color: ${({ theme }) => theme.colors.background};
    font-size: 32px;
  }

  @media (max-width: 1602px) {
    max-width: 270px;
    padding: 2rem 1rem;
    margin: auto auto auto 1.75rem;
  }

  @media (max-width: 1462px) {
    max-width: 270px;
    padding: 2rem 1rem;
    margin: auto auto auto 1.75rem;
  }

  @media (max-width: 1366px) {
  }
`;

export const Heading = styled.h2`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 0.3rem;
  color: ${({ theme }) => theme.colors.background};
`;

export const Text = styled.span`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.56rem;
  max-width: 232px;
  color: ${({ theme }) => theme.colors.background};
  margin-bottom: 0.6rem;
`;

export const TextBlack = styled.span`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.37rem;
  max-width: 252px;
  width: 250px;
  margin-top: 0.6rem;
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SimpleItalicText = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  font-style: italic;
  max-width: 232px;
  color: ${({ theme }) => theme.colors.black};
`;

export const VStack = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const HStackStar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & img {
    margin-left: 0.5rem;
    margin-top: -0.5rem;
  }
`;
