import React from "react";
import ReactLoading from "react-loading";
import { useTheme } from "../../../../../hooks/theme";

const LoadingTransition: React.FC = () => {
  const { theme } = useTheme();

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "100px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ReactLoading
        type="spin"
        color={theme.colors.primary}
        height={24}
        width={24}
      />
    </div>
  );
};

export default LoadingTransition;

