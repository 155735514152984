import { Box } from "@material-ui/core";
import React from "react";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";

export const Loading = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"500px"}
      width={"100%"}
    >
      <ContractAnimation />
    </Box>
  );
};
