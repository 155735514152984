import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .close-button-box {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    /* background-color: red; */
  }
`;

export const Modal = styled.div`
  width: 800px;
  height: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);

  padding: 10px 20px 20px 20px;

  @media (min-width: 1920px) {
    width: 974px;
  }

  @media (max-width: 1920px) {
    height: 660px;
  }
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: 528px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const BoxButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
