import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: 60% 32%;
  grid-template-rows: 1fr 1fr;
  justify-content: space-between;
  align-items: center;

  position: relative;
`;

export const InputWrap = styled.div``;

export const CategoryWrap = styled.div`
  button {
    width: 122px;
    height: 30px;

    font-size: 14px;
    font-weight: 500;
    line-height: 100%;

    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    border-radius: 5px;
  }
`;

export const InputBox = styled.div`
  width: 200px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #999c9f;
  padding-inline: 10px 3px;
  background-color: #fff;

  display: flex;
  align-items: center;
  gap: 8px;

  button {
    width: 30px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .search-icon {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 1920px) {
    width: 300px;
  }
`;

export const IconLeft = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1366px) {
    img {
      width: 16px;
    }
  }
`;

export const Input = styled.input`
  width: 80%;
  height: 100%;
  background: transparent;
  border: none;
`;

export const IconRight = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxCategories = styled.div`
  width: 360px;
  max-height: 460px;
  display: flex;
  position: absolute;
  flex-direction: column;
  background-color: #ffffff;
  top: 60px;
  right: 5px;
  -webkit-box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.49);
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.49);
  z-index: 999;
  padding: 0 1rem;

  @media (max-width: 1366px) {
    width: 270px;
    padding-top: 1rem;
    max-height: 460px;
  }
`;

export const BodyCategories = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a5a5a5;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  .empty-categories {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 1.4rem;
      margin: 0 auto;
    }
  }

  @media (max-width: 1366px) {
    padding: 0;
  }
`;

export const ListItem = styled.div`
  font-size: 1.5rem;
  color: black;
  margin: 0.5rem 1rem;
  cursor: pointer;
  transition: all 120ms linear;

  &:hover {
    color: ${({ theme }) => theme.colors.background};
    font-weight: bold;
  }
`;

export const ContentFilter = styled.div`
  height: 30px;
  /* position: absolute; */
  width: 80%;
  top: 0px;
  left: 40px;
  display: flex;
  margin-block: 5px;

  .chip {
    display: inline-flex;
    flex-direction: row;
    background-color: transparent;
    border: 1px solid #343a40;
    cursor: default;
    height: 30px;
    outline: none;
    padding: 0;
    font-size: 1.4rem;

    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    border-radius: 15px;
    vertical-align: middle;
    text-decoration: none;
  }
  .chip-head {
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: #32c5d2;
    font-size: 1.25rem;
    flex-shrink: 0;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    width: 36px;
    color: #fff;
    height: 36px;
    font-size: 20px;
    margin-right: -4px;

    @media (max-width: 1366px) {
      width: 26px;
      color: #fff;
      height: 26px;
      font-size: 10px;
      margin-right: -4px;
    }
  }
  .chip-content {
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
  }
  .chip-svg {
    color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;

    @media (max-width: 1366px) {
      font-size: 21px;
    }
  }
  .chip-svg:hover {
    color: #666666;
  }

  .chip2 {
    display: inline-flex;
    flex-direction: row;
    background-color: transparent;
    border: 1px solid #343a40;
    cursor: default;
    height: 32px;
    outline: none;
    padding: 0;
    font-size: 14px;
    font-color: #333333;
    font-family: "Open Sans", sans-serif;
    white-space: nowrap;
    align-items: center;
    border-radius: 15px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
    margin-top: 2rem;

    @media (max-width: 1366px) {
      margin-top: 1.2rem;
      font-size: 1.2rem;
      height: 30px;
      padding-bottom: 0.1rem;
    }
  }
  .chip-head2 {
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: #32c5d2;
    font-size: 1.25rem;
    flex-shrink: 0;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    width: 36px;
    color: #fff;
    height: 36px;
    font-size: 20px;
    margin-right: -4px;
  }
  .chip-content2 {
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
  }
  .chip-svg2 {
    color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
  }
  .chip-svg2:hover {
    color: #666666;
  }
`;
