import styled from "styled-components";

export const Modal = styled.div`
  width: 700px;

  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const BoxButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
