/*
In this file, you can view header of editor
the most important component is Header
*/

import React from "react";
import Header from "../Header";
import { FlexContainer, GridItemHeader, HeaderBox } from "./styles";
import { useWorkspaceEditor } from "../../hooks/workspaceEditor";

const HeaderMain: React.FC = () => {
  const { headerEditorRef } = useWorkspaceEditor();

  return (
    <GridItemHeader ref={headerEditorRef}>
      <HeaderBox>
        <FlexContainer>
          <Header />
        </FlexContainer>
      </HeaderBox>
    </GridItemHeader>
  );
};

export default HeaderMain;
