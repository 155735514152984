import React, { useCallback, useEffect, useState } from "react";

import { useAuth } from "../../../hooks/auth";

import { useModalContext } from "../../../hooks/modalContext";
import api from "../../../services/api";

import { Container, Modal, Option } from "./styles";
import ButtonMaxWidth from "../ButtonMaxWidth";
import AlertError from "../AlertError";

interface EndTrialModalProps {
  isActive: boolean;
  ref?: React.MutableRefObject<HTMLDivElement>;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  source: "end_trial" | "unsubscription";
}

const EndTrialModal: React.FC<EndTrialModalProps> = ({
  isActive,
  setIsActive,
  source
}) => {
  const { typeUser } = useAuth();
  const { endTrialWrapperRef } = useModalContext();
  const { signOut } = useAuth();
  const [loading, setLoading] = useState(false);
  const [invalidValue, setInvalidValue] = useState(false);
  const [comment, setComment] = useState("");
  const [reason, setReason] = useState("");
  const [checked, setChecked] = React.useState({
    dontHaveTime: false,
    dontWorkForMe: false,
    soExpensive: false,
    dontLike: false
  });

  const handleSendMessage = useCallback(async () => {
    if (
      !(
        checked.dontHaveTime ||
        checked.dontLike ||
        checked.dontWorkForMe ||
        checked.soExpensive
      )
    ) {
      setInvalidValue(true);
      return;
    }

    try {
      setLoading(true);
      await api.post("reason-for-cancellation", {
        reason: reason,
        comment: comment,
        type: source
      });

      setLoading(false);
      setInvalidValue(false);
      signOut();
    } catch (error) {
      setLoading(false);
      setInvalidValue(false);
      signOut();
      console.error(
        error,
        "ERROR when post reason and comment to cancellation endpoint"
      );
    }
  }, [reason, comment, typeUser, invalidValue]);

  const handleOnChangeComment = useCallback(
    event => {
      setComment(event.target.value);
    },
    [comment]
  );

  const changeObject = useCallback(
    (value: "dontHaveTime" | "dontWorkForMe" | "soExpensive" | "dontLike") => {
      switch (value) {
        case "dontHaveTime":
          setReason("Não consegui usar pelo tempo de teste");
          break;

        case "dontWorkForMe":
          setReason("Não serve pra mim");
          break;
        case "soExpensive":
          setReason("Achei caro");
          break;
        case "dontLike":
          setReason("Não gostei");
          break;

        default:
          break;
      }

      setChecked(oldState => {
        return {
          ...oldState,
          [value]: true
        };
      });
    },
    []
  );

  const handleChange = useCallback(
    (event, value) => {
      setChecked(oldState => {
        return {
          ...oldState,
          dontHaveTime: false,
          dontWorkForMe: false,
          soExpensive: false,
          dontLike: false
        };
      });

      changeObject(value);
    },
    [checked]
  );

  const MouseDown = (event: any) => {
    if (endTrialWrapperRef?.current?.contains(event?.target)) {
      return;
    } else {
      if (isActive) {
        setIsActive(false);
      }
    }
  };

  document.addEventListener("mousedown", (event: any) => {
    if (endTrialWrapperRef?.current?.contains(event?.target)) {
      return;
    } else {
      if (isActive) {
        setIsActive(false);
      }
    }
  });

  useEffect(() => {
    return () => {
      setComment("");
      setInvalidValue(false);
      setChecked(oldState => {
        return {
          ...oldState,
          dontHaveTime: false,
          dontWorkForMe: false,
          soExpensive: false,
          dontLike: false
        };
      });
    };
  }, [isActive]);

  if (!isActive) {
    return <></>;
  }

  return (
    <Container>
      <Modal ref={endTrialWrapperRef}>
        <div>
          <p>1. Por que você não quer continuar?</p>
          <Option>
            <input
              checked={checked.dontHaveTime}
              onChange={event => handleChange(event, "dontHaveTime")}
              type="radio"
            />
            a) Não consegui usar pelo tempo de teste
          </Option>
          <Option>
            <input
              checked={checked.dontWorkForMe}
              onChange={event => handleChange(event, "dontWorkForMe")}
              type="radio"
            />
            b) Não serve pra mim
          </Option>
          <Option>
            <input
              checked={checked.soExpensive}
              onChange={event => handleChange(event, "soExpensive")}
              type="radio"
            />
            c) Achei caro
          </Option>
          <Option>
            <input
              checked={checked.dontLike}
              onChange={event => handleChange(event, "dontLike")}
              type="radio"
            />
            d) Não gostei
          </Option>
        </div>
        <div>
          <p>2. Quer deixar algum comentário? (opcional)</p>
          <textarea value={comment} onChange={handleOnChangeComment} />
        </div>

        <div>
          <ButtonMaxWidth
            text="Enviar"
            typeStyle="purple"
            onClick={handleSendMessage}
            loading={loading}
          />
          {invalidValue && (
            <AlertError dataError={invalidValue && "Escolha uma opção."} />
          )}
        </div>
      </Modal>
    </Container>
  );
};

export default EndTrialModal;
