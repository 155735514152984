/*
this page render my projects into dashboard
and user can choose one template of your projects
for load on editor and continue editing your project
*/

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Header } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../services/api";
import { useMainHook } from "../../../../hooks/main";
import { LoadingFlex } from "../../../user/unknown/login/pages/Login/styles";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import {
  AllMyProjects,
  IHandleChangeDocumentNameProps,
} from "../../../../dtos/AllMyProjects";
import HeaderSection from "../../components/HeaderSection";
import { ToastContainer, toast } from "react-toastify";
import { useMetric } from "../../../../hooks/metric";
import SearchBar from "../../components/SearchBar";
import GridTemplate from "../../components/GridTemplate";
import PreviewTemplateModal from "../../components/PreviewTemplateModal";
import Pagination from "../../components/Pagination";
import Downloadfiles from "../../../../shared/components/DownloadFiles";
import { PagesProps } from "../../../../dtos/PagesProps";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { cloneDeep } from "lodash";
import { IHandlePreviewTemplateProps } from "../../../../dtos/SharedInterfaces";
import { queryClient } from "../../../../App";

interface IdataMyProjects {
  allMyProjects: AllMyProjects[];
  totalPage: number;
}

const MyProjects = () => {
  //import hooks necessary
  const {
    currentTemplateInfo,
    documentName,
    setDocumentRemoveId,
    setNameDocumentDelete,
    handleRequestModal,
    isIntentBuyModal,
    setIsIntentBuyModal,
    debouncedSearchTerm,
    loadingDebounce,
    copyDocument,
    setIsLoadingModal,
  } = useMainHook();
  const { handleCreatedProjectAction } = useMetric();

  // const queryClient = useQueryClient();

  //route properties
  const navigator = useNavigate();
  const { number_page } = useParams();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;

  //set up states
  const [templateId, setTemplateId] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const whichFileToBeDownload = useRef<"word" | "pdf">("pdf");
  const documentPreviewData = useRef<PagesProps[] | null>(null);
  const idToastifyDownloadRef = useRef<"">(null);

  const {
    data: dataMyprojects,
    isLoading,
    refetch,
  } = useQuery<IdataMyProjects>(
    ["myProjects", debouncedSearchTerm, pageNumber, copyDocument],
    async () => {
      const fetchAllMyTemplates = await api.get("user-templates", {
        params: {
          search: debouncedSearchTerm ? debouncedSearchTerm : null,
          pagination: pageNumber,
        },
      });
      return {
        allMyProjects: fetchAllMyTemplates.data.dataArray as AllMyProjects[],
        totalPage: fetchAllMyTemplates.data.pagination,
      };
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
      onSuccess: (data) => {
        //Checks if there are template on that page
        // If not, it sends me back to the previous page
        if (pageNumber > 0 && data.allMyProjects.length === 0) {
          navigator(`/projects/${pageNumber}`);
        }
      },
    }
  );

  //when user clicked on project then navigate it
  const handleChooseTemplate = useMutation({
    mutationFn: async (id: string) => {
      setIsLoadingModal(true);
      const response = await api.get(`user-templates/${id}`);

      return response.data;
    },
    onSuccess: (data) => {
      currentTemplateInfo.current = data;
      documentName.current = data.title;

      setIsLoadingModal(false);
      navigator(`/editor/my-template/${data.id}`);
    },
    onError: () => {
      toast.error("Erro ao abrir projeto");
      setIsLoadingModal(false);
    },
  });
  //to user get one copy of these document
  //then with id in hands we could be duplicated
  //considering doing the duplicate template creation process
  const handleMakeCopyDocument = useMutation({
    mutationFn: async (id: string) => {
      const creteCopyTemplate = await api.post(`user-templates/clone`, {
        user_template_id: id,
      });

      return creteCopyTemplate.data as AllMyProjects;
    },
    onSuccess: ({
      id,
      user_id,
      title,
      description,
      thumbnail,
      is_automation,
      automation_user_template_id,
      created_at,
      updated_at,
      thumbnail_url,
    }) => {
      //If reaching the maximum number of templates per page, a new search will be performed.
      //Because if you add it without the refetch, pagination will not appear.
      if (dataMyprojects?.allMyProjects?.length === 8 || pageNumber !== 0) {
        refetch();
      } else {
        const newArray = cloneDeep(dataMyprojects?.allMyProjects);
        newArray.unshift({
          id,
          user_id,
          title,
          description,
          thumbnail,
          is_automation,
          automation_user_template_id,
          created_at,
          updated_at,
          thumbnail_url,
        });

        queryClient.setQueryData(
          ["myProjects", debouncedSearchTerm, pageNumber, copyDocument],
          { allMyProjects: newArray, totalPage: dataMyprojects?.totalPage }
        );
      }

      //created project action register function
      handleCreatedProjectAction({
        project_id: id,
        creation_origin: "User Template",
        template_id: false,
        template_bits_name: false,
        researched: false,
      });

      toast.success("O documento foi criado.");
    },
    onError: (err) => {
      console.error("Error when making a copy of the document.", err);
      toast.error("Erro ao fazer a cópia do documento");
    },
  });

  //if the user wants to rename the document name
  //do one request to put id document
  const handleChangeDocumentName = useMutation({
    mutationFn: async ({
      documentId,
      newDocumentName,
    }: IHandleChangeDocumentNameProps) => {
      const template = await api.get(`user-templates/template/${documentId}`);

      const response = await api.put("user-templates", {
        user_template_id: documentId,
        template: template.data.response,
        title: newDocumentName,
        is_automatic_numbering_active: false,
        automatic_numbering_position: "right",
        first_page_automatic_numbering: false,
      });

      return response.data as AllMyProjects;
    },
    onSuccess: (data) => {
      toast.success("O nome do documento foi alterado com sucesso");

      const newDocument = dataMyprojects?.allMyProjects?.map((project) =>
        project.id === data.id ? { ...project, title: data.title } : project
      );

      queryClient.setQueryData(
        ["myProjects", debouncedSearchTerm, pageNumber, copyDocument],
        { allMyProjects: newDocument, totalPage: dataMyprojects?.totalPage }
      );
    },
    onError: () => {
      toast.error("Erro ao alterar o nome do documento");
    },
  });

  //Responsible for fetching the information of the selected template.
  const handlePreviewTemplate = useMutation({
    mutationFn: async ({ templateId, title }: IHandlePreviewTemplateProps) => {
      setModalIsOpen(true);
      const response = await api.get(`user-templates/template/${templateId}`);

      return {
        pages: response.data.response.arrayOfPages as [],
        title: title,
      };
    },
    onError: (err) => {
      setModalIsOpen(false);
      toast.error("Erro ao pré-visualizar templates.");
      console.error(err);
    },
  });

  //when user clicked on delete document
  //then ask to user if he is sure he wants deleted these document?
  //if yes, call endpoint to delete or not just return
  const handleDeleteDocument = async (document_id: string, name: string) => {
    setDocumentRemoveId(document_id);
    setNameDocumentDelete(name);
    handleRequestModal();
  };

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const loading =
    isLoading || loadingDebounce || handleMakeCopyDocument.isLoading;

  return (
    <>
      <Downloadfiles
        toastId={idToastifyDownloadRef}
        fileExport={whichFileToBeDownload.current}
        downloading={downloading}
        setDownloading={setDownloading}
        documentName={idToastifyDownloadRef.current}
        pagesToBeRendered={documentPreviewData.current}
      />
      <ToastContainer />
      <Container>
        <Header>
          <HeaderSection
            description="Este é o local onde ficam salvos os 
          projetos que você criou com o UX DOC."
            title="Meus projetos"
          />

          <SearchBar
            screen="projects"
            pageNumber={pageNumber}
            description="Digite aqui para encontrar o template que procura."
          />
        </Header>

        {loading ? (
          <LoadingFlex>
            <ContractAnimation />
          </LoadingFlex>
        ) : (
          <GridTemplate
            templates={dataMyprojects?.allMyProjects}
            handleChooseTemplate={handlePreviewTemplate.mutate}
            setTemplateId={setTemplateId}
            templateOptions={true}
            handleMakeCopy={handleMakeCopyDocument.mutate}
            handleDeleteDocument={handleDeleteDocument}
            handleChangeDocumentName={handleChangeDocumentName.mutate}
            page="My projects"
            downloading={downloading}
            setDownloading={setDownloading}
            idToastifyDownloadRef={idToastifyDownloadRef}
            documentPreviewData={documentPreviewData}
            whichFileToBeDownload={whichFileToBeDownload}
            handleUseTemplate={handleChooseTemplate.mutate}
            isIntentBuyModal={isIntentBuyModal}
            setIsIntentBuyModal={setIsIntentBuyModal}
          />
        )}

        <Pagination
          total={dataMyprojects?.totalPage}
          pageNow={pageNumber}
          screen="projects"
        />

        <PreviewTemplateModal
          open={modalIsOpen}
          handleCloseModal={handleCloseModal}
          pagesToBeRendered={handlePreviewTemplate.data?.pages}
          title={handlePreviewTemplate.data?.title}
          loadingPreview={handlePreviewTemplate.isLoading}
          chooseTemplate={handleChooseTemplate.mutate}
          templateId={templateId}
          texButton="Usar Template"
        />
      </Container>
    </>
  );
};

export default MyProjects;

