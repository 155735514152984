import IconSvg from "../IconSvg";
import { Button, Container, Heading } from "./styles";
import iconAddPage from "../../assets/icon-cross.svg";

interface ButtonAddPage extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  marginBottom?: string;
}

const ButtonAddPageCanva: React.FC<ButtonAddPage> = ({
  marginBottom,
  text,
  ...rest
}) => {
  return (
    <Container>
      <Button {...rest}>
        <IconSvg src={iconAddPage} description="Adicionar página" />
        <Heading> {text} </Heading>
      </Button>
    </Container>
  );
};

export default ButtonAddPageCanva;
