import { useCallback } from "react";
import ptBR from "date-fns/locale/pt-BR";
import { format, utcToZonedTime } from "date-fns-tz";

import { Container, HeaderTable, NoElements } from "./styles";

import SignItem from "./components/SignItem";
import { IModalSign, SignDataProps } from "../../../../../dtos/signedProps";

interface ISignatureTableProps {
  setOpenModal: React.Dispatch<React.SetStateAction<IModalSign>>;
  data: SignDataProps[];
}

const SignatureTable = ({ setOpenModal, data }: ISignatureTableProps) => {
  const formatDate = useCallback((date: string) => {
    const dateFormated = format(Date.parse(date), " dd/MM/yyyy'", {
      locale: ptBR,
    });

    return dateFormated;
  }, []);

  const formatDateFinal = useCallback((date: string) => {
    let myDataFinalFormated: string;
    let myObject = date;
    const nyTimeZone = "UTC";
    const myDate = utcToZonedTime(myObject, nyTimeZone);
    const niceDate = format(myDate, "dd/MM/yyyy", {
      timeZone: "UTC",
    });

    myDataFinalFormated = niceDate;
    return myDataFinalFormated;
  }, []);

  return (
    <Container>
      <HeaderTable>
        <h4>Nome do arquivo</h4>
        <h4>Status</h4>
        <h4>Assinantes</h4>
        <h4>Data do envio</h4>
        <h4>Ciclo de assinatura</h4>
        <h4>Data da expiração</h4>
      </HeaderTable>
      {data.length === 0 ? (
        <NoElements>Nenhuma assinatura encontrada.</NoElements>
      ) : (
        data.map((sign) => (
          <SignItem
            sign={sign}
            formatDate={formatDate}
            formatDateFinal={formatDateFinal}
            setOpenModal={setOpenModal}
            key={sign.signature.id}
          />
        ))
      )}
    </Container>
  );
};

export default SignatureTable;

