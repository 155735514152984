interface ElementsIconProps {
  isActive?: boolean;
  className?: string;
  colorActive: string;
}

const ElementsIcon = ({
  isActive,
  className,
  colorActive,
  ...rest
}: ElementsIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M8.71484 18.0029C8.88151 18.0196 9.04418 18.0323 9.20284 18.0409C9.36151 18.0496 9.53218 18.0536 9.71484 18.0529C9.89818 18.0529 10.0692 18.0486 10.2278 18.0399C10.3865 18.0312 10.5488 18.0189 10.7148 18.0029V19.3125H20.7148V9.3125H18.6648C18.6648 9.3125 18.6648 8.33594 18.4195 7.3125H20.7148C21.2648 7.3125 21.7358 7.5085 22.1278 7.9005C22.5198 8.2925 22.7155 8.76317 22.7148 9.3125V19.3125C22.7148 19.8625 22.5188 20.3335 22.1268 20.7255C21.7348 21.1175 21.2642 21.3132 20.7148 21.3125H10.7148C10.1648 21.3125 9.69384 21.1165 9.30184 20.7245C8.90984 20.3325 8.71418 19.8618 8.71484 19.3125V18.0029ZM9.71484 16.4688C7.76484 16.4688 6.11051 15.7894 4.75184 14.4308C3.39318 13.0721 2.71418 11.4181 2.71484 9.46875C2.71484 7.51875 3.39418 5.86442 4.75284 4.50575C6.11151 3.14708 7.76551 2.46808 9.71484 2.46875C11.6648 2.46875 13.3192 3.14808 14.6778 4.50675C16.0365 5.86542 16.7155 7.51942 16.7148 9.46875C16.7148 11.4188 16.0355 13.0731 14.6768 14.4318C13.3182 15.7904 11.6642 16.4694 9.71484 16.4688ZM9.71484 14.4688C11.0982 14.4688 12.2775 13.9811 13.2528 13.0058C14.2282 12.0304 14.7155 10.8514 14.7148 9.46875C14.7148 8.08542 14.2272 6.90608 13.2518 5.93075C12.2765 4.95542 11.0975 4.46808 9.71484 4.46875C8.33151 4.46875 7.15218 4.95642 6.17684 5.93175C5.20151 6.90708 4.71418 8.08608 4.71484 9.46875C4.71484 10.8521 5.20251 12.0314 6.17784 13.0068C7.15318 13.9821 8.33218 14.4694 9.71484 14.4688Z"
        fill={isActive ? colorActive : "#C4C4C4"}
      />
    </svg>
  );
};

export default ElementsIcon;
