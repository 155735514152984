/*
here the user will be able to see the variables that he created in his document,
and this component will have the responsibility of containing an algorithm that,
if the user fills in the variables, we can generate a new document renaming the
variables that the user defined. Creating a document automation by keyword key
-value variables
*/

import React, { useCallback, useEffect, useRef, useState } from "react";

import { Form } from "@unform/web";
import { Modal } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { PagesProps } from "../../../../dtos/PagesProps";
import { FormHandles } from "@unform/core";
import { useMainHook } from "../../../../hooks/main";
import { useSelection } from "../../hooks/selection";

import closedButton from "../../assets/close-icon.svg";

import {
  BoxButtonDocumentName,
  Button,
  ButtonAutomation,
  ButtonNo,
  ButtonsBox,
  ContainerButtonsYesNo,
  ContainerLoading,
  ContainerModalDocumentName,
  ContainerSidebarNav,
  ContainerVariables,
  FooterModal,
  GridInput,
  HeaderModal,
  HeaderModalDocumentName,
  LabelDocumentName,
  ListDocumentsContainer,
  ModalContainer,
  ModalContainerQuit,
  NoVariables,
  NoVariablesLabel,
  SubContainerSideBarNav,
  TextNotElement,
} from "./styles";

import * as Yup from "yup";
import ReactQuill from "react-quill";
import html2canvas from "html2canvas";
import Input from "./components/Input";
import api from "../../../../services/api";
import ButtonPrimary from "../ButtonPrimary";
import LoadingEditor from "../LoadingEditor";
import Variables from "./components/Variables";
import AutomatizedItem from "./components/AutomatizedItem";
import getVariablesFromText from "../../utils/getVariablesFromText";
import PreviewDocument from "../../../../shared/components/PreviewDocument";
import DownloadDocument from "../../../../shared/components/DownloadDocument";
import getValidationErrors from "../../../../shared/utils/getValidationsErrors";
import { toast } from "react-toastify";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import { IRenderedObject } from "../../../../dtos/RenderedObject";
import { useMetric } from "../../../../hooks/metric";
import ReactLoading from "react-loading";
import { useHeaderEditor } from "../../hooks/headerEditor";
import { useTheme } from "../../../../hooks/theme";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonExit from "../../../../shared/components/ButtonExit";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import PlainText from "../../../../shared/components/PlainText";
import PreviewTemplateModal from "../../../dashboard/components/PreviewTemplateModal";

interface IAutomatizationAPI {
  automation_user_template_id: string;
  created_at: string;
  description: null | string;
  id: string;
  is_automation: true;
  thumbnail: string;
  thumbnail_url: string;
  title: string;
  updated_at: string;
  user_id: string;
}

interface IShowAutomatizedDocumentAPI {
  automation_user_template_id: string;
  created_at: string;
  description: null | string;
  download_counter: number;
  id: string;
  is_automation: boolean;
  save_counter: number;
  template: { arrayOfPages: PagesProps[] };
  thumbnail: string;
  thumbnail_url: string;
  title: string;
  updated_at: string;
  user_id: string;
}

const AutomatizingTab: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { selectedObject, IsModalVisible, setIsModalVisible } = useSelection();
  const [isModalNameDocumentVisible, setIsModalNameDocumentVisible] =
    useState(false);
  const [isModalDeleteDocumentVisible, setIsModalDeleteDocumentVisible] =
    useState(false);
  const [documentVariables, setDocumentVariables] = useState<string[]>([]);
  const [listDocumentsAutomatized, setListDocumentsAutomatized] = useState<
    IAutomatizationAPI[]
  >([]);
  const [loading, setLoading] = useState<
    | "download"
    | "variables"
    | "automatize"
    | "automatizing"
    | "saving"
    | "deleting"
    | "preview"
    | "pages"
    | false
  >(false);
  const [modalPreviewDocumentVisiblle, setModalPreviewDocumentVisible] =
    useState(false);
  const idCurrentAutomatizedDocument = useRef<string | null>(null);
  const documentAutomatized = useRef(null);
  const quillRefComponent = useRef<ReactQuill>(null);
  const documentPreviewData = useRef<PagesProps[] | null>(null);
  const [downloading, setDownloading] = useState(false);
  const automatizedTabRef = useRef<HTMLDivElement>(null);
  const automatizedDocumentsPageNumber = useRef<number | "FINAL">(0);
  const [isSureQuit, setIsSureQuit] = useState(false);
  const [loadingDeleteDoc, setLoadingDeleteDoc] = useState(false);
  const [preview, setPreview] = useState({ pages: [], title: "" });

  const { setForceBlockKey } = useSelection();

  const { handleSaveTemplate } = useHeaderEditor();
  const { theme } = useTheme();

  const documentName = useRef("");

  const { objectScreen, setObjectScreen } = useMainHook();
  const { templateid } = useParams();
  const { handleDowloadProjectAction, handleCreatedAutomationAction } =
    useMetric();

  const modules = React.useMemo(
    () => ({
      toolbar: false,
    }),
    []
  );

  //fixing issue of copy and paste into canvas instead
  // of inputs native copy and paste
  useEffect(() => {
    setForceBlockKey(IsModalVisible);
  }, [IsModalVisible]);

  const getAutomations = useCallback(async () => {
    try {
      const fetchAutomatizedDocuments = await api.get(
        `user-templates/list-automation-user-template/${templateid}`
      );

      setListDocumentsAutomatized(fetchAutomatizedDocuments.data.dataArray);
    } catch (error) {
      console.error("error getting lists of documents automatized");
    }
  }, [templateid]);

  // this useEffect brings all the page automatizations
  useEffect(() => {
    (async () => {
      await getAutomations();
    })();
  }, []);

  //brings all variable of document
  const getVariablesFromProject = useCallback(() => {
    setDocumentVariables([]);
    let variables: string[] = [];

    objectScreen.forEach((page, index) => {
      page.renderObjects.forEach((renderedObject) => {
        if (renderedObject.text) {
          variables.push(...getVariablesFromText(renderedObject.text));
        }
      });
    });

    // removing repeated variables
    let filteredVariables = variables.filter(function (el, i) {
      return variables.indexOf(el) === i;
    });

    setDocumentVariables(filteredVariables);
  }, [objectScreen]);

  //open modal of variables input
  const handleOpenModal = useCallback(() => {
    setIsModalVisible(true);
    getVariablesFromProject();
  }, [setIsModalVisible, getVariablesFromProject]);

  useEffect(() => {
    getVariablesFromProject();
  }, [objectScreen]);

  const handleConfirmQuit = useCallback(() => {
    setIsSureQuit(true);
  }, [setIsSureQuit]);

  //trigger to close modal variables
  const handleCloseModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      if (e.target === e.currentTarget) {
        setIsModalVisible(false);
        setIsModalNameDocumentVisible(false);
        setIsModalDeleteDocumentVisible(false);
        setModalPreviewDocumentVisible(false);
        setIsSureQuit(false);
      }
    },
    []
  );

  //when user send the texts to do the automation
  const handleAutomatizeDocument = useCallback(
    async (data) => {
      try {
        let yupShape = {};
        Object.keys(data).forEach((key, index) => {
          yupShape[key] = Yup.string();
        });
        const schema = Yup.object().shape({ ...yupShape });
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading("automatizing");
        let cloneObjectScreen = cloneDeep(objectScreen);
        // Replacing all my variables for values in inputs
        Object.keys(data).forEach((variable, index) => {
          cloneObjectScreen.forEach((page, index) => {
            page.renderObjects.forEach(
              (renderedObject: IRenderedObject, index) => {
                //when input to value was empty then stay that variable
                //in the document autimatized and return
                if (data[variable] === "") {
                  renderedObject.text = variable;
                  return;
                }

                //replacing text of object
                if (renderedObject?.text) {
                  renderedObject.text = renderedObject?.text?.replace(
                    variable,
                    data[variable]
                  );
                }
                // replacing variable inside of format
                if (renderedObject?.format?.ops) {
                  renderedObject.format.ops.forEach((formatObj, index) => {
                    formatObj.insert = formatObj.insert.replace(
                      variable,
                      data[variable]
                    );
                  });
                }
              }
            );
          });
        });

        // Rendering images with canvas done
        // Biding everything in a promisse so we have control
        // Make an asynchronous call and either resolve or reject
        for (const page of cloneObjectScreen) {
          for (const renderedObject of page.renderObjects) {
            //having this if we make sure the algorithm will just enter in richText
            if (!!renderedObject?.format && renderedObject.text) {
              quillRefComponent.current
                ?.getEditor()
                ?.setText(renderedObject.text);

              quillRefComponent.current.getEditor().root.style.width = String(
                renderedObject.width + "px"
              );

              //setting format inside of quill
              quillRefComponent.current
                ?.getEditor()
                ?.setContents(renderedObject.format as any);

              // setting padding of quill for zero
              // very important any quill change to be before rendering the image otherwise
              // you can make it become distorted
              quillRefComponent.current.getEditor()!.root!.style!.padding =
                "0px";

              try {
                const canvas = await html2canvas(
                  quillRefComponent.current.getEditor()?.root,
                  {
                    scale: 5,
                    backgroundColor: "rgba(0,0,0,0)",
                  }
                );

                // setting the base64 to src on state
                renderedObject.src = canvas.toDataURL("image/png", 2);

                //setting the same height on picture to state
                renderedObject.height =
                  quillRefComponent.current.getEditor().root.offsetHeight;

                // setting the same width of picture to state
                renderedObject.width =
                  quillRefComponent.current.getEditor().root.offsetWidth;

                // making the script wait two seconds for visualizing the editor
                await new Promise((res) => setTimeout(res, 0));
              } catch (error) {
                console.error(error);
              }
            }
          }
        }

        documentAutomatized.current = cloneObjectScreen;
        setLoading(false);
        setIsModalVisible(false);
        setIsModalNameDocumentVisible(true);
      } catch (error) {
        console.error(error);
        setLoading(null);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          let errorMessage = "";
          let arrayErrors: Array<string> = [];

          Object.keys(errors).forEach(function (item) {
            arrayErrors.push("■ " + errors[item]);
            errorMessage += `${errors[item]} \n `;
          });

          toast.error(" Todos os campos são obrigatórios", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              fontSize: "12pt",
              textAlign: "center",
            },
          });
          return;
        }
      }
    },
    [objectScreen, loading]
  );

  //when user save document automatized
  const handleSaveAutomatizedDocument = useCallback(
    async (data) => {
      const { document_name } = data;
      setLoading("saving");
      try {
        const fetchAPI = await api.post("user-templates", {
          title: document_name,
          template: {
            arrayOfPages: documentAutomatized.current,
          },
          is_automation: true,
          automation_user_template_id: templateid,
        });

        //Created automation action register function
        handleCreatedAutomationAction({
          automation_id: fetchAPI.data.id,
          project_id: templateid,
        });

        await getAutomations();
        setIsModalNameDocumentVisible(false);
        setLoading(false);
      } catch (error: any) {
        console.error(error);
        setLoading(false);
      }
    },
    [getAutomations, handleCreatedAutomationAction, templateid]
  );

  const handlePreviewDocument = useCallback(async (document_id: string) => {
    try {
      setLoading("preview");
      idCurrentAutomatizedDocument.current = document_id;
      const fetchDocumentPreview = await api.get(
        `user-templates/template/${document_id}`
      );

      const fetchName = await api.get(`user-templates/${document_id}`);

      setPreview({
        pages: fetchDocumentPreview.data.response.arrayOfPages,
        title: fetchName.data.title,
      });
      setModalPreviewDocumentVisible(true);
      setLoading(false);
    } catch (error) {
      console.error("error getting document to preview", error);
    }
  }, []);

  //handle edit document to open just navigate user to the project with the id
  const handleEditDocument = useCallback(
    async (document_id: string) => {
      // // window.open(`${document_id}`);
      // //navigate(`/`, { replace: true });
      // navigate(`editor/my-template/${document_id}`, { replace: true });

      await handleSaveTemplate(true);
      window.location.replace(`${document_id}`);

      // try {
      //   window.open(`${document_id}`,);
      // } catch (err) {
      //   console.error(err, "error when get specific template");
      // }
    },
    [setObjectScreen, handleSaveTemplate]
  );

  //when user does the download
  const handleDownloadAutomatizedDocument = useCallback(
    async (document_id: string, document_name: string) => {
      idCurrentAutomatizedDocument.current = document_id;
      documentName.current = document_name;
      const fetchDocumentPreview = await api.get(
        `user-templates/template/${document_id}`
      );

      const { arrayOfPages } = fetchDocumentPreview.data.response;

      documentPreviewData.current = arrayOfPages;

      //document dowload action register function
      setDownloading(true);
      handleDowloadProjectAction({
        project_id: document_id,
        automated: true,
        type: "pdf",
      });
    },
    [handleDowloadProjectAction]
  );

  const handleDeleteAutomatizedDocument = useCallback((document_id: string) => {
    idCurrentAutomatizedDocument.current = document_id;
    setIsModalDeleteDocumentVisible(true);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    try {
      setLoading("deleting");
      await api.delete(
        `user-templates/${idCurrentAutomatizedDocument.current}`
      );
      setListDocumentsAutomatized((prevState) => {
        let cloneState = cloneDeep(prevState);
        cloneState = cloneState.filter((document) => {
          return document.id !== idCurrentAutomatizedDocument.current;
        });

        return cloneState;
      });

      setLoading(false);
      idCurrentAutomatizedDocument.current = null;
      setIsModalDeleteDocumentVisible(false);
    } catch (error) {
      setLoading(false);
      console.error("something went wrong on deleting document");
    }
  }, [idCurrentAutomatizedDocument]);

  const handleScrollTab = useCallback(
    async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const { offsetHeight, scrollTop, scrollHeight } =
        automatizedTabRef.current;

      //making calculation to know if scroll has reached the end
      if (offsetHeight + scrollTop >= scrollHeight - 1) {
        //getting out of function if page namber doesnt exist anymore
        if (automatizedDocumentsPageNumber.current === "FINAL") return;

        automatizedDocumentsPageNumber.current += 1;

        try {
          setLoading("pages");
          const fetchAutomatizedDocuments = await api.get(
            `user-templates/list-automation-user-template/a2632c3d-3973-4aea-98ba-23393bb90fe8?pagination=${automatizedDocumentsPageNumber.current}`
          );

          //veryfing if page came with any data, if not end of list has been reached

          if (fetchAutomatizedDocuments.data.dataArray.length !== 0) {
            setListDocumentsAutomatized((prevState) => [
              ...prevState,
              ...fetchAutomatizedDocuments.data.dataArray,
            ]);
            setLoading(null);
          } else {
            automatizedDocumentsPageNumber.current = "FINAL";
            setLoading(null);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [automatizedTabRef, automatizedDocumentsPageNumber]
  );

  return (
    <ContainerSidebarNav>
      <ButtonAutomation onClick={handleOpenModal}>
        Preencher automação
      </ButtonAutomation>

      <SubContainerSideBarNav
        onScroll={handleScrollTab}
        ref={automatizedTabRef}
      >
        <ListDocumentsContainer>
          {listDocumentsAutomatized.length === 0 ? (
            <TextNotElement>Nenhum automação preenchida</TextNotElement>
          ) : (
            listDocumentsAutomatized.map((itemAutomatize, index) => {
              return (
                <AutomatizedItem
                  key={itemAutomatize.id}
                  downloading={downloading}
                  loading={loading}
                  setLoading={setLoading}
                  currentSelectedDocument={idCurrentAutomatizedDocument.current}
                  document_id={itemAutomatize.id}
                  handlePreviewDocument={handlePreviewDocument}
                  handleEditDocument={handleEditDocument}
                  handleDownloadAutomatizedDocument={
                    handleDownloadAutomatizedDocument
                  }
                  handleDeleteAutomatizedDocument={
                    handleDeleteAutomatizedDocument
                  }
                  document_name={itemAutomatize.title}
                />
              );
            })
          )}
        </ListDocumentsContainer>
      </SubContainerSideBarNav>

      <Modal open={isSureQuit}>
        <ModalContainerQuit>
          <TitleH5 title="Voltar para o editor" />
          <span>Tem certeza que você quer deixar a automação?</span>

          <ButtonsBox>
            <ButtonNo
              onClick={() => {
                setIsSureQuit(false);
              }}
              color="#FFF"
            >
              Não
            </ButtonNo>
            <Button
              onClick={async (event) => {
                handleCloseModal(event);
              }}
              color={theme.colors.primary}
            >
              {loadingDeleteDoc ? (
                <ReactLoading type="spin" color="#fff" height={14} width={14} />
              ) : (
                `Sim`
              )}
            </Button>
          </ButtonsBox>
        </ModalContainerQuit>
      </Modal>

      <Modal hidden={isSureQuit} open={IsModalVisible}>
        <ModalContainer>
          <ContainerVariables>
            {loading === "automatizing" ? (
              <ContainerLoading>
                {/* <LoadingEditor /> */}
                <ContractAnimation />
              </ContainerLoading>
            ) : (
              <Form
                ref={formRef}
                onSubmit={handleAutomatizeDocument}
                style={{
                  height: "100%",
                }}
              >
                <HeaderModal>
                  <TitleH5 title="Preencher automação" />
                  <ButtonExit
                    onClick={handleConfirmQuit}
                    inactivatedColor="#999C9F"
                  />
                </HeaderModal>
                <GridInput>
                  {documentVariables.length > 0 ? (
                    documentVariables.map((variable) => {
                      return (
                        <Variables
                          key={variable}
                          name={variable}
                          variable={variable}
                        />
                      );
                    })
                  ) : (
                    <NoVariables>
                      <NoVariablesLabel>
                        Você não possui variaveis de automação em seu documento
                      </NoVariablesLabel>
                    </NoVariables>
                  )}
                </GridInput>

                {documentVariables.length > 0 && (
                  <FooterModal>
                    <ButtonMaxWidth
                      text="Cancelar"
                      typeStyle="white"
                      onClick={handleConfirmQuit}
                    />
                    <ButtonMaxWidth
                      text="Salvar"
                      typeStyle="purple"
                      type="submit"
                    />
                  </FooterModal>
                )}
              </Form>
            )}
          </ContainerVariables>
          <ReactQuill
            ref={quillRefComponent}
            modules={modules}
            style={{
              fontSize: 18,
              position: "absolute",
              top: "0",
              right: "-1000px",
              maxWidth: "500px",
              backgroundColor: "white",
              // zIndex: "-50",
              resize: "both",
            }}
          />
        </ModalContainer>
      </Modal>

      <Modal open={isModalNameDocumentVisible}>
        <ModalContainer>
          <ContainerModalDocumentName>
            <Form ref={formRef} onSubmit={handleSaveAutomatizedDocument}>
              <HeaderModalDocumentName>
                <TitleH5 title="Automação" />
                <ButtonExit
                  onClick={() => setIsModalNameDocumentVisible(false)}
                />
              </HeaderModalDocumentName>
              <PlainText>
                Defina um nome para o seu documento automatizado. Será criado
                uma cópia do documento original.
              </PlainText>
              <BoxButtonDocumentName>
                <label>Nome do documento</label>
                <Input name="document_name" />
              </BoxButtonDocumentName>

              <ButtonMaxWidth
                text="Salvar"
                typeStyle="purple"
                type="submit"
                loading={loading === "saving"}
                disabled={loading === "saving"}
              />
            </Form>
          </ContainerModalDocumentName>
        </ModalContainer>
      </Modal>

      <Modal open={isModalDeleteDocumentVisible}>
        <ModalContainer onClick={handleCloseModal}>
          <ContainerModalDocumentName>
            <LabelDocumentName>
              Você realmente deseja excluir ?
            </LabelDocumentName>
            <ContainerButtonsYesNo>
              <ButtonPrimary
                disabled={loading === "deleting"}
                onClick={handleConfirmDelete}
              >
                {loading === "deleting" ? (
                  <ContainerLoading>
                    <LoadingEditor color="#FFF" width={30} height={30} />
                  </ContainerLoading>
                ) : (
                  "Sim"
                )}
              </ButtonPrimary>
              <ButtonPrimary
                onClick={() => {
                  setIsModalDeleteDocumentVisible(false);
                }}
                style={{
                  marginLeft: "0.5rem",
                  backgroundColor: "#FFF",
                  border: "solid",
                  borderWidth: "1px",
                  borderColor: "#000",
                  overflow: "hidden",
                }}
              >
                <LabelDocumentName>Não</LabelDocumentName>
              </ButtonPrimary>
            </ContainerButtonsYesNo>
          </ContainerModalDocumentName>
        </ModalContainer>
      </Modal>

      <PreviewTemplateModal
        open={modalPreviewDocumentVisiblle}
        handleCloseModal={() => setModalPreviewDocumentVisible(false)}
        pagesToBeRendered={preview.pages}
        title={preview.title}
        loadingPreview={loading === "preview"}
        local="Editor"
      />

      <DownloadDocument
        downloading={downloading}
        setDownloading={setDownloading}
        documentName={documentName.current}
        pagesToBeRendered={documentPreviewData.current}
      />

      {loading === "pages" && (
        <div style={{ marginTop: 10 }}>
          <LoadingEditor color="#111" width={30} height={30} />
        </div>
      )}
    </ContainerSidebarNav>
  );
};

export default AutomatizingTab;

