/*
this file render modals of diff function when user
need to accept or reject text was edited by other user
*/

import React from "react";
import ModalDefault from "../ModalDefault";
import ModalDefaultDecline from "../ModalDefaultDecline";

// import { Container } from './styles';

interface ModalDiffProviderProps {
  divRefModal: React.MutableRefObject<HTMLDivElement>;
  handleDefineModifications: (whichAction: string) => void;
  handleOpenModalDifferences: (type: "Open" | "Close") => void;
  divRefModalDecline: React.MutableRefObject<HTMLDivElement>;
  handleOpenModalDifferencesDecline: (type: "Open" | "Close") => void;
}

const ModalsDiffProvider: React.FC<ModalDiffProviderProps> = ({
  divRefModal,
  handleDefineModifications,
  handleOpenModalDifferences,
  divRefModalDecline,
  handleOpenModalDifferencesDecline
}) => {
  return (
    <>
      <ModalDefault
        divRefModal={divRefModal}
        option={"Accept"}
        handleDefineModifications={handleDefineModifications}
        handleOpenModalDifferences={handleOpenModalDifferences}
      />
      <ModalDefaultDecline
        divRefModalDecline={divRefModalDecline}
        option={"Decline"}
        handleDefineModifications={handleDefineModifications}
        handleOpenModalDifferencesDecline={handleOpenModalDifferencesDecline}
      />
    </>
  );
};

export default ModalsDiffProvider;
