import React from "react";

import {
  GroupDecorationLogo,
  GroupDecorationLogoII,
  GroupDecorationLogoIII,
  GroupDecorationLogoIV
} from "./styles";

import decorationLogo from "../../assets/effect-background.svg";
import IconSvg from "../../../../../editor/components/IconSvg";

// import { Container } from './styles';

const DecorationBackground: React.FC = () => {
  return (
    <>
      <GroupDecorationLogo>
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
      </GroupDecorationLogo>

      <GroupDecorationLogoII>
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
      </GroupDecorationLogoII>

      <GroupDecorationLogoIII>
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
      </GroupDecorationLogoIII>

      <GroupDecorationLogoIV>
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />

        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
        <IconSvg
          className="decoration-logo"
          src={decorationLogo}
          description=""
        />
      </GroupDecorationLogoIV>
    </>
  );
};

export default DecorationBackground;
