/*
theme of styled components configuration
*/

import React, { createContext, useContext, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { firstTheme } from "../shared/styles/theme";
import { useAuth } from "./auth";
import { darken, lighten, saturate } from "polished";

interface ThemeContextData {
  // toggleTheme(): void;
  theme: Theme;
  lightPrimaryColor: string;
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
}

interface Theme {
  name?: string;
  colors?: {
    primary: string;
    black: string;
    gradientBody: string;
    gradientBody2: string;
    background: string;
    border: string;
    backgroundEditor: string;
    colorTextAsideDash: string;

    black100?: string;
    black75?: string;
    black50?: string;
    black20?: string;
    black10?: string;
    black5?: string;
    primary90?: string;
    white100?: string;
    error100?: string;
    error10?: string;
    focus?: string;
    emphasis?: string;
  };
}

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

// themeTemplate = {
//   name: "organization",
//   colors: {
//     primary: "red",
//     gradientBody: "#3c2058",
//     gradientBody2: "#663399",
//     secondary: "#000",
//     black: "#000",
//     background: "#663399",
//     border: "#C4C4C4",
//     gray900: "#343A40",
//     gray300: "#ADB5BD",
//     gray500: "#EEEEEE",
//     backgroundEditor: "#F8F9FA",
//     red: "#520202",
//     whiteColor: "#fff",
//     backgroundAsideDash: "#fafafa",
//     colorTextAsideDash: "#a5a5a5"
//   }
// };

export const useTheme = () => useContext(ThemeContext);

export const CustomThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(firstTheme);
  const { data } = useAuth();

  const lightPrimaryColor = darken(
    0.05,
    saturate(0.6, lighten(0.24, theme.colors.primary))
  );

  useEffect(() => {
    if (data?.organization?.name) {
      setTheme(oldState => {
        return {
          ...oldState,
          name: data?.organization?.name,
          colors: {
            primary: data?.organization_theme?.primary_color,
            backgroundAsideDash:
              data?.organization_theme?.menu_background_color,
            colorTextAsideDash: data?.organization_theme?.menu_text_color,
            gradientBody: "#3c2058",
            gradientBody2: "#663399",
            secondary: "#000",
            black: "#000",
            background: "#663399",
            border: "#C4C4C4",
            gray900: "#343A40",
            gray300: "#ADB5BD",
            gray500: "#EEEEEE",
            backgroundEditor: "#F8F9FA",
            red: "#520202",
            whiteColor: "#fff",

            black100: "#343A40",
            black75: "#676B70",
            black50: "#999C9F",
            black20: "#D6D8D9",
            black10: "#EBEBEC",
            black5: "#F5F5F5",
            primary90: data?.organization_theme?.primary_color,
            white100: "#FFF",
            error100: "#DE2D2D",
            error10: "#FCEAEA",
            focus: "#5D5FEF",
            emphasis: `${darken(
              0.05,
              saturate(
                0.6,
                lighten(0.24, data?.organization_theme?.primary_color)
              )
            )}`
          }
        };
      });
    } else {
      setTheme(firstTheme);
    }
  }, [data]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, lightPrimaryColor }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
