import styled from "styled-components";

export const FlexSearch = styled.div`
  width: 100%;
  height: 70px;
`;

export const WrapperButtonFeedback = styled.div`
  width: 100%;
  padding-inline: 10px;

  position: absolute;
  z-index: 2;
  bottom: 50px;

  button {
    width: 100%;
    padding: 8px 16px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.primary};
    background-color: #fff;
    transition: all 120ms linear;

    font-size: 14px;
    font-weight: 500;

    :hover {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }
  }
`;

export const VerticalStack = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
`;
