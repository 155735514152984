import { cloneDeep } from "lodash";
import { ILinesPage } from "../../../../hooks/pagesEditor";
import { Box, Container } from "./styled";

interface IRulerTopProps {
  setLinesPage: React.Dispatch<React.SetStateAction<ILinesPage[]>>;
  thisIndexObject: number;
  isActive: boolean;
}

const RulerLeft = ({
  setLinesPage,
  thisIndexObject,
  isActive
}: IRulerTopProps) => {
  return (
    <Container
      onMouseDown={() => {
        if (isActive) {
          setLinesPage(prevState => {
            let cloneState = cloneDeep(prevState);
            let thisObject = cloneState[thisIndexObject];
            thisObject.islineV = true;
            thisObject.islineH = false;

            return cloneState;
          });
        }
      }}
      isActive={isActive}
    >
      <div>
        <Box>
          <p>0</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>1</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>2</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>3</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>4</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>5</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>6</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>7</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>8</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>9</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>10</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>11</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>12</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>13</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>14</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>15</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>16</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>17</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>18</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>19</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>20</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>21</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>22</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>23</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>24</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>25</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>26</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>27</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>28</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
        <Box>
          <p>29</p>
          <div>
            <span className="first-vector" />
            <span className="second-vector" />
            <span className="first-vector" />
            <span className="second-vector" />
          </div>
        </Box>
      </div>
    </Container>
  );
};

export default RulerLeft;
