import React, { useEffect, useState } from "react";

import Avatar from "react-avatar";
import { useHeaderEditor } from "../../../../editor/hooks/headerEditor";

import {
  // Avatar,
  AvatarAnnonymous,
  AvatarLetterImage,
  AvatarLetterImageEditor
} from "./styles";

// const images = [
//   "https://picsum.photos/300/300",
//   "https://picsum.photos/500/500",
//   "https://picsum.photos/600/600",
//   "https://picsum.photos/700/700",
//   "https://picsum.photos/800/800",
//   "https://picsum.photos/900/900"
// ];

interface MultiplesAvatarLetterProps {
  src?: string;
  text?: string;
  setText?: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  color?: string;
  isAnnonymous?: boolean;
  isHeader?: boolean;
  isDropdown?: boolean;
  idElement?: string;
  isEditor?: boolean;
}

const MultiplesAvatarLetter: React.FC<MultiplesAvatarLetterProps> = ({
  src,
  text,
  setText,
  className,
  color,
  isAnnonymous,
  isHeader,
  isDropdown,
  idElement,
  isEditor = false,
  ...rest
}) => {
  const [textTransform, setTextTransform] = useState(text);

  useEffect(() => {
    const changeTextForLetters = text => {
      const dataText = text?.split(" ", 2);
      if (dataText?.length > 1) {
        setTextTransform(
          `${text?.substring(0, 1)}${dataText[1]?.substring(0, 1)}`
        );
      } else {
        setTextTransform(text?.substring(0, 1));
      }
    };

    changeTextForLetters(text);
  }, [text]);

  return (
    <>
      {!isAnnonymous && src.includes("avatar_default") ? (
        <div
          style={{
            position: "absolute",
            top: "-1050px"
          }}
          {...rest}
          id={idElement}
        >
          <Avatar
            maxInitials={2}
            round={true}
            name={text}
            size={"172px"}
            color={color}
          />
        </div>
      ) : !isEditor ? (
        <AvatarLetterImage src={src} className={className} color={color}>
          {isAnnonymous && (
            <AvatarAnnonymous
              className="avatar-icon"
              isDropdown={isDropdown}
              isHeader={isHeader}
              
            />
          )}
        </AvatarLetterImage>
      ) : (
        <AvatarLetterImageEditor src={src} className={className} color={color}>
          {isAnnonymous && (
            <AvatarAnnonymous
              className="avatar-icon"
              isDropdown={isDropdown}
              isHeader={isHeader}
            />
          )}
        </AvatarLetterImageEditor>
      )}
    </>
  );
};

export default MultiplesAvatarLetter;
