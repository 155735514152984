import { Container, SubModal, Item, ItemSubModal } from "./styles";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../shared/utils/MountIcons";
// import DowloadICon from "../../../../assets/dowload-icon.svg";
// import PdfICon from "../../../../assets/pdf-icon.svg";
// import WordICon from "../../../../assets/word-icon.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import api from "../../../../../../services/api";
import { useMainHook } from "../../../../../../hooks/main";
import { useMetric } from "../../../../../../hooks/metric";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { PagesProps } from "../../../../../../dtos/PagesProps";
import { useAuth } from "../../../../../../hooks/auth";
// import { useSharedDocument } from "../../../../../editor/hooks/sharedDocument";

interface IOptionsTemplateSharedWithMeProps {
  id: string;
  index: number;
  title: string;
  // downloading: boolean;
  // setDownloading: React.Dispatch<React.SetStateAction<boolean>>;
  // idToastifyDownloadRef: any;
  // whichFileToBeDownload: React.MutableRefObject<"pdf" | "word">;
  // documentPreviewData: React.MutableRefObject<PagesProps[]>;
  // isIntentBuyModal?: boolean;
  // setIsIntentBuyModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const OptionsTemplateSharedWithMe = ({
  id,
  index,
  title,
  // downloading,
  // setDownloading,
  // idToastifyDownloadRef,
  // whichFileToBeDownload,
  // documentPreviewData,
  // isIntentBuyModal,
  // setIsIntentBuyModal,
}: IOptionsTemplateSharedWithMeProps) => {
  // const {
  //   documentUsersAccess,
  // } = useSharedDocument();

  // const [isOpenDowloadModal, setIsOpenDowloadModal] = useState(false);
  const [typeUserAccount, setTypeUserAccount] = useState<
    "trial" | "premium" | "loading"
  >("loading");

  const { data } = useAuth();
  const {
    setCreatedSharedDocument,
    setObjectScreen,
    documentName,
    setIsLoadingModal
  } = useMainHook();

  // const { handleCreatedProjectAction, handleDowloadProjectAction } = useMetric();
  const { handleCreatedProjectAction } = useMetric();
  const navigator = useNavigate();

  // const isDownloadPermission = useMemo(() => {
  //   return Object.keys(documentUsersAccess).length === 0 || documentUsersAccess.access_control === true;
  // }, [documentUsersAccess]);

  const chooseTemplateBits = async (id?: string) => {
    setIsLoadingModal(true);
    let selectedTemplate = [];
    try {
      const responseSpecific = await api.get(`user-templates/template/${id}`);
      selectedTemplate.push(...responseSpecific.data.response.arrayOfPages);
    } catch (err) {
      setIsLoadingModal(false);
      console.error(err, "error when get specific template");
    }
    if (selectedTemplate.length > 0) {
      try {
        const newProject = {
          template: {
            arrayOfPages: selectedTemplate
          },
          title: `Cópia de ${title}`,
          description: "Criando novo projeto de template a partir de uma cópia",
          is_automation: false
        };

        const responseCreateProjectCopied = await api.post(
          "user-templates",
          newProject
        );

        if (responseCreateProjectCopied.status === 200) {
          setObjectScreen(selectedTemplate);
          documentName.current = newProject.title;

          //created project action register function
          handleCreatedProjectAction({
            project_id: responseCreateProjectCopied.data.id,
            creation_origin: "Shared Template",
            template_id: id,
            template_bits_name: false,
            researched: false
          });

          navigator(
            `/editor/my-template/${responseCreateProjectCopied.data.id}`
          );

          setCreatedSharedDocument(true);
          setIsLoadingModal(false);
        }
      } catch (err) {
        setIsLoadingModal(false);
        console.error(err, "error when choose template");
        window.alert("Error: Algo deu errado!");
      }
    }
  };

  // const handlePreviewDocument = useCallback(
  //   async (document_id: string, type: "pdf" | "word") => {
  //     const loadingtoast = toast.loading("Tudo certo. Aguarde um momento.");
  //     try {
       
  //       const fetchDocumentPreview = await api.get(
  //         `user-templates/template/${document_id}`
  //       );

  //       documentPreviewData.current =
  //         fetchDocumentPreview.data.response.arrayOfPages;
  //       whichFileToBeDownload.current = type;
  //       idToastifyDownloadRef.current = title;
  //       setTimeout(() => {
  //         toast.dismiss(loadingtoast);
  //       }, 1000);
  //       setDownloading(true);
  //       handleDowloadProjectAction({
  //         project_id: document_id,
  //         automated: false,
  //         type: type
  //       });
  //     } catch (error) {
  //       setTimeout(() => {
  //         toast.dismiss(loadingtoast);
  //       }, 1000);
  //       toast.error("Ops, algo deu errado!");

  //       console.error("error getting document to preview", error);
  //     }
  //   },
  //   [
  //     documentPreviewData,
  //     whichFileToBeDownload,
  //     idToastifyDownloadRef,
  //     title,
  //     setDownloading,
  //     handleDowloadProjectAction
  //   ]
  // );

  useEffect(() => {
    if (
      data.payment_method?.user_type === "user_free" &&
      !data.payment_method.subscription_id &&
      data.payment_method.cancellation_date &&
      data.payment_method.is_active
    ) {
      setTypeUserAccount("trial");
    } else {
      setTypeUserAccount("premium");
    }
  }, [data]);

  return (
    <Container isScreenLimit={index === 3 || index === 7}>
      <Item onClick={() => chooseTemplateBits(id)}>
        <div>
          <PersonalizedIcon
            dPath={MountIcons.IconCopy.dPath}
            viewBox={MountIcons.IconCopy.viewBox}
          />
          Fazer uma cópia em Meus projetos
        </div>
      </Item>
      {/* <Item
        onMouseEnter={() => setIsOpenDowloadModal(true)}
        onMouseLeave={() => setIsOpenDowloadModal(false)}
      >
        <div>
          <img src={DowloadICon} alt="" /> Baixar
        </div>
        <PersonalizedIcon
          dPath={MountIcons.IconArrowRight.dPath}
          viewBox={MountIcons.IconArrowRight.viewBox}
          inactivatedColor="#676B70"
        />
        {isOpenDowloadModal && (
          <SubModal
            isScreenLimit={
              index === 2 || index === 3 || index === 6 || index === 7
            }
          >
            <ItemSubModal isDisabled={!isDownloadPermission} onClick={() => handlePreviewDocument(id, "pdf")}>
              <img src={PdfICon} alt="" />
              <p>PDF</p>
            </ItemSubModal>
            <ItemSubModal
              isDisabled={!isDownloadPermission}
              onClick={() => {
                if (typeUserAccount === "premium") {
                  handlePreviewDocument(id, "word");
                } else {
                  setIsIntentBuyModal(true);
                }
              }}
            >
              <img src={WordICon} alt="" />
              <p>Word</p>
              <PersonalizedIcon
                dPath={MountIcons.IconCrown.dPath}
                viewBox={MountIcons.IconCrown.viewBox}
                inactivatedColor={
                  typeUserAccount === "trial" ? "#FBBE28" : "#686867"
                }
              />
            </ItemSubModal>
          </SubModal>
        )}
      </Item> */}
    </Container>
  );
};

export default OptionsTemplateSharedWithMe;
