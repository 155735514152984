import styled from "styled-components";

interface IContainerProsp {
  isActive?: boolean;
}

export const Container = styled.div<IContainerProsp>`
  width: 100%;
  height: 30px;
  position: relative;
  

  opacity: ${props => (props.isActive ? 1 : 0)};

  display: flex;
  justify-content: end;

  user-select: none;

  cursor: ${props => (props.isActive ? "n-resize" : "default")};

  & > div {
    display: grid;
    gap: 6px;
    grid-template-columns: repeat(21, 1fr) 1px;
    width: 596px;
  }

  animation: fade-in 0.5s ease-in-out;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  user-select: none;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 100%;
    color: #a5a5a5;
    position: relative;
    left: -3px;
    user-select: none;
  }

  div {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    user-select: none;

    .first-vector {
      height: 4.5px;
    }
    .second-vector {
      height: 10px;
    }
  }

  span {
    background-color: #a5a5a5;
    width: 1px;
    user-select: none;
  }
`;


export const ImgTrash = styled.img<{ isActive: boolean }>`
  position: absolute;
  left: 0;
  bottom: 0;

  height: 22px;
  width: 18px;

  display: ${({ isActive }) => (isActive ? "inline-block" : "none")} !important;
  animation: fade-in 0.3s ease-in-out;

  user-select: none;
  pointer-events: none;
`;