import { Container, Button, BoxComments, Header, LoadingBox } from "./styled";
import { AxiosResponse } from "axios";

import { useParams } from "react-router-dom";

import ListAllCommentsView from "../ListAllCommentsView";
import ListHistoryCommentsView from "../ListHistoryCommentsView";

const ListAllCommentsPage = ({ categoryComment, setCategoryComment }) => {
  // const [commentsData, setCommentsData] = useState<CommentListProps[]>([]);

  const { templateid } = useParams();

  return (
    <Container>
      <>
        <Header>
          <Button
            onClick={() =>
              setCategoryComment({
                page: "All",
                isOpenComment: false
              })
            }
            isActive={categoryComment === "All"}
          >
            Todos os comentários
          </Button>
          <Button
            onClick={() =>
              setCategoryComment({
                page: "Historic",
                isOpenComment: false
              })
            }
            isActive={categoryComment === "Historic"}
          >
            Histórico de comentários
          </Button>
        </Header>
        <BoxComments>
          {categoryComment === "All" ? (
            <ListAllCommentsView
              setCategoryComment={setCategoryComment}
              categoryComment={categoryComment}
              // handleDoneCommentary={handleDoneCommentary}
            />
          ) : (
            <ListHistoryCommentsView setCategoryComment={setCategoryComment} />
          )}
        </BoxComments>
      </>
    </Container>
  );
};

export default ListAllCommentsPage;
