import axios from "axios";
//deploy
const api = axios.create({
  baseURL: process.env.REACT_APP_CURRENT_API,
});

api.interceptors.request.use(
  (config) => {
    config.headers["X-Frame-Options"] = "DENY";
    config.headers["X-Content-Type-Options"] = "nosniff";
    config.headers["Strict-Transport-Security"] =
      "max-age=31536000; includeSubDomains; preload";
    config.headers["Content-Security-Policy"] =
      "default-src https:; script-src https:";
    config.headers["Referrer-Policy"] = "strict-origin-when-cross-origin";
    config.headers["Permissions-Policy"] =
      'geolocation=(self "https://sua-api.com")';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
