import styled from "styled-components";

export const FlowContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  @media (max-width: 1600px) {
    gap: 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px 0px;
  border-bottom: 1px solid #ebebec;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 700;
  font-size: 16px;
`;

export const StepWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 192px;
  cursor: pointer;
`;

export const StepTextWrapper = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const LinkButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  font-size: 14px;
`;

export const StepNormalText = styled.span`
  color: white;
  font-weight: 500;
  font-size: 14px;
`;

export const StepsFlexWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(172px, 172px));
  grid-gap: 10px;
  align-items: start;
`;

export const StepActiveArrow = styled.div`
  position: absolute;
  bottom: -25px;
  left: 46%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  border-top: 1px solid #999c9f;
  border-left: 1px solid #999c9f;
  transform: rotateZ(45deg);
  background-color: white;
`;

export const ConfirmButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  cursor: pointer;
  width: 95px;
  margin-top: 20px;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  border-top: 1px solid #ebebec;
  border-left: 1px solid #ebebec;
  border-right: 1px solid #ebebec;
  border-radius: 5px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const THead = styled.thead`
  background-color: #ebebec;
`;
export const TableHead = styled.th`
  padding: 10px 20px;
  text-align: left;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray600};
  font-size: 13px;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ebebec;
`;

export const TableData = styled.td`
  padding: 10px 20px;
  font-size: 14px;
`;

export const TablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #999c9f;
  border-radius: 5px;
  padding: 8px 20px 20px 20px;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DeadlineLabel = styled.span`
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 400;
  font-size: 13px;
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
`;
