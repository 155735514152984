import {
  ButtonModal,
  ContainerModal,
  InputEmail,
  Modal,
  Input,
  Table,
  Header,
  InputArea,
  HeaderSettings,
  ButtonAddUser
} from "./styled";

import ReactLoading from "react-loading";
import { useCallback, useEffect, useState } from "react";
import ParticipantItem from "../../ParticipantItem";
import { BoxButton, ParagraphError } from "../styled";
import { useTeams } from "../../../../../../hooks/teams";
import api from "../../../../../../services/api";
import { AxiosResponse } from "axios";
import {
  ISelectModalTeam,
  TeamsListProps
} from "../../../../../../dtos/teamsUtils";
import { ContractAnimation } from "../../../../../../shared/components/ContractAnimation";

import { toast } from "react-toastify";
import { useMetric } from "../../../../../../hooks/metric";
import Tooltip from "../../../../../editor/components/Tooltip";
import TitleH5 from "../../../../../../shared/components/TitleH5";
import ButtonExit from "../../../../../../shared/components/ButtonExit";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../shared/utils/MountIcons";

interface UsersTeamGroupProps {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  avatar_url: string;
  email: string;
  is_owner: true;
}

interface IModalAddParticipant {
  isSettings: boolean;
  setIsSettings: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
}

const ModalAddParticipant = ({
  isSettings,
  setIsSettings,
  setSelectModal
}: IModalAddParticipant) => {
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [emailUser, setEmailUser] = useState("");
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [inputMode, setInputMode] = useState(false);
  const [isAddUser, setIsAdduser] = useState(false);

  const [loading, setLoading] = useState(true);
  const {
    createdTeamId,
    setListTeams,
    nameOfTeam,
    setNameOfTeam,
    usersTeamGroup,
    setUsersTeamGroup,
    handleRemoveMember,
    setForceUpdate,
    forceUpdate,
    listTeams
  } = useTeams();
  const { handlePartipantAddedToTeamAction } = useMetric();

  const [userIsOwner, setUserIsOwner] = useState(false);

  useEffect(() => {
    return () => {
      setUsersTeamGroup([]);
    };
  }, []);

  const handleUpdate = async () => {
    try {
      const response: AxiosResponse<TeamsListProps> = await api.get(
        "team-users/list-user-teams"
      );
      // setForceUpdate(prevState => !prevState);
      setListTeams(response.data.dataArray);

      toast.success("Equipe modificada com sucesso!");
    } catch (err) {
      console.error(err, "ERROR when get teams list");
    }
  };

  const handleSendNewUserToTeam = useCallback(async () => {
    if (userIsOwner) {
      try {
        setLoadingUsers(true);
        const responseAddUserTeam = await api.post("team-users", {
          team_id: createdTeamId,
          email: emailUser
        });

        setForceUpdate(!forceUpdate);
        //Participant action function adds team
        handlePartipantAddedToTeamAction({
          team_id: createdTeamId,
          participant_id: responseAddUserTeam.data.id
        });

        setUsersTeamGroup(prevState => {
          let cloneState = [...prevState];
          cloneState.push({ ...responseAddUserTeam.data });
          return cloneState;
        });

        setIsErrorEmail(false);
        setLoadingUsers(false);
        setEmailUser("");
      } catch (err) {
        console.error(err, "ERROR when add new user to team");
        setIsErrorEmail(true);
        setLoadingUsers(false);

        if (err.response.data.message === "There is no user with that email") {
          setIsErrorEmail(true);
          setLoadingUsers(false);
        }
      }
    }
  }, [emailUser, createdTeamId, userIsOwner]);

  const handleChangeNameTeam = useCallback(async () => {
    try {
      const responseUpdateNameTeam = await api.put("team", {
        team_id: createdTeamId,
        name: nameOfTeam
      });
      setInputMode(!inputMode);
      setListTeams(
        listTeams.filter(team => {
          if (team.team_id === createdTeamId) {
            return (team.name = nameOfTeam);
          }
          return team;
        })
      );
    } catch (err) {
      setInputMode(!inputMode);
      console.error(err, "error response when change name of team");
    }
  }, [createdTeamId, inputMode, listTeams, nameOfTeam, setListTeams]);

  useEffect(() => {
    (async () => {
      if (!!createdTeamId) {
        try {
          const responseIsOwner = await api.get(
            `team-users/is-owner/${createdTeamId}`
          );

          setUserIsOwner(responseIsOwner.data.is_owner);
        } catch (err) {
          console.error(err, "error if thats user is owner of this team");
        }

        try {
          const response: AxiosResponse<UsersTeamGroupProps[]> = await api.get(
            `team-users/${createdTeamId}`
          );
          setUsersTeamGroup(response.data);
        } catch (err) {
          console.error(err, "ERROR when list users of team specific");
        }

        try {
          const responseValuesOfTeam = await api.get(`team/${createdTeamId}`);
          setNameOfTeam(responseValuesOfTeam.data.name);
          setLoading(false);
        } catch (err) {
          console.error(err, "ERROR when get specific team data");
        }
      }
    })();
  }, [createdTeamId]);

  return (
    <ContainerModal>
      {loading ? (
        <div className="container-loading">
          <ContractAnimation />
        </div>
      ) : isSettings ? (
        <Modal>
          <HeaderSettings isActive={inputMode}>
            <div
              onClick={() => {
                if (userIsOwner) {
                  setInputMode(true);
                }
              }}
            >
              {inputMode ? (
                <input
                  value={nameOfTeam}
                  onChange={event => setNameOfTeam(event.target.value)}
                  defaultValue={nameOfTeam}
                  autoFocus={true}
                  onBlur={handleChangeNameTeam}
                />
              ) : (
                <>
                  <TitleH5 title={nameOfTeam} />
                  <PersonalizedIcon
                    dPath={MountIcons.IconEdit.dPath}
                    viewBox={MountIcons.IconEdit.viewBox}
                    inactivatedColor="#999C9F"
                  />
                </>
              )}
            </div>
            <ButtonExit
              onClick={async () => {
                setIsSettings(false);
                setSelectModal({ modal: "", isOpen: false });
                await handleUpdate();
              }}
            />
          </HeaderSettings>
          <Table>
            <div>
              <p>Nome</p> <p>E-mail</p> <p>Função na equipe</p>
            </div>

            <ul>
              {usersTeamGroup.map(user => (
                <ParticipantItem
                  key={user.id}
                  handleRemoveMember={handleRemoveMember}
                  user={user}
                  setSelectModal={setSelectModal}
                  isOwner={userIsOwner}
                />
              ))}
            </ul>
          </Table>
          {isAddUser ? (
            <InputArea>
              <Input>
                <label>E-mail</label>
                <InputEmail
                  type="text"
                  isError={isErrorEmail}
                  value={emailUser}
                  onChange={event => setEmailUser(event.target.value)}
                />
                {isErrorEmail && (
                  <ParagraphError>
                    Esse e-mail não está cadastrado no UX DOC.
                  </ParagraphError>
                )}
              </Input>
              <button
                style={{
                  cursor: !userIsOwner ? "not-allowed" : ""
                }}
                onClick={handleSendNewUserToTeam}
              >
                {!loadingUsers ? (
                  `Enviar convite`
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <ReactLoading
                      type="spin"
                      color="#fff"
                      height={22}
                      width={22}
                    />
                  </div>
                )}
              </button>
            </InputArea>
          ) : (
            <ButtonAddUser onClick={() => setIsAdduser(true)}>
              <PersonalizedIcon
                dPath={MountIcons.IconCrossAdd.dPath}
                viewBox={MountIcons.IconCrossAdd.viewBox}
                inactivatedColor="#999C9F"
              />
              Adicionar usuários
            </ButtonAddUser>
          )}
          <BoxButton>
            <ButtonModal
              style={{
                cursor: !userIsOwner ? "not-allowed" : ""
              }}
              onClick={() => {
                if (userIsOwner) {
                  setSelectModal({ modal: "Delete Team", isOpen: true });
                }
              }}
              backgroundColor="#FFFFFF"
            >
              Excluir equipe
            </ButtonModal>

            <ButtonModal
              onClick={async () => {
                setIsSettings(false);
                setSelectModal({ modal: "", isOpen: false });
                toast.success("Equipe modificada com sucesso!");
              }}
              backgroundColor="#663399"
            >
              Salvar
            </ButtonModal>
          </BoxButton>
        </Modal>
      ) : (
        <Modal>
          <Header>
            <TitleH5 title={`Adicione os membros da equipe ${nameOfTeam}`} />
            <ButtonExit
              onClick={async () => {
                setIsSettings(false);
                setSelectModal({ modal: "", isOpen: false });
                await handleUpdate();
              }}
            />
          </Header>
          <InputArea>
            <Input>
              <label>E-mail</label>
              <InputEmail
                type="text"
                isError={isErrorEmail}
                value={emailUser}
                onChange={event => setEmailUser(event.target.value)}
              />
              {isErrorEmail && (
                <ParagraphError>
                  Esse e-mail não está cadastrado no UX DOC.
                </ParagraphError>
              )}
            </Input>
            <button
              style={{
                cursor: !userIsOwner ? "not-allowed" : ""
              }}
              onClick={handleSendNewUserToTeam}
            >
              {!loadingUsers ? (
                `Enviar convite`
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height={22}
                    width={22}
                  />
                </div>
              )}
            </button>
          </InputArea>
          <Table>
            <div>
              <p>Nome</p> <p>E-mail</p> <p>Função na equipe</p>
            </div>

            <ul>
              {usersTeamGroup.map(user => (
                <ParticipantItem
                  key={user.id}
                  handleRemoveMember={handleRemoveMember}
                  user={user}
                  setSelectModal={setSelectModal}
                  isOwner={userIsOwner}
                />
              ))}
            </ul>
          </Table>
          <BoxButton>
            <ButtonModal
              backgroundColor="#FFFFFF"
              onClick={async () => {
                setIsSettings(false);
                setSelectModal({ modal: "", isOpen: false });
                await handleUpdate();
              }}
            >
              Adicionar mais tarde
            </ButtonModal>

            <ButtonModal
              onClick={async () => {
                setIsSettings(false);
                setSelectModal({ modal: "", isOpen: false });
                toast.success("Equipe modificada com sucesso!");

                await handleUpdate();
              }}
              backgroundColor="#663399"
            >
              Criar
            </ButtonModal>
          </BoxButton>
        </Modal>
      )}
    </ContainerModal>
  );
};

export default ModalAddParticipant;
