import { useEffect } from "react";
import { useTeams } from "../../../../../../hooks/teams";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import PlainText from "../../../../../../shared/components/PlainText";
import TitleH5 from "../../../../../../shared/components/TitleH5";
import { BoxButton, Modal } from "./styled";
import { useParams } from "react-router-dom";

const ModalDeleteParticipant = ({ setSelectModal }) => {
  const { userForDelete, handleRemoveMember, setUserForDelete, createdTeamId } =
    useTeams();
  const { team_id } = useParams();

  useEffect(() => {
    return () => {
      setUserForDelete({
        name: "",
        id: ""
      });
    };
  }, []);

  return (
    <Modal>
      <TitleH5 title="Excluir membro da equipe" />

      <PlainText>
        Tem certeza que você quer excluir {userForDelete.name} ?
      </PlainText>
      <BoxButton>
        <ButtonMaxWidth
          onClick={() => {
            setSelectModal({ modal: "Add Participant", isOpen: true });
          }}
          typeStyle="purple"
          text="Não"
        />
        <ButtonMaxWidth
          onClick={async () => {
            await handleRemoveMember(
              userForDelete.id,
              team_id ? team_id : createdTeamId
            );
          }}
          typeStyle="white"
          text="Sim"
        />
      </BoxButton>
    </Modal>
  );
};

export default ModalDeleteParticipant;
