import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 25px;
  height: 24px;
  fill: currentColor;
`;

type Props = {
  color?: string;
};

const ThreeDotsIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 25 24" color={color || "#663399"}>
    <path
      d="M8.99147 12.6091C8.99147 11.6719 8.21044 10.8909 7.2732 10.8909C6.31644 10.8909 5.55493 11.6719 5.55493 12.6091C5.55493 13.5464 6.31644 14.3274 7.2732 14.3274C8.21044 14.3274 8.99147 13.5464 8.99147 12.6091ZM14.2634 12.6091C14.2634 11.6719 13.4824 10.8909 12.5452 10.8909C11.6079 10.8909 10.8269 11.6719 10.8269 12.6091C10.8269 13.5464 11.6079 14.3274 12.5452 14.3274C13.4824 14.3274 14.2634 13.5464 14.2634 12.6091ZM19.5549 12.6091C19.5549 11.6719 18.7934 10.8909 17.8367 10.8909C16.8994 10.8909 16.1184 11.6719 16.1184 12.6091C16.1184 13.5464 16.8994 14.3274 17.8367 14.3274C18.7934 14.3274 19.5549 13.5464 19.5549 12.6091Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default ThreeDotsIcon;
