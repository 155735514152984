import { Container } from "./styles";

const ButtonOptions = ({ setIsOpen }) => {
  return (
    <Container onClick={() => setIsOpen(prev => !prev)}>
      <span />
      <span />
      <span />
    </Container>
  );
};

export default ButtonOptions;
