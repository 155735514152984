import React, { ReactNode, useRef } from "react";
import { IMaskMixin } from "react-imask";
import { StyledInput, Container } from "./styles";
import IconAlert from "../../assets/icon-alert.svg";

import { InputHTMLAttributes } from "react";

// import { Container } from './styles';
interface MaskedInputProps extends InputHTMLAttributes<HTMLInputElement> {
  maskOptions: IMask.AnyMaskedOptions;
  inputRef: React.MutableRefObject<any>;
  id: string;
  error: boolean;
  myId: string;
  label: string;
}

const InputMask = IMaskMixin(
  ({ inputRef, error, myId, label, ...props }: any) => (
    <Container>
      <label>{label}</label>

      <div>
        <StyledInput
          {...props}
          ref={inputRef}
          id={myId}
          error={error}
          // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
        />
        {error && <img src={IconAlert} alt="ícone de alerta" />}
      </div>
    </Container>
  )
);

export default InputMask;
