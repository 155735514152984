import React, { ButtonHTMLAttributes } from "react";
import { Button } from "./styles";

type Props = {
  action: "advance" | "cancel";
  isDisabled?: boolean;
  isChecked?: boolean;
  isDone?: boolean;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type">;

const ButtonSecure: React.FC<
  Props & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ action, isDisabled, isChecked, isDone, ...rest }) => {
  return (
    <Button
      isDisabled={isDisabled}
      disabled={isDisabled}
      action={action}
      type="button"
      {...rest}
    >
      {action === "cancel"
        ? "Cancelar"
        : isChecked
        ? "Salvei meu código de backup"
        : isDone
        ? "Concluir"
        : "Continuar"}
    </Button>
  );
};

export default ButtonSecure;

