export const VectorOfColors = [
  "#000",
  "#545454",
  "#737373",
  "#A6A6A6",
  "#D9D9D9",
  "#FFFFFF",
  "#FF1616",
  "#FF5757",
  "#FF66C4",
  "#CB6CE6",
  "#8C52FF",
  "#5E17EB",
  "#03989E",
  "#00C2CB",
  "#5CE1E6",
  "#38B6FF",
  "#5271FF",
  "#004AAD",
  "#008037",
  "#7ED957",
  "#C9E265",
  "#FFDE59",
  "#FFBD59",
  "#FF914D"
];
