import React from "react";

interface BoldProps {
  isActive: boolean;
}

const Bold: React.FC<BoldProps> = ({ isActive }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="healthicons:n">
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 9.29423V18.5322C8 18.7974 7.89464 19.0518 7.70711 19.2393C7.51957 19.4269 7.26522 19.5322 7 19.5322C6.73478 19.5322 6.48043 19.4269 6.29289 19.2393C6.10536 19.0518 6 18.7974 6 18.5322V6.53223C6.00009 6.32679 6.06346 6.12636 6.18148 5.9582C6.2995 5.79004 6.46645 5.66231 6.65962 5.59239C6.8528 5.52246 7.06282 5.51373 7.26114 5.56737C7.45945 5.62102 7.63643 5.73445 7.768 5.89223L16 15.7702V6.53223C16 6.26701 16.1054 6.01266 16.2929 5.82512C16.4804 5.63758 16.7348 5.53223 17 5.53223C17.2652 5.53223 17.5196 5.63758 17.7071 5.82512C17.8946 6.01266 18 6.26701 18 6.53223V18.5322C17.9999 18.7377 17.9365 18.9381 17.8185 19.1063C17.7005 19.2744 17.5336 19.4021 17.3404 19.4721C17.1472 19.542 16.9372 19.5507 16.7389 19.4971C16.5406 19.4434 16.3636 19.33 16.232 19.1722L8 9.29423Z"
          fill={isActive ? "#663399" : "#343A40"}
        />
      </g>
    </svg>
  );
};

export default Bold;
