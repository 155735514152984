import styled from "styled-components";

export const Subtitle = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: 700;
`;

export const Label = styled.label`
  color: #676b70;
  font-weight: 400;
  font-size: 13px;
`;
export const RequirementLabel = styled.label`
  color: #343a40;
  font-weight: 400;
  font-size: 13px;
`;
export const SaveButton = styled.button`
  padding: 12px 16px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  cursor: pointer;
  width: 100%;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
`;

export const CancelButton = styled.button`
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  &:hover {
    box-shadow: 0 0 4px 0px ${({ theme }) => theme.colors.primary};
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  min-width: 100px;
`;
export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  width: 100%;
  background-color: white;
  padding: 10px;
`;

export const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid #ebebec;
`;

export const RequirementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
`;

export const Requirement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #ebebec;
  padding: 10px;
  border-radius: 5px;
`;
export const RequirementHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #ebebec;
  padding-bottom: 10px;
`;
export const RequirementContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StatusLabel = styled.span<{ status: string }>`
  color: ${({ status }) =>
    status === "APPROVED"
      ? "#007B36"
      : status === "REFUSED"
      ? "#DE2D2D"
      : "#ffc107"};
  font-weight: 500;
  font-size: 14px;
`;
