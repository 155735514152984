import html2canvas from "html2canvas";
import { cloneDeep } from "lodash";
import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";
import { useMainHook } from "../../../hooks/main";
import { changeSelectedObject } from "../utils/changeSelectedObject";
import { usePagesEditor } from "./pagesEditor";
import { useSelection } from "./selection";
import { KonvaEventObject } from "konva/lib/Node";
import { useDiff } from "./diffDocuments";
import * as QuillText from "quill";
import Konva from "konva";
import { useAuth } from "../../../hooks/auth";

interface TextsEditionProviderProps {
  children: ReactNode;
}
interface TextsEditionData {
  isEditing: "closed" | "blocked" | "open";
  setIsEditing: React.Dispatch<
    React.SetStateAction<"closed" | "blocked" | "open">
  >;
  toggleCloseEditing: () => void;
  quillRef: MutableRefObject<ReactQuill>;
  textRich: MutableRefObject<string>;
  // setTextRich: React.Dispatch<React.SetStateAction<string>>;
  handleClickApplyTextEditor: () => void;
  requestTextUpdate?: (text?: string) => void;
  handleChangeText?: () => void;
  colorText?: string;
  setColorText?: React.Dispatch<React.SetStateAction<string>>;
  blockingKeyboard: boolean;
  setBlockingKeyboard: React.Dispatch<React.SetStateAction<boolean>>;
  quillRefComponent?: React.MutableRefObject<ReactQuill>;
  hasBlockText: React.MutableRefObject<boolean>;
  handleDblClick: (event: KonvaEventObject<MouseEvent>) => void;
  rectangule: React.MutableRefObject<Konva.Rect>;
  blockDblClick: boolean;
  setBlockDblClick: React.Dispatch<React.SetStateAction<boolean>>;
  clickTimeoutRef: React.MutableRefObject<any>;
  currentQuillFormats: QuillText.StringMap;
  setCurrentQuillFormats: React.Dispatch<
    React.SetStateAction<QuillText.StringMap>
  >;
  selectionQuill: QuillText.RangeStatic;
  setSelectionQuill: React.Dispatch<
    React.SetStateAction<QuillText.RangeStatic>
  >;
}

const textsEditionContext = createContext<TextsEditionData>(
  {} as TextsEditionData
);

const TextsEditionProvider: React.FC<TextsEditionProviderProps> = ({
  children,
}) => {
  const {
    objectScreen,
    setObjectScreen,
    hasGroupShared,
    nameOfCurrentUser,
    stageRef,
  } = useMainHook();
  const rectangule = useRef<Konva.Rect>(null);
  const [blockDblClick, setBlockDblClick] = useState(false);
  const { whichUserEdited } = useDiff();
  const { idPage } = usePagesEditor();
  const [isEditing, setIsEditing] = useState<"closed" | "blocked" | "open">(
    "closed"
  );
  const [currentQuillFormats, setCurrentQuillFormats] =
    useState<QuillText.StringMap>(null);

  const [selectionQuill, setSelectionQuill] =
    useState<QuillText.RangeStatic | null>(null);

  const {
    selectedObject,
    setSelectedObject,
    setSelectedObjects,
    currentMultipleSelection,
  } = useSelection();
  const quillRef = useRef<ReactQuill>(null);
  const quillRefComponent = useRef<ReactQuill>(null);
  const [dataURL, setDataUrl] = useState("");
  const textRich = useRef("");
  const [blockingKeyboard, setBlockingKeyboard] = useState(false);
  const hasBlockText = useRef(false);
  const [resetBorderStroke, setResetBorderStroke] = useState(false);
  const clickTimeoutRef = useRef<number | null | any>(null);

  const { data } = useAuth();

  const handleDblClick = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      clearTimeout(clickTimeoutRef.current);
      // event.preventDefault();

      if (blockDblClick) return;
      const positionClick = event.currentTarget.getRelativePointerPosition();
      const myId = uuidv4();
      setObjectScreen((oldState) => {
        let cloneState = cloneDeep(oldState);
        let myNewText = {
          x: positionClick.x,
          y: positionClick.y,
          id: myId,
          height: 20,
          width: 206.01767901693316,
          page: String(idPage),
          object: "richTextImage",
          draggable: true,
          textHTML: "",
          src: "",
          isModify: hasGroupShared.current ? true : false,
          nameOfUserEdited: nameOfCurrentUser.current,
          idOfUserEdited: data?.user?.id,
          isSharedNewText: true,
          format: {
            ops: [
              {
                attributes: {
                  color: "#000",
                  size: "12px",
                  font: "Open Sans",
                },
                insert: " \n",
              },
            ],
          },
        };
        cloneState[idPage - 1].renderObjects.push(myNewText);
        return cloneState;
      });

      if (myId) {
        const stageObject = event.currentTarget?.getStage();
        const result = stageObject?.find(`#${myId}`);
        const ShapeResult: any = result[0];
        setSelectedObject(ShapeResult);
        rectangule?.current?.fire("dblclick");
        setTimeout(() => {
          if (quillRefComponent.current) {
            quillRefComponent.current?.focus();
          }
        }, 500);
      }
    },
    [
      blockDblClick,
      setObjectScreen,
      idPage,
      hasGroupShared,
      nameOfCurrentUser,
      setSelectedObject,
      quillRefComponent,
      data,
    ]
  );

  const toggleCloseEditing = useCallback(() => {
    if (isEditing === "closed") {
      setIsEditing("open");
    } else {
      setIsEditing("closed");
    }

    setSelectedObject(null);
  }, [isEditing]);

  const renderText = () => {
    // convert DOM into image
    html2canvas(document.querySelector(".ql-editor"), {
      backgroundColor: "rgba(0,0,0,0)",
    }).then((canvas) => {
      // show it inside Konva.Image
      setDataUrl(canvas.toDataURL());
    });
  };

  // batch updates, so we don't render text too frequently
  let timeout = null;
  const requestTextUpdate = (text?: string) => {
    if (timeout) {
      return;
    }
    timeout = setTimeout(function () {
      timeout = null;
      renderText();
    }, 200);
  };

  useEffect(() => {
    // make initial rendering
    renderText();
  }, []);
  //apply changes when user click on button of accept/reject
  //modifications inside our document when was shared
  const handleClickApplyTextEditor = useCallback(() => {
    const quillEditor = quillRef?.current?.getEditor();
    const text = quillEditor?.getText();
    const format = quillEditor?.getContents();
    requestTextUpdate();

    if (selectedObject) {
      changeSelectedObject({
        selectedObject,
        setObjectScreen,
        propertie: "src",
        value: dataURL,
      });
      changeSelectedObject({
        selectedObject,
        setObjectScreen,
        propertie: "text",
        value: text,
      });

      changeSelectedObject({
        selectedObject,
        setObjectScreen,
        propertie: "format",
        value: format,
      });

      // const findObj = objectScreen[idPage - 1].renderObjects.findIndex(object => object.id === idObj)
    } else {
      setObjectScreen((state: any) => {
        const cloneState = cloneDeep(state);
        cloneState[idPage - 1].renderObjects.push({
          idPage: idPage,
          object: "richTextImage",
          id: uuidv4(),
          draggable: true,
          src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAArCAYAAACzfkyLAAAAAXNSR0IArs4c6QAABAhJREFUeF7t2lmodnMUx/HPi5RwYSqRISVJSXFlzFC4QKSUsZAoZMpM5nnK7MKQKUlx5UbKkKIo8xC5MGV2YUwZ+tV6avd0Tu/unPO8T3v33/X21rP/+/9f//Vdv99aF2eV9ow6A6tGfbt2OQ3wyIugAW6AR56BkV+vKbgBHnkGRn69puAGeOQZGPn1moIb4JFnYOTXawpugEeegZFfrym4AR55BkZ+vabgBnjkGVj4envgOJyNP4ecgeUq+DJctUgCjsUTS0jODrgCp+OnJXy/Ep80wAtkMbA/XyLU7nYN8EqUaO2xXAV3Q1kI8Ho4pawua6/Hg9i7fj8DP+Jk7Ij38FBn0z3xBo7ExdgST+NKfDuVh5x1O77AQdgFj5Qb/IxuLBviLtyM3+vdWTgPH+AVbFpxb40b8C4OLuvOXpfjcLyK8/F6xbNVxZeYP8RNeA7/riC33lvNEnD2PhNR5AVYH7fgYbyMC/FdJfPWSuantb5r0YfhBARA1sf6d8M5U/1xAji2fh3Wwo34rMCfiF0rliToNrxUjnMMDihQv3XApgcHcFrNA3i2iuHe2jPf71ftJEX6D+7D83iyvs2d76h79gazUgtnCXgj3I9rq/oTcxK5Ha4uNd6NDUrVT9WluhYddQV+FPBCvc++d5b6oqrJMwH8GF6rH3fGuVVoSX7+TYam47FN7TN9RrcHB3DmjMwEP2CdKtZfS5WbIfeIohPbabU27/McUQUZh8v5a/SZJeBNqvIPnLpRqj9J/wtR1aH1f2w0TxfwH6WULrSFQOa7hX7v7vVfqT4OELB5AiUKi7V3z5gG3HWU5Gzfahn71z7v4Kiy9enpe64D2ywBp5rT567BxwuU7balzrXxTBVDIPRRcGww/fOtngqO1cbiU0Sx2qh44ibZZ3UK7gLeqaw//foTbFwKzprFFJxZIi3p7zUqX1b0ryqnh6yAS59MAjJoxLIOqSRnIIl1v112GhVdio8KcIaaDGBfoduDv8HR2KdsNxY+bdEZvgJt0oMDIa0iA14s/XHEVjOofVntYnU9uAs4ffyS6tNf1+CVGeMkfD/Vg7eo/nsPXlzTcHPeLBU8sc3JFJ3JNYmOWjIkxZ7Tr34pNe1eQ06mzfS89MjAfXMZU3TaQYoryt2+lJfJN5P4+2XrKcx1cWoVWaA9is2Rd+nBXcDpwYF5UQFL/92r1qaARjtFz6NAu2cu1pvnHddcz19JBc/1IosMWfOOae7nN8BzRzDbAMYEeLaZGujuDfBAwfUNuwHum6mBrmuABwqub9gNcN9MDXRdAzxQcH3DboD7Zmqg6xrggYLrG3YD3DdTA13XAA8UXN+wG+C+mRrougZ4oOD6ht0A983UQNc1wAMF1zfs/wF23fksTeJF3gAAAABJRU5ErkJggg==",
          x: 20,
          y: 20,
          text: "texto padrao",
          format: {},
          rectWidth: 150,
          rectHeight: 50,
        });

        return cloneState;
      });
    }
  }, [objectScreen, dataURL, idPage, selectedObject]);

  return (
    <textsEditionContext.Provider
      value={{
        isEditing,
        setIsEditing,
        toggleCloseEditing,
        quillRef,
        textRich,
        handleClickApplyTextEditor,
        blockingKeyboard,
        setBlockingKeyboard,
        quillRefComponent,
        hasBlockText,
        handleDblClick,
        rectangule,
        blockDblClick,
        setBlockDblClick,
        clickTimeoutRef,
        currentQuillFormats,
        setCurrentQuillFormats,
        selectionQuill,
        setSelectionQuill,
        // requestTextUpdate
      }}
    >
      {children}
    </textsEditionContext.Provider>
  );
};

// creating hook

function useTextsEdition(): TextsEditionData {
  const context = useContext(textsEditionContext);

  if (!context) {
    throw new Error("useAuth must be used with an textsEditionProvider");
  }

  return context;
}

export { TextsEditionProvider, useTextsEdition };

