import styled from "styled-components";

export const Container = styled.div`
  width: 100dvw;
  height: 100dvh;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BoxModal = styled.div`
  width: 800px;
  height: 500px;
  display: flex;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 5px;
  flex-direction: column;
`;

export const TextQrCode = styled.div`
  color: #5d5fef;
  font-size: 13px;
  font-family: Ubuntu, sans-serif;
  font-style: italic;
  margin-top: 1.8rem;
  cursor: pointer;
`;

export const Text = styled.div`
  font-size: 14px;
  font-family: Ubuntu, sans-serif;
  margin: 0 auto;
  margin-top: 1.2rem;
  line-height: 150%;
  color: #343a40;

  span {
    color: #5d5fef;
    font-style: italic;
    line-height: 150%;
  }
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  background: #fff;
  justify-content: flex-start;
  flex-shrink: 1;
  margin-top: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  gap: 20px;
  padding: 0 20px;
  align-items: center;
  border-top: 1px solid #ebebec;
`;

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`;

export const ViewMainContent = styled.div`
  width: 800px;
  height: auto;
  /* background-color: yellow; */
  display: flex;
  margin-top: 1rem;
  padding: 0 17rem;
  padding-top: 2rem;
`;

export const ViewMain = styled.div`
  width: 800px;
  height: auto;
  /* background-color: yellow; */
  display: flex;
  margin-top: 1rem;
  align-items: center;
  flex-direction: column;
`;

export const IOSView = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  /* background-color: blue; */
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

export const AndroidView = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  /* background-color: brown; */
  flex-direction: column;
  align-items: center;

  padding: 1rem;
`;

export const QRCodeBox = styled.div`
  width: 188px;
  height: 188px;
  display: flex;
  background-color: #fff;
  margin-top: 1.2rem;
  border-radius: 5px;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
`;

