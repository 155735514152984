import Konva from "konva";
import { cloneDeep } from "lodash";
import { memo, useEffect, useRef, useState } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";
import { IRenderedObject } from "../../../../../dtos/RenderedObject";
import { PagesProps } from "../../../../../dtos/PagesProps";

type Props = {
  onChange?: (newAttrs: IRenderedObject) => void;
  selectedObject?: Konva.Path | IRenderedObject | Konva.Image | any;
  shapeProps?: Konva.Path | IRenderedObject | Konva.Image | any;
  setSelectedObject?:
    | React.Dispatch<React.SetStateAction<Konva.Image | Konva.Group>>
    | any;
  addToHistory?: (isLastHistory?: boolean) => void;
  currentMultipleSelection?: React.MutableRefObject<Konva.Transformer>;
  setObjectScreen?: React.Dispatch<React.SetStateAction<PagesProps[]>>;
  transformerSelectedObject?: React.MutableRefObject<Konva.Transformer>;
  handleRemoveElementGroup?: (id: string) => void;
  pendingSave?: any;
  setPageObject?: React.Dispatch<React.SetStateAction<number>>;
  isDraggingObject?: boolean;
  setIsDraggingObject?: React.Dispatch<React.SetStateAction<boolean>>;
};

const URLImage = ({
  shapeProps,
  selectedObject,
  setSelectedObject,
  addToHistory,
  // setObjectScreen,
  currentMultipleSelection,
  transformerSelectedObject,
  handleRemoveElementGroup,
  // pendingSave,
  onChange,
  setPageObject,
  isDraggingObject,
  setIsDraggingObject,
}: Props) => {
  const [isSelected, setIsSelected] = useState(false);
  const [image] = useImage(shapeProps.src);
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();
  const [shiftPressed, setShiftPressed] = useState(false);

  useEffect(() => {
    if (shapeRef.current === selectedObject) {
      setIsSelected(true);
      transformerSelectedObject.current = trRef.current;
    } else {
      setIsSelected(false);
    }
  }, [selectedObject, shapeRef.current]);

  useEffect(() => {
    trRef.current?.setNodes([shapeRef.current]);
    trRef.current?.setZIndex(trRef.current.getParent().children.length - 1);
    trRef.current?.getLayer().batchDraw();
  }, [isSelected]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <>
      <Image
        key={shapeProps.id}
        image={image}
        ref={shapeRef}
        name="URLimage"
        {...shapeProps}
        onClick={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
          const isTargetInsideMultipleSelection =
            currentMultipleSelection.current?.nodes().includes(event.target);

          if (isTargetInsideMultipleSelection) {
            setSelectedObject(null);

            if (shiftPressed) {
              handleRemoveElementGroup(event.target.attrs.id);
              return;
            }
          } else {
            setSelectedObject(event.target as Konva.Image);
            currentMultipleSelection.current?.setNodes([]);
          }
        }}
        onDragStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
          if (
            currentMultipleSelection.current?.nodes().length === 0 ||
            !currentMultipleSelection.current
          ) {
            addToHistory();
          }

          setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
          setIsDraggingObject(true);

          //if mutipleSelection includes element being dragged
          if (
            currentMultipleSelection.current?.nodes().includes(event.target)
          ) {
            setSelectedObject(null);
          } else {
            setSelectedObject(event.target as Konva.Image);
            currentMultipleSelection.current?.setNodes([]);
          }
        }}
        onDragEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setIsDraggingObject(false);
          onChange({
            ...shapeProps,
            x: event.target.absolutePosition().x,
            y: event.target.absolutePosition().y,
          });
        }}
      />
      {isSelected && (
        <Transformer
          onTransformStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
            if (
              currentMultipleSelection.current?.nodes().length === 0 ||
              !currentMultipleSelection.current
            ) {
              addToHistory();
            }
          }}
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          onTransformEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
            let myWidth = trRef?.current?.width();
            let myHeight = trRef?.current?.height();
            let rotation = trRef?.current.rotation();

            if (myWidth && myHeight) {
              shapeRef.current.width(myWidth);
              shapeRef.current.height(myHeight);

              shapeRef.current.scaleX(1);
              shapeRef.current.scaleY(1);

              onChange({
                ...shapeProps,
                width: myWidth,
                height: myHeight,
                scaleX: 1,
                scaleY: 1,
                x: event.target.absolutePosition().x,
                y: event.target.absolutePosition().y,
                rotation: rotation,
              });
            }
          }}
        />
      )}
    </>
  );
};

export default memo(URLImage);
