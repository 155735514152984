import React from "react";
interface LeftAlignProps {
  isActive: boolean;
}
// import { Container } from './styles';

const LeftAlign: React.FC<LeftAlignProps> = ({ isActive }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mingcute:align-left-line">
        <path
          id="Vector"
          d="M14.2222 19.9196C14.5054 19.9199 14.7778 20.0284 14.9837 20.2228C15.1897 20.4172 15.3136 20.6829 15.3302 20.9656C15.3468 21.2483 15.2548 21.5267 15.073 21.7439C14.8913 21.961 14.6334 22.1006 14.3522 22.134L14.2222 22.1418H3.11111C2.82791 22.1415 2.55552 22.0331 2.34959 21.8387C2.14366 21.6442 2.01974 21.3785 2.00314 21.0958C1.98655 20.8131 2.07853 20.5347 2.26029 20.3176C2.44206 20.1004 2.69989 19.9608 2.98111 19.9274L3.11111 19.9196H14.2222ZM20.8889 14.364C21.1836 14.364 21.4662 14.4811 21.6746 14.6895C21.8829 14.8979 22 15.1805 22 15.4752C22 15.7698 21.8829 16.0525 21.6746 16.2608C21.4662 16.4692 21.1836 16.5863 20.8889 16.5863H3.11111C2.81643 16.5863 2.53381 16.4692 2.32544 16.2608C2.11706 16.0525 2 15.7698 2 15.4752C2 15.1805 2.11706 14.8979 2.32544 14.6895C2.53381 14.4811 2.81643 14.364 3.11111 14.364H20.8889ZM14.2222 8.80849C14.5054 8.8088 14.7778 8.91724 14.9837 9.11165C15.1897 9.30606 15.3136 9.57177 15.3302 9.85448C15.3468 10.1372 15.2548 10.4156 15.073 10.6327C14.8913 10.8499 14.6334 10.9895 14.3522 11.0229L14.2222 11.0307H3.11111C2.82791 11.0304 2.55552 10.922 2.34959 10.7275C2.14366 10.5331 2.01974 10.2674 2.00314 9.98471C1.98655 9.702 2.07853 9.42362 2.26029 9.20645C2.44206 8.98928 2.69989 8.84971 2.98111 8.81626L3.11111 8.80849H14.2222ZM20.8889 3.25293C21.1721 3.25324 21.4445 3.36168 21.6504 3.55609C21.8563 3.7505 21.9803 4.01621 21.9969 4.29892C22.0135 4.58164 21.9215 4.86002 21.7397 5.07719C21.5579 5.29436 21.3001 5.43393 21.0189 5.46737L20.8889 5.47515H3.11111C2.82791 5.47484 2.55552 5.3664 2.34959 5.17199C2.14366 4.97758 2.01974 4.71187 2.00314 4.42916C1.98655 4.14644 2.07853 3.86806 2.26029 3.65089C2.44206 3.43372 2.69989 3.29416 2.98111 3.26071L3.11111 3.25293H20.8889Z"
          fill={isActive ? "#663399" : "#343A40"}
        />
      </g>
    </svg>
  );
};

export default LeftAlign;
