import styled from "styled-components";

export const Container = styled.header`
  grid-area: 1 / 2 / 2 / 6;

  width: 100%;
  height: 100%;
  border-bottom: 1px solid #ebebec;
  padding-inline: 5px 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    width: 67px;
    height: 30px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      background-color: #ebebec;
      border-radius: 5px;
    }

    :active {
      color: black;
    }
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;
