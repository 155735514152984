import { createElement } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { createGlobalStyle } from "styled-components";

interface Theme {
  theme: {
    name: string;
    colors: {
      primary: string;
      black: string;
      background: string;
      border: string;
      gray900: string;
      gray300: string;
      gray100: string;
      whiteColor: string;
      black10: string;
    };
  };
}

const reactSvgComponentToMarkupString = (Component, props) =>
  `data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(createElement(Component, props))
  )}`;

const GlobalStyle = createGlobalStyle`


  strike {
    text-decoration: line-through !important;
  }  

  /* Adicionar um espaçamento extra entre as linhas */
.line2 {
  line-height: 1.5em; /* ou qualquer outro valor adequado */
}

.react-switch-handle {
  width: 18px !important;
  height: 18px !important;
}




  /* ::-webkit-scrollbar {
    width: 80%;
    
  } */

  
/* @font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}


@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
} */




    * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: "Ubuntu", sans-serif;
    -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;

   input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
  

  ul, li {

    list-style: none;
  }

  .quillRefMirrorClass {
    position: relative;
  
 

    .ql-container.ql-snow {
      border: none !important;
      /* background-color: red !important; */
      


    
    }
   
    
  }


  button {
    cursor: pointer;
    border: none;
  }

  body {
    overflow-x: hidden;
    /* overflow: hidden; */
    background: ${(props: Theme) => props.theme.colors.whiteColor};
    -webkit-font-smoothing: antialiased;

  }
  h1 {
    width: 100%;
    margin: 50px 0;
    text-align: center;
    color: ${(props) => props.theme.colors.black};
  }

  html {
    font-size: 10px;
 
  }

  ::-webkit-scrollbar {
    width: 10px;
    
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.black10}!important;
    padding: 1px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a5a5a5;
    border-radius: 100px;
    border: 2px solid ${({ theme }) => theme.colors.black10};
  }

   font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  @media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

/* .quilljs {
  background: red !important;
} */

  .quillRefMirrorClass {
     .ql-editor > * {
      cursor: text;
     
}
  }




 

  
  .react-modal-content {
    
    background: ${({ theme }) => theme.colors.gray100};
    display: flex;
    z-index: 99;
    align-items: center;
    position: relative;
  }

  .react-modal-content2 {
    background: ${({ theme }) => theme.colors.gray100};
    display: flex;
    z-index: 99;
    align-items: center;
   
  }

  //for loading initial app

  .loading-application {
    width: 100vw;
    height: 100vh;
    background:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
//hidden scroll templates carrousel
/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

  //for loading avatar
  .loading-spinner {
  width: 20px;
  height: 20px;
  border: 2.5px solid #000; 
  border-top: 2.5px solid ${({ theme }) => theme.colors.primary}; 
  border-radius: 50%;
  animation: spinner 520ms linear infinite;
}

  .react-modal-overlay-description {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
     width: 100%;
    max-width: 100%;
  }

  .react-modal-content-description {
    display: flex;
    z-index: 99;
    position: relative;
  }

  .react-modal-overlay-versioning {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow-y:auto;
    width: 100%;
    height: 100%;
    transition: all 2s linear;
    padding-top: 2rem;
    
  }

  .react-modal-content-versioning {
    display: flex;
    z-index: 99;
    position: relative;
  }

  .exit-icon {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
 
  }



  .react-modal-overlay2  {

    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow-y:auto;
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    margin: 0 auto;
    align-items: center;
 
  }

    .react-modal-overlay-without-preview  {

    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
  
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
 
  }

  
  .konvashape-icon {
      width: 80px;
      height: 80px;
    }

  @media (max-width: 1600px) {
    .icon-blank {
    width: 37px;
    height: 37px;
  }
  }

    @media (max-width: 1366px) {
    .icon-blank {
    width: 32px;
    height: 32px;
  }

  .dots-content {
    margin-left: -3rem !important;
  }
  }

  .controlsStyle {
    background: red;
  }

  .controlsClass {
    background: red;
  }

  .onfocus-pages {
    border: 3px solid ${({ theme }) => theme.colors.primary};
  }

  .close-button-preview-mode {
    position: fixed;
    top: 20px;
    right: 35px;
    width: 22px;
    height: 22px;
  }

  .width-lock-icon {
      width: 30px;
      height: 30px;
  }

  


//icons svg control media query

  @media (max-width: 1602px) {


    

    .width-icon-strong {
      width: 27px;
      height: 27px;
    }
    
    .width-icon-italic {
      width: 25px;
      height: 25px;
    }

    .width-icon-underline {
      width: 27px;
      height: 27px;
    }

    .width-icon-casetext {
      width: 25px;
      height: 25px;
    }

    .width-icon-align {
      width: 22px;
      height: 22px;
    }

    .width-icon-textheight {
      width: 22px;
      height: 22px;
    }

    .width-unlock-icon {
      width: 22px;
      height: 22px;
    }
    .width-block-icon {
      width: 23px;
      height: 23px;
    }

    .width-spam-icon {
      width: 26px;
      height: 26px;
    }

    .width-icon-stroke {
      width: 25px;
      height: 25px;
    }
    
    .width-icon-dash {
      width: 26px;
      height: 26px;
    }

    

    .width-icon-underline {
      width: 27px;
      height: 27px;
    }

    .arrow-moveup-icon {
      width: 15px;
      height: 15px;
    }

    .arrow-add-page-icon {
      width: 26px;
      height: 26px;
    }


    .width-lock-icon {
      width: 28px;
      height: 28px;
    }


    .all-to-front {
    width: 30px;
    height: 30px
  }

   .all-to-back {
    width: 30px;
    height: 30px
  }

  .opacity-btn {
    width: 28px;
    height: 28px
  }
   

  }

  @media (max-width: 1366px) {


    

    .width-icon-strong {
      width: 24px;
      height: 24px;
    }
    
    .width-icon-italic {
      width: 22px;
      height: 22px;
    }

    .width-icon-underline {
      width: 24px;
      height: 24px;
    }

    .width-icon-casetext {
      width: 22px;
      height: 22px;
    }

    .width-icon-align {
      width: 19px;
      height: 19px;
    }

    .width-icon-textheight {
      width: 19px;
      height: 19px;
    }

    .width-spam-icon {
      width: 23px;
      height: 23px;
    }

    .width-unlock-icon {
      width: 19px;
      height: 19px;
    }
    .width-block-icon {
      width: 24px;
      height: 24px;
    }


      .width-icon-stroke {
        width: 20px;
        height: 20px;
      }

         
    .width-icon-dash {
      width: 20px;
      height: 20px;
    }
    
    

    .arrow-moveup-icon {
      width: 14px;
      height: 14px;
    }
      //icon move up first page disable button
    .blocked-icon {
      width: 14px;
      height: 14px;
    }
    
    .arrow-add-page-icon {
      width: 22px;
      height: 22px;
    }

     .width-lock-icon {
      width: 22px;
      height: 22px;
  }

  .all-to-front {
    width: 28px;
    height: 28px
  }

  .all-to-back {
    width: 28px;
    height: 28px;
  }

    .opacity-btn {
    width: 25px;
    height: 25px
  }



  }

  .width-icon-toolbar {
      width: 24px;
      height: 24px;
  }

  

  .icons-align-toolbar {
      width: 19px;
      height: 19px;
    }



// quill js configs css 
.ql-snow.ql-toolbar button.ql-active .ql-fill, 
.ql-snow.ql-toolbar button:hover .ql-fill
{
  fill: ${({ theme }) => theme.colors.primary};
}
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke
{
  stroke: ${({ theme }) => theme.colors.primary};
}

.ql-toolbar.ql-snow .ql-picker-label:hover {
  stroke: ${({ theme }) => theme.colors.primary};
  fill: ${({ theme }) => theme.colors.primary};
}

.ql-container, .ql-editor{
  height: auto;
}

span.ql-picker-item:hover {
    color: ${({ theme }) => theme.colors.primary} !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
 stroke: ${({ theme }) => theme.colors.primary}
}

polygon.ql-stroke {
  stroke: ${({ theme }) => theme.colors.primary} !important;
}

span.ql-picker-label:hover {
  line.ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary} !important;
  }
}

ruby-span.ql-picker-label:hover {
  polyline.ql-stroke:hover {
  stroke: ${({ theme }) => theme.colors.primary} !important;
}
}

// fetch the number of fontsize to show in toolbar

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

/*for font-family*/

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-font {
  width: 150px !important;
  white-space: nowrap;
}

span.ql-picker-label::before {
  color: black !important;
}

span.ql-picker-item:hover {
  line.ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary} !important;
  }
}

.ql-snow .ql-size .ql-picker-item:hover {
  color: ${({ theme }) => theme.colors.primary};
}

.ql-snow .ql-picker.ql-lineheight{
  width: 58px;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.0']::before {content: '1'; }
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.0']::before {
  content: "1";
  color: transparent;
  /* background: url("../assets/line-height-rich.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  position: absolute;
  width:50px !important;
  height: 100% !important; */


}

/* .ql-lineheight {
  display: inline-block !important;
} */

/* span.ql-lineheight.ql-picker {
  background: url("../assets/line-height-rich.svg") !important;
  background-size: contain !important;
  background-repeat: no-repeat;
  height: auto;
  z-index: 100;
} */

.ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
background-image: url("../assets/line-height-rich.svg") !important;
}.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.0']::before {content: '1.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.0']::before {content: '1.0' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.2']::before {content: '1.2';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.2']::before {content: '1.2' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before {content: '1.5';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before {content: '1.5' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.6']::before {content: '1.6';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.6']::before {content: '1.6' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.8']::before {content: '1.8';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.8']::before {content: '1.8' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.0']::before {content: '2.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.0']::before {content: '2.0' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.5']::before {content: '2.5';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.5']::before {content: '2.5' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.8']::before {content: '2.8';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.8']::before {content: '2.8' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3.0']::before {content: '3.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='3.0']::before {content: '3.0' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4.0']::before {content: '4.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='4.0']::before {content: '4.0' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='5.0']::before {content: '5.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='5.0']::before {content: '5.0' !important;}

div.ql-toolbar.ql-snow {
  background-color: white !important;
}


  

span.ql-picker-label.ql-active {
  .ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary} !important;
  }
}


.ql-picker-item [data-value=custom-color-bg] {
  background-color: red !important;
}

.ql-background .ql-picker-options [data-value=custom-color-bg] {
  background: transparent !important;
  width: 100% !important;
  height: 20px !important;
  text-align: center;
}

.ql-background .ql-picker-options [data-value=custom-color-bg]:before {
  content: 'Inserir cor';
}
.ql-background .ql-picker-options [data-value=custom-color-bg]:hover {
  border-color: transparent !important;
}

.ql-color .ql-picker-options [data-value=custom-color] {
  background: transparent !important;
  width: 100% !important;
  height: 20px !important;
  text-align: center;
}
.ql-color .ql-picker-options [data-value=custom-color]:before {
  content: 'Inserir cor';
}
.ql-color .ql-picker-options [data-value=custom-color]:hover {
  border-color: transparent !important;
}


.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {

}

/* html, body, div, canvas {
    margin: 0;
    padding: 0;
} */

body > div:last-child > span + img {
      display: inline !important;
    }

    img {
    display: initial !important;
}

//styled inputs for clauses modal
@media (max-width: 1366px) {
    .input-title {
        ::placeholder {
          font-size: 1.5rem;
        }
      }
}


.rc-slider-track {
  background-color: ${({ theme }) => theme.colors.primary} !important;
}

.rc-slider-handle {
  background-color: ${({ theme }) => theme.colors.primary} !important;
  border: solid 2px ${({ theme }) => theme.colors.primary} !important;
  opacity: 1 !important;
}

.rc-slider-rail {
  background: #C4C4C4 !important;
  border-color: transparent !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
box-shadow: "0 0 5px black" !important;
border-color: transparent !important;
}

.rc-slider-handle:hover {
box-shadow: "0 0 5px black" !important;
border-color: transparent !important;
}

.rc-slider-handle:focus {
box-shadow: "0 0 5px black" !important;
border-color: transparent !important;
}

  .rc-slider-handle:active {
box-shadow: 0 0 5px ${({ theme }) => theme.colors.primary} !important;
}

.responsive-loading {
  width: 80px; 
  height: 170px;
}

@media (max-width: 1366px) {
  .responsive-loading {
  width: 52px; 
  height: 110px;
}

  
}

.Toastify {
  font-size: 1.4rem !important;
}

@media (max-width: 1366px) {
  .Toastify {
  font-size: 1.3rem !important;
}
}

div.flexbox-fix:nth-of-type(3) {
  display: none !important;
  height:20px; 
}

div.flexbox-fix:nth-of-type(2) {
  height: 30px !important;
}

div.flexbox-fix:nth-of-type(2) {
  height: 40px !important;
}

.flexbox-fix:nth-of-type(1) {
  height: 100px !important;
}




`;

export default GlobalStyle;
