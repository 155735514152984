/*
these component render which tab the user are selected
and show it the right screen
*/

import React, { useEffect, useState } from "react";
import { useAsidebarEditor } from "../../hooks/asidebarEditor";
import ClausesView from "../ClausesView";
import TemplatesView from "../TemplatesView";
import Icons from "../IconsApi";
import VersioningProject from "../VersioningProject";
import ElementsKonva from "../ElementsKonva";
import AutomatizingTab from "../AutomatizingTab";
import MasterUploads from "../MasterUploads";
import CommentsView from "../CommentsView";
import TextsFormatsTab from "../TextsFormatsTab";
import VectorsImage from "../VectorsImage";
import OfflineElement from "../OfflineElement";
import { useMainHook } from "../../../../hooks/main";

const AsidebarComponents: React.FC = () => {
  //hooks are necessary to this page
  const { isActive, handleActiveButton, selectedTab, setSelectedTab } =
    useAsidebarEditor();
  const { isOffline } = useMainHook();
  //set up states
  const [search, setSearch] = useState("");
  //reset if tab was none to templates screen as initial screen
  useEffect(() => {
    if (selectedTab === "none") {
      setSelectedTab("templates");
    }
  }, [selectedTab]);

  //if user is offline , lock elements
  //It is being called here because it is necessary
  //to render this component in case the user is offline.
  if (isOffline) {
    return <OfflineElement />;
  }

  return (
    <>
      {selectedTab === "templates" && (
        <TemplatesView search={search} setSearch={setSearch} />
      )}
      {selectedTab === "clauses" && (
        <ClausesView
          isActive={isActive}
          handleActiveButton={handleActiveButton}
        />
      )}
      {/* {selectedTab === "groupElements" && <GroupElements />} */}
      {selectedTab === "elements" && <ElementsKonva />}
      {selectedTab === "icons" && <Icons />}

      {selectedTab === "text" && <TextsFormatsTab />}

      {selectedTab === "vectors" && <VectorsImage />}
      {selectedTab === "upload" && <MasterUploads />}
      {selectedTab === "comments" && <CommentsView />}
      {selectedTab === "controlOfVersions" && <VersioningProject />}
      {selectedTab === "automatizing" && <AutomatizingTab />}
    </>
  );
};

export default AsidebarComponents;
