import { animate, useMotionValue, motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import useComponentVisible from "../../../hooks/useComponentVisible";
import ButtonIcons from "../../../../../shared/components/ButtonIcons";
import { SketchPicker } from "react-color";
import IconSvg from "../../IconSvg";
import {
  ActiveColor,
  BoxColor,
  BoxColorInput,
  Container,
  ContainerInputColor,
  DropDownContainer,
  DropDownList,
  DropDownListContainer,
  Flex,
  Grid,
  GridHistoric,
  GridItem,
  HistoricColors,
  HorizontalStack,
  Span,
} from "./styles";

import DropDownHeader from "./components/DropDownHeader/DropDownHeader";

import iconButtonInputColors from "../../../assets/icon-button-colors.svg";
import iconTransparentColor from "../../../assets/icon-transparent-color.svg";
import { useToolbarEditor } from "../../../hooks/toolbarEditor";
import { useSelection } from "../../../hooks/selection";
import { cloneDeep } from "lodash";
import { useHeaderEditor } from "../../../hooks/headerEditor";

interface ButtonColorProps {
  newColorBorder: string | null;
  colorsDefault?: string[];
  colors?: [];
  colorsSelectVector?: string[];
  addNewColor?: any;
  handleColorPickerClick?: () => void;
  handleCloseColorPickerClick?: () => void;
  isDisplayColorPickerBorder?: boolean;
  setIsDisplayColorPicker?: any;
  handleChangeBorder?: any;
  handleInputChange?: any;
  randomColor?: boolean;
  setSelectedOption?: any;
  selectedOption?: any;
  isFavoriteColor?: any;
  isStrokeColor?: string;
  blockKeyboard?: boolean;
  setBlockKeyboard?: any;
  forceUpdate?: boolean;
  setForceUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ButtonBorderColor: React.FC<ButtonColorProps> = ({
  colorsDefault,
  newColorBorder,
  colors,
  addNewColor,
  handleColorPickerClick,
  handleColorPickerClickBorder,
  isDisplayColorPickerBorder,
  setIsDisplayColorPicker,
  handleChangeBorder,
  handleInputChange,
  randomColor,
  isFavoriteColor,
  colorsSelectVector,
  setSelectedOption,
  isStrokeColor,
  selectedOption,
  blockKeyboard,
  setBlockKeyboard,
  forceUpdate,
  setForceUpdate,
  ...rest
}: any) => {
  const list = {
    visible: {
      opacity: 1,
      y: 5,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
    hidden: {
      opacity: 0,
      y: -20,
      transition: {
        when: "afterChildren",
      },
    },
  };

  const { organizeHistoryColors, colorsHistory, delayOrganizeHistoryColors } = useHeaderEditor();
  const { selectedObject } = useSelection();
  const { setColorToElementBorder } = useToolbarEditor();

  const variants = {
    visible: (i) => ({
      opacity: 1,
      transition: {
        delay: i * 0.3,
      },
    }),
    hidden: { opacity: 0 },
  };

  const x = useMotionValue(0);

  useEffect(() => {
    const controls = animate(x, 100, {
      type: "spring",
      stiffness: 2000,
    });

    return controls.stop;
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [indexActiveColor, setIndexActiveColor] = useState<null | number>(null);
  const [colorSelect, setColorSelect] = useState(newColorBorder);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  const [isTransparent, setIsTransparent] = useState(false);

  const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  // const onOptionClicked = (value: string) => {
  //   setSelectedOption(value);
  //   setIsOpen(false);

  //   // TODO - it is missing to implement { ... }
  // };

  // const handleSetColorBorder = useCallback(
  //   (color: string) => {
  //     if (color == "transparent") {
  //       setIndexActiveColor(null);
  //     }
  //     setColorToElementBorder(color);
  //     addNewColor(color);
  //     setIsDisplayColorPicker(false);
  //   },
  //   [selectedObject]
  // );

  const handleSetColorBorder = useCallback(
    (color: string) => {
      if (color == "transparent") {
        setIndexActiveColor(null);
        setIsTransparent(true);
      }
      organizeHistoryColors(color)
      setColorToElementBorder(color);
      setColorSelect(color);
      addNewColor(color);
      setIsDisplayColorPicker(false);
      setIsTransparent(false);
    },
    [setColorToElementBorder, addNewColor, setIsDisplayColorPicker]
  );

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpen(false);
      setIsComponentVisible(true);
      setIsDisplayColorPicker(false);
    } else {
      return;
    }
  }, [isComponentVisible]);

  useEffect(() => {
    if (isOpen) {
      setBlockKeyboard(true);
    } else {
      setBlockKeyboard(false);
    }
  }, [isOpen, blockKeyboard]);

  const [withoutHex, setWithoutHex] = useState("");

  useEffect(() => {
    if (selectedObject) {
      let whichColor = selectedObject?.attrs?.stroke;
      let newIndex = colorsDefault.findIndex((color) => color === whichColor);
      setIndexActiveColor(newIndex);
    }
  }, [isOpen, selectedObject]);

  useEffect(() => {
    if (colorSelect?.charAt(0) !== "#") {
      setWithoutHex(colorSelect);
    } else {
      setWithoutHex(colorSelect.substring(1));
    }

    if (colorSelect == "transparent" || colorSelect === "#FFFFFF") {
      addNewColor("transparent");
    }
  }, [colorSelect]);

  return (
    <Container>
      <DropDownContainer ref={ref}>
        <DropDownHeader
          isOpen={isOpen}
          onClick={handleClick}
          refVisible={ref}
          {...rest}
          newColor={withoutHex}
        />

        {isOpen && isComponentVisible && (
          <DropDownListContainer>
            <DropDownList
              variants={list}
              initial="hidden"
              animate="visible"
              as={motion.ul}
            >
              <Flex>
                <Grid>
                  {colorsDefault.map((color: string, index: number) => (
                    <GridItem key={Math.random()}>
                      <div className="wrapper-border">
                        <BoxColor
                          onClick={(e) => {
                            handleSetColorBorder(color);
                            setIndexActiveColor((state) => {
                              let cloneState = cloneDeep(state);
                              cloneState = index;
                              return cloneState;
                            });
                          }}
                          value={color}
                          color={color}
                        />

                        {indexActiveColor === index && <ActiveColor />}
                      </div>
                    </GridItem>
                  ))}

                  <button style={{ display: "flex" }}>
                    <div className="wrapper-border">
                      <img
                        src={iconTransparentColor}
                        onClick={(e) => {
                          handleSetColorBorder("transparent");
                          setIsTransparent((prevState) => !prevState);
                        }}
                        color={"transparent"}
                        className="button-transparent"
                        alt=""
                        style={{ display: "flex" }}
                      />

                      {isTransparent && <ActiveColor />}
                    </div>
                  </button>
                </Grid>
                <HorizontalStack>
                  <BoxColor value={colorSelect} color={colorSelect} />
                  <ContainerInputColor onSubmit={handleInputChange}>
                    <input
                      type="text"
                      name="color"
                      id="my-colors"
                      placeholder={colorSelect}
                      value={colorSelect}
                      onChange={(e) => handleSetColorBorder(e.target.value)}
                    />
                  </ContainerInputColor>

                  <BoxColorInput randomColor={randomColor} color={colorSelect}>
                    <button
                      onClick={() => setIsDisplayColorPicker(true)}
                      className="btn-get-colors"
                    >
                      {isDisplayColorPickerBorder && isComponentVisible && (
                        <div
                          className="popover-color"
                          onClick={() =>
                            setIsDisplayColorPicker(!isDisplayColorPickerBorder)
                          }
                        >
                          <div className="cover-color">
                            <SketchPicker
                              color={colorSelect}
                              onChange={(color) => {
                                setColorSelect(color.hex);
                                selectedObject.setAttr("stroke", color.hex);
                              }}
                              onChangeComplete={(color) => delayOrganizeHistoryColors(color.hex)}
                            />
                          </div>
                        </div>
                      )}
                    </button>
                  </BoxColorInput>
                </HorizontalStack>
                <HistoricColors>
                  <Span>{colorsHistory.length > 0 ? 'Cores recentes' : 'Não há cores recentes'}</Span>
                  <GridHistoric>
                    {colorsHistory.map((color) => (
                      <GridItem>
                        <div className="wrapper-border">
                          <BoxColor
                            onClick={(e) => {
                              handleSetColorBorder(color.hexadecimal);
                            }}
                            value={color.hexadecimal}
                            color={color.hexadecimal}
                          />
                        </div>
                      </GridItem>
                    ))}
                  </GridHistoric>
                </HistoricColors>
                {/* <VerticalStack>
                  <Text margin="0 0 1rem 0">Novas cores</Text>
                  <Grid>
                    {isFavoriteColor.map((value: string, index: number) => (
                      <>
                        <GridItem key={index}>
                          <BoxColor
                            onClick={() => addNewColor(value)}
                            value={value}
                            color={value}
                          />
                        </GridItem>
                      </>
                    ))}
                  </Grid>
                </VerticalStack> */}
              </Flex>
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Container>
  );
};