import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useSelection } from "./selection";
import ReactQuill, { Quill } from "react-quill";
import html2canvas from "html2canvas";
import { useMainHook } from "../../../hooks/main";
import { cloneDeep } from "lodash";
import { useTextsEdition } from "./textsEdition";
import { QueryBankProps } from "../components/ClausesView/dtos";

interface ClausesHook {
  children: ReactNode;
}

interface ClausesObjectProps {
  text: string;
  src: string;
  format: {
    ops: {
      insert: string;
      attribuites?: any;
    }[];
  };
  initialDelta?: any;
  isModify?: boolean;
  nameOfUserEdited?: string;
  idOfUserEdited?: string;
  isSharedNewText?: boolean;
  width: number;
  height: number;
  keyWords?: string;
}

interface SizeQuillProps {
  width: number;
  height: number;
}

interface DraggingProps {
  active: boolean;
  index: number;
}

interface ClausesDataProps {
  id: string;
  user_id: string;
  title: string;
  clause: string;
  description: string;
  is_favorite: boolean;
  key_words: string;
  created_at: string;
  updated_at: string;
}

interface ClausesHookData {
  handleTextClause: (text: string) => void;
  quillRenderImage: MutableRefObject<ReactQuill>;
  clausesObject: ClausesObjectProps;
  setClausesObject: React.Dispatch<React.SetStateAction<ClausesObjectProps>>;
  sourceClause: string;
  setSourceClause: React.Dispatch<React.SetStateAction<string>>;
  draggClauseRef: React.MutableRefObject<HTMLSpanElement | any>;
  divQuillClauseRef: React.MutableRefObject<HTMLDivElement>;
  sizeQuillClause: SizeQuillProps;
  setSizeQuillClause: React.Dispatch<React.SetStateAction<SizeQuillProps>>;
  dragging: DraggingProps;
  setDragging: React.Dispatch<React.SetStateAction<DraggingProps>>;
  clausesData: ClausesDataProps[];
  setClausesData: React.Dispatch<React.SetStateAction<ClausesDataProps[]>>;
  addedClause: boolean;
  setAddedClause: React.Dispatch<React.SetStateAction<boolean>>;
  idClause: string;
  setIdClause: React.Dispatch<React.SetStateAction<string>>;
  queryBank: QueryBankProps;
  setQueryBank: React.Dispatch<React.SetStateAction<QueryBankProps>>;
}

const ClausesContext = createContext<ClausesHookData>({} as ClausesHookData);

const ClausesEditorHookProvider: React.FC<ClausesHook> = ({ children }) => {
  const { quillRef } = useTextsEdition();
  const [sizeQuillClause, setSizeQuillClause] = useState<SizeQuillProps>({
    width: 0,
    height: 0
  });

  const [clausesData, setClausesData] = useState<ClausesDataProps[]>([]);

  const [dragging, setDragging] = useState<DraggingProps>({
    active: false,
    index: 0
  });

  const [textClause, setTextClause] = useState("");
  const [idClause, setIdClause] = useState("");
  const quillRenderImage = useRef<ReactQuill>(null);
  const divQuillClauseRef = useRef<HTMLDivElement>(null);
  const [sourceClause, setSourceClause] = useState("");
  const { objectScreen, setObjectScreen } = useMainHook();
  const [addedClause, setAddedClause] = useState(false);
  const [clausesObject, setClausesObject] = useState<ClausesObjectProps>({
    text: "",
    src: "",
    format: {
      ops: [
        {
          insert: "Texto padrão\n",
          attribuites: {
            size: "12px"
          }
        }
      ]
    },
    width: 0,
    height: 0,
    keyWords: ""
  });
  const draggClauseRef = useRef<HTMLSpanElement>(null);
  const { selectedObject } = useSelection();

  const [queryBank, setQueryBank] = useState<QueryBankProps>({
    pagination: 0,
    search: "",
    filter: "",
    category: ""
  });

  const handleTextClause = useCallback(
    async (value: string) => {
      if (selectedObject) {
        if (selectedObject?.attrs?.object === "richTextImage") {
          quillRenderImage.current.getEditor().root.style.padding = "0px";
          let widthImage = selectedObject?.attrs?.width;
          let heightImage = selectedObject?.attrs?.height;
          let myHeightTextArea =
            quillRenderImage.current.getEditor().root.offsetHeight;

          setClausesObject(state => {
            return {
              ...state,
              text: value,
              src: "",
              format: {
                ops: [
                  {
                    insert: `${value}\n`,
                    attribuites: {
                      size: "12px"
                    }
                  }
                ]
              },
              width: 0,
              height: 0
            };
          });

          if (clausesObject.text !== "") {
            quillRenderImage?.current?.getEditor().setText(clausesObject.text);

            const canvas = await html2canvas(
              quillRenderImage.current.getEditor().root,
              {
                backgroundColor: "rgba(0,0,0,0)"
              }
            );

            if (canvas) {
              setObjectScreen(prevState => {
                let cloneState = cloneDeep(prevState);
                cloneState.forEach(page => {
                  page.renderObjects.forEach((object: any) => {
                    if (object.id === selectedObject.attrs.id) {
                      object.scaleX = 1;
                      object.scaleY = 1;
                      object.width = widthImage;
                      object.height = myHeightTextArea;
                      object.src = canvas.toDataURL();
                      object.format = clausesObject.format;
                      object.text = clausesObject.text;
                    }
                  });
                });
                return cloneState;
              });
            }
          } else {
          }
        }
      }
    },
    [textClause, quillRenderImage.current, selectedObject, clausesObject]
  );

  return (
    <ClausesContext.Provider
      value={{
        handleTextClause,
        quillRenderImage,
        clausesObject,
        setClausesObject,
        sourceClause,
        setSourceClause,
        draggClauseRef,
        divQuillClauseRef,
        sizeQuillClause,
        setSizeQuillClause,
        dragging,
        setDragging,
        clausesData,
        setClausesData,
        addedClause,
        setAddedClause,
        idClause,
        setIdClause,
        queryBank,
        setQueryBank
      }}
    >
      {children}
    </ClausesContext.Provider>
  );
};

// creating hook

function useClausesEditor(): ClausesHookData {
  const context = useContext(ClausesContext);

  if (!context) {
    throw new Error(
      "useClausesEditor must be used with an ClausesEditorHookProvider"
    );
  }

  return context;
}

export { ClausesEditorHookProvider, useClausesEditor };
