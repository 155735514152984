import { BoxButton, HeaderBox, Modal, LoadingBox, Container } from "./styled";
import { useCallback, useEffect, useMemo, useState } from "react";
import api from "../../../../../../services/api";
import { ContractAnimation } from "../../../../../../shared/components/ContractAnimation";
import MoonLoader from "react-spinners/MoonLoader";
import ReactLoading, { LoadingProps } from "react-loading";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { cloneDeep } from "lodash";
import { useTeams } from "../../../../../../hooks/teams";
import { toast } from "react-toastify";
import TemplateSelect from "../../../../components/TemplateSelect";
import TemplatesSelect from "../../../../components/TemplateSelect";
import TitleH5 from "../../../../../../shared/components/TitleH5";
import PlainText from "../../../../../../shared/components/PlainText";
import ButtonExit from "../../../../../../shared/components/ButtonExit";
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import { useParams } from "react-router-dom";
import { ISelectModalTeam } from "../../../../../../dtos/teamsUtils";
import SearchBar from "../../../../components/SearchBar";
import { useMainHook } from "../../../../../../hooks/main";
import { useInfiniteQuery, useQueryClient } from "react-query";

interface IModalImportTemplateProps {
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
}

export interface MyTemplatesProps {
  id: string;
  user_id: string;
  title: string;
  description: string;
  thumbnail: string;
  is_automation: boolean;
  automation_user_template_id: any;
  created_at: string;
  updated_at: string;
  thumbnail_url: string;
  is_checked?: boolean;
}

export interface IListTemplatesChecked {
  id: string;
}

const ModalImportTemplate = ({ setSelectModal }: IModalImportTemplateProps) => {
  const {
    debouncedSearchInTemplate,
  } = useMainHook();
  const { setTemplatesOfTeams } = useTeams();
  const { team_id, number_page } = useParams();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;
  const queryClient = useQueryClient();

  const [myTemplates, setMyTemplates] = useState<MyTemplatesProps[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [listTemplatesChecked, setListTemplatesChecked] = useState<IListTemplatesChecked[]>([]);

  const isSearch = useMemo(() => {
    return debouncedSearchInTemplate !== "";
  }, [debouncedSearchInTemplate]);


  const fetchProjects = async ({ pageParam = 0 }) => {
    const response = await api.get("user-templates", {
      params: {
        pagination: pageParam,
        search:
          !isSearch
            ? null
            : debouncedSearchInTemplate,
      },
    });

    let myObjectNew: MyTemplatesProps[] = cloneDeep(
      response.data.dataArray
    );
    myObjectNew.forEach((template) => {
      if (!template.is_checked) {
        template.is_checked = false;
      }
    });

    const { pagination } = response.data;

    if (pagination > pageParam) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }

    return myObjectNew;
  };


  const { data, isLoading: loadingRequest, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ["docs", debouncedSearchInTemplate],
    queryFn: fetchProjects,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage && lastPage?.length > 0) {
        const nextPage = pages?.length;
        return nextPage;
      } else {
        return null;
      }
    },
    keepPreviousData: true,
  });


  const handleImportTemplates = useCallback(async () => {
    let templateIdsChoose = [];
    myTemplates.forEach(template => {
      if (template.is_checked) {
        templateIdsChoose.push({
          user_template_id: template.id
        });
      }
    });

    if (templateIdsChoose.length > 0) {
      try {
        setLoading(true);
        try {
          await api.post(
            "team-templates/clone-my-templates-to-my-team",
            {
              team_id: team_id,
              templates: templateIdsChoose
            }
          );
        } catch (err) {
          console.error(err);
          toast.error("Erro no post para clonar o template");
        }

        try {
          const responseMyTemplatesOfTeam = await api.get(
            `team-templates/${team_id}`,
            {
              params: {
                pagination: pageNumber
              }
            }
          );
          setTemplatesOfTeams(responseMyTemplatesOfTeam.data.dataArray);
          setLoading(false);
          setSelectModal({ modal: "Import Templates", isOpen: false });

          toast.success(`${templateIdsChoose.length > 1
            ? "Documentos importados com sucesso!"
            : "Documento importado com sucesso!"}`);
        } catch (err) {
          console.error(err, "error again templates team");
          toast.error("Erro ao importar documentos.");
        }
      } catch (err) {
        console.error(err, "ERROR when add new projects of team");
        setLoading(false);
      }
    }
  }, [myTemplates, setTemplatesOfTeams]);

  const handleExitSignDoc = useCallback(() => {
    setSelectModal({
      isOpen: false,
      modal: "Import Templates",
    });
    queryClient.resetQueries(["docs"]);
  }, [setSelectModal, queryClient]);

  useEffect(() => {
    if (data) {
      // Combine all page results into a single matrix
      const allTemplates = data.pages.reduce((acc, page) => [...acc, ...page], []);

      // Replace checked templates with those in filtersChecked
      const updatedTemplates = allTemplates.map(template => {
        const checkedTemplate = listTemplatesChecked.find(item => item.id === template.id);
        if (checkedTemplate) {
          return {
            ...template,
            is_checked: true,
          };
        }
        return {
          ...template,
          is_checked: false,
        };
      });
      setMyTemplates(updatedTemplates);
    }
  }, [data]);

  return (
    <Container>
      <Modal>
        {(loadingRequest || loading) ? (
          <LoadingBox>
            <ContractAnimation />
          </LoadingBox>
        ) : (
          <>
            <HeaderBox>
              <div>
                <TitleH5 title="Importe os templates de Meus projetos" />
                <ButtonExit
                  onClick={handleExitSignDoc}
                />
              </div>
              <PlainText>
                Selecione os templates que quer importar para a sua equipe
              </PlainText>
            </HeaderBox>
            <div style={{ minHeight: '40px' }}>
              <SearchBar
                screen={""}
                pageNumber={pageNumber}
                description="Digite aqui para encontrar o texto que procura."
                w_100={true}
                isImportTemplate={true}
                setCurrentPage={setCurrentPage}
                setMyTemplates={setMyTemplates}
              />
            </div>
            <TemplatesSelect
              loading={loadingRequest}
              myTemplates={myTemplates}
              setMyTemplates={setMyTemplates}
              setCurrentPage={setCurrentPage}
              hasMore={hasMore}
              fetchNextPage={fetchNextPage}
              listTemplatesChecked={listTemplatesChecked}
              setListTemplatesChecked={setListTemplatesChecked}
            />
            <BoxButton>
              <ButtonMaxWidth
                text="Cancelar"
                typeStyle="white"
                onClick={handleExitSignDoc}
              />
              <ButtonMaxWidth
                text="Importar"
                typeStyle="purple"
                onClick={handleImportTemplates}
              />
            </BoxButton>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default ModalImportTemplate;
