import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, LoadingContainer, Header } from "./styles";
import { useTeams } from "../../../../../hooks/teams";
import api from "../../../../../services/api";
import { useMainHook } from "../../../../../hooks/main";
import { useMetric } from "../../../../../hooks/metric";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import SearchBar from "../../../components/SearchBar";
import ImportIcon from "../../../../../shared/assets/arrow_down_circle-icon.svg";
import GridTemplate from "../../../components/GridTemplate";
import PreviewTemplateModal from "../../../components/PreviewTemplateModal";
import Pagination from "../../../components/Pagination";

const TemplatesTeamPage: React.FC = () => {
  const {
    setSelectModal,
    templatesOfTeams,
    setTemplatesOfTeams,
    handleDeleteTemplateTeam,
    isOwnerStaff,
    forceUpdate,
  } = useTeams();

  //route properties
  const navigate = useNavigate();
  const { team_id, number_page } = useParams();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;
  const navigator = useNavigate();

  const {
    documentName,
    setIsLoadingModal,
    currentTemplateInfo,
    debouncedSearchTerm,
    loadingDebounce,
    setSearch,
  } = useMainHook();
  const { handleCreatedProjectAction } = useMetric();

  const [idTemplateTeam, setIdTemplateTeam] = useState("");
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [preview, setPreview] = useState({ pages: [], title: "" });

  //Debounce responsible for keeping the user completing the search word

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const responseMyTemplatesOfTeam = await api.get(
          `team-templates/${team_id}`,
          {
            params: {
              search: debouncedSearchTerm ? debouncedSearchTerm : null,
              pagination: pageNumber,
            },
          }
        );

        if (templatesOfTeams.length === 0 && pageNumber > 0) {
          setSearch(debouncedSearchTerm ? debouncedSearchTerm : null);
        }

        setTotalPages(responseMyTemplatesOfTeam.data.pagination);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err, "error response");
      }
    })();
  }, [templatesOfTeams, pageNumber, team_id, debouncedSearchTerm]);

  useEffect(() => {
    return () => {
      setTemplatesOfTeams([]);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const responseMyTemplatesOfTeam = await api.get(
          `team-templates/${team_id}`,
          {
            params: {
              search: debouncedSearchTerm ? debouncedSearchTerm : null,
              pagination: pageNumber,
            },
          }
        );

        setTemplatesOfTeams(responseMyTemplatesOfTeam.data.dataArray);
        setTotalPages(responseMyTemplatesOfTeam.data.pagination);
        setLoading(false);

        //Checks if there are template on that page
        // If not, it sends me back to the previous page
        if (
          pageNumber > 0 &&
          responseMyTemplatesOfTeam.data.dataArray.length === 0
        ) {
          navigator(`/view-team/${team_id}/templates/${pageNumber}`);
        }
      } catch (err) {
        console.error(err, "ERROR when get list of templatess of the team");
        setLoading(false);
      }
    })();
  }, [pageNumber, debouncedSearchTerm, forceUpdate]);

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const handleChooseTeamTemplate = useCallback(async ({ templateId }) => {
    try {
      setLoadingPreview(true);
      setModalIsOpen(true);
      // setIsLoadingModal(true);
      const fetchDocumentPreview = await api.get(
        `team-templates/list-specific/${templateId}`
      );
      const { template, title } = fetchDocumentPreview.data;
      setPreview({ pages: template.arrayOfPages, title: title });
      setLoadingPreview(false);
      // setIsLoadingModal(false);
    } catch (error) {
      console.error("error getting document to preview");
      setLoadingPreview(false);
      // setIsLoadingModal(false);
    }
  }, []);

  //when user clicked on project then navigate it
  const handleCreatedProjectFromTeam = async (id: string) => {
    try {
      setIsLoadingModal(true);
      setLoadingPreview(true);
      const requestCloneTemplate = await api.post(
        `team-templates/clone-team-template-to-my-projects`,
        {
          team_template_id: id,
        }
      );

      //created project action register function
      handleCreatedProjectAction({
        project_id: requestCloneTemplate.data.id,
        creation_origin: "Team Template",
        template_id: id,
        template_bits_name: false,
        researched: false,
      });

      // navigate(`editor/my-template/${requestCloneTemplate.data.id}`);
      // navigate(`/editor/my-template/`);
      //get template and update the main state
      //when ready state then navigate these user
      try {
        const response = await api.get(
          `user-templates/${requestCloneTemplate.data.id}`
        );

        currentTemplateInfo.current = response.data;
        documentName.current = response.data.title;
        // setObjectScreen(response.data.template.arrayOfPages);
        navigate(`/editor/my-template/${requestCloneTemplate.data.id}`)
        setLoadingPreview(false);
        setIsLoadingModal(true);
      } catch (err) {
        setIsLoadingModal(true);
        console.error(err, "error when get specific template");
      }
    } catch (err) {
      console.error(err, "ERROR when choose template team to my projects");
    }
  };

  const handleEditTamplate = useCallback(async (title, id) => {
    documentName.current = `${title}`;
    navigate(`/editor/team-template/${id}`);
  }, []);

  return (
    <>
      <Container>
        <Header>
          {isOwnerStaff && (
            <button
              onClick={() => {
                setSelectModal({ modal: "Import Templates", isOpen: true });
              }}
            >
              <img src={ImportIcon} alt="" />
              Importar templates
            </button>
          )}
          <SearchBar
            screen={`view-team/${team_id}/templates`}
            pageNumber={pageNumber}
            description="Digite aqui para encontrar o template que procura."
          />
        </Header>

        {loading || loadingDebounce ? (
          <LoadingContainer>
            <ContractAnimation />
          </LoadingContainer>
        ) : (
          <GridTemplate
            templates={templatesOfTeams}
            handleChooseTemplate={handleChooseTeamTemplate}
            setTemplateId={setIdTemplateTeam}
            templateOptions={isOwnerStaff}
            handleEditTamplate={handleEditTamplate}
            handleDeleteDocument={handleDeleteTemplateTeam}
            handleUseTemplate={handleCreatedProjectFromTeam}
            page="Team"
          />
        )}

        <PreviewTemplateModal
          open={modalIsOpen}
          handleCloseModal={handleCloseModal}
          pagesToBeRendered={preview.pages}
          title={preview.title}
          loadingPreview={loadingPreview}
          chooseTemplate={handleCreatedProjectFromTeam}
          templateId={idTemplateTeam}
          texButton="Usar Template"
        />

        <Pagination
          total={totalPages}
          pageNow={pageNumber}
          screen={`view-team/${team_id}/templates`}
        />
      </Container>
    </>
  );
};

export default TemplatesTeamPage;
