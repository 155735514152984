import React from "react";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import { darken } from "polished";

interface TextProps extends React.HTMLProps<HTMLTextAreaElement> {
  margin?: string;
}

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  color?: string;
}

interface WrapperProps {
  marginTop?: string;
}

export const Container = styled.header`
  width: 100%;
  height: 40px;
  padding-inline: 5px 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 1;

  z-index: 99;
  background: linear-gradient(
    to top,
    ${({ theme }) => darken(0.18, theme.colors.primary)},
    ${({ theme }) => theme.colors.primary} 120%
  );

  .MuiAvatar-colorDefault {
    background-color: #9945ee;
  }

  .btn-hover {
    display: flex;
    width: 70%;
    height: 40px;
    border-radius: 10px;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .icon-back {
    width: 22px;
    height: 22px;
  }
`;

export const HeaderLeft = styled.div`
  height: 100%;
  display: flex;
  gap: 7.5px;
  align-items: center;
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "")};
  z-index: 99;
  position: relative;

  & .btn-click {
    display: flex;
    width: 80%;
    height: 40px;
    border-radius: 10px;
    justify-content: flex-end;
    align-items: center;
    background: transparent;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    @media (max-width: 1366px) {
      height: 35px;
    }
  }

  .icon-undo {
    display: flex;
    margin: 0 auto;
    align-items: center;
  }

  .icon-redo {
    display: flex;
    margin: 0 auto;
    align-items: center;
  }

  .redoRedo {
    width: 24px;
    height: 24px;
  }

  .redoUndo {
    width: 17px;
    height: 17px;
  }

  @media (max-width: 1600px) {
    .redoUndo {
      width: 18px;
      height: 18px;
    }

    .redoRedo {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 1366px) {
    .redoUndo {
      width: 17px;
      height: 17px;
    }

    .redoRedo {
      width: 22px;
      height: 22px;
    }
  }
`;

export const Text = styled.p<TextProps>`
  font-family: "Roboto";
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.6rem;
  margin: ${props => (props.margin ? props.margin : "")};

  @media (max-width: 1366px) {
    font-size: 1.6rem;
  }
`;

export const HeaderCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0 auto;
  max-width: 23.33%;
  transform: translateX(50px);

  .wrapper-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderMiddleSharedGroup = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;

  .adjust-avatar-multiples2rd {
    transform: translateX(-7px);
  }

  .adjust-avatar-multiples3rd {
    transform: translateX(-12px);
  }

  .avatar-length {
    transform: translateX(-20px);
  }

  @media (max-width: 1600px) {
  }

  @media (max-width: 1366px) {
    width: auto;
    transform: translateX(0rem);
    .avatar-length {
      height: 35px;
      width: 35px;
    }
  }
`;

export const HeaderRight = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  gap: 15px;

  position: relative;

  .disable-hover {
    background-color: #a5a5a5 !important;
    color: #fff;
    cursor: not-allowed;

    :hover {
      color: #fff;
    }
  }

  .save {
    width: 28px !important;
    height: 21px !important;
  }

  & .download {
    width: 25px;
    height: 25px;
  }

  & .btn-click {
    display: flex;
    width: 60px;
    height: 40px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background: transparent;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;

export const ButtonShare = styled.button<ButtonProps>`
  width: 150px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.white100};
  color: ${({ theme }) => theme.colors.primary90};
  border-radius: 5px;
  padding: 0;
  transition: all 120ms linear;

  font-size: 14px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const AvatarEditor = styled(Avatar)`
  width: 80px;
  height: 80px;
  margin-right: 1rem;
`;

export const InputNameDocument = styled.input<{showPen?: boolean}>`
  background-color: transparent;
  border: none;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  min-width: 360px;
  height: 30px;
  display: flex;
  border-radius: 5px;

  :hover {
    border: solid 1px rgba(255, 255, 255, 0.6);
  }

  :focus {
    border: solid 2px #5d5fef;
  }

  ::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    color: rgba(255, 255, 255, 0.6);
  }
`;
