import styled from "styled-components";
import { Theme } from "../../../../../dtos/theme";

interface DashProps {
  dash?: number[];
}

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropDownContainer = styled.div`
  margin: 0 auto;
  display: flex;
`;

export const DropDownHeader = styled.div<{ Open: boolean }>`
  display: block;

  &:hover {
    cursor: pointer;
  }
`;

export const DropDownListContainer = styled.div``;

export const DropDownList = styled.ul`
  width: 150px;
  background: white;
  color: ${({ theme }: Theme) => theme.colors.black};
  padding: 10px;
  border-radius: 5px;

  position: absolute;
  top: 35px;
  left: 193px;

  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));

  &:hover {
    cursor: pointer;
  }
`;

export const HorizontalStack = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const LineBarNormal = styled.div`
  margin-top: 1rem;
  border: 2px solid black;
  width: 100px;
`;

export const LineWithDash = styled.div<DashProps>`
  margin-top: 3rem;
  border: 2px solid black;
  border-style: dashed;
  width: 100px;
`;

export const HStack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
