import { Container } from "./styles";
import ButtonExit from "../../../../../../shared/components/ButtonExit";
import { UseMutateFunction } from "react-query";
import { IResponseCreationProjectFromBitsTemplates } from "../../../../../../dtos/CreateNewProjects";

interface IHeaderProps {
  handleCloseModal: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  chooseTemplateBits: UseMutateFunction<
    IResponseCreationProjectFromBitsTemplates,
    unknown,
    string,
    unknown
  >;
  templateId?: string;
  texButton?: "Usar Template" | "Abrir Projeto";
  title: string;
  local: "Dashboard" | "Editor";
}

const Header = ({
  handleCloseModal,
  chooseTemplateBits,
  texButton,
  templateId,
  title,
  local
}: IHeaderProps) => {
  return (
    <Container>
      <p>{title}</p>
      {local === "Dashboard" && (
        <button onClick={() => chooseTemplateBits(templateId)}>
          {texButton}
        </button>
      )}

      <ButtonExit onClick={handleCloseModal} inactivatedColor="#999C9F" />
    </Container>
  );
};

export default Header;
