import React from "react";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import { usePagesEditor } from "../../../../hooks/pagesEditor";
import ButtonIconsWithTooltip from "../../../ButtonsAsideBar/components/ButtonIconsAsidebar";
import { useTheme } from "../../../../../../hooks/theme";

// import { Container } from './styles';

interface DuplicatePageEditorProps {
  item: any;
  index: number;
}

const DuplicatePageEditor: React.FC<DuplicatePageEditorProps> = ({
  item,
  index
}) => {
  const {
    handleDuplicatePage,
    handleHoverIconDuplicatePage,
    isActiveDuplicatePage
  } = usePagesEditor();
  const { theme } = useTheme();

  return (
    <ButtonIconsWithTooltip
      onMouseEnter={() => handleHoverIconDuplicatePage(index, true)}
      onMouseLeave={() => handleHoverIconDuplicatePage(index, false)}
      onClick={() => handleDuplicatePage(Number(item.pageNumber), index)}
      style={{ position: "relative" }}
      title="Duplicar página"
      placement="bottom-start"
    >
      <PersonalizedIcon
        isActive={
          isActiveDuplicatePage.visible && isActiveDuplicatePage.index === index
        }
        viewBox="0 0 25 24"
        activeColor={theme.colors.primary}
        inactivatedColor="#999C9F"
        className="btn-page"
        dPath="M17.4914 11.2H15.0914V13.6H13.4914V11.2H11.0914V9.6H13.4914V7.2H15.0914V9.6H17.4914V11.2ZM19.0914 5.6V15.2H9.49141V5.6H19.0914ZM19.0914 4H9.49141C8.61141 4 7.89141 4.72 7.89141 5.6V15.2C7.89141 15.6243 8.05998 16.0313 8.36004 16.3314C8.66009 16.6314 9.06706 16.8 9.49141 16.8H19.0914C19.9794 16.8 20.6914 16.088 20.6914 15.2V5.6C20.6914 5.17565 20.5228 4.76869 20.2228 4.46863C19.9227 4.16857 19.5158 4 19.0914 4ZM6.29141 7.2H4.69141V18.4C4.69141 18.8243 4.85998 19.2313 5.16004 19.5314C5.46009 19.8314 5.86706 20 6.29141 20H17.4914V18.4H6.29141V7.2Z"
      />
    </ButtonIconsWithTooltip>
  );
};

export default DuplicatePageEditor;
