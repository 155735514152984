import React, { useEffect } from "react";
import {
  DateContainer,
  DownloadButton,
  EventAction,
  EventActionBottom,
  EventContainer,
  EventDate,
  EventStatus,
  EventSubtitle,
  EventTitle,
  TimelineContainer
} from "./styles";
import { Stack } from "@mui/material";
import { BiCheckCircle, BiCircle, BiInfoCircle } from "react-icons/bi";
import { MdBlockFlipped, MdCircle } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { ApprovalFlow } from "./../../../types/ApprovalFlow";
import ApproverReview from "../ApproverReview";
import { ApprovalProject } from "../../../types/ApprovalProject";
import PdfViewer from "../../PdfViewer";
import { toast } from "react-toastify";
import CreateNewProject from "../../CreateNewProject";
import api from "../../../../../../services/api";
import { format, utcToZonedTime } from "date-fns-tz";

const getIcon = status => {
  switch (status.toLowerCase()) {
    case "initiated":
      return <BiCircle color={"green"} fontSize={"30px"} />;
    case "email_sent":
      return <BiCircle color={"green"} fontSize={"30px"} />;
    case "reviewing":
      return <BiCircle color={"#999C9F"} fontSize={"30px"} />;
    case "approved":
      return <BiCheckCircle color={"green"} fontSize={"30px"} />;
    case "pending":
      return <BiInfoCircle color={"orange"} fontSize={"30px"} />;
    case "late":
      return <BiInfoCircle color={"orange"} fontSize={"30px"} />;
    case "completed":
      return <MdCircle color={"#007B36"} fontSize={"30px"} />;
    case "refused":
      return <RiCloseCircleLine color={"#DE2D2D"} fontSize={"30px"} />;
    case "canceled":
      return <MdBlockFlipped color={"#999C9F"} fontSize={"30px"} />;
    case "step_approved":
      return <MdCircle color={"#007B36"} fontSize={"22px"} />;
    default:
      return;
  }
};

const getApproverStatusLabel = status => {
  console.log(status);
  switch (status) {
    case "APPROVED":
      return "Aprovado";
    case "REFUSED":
      return "Reprovado";
    case "PENDING":
      return "Pendente";
    default:
      return;
  }
};

type Props = {
  flow: ApprovalFlow;
  isCurrentFlow: boolean;
  currentStepId: string;
  isFirstFlow: boolean;
  project: ApprovalProject;
  refreshTimeline: () => void;
};

type Event = {
  date: string;
  time: string;
  title: string;
  subtitle: string;
  status: string;
  action: JSX.Element | string;
  actionBottom: JSX.Element | string;
  actionData?: any;
  actionBottomData?: any;
};

const Timeline: React.FC<Props> = ({
  flow,
  isCurrentFlow,
  currentStepId,
  isFirstFlow,
  project,
  refreshTimeline
}: Props) => {
  const [events, setEvents] = React.useState<Event[]>([]);
  const [isApproverReviewOpen, setIsApproverReviewOpen] = React.useState(false);
  const [actionData, setActionData] = React.useState<any>();
  const [isPdfViewerOpen, setIsPdfViewerOpen] = React.useState(false);
  const [pdfFile, setPdfFile] = React.useState<File>();
  const [resendDocumentOpen, setResendDocumentOpen] = React.useState(false);

  const getStatusOrder = (status: string) => {
    switch (status) {
      case "APPROVED":
        return 1;
      case "PENDING":
        return 3;
      case "REFUSED":
        return 2;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const initialEvent = {
      date: getOnlyDate(flow.created_at),
      time: getOnlyTime(flow.created_at),
      title: isFirstFlow ? "Início do Fluxo" : "Fluxo reiniciado",
      subtitle: isFirstFlow ? "Documento enviado" : "Documento reenviado",
      status: "initiated",
      action: "Visualizar documento",
      actionBottom: ""
    };

    const events: Event[] = [initialEvent];

    const steps = flow.steps.filter(step => step.status !== "PENDING");

    steps.forEach(step => {
      const event = {
        date: getOnlyDate(step.created_at),
        time: getOnlyTime(step.created_at),
        title: `Etapa ${step.order}: ${step.name}`,
        subtitle: "envio de emails",
        status: "email_sent",
        action: "",
        actionBottom: ""
      };
      events.push(event);
      // sort approvers by status

      step.approvers.sort((a, b) => {
        return getStatusOrder(a.status) - getStatusOrder(b.status);
      });

      step.approvers.forEach(approver => {
        const event = {
          date:
            approver.status === "PENDING"
              ? ""
              : getOnlyDate(approver.updated_at),
          time:
            approver.status === "PENDING"
              ? ""
              : getOnlyTime(approver.updated_at),
          title: getApproverStatusLabel(approver.status),
          subtitle: approver.name,
          status: approver.status,
          action: approver.status === "PENDING" ? "" : "Visualizar avaliação",
          actionBottom:
            approver.status === "PENDING" &&
            step.status === "STARTED" &&
            project.status !== "CANCELED"
              ? "Reenviar e-mail"
              : "",
          actionData: {
            stepName: step.name,
            fileUrl: flow.document_file_url,
            approver_id: approver.id
          },
          actionBottomData: {
            stepName: step.name,
            fileUrl: flow.document_file_url,
            approver_id: approver.id
          }
        };
        events.push(event);
      });
      const existAnyRefused = step.approvers.some(
        approver => approver.status === "REFUSED"
      );

      if (existAnyRefused) {
        const firstRefusedDate = step.approvers
          .filter(approver => approver.status === "REFUSED")
          .sort(
            (a, b) =>
              new Date(a.updated_at).getTime() -
              new Date(b.updated_at).getTime()
          )[0];
        const event = {
          date: getOnlyDate(firstRefusedDate.updated_at),
          time: getOnlyTime(firstRefusedDate.updated_at),
          title: "Etapa recusada",
          subtitle: step.name,
          status: "refused",
          action: "",
          actionBottom: ""
        };
        events.push(event);
        // if is current flow means that the refused step is the last one and need to send the document again
        if (isCurrentFlow) {
          const lastDate = step.approvers.sort(
            (a, b) =>
              new Date(b.updated_at).getTime() -
              new Date(a.updated_at).getTime()
          )[0].updated_at;
          const reviewEvent = {
            date: getOnlyDate(lastDate),
            time: getOnlyTime(lastDate),
            title: "Em análise",
            subtitle: "Reenvio de documento",
            status: "reviewing",
            action: "",
            actionBottom: "Reenviar documento"
          };
          events.push(reviewEvent);
        }
      }

      const deadLine = new Date(step.planned_deadline_date);
      const currentDate = new Date();
      const existsApproverPendingOrLastApproveAfterDeadLine =
        (step.approvers.some(approver => approver.status === "PENDING") &&
          currentDate > deadLine) ||
        step.approvers.some(
          approver =>
            approver.status === "APPROVED" &&
            new Date(approver.updated_at) > deadLine
        );

      if (existsApproverPendingOrLastApproveAfterDeadLine) {
        const event = {
          date: getOnlyDate(step.planned_deadline_date),
          time: getOnlyTime(step.planned_deadline_date),
          title: "Etapa atrasada",
          subtitle: "Prazo de aprovação expirado",
          status: "late",
          action: "",
          actionBottom: ""
        };
        events.push(event);
      }

      if (step.status === "APPROVED") {
        const event = {
          date: getOnlyDate(step.updated_at),
          time: getOnlyTime(step.updated_at),
          title: "Etapa aprovada",
          subtitle: step.name,
          status: "step_approved",
          action: "",
          actionBottom: ""
        };
        events.push(event);
      }
    });

    if (project.status === "CANCELED" && isCurrentFlow) {
      const event = {
        date: getOnlyDate(project.updated_at),
        time: getOnlyTime(project.updated_at),
        title: "Cancelado",
        subtitle: "Projeto cancelado",
        status: "canceled",
        action: <DownloadButton>Baixar documento</DownloadButton>,
        actionBottom: ""
      } as Event;
      events.push(event);
    }
    if (project.status === "APPROVED" && isCurrentFlow) {
      const event = {
        date: getOnlyDate(project.updated_at),
        time: getOnlyTime(project.updated_at),
        title: "APROVADO",
        subtitle: "Fluxo finalizado",
        status: "completed",
        action: <DownloadButton>Baixar documento</DownloadButton>,
        actionBottom: ""
      } as Event;
      events.push(event);
    }

    setEvents(events);
  }, []);

  const getOnlyDate = (date: string) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  };

  const getOnlyTime = (utcDate: string) => {
    const dateObj = new Date(utcDate);
    return dateObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false
    });
  };

  const urlToFile = async (url: string) => {
    const response = await api.get(
      `/approval-projects/${project.id}/flows/${flow.id}/document-pdf`
    );

    // Convert base64 to binary
    const binary = atob(response.data);
    const array = [];

    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }

    // Create a blob from the binary data
    const blob = new Blob([new Uint8Array(array)], { type: "application/pdf" });

    // Create a file from the blob
    const file = new File([blob], project.name, { type: "application/pdf" });

    return file;
  };

  const handleDownload = async () => {
    try {
      toast.info("Baixando documento...", {
        autoClose: 600
      });
      const flows = project.approval_flows;
      const lastFlow = flows[flows.length - 1];
      const response = await api.get(
        `/approval-projects/${project.id}/flows/${lastFlow.id}/document-pdf`
      );
      // Convert base64 to binary
      const binary = atob(response.data);
      const array = [];

      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }

      // Create a blob from the binary data
      const blob = new Blob([new Uint8Array(array)], {
        type: "application/pdf"
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${project.name}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log(error);
      toast.error(
        "Ocorreu um erro ao baixar o documento. Contate o suporte técnico."
      );
    }
  };

  const handleDownload2 = async () => {
    try {
      const flows = project.approval_flows;
      const lastFlow = flows[flows.length - 1];
      const url = lastFlow.document_file_url;
      if (!url) {
        toast.error(
          "Documento não encontrado. Verifique se o documento foi enviado para o fluxo de aprovação."
        );
        return;
      }
      const responseFile = await fetch(url);
      if (responseFile.status === 200) {
        const blob = await responseFile.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${project.name}.pdf`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl); // Clean up the URL object
      } else {
        toast.error(
          "Ocorreu um erro ao baixar o documento. Contate o suporte técnico."
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Ocorreu um erro ao baixar o documento. Contate o suporte técnico."
      );
    }
  };

  const sendEmail = async (approver_id: string) => {
    try {
      const response = await api.post(
        `approval-projects/${project.id}/approvers/${approver_id}/resend-email`
      );
      if (response.status === 200) {
        toast.success("E-mail reenviado com sucesso!");
      } else {
        toast.error(
          "Ocorreu um erro ao reenviar o e-mail. Contate o suporte técnico."
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Ocorreu um erro ao reenviar o e-mail. Contate o suporte técnico."
      );
    }
  };

  const onActionClick = async (
    action: string | JSX.Element,
    actionData: any
  ) => {
    const isJsxElement = typeof action !== "string";

    if (isJsxElement) {
      handleDownload();
      return;
    }
    setActionData(actionData);
    switch (action) {
      case "Visualizar documento":
        const file = await urlToFile(flow.document_file_url);
        setPdfFile(file);
        setIsPdfViewerOpen(true);
        break;
      case "Visualizar avaliação":
        setActionData(actionData);
        setIsApproverReviewOpen(true);
        break;
      case "Reenviar e-mail":
        toast.info("Reenviando email...", {
          autoClose: 600
        });
        await sendEmail(actionData.approver_id);
        break;
      case "Reenviar documento":
        setResendDocumentOpen(true);
        break;
      default:
        break;
    }
  };
  return (
    <TimelineContainer>
      {events.map((event, index) => (
        <React.Fragment key={index}>
          <EventContainer>
            <DateContainer>
              <EventDate style={{ textAlign: "right" }}>
                {event.date}
                <br />
                {event.time && `às ${event.time}`}
              </EventDate>
            </DateContainer>
            <EventStatus
              showShadow={["PENDING", "CANCELED"].includes(
                event.status.toUpperCase()
              )}
            >
              {getIcon(event.status)}
            </EventStatus>
            <Stack direction="row" justifyContent={"space-between"} flex={1}>
              <Stack direction="column" spacing={"6px"}>
                <EventTitle>{event.title}</EventTitle>
                <EventSubtitle>{event.subtitle}</EventSubtitle>
              </Stack>
              <Stack direction="column" spacing={"6px"}>
                <EventAction
                  onClick={() => onActionClick(event.action, event.actionData)}
                >
                  {event.action}&nbsp;
                </EventAction>
                <EventActionBottom
                  onClick={() =>
                    onActionClick(event.actionBottom, event.actionBottomData)
                  }
                >
                  {event.actionBottom}&nbsp;
                </EventActionBottom>
              </Stack>
            </Stack>
          </EventContainer>
        </React.Fragment>
      ))}
      {isApproverReviewOpen && (
        <ApproverReview
          approver_id={actionData?.approver_id}
          open={isApproverReviewOpen}
          onClose={() => setIsApproverReviewOpen(false)}
        />
      )}

      {isPdfViewerOpen && (
        <PdfViewer
          open={isPdfViewerOpen}
          onClose={() => setIsPdfViewerOpen(false)}
          file={pdfFile}
        />
      )}

      {resendDocumentOpen && (
        <CreateNewProject
          open={resendDocumentOpen}
          onClose={refresh => {
            if (refresh) {
              refreshTimeline();
            }
            setResendDocumentOpen(false);
          }}
          resend={true}
          editing_project_id={project.id}
        />
      )}
    </TimelineContainer>
  );
};

export default Timeline;
