import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  BoxModal,
  ButtonContinue,
  Container,
  ContainerMain,
  Footer,
  Input2Fa,
  Text,
  TextEmergency,
  TokenBox,
} from "./styles";
import { ToastContainer, toast } from "react-toastify";
import HeaderModal2FA from "../HeaderModal2FA";
import LoadingTransition from "../LoadingTransition";
import { Divider } from "../Validate2FA/styles";
import api from "../../../../../services/api";
import { useAuth } from "../../../../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { useTwoFactorAuth } from "../../../../../hooks/twoFactorAuth";

type Props = {
  isProfilePage?: boolean;
};

const TwoFactorAuthentication: React.FC<Props> = ({ isProfilePage }) => {
  const { setData } = useAuth();
  const { setIsValidateOTP } = useTwoFactorAuth();
  const [isEmergencyMode, setIsEmergencyMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOTPComplete, setIsOTPComplete] = useState(false);
  const [otp, setOTP] = useState<string[]>(new Array(6).fill(""));
  const [backup, setBackup] = useState("");
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));

  const navigate = useNavigate();

  const handleSendValidateOTP = useCallback(async () => {
    try {
      if (isOTPComplete || isEmergencyMode) {
        setLoading(true);
        const token = {
          token: otp.join(""),
        };

        const tokenBackup = {
          token: backup,
        };

        const responseTwoFactorAuth = await api.post(
          "two-factor-authentication/validate",
          isEmergencyMode ? tokenBackup : token
        );

        if (
          responseTwoFactorAuth.data.message ===
          "Two factor authentication disabled successfully"
        ) {
          setData((oldState) => {
            return {
              ...oldState,
              user: {
                ...oldState.user,
                otp_enabled: false,
                otp_verified: true,
                otp_validate: false,
              },
            };
          });
          toast.success("Token de backup: 2FA foi desabilitado da sua conta.");
          setLoading(false);

          if (isProfilePage) {
            setIsValidateOTP(true);
            return;
          }

          navigate("/");
        } else {
          if (isProfilePage) {
            setIsValidateOTP(true);
          } else {
            setData((oldState) => {
              return {
                ...oldState,
                user: {
                  ...oldState.user,
                  otp_enabled: true,
                  otp_verified: true,
                  otp_validate: true,
                },
              };
            });
            setLoading(false);
            navigate("/");
          }
        }
      } else {
        toast.error("Código inválido, verifique.");
        setLoading(false);
      }
    } catch (err) {
      if (
        err.response.data.message ===
        "Failed to verify two factor authentication"
      ) {
        // setLoadingSetup2FA(false);
        setLoading(false);
        toast.error("Código inválido ou expirado.");
        return;
      }
      // console.log(err, "token is invalid!");
      toast.error("Código inválido ou expirado.");
      setLoading(false);
    }
  }, [
    isOTPComplete,
    isEmergencyMode,
    otp,
    backup,
    setData,
    navigate,
    isProfilePage,
    setIsValidateOTP,
  ]);

  const handleChange = useCallback(
    (index: number, value: string) => {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);
      const complete = newOTP.every((digit) => digit !== "");
      setIsOTPComplete(complete);

      if (value && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    },
    [otp, setOTP, inputRefs, setIsOTPComplete]
  );

  const handleKeyDown = useCallback(
    (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === "Backspace" &&
        otp[index] === "" &&
        inputRefs.current[index - 1]
      ) {
        inputRefs.current[index - 1]?.focus();
      }
    },
    [otp, inputRefs]
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const pastedData = event.clipboardData
        .getData("text/plain")
        .replace(/\D/g, "");
      const pastedOTP = pastedData.split("").slice(0, 6);
      setOTP(pastedOTP);
      const complete = pastedOTP.every((digit) => digit !== "");
      setIsOTPComplete(complete);
    },
    [setIsOTPComplete, setOTP]
  );

  const handleFocus = useCallback(
    (index: number) => {
      inputRefs.current[index]?.select();
    },
    [inputRefs]
  );

  useEffect(() => {
    setIsEmergencyMode(false);

    return () => {
      setIsEmergencyMode(false);
      setBackup("");
    };
  }, []);

  if (isEmergencyMode) {
    return (
      <Container>
        <ToastContainer />
        <BoxModal>
          <HeaderModal2FA
            hiddenClose
            title="Login com código de backup de emergência"
            isEmergency={isEmergencyMode}
          />

          <ContainerMain>
            <Text>
              Para continuar, insira o código de backup de emergência que foi
              gerado e salvo em seu computador.
            </Text>

            {loading ? (
              <LoadingTransition />
            ) : (
              <Input2Fa
                value={backup}
                onChange={(event) => setBackup(event.target.value)}
                placeholder="Insira o seu código de backup"
              />
            )}

            <TextEmergency
              onClick={() => {
                setIsEmergencyMode(false);
                setBackup("");
                setOTP(new Array(6).fill(""));
              }}
            >
              Usar código de verificação do aplicativo Google Authenticator
            </TextEmergency>
          </ContainerMain>
          <Footer>
            <ButtonContinue type="button" onClick={handleSendValidateOTP}>
              Continuar
            </ButtonContinue>
          </Footer>
        </BoxModal>
      </Container>
    );
  }

  return (
    <Container>
      <ToastContainer />
      <BoxModal>
        <HeaderModal2FA
          blockOutPage={isProfilePage}
          hiddenClose
          title="Autenticação de dois fatores com aplicativo autenticador"
        />

        <ContainerMain>
          <Text>
            Para continuar, insira o código de verificação de 6 dígitos gerado
            pelo aplicativo <strong>Google Authenticator.</strong>
          </Text>

          {loading ? (
            <LoadingTransition />
          ) : (
            <TokenBox>
              <Divider />
              {otp.map((digit, index) => (
                <input
                  key={index}
                  ref={(ref) => (inputRefs.current[index] = ref)}
                  className={`otp-input ${
                    index === 2 ? "otp-input-space" : `item-${index}`
                  }`}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(event) => handleChange(index, event.target.value)}
                  onKeyDown={(event) => handleKeyDown(index, event)}
                  onPaste={(event) => handlePaste(event)}
                  onFocus={() => handleFocus(index)}
                />
              ))}
            </TokenBox>
          )}

          <TextEmergency
            onClick={() => {
              setIsEmergencyMode(true);
              setBackup("");
              setOTP(new Array(6).fill(""));
            }}
          >
            Usar código de backup de emergência
          </TextEmergency>
        </ContainerMain>
        <Footer>
          <ButtonContinue onClick={handleSendValidateOTP}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ReactLoading
                  type="spin"
                  color={"#fff"}
                  height={14}
                  width={14}
                />
              </div>
            ) : (
              `Continuar`
            )}
          </ButtonContinue>
        </Footer>
      </BoxModal>
    </Container>
  );
};

export default TwoFactorAuthentication;
