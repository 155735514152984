import Konva from "konva";
import { cloneDeep } from "lodash";
import { PagesProps } from "../../../dtos/PagesProps";
import { IRenderedObject } from "../../../dtos/RenderedObject";

interface changeSelectedObjectInterface {
  setObjectScreen: React.Dispatch<React.SetStateAction<PagesProps[]>>;
  selectedObject: Konva.Shape;
  propertie: string;
  value: any;
  pendingSave?: React.MutableRefObject<boolean>;
}

export const changeSelectedObject = ({
  selectedObject,
  setObjectScreen,
  propertie,
  value,
  pendingSave
}: changeSelectedObjectInterface) => {
  setObjectScreen(prevState => {
    let cloneState = cloneDeep(prevState);
    let breakPageLoop = false;

    cloneState.forEach((page, index) => {
      if (breakPageLoop) return;
      const foundObjectIndex = page?.renderObjects?.findIndex(object => {
        return object?.id === selectedObject?.id();
      });

      if (foundObjectIndex !== -1) {
        page.renderObjects[foundObjectIndex][propertie] = value;
        breakPageLoop = true;
      }
    });

    return cloneState;
  });
};

