import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

export const FlexSearch = styled.div`
  width: 100%;
  height: 70px;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 500px;
  max-width: 500px;

  span {
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    color: #d4d4d4;
  }
`;

export const ScrollInfinityBox = styled.div`
  width: 100%;
  min-height: 40px;
  /* background-color: red; */
  display: flex;
`;

export const BackToAllElementsBox = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;

    color: #343a40;
  }
`;

export const SplitContainerBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 100%;
    color: #676b70;

    cursor: pointer;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-bottom: 6rem;

  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;

  .grid-items-shapes {
    padding: 1rem;

    img {
      height: 60px;
      width: 60px;
    }
  }

  .grabbing-vectors {
    cursor: grab;
  }
`;

export const Heading = styled.div`
  font-size: 1.8rem;
  margin: 1.8rem 0 1.8rem 1.6rem;
  color: ${({ theme }: Theme) => theme.colors.black};

  @media (max-width: 1600px) {
    font-size: 1.6rem;
    margin: 1.8rem 0 1.5rem 1.6rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.5rem;
    margin: 1.8rem 0 1.3rem 1.6rem;
  }
`;

export const ElementCategory = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Title = styled.div`
  font-size: 13px;

  font-weight: 500;
  line-height: 100%;

  color: #343a40;
`;

export const BoxSearch = styled.div`
  width: 100%;
  height: 30px;
  background: #ffffff;
  /* gray-light */
  border: 1px solid #999c9f;
  border-radius: 5px;
  padding-inline: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  img {
    width: 24px;
    height: 24px;
  }

  .input-search-box {
    width: 92%;
    height: 100%;

    input {
      width: 100%;
      height: 30px;
      outline: none;
      border: none;
      background-color: transparent;

      ::placeholder {
        color: #d8d8d8;
      }
    }
  }

  button {
    width: 40px;
    height: 90%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    :hover {
      background-color: #f5f5f5;
      border-radius: 5px;
    }
  }
`;
