import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 192px;
  height: 107px;
  fill: currentColor;
`;

type Props = {
  color: string;
};

const StepIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 192 107" color={color}>
    <path
      d="M168.969 103.355C168.219 104.913 166.643 105.904 164.914 105.904H6.2052C2.88962 105.904 0.712529 102.44 2.15034 99.4524L21.9344 58.3435C22.6271 56.9041 22.6598 55.2346 22.024 53.7692L1.74174 7.02508C0.452343 4.05344 2.63057 0.733887 5.86989 0.733887H164.776C166.569 0.733887 168.19 1.79805 168.904 3.44269L190.913 54.1672C191.433 55.3662 191.407 56.7322 190.84 57.9099L168.969 103.355Z"
      fill="currentColor"
      stroke="#7547A3"
    />
  </StyledSvg>
);

export default StepIcon;
