import styled from "styled-components";

export const Container = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  border: solid 1px #d6d8d9;
  padding-inline: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;

  .icon {
    width: 24px;
    height: 24px;
  }
`;

export const Label = styled.label`
  font-size: 1.4rem;
  text-align: left;
`;

export const ContainerIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ButtonIcon = styled.button`
  width: 30px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
`;
