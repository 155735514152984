import React from "react";
import DropdownZoom from "../../../DropdownZoom";
import IconToolbar from "../../../IconToolbar";

import LockedIcon from "../../icons/LockedIcon";
import UnlockIcon from "../../icons/UnlockIcon";
import { Container, ContainerImageLeft, ContainerRight } from "./styles";

import Tooltip from "../../../Tooltip";

import ButtonRuler from "../../../../../../shared/components/ButtonRuler";
import { useWorkspaceEditor } from "../../../../hooks/workspaceEditor";

// import { Container } from './styles';

interface MultiplesToolProps {
  handleToggleBlockElement: () => void;
  isDraggable: boolean;
  handleDeleteElement: () => void;
  iconTrash: string;
}

const MultiplesTool: React.FC<MultiplesToolProps> = ({
  handleToggleBlockElement,
  isDraggable,
  handleDeleteElement,
  iconTrash
}) => {
  const { handleShowRuler, showRuler } = useWorkspaceEditor();

  return (
    <>
      <ContainerImageLeft></ContainerImageLeft>
      <Container maxWidth="720px"></Container>
      <ContainerRight>
        <button
          onClick={() => {
            handleToggleBlockElement();
          }}
          className="btn-toolbar"
        >
          <Tooltip title="Bloquear elemento" placement="bottom-start">
            {isDraggable ? (
              <UnlockIcon className="width-unlock-icon" />
            ) : (
              <LockedIcon className="width-lock-icon" />
            )}
          </Tooltip>
        </button>

        <button className="btn-toolbar" onClick={handleDeleteElement}>
          <Tooltip title="Lixeira" placement="bottom-start">
            <IconToolbar
              className="width-spam-icon"
              src={iconTrash}
              description="Excluir"
            />
          </Tooltip>
        </button>
        {/* <DropdownZoom /> */}

        <ButtonRuler
          showRuler={showRuler}
          handleShowRuler={handleShowRuler}
        />
      </ContainerRight>
    </>
  );
};

export default MultiplesTool;
