import Konva from "konva";
// import { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Star, Transformer } from "react-konva";
import { Html } from "react-konva-utils";
import { PagesProps } from "../../../../../dtos/PagesProps";
import { IRenderedObject } from "../../../../../dtos/RenderedObject";

type Props = {
  onChange?: (newAttrs: IRenderedObject) => void;
  selectedObject?: IRenderedObject | Konva.Star | any;
  shapeProps?: Konva.Star | IRenderedObject | Konva.Star | any;
  setSelectedObject?:
    | React.Dispatch<React.SetStateAction<Konva.Star | Konva.Group>>
    | any;
  addToHistory?: (isLastHistory?: boolean) => void;
  currentMultipleSelection?: React.MutableRefObject<Konva.Transformer>;
  setObjectScreen?: React.Dispatch<React.SetStateAction<PagesProps[]>>;
  transformerSelectedObject?: React.MutableRefObject<Konva.Transformer>;
  handleRemoveElementGroup?: (id: string) => void;
  pendingSave?: any;
  setPageObject?: React.Dispatch<React.SetStateAction<number>>;
  isDraggingObject?: boolean;
  setIsDraggingObject?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TriangleNew = ({
  selectedObject,
  shapeProps,
  setSelectedObject,
  addToHistory,
  // setObjectScreen,
  currentMultipleSelection,
  transformerSelectedObject,
  handleRemoveElementGroup,
  // pendingSave,
  onChange,
  setPageObject,
  isDraggingObject,
  setIsDraggingObject,
}: Props) => {
  const [isSelected, setIsSelected] = useState(false);
  const shapeRef = useRef<Konva.Shape>();
  const trRef = useRef<Konva.Transformer>();

  const [showRotation, setShowRotation] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [shiftPressed, setShiftPressed] = useState(false);

  // ======>
  let isDraggable = shapeRef?.current?.draggable();
  //this code below verify if elements stay block and remove anchors for editions in scale
  // when element is block
  useEffect(() => {
    if (shapeRef.current) {
      isDraggable = shapeRef.current.draggable();
    }
  }, [shapeRef?.current?.draggable()]);
  // <=======

  useEffect(() => {
    if (shapeRef.current === selectedObject) {
      setIsSelected(true);
      transformerSelectedObject.current = trRef.current;
    } else {
      setIsSelected(false);
    }
  }, [selectedObject, shapeRef.current]);

  useEffect(() => {
    trRef.current?.setNodes([shapeRef.current]);
    trRef.current?.setZIndex(trRef.current.getParent().children.length - 1);
    trRef.current?.getLayer().batchDraw();
  }, [isSelected]);

  useEffect(() => {
    if (shapeRef.current === selectedObject) {
      setIsSelected(true);
      transformerSelectedObject.current = trRef.current;
    } else {
      setIsSelected(false);
    }
  }, [selectedObject, shapeRef.current]);

  useEffect(() => {
    trRef.current?.setNodes([shapeRef.current]);
    trRef.current?.setZIndex(trRef.current.getParent().children.length - 1);
    trRef.current?.getLayer().batchDraw();
  }, [isSelected]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <>
      <Star
        ref={shapeRef}
        name="triangleNew"
        {...shapeProps}
        innerRadius={20}
        shadowBlur={1}
        shadowColor={shapeProps.fill}
        outerRadius={40}
        numPoints={3}
        onClick={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
          const isTargetInsideMultipleSelection =
            currentMultipleSelection.current?.nodes().includes(event.target);

          if (isTargetInsideMultipleSelection) {
            setSelectedObject(null);

            if (shiftPressed) {
              handleRemoveElementGroup(event.target.attrs.id);
              return;
            }
          } else {
            setSelectedObject(event.target);
            currentMultipleSelection.current?.setNodes([]);
          }
        }}
        onDragStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
          if (
            currentMultipleSelection.current?.nodes().length === 0 ||
            !currentMultipleSelection.current
          ) {
            addToHistory();
          }
          setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
          setIsDraggingObject(true);
          //if mutipleSelection includes element being dragged
          if (
            currentMultipleSelection.current?.nodes().includes(event.target)
          ) {
            setSelectedObject(null);
          } else {
            setSelectedObject(event.target);
            currentMultipleSelection.current?.setNodes([]);
          }
        }}
        onDragEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
          setIsDraggingObject(false);
          onChange({
            ...shapeProps,
            x: event.target.absolutePosition().x,
            y: event.target.absolutePosition().y,
          });
        }}
      />

      {isSelected && showRotation && (
        <Html>
          <div
            style={{
              width: "40px",
              height: "20px",
              background: "rgba(0, 0, 0, 0.8)",
              position: "absolute",
              top: shapeProps?.y - 50,
              left: Math.ceil(shapeProps?.x - 20),
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#fff" }}>{`${rotation?.toFixed(0)}°`}</span>
          </div>
        </Html>
      )}

      {isSelected && (
        <Transformer
          ref={trRef}
          onTransform={(event: Konva.KonvaEventObject<MouseEvent>) => {
            const node = event.target;
            const rotationNode = node.rotation();
            setShowRotation(true);
            setRotation(rotationNode);
          }}
          enabledAnchors={
            isDraggable
              ? ["top-left", "top-right", "bottom-right", "bottom-left"]
              : []
          }
          onClick={(event) => {}}
          onTransformStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
            if (
              currentMultipleSelection.current?.nodes().length === 0 ||
              !currentMultipleSelection.current
            ) {
              addToHistory();
            }
          }}
          onTransformEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
            let myWidth = trRef?.current?.width();
            let myHeight = trRef?.current?.height();
            let rotation = trRef?.current.rotation();

            if (myWidth && myHeight) {
              onChange({
                ...shapeProps,
                x: event.target.absolutePosition().x,
                y: event.target.absolutePosition().y,
                rotation: rotation,
                scaleX: event.target.scaleX(),
                scaleY: event.target.scaleY(),
              });
            }
          }}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default TriangleNew;

