import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  background: rgba(211, 47, 47, 0.20);

  a {
    color: #0038ff;
  }
`;
