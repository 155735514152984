import styled from "styled-components";
import { Theme } from "../../../../../dtos/theme";
import iconSelectColor from "../../../assets/icon-selection-color.svg";

interface BoxColorInputProps {
  randomColor?: any;
  color?: any;
  positionLeftPopover: number;
  positionTopPopover: number;
  page?: "oneclick" | "editor";
}

export const DropDownContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  height: 30px;
  width: 30px;

  justify-content: center;
  position: relative;
  align-items: center;
`;

export const DropDownListContainer = styled.div`
  width: 218px;
`;

export const DropDownList = styled.ul`
  padding: 10px;
  margin: 0;
  background: white;
  color: ${({ theme }: Theme) => theme.colors.black};

  box-sizing: border-box;
  position: absolute;
  z-index: 99;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    padding-top: 1.2em;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 1.2em;
`;

export const Flex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 24px);
  gap: 5px;

  border-bottom: 1px solid #d6d8d9;
  padding-bottom: 10px;

  & button {
    background-color: transparent;
    display: flex;
  }

  .wrapper-border {
    width: 24px;
    height: 24px;
    position: relative;
    display: flex;
  }

  .button-transparent {
    width: 24px;
    height: 24px;
  }
`;

export const GridItem = styled.div`
  width: 24px;
  height: 24px;
`;

export const BoxColor = styled.li`
  width: 24px;
  height: 24px;
  background: ${({ color }) => color};
  border: ${({ color }) =>
    color === "#FFFFFF" ? "solid 2px #EBEBEC" : "none"};
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;

  & .popover-color {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
  }

  & .cover-color {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
    left: 320px;
  }

  :hover {
    border: solid 1px #000;
  }
`;

export const ActiveColor = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  background: transparent;
  border-radius: 5px;
  z-index: 999;
  border: 2px solid #5d5fef;
`;

export const BoxColorInput = styled.li<BoxColorInputProps>`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  & .btn-get-colors {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: ${() =>
    `url(${iconSelectColor}), lightgray 50% / cover no-repeat`};
  }

  & .popover-color {
    position: absolute;
    z-index: 99;
  }

  & .cover-color {
    position: fixed;
    top: ${({ positionTopPopover }) => positionTopPopover + "px"};
    right: 0px;
    bottom: 0px;
    z-index: 99;
    left: ${({ positionLeftPopover }) => positionLeftPopover + "px"};
  }
`;

export const HorizontalStack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export const ContainerInputColor = styled.form`
  flex: 1;
  input {
    padding-left: 10px;
    background-color: white;
    width: 100%;
    height: 24px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: 5px;

    font-size: 12px;
  }
`;

export const VerticalStack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 1.6rem 0;
  flex-direction: column;
`;

export const GridHistoric = styled.div`
  height: auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
`;

export const HistoricColors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Span = styled.span`
  display: flex;
`