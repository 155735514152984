import React, { useCallback, useEffect, useState } from "react";

import {
  ChooseColor,
  Container,
  CurrentColor,
  Divider,
  Grid,
  GridHistoric,
  GridItem,
  HStack,
  HistoricColors,
  Input,
  InputColor,
  Span,
  TransparencyColor
} from "./styles";

import transparencyIcon from "./assets/transparency.png";
import randomColorIcon from "./assets/randomColor.png";
import { SketchPicker } from "react-color";
import ReactQuill from "react-quill";
import { RangeStatic } from "quill";
import { ColorsPallete } from "./constants/ColorsPallete";
import { useHeaderEditor } from "../../../../hooks/headerEditor";

interface MenuColorsProps {
  refMenu?: React.MutableRefObject<HTMLDivElement>;
  callbackColor: (color: string) => void;
  currentColor: string;
  setCurrentColor: React.Dispatch<React.SetStateAction<string>>;
  quillRef?: React.MutableRefObject<ReactQuill>;
  selectionQuill: RangeStatic;
}

const MenuColors: React.FC<MenuColorsProps> = ({
  refMenu,
  callbackColor,
  currentColor,
  setCurrentColor,
  quillRef,
  selectionQuill,
}) => {
  const { delayOrganizeHistoryColors, organizeHistoryColors, colorsHistory } = useHeaderEditor();
  const [isSketchVisible, setIsSketchVisible] = useState(false);
  const [debouncedColor, setDebouncedColor] = useState(currentColor);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedColor(currentColor);
    }, 3000); // Atraso de 3 segundos

    return () => clearTimeout(timer);
  }, [currentColor]);

  const handleChangeColorSketch = useCallback(
    event => {
      setCurrentColor(event.hex);
      callbackColor(event.hex);
    },
    [setCurrentColor, callbackColor]
  );

  const handleChangeColor = useCallback(
    event => {
      // update color immediately
      if (event[0] === "#") {
        setCurrentColor(event);
        // verify if event has combination with hexadecimal format
        const hexColorRegex = /^#[0-9A-Fa-f]{6}$/;

        if (hexColorRegex.test(event)) {
          // If it responds to the pattern, set the debounceColor after 1 seconds
          setTimeout(() => {
            organizeHistoryColors(event);
            setDebouncedColor(event);
          }, 1000);
        }
      }
      else {
        // Check if the event starts with '#' and contains 6 hexadecimal digits
        const hexColorRegex = /^#[0-9A-Fa-f]{6}$/;

        setCurrentColor(`#${event}`);

        if (hexColorRegex.test(`#${event}`)) {
          //profit
          setTimeout(() => {
            organizeHistoryColors(event);
            setDebouncedColor(`#${event}`);
          }, 1000);
        }
      }
    },
    [setCurrentColor, organizeHistoryColors]
  );

  // Use debouncedColor to Quill instead of currentColor
  useEffect(() => {
    if (selectionQuill) {
      const quill = quillRef.current.getEditor();
      quill.formatText(
        selectionQuill.index,
        selectionQuill.length,
        "color",
        debouncedColor
      );
    }
  }, [debouncedColor, quillRef, selectionQuill]);

  return (
    <Container>
      <Grid>
        {ColorsPallete.map(color => (
          <GridItem
            isActive={color.active}
            color={color.hexadecimal}
            currentColor={currentColor}
            onClick={() => {
              callbackColor(color.hexadecimal)
              organizeHistoryColors(color.hexadecimal)
            }}
          ></GridItem>
        ))}
      </Grid>
      <Divider />

      <HStack>
        {currentColor === "rgba(0, 0, 0, 0)" ? (
          <TransparencyColor
            isTransparency
            // onClick={() => callbackColor("rgba(0, 0, 0, 0)")}
            url={transparencyIcon}
          />
        ) : (
          <CurrentColor color={currentColor} />
        )}

        <InputColor>
          <Input
            type="text"
            placeholder="#Ddddd"
            onChange={event => handleChangeColor(event.target.value)}
            value={currentColor}
          />
        </InputColor>

        {/* <TransparencyColor
          onClick={() => callbackColor("rgba(0, 0, 0, 0)")}
          url={transparencyIcon}
        /> */}

        <ChooseColor
          onClick={() => setIsSketchVisible(prev => !prev)}
          url={randomColorIcon}
        />
      </HStack>

      <Divider />

      <HistoricColors>
        <Span>{colorsHistory.length > 0 ? 'Cores recentes' : 'Não há cores recentes'}</Span>
        <GridHistoric>
          {colorsHistory.map(color => (
            <GridItem
              color={color.hexadecimal}
              currentColor={currentColor}
              onClick={() => callbackColor(color.hexadecimal)}
            >
            </GridItem>
          ))}
        </GridHistoric>
      </HistoricColors>


      {isSketchVisible && (
        <div style={{ position: "absolute", left: "230px", top: "80px" }}>
          <SketchPicker
            width={"218px"}
            // disableAlpha
            presetColors={[]}
            color={currentColor}
            onChange={handleChangeColorSketch}
            onChangeComplete={(e) => delayOrganizeHistoryColors(e.hex)}
          // onSwatchHover={event => console.log("oi")}
          />
        </div>
      )}
    </Container>
  );
};

export default MenuColors;