import React, { useState } from "react";

import { Dialog, DialogTitle, IconButton } from "@mui/material";
import {
  CancelButton,
  Container,
  ContainerButton,
  FormGroup,
  Input,
  Label,
  LoadingContainer,
  SaveButton,
  Subtitle
} from "./styles";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { IconCloseWrapper } from "../../styles";
import { GrClose } from "react-icons/gr";
import {
  ApprovalFlowRequest,
  MemberRequest
} from "../../types/ApprovalFlowRequest";
import { useAuth } from "../../../../../hooks/auth";
import api from "../../../../../services/api";

import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
type Props = {
  open: boolean;
  onClose: () => void;
};

const CreateNewFlow: React.FC<Props> = ({ open, onClose }: Props) => {
  const navigate = useNavigate();
  const [flowName, setFlowName] = useState("");
  const { data } = useAuth();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    if (!flowName) {
      toast.error("Nome do fluxo é obrigatório");
      return;
    }
    setLoading(true);
    const flowId = uuidv4();
    const flow: ApprovalFlowRequest = {
      id: flowId,
      name: flowName,
      status: "PENDING",
      steps: [],
      members: [
        {
          id: uuidv4(),
          approval_flow_id: flowId,
          role: "editor",
          member_id: data?.user.id
        } as MemberRequest
      ],
      is_template: true
    };

    await api.post("/approval-flows", flow);
    onClose();
    navigate(`/approvalflow/${flowId}/flow-steps/`);
    setLoading(false);
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth={"md"}>
      <DialogTitle style={{ fontWeight: 700, fontSize: "16px" }}>
        Criar novo fluxo de aprovação
      </DialogTitle>
      <>
        <IconCloseWrapper onClick={() => onClose()}>
          <GrClose />
        </IconCloseWrapper>

        <Container>
          <Subtitle>Dê um nome para o seu fluxo de aprovação</Subtitle>
          <FormGroup>
            <Label>Nome do fluxo</Label>
            <Input
              value={flowName}
              onChange={event => setFlowName(event.target.value)}
            />
          </FormGroup>

          <ContainerButton>
            <CancelButton onClick={onClose}>Cancelar</CancelButton>
            <SaveButton onClick={onSubmit}>
              {(loading && <PuffLoader color="#FFF" size="20px" />) || "Salvar"}
            </SaveButton>
          </ContainerButton>
        </Container>
      </>
    </Dialog>
  );
};

export default CreateNewFlow;
