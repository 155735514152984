import Comment from "../../../Comment";
import { Container, BoxComments, Header } from "./styled";
import IconLeft from "../../../../../../../dashboard/Teams/assets/arrow-left-icon.svg";
import ReplyComment from "../../../ReplyComment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useComments } from "../../../../../../hooks/comments";
import api from "../../../../../../../../services/api";
import { LoadingBox } from "../ListAllCommentsPage/styled";
import { ContractAnimation } from "../../../../../../../../shared/components/ContractAnimation";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import { AxiosResponse } from "axios";

export interface ICategoryComment {
  page: "All" | "Historic";
  isOpenComment: boolean;
}

interface ListSpecificProps {
  categoryComment: ICategoryComment;
  setCategoryComment: React.Dispatch<React.SetStateAction<ICategoryComment>>;
}

interface CommentSpecificProps {
  id: string;
  user_id: string;
  user_template_id: string;
  message: string;
  position: string;
  is_excluded: boolean;
  is_edited: boolean;
  is_done: boolean;
  page: string;
  created_at: string;
  updated_at: string;
  avatar: string;
  avatar_url: string;
  first_name: string;
  last_name: string;
  count_answers: number;
}

interface ReplyCommentsProps {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  avatar_url: string;
  user_id: string;
  user_template_comment_id: string;
  message: string;
  is_edited: boolean;
  created_at: string;
  updated_at: string;
}

interface ResponseReplyCreateProps {
  id: string;
  user_id: string;
  user_template_comment_id: string;
  message: string;
  is_edited: boolean;
  created_at: string;
  updated_at: string;
  avatar: string;
  avatar_url: string;
  first_name: string;
  last_name: string;
}

interface CommentSpecificProps {
  id: string;
  user_id: string;
  user_template_id: string;
  message: string;
  position: string;
  is_excluded: boolean;
  is_edited: boolean;
  is_done: boolean;
  page: string;
  created_at: string;
  updated_at: string;
  avatar: string;
  avatar_url: string;
  first_name: string;
  last_name: string;
  count_answers: number;
}

const ListSpecificComentPage: React.FC<ListSpecificProps> = ({
  categoryComment,
  setCategoryComment
}) => {
  const { selectedIdComment, setIsCommentsVisible, setListCommentsCanvas } =
    useComments();
  const refInput = useRef<HTMLTextAreaElement>();
  const [textareaFocused, setTextareaFocused] = useState(false);

  const [inputEditComment, setInputEditComment] = useState("");
  const [valueReply, setValueReply] = useState("");
  const [replyData, setReplyData] = useState<ReplyCommentsProps[]>([]);

  const [commentSpecific, setCommentSpecific] = useState(
    {} as CommentSpecificProps
  );
  const [loading, setLoading] = useState(true);
  const [lengthReplies, setLengthReplies] = useState(0);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && textareaFocused) {
      event.preventDefault(); // Prevent default "Enter" behavior (e.g., line break)
      handleAddReplyToComment();
    }
  };

  const handleAddReplyToComment = useCallback(async () => {
    try {
      const objectReply = {
        user_template_comment_id: commentSpecific.id,
        message: valueReply
      };

      const responseAddReply: AxiosResponse<ResponseReplyCreateProps> =
        await api.post(`user-comment-answers`, objectReply);

      let newObject = {
        ...responseAddReply.data,
        avatar_url: responseAddReply.data.avatar_url,
        first_name: responseAddReply.data.first_name,
        last_name: responseAddReply.data.last_name,
        avatar: responseAddReply.data.avatar
      };

      setReplyData(prevState => {
        let cloneState = cloneDeep(prevState);
        cloneState.push(newObject);
        return cloneState;
      });

      setLengthReplies(oldState => (oldState += 1));

      setValueReply("");
      toast.success("Resposta enviada com sucesso.");
    } catch (err) {
      toast.error("Ocorreu um erro.");
      console.error(err, "ERROr when get add reply");
    }
  }, [commentSpecific, valueReply, setLengthReplies]);

  const handleEditReplyComment = useCallback(
    async (reply: ReplyCommentsProps) => {
      try {
        const commentObject = {
          comment_answers_id: reply.id,
          message: inputEditComment
        };

        const responseEditReply = await api.put(
          "user-comment-answers",
          commentObject
        );

        // setCommentSpecific({ ...commentSpecific, message: inputEditComment });
        setReplyData(oldState => {
          let cloneState = cloneDeep(oldState);

          cloneState.forEach(replyObject => {
            if (replyObject.id === reply.id) {
              replyObject.message = inputEditComment;
            }
          });

          return cloneState;
        });
        toast.success("Resposta editada com sucesso!");
        setInputEditComment("");
      } catch (err) {
        console.error(err, "error when edit commentary");
        toast.error("Ocorreu um erro.");
      }
    },
    [inputEditComment, setReplyData]
  );

  const handleDeleteReplyComment = useCallback(
    async (reply: ReplyCommentsProps) => {
      try {
        const deleteReplyOfComment = await api.delete(
          `user-comment-answers/${reply.id}`
        );

        setReplyData(oldState => {
          let cloneState = cloneDeep(oldState);
          let newState = cloneState.filter(
            replyComment => replyComment.id !== reply.id
          );
          return newState;
        });

        setLengthReplies(oldState => (oldState -= 1));
        toast.success("Resposta removida com sucesso.");
      } catch (err) {
        toast.error("Ocorreu erro ao remover sua resposta.");
        console.error(err, "error when delete reply to comment");
      }
    },
    [setReplyData, setLengthReplies]
  );

  const handleEditCommentary = useCallback(async () => {
    try {
      const commentObject = {
        template_comment_id: commentSpecific.id,
        message: inputEditComment,
        position: commentSpecific.position,
        page: commentSpecific.page
      };

      const responseEditCommentary = await api.put(
        "user-template-comments",
        commentObject
      );

      setCommentSpecific({ ...commentSpecific, message: inputEditComment });
      toast.success("Comentario editado com sucesso!");
      setInputEditComment("");
    } catch (err) {
      console.error(err, "error when edit commentary");
      toast.error("Ocorreu um erro.");
    }
  }, [inputEditComment, setCommentSpecific]);

  const handleDoneCommentary = useCallback(
    async (id: string, action: "done" | "edit" | "remove") => {
      if (action === "done") {
        try {
          const responseDoneComment = await api.put(
            "user-template-comments/conclude",
            {
              template_comment_id: id
            }
          );

          setListCommentsCanvas(oldState => {
            let cloneState = cloneDeep(oldState);
            const arrayWithoutObject = cloneState.filter(
              comment => comment.id !== id
            );
            return arrayWithoutObject;
          });

          setIsCommentsVisible({ active: true, screen: "listAllComments" });

          toast.success("Comentario concluido com sucesso.");

          // setIsCommentsVisible({ active: false, screen: "none" });
        } catch (err) {
          console.error(err, "error");
        }
      }
    },
    [setIsCommentsVisible, setListCommentsCanvas]
  );

  const handleDeleteCommentary = useCallback(
    async (id: string, action: "done" | "edit" | "remove") => {
      if (action === "remove") {
        try {
          const responseDoneComment = await api.delete(
            `user-template-comments/${id}`
          );
          setListCommentsCanvas(oldState => {
            let cloneState = cloneDeep(oldState);
            const arrayWithoutObject = cloneState.filter(
              comment => comment.id !== id
            );
            return arrayWithoutObject;
          });

          toast.success("Comentario removido com sucesso2.");

          setCategoryComment(prev => {
            return {
              page: prev.page,
              isOpenComment: false
            };
          });
        } catch (err) {
          console.error(err, "error");
        }
      }
    },
    [setIsCommentsVisible, setListCommentsCanvas]
  );

  useEffect(() => {
    (async () => {
      try {
        if (!!selectedIdComment) {
          setLoading(true);
          const responseSpecificComment = await api.get(
            `user-template-comments/${selectedIdComment}`
          );

          setCommentSpecific(responseSpecificComment.data.templateComments);
          setLengthReplies(responseSpecificComment.data.dataArray.length);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.error(err, "error when get specific commentary");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!!selectedIdComment) {
          const getReplies = await api.get(
            `user-template-comments/${selectedIdComment}`,
            {
              params: {
                pagination: 0
              }
            }
          );

          setReplyData(getReplies.data.dataArray);
        }
      } catch (err) {
        console.error(err, "error when get replies");
      }
    })();
  }, [selectedIdComment]);

  return (
    <Container>
      {loading ? (
        <LoadingBox>
          <ContractAnimation />
        </LoadingBox>
      ) : (
        <>
          <Header
            onClick={() =>
              setCategoryComment(prev => {
                return {
                  page: prev.page,
                  isOpenComment: false
                };
              })
            }
          >
            <div>
              <img src={IconLeft} alt="" />
              <p>
                {categoryComment.page === "All"
                  ? "Todos os comentários"
                  : "Histórico de comentários"}
              </p>
            </div>
          </Header>
          <div>
            <Comment
              avatar={commentSpecific.avatar_url}
              numberPage={commentSpecific.page}
              commentDate={commentSpecific.created_at}
              message={commentSpecific.message}
              type="Main Comment"
              categoryComment={categoryComment.page}
              idUser={commentSpecific.user_id}
              amountReplies={lengthReplies}
              inputEditComment={inputEditComment}
              setInputEditComment={setInputEditComment}
              isShowReplies
              handleEditComment={() => handleEditCommentary()}
              handleDeleteCommentary={() => {
                handleDeleteCommentary(commentSpecific.id, "remove");
              }}
              handleDoneCommentary={() =>
                handleDoneCommentary(commentSpecific.id, "done")
              }
              name={`${commentSpecific.first_name} ${commentSpecific.last_name}`}
            />
            <BoxComments page={categoryComment.page === "Historic"}>
              {replyData.map(reply => (
                <Comment
                  avatar={reply.avatar_url}
                  type="Response"
                  idUser={reply.user_id}
                  key={reply.id}
                  categoryComment={categoryComment.page}
                  commentDate={reply.created_at}
                  message={reply.message}
                  name={`${reply.first_name} ${reply.last_name}`}
                  inputEditComment={inputEditComment}
                  setInputEditComment={setInputEditComment}
                  handleEditComment={() => handleEditReplyComment(reply)}
                  handleDeleteCommentary={() => handleDeleteReplyComment(reply)}
                />
              ))}
            </BoxComments>
            {categoryComment.page === "All" && (
              <ReplyComment
                onClick={() => {
                  handleAddReplyToComment();
                }}
                refInput={refInput}
                onBlur={() => setTextareaFocused(false)}
                onFocus={() => setTextareaFocused(true)}
                valueReply={valueReply}
                setValueReply={setValueReply}
                handleKeyDown={handleKeyDown}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default ListSpecificComentPage;
