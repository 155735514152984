import React, { useCallback, useEffect, useState } from "react";
import {
  LoadingContainer,
  MenuItemWrapper,
  THead,
  Table,
  TableContainer,
  TableData,
  TableHead,
  TableRow
} from "./styles";
import NavigationBar from "../NavigationBar";
import {
  Title,
  Header,
  SearchContainer,
  SearchLeftSide,
  SearchButton,
  CreateNewButton,
  FullContent,
  Container
} from "../../styles";
import FilterIcon from "../FilterIcon";
import AddIcon from "../AddIcon";
import ThreeDotsIcon from "../ThreeDotsIcon";
import { Avatar, Box, Menu, MenuItem } from "@material-ui/core";
import DownloadIcon from "../DownloadIcon";
import TrashIcon from "../TrashIcon";
import SearchInput from "../SearchBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import Tooltip from "../../../../editor/components/Tooltip";
import { useAuth } from "../../../../../hooks/auth";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { BiInfoCircle } from "react-icons/bi";
import { ApprovalProject } from "../../types/ApprovalProject";
import CreateNewProject from "../CreateNewProject";
import DeleteProject from "../DeleteProject";
import api from "../../../../../services/api";
import DocInfoIcon from "../DocInfoIcon";
import { ToastContainer, toast } from "react-toastify";
import ResendEmailIcon from "../ProjectDetails/ResendEmailIcon";
import DropdownFilter, { DropdownOption } from "../DropdownFilter";
import FilterOffIcon from "../FilterOffIcon";
import SearchInputBase from "../SearchInputBase";
import Pagination from "../../../components/Pagination";

export const projectStatusOptions = [
  { value: "PENDING", label: "Pendente" },
  { value: "APPROVED", label: "Aprovado" },
  { value: "REJECTED", label: "Rejeitado" },
  { value: "CANCELED", label: "Cancelado" }
];

const MyApprovalProjects: React.FC = () => {
  const navigator = useNavigate();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [activeRow, setActiveRow] = useState<number>();
  const { data } = useAuth();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [totalPages, setTotalPages] = useState(1);
  const { pagination } = params;
  const pageNumber = pagination === undefined ? 0 : parseInt(pagination) - 1;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [projects, setProjects] = useState<ApprovalProject[]>([]);
  const [project, setProject] = useState<ApprovalProject>();
  const [deleteProjectOpen, setDeleteProjectOpen] = useState(false);

  const [flowOptions, setFlowOptions] = useState<DropdownOption[]>([]);
  const [stepOptions, setStepOptions] = useState<DropdownOption[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedFlowNames, setSelectedFlowNames] = useState<string[]>([]);
  const [selectedStepNames, setSelectedStepNames] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [creation_date, setCreationDate] = useState<String>();
  const [resendDocumentOpen, setResendDocumentOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const roles = {
    owner: "Proprietário",
    user: "Membro",
    editor: "Membro"
  };

  const fetchProjects = useCallback(async () => {
    console.log("fetching projects");
    setLoading(true);

    let params = [];

    params.push(`pagination=${pageNumber}`);

    if (search) {
      params.push(`search=${search}`);
    }

    if (selectedFlowNames.length > 0) {
      selectedFlowNames.forEach(flow => {
        params.push(`flow_names[]=${flow}`);
      });
    }

    if (selectedStepNames.length > 0) {
      selectedStepNames.forEach(step => {
        params.push(`step_names[]=${step}`);
      });
    }

    if (selectedStatuses.length > 0) {
      selectedStatuses.forEach(status => {
        params.push(`statuses[]=${status}`);
      });
    }

    if (creation_date) {
      params.push(`creation_date=${creation_date}`);
    }

    const query = params.join("&");
    const response = await api.get(`/approval-projects?${query}`);
    const { data } = response;
    // sort projects by creation date
    data.sort((a: ApprovalProject, b: ApprovalProject) => {
      return (
        new Date(b.started_at).getTime() - new Date(a.started_at).getTime()
      );
    });
    setProjects(data);

    if (data.length > 0) {
      const totalItems = data[0].total_pages;
      if (totalItems > 0) {
        const totalPages = Math.ceil(totalItems / 8);
        setTotalPages(totalPages - 1);
      }
    }
    setLoading(false);
  }, [
    creation_date,
    pageNumber,
    search,
    selectedFlowNames,
    selectedStatuses,
    selectedStepNames
  ]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const getAvatar = (fullname: string, index: number) => {
    return (
      <Tooltip title={fullname} placement="bottom" key={fullname}>
        <Avatar
          alt={fullname}
          src="/static/images/avatar/1.jpg"
          {...stringAvatar(fullname)}
          style={{
            width: "30px",
            height: "30px",
            backgroundColor: stringToColor(fullname),
            marginLeft: index > 0 ? "-10px" : "0"
          }}
        />
      </Tooltip>
    );
  };

  const deleteProject = (project: ApprovalProject) => {
    setProject(project);
    setDeleteProjectOpen(true);
  };

  const statuses = {
    PENDING: "Pendente",
    APPROVED: "Aprovado",
    REJECTED: "Rejeitado",
    CANCELED: "Cancelado"
  };

  const goToDetails = (project: ApprovalProject) => {
    navigator(`/approvalflow/project-details/${project?.id}`);
  };

  const handleDownload = async (id: string) => {
    try {
      handleClose();
      toast.info("Baixando documento...");
      const response = await api.get(`/approval-projects/${id}`);
      const project = response.data as ApprovalProject;
      const flows = project.approval_flows;
      const lastFlow = flows[flows.length - 1];
      const responseFile = await api.get(
        `/approval-projects/${project.id}/flows/${lastFlow.id}/document-pdf`
      );
      // Convert base64 to binary
      const binary = atob(responseFile.data);
      const array = [];

      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }

      // Create a blob from the binary data
      const blob = new Blob([new Uint8Array(array)], {
        type: "application/pdf"
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${project.name}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log(error);
      toast.error(
        "Ocorreu um erro ao baixar o documento. Contate o suporte técnico."
      );
    }
  };

  const getCurrentStep = (project: ApprovalProject) => {
    if (project.status === "CANCELED") {
      return "Cancelado";
    } else if (project.status === "APPROVED") {
      return "Finalizado";
    } else if (project.current_step_status === "REFUSED") {
      return "Aguardando ajustes";
    } else {
      return project.current_step;
    }
  };

  const getProjectStatus = (project: ApprovalProject) => {
    if (project.current_step_status === "REFUSED") {
      return "Recusado";
    } else {
      return statuses[project.status];
    }
  };

  useEffect(() => {
    const listOptions = async () => {
      const response = await api.get(`approval-flows/flows/steps`);
      const { data } = response;
      const flows = data.flowOptions.map(flow => ({
        value: flow.name,
        label: flow.name
      }));
      const steps = data.stepOptions.map(step => ({
        value: step.name,
        label: step.name
      }));
      setFlowOptions(flows);
      setStepOptions(steps);
    };

    listOptions();
  }, []);

  return (
    <>
      <FullContent>
        <ToastContainer />
        <Container>
          <Header>
            <Title>Fluxo de Aprovação</Title>
            <Tooltip
              description={"Este é o local onde você pode criar os seus fluxos"}
              placement="bottom-start"
            >
              <figure>
                <BiInfoCircle />
              </figure>
            </Tooltip>
          </Header>
          <NavigationBar activeTab="Meus Projetos" />
          <SearchContainer>
            <SearchLeftSide>
              <SearchInputBase
                placeholder="Pesquisar"
                value={search}
                onChange={e => setSearch(e.target.value)}
                setSearch={setSearch}
              />
              <SearchButton
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                {!showFilters && <FilterIcon color="#7547A3" />}

                {showFilters && <FilterOffIcon />}
              </SearchButton>
              {showFilters && (
                <Stack direction={"row"} spacing={1}>
                  <DropdownFilter
                    options={flowOptions}
                    onChange={options => setSelectedFlowNames(options)}
                    label={"Fluxo"}
                    showSearch
                  />
                  <DropdownFilter
                    options={stepOptions}
                    onChange={options => setCreationDate(options[0])}
                    label={"Iniciado em"}
                    isDateInput
                  />
                  <DropdownFilter
                    options={projectStatusOptions}
                    onChange={options => setSelectedStatuses(options)}
                    label={"Status"}
                  />
                  <DropdownFilter
                    options={stepOptions}
                    onChange={options => setSelectedStepNames(options)}
                    label={"Etapa"}
                  />
                </Stack>
              )}
            </SearchLeftSide>
            <CreateNewButton
              onClick={() => {
                setCreateDialogOpen(true);
              }}
            >
              <AddIcon color={"#FFFF"} /> Criar nova aprovação
            </CreateNewButton>
          </SearchContainer>
          <TableContainer>
            <Table>
              <THead>
                <tr>
                  <TableHead>Nome do projeto</TableHead>
                  <TableHead>Fluxo</TableHead>
                  <TableHead>Iniciado em</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Etapa atual</TableHead>
                  <TableHead>Aprovação planejada</TableHead>
                  <TableHead>Pendente com</TableHead>
                  <TableHead></TableHead>
                </tr>
              </THead>

              {!loading && (
                <tbody>
                  {projects.map((project, index) => (
                    <TableRow
                      key={project.id}
                      onMouseEnter={() => setActiveRow(index)}
                      onMouseLeave={() => {
                        setActiveRow(undefined);
                        handleClose();
                      }}
                    >
                      <TableData>{project.name}</TableData>
                      <TableData>{project.flow_name}</TableData>
                      <TableData>
                        {new Date(project.started_at).toLocaleDateString()}
                      </TableData>
                      <TableData>{getProjectStatus(project)}</TableData>
                      <TableData>{getCurrentStep(project)}</TableData>
                      <TableData>
                        {new Date(
                          project.planned_approval_date
                        ).toLocaleDateString()}
                      </TableData>
                      <TableData>
                        {" "}
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                        >
                          {project.pending_approvers.map((approver, index) =>
                            getAvatar(approver.name, index)
                          )}
                        </Stack>
                      </TableData>
                      <TableData style={{ padding: 0, cursor: "pointer" }}>
                        {activeRow === index && (
                          <>
                            <Box
                              id={`action-button_${index}`}
                              aria-controls={
                                open ? `action-menu_${index}` : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                            >
                              <ThreeDotsIcon />
                            </Box>

                            <Menu
                              id={`action-menu_${index}`}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": `action-button_${index}`
                              }}
                            >
                              {project.current_step_status === "REFUSED" && (
                                <MenuItem
                                  onClick={() => {
                                    setResendDocumentOpen(true);
                                    setProject(project);
                                  }}
                                >
                                  <MenuItemWrapper>
                                    <ResendEmailIcon />
                                    Reenviar documento
                                  </MenuItemWrapper>
                                </MenuItem>
                              )}
                              <MenuItem onClick={() => goToDetails(project)}>
                                <MenuItemWrapper>
                                  <DocInfoIcon color="#676B70" />
                                  Detalhes
                                </MenuItemWrapper>
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleDownload(project.id)}
                              >
                                <MenuItemWrapper>
                                  <DownloadIcon color="#676B70" /> Baixar
                                </MenuItemWrapper>
                              </MenuItem>

                              <MenuItem onClick={() => deleteProject(project)}>
                                <MenuItemWrapper>
                                  <TrashIcon color="#676B70" />
                                  Excluir
                                </MenuItemWrapper>
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </TableData>
                    </TableRow>
                  ))}
                </tbody>
              )}
            </Table>
            {!loading && projects.length === 0 && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"50vh"}
                fontSize={"14px"}
                color={"#676B70"}
                p={2}
              >
                Nenhum projeto encontrado
              </Box>
            )}
            {loading && (
              <LoadingContainer>
                <ContractAnimation />
              </LoadingContainer>
            )}
          </TableContainer>
          <Box
            bgcolor={"white"}
            position={"fixed"}
            bottom={"10px"}
            right={"10px"}
            width={"calc(100% - 260px)"}
            p={2}
          >
            <Pagination
              total={totalPages}
              pageNow={pageNumber}
              screen="approvalflow/my-projects"
            />
          </Box>
          {createDialogOpen && (
            <CreateNewProject
              open={createDialogOpen}
              onClose={refresh => {
                if (refresh) fetchProjects();
                setCreateDialogOpen(false);
              }}
            />
          )}
          {deleteProjectOpen && (
            <DeleteProject
              open={deleteProjectOpen}
              project_id={project.id}
              onClose={refresh => {
                setDeleteProjectOpen(false);
                if (refresh) fetchProjects();
              }}
            />
          )}
          {resendDocumentOpen && (
            <CreateNewProject
              open={resendDocumentOpen}
              onClose={refresh => {
                if (refresh) {
                  // do something
                }
                setResendDocumentOpen(false);
              }}
              resend={true}
              editing_project_id={project?.id}
            />
          )}
        </Container>
      </FullContent>
    </>
  );
};

export default MyApprovalProjects;

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const nameParts = name.split(" ");
  let initials;

  if (nameParts.length > 1) {
    initials = `${nameParts[0][0]}${nameParts[1][0]}`;
  } else {
    const firstLetter = name.charAt(0);
    const secondLetter = name.charAt(1);
    initials = `${firstLetter}${secondLetter}`;
  }
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: initials
  };
}
