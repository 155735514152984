import { useTeams } from "../../../../../hooks/teams";
import ModalAddParticipant from "./ModalAddparticipant";
import ModalCreateTeams from "./ModalCreateTeams";
import ModalCreateText from "./ModalCreateText";
import ModalDeleteParticipant from "./ModalDeleteParticipant";
import ModalDeleteTeams from "./ModalDeleteTeams";
import ModalDeleteText from "./ModalDeleteText";
import ModalImportTemplate from "./ModalImportTemplate";
import ModalLeaveTeam from "./ModalLeaveTeam";
import ModalViewText from "./ModalViewText";
import { Container } from "./styled";

const ModalTeams = () => {
  const {
    selectModal,
    setIsSettings,
    isSettings,
    setSelectModal,
    isOwnerStaff,
    handleDeleteClauseTeam,
    clauseId,
    handleTeamLeave,
    nameOfTeam,
    idLeaveTeam
  } = useTeams();

  if (selectModal.modal === "Create Team") {
    return (
      <Container>
        <ModalCreateTeams setSelectModal={setSelectModal} />
      </Container>
    );
  } else if (selectModal.modal === "Add Participant") {
    return (
      <Container>
        <ModalAddParticipant
          isSettings={isSettings}
          setIsSettings={setIsSettings}
          setSelectModal={setSelectModal}
        />
      </Container>
    );
  } else if (selectModal.modal === "Delete Team") {
    return (
      <Container>
        <ModalDeleteTeams setSelectModal={setSelectModal} />
      </Container>
    );
  } else if (selectModal.modal === "Delete Participant") {
    return (
      <Container>
        <ModalDeleteParticipant setSelectModal={setSelectModal} />
      </Container>
    );
  } else if (selectModal.modal === "Create New Text") {
    return (
      <Container>
        <ModalCreateText
          setSelectModal={setSelectModal}
          isSettings={isSettings}
          setIsSettings={setIsSettings}
        />
      </Container>
    );
  } else if (selectModal.modal === "View Text") {
    return (
      <Container>
        <ModalViewText
          setIsSettings={setIsSettings}
          setSelectModal={setSelectModal}
          isOwnerTeam={isOwnerStaff}
        />
      </Container>
    );
  } else if (selectModal.modal === "Import Templates") {
    return (
      <Container>
        <ModalImportTemplate setSelectModal={setSelectModal} />
      </Container>
    );
  } else if (selectModal.modal === "Delete Text") {
    return (
      <Container>
        <ModalDeleteText
          setSelectModal={setSelectModal}
          handleDeleteClauseTeam={handleDeleteClauseTeam}
          clauseId={clauseId}
        />
      </Container>
    );
  } else if (selectModal.modal === "Leave Team") {
    return (
      <Container>
        <ModalLeaveTeam
          id={idLeaveTeam}
          setSelectModal={setSelectModal}
          handleTeamLeave={handleTeamLeave}
          nameOfTeam={nameOfTeam}
        />
      </Container>
    );
  }
};

export default ModalTeams;
