import React from "react";
import ChangePassword from "../../components/ChangePassword";

// import { Container } from './styles';

const ChangePasswordPage: React.FC = () => {
  return (
    <>
      <ChangePassword />
    </>
  );
};

export default ChangePasswordPage;
