import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMainHook } from "../../../hooks/main";

import Konva from "konva";
import { useSelection } from "./selection";
import { cloneDeep } from "lodash";
import { changeSelectedObject } from "../utils/changeSelectedObject";
import { automaticSelection } from "../utils/automaticSelection";
import { arrayMoveImmutable } from "array-move";
import { usePagesEditor } from "./pagesEditor";
import { IRenderedObject } from "../../../dtos/RenderedObject";
import { automaticSelectionWithoutPage } from "../utils/automaticSelectionWithoutPage";

interface ToolbarEditorProviderProps {
  children: ReactNode;
}

interface ToolbarEditorData {
  isScale: number;
  setIsScale: React.Dispatch<React.SetStateAction<number>>;
  isTranslationX: number;
  setIsTranslationX: React.Dispatch<React.SetStateAction<number>>;
  isTranslationY: number;
  setIsTranslationY: React.Dispatch<React.SetStateAction<number>>;
  valueZoom: number;
  setValueZoom: React.Dispatch<React.SetStateAction<number>>;
  setColorToElement: (color: string) => void;
  setColorToElementBorder: (color: string) => void;
  deleteElement: () => void;
  editFontSizeMax: (number: string | number) => void;
  editFontSizeMin: (number: string | number) => void;
  sizeOfFont: number | null;
  setSizeOfFont: (arg: number) => void;
  strongFontWeight: () => void;
  isStrong: boolean;
  setIsStrong: React.Dispatch<React.SetStateAction<boolean>>;
  isItalic: boolean;
  setIsItalic: React.Dispatch<React.SetStateAction<boolean>>;
  italicElement: () => void;
  isUnderline: boolean;
  setIsUnderline: React.Dispatch<React.SetStateAction<boolean>>;
  elementUnderline: () => void;
  bringElementToFront: () => void;
  pushElementToBehind: () => void;
  toggleBlockElement: () => void;
  isDraggable: boolean;
  toggleLowerOrUppercase: () => void;
  toggleLineHeight: (spacing: number) => void;
  selectedOptionFont: string;
  setSelectedOptionFont: React.Dispatch<React.SetStateAction<string>>;
  selectedNewFont: (font: string) => void;
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  toggleAlignText: (value: string) => void;
  isOpenAlign: boolean;
  setIsOpenAlign: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetIsOpenAlign: () => void;
  toggleChangeStroke: (size: any) => void;
  changeBorderRadius: number | null | undefined;
  setChangeBorderRadius: React.Dispatch<any>;
  elementBorderRadiusIncrement: (number: number) => void;
  elementBorderRadiusDecrement: (number: number) => void;
  makeGroup: () => void;
  ungroup: () => void;
  toggleElementDash: (dash: number, spacing: number) => void;
  placeholderOfFont: any;
  setPlaceholderOfFont: any;

  valueOpacity: number;
  setValueOpacity: React.Dispatch<React.SetStateAction<number>>;
  handleChangeOpacity: (value: number) => void;
  typeLine: string;
  setTypeLine: React.Dispatch<React.SetStateAction<string>>;
  dashedOption: { dash: number; spacing: number };
  setDashedOption: React.Dispatch<
    React.SetStateAction<{
      dash: number;
      spacing: number;
    }>
  >;
  handleAllToFront: (idPage: number) => void;
  handleAllToBack: (idPage: number) => void;
  handleChangeStroke: (stroke: number) => void;
}

const ToolbarEditorContext = createContext({} as ToolbarEditorData);
const ToolbarEditorProvider: React.FC = ({
  children,
}: ToolbarEditorProviderProps) => {
  const [isScale, setIsScale] = useState(1);
  const [isTranslationX, setIsTranslationX] = useState(0);
  // const [blockCommandKeyboard, setBlockCommandKeyboard] = useState(false);
  const [isTranslationY, setIsTranslationY] = useState(0);
  const [valueOpacity, setValueOpacity] = useState(100);
  const [valueZoom, setValueZoom] = useState(null);
  const [isDraggable, setIsDraggable] = useState(false);
  const [typeLine, setTypeLine] = useState<'linear' | 'dashed'>('linear')
  const {
    addToHistory,
    stageRef,
    isUpLowerCase,
    setIsUpLowerCase,
    setObjectScreen,
    objectScreen,
    pendingSave,
  } = useMainHook();

  const { idPage } = usePagesEditor();

  const {
    setSelectedObject,
    selectedObject,
    selectedObjects,
    setSelectedObjects,
    currentMultipleSelection,
  } = useSelection();

  const [selectedOptionFont, setSelectedOptionFont] = useState("");
  const [sizeOfFont, setSizeOfFont] = useState(null);
  const [placeholderOfFont, setPlaceholderOfFont] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrong, setIsStrong] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isOpenAlign, setIsOpenAlign] = useState(false);
  const [changeBorderRadius, setChangeBorderRadius] = useState(null);

  const [dashedOption, setDashedOption] = useState({ dash: 5, spacing: 5 });

  const {
    blockCommandKeyboard,
    setBlockCommandKeyboard,
    forceBlockKey,
    setForceBlockKey,
  } = useSelection();

  const handleChangeStroke = useCallback(
    (stroke: number) => {
      if (selectedObject) {
        toggleChangeStroke(stroke);
      } else {
        return;
      }
    },
    [selectedObject]
  );

  const handleAllToBack = useCallback(
    (idPage: number) => {
      // let idPage = Number(selectedObject?.attrs?.page);
      let myId: string = selectedObject?.attrs?.id;
      let indexObjectModify: number;
      let modificationFinalArray: IRenderedObject[];
      let currentPageForModification: number;
      let breakPage = false;

      objectScreen.forEach((page) => {
        if (!breakPage) {
          page.renderObjects.forEach((renderedObject) => {
            if (renderedObject.id === selectedObject.attrs.id) {
              currentPageForModification = page.pageNumber;
              breakPage = true;
            }
          });
        }
      });

      setObjectScreen((oldState) => {
        let cloneState = cloneDeep(oldState);
        cloneState.forEach((page) => {
          if (page.pageNumber == currentPageForModification) {
            let myRenders = cloneDeep(page);
            page.renderObjects.forEach((renderedObject, index) => {
              if (renderedObject.id === myId) {
                indexObjectModify = index;
              }
            });
            let newArray = cloneDeep(myRenders.renderObjects);

            modificationFinalArray = arrayMoveImmutable(
              newArray,
              indexObjectModify,
              0
            );
          }
        });

        cloneState[currentPageForModification - 1].renderObjects =
          modificationFinalArray;
        return cloneState;
      });

      pendingSave.current = true;

      setSelectedObject(null);

      setTimeout(() => {
        automaticSelectionWithoutPage({
          setSelectedObject,
          myId,
          stageRef,
          objectScreen,
          currentPageForModification,
        });
      }, 50);
    },
    [
      selectedObject?.attrs?.id,
      setObjectScreen,
      setSelectedObject,
      stageRef,
      objectScreen,
      pendingSave,
    ]
  );

  const handleAllToFront = useCallback(
    (idPage) => {
      let myId = selectedObject?.attrs?.id;
      let indexObjectModify: number;

      let currentPageForModification: number;

      let breakPage = false;

      objectScreen.forEach((page) => {
        if (!breakPage) {
          page.renderObjects.forEach((renderedObject) => {
            if (renderedObject.id === selectedObject.attrs.id) {
              currentPageForModification = page.pageNumber;
              breakPage = true;
            }
          });
        }
      });

      let lengthObjects =
        objectScreen[currentPageForModification - 1].renderObjects.length;
      let modificationFinalArray: IRenderedObject[];

      setObjectScreen((oldState) => {
        let cloneState = cloneDeep(oldState);
        cloneState.forEach((page) => {
          if (page.pageNumber == currentPageForModification) {
            let myRenders = cloneDeep(page);
            page.renderObjects.forEach((renderedObject, index) => {
              if (renderedObject.id === myId) {
                indexObjectModify = index;
              }
            });
            let newArray = cloneDeep(myRenders.renderObjects);
            modificationFinalArray = arrayMoveImmutable(
              newArray,
              indexObjectModify,
              lengthObjects - 1
            );
          }
        });

        cloneState[currentPageForModification - 1].renderObjects =
          modificationFinalArray;
        return cloneState;
      });
      pendingSave.current = true;
      setSelectedObject(null);

      setTimeout(() => {
        automaticSelectionWithoutPage({
          setSelectedObject,
          myId,
          stageRef,
          objectScreen,
          currentPageForModification,
        });
      }, 50);
    },
    [
      selectedObject?.attrs?.id,
      objectScreen,
      setObjectScreen,
      setSelectedObject,
      stageRef,
    ]
  );

  useEffect(() => {
    if (selectedObject) {
      const opacityObject = selectedObject?.opacity();
      setValueOpacity(Number((opacityObject * 100).toFixed(0)));
    }
  }, [selectedObject]);

  useEffect(() => {
    changeSelectedObject({
      setObjectScreen,
      selectedObject,
      propertie: "opacity",
      value: valueOpacity / 100,
    });
  }, [valueOpacity]);

  /////////////////*** Change dash border ***/////////////////

  useEffect(() => {
    if (selectedObject && dashedOption?.spacing !== 0) {
      toggleElementDash(dashedOption.dash, dashedOption.spacing);
    }
  }, [dashedOption]);

  const toggleElementDash = useCallback(
    (dash: number, spacing: number) => {
      changeSelectedObject({
        setObjectScreen,
        selectedObject,
        propertie: "dash",
        value: [dash, spacing],
      });
      pendingSave.current = true;
    },
    [setObjectScreen, selectedObject, pendingSave]
  );

  /////////////////*** Change borderRadius size ***/////////////////
  const elementBorderRadiusDecrement = useCallback(
    (number) => {
      addToHistory();
      if (selectedObject) {
        if (changeBorderRadius >= 1) {
          setChangeBorderRadius(number - 1);

          changeSelectedObject({
            setObjectScreen,
            selectedObject,
            propertie: "cornerRadius",
            value: number - 1,
          });
          pendingSave.current = true;
        } else {
          return setChangeBorderRadius(0);
        }
      }
    },
    [
      addToHistory,
      selectedObject,
      changeBorderRadius,
      setObjectScreen,
      pendingSave,
    ]
  );

  /////////////////*** Change borderRadius size ***/////////////////
  const elementBorderRadiusIncrement = useCallback(
    (number: number) => {
      addToHistory();
      if (selectedObject) {
        setChangeBorderRadius(number + 1);

        changeSelectedObject({
          setObjectScreen,
          selectedObject,
          propertie: "cornerRadius",
          value: number + 1,
        });
        pendingSave.current = true;
      }
    },
    [addToHistory, selectedObject, setObjectScreen, pendingSave]
  );

  useEffect(() => {
    if (selectedObject?.attrs?.cornerRadius === undefined) {
      selectedObject?.setAttrs({
        ...selectedObject.attrs,
        cornerRadius: 0,
      });
    } else {
      setChangeBorderRadius(selectedObject?.attrs?.cornerRadius);
    }
  }, [selectedObject, changeBorderRadius]);

  /////////////////*** Change text align style ***/////////////////
  const handleSetIsOpenAlign = useCallback(() => {
    setIsOpenAlign(!isOpenAlign);
  }, [isOpenAlign]);

  const toggleAlignText = useCallback(
    (value) => {
      changeSelectedObject({
        setObjectScreen,
        selectedObject,
        propertie: "align",
        value: value,
        pendingSave,
      });
    },
    [selectedObject, pendingSave]
  );

  /////////////////*** Change text lineHeight ***/////////////////
  const toggleLineHeight = useCallback(
    (spacing: number) => {
      addToHistory();

      changeSelectedObject({
        setObjectScreen,
        selectedObject,
        propertie: "lineHeight",
        value: spacing,
        pendingSave,
      });
    },
    [selectedObject, pendingSave]
  );

  /////////////////*** Change text fontFamily ***/////////////////
  const selectedNewFont = useCallback(
    (font) => {
      addToHistory();

      changeSelectedObject({
        setObjectScreen,
        selectedObject,
        propertie: "fontFamily",
        value: font,
        pendingSave,
      });

      setSelectedObject(null);
    },
    [selectedObject, selectedOptionFont]
  );
  //useEffect for get a current source font when clicked on each
  //text, change current font//////////////////////////
  useEffect(() => {
    setSelectedOptionFont(selectedObject?.attrs?.fontFamily);
  }, [selectedObject]);

  /////////////////*** Text decoration uppercase or lowercase ***/////////////////
  const toggleLowerOrUppercase = () => {
    addToHistory();
    const upperCase = selectedObject.attrs.text.toUpperCase();
    const lowerCase = selectedObject.attrs.text.toLowerCase();
    if (!isUpLowerCase) {
      setIsUpLowerCase(true);

      changeSelectedObject({
        setObjectScreen,
        selectedObject,
        propertie: "text",
        value: upperCase,
        pendingSave,
      });
    } else {
      setIsUpLowerCase(false);

      changeSelectedObject({
        setObjectScreen,
        selectedObject,
        propertie: "text",
        value: lowerCase,
        pendingSave,
      });
    }
  };

  /////////////////*** Text decoration underline ***/////////////////
  const elementUnderline = useCallback(() => {
    addToHistory();
    if (selectedObject) {
      if (
        selectedObject.attrs.textDecoration === undefined ||
        selectedObject.attrs.textDecoration === "none"
      ) {
        setIsUnderline(true);

        changeSelectedObject({
          setObjectScreen,
          selectedObject,
          propertie: "textDecoration",
          value: "underline",
          pendingSave,
        });
      } else {
        setIsUnderline(false);

        changeSelectedObject({
          setObjectScreen,
          selectedObject,
          propertie: "textDecoration",
          value: "none",
          pendingSave,
        });
      }
    }
  }, [selectedObject, isUnderline]);
  //useEffect for verify if text is underline or normal element
  useEffect(() => {
    if (selectedObject?.attrs?.textDecoration === "underline") {
      setIsUnderline(true);
    } else {
      setIsUnderline(false);
    }
  }, [selectedObject, isUnderline]);

  /////////////////*** Change Stroke size ***/////////////////
  const toggleChangeStroke = useCallback(
    (size: number) => {
      addToHistory();
      if (selectedObject) {
        if (size === 0) {
          changeSelectedObject({
            setObjectScreen,
            selectedObject,
            propertie: "strokeWidth",
            value: null,
          });
          pendingSave.current = true;
        }

        changeSelectedObject({
          setObjectScreen,
          selectedObject,
          propertie: "strokeWidth",
          value: size,
        });
        pendingSave.current = true;
      }
    },
    [addToHistory, pendingSave, selectedObject, setObjectScreen]
  );

  /////////////////*** For change colors border of elements ***/////////////////
  const setColorToElementBorder = useCallback(
    (color: string) => {
      while (color?.charAt(0) === "#") {
        color = color.substring(1);
      }

      if (selectedObject) {
        addToHistory();

        if (color === "transparent") {
          changeSelectedObject({
            setObjectScreen,
            selectedObject,
            propertie: "stroke",
            value: null,
            pendingSave,
          });

          toggleChangeStroke(0);
          pendingSave.current = true;
        } else {
          //NOTE:
          //we have do verify on hexadecimal code before changing the main state
          //because if we put a wrongs hexadecimal direct in state
          //will to generate issues in our template and
          //that page will be blank, then probably we lost all pages
          const validateHex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
          const improvedList = new RegExp(validateHex);
          //then update our main state to edit color of selected object
          if (improvedList.test(`#${color}`)) {
            setObjectScreen((oldState) => {
              const cloneState = cloneDeep(oldState);
              cloneState.forEach((page) => {
                if (Number(page.pageNumber) === Number(idPage)) {
                  page.renderObjects.forEach((renderedObject) => {
                    if (renderedObject.id === selectedObject.attrs.id) {
                      renderedObject.stroke = `#${color}`;

                      if (!renderedObject.strokeWidth) {
                        renderedObject.strokeWidth = 1;
                      }

                      if (!renderedObject.dash) {
                        renderedObject.dash = [0, 0];
                      }
                    }
                  });
                }
              });

              return cloneState;
            });
            pendingSave.current = true;

            addToHistory();
          }
        }
      }
    },
    [
      addToHistory,
      idPage,
      pendingSave,
      selectedObject,
      setObjectScreen,
      toggleChangeStroke,
    ]
  );

  /////////////////*** For change colors of elements ***/////////////////
  const setColorToElement = useCallback(
    (color: string) => {
      while (color.charAt(0) === "#") {
        color = color.substring(1);
      }

      if (selectedObject) {
        addToHistory();
        if (color === "transparent") {
          changeSelectedObject({
            setObjectScreen,
            selectedObject,
            propertie: "fill",
            value: "transparent",
            pendingSave,
          });
          pendingSave.current = true;
          return;
        }
        //NOTE:
        //we have do verify on hexadecimal code before changing the main state
        //because if we put a wrongs hexadecimal direct in state
        //will to generate issues in our template and
        //that page will be blank, then probably we lost all pages
        const validateHex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
        const improvedList = new RegExp(validateHex);
        //then update our main state to edit color of selected object
        if (improvedList.test(`#${color}`)) {
          setObjectScreen((oldState) => {
            const cloneState = cloneDeep(oldState);
            cloneState.forEach((page) => {
              if (Number(page.pageNumber) === Number(idPage)) {
                page.renderObjects.forEach((renderedObject) => {
                  if (renderedObject.id === selectedObject.attrs.id) {
                    renderedObject.fill = `#${color}`;
                  }
                });
              }
            });

            return cloneState;
          });
          pendingSave.current = true;
          addToHistory();
        }
      }
    },
    [selectedObject, addToHistory, setObjectScreen, pendingSave, idPage]
  );

  ///////////*** to swap opacity  */////////

  const handleChangeOpacity = useCallback(
    (value) => {
      setValueOpacity(value);
    },
    [valueOpacity, selectedObject]
  );

  /////////////////*** Italic Text ***/////////////////
  const italicElement = useCallback(() => {
    addToHistory();
    if (selectedObject) {
      setIsItalic(true);
      if (
        selectedObject?.attrs?.fontStyle === "bold" ||
        selectedObject?.attrs?.fontStyle === "normal" ||
        selectedObject?.attrs?.fontStyle === undefined
      ) {
        changeSelectedObject({
          setObjectScreen,
          selectedObject,
          propertie: "fontStyle",
          value: "italic",
          pendingSave,
        });
      } else {
        setIsItalic(false);

        changeSelectedObject({
          setObjectScreen,
          selectedObject,
          propertie: "fontStyle",
          value: "normal",
          pendingSave,
        });
      }
    }
  }, [selectedObject, isItalic, pendingSave]);

  /////////////////*** Strong Text ***/////////////////
  const strongFontWeight = useCallback(() => {
    addToHistory();
    if (selectedObject) {
      if (
        selectedObject?.attrs?.fontStyle === "normal" ||
        selectedObject?.attrs?.fontStyle === undefined ||
        selectedObject?.attrs?.fontStyle === "italic"
      ) {
        setIsStrong(true);
        changeSelectedObject({
          setObjectScreen,
          selectedObject,
          propertie: "fontStyle",
          value: "bold",
          pendingSave,
        });
      } else {
        setIsStrong(false);
        changeSelectedObject({
          setObjectScreen,
          selectedObject,
          propertie: "fontStyle",
          value: "normal",
          pendingSave,
        });
      }
    }
  }, [selectedObject, isStrong, pendingSave]);

  //useEffect for verify if text is bold or italic element//
  useEffect(() => {
    if (selectedObject?.attrs?.fontStyle === "bold") {
      setIsStrong(true);
      setIsItalic(false);
    } else {
      setIsStrong(false);
    }
    if (selectedObject?.attrs?.fontStyle === "italic") {
      setIsItalic(true);
      setIsStrong(false);
    } else {
      setIsItalic(false);
    }
  }, [selectedObject, isStrong, isItalic]);

  ////////////// ******** useEffect below for disable delete when choose border color ///////////
  // if still changing border color so block command keyboard, if not keyboard delelete on

  useEffect(() => {
    const myFunc = (evt: KeyboardEvent) => {
      if (
        evt.key === "Delete" ||
        (evt.key === "Backspace" &&
          blockCommandKeyboard === false &&
          forceBlockKey === false)
      ) {
        deleteElement();
      }
    };

    window.addEventListener("keydown", myFunc);
    return () => window.removeEventListener("keydown", myFunc);
  }, [blockCommandKeyboard, selectedObject, selectedObjects, forceBlockKey]);

  /////////////////*** Delete Element ***/////////////////
  const deleteElement = useCallback(() => {
    if (selectedObjects.length > 1) {
      // deleting mutiple elements
      addToHistory();
      setObjectScreen((prevState) => {
        let cloneState = cloneDeep(prevState);
        cloneState.forEach((page) => {
          selectedObjects.forEach((objectToBeDeleted) => {
            page.renderObjects.forEach((object, index) => {
              if (object.id === objectToBeDeleted.id()) {
                page.renderObjects.splice(index, 1);
              }
            });
          });
        });
        return cloneState;
      });
      pendingSave.current = true;
      // "Removing" multiple transformer from canva
      currentMultipleSelection.current.setNodes([]);
      setSelectedObjects([]);

      return;
    }
    // deleting just one element
    if (selectedObject && selectedObject.draggable()) {
      const layer: Konva.Layer = selectedObject.getParent() as Konva.Layer;
      // searching for the transfomer that contains our object
      const listOfTransformers = layer.children.filter((element) => {
        return element instanceof Konva.Transformer;
      });
      const tranformerFromObject = listOfTransformers.filter(
        (transformer: Konva.Transformer) => {
          return transformer.nodes().includes(selectedObject);
        }
      );
      const myTransformer = tranformerFromObject[0] as Konva.Transformer;
      myTransformer?.setNodes([]);
      addToHistory();
      //removing element from
      setObjectScreen((prevState) => {
        let cloneState = cloneDeep(prevState);
        cloneState.forEach((page) => {
          const newArrayWithoutDeletedObject = page.renderObjects.filter(
            (object) => {
              if (object.id !== selectedObject.id()) {
                return object;
              }
            }
          );
          page.renderObjects = newArrayWithoutDeletedObject;
        });
        return cloneState;
      });
      pendingSave.current = true;
      setSelectedObject(null);
      layer.draw();
    }
  }, [
    addToHistory,
    currentMultipleSelection,
    pendingSave,
    selectedObject,
    selectedObjects,
    setObjectScreen,
    setSelectedObject,
    setSelectedObjects,
  ]);

  /////////////////*** Blocked Element ***/////////////////
  const toggleBlockElement = useCallback(() => {
    if (selectedObjects.length > 1) {
      let blockedPoints = 0;
      let unblockedPoints = 0;
      selectedObjects.forEach((object) => {
        if (object.draggable()) {
          unblockedPoints += 1;
        } else {
          blockedPoints += 1;
        }
      });
      if (blockedPoints > unblockedPoints) {
        selectedObjects.forEach((object) => {
          object.draggable(true);
          setIsDraggable(true);
        });
      } else {
        selectedObjects.forEach((object) => {
          object.draggable(false);
          setIsDraggable(false);
        });
      }
    }
    if (selectedObject) {
      selectedObject.setDraggable(!selectedObject.draggable());
      setIsDraggable(selectedObject.draggable());
      changeSelectedObject({
        selectedObject,
        setObjectScreen,
        propertie: "draggable",
        value: !isDraggable,
      });
      pendingSave.current = true;
    }
  }, [
    selectedObjects,
    selectedObject,
    setObjectScreen,
    pendingSave,
    isDraggable,
  ]);

  // automatic toggle block element
  useEffect(() => {
    if (selectedObjects.length > 1) {
      let blockedPoints = 0;
      let unblockedPoints = 0;

      selectedObjects.forEach((object) => {
        if (object.draggable()) {
          unblockedPoints += 1;
        } else {
          blockedPoints += 1;
        }
      });

      if (blockedPoints > unblockedPoints) {
        currentMultipleSelection.current?.enabledAnchors([]);
        currentMultipleSelection.current?.rotateEnabled(false);
        setIsDraggable(false);
      } else {
        currentMultipleSelection.current?.enabledAnchors([
          "top-left",
          "top-right",
          "bottom-left",
          "bottom-right",
        ]);
        currentMultipleSelection.current?.rotateEnabled(true);
        setIsDraggable(true);
      }
      return;
    }

    if (selectedObject) {
      setIsDraggable(selectedObject.attrs.draggable);
    }
  }, [selectedObject, selectedObjects, isDraggable, currentMultipleSelection]);

  /////////////////*** Element zIndex ***/////////////////
  const bringElementToFront = useCallback(() => {
    let myId = selectedObject?.attrs?.id;
    let indexOfCurrentObject: number;
    let modificationFinalArray: IRenderedObject[];
    let currentPageForModification: number;

    let breakPage = false;

    objectScreen.forEach((page) => {
      if (!breakPage) {
        page.renderObjects.forEach((renderedObject) => {
          if (renderedObject.id === selectedObject.attrs.id) {
            currentPageForModification = page.pageNumber;
            breakPage = true;
          }
        });
      }
    });

    if (currentPageForModification) {
      let lengthArray =
        objectScreen[currentPageForModification - 1].renderObjects.length;

      if (lengthArray) {
        if (selectedObject) {
          objectScreen.forEach((page) => {
            if (page.pageNumber == currentPageForModification) {
              modificationFinalArray = page.renderObjects;
              // lengthObjects = page.renderObjects.length;
              page.renderObjects.forEach((object, index) => {
                if (object.id === myId) {
                  indexOfCurrentObject = index;
                  object.x = selectedObject.attrs.x;
                  object.y = selectedObject.attrs.y;
                  object.fill = selectedObject.attrs.fill;
                }
              });
            }
          });

          addToHistory();
          if (lengthArray > 0) {
            const newArray = arrayMoveImmutable(
              modificationFinalArray,
              indexOfCurrentObject,
              indexOfCurrentObject + 1
            );

            setObjectScreen((oldState) => {
              let cloneState = cloneDeep(oldState);
              cloneState[currentPageForModification - 1].renderObjects =
                newArray;
              return cloneState;
            });
            pendingSave.current = true;
          } else {
            return;
          }

          setSelectedObject(null);

          setTimeout(() => {
            automaticSelectionWithoutPage({
              setSelectedObject,
              myId,
              stageRef,
              objectScreen,
              currentPageForModification,
            });
          }, 0);
        }
      }
    }
  }, [
    selectedObject,
    objectScreen,
    setSelectedObject,
    setObjectScreen,
    stageRef,
    addToHistory,
  ]);

  const pushElementToBehind = useCallback(() => {
    if (selectedObject) {
      let myId = selectedObject?.attrs?.id;
      let indexOfCurrentObject: number;
      let modificationFinalArray: IRenderedObject[];

      let currentPageForModification: number;

      // const newIndex = objectScreen[idPage - 1].renderObjects.findIndex(
      //   object => object.id === selectedObject.attrs.id
      // );

      let breakPage = false;

      objectScreen.forEach((page) => {
        if (!breakPage) {
          page.renderObjects.forEach((renderedObject) => {
            if (renderedObject.id === selectedObject.attrs.id) {
              currentPageForModification = page.pageNumber;
              breakPage = true;
            }
          });
        }
      });

      let lengthArray =
        objectScreen[currentPageForModification - 1].renderObjects.length;

      objectScreen.forEach((page) => {
        if (page.pageNumber == currentPageForModification) {
          modificationFinalArray = page.renderObjects;
          // lengthObjects = page.renderObjects.length;
          page.renderObjects.forEach((object, index) => {
            if (object.id === myId) {
              indexOfCurrentObject = index;
              object.x = selectedObject.attrs.x;
              object.y = selectedObject.attrs.y;
              object.fill = selectedObject.attrs.fill;
            }
          });
        }
      });
      addToHistory();
      if (indexOfCurrentObject > 0) {
        const newArray = arrayMoveImmutable(
          modificationFinalArray,
          indexOfCurrentObject,
          indexOfCurrentObject - 1
        );

        setObjectScreen((oldState) => {
          let cloneState = cloneDeep(oldState);
          cloneState[currentPageForModification - 1].renderObjects = newArray;
          return cloneState;
        });
        pendingSave.current = true;
      } else {
        return;
      }

      setSelectedObject(null);

      setTimeout(() => {
        automaticSelectionWithoutPage({
          setSelectedObject,
          myId,
          stageRef,
          objectScreen,
          currentPageForModification,
        });
      }, 0);
    }
  }, [
    addToHistory,
    objectScreen,
    selectedObject,
    setObjectScreen,
    setSelectedObject,
    stageRef,
    pendingSave,
  ]);

  // useEffect(() => {
  //   if (selectedObject) {
  //     addToHistory();
  //     setSizeOfFont(sizeOfFont);
  //     changeSelectedObject({
  //       setObjectScreen,
  //       selectedObject,
  //       propertie: "fontSize",
  //       value: sizeOfFont
  //     });
  //   }
  // }, [sizeOfFont]);

  /////////////////*** increase font ***/////////////////
  const editFontSizeMax = useCallback(
    (number: number) => {
      addToHistory();
      if (selectedObject) {
        setSizeOfFont(number + 1);
        changeSelectedObject({
          setObjectScreen,
          selectedObject,
          propertie: "fontSize",
          value: number + 1,
        });
      }
    },
    [selectedObject, sizeOfFont]
  );

  /////////////////*** decrease font ***/////////////////
  const editFontSizeMin = useCallback(
    (number: number) => {
      addToHistory();
      if (selectedObject) {
        setSizeOfFont(number - 1);
        changeSelectedObject({
          setObjectScreen,
          selectedObject,
          propertie: "fontSize",
          value: number - 1,
        });
      }
    },
    [selectedObject, sizeOfFont]
  );

  // useEffect for verify if text is bold or normal element
  useEffect(() => {
    setPlaceholderOfFont(selectedObject?.attrs.fontSize);
    if (!blockCommandKeyboard) {
      setSizeOfFont(selectedObject?.attrs.fontSize);
    }
  }, [selectedObject, sizeOfFont, placeholderOfFont]);

  //Make group
  const makeGroup = useCallback(() => {}, []);

  //Ungroup
  const ungroup = useCallback(() => {}, []);

  return (
    <ToolbarEditorContext.Provider
      value={{
        isScale,
        setIsScale,
        isTranslationX,
        setIsTranslationX,
        isTranslationY,
        setIsTranslationY,
        valueZoom,
        setValueZoom,
        setColorToElement,
        deleteElement,
        editFontSizeMax,
        sizeOfFont,
        setSizeOfFont,
        editFontSizeMin,
        strongFontWeight,
        isStrong,
        setIsStrong,
        isItalic,
        setIsItalic,
        italicElement,
        isUnderline,
        setIsUnderline,
        elementUnderline,
        bringElementToFront,
        pushElementToBehind,
        toggleBlockElement,
        isDraggable,
        toggleLowerOrUppercase,
        selectedOptionFont,
        setSelectedOptionFont,
        selectedNewFont,
        toggleLineHeight,
        isOpen,
        setIsOpen,
        toggleAlignText,
        isOpenAlign,
        setIsOpenAlign,
        handleSetIsOpenAlign,
        setColorToElementBorder,
        toggleChangeStroke,
        changeBorderRadius,
        setChangeBorderRadius,
        elementBorderRadiusIncrement,
        elementBorderRadiusDecrement,
        makeGroup,
        ungroup,
        toggleElementDash,
        placeholderOfFont,
        setPlaceholderOfFont,
        // blockCommandKeyboard,
        // setBlockCommandKeyboard,
        // forceBlockKey,
        // setForceBlockKey,
        handleChangeOpacity,
        valueOpacity,
        setValueOpacity,
        typeLine,
        setTypeLine,
        dashedOption,
        setDashedOption,
        handleAllToFront,
        handleAllToBack,
        handleChangeStroke,
      }}
    >
      {children}
    </ToolbarEditorContext.Provider>
  );
};

// creating hook
function useToolbarEditor(): ToolbarEditorData {
  const context = useContext(ToolbarEditorContext);

  if (!context) {
    throw new Error(
      "useToolbarEditor must be used with an ToolbarEditorProvider"
    );
  }

  return context;
}

export { ToolbarEditorProvider, useToolbarEditor };
