import styled from "styled-components";

const getColorByIndex = (index: number) => {
  const colors = ["#24958E", "#F90", "#06C", "#663399"];
  const colorIndex = (index - 1) % colors.length;
  return colors[colorIndex];
};

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .empty-container {
    display: flex;
    min-height: 90px;
    justify-content: center;
    align-items: center;

    color: #343a40;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const Modal = styled.div`
  width: 700px;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;

  .close-button-box {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -14px;
    right: 0;
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
    }

    /* background-color: red; */
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;