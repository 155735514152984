import styled from "styled-components";
import { Theme } from "../../../../../../../dtos/theme";
interface ButtonToolbarColorProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Container = styled.div<{ Open: boolean }>`
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  &:hover {
    cursor: pointer;
  }
`;

export const ColorSelect = styled.div<{ newColor: string }>`
  width: 16.202px;
  height: 3.242px;

  border: 0.201px solid #343a40;
  border-radius: 0.703px;
  background: ${(props) => (props.newColor ? props.newColor : "#FFF")};
`;

export const ButtonToolbarColor = styled.div<ButtonToolbarColorProps>`
  height: 30px;
  width: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.3px;

  svg {
    width: 14.926px;
    height: 14.296px;
  }

  :hover {
    background-color: #ebebec;
    border-radius: 5px;
  }
`;

export const ButtonToolbarColorOneclick = styled.div<{ newColor: string }>`
  width: 40px;
  height: 40px;
  border: solid 1px gray;
  border-radius: 5px;

  background: ${(props) => (props.newColor ? props.newColor : "#FFF")};
`;
