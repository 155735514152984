import React from "react";
import AnimationJson from "../../assets/lottieJsons/loading.json";

import { useLottie } from "lottie-react";

const CustomSpin: React.FC = () => {
  const options = {
    animationData: AnimationJson,
    loop: true
  };

  const { View } = useLottie(options);

  return (
    <div
      style={{ width: "50px", height: "50px" }}
      className="responsive-loading"
    >
      {View}
    </div>
  );
};

export { CustomSpin };
