import React from "react";

// import { Container } from './styles';

interface IdentProps {
  isActive: boolean;
}

const RecoilRight: React.FC<IdentProps> = ({ isActive }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="streamline:interface-text-formatting-indent-increase-alignment-align-indent-paragraph-increase-formatting-text">
        <path
          id="Vector (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 4.36404C2 3.75039 2.49746 3.25293 3.11111 3.25293H20.8889C21.5025 3.25293 22 3.75039 22 4.36404C22 4.97769 21.5025 5.47515 20.8889 5.47515H3.11111C2.49746 5.47515 2 4.97769 2 4.36404ZM4.356 9.33985C4.77816 8.89449 5.48143 8.87568 5.92679 9.29785L8.66183 11.8904C8.88318 12.1003 9.00855 12.3918 9.00855 12.6968C9.00855 13.0018 8.88318 13.2934 8.66183 13.5032L5.92679 16.0958C5.48143 16.518 4.77816 16.4992 4.356 16.0538C3.93384 15.6085 3.95264 14.9052 4.398 14.483L6.28234 12.6968L4.398 10.9106C3.95264 10.4885 3.93384 9.7852 4.356 9.33985ZM9.52137 10.1042C9.52137 9.49059 10.0188 8.99313 10.6325 8.99313H20.8889C21.5025 8.99313 22 9.49059 22 10.1042C22 10.7179 21.5025 11.2154 20.8889 11.2154H10.6325C10.0188 11.2154 9.52137 10.7179 9.52137 10.1042ZM9.52137 15.2894C9.52137 14.6758 10.0188 14.1783 10.6325 14.1783H20.8889C21.5025 14.1783 22 14.6758 22 15.2894C22 15.9031 21.5025 16.4005 20.8889 16.4005H10.6325C10.0188 16.4005 9.52137 15.9031 9.52137 15.2894ZM2 21.0307C2 20.4171 2.49746 19.9196 3.11111 19.9196H20.8889C21.5025 19.9196 22 20.4171 22 21.0307C22 21.6444 21.5025 22.1418 20.8889 22.1418H3.11111C2.49746 22.1418 2 21.6444 2 21.0307Z"
          fill={isActive ? "#663399" : "#343A40"}
        />
      </g>
    </svg>
  );
};

export default RecoilRight;
