/*
this hook call it index because 
here we export all this contexts in we app.
*/

import React from "react";
import { ModalContextProvider } from "./modalContext";
import { RightMenuContextProvider } from "./rightMenuContext";
import { AuthProvider } from "./auth";
import { HeaderEditorProvider } from "../modules/editor/hooks/headerEditor";
import { MainHookProvider } from "./main";
import { CustomThemeProvider } from "./theme";
import { ToolbarEditorProvider } from "../modules/editor/hooks/toolbarEditor";
import { AsidebarEditorProvider } from "../modules/editor/hooks/asidebarEditor";
import { PagesEditorProvider } from "../modules/editor/hooks/pagesEditor";
import { WorkspaceEditorProvider } from "../modules/editor/hooks/workspaceEditor";
import { IconsColorsProvider } from "../modules/editor/hooks/iconsColors";
import { SelectionHookProvider } from "../modules/editor/hooks/selection";
import { EditorTextHookProvider } from "../modules/editor/hooks/editorText";
import { VersioningHookProvider } from "../modules/editor/hooks/versioning";
import { TextsEditionProvider } from "../modules/editor/hooks/textsEdition";
import { ClausesEditorHookProvider } from "../modules/editor/hooks/clausesEditor";
import { SharedDocumentHookProvider } from "../modules/editor/hooks/sharedDocument";
import { DiffDocumentsHookProvider } from "../modules/editor/hooks/diffDocuments";
import { KeyboardEditorHookProvider } from "../modules/editor/hooks/keyboardEditor";
import { TemplatesContextProvider } from "../modules/editor/hooks/templates";
import { StageToolsProvider } from "../modules/editor/hooks/stageTools";
import { TeamsHookProvider } from "./teams";
import { MetricProvider } from "./metric";
import { AutomaticSavingProvider } from "../modules/editor/hooks/automaticSaving";
import { SignHookProvider } from "./sign";
import { CommentsHookProvider } from "../modules/editor/hooks/comments";
import { TwoFactorAuthContext } from "./twoFactorAuth";

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <TwoFactorAuthContext>
        <MainHookProvider>
          <SignHookProvider>
            <SharedDocumentHookProvider>
              <ClausesEditorHookProvider>
                <SelectionHookProvider>
                  <PagesEditorProvider>
                    <ToolbarEditorProvider>
                      <TextsEditionProvider>
                        <EditorTextHookProvider>
                          <WorkspaceEditorProvider>
                            <MetricProvider>
                              <VersioningHookProvider>
                                <IconsColorsProvider>
                                  <HeaderEditorProvider>
                                    <DiffDocumentsHookProvider>
                                      <CustomThemeProvider>
                                        <AsidebarEditorProvider>
                                          <RightMenuContextProvider>
                                            <ModalContextProvider>
                                              <KeyboardEditorHookProvider>
                                                <TemplatesContextProvider>
                                                  <StageToolsProvider>
                                                    <TeamsHookProvider>
                                                      <AutomaticSavingProvider>
                                                        <CommentsHookProvider>
                                                          {children}
                                                        </CommentsHookProvider>
                                                      </AutomaticSavingProvider>
                                                    </TeamsHookProvider>
                                                  </StageToolsProvider>
                                                </TemplatesContextProvider>
                                              </KeyboardEditorHookProvider>
                                            </ModalContextProvider>
                                          </RightMenuContextProvider>
                                        </AsidebarEditorProvider>
                                      </CustomThemeProvider>
                                    </DiffDocumentsHookProvider>
                                  </HeaderEditorProvider>
                                </IconsColorsProvider>
                              </VersioningHookProvider>
                            </MetricProvider>
                          </WorkspaceEditorProvider>
                        </EditorTextHookProvider>
                      </TextsEditionProvider>
                    </ToolbarEditorProvider>
                  </PagesEditorProvider>
                </SelectionHookProvider>
              </ClausesEditorHookProvider>
            </SharedDocumentHookProvider>
          </SignHookProvider>
        </MainHookProvider>
      </TwoFactorAuthContext>
    </AuthProvider>
  );
};

export default AppProvider;
