import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  position: relative;

  border: 1px solid #999c9f;
  border-radius: 5px;
  padding-inline: 10px;
  background-color: #fff;

  .search-input {
    width: 24px;
    height: 24px;
  }

  button {
    width: 40px;
    height: 90%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    :hover {
      background-color: #f5f5f5;
      border-radius: 5px;
    }
  }
`;

export const Input = styled.input`
  width: 80%;
  height: 30px;
  background-color: white;

  border: none;
`;
