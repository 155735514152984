import React from "react";

// import { Container } from './styles';
interface ItalicProps {
  isActive: boolean;
}

const Italic: React.FC<ItalicProps> = ({ isActive }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ci:italic">
        <path
          id="Vector (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.125 6.57227C11.125 6.08902 11.5168 5.69727 12 5.69727H15.5C15.9832 5.69727 16.375 6.08902 16.375 6.57227C16.375 7.05551 15.9832 7.44727 15.5 7.44727H14.41L11.41 17.9473H12C12.4832 17.9473 12.875 18.339 12.875 18.8223C12.875 19.3055 12.4832 19.6973 12 19.6973H8.5C8.01675 19.6973 7.625 19.3055 7.625 18.8223C7.625 18.339 8.01675 17.9473 8.5 17.9473H9.58999L12.59 7.44727H12C11.5168 7.44727 11.125 7.05551 11.125 6.57227Z"
          fill={isActive ? "#663399" : "#343A40"}
        />
      </g>
    </svg>
  );
};

export default Italic;
