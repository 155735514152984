import { useTheme } from "../../../../../../../hooks/theme";
import PersonalizedIcon from "../../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../../shared/utils/MountIcons";
import Tooltip from "../../../../Tooltip";
import { ButtonToolbarColor, ColorSelect, Container } from "./styles";

interface IDropDownHeaderProps {
  isOpen: boolean;
  handleClick: () => void;

  rest: any;
  newColor: string;
}

const DropDownHeader = ({
  isOpen,
  handleClick,
  newColor,
  ...rest
}: IDropDownHeaderProps) => {
  const { theme } = useTheme();

  return (
    <Tooltip
      title="Cor da borda"
      description="Defina a cor da borda do elemento."
      placement="bottom-start"
      disabled={isOpen}
    >
      <Container Open={isOpen} onClick={handleClick}>
        <ButtonToolbarColor {...rest}>
          <PersonalizedIcon
            dPath={MountIcons.IconsBorderColor.dPath}
            viewBox={MountIcons.IconsBorderColor.viewBox}
            inactivatedColor="#000"
            activeColor={theme.colors.primary}
            isActive={isOpen}
          />
          <ColorSelect newColor={newColor} />
        </ButtonToolbarColor>
      </Container>
    </Tooltip>
  );
};

export default DropDownHeader;
