import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

export const Container = styled.div`
  width: 100%;

  display: flex;
  gap: 8px;
`;

export const FlexContainer = styled.div`
  width: 200px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #999c9f;
  padding-inline: 10px 3px;
  background-color: #fff;

  display: flex;
  align-items: center;
  gap: 8px;

  .search-icon {
    width: 24px;
    height: 24px;
  }

  button {
    width: 30px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  @media (min-width: 1920px) {
    width: 300px;
  }
`;

export const Input = styled.input`
  border: none;
  background-color: transparent;
  width: 100%;
`;

export const VerticalStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.2em;
`;

export const HorizontalStack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & .icon-default {
    width: 25px;
    height: 25px;
    margin-right: 0.8rem;
  }
`;

export const DropDownContainer = styled.div`
  width: 30px;
  height: 30px;
`;

export const DropDownHeader = styled.div<{ Open: boolean }>`
  svg {
    width: 24px;
    height: 24px;
  }

  button {
    width: 30px;
    height: 30px;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const DropDownListContainer = styled.div``;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: 0.9rem;
  position: absolute;
  padding: 1em;
  top: 40px;
  right: 10px;
  bottom: 0;
  min-height: 150px;
  width: 180px;
  background: white;
  box-shadow: 1px 3px 41px -15px rgba(0, 0, 0, 0.31);
  -webkit-box-shadow: 1px 3px 41px -15px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 1px 3px 41px -15px rgba(0, 0, 0, 0.21);
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Roboto";
  color: ${({ theme }: Theme) => theme.colors.black};
  font-size: 1.8rem;
  font-weight: 500;
  z-index: 400;

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    padding-top: 0.8em;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  padding-top: 1rem;
  margin-bottom: 0.8em;
  margin-top: 1rem;
  font-size: 1.6rem;
  color: #a5a5a5;
  display: flex;
  align-items: center;
`;
