import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 24px;
  height: 25px;
  fill: currentColor;
`;

type Props = {
  color?: string;
};

const ResendEmailIcon = ({ color }: Props) => (
  <StyledSvg viewBox="0 0 24 25" color={color || "#676B70"}>
    <path
      d="M3 4.15527C1.9 4.15527 1 5.05527 1 6.15527V18.1553C1 19.2553 1.9 20.1553 3 20.1553H13.5C13.228 19.5195 13.0592 18.8443 13 18.1553H3V8.15527L11 13.1553L19 8.15527V11.1553C19.1666 11.1489 19.3334 11.1489 19.5 11.1553C20.0053 11.1568 20.5087 11.2172 21 11.3353V6.15527C21 5.05527 20.1 4.15527 19 4.15527H3ZM3 6.15527H19L11 11.1553L3 6.15527ZM19 12.1553L16.75 14.4053L19 16.6553V15.1553C19.663 15.1553 20.2989 15.4187 20.7678 15.8875C21.2366 16.3563 21.5 16.9922 21.5 17.6553C21.5 18.0553 21.41 18.4353 21.24 18.7753L22.33 19.8653C22.75 19.2353 23 18.4753 23 17.6553C23 15.4453 21.21 13.6553 19 13.6553V12.1553ZM15.67 15.4453C15.25 16.0753 15 16.8353 15 17.6553C15 19.8653 16.79 21.6553 19 21.6553V23.1553L21.25 20.9053L19 18.6553V20.1553C18.337 20.1553 17.7011 19.8919 17.2322 19.423C16.7634 18.9542 16.5 18.3183 16.5 17.6553C16.5 17.2553 16.59 16.8753 16.76 16.5353L15.67 15.4453Z"
      fill="#676B70"
    />
  </StyledSvg>
);

export default ResendEmailIcon;
