import { HTMLProps } from "react";
import styled from "styled-components";
import { Theme } from "../../../dtos/theme";

interface DimentionsProps {
  width: string;
  height: string;
}

interface IconSvgModifyProps {
  src?: string;
  alt?: string;
}

interface FlexEditorProps {
  zoomValue?: number;
}

interface VStackProps extends HTMLProps<HTMLDivElement> {
  margin?: string;
  height?: string;
}

interface GridItemWorkspaceProps {
  pageOneOnly?: boolean;
}

export const ContainerLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.44);
`;

export const GridContainer = styled.div<DimentionsProps>`
  display: grid;
  grid-template-areas:
    "header header header header header header"
    "workspace workspace workspace sidebar sidebar sidebar";
  grid-template-rows: 68px calc(${({ height }) => height} - 68px);
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow: hidden;
`;

export const GridItemHeader = styled.header`
  grid-area: header;
  z-index: 99;
`;

export const Header = styled.nav`
  width: 100%;
  height: 100%;
  position: sticky;
`;

export const FlexContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to left,
    ${({ theme }: Theme) => theme.colors.gradientBody},
    ${({ theme }: Theme) => theme.colors.gradientBody2} 80%
  );
`;

export const GridItemWorkspace = styled.div<GridItemWorkspaceProps>`
  grid-area: workspace;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll !important;
  margin: 0 auto;
  width: 65vw;
  background: ${({ theme }) => theme.colors.backgroundEditor};
`;

export const FlexEditor = styled.div<FlexEditorProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f5f5f5;
  align-items: center;
  transform: none;
  /* transform: translate3d(0, 0, 0) perspective(1px)
    scale(${({ zoomValue }) => zoomValue && zoomValue})
    translateY(
      ${({ zoomValue }) =>
    zoomValue <= 1
      ? 0
      : zoomValue === 1.1
        ? "4.5%"
        : zoomValue === 1.2
          ? "8%"
          : zoomValue === 1.4
            ? "14%"
            : zoomValue === 1.6
              ? "18%"
              : zoomValue === 2 && "25%"}
    )
    translateZ(0); */

  /* -webkit-transform: translate3d(0, 0, 0) perspective(1px)
    scale(${({ zoomValue }) => zoomValue && zoomValue})
    translateY(
      ${({ zoomValue }) =>
    zoomValue <= 1
      ? 0
      : zoomValue === 1.1
        ? "5%"
        : zoomValue === 1.2
          ? "10%"
          : zoomValue === 1.4
            ? "15%"
            : zoomValue === 1.6
              ? "20%"
              : zoomValue === 2 && "25%"}
    )
    translateZ(0);

  -moz-transform: translate3d(0, 0, 0) perspective(1px)
    scale(${({ zoomValue }) => zoomValue && zoomValue})
    translateY(
      ${({ zoomValue }) =>
    zoomValue <= 1
      ? 0
      : zoomValue === 1.1
        ? "5%"
        : zoomValue === 1.2
          ? "10%"
          : zoomValue === 1.4
            ? "15%"
            : zoomValue === 1.6
              ? "20%"
              : zoomValue === 2 && "25%"}
    )
    translateZ(0);

  -o-transform: translate3d(0, 0, 0) perspective(1px)
    scale(${({ zoomValue }) => zoomValue && zoomValue})
    translateY(
      ${({ zoomValue }) =>
    zoomValue <= 1
      ? 0
      : zoomValue === 1.1
        ? "5%"
        : zoomValue === 1.2
          ? "10%"
          : zoomValue === 1.4
            ? "15%"
            : zoomValue === 1.6
              ? "20%"
              : zoomValue === 2 && "25%"}
    )
    translateZ(0);

  -ms-transform: translate3d(0, 0, 0) perspective(1px)
    scale(${({ zoomValue }) => zoomValue && zoomValue})
    translateY(
      ${({ zoomValue }) =>
    zoomValue <= 1
      ? 0
      : zoomValue === 1.1
        ? "5%"
        : zoomValue === 1.2
          ? "10%"
          : zoomValue === 1.4
            ? "15%"
            : zoomValue === 1.6
              ? "20%"
              : zoomValue === 2 && "25%"}
    )
    translateZ(0); */
  padding-bottom: ${({ zoomValue }) =>
    zoomValue <= 1
      ? 0
      : zoomValue === 1.1
        ? "10rem"
        : zoomValue === 1.2
          ? "30rem"
          : zoomValue === 1.4
            ? "40rem"
            : zoomValue === 1.5 && "0"};
  backface-visibility: hidden !important;
  flex-direction: column;
  -webkit-filter: blur(0px) !important;
  transition: transform 0.2s ease;
  filter: blur(0) !important;
  will-change: transform;

  -webkit-font-smoothing: subpixel-antialiased !important;

  /* position: relative; */
`;

export const CanvasContainer = styled.div`
  width: 790px;
  min-height: 920px;
  height: 100%;
  margin: 2.6rem 0;

 
`;

export const HorizontalStack = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
`;

export const TextPage = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: ${({ theme }: Theme) => theme.colors.gray300};
`;

export const FlexDesignArea = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

export const Canvas = styled.div`
  width: 550px;
  height: 776.69px;
  background: white;
  margin: 0 auto;
  -webkit-box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.44);
  margin-bottom: 1.8rem;
`;

export const FlexRow = styled.div`
  display: flex;
  max-width: 190px;
  width: 100%;
  justify-content: space-between;
`;

export const ToolsbarEditor = styled.div`
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #adb5bd;
  display: flex;
  background: white;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
`;

export const FlexToolbar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  padding: 0 1.6rem;
  justify-content: space-between;
  align-items: center;
`;

export const FlexSearch = styled.div`
  width: 100%;
  height: 70px;
`;

export const VStack = styled.div<VStackProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ height }) => (height ? height : "")};
  justify-content: space-between;
  margin: ${({ margin }) => (margin ? margin : "")};
`;

export const FlexButton = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const IconSvgModify = styled.svg<IconSvgModifyProps>`
  width: 100%;
  height: 100%;
  background: url(${({ src }) => src}) no-repeat center / contain;
  stroke: black;
`;

export const FlexBar = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
`;

export const SpaceOfContainer = styled.div`
  width: 100px;
  height: 20px;
  background: transparent;
`;

export const ModalOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.55);
`;

export const ModalContent = styled.div`
  width: 900px;
  height: 194.75px;
  background: white;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 3rem 2.5rem;
  border-radius: 10px;
`;

export const HStack = styled.div`
  width: 100%;
  height: 55.7px;
  display: flex;

  &:first-child {
    margin-bottom: 3rem;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const Text = styled.span`
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
`;

export const Button = styled.button<{ color?: string }>`
  width: 100%;
  height: 40px;
  background: ${({ color }) => (color ? color : "black")};
  color: white;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;

  &:first-child {
    margin-right: 0.8rem;
  }

  &:last-child {
    margin-left: 0.8rem;
  }
`;

export const ModalSave = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const ModalFlex = styled.div`
  width: 900px;
  min-height: 171px;
  background: #fff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 3rem 2.5rem;

  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    margin-top: 0.5rem;
    /* identical to box height, or 27px */

    /* gray-dark */

    color: #343a40;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-top: 1.5rem;
    /* identical to box height, or 24px */

    /* gray-dark */

    color: #343a40;
  }
`;

export const Box = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 1.5rem;
`;

export const ButtonSave = styled.div`
  width: 100%;
  height: 100%;
`;

export const ButtonSaving = styled.button<{ isSave: boolean }>`
  width: 100%;
  height: 100%;
  background: ${({ isSave }) => (isSave ? "#663399" : "#fff")};
  border: ${({ isSave }) => (isSave ? "" : "1px solid #663399")};
  border-radius: 5px;
  color: ${({ isSave }) => (isSave ? "#fff" : "#663399")};
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

