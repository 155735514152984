import React, { createContext, ReactNode, useContext, useState } from "react";

interface CategoryProps {
  created_at: string;
  creator_id: string;
  id: string;
  last_editor_id: string;
  title: string;
  updated_at: string;
}

interface ITemplatesData {
  bits_templates_category?: string;
  created_at?: string;
  creator_id?: string;
  description?: string;
  id?: string;
  key_words?: string;
  last_editor_id?: string;
  template?: any;
  thumbnail?: string;
  thumbnail_url?: string;
  title?: string;
  updated_at?: string;
  usage_counter?: number;
}

interface TemplatesCategoriesProps {
  id: string;
  category: string;
  templates?: ITemplatesData[];
}

interface TemplatesContextData {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  templatesByCategories: TemplatesCategoriesProps[];
  setTemplateByCategories: React.Dispatch<
    React.SetStateAction<TemplatesCategoriesProps[]>
  >;
  endPages: boolean;
  setEndPages: React.Dispatch<React.SetStateAction<boolean>>;
  loadingTemplates: boolean;
  setLoadingTemplates: React.Dispatch<React.SetStateAction<boolean>>;
  hasNewPage: number;
  setHasNewPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  dataCategoriesTemplates: CategoryProps[];
  setDataCategoriesTemplates: React.Dispatch<
    React.SetStateAction<CategoryProps[]>
  >;
}

interface TemplatesContextProviderProps {
  children: ReactNode;
}

const TemplatesContext = createContext<TemplatesContextData>(
  {} as TemplatesContextData
);

const TemplatesContextProvider: React.FC<TemplatesContextProviderProps> = ({
  children
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [hasNewPage, setHasNewPage] = useState(0);
  const [endPages, setEndPages] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataCategoriesTemplates, setDataCategoriesTemplates] = useState<
    CategoryProps[]
  >([]);
  const [templatesByCategories, setTemplateByCategories] = useState<
    TemplatesCategoriesProps[]
  >([]);

  return (
    <TemplatesContext.Provider
      value={{
        loading,
        setLoading,
        templatesByCategories,
        setTemplateByCategories,
        endPages,
        setEndPages,
        loadingTemplates,
        setLoadingTemplates,
        hasNewPage,
        setHasNewPage,
        currentPage,
        setCurrentPage,
        dataCategoriesTemplates,
        setDataCategoriesTemplates
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
};

// creating hook

function useTemplates(): TemplatesContextData {
  const context = useContext(TemplatesContext);

  if (!context) {
    throw new Error("useAuth must be used with an TemplatesContextProvider");
  }

  return context;
}

export { TemplatesContextProvider, useTemplates };
