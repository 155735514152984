import React from "react";

// import { Container } from './styles';
interface RightAlignProps {
  isActive: boolean;
}
const RightAlign: React.FC<RightAlignProps> = ({ isActive }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mingcute:align-right-line">
        <path
          id="Vector"
          d="M20.8889 19.8955C21.1721 19.8958 21.4445 20.0038 21.6504 20.1976C21.8563 20.3913 21.9803 20.6562 21.9969 20.9379C22.0135 21.2197 21.9215 21.4971 21.7397 21.7135C21.5579 21.93 21.3001 22.0691 21.0189 22.1024L20.8889 22.1102H9.77778C9.49458 22.1098 9.22219 22.0018 9.01626 21.808C8.81033 21.6143 8.68641 21.3495 8.66981 21.0677C8.65321 20.7859 8.74519 20.5085 8.92696 20.2921C9.10873 20.0756 9.36656 19.9365 9.64778 19.9032L9.77778 19.8955H20.8889ZM20.8889 14.3587C21.1836 14.3587 21.4662 14.4754 21.6746 14.683C21.8829 14.8907 22 15.1724 22 15.466C22 15.7597 21.8829 16.0414 21.6746 16.2491C21.4662 16.4567 21.1836 16.5734 20.8889 16.5734H3.11111C2.81643 16.5734 2.53381 16.4567 2.32544 16.2491C2.11706 16.0414 2 15.7597 2 15.466C2 15.1724 2.11706 14.8907 2.32544 14.683C2.53381 14.4754 2.81643 14.3587 3.11111 14.3587H20.8889ZM20.8889 8.82192C21.1721 8.82223 21.4445 8.93031 21.6504 9.12406C21.8563 9.31781 21.9803 9.58262 21.9969 9.86438C22.0135 10.1461 21.9215 10.4236 21.7397 10.64C21.5579 10.8564 21.3001 10.9955 21.0189 11.0289L20.8889 11.0366H9.77778C9.49458 11.0363 9.22219 10.9282 9.01626 10.7345C8.81033 10.5407 8.68641 10.2759 8.66981 9.99417C8.65321 9.71241 8.74519 9.43497 8.92696 9.21854C9.10873 9.0021 9.36656 8.86301 9.64778 8.82967L9.77778 8.82192H20.8889ZM20.8889 3.28516C21.1721 3.28547 21.4445 3.39354 21.6504 3.5873C21.8563 3.78105 21.9803 4.04586 21.9969 4.32761C22.0135 4.60937 21.9215 4.88681 21.7397 5.10324C21.5579 5.31968 21.3001 5.45878 21.0189 5.49211L20.8889 5.49986H3.11111C2.82791 5.49955 2.55552 5.39148 2.34959 5.19772C2.14366 5.00397 2.01974 4.73916 2.00314 4.45741C1.98655 4.17565 2.07853 3.89821 2.26029 3.68177C2.44206 3.46534 2.69989 3.32624 2.98111 3.29291L3.11111 3.28516H20.8889Z"
          fill={isActive ? "#663399" : "#343A40"}
        />
      </g>
    </svg>
  );
};

export default RightAlign;
