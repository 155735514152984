import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 2;
  width: 165px;
`;

export const ButtonInitial = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 44px;
  padding: 10px 16px;
  border: solid 2px
    ${({ theme, isOpen }) =>
      isOpen ? theme.colors.focus : theme.colors.white100};
  ${({ isOpen }) =>
    isOpen && "box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);"};

  border-radius: 5px 5px 0px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    cursor: pointer;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;

    font-family: "Ubuntu";
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black75};
    user-select: none;
  }

  .icon-clause {
    padding: 4px 4px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Modal = styled.ul`
  position: absolute;

  width: 100%;
  background-color: ${({ theme }) => theme.colors.white100};
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);

  a {
    display: flex;
    align-items: center;
    gap: 8px;

    padding: 10px 16px;

    font-family: "Ubuntu";
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black75};
    user-select: none;
    text-decoration: none;

    :hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }

  .icon-clause {
    padding: 4px 4px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;
