import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 218px;
  height: 61px;
  fill: currentColor;
`;

type Props = {
  color: string;
  active: boolean;
};

const StepIconSmall = ({ color, active }: Props) => (
  <StyledSvg viewBox="0 0 218 61" color={color}>
    <path
      d="M199.745 58.3891C199.377 58.9613 198.744 59.3071 198.063 59.3071H3.49693C1.91433 59.3071 0.958694 57.5561 1.81489 56.2251L16.1486 33.9427C16.9605 32.6806 16.9976 31.0701 16.2447 29.7719L1.47786 4.31053C0.704572 2.9772 1.6666 1.30713 3.20795 1.30713H197.972C198.686 1.30713 199.345 1.68685 199.702 2.30373L216.215 30.7753C216.592 31.4244 216.573 32.2296 216.167 32.8607L199.745 58.3891Z"
      fill="#7547A3"
      stroke={active ? "#5D5FEF" : "unset"}
      stroke-width={active ? "2" : "0"}
    />
  </StyledSvg>
);

export default StepIconSmall;
