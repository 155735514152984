import { Container } from "./styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "../../../../hooks/theme";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import Tooltip from "../../../editor/components/Tooltip";
import MountIcons from "../../../../shared/utils/MountIcons";
import ButtonExit from "../../../../shared/components/ButtonExit";
import { useMainHook } from "../../../../hooks/main";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../../App";
import { MyTemplatesProps } from "../../Teams/components/ModalTeams/ModalImportTemplate";

interface SearchProps {
  description: string;
  pageNumber: number;
  screen: string;
  w_100?: boolean;
  isImportTemplate?: boolean;
  isImportAssignature?: boolean;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  setMyTemplates?: React.Dispatch<React.SetStateAction<MyTemplatesProps[]>>;
}

const SearchBar = ({ description, pageNumber, screen, w_100, isImportTemplate, isImportAssignature, setCurrentPage, setMyTemplates }: SearchProps) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const {
    search,
    /*     handleSearchText, */
    setSearch,
    searchInTemplate,
    setSearchInTemplate,
    setDebouncedSearchInTemplate,
    setDebouncedSearchTerm,
    setLoadingDebounce,
    searchInAssignature,
    setSearchInAssignature,
    setDebouncedSearchInAssignature,
  } = useMainHook();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    const myFunc = (evt: KeyboardEvent) => {
      if (evt.key === "Enter" && isFocus) {
        buttonRef.current.click();
      }
    };

    window.addEventListener("keydown", myFunc);
    return () => window.removeEventListener("keydown", myFunc);
  }, [isFocus]);

  //Debounce responsible for keeping the user completing the search word
  useEffect(() => {
    if (!isImportAssignature && !isImportTemplate) {
      setLoadingDebounce(true);
      const delayDebounce = setTimeout(() => {
        setDebouncedSearchTerm(search);
        setLoadingDebounce(false);

        if (search) {
          navigate(`/${screen}/1`);
        }
      }, 900);
      return () => {
        clearTimeout(delayDebounce);
      };
    }
  }, [search]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchInTemplate(searchInTemplate);
    }, 900);
    return () => {
      clearTimeout(delayDebounce);
    };
  }, [searchInTemplate]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchInAssignature(searchInAssignature);
    }, 900);
    return () => {
      clearTimeout(delayDebounce);
    };
  }, [searchInAssignature]);

  //event listener for every time our user
  //do a new search on documents templates
  const handleSearchText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      isImportTemplate
        ? setSearchInTemplate(event.target.value)
        : isImportAssignature
          ? setSearchInAssignature(event.target.value)
          : setSearch(event.target.value);
    },
    []
  );

  //Reset states
  useEffect(() => {
    return () => {
      setSearchInTemplate("");
      setSearch("");
      setDebouncedSearchTerm("");
    };
  }, []);

  return (
    <Container isFocus={isFocus} w_100={w_100}>
      <Tooltip
        title="Pesquisar"
        description={description}
        placement="bottom-start"
      >
        <div>
          <PersonalizedIcon
            isActive={isFocus}
            className="icon-search"
            width={20}
            height={21}
            dPath={MountIcons.IconSearch.dPath}
            viewBox={MountIcons.IconSearch.viewBox}
            activeColor={theme.colors.black100}
            inactivatedColor={theme.colors.black50}
          />
        </div>
      </Tooltip>
      <input
        type="text"
        onFocus={() => setIsFocus(true)}
        value={isImportTemplate ? searchInTemplate : isImportAssignature ? searchInAssignature : search}
        onChange={handleSearchText}
      />

      {isFocus && (
        <button>
          <ButtonExit
            inactivatedColor="#999C9F"
            onClick={() => {
              if (setMyTemplates) {
                queryClient.refetchQueries('docs');
                setCurrentPage(0);
              }
              setSearchInTemplate("");
              setSearchInAssignature("");
              setSearch("");
              setIsFocus(false);
            }}
          />
        </button>
      )}
    </Container>
  );
};

export default SearchBar;
