import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import api from "../../../../../../../../services/api";
import Comment from "../../../Comment";
import { useParams } from "react-router-dom";
import { useComments } from "../../../../../../hooks/comments";
import ReactLoading from "react-loading";
import { EmptyContent } from "../../../../../ModalSign/styles";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import { LoadingBox } from "../ListAllCommentsPage/styled";
import { ContractAnimation } from "../../../../../../../../shared/components/ContractAnimation";
import { useMainHook } from "../../../../../../../../hooks/main";

// import { Container } from './styles';
interface CommentListProps {
  id: string;
  user_id: string;
  user_template_id: string;
  message: string;
  position: string;
  is_excluded: boolean;
  is_edited: boolean;
  is_done: boolean;
  page: string;
  created_at: string;
  updated_at: string;
  avatar: string;
  avatar_url: string;
  first_name: string;
  last_name: string;
  count_answers: number;
}

interface ResponseListAllCommentsProps {
  pagination: number;
  dataArray: CommentListProps[];
}

interface ListAllCommentsViewProps {
  loading?: boolean;
  setLoading?: any;
  categoryComment: any;
  setCategoryComment: any;
  // handleDoneCommentary: (
  //   id: string,
  //   action: "done" | "edit" | "remove"
  // ) => Promise<void>;
}

const ListAllCommentsView: React.FC<ListAllCommentsViewProps> = ({
  categoryComment,
  setCategoryComment
  // handleDoneCommentary
}) => {
  const { templateid } = useParams();
  const { setSelectedIdComment, setListCommentsCanvas } = useComments();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [inputEditComment, setInputEditComment] = useState("");

  // const navigateToElement = (elementId: number) => {
  //   const element = document.getElementById(`pageNumber-${elementId}`);
  //   if (element) element.scrollIntoView({ behavior: "smooth" });
  // };

  const navigateToElement = (elementId: number) => {
    const container = document.getElementById(`container-auto-fullWidth`);
    const element = document.getElementById(`pageNumber-${elementId}`);

    if (element && container) {
      const containerTop =
        container.getBoundingClientRect().top + window.scrollY;
      const elementTop = element.getBoundingClientRect().top + window.scrollY;

      //calculate diff of current position to target
      const offset = elementTop - containerTop + container.scrollTop;
      container.scrollTo({ top: offset, behavior: "smooth" });
    }
  };

  useEffect(() => {
    setSelectedIdComment(null);
  }, []);

  const [isShowEmpty, setIsShowEmpty] = useState(
    "Não há comentários nesse documento para exibir."
  );
  const [loading, setLoading] = useState(true);
  // const [isLoadingAllComments, setIsLoadingAllComments] = useState(true);
  // const [lengthReplies, setLengthReplies] = useState(0);
  const [commentsData, setCommentsData] = useState<CommentListProps[]>([]);

  const handleEditCommentary = useCallback(
    async (comment: CommentListProps) => {
      try {
        const commentObject = {
          template_comment_id: comment.id,
          message: inputEditComment,
          position: comment.position,
          page: comment.page
        };

        const responseEditCommentary = await api.put(
          "user-template-comments",
          commentObject
        );

        setCommentsData(oldState => {
          let cloneState = cloneDeep(oldState);
          cloneState.forEach(commentary => {
            if (commentary.id === comment.id) {
              commentary.message = inputEditComment;
            }
          });

          return cloneState;
        });

        toast.success("Comentario editado com sucesso!");
      } catch (err) {
        console.error(err, "error when edit commentary");
        toast.error("Ocorreu um erro.");
      }
    },
    [inputEditComment, setCommentsData]
  );

  const handleDoneCommentary = useCallback(
    async (id: string, action: "done" | "edit" | "remove") => {
      if (action === "done") {
        try {
          const responseDoneComment = await api.put(
            "user-template-comments/conclude",
            {
              template_comment_id: id
            }
          );
          toast.success("Comentario concluido com sucesso.");

          setCommentsData(oldState => {
            let cloneState = cloneDeep(oldState);
            const arrayWithoutObject = cloneState.filter(
              comment => comment.id !== id
            );

            return arrayWithoutObject;
          });

          setListCommentsCanvas(oldState => {
            let cloneState = cloneDeep(oldState);
            const arrayWithoutObject = cloneState.filter(
              comment => comment.id !== id
            );
            return arrayWithoutObject;
          });

          if (commentsData.length === 0) {
            setIsShowEmpty("Não há comentários nesse documento para exibir.");
          }
        } catch (err) {
          console.error(err, "error");
        }
      }
    },
    [commentsData.length, setListCommentsCanvas, setCommentsData]
  );

  const handleDeleteCommentary = useCallback(
    async (id: string, action: "done" | "edit" | "remove") => {
      try {
        const responseDoneComment = await api.delete(
          `user-template-comments/${id}`
        );
        toast.success("Comentario removido com sucesso.");

        setCommentsData(oldState => {
          let cloneState = cloneDeep(oldState);
          const arrayWithoutObject = cloneState.filter(
            comment => comment.id !== id
          );

          return arrayWithoutObject;
        });

        setListCommentsCanvas(oldState => {
          let cloneState = cloneDeep(oldState);
          const arrayWithoutObject = cloneState.filter(
            comment => comment.id !== id
          );
          return arrayWithoutObject;
        });

        if (commentsData.length === 0) {
          setIsShowEmpty("Não há comentários nesse documento para exibir.");
        }
      } catch (err) {
        console.error(err, "error");
      }
    },
    [commentsData.length, setListCommentsCanvas, setCommentsData]
  );

  useEffect(() => {
    (async () => {
      try {
        const responseListAllComments: AxiosResponse<ResponseListAllCommentsProps> =
          await api.get(`user-template-comments/list-all/${templateid}`, {
            params: {
              pagination: currentPage,
              is_excluded_or_done: false
            }
          });

        if (responseListAllComments.data.dataArray.length === 0) {
          setIsShowEmpty("Não há comentários nesse documento para exibir.");
        }

        setCommentsData(oldState => [
          ...oldState,
          ...responseListAllComments.data.dataArray
        ]);
        setTotalPages(responseListAllComments.data.pagination);
        setLoading(false);
      } catch (err) {
        console.error(err, "Error when get all comments");
      }
    })();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage < totalPages) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (!loading) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries?.some(entry => entry?.isIntersecting)) {
          if (hasMore) {
            setCurrentPage(oldState => oldState + 1);
          }
        }
      });
      const myDiv = document.querySelector("#sentinela");
      if (myDiv) {
        intersectionObserver?.observe(myDiv);
      }
      return () => intersectionObserver?.disconnect();
    }
  }, [loading, hasMore]);

  if (commentsData.length === 0 && !loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "30vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <EmptyContent>
          <span style={{ margin: "auto auto", fontSize: "15px" }}>
            {isShowEmpty}
          </span>
        </EmptyContent>
      </div>
    );
  }

  if (loading) {
    return (
      <LoadingBox>
        <ContractAnimation />
      </LoadingBox>
    );
  }


  return (
    <>
      {commentsData.map((comment, index) => {
        return (
          <Comment
            key={comment.id}
            handleEditComment={() => handleEditCommentary(comment)}
            message={comment.message}
            type="Comment To View"
            indexComment={index}
            idUser={comment.user_id}
            idComment={comment.id}
            avatar={comment.avatar_url}
            amountReplies={comment.count_answers}
            commentDate={comment.created_at}
            inputEditComment={inputEditComment}
            setInputEditComment={setInputEditComment}
            numberPage={comment.page}
            categoryComment={categoryComment}
            name={`${comment.first_name} ${comment.last_name}`}
            isShowReplies={true}
            handleDeleteCommentary={() =>
              handleDeleteCommentary(comment.id, "remove")
            }
            handleDoneCommentary={() =>
              handleDoneCommentary(comment.id, "done")
            }
            onClick={() => {
              navigateToElement(Number(comment.page) - 1);
              setSelectedIdComment(comment.id);
              setCategoryComment(prev => {
                return {
                  page: prev.page,
                  isOpenComment: true
                };
              });
            }}
          />
        );
      })}
      {hasMore && !loading && (
        <div
          id="sentinela"
          style={{
            display: "flex",
            width: "100%",
            height: "50px",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <ReactLoading type="spin" color="#000" height={30} width={30} />
        </div>
      )}
    </>
  );
};

export default ListAllCommentsView;
