import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect
} from "react";
import { useMainHook } from "../../../hooks/main";
import { useSelection } from "./selection";
import { useTextsEdition } from "./textsEdition";
import { changeSelectedObject } from "../utils/changeSelectedObject";
import cloneDeep from "lodash/cloneDeep";

interface ClausesHook {
  children: ReactNode;
}

interface KeyboardEditorHookData {}

const KeyboardEditorContext = createContext<KeyboardEditorHookData>({});

const KeyboardEditorHookProvider: React.FC<ClausesHook> = ({ children }) => {
  const { isEditing } = useTextsEdition();
  const { blockCommandKeyboard, forceBlockKey } = useSelection();
  const { selectedObject, selectedObjects, setSelectedObjects } =
    useSelection();
  const { objectScreen, setObjectScreen, pendingSave } = useMainHook();

  useEffect(() => {
    if (selectedObject) {
      setSelectedObjects([]);
    }
  }, [selectedObject]);

  const toggleMovimentObject = useCallback(
    (event: KeyboardEvent, moviment: "up" | "down" | "left" | "right") => {
      event.preventDefault();

      let myCurrentPositionY = selectedObject.attrs.y;
      let myCurrentPositionX = selectedObject.attrs.x;

      switch (moviment) {
        case "up":
          changeSelectedObject({
            setObjectScreen,
            selectedObject,
            propertie: "y",
            value: myCurrentPositionY - 1
          });
          break;

        case "down":
          changeSelectedObject({
            setObjectScreen,
            selectedObject,
            propertie: "y",
            value: myCurrentPositionY + 1
          });
          break;

        case "left":
          changeSelectedObject({
            setObjectScreen,
            selectedObject,
            propertie: "x",
            value: myCurrentPositionX - 1
          });
          break;

        case "right":
          changeSelectedObject({
            setObjectScreen,
            selectedObject,
            propertie: "x",
            value: myCurrentPositionX + 1
          });
          break;

        default:
          break;
      }
    },
    [objectScreen, selectedObject, blockCommandKeyboard, forceBlockKey]
  );

  const whichKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowUp") {
        if (selectedObjects.length > 0) {
          event.preventDefault();
          let newElements = cloneDeep(selectedObjects);
          let ids = [];

          newElements.forEach(object => {
            ids.push(object.attrs.id);
          });

          ids.forEach(id => {
            setObjectScreen(oldState => {
              let cloneState = cloneDeep(oldState);
              cloneState.forEach(page => {
                page.renderObjects.forEach(renderedObject => {
                  if (renderedObject.id === id) {
                    renderedObject.y = renderedObject.y - 1;
                  }
                });
              });

              return cloneState;
            });
          });

          return;
        }

        if (!selectedObject) {
          return;
        } else {
          toggleMovimentObject(event, "up");
        }
      } else if (event.key === "ArrowRight") {
        if (selectedObjects.length > 1) {
          event.preventDefault();

          let newElements = cloneDeep(selectedObjects);
          let ids = [];

          newElements.forEach(object => {
            ids.push(object.attrs.id);
          });

          ids.forEach(id => {
            setObjectScreen(oldState => {
              let cloneState = cloneDeep(oldState);
              cloneState.forEach(page => {
                page.renderObjects.forEach(renderedObject => {
                  if (renderedObject.id === id) {
                    renderedObject.x = renderedObject.x + 1;
                  }
                });
              });

              return cloneState;
            });
          });

          return;
        }

        if (!selectedObject) {
          return;
        } else {
          toggleMovimentObject(event, "right");
        }
      } else if (event.key === "ArrowLeft") {
        if (selectedObjects.length > 1) {
          event.preventDefault();

          let newElements = cloneDeep(selectedObjects);
          let ids = [];

          newElements.forEach(object => {
            ids.push(object.attrs.id);
          });

          ids.forEach(id => {
            setObjectScreen(oldState => {
              let cloneState = cloneDeep(oldState);
              cloneState.forEach(page => {
                page.renderObjects.forEach(renderedObject => {
                  if (renderedObject.id === id) {
                    renderedObject.x = renderedObject.x - 1;
                  }
                });
              });

              return cloneState;
            });
          });

          return;
        }

        if (!selectedObject) {
          return;
        } else {
          toggleMovimentObject(event, "left");
        }
      } else if (event.key === "ArrowDown") {
        if (selectedObjects.length > 1) {
          event.preventDefault();

          let newElements = cloneDeep(selectedObjects);
          let ids = [];

          newElements.forEach(object => {
            ids.push(object.attrs.id);
          });

          ids.forEach(id => {
            setObjectScreen(oldState => {
              let cloneState = cloneDeep(oldState);
              cloneState.forEach(page => {
                page.renderObjects.forEach(renderedObject => {
                  if (renderedObject.id === id) {
                    renderedObject.y = renderedObject.y + 1;
                  }
                });
              });

              return cloneState;
            });
          });

          return;
        }

        if (!selectedObject) {
          return;
        } else {
          toggleMovimentObject(event, "down");
        }

        pendingSave.current = true;
      }
    },
    [
      selectedObject,
      selectedObjects,
      pendingSave,
      setObjectScreen,
      toggleMovimentObject
    ]
  );

  useEffect(() => {
    if (isEditing !== "open") {
      if (forceBlockKey || blockCommandKeyboard) {
        return;
      } else {
        window.addEventListener("keydown", whichKey);
        return () => window.removeEventListener("keydown", whichKey);
      }
    }
  }, [
    isEditing,
    selectedObject,
    forceBlockKey,
    blockCommandKeyboard,
    selectedObjects,
    whichKey
  ]);

  return (
    <KeyboardEditorContext.Provider value={{}}>
      {children}
    </KeyboardEditorContext.Provider>
  );
};

// creating hook
function useKeyboardEditor(): KeyboardEditorHookData {
  const context = useContext(KeyboardEditorContext);

  if (!context) {
    throw new Error(
      "useKeyboardEditor must be used with an KeyboardEditorHookProvider"
    );
  }

  return context;
}

export { KeyboardEditorHookProvider, useKeyboardEditor };

