import styled from "styled-components";

interface IContainerProsp {
  isActive: boolean;
}

export const Container = styled.div<IContainerProsp>`
  width: 30px;
  height: 842px;

  position: relative;

  user-select: none;
  cursor: ${props => (props.isActive ? "e-resize" : "default")};
  opacity: ${props => (props.isActive ? 1 : 0)};

  & > div {
    display: grid;
    gap: 6px;
    grid-template-rows: repeat(30, 1fr);
    height: 842px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 100%;
    color: #a5a5a5;

    width: 11.38px;

    position: relative;
    top: -4px;
    user-select: none;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    margin-right: 6px;
    user-select: none;

    .first-vector {
      width: 4.5px;
    }
    .second-vector {
      width: 10px;
    }
  }

  span {
    background-color: #a5a5a5;
    height: 1px;
    user-select: none;
  }
`;
