import styled from "styled-components";

export const Container = styled.div`
  color: #343a40;
  background-color: #fff;

  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  font-weight: 400;

  p {
    font-size: 13px;
    font-weight: 400;
  }

  .obs-tooltip {
    color: #a5a5a5;
    display: block;
    margin-top: 3px;
  }
`;
