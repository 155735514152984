import React from "react";
import { useTheme } from "../../../../../../hooks/theme";

interface BaseIconProps {
  isActive?: boolean;
  className?: string;
}

export default function BringToForwardIcon({
  isActive,
  className
}: BaseIconProps) {
  const { theme } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M13.3151 2H5.77171C3.69727 2 2 3.69727 2 5.77171V13.3151C2 15.3896 3.69727 17.0869 5.77171 17.0869H13.3151C15.3896 17.0869 17.0869 15.3896 17.0869 13.3151V5.77171C17.0869 3.69727 15.3896 2 13.3151 2Z"
        fill="#343A40"
      />
      <path
        d="M18.2292 22.0004H10.6858C8.61133 22.0004 6.91406 20.3032 6.91406 18.2287H8.1713C8.1713 19.6117 9.30282 20.7432 10.6858 20.7432H18.2292C19.6122 20.7432 20.7437 19.6117 20.7437 18.2287V10.6853C20.7437 9.30233 19.6122 8.17081 18.2292 8.17081V6.91357C20.3036 6.91357 22.0009 8.61085 22.0009 10.6853V18.2287C22.0009 20.3032 20.3036 22.0004 18.2292 22.0004Z"
        fill="#343A40"
        stroke="#343A40"
      />
    </svg>
  );
}
