import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
`;

export const BoxResponse = styled.ul`
  display: flex;
  flex-direction: column;
`;
