import React from "react";
import { useAuth } from "../../../../hooks/auth";
import { useMainHook } from "../../../../hooks/main";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import { useAsidebarEditor } from "../../hooks/asidebarEditor";
import { useTextsEdition } from "../../hooks/textsEdition";
import ClausesIcon from "../Sidebar/components/Icons/ClausesIcon";
import CloudIcon from "../Sidebar/components/Icons/CloudIcon";
import CloudWeatherIcon from "../Sidebar/components/Icons/CloudWeatherIcon";
import ElementsIcon from "../Sidebar/components/Icons/ElementsIcon";
import TemplateIcon from "../Sidebar/components/Icons/TemplateIcon";

import ButtonIconsWithTooltip from "./components/ButtonIconsAsidebar";
import { darken, lighten, saturate } from "polished";
import { useTheme } from "../../../../hooks/theme";
import MountIcons from "../../../../shared/utils/MountIcons";
import IllustrationIcon from "../Sidebar/components/Icons/IllustrationIcon";
import AutomatizingIcon from "../Sidebar/components/Icons/CloudIcon copy";

// import { Container } from './styles';

const ButtonsAsideBar: React.FC = () => {
  const { handleNavigate, selectedTab } = useAsidebarEditor();
  const { objectScreen, handleOpenModalBlockFuncionalities, isTeamTemplate } =
    useMainHook();
  const { permissionType } = useAuth();
  const { theme } = useTheme();

  const colorActive = darken(
    0.05,
    saturate(0.6, lighten(0.24, theme.colors.primary))
  );

  return (
    <>
      <ButtonIconsWithTooltip
        width="100%"
        onClick={() => handleNavigate("templates")}
        className="btn-toolbar"
        title="Templates"
        description="Escolha um modelo para iniciar o seu projeto"
        placement="bottom-start"
      >
        <TemplateIcon
          className="asidebar-icon"
          isActive={selectedTab === "templates"}
          colorActive={colorActive}
        />
      </ButtonIconsWithTooltip>

      <ButtonIconsWithTooltip
        width="35px"
        onClick={() => handleNavigate("clauses")}
        className="btn-toolbar"
        title="Biblioteca de texto"
        description="Use textos prontos da nossa biblioteca ou utilize os textos criados por você ou da sua equipe."
        placement="bottom-start"
      >
        <ClausesIcon
          className="asidebar-icon"
          isActive={selectedTab === "clauses"}
          colorActive={colorActive}
        />
      </ButtonIconsWithTooltip>

      {/* <ButtonIconsWithTooltip
                width="35px"
                onClick={() => handleNavigate("groupElements")}
                className="btn-toolbar"
              >
                <Tooltip text="Bloco de elementos" position="bottom">
                  <GroupElementIcon
                    className="group-elements-icon"
                    isActive={selectedTab === "groupElements"}
                  />
                </Tooltip>
              </ButtonIconsWithTooltip> */}

      <ButtonIconsWithTooltip
        width="35px"
        onClick={() => handleNavigate("text")}
        className="btn-toolbar"
        title="Texto"
        description="Adicione uma nova caixa de texto no seu documento."
        placement="bottom-start"
      >
        <PersonalizedIcon
          width={31}
          inactivatedColor="#999C9F"
          activeColor={colorActive}
          isActive={selectedTab === "text"}
          height={31}
          className="asidebar-icon"
          dPath={MountIcons.IconText.dPath}
          viewBox={MountIcons.IconText.viewBox}
        />
      </ButtonIconsWithTooltip>

      <ButtonIconsWithTooltip
        width="35px"
        onClick={() => handleNavigate("elements")}
        className="btn-toolbar"
        title="Formas"
        description="Use formas prontas como círculos, quadrados e setas no seu projeto."
        placement="bottom-start"
      >
        <ElementsIcon
          className="asidebar-icon"
          isActive={selectedTab === "elements"}
          colorActive={colorActive}
        />
      </ButtonIconsWithTooltip>

      <ButtonIconsWithTooltip
        width="35px"
        onClick={() => handleNavigate("icons")}
        className="btn-toolbar"
        title="Ícones"
        description="Use ícones da nossa biblioteca para melhorar a comunicação do seu texto."
        placement="bottom"
      >
        <PersonalizedIcon
          width={32}
          inactivatedColor="#a5a5a5"
          activeColor={colorActive}
          isActive={selectedTab === "icons"}
          height={32}
          className="asidebar-icon"
          dPath={MountIcons.IconsWith.dPath}
          viewBox={MountIcons.IconsWith.viewBox}
        />
      </ButtonIconsWithTooltip>

      {/* <ButtonIconsWithTooltip
                width="35px"
                style={
                  permissionType === "editor"
                    ? { opacity: 0.2, cursor: "not-allowed" }
                    : {}
                }
                onClick={() => {
                  if (permissionType === "editor") return;
                  handleNavigate("comments");
                }}
                className="btn-toolbar"
              >
                <Tooltip position="bottom" text="Comentários">
                  <CommentsIcon
                    className="comments-svg-icon"
                    isActive={selectedTab === "comments"}
                  />
                </Tooltip>
              </ButtonIconsWithTooltip> */}
      {/* 
      <ButtonIconsWithTooltip
        width="35px"
        style={
          permissionType === "editor" || isTeamTemplate
            ? { opacity: 0.2, cursor: "not-allowed" }
            : {}
        }
        onClick={() => {
          if (permissionType === "editor" || isTeamTemplate) return;
          handleNavigate("controlOfVersions");
        }}
        className="btn-toolbar"
      >
        <Tooltip position="bottom" text="Histórico de versões">
          <CloudWeatherIcon
            className="versioning-svg-icon"
            isActive={selectedTab === "controlOfVersions"}
          />
        </Tooltip>
      </ButtonIconsWithTooltip> */}

      <ButtonIconsWithTooltip
        width="35px"
        onClick={() => handleNavigate("vectors")}
        className="btn-toolbar"
        title="Ilustrações"
        description="Use ilustrações prontas como imagens 3D, fluxogramas, molduras e linhas do tempo no seu projeto."
        placement="bottom"
      >
        <IllustrationIcon
          className="asidebar-icon"
          isActive={selectedTab === "vectors"}
          colorActive={colorActive}
        />
      </ButtonIconsWithTooltip>

      <ButtonIconsWithTooltip
        className="btn-toolbar"
        width="35px"
        onClick={() => handleNavigate("upload")}
        title="Uploads"
        description="Faça o upload de imagens salvas no seu computador como sua logo, fotos e ícones ou imagens próprias para usar em seus projetos."
        placement="bottom-end"
      >
        <CloudIcon
          className="asidebar-icon"
          isActive={selectedTab === "upload"}
          colorActive={colorActive}
        />
      </ButtonIconsWithTooltip>

      <ButtonIconsWithTooltip
        width="35px"
        style={
          permissionType === "editor" || isTeamTemplate
            ? { opacity: 0.2, cursor: "not-allowed" }
            : {}
        }
        onClick={() => {
          if (permissionType === "editor" || isTeamTemplate) return;
          //verify changes for block download with marks of editions
          let changes = false;

          objectScreen.forEach(page => {
            page.renderObjects.forEach(renderedObject => {
              if (renderedObject.text && !changes) {
                if (renderedObject.isModify) {
                  changes = true;
                } else {
                  changes = false;
                }
              }
            });
          });

          if (changes) {
            //if have changes pending then open modal to
            //user resolve conflicts of texts before
            //that do download of document

            handleOpenModalBlockFuncionalities("automatizing");
          } else {
            //if don`t have modifications in document
            //let him do download
            handleNavigate("automatizing");
          }
        }}
        className="btn-toolbar"
        title="Automação"
        description='Crie e preencha variáveis automáticas em seu projeto. Insira a marcação {{...}} sem espaços, separados por "_" nos locais que deseje automatizar. Exemplo: {{nome_do_contratante}}.'
        placement="bottom-end"
      >
        <AutomatizingIcon
          className="asidebar-icon"
          isActive={selectedTab === "automatizing"}
          colorActive={colorActive}
        />
      </ButtonIconsWithTooltip>
    </>
  );
};

export default ButtonsAsideBar;
