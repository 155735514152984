import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  Text,
  DropDownContainer,
  DropDownList,
  DropDownListContainer,
  ListItem,
  Box,
  AvatarProfile,
  ProfileInfo,
  NameProfile,
  EmailProfile,
  DividerLine,
  TrialPeriodEndSpam,
} from "./styles";
import { animate, motion, useAnimation, useMotionValue } from "framer-motion";
import ImageUploading from "react-images-uploading";

import useComponentVisible from "../../../../hooks/useComponentVisible";

import cameraIcon from "../../assets/icon-camera.svg";
import { useAuth } from "../../../../hooks/auth";

import api from "../../../../services/api";

import PuffLoader from "react-spinners/PuffLoader";
import { useMainHook } from "../../../../hooks/main";
import { useNavigate } from "react-router-dom";
import { useSharedDocument } from "../../hooks/sharedDocument";
import { getColors } from "../../utils/getColorAvatar";
import MultiplesAvatarLetter from "../../../dashboard/master/components/MultiplesAvatarLetter";

interface DropProps {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  children: ReactNode;
  isEditor?: boolean;
  zIndexActive?: boolean;
}

const DropdownAvatarDash = ({
  isOpen,
  setIsOpen,
  children,
  zIndexActive,
  isEditor = false,
}: DropProps) => {
  const list = {
    visible: {
      opacity: 1,
      y: 10,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
    hidden: {
      opacity: 0,
      y: 0,
      transition: {
        when: "afterChildren",
      },
    },
  };

  const variants = {
    visible: (i) => ({
      opacity: 1,
      transition: {
        delay: i * 0.3,
      },
    }),
    hidden: { opacity: 0 },
  };

  const { refreshUsersShared, setRefreshUsersShared } = useSharedDocument();
  const { data, signOut, modifyProfile, permissionType } = useAuth();
  const navigate = useNavigate();
  const { srcAvatar, refreshAvatar, setRefreshAvatar } = useMainHook();

  const navigation = useNavigate();
  const x = useMotionValue(0);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [letters, setLetters] = useState("");

  //get profile name
  const [dataProfile, setDataProfile] = useState({
    name: "",
    lastName: "",
  });

  const maxNumber = 69;

  const convertDigitIn = useCallback(
    (dateString?: string) => {
      if (
        data?.payment_method?.subscription_id ||
        !data.payment_method.cancellation_date
      ) {
        return;
      }
      let newDate = dateString?.split("-")?.reverse()?.join("/");
      return `Período gratuito até ${newDate}`;
    },
    [
      data?.payment_method?.subscription_id,
      data.payment_method.cancellation_date,
    ]
  );

  const onChange = useCallback(
    async (imageList, addUpdateIndex) => {
      // data for submit
      setImages(imageList);
      let formData = new FormData();
      formData.append("avatar", imageList[0].file);
      try {
        setLoading(true);
        const fetchUploadAvatarResponse = await api.patch(
          `users/avatar`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setLoading(false);
        setRefreshAvatar(!refreshAvatar);
        setImages([]);
        setRefreshUsersShared(!refreshUsersShared);
      } catch (err) {
        console.error(err, "error when update avatar");
      }
    },
    [refreshAvatar, refreshUsersShared, setRefreshAvatar, setRefreshUsersShared]
  );

  useEffect(() => {
    setRefreshAvatar(!refreshAvatar);
  }, [images]);

  useEffect(() => {
    const controls = animate(x, 100, {
      type: "spring",
      stiffness: 2000,
    });

    return controls.stop;
  },[]);

  const handleEnterBox = useCallback(() => {
    setIsComponentVisible(true);
  }, [setIsComponentVisible]);

  const handleClick = useCallback(() => {
    if (isEditor) return;
    setIsOpen(true);
    setIsComponentVisible(true);
  }, [isEditor, setIsComponentVisible, setIsOpen]);

  useEffect(() => {
    setLetters(`${data.people.first_name} ${data.people.last_name}`);
  }, []);

  useEffect(() => {
    const autoLoad = async () => {
      try {
        const request = await api.get("profile");

        setDataProfile((prevState) => {
          return {
            ...prevState,
            name: request.data.people.first_name,
            lastName: request.data.people.last_name,
          };
        });
      } catch (err) {
        console.error(err, "error when get profile user");
      }
    };

    autoLoad();
  }, [modifyProfile]);

  return (
    <DropDownContainer
      zIndexActive={zIndexActive}
      ref={ref}
      onClick={handleClick}
    >
      {children}
      {isOpen && isComponentVisible && (
        <DropDownListContainer>
          <DropDownList
            variants={list}
            initial="hidden"
            animate="visible"
            as={motion.ul}
            onMouseEnter={handleEnterBox}
          >
            <Box>
              {!loading ? (
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI2

                    <AvatarProfile onClick={onImageUpload} {...dragProps}>
                      <MultiplesAvatarLetter
                        className="avatar-profile"
                        src={srcAvatar}
                        text={letters}
                        isDropdown
                        setText={setLetters}
                        color={getColors(true)}
                      ></MultiplesAvatarLetter>
                      <img className="camera-icon" src={cameraIcon} />
                    </AvatarProfile>
                  )}
                </ImageUploading>
              ) : (
                <AvatarProfile>
                  <PuffLoader color="#663399" size="28" />
                </AvatarProfile>
              )}

              <ProfileInfo>
                <NameProfile>
                  {`${dataProfile.name} ${dataProfile.lastName}`}
                </NameProfile>
                <EmailProfile> {data?.user?.email} </EmailProfile>
                <TrialPeriodEndSpam>
                  {!data.payment_method.subscription_id &&
                    convertDigitIn(data?.payment_method?.cancellation_date)}
                </TrialPeriodEndSpam>
              </ProfileInfo>
            </Box>

            <ListItem
              style={
                permissionType === "editor"
                  ? { opacity: 0.2, cursor: "not-allowed" }
                  : {}
              }
              onClick={() => {
                if (permissionType === "editor") return;
                navigate("/perfil");
              }}
            >
              Configurações da conta
            </ListItem>

            {/* <ListItem>Ajuda</ListItem>
              <ListItem>Indicar amigos</ListItem> */}
            <ListItem
              onClick={() =>
                window.open("https://uxdoc.com.br/politica-de-privacidade")
              }
            >
              Política de privacidade
            </ListItem>
            <ListItem
              onClick={() => {
                navigation("/");
                signOut();
              }}
            >
              Sair
            </ListItem>
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default DropdownAvatarDash;
