import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  height: 100vh;

  p {
    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  @media (min-width: 1920px) {
    p {
      font-size: 16px;
    }
  }
`;

export const MainContainer = styled.div`
  width: 405px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  text-align: center;

  @media (max-width: 431px) {
    width: 87%;
  }

  @media (min-width: 1600px) {
    width: 450px;
  }

  @media (min-width: 1920px) {
    width: 560px;
  }
`;
