import React, { useCallback, useContext, useState } from "react";

import { MdOutlineDesktopWindows } from "react-icons/md";
import { FaRegStickyNote } from "react-icons/fa";
import {
  Container,
  DraggBox,
  FileNameLabel,
  Label1,
  Label2,
  NotUploadedState,
  SelectContainer,
  UploadButton,
  UploadContainer,
  UploadedState
} from "./styles";
import PdfIcon from "../../PdfIcon";
import IllustrationIcon from "../../IllustrationIcon";
import SelectItem from "../../SelectItem";
import { Box, DialogContent } from "@material-ui/core";
import { toast } from "react-toastify";
import { CreateProjectContext } from "..";
import { ApprovalFlowRequest } from "../../../types/ApprovalFlowRequest";
import TemplatesSelect from "../../TemplateSelect";
import styled from "styled-components";

interface TabProps {
  isActive: boolean;
}

const NavigationBarContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-top: 10px;
`;

const Tab = styled.div<TabProps>`
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? "400" : "400")};
  border-bottom: 3px solid
    ${({ isActive }) => (isActive ? "#7547A3" : "transparent")};
`;

interface NavTabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  icon: React.ReactNode;
}

const NavTab: React.FC<NavTabProps> = ({ label, icon, isActive, onClick }) => {
  return (
    <Tab isActive={isActive} onClick={onClick}>
      {icon}
      {label}
    </Tab>
  );
};

type Props = {
  flows: ApprovalFlowRequest[];
  activeTab: number;
  setActiveTab: (tab: number) => void;
  resend?: boolean;
};

const StepOne: React.FC<Props> = ({
  flows,
  activeTab,
  setActiveTab,
  resend
}: Props) => {
  const [isDragged, setIsDragged] = useState(false);
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const { project, setProject, uploadedFile, setUploadedFile } =
    useContext(CreateProjectContext);

  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
  };
  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    },
    []
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      let runOneTime = false;
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      // console.error(file.type, "tipo?");
      if (file.type !== "application/pdf") {
        setIsDragged(false);
        if (!runOneTime) {
          runOneTime = true;
          toast.error("Só é permitido inserir arquivos PDF.");
        }
        return;
      }
      setIsDragged(false);
      setUploadedFile(file);
    },
    [setUploadedFile, setIsDragged]
  );

  const handleFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file && file.type === "application/pdf") {
        setUploadedFile(file);
        setProject({ ...project, name: file.name });
      } else {
        toast.error("Só é permitido inserir arquivos PDF.");
        setIsDragged(false);
      }
    },
    [project, setProject, setUploadedFile]
  );

  const handleOpenFileDialog = useCallback(() => {
    const fileInput = document.getElementById("file-input");
    if (fileInput) {
      fileInput.click();
    }
  }, []);

  const handleRemoveFile = useCallback(() => {
    setUploadedFile(null);
  }, [setUploadedFile]);

  const handleSelectFlow = useCallback(
    (selectedFlow: ApprovalFlowRequest) => {
      selectedFlow.steps.forEach(step => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + step.deadline);
        step.planned_deadline_date = newDate.toISOString();
      });
      setProject({
        ...project,
        approval_flow: selectedFlow
      });
    },
    [setProject, project]
  );

  return (
    <>
      <NavigationBarContainer>
        <NavTab
          label="Importar do computador"
          isActive={activeTab === 0}
          onClick={() => handleTabClick(0)}
          icon={
            <MdOutlineDesktopWindows
              color={activeTab === 0 ? "#000" : "#676B70"}
            />
          }
        />
        <NavTab
          label="Importar de Meus projetos"
          isActive={activeTab === 1}
          onClick={() => handleTabClick(1)}
          icon={
            <FaRegStickyNote color={activeTab === 1 ? "#000" : "#676B70"} />
          }
        />
      </NavigationBarContainer>
      <DialogContent style={{ overflowX: "hidden" }}>
        <Container>
          {activeTab === 0 && (
            <>
              <UploadContainer
                onDragEnter={event => {
                  setIsDragged(true);
                }}
                onDragOver={handleDragOver}
              >
                {!uploadedFile && (
                  <NotUploadedState>
                    {isDragged && (
                      <DraggBox
                        onDrop={handleDrop}
                        onDragEnd={() => setIsDragged(false)}
                        onDragLeave={() => {
                          if (!uploadedFile) {
                            setIsDragged(false);
                          }
                        }}
                        onDragOver={handleDragOver}
                      ></DraggBox>
                    )}

                    <IllustrationIcon />
                    <input
                      id="file-input"
                      type="file"
                      accept=".pdf"
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                    <UploadButton
                      onClick={handleOpenFileDialog}
                      style={{ width: "250px" }}
                    >
                      Selecionar documento
                    </UploadButton>
                    <Label1>ou arraste um documento aqui </Label1>
                    <Label2>No máximo 5MB no formato .pdf</Label2>
                  </NotUploadedState>
                )}
                {uploadedFile && (
                  <UploadedState>
                    <PdfIcon />
                    <FileNameLabel>{uploadedFile.name}</FileNameLabel>
                    <UploadButton
                      style={{ width: "250px" }}
                      onClick={handleRemoveFile}
                    >
                      Escolher outro documento
                    </UploadButton>
                  </UploadedState>
                )}
              </UploadContainer>
              {!resend && (
                <SelectContainer open={selectModalOpen}>
                  <Box style={{ width: "170px" }}>
                    {" "}
                    <Label1>Selecione o fluxo*</Label1>
                  </Box>

                  <SelectItem
                    items={flows}
                    value="name"
                    onChange={selected => handleSelectFlow(selected)}
                    selectedItem={project.approval_flow}
                    onModalStateChange={value => setSelectModalOpen(value)}
                  />
                </SelectContainer>
              )}
            </>
          )}

          {activeTab === 1 && (
            <TemplatesSelect
              flows={flows}
              handleSelectFlow={handleSelectFlow}
              resend={resend}
            />
          )}
        </Container>
      </DialogContent>
    </>
  );
};

export default StepOne;
