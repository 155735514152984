import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const SelectTab = styled.div`
  width: 100%;
  height: 44px;
  display: flex;

  border-bottom: 1px solid #d6d8d9;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
  }
`;

export const MyUploads = styled.div<{
  selectedTab: "MyUploads" | "TeamsUploads";
}>`
  width: 50%;
  min-height: 43px;
  background-color: #fff;

  display: flex;

  color: ${({ selectedTab, theme }) =>
    selectedTab === "MyUploads" ? theme.colors.primary : "#676B70"};
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  :hover {
    background-color: #ebebec;
  }
`;

export const TeamUploads = styled.div<{
  selectedTab: "MyUploads" | "TeamsUploads";
}>`
  width: 50%;
  min-height: 43px;
  display: flex;
  color: ${({ selectedTab, theme }) =>
    selectedTab === "TeamsUploads" ? theme.colors.primary : "#676B70"};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  :hover {
    background-color: #ebebec;
  }
`;
