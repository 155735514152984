import { lighten } from "polished";
import styled from "styled-components";

export const Container = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  height: 30px;

  & > div {
    height: 100%;
    padding: 5px 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ButtonCreatedText = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;

  height: 100%;
  width: 197px;
  color: ${({ theme }) => theme.colors.white100};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary90};
  padding-left: 12px;

  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
`;

export const Main = styled.div`
  width: 100%;
  height: 68vh;
  min-height: 522px;

  @media (min-width: 1920px) {
    max-height: 734px;
  }
`;

export const GridText = styled.ul`
  display: grid;
  grid-template-rows: repeat(12, 44px);
  height: 100%;
  position: relative;
`;

export const ContainerLogin = styled.span`
  width: 100%;
  height: 400px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableText = styled.div`
  border-radius: 5px 5px 0px 0px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  min-height: 454.5px;
  height: 90%;
`;

export const HeaderTable = styled.div`
  height: 30px;
  border-radius: 5px 5px 0px 0px;
  background-color: ${({ theme }) => theme.colors.black10};

  display: flex;
  align-items: center;

  display: grid;
  grid-template-columns: 1fr 1fr;

  font-family: "Ubuntu";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black100};

  p:first-child {
    padding-left: 54px;
  }
`;

export const TextInfo = styled.span`
  background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
  color: ${({ theme }) => theme.colors.primary};
  display: block;

  padding: 15px;
  border-radius: 5px;

  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;

  @media (max-width: 1600px) {
    padding: 13px;
  }
`;
