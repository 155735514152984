import React from "react";
import { ButtonsBox, Container, Modal } from "./styles";
import { useMainHook } from "../../../../hooks/main";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import PlainText from "../../../../shared/components/PlainText";

const DeleteTemplateModal = () => {
  const { removeDocument, nameDocumentDelete, handleRequestModal } =
    useMainHook();

  return (
    <Modal>
      <Container>
        <h5>Excluir projeto</h5>
        <PlainText>
          {`Tem certeza que você quer excluir o projeto ${nameDocumentDelete}?`}
        </PlainText>

        <ButtonsBox>
          <ButtonMaxWidth
            text="Não"
            typeStyle="purple"
            onClick={handleRequestModal}
          />
          <ButtonMaxWidth
            text="Sim"
            typeStyle="white"
            onClick={async () => {
              await removeDocument.mutate();
              handleRequestModal();
            }}
            loading={removeDocument.isLoading}
          />
        </ButtonsBox>
      </Container>
    </Modal>
  );
};

export default DeleteTemplateModal;
