import styled from "styled-components";
interface TextProps extends React.HTMLProps<HTMLTextAreaElement> {
  margin?: string;
}
export const Button = styled.button`
  height: 30px;
  min-width: 93px;
  border-radius: 5px;
  background: transparent;

  display: flex;
  padding: 3px 16px 3px 10px;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .icon-back {
    width: 24px;
    height: 24px;
  }
`;

export const Text = styled.p<TextProps>`
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;

  color: ${({ theme }) => theme.colors.white100};
`;
