import styled from "styled-components";

import { lighten, darken } from "polished";
import { Theme } from "../../../../../dtos/theme";

interface AsideProps {
  src?: string;
}

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  height: 100vh;
`;

export const Form = styled.form`
  width: 405px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 431px) {
    width: 87%;
  }

  @media (min-width: 1600px) {
    width: 450px;
  }

  @media (min-width: 1920px) {
    gap: 20px;
  }
`;

export const TextError = styled.p<{ isError: boolean }>`
  padding-left: 13px;
  height: 30px;
  border: 1px solid ${({ isError }) => (isError ? "#de2d2d" : "transparent")};
  border-radius: 5px;

  font-size: 13px;

  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const AsideContainer = styled.aside`
  width: 100%;
  max-width: 40%;
  background: white;
  display: flex;
  height: 100vh;
  z-index: 100;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
`;

export const ImageLogo = styled.div<AsideProps>`
  width: 300px;
  height: 100px;
  background-image: url(${({ src }) => src && src});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const LogoOrganization = styled.img`
  width: 115px;
  height: 61px;
`;

export const MarketingSide = styled.div`
  width: 60%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 5rem 6rem 6rem 6rem;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${darken(0.45, theme.colors.primary)} 0%, ${
      theme.colors.primary
    } 100%)`};

  .computerImg {
    width: 674px;
    height: 689.03px;
    z-index: 150;
    margin-left: 2.5rem;

    @media (max-width: 1920px) {
      width: 500.75px;
      height: 511.44px;
      margin-left: 6rem;
    }

    @media (max-width: 1600px) {
      width: 426px;
      height: 436px;
      margin-left: 6rem;
    }

    @media (max-width: 1366px) {
      width: 359px;
      height: 366 px;
      margin-left: 5rem;
    }
  }

  @media (max-width: 1600px) {
    padding: 4rem 3rem 7rem 4rem;
  }

  @media (max-width: 1366px) {
    padding: 10rem 5rem 4rem 2rem;
  }
`;

export const InputBase = styled.input`
  width: 100%;
  height: 45px;
  border: none;
  border-bottom: 1px solid black;
  font-size: 2rem;
  background-color: transparent;
  padding-bottom: 1.5rem;
  margin-top: 5rem;

  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
    inset 0 0 0 100px rgba(255, 255, 255, 1);

  @media (max-width: 1600px) {
    font-size: 1.4rem;
    margin-top: 0rem;
    padding-bottom: 0rem;
  }

  @media (max-width: 1366px) {
    margin-top: 1.6rem;
    padding-bottom: 0rem;
    border-bottom: 0.5px solid black;
  }
`;

export const Label = styled.label`
  font-size: 1.6rem;
  font-style: italic;
  text-decoration: underline;
  color: #555454;
  margin-top: 1.5rem;
  margin-left: auto;
  cursor: pointer;

  transition: all 120ms linear;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 1600px) {
    font-size: 1.4rem;
    margin-top: 1rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.2rem;
  }
`;

export const ButtonStyled = styled.button`
  background-color: black;
  width: 100%;
  color: white;
  margin-bottom: 4.3rem;
  font-size: 2rem;
  font-weight: bold;
  height: 58px;
  border-radius: 10px;
  margin-top: 4.3rem;
  -webkit-box-shadow: -1px 1px 16px -8px rgba(0, 0, 0, 0.23);
  box-shadow: -1px 1px 16px -8px rgba(0, 0, 0, 0.23);
  transition: all 120ms linear;

  :hover {
    background: ${({ theme }) => theme.colors.primary};
    -webkit-box-shadow: -1px 1px 6px -1px rgba(0, 0, 0, 0.2);
    box-shadow: -1px 1px 6px -1px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1600px) {
    font-size: 1.5rem;
    margin-top: 3.3rem;
    margin-bottom: 3.3rem;
    height: 50px;
  }

  @media (max-width: 1366px) {
    height: 45px;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;

export const LoginContent = styled.form`
  display: flex;
  width: 100%;
  min-width: 448px;
  padding: 0 14rem;
  height: auto;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    text-align: center;
    font-size: 2rem;
    color: #555454;
    margin: -2rem auto 4rem auto;
  }

  @media (max-width: 1366px) {
    padding: 0 11rem;
  }

  .logo-login {
    width: 221px;
    height: 96.5px;
    margin-bottom: 4rem;
  }

  .error-validation {
    font-size: 1.4rem;
    display: flex;
    justify-content: flex-start;
    margin-right: auto;
    color: red;
  }

  .force-margin {
    margin: 2rem 0;

    :focus {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }

  .hero-login-div {
    overflow: hidden;
    display: flex;
    margin-bottom: 4rem;

    @media (max-width: 1366px) {
      /* margin-bottom: 0; */

      width: 300px !important;
      margin-top: 2rem;
      display: flex;
      align-items: center;
      transform: translateY(30px);
    }
  }

  .hero-login-img {
    transform: translateY(-1px) translateX(2px);
  }

  @media (max-width: 1600px) {
    .logo-login {
      width: 179px;
      height: 60.5px;
      margin-bottom: 0rem;
      margin-top: 2.4rem;
    }

    .hero-login-div {
      width: 178px;
      height: 77px;
      overflow: hidden;
      display: flex;
      margin-bottom: 2rem;
    }

    .hero-login-img {
      transform: translateY(-1px) translateX(2px);
    }

    span {
      margin: 0rem auto 4rem auto;
      font-size: 1.5rem;
      max-width: 340px;
    }
  }

  @media (max-width: 1366px) {
    .force-margin {
      margin: 1rem 0;
      font-size: 1.4rem;
    }

    .logo-login {
      margin-bottom: 1rem;
    }

    .hero-login-div {
      width: 139px;
      height: 60px;
      overflow: hidden;
      display: flex;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    .hero-login-img {
      transform: translateY(-1px) translateX(2px);
    }
  }

  @media (max-width: 1366px) {
    span {
      margin: 2.5rem auto 2.5rem auto;
      font-size: 1.5rem;
      max-width: 340px;
    }

    margin: 1rem auto 0rem auto;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1220px;
  height: 100%;
  overflow-x: hidden;
  overflow: hidden;
`;

export const FormBox = styled.form`
  width: 844px;
  margin: auto 2rem;
  display: flex;
  padding: 10px 0 2px 0;
  flex-direction: column;
  height: 676px;
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  border-radius: 30px;
  box-shadow: -1px 1px 15px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 15px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 15px -3px rgba(0, 0, 0, 0.75);

  .img-logo {
    margin: 7.5rem auto 1rem auto;
    width: 252.78px;
    height: 53px;
  }

  @media (max-width: 1610px) {
    width: 720px;
    height: 560px;

    .img-logo {
      margin: 7.5rem auto 1rem auto;
      width: 192.78px;
      height: 43px;
    }
  }

  @media (max-width: 1399px) {
    width: 620px;
    height: 488px;

    .img-logo {
      margin: 4.5rem auto 0rem auto;
      width: 162.78px;
      height: 43px;
    }
  }
`;

export const HStack = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .img-background {
    z-index: 999;
  }

  @media (max-width: 1366px) {
    .img-background {
      width: 460px;
      margin-top: -3rem;
    }
  }
`;

export const Heading = styled.h1`
  font-size: 4.8rem;
  max-width: 374px;
  font-family: Raleway, sans-serif;
  line-height: 65px;
  color: white;
  text-align: left;
  transform: translateY(40px);

  @media (max-width: 1600px) {
    font-size: 3.8rem;
    line-height: 52px;
    width: 300px;
    /* transform: translateY(10px) translateX(25px); */
  }

  @media (max-width: 1366px) {
    font-size: 3.2rem;
    line-height: 42px;
    margin-left: auto;

    transform: translateY(115px) translateX(30px);
    width: 270px;
  }
`;

export const Header = styled.h1`
  font-size: 3.6rem;
  width: 100%;
  font-family: Raleway, sans-serif;
  line-height: 45px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  margin: 0 auto;

  @media (max-width: 1600px) {
    /* font-size: 3.8rem;
    line-height: 52px;
    width: 300px; */
    /* transform: translateY(10px) translateX(25px); */
    /* color: ${({ theme }) => theme.colors.background}; */
  }

  @media (max-width: 1366px) {
    /* font-size: 3.2rem;
    line-height: 42px;
    margin-left: auto;
    color: ${({ theme }) => theme.colors.background};
    transform: translateY(115px) translateX(30px);
    width: 270px; */
    font-size: 3rem;
    line-height: 30px;
    text-align: center;
    width: 100%;
  }
`;

export const LoadingFlex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  max-width: 548px;
  height: 360px;
  padding: 0 2rem;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  justify-content: center;
`;

export const Input = styled.input`
  margin: 0.9rem auto;
  width: 100%;
  max-width: 418px;
  height: 45px;
  border: none;
  border-bottom: 4px solid ${({ theme }: Theme) => theme.colors.primary};
  border-radius: 2px;
  padding: 1rem 1rem 1rem 0rem;

  &::placeholder {
    font-size: 1.25rem;
    font-family: "Roboto";
    font-weight: 400;
  }
`;

export const Button = styled.button`
  width: 100%;
  max-width: 423px;
  height: 58px;
  margin: 0 auto;
  font-size: 1.25rem;
  font-family: "Roboto";
  font-weight: 600;
  margin-top: 3rem;
  color: ${({ theme }: Theme) => theme.colors.whiteColor};
  background: linear-gradient(
    to top,
    ${({ theme }) => theme.colors.primary},
    ${({ theme }) => theme.colors.primary} 148%
  );

  transition: all 0.9s linear;

  &:hover {
    background: linear-gradient(
      to top,
      ${({ theme }) => theme.colors.black},
      ${({ theme }) => theme.colors.black} 148%
    );
  }
`;

export const PasswordVerification = styled.div<{ isActive: any }>`
  width: 100%;
  padding-top: 15.15px;
  margin-bottom: 15.15px;
  display: flex;
  flex-direction: column;
  transform: translateY(10px);
  font-weight: 400;

  p {
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    color: #343a40;
    margin-bottom: 1rem;
  }

  li {
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    color: #343a40;
    list-style: circle;
  }

  .have-min-length {
    color: ${({ isActive }) =>
      isActive.haveMinLength === ""
        ? "#343a40"
        : isActive.haveMinLength === "wrong"
        ? "#DE2D2D"
        : isActive.haveMinLength === "right" && "#007B36"};
  }

  .have-min-upper-lower {
    color: ${({ isActive }) =>
      isActive.haveUpperOrLower === ""
        ? "#343a40"
        : isActive.haveUpperOrLower === "wrong"
        ? "#DE2D2D"
        : isActive.haveUpperOrLower === "right" && "#007B36"};
  }

  .have-symbol {
    color: ${({ isActive }) =>
      isActive.haveOneSymbol === ""
        ? "#343a40"
        : isActive.haveOneSymbol === "wrong"
        ? "#DE2D2D"
        : isActive.haveOneSymbol === "right" && "#007B36"};
  }

  @media (max-width: 1366px) {
    margin-bottom: 8px;
    padding-top: 8px;
  }
`;
