import React, { useEffect, useRef } from "react";

import {
  Button,
  ButtonApply,
  ButtonsBox,
  CheckContain,
  CheckContainNum,
  CheckFirstPage,
  Container,
  HeaderOfBox,
  MainBox,
  SettingsBox,
  SwitchContain,
  Title
} from "./styles";
import hashIcon from "../../assets/hash-icon.svg";
import exitIcon from "../../assets/exit-icon-editor.svg";
import Switch from "react-switch";
import { usePagesEditor } from "../../hooks/pagesEditor";
import api from "../../../../services/api";
import ReactLoading from "react-loading";
import Tooltip from "../Tooltip";
import { useParams } from "react-router-dom";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../shared/utils/MountIcons";
import ButtonExit from "../../../../shared/components/ButtonExit";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import PlainText from "../../../../shared/components/PlainText";

const AutomaticNumbering: React.FC = () => {
  const boxRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const {
    isActive,
    setIsActive,
    isChecked,
    setIsChecked,
    position,
    setPosition,
    showAfterFirstPage,
    setShowAfterFirstPage,
    handleApplyAutoNumbering,
    idTemplate,
    loadingAutoNumber,
    setLoadingAutoNumber
  } = usePagesEditor();

  const { typetemplate, templateid } = useParams();

  useEffect(() => {
    (async () => {
      if (typetemplate === "team-template") {
        try {
          const templateInfo = await api.get(
            `team-templates/list-specific/${templateid}`
          );

          setIsChecked(templateInfo.data.is_automatic_numbering_active);
          setPosition(
            templateInfo.data.automatic_numbering_position as "center" | "right"
          );
          setShowAfterFirstPage(
            templateInfo.data.first_page_automatic_numbering
          );
        } catch (err) {
          console.error(err, "error when get info auto numbering");
        }
      } else {
        try {
          const templateInfo = await api.get(`user-templates/${templateid}`);

          setIsChecked(templateInfo.data.is_automatic_numbering_active);
          setPosition(
            templateInfo.data.automatic_numbering_position as "center" | "right"
          );
          setShowAfterFirstPage(
            templateInfo.data.first_page_automatic_numbering
          );
        } catch (err) {
          console.error(err, "error when get info auto numbering");
        }
      }
    })();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        boxRef.current &&
        !boxRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Container>
      <Tooltip
        title="Adicionar números de páginas"
        description="Numere as páginas do documento.
        Você pode escolher a posição e mostrar na primeira página ou não."
        placement="bottom-end"
      >
        <Button ref={buttonRef} onClick={() => setIsActive(prev => !prev)}>
          <PersonalizedIcon
            dPath={MountIcons.IconHashtag.dPath}
            viewBox={MountIcons.IconHashtag.viewBox}
            inactivatedColor="#343A40"
          />
        </Button>
      </Tooltip>
      {isActive && (
        <SettingsBox ref={boxRef}>
          <HeaderOfBox>
            <p>Números de página</p>
          </HeaderOfBox>

          <MainBox>
            <CheckContainNum>
              <div>
                <input
                  onChange={() => setIsChecked(true)}
                  checked={isChecked}
                  type="radio"
                />
                <span>ativar</span>
              </div>

              <div>
                <input
                  onChange={() => setIsChecked(false)}
                  checked={!isChecked}
                  type="radio"
                />
                <span>desativar</span>
              </div>
            </CheckContainNum>

            <Title>Posição no rodapé</Title>
            <CheckContain>
              <div>
                <input
                  onChange={() => setPosition("center")}
                  checked={position === "center"}
                  type="radio"
                  value={"center"}
                />
                <span>centro</span>
              </div>
              <div>
                <input
                  onChange={() => setPosition("right")}
                  checked={position === "right"}
                  type="radio"
                />
                <span>lado direito</span>
              </div>
            </CheckContain>

            <CheckFirstPage>
              <input
                checked={showAfterFirstPage}
                onChange={() => setShowAfterFirstPage(prev => !prev)}
                type="checkbox"
              />
              <span>Mostrar na primeira página</span>
            </CheckFirstPage>

            <ButtonsBox>
              <ButtonMaxWidth
                text="Cancelar"
                typeStyle="white"
                onClick={() => setIsActive(false)}
              />
              <ButtonMaxWidth
                text="Aplicar"
                typeStyle="purple"
                onClick={() => {
                  handleApplyAutoNumbering(
                    typetemplate === "team-template",
                    templateid
                  );
                }}
                loading={loadingAutoNumber}
              />
            </ButtonsBox>
          </MainBox>
        </SettingsBox>
      )}
    </Container>
  );
};

export default AutomaticNumbering;
