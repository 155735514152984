import styled from "styled-components";

export const Container = styled.div`
  width: 91.077px;
  height: 40px;
  overflow: hidden;
  display: flex;
  user-select: none;

  img {
    transform: translateY(-1px) translateX(2px);
    user-select: none;
  }
`;
