import React, { useCallback, useEffect, useState } from "react";
import { Container, MainContainer } from "./styles";
import LogoUXDOC from "../register/assets/logo-white-uxdoc.svg";
import Ilustration from "./assets/doubt-illustration.svg";
import Backgroud from "./assets/backgroud.svg";
import { Link, useNavigate } from "react-router-dom";
import EndTrialModal from "../../../../shared/components/EndTrialModal";
import { useModalContext } from "../../../../hooks/modalContext";
import { useAuth } from "../../../../hooks/auth";
import api from "../../../../services/api";
import ReactLoading from "react-loading";
import UxDocLogoLarge from "../../../../shared/components/UxDocLogoLarge";
import TitleH3 from "../../../../shared/components/TitleH3";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";

const TrialEnd: React.FC = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { endTrialModal, setEndTrialModal } = useModalContext();
  const [loading, setLoading] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  useEffect(() => {
    document.title = `UX DOC | Seu período de teste acabou
`;
  }, []);

  const handleDeclinePayment = useCallback(async () => {
    try {
      setLoading(true);
      const responseCancellation = await api.get(
        "reason-for-cancellation/check-register",
        {
          params: {
            type: "end_trial"
          }
        }
      );

      if (!responseCancellation.data.is_register) {
        setLoading(false);
        setEndTrialModal(true);
      } else {
        setLoading(false);
        signOut();
      }
    } catch (err) {
      setLoading(false);
      signOut();
      console.error(err, "error when profile TEST2");
    }
  }, [isVisibleModal, setEndTrialModal]);

  return (
    <Container>
      <EndTrialModal
        source="end_trial"
        isActive={endTrialModal}
        setIsActive={setEndTrialModal}
      />

      <UxDocLogoLarge />

      <MainContainer>
        <TitleH3 title="O seu período de teste acabou :(" />

        <p>
          Não se preocupe!
          <br />
          Assine agora mesmo e continue criando os seus documentos.
          <br />
          Basta clicar no botão abaixo.
        </p>

        <ButtonMaxWidth
          text="Ir para a área de pagamento"
          typeStyle="purple"
          type="submit"
          onClick={() => navigate("/checkout")}
        />

        <ButtonMaxWidth
          text="Não tenho interesse agora"
          typeStyle="white"
          loading={loading}
          onClick={handleDeclinePayment}
        />
      </MainContainer>
    </Container>
  );
};

export default TrialEnd;
