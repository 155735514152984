import React, { useCallback, useEffect, useState } from "react";
import {
  LoadingContainer,
  MenuItemWrapper,
  THead,
  Table,
  TableContainer,
  TableData,
  TableHead,
  TableRow
} from "./styles";
import NavigationBar from "../NavigationBar";
import {
  Title,
  Header,
  SearchContainer,
  SearchLeftSide,
  SearchButton,
  CreateNewButton,
  FullContent,
  Container
} from "../../styles";
import FilterIcon from "../FilterIcon";
import AddIcon from "../AddIcon";
import CreateNewFlow from "../CreateNewFlow";
import ThreeDotsIcon from "../ThreeDotsIcon";
import { Avatar, Box, Menu, MenuItem } from "@material-ui/core";
import DownloadIcon from "../DownloadIcon";
import EditIcon from "../EditIcon";
import ShareIcon from "../ShareIcon";
import TrashIcon from "../TrashIcon";
import ShareFlow from "../ShareFlow";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { ApprovalFlow, Member } from "../../types/ApprovalFlow";
import Tooltip from "../../../../editor/components/Tooltip";
import { useAuth } from "../../../../../hooks/auth";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { BiInfoCircle } from "react-icons/bi";
import api from "../../../../../services/api";
import DeleteFlow from "../DeleteFlow";
import EditMembers from "../EditMembers";
import { ApprovalFlowRequest } from "../../types/ApprovalFlowRequest";
import DropdownFilter, { DropdownOption } from "../DropdownFilter";
import Pagination from "../../../components/Pagination";
import SearchInputBase from "../SearchInputBase";
import FilterOffIcon from "../FilterOffIcon";
import { ToastContainer, toast } from "react-toastify";
import { IoEyeOutline } from "react-icons/io5";
import * as XLSX from "xlsx";
import { ApprovalProject } from "../../types/ApprovalProject";
import { set } from "lodash";

const options = [
  { value: "user", label: "Membro" },
  { value: "editor", label: "Proprietário" }
];

type ExcelReportData = {
  Nome: string;
  Documento: string;
  Enviado_Por: string;
  Fluxo: string;
  Iniciado_Em: string;
  Status: string;
  Etapa_Atual: string;
  Pendente_com: string;
  Data_Planejada: string;
};

const MyFlows: React.FC = () => {
  const navigator = useNavigate();
  const params = useParams();
  const { pagination } = params;
  const pageNumber = pagination === undefined ? 0 : parseInt(pagination) - 1;

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [activeRow, setActiveRow] = useState<number>();
  const { data } = useAuth();
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [shareFlowOpen, setShareFlowOpen] = useState(false);
  const [flowName, setFlowName] = useState("");
  const [flowId, setFlowId] = useState("");
  const [flows, setFlows] = useState<ApprovalFlow[]>([]);
  const [flow, setFlow] = useState<ApprovalFlow>();
  const [members, setMembers] = useState<Member[]>([]);
  const [deleteFlowOpen, setDeleteFlowOpen] = useState(false);
  const [editMembersOpen, setEditMembersOpen] = useState(false);
  const [membersOptions, setMembersOptions] = useState<DropdownOption[]>([]);
  const [createdByOptions, setCreatedByOptions] = useState<DropdownOption[]>(
    []
  );
  const [totalPages, setTotalPages] = useState(1);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const [creatorIds, setCreatorIds] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [roleTypes, setRoleTypes] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState(false);

  const statuses = {
    PENDING: "Pendente",
    APPROVED: "Aprovado",
    REJECTED: "Rejeitado",
    CANCELED: "Cancelado"
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openShareFlow = (id: string) => {
    setShareFlowOpen(true);
    const flow = flows.find(flow => flow.id === id);
    setFlowName(flow.name);
    setFlowId(flow.id);
  };

  const editFlow = (flow_id: string) => {
    const flow = flows.find(flow => flow.id === flow_id);

    if (flow.steps.length === 0 && !flow.user_is_editor) {
      toast.error("Não é possível visualizar um fluxo sem etapas.");
      return;
    }

    if (flow.steps.length === 0) {
      navigator(`/approvalflow/${flow.id}/flow-steps`);
    } else {
      navigator("/approvalflow/flow-edit/" + flow_id);
    }
  };

  const roles = {
    owner: "Proprietário",
    user: "Membro",
    editor: "Membro"
  };

  const fetchFlows = useCallback(async () => {
    setLoading(true);
    let params = [];

    params.push(`pagination=${pageNumber}`);

    if (search) {
      params.push(`search=${search}`);
    }

    if (roleTypes && roleTypes.length > 0) {
      roleTypes.forEach(role => {
        params.push(`roles[]=${role}`);
      });
    }

    if (creatorIds && creatorIds.length > 0) {
      creatorIds.forEach(id => {
        params.push(`creator_ids[]=${id}`);
      });
    }

    if (memberIds && memberIds.length > 0) {
      memberIds.forEach(email => {
        params.push(`member_emails[]=${email}`);
      });
    }

    const query = params.join("&");
    const response = await api.get(`/approval-flows?${query}`);
    response.data.forEach((flow: ApprovalFlow) => {
      flow.user_is_editor = flow.members?.some(
        member => member.member_id === data.user?.id && member.role === "editor"
      );
    });
    setFlows(response.data);
    if (response.data.length > 0) {
      const totalItems = response.data[0].queryTotalResults;
      if (totalItems > 0) {
        const totalPages = Math.ceil(totalItems / 8);
        setTotalPages(totalPages - 1);
      }
    }

    setLoading(false);
  }, [creatorIds, data, memberIds, pageNumber, roleTypes, search]);

  useEffect(() => {
    fetchFlows();
  }, [fetchFlows]);

  useEffect(() => {
    const listMembersAndCreators = async () => {
      const response = await api.get(`approval-flows/members/creators`);
      const { data } = response;
      const creators = data.creators.map(creator => ({
        value: creator.id,
        label: `${creator.first_name} ${creator.last_name}`
      }));
      const members = data.members.map(member => ({
        value: member.id,
        label: `${member.first_name} ${member.last_name}`
      }));
      setCreatedByOptions(creators);
      setMembersOptions(members);
    };

    listMembersAndCreators();
  }, []);

  const getAvatar = (
    firstName: string,
    lastName: string,
    index: number,
    file_url: string
  ) => {
    const fullname = `${firstName} ${lastName}`;
    return (
      <Tooltip title={fullname} placement="bottom" key={fullname}>
        <Avatar
          alt={fullname}
          src={file_url}
          {...stringAvatar(fullname)}
          style={{
            width: "30px",
            height: "30px",
            backgroundColor: stringToColor(fullname),
            marginLeft: index > 0 ? "-10px" : "0"
          }}
        />
      </Tooltip>
    );
  };

  const getUserRole = (creator_id: string, members: Member[]) => {
    const user = data?.user;
    const isCreator = creator_id === user?.id;
    if (isCreator) {
      return roles["owner"];
    }
    const userMember = members.find(member => member.member_id === user?.id);
    if (userMember) {
      return roles[userMember.role];
    }
    return roles["user"];
  };

  const deleteFlow = (flow_id: string) => {
    setDeleteFlowOpen(true);
    setFlowId(flow_id);
  };

  const editFlowMembers = (flow: ApprovalFlow, sharing: boolean) => {
    setFlow(flow);
    setFlowId(flow.id);
    setMembers(flow.members);
    setShareFlowOpen(sharing);
    setEditMembersOpen(true);
  };

  const getCurrentStep = (project: ApprovalProject) => {
    if (project.status === "CANCELED") {
      return "Cancelado";
    } else if (project.status === "APPROVED") {
      return "Finalizado";
    } else if (project.current_step_status === "REFUSED") {
      return "Aguardando ajustes";
    } else {
      return project.current_step;
    }
  };

  const getProjectStatus = (project: ApprovalProject) => {
    if (project.current_step_status === "REFUSED") {
      return "Recusado";
    } else {
      return statuses[project.status];
    }
  };

  const exportExcel = async (flow_id: string, flow_name: string) => {
    try {
      const response = await api.get(
        `approval-projects/approval-flow/${flow_id}/report?approval_flow_name=${flow_name}`
      );
      const data: ExcelReportData[] = [];
      response.data.forEach((item: ApprovalProject) => {
        const Nome = item.name;
        const Documento = item.document_file_name;
        const Enviado_Por = item.creator_name;
        const Fluxo = item.flow_name;
        const Iniciado_Em = new Date(item.started_at).toLocaleDateString();
        const Status = getProjectStatus(item);
        const Etapa_Atual = getCurrentStep(item);
        const Data_Planejada = new Date(
          item.planned_approval_date
        ).toLocaleDateString();
        const Pendente_com = item.pending_approvers
          .map(approve => approve.name)
          .join(", ");

        data.push({
          Nome,
          Documento,
          Enviado_Por,
          Fluxo,
          Iniciado_Em,
          Status,
          Etapa_Atual,
          Pendente_com,
          Data_Planejada
        });
      });

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "report.xlsx");
      toast.success("Relatório exportado com sucesso");
    } catch (error) {
      toast.error("Erro ao exportar relatório");
    }
  };

  return (
    <>
      <FullContent>
        <ToastContainer />
        <Container>
          <Header>
            <Title>Fluxo de Aprovação</Title>
            <Tooltip
              description={"Este é o local onde você pode criar os seus fluxos"}
              placement="bottom-start"
            >
              <figure>
                <BiInfoCircle />
              </figure>
            </Tooltip>
          </Header>
          <NavigationBar activeTab="Meus Fluxos" />
          <SearchContainer>
            <SearchLeftSide>
              <SearchInputBase
                placeholder="Pesquisar"
                value={search}
                onChange={e => setSearch(e.target.value)}
                setSearch={setSearch}
              />
              <SearchButton
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                {!showFilters && <FilterIcon color="#7547A3" />}

                {showFilters && <FilterOffIcon />}
              </SearchButton>
              {showFilters && (
                <Stack direction={"row"} spacing={1}>
                  <DropdownFilter
                    options={createdByOptions}
                    onChange={options => setCreatorIds(options)}
                    label={"Criado por"}
                    showSearch
                  />
                  <DropdownFilter
                    options={membersOptions}
                    onChange={options => setMemberIds(options)}
                    label={"Membros"}
                    showSearch
                  />
                  <DropdownFilter
                    options={options}
                    onChange={options => setRoleTypes(options)}
                    label={"Tipo de usuário"}
                  />
                </Stack>
              )}
            </SearchLeftSide>

            <CreateNewButton
              onClick={() => {
                setCreateDialogOpen(true);
              }}
            >
              <AddIcon color={"#FFFF"} /> Criar novo fluxo
            </CreateNewButton>
          </SearchContainer>

          <TableContainer>
            <Table>
              <THead>
                <tr>
                  <TableHead style={{ width: "50%" }}>Nome do fluxo</TableHead>
                  <TableHead>Criado por</TableHead>
                  <TableHead>Membros</TableHead>
                  <TableHead>Tipo de usuário</TableHead>
                  <TableHead></TableHead>
                </tr>
              </THead>

              {!loading && (
                <tbody>
                  {flows.map((flow, index) => (
                    <TableRow
                      key={flow.id}
                      onMouseEnter={() => setActiveRow(index)}
                      onMouseLeave={() => {
                        setActiveRow(undefined);
                        handleClose();
                      }}
                    >
                      <TableData>{flow.name}</TableData>
                      <TableData>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                        >
                          {getAvatar(
                            flow.creator_first_name,
                            flow.creator_last_name,
                            0,
                            flow.creator_avatar_url
                          )}
                        </Stack>
                      </TableData>

                      <TableData>
                        {" "}
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                        >
                          {flow.members
                            .filter(m => m.member_id !== data?.user?.id)
                            .map((member, index) =>
                              getAvatar(
                                member.first_name,
                                member.last_name,
                                index,
                                member.avatar_url
                              )
                            )}
                        </Stack>
                      </TableData>

                      <TableData>
                        {getUserRole(flow.creator_id, flow.members)}
                      </TableData>
                      <TableData style={{ padding: 0, cursor: "pointer" }}>
                        {activeRow === index && (
                          <>
                            <Box
                              id={`action-button_${index}`}
                              aria-controls={
                                open ? `action-menu_${index}` : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                            >
                              <ThreeDotsIcon />
                            </Box>

                            <Menu
                              id={`action-menu_${index}`}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": `action-button_${index}`
                              }}
                            >
                              {flow.user_is_editor && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      exportExcel(flow.id, flow.name);
                                    }}
                                  >
                                    <MenuItemWrapper>
                                      <DownloadIcon color="#676B70" /> Baixar
                                      relatório em Excel
                                    </MenuItemWrapper>
                                  </MenuItem>
                                  <MenuItem onClick={() => editFlow(flow.id)}>
                                    <MenuItemWrapper>
                                      <EditIcon
                                        color="#676B70"
                                        width="24px"
                                        height="24px"
                                      />{" "}
                                      Editar fluxo
                                    </MenuItemWrapper>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => editFlowMembers(flow, true)}
                                  >
                                    <MenuItemWrapper>
                                      <ShareIcon color="#676B70" /> Compartilhar
                                    </MenuItemWrapper>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => editFlowMembers(flow, false)}
                                    disabled={flow.members.length === 1}
                                  >
                                    <MenuItemWrapper>
                                      <EditIcon
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                      />{" "}
                                      Editar membros
                                    </MenuItemWrapper>
                                  </MenuItem>
                                  <MenuItem onClick={() => deleteFlow(flow.id)}>
                                    <MenuItemWrapper>
                                      <TrashIcon color="#676B70" />
                                      Excluir
                                    </MenuItemWrapper>
                                  </MenuItem>
                                </>
                              )}
                              {!flow.user_is_editor && (
                                <MenuItem onClick={() => editFlow(flow.id)}>
                                  <MenuItemWrapper>
                                    <IoEyeOutline
                                      color="#676B70"
                                      fontSize={"24px"}
                                    />
                                    Visualizar
                                  </MenuItemWrapper>
                                </MenuItem>
                              )}
                            </Menu>
                          </>
                        )}
                      </TableData>
                    </TableRow>
                  ))}
                </tbody>
              )}
            </Table>
            {!loading && flows.length === 0 && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                fontSize={"13px"}
                color={"#676B70"}
                p={2}
                alignItems={"center"}
                height={"80%"}
              >
                Nenhum fluxo encontrado.
              </Box>
            )}
            {loading && (
              <LoadingContainer>
                <ContractAnimation />
              </LoadingContainer>
            )}
            <Box
              bgcolor={"white"}
              position={"fixed"}
              bottom={"10px"}
              right={"10px"}
              width={"calc(100% - 260px)"}
              p={2}
            >
              <Pagination
                total={totalPages}
                pageNow={pageNumber}
                screen="approvalflow/my-flows"
              />
            </Box>
          </TableContainer>

          <CreateNewFlow
            open={createDialogOpen}
            onClose={() => {
              setCreateDialogOpen(false);
            }}
          />

          {deleteFlowOpen && (
            <DeleteFlow
              open={deleteFlowOpen}
              flow_id={flowId}
              onClose={refresh => {
                setDeleteFlowOpen(false);
                if (refresh) fetchFlows();
              }}
            />
          )}
          {editMembersOpen && (
            <EditMembers
              isEditing={!shareFlowOpen}
              open={editMembersOpen}
              onClose={refresh => {
                if (refresh) fetchFlows();
                setEditMembersOpen(false);
                setShareFlowOpen(false);
              }}
              flowId={flowId}
              currentMembers={members}
              flow={flow}
              loggedUserId={data?.user?.id}
            />
          )}
        </Container>
      </FullContent>
    </>
  );
};

export default MyFlows;

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
  };
}
