import { HTMLProps } from "react";
import styled from "styled-components";

export const ListItemText = styled.div`
  width: 100%;
  border: 1px solid #d6d8d9;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  gap: 10px;

  user-select: none;
  cursor: grab;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    width: 30px;
    height: 30px;
    border-radius: 5px;

    :hover {
      background-color: #ebebec;
    }
  }
`;
