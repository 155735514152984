import { HTMLInputTypeAttribute } from "react";
import { Container } from "./styles";
import { UseFormRegister } from "react-hook-form";
import IconAlert from "../../assets/icon-alert.svg";

interface SubmitProps {
  email?: string;
  password?: string;
  first_name?: string;
  last_name?: string;
  where_do_you_work?: string;
  how_did_you_meet_us?: string;
  phone?: string;
  register?: string;
  emailConfirm?: string;
  confirmPassword?: string;
  oldPassword?: string;
  other?: string;
}

interface IInputProps {
  label?: string;
  type: HTMLInputTypeAttribute;
  register: UseFormRegister<SubmitProps>;
  name:
    | "password"
    | "email"
    | "first_name"
    | "emailConfirm"
    | "last_name"
    | "where_do_you_work"
    | "how_did_you_meet_us"
    | "phone"
    | "oldPassword"
    | "confirmPassword"
    | "other";
  error: boolean;
}

const InputForm = ({ label, type, register, name, error }: IInputProps) => {
  return (
    <Container error={error}>
      {!!label && <label>{label}</label>}
      <div>
        <input type={type} {...register(name)} />
        {error && <img src={IconAlert} alt="ícone de alerta" />}

        {/*<img src={IconAlert} alt="ícone de alerta" />
         <img src={IconCheck} alt="ícone de verificado" /> */}
      </div>
    </Container>
  );
};

export default InputForm;
