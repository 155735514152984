import React, { useCallback, useState } from "react";

import {
  BodyText,
  Button,
  Container,
  DescriptionText,
  HStack,
  HeadText,
  VStack,
} from "./styles";
import { useTwoFactorAuth } from "../../../../../../../hooks/twoFactorAuth";

import ReactLoading from "react-loading";
import { useTheme } from "../../../../../../../hooks/theme";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../../../hooks/auth";

type Props = {
  isActive?: boolean;
  isUserHasOTP: boolean;
  setIsUserHasOTP: React.Dispatch<React.SetStateAction<boolean>>;
};

const SecurityPage: React.FC<Props> = ({
  isUserHasOTP,
  setIsUserHasOTP,
  isActive = false,
}) => {
  const { theme } = useTheme();
  const { data } = useAuth();
  const { setModalTwoFactor, setIsDisable2FA, isUserValidateOTP } =
    useTwoFactorAuth();

  // const { setData } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleSetup2Fa = useCallback(() => {
    setModalTwoFactor("setup");
  }, [setModalTwoFactor]);

  const handleDisable2fa = useCallback(() => {
    if (!isUserValidateOTP && data?.user?.otp_enabled) {
      toast.error("Autenticação não realizada!");
    } else {
      setIsDisable2FA(true);
    }
  }, [data?.user?.otp_enabled, isUserValidateOTP]);

  return (
    <Container>
      <HStack>
        <VStack>
          <HeadText>Segurança</HeadText>
          <DescriptionText>Autenticação de dois fatores</DescriptionText>
          <BodyText>
            {isUserHasOTP
              ? "Autenticação ativa."
              : `Aumente a segurança da sua conta usando autenticação de dois
            fatores.`}
          </BodyText>
        </VStack>

        {isUserHasOTP ? (
          <Button onClick={handleDisable2fa} type="button">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ReactLoading
                  type="spin"
                  color={theme.colors.primary}
                  height={16}
                  width={16}
                />
              </div>
            ) : (
              `Desabilitar`
            )}
          </Button>
        ) : (
          <Button onClick={handleSetup2Fa} type="button">
            Habilitar
          </Button>
        )}
      </HStack>
    </Container>
  );
};

export default SecurityPage;

