import styled from "styled-components";

export const ContainerItem = styled.div`
  display: flex;
  align-items: start;
  gap: 5px;

  button {
    transform: translateY(-3px);
  }
`;
