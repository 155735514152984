import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { format, utcToZonedTime } from "date-fns-tz";
import { ArrowClockwise } from "phosphor-react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import {
  Container,
  Header,
  SubsDescription,
  InfoBox,
  SubStatus,
  StatusDoc,
  Vectors,
  StatusSignature,
  Footer,
  ButtonCancel,
} from "./styles";

import api from "../../../../../services/api";
import { useSign } from "../../../../../hooks/sign";
import { useAuth } from "../../../../../hooks/auth";

import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import PlainText from "../../../../../shared/components/PlainText";
import { SignDataProps } from "../../../../../dtos/signedProps";
import MountIcons from "../../../../../shared/utils/MountIcons";
import TitleH5 from "../../../../../shared/components/TitleH5";

const SignatureSpecificPage = () => {
  const { refreshPage, setRefreshPage } = useSign();
  const { data } = useAuth();
  const { document_id } = useParams();

  const [dataSignSpecific, setDataSignSpecific] = useState<SignDataProps[]>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const getSpecificTemplate: AxiosResponse<SignDataProps[]> =
          await api.get(`signatures/${document_id}`);
        setDataSignSpecific(getSpecificTemplate.data);
        setLoading(false);
      } catch (err) {
        console.error(err, "error when get specific template.");
      }
    })();
  }, [document_id, refreshPage]);

  const handleResendMailToUser = useCallback(
    async (id: string, userId: string) => {
      try {
        const object = {
          signature_id: id,
          signature_user_id: userId,
        };

        const resendMail = await api.post(`signatures/resend`, object);
        toast.success("E-mail reenviado com sucesso!");
      } catch (err) {
        console.error(err, "error when resend mail");
        toast.error("Ocorreu um erro ao enviar o email.");
      }
    },
    []
  );

  const formatDateFinal = useCallback((date: string) => {
    let myDataFinalFormated: string;
    let myObject = date;
    const nyTimeZone = "UTC";
    const myDate = utcToZonedTime(myObject, nyTimeZone);
    const niceDate = format(myDate, "dd/MM/yyyy", {
      timeZone: "UTC",
    });

    myDataFinalFormated = `Até dia ${niceDate}`;
    return myDataFinalFormated;
  }, []);

  const handleDeleteSignature = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const deleteSignature = await api.delete(`signatures/cancel/${id}`);
        console.error(deleteSignature.status, deleteSignature.data, "foi?");
        try {
          const getSpecificTemplate: AxiosResponse<SignDataProps[]> =
            await api.get(`signatures/${document_id}`);
          setDataSignSpecific(getSpecificTemplate.data);
          toast.success("Assinatura cancelada com sucesso.");
          setLoading(false);
        } catch (err) {
          console.error(err, "error when get specific template.");
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        toast.error("Erro, tente novamente mais tarde.");
        console.error(err, "error when occurs response for delete signature");
      }
    },
    [document_id, setDataSignSpecific]
  );

  const handleRefresh = useCallback(async () => {
    setRefreshPage(!refreshPage);
  }, [refreshPage, setRefreshPage]);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (loading) {
    return (
      <Container>
        <div
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ContractAnimation />
        </div>
      </Container>
    );
  }

  return (
    <>
      <ToastContainer />
      <Container>
        <Header>
          <button onClick={handleGoBack}>
            <PersonalizedIcon
              dPath={MountIcons.IconArrowLeft.dPath}
              viewBox={MountIcons.IconArrowLeft.viewBox}
            />
            Assinatura
          </button>

          <button onClick={handleRefresh} className="refresh">
            <ArrowClockwise size={22} color="#999C9F" weight="bold" />
          </button>
        </Header>
        <SubsDescription>
          <img src={dataSignSpecific["signature"].thumbnail_url} alt="" />
          <div>
            <div>
              <TitleH5 title="Nome do documento:" />
              <PlainText> {dataSignSpecific["signature"]?.title} </PlainText>
            </div>
            <div>
              <TitleH5 title="Documento enviado por:" />
              <PlainText> {data.user.email} </PlainText>
            </div>
            <InfoBox>
              <TitleH5 title="Responsável(s) pela assinatura:" />

              {dataSignSpecific["signature_users"].map((user) => (
                <PlainText key={user.id}>
                  {`${user.first_name} ${user.last_name}`}
                  <SubStatus isSigned={user.is_signed}>
                    {user.is_signed ? `assinado` : "pendente"}

                    {!user?.is_signed &&
                      dataSignSpecific["signature"].status ===
                        "Aguardando assinatura" && (
                        <button
                          className="resend-link"
                          onClick={() =>
                            handleResendMailToUser(document_id, user.id)
                          }
                        >
                          Reenviar e-mail para assinatura
                        </button>
                      )}
                  </SubStatus>
                </PlainText>
              ))}
            </InfoBox>
          </div>
        </SubsDescription>
        <StatusDoc>
          {dataSignSpecific["signature"].status === "Aguardando assinatura" ? (
            <div>
              <div className="status_circle-1" />{" "}
              <span>Assinatura pendente</span>
            </div>
          ) : dataSignSpecific["signature"].status === "Concluído" ? (
            <div>
              <div className="status_circle-success" />{" "}
              <span>Assinatura finalizada</span>
            </div>
          ) : dataSignSpecific["signature"].status === "Assinatura expirada" ? (
            <div>
              <div className="status_circle-expired" />{" "}
              <span>Assinatura expirada</span>
            </div>
          ) : (
            dataSignSpecific["signature"].status === "Assinatura cancelada" && (
              <div>
                <div className="status_circle-canceled" />{" "}
                <span>Assinatura cancelada</span>
              </div>
            )
          )}
          <div>
            {/* <StatusSignature>
                <Vectors isActive={true}>
                  <div />
                  <span />
                </Vectors>
                Documento enviado em:{" "}
                {`${formatDate(dataSignSpecific["signature"].created_at)}`}
                <br /> por:{" "}
                {`${data.people.first_name} ${data.people.last_name}`}
              </StatusSignature> */}

            {dataSignSpecific["signature_log"].map((log) => (
              <StatusSignature key={log.id}>
                <Vectors isActive={true}>
                  <div />
                  <span />
                </Vectors>
                {log.event}
              </StatusSignature>
            ))}

            {dataSignSpecific["signature"].status !== "Assinatura cancelada" &&
              dataSignSpecific["signature_users"].map(
                (user) =>
                  !user.is_signed && (
                    <StatusSignature key={user.id}>
                      <Vectors isActive={false}>
                        <div />
                        <span />
                      </Vectors>
                      Documento assinador por:
                      <br /> em:
                    </StatusSignature>
                  )
              )}

            <StatusSignature status={dataSignSpecific["signature"].status}>
              <Vectors status={dataSignSpecific["signature"].status}>
                <div className="status_last-Vector" />
              </Vectors>
              <span>
                Prazo para assinaturas:{" "}
                {`${formatDateFinal(dataSignSpecific["signature"].final_date)}`}
              </span>
            </StatusSignature>
          </div>
        </StatusDoc>
        <Footer>
          <div>
            <button onClick={handleGoBack}>Voltar</button>
          </div>
          {dataSignSpecific["signature"].status === "Aguardando assinatura" && (
            <ButtonCancel
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleDeleteSignature(dataSignSpecific["signature"].id)
              }
            >
              Cancelar solicitação de assinatura
            </ButtonCancel>
          )}
        </Footer>
      </Container>
    </>
  );
};

export default SignatureSpecificPage;

