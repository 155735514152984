import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow-y: auto;
`;

export const SearchBox = styled.div`
  width: 100%;
  height: 30px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #999c9f;
  padding-inline: 10px;

  display: flex;
  align-items: center;
  gap: 8px;

  input {
    border: none;
    width: 100%;
    height: 28px;
  }

  img {
    width: 23px;
    height: 23px;
  }

  button {
    width: 40px;
    height: 90%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    :hover {
      background-color: #f5f5f5;
      border-radius: 5px;
    }
  }
`;

export const VectorsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 7px;

  background-color: #fff;
  border-radius: 5px;
`;

export const GridImage = styled.div<{ vector: string }>`
  height: 70px;
  width: 70px;

  background-image: ${({ vector }) => vector && `url(${vector})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const BoxImage = styled.div`
  width: 80px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #ebebec;
    border-radius: 5px;
  }
`;
