import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

export const ContainerModal = styled.div`
  min-width: 900px;
  height: 359px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  -webkit-box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.11);
  position: relative;
  border-radius: 10px;
  padding: 0 1.6rem;
  justify-content: space-around;
  padding-bottom: 1.6rem;
`;

export const FormBox = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 3rem;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  min-height: 73px;
  border-radius: 20px;
  align-items: center;
  border: solid 1px red;
  padding: 0 1.6rem;
  line-height: 190%;
  justify-content: center;
`;

export const Text = styled.span`
  color: red;
  font-size: 1.6rem;
  text-align: center;
  max-width: 700px;
`;

export const Wrapper = styled.div`
  display: flex;
  background: red;
  width: 100%;
  justify-content: space-between;
`;

export const TextArea = styled.textarea`
  min-width: 100%;
  min-height: 227px;
  border-radius: 15px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  font-size: 1.8rem;
  color: ${({ theme }: Theme) => theme.colors.gray900};
  line-height: 2.7rem;

  &::placeholder {
    font-size: 1.8rem;
    color: ${({ theme }: Theme) => theme.colors.gray900};
    line-height: 2.7rem;
  }
`;
