import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 15px;

  display: flex;
  gap: 10px;

  font-size: 14px;
  font-weight: 400;
  line-height: 150%;

  img {
    width: 24px;
    height: 24px;
  }
`;
