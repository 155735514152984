import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";

import { Container, ContentLoading } from "./styles";

import { useSign } from "../../../../../hooks/sign";
import api from "../../../../../services/api";

import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { ResponseSign } from "../../../../../dtos/signedProps";
import Pagination from "../../../components/Pagination";
import SignatureTable from "../SignatureTable";

const SignatureDocs = () => {
  const {
    setDataSign,
    loadingDocs,
    setLoadingDocs,
    setTotalPagesDocs,
    setAmountSignDocs,
    setOpenModal,
    totalPagesDocs,
    dataSign,
    refreshPage,
    arrayFilters,
  } = useSign();

  //route properties
  const { number_page } = useParams();
  const navigator = useNavigate();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;

  const filter = arrayFilters.reduce((acc, curr) => {
    if (
      (curr.id === "created_at") &&
      !acc.includes("created_at")
    ) {
      acc.push("created_at");
    } else if (
      (curr.id === "final_date") &&
      !acc.includes("final_date")
    ) {
      acc.push("final_date");
    } else if (curr.id === "status" && !acc.includes("status")) {
      acc.push("status");
    }
    return acc;
  }, []);

  const paramsFilter = {
    filter,
    status: arrayFilters.find(item => item.id === 'status')?.value,
    created_at_start: arrayFilters.find(item => item.id === 'created_at_start')?.value,
    created_at_end: arrayFilters.find(item => item.id === 'created_at_end')?.value,
    final_date_start: arrayFilters.find(item => item.id === 'final_date_start')?.value,
    final_date_end: arrayFilters.find(item => item.id === 'final_date_end')?.value,
  };

  useEffect(() => {
    (async () => {
      try {
        setLoadingDocs(true);
        const responseSignatures: AxiosResponse<ResponseSign> = await api.get(
          "signatures",
          {
            params: {
              pagination: pageNumber,
              ...(arrayFilters.length > 0 ? paramsFilter : {}),
            }
          }
        );

        setDataSign(responseSignatures.data.dataArray);
        setTotalPagesDocs(responseSignatures.data.pagination);
        setAmountSignDocs(oldState => {
          return {
            ...oldState,
            spam: responseSignatures.data.countSignatureTrash,
            docs: responseSignatures.data.countSignature
          };
        });

        if (pageNumber > 0 && responseSignatures.data.dataArray.length === 0) {
          navigator(`/signatures/documents/${pageNumber}`);
        }
        setLoadingDocs(false);
      } catch (err) {
        console.error(err.response.data, "error when get list of all signs");
      }
    })();
  }, [pageNumber, refreshPage, arrayFilters]);

  if (loadingDocs) {
    return (
      <Container>
        <ContentLoading>
          <ContractAnimation />
        </ContentLoading>
      </Container>
    );
  }

  return (
    <Container>
      <SignatureTable data={dataSign} setOpenModal={setOpenModal} />

      <Pagination
        total={totalPagesDocs}
        pageNow={pageNumber}
        screen="signatures/documents"
      />
    </Container>
  );
};

export default SignatureDocs;
