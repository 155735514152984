import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";

import {
  BoxChip,
  BoxInput,
  BoxWarning,
  ButtonSave,
  InputText,
  ModalContainer,
  ModalShowClausule,
  Title
} from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import api from "../../../../services/api";
import { useModalContext } from "../../../../hooks/modalContext";

import { useClausesEditor } from "../../hooks/clausesEditor";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { toast } from "react-toastify";

import { useSelection } from "../../hooks/selection";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonExit from "../../../../shared/components/ButtonExit";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../shared/utils/MountIcons";

interface NewClausesModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

interface ObjectClauseForApiProps {
  title: string;
  clause: string;
  description: string;
  is_favorite: boolean;
  key_words?: string;
}

export const NewClausesModal: React.FC<NewClausesModalProps> = ({
  isOpen,
  onRequestClose
}) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 100,
        staggerDirection: -1
      }
    }
  };

  //style for input chip mui
  const useStyles = makeStyles({
    customTextField: {
      color: "red",
      "& input::placeholder": {
        fontSize: "15px",
        color: "#000 !important"
      },

      "& input::focus": {
        borderColor: "red"
      }
    },

    chip: {
      background: "white",
      border: "1px solid #343A40",
      fontSize: "12px",
      fontWeight: "bold",
      fontFamily: "Open Sans"
    },
    inputRoot: { border: "none", position: "relative" }
  });

  const classes = useStyles();
  const inputRef = useRef<any>();
  const { refreshClause, setRefreshClause } = useModalContext();
  const { clausesData, setClausesData, addedClause, setAddedClause } =
    useClausesEditor();
  const [dataChip, setDataChip] = useState<string[]>([]);
  const [isSuggestion, setIsSuggestion] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [suggestionData, setSuggestionData] = useState<string[]>([]);
  const [insideSuggestion, setInsideSuggestion] = useState(false);

  const { blockCommandKeyboard, forceBlockKey, setForceBlockKey } =
    useSelection();

  const [loading, setLoading] = useState(false);

  const [newClause, setNewClause] = useState<ObjectClauseForApiProps>({
    title: "",
    clause: "",
    description: "Created by user",
    is_favorite: false
  });

  useEffect(() => {
    if (isOpen) {
      setForceBlockKey(true);
    } else {
      setForceBlockKey(false);
      setDataChip([]);
      setIsSuggestion(false);
      setNewClause(state => {
        return {
          ...state,
          title: "",
          clause: ""
        };
      });
    }
  }, [isOpen]);

  const onChangeTitle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewClause((state: ObjectClauseForApiProps) => {
        return {
          ...state,
          title: event.target.value
        };
      });
    },
    []
  );

  const handleAddChip = useCallback(
    chip => {
      setDataChip(prevState => {
        let cloneState = [...prevState, chip];
        return cloneState;
      });
    },
    [dataChip]
  );

  const handleDeleteChip = useCallback(
    (chip, indexChip) => {
      setDataChip(prevState => {
        let cloneState = [...prevState];
        let arrayFiltered = cloneState.filter(
          (_, index) => index !== indexChip
        );
        return arrayFiltered;
      });
    },
    [dataChip]
  );

  const onChangeClause = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNewClause((state: ObjectClauseForApiProps) => {
        return {
          ...state,
          clause: event.target.value
        };
      });
    },
    []
  );

  const handleSendClause = useCallback(
    async event => {
      let key = event.charCode || event.keyCode || 0;
      if (key == 13) {
        alert("No Enter!");
        event.preventDefault();
      }
      event.preventDefault();
      try {
        setLoading(prevState => !prevState);
        let newClauseForApi = { ...newClause, key_words: dataChip.toString() };
        let newClauseForApiWithoutKeyWord = { ...newClause };
        let newClauseComplete;

        if (dataChip.length > 0) {
          const requestSendClause = await api.post(
            "user-clause",
            newClauseForApi
          );
          newClauseComplete = requestSendClause.data;
        } else {
          const requestSendClause = await api.post(
            "user-clause",
            newClauseForApiWithoutKeyWord
          );
          newClauseComplete = requestSendClause.data;
        }

        setRefreshClause(!refreshClause);
        setNewClause(state => {
          return {
            ...state,
            title: "",
            clause: "",
            description: "Created by user",
            is_favorite: false,
            key_words: dataChip.toString()
          };
        });
        setClausesData(prevState => {
          return [...prevState, newClauseComplete];
        });

        setLoading(prevState => !prevState);
        toast.success("Clausula cadastrada com sucesso!");
        setDataChip([]);
        setAddedClause(prevState => !prevState);
        setIsSuggestion(false);
        onRequestClose();
      } catch (err) {
        console.error(err, "error when send clause object");
        setLoading(prevState => !prevState);
        toast.error("Algo inesperado aconteceu.");
      }
    },
    [newClause, refreshClause, clausesData, dataChip, addedClause]
  );

  const handleCloseSuggestion = () => {
    setIsSuggestion(false);
  };

  const handleOnBlurChip = useCallback(() => {
    if (!insideSuggestion) {
      setIsSuggestion(false);
    }
  }, [insideSuggestion]);

  //fetch words for suggestions box
  //and initial loading

  useEffect(() => {
    const autoSuggestionsLoad = async () => {
      try {
        const responseSuggestions = await api.get(
          "user-clause/list-array-key-words"
        );

        setSuggestionData(responseSuggestions.data.key_words);
        setInitialLoading(false);
      } catch (err) {
        console.error(err, "error response");
        setInitialLoading(false);
      }
    };

    autoSuggestionsLoad();
  }, []);

  const handleFocusChip = useCallback(
    async chip => {
      if (suggestionData.length > 0) {
        setIsSuggestion(state => !state);
      } else {
        setIsSuggestion(state => !state);
      }
    },
    [isSuggestion, suggestionData]
  );

  return (
    <Modal
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <AnimatePresence>
        <ModalShowClausule
          as={motion.form}
          initial="hidden"
          animate="show"
          variants={container}
          exit={{
            opacity: 0,
            scale: 0.8,
            x: -300
          }}
          transition={{ duration: 1 }}
        >
          <ModalContainer>
            <Title>
              <TitleH5 title="Cadastrar novo texto" />

              <ButtonExit onClick={() => onRequestClose()} />
            </Title>

            <BoxInput>
              <div>
                <label>Título</label>
                <input
                  placeholder="Título"
                  name="title"
                  value={newClause.title}
                  onChange={onChangeTitle}
                />
              </div>
              <BoxChip>
                <label>Palavra-chave</label>
                <input
                  type="text"
                  value={newClause?.key_words}
                  onChange={e =>
                    setNewClause(state => {
                      return {
                        ...state,
                        key_words: e.target.value
                      };
                    })
                  }
                />
              </BoxChip>
              <div>
                <label>Texto</label>
                <InputText value={newClause.clause} onChange={onChangeClause} />
              </div>
            </BoxInput>

            <BoxWarning>
              <PersonalizedIcon
                dPath={MountIcons.IconAlert.dPath}
                viewBox={MountIcons.IconAlert.viewBox}
                inactivatedColor="#de2d2d"
              />
              <p>
                Tudo que você escrever aqui ficará gravado no banco de
                cláusulas. Assim, evite usar informações confidenciais de
                clientes neste texto, dê preferência a incluir estas informações
                confidenciais apenas no documento que está criando.
              </p>
            </BoxWarning>

            <ButtonSave onClick={handleSendClause}>Salvar</ButtonSave>
          </ModalContainer>
        </ModalShowClausule>
      </AnimatePresence>
    </Modal>
  );
};
