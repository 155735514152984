import React from "react";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";

import { usePagesEditor } from "../../../../hooks/pagesEditor";

import ButtonIconsWithTooltip from "../../../ButtonsAsideBar/components/ButtonIconsAsidebar";
import { useTheme } from "../../../../../../hooks/theme";

interface AddPageEditorProps {
  item?: any;
  index: number;
}

export default function AddPageEditor({ item, index }: AddPageEditorProps) {
  const { isActiveNewPage, handleAddNewPage, handleHoverIcon } =
    usePagesEditor();
  const { theme } = useTheme();

  return (
    <ButtonIconsWithTooltip
      onMouseEnter={() => handleHoverIcon(index, true)}
      onMouseLeave={() => handleHoverIcon(index, false)}
      onClick={() => handleAddNewPage(item.pageNumber)}
      style={{ position: "relative" }}
      title="Adicionar página"
      placement="bottom-start"
      className="btn-page"
    >
      <PersonalizedIcon
        isActive={isActiveNewPage.visible && isActiveNewPage.index === index}
        viewBox="0 0 25 24"
        activeColor={theme.colors.primary}
        inactivatedColor="#999C9F"
        className="arrow-add-page-icon"
        dPath="M13.4914 11.2H15.8914V12.8H13.4914V15.2H11.8914V12.8H9.49141V11.2H11.8914V8.8H13.4914V11.2ZM20.6914 6.8V15.2L15.8914 20H7.49141C5.97141 20 4.69141 18.72 4.69141 17.2V6.8C4.69141 5.28 5.97141 4 7.49141 4H17.8914C19.4114 4 20.6914 5.28 20.6914 6.8ZM19.0914 7.04C19.0914 6.24 18.4514 5.6 17.6514 5.6H7.73141C6.93141 5.6 6.29141 6.24 6.29141 7.04V17.04C6.29141 17.84 6.93141 18.48 7.73141 18.48H15.0914V17.28C15.0914 15.76 16.3714 14.48 17.8914 14.48H19.0914V7.04Z"
      />
    </ButtonIconsWithTooltip>
  );
}
