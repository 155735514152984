import styled from "styled-components";

interface IputColor {
  error: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  label {
    font-family: "Ubuntu";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }

  div {
    position: relative;
  }

  img {
    width: 20px;
    height: 20px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
`;

export const StyledInput = styled.input<IputColor>`
  width: 100%;
  max-height: 40px;
  padding: 11.5px 10px;
  border-radius: 5px;
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.error100 : theme.colors.black50};
  background-color: ${({ error, theme }) => error && theme.colors.error10};


  :focus {
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.colors.error100 : theme.colors.focus};
  }
`;
