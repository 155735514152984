import React from "react";
import AddPageEditor from "../AddPageEditor";
import DuplicatePageEditor from "../DuplicatePageEditor";
import IconMoreOptions from "../IconMoreOptions";
import MoveDownPage from "../MoveDownPage";
import MoveUpPage from "../MoveUpPage";
import RemovePageEditor from "../RemovePageEditor";
import exitIcon from "../../../../assets/exit-icon.svg";
import DisableRemovePage from "../DisableRemovePage";

// import { Container } from './styles';

interface ButtonsPagesControlProps {
  item: any;
  index: number;
  onClick?: (any: any, anywhere: any) => void;
  pageSize?: number;
}

const ButtonsPagesControl: React.FC<ButtonsPagesControlProps> = ({
  item,
  index,
  onClick,
  pageSize
}) => {
  return (
    <>
      <MoveUpPage index={index} item={item} />
      <MoveDownPage index={index} item={item} />
      <AddPageEditor index={index} item={item} />
      <DuplicatePageEditor index={index} item={item} />
      {pageSize === 1 ? (
        <DisableRemovePage index={index} item={item} />
      ) : (
        <RemovePageEditor index={index} item={item} />
      )}
    </>
  );
};

export default ButtonsPagesControl;
