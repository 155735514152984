import React from "react";
import SuspendedView from "../../components/SuspendedView";

// import { Container } from './styles';

const SuspendedPage: React.FC = () => {
  return <SuspendedView />;
};

export default SuspendedPage;
