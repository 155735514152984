import React from "react";

interface FilterProps {
  className?: string;
  isActive?: boolean;
}

export default function FavotiteIcon({ className, isActive }: FilterProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M13.2512 7.13034L18.4338 7.57004L14.4964 10.981C14.1106 11.3152 13.9417 11.8356 14.0577 12.3327L15.24 17.4008L10.7847 14.7118C10.3478 14.4481 9.80067 14.4481 9.36371 14.7118L4.90511 17.4028L6.08111 12.3309C6.19615 11.8347 6.02753 11.3157 5.6429 10.9818L1.712 7.57026L6.89725 7.13034C7.40543 7.08723 7.84799 6.76639 8.047 6.29683L10.0742 1.51376L12.1014 6.29683C12.3004 6.76639 12.743 7.08723 13.2512 7.13034ZM10.4195 0.699118C10.4194 0.699317 10.4193 0.699516 10.4192 0.699714L10.4195 0.699119L10.0742 0.552784L10.4195 0.699118Z"
        stroke={isActive ? "#663399" : "#999C9F"}
        fill={isActive ? "#663399" : "none"}
        stroke-width="1.75"
        style={{ transform: "translateX(2px) translateY(3px)" }}
      />
    </svg>
  );
}
