import { Container, EditName, SubModal, Item, ItemSubModal } from "./styles";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../shared/utils/MountIcons";
import DowloadICon from "../../../../assets/dowload-icon.svg";
import PdfICon from "../../../../assets/pdf-icon.svg";
import WordICon from "../../../../assets/word-icon.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTeams } from "../../../../../../hooks/teams";
import api from "../../../../../../services/api";
import { PagesProps } from "../../../../../../dtos/PagesProps";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../../hooks/auth";
import {
  AllMyProjects,
  IHandleChangeDocumentNameProps
} from "../../../../../../dtos/AllMyProjects";
import { UseMutateFunction } from "react-query";
import { useMetric } from "../../../../../../hooks/metric";
import { useHeaderEditor } from "../../../../../editor/hooks/headerEditor";

interface IOptionsTemplateOneClickProps {
  id: string;
  handleMakeCopy: UseMutateFunction<any, unknown, string, unknown>;
  handleDeleteDocument: (document_id: string, name: string) => Promise<void>;
  title: string;
  index: number;
  handleChangeDocumentName: UseMutateFunction<
    AllMyProjects,
    unknown,
    IHandleChangeDocumentNameProps,
    unknown
  >;
  isEditName: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditName: React.Dispatch<React.SetStateAction<boolean>>;
  downloading: boolean;
  setDownloading: React.Dispatch<React.SetStateAction<boolean>>;
  idToastifyDownloadRef: any;
  whichFileToBeDownload: React.MutableRefObject<"pdf" | "word">;
  documentPreviewData: React.MutableRefObject<PagesProps[]>;
  isIntentBuyModal?: boolean;
  setIsIntentBuyModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const OptionsTemplateOneClick = ({
  isEditName,
  setIsEditName,
  handleMakeCopy,
  id,
  setIsOpen,
  handleDeleteDocument,
  title,
  index,
  handleChangeDocumentName,
  documentPreviewData,
  whichFileToBeDownload,
  idToastifyDownloadRef,
  downloading,
  setDownloading,
  isIntentBuyModal,
  setIsIntentBuyModal,
}: IOptionsTemplateOneClickProps) => {
  const [isOpenTeamModal, setIsOpenTeamModal] = useState(false);
  const [isOpenDowloadModal, setIsOpenDowloadModal] = useState(false);
  const [typeUserAccount, setTypeUserAccount] = useState<
    "trial" | "premium" | "loading"
  >("loading");
  const { data } = useAuth();
  const { handleDowloadProjectAction } = useMetric();
  const { setIsNewDownloadObject } = useHeaderEditor();
  const { handleCopyTemplateTeam, listTeamsOfOwner } = useTeams();

  const handlePreviewDocument = useCallback(
    async (document_id: string, type: "pdf" | "word") => {

      const loadingtoast = toast.loading("Tudo certo. Aguarde um momento.");
      try {
        //do something else

        const fetchDocumentPreview = await api.get(
          `user-templates/template/${document_id}`
        );

        documentPreviewData.current =
          fetchDocumentPreview.data.response.arrayOfPages;
        whichFileToBeDownload.current = type;
        idToastifyDownloadRef.current = title;

        setTimeout(() => {
          toast.dismiss(loadingtoast);
        }, 1000);
        setDownloading(true);

        // Registrar ação de download do documento
        handleDowloadProjectAction({
          project_id: document_id,
          automated: false,
          type: type
        });
      } catch (error) {
        setTimeout(() => {
          toast.dismiss(loadingtoast);
        }, 1000);
        toast.error("Ops, algo deu errado!");

        console.error("error getting document to preview", error);
      }
    },
    [
      documentPreviewData,
      whichFileToBeDownload,
      idToastifyDownloadRef,
      title,
      setDownloading,
      handleDowloadProjectAction
    ]
  );

  const isBitsAcademyEmail = useMemo(() => {
    return !!data?.user?.email.match("@bitsacademy.com.br");
  }, [data]);

  useEffect(() => {
    if (
      data.payment_method?.user_type === "user_free" &&
      !data.payment_method.subscription_id &&
      data.payment_method.cancellation_date &&
      data.payment_method.is_active
    ) {
      setTypeUserAccount("trial");
    } else {
      setTypeUserAccount("premium");
    }
  }, [data]);

  return (
    <Container isScreenLimit={index === 3 || index === 7}>
      <Item>
        <EditName>
          {isEditName ? (
            <input
              type="text"
              defaultValue={title}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  handleChangeDocumentName({
                    documentId: id,
                    newDocumentName: event.target.value
                  });
                  setIsEditName(false);
                }
              }}
            />
          ) : (
            <div onClick={() => setIsEditName(true)}>
              <p>{title}</p>
              <PersonalizedIcon
                dPath={MountIcons.IconEdit.dPath}
                viewBox={MountIcons.IconEdit.viewBox}
                inactivatedColor="#999C9F"
              />
            </div>
          )}
        </EditName>
      </Item>
      <Item onClick={() => handleMakeCopy(id)}>
        <div>
          <PersonalizedIcon
            dPath={MountIcons.IconCopy.dPath}
            viewBox={MountIcons.IconCopy.viewBox}
          />
          Fazer uma cópia em Meus projetos
        </div>
      </Item>
      <Item
        onMouseEnter={() => setIsOpenTeamModal(true)}
        onMouseLeave={() => setIsOpenTeamModal(false)}
      >
        <div>
          <PersonalizedIcon
            dPath={MountIcons.TeamsIcon.dPath}
            viewBox={MountIcons.TeamsIcon.viewBox}
          />
          Criar cópia para equipe
        </div>
        <PersonalizedIcon
          dPath={MountIcons.IconArrowRight.dPath}
          viewBox={MountIcons.IconArrowRight.viewBox}
          inactivatedColor="#676B70"
        />
        {isOpenTeamModal && (
          <SubModal
            isScreenLimit={
              index === 2 || index === 3 || index === 6 || index === 7
            }
          >
            {listTeamsOfOwner?.map(team => (
              <ItemSubModal
                key={team.team_id}
                onClick={async () => {
                  setIsOpen(false);
                  await handleCopyTemplateTeam(id, team.team_id);
                }}
              >
                <PersonalizedIcon
                  dPath={MountIcons.TeamsIcon.dPath}
                  viewBox={MountIcons.TeamsIcon.viewBox}
                />
                <p>{team.name}</p>
              </ItemSubModal>
            ))}
          </SubModal>
        )}
      </Item>
      <Item
        onMouseEnter={() => setIsOpenDowloadModal(true)}
        onMouseLeave={() => setIsOpenDowloadModal(false)}
      >
        <div>
          <img src={DowloadICon} alt="" /> Baixar
        </div>
        <PersonalizedIcon
          dPath={MountIcons.IconArrowRight.dPath}
          viewBox={MountIcons.IconArrowRight.viewBox}
          inactivatedColor="#676B70"
        />
        {isOpenDowloadModal && (
          <SubModal
            isScreenLimit={
              index === 2 || index === 3 || index === 6 || index === 7
            }
          >
            {isBitsAcademyEmail && (
              <>
                <ItemSubModal onClick={() => {
                  setIsNewDownloadObject({
                    isNewDownload: true,
                    isPDF: true,
                    id,
                  });
                  handlePreviewDocument(id, "pdf");
                }
                }>
                  <img src={PdfICon} alt="" />
                  <p>PDF Novo</p>
                </ItemSubModal>
                <ItemSubModal onClick={() => {
                  setIsNewDownloadObject({
                    isNewDownload: true,
                    isPDF: false,
                    id,
                  });
                  handlePreviewDocument(id, "word");
                }
                }>
                  <img src={WordICon} alt="" />
                  <p>Word Novo</p>
                </ItemSubModal>
              </>
            )}
            <ItemSubModal onClick={() => handlePreviewDocument(id, "pdf")}>
              <img src={PdfICon} alt="" />
              <p>PDF</p>
            </ItemSubModal>
            <ItemSubModal
              onClick={() => handlePreviewDocument(id, "word")}
            >
              <img src={WordICon} alt="" />
              <p>Word</p>
            </ItemSubModal>
          </SubModal>
        )}
      </Item>
      <Item onClick={() => handleDeleteDocument(id, title)}>
        <div>
          <PersonalizedIcon
            dPath={MountIcons.IconTrash.dPath}
            viewBox={MountIcons.IconTrash.viewBox}
          />
          Excluir
        </div>
      </Item>
    </Container>
  );
};

export default OptionsTemplateOneClick;
