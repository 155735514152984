import { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../hooks/auth";
import api from "../../../../../../services/api";
import { useMetric } from "../../../../../../hooks/metric";

// import { Container } from './styles';
interface ProfileProps {
  user?: {
    id: string;
    people_id: string;
    email: string;
    avatar: string;
    is_blocked: boolean;
    user_type: string;
    created_at: string;
    updated_at: string;
    avatar_url: string;
  };
  people?: {
    id: string;
    address_id: string;
    contact_id: string;
    first_name: string;
    last_name: string;
    born_at: null | string;
    gender: null | string;
    document: null | string;
    is_individual: boolean;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  addresses?: {
    id: string;
    zipcode: null | string;
    street: null | string;
    number: null | string;
    district: null | string;
    city: null | string;
    state: null | string;
    country: null | string;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  contacts?: {
    id: string;
    phone: string;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  is_finished_profile?: boolean;
  payment_method?: {
    is_active: boolean;
    customer_id: string;
    subscription_id: string;
    cancellation_date: null | string;
  };
}

const VerificationPaid: React.FC = () => {
  const navigate = useNavigate();
  const { setData, signOut } = useAuth();
  const { handlePaymentAcceptedAction } = useMetric();

  useEffect(() => {
    (async () => {
      try {
        const responseUserProfile: AxiosResponse<ProfileProps> = await api.get(
          "profile"
        );

        setData(oldState => {
          return {
            ...oldState,
            payment_method: {
              ...responseUserProfile.data.payment_method
            }
          };
        });

        //function of recording the payment action
        handlePaymentAcceptedAction();

        navigate("/");
      } catch (err) {
        // signOut();
        console.error(err, "error when update profile");
      }
    })();

    //function responsible for Payment Accepted action
  }, []);

  return <div />;
};

export default VerificationPaid;
