import React from "react";
import Tooltip from "../../../../../../Tooltip";

// import { Container } from './styles';
interface ColorsProps {
  onClick: () => void;
  color: string;
}

const Colors: React.FC<ColorsProps> = ({ onClick, color }) => {
  return (
    <Tooltip
      placement="bottom-start"
      title="Cor do texto"
      description="Modifique a cor do seu texto."
    >
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M8.58647 14.827C8.27874 14.827 8.03443 14.6953 7.85353 14.4319C7.67264 14.1686 7.63598 13.8887 7.74355 13.5925L11.2758 4.1008C11.3427 3.91191 11.4564 3.76349 11.617 3.65556C11.7775 3.54762 11.9514 3.49365 12.1388 3.49365H12.8613C13.062 3.49365 13.2394 3.54762 13.3935 3.65556C13.5476 3.76349 13.6579 3.91191 13.7242 4.1008L17.2765 13.6127C17.3836 13.9095 17.3469 14.1861 17.1666 14.4425C16.9862 14.6988 16.7419 14.827 16.4336 14.827C16.2463 14.827 16.0724 14.773 15.9118 14.6651C15.7513 14.5571 15.6375 14.4087 15.5706 14.2198L14.7879 11.9127H10.2522L9.42939 14.2401C9.36249 14.429 9.25197 14.5739 9.09784 14.6748C8.9437 14.7757 8.77271 14.8264 8.58647 14.827ZM10.8142 10.2937H14.1858L12.5401 5.59841H12.4599L10.8142 10.2937Z"
          fill="#343A40"
        />
        <rect
          x="4.57223"
          y="17.3813"
          width="15.8555"
          height="3.0124"
          rx="0.6"
          fill={color}
          stroke="#343A40"
          stroke-width="0.2"
        />
      </svg>
    </Tooltip>
  );
};

export default Colors;
