import { useCallback, useEffect } from "react";
import RulerLeft from "./components/RulerLeft";
import RulerTop from "./components/RulerTop";
import { Container } from "./styled";
import { usePagesEditor } from "../../hooks/pagesEditor";
import { useWorkspaceEditor } from "../../hooks/workspaceEditor";
import { cloneDeep } from "lodash";
import GuidelineH from "./components/GuidelineH";
import GuidelineV from "./components/GuidelineV";
import { v4 as uuidv4 } from "uuid";

import RulerBottom from "./components/RulerBottom";

const GuideZone = ({ children, item }) => {
  const { setLinesPage, linespage, mousePosition } = usePagesEditor();
  const { handleMouseMoveLineGuide, showRuler, isDragTemplate } =
    useWorkspaceEditor();

  const thisIndexObject = linespage.findIndex((element) => {
    return element.pageNumber === parseInt(item?.pageNumber + "");
  });

  //responsible for creating an object that stores the
  //guidelines for each page of the template
  useEffect(() => {
    setLinesPage((prevState) => {
      let cloneState = cloneDeep(prevState);
      cloneState.push({
        pageNumber: parseInt(item?.pageNumber + ""),
        islineH: false,
        islineV: false,
        lineGuidH: item.lineGuide?.h ? item.lineGuide.h : [],
        lineGuidV: item.lineGuide?.v ? item.lineGuide.v : [],
      });

      return cloneState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //responsible for clearing the guidelines when a new template is dragged
  useEffect(() => {
    if (isDragTemplate) {
      setLinesPage((prevState) => {
        let cloneState = prevState.map((element) => {
          if (element === linespage[thisIndexObject]) {
            return {
              pageNumber: parseInt(item?.pageNumber + ""),
              islineH: false,
              islineV: false,
              lineGuidH: [],
              lineGuidV: [],
            };
          }

          return element;
        });

        return cloneState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragTemplate]);

  const setLineGuide = useCallback(() => {
    if (linespage[thisIndexObject]?.islineV) {
      if (mousePosition.x > 23 && mousePosition.x < 618) {
        setLinesPage((prevState) => {
          let cloneState = cloneDeep(prevState);
          let thisObject = cloneState[thisIndexObject];
          let id = uuidv4();
          thisObject.lineGuidV = [
            ...thisObject.lineGuidV,
            { x: mousePosition.x, id },
          ];

          return cloneState;
        });
      }

      setLinesPage((prevState) => {
        let cloneState = cloneDeep(prevState);
        let thisObject = cloneState[thisIndexObject];
        thisObject.islineV = false;

        return cloneState;
      });
    }

    if (linespage[thisIndexObject]?.islineH) {
      if (mousePosition.y > 23 && mousePosition.y < 865) {
        setLinesPage((prevState) => {
          let cloneState = cloneDeep(prevState);
          let thisObject = cloneState[thisIndexObject];
          let id = uuidv4();
          thisObject.lineGuidH = [
            ...thisObject.lineGuidH,
            { y: mousePosition.y, id },
          ];

          return cloneState;
        });
      }

      setLinesPage((prevState) => {
        let cloneState = cloneDeep(prevState);
        let thisObject = cloneState[thisIndexObject];
        thisObject.islineH = false;

        return cloneState;
      });
    }
  }, [
    linespage,
    mousePosition.x,
    mousePosition.y,
    setLinesPage,
    thisIndexObject,
  ]);

  return (
    <Container
      onMouseMove={(e) => {
        if (
          linespage[thisIndexObject]?.islineV ||
          linespage[thisIndexObject]?.islineH
        ) {
          handleMouseMoveLineGuide(e, item.pageNumber);
        }
      }}
      className="get-height_guide_zone"
      onClick={() => {
        setLineGuide();
      }}
      onMouseUp={() => {
        setLineGuide();
      }}
      onMouseLeave={() => {
        setLineGuide();
      }}
    >
      {
        showRuler && (
          <RulerTop
            setLinesPage={setLinesPage}
            thisIndexObject={thisIndexObject}
            isActive={showRuler}
            linespage={linespage}
          />
        )
      }

      {linespage[thisIndexObject]?.islineH && (
        <GuidelineH
          position={{ top: mousePosition.y, left: 0 }}
          isActive={true}
          thisIndexObject={thisIndexObject}
          handleMouseMoveLineGuide={handleMouseMoveLineGuide}
          pageNumber={item.pageNumber}
        />
      )}
      {showRuler &&
        linespage[thisIndexObject]?.lineGuidH.map((element, index) => {
          return (
            <GuidelineH
              key={index}
              position={{ top: element.y, left: 0 }}
              setLinesPage={setLinesPage}
              id={element.id}
              isActive={false}
              thisIndexObject={thisIndexObject}
              handleMouseMoveLineGuide={handleMouseMoveLineGuide}
              pageNumber={item.pageNumber}
            />
          );
        })}

      <RulerLeft
        setLinesPage={setLinesPage}
        thisIndexObject={thisIndexObject}
        isActive={showRuler}
      />
      {linespage[thisIndexObject]?.islineV && (
        <GuidelineV
          position={{ top: 0, left: mousePosition.x }}
          isActive={true}
          thisIndexObject={thisIndexObject}
          handleMouseMoveLineGuide={handleMouseMoveLineGuide}
          pageNumber={item.pageNumber}
        />
      )}
      {showRuler &&
        linespage[thisIndexObject]?.lineGuidV.map((element, index) => {
          return (
            <GuidelineV
              key={index}
              position={{ top: 0, left: element.x }}
              setLinesPage={setLinesPage}
              id={element.id}
              isActive={false}
              thisIndexObject={thisIndexObject}
              handleMouseMoveLineGuide={handleMouseMoveLineGuide}
              pageNumber={item.pageNumber}
            />
          );
        })}

      {children}
      <RulerBottom
        setLinesPage={setLinesPage}
        thisIndexObject={thisIndexObject}
        isActive={showRuler}
        linespage={linespage}
      />
    </Container>
  );
};

export default GuideZone;
