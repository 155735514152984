import styled from "styled-components";

export const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1999;
`;

export const Container = styled.div`
  width: 700px;
  background: ${({ theme }) => theme.colors.white100};
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 20px;

  h5 {
    font-family: "Ubuntu";
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.black100};
    margin-bottom: 5px;

    user-select: none;
  }

  & > p {
    user-select: none;
  }

  @media (min-width: 1600px) {
    h5 {
      font-size: 18px;
    }

    & > span {
      font-size: 15px;
    }
  }

  @media (min-width: 1920px) {
    width: 820px;
    padding: 23px;

    h5 {
      font-size: 22px;
    }

    & > span {
      font-size: 16px;
    }
  }
`;

export const TextBox = styled.div`
  width: 100%;
  height: 50%;
  background: red;
`;

export const ButtonsBox = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 30px;
`;

