import React from "react";

import { Container, HStack, Heading, Text } from "./styles";
import { CheckCircle } from "phosphor-react";

const Done2Fa: React.FC = () => {
  return (
    <Container>
      <HStack>
        <CheckCircle size={24} color="#007B36" />
        <Heading>Configuração concluída</Heading>
      </HStack>
      <Text>
        De agora em diante, sempre que você entrar na sua conta, precisará
        inserir sua senha e um código de autenticação gerado pelo{" "}
        <strong>Google Authenticator.</strong>
      </Text>
    </Container>
  );
};

export default Done2Fa;

