import styled from "styled-components";

export const GridItemSidebarMain = styled.div`
  grid-area: sidebar;
  width: 397px;

  

  .asidebar-icon {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 1920px) {
    width: 500px;
  }
`;

export const SidebarBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  display: block;
  background: #f8f9fa;
  flex-direction: column;
`;

export const SidebarNavigate = styled.div`
  width: 100%;
  height: 40px;
  background: ${({ theme }) => theme.colors.gray900};

  display: grid;
  grid-template-columns: repeat(8, 1fr);

  & .btn-toolbar {
    position: relative;
    width: 100%;
  }
`;

export const ControlOfEditions = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  min-width: 259px;
  height: 100%;
  display: flex;
  padding: 10px;
`;

export const ButtonDefault = styled.button<{ color: string }>`
  width: 116px;
  height: 50px;
  background: ${({ color }) => color && color};
  border-radius: 5px;
  color: white;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 400;
  margin: 0 0.4rem;
  -webkit-box-shadow: 0px 1px 6px -2px #000000;
  box-shadow: 0px 1px 6px -2px #000000;

  @media (max-width: 1600px) {
  }

  @media (max-width: 1366px) {
    width: 100px;
    height: 48px;
    font-size: 1.2rem;
  }
`;

export const SidebarTextEdition = styled.div`
  width: 100%;
  height: 60px;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  display: flex;
  z-index: 40;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gray900};
  position: relative;

  & .icon-exit-edit-text {
    position: absolute;
    right: 4%;
    top: 35%;
    bottom: 0;
  }
`;

export const Heading = styled.div`
  font-family: "Roboto";
  font-size: 1.6rem;
  color: #fff;
  margin: 0 auto;
`;

export const Button = styled.button`
  background: white;
`;

export const BlockedTextEditor = styled.div`
  //background-color: rgba(0, 0, 0, 0.4);
  //background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
`;
