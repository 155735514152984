const colors = [
  "#24958E",
  "#D38E26",
  "#0066CC",
  "#6858BB",
  "#61A861",
  "#BA52C1"
];

export const getColors = (myColor = false) => {
  return colors[Math.floor(Math.random() * colors.length)];
};
