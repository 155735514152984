import styled from "styled-components";

export const Container = styled.div<{ isEmpty: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.isEmpty ? 'auto' : 'repeat(4, 1fr)')};
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 15px;
  overflow-y: auto;
  min-height: 430px;
`;

export const NoElements = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  color: #999c9f;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1600px) {
    min-height: 500px;
  }
`;

export const Template = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 15px;

  input {
    height: 24px;
    width: 24px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;

    position: absolute;
    left: 5px;
    top: 5px;

    accent-color: ${({ theme }) => theme.colors.primary};
    :checked {
      background: ${({ theme }) => theme.colors.primary};
      border: 1px solid #c4c4c4;
      border-radius: 5px;
    }
  }

  p {
    line-height: 16px;
    text-align: center;
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999c9f;
    text-align: start;
  }
`;

export const ImageTemplate = styled.figure`
  width: 161.245px;
  height: 227.967px;
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  background: #fff;

  img {
    width: 100%;
    height: 100%;
  }
`;
