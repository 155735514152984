import { useState } from "react";
import PlainText from "../../../../../../../shared/components/PlainText";
import Options from "../Options";
import {
  Container,
  SubscriberBox,
  AvatarCustom,
  AvailableDate
} from "./styled";
import {
  IModalSign,
  SignDataProps
} from "../../../../../../../dtos/signedProps";

interface ISignItem {
  sign: SignDataProps;
  formatDate: (date: string) => string;
  formatDateFinal: (date: string) => string;
  setOpenModal: React.Dispatch<React.SetStateAction<IModalSign>>;
}

const SignItem = ({
  sign,
  formatDate,
  formatDateFinal,
  setOpenModal
}: ISignItem) => {
  const [isVisibleOption, setIsVisibleOption] = useState(false);

  return (
    <Container
      onMouseEnter={() => setIsVisibleOption(true)}
      onMouseLeave={() => setIsVisibleOption(false)}
    >
      <PlainText> {sign.signature.title} </PlainText>
      <PlainText>
        {sign.signature.status === "Assinatura expirada"
          ? "Expirado"
          : sign.signature.status}
      </PlainText>
      <SubscriberBox>
        {sign.signature_users?.slice(0, 3).map((user, indexOfUser) => (
          <AvatarCustom indexOfUser={indexOfUser + 1} key={user.id}>
            <span>
              {`${user.first_name.charAt(0).toUpperCase()}${user.last_name
                .charAt(0)
                .toUpperCase()}`}
            </span>
          </AvatarCustom>
        ))}
      </SubscriberBox>
      <PlainText> {formatDate(sign.signature.created_at)} </PlainText>
      <PlainText>
        {sign?.signature?.status === "Concluído"
          ? `${sign?.signature?.difference_days} dias`
          : '-'}
      </PlainText>
      <AvailableDate isExpired={false}>
        {sign.signature.status === "Concluído"
          ? "Concluído"
          : formatDateFinal(sign.signature.final_date)
        }
      </AvailableDate>
      {isVisibleOption && <Options sign={sign} setOpenModal={setOpenModal} />}
    </Container>
  );
};

export default SignItem;
