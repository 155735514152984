interface IconShieldLockProps {
  isActive: boolean;
  colorActive: string;
}

export const IconShieldLock = ({
  isActive,
  colorActive
}: IconShieldLockProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11067_29021"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.446289" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11067_29021)">
        <path
          d="M12 22.4463C9.68333 21.863 7.77083 20.5338 6.2625 18.4588C4.75417 16.3838 4 14.0796 4 11.5463V5.44629L12 2.44629L20 5.44629V11.5463C20 14.0796 19.2458 16.3838 17.7375 18.4588C16.2292 20.5338 14.3167 21.863 12 22.4463ZM12 20.3463C13.7333 19.7963 15.1667 18.6963 16.3 17.0463C17.4333 15.3963 18 13.563 18 11.5463V6.82129L12 4.57129L6 6.82129V11.5463C6 13.563 6.56667 15.3963 7.7 17.0463C8.83333 18.6963 10.2667 19.7963 12 20.3463ZM10 16.4463H14C14.2833 16.4463 14.5208 16.3505 14.7125 16.1588C14.9042 15.9671 15 15.7296 15 15.4463V12.4463C15 12.163 14.9042 11.9255 14.7125 11.7338C14.5208 11.5421 14.2833 11.4463 14 11.4463V10.4463C14 9.89629 13.8042 9.42546 13.4125 9.03379C13.0208 8.64212 12.55 8.44629 12 8.44629C11.45 8.44629 10.9792 8.64212 10.5875 9.03379C10.1958 9.42546 10 9.89629 10 10.4463V11.4463C9.71667 11.4463 9.47917 11.5421 9.2875 11.7338C9.09583 11.9255 9 12.163 9 12.4463V15.4463C9 15.7296 9.09583 15.9671 9.2875 16.1588C9.47917 16.3505 9.71667 16.4463 10 16.4463ZM11 11.4463V10.4463C11 10.163 11.0958 9.92546 11.2875 9.73379C11.4792 9.54212 11.7167 9.44629 12 9.44629C12.2833 9.44629 12.5208 9.54212 12.7125 9.73379C12.9042 9.92546 13 10.163 13 10.4463V11.4463H11Z"
          fill={isActive ? colorActive : "#676B70"}
        />
      </g>
    </svg>
  );
};
