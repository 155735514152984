import { Container } from "./styles";

interface IPasswordAlertProps {
  passwordMessages: {
    haveMinLength: string;
    haveUpperOrLower: string;
    haveOneSymbol: string;
  };
}

const PasswordAlert = ({ passwordMessages }: IPasswordAlertProps) => {
  return (
    <Container isActive={passwordMessages}>
      <p>Sua senha deve conter:</p>
      <ul>
        <li className="have-min-length">Pelo menos conter 8 caracteres</li>
        <li className="have-min-upper-lower">Letras maiúsculas e minúsculas</li>
        <li className="have-symbol">
          Pelo menos 1 símbolo especial e um número (!@#$%&0-9)
        </li>
      </ul>
    </Container>
  );
};

export default PasswordAlert;
