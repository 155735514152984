import styled from "styled-components";

export const Button = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  background-color: transparent;
  position: relative;
  border-radius: 5px;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: #ebebec;
  }
`;
