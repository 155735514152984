import React from "react";
import { Button, TitleBox } from "./styles";
import { DescriptionText } from "../../pages/ProfilePage/components/SecurityPage/styles";
import { X } from "phosphor-react";
import { useTwoFactorAuth } from "../../../../../hooks/twoFactorAuth";
import { useAuth } from "../../../../../hooks/auth";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  title: string;
  hiddenClose?: boolean;
  blockOutPage?: boolean;
  isEmergency?: boolean;
  isSetup?: boolean;
};

const HeaderModal2FA: React.FC<Props> = ({
  title,
  hiddenClose = false,
  blockOutPage = false,
  isEmergency = false,
  isSetup = false,
}) => {
  const { handleCloseModal } = useTwoFactorAuth();
  const { signOut } = useAuth();
  const navigate = useNavigate();

  let isProfilePageAccess = !blockOutPage;

  const myCurrentUrl = window.location.href;

  let isProfilePath = myCurrentUrl?.includes("perfil");

  return (
    <TitleBox>
      {isSetup ? (
        <DescriptionText style={{ fontWeight: 700, marginTop: 0 }}>
          {isEmergency
            ? "Autenticação de dois fatores com backup de emergência"
            : isProfilePageAccess && !isEmergency
            ? "Autenticação de dois fatores"
            : title}
        </DescriptionText>
      ) : (
        <DescriptionText style={{ fontWeight: 700, marginTop: 0 }}>
          {isEmergency && isProfilePath
            ? "Autenticação de dois fatores com backup de emergência"
            : isEmergency && !isProfilePageAccess
            ? "Login com código de backup de emergência"
            : isProfilePageAccess && !isEmergency
            ? "Login com aplicativo autenticador"
            : title}
        </DescriptionText>
      )}

      {isProfilePageAccess ? (
        <Button onClick={!hiddenClose ? handleCloseModal : signOut}>
          <X size={20} color="#999C9F" />
        </Button>
      ) : isSetup ? (
        <Button onClick={() => navigate("/")}>
          <X size={20} color="#999C9F" />
        </Button>
      ) : (
        <Button onClick={() => navigate("/")}>
          <X size={20} color="#999C9F" />
        </Button>
      )}
    </TitleBox>
  );
};

export default HeaderModal2FA;

