import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  height: 100vh;
`;

export const Form = styled.form`
  width: 405px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 431px) {
    width: 87%;
  }
`;

export const FooterForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const LoadingFlex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const LogoOrganization = styled.img`
  width: 115px;
  height: 61px;
`;
