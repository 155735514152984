import styled from "styled-components";

interface ContainerSidebarNavProps {
  paddingTop?: string;
}

export const ContainerSidebarNav = styled.div<ContainerSidebarNavProps>`
  display: flex;
  flex-direction: column;
  padding: 10px;

  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "")};
  height: 100%;
  width: 100%;
  padding-bottom: 8rem;
  background-color: #f5f6fa;
`;

export const ButtonAutomation = styled.button`
  width: 100%;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 5px;
  color: #fff;

  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
`;

export const SubContainerSideBarNav = styled.div`
  height: 80%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 20px;
`;

export const ListDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  position: relative;
  visibility: "none";

  .closed-modal-automation {
    width: 30px;
    height: 30px;
    right: 10px;
    top: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const ModalContainerQuit = styled.div`
  min-width: 900px;
  min-height: 157px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  z-index: 100000;

  span {
    font-size: 14px;
    color: #3d3d3d;
    margin-top: 10px;
  }

  .closed-modal-automation {
    width: 30px;
    height: 30px;
    right: 10px;
    top: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const TextBox = styled.div`
  width: 100%;
  height: 50%;
  background: red;
`;

export const ButtonsBox = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 3rem;
`;

export const Button = styled.button<{ color: string }>`
  width: 415px;
  height: 40px;
  background-color: ${({ color }) => color && color};
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonNo = styled.button<{ color: string }>`
  width: 415px;
  height: 40px;
  background-color: ${({ color, theme }) =>
    color === "#FFF" && theme.colors.white100};
  border-radius: 5px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
`;

export const ContainerVariables = styled.div`
  width: 800px;
  height: 80%;
  max-height: 620px;
  background-color: #fff;
  border-radius: 5px;

  @media (max-height: 767px) {
    height: 95%;
  }
`;

export const ContainerModalDocumentName = styled.div`
  width: 700px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;

  position: relative;
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const LabelDocumentName = styled.label`
  color: #343a40;
  font-size: 1.4rem;
  font-family: "Roboto";
`;

export const ContainerButtonsYesNo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
`;

export const NoVariables = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;

export const NoVariablesLabel = styled.label`
  font-size: 12pt;
  text-align: center;
`;

export const HeaderModal = styled.div`
  padding: 20px 20px 10px 20px;
  border-bottom: 1px solid #ebebec;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GridInput = styled.div`
  width: 100%;
  height: 495px;
  padding: 10px 20px;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-height: 767px) {
    height: 80%;
  }
  @media (max-height: 600px) {
    height: 77%;
  }
`;

export const FooterModal = styled.div`
  display: flex;
  gap: 10px;

  padding: 10px 20px 20px 20px;
`;

export const HeaderModalDocumentName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;

  & > div {
    position: absolute;
    top: 13px;
    right: 15px;
  }
`;

export const BoxButtonDocumentName = styled.div`
  padding-top: 15px;
  padding-bottom: 30px;

  & > div {
    border-radius: 5px;
    border: 1px solid #999c9f;
    margin-top: 5px;
  }

  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
`;

export const TextNotElement = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  user-select: none;
  color: #999c9f;

  height: 300px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
