import React from "react";

import { Container } from "./styles";
import SupportIcon from "./icon/SupportIcon";
import { Link } from "react-router-dom";

const ButtonSuport: React.FC = () => {
  const handleNavigateToSupport = () => {
    window.open("https://uxdoc.com.br/ajuda/", "_blank");
  };

  return (
    <Container onClick={handleNavigateToSupport}>
      <SupportIcon />
    </Container>
  );
};

export default ButtonSuport;
