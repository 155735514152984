import React, { useCallback, useEffect, useState } from "react";

import {
  Container,
  Header,
  SignContainerBox,
} from "./styles";

import { useSign } from "../../../../hooks/sign";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../../services/api";
import { useMainHook } from "../../../../hooks/main";
import jsPDF from "jspdf";
import { useWorkspaceEditor } from "../../hooks/workspaceEditor";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonExit from "../../../../shared/components/ButtonExit";
import { AxiosResponse } from "axios";
import FormSign from "../../../../shared/components/FormSign";

interface IFormAddMember {
  first_name: string,
  last_name: string,
  email: string,
  type: string,
  others?: string,
}

const ModalSign: React.FC = () => {
  const { setIsSignEditor, isSignEditor, setSignerData, signerData } = useSign();
  const { containerRef } = useWorkspaceEditor();
  const { documentName, stageRef, setObjectScreen, objectScreen } =
    useMainHook();
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mail, setMail] = useState("");
  const [date, setDate] = useState("");
  const [assignWho, setAssignWho] = useState("assinante");
  const [others, setOthers] = useState("");

  //check stages
  const waitForStages = useCallback(async (): Promise<void> => {
    return new Promise<void>((resolve) => {
      const checkStages = () => {
        if (stageRef.current.length === objectScreen.length) {
          resolve();
        } else {
          setTimeout(checkStages, 100);
        }
      };

      checkStages();
    });
  }, [stageRef, objectScreen]);

  function base64toBlob(base64String: string): Blob {
    const byteString = atob(base64String);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }
    const mimeString = "image/jpeg";
    return new Blob([arrayBuffer], { type: mimeString });
  }

  const handleSendDocSign = useCallback(async () => {
    if (!date) {
      setLoading(false);
      return toast.error("Preencha uma data para o documento");
    }
    if (signerData.length === 0) {
      setLoading(false);
      return toast.error("Adicione um assinante para enviar");
    }
    // current date
    const currentDate = new Date();
    //format date
    const inputDateTimestamp = Date.parse(date);
    // verify if paste or present date is invalid
    const isValidDate =
      !isNaN(inputDateTimestamp) && inputDateTimestamp > currentDate.getTime();

    // if not date for future then return error
    if (!isValidDate) {
      setLoading(false);
      toast.error("Coloque uma data para o futuro.");
      return;
    }

    let usersToSignDoc = [];
    signerData.forEach((user) => {
      const userToAdd: IFormAddMember = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        type: user.type,
      };
      
      if (user.others) {
        userToAdd.others = user.others;
      }

      usersToSignDoc.push(userToAdd);
    });

    try {
      await toast.promise(
        new Promise(async (resolve: any, reject) => {
          try {
            setLoading(true);
            setObjectScreen((oldState) => {
              let cloneState = cloneDeep(oldState);
              for (const object of cloneState) {
                object.visible = true;
              }
              return cloneState;
            });

            setTimeout(async () => {
              await waitForStages();
            }, 2000);

            let firstPageThumb = stageRef.current[0].toDataURL();
            let myDoc = new jsPDF("p", "px", "a4", true);
            const stageRefLength = stageRef.current.length;

            for (let index = 0; index < objectScreen.length; index++) {
              const page = stageRef.current[index];
              const data_url = page.toDataURL({
                pixelRatio: 1,
              });
              let width = myDoc.internal.pageSize.getWidth();
              let height = myDoc.internal.pageSize.getHeight();
              if (index > 0) {
                myDoc.addPage();
              }
              myDoc.addImage(data_url, "PNG", 0, 0, width, height);

              if (index < stageRefLength - 1) {
                await new Promise((resolve) => setTimeout(resolve, 350));
              }
            }

            const pdfBlob = new Blob([myDoc.output("blob")], {
              type: "application/pdf",
            });

            const formData = new FormData();
            formData.append("pdf", pdfBlob, "document.pdf");

            try {
              const sendDoc: AxiosResponse<any> = await api.post("signatures", {
                title: documentName.current,
                final_date: date,
                users: usersToSignDoc,
              });

              if (!sendDoc.data) {
                throw new Error("Falha ao criar documento");
              }

              const sendPDF = await api.patch(
                `signatures/pdf-upload/${sendDoc.data.id}`,
                formData
              );

              if (!sendPDF.data?.document_base64) {
                throw new Error("Falha ao fazer upload do PDF");
              }

              const base64WithoutPrefix = firstPageThumb.replace(
                /^data:image\/(png|jpeg|jpg);base64,/,
                ""
              );
              const blob = base64toBlob(base64WithoutPrefix);
              const file = new File([blob], "filename.jpg", {
                type: "image/jpeg",
              });
              const thumbnailFormData = new FormData();
              thumbnailFormData.append("thumbnail", file);

              const makeThumbnail = await api.patch(
                `signatures/thumbnail-upload/${sendPDF.data.id}`,
                thumbnailFormData
              );

              if (!makeThumbnail.data) {
                throw new Error("Falha ao fazer upload da miniatura");
              }

              setLoading(false);
              resolve();
            } catch (err) {
              setLoading(false);
              toast.error("Ops, tente mais tarde.");
            }
          } catch (error) {
            setLoading(false);
            reject(error);
          }
        }),
        {
          pending: "Gerando assinatura...",
          success: "Documento enviado para assinatura.",
          error: "Ocorreu um erro ao enviar o documento.",
        }
      );

      handleCancel();
      setSignerData([]);
      setDate("");
    } catch (err) {
      console.error(err, "erro when send doc to sign");
      toast.error("Erro ao criar documento. Tente mais tarde.");
    }
  }, [
    signerData,
    setSignerData,
    date,
    documentName,
    stageRef,
    objectScreen,
    setObjectScreen,
    waitForStages,
  ]);

  const handleCancel = useCallback(() => {
    setIsSignEditor((prev) => !prev);
  }, [setIsSignEditor]);

  const handleRemoveMember = useCallback(
    (idUser) => {
      setSignerData((oldState) => {
        let cloneState = cloneDeep(oldState);
        const newArr = cloneState.filter((user) => user.id !== idUser);
        return newArr;
      });
    },
    [setSignerData]
  );

  const handleAddMember = useCallback(() => {
    let isDuplicateMail = false;

    if (mail.includes("@")) {
      signerData.forEach((object) => {
        if (!isDuplicateMail) {
          if (object.email === mail) {
            isDuplicateMail = true;
          }
        }
      });

      if (!isDuplicateMail) {
        setSignerData((oldState) => [
          ...oldState,
          {
            firstName: firstName,
            lastName: lastName,
            email: mail,
            type: assignWho,
            others: assignWho === 'outros' ? others : "",
            id: uuidv4(),
          },
        ]);

        setOthers("");
        setFirstName("");
        setLastName("");
        setMail("");
      } else {
        toast.error("E-mail já incluído na sua lista.");
      }
    } else {
      toast.error("E-mail inválido.");
    }
  }, [mail, signerData, firstName, lastName]);

  useEffect(() => {
    return () => {
      setFirstName("");
      setLastName("");
      setMail("");
      setDate("");
      setAssignWho("");
      setOthers("");
    };
  }, []);

  return (
    <Container>
      <ToastContainer position="bottom-left" />
      <SignContainerBox>
        <Header>
          <TitleH5 title="Cadastro do assinante" />
          <ButtonExit onClick={handleCancel} />
        </Header>
        <FormSign
          name={firstName}
          setName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          mail={mail}
          setMail={setMail}
          assignWho={assignWho}
          setAssignWho={setAssignWho}
          others={others}
          setOthers={setOthers}
          loading={loading}
          signerData={signerData}
          dateFormatted={date}
          setDateFormatted={setDate}
          handleAddMemberToSign={handleAddMember}
          handleDeleteMemberToSign={handleRemoveMember}
          handleBackImport={handleCancel}
          handleSendDocToSign={handleSendDocSign}
        />
      </SignContainerBox>
    </Container>
  );
};

export default ModalSign;
