import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Divider, Text, TokenBox } from "./styles";
import BackupRestoreCode from "../BackupRestoreCode";
import { useTwoFactorAuth } from "../../../../../hooks/twoFactorAuth";
import LoadingTransition from "../LoadingTransition";

type Props = {
  setIsOTPComplete: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthenticated: boolean;
  otp: string[];
  setOTP: React.Dispatch<React.SetStateAction<string[]>>;
};

const Validate2FA: React.FC<Props> = ({
  setIsOTPComplete,
  isAuthenticated,
  otp,
  setOTP,
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));

  const { loadingSetup2FA, setLoadingSetup2FA, backupRestoreCode } =
    useTwoFactorAuth();

  const handleChange = useCallback(
    (index: number, value: string) => {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);
      const complete = newOTP.every((digit) => digit !== "");
      setIsOTPComplete(complete);

      if (value && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    },
    [otp, setOTP, inputRefs, setIsOTPComplete]
  );

  const handleKeyDown = useCallback(
    (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === "Backspace" &&
        otp[index] === "" &&
        inputRefs.current[index - 1]
      ) {
        inputRefs.current[index - 1]?.focus();
      }
    },
    [otp, inputRefs]
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const pastedData = event.clipboardData
        .getData("text/plain")
        .replace(/\D/g, "");
      const pastedOTP = pastedData.split("").slice(0, 6);
      setOTP(pastedOTP);
      const complete = pastedOTP.every((digit) => digit !== "");
      setIsOTPComplete(complete);
    },
    [setIsOTPComplete, setOTP]
  );

  const handleFocus = useCallback(
    (index: number) => {
      inputRefs.current[index]?.select();
    },
    [inputRefs]
  );

  useEffect(() => {
    setIsOTPComplete(false);

    return () => {
      setLoadingSetup2FA(false);
      setOTP(new Array(6).fill(""));
    };
  }, []);

  if (!isAuthenticated) {
    return (
      <Container>
        <Text>
          Insira seu código de autenticação de 6 dígitos fornecido pelo
          aplicativo <strong>Google Authenticator.</strong>
        </Text>

        {loadingSetup2FA ? (
          <LoadingTransition />
        ) : (
          <TokenBox>
            <Divider />
            {otp.map((digit, index) => (
              <input
                key={index}
                ref={(ref) => (inputRefs.current[index] = ref)}
                className={`otp-input ${
                  index === 2 ? "otp-input-space" : `item-${index}`
                }`}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(event) => handleChange(index, event.target.value)}
                onKeyDown={(event) => handleKeyDown(index, event)}
                onPaste={(event) => handlePaste(event)}
                onFocus={() => handleFocus(index)}
              />
            ))}
          </TokenBox>
        )}
      </Container>
    );
  } else {
    return <BackupRestoreCode code={backupRestoreCode} />;
  }
};

export default Validate2FA;

