interface IconKeyProps {
  isActive: boolean;
  colorActive: string;
}

export const IconKey = ({ isActive, colorActive }: IconKeyProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0008 9.11312C17.4428 9.11312 17.8667 8.93752 18.1793 8.62496C18.4918 8.3124 18.6674 7.88848 18.6674 7.44645C18.6674 7.00442 18.4918 6.5805 18.1793 6.26794C17.8667 5.95538 17.4428 5.77979 17.0008 5.77979C16.5587 5.77979 16.1348 5.95538 15.8223 6.26794C15.5097 6.5805 15.3341 7.00442 15.3341 7.44645C15.3341 7.88848 15.5097 8.3124 15.8223 8.62496C16.1348 8.93752 16.5587 9.11312 17.0008 9.11312Z"
        fill={isActive ? colorActive : "#676B70"}
      />
      <path
        d="M11.1674 19.113V18.2796H12.8341C13.0551 18.2796 13.267 18.1918 13.4233 18.0355C13.5796 17.8793 13.6674 17.6673 13.6674 17.4463V15.7796H15.3341C16.734 15.7793 18.0982 15.3383 19.2334 14.5192C20.3687 13.7 21.2172 12.5443 21.6588 11.2159C22.1004 9.8875 22.1126 8.45378 21.6937 7.11803C21.2748 5.78228 20.446 4.6123 19.3249 3.77395C18.2038 2.93561 16.8473 2.47145 15.4476 2.44728C14.0479 2.42312 12.6761 2.84017 11.5268 3.63932C10.3774 4.43847 9.50874 5.57915 9.04398 6.89964C8.57922 8.22013 8.54193 9.65342 8.9374 10.9963L2.4874 17.4463C2.17543 17.759 2.00039 18.1829 2.00073 18.6246V20.7796C2.00073 21.2216 2.17633 21.6456 2.48889 21.9581C2.80145 22.2707 3.22537 22.4463 3.6674 22.4463H7.00073C7.44276 22.4463 7.86668 22.2707 8.17924 21.9581C8.4918 21.6456 8.6674 21.2216 8.6674 20.7796V19.9463H10.3341C10.5551 19.9463 10.767 19.8585 10.9233 19.7022C11.0796 19.5459 11.1674 19.334 11.1674 19.113ZM10.3341 9.11296C10.3341 8.12405 10.6273 7.15735 11.1767 6.3351C11.7261 5.51286 12.507 4.872 13.4206 4.49356C14.3343 4.11512 15.3396 4.0161 16.3095 4.20903C17.2794 4.40196 18.1703 4.87816 18.8696 5.57742C19.5689 6.27668 20.0451 7.1676 20.238 8.1375C20.4309 9.10741 20.3319 10.1127 19.9535 11.0264C19.575 11.94 18.9342 12.7209 18.1119 13.2703C17.2897 13.8197 16.323 14.113 15.3341 14.113H12.8341C12.6131 14.113 12.4011 14.2008 12.2448 14.357C12.0885 14.5133 12.0007 14.7253 12.0007 14.9463V16.613H10.3341C10.1131 16.613 9.90109 16.7008 9.74481 16.857C9.58853 17.0133 9.50073 17.2253 9.50073 17.4463V18.2796H7.83407C7.61305 18.2796 7.40109 18.3674 7.24481 18.5237C7.08853 18.68 7.00073 18.8919 7.00073 19.113V20.7796H3.6674V18.6246L10.4824 11.8096C10.5967 11.6955 10.6753 11.5506 10.7087 11.3926C10.7422 11.2346 10.729 11.0703 10.6707 10.9196C10.4541 10.3596 10.3341 9.75296 10.3341 9.11296Z"
        fill={isActive ? colorActive : "#676B70"}
      />
    </svg>
  );
};
