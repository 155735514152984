import React, { useEffect, useState } from "react";
import {
  Container,
  LoadingFlex,
  Form,
  LogoOrganization,
  TextError
} from "./styles";

import { motion } from "framer-motion";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";

import { toast, ToastContainer } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../services/api";
import ReactLoading from "react-loading";
import UxDocLogoLarge from "../../../../../shared/components/UxDocLogoLarge";
import TitleH3 from "../../../../../shared/components/TitleH3";
import InputForm from "../../../../../shared/components/InputForm";
import PasswordAlert from "../../../unknown/register/components/PasswordAlert";
import ButtonMaxWidth from "../../../../../shared/components/ButtonMaxWidth";
import { useAuth } from "../../../../../hooks/auth";

interface ProfileProps {
  first_name: string;
  last_name: string;
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
  document: string;
  is_individual: boolean;
  phone: string;
}

interface SubmitProps {
  oldPassword?: string;
  password?: string;
  confirmPassword?: string;
}

const ChangePassword: React.FC = () => {
  const { data } = useAuth();

  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [dataError, setDataError] = useState("");
  const [profileUser, setProfileUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    old_password: "",
    password: "",
    password_confirmation: "",
    document: "",
    is_individual: false,
    phone: ""
  } as ProfileProps);
  const [passwordMessages, setPasswordMessages] = useState({
    haveMinLength: "",
    haveUpperOrLower: "",
    haveOneSymbol: ""
  });

  const schema = yup.object({
    oldPassword: yup.string().min(6).required("Senha antiga obrigatoria"),
    password: yup
      .string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(
        /(?=.*[A-Z])/g,
        "A senha deve conter pelo menos uma letra maiúscula"
      )
      .matches(/(?=.*\d)/g, "A senha deve conter pelo menos um número")
      .matches(/[a-z]/, "Senha deve conter pelo menos uma letra minúscula")
      .matches(/[*@!#%&()^~{}]+/, "A senha deve conter pelo menos um símbolo")
      .required(`Senha necessária`),
    confirmPassword: yup
      .string()
      .required("Confirmação de senha obrigatória")
      .oneOf([yup.ref("password"), null], "As senhas não são iguais")
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<SubmitProps>({
    resolver: yupResolver(schema)
  });

  const second = useWatch({
    control,
    name: "password"
  });

  function hasSymbol(str: string) {
    const hasSymbol = /(?=.*\d)(?=.*[\W])/.test(str);

    if (hasSymbol) {
      setPasswordMessages(oldState => {
        return {
          ...oldState,
          haveOneSymbol: "right"
        };
      });
    } else {
      setPasswordMessages(oldState => {
        return {
          ...oldState,
          haveOneSymbol: "wrong"
        };
      });
      setDataError(
        "A senha deve conter pelo menos 1 símbolo especial e um número "
      );
    }
  }

  function hasUpperCaseAndLowerCase(str: string) {
    if (str) {
      const verifyExists = /[a-z]/.test(str) && /[A-Z]/.test(str);

      if (verifyExists) {
        setPasswordMessages(oldState => {
          return {
            ...oldState,
            haveUpperOrLower: "right"
          };
        });
      } else {
        setPasswordMessages(oldState => {
          return {
            ...oldState,
            haveUpperOrLower: "wrong"
          };
        });
        setDataError(
          "Senha deve conter pelo menos uma letra minúscula e maiúscula"
        );
      }
    }
  }

  useEffect(() => {
    if (!second) {
      setPasswordMessages(oldState => {
        return {
          ...oldState,
          haveMinLength: "",
          haveUpperOrLower: "",
          haveOneSymbol: ""
        };
      });
    }

    if (second?.length > 1) {
      hasUpperCaseAndLowerCase(second);
    }

    if (second?.length > 1) {
      hasSymbol(second);
    }

    if (second !== undefined) {
      if (second?.length > 1 && second?.length >= 8) {
        setPasswordMessages(oldState => {
          return {
            ...oldState,
            haveMinLength: "right"
          };
        });
      } else {
        setPasswordMessages(oldState => {
          return {
            ...oldState,
            haveMinLength: "wrong"
          };
        });
        setDataError("A senha deve ter no mínimo 8 caracteres");
      }
    } else {
      setDataError("");
    }

    if (
      second?.length > 8 &&
      /[a-z]/.test(second) &&
      /[A-Z]/.test(second) &&
      /(?=.*\d)(?=.*[\W])/.test(second)
    ) {
      setDataError("");
    }
  }, [second]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      scale: 1.05,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 200,
        staggerDirection: -1
      }
    }
  };

  const onSubmit: SubmitHandler<SubmitProps> = async data => {
    if (data.oldPassword === "") {
      setDataError("Insira a senha.");
      return;
    }

    if (data.password !== data.confirmPassword) {
      setDataError("As senhas não sao iguais.");
      return;
    }

    try {
      setLoading(true);

      const responseResetPassword = await api.put("profile/reset-password", {
        old_password: data.oldPassword,
        new_password: data.password
      });

      toast.success("Nova senha atualizada, redirecionando para perfil...");
      setLoading(false);
      setTimeout(() => {
        setRedirect(true);
      }, 1000);
    } catch (error) {
      setLoading(false);

      setDataError("Senha antiga inválida");
    }
  };

  useEffect(() => {
    if (redirect) {
      window.location.replace("/perfil");
    }
  }, [redirect]);

  useEffect(() => {
    const autoLoad = async () => {
      const response = await api.get("profile");
      const { user, people, contacts } = response.data;

      setProfileUser({
        ...profileUser,
        first_name: people.first_name,
        last_name: people.last_name,
        email: user.email,
        document: people.document,
        is_individual: people.is_individual,
        phone: contacts.phone
      });

      setLoadingScreen(false);
    };

    autoLoad();
  }, []);

  useEffect(() => {
    if (errors?.password?.message) {
      setDataError(`${errors?.password?.message}`);
    } else if (errors?.confirmPassword?.message) {
      setDataError(`${errors?.confirmPassword?.message}`);
    } else if (errors?.oldPassword?.message) {
      if (
        errors?.oldPassword?.message ===
        "oldPassword must be at least 6 characters"
      ) {
        setDataError("A senha antiga deve conter pelo menos 6 caracteres");
      }
      setDataError(`${errors?.oldPassword?.message}`);
    }
  }, [errors]);

  return (
    <>
      {loadingScreen ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <LoadingFlex>
            <ReactLoading type="spin" color="#fff" height={22} width={22} />
          </LoadingFlex>
        </div>
      ) : (
        <Container>
          <ToastContainer position="top-left" />
          {data.organization ? (
            <LogoOrganization src={data.organization.logo_url} />
          ) : (
            <UxDocLogoLarge />
          )}

          <TitleH3 title="Crie uma nova senha" />

          <Form
            initial="hidden"
            animate="show"
            variants={container}
            as={motion.form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <InputForm
              label="Senha Antiga*"
              type="text"
              register={register}
              name="oldPassword"
              error={!!errors?.oldPassword?.message}
            />

            <div>
              <InputForm
                label="Nova senha*"
                type="text"
                register={register}
                name="password"
                error={!!errors?.password?.message}
              />

              <PasswordAlert passwordMessages={passwordMessages} />
            </div>

            <InputForm
              label="Confirmar nova senha*"
              type="text"
              register={register}
              name="confirmPassword"
              error={!!errors?.confirmPassword?.message}
            />

            <div>
              <TextError isError={!!dataError}>{dataError}</TextError>
              <ButtonMaxWidth
                text="Salvar"
                typeStyle="purple"
                type="submit"
                disabled={loading ? true : false}
              />
            </div>
          </Form>
        </Container>
      )}
    </>
  );
};

export default ChangePassword;
