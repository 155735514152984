import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import { AxiosResponse } from "axios";

import { BoxButton, BoxInput, Container, Header, Modal } from "./styles";

import { useSign } from "../../../../../hooks/sign";
import api from "../../../../../services/api";

import { IModalSign, ResponseSign } from "../../../../../dtos/signedProps";
import TitleH5 from "../../../../../shared/components/TitleH5";
import PlainText from "../../../../../shared/components/PlainText";
import { ParagraphError } from "../../../Teams/components/ModalTeams/styled";
import ButtonMaxWidth from "../../../../../shared/components/ButtonMaxWidth";
import { useParams } from "react-router-dom";

interface IModalDeleteDocumentProps {
  setOpenModal: React.Dispatch<React.SetStateAction<IModalSign>>;
  screenNowSignature: string | undefined;
}

const ModalDeleteDocument = ({
  setOpenModal,
  screenNowSignature
}: IModalDeleteDocumentProps) => {
  const {
    docToRemove,
    setDataSign,
    setDocToRemove,
    setTotalPagesDocs,
    setLoadingDocs,
    setDataSpamDocs,
    setLoadingSpamDocs,
    setTotalPagesSpamDocs,
    setAmountSignDocs,
    setRefreshPage,
    refreshPage
  } = useSign();
  const { number_page } = useParams();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;

  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleDeleteSignDoc = useCallback(async () => {
    if (text.toLowerCase() === "confirmar") {
      setIsError(false);
      if (
        screenNowSignature === "documents" ||
        screenNowSignature === undefined
      ) {
        setLoading(true);
        try {
          const deleteDocSign = await api.delete(`signatures/${docToRemove}`);
          if (deleteDocSign.data.is_excluded) {
            setRefreshPage(!refreshPage);
            setLoading(false);
            setDocToRemove("");
            toast.success("Documento movido para lixeira.");
            setOpenModal({
              isOpen: false,
              modalName: ""
            });
          }
        } catch (err) {
          toast.error("Erro ao deletar documento");
          console.error(err, "error");
        }
      } else if (screenNowSignature === "trash") {
        setLoading(true);

        try {
          const deleteDocSign = await api.post("signature-trash/delete", {
            signatures: [
              {
                signature_id: docToRemove
              }
            ]
          });

          if (
            deleteDocSign.data.response === "Signature deleted successfully"
          ) {
            setRefreshPage(!refreshPage);
            setLoadingSpamDocs(false);
            setDocToRemove("");
            toast.success("Documento excluído permanentemente. ");
            setOpenModal({
              isOpen: false,
              modalName: ""
            });
          } else {
            toast.error("Algo deu errado. Tente novamente mais tarde.");
          }
        } catch (err) {
          console.error(err.response.data, "error");
          setLoading(false);
          toast.error("Erro ao excluir. Tente novamente mais tarde.");
        }
      }
    } else {
      setIsError(true);
      toast.error("Palavra errada.");
    }
  }, [
    text,
    screenNowSignature,
    docToRemove,
    setRefreshPage,
    refreshPage,
    setDocToRemove,
    setOpenModal,
    setLoadingSpamDocs
  ]);

  return (
    <Container>
      <Modal>
        <Header>
          <TitleH5
            title={
              screenNowSignature === "trash"
                ? "Excluir definitivamente"
                : "Excluir"
            }
          />
          <PlainText>
            Tem certeza que você quer{" "}
            <b>
              {screenNowSignature === "trash"
                ? "Excluir definitivamente"
                : "Excluir"}
            </b>{" "}
            esse documento?
          </PlainText>
        </Header>
        <BoxInput check={isError}>
          <PlainText>
            Para você completar essa ação, escreva a palavra <b>“confirmar” </b>
            na caixa de texto abaixo
          </PlainText>
          <div>
            <input
              value={text}
              onChange={event => setText(event.target.value)}
              type="text"
            />
            {isError && (
              <ParagraphError>
                Você precisa digitar corretamente a palavra: confirmar
              </ParagraphError>
            )}
          </div>
        </BoxInput>
        <BoxButton>
          <ButtonMaxWidth
            onClick={() =>
              setOpenModal({
                isOpen: false,
                modalName: ""
              })
            }
            text="Não"
            typeStyle="purple"
          />
          <ButtonMaxWidth
            type="button"
            disabled={loading}
            onClick={handleDeleteSignDoc}
            text="Sim"
            typeStyle="white"
            loading={loading}
          />
        </BoxButton>
      </Modal>
    </Container>
  );
};

export default ModalDeleteDocument;
