import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  z-index: 101;
  width: 100%;
  height: 15px;

  display: flex;
  align-items: center;

  pointer-events: none;
  user-select: none;
`;

export const FirsLine = styled.div<{ isActive: boolean }>`
  height: 100%;
  width: 30px;

  display: flex;
  align-items: center;

  cursor: n-resize;

  pointer-events: visible;
  user-select: none;

  div {
    height: 1px;
    width: 100%;
    background-color: ${props => props.theme.colors.primary};
    position: relative;

    .vector-up {
      position: absolute;
      bottom: 4px;
      left: 14px;
      border-left: 5.3px solid transparent;
      border-right: 5.3px solid transparent;
      border-bottom: 5.3px solid ${props => props.theme.colors.primary};

      display: ${({ isActive }) => (isActive ? "none" : "inline-block")};
      transition: 1s;
      animation: fade-in 0.3s ease-in-out;
    }

    .vector-dow {
      position: absolute;
      top: 4px;
      left: 14px;

      border-left: 5.3px solid transparent;
      border-right: 5.3px solid transparent;
      border-top: 5.3px solid ${props => props.theme.colors.primary};

      display: ${({ isActive }) => (isActive ? "none" : "inline-block")};
      transition: 1s;
      animation: fade-in 0.3s ease-in-out;
    }
  }
`;
export const SecondLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.colors.primary};
`;
