import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #ebebec;

  button {
    padding: 8px 16px 8px 8px;
    background-color: #ffff;

    display: flex;
    align-items: center;

    gap: 8px;

    :hover {
      background-color: #f5f5f5;
    }
  }

  .refresh {
    width: 40px;
    height: 40px;
    padding: 0px;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const SubsDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;

  img {
    width: 141.685px;
    height: 200px;
    box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);

    position: absolute;
    top: 0;
    right: 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 15px;

    p:nth-child(2n) {
      color: #343a40;
      font-weight: 400;
    }
  }
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .resend-link {
    color: #5d5fef;
    cursor: pointer;
    background-color: transparent;
  }

  p {
    display: flex;

    span {
      display: flex;
      gap: 30px;
    }
  }
`;

export const SubStatus = styled.span<{ isSigned: boolean }>`
  color: ${({ isSigned }) => (isSigned ? "#14AE5C" : "#FFA629")};
  margin-left: 30px;
`;

export const StatusDoc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .status_circle-1 {
    background-color: #ffa629;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .status_circle-success {
    background-color: #14ae5c;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .status_circle-expired {
    background-color: red;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .status_circle-canceled {
    background-color: red;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  & > div:first-child {
    display: flex;
    gap: 15px;
  }

  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.176px;
`;

export const StatusSignature = styled.div<{ status?: string }>`
  display: flex;
  gap: 15px;

  span {
    color: ${({ status }) =>
      status === "Assinatura expirada" || status === "Assinatura cancelada"
        ? "red"
        : "#000"};
  }
`;

export const Vectors = styled.div<{ isActive?: boolean; status?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 20px;

  div {
    width: 20px;
    height: 20px;
    background-color: ${({ isActive }) => (isActive ? "#14ae5c" : "#a5a5a5")};
    border-radius: 50%;
  }

  span {
    height: 43px;
    width: 2px;
    background-color: #a5a5a5;
    display: inline-block;
  }

  .status_last-Vector {
    background-color: ${({ status }) =>
      status === "Assinatura expirada" || status === "Assinatura cancelada"
        ? "red"
        : status === "Concluído"
        ? "#14ae5c"
        : "#000"};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  div {
    display: flex;
    gap: 10px;
  }

  button:first-child {
    padding: 13px 16px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    border-radius: 5px;

    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
  }
`;

export const ButtonCancel = styled.button`
  color: #999c9f;
  width: 251px;
  height: 40px;
  background-color: #fff;
  margin-left: -15px;

  :hover {
    background-color: #f5f5f5;
  }
`;
