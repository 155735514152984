import React, { MutableRefObject, useEffect } from "react";
import { Container } from "./style";
import IconArrowLeft from "../../assets/arrow-left.svg";
import { useAuth } from "../../../../../../hooks/auth";

interface ReplyProps extends React.HTMLProps<HTMLDivElement> {
  valueReply: string;
  onFocus: (event?: any) => void;
  onBlur: (event?: any) => void;
  refInput: MutableRefObject<HTMLTextAreaElement>;
  setValueReply: React.Dispatch<React.SetStateAction<string>>;
  handleKeyDown: (e: any) => void;
}

const ReplyComment: React.FC<ReplyProps> = ({
  valueReply,
  setValueReply,
  onFocus,
  onBlur,
  refInput,
  handleKeyDown,
  ...rest
}) => {
  const { data } = useAuth();

  useEffect(() => {
    if (valueReply.length === 0) {
      refInput.current.style.height = "26px";
    }
  }, [valueReply]);

  return (
    <Container>
      <div>
        <img src={data.user.avatar_url} alt="" />
        <textarea
          spellCheck="false"
          value={valueReply}
          onChange={event => setValueReply(event.target.value)}
          onInput={() => {
            refInput.current.style.height =
              refInput.current.scrollHeight + "px";
          }}
          placeholder="Responder"
          onKeyDown={handleKeyDown} // Attach the event handler here
          ref={refInput}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      <div style={{ width: "auto" }} {...rest}>
        <img style={{ cursor: "pointer" }} src={IconArrowLeft} alt="" />
      </div>
    </Container>
  );
};

export default ReplyComment;
