import { Box, Dialog } from "@material-ui/core";
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { OneClickContextProps } from "./types/oneClickContextProps";
import { SharedState } from "./types/sharedState";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Item, SelectBox } from "./styles";
import { Alert, Divider, Typography } from "@mui/material";
import iconWord from "../../assets/icon-word.svg";
import iconFile from "../../assets/icon-file.svg";
import InputRadio from "../../shared/components/InputRadio";
import { ButtonColor } from "../editor/components/ToolbarByElement/ButtonColor";
import { VectorOfColors } from "../../shared/utils/VectorOfColors";
import api from "../../services/api";
import { PuffLoader } from "react-spinners";

import { BoxContainer } from "./styles";

import { useMetric } from "../../hooks/metric";
import { useAuth } from "../../hooks/auth";


type Props = {
  open: boolean;
  handleClose: () => void;
};

const INITIAL_STATE: SharedState = {
  colorDefault: "#03989E",
  documentType: "Acordo de Acionistas",
  uploadDocument: {
    file: null,
    name: ""
  },
  uploadLogo: {
    file: null,
    name: ""
  },
};

export const OneClickContext = createContext<OneClickContextProps>({
  sharedState: INITIAL_STATE,
  setSharedState: () => { }
});

export const ImportDocModal: React.FC<Props> = ({
  open,
  handleClose,
}: Props) => {

  const { data } = useAuth();
  const { handleSelectOptionsFromForm, handleSelectSendOrCancel, handleCloseModal } = useMetric();

  const fileInputRefDocument = useRef(null);
  const fileInputRefLogo = useRef(null);
  const [uxWriting, setUxWriting] = useState<boolean>(true);
  const [isLoadingOneClick, setIsLoadingOneClick] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [sharedState, setSharedState] = useState<SharedState>(INITIAL_STATE);
  const colorsSelectVector = ["#000"];
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const handleSetIsDisplayColorPicker = (value) => { };

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleClickUploadDocument = () => {
    fileInputRefDocument.current.click();
  };

  const handleFileChangeDocument = (event) => {
    const file = event.target.files[0];
    setSharedState((prevState) => ({
      ...prevState,
      uploadDocument: {
        file: file,
        name: file.name,
      }
    }));
    handleSelectOptionsFromForm({
      etapa: '1',
      user_id: data.user.id,
      select_document: true,
    });
  };

  const handleFileChangeLogo = (event) => {
    const file = event.target.files[0];
    setSharedState((prevState) => ({
      ...prevState,
      uploadLogo: {
        file: file,
        name: file.name,
      }
    }));
    handleSelectOptionsFromForm({
      etapa: '5',
      user_id: data.user.id,
      upload_logo: true,
    });
  };

  const handleClickUploadLogo = () => {
    fileInputRefLogo.current.click();
  };

  const enableButton = useMemo(() => {
    return sharedState.uploadDocument.file && sharedState.uploadLogo.file;
  }, [sharedState]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      // setIsDisplayColorPicker(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sharedState.colorDefault]
  );

  const typeDocumentsData = [
    { value: "Acordo de Acionistas", label: "Acordo de Acionistas" },
    { value: "Contrato de arrendamento", label: "Contrato de arrendamento" },
    { value: "Contrato de Cessão de Direitos Autorais", label: "Contrato de Cessão de Direitos Autorais" },
    { value: "Contrato de Cessão de Quotas", label: "Contrato de Cessão de Quotas" },
    { value: "Contrato de compra e venda", label: "Contrato de compra e venda" },
    { value: "Contrato de doação", label: "Contrato de doação" },
    { value: "Contrato de fiança bancária", label: "Contrato de fiança bancária" },
    { value: "Contrato de franquia", label: "Contrato de franquia" },
    { value: "Contrato de hipoteca", label: "Contrato de hipoteca" },
    { value: "Contrato de licença de uso de marca", label: "Contrato de licença de uso de marca" },
    { value: "Contrato de locação", label: "Contrato de locação" },
    { value: "Contrato de mútuo conversível", label: "Contrato de mútuo conversível" },
    { value: "Contrato de opção de compra", label: "Contrato de opção de compra" },
    { value: "Contrato de parceria", label: "Contrato de parceria" },
    { value: "Contrato de patrocínio", label: "Contrato de patrocínio" },
    { value: "Contrato de prestação de serviços/ fornecimento", label: "Contrato de prestação de serviços/ fornecimento" },
    { value: "Contrato de Representação Artística", label: "Contrato de Representação Artística" },
    { value: "Contrato de seguro", label: "Contrato de seguro" },
    { value: "Contrato de trabalho", label: "Contrato de trabalho" },
    { value: "Contrato de Transferência de Tecnologia", label: "Contrato de Transferência de Tecnologia" },
    { value: "Contrato de trespasse", label: "Contrato de trespasse" },
    { value: "Contrato para comercialização varejista", label: "Contrato para comercialização varejista" },
    { value: "NDA / Acordo ou Contrato de confidencialidade", label: "NDA / Acordo ou Contrato de confidencialidade" },
    { value: "Termo de rescisão / Distrato", label: "Termo de rescisão / Distrato" }
  ];

  const onSubmitOneClick = async () => {

    setIsLoadingOneClick(prevState => !prevState);
    const formDataLogo = new FormData();
    formDataLogo.append("image", sharedState.uploadLogo.file);

    // Upload da logo -> retorno URL
    const responseLogo = await api.patch('oneclick/upload-image', formDataLogo, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });

    const formData = new FormData();
    formData.append("docx", sharedState.uploadDocument.file);

    const queryParams = new URLSearchParams({
      document_type: sharedState.documentType,
      logo_url: responseLogo.data.image_url,
      main_color: sharedState.colorDefault,
      ux_writing: uxWriting ? 'true' : 'false',
    }).toString();

    try {
      const responseFiles = await api.patch(`oneclick?${queryParams}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
      if (responseFiles.data && responseFiles.status === 200) {
        nextStep();
        setSharedState(INITIAL_STATE);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingOneClick(prevState => !prevState);
    }
  };

  useEffect(() => {
    handleSelectOptionsFromForm({
      etapa: '3',
      user_id: data.user.id,
      ux_or_normal: uxWriting,
    });
  }, [uxWriting]);

  useEffect(() => {
    setActiveStep(1);
    setSharedState(INITIAL_STATE);
  }, [open]);

  return (
    <OneClickContext.Provider value={{ sharedState, setSharedState }}>
      <Dialog
        maxWidth='md'
        open={open && activeStep === 1}
        style={{
          overflow: "hidden",
          zIndex: 100,
        }}
        PaperProps={{
          style: { overflowY: "auto", width: "800px", height: "560px", display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }
        }}
      >
        <Box component={'div'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h5" fontWeight={'bolder'}>
            Crie o seu documento em Legal Design com IA
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box component={'div'} display={'flex'} flexDirection={'column'} style={{ gap: '10px' }}>
          <Box component={'div'} display={'flex'} alignItems={'center'} flexDirection={'row'} style={{ gap: '12px' }}>
            <Box component={'div'}>
              <input
                type="file"
                accept=".docx"
                onChange={handleFileChangeDocument}
                ref={fileInputRefDocument}
                style={{ display: "none" }}
              />
              <Button colorScheme='purple' onClick={handleClickUploadDocument}>{sharedState.uploadDocument.file ? 'Escolher outro documento' : 'Importar documento'}</Button>
            </Box>
            <Box component={'div'}>
              {sharedState.uploadDocument.file && (
                <Box component={'div'} display={'flex'} alignItems={'center'}>
                  <img src={iconWord} style={{ paddingRight: '5px' }} />
                  <Typography variant="h6">{sharedState.uploadDocument.name}</Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box component={'div'} display={'flex'}>
            <Alert severity="error" style={{ width: '100%', alignItems: 'center', fontSize: '12px', color: '#212121', background: '#fff', border: '1px solid #DE2D2D' }}>
              Obrigatório. Somente arquivos em formato Word com formato .docx
            </Alert>
          </Box>
        </Box>
        <Divider />
        <SelectBox>
          <Box component={'label'}>Tipo de documento</Box>
          <select
            value={sharedState.documentType}
            onChange={(e) => {
              handleSelectOptionsFromForm({
                etapa: '2',
                user_id: data.user.id,
                type_document: e.target.value
              });
              setSharedState((prevState) => ({
                ...prevState,
                documentType: e.target.value
              }));
            }}
          >
            {typeDocumentsData.map((option, index) => {
              return (
                <Item
                  key={index}
                  value={option.value}
                  active={option.value === sharedState.documentType}
                >
                  {option.label}
                </Item>
              );
            })}
          </select>
        </SelectBox>
        <Divider />
        <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} style={{ gap: '12px' }}>
          <Box component={'div'} display={'flex'} flexDirection={'column'} style={{ gap: '10px' }}>
            <InputRadio
              text="Remover juridiquês"
              onChange={() => setUxWriting(prevState => !prevState)}
              checked={uxWriting}
            />
            <InputRadio
              text="Texto original"
              onChange={() => setUxWriting(prevState => !prevState)}
              checked={!uxWriting}
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <BoxContainer>
            <ButtonColor
              style={{ height: "40px", width: "40px" }}
              colorsDefault={VectorOfColors}
              addNewColor={(color) => {
                handleSelectOptionsFromForm({
                  etapa: '4',
                  user_id: data.user.id,
                  color: true,
                });
                setSharedState((prevState) => ({
                  ...prevState,
                  colorDefault: color
                }));
              }}
              newColor={sharedState.colorDefault}
              isFavoriteColor={false}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              colorsSelectVector={colorsSelectVector}
              handleColorPickerClick={() => {
                setIsColorPickerOpen((prev) => !prev);
              }}
              setIsDisplayColorPicker={handleSetIsDisplayColorPicker}
              isDisplayColorPicker={isColorPickerOpen}
              handleChange={(color: any) => {
                setSharedState((prevState) => ({
                  ...prevState,
                  colorDefault: color
                }));
              }}
              rightPosition={32}
              topPosition={-5}
              randomColor={false}
              handleInputChange={handleInputChange}
              blockKeyboard={false}
              setBlockKeyboard={() => { }} // TODO: fix this
              positionLeftPopover={-225}
              positionTopPopover={-85}
              page="oneclick"
            />
            <Box component={'div'} display={'flex'} flexDirection={'column'}>
              <Typography variant="h5" sx={{
                fontSize: '14px',
                color: '#212121'
              }}>Cor principal</Typography>
              <Typography variant="subtitle1" sx={{
                fontSize: '13px',
                color: '#212121',
              }}>(capa, títulos, ícones e formas)</Typography>
            </Box>
          </BoxContainer>
        </Box>
        <Divider />
        <Box component={'div'} display={'flex'} flexDirection={'column'} style={{ gap: '10px' }}>
          <Box component={'div'} display={'flex'} alignItems={'center'} flexDirection={'row'} style={{ gap: '12px' }}>
            <Box component={'div'}>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileChangeLogo}
                ref={fileInputRefLogo}
                style={{ display: "none" }}
              />
              <Button colorScheme='purple' onClick={handleClickUploadLogo}>{sharedState.uploadLogo.file ? 'Alterar logo' : 'Upload do seu logo'}</Button>
            </Box>
            <Box component={'div'}>
              {sharedState.uploadLogo.file && (
                <Box component={'div'} display={'flex'} alignItems={'center'}>
                  <img src={iconFile} style={{ paddingRight: '5px' }} />
                  <Typography variant="h6">{sharedState.uploadLogo.name}</Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box component={'div'} display={'flex'}>
            <Alert severity="error" style={{ width: '100%', alignItems: 'center', fontSize: '12px', color: '#212121', background: '#fff', border: '1px solid #DE2D2D' }}>
              Obrigatório. Somente arquivos em formato .png, .jpg ou .jpeg
            </Alert>
          </Box>
        </Box>
        <Divider />
        <Box component={'div'} display={'flex'} justifyContent={'space-between'} style={{ gap: '20px' }} alignItems={'center'}>
          <Button
            onClick={() => {
              handleSelectSendOrCancel({
                title: 'Cancelaram o fluxo',
                user_id: data.user.id,
                send: false,
              });
              handleClose();
            }}
            style={{
              padding: '13px 16px',
              color: '#7547A3',
              background: '#fff',
              border: '1px solid #7547A3'
            }}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="purple"
            style={{
              padding: '13px 16px'
            }}
            onClick={() => {
              nextStep();
              handleSelectSendOrCancel({
                title: 'Continuou o fluxo',
                user_id: data.user.id,
                send: true,
              });
            }}
            disabled={!enableButton}
          >
            Enviar
          </Button>
        </Box>
      </Dialog>
      <Dialog
        maxWidth="md"
        open={(activeStep === 2 || activeStep === 3)}
        style={{
          overflow: "hidden",
          zIndex: 100,
        }}
        PaperProps={{
          style: { overflowY: "auto", width: "700px", display: 'flex', flexDirection: 'column', gap: '16px', padding: '14px' }
        }}
      >
        <Box component={'div'} display={'flex'} flexDirection={'column'} style={{ gap: '6px' }} padding={'6px'}>
          <Box component={'div'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h5" fontWeight={'bolder'}>
              {activeStep === 2 ? `Confirmação da escolha do texto para ${uxWriting ? `Remover juridiquês?` : ' “Texto original”?'}` : 'Criando documento...'}
            </Typography>
            {activeStep === 2 && !isLoadingOneClick && (
              <IconButton
                aria-label="close"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Typography variant="h5">
            {activeStep === 2
              ? 'Tem certeza que você quer usar esse tipo de texto?'
              : 'O seu documento está sendo gerado, fique à vontade para usar o UX DOC livremente! Avisaremos por e-mail assim que ele estiver pronto.'}
          </Typography>
        </Box>
        {activeStep === 2 ? (
          <Box component={'div'} display={'flex'} justifyContent={'space-between'} style={{ gap: '20px' }} alignItems={'center'}>
            <Button
              disabled={isLoadingOneClick}
              onClick={() => {
                prevStep();
                handleSelectSendOrCancel({
                  title: 'Cancelou o envio',
                  user_id: data.user.id,
                  send: false,
                });
              }}
              style={{
                padding: '13px 16px',
                color: '#7547A3',
                background: '#fff',
                border: '1px solid #7547A3'
              }}
            >
              Não
            </Button>
            <Button
              colorScheme="purple"
              style={{
                padding: '13px 16px'
              }}
              onClick={() => {
                handleSelectSendOrCancel({
                  title: 'Envio da documentação',
                  user_id: data.user.id,
                  send: true,
                });
                onSubmitOneClick();
              }}
              disabled={isLoadingOneClick}
            >
              {isLoadingOneClick ? <PuffLoader
                color="#FFF"
                size="18px"
              /> : 'Sim'}
            </Button>
          </Box>
        ) : (
          <Button
            colorScheme="purple"
            style={{
              padding: '13px 16px'
            }}
            onClick={() => {
              handleClose()
              handleCloseModal({
                user_id: data.user.id,
                close: true,
              })
            }}
          >
            Fechar
          </Button>
        )}
      </Dialog>
    </OneClickContext.Provider>
  );
};
