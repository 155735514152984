/*
this file utils for control wich object is selected
then modify UI of tools bar of editor to selected object
*/

import Konva from "konva";
import { Shape, ShapeConfig } from "konva/lib/Shape";

interface WhichSelectedObjectProps {
  selectedObject: Konva.Shape;
  selectedObjects: Shape<ShapeConfig>[];
  setSelectedObject: React.Dispatch<React.SetStateAction<Konva.Shape>>;
  setWhichTypeElement: React.Dispatch<
    React.SetStateAction<
      | "Image"
      | "BlankPage"
      | "Default"
      | "MultipleItems"
      | "Text"
      | "RichTextEditor"
    >
  >;
  currentMultipleSelection: React.MutableRefObject<Konva.Transformer | null>;
  setSelectedObjects: React.Dispatch<
    React.SetStateAction<Shape<ShapeConfig>[]>
  >;
}

const whichSelectedObject = ({
  selectedObject,
  selectedObjects,
  setSelectedObject,
  setWhichTypeElement,
  currentMultipleSelection,
  setSelectedObjects
}: WhichSelectedObjectProps) => {
  // you can verify if user is selecting just one element
  if (selectedObjects.length === 1) {
    //if only one object in multiple selections then
    //reset nodes of group and selected objects for
    //fixing issues on multiple selections
    currentMultipleSelection.current?.setNodes([]);
    setSelectedObjects([]);
    if (selectedObjects[0]?.attrs?.object !== "richTextImage") {
      setSelectedObject(selectedObjects[0]);
    }
  }
  //but if nodes more length then active toolbar of
  //multiple selection type
  if (selectedObjects?.length > 1) {
    setWhichTypeElement("MultipleItems");
  }
  //if selected object unique so identify which
  //object and show toolbar UI for each component
  if (selectedObject) {
    if (
      selectedObject?.attrs?.object === "simpleText" ||
      selectedObject?.attrs?.object === "text"
    ) {
      setWhichTypeElement("Text");
    } else if (
      selectedObject?.attrs?.object === "rectangle" ||
      selectedObject?.attrs?.object === "circle" ||
      selectedObject?.attrs?.object === "triangle2" ||
      selectedObject?.attrs?.object === "triangleSVG" ||
      selectedObject?.attrs?.object === "starSVG" ||
      selectedObject?.attrs?.object === "square" ||
      selectedObject?.attrs?.object === "icon" ||
      selectedObject?.attrs?.object === "star" ||
      selectedObject?.attrs?.object === "simpleLine"
    ) {
      setWhichTypeElement("Default");
    } else if (selectedObject?.attrs?.object === "image") {
      setWhichTypeElement("Image");
    } else if (selectedObject?.attrs?.object === "dropImage") {
      setWhichTypeElement("Image");
    } else if (selectedObject?.attrs?.object === "richTextImage") {
      setWhichTypeElement("RichTextEditor");
    }
  } else {
    setWhichTypeElement("BlankPage");
  }
};

export { whichSelectedObject };
