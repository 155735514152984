import React, { useEffect } from "react";
import { format } from "date-fns-tz";
import ptBR from "date-fns/locale/pt-BR";
import {
  CardImage,
  CardInfos,
  Container,
  Heading,
  Text,
  SimpleItalicText,
  TextBlack,
  VStack,
  HStackStar,
  SpaceWrapper
} from "./styles";

import DropdownCheck from "../../../DropdownCheck";
import DropdownCheckMain from "../../../DropdownCheckMain";

import starIcon from "../../../../assets/star-icon.svg";

interface CardVersionProps {
  card?: string;
  date?: string;
  hours?: number | Date | null | undefined;
  version?: string;
  user?: string;
  description?: string;
  template?: any;
  idTemplateMain?: string;
  currentTemplateInfo?: any;
  thumbnailUrl?: string;
}

export const CardVersionMain: React.FC<CardVersionProps> = ({
  card,
  date,
  hours,
  version,
  user,
  description,
  template,
  idTemplateMain,
  currentTemplateInfo,
  thumbnailUrl
}) => {
  return (
    <Container style={{ paddingBottom: "2rem" }}>
      <CardImage srcImg={thumbnailUrl}></CardImage>

      <CardInfos>
        <VStack>
          <Heading>
            {date
              ? format(Date.parse(date), " dd/MM/yyyy' - ' HH':'mm", {
                  locale: ptBR,
                  timeZone: "America/Sao_Paulo"
                })
              : ""}
          </Heading>

          <SpaceWrapper>
            <HStackStar>
              <Text>{`Versão ${version}`}</Text>

              <img style={{ marginLeft: "0.5rem" }} src={starIcon} />
            </HStackStar>
            {/* <DropdownCheckMain
              idTemplateMain={idTemplateMain}
              dataTemplate={template}
              info={currentTemplateInfo}
            /> */}
          </SpaceWrapper>
          <SimpleItalicText>
            {`Criado por: ${user ? user : ""}`}
          </SimpleItalicText>
        </VStack>
        <TextBlack></TextBlack>
      </CardInfos>
    </Container>
  );
};
