import ModalRegisterSubscription from "../ModalRegisterSubscriber";
import ModalImportDocument from "../ModalImportDocument";
import ModalDeleteDocument from "../ModalDeleteDocument";
import { Modal } from "@material-ui/core";
import { IModalSign } from "../../../../../dtos/signedProps";

interface IControllerModalSignProps {
  setOpenModal: React.Dispatch<React.SetStateAction<IModalSign>>;
  openModal: IModalSign;
  screenNowSignature: string | undefined;
}

const ControllerModalSign = ({
  setOpenModal,
  openModal,
  screenNowSignature
}: IControllerModalSignProps) => {
  return (
    <Modal
      open={openModal.isOpen}
      children={
        <div style={{ width: "100%", height: "100%" }}>
          {openModal.modalName === "Delete document" && (
            <ModalDeleteDocument
              setOpenModal={setOpenModal}
              screenNowSignature={screenNowSignature}
            />
          )}

          {openModal.modalName === "Import document" && (
            <ModalImportDocument setOpenModal={setOpenModal} />
          )}

          {openModal.modalName === "Subscriber registration" && (
            <ModalRegisterSubscription setOpenModal={setOpenModal} />
          )}
        </div>
      }
    />
  );
};

export default ControllerModalSign;
