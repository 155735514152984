/*
main hook - our main hook contains objects e values most importants
of app, such as the main state object of editor, stage refs of konva
library, storage of details that user, name of current document
also document name and history changes in document, to control
undo and redo for example (history)
*/

import Konva from "konva";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  DragEvent,
} from "react";
import cloneDeep from "lodash/cloneDeep";
import { PagesProps } from "../dtos/PagesProps";
import api from "../services/api";
import { AllMyProjects } from "../dtos/AllMyProjects";
import { toast } from "react-toastify";
import { useAuth } from "./auth";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { queryClient } from "../App";

//set up interfaces
interface IHistory {
  currentStep: number;
  step: PagesProps[][];
}

interface UserAlreadyUsed {
  idUser: string;
  isActive: boolean;
}

interface MyProfile {
  user: {
    id: string;
    people_id: string;
    email: string;
    avatar: string;
    is_admin: boolean;
    is_blocked: boolean;
    user_type: string;
    created_at: string;
    updated_at: string;
    avatar_url: string;
  };
  people: {
    id: string;
    address_id: string;
    contact_id: string;
    first_name: string;
    last_name: string;
    born_at: null | string;
    gender: null | string;
    cpf: null | string;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  addresses: {
    id: string;
    zipcode: null | string;
    street: null | string;
    number: null | string;
    district: null | string;
    city: null | string;
    state: null | string;
    country: null | string;
    is_finished: false;
    created_at: string;
    updated_at: string;
  };
  contacts: {
    id: string;
    phone: string;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  is_finished_profile: boolean;
}

interface MainhookProviderProps {
  children: ReactNode;
}

interface MainContextData {
  addToRefs: (element: Konva.Stage) => void;
  currentTemplateInfo: React.MutableRefObject<AllMyProjects>;
  objectScreen: PagesProps[];
  stageRef: React.MutableRefObject<Konva.Stage[]>;
  setObjectScreen: React.Dispatch<React.SetStateAction<PagesProps[]>>;
  dragUrl:
    | React.MutableRefObject<string | DragEvent<HTMLImageElement> | EventTarget>
    | any;
  history: IHistory;
  setHistory: React.Dispatch<React.SetStateAction<IHistory>>;
  addToHistory: (isLastHistory?: boolean) => void;
  isUpLowerCase: boolean;
  setIsUpLowerCase: React.Dispatch<React.SetStateAction<boolean>>;
  documentName: React.MutableRefObject<string>;
  clearHistory: () => void;
  srcAvatar: string;
  setSrcAvatar: React.Dispatch<React.SetStateAction<string>>;
  refreshAvatar: boolean;
  setRefreshAvatar: React.Dispatch<React.SetStateAction<boolean>>;
  myProfileId: string;
  profile: MyProfile;
  isModalCreateClause: boolean;
  setIsModalCreateClause: React.Dispatch<React.SetStateAction<boolean>>;
  templateIsLoaded: boolean;
  setTemplateIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  nameOfCurrentUser?: React.MutableRefObject<string>;
  divRefModal: React.MutableRefObject<HTMLDivElement>;
  divRefModalDecline: React.MutableRefObject<HTMLDivElement>;
  handleOpenModalDifferences: (type: "Open" | "Close") => void;
  handleOpenModalDifferencesDecline: (type: "Open" | "Close") => void;
  whichOptionForModal: React.MutableRefObject<"Accept" | "Decline">;
  isModalBlockFunctionality: boolean;
  setIsModalBlockFuncionality: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenModalBlockFuncionalities: (
    sourceModal: "download" | "automatizing" | "shared" | "disableEditions"
  ) => void;
  handleCloseModalBlockFuncionalities: () => void;
  sourceModalBlock: "download" | "automatizing" | "shared" | "disableEditions";
  setSourceModalBlock: React.Dispatch<
    React.SetStateAction<"download" | "automatizing">
  >;
  strokeWidth?: React.MutableRefObject<string>;
  createdSharedDocument?: boolean;
  setCreatedSharedDocument?: React.Dispatch<React.SetStateAction<boolean>>;
  canvasIsLoading?: boolean;
  setCanvasIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  isTeamTemplate: boolean;
  setIsTeamTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  firstLoading: boolean;
  setFirstLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isVisibleClauseText: boolean;
  setIsVisibleClauseText: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseViewTextClause: () => void;
  textClauseView: string;
  setTextClauseView: React.Dispatch<React.SetStateAction<string>>;
  isTeamClause: boolean;
  setIsTeamClause: React.Dispatch<React.SetStateAction<boolean>>;
  saveModalVisible: boolean;
  setSaveModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  allMyProjects: AllMyProjects[];
  setAllMyProjects: React.Dispatch<React.SetStateAction<AllMyProjects[]>>;
  removeDocument: UseMutationResult<any, unknown, void, unknown>;
  documentRemoveId: string;
  setDocumentRemoveId: React.Dispatch<React.SetStateAction<string>>;
  copyDocument: boolean;
  setCopyDocument: React.Dispatch<React.SetStateAction<boolean>>;
  hasGroupShared: React.MutableRefObject<boolean>;
  isActiveControlOfEditions: boolean;
  setIsActiveControlOfEditions: React.Dispatch<React.SetStateAction<boolean>>;
  isModalDeleteAutomation: boolean;
  setIsModalDeleteAutomation: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteAutomationById: (id: string) => Promise<void>;
  idAutomationToDelete: string;
  setIdAutomationToDelete: React.Dispatch<React.SetStateAction<string>>;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  nameDocumentDelete: string;
  setNameDocumentDelete: React.Dispatch<React.SetStateAction<string>>;
  ctrlPressed: boolean;
  autoNumber: {
    isActive: boolean;
    position: number | "center" | "right";
    firstPage: boolean;
  };
  setAutoNumber: React.Dispatch<
    React.SetStateAction<{
      isActive: boolean;
      position: number | "center" | "right";
      firstPage: boolean;
    }>
  >;
  isOffline: boolean;
  lastSaveDate: string;
  setLastSaveDate: React.Dispatch<React.SetStateAction<string>>;
  isLoadingSavingTemplate: boolean;
  setIsLoadingSavingTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  isIntentBuyModal?: boolean;
  setIsIntentBuyModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleRequestModal: () => void;
  modalProviderIsOpen: boolean;
  dependencyChange: boolean;
  setDependencyChange: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingModal: boolean;
  setIsLoadingModal: React.Dispatch<React.SetStateAction<boolean>>;
  pendingSave: React.MutableRefObject<boolean>;
  loadingDebounce: boolean;
  debouncedSearchTerm: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  search: string;
  setSearchInTemplate: React.Dispatch<React.SetStateAction<string>>;
  searchInTemplate: string;
  debouncedSearchInTemplate: string;
  setDebouncedSearchInTemplate: React.Dispatch<React.SetStateAction<string>>;
  setSearchInAssignature: React.Dispatch<React.SetStateAction<string>>;
  searchInAssignature: string;
  debouncedSearchInAssignature: string;
  setDebouncedSearchInAssignature: React.Dispatch<React.SetStateAction<string>>;
  setDebouncedSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  /* handleSearchText: (event: React.ChangeEvent<HTMLInputElement>) => void; */
  setLoadingDebounce: React.Dispatch<React.SetStateAction<boolean>>;
  isDraggingObject: boolean;
  setIsDraggingObject: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IAutoNumber {
  isActive: boolean;
  position: "center" | "right";
  firstPage: boolean;
}

//creating context api of react js
const MainContext = createContext<MainContextData>({} as MainContextData);

const MainHookProvider: React.FC<MainhookProviderProps> = ({ children }) => {
  //States used in the Editor.
  const pendingSave = useRef(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isUpLowerCase, setIsUpLowerCase] = useState<boolean>(false);
  const [isLoadingSavingTemplate, setIsLoadingSavingTemplate] = useState(false);
  const [autoNumber, setAutoNumber] = useState<IAutoNumber>({
    isActive: false,
    position: "right",
    firstPage: true,
  });
  const [canvasIsLoading, setCanvasIsLoading] = useState(false);
  const [isTeamTemplate, setIsTeamTemplate] = useState(false);
  const [textClauseView, setTextClauseView] = useState("");
  const [isTeamClause, setIsTeamClause] = useState(false);
  const [isVisibleClauseText, setIsVisibleClauseText] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [isModalBlockFunctionality, setIsModalBlockFuncionality] =
    useState(false);
  const [templateIsLoaded, setTemplateIsLoaded] = useState(true);
  const [sourceModalBlock, setSourceModalBlock] = useState<
    "download" | "automatizing" | "shared" | "disableEditions"
  >(null);
  const [myProfileId, setMyProfileId] = useState("");
  const [isOffline, setIsOffline] = useState(false);
  const [lastSaveDate, setLastSaveDate] = useState("");
  const [history, setHistory] = useState<IHistory>({
    currentStep: 0,
    step: [],
  });
  const [isActiveControlOfEditions, setIsActiveControlOfEditions] =
    useState(false);
  const [ctrlPressed, setCtrlPressed] = useState(false);

  //States used in the Dashboard.
  const [isModalCreateClause, setIsModalCreateClause] = useState(false);
  const [idAutomationToDelete, setIdAutomationToDelete] = useState("");
  const [allMyProjects, setAllMyProjects] = useState<AllMyProjects[]>([]);
  const [firstLoading, setFirstLoading] = useState(false);
  const [nameDocumentDelete, setNameDocumentDelete] = useState("");
  const [profile, setProfile] = useState({} as MyProfile);
  const [isModalDeleteAutomation, setIsModalDeleteAutomation] = useState(false);
  const [copyDocument, setCopyDocument] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [documentRemoveId, setDocumentRemoveId] = useState("");
  const [modalProviderIsOpen, setModalProviderIsOpen] = useState(false);
  const [loadingDebounce, setLoadingDebounce] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [searchInTemplate, setSearchInTemplate] = useState("");
  const [debouncedSearchInTemplate, setDebouncedSearchInTemplate] = useState("");
  const [searchInAssignature, setSearchInAssignature] = useState("");
  const [debouncedSearchInAssignature, setDebouncedSearchInAssignature] = useState("");

  //States used in the Dashboard and Editor.
  const [objectScreen, setObjectScreen] = useState<PagesProps[]>([]);
  const [createdSharedDocument, setCreatedSharedDocument] = useState(false);
  const [isIntentBuyModal, setIsIntentBuyModal] = useState(false);
  const [srcAvatar, setSrcAvatar] = useState("");
  const [refreshAvatar, setRefreshAvatar] = useState(false);

  const [dependencyChange, setDependencyChange] = useState(false);

  //Refs used in the Editor
  const hasGroupShared = useRef(false);
  const strokeWidth = useRef(null);
  const divRefModal = useRef<HTMLDivElement>(null);
  const divRefModalDecline = useRef<HTMLDivElement>(null);
  const dragUrl = useRef(null);
  const whichOptionForModal = useRef<"Accept" | "Decline">("Accept");
  const stageRef = useRef<Konva.Stage[]>(null);
  stageRef.current = [];

  const [isDraggingObject, setIsDraggingObject] = useState(false);

  //Refs used in the Dashboard and Editor
  const nameOfCurrentUser = useRef("");
  const currentTemplateInfo = useRef(null);
  const documentName = useRef("");

  //get profile user and update avatar
  // image and main object state
  const { data } = useAuth();
  useEffect(() => {
    const autoLoad = async () => {
      try {
        const responseProfile = await api.get("profile");
        setSrcAvatar(responseProfile?.data?.user?.avatar_url);
        setMyProfileId(responseProfile?.data?.user?.id);
        setProfile(responseProfile?.data);
      } catch (err) {
        console.error(err, "error when get profile");
      }
    };

    !!data && autoLoad();
  }, [refreshAvatar]);

  //everytime when some propery of user avatar change
  //get name that user
  useEffect(() => {
    (async () => {
      if (!!data) {
        try {
          const requestProfile = await api.get("profile");
          nameOfCurrentUser.current = `${requestProfile.data.people.first_name} ${requestProfile.data.people.last_name}`;
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }, [refreshAvatar]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  //Check if the user is offline
  useEffect(() => {
    const handleOffline = () => {
      setIsOffline(true);
    };

    const handleOnline = () => {
      setIsOffline(false);
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    // Função de limpeza
    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  /* //Debounce responsible for keeping the user completing the search word
  useEffect(() => {
    setLoadingDebounce(true);
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setLoadingDebounce(false);
    }, 900);
    return () => {
      clearTimeout(delayDebounce);
    };
  }, [search]); */

  const removeDocument = useMutation({
    mutationFn: async () => {
      const respose = await api.delete(`user-templates/${documentRemoveId}`);
      return respose;
    },
    onSuccess: () => {
      queryClient.refetchQueries("myProjects");

      //Temporary, as React Query has not been added to the One-Click page.
      setRefresh(!refresh);
    },
    onError: (err) => {
      toast.error("Error ao deletar projeto");
      console.error("error trying to delete document", err);
    },
    onSettled: (data) => {
      setDocumentRemoveId("");
      resetRemoveDocument();
    },
  });

  //Responsible for clearing the data from the removeDocument function after it is completed.
  const resetRemoveDocument = useCallback(() => {
    removeDocument.reset();
  }, [removeDocument]);

  const handleDeleteAutomationById = useCallback(
    async (id: string) => {
      try {
        const responseDelete = await api.delete(`user-templates/${id}`);
        setRefresh((prevState) => !prevState);
        toast.success("Automação excluida com sucesso.");
        setIsModalDeleteAutomation(false);
        setIdAutomationToDelete("");
      } catch (err) {
        setIdAutomationToDelete("");
        console.error(err, "error when delete automation");
        toast.error("Algo deu errado!");
      }
    },
    [setIsModalDeleteAutomation, setRefresh]
  );

  // modal for control when should be
  // block some funcionalities for any reason.
  const handleOpenModalBlockFuncionalities = useCallback(
    (
      sourceModal: "download" | "automatizing" | "shared" | "disableEditions"
    ) => {
      switch (sourceModal) {
        //yser will be download document?
        //show warning and alert about that
        //exists modifications on your document.
        //and exist group of shared users
        case "download":
          setIsModalBlockFuncionality(true);
          setSourceModalBlock("download");
          break;

        case "automatizing":
          setIsModalBlockFuncionality(true);
          setSourceModalBlock("automatizing");
          break;

        case "shared":
          setIsModalBlockFuncionality(true);
          setSourceModalBlock("shared");
          break;

        case "disableEditions":
          setIsModalBlockFuncionality(true);
          setSourceModalBlock("disableEditions");
          break;

        default:
          break;
      }
    },
    []
  );

  //when handle close modal to block some features because
  //have pending information in document to review for
  //other user of your group users shared
  const handleCloseModalBlockFuncionalities = useCallback(() => {
    setIsModalBlockFuncionality(false);
  }, []);

  //access ref to open modal and set visible modal
  //when this will be accept option only
  const handleOpenModalDifferences = useCallback(
    (type: "Open" | "Close") => {
      if (type === "Open") {
        divRefModal.current.style.visibility = "visible";
      }

      if (type === "Close") {
        divRefModal.current.style.visibility = "hidden";
      }
    },
    [divRefModal]
  );

  //access ref to open modal and set visible modal
  //when this will be decline option only
  const handleOpenModalDifferencesDecline = useCallback(
    (type: "Open" | "Close") => {
      if (type === "Open") {
        divRefModalDecline.current.style.visibility = "visible";
      }

      if (type === "Close") {
        divRefModalDecline.current.style.visibility = "hidden";
      }
    },
    [divRefModalDecline]
  );

  //most importants refs of stage canvas (Konva)
  //for each page of state create new ref and push him
  //each ref contains your own config and only verify
  //if not exists then concate into do main scope
  // NOTE: ref => stageRef => [stage1.current, stage2.current]
  const addToRefs = useCallback(
    (el) => {
      if (el && !stageRef.current.includes(el)) {
        stageRef.current.push(el);
      }
    },
    [stageRef]
  );

  //this callback must be requested
  // whenever something changes in our main state,
  // sure, be spread throughout the code,
  // to store and make the document design steps
  const addToHistory = useCallback(
    (isLastHistory: boolean) => {
      let formatedObjectToApi: PagesProps[] = cloneDeep(objectScreen);

      setHistory((prevState) => {
        let cloneState = cloneDeep(prevState);
        // If the history is already at the limit of 10 steps, remove the first step
        if (cloneState.step.length > 10) {
          cloneState.step.shift();
        }

        if (cloneState.currentStep === cloneState.step.length) {
          // If the current step is at the last step of the history
          if (!isLastHistory) {
            cloneState.currentStep += 1; // Increase the current step counter if it's not the last step
          }
          cloneState.step.push(formatedObjectToApi);
        } else {
          // If the current step is behind the last step of the history
          // Cut off steps ahead of the current step
          const slicedArray = cloneState.step.slice(
            0,
            cloneState.currentStep + 1
          );
          cloneState.currentStep = slicedArray.length;
          cloneState.step = cloneDeep(slicedArray);
          cloneState.step.push(formatedObjectToApi);
        }

        return cloneState;
      });
    },
    [objectScreen]
  );

  //case necessary restore all steps and array of steps
  const clearHistory = useCallback(() => {
    setHistory({ currentStep: 0, step: [] });
  }, []);

  const handleCloseViewTextClause = useCallback(() => {
    setIsVisibleClauseText(false);
  }, [setIsVisibleClauseText]);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === "Control") {
      setCtrlPressed(true);
    }
  }, []);

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (event.key === "Control") {
      setCtrlPressed(false);
    }
  }, []);

  const handleRequestModal = useCallback(() => {
    setModalProviderIsOpen((prevState) => !prevState);
  }, [setModalProviderIsOpen]);

  /* //event listener for every time our user
  //do a new search on documents templates
  const handleSearchText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  ); */

  return (
    <MainContext.Provider
      value={{
        loadingDebounce,
        debouncedSearchTerm,
        setSearch,
        search,
        setSearchInTemplate,
        searchInTemplate,
        setSearchInAssignature,
        searchInAssignature,
        debouncedSearchInTemplate,
        setDebouncedSearchInTemplate,
        debouncedSearchInAssignature,
        setDebouncedSearchInAssignature,
        addToRefs,
        addToHistory,
        currentTemplateInfo,
        objectScreen,
        stageRef,
        dragUrl,
        history,
        setObjectScreen,
        setHistory,
        isUpLowerCase,
        setIsUpLowerCase,
        documentName,
        clearHistory,
        srcAvatar,
        setSrcAvatar,
        refreshAvatar,
        setRefreshAvatar,
        myProfileId,
        profile,
        isModalCreateClause,
        setIsModalCreateClause,
        templateIsLoaded,
        setTemplateIsLoaded,
        nameOfCurrentUser,
        divRefModal,
        handleOpenModalDifferences,
        whichOptionForModal,
        divRefModalDecline,
        handleOpenModalDifferencesDecline,
        isModalBlockFunctionality,
        setIsModalBlockFuncionality,
        handleCloseModalBlockFuncionalities,
        handleOpenModalBlockFuncionalities,
        sourceModalBlock,
        setSourceModalBlock,
        strokeWidth,
        createdSharedDocument,
        setCreatedSharedDocument,
        canvasIsLoading,
        setCanvasIsLoading,
        isTeamTemplate,
        setIsTeamTemplate,
        firstLoading,
        setFirstLoading,
        isVisibleClauseText,
        setIsVisibleClauseText,
        handleCloseViewTextClause,
        textClauseView,
        setTextClauseView,
        isTeamClause,
        setIsTeamClause,
        saveModalVisible,
        setSaveModalVisible,
        allMyProjects,
        setAllMyProjects,
        removeDocument,
        documentRemoveId,
        setDocumentRemoveId,
        copyDocument,
        setCopyDocument,
        hasGroupShared,
        isActiveControlOfEditions,
        setIsActiveControlOfEditions,
        isModalDeleteAutomation,
        setIsModalDeleteAutomation,
        handleDeleteAutomationById,
        idAutomationToDelete,
        setIdAutomationToDelete,
        refresh,
        setRefresh,
        nameDocumentDelete,
        setNameDocumentDelete,
        ctrlPressed,
        autoNumber,
        setAutoNumber,
        isOffline,
        lastSaveDate,
        setLastSaveDate,
        isLoadingSavingTemplate,
        setIsLoadingSavingTemplate,
        handleRequestModal,
        modalProviderIsOpen,
        isIntentBuyModal,
        setIsIntentBuyModal,
        dependencyChange,
        setDependencyChange,
        isLoadingModal,
        setIsLoadingModal,
        pendingSave,
        setDebouncedSearchTerm,
        /* handleSearchText, */
        setLoadingDebounce,
        isDraggingObject,
        setIsDraggingObject,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

// creating hook

function useMainHook(): MainContextData {
  const context = useContext(MainContext);

  if (!context) {
    throw new Error("useAuth must be used with an MainHookProvider");
  }

  return context;
}

export { MainHookProvider, useMainHook };
