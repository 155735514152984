import React, {
  HTMLProps,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import { DiffControlView, FlexRight, HStack, MidBox, Stack } from "./styles";
import { useParams } from "react-router-dom";
import api from "../../../../../../../../services/api";
import { toast } from "react-toastify";
import { useMainHook } from "../../../../../../../../hooks/main";
import ReactLoading from "react-loading";
import { useDiff } from "../../../../../../hooks/diffDocuments";
import { useTextsEdition } from "../../../../../../hooks/textsEdition";
// import checkIcon from "./assets/check.svg";

interface FontFamilyMenuProps {
  onClose?: () => void;
  onCloseDiffMenu?: any;
  isDiffMenu?: any;
  refMenu?: any;
  // setBlockClose: React.Dispatch<React.SetStateAction<boolean>>;
  callback: (size: string) => void;
  isCurrentValue?: string;
  setIsDiffActive?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DiffMenu: React.FC<FontFamilyMenuProps> = ({
  callback,
  onCloseDiffMenu,
  isDiffMenu,
  refMenu,
  setIsDiffActive
}) => {
  const [activeDiffs, setActiveDiffs] = useState<"enable" | "disable">(
    "enable"
  );

  const [loading, setLoading] = useState(true);
  const { handleOpenModalBlockFuncionalities } = useMainHook();
  const { templateid } = useParams();
  const { setTemplateStartedShared } = useDiff();

  const { hasGroupShared, objectScreen, setObjectScreen } = useMainHook();
  const { quillRefComponent } = useTextsEdition();
  const handleChangeDiffDoc = useCallback(
    async (status: "enable" | "disable") => {
      let changes = false;

      const quill = quillRefComponent.current.getEditor();
      const fullText = quill.getText();

      let hasTextWithColor = false;

      for (let i = 0; i < fullText.length; i++) {
        const charFormat = quill.getFormat(i);
        if (charFormat && charFormat.color === "#de2d2d") {
          hasTextWithColor = true;
          break; // Exit the loop if we find a character with the specified color
        }

        if (charFormat && charFormat.color === "#9945ee") {
          hasTextWithColor = true;
          break; // Exit the loop if we find a character with the specified color
        }
      }

      if (hasTextWithColor) {
        changes = true;
      }
      objectScreen.forEach((page, index) => {
        page.renderObjects.forEach((renderedObject, index) => {
          if (renderedObject.text && !changes) {
            if (renderedObject.isModify) {
              changes = true;
            } else {
              changes = false;
            }
          }
        });
      });

      if (changes) {
        handleOpenModalBlockFuncionalities("disableEditions");
      } else {
        try {
          setIsDiffActive(status === "enable" ? true : false);
          setActiveDiffs(status);

          if (status === "enable") {
            setTemplateStartedShared(true);
          }

          const changeTrackingStatus = await api.put("user-share-template", {
            user_template_id: templateid,
            is_change_tracking_enabled: status === "enable" ? true : false
          });

          if (changeTrackingStatus.status === 200) {
            if (status === "enable") {
              hasGroupShared.current = true;
              toast.success("Controle de textos ativado.");
            } else {
              hasGroupShared.current = false;
              toast.success("Controle de textos desativado.");
            }
          }
        } catch (err) {
          console.log(err + "erro changes tracking ");
        }
      }
    },
    [
      templateid,
      hasGroupShared,
      setIsDiffActive,
      setTemplateStartedShared,
      objectScreen,
      quillRefComponent,
      handleOpenModalBlockFuncionalities
    ]
  );

  useEffect(() => {
    (async () => {
      try {
        const existsDiffsOnDoc = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );

        const isEnable = existsDiffsOnDoc.data.is_change_tracking_enabled;
        let defineDiff: "enable" | "disable" = isEnable ? "enable" : "disable";
        setActiveDiffs(defineDiff);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err, "error response control of diffs");
      }
    })();
  }, [templateid]);

  return (
    <>
      <DiffControlView ref={refMenu}>
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <ReactLoading type="spin" color="#000" height={16} width={16} />
          </div>
        ) : (
          <>
            <p>Controle de alterações</p>
            <Stack>
              <MidBox>
                <input
                  value={"ativar"}
                  checked={activeDiffs === "enable"}
                  type="radio"
                  name="Ativar"
                  onClick={() => {
                    handleChangeDiffDoc("enable");
                    onCloseDiffMenu();
                  }}
                />{" "}
                <p className="activate-p">Ativar</p>
              </MidBox>
              <MidBox>
                <input
                  value={"disable"}
                  checked={activeDiffs === "disable"}
                  type="radio"
                  name="desactive"
                  onClick={() => handleChangeDiffDoc("disable")}
                />{" "}
                <p className="activate-p">Desativar</p>
              </MidBox>
            </Stack>
          </>
        )}
      </DiffControlView>
    </>
  );
};

export default DiffMenu;
