import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMainHook } from "../../../hooks/main";
import { useHeaderEditor } from "./headerEditor";
import { cloneDeep } from "lodash";
import api from "../../../services/api";
import * as Quill from "quill";
import { useTextsEdition } from "./textsEdition";
import Delta from "quill-delta/lib/delta";
import { useSelection } from "./selection";
import ReactQuill from "react-quill";
import html2canvas from "html2canvas";
import { usePagesEditor } from "./pagesEditor";
interface DiffContext {
  children: ReactNode;
}

interface SelectionProps {
  index: number;
  length: number;
}

interface UsersSharedGroupProps {
  user_template_id?: string;
  is_change_tracking_enabled: boolean;
  users?: [
    {
      id: string;
      name: string;
      email: string;
      avatar_url: string;
      permissions: string;
    }
  ];
}

interface TextDiffDataProps {
  templateStartedShared: boolean;
  setTemplateStartedShared: React.Dispatch<React.SetStateAction<boolean>>;
  oldContentsState: null | Quill.DeltaStatic;
  setOldContentsState: null | React.Dispatch<
    React.SetStateAction<Quill.DeltaStatic>
  >;
  newContentsState: null | Quill.DeltaStatic;
  setNewContentsState: null | React.Dispatch<
    React.SetStateAction<Quill.DeltaStatic>
  >;
  selectionCursor: SelectionProps;
  setSelectionCursor: React.Dispatch<React.SetStateAction<SelectionProps>>;
  findDiff: () => void;
  buttonRef: React.MutableRefObject<HTMLButtonElement>;
  isEditingQuill: boolean;
  setIsEditingQuill: React.Dispatch<React.SetStateAction<boolean>>;
  newDelta: null | Quill.DeltaStatic;
  setNewDelta: React.Dispatch<React.SetStateAction<Quill.DeltaStatic>>;
  newChanges: string;
  setNewChanges: React.Dispatch<React.SetStateAction<string>>;
  oldContentsRef: null | React.MutableRefObject<Quill.DeltaStatic>;
  newContentsRef: null | React.MutableRefObject<Quill.DeltaStatic>;
  getQuillCursorRef: null | React.MutableRefObject<SelectionProps>;
  onBlurQuillText: () => void;
  isNewTextRef?: React.MutableRefObject<boolean>;
  isTypingQuillRef?: React.MutableRefObject<boolean>;
  // hasGroupShared?: React.MutableRefObject<boolean>;
  showButtons: boolean;
  setShowButtons: React.Dispatch<React.SetStateAction<boolean>>;
  handleDefineModifications: (whichAction: string) => void;
  whichUserEdited: React.MutableRefObject<string>;
  nameOfUserEdited: React.MutableRefObject<string>;
  interceptModifications: React.MutableRefObject<boolean>;
  removeBlurEffect: React.MutableRefObject<boolean>;
  removeBlur?: boolean;
  textIsChanged: React.MutableRefObject<boolean>;
  quillReComponent: React.MutableRefObject<ReactQuill>;
  handleDecline: () => void;
  blockRenderImage: React.MutableRefObject<boolean>;
  blockBackspace: React.MutableRefObject<boolean>;
  handlePasteQuill: (range: Quill.RangeStatic) => void;
  whichKeyPress: React.MutableRefObject<string>;
  selectionFormatText: React.MutableRefObject<Quill.StringMap>;
  updateIfGroup: boolean;
  setUpdateIfGroup: React.Dispatch<React.SetStateAction<boolean>>;
  loadingChecked: boolean;
  currentOwner: boolean;
  setCurrentOwner: React.Dispatch<React.SetStateAction<boolean>>;
  loadingGeneratingText: boolean;
  setLoadingGeneratingText: React.Dispatch<React.SetStateAction<boolean>>;
}

const DiffContext = createContext<TextDiffDataProps>({} as TextDiffDataProps);

const DiffDocumentsHookProvider: React.FC<DiffContext> = ({ children }) => {
  const { handleSaveTemplate } = useHeaderEditor();

  const { selectedObject, setSelectedObject } = useSelection();
  const {
    objectScreen,
    setObjectScreen,
    currentTemplateInfo,
    myProfileId,
    divRefModal,
    handleOpenModalDifferencesDecline,
    hasGroupShared,
    isActiveControlOfEditions,
    setIsActiveControlOfEditions,
  } = useMainHook();
  const { quillRef, quillRefComponent } = useTextsEdition();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [loadingGeneratingText, setLoadingGeneratingText] = useState(false);
  const [templateStartedShared, setTemplateStartedShared] = useState(false);
  const [oldContentsState, setOldContentsState] =
    useState<Quill.DeltaStatic>(null);
  const [newContentsState, setNewContentsState] =
    useState<Quill.DeltaStatic>(null);
  const [selectionCursor, setSelectionCursor] = useState<SelectionProps>({
    index: 0,
    length: 0,
  });

  //button for controll if button for accept or reject
  // will be visibility or not yet
  const [showButtons, setShowButtons] = useState(false);
  const [updateIfGroup, setUpdateIfGroup] = useState(false);
  const [isEditingQuill, setIsEditingQuill] = useState(false);

  const [newDelta, setNewDelta] = useState<Quill.DeltaStatic>(null);
  const [newChanges, setNewChanges] = useState("");
  const [removeBlur, setRemoveBlur] = useState(false);
  const [usersSharedGroup, setUsersSharedGroup] = useState(
    {} as UsersSharedGroupProps
  );
  const oldContentsRef = useRef<Quill.DeltaStatic>(null);
  const newContentsRef = useRef<Quill.DeltaStatic>(null);
  const isNewTextRef = useRef(false);

  const getQuillCursorRef = useRef<SelectionProps | Quill.RangeStatic>(null);
  const isTypingQuillRef = useRef(false);
  const idRichText = useRef("");
  const whichUserEdited = useRef("");
  const nameOfUserEdited = useRef("");
  const interceptModifications = useRef(false);
  const removeBlurEffect = useRef(false);
  const textIsChanged = useRef(false);
  const quillReComponent = useRef<ReactQuill>(null);
  const blockRenderImage = useRef(false);
  const blockBackspace = useRef(false);
  const beforeFormatsFontSize = useRef(null);
  const whichKeyPress = useRef("");
  const selectionFormatText = useRef<Quill.StringMap>(null);
  const [loadingChecked, setLoadingChecked] = useState(false);

  const [currentOwner, setCurrentOwner] = useState(false);

  const { idPage } = usePagesEditor();

  // useEffect(() => {
  //   if (quillRef.current) {
  //     let keyboard = quillRef.current.getEditor().getModule("keyboard");
  //     keyboard.bindings["Backspace"] = null;
  //     keyboard.bindings["8"] = null;
  //   }
  // }, [quillRef.current]);

  // const hasDifference = useRef(false);
  const isEqual = useRef(false);
  // let oldFormats: Quill.DeltaStatic;
  // let newFormats: Quill.DeltaStatic;

  const oldFormats = useRef<Quill.DeltaStatic>(null);
  const newFormats = useRef<Quill.DeltaStatic>(null);

  // //avoid bug of onBlur maximum stack
  // useEffect(() => {
  //   if (selectedObject?.attrs?.object === "richTextImage") {
  //     setRemoveBlur(false);
  //   } else {
  //     setRemoveBlur(true);
  //   }
  // }, [selectedObject]);

  //function for copy and paste
  //prevent bug of onBlur on quill js
  const handlePasteQuill = useCallback(
    (range: Quill.RangeStatic) => {
      navigator.clipboard.readText().then((clipText) => {
        quillRefComponent.current.getEditor().insertText(range.index, clipText);

        if (hasGroupShared.current) {
          let lengthText = clipText.length;
          quillRefComponent.current
            .getEditor()
            .formatText(range.index, lengthText, "color", "#9945ee");

          if (range.length > 1) {
            if (selectionFormatText.current.color === "#9945ee") {
              quillRefComponent.current
                .getEditor()
                .deleteText(range.index + lengthText, range.length);
            }

            quillRefComponent.current
              .getEditor()
              .formatText(
                range.index + lengthText,
                range.length,
                "color",
                "#de2d2d"
              );
          }
        }
      });
    },
    [quillRefComponent]
  );

  // //detect when open editor if that text was edited
  // useEffect(() => {
  //   if (quillRef.current) {
  //     oldFormats.current =
  //       selectedObject && new Delta(selectedObject?.attrs?.initialDelta);
  //     newFormats.current =
  //       selectedObject && new Delta(selectedObject?.attrs?.format);
  //   }

  //   if (oldFormats.current && newFormats.current) {
  //     // const test = arrayEquals(oldFormats?.ops, newFormats?.ops);
  //     //verify if we already have differences on richText
  //     isEqual.current =
  //       oldFormats.current?.ops?.length === newFormats.current?.ops?.length;

  //     if (hasGroupShared.current) {
  //       if (selectedObject?.attrs?.isSharedNewText) {
  //         if (
  //           selectedObject?.attrs?.idOfUserEdited !== myProfileId ||
  //           currentOwner
  //         ) {
  //           setShowButtons(true);
  //         } else {
  //           setShowButtons(false);
  //         }
  //       } else {
  //         if (selectedObject?.attrs?.isModify) {
  //           if (
  //             selectedObject?.attrs?.idOfUserEdited !== myProfileId ||
  //             currentOwner
  //           ) {
  //             setShowButtons(prevState => true);
  //           } else {
  //             setShowButtons(prevState => false);
  //           }
  //         } else {
  //           setShowButtons(prevState => false);
  //         }
  //       }
  //     } else {
  //       setShowButtons(false);
  //     }
  //   }
  // }, [selectedObject, quillRef.current, currentOwner]);

  //get template info
  let templateid = currentTemplateInfo?.current?.id;
  whichUserEdited.current = myProfileId;

  // for we catch if document was shared before or not yet
  useEffect(() => {
    (async () => {
      if (!!templateid && templateStartedShared) {
        setLoadingChecked(true);

        const belongsSharedGroup = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );
        setUsersSharedGroup(belongsSharedGroup.data);
        setLoadingChecked(false);
      }
    })();
  }, [templateStartedShared, selectedObject, updateIfGroup]);

  useEffect(() => {
    if (usersSharedGroup?.users) {
      setLoadingChecked(true);
      usersSharedGroup.users.forEach((user) => {
        if (user.id === myProfileId) {
          if (user.permissions === "Owner") {
            setCurrentOwner(true);
          }
        }
      });

      if (usersSharedGroup?.is_change_tracking_enabled) {
        //set up ref to true if exists more users these document
        hasGroupShared.current = true;
        setIsActiveControlOfEditions(true);
        setLoadingChecked(false);
      } else {
        hasGroupShared.current = false;
        setIsActiveControlOfEditions(false);
        setLoadingChecked(false);
      }
    }
  }, [usersSharedGroup, updateIfGroup, templateStartedShared]);

  //for user enter in document force update
  //for know if users shared group has already enable or disable
  //only on first enter of the page

  useEffect(() => {
    setTimeout(() => {
      setUpdateIfGroup((prevState) => !prevState);
    }, 4000);
  }, []);

  //onBlur get focus again and last position of cursor
  const onBlurQuillText = useCallback(() => {}, [
    quillRef,
    quillRefComponent,
    selectedObject,
    isTypingQuillRef,
    removeBlur,
  ]);

  const handleDecline = useCallback(async () => {
    blockRenderImage.current = true;
    let contentEdited: Quill.DeltaStatic = quillRef?.current
      ?.getEditor()
      ?.getContents();

    //here will edit the current contents
    contentEdited?.forEach((op) => {
      //change the text color purple for black
      if (op?.attributes?.color === "#9945ee") {
        op.attributes.color = "#000";
        op.attributes.underline = false;
      }
      //this logic to remove red and struckthrough text of delta
      if (op?.attributes?.underline) {
        op.insert = "";
      }
    });

    //apply effects
    quillReComponent.current.getEditor().root.style.width = `${myWidth.current}px`;
    quillReComponent?.current?.getEditor()?.setContents(contentEdited);

    try {
      const canvas = await html2canvas(
        quillReComponent.current.getEditor().root,
        {
          scale: 1,
          backgroundColor: "rgba(0,0,0,0)",
        }
      );

      let image = `${canvas.toDataURL()}`;

      setObjectScreen((oldState) => {
        let cloneState = cloneDeep(oldState);
        cloneState.forEach((object) => {
          object.renderObjects.forEach((renderedObject) => {
            if (renderedObject.id === idRichText.current) {
              renderedObject.idOfUserEdited = "";
              renderedObject.isModify = false;
              renderedObject.nameOfUserEdited = "";
              renderedObject.src = image;
              renderedObject.format = quillReComponent.current
                .getEditor()
                .getContents();
              renderedObject.initialDelta = quillReComponent.current
                .getEditor()
                .getContents();
              renderedObject.text = quillReComponent.current
                .getEditor()
                .getText();
            }
          });
        });

        return cloneState;
      });
    } catch (err) {
      console.error(err, "error when transform image");
    }
  }, [quillRef, setObjectScreen]);

  const handleDeleteElement = (id: string) => {
    setObjectScreen((oldState) => {
      let cloneState = cloneDeep(oldState);
      let newArray = cloneState[idPage - 1].renderObjects.filter(
        (renderedObject) => renderedObject.id !== id
      );
      cloneState[idPage - 1].renderObjects = newArray;
      return cloneState;
    });
  };

  const handleDefineModifications = useCallback(
    async (whichAction: "Accept" | "Decline") => {
      hasGroupShared.current = false;
      setLoadingGeneratingText(true);
      isNewTextRef.current = true;
      quillRefComponent.current.focus();
      interceptModifications.current = true;

      if (whichAction === "Accept") {
        let textContentsAfterAnyAction = quillRefComponent.current
          .getEditor()
          .getFormat();

        if (textContentsAfterAnyAction.size) {
          beforeFormatsFontSize.current = textContentsAfterAnyAction.size;
        }

        let contentEdited: Quill.DeltaStatic = quillRefComponent?.current
          ?.getEditor()
          ?.getContents();

        //here will edit the current contents
        // contentEdited?.forEach(op => {
        //   //change the text color purple for black
        //   if (op?.attributes?.color === "#9945ee") {
        //     op.attributes.color = "#000";
        //   }
        //   //this logic to remove red and struckthrough text of delta
        //   if (op?.attributes?.color === "#de2d2d") {
        //     op.insert = "";
        //   }
        // });

        contentEdited.forEach((op) => {
          if (op.attributes && op.attributes.color === "#9945ee") {
            op.attributes.color = "#000";
          }

          if (op.attributes && op.attributes.color === "#de2d2d") {
            op.insert = "";
          }
        });

        let newDelts = new Delta(contentEdited);

        if (newDelts) {
          //apply effects
          quillRefComponent?.current?.getEditor()?.setContents(newDelts);
        }

        let myText = quillRefComponent.current.getEditor().getText();
        let myTextLength = myText.length;

        if (myTextLength <= 1) {
          handleDeleteElement(selectedObject.attrs.id);
        }

        // dont forget edit state
        //change formats and initialdelta for main version
        // with unique reason to reset changes

        setObjectScreen((oldState) => {
          let cloneState = cloneDeep(oldState);

          cloneState.forEach((page) => {
            page.renderObjects.forEach((renderObject) => {
              if (renderObject.id === idRichText.current) {
                renderObject.initialDelta = quillRefComponent?.current
                  ?.getEditor()
                  .getContents();
                renderObject.format = quillRefComponent?.current
                  ?.getEditor()
                  .getContents();
                renderObject.isModify = false;
                renderObject.idOfUserEdited = "";
                renderObject.isSharedNewText = false;
              }
            });
          });

          return cloneState;
        });

        // selectedObject.attrs.isModify = false;
        // selectedObject.attrs.idOfUserEdited = "";
        // selectedObject.attrs.isSharedNewText = false;

        setTimeout(() => {
          hasGroupShared.current = true;
          setLoadingGeneratingText(false);
          setSelectedObject(null);
          //close modal
          if (divRefModal.current) {
            divRefModal.current.style.visibility = "hidden";
          }
        }, 400);
      } else {
        if (whichAction === "Decline") {
          let textContentsAfterAnyAction = quillRefComponent.current
            .getEditor()
            .getFormat();

          if (textContentsAfterAnyAction.size) {
            beforeFormatsFontSize.current = textContentsAfterAnyAction.size;
          }

          let contentEdited: Quill.DeltaStatic = quillRefComponent?.current
            ?.getEditor()
            ?.getContents();
          interceptModifications.current = true;
          if (
            selectedObject.attrs.isModify &&
            selectedObject.attrs.isSharedNewText
          ) {
            for (let i = 0; i < contentEdited.ops.length; i++) {
              const op = contentEdited.ops[i];
              if (op.attributes && op.attributes.color) {
                const color = op.attributes.color;
                if (color === "#9945ee" || color === "#de2d2d") {
                  contentEdited.ops.splice(i, 1);
                  i--;
                }
              }
            }

            //apply effects
            quillRefComponent?.current?.getEditor()?.setContents(contentEdited);
            handleDeleteElement(selectedObject.attrs.id);
            hasGroupShared.current = true;
          } else {
            if (selectedObject.attrs.initialDelta) {
              //apply effects
              quillRefComponent.current
                .getEditor()
                .setContents(selectedObject.attrs.initialDelta);

              contentEdited = quillRefComponent.current
                .getEditor()
                .getContents();

              contentEdited.forEach((op) => {
                if (op.attributes && op.attributes.color === "#9945ee") {
                  op.insert = "";
                }

                if (op.attributes && op.attributes.color === "#de2d2d") {
                  op.attributes.color = "#000";
                }
              });

              let newDeltaFinal = new Delta(contentEdited);

              if (newDeltaFinal) {
                //apply effects
                quillRefComponent?.current
                  ?.getEditor()
                  ?.setContents(newDeltaFinal);
              }
              hasGroupShared.current = true;
            } else {
              //here will edit the current contents
              contentEdited.forEach((op) => {
                if (op.attributes && op.attributes.color === "#9945ee") {
                  op.insert = "";
                }

                if (op.attributes && op.attributes.color === "#de2d2d") {
                  op.attributes.color = "#000";
                  op.attributes.strike = false;
                }
              });

              let newDelts = new Delta(contentEdited);

              if (newDelts) {
                //apply effects
                quillRefComponent?.current?.getEditor()?.setContents(newDelts);
                hasGroupShared.current = true;
              }
            }
          }

          let myText = quillRefComponent.current.getEditor().getText();
          let myTextLength = myText.length;

          // quillRefComponent.current
          //   .getEditor()
          //   .formatText(0, myTextLength, "size", beforeFormatsFontSize.current);

          // dont forget edit state
          //change formats and initialdelta for main version
          // with unique reason to reset changes
          setObjectScreen((oldState) => {
            let cloneState = cloneDeep(oldState);

            setObjectScreen((oldState) => {
              let cloneState = cloneDeep(oldState);

              cloneState.forEach((page) => {
                page.renderObjects.forEach((renderObject) => {
                  if (renderObject.id === idRichText.current) {
                    renderObject.initialDelta = quillRefComponent?.current
                      ?.getEditor()
                      .getContents();
                    renderObject.format = quillRefComponent?.current
                      ?.getEditor()
                      .getContents();
                    renderObject.isModify = false;
                    renderObject.idOfUserEdited = "";
                    renderObject.isSharedNewText = false;
                  }
                });
              });

              return cloneState;
            });

            return cloneState;
          });

          // selectedObject!.attrs.isModify = false;
          // selectedObject!.attrs.idOfUserEdited = "";
          // selectedObject.attrs.isSharedNewText = false;
        }

        setTimeout(() => {
          setLoadingGeneratingText(false);
          //close modal
          setSelectedObject(null);
          hasGroupShared.current = true;
          if (divRefModal.current) {
            // divRefModal.current.style.visibility = "hidden";
            handleOpenModalDifferencesDecline("Close");
          }
        }, 50);
      }
    },
    [
      selectedObject,
      setSelectedObject,
      divRefModal,
      handleOpenModalDifferencesDecline,
      quillRefComponent,
      handleDeleteElement,
      setObjectScreen,
      hasGroupShared,
    ]
  );

  //get width of object
  const myWidth = useRef("");

  //get the id of rich text element
  useEffect(() => {
    if (selectedObject?.attrs?.text) {
      idRichText.current = selectedObject?.attrs?.id;
      myWidth.current = selectedObject?.attrs?.width;
    }
  }, [selectedObject]);

  // function for control editions
  //on real time
  const findDiff = useCallback(
    (delta?: Quill.DeltaStatic) => {
      //get old contents
      let oldContent: Quill.DeltaStatic = oldContentsRef.current;
      //get newContents on real time
      let newContent: Quill.DeltaStatic = newContentsRef.current;
      //generate delta for include in diff method quill
      let generateDeltaNewContents: Quill.DeltaStatic = new Delta(newContent);
      //get the diff
      let diff: Quill.DeltaStatic = oldContent?.diff(generateDeltaNewContents);
      //modify this diff if delete or insert
      if (diff) {
        for (let i = 0; i < diff?.ops?.length; i++) {
          let op = diff.ops[i];
          // if the change was a deletion
          if (op.hasOwnProperty("delete")) {
            // keep the text
            op.retain = op.delete;
            delete op.delete;
            // but color it red and underline
            op.attributes = {
              color: "red",
              underline: true,
            };
          }
          // if the change was an insertion
          if (op.hasOwnProperty("insert")) {
            // color it green
            op.attributes = {
              color: "#9945EE",
              underline: true,
            };
          }
        }

        //diff and compose quill method with changes
        let adjusted = oldContent.compose(diff);

        // profit!
        quillRef?.current?.getEditor()?.setContents(adjusted);
        //force it focus editor!
        quillRef?.current?.getEditor()?.focus();
        // quillRef?.current?.getEditor()?.setSelection(getQuillCursorRef.current);
      }
    },
    [quillRef.current, oldContentsRef, newContentsRef, interceptModifications]
  );

  //when shared document add property of start delta
  //for use them as initial document for compare
  useEffect(() => {
    const autoLoad = async () => {
      //for detect if really document was shared
      if (templateStartedShared) {
        let cloneState = cloneDeep(objectScreen);
        cloneState.forEach((page, index) => {
          page.renderObjects.forEach((renderObject, index) => {
            if (renderObject.text) {
              let cloneFormat = cloneDeep(renderObject.format);
              renderObject.initialDelta = cloneFormat;
            }
          });
        });
        //set up on main state
        setObjectScreen(cloneState);
        await handleSaveTemplate(true);
      }
    };

    autoLoad();
  }, [templateStartedShared]);

  return (
    <DiffContext.Provider
      value={{
        templateStartedShared,
        setTemplateStartedShared,
        oldContentsState,
        setOldContentsState,
        newContentsState,
        setNewContentsState,
        selectionCursor,
        setSelectionCursor,
        findDiff,
        buttonRef,
        newDelta,
        setNewDelta,
        isEditingQuill,
        setIsEditingQuill,
        newChanges,
        setNewChanges,
        newContentsRef,
        oldContentsRef,
        getQuillCursorRef,
        onBlurQuillText,
        isNewTextRef,
        isTypingQuillRef,
        // hasGroupShared,
        showButtons,
        setShowButtons,
        handleDefineModifications,
        whichUserEdited,
        nameOfUserEdited,
        interceptModifications,
        removeBlurEffect,
        removeBlur,
        textIsChanged,
        quillReComponent,
        handleDecline,
        blockRenderImage,
        blockBackspace,
        handlePasteQuill,
        whichKeyPress,
        selectionFormatText,
        updateIfGroup,
        setUpdateIfGroup,
        loadingChecked,
        currentOwner,
        setCurrentOwner,
        loadingGeneratingText,
        setLoadingGeneratingText,
      }}
    >
      {children}
    </DiffContext.Provider>
  );
};

// creating hook

function useDiff(): TextDiffDataProps {
  const context = useContext(DiffContext);

  if (!context) {
    throw new Error("useDiff must be used with an DiffDocumentsHookProvider");
  }

  return context;
}

export { DiffDocumentsHookProvider, useDiff };
