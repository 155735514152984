import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 15px;

  overflow-y: auto;
`;

export const Template = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    height: 24px;
    width: 24px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;

    position: absolute;
    left: 5px;
    top: 5px;

    accent-color: ${({ theme }) => theme.colors.primary};
    :checked {
      background: ${({ theme }) => theme.colors.primary};
      border: 1px solid #c4c4c4;
      border-radius: 5px;
    }
  }

  p {
    line-height: 16px;
    text-align: center;
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999c9f;
    text-align: start;
  }

  @media (min-width: 1920px) {
    p {
      max-width: 23ch;
    }
  }
`;

export const ImageTemplate = styled.figure`
  width: 161.245px;
  height: 227.967px;
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.25);
  background: #fff;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1920px) {
    width: 202px;
    height: 278px;
  }
`;
