import {
  Container,
  Modal,
  BoxHeader,
  Button,
  DragBox,
  BoxButton,
  SelectDocBox,
  DraggBox,
  ContentPDF,
  Box,
} from "./styles";
import Ilustration from "../../assets/ilustration.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import api from "../../../../../services/api";
// import { cloneDeep } from "lodash";
import { useSign } from "../../../../../hooks/sign";
import { toast } from "react-toastify";
import pdfIcon from "../../assets/PDF_file_icon.svg";
import TemplatesSelectSign from "../../../components/TemplateSelectSign";
// import ReactLoading from "react-loading";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import PersonalizedIcon from "../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../shared/utils/MountIcons";
import ButtonExit from "../../../../../shared/components/ButtonExit";
import PlainText from "../../../../../shared/components/PlainText";
import ButtonMaxWidth from "../../../../../shared/components/ButtonMaxWidth";
import SearchBar from "../../../components/SearchBar";
import { useMainHook } from "../../../../../hooks/main";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { cloneDeep } from "lodash";

interface MyTemplatesProps {
  id: string;
  user_id: string;
  title: string;
  description: string;
  thumbnail: string;
  is_automation: boolean;
  automation_user_template_id: any;
  created_at: string;
  updated_at: string;
  thumbnail_url: string;
  is_checked?: boolean;
}

export interface IListTemplatesChecked {
  id: string
}

const ModalImportDocument = ({ setOpenModal }) => {
  const { debouncedSearchInAssignature } = useMainHook();
  const [myTemplates, setMyTemplates] = useState<MyTemplatesProps[]>([]);
  const [listTemplatesChecked, setListTemplatesChecked] = useState<IListTemplatesChecked[]>([])
  const [currentPage, setCurrentPage] = useState(0);
  const [downloadButton, setDownloadButton] = useState(false);
  const {
    setSignerData,
    uploadedFile,
    setUploadedFile,
    selectableTemplateId,
    setDocumentName,
    setPagesRender,
    generating,
    setGenerating,
    importOriginPage,
    setImportOriginPage,
  } = useSign();

  const [isDragged, setIsDragged] = useState(false);

  const [hasMore, setHasMore] = useState(false);

  const queryClient = useQueryClient();

  const isSearch = useMemo(() => {
    return debouncedSearchInAssignature !== "";
  }, [debouncedSearchInAssignature]);

  const handleNextStepSignDoc = useCallback(async () => {
    if (importOriginPage === "Local") {
      setDownloadButton(true);
      if (uploadedFile) {
        setOpenModal({
          isOpen: true,
          modalName: "Subscriber registration",
        });
      } else {
        setDownloadButton(false);
        return toast.error("Adicione um documento local ou do UX DOC");
      }
    } else {
      setDownloadButton(true);
      setUploadedFile(null);

      try {
        const responseTemplateInfo = await api.get(
          `user-templates/template/${selectableTemplateId}`
        );

        setPagesRender(responseTemplateInfo.data.response.arrayOfPages);

        if (responseTemplateInfo.data.response.arrayOfPages) {
          try {
            const responseTemplateData = await api.get(
              `user-templates/${selectableTemplateId}`
            );

            let nameDocFile = responseTemplateData.data.title;

            if (nameDocFile === undefined) {
              nameDocFile = "Novo documento";

              setDocumentName(nameDocFile);
            } else {
              setDocumentName(
                (state) => (state = responseTemplateData?.data?.title)
              );
            }

            setGenerating(true);
          } catch (err) {
            console.error(err)
          }
        }
      } catch (err) {
        console.error(err, "ERROR when get template!!");
        toast.error("Algo deu errado, tente novamente mais tarde.");
      }
    }
  }, [
    importOriginPage,
    uploadedFile,
    setOpenModal,
    setUploadedFile,
    selectableTemplateId,
    setPagesRender,
    setDocumentName,
    setGenerating,
  ]);

  const handleRemoveFile = useCallback(() => {
    setUploadedFile(null);
  }, [setUploadedFile]);

  const fetchProjects = async ({ pageParam = 0 }) => {
    const response = await api.get("user-templates", {
      params: {
        pagination: pageParam,
        search:
          !isSearch
            ? null
            : debouncedSearchInAssignature,
      },
    });

    let myObjectNew: MyTemplatesProps[] = cloneDeep(
      response.data.dataArray
    );
    myObjectNew.forEach((template) => {
      if (!template.is_checked) {
        template.is_checked = false;
      }
    });

    const { pagination } = response.data;

    if (pagination > currentPage) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }

    return myObjectNew;
  };

  // Preciso fazer com o que data seja alocado no setMyTemplates
  const { data, isLoading, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ["docs", debouncedSearchInAssignature],
    queryFn: fetchProjects,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage && lastPage?.length > 0) {
        const nextPage = pages?.length;
        return nextPage;
      } else {
        return null;
      }
    },
    keepPreviousData: true,
  });

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      let runOneTime = false;
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      // console.error(file.type, "tipo?");
      if (file.type !== "application/pdf") {
        setIsDragged(false);
        if (!runOneTime) {
          runOneTime = true;
          toast.error("Só é permitido inserir arquivos PDF.");
        }
        return;
      }
      setIsDragged(false);
      setUploadedFile(file);
    },
    [setUploadedFile, setIsDragged]
  );

  const handleExitSignDoc = useCallback(() => {
    setOpenModal({
      isOpen: false,
      modalName: "",
    });
    setSignerData([]);
    setUploadedFile(null);
    queryClient.resetQueries(["docs"]);
    // refetch();
  }, [setOpenModal, setSignerData, setUploadedFile, queryClient]);

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    },
    []
  );

  const handleFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file && file.type === "application/pdf") {
        setUploadedFile(file);
      } else {
        toast.error("Só é permitido inserir arquivos PDF.");
        setIsDragged(false);
      }
    },
    [setUploadedFile]
  );

  const handleOpenFileDialog = useCallback(() => {
    const fileInput = document.getElementById("file-input");
    if (fileInput) {
      fileInput.click();
    }
  }, []);


  // UseEffect para fazer com o que o myTemplates pegue os valores de data

  useEffect(() => {
    if (data) {
      // Combine all page results into a single matrix
      const allTemplates = data.pages.reduce((acc, page) => [...acc, ...page], []);

      // Replace checked templates with those in filtersChecked
      const updatedTemplates = allTemplates.map(template => {
        const checkedTemplate = listTemplatesChecked.find(item => item.id === template.id);
        if (checkedTemplate) {
          return {
            ...template,
            is_checked: true,
          };
        }
        return {
          ...template,
          is_checked: false,
        };
      });
      setMyTemplates(updatedTemplates);
    }
  }, [data]);

  return (
    <Container>
      <Modal>
        <BoxHeader>
          <div>
            <Button
              onClick={() => setImportOriginPage("Local")}
              isActive={importOriginPage === "Local"}
            >
              <PersonalizedIcon
                dPath={MountIcons.IconComputer.dPath}
                viewBox={MountIcons.IconComputer.viewBox}
                activeColor="#343A40"
                inactivatedColor="#676B70"
                isActive={importOriginPage === "Local"}
              />
              Importar de Computador
            </Button>
            <Button
              onClick={() => setImportOriginPage("MyProject")}
              isActive={importOriginPage === "MyProject"}
            >
              <PersonalizedIcon
                dPath={MountIcons.MyProjectsIcon.dPath}
                viewBox={MountIcons.MyProjectsIcon.viewBox}
                className="icon-scale"
                activeColor="#343A40"
                inactivatedColor="#676B70"
                isActive={importOriginPage === "MyProject"}
              />
              Importar de Meus Projetos
            </Button>
          </div>
          <ButtonExit onClick={handleExitSignDoc} />
        </BoxHeader>
        {importOriginPage === "MyProject" && (
          <SearchBar
            screen="signatures"
            pageNumber={0}
            description="Digite aqui para encontrar o texto que procura."
            w_100={true}
            isImportAssignature={true}
            setCurrentPage={setCurrentPage}
            setMyTemplates={setMyTemplates}
          />
        )}
        {importOriginPage === "Local" ? (
          <DragBox
            // onDrop={handleDrop}
            onDragEnter={(event) => {
              setIsDragged(true);
            }}
            // onDragLeave={() => setIsDragged(false)}
            onDragOver={handleDragOver}
          >
            {isDragged && (
              <DraggBox
                onDrop={handleDrop}
                onDragEnd={() => setIsDragged(false)}
                onDragLeave={() => {
                  if (!uploadedFile) {
                    setIsDragged(false);
                  }
                }}
                onDragOver={handleDragOver}
              ></DraggBox>
            )}

            {!uploadedFile && (
              <>
                <img src={Ilustration} alt="" />
                <input
                  id="file-input"
                  type="file"
                  accept=".pdf"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
                <button type="button" onClick={handleOpenFileDialog}>
                  Selecionar documento
                </button>
                <PlainText>ou arraste um documento aqui</PlainText>
                <PlainText>No máximo 5MB no formato .pdf</PlainText>
              </>
            )}

            {uploadedFile && (
              <ContentPDF>
                <Box>
                  <img src={pdfIcon} alt="" />
                  <PlainText> {uploadedFile.name} </PlainText>
                  <button type="button" onClick={handleRemoveFile}>
                    Escolher outro documento
                  </button>
                </Box>
              </ContentPDF>
            )}
          </DragBox>
        ) : (
          <SelectDocBox>
            {generating || downloadButton ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ContractAnimation />
              </div>
            ) : (
              <TemplatesSelectSign
                loading={isLoading}
                myTemplates={myTemplates}
                setMyTemplates={setMyTemplates}
                setCurrentPage={setCurrentPage}
                hasMore={hasMore}
                setHasMore={setHasMore}
                fetchNextPage={fetchNextPage}
                listTemplatesChecked={listTemplatesChecked}
                setListTemplatesChecked={setListTemplatesChecked}
              />
            )}
          </SelectDocBox>
        )}
        <BoxButton>
          <ButtonMaxWidth
            text="Cancelar"
            typeStyle="white"
            onClick={handleExitSignDoc}
          />

          <ButtonMaxWidth
            text="Cadastrar assinante"
            typeStyle="purple"
            onClick={handleNextStepSignDoc}
            loading={downloadButton || generating}
          />
        </BoxButton>
      </Modal>
    </Container>
  );
};

export default ModalImportDocument;

