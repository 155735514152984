import { Container, EditName, SubModal, Item, ItemSubModal } from "./styles";
import PersonalizedIcon from "../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../shared/utils/MountIcons";

import { UseMutateFunction } from "react-query";
import { IResponseCreationProjectFromBitsTemplates } from "../../../../../../dtos/CreateNewProjects";

interface IOptionsTemplateTeamProps {
  index: number;
  handleUseTemplate: UseMutateFunction<
    IResponseCreationProjectFromBitsTemplates,
    unknown,
    string,
    unknown
  >;
  id: string;
  handleEditTamplate?: (title: string, id: string) => Promise<void>;
  title: string;
  handleDeleteDocument: (id: string) => Promise<void>;
}

const OptionsTemplateTeam = ({
  index,
  handleUseTemplate,
  id,
  title,
  handleEditTamplate,
  handleDeleteDocument
}: IOptionsTemplateTeamProps) => {
  return (
    <Container isScreenLimit={index === 3 || index === 7}>
      <Item onClick={() => handleUseTemplate(id)}>
        <div>
          <PersonalizedIcon
            dPath={MountIcons.IconOpen.dPath}
            viewBox={MountIcons.IconOpen.viewBox}
          />
          Usar template
        </div>
      </Item>
      <Item onClick={() => handleEditTamplate(title, id)}>
        <div>
          <PersonalizedIcon
            dPath={MountIcons.IconEdit.dPath}
            viewBox={MountIcons.IconEdit.viewBox}
            className="image-scale"
          />
          Editar
        </div>
      </Item>
      <Item onClick={() => handleDeleteDocument(id)}>
        <div>
          <PersonalizedIcon
            dPath={MountIcons.IconTrash.dPath}
            viewBox={MountIcons.IconTrash.viewBox}
          />
          Excluir
        </div>
      </Item>
    </Container>
  );
};

export default OptionsTemplateTeam;
