import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";

import { Animation, BoxText, Container } from "./styles";
import animation from "../../../lotties/animation-page-not-found.json";
import UxDocLogoLarge from "../../components/UxDocLogoLarge";

const PageNotFound = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000)
    return () => setLoading(false);
  }, [])

  if(loading) {
    return (
      <></>
    )
  }
  

  return (
    <Container>
      <UxDocLogoLarge />
      <Animation>
        <Lottie animationData={animation} />
      </Animation>
      <BoxText>
        <h1>Calma é só um Erro 404...</h1>
        <p>
          Isso significa que o que você está procurando pode ter mudado de
          lugar.
          <br />
          Vamos te ajudar no caminho de volta.
        </p>
      </BoxText>
      <Link to={"/"}>Home</Link>
    </Container>
  );
};

export default PageNotFound;
