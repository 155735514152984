import React, { useEffect, useState } from "react";
import { ParagraphObjects } from "../../../types/paragraphObjects";
import Screen from "../Screen";
import { PagesProps } from "../../../../../dtos/PagesProps";
import { IRenderedObject } from "../../../../../dtos/RenderedObject";

interface Props {
  prevStep: () => void;
  handleClose: () => void;
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  paragraphObjects: ParagraphObjects[];
}

const TemplateEmpty: React.FC<Props> = ({
  prevStep,
  handleClose,
  setIsLoading,
  isLoading,
  paragraphObjects
}) => {
  const [finalPageScreen, setFinalPageScreen] = useState<PagesProps[]>([]);

  const builFinalPageScreen = () => {
    const canvasCenterX = 596 / 2;

    const newPages: PagesProps[] = [];
    const pageHeight = 841.89 - 40; // A4 paper height in pixels
    let currentPageNumber = 1;
    let currentPageHeight = 200;
    let currentPageObjects: IRenderedObject[] = [];

    const title = paragraphObjects[0].object;
    const itemX = canvasCenterX - title.width / 2;
    currentPageObjects.push({
      ...title,
      y: 154,
      x: itemX
    });

    paragraphObjects.slice(1).forEach(item => {
      const paragraphObject = item.object;

      const objectHeight = getObjectHeight(item);

      // Check if adding the object and its associated objects will exceed the current page height
      if (currentPageHeight + objectHeight <= pageHeight) {
        paragraphObject.y = currentPageHeight + 20;
        currentPageObjects.push(paragraphObject);
        currentPageHeight += objectHeight + 10;
      } else {
        // Create a new page
        const newPage: PagesProps = {
          pageNumber: currentPageNumber,
          renderObjects: currentPageObjects
        };
        newPages.push(newPage);

        // Reset for the next page
        currentPageNumber++;
        paragraphObject.y = 80;
        currentPageObjects = [paragraphObject];
        currentPageHeight = objectHeight + 80;
      }
    });

    // Add the last page
    if (currentPageObjects.length > 0) {
      const lastPage: PagesProps = {
        pageNumber: currentPageNumber,
        renderObjects: currentPageObjects
      };
      newPages.push(lastPage);
    }

    setFinalPageScreen(newPages);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const getObjectHeight = (object: ParagraphObjects): number => {
    const { object: mainObject } = object;
    let height = 0;

    if (mainObject) {
      height += mainObject.height || 0;
    }

    return height;
  };

  useEffect(() => {
    builFinalPageScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paragraphObjects]);

  return (
    <>
      <Screen
        objectScreen={finalPageScreen}
        prevStep={prevStep}
        handleClose={handleClose}
        isRenderingImage={isLoading}
        saveAsNormalProject={true}
      />
    </>
  );
};

export default TemplateEmpty;
