import React, { useCallback, useState } from "react";

import {
  Box,
  ButtonPayment,
  Container,
  DetailsBox,
  LoadingFlex,
  MonthlyPlan,
  PlansBox,
  RecommendedPlan,
  RecommendedTag
} from "./styles";

import logoUX from "./assets/uxdoc.png";
import { getStripeJs } from "../../../../services/stipe-js";
import api from "../../../../services/api";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import { useMainHook } from "../../../../hooks/main";

const ModalBuyProduct: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { setIsIntentBuyModal } = useMainHook();

  const handleOnClose = useCallback(() => {
    setIsIntentBuyModal(false);
  }, [setIsIntentBuyModal]);

  const handlePlan = useCallback(async (type: "monthly" | "yearly") => {
    try {
      // setIsIntentBuyModal(false);

      setLoading(true);
      const response = await api.post("stripe/create-session-payment", {
        type_plan: type
      });
      const { session_id } = response.data;
      const stripe = await getStripeJs();
      await stripe?.redirectToCheckout({
        sessionId: session_id
      });
    } catch (err) {
      setLoading(true);
      console.error(err, "error when get payment checkout stripe");
      alert(err.message);
    }
  }, []);

  if (loading) {
    return (
      <Container>
        <Box>
          <LoadingFlex>
            <ContractAnimation />
          </LoadingFlex>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box>
        <DetailsBox>
          <img src={logoUX} alt="" />

          <h2>Starter</h2>
          <ul>
            <li>1 usuário</li>
            <li>Documentos ilimitados com legal design</li>
            <li>Modelos prontos</li>
            <li>Insira elementos visuais da sua marca</li>
            <li>Automação</li>
            <li>Banco de texto simplificado</li>
            <li>Ícones jurídicos</li>
            <li>Controle de alterações</li>
            <li>Documento compartilhável</li>
            <li>Armazenamento em nuvem</li>
            <li>Assinatura eletrônica</li>
            <li>Download em Word</li>
          </ul>
        </DetailsBox>
        <PlansBox>
          <div onClick={handleOnClose} className="close-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M18.6899 5.82071C18.5917 5.72227 18.475 5.64417 18.3465 5.59089C18.2181 5.5376 18.0803 5.51017 17.9413 5.51017C17.8022 5.51017 17.6645 5.5376 17.536 5.59089C17.4076 5.64417 17.2909 5.72227 17.1926 5.82071L12 11.0027L6.80736 5.81009C6.70905 5.71178 6.59233 5.6338 6.46388 5.58059C6.33543 5.52738 6.19776 5.5 6.05873 5.5C5.91969 5.5 5.78202 5.52738 5.65357 5.58059C5.52512 5.6338 5.40841 5.71178 5.31009 5.81009C5.21178 5.90841 5.1338 6.02512 5.08059 6.15357C5.02738 6.28202 5 6.41969 5 6.55873C5 6.69776 5.02738 6.83543 5.08059 6.96388C5.1338 7.09233 5.21178 7.20905 5.31009 7.30736L10.5027 12.5L5.31009 17.6926C5.21178 17.791 5.1338 17.9077 5.08059 18.0361C5.02738 18.1646 5 18.3022 5 18.4413C5 18.5803 5.02738 18.718 5.08059 18.8464C5.1338 18.9749 5.21178 19.0916 5.31009 19.1899C5.40841 19.2882 5.52512 19.3662 5.65357 19.4194C5.78202 19.4726 5.91969 19.5 6.05873 19.5C6.19776 19.5 6.33543 19.4726 6.46388 19.4194C6.59233 19.3662 6.70905 19.2882 6.80736 19.1899L12 13.9973L17.1926 19.1899C17.291 19.2882 17.4077 19.3662 17.5361 19.4194C17.6646 19.4726 17.8022 19.5 17.9413 19.5C18.0803 19.5 18.218 19.4726 18.3464 19.4194C18.4749 19.3662 18.5916 19.2882 18.6899 19.1899C18.7882 19.0916 18.8662 18.9749 18.9194 18.8464C18.9726 18.718 19 18.5803 19 18.4413C19 18.3022 18.9726 18.1646 18.9194 18.0361C18.8662 17.9077 18.7882 17.791 18.6899 17.6926L13.4973 12.5L18.6899 7.30736C19.0934 6.90384 19.0934 6.22423 18.6899 5.82071Z"
                fill="#999C9F"
              />
            </svg>
          </div>
          <RecommendedPlan>
            <RecommendedTag>
              <span>Recomendado</span>
            </RecommendedTag>
            <h4>Plano anual</h4>
            <span className="dashed-text">
              {" "}
              <span
                style={{ textDecoration: "line-through", marginRight: "5px" }}
              >
                {" "}
                R$ 718,80{" "}
              </span>
              <span className="dark-text"> R$ 478,92 | </span>{" "}
              <span className="green-text"> 33% de desconto </span>
            </span>
            <div className="box-information-values">
              <span className="information-values">
                Equivalente a R$ 39,91/mês
              </span>
            </div>

            <span className="just-one-payment-text">
              Pagamento único. Renovação automática.
            </span>

            <ButtonPayment onClick={() => handlePlan("yearly")}>
              Plano anual
            </ButtonPayment>
          </RecommendedPlan>
          <MonthlyPlan>
            <h4>Plano mensal</h4>
            <span className="dark-text">R$ 59,90/mês</span>{" "}
            <span
              style={{ marginBottom: "5px" }}
              className="just-one-payment-text"
            >
              Renovação automática.
            </span>
            <ButtonPayment onClick={() => handlePlan("monthly")}>
              Plano mensal
            </ButtonPayment>
          </MonthlyPlan>
        </PlansBox>
      </Box>
    </Container>
  );
};

export default ModalBuyProduct;
