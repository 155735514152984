import styled from "styled-components";

export const TableContainer = styled.div`
  width: 100%;
  border: 1px solid #ebebec;
  border-radius: 5px;
  position: relative;
  overflow: auto;
  height: calc(100vh - 250px);
  padding-bottom: 40px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const THead = styled.thead`
  background-color: #ebebec;
`;
export const TableHead = styled.th`
  padding: 10px 20px;
  text-align: left;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray600};
  font-size: 13px;
  position: sticky;
  top: 0;
  background-color: #ebebec;
  z-index: 50;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ebebec;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const TableData = styled.td`
  padding: 5px 20px;
  font-size: 14px;
  height: 41px;
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #676b70;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  background-color: white;
`;
