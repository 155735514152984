import React, {
  createContext,
  ReactNode,
  useContext,
  useRef,
  useState
} from "react";
import { IModalSign, SignDataProps } from "../dtos/signedProps";
import { PagesProps } from "../dtos/PagesProps";

interface SharedDocumentProps {
  children: ReactNode;
}

interface UsersListProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  type: string;
  others?: string;
}
interface ArrayProps {
  id: string;
  value: string;
}

interface SubmitProps {
  status: string;
  created_at_start: string;
  created_at_end: string;
  final_date_start: string;
  final_date_end: string;
  created_at?: string;
  final_date?: string;
  data_envio?: string;
  data_expedicao?: string;
}
type IImportOriginPage = "Local" | "MyProject";

interface SignPropsData {
  isSignEditor: boolean;
  setIsSignEditor: React.Dispatch<React.SetStateAction<boolean>>;
  dataSign: SignDataProps[];
  setDataSign: React.Dispatch<React.SetStateAction<SignDataProps[]>>;
  amountSignDocs: {
    spam: number;
    docs: number;
  };
  dateFormatted: string;
  setDateFormatted: React.Dispatch<React.SetStateAction<string>>;
  signerData: {
    firstName: string;
    lastName: string;
    email: string;
    type: string;
    others?: string;
    id: string;
  }[];
  setSignerData: React.Dispatch<
    React.SetStateAction<
      {
        firstName: string;
        lastName: string;
        email: string;
        type: string;
        others?: string;
        id: string;
      }[]
    >
  >;
  openModal: IModalSign;
  setOpenModal: React.Dispatch<React.SetStateAction<IModalSign>>;
  uploadedFile: File;
  setUploadedFile: React.Dispatch<React.SetStateAction<File>>;
  loadingDocs: boolean;
  setLoadingDocs: React.Dispatch<React.SetStateAction<boolean>>;
  paramsDocsSign: {
    pagination: number;
  };
  setParamsDocSign: React.Dispatch<
    React.SetStateAction<{
      pagination: number;
    }>
  >;
  totalPagesDocs: number;
  setTotalPagesDocs: React.Dispatch<React.SetStateAction<number>>;
  documentName: string;
  setDocumentName: React.Dispatch<React.SetStateAction<string>>;
  generating: boolean;
  setGenerating: React.Dispatch<React.SetStateAction<boolean>>;
  pagesRender: PagesProps[];
  setPagesRender: React.Dispatch<React.SetStateAction<PagesProps[]>>;
  selectableTemplateId: string;
  setSelectableTemplateId: React.Dispatch<React.SetStateAction<string>>;
  docToRemove: string;
  setDocToRemove: React.Dispatch<React.SetStateAction<string>>;
  isOpenOptions: boolean;
  setIsOpenOptions: React.Dispatch<React.SetStateAction<boolean>>;
  generateThumb: boolean;
  setGenerateThumb: React.Dispatch<React.SetStateAction<boolean>>;
  urlPdf: string;
  setUrlPdf: React.Dispatch<React.SetStateAction<string>>;
  idPdfFile: React.MutableRefObject<string>;
  refreshPage: boolean;
  setRefreshPage: React.Dispatch<React.SetStateAction<boolean>>;
  dataSpamDocs: SignDataProps[];
  setDataSpamDocs: React.Dispatch<React.SetStateAction<SignDataProps[]>>;
  loadingSpamDocs: boolean;
  setLoadingSpamDocs: React.Dispatch<React.SetStateAction<boolean>>;
  totalPagesSpamDocs: number;
  setTotalPagesSpamDocs: React.Dispatch<React.SetStateAction<number>>;
  setAmountSignDocs: React.Dispatch<
    React.SetStateAction<{
      spam: number;
      docs: number;
    }>
  >;
  importOriginPage: IImportOriginPage;
  setImportOriginPage: React.Dispatch<React.SetStateAction<IImportOriginPage>>;
  arrayFilters: ArrayProps[];
  setArrayFilters: React.Dispatch<React.SetStateAction<ArrayProps[]>>;
  filters: SubmitProps;
  setFilters: React.Dispatch<React.SetStateAction<SubmitProps>>;
}

const SignContext = createContext<SignPropsData>({} as SignPropsData);

const SignHookProvider: React.FC<SharedDocumentProps> = ({ children }) => {
  const [dataSign, setDataSign] = useState<SignDataProps[]>([]);
  const [dataSpamDocs, setDataSpamDocs] = useState<SignDataProps[]>([]);
  const [isSignEditor, setIsSignEditor] = useState(false);
  const [loadingDocs, setLoadingDocs] = useState(true);
  const [amountSignDocs, setAmountSignDocs] = useState({
    spam: 0,
    docs: 0
  });
  const [dateFormatted, setDateFormatted] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [generating, setGenerating] = useState(false);
  const [pagesRender, setPagesRender] = useState<PagesProps[]>([]);
  const [signerData, setSignerData] = useState<UsersListProps[]>([]);
  const [openModal, setOpenModal] = useState<IModalSign>({
    isOpen: false,
    modalName: ""
  });
  const [refreshPage, setRefreshPage] = useState(false);
  const [urlPdf, setUrlPdf] = useState("");
  const [loadingSpamDocs, setLoadingSpamDocs] = useState(true);
  const [generateThumb, setGenerateThumb] = useState(false);
  const [selectableTemplateId, setSelectableTemplateId] = useState("");
  const [totalPagesDocs, setTotalPagesDocs] = useState(0);
  const [paramsDocsSign, setParamsDocSign] = useState({
    pagination: 0
  });
  const [totalPagesSpamDocs, setTotalPagesSpamDocs] = useState(0);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [importOriginPage, setImportOriginPage] =
    useState<IImportOriginPage>("Local");

  const [docToRemove, setDocToRemove] = useState("");
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [arrayFilters, setArrayFilters] = useState<ArrayProps[]>([]);
  const [filters, setFilters] = useState<SubmitProps>({
    created_at_start: '',
    created_at_end: '',
    final_date_start: '',
    final_date_end: ''
  } as SubmitProps);

  const idPdfFile = useRef("");

  return (
    <SignContext.Provider
      value={{
        isSignEditor,
        setIsSignEditor,
        dataSign,
        setDataSign,
        amountSignDocs,
        dateFormatted,
        setDateFormatted,
        signerData,
        setSignerData,
        openModal,
        setOpenModal,
        uploadedFile,
        setUploadedFile,
        loadingDocs,
        setLoadingDocs,
        paramsDocsSign,
        setParamsDocSign,
        totalPagesDocs,
        setTotalPagesDocs,
        documentName,
        setDocumentName,
        generating,
        setGenerating,
        pagesRender,
        setPagesRender,
        selectableTemplateId,
        setSelectableTemplateId,
        docToRemove,
        setDocToRemove,
        isOpenOptions,
        setIsOpenOptions,
        generateThumb,
        setGenerateThumb,
        urlPdf,
        setUrlPdf,
        idPdfFile,
        refreshPage,
        setRefreshPage,
        dataSpamDocs,
        setDataSpamDocs,
        loadingSpamDocs,
        setLoadingSpamDocs,
        totalPagesSpamDocs,
        setTotalPagesSpamDocs,
        setAmountSignDocs,
        importOriginPage,
        setImportOriginPage,
        arrayFilters,
        setArrayFilters,
        filters,
        setFilters
      }}
    >
      {children}
    </SignContext.Provider>
  );
};

// creating hook

function useSign(): SignPropsData {
  const context = useContext(SignContext);

  if (!context) {
    throw new Error("useSign must be used with an SignHookProvider");
  }

  return context;
}

export { SignHookProvider, useSign };
