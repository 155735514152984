import React, { useCallback, useEffect } from "react";
import Modal from "react-modal";
import ButtonPrimary from "../ButtonPrimary";
import { InputModal } from "../InputModal";
import { Box, ContainerModal, FormBox, Text, TextArea } from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import { useVersioning } from "../../hooks/versioning";

import PuffLoader from "react-spinners/PuffLoader";
import { LoadingFlex } from "../../../user/unknown/login/pages/Login/styles";

interface ModalVersioningProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ModalDescriptionVersioning: React.FC<ModalVersioningProps> = ({
  isOpen,
  onRequestClose
}) => {
  const {
    descriptionHistory,
    setDescriptionHistory,
    handleCreateNewVersionTemplate,
    loadingCreateVersion
  } = useVersioning();

  const handleSubmitSaveDescriptionVersioning = useCallback(
    e => {
      e.preventDefault();
      handleCreateNewVersionTemplate();
    },
    [descriptionHistory]
  );

  const container = {
    hidden: { opacity: 0, scale: 0.9, stiffness: 60, duration: 10000 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 300,
        stiffness: 100,
        staggerDirection: -2
      }
    },
    exit: {}
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.9,
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <Modal
      className="react-modal-content-description"
      overlayClassName="react-modal-overlay-description"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {/* <ExitIcon src={iconExit} onClick={onRequestClose} /> */}
      <ContainerModal
        as={motion.div}
        initial="hidden"
        animate="show"
        variants={container}
        exit={{
          opacity: 0,
          scale: 0.8,
          x: -300
        }}
        transition={{ duration: 1 }}
      >
        <FormBox onSubmit={handleSubmitSaveDescriptionVersioning}>
          <TextArea
            as={motion.textarea}
            variants={item}
            placeholder="Escrever detalhes da versão"
            maxLength={150}
            required
            value={descriptionHistory}
            onChange={e => setDescriptionHistory(e.target.value)}
          ></TextArea>

          <ButtonPrimary>
            {loadingCreateVersion ? (
              <LoadingFlex>
                <PuffLoader color="#ffff" size="28" />
              </LoadingFlex>
            ) : (
              "Salvar"
            )}
          </ButtonPrimary>
        </FormBox>
      </ContainerModal>
    </Modal>
  );
};

export default ModalDescriptionVersioning;
