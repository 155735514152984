import styled from "styled-components";

export const ModalShowClausule = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  width: 700px;
  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  input {
    color: #000;
    border: solid 1px #a5a5a5;
    height: 40px;
    width: 100%;
    border-radius: 3px;
    padding-left: 15px;

    ::placeholder {
      color: #a5a5a5;
    }
  }

  @media (max-height: 560px) {
    gap: 10px;
    padding: 10px;

    input {
      height: 35px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  label {
    font-family: "Ubuntu";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
    display: inline-block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    height: 40px;
    padding: 11.5px 10px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.black50};

    :focus {
      border: 1px solid ${({ theme }) => theme.colors.focus};
    }
  }
`;

export const BoxChip = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputText = styled.textarea`
  height: 150px;
  width: 100%;
  color: #000;
  border: solid 1px #a5a5a5;
  border-radius: 5px;
  padding: 10px 15px;

  box-sizing: border-box;

  resize: none;
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.focus};
  }

  ::placeholder {
    color: #a5a5a5;
  }
`;

export const BoxWarning = styled.div`
  border: 1px solid #de2d2d;
  width: 100%;

  border-radius: 5px;

  padding: 14.8px 23.87px;

  display: flex;
  align-items: center;
  gap: 8px;

  p {
    text-align: justify;
    font-family: "Ubuntu";
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.black100};
    width: 96%;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const BoxButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 400px;
  transform: translateY(30px);
`;

export const ButtonSave = styled.button`
  width: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  height: 40px;

  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;

  :hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }
`;
