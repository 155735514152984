import React, { useCallback, useEffect, useState } from "react";

import {
  BoxImage,
  Container,
  GridImage,
  SearchBox,
  VectorsGrid
} from "./styles";
import imageExample from "../../assets/text-box-07-green-orange.png";
import imageSearching from "../../assets/icon-search.svg";

import api from "../../../../services/api";
import { AxiosResponse } from "axios";
import { useWorkspaceEditor } from "../../hooks/workspaceEditor";
import ReactLoading from "react-loading";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import Tooltip from "../Tooltip";
import ButtonExit from "../../../../shared/components/ButtonExit";

interface objectImageProps {
  id: string;
  name: string;
  image: string;
  image_url: string;
  created_at: string;
  updated_at: string;
}

interface ResponseVectorsProps {
  pagination: number;
  dataArray: objectImageProps[];
}

const VectorsImage: React.FC = () => {
  const [vectorsData, setVectorsData] = useState<objectImageProps[]>([]);
  const { draggableVector } = useWorkspaceEditor();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [isFocus, setIsFocus] = useState(false);

  const deBounceSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length > 0) {
        setLoading(true);
      } else {
        setLoading(false);
      }
      setSearch(event.target.value);
    },
    [setSearch]
  );

  useEffect(() => {
    if (search === "" && vectorsData.length > 0) {
      setVectorsData([]);
    }
  }, [search]);

  useEffect(() => {
    setCurrentPage(0);
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  useEffect(() => {
    (async () => {
      try {
        const responseVectors: AxiosResponse<ResponseVectorsProps> =
          await api.get("bits-image", {
            params: {
              pagination: currentPage,
              search: debouncedSearch ? debouncedSearch : null
            }
          });

        if (debouncedSearch) {
          setVectorsData(responseVectors.data.dataArray);
        } else {
          setVectorsData(oldState => [
            ...oldState,
            ...responseVectors.data.dataArray
          ]);
        }
        setLoading(false);
      } catch (err) {
        console.error(err, "ERROR response vectors api");
      }
    })();

    return () => {};
  }, [currentPage, debouncedSearch]);

  //verifing with the api if next page exists
  useEffect(() => {
    const autoLoad = async () => {
      const response = await api.get("bits-image", {
        params: {
          pagination: currentPage + 1
        }
      });
      //if return length then has more pages
      if (response.data.dataArray.length > 0) {
        setHasMore(true);
      } else {
        //if return length 0 because doesn`t exists any pages more
        setHasMore(false);
      }
    };

    autoLoad();
  }, [currentPage]);

  //intercept div for generate infinity scroll
  //for pagination observer when final div appear2
  useEffect(() => {
    const myElement = document.querySelector("#vectorsMorePage");

    if (myElement && !loading) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          if (hasMore) {
            setCurrentPage(currentPage + 1);
          }
        }
      });
      //when intersection id sentinella appear
      intersectionObserver?.observe(
        document?.querySelector("#vectorsMorePage")
      );
      //cleanup function for observer
      return () => intersectionObserver?.disconnect();
    }
  }, [loading, hasMore, debouncedSearch, currentPage]);

  return (
    <Container>
      <Tooltip
        title="Pesquisar"
        description="Digite aqui para encontrar o que procura"
        placement="bottom-start"
      >
        <SearchBox>
          <img src={imageSearching} alt="" />

          <input
            value={search}
            onChange={deBounceSearch}
            type="text"
            onFocus={() => setIsFocus(true)}
          />

          {isFocus && (
            <button>
              <ButtonExit
                inactivatedColor="#999C9F"
                onClick={() => {
                  setSearch("");
                  setIsFocus(false);
                }}
              />
            </button>
          )}
        </SearchBox>
      </Tooltip>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex"
          }}
        >
          <ContractAnimation />
        </div>
      ) : (
        <VectorsGrid>
          {vectorsData.map((vector, index) => (
            <Tooltip
              title="Arraste para usar"
              placement="bottom-start"
              key={index}
            >
              <BoxImage>
                <GridImage
                  onDragStart={(e: React.DragEvent<HTMLImageElement>) => {
                    draggableVector.current = vector.image_url;
                  }}
                  style={{ cursor: "grab" }}
                  draggable
                  vector={vector.image_url}
                />
              </BoxImage>
            </Tooltip>
          ))}
        </VectorsGrid>
      )}
      {!loading && hasMore && !debouncedSearch && (
        <div
          id="vectorsMorePage"
          style={{
            width: "100%",
            height: "50px",
            marginTop: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <ReactLoading type="spin" color="#000" height={22} width={22} />
        </div>
      )}
    </Container>
  );
};

export default VectorsImage;
