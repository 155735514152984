export const dataAlpha = [
  {
    letter: "todos",
    object: "all"
  },
  {
    letter: "A",
    object: "a"
  },
  {
    letter: "B",
    object: "b"
  },
  {
    letter: "C",
    object: "c"
  },
  {
    letter: "D",
    object: "d"
  },
  {
    letter: "E",
    object: "e"
  },
  {
    letter: "F",
    object: "f"
  },
  {
    letter: "G",
    object: "g"
  },
  {
    letter: "H",
    object: "h"
  },
  {
    letter: "I",
    object: "i"
  },
  {
    letter: "J",
    object: "j"
  },
  {
    letter: "K",
    object: "k"
  },
  {
    letter: "L",
    object: "l"
  },
  {
    letter: "M",
    object: "m"
  },
  {
    letter: "N",
    object: "n"
  },
  {
    letter: "O",
    object: "o"
  },
  {
    letter: "P",
    object: "p"
  },
  {
    letter: "Q",
    object: "q"
  },
  {
    letter: "R",
    object: "r"
  },
  {
    letter: "S",
    object: "s"
  },
  {
    letter: "T",
    object: "t"
  },
  {
    letter: "U",
    object: "u"
  },
  {
    letter: "V",
    object: "v"
  },
  {
    letter: "W",
    object: "w"
  },
  {
    letter: "X",
    object: "x"
  },
  {
    letter: "Y",
    object: "y"
  },
  {
    letter: "Z",
    object: "z"
  }
];
