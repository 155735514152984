import styled, { css } from "styled-components";
import { Avatar as AvatarMUI } from "@material-ui/core";

interface AvataProps {
  color?: string;
  src?: string;
}

interface AvatarMUIProps {
  isHeader?: boolean;
  isDropdown?: boolean;
}

interface TextAvatarProps {
  isDropdown?: boolean;
}

export const SmallestSizeCSS = css`
  width: 30px;
  height: 30px;
  ${props => props.theme.breakpoints.up("lg")} {
    width: 35px;
    height: 35px;
  }
`;

export const SmallerSizeCSS = css`
  width: 36px;
  height: 36px;
  ${props => props.theme.breakpoints.up("lg")} {
    width: 44px;
    height: 44px;
  }
`;

export const SmallSizeCSS = css`
  width: 40px;
  height: 40px;
  ${props => props.theme.breakpoints.up("lg")} {
    width: 50px;
    height: 50px;
  }
`;

export const MediumSizeCSS = css`
  width: 60px;
  height: 60px;
  ${props => props.theme.breakpoints.up("lg")} {
    width: 80px;
    height: 80px;
  }
`;

export const BigSizeCSS = css`
  width: 120px;
  height: 120px;
  ${props => props.theme.breakpoints.up("lg")} {
    width: 150px;
    height: 150px;
  }
`;

export const Avatar = styled.div`
  align-items: center;
  color: #fff;
  background-color: #bdbdbd;
  border: 4px solid #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  font-family: Nunito-Bold;
  font-size: 1rem;
  height: 30px;
  overflow: hidden;
  text-align: center;
  width: 30px;
  margin-left: auto;
  cursor: pointer;
  img {
    margin-right: 0.5rem;
    object-fit: contain;
    cursor: pointer;
  }
`;

export const AvatarLetter = styled.div<AvataProps>`
  height: 30px;
  width: 30px;
  background-color: ${({ theme, color }) =>
    color ? color : theme.colors.background};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid white;
  cursor: pointer;
`;

export const AvatarAnnonymous = styled(AvatarMUI)<AvatarMUIProps>`
  max-width: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6858bb !important;
  opacity: ${({ isHeader }) => (isHeader ? "1" : "0.5")};
`;

export const AvatarLetterImage = styled.div<AvataProps>`
  height: 30px;
  width: 30px;
  background-image: url(${({ src }) => src && src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid white;
  cursor: pointer;
`;

export const AvatarLetterImageEditor = styled.div<AvataProps>`
  height: 30px;
  width: 30px;
  background-image: url(${({ src }) => src && src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid white;
  cursor: pointer;
`;

export const TextAvatar = styled.p<TextAvatarProps>`
  font-size: ${({ isDropdown }) => (isDropdown ? "3rem" : "1.8rem")};
  font-weight: bold;
  cursor: pointer;
  display: flex;
  color: white;

  @media (max-width: 1600px) {
    font-size: ${({ isDropdown }) => (isDropdown ? "2.4rem" : "1.8rem")};
  }

  @media (max-width: 1366px) {
    font-size: ${({ isDropdown }) => (isDropdown ? "2.4rem" : "1.4rem")};
    margin-top: 0.1rem;
  }
`;
